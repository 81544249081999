import React, { useEffect, useState } from "react";
import { defaultSquadLogo } from "helpers/IconHelper";
import { StyledImage } from "./ImageStyle";

const SquadLogo: React.FC<{ url: string, name?: string }> = ({ url, name }) => {
    const [src, setSrc] = useState(() => url || "");

    useEffect(() => {
        if (url) setSrc(url);
    }, [url]);

    return <StyledImage key={src} src={src} alt={name} onError={() => setSrc(defaultSquadLogo)} />;
};

export default SquadLogo;
