import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";
import React from "react";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Button from "../../designToken/button/Button";
import Dialog, { DIALOG_SIZE } from "../../designToken/dialog/Dialog";
import { CardBody, CardText } from "./FantasyDialogStyle";

export enum FANTASY_DIALOG_VARIANT {
    FANTASY_BASIC_POPUP

}

export interface FantasyDialogProps {
    variant?: FANTASY_DIALOG_VARIANT
    show: boolean
    onHide?: () => void
    title?: string
    text?: string | React.ReactNode
    textClassName?: string
    bodyComponent?: React.ReactNode
    confirmButton?: boolean
    cancelButton?: boolean
    confirmButtonText?: string
    cancelButtonText?: string
    confirmButtonVariant?: BUTTON_VARIANTS
    cancelButtonVariant?: BUTTON_VARIANTS
    buttonPattern?: BUTTON_PATTERN
    buttonSize?: BUTTON_SIZE
    confirmButtonClassName?: string
    cancelButtonClassName?: string
    onConfirm?: () => void
    onCancel?: () => void
    imageSection?: React.ReactNode,
    size?: DIALOG_SIZE
}

const FantasyDialog: React.FC<FantasyDialogProps> = ({
                                                         show,
                                                         onHide,
                                                         title,
                                                         text,
                                                         textClassName,
                                                         bodyComponent,
                                                         confirmButton = true,
                                                         cancelButton = true,
                                                         confirmButtonText,
                                                         cancelButtonText,
                                                         confirmButtonVariant = BUTTON_VARIANTS.primary,
                                                         cancelButtonVariant = BUTTON_VARIANTS.outlineDark,
                                                         buttonPattern = BUTTON_PATTERN.pill,
                                                         buttonSize = BUTTON_SIZE.md,
                                                         confirmButtonClassName,
                                                         cancelButtonClassName,
                                                         onConfirm,
                                                         onCancel,
                                                         imageSection,
                                                         size
                                                     }) => {
    return <Dialog show={show} onHide={onHide} size={size}>
        <Card className="border-0">
            {imageSection}
            <CardBody>
                {
                    title &&
                    <Card.Title className="fz-sf-bold-title-3 text-light-primary">{title}</Card.Title>
                }
                {
                    text &&
                    <CardText className={`fz-sf-regular-body-2 text-light-primary ${textClassName}`}>
                        {text}
                    </CardText>
                }
                {
                    bodyComponent
                }
                <div className="d-flex flex-wrap gap-3">
                    {
                        confirmButton &&
                        <Button
                            variant={confirmButtonVariant}
                            pattern={buttonPattern}
                            size={buttonSize}
                            className={`w-100 ${confirmButtonClassName}`}
                            onClick={onConfirm}
                        >
                            {confirmButtonText || <FormattedMessage id="button_confirm" />}
                        </Button>
                    }
                    {
                        cancelButton &&
                        <Button
                            variant={cancelButtonVariant}
                            pattern={buttonPattern}
                            size={buttonSize}
                            className={`w-100 ${cancelButtonClassName}`}
                            onClick={onCancel}
                        >
                            {cancelButtonText || <FormattedMessage id="button_cancel" />}
                        </Button>
                    }
                </div>
            </CardBody>
        </Card>
    </Dialog>;
};

export default FantasyDialog;
