import { GameLogVo } from "data/vo/player/gameLog/GameLogVo";
import { FlattedGameLogProps } from "data/vo/player/PlayerInfoVo";
import { divideByOneHundred } from "helpers/number/NumberHelper";

export class FootballGameLogVo extends GameLogVo {
    private readonly _assist: number;
    private readonly _shotsOnTarget: number;
    private readonly _chanceCreated: number;
    private readonly _passesCompleted: number;
    private readonly _tackleWon: number;
    private readonly _interceptionWon: number;
    private readonly _save: number;
    private readonly _penaltiesSaved: number;
    private readonly _cleanSheet: number;
    private readonly _yellowCard: number;
    private readonly _redCard: number;
    private readonly _ownGoal: number;
    private readonly _goalsConceded: number;
    private readonly _penaltyMissed: number;
    private readonly _starting11: number;
    private readonly _substitute: number;
    private readonly _goal: number;

    constructor(gameLog: FlattedGameLogProps) {
        super(gameLog);
        this._goal = gameLog.goal ?? 0;
        this._assist = gameLog.assist ?? 0;
        this._shotsOnTarget = gameLog.shotsOnTarget ?? 0;
        this._chanceCreated = gameLog.chanceCreated ?? 0;
        this._passesCompleted = gameLog.passesCompleted ?? 0;
        this._tackleWon = gameLog.tackleWon ?? 0;
        this._interceptionWon = gameLog.interceptionWon ?? 0;
        this._save = gameLog.save ?? 0;
        this._penaltiesSaved = gameLog.penaltiesSaved ?? 0;
        this._cleanSheet = gameLog.cleanSheet ?? 0;
        this._yellowCard = gameLog.yellowCard ?? 0;
        this._redCard = gameLog.redCard ?? 0;
        this._ownGoal = gameLog.ownGoal ?? 0;
        this._goalsConceded = gameLog.goalsConceded ?? 0;
        this._penaltyMissed = gameLog.penaltyMissed ?? 0;
        this._starting11 = gameLog.starting11 ?? 0;
        this._substitute = gameLog.substitute ?? 0;
    }

    get assist(): number {
        return divideByOneHundred(this._assist);
    }

    get shotsOnTarget(): number {
        return divideByOneHundred(this._shotsOnTarget);
    }

    get chanceCreated(): number {
        return divideByOneHundred(this._chanceCreated);
    }

    get passesCompleted(): number {
        return divideByOneHundred(this._passesCompleted);
    }

    get tackleWon(): number {
        return divideByOneHundred(this._tackleWon);
    }

    get interceptionWon(): number {
        return divideByOneHundred(this._interceptionWon);
    }

    get save(): number {
        return divideByOneHundred(this._save);
    }

    get penaltiesSaved(): number {
        return divideByOneHundred(this._penaltiesSaved);
    }

    get cleanSheet(): number {
        return divideByOneHundred(this._cleanSheet);
    }

    get yellowCard(): number {
        return divideByOneHundred(this._yellowCard);
    }

    get redCard(): number {
        return divideByOneHundred(this._redCard);
    }

    get ownGoal(): number {
        return divideByOneHundred(this._ownGoal);
    }

    get goalsConceded(): number {
        return divideByOneHundred(this._goalsConceded);
    }

    get penaltyMissed(): number {
        return divideByOneHundred(this._penaltyMissed);
    }

    get starting11(): number {
        return divideByOneHundred(this._starting11);
    }

    get substitute(): number {
        return divideByOneHundred(this._substitute);
    }

    get goal(): number {
        return divideByOneHundred(this._goal);
    }

}
