import React, { useRef } from "react";
import { InputGroup } from "react-bootstrap";
import { BottomMessage, ErrorMessage, InputGroupWrapper, Label, TextareaField } from "./Styles";
import { hasError } from "./TextFields.helper";

const Textarea: React.FC<TextareaProps> = ({
                                               label = "",
                                               value,
                                               onChange,
                                               placeholder = "",
                                               errorMessage = "",
                                               bottomMessage = "",
                                           }) => {

    const inputRef = useRef<HTMLTextAreaElement>(null);

    const focusToInput = () => {
        inputRef.current?.focus();
    };

    return (
        <InputGroupWrapper className={hasError(errorMessage)}>
            <Label onClick={focusToInput}>{label}</Label>
            <InputGroup>
                <TextareaField value={value} placeholder={placeholder} onChange={onChange} ref={inputRef} />
            </InputGroup>
            {
                errorMessage
                && <ErrorMessage>{errorMessage}</ErrorMessage>
            }
            {
                bottomMessage
                && <BottomMessage>{bottomMessage}</BottomMessage>
            }
        </InputGroupWrapper>
    );
};

interface TextareaProps {
    label: string,
    value: string,
    onChange?: () => void,
    placeholder?: string,
    errorMessage?: string,
    bottomMessage?: string,
}

export default Textarea;
