import React from "react";
import { FormattedMessage } from "react-intl";
import { EnrichedCoupon } from "../../../types/coupon/Coupon";
import { CouponIcon, CouponInfo } from "./CouponStyle";


const FreeCouponContent = ({ icon }) => {
    return <>
        <CouponIcon className="bg-sys-danger">{icon}</CouponIcon>
        <CouponInfo>
            <div className="fz-sf-bold-body-2 text-sys-danger-1">
                <FormattedMessage id="free_coupon_title" />
            </div>
            <div className="fz-sf-regular-footnote text-text-secondary-1">
                <FormattedMessage id="free_coupon_description" />
            </div>
        </CouponInfo>
    </>;
};

const NotFreeCouponContent = ({ icon, discountPercentage }) => {
    return <>
        <CouponIcon className="bg-primary-3">{icon}</CouponIcon>
        <CouponInfo>
            <div className="fz-sf-bold-body-2 text-primary-2">
                {discountPercentage}% OFF
            </div>
            <div className="fz-sf-regular-footnote text-text-secondary-1">
                <FormattedMessage id="coupon_description" values={{
                    percentage: discountPercentage,
                }} />
            </div>
        </CouponInfo>
    </>;
};

interface CouponCardBodyContentProps {
    coupon: EnrichedCoupon;
}

const CouponCardBodyContent = ({ coupon }: CouponCardBodyContentProps) => {
    return coupon.isFree
        ? <FreeCouponContent icon={coupon.icon} />
        : <NotFreeCouponContent icon={coupon.icon}
                                discountPercentage={coupon.discountPercentage} />;

};

export default CouponCardBodyContent;
