import FantasyFormInput from "components/input/FantasyFormInput";
import { ReferralRepository } from "data/repository/referral/ReferralRepository";
import Button from "designToken/button/Button";
import { isCanNotUseSelfCode, isDataNotFound, isReferrerValidateError } from "helpers/ErrorHelper";
import { useReferredSuccessDialog } from "hooks/useDialog";
import useFantasyCommand from "hooks/useFantasyCommand";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { FieldValues, useForm } from "react-hook-form";
import { MdOutlineReduceCapacity } from "react-icons/md";
import { useIntl } from "react-intl";
import useValidator from "hooks/useValidator";
import useHandleApiError, { ERROR_HANDLER_FLOW } from "hooks/useHandleApiError";
import { ApiError } from "apis/Api";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";

interface ReferralCodeInputProps {
    buttonPattern: BUTTON_PATTERN
    buttonText: string
    onCancel: () => void
}

export const ReferralCodeInput: React.FC<ReferralCodeInputProps> = ({
                                                                        buttonPattern,
                                                                        buttonText,
                                                                        onCancel
                                                                    }) => {
    const {
        register,
        getValues,
        formState: {
            errors
        },
        handleSubmit,
        resetField
    } = useForm();
    const intl = useIntl();
    const referredSuccessPopup = useReferredSuccessDialog();
    const handleApiError = useHandleApiError();
    const { referralCodeValidator } = useValidator();

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState<string | null>(null);

    const referralRepository = new ReferralRepository();
    const handleError = (error: any) => {
        if (isDataNotFound(error)) {
            setApiErrorMessage(intl.formatMessage({ id: "add_referral_page_label_code_is_invalid" }));
            return ERROR_HANDLER_FLOW.STOP;
        }

        if (isReferrerValidateError(error)) {
            setApiErrorMessage(
                intl.formatMessage(
                    { id: "add_referral_page_warning_dialog_label_duplicated_referral" },
                    { username: error.response.data.data.referralCodeOwner }
                )
            );
            return ERROR_HANDLER_FLOW.STOP;
        }

        if (isCanNotUseSelfCode(error)) {
            setApiErrorMessage(intl.formatMessage({ id: "add_referral_page_warning_dialog_label_use_myself_code" }));
            return ERROR_HANDLER_FLOW.STOP;
        }

        return ERROR_HANDLER_FLOW.STOP;
    };

    const sendReferralCode = useFantasyCommand<string>(referralRepository.sendReferralCode, {
        onFail: () => ERROR_HANDLER_FLOW.STOP
    });
    const onSubmit = async (data: FieldValues) => {
        setIsSubmitted(true);

        try {
            await sendReferralCode.mutateAsync(data.referralCode);
            referredSuccessPopup(onCancel);
        } catch (e) {
            await handleApiError(e as ApiError, handleError);
        } finally {
            setIsSubmitted(false);
        }
    };


    const handleResetField = () => {
        resetField("referralCode");
        setApiErrorMessage(null);
    };

    return <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex gap-4 flex-column mb-3">
            <FantasyFormInput
                prefixIcon={<MdOutlineReduceCapacity size="2.2rem" />}
                title={intl.formatMessage({ id: "label_enter_referral_code" })}
                name="referralCode"
                register={register}
                getValues={getValues}
                validator={referralCodeValidator}
                errors={errors}
                error={apiErrorMessage}
                onResetField={handleResetField}
            />
            <Button
                variant={BUTTON_VARIANTS.primary}
                size={BUTTON_SIZE.lg}
                pattern={buttonPattern}
                className="w-100 mt-3"
                disabled={isSubmitted}
            >
                {buttonText}
            </Button>
        </div>
    </Form>;
};
