import { Callback } from "Common";
import React from 'react';
import { FormattedMessage } from "react-intl";
import { FantasyPopupProps, POPUP_SIZE } from "../../../components/popup/FantasyBasicPopup";
import { BUTTON_PATTERN, NEW_BUTTON_VARIANTS } from "../../../designToken/button/types";
import { allSpotsFilledImg } from "../../../helpers/IconHelper";

export interface SpotsFilledDialogProps {
    onClose: Callback;
}

export function makeContestSpotsFilledProps(props: SpotsFilledDialogProps): FantasyPopupProps {
    return {
        show: true,
        popupSize: POPUP_SIZE.MD,
        title: (
            <FormattedMessage id={"all_spots_filled"} />
        ),
        content: (
            <>
                <div className={"tw-m-auto tw-max-w-[16.2rem]"}>
                    <img src={allSpotsFilledImg} alt="" />
                </div>
                <p className={"tw-px-5 tw-text-subtitle-1 tw-whitespace-pre-line"}>
                    <FormattedMessage id={"spots_filled_content"} />
                </p>
            </>
        ),
        buttonSetting: {
            confirmButtonVariant: NEW_BUTTON_VARIANTS.primaryDark,
            buttonPattern: BUTTON_PATTERN.pill,
            confirmButtonText:
                <FormattedMessage id={"button_join_another"} />,
            onConfirm: props.onClose,
            cancelButton: false,
        },
        onHide: props.onClose
    };
}
