import { divideByOneHundred } from "helpers/number/NumberHelper";

type MyRefereeSummaryVoProps = {
    userId: number;
    nickname: string;
    avatar: string;
    rubyCent: number;
    registeredAt: number;
    referralAt: number;
};

export class MyRefereeSummaryVo {
    userId: number;
    nickname: string;
    avatar: string;
    rubyCent: number;
    registeredAt: number;
    referralAt: number;

    constructor(props: MyRefereeSummaryVoProps) {
        this.userId = props.userId;
        this.nickname = props.nickname;
        this.avatar = props.avatar;
        this.rubyCent = props.rubyCent;
        this.registeredAt = props.registeredAt;
        this.referralAt = props.referralAt;
    }

    get ruby(): number {
        return divideByOneHundred(this.rubyCent);
    }
}
