import React from "react";
import { Spinner } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "designToken/button/Button";
import { UserDeleteSubmitParagraph } from "components/userDelete/UserDeleteSubmitStyle";
import { useForm } from "react-hook-form";
import FantasyFormInput from "components/input/FantasyFormInput";
import useValidator from "hooks/useValidator";
import useFantasyHistory from "hooks/useFantasyHistory";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";

export interface SubmitEmailFormProps {
    isLoading: boolean
    onMutate: (values: any) => void
}

const SubmitEmailForm: React.FC<SubmitEmailFormProps> = ({
                                                             isLoading,
                                                             onMutate
                                                         }) => {
    const intl = useIntl();
    const {
        register,
        formState: { errors },
        handleSubmit,
        getValues
    } = useForm();

    const { emailValidator } = useValidator();

    const history = useFantasyHistory();

    return (
        <div>
            <UserDeleteSubmitParagraph>
                <FormattedMessage id="user_delete_page_description" />
            </UserDeleteSubmitParagraph>
            <div className="mb-5">
                <FantasyFormInput
                    title={intl.formatMessage({ id: "label_email" })}
                    name="email"
                    type="email"
                    validator={emailValidator}
                    register={register}
                    getValues={getValues}
                    errors={errors}
                />
            </div>
            <div className="d-flex gap-4 justify-content-end">
                <Button variant={BUTTON_VARIANTS.outlineDark}
                        dataTestId={"cancel-btn"}
                        size={BUTTON_SIZE.md}
                        pattern={BUTTON_PATTERN.pill}
                        onClick={history.goBack}
                >
                    <FormattedMessage
                        id="button_cancel" />
                </Button>
                <Button disabled={isLoading}
                        dataTestId={"confirm-btn"}
                        size={BUTTON_SIZE.md}
                        pattern={BUTTON_PATTERN.pill}
                        onClick={handleSubmit((data) => onMutate(data))}
                >
                    {
                        isLoading
                            ? (<Spinner animation="border" role="status" />)
                            : (<FormattedMessage id="button_confirm" />)
                    }
                </Button>
            </div>
        </div>
    );
};

export default SubmitEmailForm;
