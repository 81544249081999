import { CHECKBOX_SHAPE, FantasyCheckboxProps } from "components/checkbox/types";
import { Checkbox } from "components/ui/checkbox";
import { FONT } from "designToken/font";
import { cn } from "helpers/cn";
import { uniqueId } from "lodash";
import * as React from "react";

export const FantasyCheckbox: React.FC<FantasyCheckboxProps> = ({
    variant,
    shape = CHECKBOX_SHAPE.square,
    text,
    textFont = FONT.caption1,
    checked,
    disabled,
    onClick,
    onCheckedChange,
    children
}) => {
    const id = uniqueId();
    return (
        <div className="tw-flex tw-gap-2 tw-items-center">
            <Checkbox id={id}
                      className={cn(`tw-checkbox-${variant}`, `tw-checkbox-${shape}`)}
                      checked={checked}
                      disabled={disabled}
                      onClick={onClick}
                      onCheckedChange={onCheckedChange}
            />
            <label
                htmlFor={id}
                className={cn("tw-text-start", textFont, { "tw-text-gray-200": disabled })}
            >
                {children || text}
            </label>
        </div>
    );
};
