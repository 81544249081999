import { ApBadge } from "domain/ap/ActivityPointProgramStyle";
import {
    ActivityPointProgressWrapper,
    BadgeWrapper,
    CurrentAp,
    CurrentApWrapper,
    CurrentLevel,
    CurrentProgressBar,
    InfoWrapper,
    LevelAndApWrapper,
    LevelBadge,
    Text,
    TotalProgressBar
} from "domain/ap/ActivityPointProgressStyle";
import useCurrency from "hooks/useCurrency/useCurrency";
import React from "react";
import { FormattedMessage } from "react-intl";
import { MyApOverview } from "types/activityPoint/MyApOverview";
import { apLevelConfig, getCurrentApProgress, isBeginner, isUltimateMaster } from "./ActivityPointHelper";
import { BuyCoinStepper, BuyCoinStepperType } from "./BuyCoinStepper";

const apIcon = "/images/ap/ap_icon.png";

interface ActivityPointProgressProps {
    myApOverview: MyApOverview;
}

const ActivityPointProgress: React.FC<ActivityPointProgressProps> = ({ myApOverview }) => {
    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();
    const { currentLevel, currentAp, currentLevelMinAp, nextLevel, nextLevelMinAp } = myApOverview;

    return <ActivityPointProgressWrapper>
        {
            !isBeginner(currentLevel) && <BadgeWrapper>
                <LevelBadge src={apLevelConfig[currentLevel]?.image} />
            </BadgeWrapper>
        }

        <InfoWrapper $level={currentLevel}>
            <LevelAndApWrapper>
                <CurrentLevel $level={currentLevel}>{apLevelConfig[currentLevel]?.text()}</CurrentLevel>
                <CurrentApWrapper>
                    <CurrentAp $level={currentLevel}>{toFormattedNumber(currentAp)}</CurrentAp>
                    <ApBadge $bgImg={apIcon} />
                </CurrentApWrapper>
            </LevelAndApWrapper>

            {
                !isUltimateMaster(currentLevel) && !isBeginner(currentLevel) &&
                <>
                    <TotalProgressBar>
                        <CurrentProgressBar
                            $currentApProgress={getCurrentApProgress({
                                currentAp,
                                currentLevelMinAp,
                                nextLevelMinAp
                            })} />
                    </TotalProgressBar>
                    <Text>
                        <FormattedMessage
                            id="ap_overview_view_label_next_level"
                            values={{
                                ap: toFormattedNumber(nextLevelMinAp - currentAp),
                                apLevel: apLevelConfig[nextLevel]?.text()
                            }} />
                    </Text>
                </>
            }
            {
                isBeginner(currentLevel) &&
                <>
                    <BuyCoinStepper type={BuyCoinStepperType.ApOverviewStepper}></BuyCoinStepper>
                    <Text>
                        <FormattedMessage
                            id="ap_overview_view_label_beginner_next_level"
                            values={{
                                ap: toFormattedNumber(nextLevelMinAp - currentAp),
                                apLevel: apLevelConfig[nextLevel]?.text()
                            }} />
                    </Text>
                </>
            }
        </InfoWrapper>
    </ActivityPointProgressWrapper>;
};

export default ActivityPointProgress;
