import PlayerAvatar from "components/image/PlayerAvatar";
import { InjuryPlayingChance } from "domain/team/player/components/playerCard/InjuryPlayingChance";
import React from "react";
import { MdOutlineInfo } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { LINEUP, SquadPlayer } from "types/player/Player";
import { PlayerInfoWrapper, PlayerName, PlayerText, PlayerUrlWrapper, Starting, Substitute } from "./PlayerCardStyle";
import clsx from "clsx";

interface PlayerInfoProps {
    player: SquadPlayer;
    text: string;
    selectionRateTitle?: string;
}

export const PlayerLineup: React.FC<{ lineup: LINEUP, as?: string }> = ({ lineup, as }) => {
    if (lineup === LINEUP.STARTING_LINEUP) {
        return <Starting data-testid="player_lineup" as={as}>
            <FormattedMessage id="player_starting" />
        </Starting>;
    }

    if (lineup === LINEUP.SUBSTITUTE) {
        return <Substitute data-testid="player_lineup" as={as}>
            <FormattedMessage id="player_substitute" />
        </Substitute>;
    }

    return null;
};

const PlayerSelectionRate: React.FC<{ title: string, rate: number }> = ({
                                                                            title,
                                                                            rate,
                                                                        }) => {

    const formattedRate = rate === 0 || rate === 100
        ? `${rate.toFixed(0)}%`
        : `${rate.toFixed(2)}%`;

    return <div className={clsx("tw-text-caption-2 tw-text-grey-800",
        title.length === 0 ? "tw-w-[4rem]" : "tw-w-[6rem]")}>
        <span>{title}</span>
        <span>{formattedRate}</span>
    </div>;
};

const PlayerCardInfo: React.FC<PlayerInfoProps> = ({ player, text, selectionRateTitle = "" }) => {
    const { id, avatar, lineup, playerSelectionRatePercent, name } = player;

    return <>
        <PlayerUrlWrapper>
            <MdOutlineInfo size="1.6rem"
                           className="text-text-secondary-1 position-absolute top-0 start-0" />
            <PlayerAvatar id={id} url={avatar} />

        </PlayerUrlWrapper>

        <PlayerInfoWrapper>
            <div className="d-flex">
                <PlayerName>{name}</PlayerName>
                {player.isInjured && (
                    <InjuryPlayingChance player={player} />
                )}
            </div>
            <PlayerText>{text}</PlayerText>

            <div className="tw-flex tw-gap-2">
                <PlayerSelectionRate title={selectionRateTitle} rate={playerSelectionRatePercent} />
                <PlayerLineup lineup={lineup} />
            </div>
        </PlayerInfoWrapper>
    </>;

};

export default PlayerCardInfo;
