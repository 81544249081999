import { divideByOneHundred, parseNumberToIntegerAndDecimal } from "helpers/number/NumberHelper";
import PropTypes from "prop-types";
import React from "react";
import { AmountValue, SelectedAmount } from "./TotalSelectedAmountStyle";

const TotalSelectedAmount = ({ usdCent }) => {
    const { integer, decimal } = parseNumberToIntegerAndDecimal(divideByOneHundred(usdCent || 0));

    return <SelectedAmount>
        $ <AmountValue>{integer}</AmountValue>.{decimal}
    </SelectedAmount>;
};

export default TotalSelectedAmount;

TotalSelectedAmount.propTypes = {
    usdCent: PropTypes.number
};
