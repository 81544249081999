import { ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";
import { Icon } from "helpers/IconHelper";
import React from "react";
import { cn } from "helpers/cn";
import { FeaturedContestVo } from "data/vo/contest/FeaturedContestVo";

const EntryToIconMap = {
    [ENTRY_FEE_TYPE_FROM_API.DEPOSIT]: Icon.Coin,
    [ENTRY_FEE_TYPE_FROM_API.RUBY]: Icon.Ruby,
};

interface EntryFeeProps {
    featuredContest: FeaturedContestVo;
}

export const EntryFee: React.FC<EntryFeeProps> = ({ featuredContest }) => {
    const EntryIcon = EntryToIconMap[featuredContest.entryFeeType];

    return <div className={cn(
        "tw-w-[107px] tw-h-[107px] tw-rounded-full tw-bg-[#A31428]",
        "tw-absolute tw-top-[-53px] tw-left-[50%] tw-transform tw--translate-x-1/2",
        "tw-pt-[55px] tw-flex tw-flex-col tw-items-center",
        "tw-text-white"
    )}>
        <div className={cn(
            "tw-font-bold tw-text-[14px] tw-leading-[25.2px] tw-flex gap-1 tw-items-center"
        )}>
            <EntryIcon />
            {featuredContest.entryFee}
        </div>
        <div className={cn(
            "tw-font-normal tw-text-[12px] tw-leading-[24.48px] tw-mt-[-8px]"
        )}>
            Entry
        </div>
    </div>;

};
