import { Icon } from "../../helpers/IconHelper";
import React, { useRef, useState } from "react";
import { FantasyIconProps } from "../../helpers/FantasyIcon";
import { cn } from "../../helpers/cn";
import { Overlay, Tooltip } from "react-bootstrap";
import { useQuestContext } from "./QuestProvider";

const PrizeClaimedChip = ({ className, icon: IconComponent, value }: {
    className?: string,
    icon: React.FC<FantasyIconProps>,
    value: number
}) => {
    return <div
        className={cn("tw-rounded-full tw-flex tw-flex-row", className)}>
        <div
            className="tw-flex tw-flex-row tw-wa tw-bg-grey-100 tw-rounded-full tw-py-[0.2rem] tw-pl-[0.2rem] tw-pr-[0.8rem]">
            <div className="tw-bg-grey-50 tw-mr-[0.4rem] tw-rounded-full">
                <IconComponent className="tw-m-[0.3rem] tw-my-[0.3rem]" size="1.4rem" />
            </div>
            <div className="tw-my-auto">
                {value}
            </div>
        </div>
    </div>;
};

const QuestPrizeClaimed = () => {
    const context = useQuestContext();

    const [showTooltip, setShowTooltip] = useState(false);
    const target = useRef(null);

    return <div className="tw-pt-2 tw-px-4 tw-flex tw-flex-row tw-justify-end">
        <div className="tw-flex tw-flex-row" ref={target} onClick={() => setShowTooltip(true)}>
            <PrizeClaimedChip icon={Icon.QuestCoin}
                              value={context.taskPrizeClaimed?.coin ?? 0} />
            <PrizeClaimedChip className="tw-ml-2"
                              icon={Icon.PrizeRuby}
                              value={context.taskPrizeClaimed?.ruby ?? 0} />
            <Icon.Info className="tw-ml-1 tw-my-auto tw-text-grey-150"
                       size="1.8rem" />
        </div>
        <Overlay
            show={showTooltip}
            placement="bottom"
            target={target.current}>
            {
                ({
                     placement: _placement,
                     arrowProps: _arrowProps,
                     show: _show,
                     popper: _popper,
                     hasDoneInitialMeasure: _hasDoneInitialMeasure,
                     ...props
                 }) => (
                    <Tooltip className="tw-absolute">
                        <div className="fz-sf-regular-footnote">tooltip</div>
                    </Tooltip>
                )
            }
        </Overlay>
    </div>;
};

export default QuestPrizeClaimed;