import { useCallback } from "react";
import { signOut } from "firebase/auth";
import { auth } from "domain/auth/AuthConfig";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import useFantasyHistory from "hooks/useFantasyHistory";

const useSignOut = () => {
    const history = useFantasyHistory();

    return useCallback(async () => {
        await signOut(auth);
        history.replace(ROUTER_PATH_CONFIG.auth.index, {
            statusCode: 401
        });
    }, [history]);

};

export default useSignOut;
