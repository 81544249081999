import React from "react";
import { useFormContext } from "react-hook-form";
import { cn } from "helpers/cn";
import { ErrorMessage } from "@hookform/error-message";


interface TextAreaFieldProps extends React.ComponentPropsWithoutRef<"textarea"> {
    name: string;
}

export const TextAreaField: React.FC<TextAreaFieldProps> = ({ name, className, ...restProps }) => {
    const {
        register, formState: { errors }, setValue, watch
    } = useFormContext();

    const MIN_TEXTAREA_HEIGHT = 0;
    const textareaRef = React.useRef<HTMLTextAreaElement>(null);

    const modifyTextAreaHeight = () => {
        if (!textareaRef.current) {
            return;
        }
        textareaRef.current.style.height = "inherit";
        textareaRef.current.style.height = `${Math.max(textareaRef.current.scrollHeight, MIN_TEXTAREA_HEIGHT)}px`;
    };

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(name, event.target.value, { shouldValidate: true });
        modifyTextAreaHeight();
    };

    return <div>
        <textarea
            {...restProps}
            {...register(name)}
            onChange={handleChange}
            ref={textareaRef}
            className={cn(
                className,
                "tw-resize-none",
                { "tw-text-error-700": errors[name] }
            )}
            rows={1}
            value={watch(name)}
        />
        <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) =>
                <div className="tw-text-error-700 tw-text-caption-1 px-3 tw-w-full tw-text-left">
                    {message}
                </div>
            }
        />
    </div>;
};
