import { divideByOneHundred } from "helpers/number/NumberHelper";
import { PrizeListDto } from "../../dto/PrizeList/PrizeListDto";

export class PrizeListVo {
    public from: number;
    public to: number;
    public prize: number;

    constructor(dto: PrizeListDto) {
        this.from = dto.fromRank;
        this.to = dto.toRank;
        this.prize = divideByOneHundred(dto.prizeCent);
    }
}
