import React from "react";
import { cn } from "helpers/cn";

interface DemarcationProps {
    lineClassName?: string
    className?: string
    children: React.ReactNode;
}

export const Demarcation: React.FC<DemarcationProps> = ({
                                                            className,
                                                            lineClassName,
                                                            children
                                                        }) => {

    return <div
        className={cn(
            "tw-flex tw-items-center tw-py-2 tw-px-4 tw-gap-2",
            "tw-text-grey-800 tw-text-overline "
            , className)
        }>
        <div className={cn("tw-flex-1 tw-bg-grey-400 tw-h-[0.1rem]", lineClassName)} />
        <span className="tw-flex-shrink-0">
            {children}
        </span>
        <div className={cn("tw-flex-1 tw-bg-grey-400 tw-h-[0.1rem]", lineClassName)} />
    </div>;
};
