import React from "react";
import { Placeholder } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Button from "../../../designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, NEW_BUTTON_VARIANTS } from "../../../designToken/button/types";
import { Icon } from "../../../helpers/IconHelper";
import useFantasyHistory from "../../../hooks/useFantasyHistory";
import { CirclePlaceHolder, TextPlaceHolder } from "../SkeletonStyle";

const CustomTextPlaceHolder = ({
                                   width = 4.5,
                                   height = 1.2
                               }) => {
    return (
        <TextPlaceHolder $width={width} $height={height} $radius={0.8} $bg="tw-bg-grey-200" />
    );
};

const CustomCirclePlaceHolder = () => <CirclePlaceHolder $size={2.8} $bg="tw-bg-grey-200" />;

const CustomCardSkeleton = () => {
    return (
        <div>
            <div className="tw-bg-white tw-rounded-t-2xl tw-py-2 tw-pl-3 tw-pr-12 tw-flex tw-items-center tw-gap-2">
                <CustomTextPlaceHolder width={2} />
                <div className="tw-flex-1">
                    <div className="tw-mb-1">
                        <CustomCirclePlaceHolder />
                        <CustomCirclePlaceHolder />
                        <CustomCirclePlaceHolder />
                        <CustomCirclePlaceHolder />
                        <CustomCirclePlaceHolder />
                    </div>
                    <div>
                        <CustomCirclePlaceHolder />
                        <CustomCirclePlaceHolder />
                        <CustomCirclePlaceHolder />
                        <CustomCirclePlaceHolder />
                    </div>
                </div>
                <CustomTextPlaceHolder width={7.2} />
            </div>
            <div className="tw-py-1 tw-bg-black tw-bg-opacity-[0.04] tw-rounded-b-2xl tw-flex justify-content-center">
                <CustomTextPlaceHolder width={2} />
            </div>
        </div>
    );
};

const MyNineCatDetailSkeleton = () => {
    const history = useFantasyHistory();

    return <Placeholder animation="glow" data-testid="my-nine-cat-detail-loading">
        <div className="tw-bg-grey-50">
            <div className="tw-bg-primary-900 tw-pt-8 tw-pb-2 tw-px-4">
                <div className="tw-flex tw-h-8 tw-items-center tw-mb-2">
                    <div className="tw-relative tw-text-white">
                        <button
                            className="tw-cursor-pointer tw-absolute tw-top-[50%] tw-translate-y-[-50%]"
                            onClick={history.goBack}
                            data-testid="leave-slate-button"
                        >
                            <Icon.ArrowLeft className="tw-text-inherit" size="2.4rem" />
                        </button>
                    </div>
                    <div className="tw-flex tw-items-center tw-justify-center tw-w-full">
                        <CustomTextPlaceHolder width={7.2} height={2.4} />
                        <div className="tw-px-2">
                            <div className="tw-w-[1rem] tw-h-[2.4rem] tw-bg-primary-600 tw-rounded-full" />
                        </div>
                        <CustomTextPlaceHolder width={7.2} height={2.4} />
                    </div>
                </div>

                <div className="tw-text-center tw-py-2">
                    <CustomTextPlaceHolder width={3.6} />
                </div>
            </div>
            <div className="tw-px-4 tw-flex tw-gap-4 tw-flex-col -tw-mt-2">
                <div
                    className="tw-bg-grey-100 mx-auto tw-px-4 tw-py-3 tw-rounded-2xl tw-flex tw-gap-2.5 tw-items-center">
                    <div className="tw-space-y-3">
                        <CustomTextPlaceHolder />
                        <br />
                        <CustomTextPlaceHolder />
                    </div>
                    <span className="tw-w-[1px] tw-h-[3rem] tw-bg-grey-550"></span>
                    <div className="tw-space-y-3">
                        <CustomTextPlaceHolder />
                        <br />
                        <CustomTextPlaceHolder />
                    </div>
                    <span className="tw-w-[1px] tw-h-[3rem] tw-bg-grey-550"></span>
                    <div className="tw-space-y-3">
                        <CustomTextPlaceHolder />
                        <br />
                        <CustomTextPlaceHolder />
                    </div>
                    <span className="tw-w-[1px] tw-h-[3rem] tw-bg-grey-550"></span>
                    <div className="tw-space-y-3">
                        <CustomTextPlaceHolder />
                        <br />
                        <CustomTextPlaceHolder />
                    </div>
                </div>
                <div className="tw-space-y-2">
                    <div className="tw-text-grey-1000 tw-text-subtitle-1 tw-font-bold">
                        <FormattedMessage id="contest_detail_page_leaderboard_label_your_teams" />
                    </div>
                    <CustomCardSkeleton />
                    <CustomCardSkeleton />
                </div>
                <Button
                    prefixIcon={Icon.ChampionIcon}
                    className="tw-w-full"
                    variant={NEW_BUTTON_VARIANTS.primaryDark}
                    size={BUTTON_SIZE.md}
                    pattern={BUTTON_PATTERN.pill}
                    disabled
                >
                    <FormattedMessage id="my_contest_page_button_check_leaderboard" />
                </Button>
            </div>
        </div>

    </Placeholder>;
};

export default MyNineCatDetailSkeleton;
