import React, { useCallback } from "react";
import Portal from "../portal/Portal";
import {
    CloseButton,
    DownloadButton,
    DownloadModal,
    DownloadModalBody,
    DownloadModalOverlay,
    DownloadWrapper,
    Qrcode,
    Text,
} from "./AppDownloadModalStyle";
import { useMe } from "../../domain/me/me/MeProvider";
import useFantasyConfig from "../../hooks/useFantasyConfig";

const androidAppStoreQrCode = "/images/android_app_store_qr_code.png";
const androidAppImg = "/images/android_app.png";
const closeImg = "/images/close_icon_primary.png";

const AppDownloadModal = () => {
    const { openDownloadModal, setOpenDownloadModal } = useMe();
    const { appDownloadLink } = useFantasyConfig();

    const handleClose = useCallback(() => {
        setOpenDownloadModal(false);
    }, [setOpenDownloadModal]);

    return openDownloadModal ? (
        <Portal>
            <DownloadWrapper onClick={handleClose}>
                <DownloadModalOverlay />
                <DownloadModal onClick={(e) => e.stopPropagation()}>
                    <DownloadModalBody>
                        <CloseButton onClick={handleClose} closeImg={closeImg} />
                        <Qrcode qrcodeImg={androidAppStoreQrCode} />
                        <a href={appDownloadLink.googlePlay}>
                            <DownloadButton androidAppImg={androidAppImg} />
                        </a>
                        <Text>Download our app and enjoy all the features!</Text>
                    </DownloadModalBody>
                </DownloadModal>
            </DownloadWrapper>
        </Portal>
    ) : null;
};

export default AppDownloadModal;
