import { TextPlaceHolder } from "../SkeletonStyle";
import { MatchInfoWrapper, SquadCode, VSText } from "../../container/navbar/NavbarStyle";
import React from "react";
import { Placeholder } from "react-bootstrap";

const NavbarMatchInfoSkeleton = () => {

    return <Placeholder animation="glow">
        <MatchInfoWrapper>
            <SquadCode>
                <TextPlaceHolder $width={3} />
            </SquadCode>
            <VSText>VS</VSText>
            <SquadCode>
                <TextPlaceHolder $width={3} />
            </SquadCode>
        </MatchInfoWrapper>
    </Placeholder>;
};

export default NavbarMatchInfoSkeleton;
