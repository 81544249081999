import { omit } from "lodash";
import React, { useState } from "react";
import { Control } from "react-hook-form";
import { FormControl, FormField, FormItem } from "components/ui/form";
import { Textarea } from "components/ui/textarea";
import { FormErrorMessage } from "domain/kyc/pages/kycVerificationForm/components/FormErrorMessage";
import { cn } from "helpers/cn";

export enum TEXT_AREA_VARIANT {
    WHITE = "white",
    GRAY = "gray",
}

export interface ShadCNTextAreaProps {
    control?: Control<any>;
    autoResizeRow?: boolean;
    name: string;
    inputProps?: Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, "name">;
    showErrorMessage?: boolean;
    showErrorIcon?: boolean;
    maxLength?: number;
    variant?: TEXT_AREA_VARIANT;
    onFocus?: () => void;
}

const variantClassNameMap = {
    [TEXT_AREA_VARIANT.WHITE]: {
        borderColor: "tw-border-transparent",
        backgroundColor: "tw-bg-background",
    },
    [TEXT_AREA_VARIANT.GRAY]: {
        borderColor: "tw-border-transparent focus:tw-border-info-700",
        backgroundColor: "tw-bg-grey-50",
    },
};

export const FantasyTextArea: React.FC<ShadCNTextAreaProps> = ({
                                                                   control,
                                                                   name,
                                                                   autoResizeRow = true,
                                                                   inputProps,
                                                                   showErrorMessage = true,
                                                                   showErrorIcon = true,
                                                                   maxLength,
                                                                   variant = TEXT_AREA_VARIANT.WHITE,
                                                                   onFocus,
                                                               }) => {
    const [isFocused, setIsFocused] = useState(false);
    const { borderColor, backgroundColor } = variantClassNameMap[variant];

    return (
        <FormField
            control={control}
            name={name}
            render={({ field, fieldState }) => {
                const rows = field.value.split("\n").length;
                return (
                    <FormItem>
                        <FormControl>
                            <div className={"tw-relative"}>
                                <FormItem>
                                    <FormControl>
                                        <Textarea
                                            {...field}
                                            onFocus={() => {
                                                setIsFocused(true);
                                                if (onFocus) onFocus();
                                            }}
                                            onBlur={() => {
                                                setIsFocused(false);
                                                field.onBlur();
                                            }}
                                            className={cn(
                                                "tw-w-full tw-rounded-[0.8rem] tw-text-body-1",
                                                "tw-px-[1.2rem] tw-py-[0.8rem]",
                                                "focus-visible:tw-outline-0 focus-visible:tw-ring-offset-0 focus-visible:tw-ring-transparent focus-visible:tw-ring-offset-transparent",
                                                "tw-border tw-min-h-[4.8rem]",
                                                backgroundColor,
                                                fieldState.error ? "tw-border-error-700" : borderColor,
                                                autoResizeRow ? "tw-resize-none" : "",
                                                inputProps?.className ?? "",
                                            )}
                                            {...omit(inputProps, "className")}
                                            rows={autoResizeRow ? rows : undefined}
                                        />
                                    </FormControl>
                                </FormItem>
                            </div>
                        </FormControl>
                        {showErrorMessage &&
                            <FormErrorMessage showIcon={showErrorIcon} />
                        }
                        {
                            maxLength && isFocused && !fieldState.error &&
                            <div className="tw-text-caption-1 tw-text-start tw-text-info-700 tw-pt-1">
                                {field.value.length}/{maxLength}
                            </div>
                        }
                    </FormItem>
                );
            }} />
    );
};
