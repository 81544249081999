import { useEffect, useRef } from "react";

export const useClaimAnimation = () => {
    const rewardRef = useRef<HTMLDivElement>(null);

    const handleAnimationEnd = () => {
        if (rewardRef.current) {
            rewardRef.current.classList.remove("animate__animated", "animate__fadeOutUp", "d-flex");
            rewardRef.current.classList.add("d-none");
        }
    };

    const handleAnimationStart = () => {
        if (rewardRef.current) {
            rewardRef.current.classList.remove("d-none");
            rewardRef.current.classList.add("animate__animated", "animate__fadeOutUp", "d-flex");
        }
    };

    useEffect(() => {
        const element = rewardRef.current;
        if (element) {
            element.addEventListener("animationend", handleAnimationEnd);
        }

        return () => {
            if (element) {
                element.removeEventListener("animationend", handleAnimationEnd);
            }
        };
    }, []);

    return {
        rewardRef,
        handleAnimationStart
    };
};
