import {
    depositBuyCoinWithRubiesImg,
    depositFirstPurchaseAndBuyCoinWithRubiesImg,
    upgradeToSteelImg
} from "helpers/IconHelper";
import { FormattedMessage } from "react-intl";
import React from "react";
import { useLocaleProvider } from "LocaleProvider";
import SYSTEM_LINKS from "helpers/LinkHelper";
import useFirstTimePurchase from "hooks/useFirstTimePurchase";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import { fetchMyApOverview } from "apis/ap/ActivityPointApi";
import { AP_LEVEL } from "types/activityPoint/ApLevel";

const MoreTextLink = () => {
    const { locale } = useLocaleProvider();

    return <a
        className="tw-text-primary-500"
        href={SYSTEM_LINKS.firstTimePurchase(locale)}
        target="_blank"
        rel="noreferrer"
    >
        <FormattedMessage id="chat_room_page_label_system_announcement_more" />
    </a>;
};
const FirstDepositWithExtraBonus = ({ rubyPercentage, minCoin }) => {

    return (
        <div className="tw-py-4 tw-px-10 tw-flex tw-gap-1 tw-flex-col tw-items-center"
             data-testid="first-buy-coin-with-extra-bonus-message">
            <img
                src={depositFirstPurchaseAndBuyCoinWithRubiesImg}
                alt="buy_coin_with_rubies_bonus_and_first_buy_coin"
                className="tw-h-14"
            />
            <div
                className="tw-bg-gray-50 tw-text-grey-1000 tw-text-caption-2 tw-font-bold tw-px-2 tw-py-0.5 tw-inline-block">
                <FormattedMessage id="add_coin_page_first_purchase_title" />
            </div>
            <div className="tw-text-grey-1000 tw-text-h6 tw-font-bold">
                <FormattedMessage id="add_coin_page_first_purchase_hint" values={{ rubyPercentage }} />
            </div>
            <div className="tw-w-[26rem] tw-inline-block tw-text-center tw-text-caption-1 tw-text-grey-550">
                <FormattedMessage id="add_coin_page_first_purchase_description" values={{ rubyPercentage, minCoin }} />
                <MoreTextLink />
            </div>
        </div>
    );
};
const FirstDepositInfo = ({ rubyPercentage, minCoin }) => {
    return (
        <div className="tw-py-4 tw-px-10 tw-flex tw-gap-1 tw-flex-col tw-items-center"
             data-testid="first-deposit-message">
            <img
                src={depositBuyCoinWithRubiesImg}
                alt="buy_coin_with_rubies_bonus"
                className="tw-h-14"
            />
            <div
                className="tw-bg-gray-50 tw-text-grey-1000 tw-text-caption-2 tw-font-bold tw-px-2 tw-py-0.5 tw-inline-block">
                <FormattedMessage id="add_coin_page_extra_bonus_title" />
            </div>
            <div className="tw-text-grey-1000 tw-text-h6 tw-font-bold">
                <FormattedMessage id="add_coin_page_extra_bonus_hint" values={{ rubyPercentage }} />
            </div>
            <div className="tw-w-[26rem] tw-inline-block tw-text-center tw-text-caption-1 tw-text-grey-550">
                <FormattedMessage id="add_coin_page_extra_bonus_description" values={{ rubyPercentage, minCoin }} />
                <MoreTextLink />
            </div>
        </div>
    );
};
const UpgradeToSteel = () => {
    return (
        <div className="tw-py-4 tw-px-10 tw-flex tw-gap-1 tw-flex-col tw-items-center" data-testid="upgrade-to-steel">
            <img
                src={upgradeToSteelImg}
                alt="buy_coin_with_upgrade_to_steel"
                className="tw-h-14"
            />
            <div
                className="tw-bg-gray-50 tw-text-grey-1000 tw-text-caption-2 tw-font-bold tw-px-2 tw-py-0.5 tw-inline-block">
                <FormattedMessage id="add_coin_page_upgrade_to_steel_title" />
            </div>
            <div className="tw-text-grey-1000 tw-text-h6 tw-font-bold">
                <FormattedMessage id="add_coin_page_upgrade_to_steel_hint" />
            </div>
            <div className="tw-w-[26rem] tw-inline-block tw-text-center tw-text-caption-1 tw-text-grey-550">
                <FormattedMessage id="add_coin_page_upgrade_to_steel_description" />
            </div>
        </div>
    );
};
export const Promo: React.FC = () => {
    const firstTimePurchase = useFirstTimePurchase();
    const apOverview = useFantasyQuery([QUERY_KEY.MY_AP_OVERVIEW], fetchMyApOverview);

    if (apOverview.data?.currentLevel === AP_LEVEL.BEGINNER && firstTimePurchase.eligible) {
        return (
            <FirstDepositWithExtraBonus
                rubyPercentage={firstTimePurchase.rubyPercentage}
                minCoin={firstTimePurchase.minCoin}
            />
        );
    }
    if (firstTimePurchase.eligible) {
        return (
            <FirstDepositInfo
                rubyPercentage={firstTimePurchase.rubyPercentage}
                minCoin={firstTimePurchase.minCoin}
            />
        );
    }

    if (apOverview.data?.currentLevel === AP_LEVEL.BEGINNER) {
        return <UpgradeToSteel />;
    }

    return null;
};
