import _ from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ListItem, ListTitle, ListValue } from "../components/TransactionDetailStyle";
import { TransactionDetailVo } from "data/vo/wallet/TransactionDetailVo";

interface ContestDetailsProps extends Pick<TransactionDetailVo, "sport" | "matchName" | "contestName"> {

}

const WinningContestDetails: React.FC<ContestDetailsProps> = ({ sport, matchName, contestName }) => {
    return <>
        <ListItem>
            <ListTitle><FormattedMessage id="label_sport" /></ListTitle>
            <ListValue>{sport ? _.capitalize(sport) : "-"}</ListValue>
        </ListItem>
        <ListItem>
            <ListTitle><FormattedMessage id="label_match" /></ListTitle>
            <ListValue>{matchName || "-"}</ListValue>
        </ListItem>
        <ListItem>
            <ListTitle><FormattedMessage id="label_contest_name" /></ListTitle>
            <ListValue>{contestName || "-"}</ListValue>
        </ListItem>
    </>;
};

export default WinningContestDetails;
