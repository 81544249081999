import React from "react";
import RubyContestChampionDialog from "./RubyContestChampionDialog";
import B2CPopupWrapper from "../../../popup/popupWrappers/B2CPopupWrapper";
import MainPagePopupWrapper from "../../../popup/popupWrappers/MainPagePopupWrapper";

const B2CRubyContestChampionDialog = () => (
    <B2CPopupWrapper>
        <MainPagePopupWrapper>
            <RubyContestChampionDialog />
        </MainPagePopupWrapper>
    </B2CPopupWrapper>
);

export default B2CRubyContestChampionDialog;