import { Icon } from "helpers/IconHelper";
import React from "react";
import { CURRENCY_ICON_SUIT_NAME } from "store/reducer/config/FantasyGameConfigState";
import { ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";
import { CurrencyIconSuit } from "./AmountDisplay";
import { WALLET_TYPE } from "data/dto/wallet/walletHistoryDto";

type CustomizeCurrencyIconSuit = Record<CURRENCY_ICON_SUIT_NAME, Record<ENTRY_FEE_TYPE_FROM_API, React.ReactNode>>;
export const getCurrencyIconSuitByEntryFeeType = (type: ENTRY_FEE_TYPE_FROM_API, iconSize: string = "1.3rem"): CurrencyIconSuit => {
    const iconSuit = {
        [CURRENCY_ICON_SUIT_NAME.DEFAULT]: {
            [ENTRY_FEE_TYPE_FROM_API.DEPOSIT]: null,
            [ENTRY_FEE_TYPE_FROM_API.RUBY]: null,
        },
        [CURRENCY_ICON_SUIT_NAME.PESO]: {
            [ENTRY_FEE_TYPE_FROM_API.DEPOSIT]: <Icon.Peso size={iconSize} />,
            [ENTRY_FEE_TYPE_FROM_API.RUBY]: <Icon.DailyRuby size={iconSize} />,
        },
    };

    return {
        [CURRENCY_ICON_SUIT_NAME.DEFAULT]: iconSuit[CURRENCY_ICON_SUIT_NAME.DEFAULT][type],
        [CURRENCY_ICON_SUIT_NAME.PESO]: iconSuit[CURRENCY_ICON_SUIT_NAME.PESO][type],
    };
};

export const getCurrencyIconSuitByWalletType = (type: WALLET_TYPE, iconSize: string = "1.3rem"): CurrencyIconSuit => {
    const iconSuit = {
        [CURRENCY_ICON_SUIT_NAME.DEFAULT]: {
            [WALLET_TYPE.COIN]: null,
            [WALLET_TYPE.RUBY]: null,
            [WALLET_TYPE.WINNING]: null,
        },
        [CURRENCY_ICON_SUIT_NAME.PESO]: {
            [WALLET_TYPE.COIN]: <Icon.Peso size={iconSize} />,
            [WALLET_TYPE.RUBY]: <Icon.DailyRuby size={iconSize} />,
            [WALLET_TYPE.WINNING]: <Icon.Peso size={iconSize} />,
        }
    };

    return {
        [CURRENCY_ICON_SUIT_NAME.DEFAULT]: iconSuit[CURRENCY_ICON_SUIT_NAME.DEFAULT][type],
        [CURRENCY_ICON_SUIT_NAME.PESO]: iconSuit[CURRENCY_ICON_SUIT_NAME.PESO][type],
    };
};

export const getCustomIconSuit = (type: ENTRY_FEE_TYPE_FROM_API, customizeCurrencyIcons: CustomizeCurrencyIconSuit): CurrencyIconSuit => {
    return {
        [CURRENCY_ICON_SUIT_NAME.DEFAULT]: customizeCurrencyIcons[CURRENCY_ICON_SUIT_NAME.DEFAULT][type],
        [CURRENCY_ICON_SUIT_NAME.PESO]: customizeCurrencyIcons[CURRENCY_ICON_SUIT_NAME.PESO][type],
    };
};
