import clsx from "clsx";
import { ListGroup } from "react-bootstrap";
import styled from "styled-components";


export const ListItem = styled(ListGroup.Item).attrs(() => ({
    className: "d-flex align-items-center px-0 pb-2 border-0 tw-border-system-labelLightQuaternary"
}))`
    padding: 0 .8rem .8rem;
    border-bottom-width: 1px;
`;

export const DepositWrapper = styled(ListItem).attrs(() => ({
    className: ""
}))``;

export const RubyWrapper = styled(ListItem).attrs(() => ({
    className: ""
}))``;

export const CouponWrapper = styled(ListItem).attrs(() => ({}))`
    border-bottom: 0;
`;

export const CouponButtonWrapper = styled.div.attrs(() => ({
    className: "ms-auto d-flex justify-content-between clickable gap-2"
}))``;

export const EntryFeeWrapper = styled(ListItem).attrs(() => ({
    className: ""
}))``;

export const TotalWrapper = styled(ListItem).attrs(() => ({
    className: ""
}))`
`;
export const BalanceWrapper = styled.div.attrs(() => ({
    className: "pt-3 pb-3 px-3 d-flex flex-column align-items-start gap-2 tw-bg-system-neutral25"
}))`
`;

export const BalanceTitle = styled.div.attrs(() => ({
    className: "fz-sf-bold-body-2 text-light-secondary"
}))``;

export const BalanceItemWrapper = styled.div.attrs(() => ({
    className: "d-flex align-items-center justify-content-between w-100"
}))``;

export const BalanceItemLabel = styled.div.attrs(() => ({
    className: "fz-sf-regular-body-2 tw-text-system-labelLightSecondary"
}))`
`;

export const BalanceItemValue = styled.div.attrs<{
    $isBalancePositive: boolean
}>(({ $isBalancePositive }) => ({
    className: clsx($isBalancePositive ? "text-light-primary" : "text-sys-danger-1", "fz-sf-bold-body-1")
}))``;

export const NotedWrapper = styled.div.attrs(() => ({
    className: "align-items-center px-0 border-0 fz-sf-regular-body-2 text-light-secondary pt-3 gap-2"
}))``;

export const NoteTooltipText = styled.span.attrs(() => ({
    className: "fz-sf-regular-body-2 text-sys-info-1"
}))`

    padding: 0 0.5rem;
`;

export const NoteTooltipWrapper = styled.div.attrs(() => ({
    className: ""
}))`
    text-align: left;
`;

export const ListTitle = styled.div.attrs(() => ({
    className: "fz-sf-regular-body-2 d-flex gap-1 tw-text-system-labelLightSecondary"
}))`
`;
export const ListValue = styled.div.attrs(() => ({
    className: "ms-auto fz-sf-regular-body-2"
}))`
`;

export const DepositValue = styled(ListValue).attrs(() => ({
    className: "text-light-primary"
}))``;

export const RubyValue = styled(ListValue).attrs(() => ({
    className: "text-light-primary"
}))``;

export const FullRubyValue = styled(ListValue).attrs<{
    $isUseRuby: boolean
}>(({ $isUseRuby }) => ({
    className: clsx(
        $isUseRuby ? "text-primary-2" : "text-light-quaternary"
        , "d-flex gap-2 clickable"
    )
}))``;

export const EntryFeeValue = styled(ListValue).attrs(() => ({
    className: "text-light-primary"
}))``;

export const EntryFeeTotalValue = styled.span.attrs(() => ({
    className: "fz-sf-bold-body-1"
}))``;
export const TotalValue = styled.div.attrs(() => ({
    className: "fz-sf-bold-title-3 ms-auto text-primary-1"
}))``;

export const Dot = styled.span.attrs({
    className: "rounded-circle position-absolute bg-sys-danger top-0 end-0"
})`
    width: .5rem;
    height: .5rem;
`;

export const CouponDiscountWrapper = styled.span.attrs<{
    $isDisabled: boolean
}>(({ $isDisabled }) => ({
    className: clsx("fz-sf-regular-body-1", $isDisabled ? "text-light-quaternary" : "text-light-primary")
}))``;

export const IconWrapper = styled.div.attrs<{
    $isDisabled: boolean
}>(({ $isDisabled }) => ({
    className: clsx("position-relative", $isDisabled ? "text-light-quaternary" : "text-primary-2")
}))`
    width: 2rem;
`;
export const DashedDivider = styled.div.attrs({
    className: "border-light-primary border-top-dashed mt-1 pb-3"
})``;


