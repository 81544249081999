export enum REWARD_TYPE {
    VOUCHER = "VOUCHER",
    REDEPOSIT = "REDEPOSIT"
}

export enum REWARD_SENDING_TYPE {
    AUTO = "AUTO",
    MANUAL = "MANUAL"
}

export enum REWARD_ORDER_STATUS {
    PENDING = "PENDING",
    COMPLETED = "COMPLETED",
    CANCELLED = "CANCELLED"
}


export interface RewardVO {
    id: number,
    title: string,
    priceCent: number,
    type: REWARD_TYPE
    country: string,
    previewUrl: string,
    quantity: number,
}
