import Navbar from "components/container/navbar/Navbar";
import { TOAST_TYPE } from "designToken/alert/components/AlertHelper";
import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";
import ReferralCounterBar from "domain/me/referral/components/ReferralCounterBar";
import {
    ReferralBody,
    ReferralBodyContainer,
    ReferralButtonWrapper,
    ReferralCountItemLink,
    ReferralDescriptionItemImgWrapper,
    ReferralHead,
    ReferralHeadInfoWrapper,
    ReferralImgWrapper
} from "domain/me/referral/components/ReferralStyle";
import { useReferralProvider } from "domain/me/referral/ReferralProvider";
import { referralGiftImg, referralInviteImg, referralRewardImg, } from "helpers/IconHelper";
import SYSTEM_LINKS from "helpers/LinkHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import useFantasyConfig from "hooks/useFantasyConfig";
import { useLocaleProvider } from "LocaleProvider";
import React, { useMemo } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { MdChevronRight, MdContentCopy, MdInfoOutline } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { useSelector } from "store";

import { addToast } from "store/actions/AlertActions";

const ReferralDescriptions = () => {
    const intl = useIntl();

    const { returnToReferrerPercentage, maxRubyPerRefereeText } = useReferralProvider();

    const { title } = useFantasyConfig();

    const descriptions = [
        {
            img: referralInviteImg,
            alt: "referral-invite-img",
            title: intl.formatMessage({ id: "referral_program_page_label_invite_friend" }),
            content: intl.formatMessage({ id: "referral_program_page_label_invite_friend_description" }, {
                appName: title,
            })
        },
        {
            img: referralGiftImg,
            alt: "referral-gift-img",
            title: intl.formatMessage({ id: "referral_program_page_label_collect_ruby" },
                { returnToReferrerPercentage: returnToReferrerPercentage }),
            content: intl.formatMessage({ id: "referral_program_page_label_collect_ruby_description" }, {
                returnToReferrerPercentage: returnToReferrerPercentage,
                maxRuby: maxRubyPerRefereeText
            })
        },
    ];


    return <div className="pt-5 d-flex flex-column gap-3">
        {
            descriptions.map((description) => {
                return <div className="d-flex gap-2" key={description.alt}>
                    <ReferralDescriptionItemImgWrapper>
                        <img src={description.img} alt={description.alt} className="img-fluid " />
                    </ReferralDescriptionItemImgWrapper>
                    <div>
                        <div className="fz-sf-bold-body-1 text-light-primary mb-2">
                            {description.title}
                        </div>
                        <div className="fz-sf-regular-footnote text-light-secondary">{description.content}</div>
                    </div>
                </div>;
            })
        }
    </div>;
};

const MaxRubyPerReferee = () => {
    const { maxRubyPerRefereeText } = useReferralProvider();

    return <span className="fz-sf-bold-title-1 text-sys-danger-2">{maxRubyPerRefereeText}</span>;
};

export const Referral: React.FC = () => {
    const intl = useIntl();
    const { locale } = useLocaleProvider();
    const { title, appDownloadLink, gameUrl, featureConfig: { hasApp } } = useFantasyConfig();

    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();
    const { refereeRuby } = useSelector(state => state.SettingsState.referrer);


    const dispatch = useDispatch();
    const { myReferralCode, myRefereeSummaries } = useReferralProvider();


    const handleCopy = () => {
        dispatch(
            addToast({
                type: TOAST_TYPE.SUCCESS,
                content: intl.formatMessage({
                    id: "copy_referral_code_success_toast_label",
                }),
            }),
        );
    };

    const rubiesText = toFormattedNumber(refereeRuby);
    const copiedText = useMemo(() => {
        // NOTE: 為來可以從後端給 appDownloadLink, webSiteUrl，前端判斷有幾個url來決定顯示
        const textTitle = intl.formatMessage(
            { id: "invite_friend_page_copy_message_content_1" },
            {
                rubies: rubiesText,
                appName: title
            },
        );
        const text1 = (() => {
            //TODO jay use hasApp(configs.js)
            const key = hasApp
                ? "invite_friend_page_copy_message_content_2"
                : "invite_friend_page_copy_message_content_2_daily";

            const url = hasApp
                ? `\n Android: ${appDownloadLink.googlePlay}\n iOS: ${appDownloadLink.appStore}`
                : gameUrl;

            return intl.formatMessage(
                {
                    id: key
                },
                {
                    url: url,
                    appName: title,
                },
            );
        })();
        const text2 = intl.formatMessage(
            { id: "invite_friend_page_copy_message_content_3" },
            { code: myReferralCode },
        );
        const text3 = intl.formatMessage({
            id: "invite_friend_page_copy_message_content_4",
        }, {
            rubies: rubiesText,
        });
        const text4 = intl.formatMessage({
            id: "invite_friend_page_copy_message_content_5",
        });

        return ` ${textTitle}\n\n ${text1}\n ${text2}\n ${text3}\n\n ${text4} `;
    }, [appDownloadLink, intl, myReferralCode, title]);

    return (
        <>
            <ReferralHead>
                <Navbar
                    isHeaderLine={false}
                    navbarCenter={
                        <div className={"text-center fz-sf-bold-title-3 text-text-primary-2"}>
                            <FormattedMessage id="label_referral_program" />
                        </div>
                    }
                    endIcon={
                        <a href={SYSTEM_LINKS.referAFriend(locale)} target="_blank" rel="noreferrer">
                            <MdInfoOutline size="2.4rem" className="text-text-primary-2" />
                        </a>
                    }
                    navBackIconColor="text-text-primary-2"
                    isTransparent={true}
                    shouldCalculatePaddingTop={false}
                />
                <ReferralImgWrapper>
                    <img src={referralRewardImg} alt="referral-program-img"
                         className="img-fluid" />
                </ReferralImgWrapper>
                <ReferralHeadInfoWrapper className="fz-sf-bold-title-3 text-text-primary-2 text-center">
                    <FormattedMessage id="referral_program_page_label_description" values={{
                        maxRuby: <MaxRubyPerReferee />
                    }} />
                </ReferralHeadInfoWrapper>
                <ReferralHeadInfoWrapper
                    className="p-3 d-flex justify-content-between border border-2 border-style-dashed border-light-secondary">
                    <div className="fz-sf-regular-title-3 text-text-primary-2">
                        {myReferralCode}
                    </div>
                    <CopyToClipboard text={myReferralCode} onCopy={handleCopy}>
                        <MdContentCopy
                            size="2.4rem"
                            className="text-text-primary-2 clickable"
                        />
                    </CopyToClipboard>
                </ReferralHeadInfoWrapper>
            </ReferralHead>

            <ReferralBody>
                <ReferralBodyContainer>
                    {
                        myRefereeSummaries.length > 0
                            ? <ReferralCountItemLink to={ROUTER_PATH_CONFIG.me.referral.bonus}>
                                <ReferralCounterBar />
                                <MdChevronRight
                                    className="position-absolute end-0 top-50 translate-middle-y text-primary-2"
                                    size="1.6rem" />
                            </ReferralCountItemLink>
                            : <ReferralCounterBar />
                    }
                    <ReferralDescriptions />
                </ReferralBodyContainer>
                <ReferralButtonWrapper>
                    <CopyToClipboard text={copiedText} onCopy={handleCopy}>
                        <Button size={BUTTON_SIZE.lg}
                                className="w-100"
                                variant={BUTTON_VARIANTS.outlinePrimary}
                                pattern={BUTTON_PATTERN.pill}>
                            <FormattedMessage id="invite_friend_page_button_copy_link" />
                        </Button>
                    </CopyToClipboard>
                </ReferralButtonWrapper>
            </ReferralBody>
        </>
    );
};

