import { divideByOneHundred, isNegative } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import _ from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ListItem, ListTitle, ListValue } from "../components/TransactionDetailStyle";
import { TransactionDetailVo } from "data/vo/wallet/TransactionDetailVo";
import { cn } from "helpers/cn";

interface RubyContestMoreDetailProps {
    transactionData: TransactionDetailVo;
}

const RubyContestMoreDetail: React.FC<RubyContestMoreDetailProps> = ({ transactionData }) => {
    const {
        sport,
        matchName,
        contestName,
        entryFeeCent,
    } = transactionData;
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();

    return (
        <>
            <ListItem>
                <ListTitle><FormattedMessage id="label_sport" /></ListTitle>
                <ListValue>{sport ? _.capitalize(sport) : "-"}</ListValue>
            </ListItem>
            <ListItem>
                <ListTitle><FormattedMessage id="label_match" /></ListTitle>
                <ListValue>{matchName || "-"}</ListValue>
            </ListItem>
            <ListItem>
                <ListTitle><FormattedMessage id="label_contest_name" /></ListTitle>
                <ListValue>{contestName || "-"}</ListValue>
            </ListItem>
            <ListItem>
                <ListTitle><FormattedMessage id="fantasy_sport_label_entry_fee" /></ListTitle>
                <div className={cn(
                    "fz-sf-bold-body-1",
                    isNegative(entryFeeCent ?? 0) ? "tw-text-error-700" : "tw-text-grey-1000"
                )}>
                    {toWalletNumber(divideByOneHundred(entryFeeCent || 0))}
                </div>
            </ListItem>
        </>
    );
};

export default RubyContestMoreDetail;
