import BasketballNewsEmpty from "components/basketballPlayer/BasketballNewsEmpty";
import BasketballPlayerNews from "components/basketballPlayer/BasketballPlayerNews";
import IconButton, { IconButtonFor } from "components/buttons/IconButton";
import PlayerAvatar from "components/image/PlayerAvatar";
import FantasyScrollingPopup, {
    ScrollingPopupBody,
    ScrollingPopupFooter
} from "components/popup/FantasyScrollingPopup";
import { SupplierLogo } from "components/supplierLogo/SupplierLogo";
import { SUPPLIER_LOGO_TYPE } from "components/supplierLogo/type";
import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { DIALOG_SIZE } from "designToken/dialog/Dialog";
import NineCatGameLog from "domain/slate/components/gameLog/NineCatGameLog";
import NineCatGameLogEmpty from "domain/slate/components/gameLog/NineCatGameLogEmpty";
import NineCatGameLogSkeleton from "domain/slate/components/gameLog/NineCatGameLogSkeleton";
import { useCreateSlateTeamContext } from "domain/slate/providers/CreateNineCatTeamProvider";
import { useNineCatContext } from "domain/slate/providers/NineCatProvider";
import { cn } from "helpers/cn";
import { Icon } from "helpers/IconHelper";
import { useDragToScrollX } from "hooks/useDragToScrollX";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import BasketballNewsSkeleton from "../../../components/basketballPlayer/BasketballNewsSkeleton";
import PlayerPopupProfile from "./PlayerPopupProfile";
import PlayerPopupProfileSkeleton from "./PlayerPopupProfileSkeleton";

interface SlatePlayerPopupProps {
    open: boolean;
    onOpenChange: (open: boolean) => void;
}

const NineCatPlayerPopup: React.FC<SlatePlayerPopupProps> = ({ open, onOpenChange }) => {
    const bodyRef = useRef<HTMLDivElement>(null);
    const tableWrapperRef = useRef<HTMLDivElement>(null);
    const intl = useIntl();

    const [isShow, setIsShow] = useState(false);
    const [bodyKey, setBodyKey] = useState(0);

    const {
        selectedPlayer,
        playerInfo,
        playerNews,
        isPlayerDetailLoading
    } = useNineCatContext();

    const {
        focusedStat,
        focusedPlayerId,
        handleToggleFocusPlayer
    } = useCreateSlateTeamContext();

    useDragToScrollX(tableWrapperRef);

    const handleScroll = () => {
        if (bodyRef.current) {
            setIsShow(bodyRef.current.scrollTop > 100);
        }
    };

    const handleClose = () => {
        onOpenChange(false);
    };

    const handleSelectPlayer = () => {
        if (!focusedStat && !focusedPlayerId) {
            handleToggleFocusPlayer(selectedPlayer!.id as string);
        }
        handleClose();
    };


    useEffect(() => {
        if (open) {
            setBodyKey(Date.now());
            setIsShow(false);
        }
    }, [open]);

    useEffect(() => {
        if (bodyRef.current) {
            bodyRef.current.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (bodyRef.current) {
                bodyRef.current.removeEventListener("scroll", handleScroll);
            }
        };
    }, [bodyKey]);

    const renderTitle = (title: string, classNames?: string) => (
        <div className="tw-flex tw-items-center tw-gap-2 tw-mb-2">
            <div className={cn("tw-w-2 tw-h-6 tw-rounded-[4px] tw-bg-primary-500", classNames)} />
            <span className="tw-text-subtitle-1 tw-font-medium tw-text-grey-1000">
                {title}
            </span>
        </div>
    );

    const renderPlayerProfile = () => {
        return (
            <div
                className="tw-pb-6 tw-p-4 tw-flex-shrink-0 tw-flex tw-flex-col tw-gap-2 tw-bg-primary-900 tw-relative tw-mb-20">
                <div className="tw-text-right">
                    <IconButton
                        for={IconButtonFor.ON_DARK}
                        background={false}
                        icon={Icon.Close}
                        onClick={handleClose}
                    />
                </div>
                {
                    isPlayerDetailLoading
                        ? <PlayerPopupProfileSkeleton />
                        : <PlayerPopupProfile selectedPlayer={selectedPlayer!} playerInfo={playerInfo!} />
                }

            </div>
        );
    };

    const renderGameLog = () => {
        if (isPlayerDetailLoading) {
            return <NineCatGameLogSkeleton />;
        }

        if (!playerInfo?.gameLogs?.length) {
            return <NineCatGameLogEmpty />;
        }

        return <NineCatGameLog gameLogs={playerInfo.gameLogs.slice(0, 5)} />;
    };

    const renderNews = () => {
        if (isPlayerDetailLoading) {
            return <BasketballNewsSkeleton />;
        }

        if (!playerNews?.length) {
            return <BasketballNewsEmpty />;
        }

        return playerNews.map((news) => (
            <BasketballPlayerNews key={news.title} {...news} />
        ));
    };

    const renderPopupTitle = () => {
        if (!isShow || isPlayerDetailLoading) return null;

        return (
            <div className="tw-absolute top-0 tw-w-full tw-z-1">
                <div
                    className="tw-pb-6 tw-p-4 tw-flex tw-flex-col tw-gap-2 tw-bg-primary-900">
                    <div className="tw-text-right">
                        <IconButton
                            for={IconButtonFor.ON_DARK}
                            background={false}
                            icon={Icon.Close}
                            onClick={handleClose}
                        />
                    </div>
                    <div className="tw-flex tw-items-center justify-content-center tw-gap-3">
                        <div
                            className="tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center tw-mb-1.5">
                            <div
                                className="tw-absolute tw-overflow-hidden tw-cursor-pointer tw-w-8 tw-h-8 tw-bg-primary-800 tw-rounded-full"
                            >
                                <PlayerAvatar
                                    id={selectedPlayer!.id}
                                    url={selectedPlayer!.avatar}
                                />
                            </div>
                        </div>
                        <div className="tw-text-h6 tw-font-bold tw-text-white">
                            {selectedPlayer!.firstName} {selectedPlayer!.lastName}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderBody = () => {
        return (
            <ScrollingPopupBody className="tw-px-0 tw-relative" ref={bodyRef} key={bodyKey}>
                <>
                    {renderPlayerProfile()}

                    <div className="tw-mb-8 tw-px-6">
                        {renderTitle(intl.formatMessage({ id: "player_info_popup_label_last_5_games" }), "tw-bg-primary-300")}
                        <div className="tw-bg-grey-100 -tw-mx-6 tw-pb-4">
                            <div
                                className="tw-max-w-app-max tw-full tw-overflow-scroll"
                                ref={tableWrapperRef}
                            >
                                <div className="tw-p-4">
                                    {renderGameLog()}
                                </div>
                            </div>
                            <SupplierLogo supplier={playerInfo?.supplier}
                                          type={SUPPLIER_LOGO_TYPE.DIVIDER} />
                        </div>
                    </div>
                    <div className="mb-4 tw-px-6">
                        {renderTitle(intl.formatMessage({ id: "player_info_popup_label_player_news" }))}
                        {renderNews()}
                    </div>
                </>
            </ScrollingPopupBody>
        );
    };

    return (
        <FantasyScrollingPopup
            open={open}
            onOpenChange={onOpenChange}
            dialogSize={DIALOG_SIZE.XL}
            title={renderPopupTitle()}
            body={renderBody()}
            testId="player-popup-profile"
            footer={
                <ScrollingPopupFooter className="tw-flex tw-gap-4 tw-pb-3 tw-px-4">
                    <Button
                        variant={NEW_BUTTON_VARIANTS.grey}
                        pattern={BUTTON_PATTERN.rounded}
                        size={BUTTON_SIZE.lg}
                        className="tw-flex-1"
                        onClick={handleClose}
                    >
                        <FormattedMessage id="button_got_it" />
                    </Button>
                    <Button
                        variant={NEW_BUTTON_VARIANTS.secondaryDark}
                        pattern={BUTTON_PATTERN.rounded}
                        size={BUTTON_SIZE.lg}
                        className="tw-flex-1"
                        onClick={handleSelectPlayer}
                    >
                        <FormattedMessage id="label_select" />
                    </Button>
                </ScrollingPopupFooter>
            }
        />
    );
};

export default NineCatPlayerPopup;
