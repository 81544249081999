import React from "react";
import { cn } from "helpers/cn";

interface InfoDetailTextProps {
    title: React.ReactNode;
    value: string;
}

export const InfoDetailText: React.FC<InfoDetailTextProps> = ({ title, value }) => {
    return <div>
        <div className={cn(
            "tw-text-body-2 tw-text-gray-800/50"
        )}>
            {title}
        </div>
        <div className={cn(
            "tw-text-body-1 tw-text-gray-900 tw-font-bold"
        )}>
            {value}
        </div>
    </div>;
};
