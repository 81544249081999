import React, { ReactNode } from "react";
import { cn } from "helpers/cn";

export interface StepTextProps {
    title: ReactNode
    subtitle: ReactNode
}

export const StepText: React.FC<StepTextProps> = ({
                                                      title,
                                                      subtitle
                                                  }) => {
    return <div>
        <div className={cn("tw-text-b2b-primary-400 tw-text-h3 tw-font-black tw-text-center")}>
            {title}
        </div>
        <div className={cn("tw-text-center tw-text-grey-750 tw-text-body-1")}>
            {subtitle}
        </div>
    </div>;
};
