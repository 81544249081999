import React from "react";
import { Card } from "react-bootstrap";
import { cn } from "helpers/cn";

export const Text: React.FC<{ text?: string, textClassName?: string }> = ({ text, textClassName }) => {
    if (!text) return null;
    return <Card.Text
        className={cn("tw-px-3 tw-text-subtitle-1 tw-text-grey-1000 tw-whitespace-pre-line", textClassName)}>
        {text}
    </Card.Text>;
};
