import { ajax } from "apis/ApiUtils";
import { fetchWalletHistoryUrl, getRubyTransactionDetailUrl, getTransactionDetailUrl } from "apis/ApiUrlConfig";
import { WalletHistoriesDto } from "data/dto/wallet/walletHistoryDto";
import { TransactionDetailDto } from "data/dto/wallet/TransactionDetailDto";

export const fetchWalletHistory = async (): Promise<WalletHistoriesDto> => {
    const { data } = await ajax<WalletHistoriesDto>({ url: fetchWalletHistoryUrl });
    return data.data;
};

export const fetchTransactionDetail = async (historyId: number): Promise<TransactionDetailDto> => {
    const { data } = await ajax<TransactionDetailDto>({ url: getTransactionDetailUrl(historyId) });
    return data.data;
};

export const fetchRubyTransactionDetail = async (historyId: number): Promise<TransactionDetailDto> => {
    const { data } = await ajax<TransactionDetailDto>({ url: getRubyTransactionDetailUrl(historyId) });
    return data.data;
};
