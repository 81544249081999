import React from "react";
import { cn } from "helpers/cn";
import { FormattedMessage } from "react-intl";
import Carousel from "components/carousel";
import { PlayerCard } from "domain/result/detail/components/Info/component/PlayerCard";
import { SPORT } from "types/sports/Sport";
import { MatchResultPlayerVo } from "data/vo/player/matchResult/MatchResultPlayersVo";
import { SwiperSlide } from "swiper/react";

interface RoleRankingProps {
    sport: SPORT;
    role: string;
    players: MatchResultPlayerVo[];
    onAvatarClick: (player: MatchResultPlayerVo) => void;
}

export const Ranking: React.FC<RoleRankingProps> = ({
                                                        sport,
                                                        role,
                                                        players,
                                                        onAvatarClick
                                                    }) => {
    if (players.length === 0) {
        return null;
    }

    const maxScore = players[0].point;

    return <div className={cn("tw-w-full tw-flex tw-flex-col tw-gap-2",)}>
        <div className={cn("tw-py-1 tw-text-body-1 tw-font-bold tw-text-grey-850/80")}>
            <FormattedMessage id={`player_role_${sport}_${role}`} />
        </div>
        <Carousel
            className={cn("tw-w-full")}
            slidesPerView="auto"
            spaceBetween={8}
        >
            {
                players?.map((player, index) => {
                    return <SwiperSlide
                        key={`${role}_${player.id}`}
                        data-testid={`player_${role}_${player.id}`}
                        className={cn("!tw-w-auto !tw-items-stretch")}
                    >
                        <PlayerCard
                            player={player}
                            isMvp={player.point === maxScore}
                            onAvatarClick={onAvatarClick}
                        />
                    </SwiperSlide>;
                })
            }
        </Carousel>
    </div>;
};
