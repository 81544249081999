import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

export const TeamNameWrapper = styled("div").attrs<{
    $highlight?: boolean
}>({
    className: "d-flex align-items-center py-2 px-3 rounded-top"
})`
    background-color: ${({ $highlight }) => $highlight ? "var(--secondary-20)" : "var(--primary-8)"}
`;
export const CheckBoxWrapper = styled.div.attrs(() => ({}))`
    margin-right: .4rem;
`;

export const TeamName = styled("div").attrs(() => ({
    className: "fz-sf-bold-body-1"
}))``;

export const TeamInfo = styled("div").attrs(() => ({
    className: "d-flex align-items-center"
}))`

`;
export const SpecialPlayerWrapper = styled(Col).attrs(() => ({
    className: "col-3 position-relative"
}))`
`;
export const CaptainIconWrapper = styled.div.attrs(() => ({
    className: "rounded-circle d-flex justify-content-center align-items-center position-absolute tw-bg-primary-600"
}))`
    width: 2.6rem;
    height: 2.6rem;
    border: solid 2px #9B9BC1;
    left: .35rem;
`;
export const ViceCaptainIconWrapper = styled(CaptainIconWrapper).attrs(() => ({
    className: "tw-bg-system-primary60"
}))`
`;
export const CaptainAndViceCaptainIcon = styled.div.attrs(() => ({
    className: "fz-sf-regular-subhead"
}))`
    color: var(--white);
`;
export const SpecialPlayerImageWrapper = styled.div.attrs(() => ({
    className: "mx-auto position-relative"
}))`
    width: 5.6rem;
    height: 5.6rem;
`;
export const SpecialPlayerName = styled.div.attrs(() => ({
    className: "text-center fz-sf-regular-caption-1 text-truncate mx-auto"
}))`
    max-width: 8.8rem;
    line-height: 1.3rem;
`;
export const SquadAndRoleInfo = styled(Col).attrs(() => ({
    className: ""
}))`

`;
export const SquadRow = styled("div").attrs(() => ({
    className: "d-flex"
}))`
    margin: .8rem 0 1.6rem;
    gap: 2.4rem;
`;
export const SquadLeftInfo = styled(Col).attrs(() => ({
    className: "col-6 d-flex justify-content-end align-items-center"
}))`
`;
export const SquadRightInfo = styled(Col).attrs(() => ({
    className: "col-6 d-flex justify-content-start align-items-center"
}))`
`;
export const SquadLogoWrapper = styled.div.attrs(() => ({
    className: ""
}))`
    width: 2rem;
`;
export const SquadPlayerCount = styled.div.attrs(() => ({
    className: "fz-sf-bold-headline ps-1"
}))`

`;
export const RoleInfo = styled(Col).attrs(() => ({
    className: "d-flex flex-column align-items-center"
}))``;

export const RoleName = styled.div.attrs(() => ({
    className: "fz-sf-regular-caption-1 tw-text-system-labelLightSecondary"
}))`
`;
export const RoleCount = styled.div.attrs(() => ({
    className: "fz-sf-bold-footnote tw-text-system-labelLightPrimary"
}))`
`;
export const PointsRow = styled(Row).attrs(() => ({}))`
    margin: .4rem 0;
`;
export const PointsCol = styled(Col).attrs(() => ({
    className: "text-center"
}))``;
export const PointsTitle = styled.div.attrs(() => ({
    className: "fz-sf-regular-caption-1 tw-text-system-labelLightSecondary"
}))`
`;
export const PointsValue = styled.div.attrs(() => ({
    className: "fz-sf-bold-headline tw-text-system-labelLightPrimary"
}))`
    margin-top: -.2rem;
`;
export const Dot = styled.span.attrs({
    className: "rounded-circle border-light-primary"
})`
    border: 1px solid;
    width: 0.7rem;
    height: 0.7rem;
`;
