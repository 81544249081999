import JoinableNavbarMatchInfo from "components/container/navbar/JoinableNavbarMatchInfo";
import Navbar from "components/container/navbar/Navbar";
import PageViewRecorder from "components/ga/PageViewRecorder";
import ShareContestPopup from "components/popup/shareContestPopup/ShareContestPopup";
import { ContestDetailCard } from "domain/contest/components/ContestDetailCard";
import { ContestDetailWrapper } from "domain/contest/components/ContestStyle";
import ContestInfoDetail from "domain/contest/join/info/detail/ContestInfoDetail";
import { useStopJoiningDialog } from "hooks/useDialog";
import useFantasyHistory from "hooks/useFantasyHistory";
import React, { useState } from "react";
import { ContestDetailVo } from "data/vo/contest/ContestDetailVo";
import { LeaderboardTeamVo } from "data/vo/leaderboard/LeaderboardTeamVo";
import { MatchVO } from "types/match/Match";

interface ContestInfoProps {
    contest: ContestDetailVo;
    myJoinedTeams: LeaderboardTeamVo[]
    match: MatchVO;
    children?: React.ReactNode
}

const ContestInfo: React.FC<ContestInfoProps> = ({ contest, match, myJoinedTeams, children }) => {
    const contestInfoBack = useFantasyHistory().goBack;
    const stopJoiningDialog = useStopJoiningDialog();

    const [copyModalShow, setCopyModalShow] = useState(false);

    function onCopyModalShow() {
        setCopyModalShow(true);
    }

    function onCopyModalHide() {
        setCopyModalShow(false);
    }

    return <>
        <PageViewRecorder title="Contest Detail" />
        <Navbar navbarStartAppend={<JoinableNavbarMatchInfo match={match}
                                                            handleTimeout={stopJoiningDialog} />}
                onNavbarBackClick={contestInfoBack}
                showWallet={true} />
        <ContestDetailWrapper>
            <ContestDetailCard
                contest={{
                    teams: contest.joinedTeams,
                    regularPoolCent: contest.regularPoolCent,
                    hostDonatedPoolCent: contest.hostDonatedPoolCent,
                    entryFeeCent: contest.entryFeeCent,
                    entryFeeType: contest.entryFeeType,
                    name: contest.name,
                    spots: contest.spots,
                    status: contest.status,
                    type: contest.type,
                    minApLevel: contest.minApLevel,
                    firstPrizeCent: contest.firstPrizeCent,
                    minimumSlip: contest.minimumSlip,
                    allowDiscountWithRuby: contest.allowDiscountWithRuby,
                    allowMultiple: contest.allowMultiple,
                    prizeTeams: contest.prizeTeams,
                }}
                onCopyModalShow={onCopyModalShow}
                matchStatus={match.matchStatus}
            />
        </ContestDetailWrapper>

        {
            contest && <ContestInfoDetail
                contest={contest}
                myJoinedTeams={myJoinedTeams}
            />
        }

        {children}


        <ShareContestPopup
            show={copyModalShow}
            code={contest.code}
            onHide={onCopyModalHide}
            contestId={contest.id}
        />
    </>;
};

export default ContestInfo;
