import { infoIconBlack } from "helpers/IconHelper";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import Navbar from "../../../components/container/navbar/Navbar";
import { useLoadingToggle } from "../../../components/loading/Loading";
import Dialog, { DIALOG_SIZE } from "../../../designToken/dialog/Dialog";
import {
    DialogSmBody,
    DialogSmButton,
    DialogSmButtonWrapper,
    DialogSmContent,
    DialogSmContentWrapper
} from "../../../designToken/dialog/DialogStyle";
import useUserIdentity from "../../../hooks/useUserIdentity";
import { RootStoreState } from "../../../store/reducer";
import { ActiveTabItem, TabItem, TabWrapper } from "../../../styles/component/TabStyle";
import { CouponDialogContent, InfoIcon } from "../components/CouponStyle";
import ExpiredCoupon from "../components/ExpiredCoupon";
import UnusedCoupon from "../components/UnusedCoupon";
import UsedCoupon from "../components/UsedCoupon";
import useUpdateCoupon from "../useUpdateCoupon";


const couponTabs = {
    unused: {
        key: "unused",
        text: <FormattedMessage id="my_coupon_page_tab_unused" />,
        content: UnusedCoupon
    },
    used: {
        key: "used",
        text: <FormattedMessage id="my_coupon_page_tab_used" />,
        content: UsedCoupon
    },
    expired: {
        key: "expired",
        text: <FormattedMessage id="my_coupon_page_tab_expired" />,
        content: ExpiredCoupon
    }
};

const CouponTabs = ({ activeTab, setActiveTab, myCoupons }) => {
    return <TabWrapper>
        {
            Object.entries(couponTabs).map(([_, { key, text }]) => {
                return activeTab === key
                    ? <ActiveTabItem key={key}>
                        {text} ({myCoupons[key].length})
                    </ActiveTabItem>
                    : <TabItem key={key}
                               onClick={() => {
                                   window.scrollTo(0, 0);
                                   setActiveTab(key);
                               }}>
                        {text} ({myCoupons[key].length})
                    </TabItem>;
            })
        }
    </TabWrapper>;
};

const MyCoupon = () => {
    const { CouponState: myCoupons } = useSelector((store: RootStoreState) => store);
    const { isB2C } = useUserIdentity();
    const [activeTab, setActiveTab] = useState(couponTabs.unused.key);
    const loadingToggle = useLoadingToggle();
    const updateCoupon = useUpdateCoupon();

    const [showDialog, setShowDialog] = useState(false);

    const openDialog = () => setShowDialog(true);
    const hideDialog = () => setShowDialog(false);

    useEffect(() => {
        (async () => {
            loadingToggle(true);

            await updateCoupon();

            loadingToggle(false);
        })();

    }, [loadingToggle, updateCoupon]);

    const { content: Content } = couponTabs[activeTab] || {};
    const props = { coupons: myCoupons[activeTab] };

    return <>
        {
            isB2C
            && <Navbar
                navbarCenter={
                    <div
                        className="h-100 fz-sf-bold-body-1 text-text-primary-1 d-flex align-items-center justify-content-center">
                        <FormattedMessage
                            id="label_my_coupons"
                        />
                    </div>
                }
                endIcon={<InfoIcon src={infoIconBlack} onClick={openDialog} />}
                navbarBottom={<CouponTabs activeTab={activeTab} setActiveTab={setActiveTab} myCoupons={myCoupons} />}
            />
        }

        <Content {...props} />

        <Dialog show={showDialog}
                contentClassName="overflow-hidden"
                size={DIALOG_SIZE.SM}
                onHide={hideDialog}
        >
            <DialogSmBody>
                <DialogSmContentWrapper>
                    <DialogSmContent>
                        <CouponDialogContent>
                            <FormattedMessage id="coupon_tooltip_dialog_label_1" tagName="li" />
                            <FormattedMessage id="coupon_tooltip_dialog_label_2" tagName="li" />
                            <FormattedMessage id="coupon_tooltip_dialog_label_3" tagName="li" />
                        </CouponDialogContent>
                    </DialogSmContent>
                </DialogSmContentWrapper>
                <DialogSmButtonWrapper>
                    <DialogSmButton onClick={hideDialog}>
                        <FormattedMessage id="dialog_button_close" />
                    </DialogSmButton>
                </DialogSmButtonWrapper>
            </DialogSmBody>
        </Dialog>
    </>;
};

export default MyCoupon;

