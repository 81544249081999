import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Api from "apis/Api";
import App from "App";
import B2bLogin from "B2bLogin";
import customHistory from "CustomHistory";
import HealthCheck from "HealthCheck";
import { storageHelper } from "helpers/localStorage/LocalStorageHelper";
import { LOCAL_STORAGE_KEYS } from "helpers/localStorage/types";
import { LocaleProvider } from "LocaleProvider";
import { defaultLocale, LOCALE } from "locales";
import { LocaleContext, SetLocaleContext } from "locales/LocaleContext";
import MobileAppLogin from "MobileAppLogin";
import { OfficialWebsite } from "officialWebsite/OfficialWebsite";
import React, { useCallback, useState } from "react";
import { Provider } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import store from "store";


const Root = () => {
    const localeStorage = storageHelper(LOCAL_STORAGE_KEYS.LOCALE);
    const [locale, setLocale] = useState<LOCALE>(localeStorage.get() ?? defaultLocale);

    const onLanguageChange = useCallback((language: LOCALE) => {
        setLocale(language);
        localeStorage.put(language);
    }, [localeStorage]);


    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                retry: false,
            }
        }
    });

    return (
        <Provider store={store}>
            <LocaleProvider>
                <LocaleContext.Provider value={locale}>
                    <SetLocaleContext.Provider value={onLanguageChange}>
                        <QueryClientProvider client={queryClient}>
                            <Router history={customHistory}>
                                <Api>
                                    <Switch>
                                        <Route exact path={ROUTER_PATH_CONFIG.healthCheck}>
                                            <HealthCheck />
                                        </Route>
                                        <Route exact path={ROUTER_PATH_CONFIG.b2bRedirect}>
                                            <B2bLogin />
                                        </Route>
                                        <Route exact path={ROUTER_PATH_CONFIG.mobileAppRedirect}>
                                            <MobileAppLogin />
                                        </Route>
                                        <Route path={ROUTER_PATH_CONFIG.officialWebsite.index}>
                                            <OfficialWebsite />
                                        </Route>
                                        <Route path={ROUTER_PATH_CONFIG.home}>
                                            <App />
                                        </Route>
                                    </Switch>
                                </Api>
                            </Router>
                        </QueryClientProvider>
                    </SetLocaleContext.Provider>
                </LocaleContext.Provider>
            </LocaleProvider>
        </Provider>
    );
};

export default Root;
