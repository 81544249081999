import { divideByOneHundred, isNegative } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import _ from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import B2CWrapper from "../../../containers/b2cWrapper/B2CWrapper";
import { ListItem, ListTitle, ListValue } from "../components/TransactionDetailStyle";
import { TransactionDetailVo } from "data/vo/wallet/TransactionDetailVo";
import { cn } from "helpers/cn";

const CoinContestMoreDetail = (transactData: TransactionDetailVo) => {
    const {
        sport,
        matchName,
        contestName,
        entryFeeCent,
        couponCent,
        rubyCent
    } = transactData;
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();

    return (
        <>
            <ListItem>
                <ListTitle><FormattedMessage id="label_sport" /></ListTitle>
                <ListValue>{sport ? _.capitalize(sport) : "-"}</ListValue>
            </ListItem>
            <ListItem>
                <ListTitle><FormattedMessage id="label_match" /></ListTitle>
                <ListValue>{matchName || "-"}</ListValue>
            </ListItem>
            <ListItem>
                <ListTitle><FormattedMessage id="label_contest_name" /></ListTitle>
                <ListValue>{contestName || "-"}</ListValue>
            </ListItem>
            <ListItem>
                <ListTitle><FormattedMessage id="fantasy_sport_label_entry_fee" /></ListTitle>
                <div className={cn(
                    "fz-sf-bold-body-1",
                    isNegative(entryFeeCent ?? 0) ? "tw-text-error-700" : "tw-text-grey-1000"
                )}>
                    {toWalletNumber(divideByOneHundred(entryFeeCent || 0))}
                </div>
            </ListItem>
            <B2CWrapper>
                <ListItem className="bg-gray-2">
                    <ListTitle><FormattedMessage id="label_coupon" /></ListTitle>
                    <ListValue>{toWalletNumber(divideByOneHundred(couponCent || 0))}</ListValue>
                </ListItem>
                <ListItem className="bg-gray-2">
                    <ListTitle><FormattedMessage id="label_ruby" /></ListTitle>
                    <ListValue>{toWalletNumber(divideByOneHundred(rubyCent || 0))}</ListValue>
                </ListItem>
            </B2CWrapper>
        </>
    );
};

export default CoinContestMoreDetail;
