import React from "react";
import { TransactionDetailVo } from "data/vo/wallet/TransactionDetailVo";
import { WALLET_API_RESPONSE_EVENT, WALLET_TYPE } from "data/dto/wallet/walletHistoryDto";
import RubyContestMoreDetail from "domain/wallet/transactionDetail/RubyContestMoreDetail";
import WinningContestDetails from "domain/wallet/transactionDetail/WinningContestDetails";
import { ClaimTaskNote } from "domain/wallet/transactionDetail/ClaimTaskNote";
import TransactionDetailBase from "domain/wallet/transactionDetail/TransactionDetailBase";

export interface RubyTransactionDetailProps {
    transactionData?: TransactionDetailVo;
    onHide: () => void;
}

const RubyTransactionDetail: React.FC<RubyTransactionDetailProps> = ({
                                                                         transactionData,
                                                                         onHide
                                                                     }) => {

    if (!transactionData) return null;

    switch (transactionData.event) {
        case WALLET_API_RESPONSE_EVENT.JOIN_CONTEST: // 實際上為折扣deposit場contest
        case WALLET_API_RESPONSE_EVENT.JOIN_DEPOSIT_CONTEST: // 實際上為折扣deposit場contest
        case WALLET_API_RESPONSE_EVENT.JOIN_RUBY_CONTEST:
        case WALLET_API_RESPONSE_EVENT.REFUND_CONTEST: // 舊的
        case WALLET_API_RESPONSE_EVENT.REFUND_RUBY_CONTEST:
        case WALLET_API_RESPONSE_EVENT.REFUND_DEPOSIT_CONTEST: // deposit場的退還ruby
            return <TransactionDetailBase
                transactionData={transactionData}
                extraDetailComponent={
                    () => <RubyContestMoreDetail transactionData={transactionData} />
                }
                walletType={WALLET_TYPE.RUBY}
                onHide={onHide}
            />;

        case WALLET_API_RESPONSE_EVENT.WIN_CONTEST:
            return <TransactionDetailBase
                transactionData={transactionData}
                extraDetailComponent={() => (
                    <WinningContestDetails {...transactionData} />
                )}
                walletType={WALLET_TYPE.RUBY}
                onHide={onHide}
            />;

        case WALLET_API_RESPONSE_EVENT.CLAIM_TASK:
            return <TransactionDetailBase
                transactionData={transactionData}
                noteComponent={ClaimTaskNote}
                walletType={WALLET_TYPE.RUBY}
                onHide={onHide}
            />;

        default:
            return <TransactionDetailBase
                transactionData={transactionData}
                walletType={WALLET_TYPE.RUBY}
                onHide={onHide}
            />;
    }
};

export default RubyTransactionDetail;
