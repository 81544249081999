import styled from "styled-components";
import { FixedButton } from "../../styles/component/ButtonsStyle";

export const CurrencyAndLocalePageWrapper = styled.div.attrs({
    className: "w-100"
})`
  margin-top: 2rem;
  height: calc(100vh - 84px);
  padding: 1.6rem 1.6rem 2.4rem;
`;

export const Title = styled.div.attrs(() => ({
    className: "d-flex justify-content-center align-items-center fz-sf-bold-title-3 h-100 tw-text-system-primary80"
}))`
  font-size: 1.8rem;
`;

export const Text = styled.div.attrs({
    className: "fz-sf-bold-body-1 text-primary-3"
})`
  margin-bottom: 2.4rem;
`;

export const ActiveSelectionText = styled.div.attrs({
    className: "fz-sf-regular-body-1 text-primary-1"
})`
`;

export const SelectionText = styled.div.attrs({
    className: "fz-sf-regular-body-1 text-light-primary"
})`
`;

export const ActiveExampleText = styled.span.attrs({
    className: "fz-sf-regular-body-2 text-primary-3"
})`
  margin-left: .2rem;
`;

export const ExampleText = styled.span.attrs({
    className: "fz-sf-regular-body-2 text-light-secondary"
})`
  margin-left: .2rem;
`;

export const CheckWrapper = styled.div.attrs({
    className: ""
})`
  width: 2rem;

  img {
    width: 100%;
  }
`;

export const Selection = styled.div.attrs({
    className: "d-flex justify-content-between align-items-center border-light-primary"
})`
  cursor: pointer;
  padding: 1.2rem .8rem;
  border-bottom: 1px solid;
`;

export const ActiveSelection = styled(Selection).attrs({
    className: "bg-primary-7"
})`
  cursor: auto;
`;

export const SubmitButton = styled(FixedButton).attrs(() => ({
    className: "cl-primary-solid-button"
}))`
  bottom: 2.4rem;
`;
