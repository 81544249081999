import SquadLogo from "components/image/SquadLogo";
import { getSportConfig } from "domain/SportConfig";
import { TeamContext } from "domain/team/OperateTeam";
import { countBySquadKey, getTShirtIcons } from "domain/team/OperateTeamHelper";
import {
    AllPlayerLimit,
    AllPlayerLimitCol,
    AllPlayerNumber,
    CreditCol,
    CreditNumber,
    MatchInfoRow,
    NavbarBottomContainer,
    NavbarBottomSquadAPlayerCol,
    NavbarBottomSquadBPlayerCol,
    RemoveIconWrapper,
    RestrictionNumber,
    RestrictionTitle,
    SquadACode,
    SquadBCode,
    SquadLogoImgWrapper,
    SquadPlayerLimit,
    SquadPlayerLimitWrapper,
    SquadPlayerNumber,
    TeamInfoRow,
    TShirt
} from "domain/team/player/ChoosePlayerStyle";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import { useWarningDialog } from "hooks/useDialog";
import React, { useContext } from "react";
import { MdOutlineDoNotDisturbOn } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import { MatchVO } from "types/match/Match";
import { SelectablePlayer } from "types/player/Player";
import { SPORT } from "types/sports/Sport";

interface ChoosePlayerNavbarBottomProps {
    match: MatchVO,
    selectedPlayers: SelectablePlayer[],
    restCreditCent: number,
    sport: SPORT
}

const ChoosePlayerNavbarBottom: React.FC<ChoosePlayerNavbarBottomProps> = ({
                                                                               match,
                                                                               selectedPlayers,
                                                                               restCreditCent,
                                                                               sport
                                                                           }) => {
    const { team: { maxPlayerCount, maxPlayerCountEachSquad } } = getSportConfig(sport);

    const intl = useIntl();
    const showWarningPopup = useWarningDialog();
    const { setAllPlayers } = useContext(TeamContext);
    const squadKeyToPlayers = countBySquadKey(selectedPlayers);

    const onShowWRemoveWarning = () => {
        showWarningPopup({
            title: intl.formatMessage({ id: "sure_to_clear_team_title" }),
            content: intl.formatMessage({ id: "sure_to_clear_team_description" }),
            cancelButton: {
                text: intl.formatMessage({ id: "label_cancel" })
            },
            confirmButton: {
                text: intl.formatMessage({ id: "label_sure" }),
                onClick: onRemove
            }
        });
    };

    const onRemove = () => {
        setAllPlayers(preState => preState.map(player => {
            player.setSelected(false);
            return player;
        }));
    };

    function getSelectedSquadPlayerCount(squadKey: SelectablePlayer["squadKey"]) {
        return squadKeyToPlayers[squadKey] || 0;
    }

    return (
        <NavbarBottomContainer>
            <MatchInfoRow>
                <AllPlayerLimitCol>
                    <RestrictionTitle><FormattedMessage id="label_players" /></RestrictionTitle>
                    <RestrictionNumber data-testid={"selected-players-count"}>
                        <AllPlayerNumber>{selectedPlayers.length}</AllPlayerNumber>
                        <AllPlayerLimit>/ {maxPlayerCount}</AllPlayerLimit>
                    </RestrictionNumber>
                </AllPlayerLimitCol>

                <NavbarBottomSquadAPlayerCol>
                    <SquadLogoImgWrapper>
                        <SquadLogo url={match.squadALogoUrl} name={match.squadAName} />
                    </SquadLogoImgWrapper>
                    <SquadPlayerLimitWrapper>
                        <SquadACode>{match.squadACode}</SquadACode>
                        <SquadPlayerLimitWrapper data-testid={"squad-a-players-count"}>
                            <SquadPlayerNumber>{getSelectedSquadPlayerCount(match.squadAKey)}</SquadPlayerNumber>
                            <SquadPlayerLimit>/ {maxPlayerCountEachSquad}</SquadPlayerLimit>
                        </SquadPlayerLimitWrapper>
                    </SquadPlayerLimitWrapper>
                </NavbarBottomSquadAPlayerCol>

                <NavbarBottomSquadBPlayerCol>
                    <SquadPlayerLimitWrapper>
                        <SquadBCode>{match.squadBCode}</SquadBCode>
                        <SquadPlayerLimitWrapper data-testid={"squad-b-players-count"}>
                            <SquadPlayerNumber>{getSelectedSquadPlayerCount(match.squadBKey)}</SquadPlayerNumber>
                            <SquadPlayerLimit>/ {maxPlayerCountEachSquad}</SquadPlayerLimit>
                        </SquadPlayerLimitWrapper>
                    </SquadPlayerLimitWrapper>
                    <SquadLogoImgWrapper>
                        <SquadLogo url={match.squadBLogoUrl} name={match.squadBName} />
                    </SquadLogoImgWrapper>
                </NavbarBottomSquadBPlayerCol>

                <CreditCol>
                    <RestrictionTitle><FormattedMessage id="label_credits" /></RestrictionTitle>
                    <CreditNumber data-testid={"rest-credit"}>{divideByOneHundred(restCreditCent)}</CreditNumber>
                </CreditCol>
            </MatchInfoRow>
            <TeamInfoRow className="position-relative">
                {
                    getTShirtIcons(selectedPlayers.length, maxPlayerCount)
                        .map((shirt, idx) => <TShirt key={idx} $bgImg={shirt} />)
                }
                <RemoveIconWrapper>
                    <MdOutlineDoNotDisturbOn size="2rem"
                                             onClick={onShowWRemoveWarning} />
                </RemoveIconWrapper>
            </TeamInfoRow>
        </NavbarBottomContainer>
    );
};

export default ChoosePlayerNavbarBottom;
