import { HINT_CARD_VARIANT } from "components/hintcard/HintCard";
import { WithdrawHintCard } from "domain/withdraw/WithdrawHintCard";
import { useWithdrawContext } from "domain/withdraw/WithdrawProvider";
import React from 'react';
import { FormattedMessage } from "react-intl";
import { z } from "zod";
import { Form, FormLabel } from "../../components/ui/form";
import Button from "../../designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "../../designToken/button/types";
import { FantasyIntegerTextField } from "../../designToken/textFields/FantasyTextField";
import { cn } from "../../helpers/cn";
import { Icon } from "../../helpers/IconHelper";
import useCurrency from "../../hooks/useCurrency/useCurrency";
import { useSelector } from "../../store";
import { formatToTextGroups } from "helpers/stringFormatHelpers";
import { InfoCard } from "./InfoCard";
import { FORM_FIELD_AMOUNT } from "domain/withdraw/hooks/useWithdrawStep2Form";
import IconButton, { IconButtonFor } from "components/buttons/IconButton";
import WithdrawLayout from "domain/withdraw/WithdrawLayout";

function getPesoIconTranslateX(amountTextSize = 0) {
    const INIT_TRANSLATE_X = -16;
    if (amountTextSize < 2) {
        return INIT_TRANSLATE_X;
    }

    const TEXT_WIDTH = -10.5;
    const PESO_ICON_WIDTH = 9;
    return (amountTextSize * TEXT_WIDTH) / 2 - PESO_ICON_WIDTH;
}


function AvailableWinning(props: { isAmountExceededValidationError: boolean, winningBalance: string }) {
    return <p className={cn("tw-text-body-1", "tw-flex tw-items-center tw-justify-center tw-gap-1", "tw-py-1 tw-px-2", {
        "tw-bg-error-50 tw-rounded-md": props.isAmountExceededValidationError
    })}>
        <FormattedMessage id="label_available" />
        <div className={"tw-text-subtitle-2 tw-flex tw-items-center"}>
            <Icon.PesoThin className="tw-inline-block" size="1.2rem" />
            {props.winningBalance}
        </div>
    </p>;
}

const WithdrawStep2: React.FC = () => {
    const { step2Form, handleGoBack, handleStepNext, sendToInfo } = useWithdrawContext();

    const amount = step2Form.watch(FORM_FIELD_AMOUNT);
    const amountTextLength = String(amount || "").length;

    const errorType = step2Form.formState.errors[FORM_FIELD_AMOUNT]?.type;
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();
    const { WalletState: { winning } } = useSelector(state => state);

    const hintCardVariant: HINT_CARD_VARIANT = (errorType === z.ZodIssueCode.too_small) ?
        HINT_CARD_VARIANT.ERROR_LIGHT : HINT_CARD_VARIANT.PRIMARY_LIGHT;

    const handleStep2Next = step2Form.handleSubmit(handleStepNext);

    return (
        <div className={"tw-pb-[4rem]"} data-testid="step2Wrapper">
            <WithdrawLayout
                topSlot={
                    <div
                        className={cn("tw-flex tw-flex-col tw-items-center tw-gap-5", "tw-py-[2.2rem]", "-tw-mb-[2rem]")}>
                        <InfoCard
                            icon={<Icon.LeftRightArrows />}
                            label={<FormattedMessage id="label_send_to" />} />
                        <div className={cn("tw-flex tw-flex-col tw-items-center tw-gap-1", "tw-text-center")}>
                            <IconButton
                                for={IconButtonFor.ON_LIGHT}
                                data-testid="editAccountButton"
                                background={true}
                                icon={() => <Icon.EditFilled size={"1.2rem"} className={"!tw-text-black"} />}
                                onClick={handleGoBack}
                            />
                            <h6 className={"tw-text-h6 tw-font-bold"}>
                                {sendToInfo.accountName}
                            </h6>
                            <hr className={cn("tw-w-[16rem]", "tw-border-b-[1px] tw-border-grey-400 tw-border-solid")} />
                            <div>
                                <p className={"tw-text-subtitle-2"}>
                                    {sendToInfo.selectedBank}
                                </p>
                                <p className={"tw-text-subtitle-2"}>
                                    {formatToTextGroups(sendToInfo.accountNumber)}
                                </p>
                            </div>
                        </div>
                    </div>
                }
                bottomSlot={
                    <div
                        className={cn("tw-relative tw-z-2", "tw-pt-[5rem] tw-px-7", "tw-flex tw-flex-col tw-gap-1 tw-item tw-items-center tw-m-auto")}>
                        <Form {...(step2Form)} >
                            <div className={"tw-relative tw-w-full"}>
                                <FormLabel
                                    className={"tw-text-body-1 "}
                                    htmlFor={FORM_FIELD_AMOUNT}>
                                    <FormattedMessage id="label_amount" />
                                </FormLabel>
                                <FantasyIntegerTextField
                                    name={FORM_FIELD_AMOUNT}
                                    inputProps={{
                                        // autoFocus: true,
                                        id: FORM_FIELD_AMOUNT,
                                        className: cn("tw-placeholder-grey-600 tw-bg-grey-50", "tw-text-center", "tw-text-h6-medium", "tw-py-[3.8rem] focus:placeholder:tw-text-transparent"),
                                        autoComplete: "off",
                                        placeholder: "0"
                                    }}
                                />
                                <Icon.PesoThin
                                    className={cn("tw-inline-block", "tw-absolute tw-left-0 tw-right-0 tw-top-[5rem] tw-m-auto")}
                                    size="1.8rem"
                                    style={{
                                        transform: `translateX(${getPesoIconTranslateX(amountTextLength)}px)`
                                    }} />
                            </div>
                        </Form>
                        <AvailableWinning
                            isAmountExceededValidationError={errorType === z.ZodIssueCode.too_big}
                            winningBalance={toWalletNumber(winning)} />
                        <div className={"tw-pt-8 tw-w-full"}>
                            <WithdrawHintCard variant={hintCardVariant} />
                        </div>
                        <div className={cn("tw-pt-[5.6rem] tw-w-full", "tw-flex tw-gap-3 tw-items-center")}>
                            <IconButton
                                for={IconButtonFor.ON_LIGHT}
                                data-testid="bottomEditAccountButton"
                                background={false}
                                iconSize={"3rem"}
                                icon={Icon.EditFilled}
                                onClick={handleGoBack}
                            />
                            <Button
                                className={"tw-w-full"}
                                pattern={BUTTON_PATTERN.pill}
                                variant={BUTTON_VARIANTS.primaryLight}
                                dataTestId={"nextButton"}
                                size={BUTTON_SIZE.lg}
                                disabled={!step2Form.formState.isValid}
                                onClick={handleStep2Next}
                            >
                                <FormattedMessage id="button_review" />
                            </Button>
                        </div>
                    </div>
                }
            />
        </div>
    );
};

export default WithdrawStep2;
