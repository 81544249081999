import React from "react";
import { howToPlayBgFootballField, howToPlayStep5 } from "helpers/IconHelper";
import { cn } from "helpers/cn";
import { FormattedMessage } from "react-intl";
import StepWrapper from "domain/howToPlay/StepWrapper";

export const Step5 = () => {
    return <div className="tw-relative tw-w-full tw-flex">
        <StepBackground />
        <StepWrapper
            number={5}
            title={<FormattedMessage id="how_to_play_step_5_title" />}
            subtitle={<FormattedMessage id="how_to_play_step_5_subtitle" />}
        >
            <img
                src={howToPlayStep5}
                alt="howToPlayStep5"
                className="tw-px-2"
            />
        </StepWrapper>
    </div>;
};

const StepBackground = () => {

    return <img
        alt=""
        src={howToPlayBgFootballField}
        className={cn(
            "tw-absolute tw-w-[calc(100%_+_4.2rem)] tw-max-w-none",
            "-tw-bottom-40 -tw-left-[2.1rem] -tw-right-[2.1rem]"
        )}
    />;
};
