import { deleteUserSubmitUrl, getDeleteUserUrl } from "apis/ApiUrlConfig";
import { ajax } from "apis/ApiUtils";
import { UserId } from "Common";
import { fetchCurrentApLevelList, fetchMyProfile } from "data/api/user/UserApi";
import { CurrentApLevelVo } from "data/vo/user/CurrentApLevelVo";
import { MyProfileVo } from "data/vo/user/MyProfileVo";
import { getUserIdToCurrentLevel } from "domain/ap/ActivityPointHelper";
import { AP_LEVEL } from "types/activityPoint/ApLevel";

export interface UserIdToCurrentApLevel {
    [userId: string]: AP_LEVEL;
}

export class UserRepository {

    async getMyProfile() {
        const myProfileDto = await fetchMyProfile();

        return new MyProfileVo(myProfileDto);
    }

    async getCurrentApLevelList(userIds: UserId[]): Promise<UserIdToCurrentApLevel> {
        if (userIds.length === 0) {
            return {};
        }
        const apLevels = await fetchCurrentApLevelList(userIds);

        return getUserIdToCurrentLevel(apLevels.map((apLevel => new CurrentApLevelVo(apLevel.userId, apLevel.currentLevel))));
    }

    async submitDeleteUser(email: string) {
        await ajax({
            method: "POST",
            url: deleteUserSubmitUrl,
            data: { email: email }
        });
    }

    async deleteUser(code: string) {
        await ajax({
            method: "DELETE",
            url: getDeleteUserUrl(code),
        });
    }
}
