import { fetchValidateSession } from "apis/session/SessionApi";

export class SessionRepository {
    public async validateSession(): Promise<any> {
        // TODO, deving...
        const testValidateSession = localStorage.getItem("test-validate-session");
        if (testValidateSession) {
            return Promise.reject({
                response: {
                    status: 401,
                    message: "Unauthorized"
                }
            });
        }
        
        return await fetchValidateSession();
    }
}
