import { FRIEND_TYPE } from "domain/friend/FriendHelper";
import { UserStatus } from "store/reducer/teamPreview/types";
import { ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";
import { SPORT } from "types/sports/Sport";
import {
    fetchFriendsUrl,
    fetchMyTransferredUrl,
    getAddFriendUrl,
    getCareerStats,
    getFollowUrl,
    getTransferUrl,
    getUnfriendUrl,
    getUserProfileUrl,
    updateMyProfileUrl,
} from "../ApiUrlConfig";
import { ajax } from "../ApiUtils";

export interface FriendDto {
    userId: number;
    avatar: string;
    nickname: string;
    userCode: string;
}

interface FriendsDto {
    friends: FriendDto[];
}

export const fetchFriends = async () => {
    const { data } = await ajax<FriendsDto>({ url: fetchFriendsUrl });

    return data.data;
};

export interface UserProfileDto {
    id: number;
    avatar: string;
    nickname: string;
    description: string;
    walletBalanceCent: number;
    type: FRIEND_TYPE;
    userCode: string;
    hasMobileNumber: boolean;
    status: UserStatus;
}

export const fetchUserProfile = async (userId: number) => {
    const { data } = await ajax<UserProfileDto>({
        url: getUserProfileUrl(userId),
    });

    return data.data;
};

interface UpdateMyProfileProps {
    nickname: string;
    description: string;
}

export const updateMyProfile = async ({
                                          nickname,
                                          description,
                                      }: UpdateMyProfileProps) => {
    await ajax({
        method: "PUT",
        url: updateMyProfileUrl,
        data: { nickname, description },
    });

    return true;
};

export const addFriend = async (userId: number) => {
    await ajax({
        method: "POST",
        url: getAddFriendUrl(userId),
    });
};

export const unfriend = async (userId: number) => {
    await ajax({
        method: "DELETE",
        url: getUnfriendUrl(userId),
    });
};

export interface MyTransferredDto {
    coinCent: number;
    winningCent: number;
    todayCentQuota: number;
}

export const fetchMyTransferred = async () => {
    const { data } = await ajax<MyTransferredDto>({ url: fetchMyTransferredUrl });
    return data.data;
};

export const transfer = async (friendId: number, amountCent: number, note?: string) => {
    await ajax({
        method: "POST",
        url: getTransferUrl(friendId),
        data: {
            cent: amountCent,
            note,
        },
    });

    return true;
};

export interface FollowDto {
    followersCount: number;
    followingCount: number;
}

export const fetchFollow = async (userId: number): Promise<FollowDto> => {
    const { data } = await ajax<FollowDto>({ url: getFollowUrl(userId) });
    return data.data;
};

export interface IStats {
    sport: SPORT;
    entryFeeType: ENTRY_FEE_TYPE_FROM_API;
    topScoreCent: number;
    roiDimi: number;
    prDimi: number;
    isStatsEmpty: boolean;
}

export interface ICareerStatsDto {
    from: number;
    until: number;
    stats: IStats[];
}

export const fetchCareerStats = async () => {
    const { data } = await ajax<ICareerStatsDto>({ url: getCareerStats });
    return data.data;
};

export interface SupportedRegion {
    name: string,
    callingCode: number,
}
