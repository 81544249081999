import React from "react";
import OperationTeamFlow from "../../components/operationTeamFlow/OperationTeamFlow";
import { StartedMatchDetailDataProvider, useStartedMatchDetailData } from "../../providers/MyStartedMatchDataProvider";
import { StartedMatchDetailFlowProvider } from "../../providers/MyStartedMatchFlowProvider";
import MyContestDetail from "./MyContestDetail";

const MyStartedMatchDetailFlow = () => {
    return <StartedMatchDetailDataProvider>
        <StartedMatchDetailFlowProvider>
            <MyStartedMatchDetail />
        </StartedMatchDetailFlowProvider>
    </StartedMatchDetailDataProvider>;
};

const MyStartedMatchDetail = () => {
    const { hasData } = useStartedMatchDetailData();

    if (!hasData) {
        return null;
    }

    return <>
        <MyContestDetail />
        <OperationTeamFlow />
    </>;

};

export default MyStartedMatchDetailFlow;
