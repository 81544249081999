import { cn } from "helpers/cn";
import React from "react";

interface ContentProps {
    title: React.ReactNode;
    children: React.ReactNode;
}

export const Content: React.FC<ContentProps> = ({ title, children }) => {

    return <div className={cn(
        "tw-w-full tw-bg-white tw-p-4 tw-rounded-[0.8rem] tw-shadow-sm",
        "tw-flex tw-flex-col tw-gap-2",
    )}>
        <div className={cn(
            "tw-text-subtitle-1 tw-text-gray-900 tw-font-bold tw-py-1",
            "tw-border-b-[1px] tw-border-gray-100"
        )}>
            {title}
        </div>
        {children}
    </div>;
};
