import { PlayerInfoVo } from "data/vo/player/PlayerInfoVo";
import { Glossary } from "domain/team/playerInfoDialog/gameLog/glossary/Glossary";
import MatchBasketballTabGameLog from "domain/team/playerInfoDialog/gameLog/MatchBasketballTabGameLog";
import { MatchGameLog } from "domain/team/playerInfoDialog/gameLog/MatchGameLog";
import PlayerInfoDialogLoading from "domain/team/playerInfoDialog/loading/PlayerInfoDialogLoading";
import React from "react";
import { SquadPlayer } from "types/player/Player";
import { SPORT } from "types/sports/Sport";

interface PerformanceAndGameLogProps {
    player: SquadPlayer;
    playerInfo: PlayerInfoVo | null | undefined;
}


const MatchGameLogAndGlossary: React.FC<PerformanceAndGameLogProps> = (props) => {
    const { player, playerInfo } = props;

    if (!playerInfo) {
        return <PlayerInfoDialogLoading />;
    }

    return <>
        {
            player.sport === SPORT.BASKETBALL
                ? <MatchBasketballTabGameLog player={player} playerInfo={playerInfo} />
                : <>
                    <MatchGameLog player={player} playerInfo={playerInfo} />
                    <Glossary sport={player.sport} />
                </>
        }
    </>;
};


export default MatchGameLogAndGlossary;
