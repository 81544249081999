import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { Referral } from "domain/me/referral/components/Referral";
import { RefereeList } from "domain/me/referral/components/RefereeList";
import ReferralProvider from "domain/me/referral/ReferralProvider";


const ReferralSwitcher: React.FC = () => {
    return (
        <ReferralProvider>
            <Switch>
                <Route exact path={ROUTER_PATH_CONFIG.me.referral.index}>
                    <Referral />
                </Route>
                <Route exact path={ROUTER_PATH_CONFIG.me.referral.bonus}>
                    <RefereeList />
                </Route>
                <Redirect to={ROUTER_PATH_CONFIG.home} />
            </Switch>
        </ReferralProvider>
    );
};

export default ReferralSwitcher;
