import { UPDATE_COUPON_DATA } from "../../Constants";
import { EnrichedCoupons } from "../../../types/coupon/Coupon";

const init: EnrichedCoupons = {
    unused: [],
    used: [],
    expired: []
};

const CouponState = (prevState = init, action) => {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_COUPON_DATA:
            return { ...payload };
        default:
            return prevState;
    }
};

export default CouponState;