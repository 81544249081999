import { useSelector } from "react-redux";
import { RootStoreState } from "store/reducer";

export const useWithdrawSettings = () => {
    const settingState = useSelector((store: RootStoreState) => store.SettingsState);

    const { transactionFee, minimumWithdrawAmount } = settingState.withdraw;

    return {
        transactionFee: transactionFee,
        minimumWithdrawAmount: minimumWithdrawAmount,
    };
};
