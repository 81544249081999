import React from "react";
import useCurrency from "hooks/useCurrency/useCurrency";
import {
    ExpectedAmountCent,
    WalletHistoryAmountWrapper
} from "domain/wallet/components/walletHistoryRow/WalletHistoryRowStyle";
import { WalletHistoryVo } from "data/vo/wallet/WalletHistoryVo";
import { AmountDisplay } from "domain/wallet/components/walletHistoryRow/components/AmountDisplay";
import { FormattedMessage } from "react-intl";
import { cn } from "helpers/cn";
import { isNegative } from "helpers/number/NumberHelper";
import { Icon } from "helpers/IconHelper";

interface AmountViewProps {
    record: WalletHistoryVo
}

export const AmountView: React.FC<AmountViewProps> = ({ record }) => {
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();

    if (record.isOrderPending) {
        return (
            <WalletHistoryAmountWrapper>
                <AmountDisplay
                    amount={record.amount}
                    negativeClass="tw-text-warning-900"
                    positiveClass="tw-text-tertiary-700"
                />
                <ProcessingLabel />
            </WalletHistoryAmountWrapper>
        );
    }

    if (record.isOrderCancelled) {
        return (
            <WalletHistoryAmountWrapper>
                <AmountDisplay
                    amount={record.amount}
                    negativeClass={record.isWinningType ? "tw-text-error-700" : "tw-text-gray-1000 tw-line-through"}
                    positiveClass={record.isWinningType ? "tw-text-gray-1000" : "tw-text-gray-1000 tw-line-through"}
                />
                {record.isWinningType ? <WinningCancelledLabel amount={record.amount} /> : <CancelledLabel />}
            </WalletHistoryAmountWrapper>
        );
    }

    return (
        <WalletHistoryAmountWrapper>
            {record.hasDifferentExpectedAmountCent &&
                <ExpectedAmountCent>{toWalletNumber(record.expectedAmount)}</ExpectedAmountCent>}
            <AmountDisplay
                amount={record.amount}
                negativeClass="tw-text-error-700"
                positiveClass="twTextGray1000"
            />
        </WalletHistoryAmountWrapper>
    );
};

const ProcessingLabel: React.FC = () => {
    return <span
        className="tw-text-caption-1 text-center tw-rounded-[0.4rem] tw-px-2 tw-py-1 tw-bg-warning-100 tw-text-tertiary-800">
        <FormattedMessage id="label_processing" />
    </span>;
};

const CancelledLabel: React.FC = () => {
    return <span
        className="tw-text-caption-1 text-center tw-rounded-[0.4rem] tw-px-2 tw-py-1 tw-bg-error-50 tw-text-error-900">
        <FormattedMessage id="label_cancelled" />
    </span>;
};

const WinningCancelledLabel: React.FC<{ amount: number }> = ({ amount }) => {
    return <span
        className={cn("tw-bg-grey-100 tw-text-caption-1 tw-text-grey-600 tw-px-2 tw-py-1 ",
            "tw-inline-flex tw-items-center tw-justify-center tw-gap-1 ",
            "tw-rounded-[0.4rem]")}
    >
        {
            isNegative(amount)
                ? <><Icon.Undo className="tw-scale-x-[-1]" /><FormattedMessage id="label_declined" /></>
                : <><Icon.Undo /><FormattedMessage id="label_reversed" /></>
        }
    </span>;
};
