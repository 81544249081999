import { ID } from "Common";
import { INJURY_PLAYING_CHANCE } from "data/vo/player/profile/BasketballPlayerInfoProfileVo";
import { AP_LEVEL } from "types/activityPoint/ApLevel";
import { CONTEST_TYPE_FROM_API, ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";
import { JoinedTeam } from "./types";

export enum NINE_CAT_STAT {
    PTS = "PTS",
    FGE = "FGE",
    FTE = "FTE",
    OR = "OR",
    DR = "DR",
    AST = "AST",
    ST = "ST",
    BLK = "BLK",
    "3PM" = "3PM"
}

export interface NineCatStatsVO {
    key: string;
    statAbbr: NINE_CAT_STAT;
}

export type NineCatAllStatsVO = NineCatStatsVO[]

export interface NineCatPlayerInfoVO {
    id: ID;
    firstName: string;
    middleName: string;
    lastName: string;
    avatar: string;
    roleAbbr: string;
    squadName: string;
    squadLogoUrl: string;
    matchId: string;
}

export interface PlayerStatsSlotCardVO {
    focused?: boolean;
    nineCatStats: NineCatStatsVO;
}

export interface FilledPlayerInfoVO {
    stat: NINE_CAT_STAT;
    playerInfo: NineCatPlayerInfoVO;
}

export interface NineCatPlayerDetailVO extends NineCatPlayerInfoVO, Record<NINE_CAT_STAT, number> {
    injuryPlayingChance: INJURY_PLAYING_CHANCE;
}

export enum CONTEST_CARD_VARIANT {
    MyGame,
    JoinContest
}

export enum CONTEST_ENTRY_TYPE {
    COIN = "coin",
    RUBY = "ruby",
    FREE = "free",
}

export interface NineCatContestVo {
    id: number;
    nineCatId: number;
    code: string;
    type: CONTEST_TYPE_FROM_API;
    entryFeeType: ENTRY_FEE_TYPE_FROM_API;
    entryFeeCent: number;
    entryFee: number;
    firstPrize: number;
    entryLimit: number;
    spots: number;
    myJoinedTeams: JoinedTeam[];
    allowDiscountWithRuby: boolean;
    minApLevel: AP_LEVEL;
    minApLevelWeight: number;
    slipCount: number;
}
