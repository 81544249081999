import React, { useMemo, useState } from "react";
import { FieldErrors, FieldValues, RegisterOptions, UseFormGetValues, UseFormRegister } from "react-hook-form";
import { MdCancel } from "react-icons/md";
import {
    FANTASY_INPUT_STATUS,
    FantasyInputLabel,
    FantasyInputStatusColorMap,
    FantasyInputStyle,
    FantasyInputWrapper
} from "./FantasyFormInputStyle";

interface FantasyFormInputProps {
    title: string,
    name: string,
    type?: string,
    prefixIcon?: React.ReactNode,
    suffixIcon?: React.ReactNode,
    isDisabled?: boolean,
    validator?: RegisterOptions<FieldValues>,
    register: UseFormRegister<FieldValues>,
    getValues: UseFormGetValues<FieldValues>,
    onResetField?: () => void
    errors?: FieldErrors,
    error?: string | null
}


const FantasyFormInput: React.FC<FantasyFormInputProps> = ({
                                                               prefixIcon,
                                                               suffixIcon,
                                                               title,
                                                               name,
                                                               type = "input",
                                                               validator,
                                                               register,
                                                               getValues,
                                                               onResetField,
                                                               errors,
                                                               error,
                                                               isDisabled,
                                                           }) => {
    const [isFocus, setIsFocus] = useState<boolean>(false);

    const status = useMemo<FANTASY_INPUT_STATUS>(() => {
        if (isDisabled) {
            return FANTASY_INPUT_STATUS.DISABLED;
        }

        if (!!errors?.[name]?.message || !!error) {
            return FANTASY_INPUT_STATUS.ERROR;
        }

        if (isFocus) {
            return FANTASY_INPUT_STATUS.FOCUS;
        }

        if (getValues(name)) {
            return FANTASY_INPUT_STATUS.FILL;
        }

        return FANTASY_INPUT_STATUS.DEFAULT;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFocus, isDisabled, errors?.[name]?.message, error, getValues(name)]);

    const {
        borderColor,
        iconColor,
        textColor,
        labelColor,
        bgColor = "bg-light-primary"
    } = useMemo(() => FantasyInputStatusColorMap[status], [status]);

    const { onBlur, ...props } = useMemo(() => register(name, validator), [name, register, validator]);

    const handleFocus = () => {
        setIsFocus(true);
    };

    const handleOnBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
        setIsFocus(false);
        await onBlur(event);
    };

    const renderSuffixIcon = () => {
        if (suffixIcon) {
            return suffixIcon;
        }

        if (status === FANTASY_INPUT_STATUS.ERROR && onResetField) {
            return <MdCancel size="1.6rem" className="clickable" onClick={onResetField} />;
        }
    };

    return (
        <div>
            <FantasyInputWrapper $borderColor={borderColor} $iconColor={iconColor} $bgColor={bgColor}
                                 $isDisabled={isDisabled}>
                <div className="flex-shrink-0">
                    {prefixIcon}
                </div>
                <div className="position-relative w-100">
                    <FantasyInputStyle type={type}
                                       $textColor={textColor}
                                       $bgColor={bgColor}
                                       $isDisabled={isDisabled}
                                       onFocus={handleFocus}
                                       onBlur={handleOnBlur}
                                       disabled={isDisabled}
                                       data-testid={`fantasy-input-${name}`}
                                       {...props}
                    />
                    <FantasyInputLabel $labelColor={labelColor}>
                        {
                            // todo 處理兩行錯誤在 label 上的樣式異常，暫時性的解法，等與設計討論後，與 APP 一起調整
                            // todo 這邊的 errors type 有問題，等待修正
                            name === "referralCode" ? title : (errors?.[name]?.message as string) || error || title
                        }
                    </FantasyInputLabel>
                </div>
                <div className="flex-shrink-0">
                    {renderSuffixIcon()}
                </div>
            </FantasyInputWrapper>
            {
                // todo 處理兩行錯誤在 label 上的樣式異常，暫時性的解法，等與設計討論後，與 APP 一起調整
                // todo 這邊的 errors type 有問題，等待修正
                name === "referralCode" && (errors?.[name]?.message || error) &&
                <div className="text-sys-danger-1 fz-sf-regular-footnote mt-2 text-start ms-4">
                    {(errors?.[name]?.message as string) || error}
                </div>
            }
        </div>
    );
};

export default FantasyFormInput;
