import { ErrorMessage } from "components/error/ErrorMessage";
import { cn } from "helpers/cn";
import React, { ClipboardEvent, KeyboardEvent, useEffect, useRef, useState } from "react";

interface OTPInputProps {
    onChangeOtp: (otp: string) => void;
    errorMessage?: string;
}

const OtpInput: React.FC<OTPInputProps> = ({
                                               onChangeOtp,
                                               errorMessage
                                           }) => {
    const [otp, setOtp] = useState<string[]>(Array(6).fill(""));
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    const isInputValid = (char: string) => !isNaN(Number(char)) && char !== " ";

    const handleChange = (element: HTMLInputElement, index: number) => {
        if (!isInputValid(element.value)) return false;

        setOtp(otp.map((current, idx) => (idx === index ? element.value : current)));

        if (element.value !== "") {
            const nextElement = inputRefs.current[index + 1];
            if (nextElement) {
                nextElement.focus();
            }
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key !== "Backspace") return;

        if (otp[index] === "") {
            const prevElement = inputRefs.current[index - 1];
            if (prevElement) {
                prevElement.focus();
            }
        }
    };


    const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault();
        const pastedData = event.clipboardData.getData("text");
        const pastedArray = pastedData.slice(0, 6).split("");

        if (pastedArray.length === 6 && pastedArray.every(char => isInputValid(char))) {
            setOtp(pastedArray);
            inputRefs.current[5]?.focus();
        }
    };

    useEffect(() => {
        onChangeOtp(otp.join(""));
    }, [otp]);

    return (
        <div className="tw-w-full">

            <div className="tw-flex tw-justify-center tw-items-center tw-gap-3">
                {otp.map((data, index) => (
                    <div className="tw-flex-1" key={index}>
                        <input
                            key={index}
                            type="text"
                            maxLength={1}
                            value={data}
                            ref={(input) => (inputRefs.current[index] = input)}
                            onChange={(e) => handleChange(e.target, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            onPaste={handlePaste}
                            className={cn("tw-w-full tw-aspect-square tw-border-[1px] tw-border-transparent tw-rounded-lg tw-text-center tw-text-h3 tw-text-grey-600 tw-font-medium focus:tw-outline-none", {
                                "tw-border-error-700": errorMessage,
                            })}
                        />
                    </div>
                ))}
            </div>

            <ErrorMessage errorMessage={errorMessage} />
        </div>
    );
};

export default OtpInput;
