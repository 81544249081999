import Countdown from "components/countdown/Countdown";
import MyNineCatDetailSkeleton from "components/skeleton/MyNineCatDetailSkeleton";
import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { MY_MATCH_TAB } from "domain/match/components/providers/MyMatchProvider";
import { ContestDetailInfos } from "domain/slate/components/ContestDetailInfos";
import MySlateNavTitle from "domain/slate/components/MySlateNavTitle";
import { NineCatTeamSelector } from "domain/slate/components/NineCatTeamSelector";
import TopNavbar, { TopNavbarType } from "domain/slate/components/TopNavbar";
import { SLATE_STATUS_FROM_API } from "domain/slate/data/dto";
import { useMySlate } from "domain/slate/providers/MySlateProvider";
import { Icon } from "helpers/IconHelper";
import useKycRestriction from "hooks/useCheckKycRestriction/useKycRestriction";
import useFantasyHistory from "hooks/useFantasyHistory";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { CONTEST_STATUS_FROM_API } from "types/contest/Contest";

interface MySlateDetailProps {
    onTogglePage: () => void;
}

const MyNineCatDetail: React.FC<MySlateDetailProps> = ({ onTogglePage }) => {
    const history = useFantasyHistory();
    const { nineCatId } = useParams();
    const isRestrictedByKyc = useKycRestriction();
    const {
        nineCatDetail,
        myTeams,
        contestDetail,
        isLoading,
        handleTimeoutRefetch,
        contestEntry
    } = useMySlate();

    const renderStatus = (slateStatus: SLATE_STATUS_FROM_API, slateStartAt?: number) => {
        if (slateStatus === SLATE_STATUS_FROM_API.UPCOMING && slateStartAt! > Date.now()) {
            return <div
                className="tw-flex tw-justify-center tw-items-center tw-gap-1 tw-text-secondary-150 tw-text-caption-1 tw-font-bold"
                data-testid="slate-upcoming"
            >
                <Icon.Clock />
                <Countdown
                    finishAt={slateStartAt!}
                    handleTimeout={handleTimeoutRefetch}
                />
            </div>;
        }

        if (slateStatus === SLATE_STATUS_FROM_API.LIVE) {
            return <div
                className="tw-flex tw-justify-center tw-items-center tw-gap-1 tw-text-warning-400 tw-text-caption-1 tw-font-bold"
                data-testid="slate-live"
            >
                <Icon.Live />
                <span>
                    <FormattedMessage id="label_live_matches" />
                </span>
            </div>;
        }

        if (slateStatus === SLATE_STATUS_FROM_API.COMPLETED || slateStatus === SLATE_STATUS_FROM_API.PROCESSING) {
            return <div
                className="text-center tw-text-tertiary-150 tw-text-caption-1 tw-font-bold"
                data-testid="slate-completed"
            >
                <span>
                    <FormattedMessage id="label_completed" />
                </span>
            </div>;
        }
    };

    if (isLoading) {
        return <MyNineCatDetailSkeleton />;
    }

    return (
        <div className="tw-bg-grey-50 tw-h-full">
            <TopNavbar
                type={TopNavbarType.DARK}
                onLeaveClick={() => {
                    history.push(ROUTER_PATH_CONFIG.myMatch.index, {
                        myMatchTab: MY_MATCH_TAB.NINE_CAT,
                    });
                }}
                title={
                    <MySlateNavTitle
                        contestDetail={contestDetail}
                        contestEntry={contestEntry}
                    />
                }
                slate={nineCatDetail}
            />
            <div
                className="tw-bg-primary-900 tw-pt-2 tw-pb-4"
            >
                {
                    contestDetail?.status === CONTEST_STATUS_FROM_API.CANCELLED
                        ? (<div
                            className="text-center tw-text-grey-300 tw-text-caption-1 tw-font-bold"
                            data-testid="contest-cancelled"
                        >
                            <span>
                                <FormattedMessage id="label_cancelled" />
                            </span>
                        </div>)
                        : renderStatus(nineCatDetail?.slateStatus!, nineCatDetail?.startsAt)
                }
            </div>


            <div className="tw-text-center -tw-mt-2.5">
                <div
                    className="tw-inline-block tw-bg-grey-100 tw-rounded-2xl tw-px-4 tw-py-2 tw-text-left"
                >
                    <ContestDetailInfos
                        classNames="tw-pt-0 tw-pl-0"
                        firstPrize={contestDetail?.firstPrize || 0}
                        hasJoinedTeams={false}
                        entryLimit={contestDetail?.entryLimit || 0}
                        spots={contestDetail?.spots || 0}
                        rubyEligible={contestDetail?.allowDiscountWithRuby || false}
                    />
                </div>
            </div>
            <div className="tw-p-4 tw-space-y-4">
                <div className="tw-space-y-2">
                    <div className="tw-text-grey-1000 tw-text-subtitle-1 tw-font-bold">
                        <FormattedMessage id="contest_detail_page_leaderboard_label_your_teams" />
                    </div>
                    {
                        myTeams?.map((team) => (
                            <NineCatTeamSelector
                                hasCheckbox={false}
                                checked
                                key={team.id}
                                team={team}
                            />
                        ))
                    }

                    {
                        nineCatDetail?.slateStatus === SLATE_STATUS_FROM_API.UPCOMING && (
                            <div className="tw-px-3 tw-py-1.5">
                                <div
                                    className="tw-flex tw-justify-center tw-items-center tw-gap-1 tw-text-grey-1000 tw-text-caption-2 tw-cursor-pointer tw-underline"
                                    onClick={async () => {
                                        if (await isRestrictedByKyc()) {
                                            return;
                                        }
                                        history.push(`/slate/${nineCatId}`);
                                    }}
                                >
                                    <Icon.Plus />
                                    <FormattedMessage id="label_create_a_new_team_style_2" />
                                </div>
                            </div>
                        )
                    }
                </div>

                <Button
                    dataTestId={"check-leaderboard-button"}
                    prefixIcon={Icon.ChampionIcon}
                    className="tw-w-full"
                    variant={NEW_BUTTON_VARIANTS.primaryDark}
                    size={BUTTON_SIZE.md}
                    pattern={BUTTON_PATTERN.pill}
                    onClick={onTogglePage}
                    disabled={contestDetail?.status === CONTEST_STATUS_FROM_API.CANCELLED}
                >
                    <FormattedMessage id="my_contest_page_button_check_leaderboard" />
                </Button>
            </div>
        </div>
    );
};

export default MyNineCatDetail;
