import { fetchRewardListUrl, fetchRewardOrdersUrl, getBuyRewardUrl, getRewardUrl } from "../ApiUrlConfig";
import { ajax } from "../ApiUtils";

export const fetchRewardList = () => {
    return new Promise((resolve, reject) => {
        ajax({ url: fetchRewardListUrl })
            .then(({ data }) => resolve(data.data))
            .catch(error => reject(error));
    });
};

export const fetchReward = rewardId => {
    return new Promise((resolve, reject) => {
        ajax({ url: getRewardUrl(rewardId) })
            .then(({ data }) => resolve(data.data))
            .catch(error => reject(error));
    });
};

export const buyReward = rewardId => {
    return new Promise((resolve, reject) => {
        ajax({
            method: "POST",
            url: getBuyRewardUrl(rewardId)
        })
            .then(() => resolve())
            .catch(error => reject(error));
    });
};

export const fetchRewardOrders = () => {
    return ajax({ url: fetchRewardOrdersUrl })
        .then(({ data }) => data.data);
};
