import Navbar from "../../components/container/navbar/Navbar";
import { FormattedMessage } from "react-intl";
import React from "react";
import { WithdrawStep1 } from "domain/withdraw/WithdrawStep1";
import WithdrawStep2 from "./WithdrawStep2";
import { useWithdrawContext, withWithdrawProvider } from "domain/withdraw/WithdrawProvider";
import { WithdrawStep4Container } from "domain/withdraw/WithdrawStep4Container";
import { WithdrawStep3 } from "domain/withdraw/WithdrawStep3";
import { Icon } from "helpers/IconHelper";
import { WITHDRAW_STEP } from "domain/withdraw/WithdrawCurrentStepVo";

const stepComponentMap = {
    [WITHDRAW_STEP.step1]: WithdrawStep1,
    [WITHDRAW_STEP.step2]: WithdrawStep2,
    [WITHDRAW_STEP.step3]: WithdrawStep3,
    [WITHDRAW_STEP.step4]: WithdrawStep4Container,
};

const PaymongoWinningWithdraw = () => {
    const { handleGoBack, currentStep } = useWithdrawContext();

    const CurrentStepComponent = stepComponentMap[currentStep.value];

    return (
        <div className="tw-bg-white tw-h-full">
            <Navbar
                isTransparent={true}
                isHeaderLine={false}
                className={"tw-bg-grey-50"}
                startIcon={
                    <Icon.ArrowLeft
                        className={"tw-cursor-pointer"}
                        size={"2.4rem"}
                        onClick={handleGoBack} />
                }
                navbarCenter={
                    currentStep.isFinalStep() ? <div className={"tw-min-h-8"} /> : (
                        <div
                            className="tw-flex tw-justify-center tw-items-center tw-h-full tw-text-h5 tw-font-bold">
                            <FormattedMessage id="wallet_view_button_withdraw" />
                        </div>
                    )
                }
                onNavbarBackClick={handleGoBack}
            />
            <CurrentStepComponent />
        </div>
    );
};
export default withWithdrawProvider(PaymongoWinningWithdraw);
