import { getSportKey } from "helpers/SportHelper";
import { UPDATE_CURRENT_SPORT } from "store/Constants";
import { SPORT } from "types/sports/Sport";

// TODO: Implement the SportState reducer
//  move SportProvider into SportState
//  work on progress
interface SportStateData {
    currentSport: SPORT;
}

interface SportStateAction {
    type: string,
    payload: Partial<SportStateData>
}

const initState: SportStateData = {
    currentSport: getSportKey()
};

const SportState = (prevState = initState, action: SportStateAction) => {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_CURRENT_SPORT:
            return {
                ...prevState,
                currentSport: payload,
            };
        default:
            return prevState;
    }
};

export default SportState;
