import { ajax } from "../ApiUtils";
import { fetchAdUrl } from "../ApiUrlConfig";
import { GeneralAd } from "../../types/ads/GeneralAd";
import { SPORT } from "../../types/sports/Sport";


export type FetchAdResponse = GeneralAd[]

export class GeneralAdListVO {
    id: number;
    imageUrl: string;
    linkUrl: string;
    openInNewWindow: boolean;
    title: string;

    constructor(data: GeneralAd) {
        this.id = data.id;
        this.linkUrl = data.linkUrl;
        this.imageUrl = data.imageUrl;
        this.openInNewWindow = data.openInNewWindow;
        this.title = data.title;
    }

}

export const fetchAd = (sport: SPORT, language: string): Promise<FetchAdResponse> => {
    return ajax<FetchAdResponse>({ url: fetchAdUrl(sport, language.toUpperCase()) })
        .then((res) => res.data.data)
        .catch(error => {
            throw error;
        });
};

export class AdRepository {
    async getAds(sport: SPORT, language: string) {
        const generalAds = await fetchAd(sport, language);
        return generalAds.map(ad => new GeneralAdListVO(ad));
    }
}
