import { SportRoleVO } from "./Sport";

export type CRICKET_KEY = "WICKET_KEEPER" | "BATSMAN" | "ALL_ROUNDER" | "BOWLER";

export const CRICKET_ROLE: SportRoleVO<CRICKET_KEY> = {
    WICKET_KEEPER: {
        abbr: "WK",
        apiValue: "keeper"
    },
    BATSMAN: {
        abbr: "BAT",
        apiValue: "batsman"
    },
    ALL_ROUNDER: {
        abbr: "AR",
        apiValue: "all_rounder"
    },
    BOWLER: {
        abbr: "BOWL",
        apiValue: "bowler"
    }
};