import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";
import { pixelToRem } from "helpers/number/NumberHelper";
import React from "react";
import { FormattedMessage } from "react-intl";
import Button from "../../../../designToken/button/Button";
import { useFindElementById } from "./hooks/useFindElementById";
import { Highlight, Position, TutorialBackground } from "./TutorialStyle";
import { HIGHLIGHT_PATTERN, TutorialStepProps } from "./type";

export const TutorialStep3: React.FC<TutorialStepProps> = ({ handleNextStep }) => {
    const taskButtonElement = useFindElementById("task-button");

    if (!taskButtonElement) {
        return <TutorialBackground />;
    }

    const offset = findOffset(taskButtonElement);

    const positionTop = pixelToRem(offset.top - 11);
    const positionLeft = pixelToRem(offset.left - 40);
    const highlightHeight = 6;

    return <>
        <TutorialBackground>
            <Position top={positionTop} left={positionLeft}>
                <Highlight width={14} height={highlightHeight} pattern={HIGHLIGHT_PATTERN.pill} />
            </Position>
        </TutorialBackground>
        <Position top={positionTop + highlightHeight} left={positionLeft} zIndex={99999}>
            <div className="text-dark-primary fz-sf-regular-body-1 mt-4">
                <FormattedMessage id="daily_task_tutorial_step_3" />
            </div>
            <Button
                variant={BUTTON_VARIANTS.outlineLight}
                pattern={BUTTON_PATTERN.pill}
                size={BUTTON_SIZE.sm}
                className="mt-4"
                onClick={handleNextStep}
            >
                <FormattedMessage id="button_done" />
            </Button>
        </Position>
    </>;
};

const findOffset = (element: HTMLElement): { top: number, left: number } => {
    if (!element) {
        return { top: 0, left: 0 };
    }

    const { top, left } = findOffset(element.offsetParent as HTMLElement);

    return {
        top: top + element.offsetTop,
        left: left + element.offsetLeft
    };
};
