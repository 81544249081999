import { DoubledLinkedMap } from "./DoubledLinkedMap";

export class StatToPlayerIdMap {
    private doubledLinkedMap: DoubledLinkedMap<string, string>;

    constructor(map: Record<string, string>) {
        this.doubledLinkedMap = new DoubledLinkedMap<string, string>(map);
    }

    getMap() {
        return this.doubledLinkedMap.getMap();
    }

    exchangePlayerId(prevPlayerId: string, nextPlayerId: string) {
        this.doubledLinkedMap.exchangeVals(prevPlayerId, nextPlayerId);
        return this;
    }

    exchangeStat(prevStat: string, nextStat: string) {
        this.doubledLinkedMap.exchange(prevStat, nextStat);
        return this;
    }

    exchangeStatByPlayerId(focusedStat: string, nextPlayerId: string) {
        this.doubledLinkedMap.exchangeKeyByVal(focusedStat, nextPlayerId);
        return this;
    }

    exchangePlayerIdByStat(focusedPlayerId: string, nextStat: string
    ) {
        this.doubledLinkedMap.exchangeValByKey(focusedPlayerId, nextStat);
        return this;
    }

    get(statOrPlayerId: string | undefined) {
        return this.doubledLinkedMap.get(statOrPlayerId);
    }
}
