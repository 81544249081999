import { ApiExecuteContext } from "apis/ApiContext";
import { fetchPlayerStats } from "apis/match/MatchApi";
import { fetchSquad } from "apis/player/PlayerApi";
import clsx from "clsx";
import { StartedMatchEmptyPlayerStats } from "components/empty/StartedMatchEmptyPlayerStats";
import PlayerAvatar from "components/image/PlayerAvatar";
import { getPlayersWithWeightedPointCent } from "domain/match/components/stats/PlayerStatsHelper";
import {
    CAndVcIcon,
    CaptainDot,
    MyTeam,
    MyTeams,
    PlayerImageWrapper,
    PlayerInfo,
    PlayerInfoWrapper,
    PlayerName,
    PlayerNameTd,
    PlayerRole,
    PlayerStatsWrapper,
    SortingIcon,
    SpecialPlayerDotWrapper,
    StatsHeader,
    StatsTable,
    StatsTd,
    StatsTh,
    SymbolDotWrapper,
    SymbolText,
    SymbolWrapper,
    Title,
    TitleRow,
    ViceCaptainDot
} from "domain/match/components/stats/PlayerStatsStyle";
import { SpinnerWrapper, STICK_TOP, StickySection } from "domain/match/pages/started/MyContestDetailStyle";
import { useStartedMatchDetailData } from "domain/match/providers/MyStartedMatchDataProvider";
import { useStartedMatchDetailFlow } from "domain/match/providers/MyStartedMatchFlowProvider";
import { getSportConfig } from "domain/SportConfig";
import usePlayerInfoDialog from "domain/team/playerInfoDialog/PlayerInfoDialog";
import { PlayerInfoDialogFooterType } from "domain/team/playerInfoDialog/PlayerInfoDialogFooter";
import { PlayerInfoDialogHeaderType } from "domain/team/playerInfoDialog/PlayerInfoDialogHeader";
import { sortDownIcon, sortUpIcon } from "helpers/IconHelper";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import { DESC, sortByIdAsc, sortByKeyAndName } from "helpers/SortingHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import useSort from "hooks/useSort/useSort";
import _ from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { MatchVO } from "types/match/Match";
import { StatsPlayer } from "types/player/Player";
import { SPORT } from "types/sports/Sport";
import { MyTeamVO } from "types/team/MyTeam";

const thName = {
    points: {
        key: "point",
        text: <FormattedMessage id="label_score" />
    },
    select: {
        key: "playerSelectionRate",
        text: "%Sel"
    }
};

const SpecialPlayerDotFactory: React.FC<{
    player: StatsPlayer
}> = ({ player }) => {
    if (player.isCaptain) {
        return <SpecialPlayerDotWrapper>
            <CaptainDot />
        </SpecialPlayerDotWrapper>;
    }

    if (player.isViceCaptain) {
        return <SpecialPlayerDotWrapper>
            <ViceCaptainDot />
        </SpecialPlayerDotWrapper>;
    }

    return null;
};

interface PlayerStatsContentProps {
    myTeams: MyTeamVO[]
    allPlayers: StatsPlayer[]
    sport: SPORT,
    match: MatchVO
}

const PlayerStatsContent: React.FC<PlayerStatsContentProps> = ({ myTeams, allPlayers, sport, match }) => {
    const { sortingDirection, sortKey, handleSort } = useSort(thName.points.key);

    const { getRoleAbbr } = getSportConfig(sport).team;

    const [selectedTeam, setSelectedTeam] = useState(myTeams[0]);

    const playersWithWeightedPointCent = getPlayersWithWeightedPointCent(allPlayers, selectedTeam);

    const sortedAllPlayers = sortByKeyAndName(playersWithWeightedPointCent, sortKey, sortingDirection);

    const selectedTeamPlayerIds = selectedTeam.players.map(player => player.id);

    const {
        PlayerInfoDialog,
        handlePlayerInfoDialogOpen
    } = usePlayerInfoDialog(PlayerInfoDialogHeaderType.POINT, PlayerInfoDialogFooterType.ONLY_CLOSE);

    const handleTeamClick = (id: number) => {
        setSelectedTeam(_.find(myTeams, { id })!);
    };

    return <>
        <Card className="rounded-0 border-0">
            <StickySection $top={STICK_TOP}>
                <StatsHeader>
                    <TitleRow>
                        <Title>
                            <FormattedMessage id="my_matches_page_player_stats_label_stats_at_match" />
                        </Title>
                        <CAndVcIcon>
                            <SymbolWrapper>
                                <SymbolDotWrapper><CaptainDot /></SymbolDotWrapper>
                                <SymbolText>C</SymbolText>
                            </SymbolWrapper>
                            <SymbolWrapper>
                                <SymbolDotWrapper><ViceCaptainDot /></SymbolDotWrapper>
                                <SymbolText>VC</SymbolText>
                            </SymbolWrapper>
                        </CAndVcIcon>
                    </TitleRow>
                    <MyTeams>
                        {
                            myTeams.map((myTeam) =>
                                <MyTeam key={myTeam.id}
                                        onClick={() => handleTeamClick(myTeam.id)}
                                        className={clsx({
                                            "tw-border-secondary-600 tw-bg-secondary-600 tw-text-grey-0": selectedTeam.id === myTeam.id,
                                            "tw-border-primary-300 tw-bg-transparent tw-text-primary-300": selectedTeam.id !== myTeam.id
                                        })}
                                >
                                    {myTeam.name} ({divideByOneHundred(myTeam.totalScoreCent)} Pts)
                                </MyTeam>
                            )
                        }
                    </MyTeams>
                </StatsHeader>
                <StatsTable className="border-top border-bottom border-light-primary">
                    <thead className="bg-gray-1">
                    <tr>
                        <StatsTh><FormattedMessage id="label_players" /></StatsTh>
                        {
                            Object.values(thName).map(({ key, text }) => {
                                    const active = key === sortKey;

                                    return <StatsTh key={key}
                                                    className={clsx({
                                                        "tw-text-secondary-600": active,
                                                        "tw-text-system-labelLightSecondary": !active,
                                                    })}
                                                    onClick={() => handleSort(key)}>
                                        {text}
                                        <SortingIcon className={active ? "visible" : "invisible"}
                                                     $bgImg={sortingDirection === DESC ? sortDownIcon : sortUpIcon} />
                                    </StatsTh>;
                                }
                            )
                        }
                    </tr>
                    </thead>
                </StatsTable>
            </StickySection>

            <StatsTable>
                <tbody>

                {
                    sortedAllPlayers.map(player => {
                            return <tr key={player.id}
                                       className={clsx("clickable", {
                                           "tw-bg-secondary-150": selectedTeamPlayerIds?.includes(player.id),
                                           "tw-bg-system-labelDarkPrimary": !selectedTeamPlayerIds?.includes(player.id),
                                       })}
                                       onClick={() => {handlePlayerInfoDialogOpen(player);}}>
                                <PlayerNameTd>
                                    <PlayerInfoWrapper>
                                        <PlayerImageWrapper>
                                            <SpecialPlayerDotFactory player={player} />
                                            <PlayerAvatar id={player.id} url={player.avatar} />
                                        </PlayerImageWrapper>
                                        <PlayerInfo>
                                            <PlayerName>{player.name}</PlayerName>
                                            <PlayerRole>{player.squadCode} - {getRoleAbbr(player.role)}</PlayerRole>
                                        </PlayerInfo>
                                    </PlayerInfoWrapper>
                                </PlayerNameTd>
                                <StatsTd>{player.point}</StatsTd>
                                <StatsTd>{player.playerSelectionRatePercent}</StatsTd>
                            </tr>;
                        }
                    )
                }
                </tbody>
            </StatsTable>
        </Card>
        <PlayerInfoDialog match={match} players={sortedAllPlayers} myTeams={myTeams} />
    </>;
};

const PlayerStatsLayout = ({ isLoading, sortedMyTeams, playerStats, sport, match }) => {
    if (isLoading) {
        return <SpinnerWrapper>
            <Spinner animation="border" />
        </SpinnerWrapper>;
    }

    if (_.isEmpty(playerStats)) {
        return <StartedMatchEmptyPlayerStats />;
    }

    return <PlayerStatsContent myTeams={sortedMyTeams} allPlayers={playerStats} sport={sport} match={match} />;
};

const PlayerStats = () => {
    const { sport } = useStartedMatchDetailFlow();
    const startedMatchDetailData = useStartedMatchDetailData();
    const match = startedMatchDetailData.match!;
    const myTeams = startedMatchDetailData.myTeams!;

    const history = useFantasyHistory();
    const apiExecutor = useContext(ApiExecuteContext);
    const [playerStats, setPlayerStats] = useState<StatsPlayer[]>([]);
    const [isLoading, setLoading] = useState(false);

    const sortedMyTeams = useMemo(() => sortByIdAsc(myTeams), [myTeams]);


    useEffect(() => {
        setLoading(true);

        apiExecutor(
            Promise.all([
                fetchSquad(sport, match.matchId),
                fetchPlayerStats(sport, match.matchId)
            ]),
            {
                onSuccess: ([squads, stats]) => {
                    const playersWithStats = StatsPlayer.fromSquadAllJson(squads, stats, match, sport);
                    setPlayerStats(playersWithStats);
                },
                onFinally: () => {
                    setLoading(false);
                }
            }
        );
    }, [apiExecutor, sport, history, match]);

    return <PlayerStatsWrapper>
        <PlayerStatsLayout isLoading={isLoading}
                           sortedMyTeams={sortedMyTeams}
                           playerStats={playerStats}
                           sport={sport}
                           match={match} />
    </PlayerStatsWrapper>;
};

export default React.memo(PlayerStats);
