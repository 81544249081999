import { CreateNineCatTeamProvider } from "domain/slate/providers/CreateNineCatTeamProvider";
import { NineCatProvider, useNineCatContext } from "domain/slate/providers/NineCatProvider";
import React from "react";

function NineCatPage() {
    const { currentPage: Page, } = useNineCatContext();

    return <Page />;
}

export default () => {
    return (
        <NineCatProvider>
            <CreateNineCatTeamProvider>
                <NineCatPage />
            </CreateNineCatTeamProvider>
        </NineCatProvider>
    );
};
