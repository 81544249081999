import BasketballNewsEmpty from "components/basketballPlayer/BasketballNewsEmpty";
import BasketballPlayerNews from "components/basketballPlayer/BasketballPlayerNews";
import {
    BasketballPlayerNewsProvider,
    useBasketballPlayerNews
} from "domain/team/playerInfoDialog/news/BasketballPlayerNewsProvider";
import React from "react";

const BasketballPlayerNewsList = () => {

    const { newsList } = useBasketballPlayerNews();

    if (newsList.length === 0) {
        return <BasketballNewsEmpty />;
    }

    return <>
        {
            newsList.map((news, index) => (
                <BasketballPlayerNews key={index} {...news} offset />
            ))
        }
    </>;
};

export const BasketballPlayerNewsWithProvider = ({ playerId }) => {
    return <BasketballPlayerNewsProvider playerId={playerId}>
        <BasketballPlayerNewsList />
    </BasketballPlayerNewsProvider>;
};
