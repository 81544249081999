import React from "react";
import { useTaskProvider } from "../../../TaskProvider";
import Button from "../../../../../designToken/button/Button";
import { FormattedMessage } from "react-intl";
import { ButtonFactoryProps } from "../DailyTaskCard";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";


export const WatchAdButtonFactory: React.FC<ButtonFactoryProps> = ({
                                                                       taskInfo,
                                                                       apLevel,
                                                                       taskProgress
                                                                   }) => {
    const { apOverview, handleAppDownloadDialogOpen } = useTaskProvider();

    const handleClick = () => {
        handleAppDownloadDialogOpen();
    };

    if (!taskProgress || !taskInfo) {
        return null;
    }

    if (!apOverview || apOverview.currentLevel !== apLevel) {
        return <Button
            className="position-relative text-nowrap px-0"
            variant={BUTTON_VARIANTS.success}
            pattern={BUTTON_PATTERN.pill}
            size={BUTTON_SIZE.md}
            disabled
            id="task-button"
            dataTestId={`task-button-${apLevel}`}
        >
            <FormattedMessage id="daily_task_item_button_watch_ad" /> ({taskProgress.claimed}/{taskInfo.maxClaimedLimit})
        </Button>;
    }

    return <Button
        className="position-relative text-nowrap px-0"
        variant={BUTTON_VARIANTS.success}
        pattern={BUTTON_PATTERN.pill}
        size={BUTTON_SIZE.md}
        onClick={handleClick}
        id="task-button"
        dataTestId={`task-button-${apLevel}`}
    >
        <FormattedMessage id="daily_task_item_button_watch_ad" /> ({taskProgress.claimed}/{taskInfo.maxClaimedLimit})
    </Button>;
};
