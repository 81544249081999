import { apLevelConfig } from "domain/ap/ActivityPointHelper";
import { firstIcon, multipleEntryIcon, oneTeamIcon, singleEntryIcon, vipIcon, winRateIcon, } from "helpers/IconHelper";
import { isMatchJoinable } from "helpers/match/MatchHelper";
import { multiplyByOneHundred } from "helpers/number/NumberHelper";
import { floor, min } from "lodash";
import { ReactNode } from "react";
import { MdDiamond, MdGroupAdd } from "react-icons/md";
import { CONTEST_STATUS_FROM_API, CONTEST_TYPE_FROM_API, } from "types/contest/Contest";
import { shouldContestShowApLevelIcon, } from "./ApLevelHelper";

const INSUFFICIENT_DEPOSIT = "INSUFFICIENT_DEPOSIT";

export const getWinRate = (prizeTeams: number, spots: number) =>
    floor(multiplyByOneHundred(prizeTeams / spots), 1);

export const isContestCancelled = (contestStatus: CONTEST_STATUS_FROM_API) => CONTEST_STATUS_FROM_API.CANCELLED === contestStatus;
export const isContestJoinable = (contestStatus: CONTEST_STATUS_FROM_API) => CONTEST_STATUS_FROM_API.PUBLISHED === contestStatus;
export const isSettingEmpty = (SettingState) => !SettingState?.status;

export const isSpotsFull = (spots: number, teams: number) => spots <= teams;

export const checkIsContestJoinable = ({
                                           SettingsState,
                                           spots,
                                           teams,
                                           allowMultiple,
                                           myJoinedTeamsCount,
                                       }) => {
    if (isSettingEmpty(SettingsState)) return false;
    if (isSpotsFull(spots, teams)) return false;

    const userMaxEntries = getMaxUserEntries({
        allowMultiple,
        spots,
        spotsToMaxEntries: SettingsState.contest.spotsToMaxEntries,
        defaultMaxEntries: SettingsState.contest.defaultMaxEntries,
    });

    return myJoinedTeamsCount < userMaxEntries;
};

export const getMaxUserEntries = ({
                                      spotsToMaxEntries,
                                      defaultMaxEntries,
                                      allowMultiple,
                                      spots,
                                  }) => {
    if (allowMultiple) {
        return (
            min(
                Object.entries(spotsToMaxEntries)
                    .filter(([spotsUpperBound]) => spots <= parseInt(spotsUpperBound))
                    .map(([, maxEntries]) => maxEntries),
            ) || defaultMaxEntries
        );
    } else {
        return 1;
    }
};
export const isPracticeContest = (contestType: CONTEST_TYPE_FROM_API | string) =>
    CONTEST_TYPE_FROM_API.PRACTICE === contestType;

export const isPromotionalContest = (contestType: CONTEST_TYPE_FROM_API | string) =>
    CONTEST_TYPE_FROM_API.PROMOTIONAL === contestType;

export const isInsufficientDepositError = (error) =>
    error?.response?.status === 400 &&
    error?.response?.data?.errorCode === INSUFFICIENT_DEPOSIT;

const getEntryIcon = ({
                          contest,
                          spotsToMaxEntries,
                          defaultMaxEntries,
                          formattedMessage,
                      }) => {
    const { allowMultiple, spots } = contest;

    const maxEntries = getMaxUserEntries({
        spotsToMaxEntries,
        defaultMaxEntries,
        allowMultiple,
        spots,
    });

    const tooltipText = formattedMessage(
        { id: "contest_item_view_label_entry" },
        { entries: maxEntries },
    );

    if (allowMultiple) {
        return {
            bgImg: multipleEntryIcon,
            value: formattedMessage(
                { id: "contest_card_label_upto" },
                { entries: maxEntries },
            ),
            tooltipText,
        };
    }

    return {
        bgImg: singleEntryIcon,
        value: formattedMessage({ id: "contest_card_label_single" }),
        tooltipText,
    };
};

export interface TagVO {
    bgImg: string | ReactNode;
    value: string;
    tooltipText: string;
}

function isPrizeHunter(contestType: CONTEST_TYPE_FROM_API) {
    return contestType === CONTEST_TYPE_FROM_API.PRIZE_HUNTER;
}

export function CreateContestCardTags(isB2C, formattedMessage, formatNumber) {
    const tagList = [
        {
            condition: ({ contest }) => !isPracticeContest(contest.type),
            getTag: ({ contest: { firstPrizeCent } }) => ({
                bgImg: firstIcon,
                value: `${formatNumber(firstPrizeCent)}`,
                tooltipText: formattedMessage(
                    { id: "contest_item_view_label_first_prize" },
                    { prize: formatNumber(firstPrizeCent) },
                ),
            }),
        },
        {
            condition: ({ contest }) => isPracticeContest(contest.type),
            getTag: () => ({
                bgImg: firstIcon,
                value: formattedMessage({ id: "contest_card_label_glory_awaits" }),
                tooltipText: formattedMessage({
                    id: "contest_item_view_label_legend_figure",
                }),
            }),
        },
        {
            condition: ({ contest: { type } }) =>
                !isPracticeContest(type) && !isPrizeHunter(type),
            getTag: ({ contest: { prizeTeams, spots, teams }, matchStatus }) => ({
                bgImg: winRateIcon,
                value: `${getWinRate(prizeTeams, isMatchJoinable(matchStatus) ? spots : teams)}%`,
                tooltipText: formattedMessage(
                    { id: "contest_item_view_label_win_rate" },
                    { joined: prizeTeams },
                ),
            }),
        },
        {
            condition: () => true,
            getTag: getEntryIcon,
        },
        {
            condition: ({ contest }) =>
                isB2C && shouldContestShowApLevelIcon(contest),
            getTag: ({ contest: { minApLevel } }) => ({
                bgImg: vipIcon,
                value: apLevelConfig[minApLevel].text(),
                tooltipText: formattedMessage(
                    { id: "contest_item_view_label_ap_level" },
                    { apLevel: apLevelConfig[minApLevel].text() },
                ),
            }),
        },
        {
            condition: ({ contest }) => isPrizeHunter(contest.type),
            getTag: () => ({
                bgImg: oneTeamIcon,
                value: formattedMessage({ id: "contest_card_label_prize_hunter" }),
                tooltipText: formattedMessage({
                    id: "contest_item_view_label_prize_hunter",
                }),
            }),
        },
        {
            condition: ({ contest: { minimumSlip } }) => minimumSlip > 1,
            getTag: ({ contest: { minimumSlip } }) => ({
                bgImg: <MdGroupAdd size="16px" className="text-text-tertiary-1" />,
                value: `${minimumSlip}`,
                tooltipText: formattedMessage(
                    { id: "contest_item_view_label_minimum_slip" },
                    { minimumSlip: minimumSlip },
                ),
            }),
        },
        {
            condition: ({ contest: { allowDiscountWithRuby } }) => allowDiscountWithRuby,
            getTag: () => ({
                bgImg: <MdDiamond size="16px" className="text-text-tertiary-1" />,
                value: "",
                tooltipText: formattedMessage(
                    { id: "contest_item_view_label_allow_discount_with_ruby" },
                ),
            }),
        },
    ];

    return (contest, spotsToMaxEntries, defaultMaxEntries, matchStatus): TagVO[] => {
        const props = {
            contest,
            spotsToMaxEntries,
            defaultMaxEntries,
            matchStatus,
            formattedMessage,
        };

        return tagList
            .filter((tag) => tag.condition(props))
            .map((tag) => tag.getTag(props));
    };
}

export function hasJoinableTeams(myTeams, myJoinedTeams) {
    return myTeams.length > (myJoinedTeams?.length || 0);
}

export function isOnlyOneJoinableTeam(myTeams, myJoinedTeams) {
    return myTeams.length - (myJoinedTeams?.length || 0) === 1;
}
