import React from "react";
import { NoLabelSelected } from "components/empty/NoLabelSelected";
import { HomeEmptyMatch } from "components/empty/HomeEmptyMatch";
import { checkAllTournamentNotSelected, Tournament } from "helpers/TournamentHelper";
import { FormattedMessage } from "react-intl";

interface MatchEmptyDisplayProps {
    tournaments: Tournament[];
}

export const MatchEmptyDisplay: React.FC<MatchEmptyDisplayProps> = ({
                                                                        tournaments,
                                                                    }) => {
    if (checkAllTournamentNotSelected(tournaments)) {
        return <NoLabelSelected message={<FormattedMessage id="home_page_label_choose_a_tournament" />} />;
    }

    return <HomeEmptyMatch />;
};
