import { CoinProductVo } from "data/vo/coin/CoinProductVo";
import _ from "lodash";

export class CoinProductsVo {
    public readonly data: CoinProductVo[];

    private constructor(products: CoinProductVo[]) {
        this.data = products;
    }

    public get length() {
        return this.data.length;
    }

    public get hasOnSale() {
        return this.data.some(product => product.isOnSale);
    }

    public static create(products: CoinProductVo[]) {
        return new CoinProductsVo(products);
    }

    public static empty() {
        return new CoinProductsVo([]);
    }

    public getOnSale = () => {
        return this.data
            .filter(product => product.isOnSale);
    };

    public getStandard = () => {
        return this.data
            .filter(product => product.isStandard);
    };

    public getProductByRequiredCoin = (requiredDepositCent: number) => {

        return this.data.find(product => product.coinCent >= requiredDepositCent)
            || _.findLast(this.data);
    };
}
