import { DISCOUNT_TYPE } from "domain/contest/components/ConfirmationDialog/types";
import { getMaxRubyDiscountCent } from "domain/wallet/WalletHelper";
import { useMemo } from "react";
import { useSelector } from "store";
import { EnrichedCoupon } from "types/coupon/Coupon";
import { SelectedTeam } from "types/team/SelectedTeam";


export class Discount {
    discountType: DISCOUNT_TYPE;

    constructor(discountType) {
        this.discountType = discountType;
    }

    isUseRuby() {
        return this.discountType === DISCOUNT_TYPE.RUBY;
    }

    isUseCoupon() {
        return this.discountType === DISCOUNT_TYPE.COUPON;
    }

    isNone() {
        return this.discountType === DISCOUNT_TYPE.NONE;
    }
}

interface useJoinContestCalcProps {
    discountType: DISCOUNT_TYPE;
    entryFeeCent: number;
    selectedTeams: SelectedTeam[];
    selectedCoupons: EnrichedCoupon[];
    isUseRuby: boolean;
}

export interface UseJoinContestCalc {
    entryFeeDeductDiscountCent: number,
    couponDiscountCent: number,
    rubyDiscountToDepositCent: number,
    balanceDepositCent: number,
    balanceWinningCent: number,
    balanceRubyCent: number,
}

function getDiscountCent(discountType: DISCOUNT_TYPE, rubyDiscountToDepositCent: number, couponDiscountCent) {
    let discountCent = 0;
    const discount = new Discount(discountType);
    if (discount.isUseRuby()) {
        discountCent = rubyDiscountToDepositCent;
    }
    if (discount.isUseCoupon()) {
        discountCent = couponDiscountCent;
    }
    return discountCent;
}

const getTotalCouponDiscountCent = (selectedCoupons, contestEntryFeeCent) => {
    return selectedCoupons.reduce((total, coupon) => {
        return total + coupon.contestDiscountCent(contestEntryFeeCent);
    }, 0);
};

export const useJoinContestCalc = ({
                                       discountType,
                                       entryFeeCent,
                                       selectedTeams,
                                       selectedCoupons,
                                       isUseRuby
                                   }: useJoinContestCalcProps): UseJoinContestCalc => {

    const {
        WalletState: {
            coinCent,
            winningCent,
            rubyCent,
        },
        SettingsState: {
            contest: {
                rubyExchangeRate,
                rubyExchangeRateCent,
            }
        }
    } = useSelector(state => state);
    const totalEntryFeeCent = useMemo(() => selectedTeams.length * entryFeeCent, [selectedTeams, entryFeeCent]);
    const couponDiscountCent = useMemo(() => getTotalCouponDiscountCent(selectedCoupons, entryFeeCent), [selectedCoupons, entryFeeCent]);
    const rubyDiscountToDepositCent = useMemo(() => Math.min(
        getMaxRubyDiscountCent(rubyCent, rubyExchangeRateCent),
        totalEntryFeeCent
    ), [rubyCent, totalEntryFeeCent, rubyExchangeRateCent]);

    const entryFeeDeductDiscountCent = useMemo(() => {
        const discountCent = getDiscountCent(discountType, rubyDiscountToDepositCent, couponDiscountCent);

        return Math.max(totalEntryFeeCent - discountCent, 0);
    }, [discountType, rubyDiscountToDepositCent, couponDiscountCent, totalEntryFeeCent]);

    const balanceDepositCent = useMemo(() => coinCent - entryFeeDeductDiscountCent, [coinCent, entryFeeDeductDiscountCent]);

    const balanceWinningCent = useMemo(() => {
        if (balanceDepositCent >= 0) {
            return winningCent;
        }

        return winningCent - Math.abs(balanceDepositCent);
    }, [balanceDepositCent, winningCent]);

    const balanceRubyCent = useMemo(() => isUseRuby
            ? rubyCent - (rubyDiscountToDepositCent * rubyExchangeRate)
            : rubyCent
        , [isUseRuby, rubyCent, rubyDiscountToDepositCent, rubyExchangeRate]);

    return {
        entryFeeDeductDiscountCent,
        couponDiscountCent,
        rubyDiscountToDepositCent,
        balanceDepositCent,
        balanceWinningCent,
        balanceRubyCent,
    };

};
