import { Col } from "react-bootstrap";
import styled from "styled-components";

export const ExpiredRubyInfoRow = styled.div.attrs({
    className: "d-flex"
})`
  padding: .8rem 1.2rem;
  margin-top: -1.6rem;
`;

export const ExpiredInfoWrapper = styled(Col).attrs({
    className: "d-flex align-items-center px-0 col-8"
})``;

export const ExpiredInfo = styled.div.attrs({
    className: "fz-sf-regular-subhead tw-text-system-labelLightSecondary"
})`
`;

export const ExpiredInSevenDaysRubyAmount = styled(Col).attrs({
    className: "px-0 fz-sf-regular-body-1 text-end tw-text-system-labelLightPrimary"
})`
`;
