import { KycAwaitToWithdrawPopup } from "domain/kyc/components/KycAwaitToWithdrawPopup";
import { KycUnverifiedToWithdrawPopup } from "domain/kyc/components/KycUnverifiedToWithdrawPopup";
import useMyProfile from "hooks/useMyProfile";
import React from "react";
import { isAwait, isVerified } from "types/kyc/KycStatus";

export interface KycToWithdrawPopupProps {
    isShow: boolean;
    onClose: () => void;
}

export const KycToWithdrawPopupFactory: React.FC<KycToWithdrawPopupProps> = ({
                                                                                 isShow,
                                                                                 onClose
                                                                             }) => {
    const { myProfile: { kycStatus } } = useMyProfile();

    if (isVerified(kycStatus)) {
        return null;
    }

    if (isAwait(kycStatus)) {
        return <KycAwaitToWithdrawPopup isShow={isShow} onClose={onClose} />;
    }

    return <KycUnverifiedToWithdrawPopup isShow={isShow} onClose={onClose} />;
};
