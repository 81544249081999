import { StraightLine } from "components/straightLine";
import { FilterDot, FilterWrapper, SportsMenuWrapper } from "domain/home/HomeStyle";
import { SportMenu } from "domain/sport/SportMenu";
import React from "react";
import { MdFilterList } from "react-icons/md";

interface SportTabsProps {
    onClick: () => void,
    shouldShowDot: boolean
}

export const SportTabs: React.FC<SportTabsProps> = ({ onClick, shouldShowDot }) => {
    return <>
        <SportsMenuWrapper>
            <SportMenu />
        </SportsMenuWrapper>
        <StraightLine $height={1.8} />
        <FilterWrapper
            role="button"
            onClick={onClick}
        >
            <MdFilterList size="2rem" />
            {shouldShowDot && <FilterDot />}
        </FilterWrapper>
    </>;
};
