import { fetchRubyTransactionDetail, fetchTransactionDetail, fetchWalletHistory } from "data/api/wallet/walletApi";
import { WalletHistoriesVo } from "data/vo/wallet/WalletHistoriesVo";
import { RubyWalletHistoryVo } from "data/vo/wallet/RubyWalletHistoryVo";
import { TransactionDetailDto } from "data/dto/wallet/TransactionDetailDto";
import { TransactionDetailVo } from "data/vo/wallet/TransactionDetailVo";
import { WALLET_TYPE } from "data/dto/wallet/walletHistoryDto";
import { WalletHistoryVo } from "data/vo/wallet/WalletHistoryVo";

export class WalletRepository {

    async getWalletHistories() {
        const walletHistoriesDto = await fetchWalletHistory();

        return WalletHistoriesVo.create({
            coin: walletHistoriesDto.coin.map(history => WalletHistoryVo.create({
                ...history,
                type: WALLET_TYPE.COIN
            })),
            winning: walletHistoriesDto.winning.map(history => WalletHistoryVo.create({
                ...history,
                type: WALLET_TYPE.WINNING
            })),
            ruby: RubyWalletHistoryVo.create(
                walletHistoriesDto.ruby.expiringTime,
                walletHistoriesDto.ruby.expiringRubyCent,
                walletHistoriesDto.ruby.histories.map(history => WalletHistoryVo.create({
                    ...history,
                    type: WALLET_TYPE.RUBY
                }))
            )
        });
    }

    async getWalletTransactionDeTail(walletHistory: WalletHistoryVo) {
        if (!walletHistory.historyId) {
            return TransactionDetailVo.create(walletHistory);
        }

        let transactionDetailDto: TransactionDetailDto;
        if (walletHistory.type === WALLET_TYPE.RUBY) {
            transactionDetailDto = await fetchRubyTransactionDetail(walletHistory.historyId);
        } else {
            transactionDetailDto = await fetchTransactionDetail(walletHistory.historyId);
        }

        return TransactionDetailVo.create({
            ...transactionDetailDto
        });
    }

}
