import styled from "styled-components";

export const QRCodeWrapper = styled.div.attrs(() => ({
    className: "position-fixed d-none d-xl-flex",
}))`
  right: 1.7rem;
  bottom: 2.1rem;

  > img {
    -webkit-user-drag: none;
  }
`;
