import { useAppPopups } from "domain/popup/AppPopupsProvider";
import useFantasyHistory from "hooks/useFantasyHistory";
import React, { ReactNode, useEffect, useState } from "react";
import { matchPath } from "react-router";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";

const mainPageMatchers = [
    ROUTER_PATH_CONFIG.home,
    ROUTER_PATH_CONFIG.myMatch.index,
    ROUTER_PATH_CONFIG.result.index,
    ROUTER_PATH_CONFIG.chatRoom,
    ROUTER_PATH_CONFIG.me.index,
].map((path) => ({ path: path, exact: true, strict: true }));

const MainPagePopupWrapper = ({ children }: { children: ReactNode }) => {
    const popups = useAppPopups();
    const history = useFantasyHistory();
    const [isMainPage, setIsMainPage] = useState(false);

    const checkIsMainPage = () => {
        return mainPageMatchers.some((matcher) => {
            return matchPath(history.location.pathname, matcher) !== null;
        });
    };

    useEffect(() => {
        checkIsMainPage()
            ? setIsMainPage(true)
            : popups.keep();
    }, [history.location.pathname, popups]);

    if (!isMainPage) {
        return null;
    }

    return <>{children}</>;
};

export default MainPagePopupWrapper;
