import { useCallback, useState } from "react";
import { TASK_TYPE } from "types/task/TaskType";

export enum VideoPlayerStatus {
    IDLE, PLAYING, ENDED, INTERRUPTED
};

export interface VideoPlayer {
    show: boolean;
    url: string;
    play: (url: string, type: TASK_TYPE, code: string) => void;
    stop: () => void;
    handleEnd: () => void;
    reset: () => void;
    taskType: TASK_TYPE | null;
    code: string | null;
    getStatus: (type: TASK_TYPE, code: string) => VideoPlayerStatus;
}

export const useVideoPlayer = (): VideoPlayer => {
    const [show, setShow] = useState(false);
    const [url, setUrl] = useState("");
    const [taskType, setTaskType] = useState<TASK_TYPE | null>(null);
    const [taskCode, setTaskCode] = useState<string | null>(null);
    const [status, setStatus] = useState(VideoPlayerStatus.IDLE);

    const play = useCallback((url: string, type: TASK_TYPE, code: string) => {
        setStatus(VideoPlayerStatus.PLAYING);
        setUrl(url);
        setShow(true);
        setTaskType(type);
        setTaskCode(code);
    }, []);

    const stop = useCallback(() => {
        setShow(false);
        setStatus(VideoPlayerStatus.INTERRUPTED);
    }, []);

    const handleEnd = useCallback(() => {
        setShow(false);
        setStatus(VideoPlayerStatus.ENDED);
    }, []);

    const reset = useCallback(() => {
        setUrl("");
        setTaskType(null);
        setTaskCode(null);
        setStatus(VideoPlayerStatus.IDLE);
    }, []);

    const getStatus = useCallback((type: TASK_TYPE, code: string) => {
        if (type === taskType && code === taskCode) {
            return status;
        }

        return VideoPlayerStatus.IDLE;
    }, [taskType, taskCode, status]);

    return {
        show,
        url,
        play,
        stop,
        handleEnd,
        reset,
        taskType,
        code: taskCode,
        getStatus,
    };
};
