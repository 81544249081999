import React from "react";
import styled from "styled-components";

interface CommonImageSectionProps {
    src: string
}

const CommonImageSection: React.FC<CommonImageSectionProps> = ({ src }) => {
    return <CardImg>
        <img src={src} alt="awardNoneIcon" />
    </CardImg>;
};

const CardImg = styled.div.attrs({
    className: "card-img-top text-center"
})`
  padding-top: 2.4rem;

  > img {
    width: 17.3rem;
    height: auto;
    -webkit-user-drag: none;
  }

`;

export default CommonImageSection;
