import { cn } from "helpers/cn";
import React from "react";

export function PlayerCardAnimatedPing() {
    return <div
        className={"tw-absolute tw-top-[0px] tw-left-[0px] tw-bottom-[0px] tw-w-full tw-m-auto tw-overflow-clip tw-rounded-[1.6rem] tw-pointer-events-none"}>
        <div
            className={cn("tw-absolute tw-top-[2rem] tw-right-[1rem] tw-origin-center tw-bg-[#F2F2F2]/[0.5] tw-w-[3rem] tw-h-[3rem]", "tw-rounded-[0.8rem] tw-animate-custom-ping")} />
    </div>;
}
