import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Icon } from "helpers/IconHelper";
import { ShareOption } from "components/popup/shareContestPopup/component/ShareOption";
import { SHARE_STEP } from "components/popup/shareContestPopup/type";
import { ToChatRoom } from "components/popup/shareContestPopup/component/ToChatRoom";
import { CopyContestCodeToClipboard } from "components/popup/shareContestPopup/component/CopyContestCodeToClipboard";
import FantasyBasicPopup, { FANTASY_POPUP_BACKGROUND, POPUP_SIZE } from "components/popup/FantasyBasicPopup";
import { useSelector } from "store";

interface ShareContestPopupProps {
    show: boolean;
    code: string;
    contestId: number;
    onHide: () => void;
}

const ShareContestPopup: React.FC<ShareContestPopupProps> = ({ show, code, contestId, onHide }) => {
    const intl = useIntl();
    const { featureConfig } = useSelector((store) => store.FantasyGameConfigState);

    const [step, setStep] = useState(SHARE_STEP.SELECT_OPTION);
    const title = step === SHARE_STEP.SELECT_OPTION
        ? intl.formatMessage({ id: "share_contest_dialog_title" })
        : intl.formatMessage({ id: "share_contest_popup_label_to_chat_room" });

    const handleClose = () => {
        setStep(SHARE_STEP.SELECT_OPTION);
        onHide();
    };

    return <FantasyBasicPopup
        show={show}
        onHide={handleClose}
        title={{
            title,
            titleClassName: "tw-text-h3 tw-text-primary-800"
        }}
        popupSize={POPUP_SIZE.MD}
        background={FANTASY_POPUP_BACKGROUND.white}
        content={
            <>
                {(() => {
                    switch (step) {
                        case SHARE_STEP.SELECT_OPTION:
                            return (
                                <div className="tw-self-stretch tw-mt-5">
                                    {featureConfig.chatRoom && (
                                        <ShareOption
                                            icon={Icon.Chat}
                                            label={intl.formatMessage({ id: "share_contest_popup_label_to_chat_room" })}
                                            onClick={() => {
                                                setStep(SHARE_STEP.TO_CHAT_ROOM);
                                            }}
                                        />
                                    )}
                                    <CopyContestCodeToClipboard code={code} onCopied={handleClose}>
                                        <ShareOption
                                            icon={Icon.Link}
                                            label={intl.formatMessage({ id: "share_contest_popup_label_copy_link" })}
                                        />
                                    </CopyContestCodeToClipboard>
                                </div>
                            );
                        case SHARE_STEP.TO_CHAT_ROOM:
                            return <ToChatRoom contestId={contestId} onShared={handleClose} />;
                        default:
                            return null;
                    }
                })()}
            </>
        }
        buttonSetting={{
            confirmButton: false,
            cancelButton: false
        }}
    />;
};

export default ShareContestPopup;
