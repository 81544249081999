import { StorageHelper } from "helpers/localStorage/types";

export const getDataFromSessionStorage = (key: string) => {
    const item = sessionStorage.getItem(key);
    if (!item) return null;
    return JSON.parse(item);
};
export const setDataToSessionStorage = (key: string, data: any) => sessionStorage.setItem(key, JSON.stringify(data));
export const removeDataFromSessionStorage = (key: string) => sessionStorage.removeItem(key);
export const clearSessionStorage = () => sessionStorage.clear();

export const sessionStorageHelper: StorageHelper = (...keys: string[]) => {
    const key = keys.join("-");

    const put = (data: any) => setDataToSessionStorage(key, data);
    const get = () => getDataFromSessionStorage(key);
    const remove = () => removeDataFromSessionStorage(key);
    const clear = () => clearSessionStorage();

    return { get, put, remove, clear };
};
