import { useCallback, useRef, useState } from "react";

export const useToggle = (initToggle = false) => {
    const [toggle, setToggle] = useState(initToggle);

    const handleToggle = useCallback(() => {
        setToggle(t => !t);
    }, []);

    return ({
        toggle,
        setToggle,
        handleToggle
    });
};

const useTimeoutToggle = (initToggle = false, timeMs = 3000) => {
    const timer = useRef<any>();
    const {
        toggle,
        setToggle,
        handleToggle
    } = useToggle(initToggle);

    const handleTimeoutToggle = useCallback(() => {
        if (timer.current) return;

        timer.current = setTimeout(() => {
            handleToggle();
        }, timeMs);

        return () => {
            clearTimeout(timer.current);
            timer.current = undefined;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return ({
        toggle,
        setToggle,
        handleTimeoutToggle,
    });
};

export default useTimeoutToggle;
