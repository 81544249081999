import React from "react";
import { useApLevelCarousel } from "../task/components/ApLevel/hooks/useApLevelCarousel";
import { Progress } from "../task/components/ApLevel/Progress";
import { ProgressBar } from "../task/components/ApLevel/ProgressBar";
import { ApLevelSwiperSlide, CarouselWrapper } from "../task/components/ApLevel/ApLevelStyle";
import Carousel from "../../components/carousel";
import { ApLevelCarouselItem } from "../task/components/ApLevel/ApLevelCarouselItem";
import { useSelector } from "../../store";
import { useQuestContext } from "./QuestProvider";
import { useApLevelCarouselConfig } from "../task/components/ApLevel/hooks/useApLevelCarouselConfig";

const DailyQuests = () => {
    return <div>
        <ApLevelCarousel />
    </div>;
};

const ApLevelCarousel = () => {
    const context = useQuestContext();

    const carousel = useApLevelCarousel(context);
    const carouselConfig = useApLevelCarouselConfig();
    const { apLevels } = useSelector(state => state.SettingsState);

    if (!context.apOverview || apLevels.length === 0) {
        return <CarouselWrapper />;
    }

    return <CarouselWrapper>
        <Progress>
            <ProgressBar progress={carousel.getProgress(context.currentLevelIndex)} />
        </Progress>
        <Carousel
            slidesPerView={carouselConfig.slidesPerView}
            centeredSlides
            onRealIndexChange={carousel.handleRealIndexChange}
            onSetTranslate={carousel.handleSetTranslate}
            onInit={carousel.handelSwiperInit}
            initialSlide={context.currentLevelIndex}
        >
            {
                apLevels.map((apLevel, index) =>
                    <ApLevelSwiperSlide key={index}
                                        $offsetY={carousel.getOffsetY(index)}
                                        onClick={carousel.handleSwiperSlideClick(index)}
                    >
                        <ApLevelCarouselItem
                            apLevel={apLevel}
                            currentIndexDiff={Math.abs(index - context.currentCarouselIndex)}
                            apOverview={context.apOverview}
                        />
                    </ApLevelSwiperSlide>
                )
            }
        </Carousel>
    </CarouselWrapper>;
};

export default DailyQuests;