import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import useMyProfile from "hooks/useMyProfile";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import Me from "domain/me/me/Me";
import { MeProvider } from "domain/me/me/MeProvider";
import Profile from "domain/me/profile/Profile";
import ReferralSwitcher from "domain/me/referral/ReferralSwitcher";
import { useSelector } from "../../store";

const MeSwitcher = () => {
    const { myProfile, getMyProfile } = useMyProfile();
    const {featureConfig} = useSelector(state => state.FantasyGameConfigState);

    useEffect(() => {
        getMyProfile();
    }, [getMyProfile]);

    if (!myProfile) return null;

    return (
        // TODO check no match route and redirect to home
        <Switch>
            {
                featureConfig.referral && (
                    <Route path={ROUTER_PATH_CONFIG.me.referral.index}>
                        <ReferralSwitcher />
                    </Route>
                )
            }

            <MeProvider>
                <Switch>
                    <Route exact path={ROUTER_PATH_CONFIG.me.edit}>
                        <Profile />
                    </Route>
                    <Route exact path={ROUTER_PATH_CONFIG.me.index}>
                        <Me />
                    </Route>
                </Switch>
            </MeProvider>
            <Redirect to={ROUTER_PATH_CONFIG.home} />
        </Switch>
    );
};

export default MeSwitcher;
