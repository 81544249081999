import React from "react";
import NonPracticeContestCard from "./NonPracticeContestCard";
import { isPracticeContest } from "helpers/ContestHelper";
import PracticeContestCard from "./PracticeContestCard";
import { MATCH_STATUS_FROM_API } from "types/match/Match";
import { Contest } from "apis/match/type";

export interface ContestCardFactoryProps {
    matchStatus: MATCH_STATUS_FROM_API,
    openContestPanel?: (e: MouseEvent, contest: Contest) => void,
    contest: Contest,
    onContestClick: (e: React.MouseEvent, contest: Contest) => void,
}

const ContestCardFactory: React.FC<ContestCardFactoryProps> = (props) => {
    if (isPracticeContest(props.contest.type)) {
        return <PracticeContestCard {...props} />;
    }

    return <NonPracticeContestCard {...props} />;
};

export default ContestCardFactory;
