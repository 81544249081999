import styled from "styled-components";

export const CarouselWrapper = styled.div.attrs(() => ({
    className: "flex-grow-1 pt-0 pt-sm-5"
}))`

  .swiper-pagination {
    position: unset;
    margin-top: 1rem;
  }

  .swiper-pagination-bullet {
    height: 0.8rem;
    width: 0.8rem;
  }

  .swiper-pagination-bullet-active {
    width: 2rem;
    border-radius: 10rem;
    background-color: var(--cl-primary80);
  }
`;
export const AuthImage = styled.img`
  @supports (height: 100dvh) {
    max-height: 45dvh;
  }

  @supports not (height: 100dvh) {
    max-height: 45vh;
  }
`;