import { divideByOneHundred } from "helpers/number/NumberHelper";
import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { FantasyPopupButtonSetting } from "../../../../components/popup/component/ButtonSection";
import FantasyBasicPopup from "../../../../components/popup/FantasyBasicPopup";
import SpeechBubble from "../../../../components/speechBubble";
import { BUTTON_PATTERN, BUTTON_SIZE, NEW_BUTTON_VARIANTS } from "../../../../designToken/button/types";
import { cn } from "../../../../helpers/cn";
import { Icon } from "../../../../helpers/IconHelper";
import useFantasyHistory from "../../../../hooks/useFantasyHistory";
import { ROUTER_PATH_CONFIG } from "../../../../router/RouterPathConfig";
import { useDailyCheckInPopup } from "../DailyCheckInPopupProvider";
import { DailyCheckInPopupItem } from "./DailyCheckInPopupItem";

export const dailyCheckInDefaultPopupConfirmButton = {
    none: { confirmButton: false },
    moreReward: {
        confirmButtonVariant: NEW_BUTTON_VARIANTS.tertiaryLight,
        confirmButtonSuffixIcon: Icon.Calendar,
        confirmButtonText: <FormattedMessage id="daily_check_in_popup_button_more_rewards" />,
    },
};

function DailyCheckInPopupSpeechBubble({ text }: { text: ReactNode }) {
    return <div>
        <SpeechBubble className={cn(
            "tw-text-caption-2 tw-font-light tw-leading-none",
            "tw-rounded-full tw-px-2 tw-py-1",
            "tw-inline-flex tw-gap-1 tw-z-50")}
                      bgClassName="tw-bg-grey-100"
                      arrowSize="medium"
                      arrow="bottom">
            <Icon.Lightning className="tw-text-grey-600" />
            {text}
        </SpeechBubble>
    </div>;
}

const DailyCheckInPopupTitle = () => {
    const context = useDailyCheckInPopup();
    return <div className="tw-flex tw-flex-col tw-items-center tw-gap-1">
        <div className="tw-font-bold tw-text-h5">
            {
                context.myDaysInRow === 1
                    ? <FormattedMessage id="daily_check_in_popup_title_first_day" />
                    : <FormattedMessage id="daily_check_in_popup_title" values={{ days: context.myDaysInRow }} />
            }
        </div>
        {
            context.myDaysInRow === context.maxDaysInRow &&
            <DailyCheckInPopupSpeechBubble
                text={<FormattedMessage id="daily_check_in_upgrade_popup_label_max_reward" />} />
        }
        {
            context.myDaysInRow > context.maxDaysInRow &&
            <DailyCheckInPopupSpeechBubble
                text={<FormattedMessage id="daily_check_in_upgrade_popup_label_max_reward_continue" />} />
        }
        <div
            data-testid="daily-check-in-default-popup-today-reward-value"
            className="tw-font-light tw-text-subtitle-2">
            {
                context.todayPrize.coinCent > 0
                    ? <FormattedMessage id="daily_check_in_popup_subtitle_coins_today"
                                        values={{ amount: divideByOneHundred(context.todayPrize.coinCent) }} />
                    : <FormattedMessage id="daily_check_in_popup_subtitle_rubies_today"
                                        values={{ amount: divideByOneHundred(context.todayPrize.rubyCent) }} />
            }
        </div>
    </div>;
};


const DailyCheckInPopupContent = () => {
    return <div className="tw-flex tw-flex-col tw-items-center tw-pt-5 tw-pb-6">
        <div className="tw-flex tw-flex-row tw-items-center">
            {[1, 2, 3, 4].map((i) => <DailyCheckInPopupItem key={i} itemDaysInRow={i} leading={i === 1} />)}
        </div>
        <div className="tw-flex tw-flex-row tw-items-center tw-pt-5">
            {[5, 6, 7, 8].map((i) => <DailyCheckInPopupItem key={i} itemDaysInRow={i} leading={i === 5} />)}
        </div>
    </div>;
};

type DailyCheckInDefaultPopupProps = {
    confirmButtonSetting: FantasyPopupButtonSetting;
    overlaySection?: ReactNode;

    show: boolean;
    onHide: () => void;
};

const DailyCheckInDefaultPopup: React.FC<DailyCheckInDefaultPopupProps> = (props) => {

    const context = useDailyCheckInPopup();
    const history = useFantasyHistory();

    const handleConfirm = () => {
        history.push(ROUTER_PATH_CONFIG.task.index, { apLevel: context.myApLevel.currentLevel });
    };

    return <FantasyBasicPopup
        show={props.show}
        dismissible={false}
        ref={context.popupRef.ref}
        onHide={props.onHide}
        title={<DailyCheckInPopupTitle />}
        content={<DailyCheckInPopupContent />}
        buttonSetting={{
            buttonWrapperClassName: "tw-px-10",
            buttonPattern: BUTTON_PATTERN.rounded,
            buttonSize: BUTTON_SIZE.md,

            cancelButtonText: <FormattedMessage id="dialog_button_close" />,
            onCancel: props.onHide,

            onConfirm: handleConfirm,
            ...props.confirmButtonSetting,
        }}
        onReady={() => context.setIsPopupReady(true)}
        overlaySection={props.overlaySection}
    />;
};

export default DailyCheckInDefaultPopup;

