import { GameLogVo } from "data/vo/player/gameLog/GameLogVo";
import { FlattedGameLogProps } from "data/vo/player/PlayerInfoVo";
import { divideByOneHundred } from "helpers/number/NumberHelper";

export class CricketGameLogVo extends GameLogVo {
    private readonly _run: number;
    private readonly _four: number;
    private readonly _six: number;
    private readonly _strikeRate: number;
    private readonly _fifty: number;
    private readonly _duck: number;
    private readonly _wickets: number;
    private readonly _maidenOver: number;
    private readonly _economyRate: number;
    private readonly _catches: number;
    private readonly _runOutStumping: number;
    private readonly _runOutThrower: number;
    private readonly _runOutCatcher: number;
    private readonly _directRunOut: number;
    private readonly _stumping: number;
    private readonly _thirty: number;
    private readonly _bonus: number;
    private readonly _bonusCatch: number;
    private readonly _bonusBowedLBW: number;
    private readonly _starting: number;

    constructor(gameLog: FlattedGameLogProps) {
        super(gameLog);
        this._starting = gameLog.starting ?? 0;
        this._run = gameLog.run ?? 0;
        this._four = gameLog.four ?? 0;
        this._six = gameLog.six ?? 0;
        this._strikeRate = gameLog.strikeRate ?? 0;
        this._fifty = gameLog.fifty ?? 0;
        this._duck = gameLog.duck ?? 0;
        this._wickets = gameLog.wickets ?? 0;
        this._maidenOver = gameLog.maidenOver ?? 0;
        this._economyRate = gameLog.economyRate ?? 0;
        this._catches = gameLog.catches ?? 0;
        this._runOutStumping = gameLog.runOutStumping ?? 0;
        this._runOutThrower = gameLog.runOutThrower ?? 0;
        this._runOutCatcher = gameLog.runOutCatcher ?? 0;
        this._directRunOut = gameLog.directRunOut ?? 0;
        this._stumping = gameLog.stumping ?? 0;
        this._thirty = gameLog.thirty ?? 0;
        this._bonus = gameLog.bonus ?? 0;
        this._bonusCatch = gameLog.bonusCatch ?? 0;
        this._bonusBowedLBW = gameLog.bonusBowedLBW ?? 0;
    }

    get bonusBowedLBW(): number {
        return divideByOneHundred(this._bonusBowedLBW);
    }

    get bonusCatch(): number {
        return divideByOneHundred(this._bonusCatch);
    }

    get bonus(): number {
        return divideByOneHundred(this._bonus);
    }

    get thirty(): number {
        return divideByOneHundred(this._thirty);
    }

    get stumping(): number {
        return divideByOneHundred(this._stumping);
    }

    get directRunOut(): number {
        return divideByOneHundred(this._directRunOut);
    }

    get runOutCatcher(): number {
        return divideByOneHundred(this._runOutCatcher);
    }

    get runOutThrower(): number {
        return divideByOneHundred(this._runOutThrower);
    }

    get runOutStumping(): number {
        return divideByOneHundred(this._runOutStumping);
    }

    get catches(): number {
        return divideByOneHundred(this._catches);
    }

    get economyRate(): number {
        return divideByOneHundred(this._economyRate);
    }

    get maidenOver(): number {
        return divideByOneHundred(this._maidenOver);
    }

    get wickets(): number {
        return divideByOneHundred(this._wickets);
    }

    get duck(): number {
        return divideByOneHundred(this._duck);
    }

    get fifty(): number {
        return divideByOneHundred(this._fifty);
    }

    get strikeRate(): number {
        return divideByOneHundred(this._strikeRate);
    }

    get six(): number {
        return divideByOneHundred(this._six);
    }

    get four(): number {
        return divideByOneHundred(this._four);
    }

    get run(): number {
        return divideByOneHundred(this._run);
    }

    get starting(): number {
        return divideByOneHundred(this._starting);
    }
}
