import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const StyledRelationBar = styled.div.attrs({
    className: "d-flex mb-4",
})``;

export const StyledRelationBarItemWrapper = styled(NavLink).attrs({
    className: "d-flex flex-grow-1 justify-content-center position-relative",
})`
`;

export const StyledDivider = styled.div.attrs({
    className: "tw-bg-system-gray200",
})`
  width: 0.1rem;
  height: 2.8rem;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;
