import { INJURY_PLAYING_CHANCE } from "data/vo/player/profile/BasketballPlayerInfoProfileVo";
import Badge, { BADGE_PATTERN, BADGE_SIZE, BADGE_VARIANTS } from "designToken/badge/Badge";
import React from "react";
import { SquadPlayer } from "types/player/Player";

function getVariant(injuryPlayingChance: INJURY_PLAYING_CHANCE): BADGE_VARIANTS {
    if (injuryPlayingChance === INJURY_PLAYING_CHANCE.PROBABLE) {
        return BADGE_VARIANTS.OUTLINE_WARNING;
    }

    if (injuryPlayingChance === INJURY_PLAYING_CHANCE.QUESTIONABLE) {
        return BADGE_VARIANTS.OUTLINE_SECONDARY;
    }

    if (injuryPlayingChance === INJURY_PLAYING_CHANCE.DOUBTFUL) {
        return BADGE_VARIANTS.OUTLINE_DANGER_2;
    }

    return BADGE_VARIANTS.OUTLINE_DANGER;
}

export const InjuryPlayingChance: React.FC<{ player: SquadPlayer }> = ({ player }) => {
    return (
        <Badge className="ms-3 flex-grow-0 align-self-center"
               size={BADGE_SIZE.SM}
               variant={getVariant(player.injuryPlayingChance)}
               pattern={BADGE_PATTERN.ROUNDED}
               dataTestId="injury-playing-chance"
        >
            {player.injuryPlayingChanceAbbr}
        </Badge>
    );
};
