import React, { useState } from "react";
import { MdInfo } from "react-icons/md";
import { NineCatDetailVo } from "../data/vo";
import SlatePopup from "./SlatePopup";

const NineCatSlateTitle: React.FC<{ slate: NineCatDetailVo }> = ({ slate }) => {
    const [openSlateDetailModal, setOpenSlateDetailModal] = useState(false);


    return (
        <>
            <div className="tw-flex tw-items-center tw-justify-center tw-cursor-pointer" onClick={() => {
                setOpenSlateDetailModal(true);
            }}>
                <h2 className={"tw-text-h5 tw-font-bold"}>
                    {slate?.fullTitle}
                </h2>
                <div className={"tw-pl-1"}>
                    <MdInfo fill={"#B3B3B3"} size={"1.4rem"} />
                </div>
            </div>

            <SlatePopup
                open={openSlateDetailModal}
                onOpenChange={setOpenSlateDetailModal}
                slate={slate}
            />
        </>
    );
};

export default NineCatSlateTitle;
