import styled from "styled-components";
import { APP_MAX_WIDTH } from "styles/Constants";

export const StyledTitleBarWrapper = styled.div.attrs({
    className: "d-flex align-items-center justify-content-between",
})`
  padding: 0 1.6rem;
  height: 4.2rem;
  background-color: transparent;

  @media (min-width: ${APP_MAX_WIDTH}) {
    padding: 0 3.2rem;
  }
`;

export const StyledScrollingTitleBarWrapper = styled(StyledTitleBarWrapper)`
  position: fixed;
  z-index: 100000;
  background-color: rgba(255, 255, 255);
  width: 100%;
  max-width: ${APP_MAX_WIDTH};
`;

export const StyledTitleBarTitle = styled.div.attrs({
    className: "fz-sf-bold-title-3 text-text-primary-1",
})``;

export const StyledTitleBarIcon = styled.div.attrs({
    className: "text-text-primary-1 clickable",
})``;

export const StyledTitleBarScrollingWrapper = styled.div.attrs({
    className: "d-flex gap-2 align-items-center",
})`
  overflow: hidden;
`;

export const StyledTitleBarScrollingTitle = styled.span.attrs({
    className: "fz-sf-bold-body-1 text-text-primary-1",
})`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
