import { cn } from "helpers/cn";
import React from "react";

interface SpeechBubbleProps {
    className?: string;
    bgClassName: string;
    arrowSize: "small" | "medium";
    arrow: "left" | "bottom";
    children: React.ReactNode;
}

const size = {
    small: "tw-w-[5px] tw-h-[5px]",
    medium: "tw-w-[8px] tw-h-[8px]",
};

const arrowClassNameMap = {
    left: {
        small: cn(size.small, "tw-rounded-bl-[1px] tw-top-1/2 tw-left-[3px] -tw-translate-x-full -tw-translate-y-1/2"),
        medium: cn(size.medium, "tw-rounded-bl-[1px] tw-top-1/2 tw-left-[4px] -tw-translate-x-full -tw-translate-y-1/2")
    },
    bottom: {
        small: cn(size.small, "tw-rounded-br-[1px] tw-left-1/2 -tw-bottom-[2px] -tw-translate-x-1/2"),
        medium: cn(size.medium, "tw-rounded-br-[1px] tw-left-1/2 -tw-bottom-[4px] -tw-translate-x-1/2")
    },
};

const SpeechBubble: React.FC<SpeechBubbleProps> = ({
                                                       children,
                                                       className,
                                                       bgClassName,
                                                       arrowSize,
                                                       arrow
                                                   }) => {

    return <div className={cn(
        "tw-flex tw-items-center tw-justify-center tw-relative tw-z-0",
        "tw-px-[0.2rem] tw-py-[0.4rem] tw-rounded-[2px]",
        className,
        bgClassName
    )}>
        {children}
        <div
            className={cn(
                "tw-absolute tw-transform -tw-z-1 tw-rotate-45 ",
                bgClassName,
                arrowClassNameMap[arrow][arrowSize]
            )}
        />
    </div>;
};

export default SpeechBubble;
