import { ApiError } from "apis/Api";
import Countdown from "components/countdown/Countdown";
import { useKycOtp } from "domain/kyc/pages/kycOtp/KycOtpProvider";
import { cn } from "helpers/cn";
import { isOtpSendExceedDailyLimit } from "helpers/ErrorHelper";
import useHandleApiError, { ERROR_HANDLER_FLOW } from "hooks/useHandleApiError";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

const OTP_RESEND_COOLDOWN = 90 * 1000;
export const OtpResendSection: React.FC = () => {
    const intl = useIntl();
    const { sendOtp, otpRequest, setOtpSendError, error } = useKycOtp();
    const handleApiError = useHandleApiError();
    const [isResendSuccess, setIsResendSuccess] = useState(false);
    const [canOtpResend, setCanOtpResend] = useState(false);

    const handelResend = async () => {
        if (error.isOtpSendExceedDailyLimit) {
            return;
        }

        try {
            setIsResendSuccess(false);
            setOtpSendError(null);

            await sendOtp(otpRequest);

            setCanOtpResend(false);
            setIsResendSuccess(true);
        } catch (e) {
            await handleApiError(e as ApiError, (error) => {
                if (isOtpSendExceedDailyLimit(error)) {
                    setOtpSendError(error.response!.data.errorCode);
                    return ERROR_HANDLER_FLOW.STOP;
                }

                return ERROR_HANDLER_FLOW.COUNTINUE;
            });
        }
    };

    return <div className="tw-text-subtitle-2 tw-text-center">
        {
            isResendSuccess && (
                <div className="tw-text-success-800">
                    <FormattedMessage id="otp_resend_successful" />
                </div>
            )
        }
        <div>
            <FormattedMessage id="otp_resend_description" />
        </div>
        <div>
            <FormattedMessage id="otp_resend_limit_tip" />
        </div>
        {
            canOtpResend
                ? <div className={cn("tw-underline tw-text-info-700  tw-cursor-pointer tw-inline-block tw-px-1", {
                    "tw-bg-warning-50": error.isOtpVerifyExceedAttemptLimit || error.isOtpCodeExpired,
                    "tw-text-grey-700 tw-cursor-not-allowed tw-bg-transparent": error.isOtpSendExceedDailyLimit,
                })} onClick={handelResend}>
                    <FormattedMessage id="otp_resend_now" />
                </div>
                : <div className="tw-underline tw-text-grey-700">
                    <Countdown
                        finishAt={Date.now() + OTP_RESEND_COOLDOWN}
                        onFormatCountdownTime={(timeObject) => {
                            return intl.formatMessage({ id: "otp_resend_cooldown" }, { seconds: timeObject.second + timeObject.minute * 60 });
                        }}
                        handleTimeout={() => {
                            setCanOtpResend(true);
                        }}
                    />
                </div>
        }
    </div>;
};
