export enum AWARD_TYPE {
    COUPON = "COUPON",
    DEPOSIT = "DEPOSIT",
    RUBY = "RUBY",
    NO_AWARD = "NO_AWARD"
}

export const isCoupon = (awardType: AWARD_TYPE) => awardType === AWARD_TYPE.COUPON;
export const isDeposit = (awardType: AWARD_TYPE) => awardType === AWARD_TYPE.DEPOSIT;
export const isRuby = (awardType: AWARD_TYPE) => awardType === AWARD_TYPE.RUBY;
export const isNoAward = (awardType: AWARD_TYPE) => awardType === AWARD_TYPE.NO_AWARD;
