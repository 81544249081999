import { FeaturedContestVo } from "data/vo/contest/FeaturedContestVo";
import { Callback } from "Common";
import React from "react";
import Carousel from "components/carousel";
import { FeatureContestCard } from "officialWebsite/FeatureContest/components/FeatureContestCard";
import { CarouselArrow } from "officialWebsite/FeatureContest/components/CarouselArrow";
import { SwiperSlide } from "swiper/react";

interface FeatureContestCarouselComponentProps {
    featureContests: FeaturedContestVo[]
    refetchFeatureContests: Callback
}

export const FeatureContestCarousel: React.FC<FeatureContestCarouselComponentProps> = ({
                                                                                           featureContests,
                                                                                           refetchFeatureContests,
                                                                                       }) => {

    return (
        <div className="tw-mt-[21px]">
            <Carousel
                className="tw-px-[62px]"
                slidesPerView="auto"
                spaceBetween={14}
            >
                {
                    featureContests.map((featureContest) => {
                        return <SwiperSlide
                            key={featureContest.code}
                            className="!tw-w-auto pb-3"
                            data-testid={`feature-contest-card-${featureContest.code}`}
                        >
                            <FeatureContestCard
                                featuredContest={featureContest}
                                onRefetchFeatureContests={refetchFeatureContests}
                            />
                        </SwiperSlide>;
                    })
                }
                <CarouselArrow />
            </Carousel>
        </div>
    );
};
