import { FlattedGameLogProps } from "data/vo/player/PlayerInfoVo";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import { formatToMMMDD } from "helpers/TimeHelper";


export class GameLogVo {
    private readonly _totalPointCent: number;
    private readonly _oppositeSquadCode: string;
    private readonly _matchStartsAt: number;
    private readonly _squadAScores: string;
    private readonly _squadBScores: string;
    private readonly _winningSquadCode: string;
    private readonly _playerSquadCode: string;

    constructor(gameLog: FlattedGameLogProps) {
        this._matchStartsAt = gameLog.matchStartsAt;
        this._oppositeSquadCode = gameLog.oppositeSquadCode;
        this._totalPointCent = gameLog.totalPointCent;
        this._squadAScores = gameLog.squadAScores || "";
        this._squadBScores = gameLog.squadBScores || "";
        this._winningSquadCode = gameLog.winningSquadCode || "";
        this._playerSquadCode = gameLog.playerSquadCode || "";
    }

    get totalPoint(): number {
        return divideByOneHundred(this._totalPointCent);
    }

    get oppositeSquadCode(): string {
        return this._oppositeSquadCode;
    }

    get matchStartsAt(): number {
        return this._matchStartsAt;
    }

    get matchDate(): string {
        return formatToMMMDD(this._matchStartsAt);
    }

    get winOrLoseText(): string {
        return this._winningSquadCode === this._playerSquadCode ? "W" : "L";
    }

    get gameLogResult(): string {
        return `${this.winOrLoseText} ${this._squadAScores}-${this._squadBScores}`;
    }
}

