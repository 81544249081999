import clsx, { ClassValue } from "clsx";
import { extendTailwindMerge } from "tailwind-merge";

export const cn = (...args: ClassValue[]) => {
    return twMerge(clsx(...args));
};

const twMerge = extendTailwindMerge({
    prefix: "tw-",
    extend: {
        classGroups: {
            "font-size": ["text-h1", "text-h2", "text-h3", "text-h4", "text-h5", "text-h6", "text-subtitle-1", "text-subtitle-2", "text-body-1", "text-body-2", "text-caption-1", "text-caption-2", "text-overline", "text-title-2"]
        }
    }
});
