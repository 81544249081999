import NineCatLeavePopup from "domain/slate/components/NineCatLeavePopup";
import { useCreateSlateTeamContext } from "domain/slate/providers/CreateNineCatTeamProvider";
import useFantasyHistory from "hooks/useFantasyHistory";
import React, { useEffect, useRef, useState } from "react";
import { Prompt } from "react-router-dom";
import { ROUTER_PATH_CONFIG } from "../../../router/RouterPathConfig";
import NineCatDescriptionWithIcon from "../components/NineCatDescriptionWithIcon";
import TopNavbar, { TopNavbarType } from "../components/TopNavbar";
import { NineCatDetailVo } from "../data/vo";

interface CreateSlateTeamTopBarProps {
    slate?: NineCatDetailVo;
}


function useRouteBlockCheck(checkShouldBlock: () => boolean, excludedBlockPaths?: string[]) {
    const history = useFantasyHistory();
    const [isLeaveModalOpen, setLeaveModalOpen] = useState(false);
    const shouldBlockRoute = useRef(true);

    function handleOpenLeaveModal() {
        shouldBlockRoute.current = false;
        setLeaveModalOpen(true);
    }

    function handleLeave() {
        if (!checkShouldBlock()) return history.goBack();

        handleOpenLeaveModal();
    }

    const handleBlockRoute = ({ pathname }) => {
        if (!shouldBlockRoute.current) return true;
        if (!checkShouldBlock()) return true;
        if (excludedBlockPaths?.includes(pathname)) return true;

        handleOpenLeaveModal();
        return false;
    };

    const handleLeaveWithoutCheck = () => history.goBack();

    function handleResetBlockRouteAfterModalClosed() {
        if (!isLeaveModalOpen) {
            shouldBlockRoute.current = true;
        }
    }

    useEffect(() => {
        handleResetBlockRouteAfterModalClosed();
    }, [isLeaveModalOpen]);

    return {
        isLeaveModalOpen,
        setLeaveModalOpen,
        handleLeave,
        handleBlockRoute,
        handleLeaveWithoutCheck
    };
}

export const CreateNineCatTeamTopBar: React.FC<CreateSlateTeamTopBarProps> = ({ slate }) => {
    const { hasSelectPlayer } = useCreateSlateTeamContext();
    const {
        isLeaveModalOpen,
        setLeaveModalOpen,
        handleLeave,
        handleLeaveWithoutCheck,
        handleBlockRoute
    } = useRouteBlockCheck(() => hasSelectPlayer,
        [ROUTER_PATH_CONFIG.myMatch.index]
    );

    return (
        <>
            <TopNavbar
                type={TopNavbarType.DARK}
                onLeaveClick={handleLeave}
                title={
                    <NineCatDescriptionWithIcon>
                        <h2 className={"tw-text-h5 tw-font-bold"}>
                            9-Cat
                        </h2>
                    </NineCatDescriptionWithIcon>
                }
                slate={slate} />

            <NineCatLeavePopup
                open={isLeaveModalOpen}
                onOpenChange={setLeaveModalOpen}
                onLeaveButtonClick={handleLeaveWithoutCheck}
            />
            <Prompt when message={handleBlockRoute} />
        </>
    );
};
