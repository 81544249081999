import { Placeholder } from "react-bootstrap";
import { TextPlaceHolder } from "../SkeletonStyle";
import MatchCardSkeleton from "../MatchCardSkeleton";
import React from "react";
import { MATCH_CARD_SKELETON_FOOTER_TYPE } from "../MatchCardSkeleton/type";

interface MatchesSkeletonProps {
    footerType?: MATCH_CARD_SKELETON_FOOTER_TYPE
}

export const MatchesSkeleton: React.FC<MatchesSkeletonProps> = ({
                                                                    footerType
                                                                }) => {
    return <>
        <Placeholder animation="glow">
            <TextPlaceHolder />
        </Placeholder>
        {
            [...Array(10)].map((_, index) => <MatchCardSkeleton key={index} footerType={footerType} />)
        }
    </>;
};
