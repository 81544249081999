import { ApiError } from "apis/Api";
import { Callback } from "Common";
import CreateTeamPanel from "domain/slate/components/CreateTeamPanel";
import { FilledPlayerInfoVO, NINE_CAT_STAT } from "domain/slate/data/ComponentTypes";
import { useCreateSlateTeamContext } from "domain/slate/providers/CreateNineCatTeamProvider";
import { useNineCatContext } from "domain/slate/providers/NineCatProvider";
import SlateRepository, { toCreateMyTeamRequest } from "domain/slate/repository/SlateRepository";
import { createErrorObject } from "helpers/ErrorHelper";
import useFantasyCommand from "hooks/useFantasyCommand";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { addErrorPopup } from "store/actions/AlertActions";

interface CreateSlateTeamPanelContainerProps {
    filledPlayerInfoList: FilledPlayerInfoVO[];
    onClearAll: Callback;
}

export const CreateSlateTeamPanelContainer: React.FC<CreateSlateTeamPanelContainerProps> = ({
                                                                                                filledPlayerInfoList,
                                                                                                onClearAll,
                                                                                            }) => {
    const { slateId } = useParams();
    const { toJoinSlateContestPage, refetchMyTeams, isRestrictedByKyc } = useNineCatContext();
    const dispatch = useDispatch();

    const {
        focusedStat,
        handleClearStatPlayerIdMap,
        handleToggleFocusStat,
        statToPlayerIdMap
    } = useCreateSlateTeamContext();


    const createTeamCommand = useFantasyCommand(new SlateRepository().createMyTeam);

    const handleDone = async () => {
        if (await isRestrictedByKyc()) {
            return;
        }
        if (createTeamCommand.isLoading) return;

        try {
            await createTeamCommand.mutateAsync(toCreateMyTeamRequest(slateId, statToPlayerIdMap));
        } catch (e) {
            dispatch(addErrorPopup(createErrorObject(e as ApiError)));
        }

        await refetchMyTeams();
        handleClearStatPlayerIdMap();
        toJoinSlateContestPage();
    };

    function handleClearAll() {
        onClearAll();
    }


    function handleToggleSelectStat(stat: NINE_CAT_STAT | undefined) {
        handleToggleFocusStat(stat);
    }

    return (
        <div className={"tw-py-4"}>
            <CreateTeamPanel
                focusedStat={focusedStat}
                filledPlayerInfoList={filledPlayerInfoList}
                onSelectStat={handleToggleSelectStat}
                onClearAll={handleClearAll}
                onDone={handleDone} />
        </div>
    );
};
