import { fetchCampaign } from "apis/campaign/CampaignApi";
import { createErrorObject } from "helpers/ErrorHelper";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import { ERROR_HANDLER_FLOW } from "hooks/useHandleApiError";
import { useDispatch } from "react-redux";

import { addErrorPopup } from "store/actions/AlertActions";


export enum CampaignStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE"

}

export const useCampaignStatus = () => {
    const dispatch = useDispatch();

    const { data: campaignStatus } = useFantasyQuery([QUERY_KEY.CAMPAIGN_STATUS], fetchCampaign, {
        onFail: _error => {
            dispatch(addErrorPopup(createErrorObject(_error)));
            return ERROR_HANDLER_FLOW.STOP;
        }
    });

    return campaignStatus ? campaignStatus : CampaignStatus.INACTIVE;
};
