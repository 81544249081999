import CopyInvitationCodeButtonWithModal from "components/copyCode/CopyInvitationCodeButtonWithModal";
import ContestMaxPrizePool from "domain/contest/components/ContestMaxPrizePool";
import JoinContestButton from "domain/match/pages/upcoming/contest/card/button/JoinContestButton";
import { ContestCardFactoryProps } from "domain/match/pages/upcoming/contest/card/ContestCardFactory";
import {
    Contest,
    ContestBodyRow,
    ContestCardInfo,
    ContestFooterRow,
    ContestInfoRow,
    ContestName
} from "domain/match/pages/upcoming/contest/card/ContestCardStyle";
import MyJoinedTeamList from "domain/match/pages/upcoming/contest/card/joined/MyJoinedTeamList";
import ContestInfoTagList from "domain/match/pages/upcoming/contest/card/tag/ContestInfoTagList";
import { CreateContestCardTags } from "helpers/ContestHelper";
import { isMatchJoinable } from "helpers/match/MatchHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootStoreState } from "store/reducer";
import useUserIdentity from "../../../../../../hooks/useUserIdentity";

const NonPracticeContestCard: React.FC<ContestCardFactoryProps> = ({
                                                                       matchStatus,
                                                                       contest,
                                                                       onContestClick,
                                                                       openContestPanel
                                                                   }) => {
    const {
        SettingsState: {
            contest: {
                spotsToMaxEntries,
                defaultMaxEntries
            }
        }
    } = useSelector((store: RootStoreState) => store);
    const { isB2C } = useUserIdentity();
    const {
        name,
        myJoinedTeamsNames,
    } = contest;
    const intl = useIntl();

    const {
        currentCurrencyObject: { toPeriodNumber },
    } = useCurrency();

    const getContestCardTags = CreateContestCardTags(isB2C, intl.formatMessage, toPeriodNumber);

    return (
        <Contest
            className="clickable animate__animated animate__fadeIn"
            onClick={(e) => {
                onContestClick(e, contest);
            }}
        >
            <ContestBodyRow>
                <ContestCardInfo>
                    <ContestName className="fz-sf-regular-body-2">{name}</ContestName>
                    <ContestInfoRow>
                        <ContestMaxPrizePool contest={contest} />
                    </ContestInfoRow>
                </ContestCardInfo>
                <JoinContestButton contest={contest}
                                   matchStatus={matchStatus}
                                   openContestPanel={openContestPanel} />
            </ContestBodyRow>
            <ContestFooterRow>
                <ContestInfoTagList
                    tags={getContestCardTags(contest, spotsToMaxEntries, defaultMaxEntries, matchStatus)}>
                    {
                        isMatchJoinable(matchStatus) &&
                        <CopyInvitationCodeButtonWithModal contestCode={contest.code} contestId={contest.id} />
                    }
                </ContestInfoTagList>
            </ContestFooterRow>
            <MyJoinedTeamList myJoinedTeamsNames={myJoinedTeamsNames} />
        </Contest>
    );
};

export default NonPracticeContestCard;



