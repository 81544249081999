import {
    CustomAlertWrapper,
    ToastCloseButton,
    ToastContent,
    ToastWrapper
} from "designToken/alert/components/AlertStyle";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { useSelector } from "store";

import { removeToast } from "store/actions/AlertActions";
import { closeAlertMillisecond, TOAST_TYPE } from "../AlertHelper";


const intiNotificationState = {
    type: TOAST_TYPE.SUCCESS,
    content: ""
};

interface Toast {
    type: TOAST_TYPE,
    content: string | React.ReactNode,
    icon?: React.ReactNode
    closeAlertMillisecond?: number
}

const AlertToast = () => {
    const dispatch = useDispatch();
    const { AlertState: { notification: stateNotification } } = useSelector(store => store);

    const [toast, setToast] = useState<Toast>(intiNotificationState);
    const [showToast, setShowToast] = useState(false);

    const toastRef = useRef(null);
    let timer = useRef<NodeJS.Timeout | null>(null);

    const clearNotification = () => {
        setToast(intiNotificationState);
    };

    const closeToast = useCallback(() => {
        dispatch(removeToast());
        if (timer.current) {
            clearTimeout(timer.current);
        }

        setShowToast(false);
    }, [dispatch]);

    useEffect(() => {
        if (stateNotification[0]) {
            setToast({
                type: stateNotification[0].type,
                content: stateNotification[0].content,
                icon: stateNotification[0].icon,
                closeAlertMillisecond: stateNotification[0].closeAlertMillisecond
            });
        }
    }, [stateNotification]);

    useEffect(() => {
        if (toast.content) {
            setShowToast(true);

            timer.current = setTimeout(() => {
                closeToast();
            }, toast.closeAlertMillisecond ?? closeAlertMillisecond);
        }
    }, [toast, closeToast]);


    return (
        toast.content
            ? <CustomAlertWrapper>
                <CSSTransition in={showToast}
                               classNames="alert"
                               nodeRef={toastRef}
                               timeout={250}
                               unmountOnExit
                               onExited={clearNotification}>
                    <ToastWrapper ref={toastRef} $type={toast.type}>
                        {
                            toast.icon
                                ? <div className="me-3">{toast.icon}</div>
                                : <div className="alert-icon" />
                        }
                        {
                            typeof toast.content === "string"
                                ? <ToastContent>{toast.content}</ToastContent>
                                : toast.content
                        }
                        <ToastCloseButton onClick={closeToast} />
                    </ToastWrapper>
                </CSSTransition>
            </CustomAlertWrapper>
            : null
    );
};

export default AlertToast;
