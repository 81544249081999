import React, { ReactNode } from "react";
import styled from "styled-components";

export enum BADGE_SIZE {
    SM = "sm",
    MD = "md"
}

export enum BADGE_VARIANTS {
    PRIMARY = "primary",
    PRIMARY_LIGHT = "primary-light",
    SECONDARY = "secondary",
    TERTIARY = "tertiary",
    SUCCESS = "success",
    DANGER = "danger",
    DANGER_2 = "danger-2",
    WARNING = "warning",
    WARNING_2 = "warning-2",
    INFO = "info",
    LIGHT = "light",
    DARK = "dark",
    OUTLINE_PRIMARY = "outline-primary",
    OUTLINE_PRIMARY_LIGHT = "outline-primary-light",
    OUTLINE_SECONDARY = "outline-secondary",
    OUTLINE_TERTIARY = "outline-tertiary",
    OUTLINE_SUCCESS = "outline-success",
    OUTLINE_DANGER = "outline-danger",
    OUTLINE_DANGER_2 = "outline-danger-2",
    OUTLINE_WARNING = "outline-warning",
    OUTLINE_WARNING_2 = "outline-warning-2",
    OUTLINE_INFO = "outline-info",
    OUTLINE_LIGHT = "outline-light",
    OUTLINE_DARK = "outline-dark"
}

export enum BADGE_PATTERN {
    ROUNDED = "rounded",
    PILL = "pill"
}

const StyledBadge = styled.div.attrs<{
    $variant: BADGE_VARIANTS,
    $pattern: BADGE_PATTERN,
    $size: BADGE_SIZE
}>(({ $variant, $pattern, $size }) => ({
    className: `d-flex align-items-center badge badge-${$variant} badge-${$pattern}-${$size} ${BADGE_SIZE.MD === $size ? "fz-sf-regular-footnote" : "fz-sf-regular-caption-1"}`
}))`
    .text {
        font: inherit;
    }
`;

interface BadgeProps {
    size: BADGE_SIZE
    variant: BADGE_VARIANTS
    pattern: BADGE_PATTERN
    icon?: any
    className?: string
    onClick?: () => void
    children?: ReactNode
    style?: Object
    dataTestId?: string
}


const Badge = React.forwardRef(({
                                    size = BADGE_SIZE.SM,
                                    variant = BADGE_VARIANTS.PRIMARY,
                                    pattern = BADGE_PATTERN.ROUNDED,
                                    icon = "",
                                    className = "",
                                    onClick = () => {
                                    },
                                    children = null,
                                    style = {},
                                    dataTestId
                                }: BadgeProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    return (
        <StyledBadge ref={ref}
                     className={className}
                     $variant={variant}
                     $pattern={pattern}
                     $size={size}
                     onClick={onClick}
                     style={style}
                     data-testid={dataTestId}
        >
            {icon && <img src={icon} alt="badge-icon" />}
            <span className="text">{children}</span>
        </StyledBadge>
    );
});

export default Badge;
