import useDevice from "hooks/useDevice/useDevice";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { getSportConfig } from "domain/SportConfig";
import { useSelector } from "../../../store";
import PreviewPlayer from "./PreviewPlayer";
import { PlayerRow, TeamPreviewContainer, TitleCol, TitleRow } from "./TeamPreviewStyle";

export const TeamPreviewWithPlayer: React.FC = () => {
    const {
        TeamPreviewState: {
            config: {
                sport,
                onAvatarClick
            },
            data: {
                players,
                match,
            }
        }
    } = useSelector(state => state);

    const { roles } = getSportConfig(sport);
    const { deviceHeight } = useDevice();
    const [teamPreviewScale, setTeamPreviewScale] = useState(1);

    useEffect(() => {
        setTeamPreviewScale(Math.min(deviceHeight / 800, 1));
    }, [deviceHeight]);

    if (!match) {
        return null;
    }

    return <TeamPreviewContainer scale={teamPreviewScale} data-testid="team-preview-with-player">
        {
            Object.values(roles).map(role => {
                return <div key={`team-preview-${role.apiValue}`}>
                    <TitleRow>
                        <TitleCol><FormattedMessage
                            id={`player_role_${sport}_${role.apiValue}`} /></TitleCol>
                    </TitleRow>
                    <PlayerRow>
                        {
                            players
                                .filter(player => player.role === role.apiValue)
                                .map(player => {
                                    return <PreviewPlayer key={player.id}
                                                          player={player}
                                                          matchStatus={match.matchStatus}
                                                          squadACode={match.squadACode}
                                                          onAvatarClick={onAvatarClick}
                                    />;
                                })
                        }
                    </PlayerRow>
                </div>;
            })
        }
    </TeamPreviewContainer>;
};
