import React from "react";

import { useApLevelCarouselConfig } from "./hooks/useApLevelCarouselConfig";

interface ProgressBarProps {
    progress: number
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
    const { scale, leftBezierConfig, rightBezierConfig, width } = useApLevelCarouselConfig();

    return <div className="position-absolute">
        <svg width={width} height={52 * scale}
             viewBox={`0 0 ${width} ${52 * scale}`}
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d={`M${leftBezierConfig.startPoint[0]} ${leftBezierConfig.startPoint[1]}
                    C${leftBezierConfig.curvePoint1[0]} ${leftBezierConfig.curvePoint1[1]} 
                     ${leftBezierConfig.curvePoint2[0]} ${leftBezierConfig.curvePoint2[1]}
                     ${leftBezierConfig.endPoint[0]} ${leftBezierConfig.endPoint[1]}
                    C${rightBezierConfig.curvePoint1[0]} ${rightBezierConfig.curvePoint1[1]}
                     ${rightBezierConfig.curvePoint2[0]} ${rightBezierConfig.curvePoint2[1]}
                     ${rightBezierConfig.endPoint[0]} ${rightBezierConfig.endPoint[1]}
                `}
                stroke="url(#paint0_linear_10894_185235)" />
            <defs>
                <linearGradient id="paint0_linear_10894_185235"
                                x1="0"
                                y1={51 * scale}
                                x2={width * progress}
                                y2={51 * scale}
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0%" stopColor="#00E2FF" stopOpacity="0" />
                    <stop offset="30%" stopColor="#00E2FF" />
                    <stop offset="90%" stopColor="#00E2FF" />
                    <stop offset="100%" stopColor="#00E2FF" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    </div>;
};
