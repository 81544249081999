import FantasyBasicPopup, { FANTASY_POPUP_BACKGROUND, POPUP_SIZE } from "components/popup/FantasyBasicPopup";
import BasketballScoringView from "domain/team/player/components/scoringPopup/components/BasketballScoringView";
import CricketScoringView from "domain/team/player/components/scoringPopup/components/CricketScoringView";
import FootballScoringView from "domain/team/player/components/scoringPopup/components/FootballScoringView";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { SPORT } from "types/sports/Sport";

interface ScoringPopupProps {
    show: boolean;
    sport: SPORT;
    onClose: () => void;
}

const getScoringContent = (sport: SPORT) => {
    switch (sport) {
        case SPORT.CRICKET:
            return <CricketScoringView />;
        case SPORT.FOOTBALL:
            return <FootballScoringView />;
        case SPORT.BASKETBALL:
            return <BasketballScoringView />;
    }
};

const ScoringPopup: React.FC<ScoringPopupProps> = ({ show, sport, onClose }) => {
    const intl = useIntl();

    const scoringContent = getScoringContent(sport);

    useEffect(() => {
        if (show) {
            const timer = setTimeout(() => {
                document.body.style.pointerEvents = "";
            }, 0);

            return () => clearTimeout(timer);
        } else {
            document.body.style.pointerEvents = "auto";
        }
    }, [show]);

    return <FantasyBasicPopup
        show={show}
        title={intl.formatMessage({ id: "scoring_popup_title" })}
        content={scoringContent}
        onHide={onClose}
        popupSize={POPUP_SIZE.SCORING}
        buttonSetting={{
            confirmButton: false,
            cancelButton: false,
        }}
        background={FANTASY_POPUP_BACKGROUND.white}
        offset
    />;
};

export default ScoringPopup;
