import Button from "designToken/button/Button";
import { emptyNineCatImg, Icon } from "helpers/IconHelper";
import React from "react";
import { FormattedMessage } from "react-intl";
import { BUTTON_PATTERN, BUTTON_SIZE, NEW_BUTTON_VARIANTS } from "../../designToken/button/types";
import useFantasyHistory from "../../hooks/useFantasyHistory";
import { ROUTER_PATH_CONFIG } from "../../router/RouterPathConfig";

export const MyEmptyNineCat = () => {
    const history = useFantasyHistory();

    return (
        <div
            className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-4 tw-py-7"
            data-testid="my-match-9-cat-empty"
        >
            <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-[30rem] tw-text-center">
                <img src={emptyNineCatImg} alt="my_empty_nine_cat" className="tw-w-[20rem] tw-h-auto" />
                <p className="tw-text-body-2 tw-text-grey-1000 tw-font-thin" data-testid="my-matches-empty-text">
                    <FormattedMessage id="my_games_page_description_empty_9_cat" />
                </p>
            </div>

            <Button
                variant={NEW_BUTTON_VARIANTS.secondaryLight}
                pattern={BUTTON_PATTERN.pill}
                size={BUTTON_SIZE.sm}
                onClick={() => {
                    history.push(ROUTER_PATH_CONFIG.home);
                }}
                prefixIcon={Icon.FireIcon()}
            >
                <FormattedMessage id="label_join_now" />
            </Button>

        </div>
    );

};

export default MyEmptyNineCat;
