import { QueryObserverResult } from "@tanstack/react-query";
import { NineCatPlayerDetailVO } from "domain/slate/data/ComponentTypes";
import { MyNineCatTeamDto } from "domain/slate/data/dto";
import { MySlateTeamVo } from "domain/slate/data/vo";
import { useQuerySlatePlayers } from "domain/slate/hooks/useQuerySlatePlayers";
import SlateRepository from "domain/slate/repository/SlateRepository";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import _ from "lodash";
import { SPORT } from "../../../types/sports/Sport";

export interface QueryMySlateTeamsResponse {
    data: MySlateTeamVo[],
    isLoading: boolean,
    error: any,
    refetch: () => Promise<QueryObserverResult<MyNineCatTeamDto[]>>,
}

export const useQueryMyNineCatTeams = (slateId: number): QueryMySlateTeamsResponse => {

    const { data: players } = useQuerySlatePlayers(slateId);

    const playerIdsToPlayer = players.reduce((idToPlayer: Record<number, NineCatPlayerDetailVO>, player) => {
        idToPlayer[player.id] = player;
        return idToPlayer;
    }, {});

    const myTeamsData = useFantasyQuery([QUERY_KEY.GET_MY_NINE_CAT_SLATE_TEAM, slateId], () => {
        return new SlateRepository().getMyTeam(slateId, SPORT.BASKETBALL);
    }, {
        options: {
            enabled: !!slateId,
        }
    });

    const { data: myTeams } = myTeamsData;

    if (_.isEmpty(playerIdsToPlayer) || !myTeams) {
        return {
            ...myTeamsData,
            isLoading: true,
            data: [],
        };
    }

    return {
        ...myTeamsData,
        data: myTeams.map(team => new MySlateTeamVo({
            id: team.id,
            name: team.name,
            points: playerIdsToPlayer[team.points],
            fieldGoalsEfficiency: playerIdsToPlayer[team.fieldGoalsEfficiency],
            freeThrowsEfficiency: playerIdsToPlayer[team.freeThrowsEfficiency],
            offensiveRebounds: playerIdsToPlayer[team.offensiveRebounds],
            defensiveRebounds: playerIdsToPlayer[team.defensiveRebounds],
            assists: playerIdsToPlayer[team.assists],
            steals: playerIdsToPlayer[team.steals],
            blocks: playerIdsToPlayer[team.blocks],
            threePointersMade: playerIdsToPlayer[team.threePointersMade]
        })),
    };


};
