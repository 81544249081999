import { WalletBalanceLabel } from "domain/wallet/components/button/WalletButton";
import { WalletBalance } from "domain/wallet/components/button/WalletButtonStyle";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";
import { Icon } from "../../../../helpers/IconHelper";
import { TransferButton } from "../buttons/TransferButton";
import {
    CareerStatsTabInItem,
    CareerStatsTabInItemWrapper,
    UserInfoPanelWrapper,
    UserInfoWrapper
} from "../UserProfileStyle";
import useUserProfileContext from "../useUserProfileContext";
import { UserProfileCareerStats } from "./careerStats/UserProfileCareerStats";
import { ReachedFollowingLimit } from "./ReachedFollowingLimit";

const UserProfilePanel: React.FC = () => {

    const { userInfo: otherUser, careerStatsList: [otherCareerStats, myCareerStats] } = useUserProfileContext();

    const [tab, setTab] = useState(ENTRY_FEE_TYPE_FROM_API.DEPOSIT);

    return <UserInfoWrapper>l
        <UserInfoPanelWrapper>
            <div className="mb-4">
                <WalletBalance>
                    <Icon.WalletBalance className="text-text-secondary-1" />
                    <div className="text-light-secondary fz-sf-regular-caption-1">
                        <FormattedMessage id="wallet_view_title" />
                    </div>
                </WalletBalance>
                <div className="d-flex justify-content-center">
                    <WalletBalanceLabel total={divideByOneHundred(otherUser.walletBalanceCent)} />
                    <TransferButton />
                </div>
            </div>

            <CareerStatsTabInItemWrapper>
                <CareerStatsTabInItem
                    className={`me-4 ${tab === ENTRY_FEE_TYPE_FROM_API.DEPOSIT ? "nav-tabs-on-line" : "nav-tabs-off-text"}`}
                    onClick={() => setTab(ENTRY_FEE_TYPE_FROM_API.DEPOSIT)}
                >
                    <FormattedMessage id="label_deposit" />
                </CareerStatsTabInItem>
                <CareerStatsTabInItem
                    className={`${tab === ENTRY_FEE_TYPE_FROM_API.RUBY ? "nav-tabs-on-line" : "nav-tabs-off-text"}`}
                    onClick={() => setTab(ENTRY_FEE_TYPE_FROM_API.RUBY)}
                >
                    <FormattedMessage id="label_ruby" />
                </CareerStatsTabInItem>
            </CareerStatsTabInItemWrapper>

            <UserProfileCareerStats from={myCareerStats.from}
                                    until={myCareerStats.until}
                                    myCareerStats={myCareerStats.getStatsWithRegisteredByEntryFeeType(tab)}
                                    otherCareerStats={otherCareerStats.getStatsWithRegisteredByEntryFeeType(tab)}
            />
        </UserInfoPanelWrapper>
        <ReachedFollowingLimit />
    </UserInfoWrapper>;
};

export default UserProfilePanel;
