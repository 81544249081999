import { showTextViaQuantity } from "domain/reward/RewardHelper";
import React from "react";
import { MdOutlineShoppingBag } from "react-icons/md";
import styled from "styled-components";

const RewardQuantityWrapper = styled.div.attrs(() => ({
    className: "d-flex py-1 align-items-center my-1 text-text-secondary-1"
}))``;
const RewardQuantityInfo = styled.div.attrs<{ fontStyle: string }>(({ fontStyle }) => ({
    className: `${fontStyle}`
}))`
  margin-left: 0.6rem;
`;

interface RewardQuantityProps {
    quantity: number;
    iconSize: string;
    fontStyle: string;
}

const RewardQuantity = ({ quantity, iconSize, fontStyle }: RewardQuantityProps) => {
    return (
        <RewardQuantityWrapper>
            <MdOutlineShoppingBag size={iconSize} />
            <RewardQuantityInfo fontStyle={fontStyle}>{showTextViaQuantity(quantity)}</RewardQuantityInfo>
        </RewardQuantityWrapper>
    );
};

export default RewardQuantity;
