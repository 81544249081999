import React from "react";
import { MATCH_STATUS_FROM_API } from "../../../types/match/Match";
import { MyTeamVO } from "../../../types/team/MyTeam";
import { TeamCard } from "../../card/TeamCardStyle";
import TeamCardContent from "../../team/TeamCardContent";
import { CheckBoxWrapper, TeamName, TeamNameWrapper } from "../../team/TeamCardStyle";
import { TeamCardWrapper } from "../SelectTeamPanelStyle";
import { FantasyCheckbox } from "components/checkbox/FantasyCheckbox";
import { CHECKBOX_VARIANTS } from "components/checkbox/types";

interface SelectedCardProps {
    team: MyTeamVO;
    onTeamUnselected: (teamId: number) => void;
    matchStatus: MATCH_STATUS_FROM_API;
}

export const SelectedCard: React.FC<SelectedCardProps> = ({
                                                              team,
                                                              onTeamUnselected,
                                                              matchStatus
                                                          }) => {
    return <TeamCardWrapper className="clickable fantasy-select-team-card-border"
                            data-testid={`my-team-${team.id}`}
                            data-selected
                            onClick={() => onTeamUnselected(team.id)}>
        <TeamCard>
            <TeamCardContent team={team} matchStatus={matchStatus}>
                <TeamNameWrapper $highlight>
                    <CheckBoxWrapper>
                        <FantasyCheckbox variant={CHECKBOX_VARIANTS.secondary} checked />
                    </CheckBoxWrapper>
                    <TeamName>{team.name}</TeamName>
                </TeamNameWrapper>
            </TeamCardContent>
        </TeamCard>
    </TeamCardWrapper>;
};
