import { Callback } from "Common";
import { cn } from "helpers/cn";
import { Icon } from "helpers/IconHelper";
import React from "react";
import { FormattedMessage } from "react-intl";

interface DoneButtonProps {
    disabled?: boolean;
    onDone: Callback;
}

export const DoneButton: React.FC<DoneButtonProps> = ({
                                                          disabled,
                                                          onDone
                                                      }) => {
    return <button
        data-testid={"done-button"}
        disabled={disabled}
        className={cn(
            "tw-flex tw-justify-center tw-items-center tw-p-1.5 tw-h-[4.6rem] tw-rounded-2xl",
            "hover:tw-bg-gradient-to-l",
            disabled ? "tw-bg-gray-100 " : "tw-bg-gradient-to-r tw-from-secondary-150 tw-to-primary-150 tw-shadow-sm",
        )}
        onClick={onDone}
    >
        <Icon.Check className={cn(disabled ? "tw-text-gray-300" : "tw-text-secondary-600")} size={"1.2rem"} />
        <p className={cn(
            "tw-pl-1.5 tw-font-bold tw-text-xl",
            disabled ? "tw-text-gray-300" : "tw-text-black")
        }>
            <FormattedMessage id="button_done" />
        </p>
    </button>;
};
