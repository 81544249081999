import {
    MatchCard,
    MatchCardFooter,
    MatchCardHeader,
    MatchCardTournament,
    MatchStatus
} from "components/matchCards/MatchCardStyle";
import { StartedMatchCardBody } from "components/matchCards/StartedMatchCardBody";
import { KickoffCountdown } from "domain/home/components/upcoming/UpcomingMatches";
import { LineupsOut } from "domain/match/components/LineupsOut";
import MatchInfoSummaryAndMyJoinedTeams from "domain/match/components/myMatches/MatchInfoSummaryAndMyJoinedTeams";
import MatchStatusWithDot from "domain/match/components/status/MatchStatusWithDot";
import { isMatchKickoff, isMatchProcessing } from "helpers/match/MatchHelper";
import { sortByMatchStartsAtDesc } from "helpers/SortingHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import React from "react";
import { FormattedMessage } from "react-intl";
import { getStartedMatchDetailPageUrl } from "router/RouterUrls";
import { MyMatch } from "types/match/MyMatch";
import { MyMatchVo } from "../../../../data/vo/match/MyMatchVo";
import MyMatchItemWrapper from "./MyMatchItemWrapper";

const MatchItem = ({ match, handleTimeout }) => {
    const history = useFantasyHistory();

    const handleMatchClick = () => {
        history.push(getStartedMatchDetailPageUrl(match.sport, match.matchId));
    };

    return <MatchCard
        data-testid={`my-match-${match.matchId}`}
        className="animate__animated animate__fadeIn"
        onClick={handleMatchClick}
    >
        <MatchCardHeader>
            <MatchCardTournament>{match.tournament}</MatchCardTournament>
            <LineupsOut matchStatus={match.matchStatus} isLineupsOut={match.isLineupsOut} />
        </MatchCardHeader>
        <StartedMatchCardBody match={{ ...match, winningSquadCode: "" }}>
            <MatchStatus $matchStatus={match.matchStatus}>
                <MatchStatusWithDot matchStatus={match.matchStatus}
                                    shouldBlink={isMatchProcessing(match.matchStatus)} />
                {
                    isMatchKickoff(match.matchStatus)
                    && <KickoffCountdown key={`${match.matchId}-${match.matchStartsAt}`}
                                         matchStartsAt={match.matchStartsAt}
                                         handleTimeout={handleTimeout} />
                }
            </MatchStatus>
        </StartedMatchCardBody>
        <MatchCardFooter>
            <MatchInfoSummaryAndMyJoinedTeams match={match} />
        </MatchCardFooter>
    </MatchCard>;
};

interface MyMatchLiveProps {
    handleTimeout: Function,
    matches: MyMatchVo[] | MyMatch[]
}

const MyMatchLive: React.FC<MyMatchLiveProps> = ({ matches, handleTimeout }) => {
    if (matches.length === 0) {
        return null;
    }

    return (
        <MyMatchItemWrapper
            title={<FormattedMessage id="label_live_matches" />}
            titleTestId="my-matches-title-live"
        >
            {
                sortByMatchStartsAtDesc(matches).map((match) => (
                    <MatchItem
                        key={match.matchId}
                        match={match}
                        handleTimeout={handleTimeout}
                    />
                ))
            }
        </MyMatchItemWrapper>
    );
};

export default MyMatchLive;
