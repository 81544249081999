import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { ErrorParameterImg, ErrorStatus, ErrorText, ErrorTitle } from "./ErrorContentStyle";

const ErrorContent = ({ error, title }) => {
    const now = moment().format("YYYY-MM-DD HH:mm:ssZ");

    return (
        <>
            <ErrorTitle className={title}>{error.message || "CONNECTION ERROR"}</ErrorTitle>
            <ErrorStatus>{error.status}</ErrorStatus>
            <ErrorParameterImg img={error.img} />
            <ErrorText>{now}</ErrorText>
        </>
    );
};

ErrorContent.propTypes = {
    title: PropTypes.string,
    error: PropTypes.shape({
        message: PropTypes.string,
        status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        img: PropTypes.string
    })
};

export default ErrorContent;
