import { ApiExecuteContext } from "apis/ApiContext";
import { updateMyProfile } from "apis/user/UserApi";
import { useLoadingToggle } from "components/loading/Loading";
import { TOAST_TYPE } from "designToken/alert/components/AlertHelper";
import { createErrorObject, ERROR_CODE } from "helpers/ErrorHelper";
import { isNicknameLengthValid, isNicknameLettersAndNumbers } from "helpers/RegexHelper";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { IntlShape } from "react-intl/lib";
import { useDispatch } from "react-redux";
import { addErrorPopup, addToast } from "store/actions/AlertActions";

interface UserProfileOptions {
    myProfile: any;
    getMyProfile: Function;
}

const getIntlNicknameInputErrMessage = (nickname: string, intl: IntlShape) => {
    if (!isNicknameLettersAndNumbers(nickname))
        return intl.formatMessage({ id: "nickname_input_error_format_letters_and_numbers" });
    if (!isNicknameLengthValid(nickname))
        return intl.formatMessage({ id: "nickname_input_error_char_lengths" });

    return undefined;
};

const useProfile = ({
                        myProfile,
                        getMyProfile
                    }: UserProfileOptions) => {
    const navbarRef = useRef<HTMLDivElement>(null);

    const toggleLoading = useLoadingToggle();
    const apiExecutor = useContext(ApiExecuteContext);
    const dispatch = useDispatch();
    const [containerHeight, setContainerHeight] = useState(0);
    const [isEditing, setEditing] = useState(false);

    const [nicknameErrorMessage, setNicknameErrorMessage] = useState<string>();

    const [profileData, setProfileData] = useState({
        description: "",
        nickname: ""
    });
    const intl = useIntl();
    const onCopy = () => {
        dispatch(addToast({
            type: TOAST_TYPE.SUCCESS,
            content: intl.messages["id_copied_toast_label"]
        }));
    };

    const handleUpdateAboutMe = () => {
        toggleLoading(true);

        apiExecutor(
            updateMyProfile(profileData),
            {
                onSuccess: () => {
                    getMyProfile();
                    dispatch(addToast({
                        type: TOAST_TYPE.SUCCESS,
                        content: intl.formatMessage({
                            id: "toast_message_successfully_changed"
                        })
                    }));
                },
                onFail: error => {
                    const errCode = error?.response?.data?.errorCode;
                    if (errCode === ERROR_CODE.NICKNAME_DUPLICATED) {
                        setNicknameErrorMessage(
                            intl.formatMessage({
                                id: "nickname_input_error_duplicate"
                            })
                        );
                        return;
                    }

                    dispatch(addErrorPopup(createErrorObject(error)));
                },
                onFinally: () => {
                    setEditing(false);
                    toggleLoading(false);
                }
            }
        );
    };

    const handleUpdateDescription = useCallback((val) => {
        setProfileData(p => ({
            ...p,
            description: val
        }));
    }, []);

    const handleUpdateNickname = useCallback((val) => {
        setProfileData(p => ({
            ...p,
            nickname: val
        }));

        const errMessage = getIntlNicknameInputErrMessage(val, intl);

        setNicknameErrorMessage(errMessage);
    }, [intl]);

    useEffect(() => {
        setProfileData(myProfile);
    }, [myProfile]);

    useEffect(() => {
        if (!navbarRef.current) return;

        const { height } = navbarRef.current.getBoundingClientRect();
        setContainerHeight(height);
    }, []);


    return ({
        navbarRef,
        containerHeight,
        profileData,
        nicknameErrorMessage,
        isEditing,
        setEditing,
        onCopy,
        handleUpdateAboutMe,
        handleUpdateDescription,
        handleUpdateNickname,
    });
};

export default useProfile;
