import { defaultLocale } from "locales";
import _ from "lodash";

// indian
export const toIndianPeriodNumber = (cent: number) => {
    let result = "";
    let numberUnit = "";
    let singleDigit = divideByOneHundred(cent);

    const oneBillion = 1_000_000_000,
        tenMillion = 10_000_000,
        hundredThousand = 100_000;

    if (isNaN(+cent) || cent < 0) return result;

    if (singleDigit >= oneBillion) {
        result = _.floor(singleDigit / oneBillion, 2).toString();
        numberUnit = " Arabs"; // 10 的 9 次方
    } else if (singleDigit >= tenMillion) {
        result = _.floor(singleDigit / tenMillion, 2).toString();
        numberUnit = " Crores"; // 10 的 7 次方
    } else if (singleDigit >= hundredThousand) {
        result = _.floor(singleDigit / hundredThousand, 2).toString();
        numberUnit = " Lakhs"; // 10 的 5 次方
    } else result = singleDigit.toString();

    return `${toIndianFormattedNumber(result)}${numberUnit}`;
};

export const toIndianFormattedNumber = (number, fractionDigits = 0) => {
    return new Intl.NumberFormat("hi", { minimumFractionDigits: fractionDigits }).format(number);
};

export const toIndianWalletNumber = number => {
    return new Intl.NumberFormat("hi", { minimumFractionDigits: 2 }).format(number);
};

// universal
export const toUniversalPeriodNumber = (cent: number) => {
    let result = "";
    let numberUnit = "";
    let singleDigit = divideByOneHundred(cent);

    const oneBillion = 1_000_000_000,
        oneMillion = 1_000_000,
        hundredThousand = 100_000,
        thousand = 1_000;

    if (isNaN(+cent) || cent < 0) return result;

    if (singleDigit >= oneBillion) {
        result = _.floor(singleDigit / oneBillion, 2).toString();
        numberUnit = " B";
    } else if (singleDigit >= oneMillion) {
        result = _.floor(singleDigit / oneMillion, 2).toString();
        numberUnit = " M";
    } else if (singleDigit >= hundredThousand) {
        result = _.floor(singleDigit / thousand, 2).toString();
        numberUnit = " K";
    } else result = singleDigit.toString();

    return `${toUniversalFormattedNumber(result)}${numberUnit}`;
};

export const toUniversalFormattedNumber = (number, fractionDigits = 0) => {
    return new Intl.NumberFormat(defaultLocale, { minimumFractionDigits: fractionDigits }).format(number);
};

export const toUniversalWalletNumber = number => {
    return new Intl.NumberFormat(defaultLocale, { minimumFractionDigits: 2 }).format(number);
};

// others
export const divideByOneHundred = (amount: number) => amount / 100;

export const multiplyByOneHundred = (amount: number) => amount * 100;

export const isNegative = (amount: number) => amount < 0;

export const filterDigits = (input) => input.replace(/\D/g, "");

export const parseNumberToIntegerAndDecimal = number => {
    const [integer, decimal] = toIndianWalletNumber(number).toString().split(".");

    return { integer, decimal };
};

export const pixelToRem = (px: number): number => px / 10;

export const generateNumberArray = (n: number): number[] => {
    return Array.from({ length: n }, (_, index) => index + 1);
};

// input 0, 3
// output 0 | 1 | 2 | 3
export const getRandomInt = (min: number, max: number): number => {
    const _min = Math.ceil(min);
    const _max = Math.floor(max);

    return Math.floor(Math.random() * (_max - _min + 1)) + _min;
};
