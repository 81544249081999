import { Contest } from "../../../../../../apis/match/type";
import { CONTEST_TYPE_FROM_API, ENTRY_FEE_TYPE_FROM_API } from "../../../../../../types/contest/Contest";

export enum PUBLIC_CONTEST_GROUP_TYPE {
    PROMOTIONAL = "PROMOTIONAL",
    PRIZE_HUNTER = "PRIZE_HUNTER",
    DEPOSIT_NORMAL = "DEPOSIT_NORMAL",
    RUBY_NORMAL = "RUBY_NORMAL",
    PRACTICE = "PRACTICE"
}

const getPublicContestGroupType = ({
                                       type,
                                       entryFeeType,
                                   }: Pick<Contest, "type" | "entryFeeType">) => {
    switch (type) {
        case CONTEST_TYPE_FROM_API.PROMOTIONAL:
            return PUBLIC_CONTEST_GROUP_TYPE.PROMOTIONAL;
        case CONTEST_TYPE_FROM_API.PRIZE_HUNTER:
            return PUBLIC_CONTEST_GROUP_TYPE.PRIZE_HUNTER;
        case CONTEST_TYPE_FROM_API.PRACTICE:
            return PUBLIC_CONTEST_GROUP_TYPE.PRACTICE;
        case CONTEST_TYPE_FROM_API.NORMAL: {
            if (entryFeeType === ENTRY_FEE_TYPE_FROM_API.RUBY) return PUBLIC_CONTEST_GROUP_TYPE.RUBY_NORMAL;

            return PUBLIC_CONTEST_GROUP_TYPE.DEPOSIT_NORMAL;
        }

        default:
            return PUBLIC_CONTEST_GROUP_TYPE.DEPOSIT_NORMAL;
    }
};

export default getPublicContestGroupType;