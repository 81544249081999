import React from "react";
import { FormattedMessage } from "react-intl";
import { emptyTactics } from "helpers/IconHelper";
import { EmptyImg, EmptyText, EmptyWrapper } from "./EmptyStyle";


export const UpcomingMatchEmptyContest = () => {
    return <EmptyWrapper>
        <EmptyImg src={emptyTactics} />
        <EmptyText>
            <FormattedMessage id="match_detail_page_my_contests_section_label_empty" />
        </EmptyText>
    </EmptyWrapper>;

};
