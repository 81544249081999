import { TOAST_TYPE } from "designToken/alert/components/AlertHelper";
import useFantasyConfig from "hooks/useFantasyConfig";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";

import { addToast } from "store/actions/AlertActions";

interface CopyContestCodeToClipboardProps {
    code: string;
    children: React.ReactNode;
    onCopied: () => void;
}

export const CopyContestCodeToClipboard: React.FC<CopyContestCodeToClipboardProps> = ({
                                                                                          code,
                                                                                          children,
                                                                                          onCopied
                                                                                      }) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const { gameUrl } = useFantasyConfig();
    const url = gameUrl + ROUTER_PATH_CONFIG.joinContest.replace(/:contestCode/, code);

    const handleCopied = () => {
        dispatch(addToast({
            type: TOAST_TYPE.SUCCESS,
            content: intl.formatMessage({ id: "share_contest_dialog_link_copied" })
        }));

        onCopied();
    };

    return <CopyToClipboard text={url}
                            onClick={e => e.preventDefault()}
                            onCopy={handleCopied}>
        {children}
    </CopyToClipboard>;
};
