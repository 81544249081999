import styled from "styled-components";
import React from "react";


export const TeamCard: React.FC<React.HTMLAttributes<HTMLDivElement>> = styled.div.attrs({
    className: "overflow-hidden"
})`
    border-radius: .8rem;
`;


// team
export const TeamCardHeader: React.FC<React.HTMLAttributes<HTMLDivElement>> = styled.div.attrs({
    className: "p-2"
})``;

// team
export const TeamCardBody: React.FC<React.HTMLAttributes<HTMLDivElement>> = styled.div.attrs({
    className: "py-3 px-4 fantasy-card-body"
})`
`;
