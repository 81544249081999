import Dialog, { DIALOG_SIZE } from "designToken/dialog/Dialog";
import MatchGameLogAndGlossary from "domain/team/playerInfoDialog/gameLog/MatchGameLogAndGlossary";
import { InYourTeams } from "domain/team/playerInfoDialog/inYourTeams/InYourTeams";
import MatchPlayerPerformance from "domain/team/playerInfoDialog/performance/MatchPlayerPerformance";
import {
    PlayerInfoDialogFooterMap,
    PlayerInfoDialogFooterProps,
    PlayerInfoDialogFooterType
} from "domain/team/playerInfoDialog/PlayerInfoDialogFooter";
import {
    PlayerInfoDialogHeaderMap,
    PlayerInfoDialogHeaderProps,
    PlayerInfoDialogHeaderType
} from "domain/team/playerInfoDialog/PlayerInfoDialogHeader";
import {
    PlayerInfoDialogCard,
    PlayerInfoDialogCardBody,
    PlayerInfoDialogWrapper
} from "domain/team/playerInfoDialog/PlayerInfoDialogStyle";
import { PlayerProfile } from "domain/team/playerInfoDialog/profile/PlayerProfile";
import usePlayerInfo from "domain/team/playerInfoDialog/usePlayerInfo";
import useDevice from "hooks/useDevice/useDevice";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { APP_MAX_WIDTH_VALUE } from "styles/Constants";
import { MatchVO } from "types/match/Match";
import { SelectablePlayer, SquadPlayer } from "types/player/Player";
import { MyTeamVO } from "types/team/MyTeam";

interface PlayerInfoDialogProps {
    match: MatchVO;
    players: SquadPlayer[];
    myTeams: MyTeamVO[];
    handleSelectedPlayer?: Function;
    onClose: () => void;
    Header: React.FC<PlayerInfoDialogHeaderProps>;
    Footer: React.FC<PlayerInfoDialogFooterProps>;
}

const PlayerInfoDialog = React.forwardRef(({
                                               players,
                                               match,
                                               myTeams,
                                               handleSelectedPlayer = () => {},
                                               onClose,
                                               Header,
                                               Footer
                                           }: PlayerInfoDialogProps, ref) => {

    const [currentPlayerId, setCurrentPlayerId] = useState<string>("");

    const currentPlayer = players.find(player => player.id === currentPlayerId);

    const { data: playerInfo } = usePlayerInfo(currentPlayer, match);

    const { deviceWidth } = useDevice();

    useImperativeHandle(ref, () => ({
        setCurrentPlayer: (player: SelectablePlayer) => {
            setCurrentPlayerId(prevId => {
                if (prevId === player?.id) return "";
                return player?.id || "";
            });
        }
    }), []);

    useEffect(() => {
        const shouldOverflowAuto = currentPlayerId && deviceWidth >= APP_MAX_WIDTH_VALUE * 2;

        if (shouldOverflowAuto) {
            document.querySelector("body")?.classList.add("overflow-auto");
        } else {
            document.querySelector("body")?.classList.remove("overflow-auto");
        }

        return () => {
            if (shouldOverflowAuto) {
                document.querySelector("body")?.classList.remove("overflow-auto");
            }
        };
    }, [currentPlayerId, deviceWidth]);

    if (!currentPlayer) return null;

    return <Dialog show={true}
                   size={DIALOG_SIZE.LG}
                   contentClassName="bg-transparent border-0 shadow-none"
                   onHide={onClose}
                   data-testid="player-info-dialog"
                   offset>
        <PlayerInfoDialogWrapper>
            <PlayerInfoDialogCard>
                <Header player={currentPlayer}
                        playerInfo={playerInfo}
                        players={players} />
                <PlayerInfoDialogCardBody>
                    <PlayerProfile player={currentPlayer} playerInfo={playerInfo} />
                    <InYourTeams myTeams={myTeams} player={currentPlayer} />
                    <MatchPlayerPerformance player={currentPlayer} playerInfo={playerInfo} />
                    <MatchGameLogAndGlossary player={currentPlayer} playerInfo={playerInfo} />
                </PlayerInfoDialogCardBody>
                <Footer player={currentPlayer}
                        handleSelectedPlayer={handleSelectedPlayer}
                        closeDialog={onClose} />
            </PlayerInfoDialogCard>
        </PlayerInfoDialogWrapper>
    </Dialog>;
});

interface DialogRef {
    setCurrentPlayer: (player: (SelectablePlayer | null)) => void;
}

interface MakePlayerInfoDialogProps {
    match: MatchVO;
    players: SquadPlayer[];
    myTeams: MyTeamVO[];
    handleSelectedPlayer?: Function;
}

const usePlayerInfoDialog = (headerType: PlayerInfoDialogHeaderType, footerType: PlayerInfoDialogFooterType) => {
    const dialogRef = useRef<DialogRef>(null);
    const [isPlayerInfoOpen, setIsPlayerInfoOpen] = useState(false);


    const [component] = useState(() => ({
                                            players,
                                            match,
                                            myTeams,
                                            handleSelectedPlayer,
                                        }: MakePlayerInfoDialogProps) =>
        <PlayerInfoDialog
            ref={dialogRef}
            players={players}
            match={match}
            myTeams={myTeams}
            handleSelectedPlayer={handleSelectedPlayer}
            onClose={handlePlayerInfoDialogClose}
            Header={PlayerInfoDialogHeaderMap[headerType]}
            Footer={PlayerInfoDialogFooterMap[footerType]}
        />);

    const handlePlayerInfoDialogOpen = (player) => {
        dialogRef.current?.setCurrentPlayer(player);
        setIsPlayerInfoOpen(true);
    };

    const handlePlayerInfoDialogClose = () => {
        dialogRef.current?.setCurrentPlayer(null);
        setIsPlayerInfoOpen(false);
    };

    return {
        handlePlayerInfoDialogOpen,
        handlePlayerInfoDialogClose,
        isPlayerInfoOpen,
        PlayerInfoDialog: component
    };
};
export default usePlayerInfoDialog;
