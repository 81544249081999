import { Callback } from "Common";
import NineCatPlayerCard, { NineCatPlayerCardProps } from "domain/slate/components/NineCatPlayerCard";
import React from "react";

export interface NineCatPlayerCardListProps {
    players: Omit<NineCatPlayerCardProps, "onFocusStatPlayer" | "onOpenPlayerInfo">[];
    onOpenPlayerInfo: Callback;
    onFocusStatPlayer: Callback;
}

export const NineCatPlayerCardList: React.FC<NineCatPlayerCardListProps> = ({
                                                                                players,
                                                                                onOpenPlayerInfo,
                                                                                onFocusStatPlayer
                                                                            }) => {
    return <div className={"tw-max-w-[35.8rem] tw-m-auto tw-grid tw-grid-cols-2 tw-gap-2"}>
        {players.map(player => (
            <NineCatPlayerCard
                key={player.playerDetail.id}
                {...player}
                onOpenPlayerInfo={onOpenPlayerInfo}
                onFocusStatPlayer={onFocusStatPlayer}
            />
        ))}
    </div>;
};
