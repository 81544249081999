import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";
import { Logo } from "domain/auth/components/Logo";
import { AuthWelcomeImage, SelectButton, SelectButtonWrapper } from "domain/auth/language/Style";
import { analyticsEvent, logCustomEvent } from "ga";
import useFantasyConfig from "hooks/useFantasyConfig";
import useFantasyHistory from "hooks/useFantasyHistory";
import { useLocaleProvider } from "LocaleProvider";
import { languages } from "locales";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";

const Language: React.FC = () => {
    const history = useFantasyHistory();
    const { locale, setLocale } = useLocaleProvider();
    const { title } = useFantasyConfig();

    const handleRedirect = () => {
        logCustomEvent(analyticsEvent.webLanguage(locale));
        history.push(ROUTER_PATH_CONFIG.auth.index);
    };

    useEffect(() => {
        setLocale(locale);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="px-4 py-5 d-flex flex-column justify-content-between h-100 gap-4">
            <div className="tw-text-center tw-flex tw-flex-col tw-justify-center tw-gap-3">
                <AuthWelcomeImage />
                <Logo />
            </div>
            <div className="flex-grow-1">
                <div className="fz-sf-bold-title-1 mb-2">
                    <FormattedMessage id="auth_select_language_title" />
                </div>
                <div className="fz-sf-regular-body-1 text-text-secondary-1 mb-4">
                    <FormattedMessage id="auth_select_language_description" values={{
                        appName: title
                    }} />
                </div>
                <SelectButtonWrapper>
                    {languages.map(({ key, text }) => (
                        <SelectButton
                            key={key}
                            onClick={() => setLocale(key)}
                            $isActive={locale === key}
                            $testId={`language-${key}`}
                        >
                            {text}
                        </SelectButton>
                    ))}
                </SelectButtonWrapper>
            </div>
            <div>
                <Button
                    variant={BUTTON_VARIANTS.primary}
                    size={BUTTON_SIZE.lg}
                    pattern={BUTTON_PATTERN.pill}
                    onClick={handleRedirect}
                    className="w-100"
                >
                    <FormattedMessage id="auth_select_language_submit_button" />
                </Button>
            </div>
        </div>
    );
};

export default Language;
