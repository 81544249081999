import { ListGroup } from "react-bootstrap";
import styled from "styled-components";

export const ListGroupWrapper = styled(ListGroup).attrs(() => ({
    className: "border-0 mb-4 tw-bg-grey-0",
}))`
    padding: 2rem 1.6rem;
    border-radius: 0.8rem;
`;
export const ListItem = styled.a.attrs(() => ({
    className: "d-flex align-items-center border-0 px-0",
}))`
    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 1.4rem;
    }

    &:hover {
        text-decoration: none !important;
    }
`;

export const IconWrapper = styled.div.attrs<{ $bgColor?: string }>(({ $bgColor }) => ({
    className: ` tw-text-primary-600 ${$bgColor ? $bgColor : "tw-bg-primary-100"}`
}))`
    padding: 1rem;
    border-radius: 0.8rem;
`;

export const ListName = styled.div.attrs(() => ({
    className: "fz-sf-regular-body-1 tw-text-system-primary80",
}))`
    margin: 0 0.8rem;
`;
export const InfoText = styled.div.attrs({
    className: "fz-sf-regular-subhead text-light-tertiary",
})`
    margin-right: 0.8rem;
`;

export const RightWrapper = styled.div.attrs({
    className: "d-flex align-items-center ms-auto",
})``;
