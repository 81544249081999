export enum TASK_TYPE {
    WATCH_AD = "WATCH_AD",
    WATCH_TUTORIAL = "WATCH_TUTORIAL",
    WATCH_VIDEO = "WATCH_VIDEO",
}

export const isKnownTaskType = (value: string): boolean => Object.values(TASK_TYPE).includes(value as TASK_TYPE);

export enum TASK_RESET_TYPE {
    NEVER = "NEVER",
    DAILY = "DAILY",
}

export enum TASK_PROGRESS {
    AVAILABLE = "AVAILABLE",
    CLAIMABLE = "CLAIMABLE",
    COMPLETED = "COMPLETED"
}
