import { fetchCareerStats, fetchFollow } from "apis/user/UserApi";
import { CareerStatsVo } from "data/vo/me/CareerStatsVo";
import { FollowVo } from "data/vo/me/FollowVo";

export class MeRepository {
    public getFollow = async (userId: number) => {
        const { followersCount, followingCount } = await fetchFollow(userId);

        return new FollowVo(followersCount, followingCount);
    };


    public getCareerStats = async () => {
        const { from, until, stats } = await fetchCareerStats();

        return new CareerStatsVo(from, until, stats);
    };
}
