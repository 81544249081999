import NewUserCouponPopup from "domain/popup/newUser/NewUserCouponPopup";
import NewUserRubyPopup from "domain/popup/newUser/NewUserRubyPopup";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "store";
import { useAppPopups } from "../AppPopupsProvider";
import B2CPopupWrapper from "../popupWrappers/B2CPopupWrapper";

const NewUserPopupFlow = () => {
    const popups = useAppPopups();
    const { UserState: { isNewUser } } = useSelector(store => store);
    const { newUserGifts } = useSelector(store => store.SettingsState);
    const [currentPopup, setCurrentPopup] = useState(0);

    const handleCloseNewUserPopup = () => {
        setCurrentPopup(current => current + 1);
    };

    const newUserPopupQueue = useMemo(() => {

        const queue: React.FC<{ isShow: boolean, onClose: () => void }>[] = [];
        if (newUserGifts.rubyCent > 0) {
            queue.push(NewUserRubyPopup);
        }

        if (newUserGifts.couponDiscountPercentage > 0) {
            queue.push(NewUserCouponPopup);
        }

        return queue;
    }, [newUserGifts]);

    useEffect(() => {
        if (!isNewUser || currentPopup >= newUserPopupQueue.length) {
            popups.done();
        }
    }, [isNewUser, currentPopup, newUserPopupQueue]);

    if (!isNewUser) {
        return null;
    }

    return <>
        <B2CPopupWrapper>
            {
                newUserPopupQueue.map((Popup, index) => {
                    return <Popup
                        key={index}
                        isShow={currentPopup === index}
                        onClose={handleCloseNewUserPopup}
                    />;
                })
            }
        </B2CPopupWrapper>
    </>;
};

export default NewUserPopupFlow;
