import React from "react";

export enum BOTTOM_NAV_ICON_KEY {
    HOME = "HOME",
    MATCHES = "MATCHES",
    RESULT = "RESULT",
    CHAT = "CHAT",
    ME = "ME",
    HISTORY = "HISTORY",
    GUIDE = "GUIDE",
}

export interface NavbarBottomType {
    key: BOTTOM_NAV_ICON_KEY;
    name: string;
    active: boolean;
    icon: string | React.FC;
    to: string;
    additional?: {
        show: boolean
        component: React.FC;
    };
    isShow: boolean;
}

export interface BottomNavConfig {
    activeColor: string;
    bgColor?: string;
    tabs: NavbarBottomType[];
}
