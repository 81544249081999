import NotRankedYetLeaderboard from "components/leaderboard/NotRankedYetLeaderboard";
import RankedLeaderboard from "components/leaderboard/RankedLeaderboard";
import { LeaderboardRepository } from "data/repository/leaderboard/LeaderboardRepository";
import { ContestDetailVo } from "data/vo/contest/ContestDetailVo";
import { LeaderboardTeamVo } from "data/vo/leaderboard/LeaderboardTeamVo";
import { isContestCanceled, isMatchJoinable } from "helpers/match/MatchHelper";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import useMyProfile from "hooks/useMyProfile";

interface LeaderboardSwitcherProps {
    contest: ContestDetailVo,
    myJoinedTeams: LeaderboardTeamVo[]
}

const LeaderboardSwitcher: React.FC<LeaderboardSwitcherProps> = ({
                                                                     contest,
                                                                     myJoinedTeams,
                                                                 }) => {
    const { myProfile } = useMyProfile();
    const leaderboardRepository = new LeaderboardRepository();

    const leaderboard = useFantasyQuery(
        [QUERY_KEY.GET_LEADERBOARD, contest.id],
        async () => leaderboardRepository.getLeaderboard(contest.id),
        {
            options: {
                enabled: !!contest.id && !isContestCanceled(contest.status) && !isMatchJoinable(contest.matchStatus)
            }
        }
    );

    if (isContestCanceled(contest.status) || isMatchJoinable(contest.matchStatus)) {
        return (
            <NotRankedYetLeaderboard
                isCancelled={isContestCanceled(contest.status)}
                myJoinedTeams={myJoinedTeams.length || 0}
                joinedTeamCount={contest.joinedTeams}
                userName={myProfile.nickname}
                avatar={myProfile.avatar}
            />
        );
    }

    return (
        <RankedLeaderboard
            dataSource={leaderboard.data}
            myJoinedTeams={myJoinedTeams}
            sport={contest.sport}
            entryFeeType={contest.entryFeeType}
            matchStatus={contest.matchStatus}
        />
    );
};

export default LeaderboardSwitcher;
