import { ContestTeamsRow } from "domain/match/pages/upcoming/contest/card/ContestCardStyle";
import { overTenTagsIcon } from "helpers/IconHelper";
import React from "react";
import Badge, { BADGE_PATTERN, BADGE_SIZE, BADGE_VARIANTS } from "../../../../../../../designToken/badge/Badge";
import { ImgWrapper, OverTenTagsIcon, TeamsListSection, TeamTagNameWrapper, TeamTagRow } from "./JoinedTeamListStyle";

const teamTagViewMaxCount = 10;

const OverTenTagsIconLayout = ({ arrayIndex, allTeamsCount }) => {
    const isLastTagAndAllTeamsOver10 = arrayIndex === teamTagViewMaxCount - 1 && allTeamsCount > teamTagViewMaxCount;

    return isLastTagAndAllTeamsOver10
        ? <ImgWrapper>
            <OverTenTagsIcon src={overTenTagsIcon} />
        </ImgWrapper>
        : null;
};

const TeamNameList = ({ myJoinedTeamsNames }) => {
    const showList = myJoinedTeamsNames.slice(0, teamTagViewMaxCount);

    return <TeamsListSection>
        <TeamTagRow>
            {
                showList.map((name, idx) =>
                    <TeamTagNameWrapper key={`team-tag-${idx}`}>
                        <Badge variant={BADGE_VARIANTS.OUTLINE_SECONDARY}
                               size={BADGE_SIZE.SM}
                               pattern={BADGE_PATTERN.ROUNDED}>{name}</Badge>
                        <OverTenTagsIconLayout arrayIndex={idx} allTeamsCount={myJoinedTeamsNames.length} />
                    </TeamTagNameWrapper>)
            }
        </TeamTagRow>
    </TeamsListSection>;
};

const MyJoinedTeamList = ({ myJoinedTeamsNames }) => {
    return <>
        {
            myJoinedTeamsNames.length > 0
            && <ContestTeamsRow>
                <TeamNameList myJoinedTeamsNames={myJoinedTeamsNames} />
            </ContestTeamsRow>
        }
    </>;
};

export default MyJoinedTeamList;
