import { WALLET_API_RESPONSE_EVENT } from "data/dto/wallet/walletHistoryDto";
import { FormattedMessage } from "react-intl";
import React from "react";
import { TransactionDetailVo } from "data/vo/wallet/TransactionDetailVo";

export const RefundContestNote = (transactionData: TransactionDetailVo) => (
    <>
        {transactionData.event === WALLET_API_RESPONSE_EVENT.REFUND_CONTEST &&
            <FormattedMessage id="label_contest_cancelled" />}
    </>
);
