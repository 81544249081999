import { divideByOneHundred } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const Prize = styled.div.attrs<{
    $font?: string;
    $isRubyContest?: boolean;
}>(({ $font, $isRubyContest }) => ({
    className: `${$font} ${
        $isRubyContest ? "text-sys-danger-1" : "text-sys-success-1"
    }`,
}))``;

interface WonPrizeLayoutProps {
    wonPrizeCent: number;
    font?: string;
    isRubyContest?: boolean;
}

export const WonPrizeLayout: React.FC<WonPrizeLayoutProps> = ({
                                                                  wonPrizeCent,
                                                                  font = "fz-sf-bold-footnote",
                                                                  isRubyContest,
                                                              }) => {
    const {
        currentCurrencyObject: { toWalletNumber },
    } = useCurrency();

    const wonNum = toWalletNumber(divideByOneHundred(wonPrizeCent));

    if (wonPrizeCent <= 0) {
        return null;
    }

    return (
        <Prize $isRubyContest={isRubyContest} $font={font} data-testid="won-prize">
            <FormattedMessage
                id={
                    isRubyContest
                        ? "contest_detail_page_label_won_rubies"
                        : "contest_detail_page_label_won_coins"
                }
                values={{
                    number: wonNum,
                }}
            />
        </Prize>
    );
};
