import IconButton, { IconButtonFor } from "components/buttons/IconButton";
import { ButtonInitConfig, ButtonSection, FantasyPopupButtonSetting } from "components/popup/component/ButtonSection";
import { Text } from "components/popup/component/Text";
import { Title } from "components/popup/component/Title";
import { Dialog, DialogContent } from "components/ui/dialog";
import { Icon } from "helpers/IconHelper";
import React, { ReactNode, useEffect } from "react";
import { Card } from "react-bootstrap";
import { cn } from "helpers/cn";

export enum POPUP_SIZE {
    SM = "sm",
    MD = "md",
    LG = "lg",
    XL = "xl",
    SCORING = "scoring"
}

export interface FantasyPopupProps {
    show: boolean;
    onHide?: () => void;
    title?: ReactNode | FantasyPopupTitle;
    texts?: FantasyPopupText[];
    content?: React.ReactNode;
    imageSection?: React.ReactNode;
    buttonSetting?: FantasyPopupButtonSetting;
    bottomSection?: React.ReactNode;
    popupSize?: POPUP_SIZE;
    offset?: boolean;
    background?: FANTASY_POPUP_BACKGROUND;
    dismissible?: boolean;
    onReady?: () => void;
    overlaySection?: React.ReactNode;
    iconSize?: string
}

export interface FantasyPopupText {
    text?: string,
    textClassName?: string
}

export interface FantasyPopupTitle {
    title: string,
    titleClassName?: string
}

export enum FANTASY_POPUP_BACKGROUND {
    grey = "tw-bg-grey-50",
    white = "tw-bg-white",
    primary = "tw-bg-primary-900 tw-text-white",
}


const FantasyBasicPopup = React.forwardRef<HTMLDivElement, FantasyPopupProps>((
    {
        show,
        onHide,
        title,
        texts,
        content,
        buttonSetting,
        imageSection,
        bottomSection,
        popupSize = POPUP_SIZE.LG,
        offset = false,
        background = FANTASY_POPUP_BACKGROUND.grey,
        dismissible = true,
        onReady,
        overlaySection,
        iconSize
    }, ref) => {

    useEffect(() => {
        if (show && onReady) {
            const popupTransitionDurationMs = 300;
            setTimeout(onReady, popupTransitionDurationMs);
        }
    }, [show]);

    const _buttonSetting = { ...ButtonInitConfig, ...buttonSetting };

    const sizeMap = {
        [POPUP_SIZE.SM]: "tw-w-[29.4rem]",
        [POPUP_SIZE.MD]: "tw-w-[33.6rem]",
        [POPUP_SIZE.LG]: "tw-px-6",
        [POPUP_SIZE.XL]: "tw-px-4",
        [POPUP_SIZE.SCORING]: "tw-w-[34rem] tw-max-h-[91.8rem] tw-h-[85vh]",
    };

    const CloseIconMap: Record<FANTASY_POPUP_BACKGROUND, IconButtonFor> = {
        [FANTASY_POPUP_BACKGROUND.grey]: IconButtonFor.ON_LIGHT,
        [FANTASY_POPUP_BACKGROUND.white]: IconButtonFor.ON_LIGHT,
        [FANTASY_POPUP_BACKGROUND.primary]: IconButtonFor.ON_DARK,
    };

    return <Dialog open={show} onOpenChange={onHide}>
        <DialogContent className={sizeMap[popupSize]}
                       ref={ref}
                       onHide={dismissible ? onHide : undefined}
                       offset={offset}
                       overlaySection={overlaySection}>
            <Card
                className={cn("border-0 tw-max-h-[calc(100vh-8rem)] tw-overflow-hidden tw-rounded-[1.6rem]", background)}>
                <Card.Body className="tw-pt-4 tw-pb-8 tw-flex tw-flex-col tw-gap-2">
                    <IconButton for={CloseIconMap[background]}
                                background={false}
                                iconSize={iconSize}
                                icon={Icon.Close}
                                onClick={onHide}
                                className="tw-self-end" />

                    <div
                        className="tw-px-2 tw-flex tw-flex-col tw-gap-3 tw-text-center tw-justify-center tw-items-center"
                    >
                        <Title title={title} />
                        {imageSection}
                        {
                            texts && texts.map(({ text, textClassName }, index) =>
                                <Text key={index} text={text} textClassName={textClassName} />,
                            )
                        }
                        {content}
                        <ButtonSection {..._buttonSetting} />
                        {bottomSection}
                    </div>
                </Card.Body>
            </Card>
        </DialogContent>
    </Dialog>;
});

export default FantasyBasicPopup;
