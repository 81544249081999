import { EmptyImg, EmptyText, EmptyWrapper } from "./EmptyStyle";
import { emptyTactics } from "helpers/IconHelper";
import { FormattedMessage } from "react-intl";
import React from "react";

export const StartedMatchEmptyScoreCard = () => {
    return <EmptyWrapper>
        <EmptyImg src={emptyTactics} />
        <EmptyText>
            <FormattedMessage id="scorecard_not_available" />
        </EmptyText>
    </EmptyWrapper>;
};
