import React from "react";
import useCurrency from "hooks/useCurrency/useCurrency";
import { cn } from "helpers/cn";
import { isNegative } from "helpers/number/NumberHelper";

export const AmountDisplay: React.FC<{ amount: number; negativeClass: string; positiveClass: string; }> = ({
                                                                                                               amount,
                                                                                                               negativeClass,
                                                                                                               positiveClass
                                                                                                           }) => {
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();

    return (
        <div className={cn("fz-sf-bold-body-1 text-end", isNegative(amount) ? negativeClass : positiveClass)}>
            {toWalletNumber(amount)}
        </div>
    );
};
