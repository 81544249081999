import { PlayerRepository } from "data/repository/player/PlayerRepository";
import { BasketballPlayerNewsVo } from "data/vo/player/news/BasketballPlayerNewsVo";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import React, { useMemo } from "react";

interface BasketballPlayerNewsContextProps {
    newsList: BasketballPlayerNewsVo[];
}

interface BasketballPlayerNewsProviderProps {
    children: React.ReactNode;
    playerId: string;
}

const BasketballPlayerNewsContext = React.createContext<BasketballPlayerNewsContextProps | null>(null);

export const BasketballPlayerNewsProvider: React.FC<BasketballPlayerNewsProviderProps> = ({ playerId, children }) => {
    const playerInfoRepository = new PlayerRepository();

    const basketballPlayerNewsList = useFantasyQuery([QUERY_KEY.BASKETBALL_PLAYER_NEWS, playerId], () => playerInfoRepository.getBasketballPlayerNews(playerId));

    const value = useMemo(() => {
        return { newsList: basketballPlayerNewsList.data || [] };
    }, [basketballPlayerNewsList]);

    return <BasketballPlayerNewsContext.Provider value={value}>
        {children}
    </BasketballPlayerNewsContext.Provider>;
};

export const useBasketballPlayerNews = () => {
    const context = React.useContext(BasketballPlayerNewsContext);
    if (!context) {
        throw new Error("useBasketballPlayerNews must be used within a BasketballPlayerNewsProvider");
    }
    return context;
};
