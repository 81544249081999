import { useIntl } from "react-intl";
import ScoringTable from "domain/team/player/components/scoringPopup/components/ScoringTable";
import React from "react";
import ScoringNote from "domain/team/player/components/scoringPopup/components/ScoringNote";


const FootballScoringView = () => {
    const intl = useIntl();
    return <div className="tw-flex tw-flex-col tw-gap-6 tw-max-h-[calc(min(91.8rem,85vh)-12.4rem)] overflow-x-auto">
        <ScoringTable
            title={intl.formatMessage({ id: "scoring_popup_subtitle_attack" })}
            scoring={{
                "For every goal scored (Forward)": "40",
                "For every goal scored (Midfielder)": "50",
                "For every goal scored (GK/Defender)": "60",
                "For every assist": "20",
                "For every 5 passes completed": "1",
                "For shots on target": "6",
                "Chance Created": "3",
            }}
        />
        <ScoringTable
            title={intl.formatMessage({ id: "scoring_popup_subtitle_defense" })}
            scoring={{
                "Interception won": "4",
                "Clean sheet +54 min. (GK/Defender)": "20",
                "Shots saved (GK)": "6",
                "Penalty saved (GK)": "50",
                "Tackle won": "4",
            }}
        />
        <ScoringTable
            title={intl.formatMessage({ id: "scoring_popup_subtitle_cards_and_penalties" })}
            scoring={{
                "Yellow card": "-4",
                "Red card": "-10",
                "For every own goal": "-8",
                "For goals conceded (GK/Defender)": "-2",
                "For every penalty missed": "-20",
            }}
        />
        <ScoringTable
            title={intl.formatMessage({ id: "scoring_popup_subtitle_other_points" })}
            scoring={{
                "Starting 11": "4",
                "Coming on as a substitute": "2",
            }}
        />
        <ScoringNote />
    </div>;
};

export default FootballScoringView;
