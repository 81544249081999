import { BASKETBALL_ROLE } from "../../../types/sports/Basketball";
import { NINE_CAT_STAT } from "../data/ComponentTypes";

export const options: NINE_CAT_STAT[] = [
    NINE_CAT_STAT.PTS,
    NINE_CAT_STAT.FGE,
    NINE_CAT_STAT.FTE,
    NINE_CAT_STAT.OR,
    NINE_CAT_STAT.DR,
    NINE_CAT_STAT.AST,
    NINE_CAT_STAT.ST,
    NINE_CAT_STAT.BLK,
    NINE_CAT_STAT["3PM"],
];
export const OPTION_ALL = "All";
export const roleOptions = [
    OPTION_ALL,
    BASKETBALL_ROLE.POINT_GUARD.abbr,
    BASKETBALL_ROLE.SHOOTING_GUARD.abbr,
    BASKETBALL_ROLE.SMALL_FORWARD.abbr,
    BASKETBALL_ROLE.POWER_FORWARD.abbr,
    BASKETBALL_ROLE.CENTER.abbr,
];
