import { getColorObject } from "domain/match/pages/upcoming/contest/card/button/JoinContestButtonHelper";
import {
    EntryFeeText,
    EntryFeeTitle,
    JoinableButton,
    ProgressBar,
    ProgressBarWrapper
} from "domain/match/pages/upcoming/contest/card/button/JoinContestButtonStyle";
import {
    JoinButtonWrapper,
    SpotNumber,
    SpotWrapper,
    TeamsCount
} from "domain/match/pages/upcoming/contest/card/ContestCardStyle";
import { checkIsContestJoinable } from "helpers/ContestHelper";
import { isMatchJoinable } from "helpers/match/MatchHelper";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { RootStoreState } from "store/reducer";
import AmountDisplay from "../../../../../../../containers/amount/AmountDisplay";
import { getCurrencyIconSuitByEntryFeeType } from "../../../../../../../containers/amount/CurrencyIconSuitHelper";
import { cn } from "../../../../../../../helpers/cn";
import useHasCurrencyIcon from "../../../../../../../hooks/useHasCurrencyIcon";


const EntryFee = ({ entryFeeCent, textColorClassname: textColorClassName, entryFeeType }) => {
    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();
    const currencyIconSuit = getCurrencyIconSuitByEntryFeeType(entryFeeType);
    const hasCurrencyIcon = useHasCurrencyIcon(currencyIconSuit); // need known CurrencyIconSuit

    const { font, size } = useMemo(() => {
        const entryFeeDigits = String(divideByOneHundred(entryFeeCent)).length;
        const totalDigits = entryFeeDigits + (hasCurrencyIcon ? 1 : 0);
        if (totalDigits >= 5) {
            return {
                font: "tw-text-subtitle-1",
                size: "1.0rem"
            };
        }
        if (totalDigits >= 4) {
            return {
                font: "tw-text-h5",
                size: "1.3rem"
            };
        }
        return {
            font: "tw-text-h3",
            size: "1.3rem"
        };
    }, [entryFeeCent]);

    if (entryFeeCent <= 0) {
        return <EntryFeeText className="fz-sf-bold-title-2"
                             $textColorClassName={textColorClassName}
                             data-testid="entry-fee">
            <FormattedMessage id="contest_card_button_free" />
        </EntryFeeText>;
    }

    return <>
        <EntryFeeTitle $textColorClassName={textColorClassName}>
            <FormattedMessage id="contest_card_button_entry" />
        </EntryFeeTitle>
        <div className={cn(textColorClassName, font)} data-testid="entry-fee">
            <AmountDisplay
                currencyIconSuit={getCurrencyIconSuitByEntryFeeType(entryFeeType, size)}
                className="tw-text-center tw-text-inherit tw-gap-0 tw-font-bold"
            >
                {toFormattedNumber(divideByOneHundred(entryFeeCent))}
            </AmountDisplay>
        </div>
    </>;
};


const JoinContestButton = ({ contest, matchStatus, openContestPanel }) => {
    const { SettingsState } = useSelector((store: RootStoreState) => store);
    const { teams, spots, entryFeeCent, entryFeeType, myJoinedTeamsNames, allowMultiple } = contest;
    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();

    const isJoined = myJoinedTeamsNames?.length > 0;
    const isJoinable = isMatchJoinable(matchStatus) && checkIsContestJoinable({
        SettingsState,
        spots,
        teams,
        allowMultiple,
        myJoinedTeamsCount: myJoinedTeamsNames.length
    });

    const colorObject = getColorObject(isJoinable, isJoined, contest);

    const ratio = teams / spots;

    const handleProgressbarClick = (e) => {

        if (isJoinable) {
            e.stopPropagation();
            openContestPanel(e, contest);
        }
    };


    return <JoinButtonWrapper>
        <ProgressBarWrapper data-testid="join-contest-button" onClick={handleProgressbarClick}>
            <ProgressBar $ratio={ratio} $progressBar={colorObject.progressBar} />
            <JoinableButton $boxShadow={colorObject.buttonBoxShadow}
                            $joinButtonBackground={colorObject.joinButtonBackground}
                            data-testid="joinable-button">
                <EntryFee entryFeeCent={entryFeeCent} textColorClassname={colorObject.text}
                          entryFeeType={entryFeeType} />
            </JoinableButton>
        </ProgressBarWrapper>
        <SpotWrapper>
            <TeamsCount $textColorClassName={colorObject.text}>{teams}</TeamsCount>
            <SpotNumber>&nbsp;/&nbsp;{toFormattedNumber(spots)}</SpotNumber>
        </SpotWrapper>
    </JoinButtonWrapper>;
};

export default JoinContestButton;

