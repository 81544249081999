import { Profile } from "data/vo/player/PlayerInfoVo";
import { PlayerInfoProfileVo } from "data/vo/player/profile/PlayerInfoProfileVo";
import { getSportConfig } from "domain/SportConfig";
import { getSentenceWithComma } from "helpers/string/StringHelper";
import { SPORT } from "types/sports/Sport";

export class FootballPlayerInfoProfileVo extends PlayerInfoProfileVo {
    private readonly _height: number | null;
    private readonly _weight: number | null;
    private readonly _preferredFoot: string | null;

    constructor(profile: Profile) {
        super(profile);
        this._height = profile.height!;
        this._weight = profile.weight!;
        this._preferredFoot = profile.preferredFoot!;
    }

    get weight(): number | null {
        return this._weight;
    }

    get preferredFoot(): string | null {
        return this._preferredFoot;
    }

    get height(): number | null {
        return this._height;
    }

    get roleAbbr(): string {
        return this.role ? getSportConfig(SPORT.FOOTBALL).team.getRoleAbbr(this.role) : "";
    }

    getDescription(formattedMessage: (id: string) => string): string {
        const profiles = [
            {
                key: "roleAbbr",
                title: formattedMessage("role_title")
            },
            {
                key: "height",
                title: formattedMessage("height_title")
            },
            {
                key: "weight",
                title: formattedMessage("weight_title")
            },
            {
                key: "preferredFoot",
                title: formattedMessage("preferred_foot_title")
            }
        ];

        return getSentenceWithComma(
            ...profiles.map(({ key, title }) => {
                const value = this[key];

                if (!value) return null;

                return `${title}: ${(value)}`;
            })
        );
    }
}
