import { Contest } from "apis/match/type";
import { isFromAddDeposit } from "components/panel/SelectTeamPanelHelper";
import SelectTeamPanel from "containers/selectTeamPanel";
import { ContestDetailVo } from "data/vo/contest/ContestDetailVo";
import { componentTag } from "domain/match/pages/upcoming/MatchDetailFlow";
import { useStartedMatchDetailData } from "domain/match/providers/MyStartedMatchDataProvider";
import { analyticsEvent, logCustomEvent } from "ga";
import { useMinApLevel } from "helpers/ApLevelHelper";
import { hasJoinableTeams, isOnlyOneJoinableTeam } from "helpers/ContestHelper";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ContestDetailVO } from "types/contest/ContestDetail";
import { SPORT } from "types/sports/Sport";
import { MyTeamVO } from "types/team/MyTeam";

interface StartedMatchDetailFlowProps {
    sport: SPORT;
    contest: ContestDetailVO | null,
    currentFlow: componentTag;
    beOperationTeam: MyTeamVO | null,
    handleCreateTeam: (team?) => void
    handleEditTeam: (team) => void,
    closeFlow: () => void,
    afterOperatedTeam: (callback) => void,
    openSelectTeamPanel: () => void,
    onJoinContestFlowAfterCreateTeam: () => void,
    onJoinButtonClick: (e: MouseEvent, contest: Contest | ContestDetailVO | ContestDetailVo) => void,
}

const StartedMatchDetailFlowContext = React.createContext<StartedMatchDetailFlowProps | null>(null);
export const StartedMatchDetailFlowProvider = ({ children }) => {
    const location = useLocation();
    const { sport } = useParams();

    const { myTeams, contest, myApOverview, updateMyTeam, updateContest } = useStartedMatchDetailData();
    const { isApLevelReached } = useMinApLevel();
    const [currentFlow, setCurrentFlow] = useState<componentTag>(componentTag.DETAIL);
    const [beOperationTeam, setBeOperationTeam] = useState(null);
    const {
        contestJoiningCallbacks,
        handleJoinRubyContest,
    } = useStartedMatchDetailData();

    const [isCreateTeamWhileSelectingTeam, setIsCreateTeamWhileSelectingTeam] = useState(false);


    const handleCreateTeam = useCallback((team = null) => {
        logCustomEvent(analyticsEvent.webClickCreateTeam);
        setBeOperationTeam(team);
        setCurrentFlow(componentTag.CREATE);
    }, []);

    const handleCreateTeamWhileSelectingTeam = useCallback((team = null) => {
        handleCreateTeam(team);
        setIsCreateTeamWhileSelectingTeam(true);
    }, [handleCreateTeam]);

    const handleEditTeam = useCallback((team) => {
        setBeOperationTeam(team);
        setCurrentFlow(componentTag.EDIT);
    }, []);

    const selectTeamPanel = SelectTeamPanel.useSelectTeamPanel({
        sport,
        goToCreateTeam: handleCreateTeamWhileSelectingTeam,
        onSubmit: contestJoiningCallbacks,
        onJoinRubyContest: handleJoinRubyContest,
    }, {
        selectedTeamIds: location?.state?.selectedTeamsIds || [],
        show: isFromAddDeposit(location?.state?.selectedTeamsIds),
        autoOpenConfirmation: isFromAddDeposit(location?.state?.selectedTeamsIds),
    });

    const closeFlow = useCallback(() => {
        if (isCreateTeamWhileSelectingTeam) {
            selectTeamPanel.open();
        }
        setCurrentFlow(componentTag.DETAIL);
    }, [isCreateTeamWhileSelectingTeam, selectTeamPanel]);

    const openSelectTeamPanel = useCallback(() => {
        selectTeamPanel.open();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const afterOperatedTeam = useCallback(async (callback) => {
        updateMyTeam((myTeams) => {
            closeFlow();
            setIsCreateTeamWhileSelectingTeam(false);
            if (callback) {
                callback(myTeams);
            }
        });
    }, [updateMyTeam, closeFlow]);

    const onJoinContestFlowAfterCreateTeam = useCallback(() => {
        if (!contest) return;

        afterOperatedTeam((_myTeams) => {
            selectTeamPanel.open({
                selectedTeamIds: [_myTeams[0].id],
                autoOpenConfirmation: isOnlyOneJoinableTeam(_myTeams, contest.myJoinedTeams)
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [afterOperatedTeam, contest]);

    const onJoinButtonClick = useCallback(async (e: MouseEvent, _contest: Contest | ContestDetailVO | ContestDetailVo) => {
        logCustomEvent(analyticsEvent.webTryToJoinContest);

        if (!isApLevelReached(myApOverview, _contest as Contest)) {
            return;
        }

        updateContest(_contest.code);
    }, [myApOverview, isApLevelReached, updateContest]);

    useEffect(() => {
        if (contest) {
            if (hasJoinableTeams(myTeams, contest.myJoinedTeams)) {
                selectTeamPanel.open();
            } else {
                handleCreateTeam();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contest]);

    const contextValue = useMemo(() => ({
        sport,
        contest,
        currentFlow,
        beOperationTeam,
        handleCreateTeam,
        handleEditTeam,
        closeFlow,
        afterOperatedTeam,
        openSelectTeamPanel,
        onJoinContestFlowAfterCreateTeam,
        onJoinButtonClick,
    }), [
        sport,
        contest,
        currentFlow,
        beOperationTeam,
        handleCreateTeam,
        handleEditTeam,
        closeFlow,
        afterOperatedTeam,
        openSelectTeamPanel,
        onJoinContestFlowAfterCreateTeam,
        onJoinButtonClick
    ]);

    return <StartedMatchDetailFlowContext.Provider value={contextValue}>
        {children}
    </StartedMatchDetailFlowContext.Provider>;
};
export const useStartedMatchDetailFlow = () => {
    const context = useContext(StartedMatchDetailFlowContext);

    if (!context) {
        throw new Error("useStartedMatchDetailFlow must be used within a StartedMatchDetailFlowProvider");
    }

    return context;
};
