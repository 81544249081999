import { ajax } from "apis/ApiUtils";
import { getSquadUrl } from "apis/ApiUrlConfig";
import { ROLE, SPORT } from "types/sports/Sport";
import { LINEUP } from "types/player/Player";
import { INJURY_PLAYING_CHANCE } from "data/vo/player/profile/BasketballPlayerInfoProfileVo";

interface SquadPlayerDto {
    id: string
    firstName: string
    middleName: string
    lastName: string
    avatar: string
    creditCent: number
    role: ROLE
    lineup: LINEUP
    pointAvgCent: number
    playerSelectionRate: number
    captainSelectionRate: number
    viceCaptainSelectionRate: number
    injuryStatus: string
    injuryPlayingChance: INJURY_PLAYING_CHANCE
}

export interface SquadDto {
    code: string
    key: string
    players: SquadPlayerDto[]
}

export const fetchSquad = async (sport: SPORT, matchId: string, signal?: AbortSignal): Promise<SquadDto[]> => {
    const { data } = await ajax<SquadDto[]>({ url: getSquadUrl(sport, matchId), signal });
    return data.data;
};
