import AmountDisplay from "containers/amount/AmountDisplay";
import { getCurrencyIconSuitByEntryFeeType } from "containers/amount/CurrencyIconSuitHelper";
import { Icon } from "helpers/IconHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import React from "react";
import { FormattedMessage } from "react-intl";

const RegularPrize = ({ regularPrizePoolCent, entryFeeType }) => {
    const { currentCurrencyObject: { toPeriodNumber } } = useCurrency();
    return (
        <div data-testid="regular-prize">
            <AmountDisplay currencyIconSuit={getCurrencyIconSuitByEntryFeeType(entryFeeType)}>
                {toPeriodNumber(regularPrizePoolCent)}
            </AmountDisplay>
        </div>
    );
};

const DonatedPrize = ({ hostDonatedPrizePoolCent, entryFeeType }) => {
    const { currentCurrencyObject: { toPeriodNumber } } = useCurrency();

    return (
        <>
            <div data-testid="host-donated-prize">
                <AmountDisplay currencyIconSuit={getCurrencyIconSuitByEntryFeeType(entryFeeType)}>
                    {toPeriodNumber(hostDonatedPrizePoolCent)}
                </AmountDisplay>
            </div>
            <Icon.Bonus className="tw-ml-1"></Icon.Bonus>
        </>
    );
};

const ContestMaxPrizePool = ({ contest }) => {
    const { regularPoolCent, hostDonatedPoolCent, entryFeeType } = contest;

    const hasRegularPool = regularPoolCent > 0;
    const hasHostDonatedPoll = hostDonatedPoolCent > 0;
    const hasBothPool = hasRegularPool && hasHostDonatedPoll;

    return (
        <div className="flex-grow-1">
            <div className="tw-text-caption-1 tw-text-system-labelLightSecondary">
                <FormattedMessage id="fantasy_sport_label_max_prize_pool" />
            </div>
            <div className="tw-flex tw-items-baseline tw-font-bold tw-text-title-2 tw-text-system-labelLightPrimary">
                {hasRegularPool && <RegularPrize regularPrizePoolCent={regularPoolCent} entryFeeType={entryFeeType} />}
                {hasBothPool && <div className="tw-mx-[0.2rem]">+</div>}
                {hasHostDonatedPoll &&
                    <DonatedPrize hostDonatedPrizePoolCent={hostDonatedPoolCent} entryFeeType={entryFeeType} />}
            </div>
        </div>
    );
};

export default ContestMaxPrizePool;
