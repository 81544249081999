import { orderBy } from "lodash";
import { useCallback, useContext, useEffect, useState } from "react";
import { ApiExecuteContext } from "../../../../apis/ApiContext";
import { fetchMyCoupon } from "../../../../apis/coupon/CouponApi";
import { EnrichedCoupon } from "../../../../types/coupon/Coupon";
import { isCouponValid } from "../../../coupon/helper/CouponHelper";
import useUpdateWallet from "../../../wallet/useUpdateWallet";

interface UseUpdateCouponAndWalletProps {
    contestEntryFeeCent: number
}

interface UseUpdateCouponAndWalletResult {
    myUnusedCoupon: EnrichedCoupon[];
}

export const useUpdateCouponAndWallet = ({ contestEntryFeeCent }: UseUpdateCouponAndWalletProps): UseUpdateCouponAndWalletResult => {
    const apiExecutor = useContext(ApiExecuteContext);
    const updateWallet = useUpdateWallet();
    const [myUnusedCoupon, setMyUnusedCoupon] = useState<EnrichedCoupon[]>([]);

    const updateCouponAndWallet = useCallback(
        async () => {
            const [myCoupon] = await apiExecutor(Promise.all([
                fetchMyCoupon(),
                updateWallet()
            ]));

            const unusedCoupons = orderBy(myCoupon.unused.filter((coupon) => isCouponValid(coupon, contestEntryFeeCent)), "to");
            setMyUnusedCoupon(unusedCoupons);
        }, [apiExecutor, contestEntryFeeCent, updateWallet]
    );

    useEffect(() => {
        updateCouponAndWallet();
    }, [updateCouponAndWallet]);

    return {
        myUnusedCoupon
    };
};
