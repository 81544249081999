import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuTrigger
} from "components/ui/dropdown-menu";
import { cn } from "helpers/cn";
import { omit } from "lodash";
import React, { ReactNode, useState } from "react";

interface FantasyDropdownMenuProps {
    selected?: boolean;
    labelComponent: ReactNode;
    children: ReactNode | ReactNode[];
}

export const FantasyDropdownMenu: React.FC<FantasyDropdownMenuProps> = (props) => {
    const [opened, setOpened] = useState(false);

    return <DropdownMenu onOpenChange={opened => setOpened(opened)}>
        <DropdownMenuTrigger asChild>
            <button
                className={cn("tw-flex tw-items-center tw-gap-1 tw-w-full tw-pl-[1.2rem] tw-outline-none tw-bg-grey-50 tw-rounded-lg",
                    props.selected ? "tw-text-warning-1000 " : "tw-text-black",
                    opened ? "tw-shadow-[0_3px_3px_-2px_rgba(0,0,0,0.2),0_3px_4px_0px_rgba(0,0,0,0.14)] tw-rounded-tr-lg tw-rounded-tl-lg tw-rounded-br-none tw-rounded-bl-none" : "",
                )}>
                {props.labelComponent}
            </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
            className={"tw-w-[7rem] tw-p-0 tw-border-none tw-translate-y-[-4px] tw-rounded-br-xl tw-rounded-bl-xl tw-shadow-[0_3px_3px_-2px_rgba(0,0,0,0.2),0_3px_4px_0px_rgba(0,0,0,0.14)]"}
        >
            <DropdownMenuGroup>
                {props.children}
            </DropdownMenuGroup>
        </DropdownMenuContent>
    </DropdownMenu>;
};
export const FantasyDropdownMenuItem = (props: {
    selected?: boolean
} & React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item>) => {
    const {
        selected,
        children,
    } = props;
    return <DropdownMenuItem
        className={cn("tw-bg-grey-150 tw-py-2 tw-px-4 tw-text-overline tw-justify-center",
            "focus:tw-bg-primary-700 focus:tw-text-white",
            selected ? "tw-bg-primary-500 tw-text-white" : "tw-bg-grey-150", props.className)}
        {...omit(props, "className")}
    >
        {children}
    </DropdownMenuItem>;
};
