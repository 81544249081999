import { TeamPreviewWithPlayer } from "../components/TeamPreviewWithPlayer";
import { TeamPreviewWithoutPlayer } from "../components/TeamPreviewWithoutPlayer";
import React from "react";
import { useSelector } from "../../../store";

const TeamPreviewContentFactory: React.FC = () => {
    const {
        players
    } = useSelector(state => state.TeamPreviewState.data);

    if (players.length === 0) {
        return <TeamPreviewWithoutPlayer />;
    }

    return (
        <TeamPreviewWithPlayer />
    );

};

export default TeamPreviewContentFactory;