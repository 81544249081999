import { awardBackground, awardCoinIcon } from "helpers/IconHelper";
import styled from "styled-components";

const AwardDeposit = () => {

    return <CardImg>
        <img className="tw-max-w-[21.6rem]" src={awardCoinIcon} alt="awardDepositIcon" />
    </CardImg>;
};

const CardImg = styled.div.attrs({
    className: "card-img-top d-flex align-items-center justify-content-center bg-gray-2"
})`
  margin-bottom: -1.2rem;
  padding-top: 4.2rem;
  padding-bottom: 1.2rem;
  background: url(${awardBackground}) no-repeat center;
`;

export default AwardDeposit;
