import { DarkTopNavbar } from "components/container/navbar/TopNavbar";
import { ChatRoomWrapper } from "domain/chat/ChatRoomStyle";
import { ChatRoomContent } from "domain/chat/components/ChatRoomContent";
import React from "react";
import { FormattedMessage } from "react-intl";

const ChatRoom = () => {
    return (
        <ChatRoomWrapper>
            <DarkTopNavbar className="tw-sticky tw-top-0 tw-left-0 tw-w-full tw-max-w-app-max ">
                <div className="tw-text-h6 tw-font-bold tw-text-grey-0 tw-text-center">
                    <FormattedMessage id="label_chat_room" />
                </div>
            </DarkTopNavbar>
            <ChatRoomContent />

        </ChatRoomWrapper>
    );
};

export default ChatRoom;
