import { useDailyCheckInPopup } from "../../../quest/DailyCheckInPopup/DailyCheckInPopupProvider";
import React, { useCallback, useEffect, useRef, useState } from "react";
import DailyCheckInDefaultPopup, {
    dailyCheckInDefaultPopupConfirmButton
} from "../../../quest/DailyCheckInPopup/component/DailyCheckInDefaultPopup";
import { cn } from "../../../../helpers/cn";
import { FantasyHomeNavbar } from "../../../home/components/FantasyHomeNavbar/FantasyHomeNavbar";
import { useAppPopups } from "../../AppPopupsProvider";

const forwardClosePopupAnimation = (
    popupChildren: { overlay: Element, content: Element },
    navbar: HTMLDivElement,
) => {
    navbar.classList.add("tw-backdrop-ease-out");

    const overlayClass = popupChildren.overlay.classList;
    overlayClass.remove("tw-backdrop-default-animate-in-out");
    overlayClass.add("tw-backdrop-ease-out");

    const contentClass = popupChildren.content.classList;
    contentClass.remove("tw-popup-default-animate-in-out");
    contentClass.add("tw-popup-slide-zoom-out");
};

const FloatingHomeNavbar = React.forwardRef<HTMLDivElement>((_, ref) => {
    return <div
        ref={ref}
        className={cn(
            "tw-absolute tw-left-0 tw-top-0 tw-z-50 tw-w-full",
            "tw-opacity-0",
        )}>
        <FantasyHomeNavbar />
    </div>;
});

const MainDailyCheckInDefaultPopup = () => {
    const context = useDailyCheckInPopup();
    const floatingHomeNavbarRef = useRef<HTMLDivElement>(null);
    const popups = useAppPopups();

    const [show, setShow] = useState(false);

    useEffect(() => {
        context.setIsShow(true);
    }, []);

    useEffect(() => {
        context.isShow
            ? setShow(context.isShowDefaultPopup)
            : handleHide();
    }, [context.isShowDefaultPopup]);

    const handleHide = useCallback(() => {
        if (context.popupRef.children === null || floatingHomeNavbarRef.current === null) {
            return;
        }

        forwardClosePopupAnimation(context.popupRef.children, floatingHomeNavbarRef.current);

        const hidePopupDurationMs = 1300;
        setTimeout(() => {
            setShow(false);
            popups.keep();
        }, hidePopupDurationMs);
    }, [context.popupRef]);

    return <DailyCheckInDefaultPopup
        confirmButtonSetting={dailyCheckInDefaultPopupConfirmButton.moreReward}
        overlaySection={<FloatingHomeNavbar ref={floatingHomeNavbarRef} />}
        show={show}
        onHide={handleHide}
    />;
};

export default MainDailyCheckInDefaultPopup;