import React from "react";
import { ModalFooterWrapper } from "domain/home/components/tournamentFilterDialog/TournamentFilterDialogStyle";
import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_VARIANTS } from "designToken/button/types";
import { FormattedMessage } from "react-intl";
import { TournamentFilterButtonsProps } from "components/tournament/types";

export const TournamentFilterButtons: React.FC<TournamentFilterButtonsProps> = ({ onApply, onClose }) => {
    return <ModalFooterWrapper>
        <Button className="w-100" variant={BUTTON_VARIANTS.outlinePrimary} pattern={BUTTON_PATTERN.pill}
                onClick={onClose}>
            <FormattedMessage id="dialog_button_close" />
        </Button>
        <Button className="w-100" pattern={BUTTON_PATTERN.pill} onClick={onApply} dataTestId="submit">
            <FormattedMessage id="dialog_button_apply" />
        </Button>
    </ModalFooterWrapper>;
};
