import { TOAST_TYPE } from "designToken/alert/components/AlertHelper";
import Badge, { BADGE_PATTERN, BADGE_SIZE, BADGE_VARIANTS } from "designToken/badge/Badge";
import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";
import Input from "designToken/textFields/Input";
import Select from "designToken/textFields/Select";
import SelectInput from "designToken/textFields/SelectInput";
import Textarea from "designToken/textFields/Textarea";
import { chatRoomIconActive, homeIcon } from "helpers/IconHelper";
import {
    useCreateTeamSuccessDialog,
    useEditTeamSuccessDialog,
    useReferredSuccessDialog,
    useStopJoiningDialog,
    useTransferSuccessDialog,
    useWarningDialog
} from "hooks/useDialog";
import useFantasyHistory from "hooks/useFantasyHistory";
import { uniqueId } from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";

import { addToast } from "store/actions/AlertActions";

function InputFields() {
    const errorMessage = "error message";
    const disabled = true;

    const items = [
        {
            id: "1",
            startImg: {
                front: homeIcon,
                end: homeIcon
            },
            startText: "AAAAA",
            endImg: {
                front: chatRoomIconActive,
                end: chatRoomIconActive
            },
            endText: "sdfs"
        },
        {
            id: "2",
            startImg: {
                front: homeIcon,
                end: homeIcon
            },
            startText: "BBBBBB",
            endImg: {
                front: chatRoomIconActive,
                end: chatRoomIconActive
            },
            endText: "ss"
        },
        {
            id: "3",
            startImg: {
                front: homeIcon,
                end: homeIcon
            },
            startText: "CCCCCC",
            endImg: {
                front: chatRoomIconActive,
                end: chatRoomIconActive
            },
            endText: "ss"
        },
        {
            id: "4",
            startImg: {
                front: homeIcon,
                end: homeIcon
            },
            startText: "KKKKKKK",
            endImg: {
                front: chatRoomIconActive,
                end: chatRoomIconActive
            },
            endText: "ss"
        }
    ];

    const activeItem = {
        id: "2",
        value: "BBBBBB"
    };

    const disabledItemIds = ["3"];

    return <div className="d-flex container-fluid px-5">
        <div className="row w-100">
            <div className="py-5 d-flex flex-column col" style={{ gap: "15px" }}>
                <h2>input + button(optional)</h2>
                <Input label="label"
                       value={""}
                       onChange={() => {}}
                       placeholder="Placeholder"
                />
                <Input label="label"
                       value={""}
                       onChange={() => {}}
                       placeholder="Placeholder"
                       button="button"
                />
                <Input label="label"
                       value={""}
                       onChange={() => {}}
                       placeholder="Placeholder"
                       button="button"
                       errorMessage={errorMessage}
                />
                <Input label="label"
                       value={""}
                       onChange={() => {}}
                       placeholder="Placeholder"
                       button="button"
                       disabled={disabled}
                />
            </div>

            <div className="py-5 d-flex flex-column col" style={{ gap: "15px" }}>
                <h2>select + input + button(optional)</h2>
                <SelectInput label="label"
                             value=""
                             placeholder="Placeholder"
                             items={items}
                />
                <SelectInput label="label"
                             value=""
                             placeholder="Placeholder"
                             button="button"
                             items={items}
                             activeItem={activeItem}
                             disabledItemIds={disabledItemIds}
                />
                <SelectInput label="label"
                             value={""}
                             onChange={() => {}}
                             placeholder="Placeholder"
                             errorMessage={errorMessage}
                             button="button"
                             items={items}
                             onItemClick={() => {}}
                />
                <SelectInput label="label"
                             value={""}
                             onChange={() => {}}
                             placeholder="Placeholder"
                             button="button"
                             items={items}
                             onItemClick={() => {}}
                />
            </div>
            <div className="py-5 d-flex flex-column col" style={{ gap: "15px" }}>
                <h2>select</h2>
                <Select label="label"
                        items={items}
                        onItemClick={() => {}}
                        disabledItemIds={disabledItemIds}
                />
                <Select label="label"
                        items={items}
                        onItemClick={() => {}}
                        activeItem={activeItem}
                        disabledItemIds={disabledItemIds}
                />
                <Select label="label"
                        items={items}
                        onItemClick={() => {}}
                        errorMessage={"sdfs"}
                        activeItem={activeItem}
                />
                <Select label="label"
                        items={items}
                        onItemClick={() => {}}
                        activeItem={activeItem}
                />
            </div>
            <div className="py-5 d-flex flex-column col" style={{ gap: "15px" }}>
                <h2>textarea</h2>
                <Textarea label="label"
                          value={""}
                          onChange={() => {}}
                          placeholder="Placeholder"
                />
                <Textarea label="label"
                          value={"sdjahfjkasdf"}
                          onChange={() => {}}
                          placeholder="Placeholder"
                />
                <Textarea label="label"
                          value={"dddddd"}
                          onChange={() => {}}
                          placeholder="Placeholder"
                          errorMessage={"error message"}
                />
                <Textarea label="label"
                          value={""}
                          onChange={() => {}}
                          placeholder="Placeholder"
                          bottomMessage={"bottom message"}
                />
                <Textarea label="label"
                          value={""}
                          onChange={() => {}}
                          placeholder="Placeholder"
                          bottomMessage={"bottom message"}
                />

            </div>
        </div>
    </div>;
}

function Buttons() {
    return <div className="container-fluid p-5">
        <div className="row">
            <h2 className="text-center mb-3">Rounded button</h2>
            {
                Object.values(BUTTON_VARIANTS).map((variant, idx) => {
                    return <div className="col-2 mb-5 d-flex flex-column justify-content-start align-items-center"
                                style={{ gap: "10px" }}
                                key={uniqueId(`${variant}-${idx}-rounded-button-`)}
                    >
                        <h3>{variant}</h3>
                        {
                            Object.values(BUTTON_SIZE).map(size => {
                                return <Button key={uniqueId(`${variant}-${size}-rounded-button-`)} variant={variant}
                                               size={size}
                                               pattern={BUTTON_PATTERN.rounded}>Button</Button>;
                            })
                        }
                        <Button variant={variant}
                                size={BUTTON_SIZE.lg}
                                pattern={BUTTON_PATTERN.rounded}
                                disabled={true}
                        >Button
                        </Button>
                    </div>;
                })
            }
        </div>
        <div className="row">
            <h2 className="text-center mb-3">pill button</h2>
            {
                Object.values(BUTTON_VARIANTS).map((variant, idx) => {
                    return <div className="col-2 mb-5 d-flex flex-column justify-content-start align-items-center"
                                style={{ gap: "10px" }}
                                key={uniqueId(`${variant}-${idx}-pill-button-`)}>
                        <h3>{variant}</h3>
                        {
                            Object.values(BUTTON_SIZE).map(size => {
                                return <Button key={uniqueId(`${variant}-${size}-pill-button-`)} variant={variant}
                                               size={size}
                                               pattern={BUTTON_PATTERN.pill}>Button</Button>;
                            })
                        }
                        <Button variant={variant} size={BUTTON_SIZE.lg} disabled={true}
                                pattern={BUTTON_PATTERN.pill}>Button</Button>
                    </div>;
                })
            }
        </div>
    </div>;
}


function Toasts() {
    const dispatch = useDispatch();
    const intl = useIntl();

    const warningDialog = useWarningDialog();
    const stopJoiningDialog = useStopJoiningDialog();
    const transferSuccessDialog = useTransferSuccessDialog();
    const referredSuccessDialog = useReferredSuccessDialog();
    const createTeamSuccessDialog = useCreateTeamSuccessDialog();
    const editTeamSuccessDialog = useEditTeamSuccessDialog();

    return <div className="container-fluid p-5">
        <div className="row">
            <div className="col d-flex align-items-center" style={{ gap: "10px" }}>
                <h2>Toast</h2>
                <Button variant={BUTTON_VARIANTS.danger} onClick={() => {
                    dispatch(addToast({
                        type: TOAST_TYPE.DANGER,
                        content: "內容"
                    }));
                }}>danger</Button>
                <Button variant={BUTTON_VARIANTS.warning} onClick={() => {
                    dispatch(addToast({
                        type: TOAST_TYPE.WARNING,
                        content: "內容"
                    }));
                }}>warning</Button>
                <Button variant={BUTTON_VARIANTS.success} onClick={() => {
                    dispatch(addToast({
                        type: TOAST_TYPE.SUCCESS,
                        content: "內容"
                    }));
                }}>success</Button>
                <Button variant={BUTTON_VARIANTS.info} onClick={() => {
                    dispatch(addToast({
                        type: TOAST_TYPE.INFO,
                        content: "內容"
                    }));
                }}>info</Button>
            </div>
        </div>
        <div className="row mt-5">
            <h2>Dialog</h2>
            <div className="col d-flex align-items-center" style={{ gap: "10px" }}>
                <Button variant={BUTTON_VARIANTS.warning}
                        onClick={() => {
                            warningDialog({
                                title: intl.formatMessage({ id: "label_are_you_sure" }),
                                content: intl.formatMessage({ id: "add_referral_page_leaving_dialog_label_can_not_enter_again" }),
                                cancelButton: {
                                    text: intl.formatMessage({ id: "label_cancel" })
                                },
                                confirmButton: {
                                    text: intl.formatMessage({ id: "label_sure" }),
                                    onClick: () => alert("close")
                                }
                            });
                        }}>Warning Dialog</Button>
                <Button variant={BUTTON_VARIANTS.danger}
                        onClick={() => {
                            stopJoiningDialog(() => {
                                alert("onConfirm");
                            });
                        }}>Match Started Dialog</Button>
                <Button variant={BUTTON_VARIANTS.success}
                        onClick={() => transferSuccessDialog("Andrwe", 100)}>Transfer Success Dialog</Button>
                <Button variant={BUTTON_VARIANTS.success}
                        onClick={() => referredSuccessDialog(() => alert("onExited"))}>Referred Success Dialog</Button>
                <Button variant={BUTTON_VARIANTS.success}
                        onClick={createTeamSuccessDialog}>Created Team Success Dialog</Button>
                <Button variant={BUTTON_VARIANTS.success}
                        onClick={editTeamSuccessDialog}>Edited Team Success Dialog</Button>
            </div>
        </div>
    </div>;
}

function Badges() {
    return <div className="container-fluid p-5">
        <div className="row">
            <h2 className="text-center mb-3">Rounded badges</h2>
            {
                Object.values(BADGE_VARIANTS).map((variant) => {
                    return <div className="col-2 mb-5 d-flex flex-column justify-content-start align-items-center"
                                style={{ gap: "10px" }}>
                        <h3>{variant}</h3>
                        {
                            Object.values(BADGE_SIZE).map(size => {
                                return <Badge variant={variant} size={size}
                                              pattern={BADGE_PATTERN.ROUNDED}>Badge</Badge>;
                            })
                        }
                    </div>;
                })
            }
        </div>
        <div className="row">
            <h2 className="text-center mb-3">pill badges</h2>
            {
                Object.values(BADGE_VARIANTS).map((variant) => {
                    return <div className="col-2 mb-5 d-flex flex-column justify-content-start align-items-center"
                                style={{ gap: "10px" }}>
                        <h3>{variant}</h3>
                        {
                            Object.values(BADGE_SIZE).map(size => {
                                return <Badge variant={variant} size={size}
                                              pattern={BADGE_PATTERN.PILL}>Badge</Badge>;
                            })
                        }
                    </div>;
                })
            }
        </div>
    </div>;
}

function DesignToken() {
    const history = useFantasyHistory();

    return <>
        <div className="d-flex p-5" style={{ gap: "10px" }}>
            <Button onClick={() => history.push("/design-token/buttons")}>Buttons</Button>
            <Button onClick={() => history.push("/design-token/toasts")}>Toasts</Button>
            <Button onClick={() => history.push("/design-token/input-fields")}>Input-fields</Button>
            <Button onClick={() => history.push("/design-token/badges")}>Badges</Button>
        </div>
        <Switch>
            <Route exact path="/design-token/buttons">
                <Buttons />
            </Route>
            <Route exact path="/design-token/toasts">
                <Toasts />
            </Route>
            <Route exact path="/design-token/input-fields">
                <InputFields />
            </Route>
            <Route exact path="/design-token/badges">
                <Badges />
            </Route>
        </Switch>
    </>;
}

export default DesignToken;
