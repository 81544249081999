import { useEffect } from "react";


export const useLockScroll = (lock: boolean = true) => {

    useEffect(() => {
        const bodyElement = document.querySelector("body");

        if (bodyElement && lock) {
            bodyElement.style.overflow = "hidden";
        }

        return () => {
            if (bodyElement) {
                bodyElement.removeAttribute("style");
            }
        };

    }, [lock]);
};
