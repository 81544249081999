import { SPORT } from "types/sports/Sport";
import React from "react";
import { TabButton } from "officialWebsite/FeatureContest/components/TabButton";
import { getAllSportConfig } from "domain/SportConfig";

interface FeatureContestTabsProps {
    selectedSport: string;
    changeSport: (sport: SPORT) => void;
}

export const FeatureContestTabs: React.FC<FeatureContestTabsProps> = ({ selectedSport, changeSport }) => {
    const sportTab = Object.values(getAllSportConfig());

    const sportConfigDetails = sportTab.reverse();
    return <div className="tw-flex tw-justify-around">
        {
            sportConfigDetails.map(({ icon, name, key: sportKey }, index) => {
                return <TabButton
                    key={sportKey}
                    icon={icon.black}
                    name={name}
                    sportKey={sportKey}
                    active={sportKey === selectedSport.toUpperCase()}
                    onClick={() => changeSport(sportKey.toLowerCase() as SPORT)}
                />;
            })
        }
    </div>;
};
