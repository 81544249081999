import Navbar from "components/container/navbar/Navbar";
import PageViewRecorder from "components/ga/PageViewRecorder";
import { HINT_CARD_VARIANT, HintCard } from "components/hintcard/HintCard";
import { useAddCoinFlow } from "domain/coin/AddCoinFlow";
import CoinProducts from "domain/coin/component/product/CoinProducts";
import { Icon } from "helpers/IconHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import useFantasyHistory from "hooks/useFantasyHistory";
import _ from "lodash";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { RootStoreState } from "store/reducer";
import useFantasyConfig from "hooks/useFantasyConfig";

const SelectCoinProduct = () => {
    const history = useFantasyHistory();
    const { products, currentProduct, setCurrentProduct, cancelAddDepositFlow } = useAddCoinFlow();
    const { WalletState: { coin } } = useSelector((store: RootStoreState) => store);
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();
    const { isDaily } = useFantasyConfig();

    useEffect(() => {
        if (_.isNil(currentProduct) && history.location?.state?.requiredDepositCent) {
            setCurrentProduct(products.getProductByRequiredCoin(history.location.state.requiredDepositCent) ?? null);
        }
    }, [history.location?.state?.requiredDepositCent, products, currentProduct, setCurrentProduct]);

    return <>
        <Navbar
            navbarStart={false}
            navbarCenter={<div
                className="tw-flex tw-justify-center tw-items-center tw-h-full fz-sf-bold-title-3 text-white">
                <FormattedMessage id="label_add_deposit" />
            </div>}
            endIcon={<Icon.Close className="tw-cursor-pointer tw-text-white" onClick={cancelAddDepositFlow} />}
            navbarBottom={
                <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-pt-[3.3rem] tw-pb-[4rem]">
                    <div className="tw-text-center fz-sf-bold-title-lg tw-text-grey-0">{toWalletNumber(coin || 0)}</div>
                    {
                        !isDaily &&
                        <div className="tw-mt-[1.6rem]">
                            <HintCard variant={HINT_CARD_VARIANT.PRIMARY}
                                      prefixIcon={Icon.BitCoin}
                                      hintText={(
                                          <FormattedMessage id={"add_deposit_from_crypto_currency_hint"} />
                                      )} />
                        </div>
                    }
                </div>
            }
            shouldCalculatePaddingTop={false}
            isTransparent={true}
            isHeaderLine={false}
            className="header-bg-linear-gradient-up !tw-sticky"
        />
        <PageViewRecorder title="Select Deposit Product" />
        <CoinProducts />
    </>;
};

export default SelectCoinProduct;
