import { Demarcation } from "components/Demarcation/Demarcation";
import LeaderboardItem from "components/leaderboard/LeaderboardItem";
import TeamPreview from "containers/teamPreview";
import { LeaderboardTeamVo } from "data/vo/leaderboard/LeaderboardTeamVo";
import { LeaderboardVo } from "data/vo/leaderboard/LeaderboardVo";
import usePlayerInfoDialog from "domain/team/playerInfoDialog/PlayerInfoDialog";
import { PlayerInfoDialogFooterType } from "domain/team/playerInfoDialog/PlayerInfoDialogFooter";
import { PlayerInfoDialogHeaderType } from "domain/team/playerInfoDialog/PlayerInfoDialogHeader";
import { multiplyByOneHundred, toUniversalFormattedNumber } from "helpers/number/NumberHelper";
import useMyProfile from "hooks/useMyProfile";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "store";
import { BOTTOM_SLOT_TYPE } from "store/reducer/teamPreview/types";
import { ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";
import { MATCH_STATUS_FROM_API } from "types/match/Match";
import { SPORT } from "types/sports/Sport";

interface RankedLeaderboardProps {
    sport?: SPORT;
    dataSource?: LeaderboardVo;
    myJoinedTeams?: LeaderboardTeamVo[];
    entryFeeType?: ENTRY_FEE_TYPE_FROM_API;
    matchStatus?: MATCH_STATUS_FROM_API;
}

const RankedLeaderboard: React.FC<RankedLeaderboardProps> = ({
                                                                 dataSource,
                                                                 myJoinedTeams,
                                                                 sport,
                                                                 entryFeeType = ENTRY_FEE_TYPE_FROM_API.DEPOSIT,
                                                                 matchStatus = MATCH_STATUS_FROM_API.COMPLETED,
                                                             }) => {
    const { myProfile } = useMyProfile();
    const isEmpty = !dataSource || !myJoinedTeams;
    const {
        TeamPreviewState: {
            data: { match: _match, myTeams },
        },
    } = useSelector((store) => store);

    const { PlayerInfoDialog, handlePlayerInfoDialogOpen } =
        usePlayerInfoDialog(
            PlayerInfoDialogHeaderType.POINT,
            PlayerInfoDialogFooterType.ONLY_CLOSE,
        );

    const teamPreview = TeamPreview.useTeamPreview({
        sport,
        onAvatarClick: handlePlayerInfoDialogOpen,
        bottomSlotType: BOTTOM_SLOT_TYPE.USER_PROFILE,
    });


    const openPreview = async ({ teamId, userId, teamName, scoreCent }) => {
        teamPreview.updateTeamInfoForUserProfile({
            teamId,
            teamName,
            scoreCent,
        });
        await teamPreview.updatePlayersByTeamId(teamId);
        teamPreview.open();
        await teamPreview.updateUserProfile(userId, true);
    };


    const handlePreviewTeam = async (team: LeaderboardTeamVo) => {
        await openPreview({
            teamId: team.teamId,
            userId: team.userId,
            teamName: team.teamName,
            scoreCent: multiplyByOneHundred(team.score),
        });
    };

    if (isEmpty) return null;

    const renderEndOfPrizesText = () => {
        return <Demarcation>
            <FormattedMessage id="contest_leaderboard_view_label_end_of_prizes" />
        </Demarcation>;
    };

    const renderParticipantsText = (participants: number) => {
        return (
            <div className="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-py-2">
                <div className="tw-flex tw-flex-col tw-gap-0.5">
                    <div className="tw-w-1 tw-h-1 tw-rounded-full tw-bg-grey-200" />
                    <div className="tw-w-1 tw-h-1 tw-rounded-full tw-bg-grey-150" />
                    <div className="tw-w-1 tw-h-1 tw-rounded-full tw-bg-grey-100" />
                </div>
                <span className="tw-text-grey-1000 tw-text-overline">
                        ({toUniversalFormattedNumber(participants)} <FormattedMessage
                    id="contest_leaderboard_view_label_participants" />)
                    </span>
            </div>
        );
    };

    return (
        <>
            <div className="tw-p-4" data-testid="leaderboard">
                <div className="tw-space-y-2">
                    <div className="tw-space-y-2" data-testid="my-leaderboard">
                        {
                            myJoinedTeams.map(team => (
                                <LeaderboardItem
                                    team={team}
                                    isSelf
                                    key={team.teamId}
                                    onPreviewTeam={handlePreviewTeam}
                                    entryFeeType={entryFeeType}
                                    matchStatus={matchStatus}
                                />
                            ))
                        }
                    </div>
                    <div className="tw-px-3 tw-py-2 tw-flex tw-items-center tw-gap-4 tw-text-body-1 tw-text-grey-1000">
                        <div className="tw-flex-1">
                            <FormattedMessage id="label_rank" />
                        </div>
                        <div>
                            {toUniversalFormattedNumber(dataSource.joinedTeams)} <FormattedMessage id="label_teams" />
                        </div>
                        <div className="tw-flex-shrink-0 tw-min-w-10 tw-text-center">
                            <FormattedMessage id="label_score" />
                        </div>
                    </div>
                    <div className="tw-space-y-2" data-testid="all-leaderboard">

                        {dataSource?.isPractice && renderEndOfPrizesText()}

                        {
                            dataSource.mergedTeams.map((item) => (
                                <React.Fragment key={item.team.teamId}>
                                    {item.participants > 0 && renderParticipantsText(item.participants)}
                                    <LeaderboardItem
                                        team={item.team}
                                        isSelf={myProfile.id === item.team.userId}
                                        onPreviewTeam={handlePreviewTeam}
                                        entryFeeType={entryFeeType}
                                        matchStatus={matchStatus}
                                    />
                                    {item.isLastPrizesTeam && renderEndOfPrizesText()}
                                </React.Fragment>
                            ))
                        }
                    </div>
                </div>
            </div>
            <PlayerInfoDialog
                match={_match!}
                myTeams={myTeams}
                players={teamPreview.players}
            />
        </>
    );
};

export default RankedLeaderboard;
