import { TOAST_TYPE } from "designToken/alert/components/AlertHelper";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import { addToast } from "store/actions/AlertActions";


export const useMatchPreparingToast = () => {
    const dispatch = useDispatch();
    const intl = useIntl();

    return useCallback(() => {
        dispatch(addToast({
            type: TOAST_TYPE.WARNING,
            content: intl.formatMessage({ id: "match_preparing_toast_label" })
        }));

    }, [dispatch, intl]);
};


export const useToast = () => {
    const dispatch = useDispatch();
    const intl = useIntl();

    return useCallback((formatMessageId, type = TOAST_TYPE.WARNING, icon?: React.ReactNode) => {
        dispatch(addToast({
            type,
            content: intl.formatMessage({ id: formatMessageId }),
            icon
        }));

    }, [dispatch, intl]);

};
