import FantasyScrollingPopup, {
    ScrollingPopupBody,
    ScrollingPopupFooter,
    ScrollingPopupHeader,
    ScrollingPopupPrimaryButton
} from "components/popup/FantasyScrollingPopup";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

interface SlateDescriptionProps {
    open: boolean;
    onOpenChange: (open: boolean) => void;
}

const SlateDescriptionPopup: React.FC<SlateDescriptionProps> = ({ open, onOpenChange, }) => {
    const intl = useIntl();
    const handleClose = () => {
        onOpenChange(false);
    };


    return (
        <FantasyScrollingPopup
            open={open}
            onOpenChange={onOpenChange}
            title={
                <ScrollingPopupHeader
                    title={intl.formatMessage({ id: "nine_cat_popup_title" })}
                    onClose={handleClose}
                />
            }
            body={
                <ScrollingPopupBody>
                    <FormattedMessage id="nine_cat_popup_description" />
                </ScrollingPopupBody>
            }
            footer={
                <ScrollingPopupFooter>
                    <ScrollingPopupPrimaryButton onClick={handleClose} />
                </ScrollingPopupFooter>
            }
        />
    );
};

export default SlateDescriptionPopup;
