import { ApiExecuteContext } from "apis/ApiContext";
import { fetchRewardOrders } from "apis/reward/RewardApi";
import Navbar from "components/container/navbar/Navbar";
import { useLoadingToggle } from "components/loading/Loading";
import RewardOrderHistoryList from "domain/reward/orders/RewardOrderHistoryList";
import {
    RewardOrderHistoryTab,
    RewardOrderHistoryTabWrapper,
    RewardOrderHistoryWrapper
} from "domain/reward/orders/RewardOrderHistoryStyle";
import { SetShowNewOrderDotContext } from "domain/reward/RewardContainer";
import { getLabelI18nText, REWARD_ORDER_TAB_ALL } from "domain/reward/RewardHelper";
import { Title } from "domain/reward/RewardListStyle";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { SYSTEM_COLORS } from "styles/Constants";
import { REWARD_ORDER_STATUS } from "types/reward/Reward";

const getTabs = (activeTab, rewardOrders, intl) => {

    function sort(rewardOrders) {
        return [...rewardOrders].sort((a, b) => {
            const statusOrders = [REWARD_ORDER_STATUS.PENDING, REWARD_ORDER_STATUS.COMPLETED, REWARD_ORDER_STATUS.CANCELLED];

            return a.createTime === b.createTime
                ? statusOrders.indexOf(a.status) - statusOrders.indexOf(b.status)
                : b.createTime - a.createTime;
        });
    }


    return [
        {
            tabKey: REWARD_ORDER_TAB_ALL,
            name: "All",
            layout: <RewardOrderHistoryList rewardOrderList={sort(rewardOrders)}
                                            emptyText={intl.formatMessage({ id: "reward_list_page_label_all_empty" })} />
        },
        {
            tabKey: REWARD_ORDER_STATUS.COMPLETED,
            name: "Completed",
            layout: <RewardOrderHistoryList
                rewardOrderList={sort(rewardOrders.filter(order => [REWARD_ORDER_STATUS.CANCELLED, REWARD_ORDER_STATUS.COMPLETED].includes(order.status)))}
                emptyText={intl.formatMessage({ id: "reward_list_page_label_completed_empty" })} />
        }
    ];
};

export const RewardOrderHistoryTabs = ({ tabs, activeTab, onTabClick }) => {
    return <RewardOrderHistoryTabWrapper>
        {
            tabs.map((tab) => {
                return <RewardOrderHistoryTab key={tab.tabKey}
                                              $active={activeTab === tab.tabKey}
                                              onClick={() => onTabClick(tab.tabKey)}
                                              data-testid={`tab-${tab.name}`}
                >
                    {getLabelI18nText(tab.name)}
                </RewardOrderHistoryTab>;
            })
        }
    </RewardOrderHistoryTabWrapper>;
};
const RewardOrderHistory = () => {
    const apiExecutor = useContext(ApiExecuteContext);
    const updateShowNewOrderDot = useContext(SetShowNewOrderDotContext);
    const toggleLoading = useLoadingToggle();
    const [rewardOrders, setRewardOrders] = useState([]);
    const [activeTab, setActiveTab] = useState(REWARD_ORDER_TAB_ALL);
    const intl = useIntl();

    const tabs = useMemo(() => getTabs(activeTab, rewardOrders, intl), [activeTab, rewardOrders, intl]);

    const tabBodyLayout = () => {
        let currentTab = tabs.find(({ tabKey }) => tabKey === activeTab);
        return currentTab?.layout || null;
    };

    const onTabClick = (eventKey) => {
        setActiveTab(eventKey);
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        updateShowNewOrderDot(false);
    }, [updateShowNewOrderDot]);

    useEffect(() => {
        toggleLoading(true);
        apiExecutor(
            fetchRewardOrders(),
            {
                onSuccess: (res) => {
                    setRewardOrders(res);
                },
                onFinally: () => toggleLoading(false)
            }
        );
    }, [toggleLoading, apiExecutor]);

    return (
        <>
            <Navbar
                navBackIconColor={SYSTEM_COLORS.primary80}
                navbarCenter={<Title><FormattedMessage id="reward_order_history_page_title" /></Title>}
                navbarBottom={<RewardOrderHistoryTabs
                    tabs={tabs}
                    activeTab={activeTab}
                    onTabClick={onTabClick} />}
            />
            <RewardOrderHistoryWrapper>
                <Container className="pb-2" data-testid="reward-order-container">
                    {tabBodyLayout()}
                </Container>
            </RewardOrderHistoryWrapper>
        </>
    );
};

export default RewardOrderHistory;
