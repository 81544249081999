import PlayerAvatar from "components/image/PlayerAvatar";
import { PlayerInfoVo } from "data/vo/player/PlayerInfoVo";
import { InjuryLabel } from "domain/slate/components/InjuryLabel";
import { NineCatPlayerDetailVO } from "domain/slate/data/ComponentTypes";
import React from "react";
import { useIntl } from "react-intl";

interface PlayerPopupProfileProps {
    selectedPlayer: NineCatPlayerDetailVO;
    playerInfo: PlayerInfoVo;

}

const PlayerPopupProfile: React.FC<PlayerPopupProfileProps> = ({
                                                                   selectedPlayer,
                                                                   playerInfo,
                                                               }) => {
    const intl = useIntl();
    return (
        <>
            <div className="tw-flex tw-flex-col tw-items-center">
                <div
                    className="tw-w-20 tw-h-20 tw-flex tw-items-center tw-justify-center tw-mb-1.5">
                    <div
                        className="tw-absolute tw-overflow-hidden tw-cursor-pointer tw-w-[7rem] tw-h-[7rem] tw-bg-primary-800 tw-rounded-full"
                    >
                        <PlayerAvatar
                            id={selectedPlayer.id}
                            url={selectedPlayer.avatar}
                        />
                    </div>
                </div>
                <div className="tw-text-h4 tw-text-white">
                    {selectedPlayer.firstName} {selectedPlayer.lastName}
                </div>
                <div
                    className="tw-text-white tw-text-body-2 tw-mb-2 tw-flex tw-items-center tw-gap-2">
                    <div className="tw-flex tw-items-center tw-gap-1">
                        <img
                            className="tw-w-4 tw-h-4"
                            alt={selectedPlayer.squadName}
                            src={selectedPlayer.squadLogoUrl}
                        />
                        {selectedPlayer.squadName}
                        <span>|</span>
                        {selectedPlayer.roleAbbr}
                    </div>

                    <InjuryLabel injuryPlayingChange={selectedPlayer.injuryPlayingChance} />
                </div>

                <div className="tw-text-error-300 tw-text-body-2">
                    {playerInfo.profile.getInjuryInfo((id: string) => intl.formatMessage({ id }))}
                </div>
            </div>

            <div
                className="tw-absolute -tw-bottom-11 tw-m-auto tw-left-0 tw-right-0 tw-bg-grey-100 tw-rounded-2xl tw-px-4 tw-py-2 tw-w-[90%] tw-text-body-2 tw-text-grey-1000 tw-text-center">
                <p>Nationality: {playerInfo.profile.nationality}</p>
                <p>{playerInfo.profile.basicInfo}</p>
            </div>
        </>
    );
};

export default PlayerPopupProfile;
