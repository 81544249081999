import clsx from "clsx";
import { isFunction, omit } from "lodash";
import PropTypes from "prop-types";
import React, { ReactNode } from "react";
import { Modal } from "react-bootstrap";

export enum DIALOG_SIZE {
    SM = "sm", //270
    LG = "lg", //340
    XL = "xl" // 550(app max width)
}

export enum DIALOG_BACKDROP {
    STATIC = "static"
}

export enum DIALOG_FULL_SCREEN {
    FULL = "full",
    NORMAL = "normal"
}

interface DialogProps {
    show: boolean
    full?: DIALOG_FULL_SCREEN
    offset?: boolean
    size?: DIALOG_SIZE
    backdrop?: DIALOG_BACKDROP | boolean
    contentClassName?: string
    children: ReactNode
    animation?: boolean
    contestClassName?: string
    dialogClassName?: string
    backdropClassName?: string
    className?: string
    onExited?: () => void,
    centered?: boolean,
}

const Dialog = (props: DialogProps) => {

    const fullDialog = DIALOG_FULL_SCREEN.FULL === props?.full ? "full-modal" : "";
    const offsetDialog = props?.offset ? "offset-x-550" : "";

    return (
        <Modal
            className={clsx(props.className, fullDialog, offsetDialog)}
            backdropClassName={clsx(props.backdropClassName, `${fullDialog} ${offsetDialog}`)}
            {...omit(props, ["offset", "className"])}
            aria-labelledby="contained-modal-title-vcenter"
            centered={props.centered ?? true}
            backdrop={props.backdrop || true}
        >
            {props.children}
        </Modal>
    );
};

Dialog.propTypes = {
    show: PropTypes.bool.isRequired,
    backdrop: PropTypes.oneOf([true, ...Object.values(DIALOG_BACKDROP)]),
    onHide: function (props, propName, componentName) {
        if (props.backdrop && !isFunction(props[propName])) {
            return new Error(
                "Invalid prop `" + propName + "` supplied to" +
                " `" + componentName + "`. Validation failed."
            );
        }
        return null;
    },
    full: PropTypes.oneOf(Object.keys(DIALOG_FULL_SCREEN)),
    contentClassName: PropTypes.string
};

export default Dialog;
