import { fetchFollow } from "apis/user/UserApi";
import clsx from "clsx";
import UserAvatarWithFrame from "components/avatar/UserAvatarWithFrame";
import TeamPreviewUserProfileSkeleton from "components/skeleton/TeamPreviewUserProfileSkeleton";
import B2CWrapper from "containers/b2cWrapper/B2CWrapper";
import TeamPreview from "containers/teamPreview";
import Badge, { BADGE_PATTERN, BADGE_SIZE, BADGE_VARIANTS } from "designToken/badge/Badge";
import { BUTTON_VARIANTS } from "designToken/button/types";
import { FriendActionButton } from "domain/friend/profile/components/follow/FriendActionButton";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import useFantasyHistory from "hooks/useFantasyHistory";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import React from "react";
import { getUserInfoPageUrl } from "router/RouterUrls";
import { useSelector } from "store";
import useUserIdentity from "../../../hooks/useUserIdentity";
import { TeamPreviewUserProfileWrapper } from "./TeamPreviewStyle";


export const TeamPreviewUserProfile: React.FC = () => {
    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();
    const history = useFantasyHistory();
    const teamPreview = TeamPreview.useTeamPreview();
    const { isB2B, isB2C } = useUserIdentity();

    const {
        TeamPreviewState: {
            data: {
                userProfile,
                teamInfoForUserProfile: {
                    teamName,
                    scoreCent
                },
            }
        },
        SettingsState: {
            following: {
                maxFollowing
            },
        },
    }
        = useSelector(store => store);
    const myFollow = useFantasyQuery([QUERY_KEY.GET_USER_FOLLOWERS, userProfile?.id], () => fetchFollow(userProfile!.id), {
        options: {
            enabled: typeof userProfile?.id === "number" && isB2C
        }
    });


    const goToUserProfile = () => {
        if (isB2B) {
            return;
        }

        teamPreview.updateIsOpenWhenBack(true);
        history.push(getUserInfoPageUrl(userProfile!.id));
    };

    if (!userProfile) {
        return <TeamPreviewUserProfileSkeleton />;
    }

    return <TeamPreviewUserProfileWrapper data-testid="team-preview-user-profile">
        <div className={clsx("d-flex gap-2", isB2B ? "" : "clickable")} onClick={goToUserProfile}>
            <div className="p-1">
                <UserAvatarWithFrame url={userProfile.avatar} name={userProfile.nickname} size="3.4rem" />
            </div>
            <div>
                <div className="fz-sf-bold-footnote text-text-primary-1">{userProfile.nickname}</div>
                <div className="fz-sf-bold-body-2 text-secondary-2 d-flex gap-2 align-items-center">
                    {toFormattedNumber(divideByOneHundred(scoreCent))}
                    <div className="fz-sf-bold-caption-1">Pts</div>
                    <Badge size={BADGE_SIZE.SM}
                           variant={BADGE_VARIANTS.SECONDARY}
                           pattern={BADGE_PATTERN.ROUNDED}
                    >
                        {teamName}
                    </Badge>
                </div>
            </div>
        </div>

        <B2CWrapper>
            <FriendActionButton
                user={userProfile}
                isReachedFollowingLimit={
                    !!myFollow?.data && myFollow?.data?.followingCount >= maxFollowing
                }
                unFollowButtonVariant={BUTTON_VARIANTS.light}
                onRefetchUserInfo={() => teamPreview.updateUserProfile(userProfile.id)} />
        </B2CWrapper>

    </TeamPreviewUserProfileWrapper>;
};

