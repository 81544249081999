import { Callback } from "Common";
import FilledPlayerStatSlotCardItem from "domain/slate/components/FilledPlayerStatSlotCardItem";
import PlayerStatSlotCardItem from "domain/slate/components/PlayerStatSlotCardItem";
import { NineCatPlayerInfoVO, PlayerStatsSlotCardVO } from "domain/slate/data/ComponentTypes";
import { useNineCatContext } from "domain/slate/providers/NineCatProvider";
import React from "react";
import { MouseEvent } from "react/index";

export interface PlayerStatsSlotCardFactoryProps extends PlayerStatsSlotCardVO {
    playerInfo?: NineCatPlayerInfoVO;
    onSelectStatPlayer: Callback;
    onOpenStatRulePopup: Callback<[MouseEvent]>;
}

const PlayerStatSlotCardFactory: React.FC<PlayerStatsSlotCardFactoryProps> = (props) => {
    const { handleOpenPlayerPopup } = useNineCatContext();
    const {
        playerInfo,
        onSelectStatPlayer,
    } = props;

    if (playerInfo) {
        return (
            <FilledPlayerStatSlotCardItem
                {...props}
                playerInfo={playerInfo}
                onSelectStatPlayer={onSelectStatPlayer}
                onOpenPlayerInfo={handleOpenPlayerPopup}
                onOpenStatDetailModal={props.onOpenStatRulePopup}
            />
        );

    }

    return (
        <PlayerStatSlotCardItem
            {...props}
            onSelectStat={onSelectStatPlayer}
            onOpenStatDetailModal={props.onOpenStatRulePopup}
        />
    );
};

export default PlayerStatSlotCardFactory;
