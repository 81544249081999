import React from "react";
import { TransactionDetailVo } from "data/vo/wallet/TransactionDetailVo";
import { FormattedMessage } from "react-intl";

export const StatusLabel: React.FC<{ transactionDetailData: TransactionDetailVo }> = ({ transactionDetailData }) => {

    if (transactionDetailData.isCancelled) {
        return <FormattedMessage id="label_cancelled" />;
    }

    if (transactionDetailData.isPadding) {
        return <FormattedMessage id="label_processing" />;
    }

    return <FormattedMessage id="label_success" />;
};
