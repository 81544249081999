import { OrderStatus, WALLET_API_RESPONSE_EVENT } from "data/dto/wallet/walletHistoryDto";
import { SPORT } from "types/sports/Sport";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import { formatToTextGroups } from "helpers/stringFormatHelpers";

export class TransactionDetailVo {
    public readonly historyId?: number;
    public readonly createTime: number;
    public readonly event: WALLET_API_RESPONSE_EVENT;
    public readonly amountCent: number;

    public readonly product?: string;
    public readonly sport?: SPORT;
    public readonly matchName?: string;
    public readonly contestName?: string;

    public readonly regularWinningCent?: number;
    public readonly extraWinningCent?: number;
    public readonly entryFeeCent?: number;
    public readonly couponCent?: number;
    public readonly rubyCent?: number;

    public readonly redemptionCode?: string;
    public readonly taskType?: string;

    public readonly orderStatus?: OrderStatus;
    public readonly receivingInstitutionName?: string;
    public readonly receiverAccountNumber?: string;
    public readonly withdrawalNetAmountCent?: number;
    public readonly withdrawalHandlingFeeCent?: number;


    constructor(
        createTime: number,
        event: WALLET_API_RESPONSE_EVENT,
        amountCent: number,
        historyId?: number,
        product?: string,
        sport?: SPORT,
        matchName?: string,
        contestName?: string,
        regularWinningCent?: number,
        extraWinningCent?: number,
        entryFeeCent?: number,
        couponCent?: number,
        rubyCent?: number,
        redemptionCode?: string,
        taskType?: string,
        orderStatus?: OrderStatus,
        receivingInstitutionName?: string,
        receiverAccountNumber?: string,
        withdrawalNetAmountCent?: number,
        withdrawalHandlingFeeCent?: number
    ) {
        this.historyId = historyId;
        this.createTime = createTime;
        this.event = event;
        this.amountCent = amountCent;
        this.product = product;
        this.sport = sport;
        this.matchName = matchName;
        this.contestName = contestName;
        this.regularWinningCent = regularWinningCent;
        this.extraWinningCent = extraWinningCent;
        this.entryFeeCent = entryFeeCent;
        this.couponCent = couponCent;
        this.rubyCent = rubyCent;
        this.redemptionCode = redemptionCode;
        this.taskType = taskType;
        this.orderStatus = orderStatus;
        this.receivingInstitutionName = receivingInstitutionName;
        this.receiverAccountNumber = receiverAccountNumber;
        this.withdrawalNetAmountCent = withdrawalNetAmountCent;
        this.withdrawalHandlingFeeCent = withdrawalHandlingFeeCent;
    }

    public get displayReceiverAccountNumber() {
        return formatToTextGroups(this.receiverAccountNumber ?? "");
    }

    public get withdrawalNetAmount() {
        return divideByOneHundred(this.withdrawalNetAmountCent ?? 0);
    }

    public get withdrawalHandlingFee() {
        return divideByOneHundred(this.withdrawalHandlingFeeCent ?? 0);
    }

    public get isPadding() {
        return this.orderStatus === OrderStatus.PENDING;
    }

    public get isCancelled() {
        return this.orderStatus === OrderStatus.CANCELLED;
    }

    public get amount() {
        return divideByOneHundred(this.amountCent);
    }

    public static create({
                             historyId,
                             createTime,
                             event,
                             amountCent,
                             product,
                             sport,
                             matchName,
                             contestName,
                             regularWinningCent,
                             extraWinningCent,
                             entryFeeCent,
                             couponCent,
                             rubyCent,
                             redemptionCode,
                             taskType,
                             orderStatus,
                             receivingInstitutionName,
                             receiverAccountNumber,
                             withdrawalNetAmountCent,
                             withdrawalHandlingFeeCent
                         }: {
        historyId?: number;
        createTime: number;
        event: WALLET_API_RESPONSE_EVENT;
        amountCent: number;
        product?: string;
        sport?: SPORT;
        matchName?: string;
        contestName?: string;
        regularWinningCent?: number;
        extraWinningCent?: number;
        entryFeeCent?: number;
        couponCent?: number;
        rubyCent?: number;
        redemptionCode?: string;
        taskType?: string;
        orderStatus?: OrderStatus;
        receivingInstitutionName?: string;
        receiverAccountNumber?: string;
        withdrawalNetAmountCent?: number;
        withdrawalHandlingFeeCent?: number;
    }): TransactionDetailVo {
        return new TransactionDetailVo(createTime, event, amountCent, historyId, product, sport, matchName, contestName, regularWinningCent, extraWinningCent, entryFeeCent, couponCent, rubyCent, redemptionCode, taskType, orderStatus, receivingInstitutionName, receiverAccountNumber, withdrawalNetAmountCent, withdrawalHandlingFeeCent);
    }
}
