import { Container, Image, ListGroup } from "react-bootstrap";
import styled from "styled-components";
import { APP_MAX_WIDTH } from "styles/Constants";

export const MeContainer = styled(Container).attrs(() => ({
    className: "position-relative",
}))`
    padding: 1.6rem 1.6rem 3rem;

    @media (min-width: ${APP_MAX_WIDTH}) {
        padding: 1.6rem 3.2rem 3rem;
    }
`;
styled.div.attrs(() => ({
    className: "position-absolute",
}))`
    top: 1.6rem;
    right: 1.6rem;
    width: 2.4rem;
    cursor: pointer;

    @media (min-width: ${APP_MAX_WIDTH}) {
        right: 3.2rem;
    }
`;
export const UserInfoAvatarWrapper = styled.div.attrs(() => ({
    className: "position-relative",
}))`
    margin-bottom: 1.8rem;
`;

styled.div.attrs({
    className: "rounded-circle overflow-hidden position-absolute",
})`
    width: 78%;
    height: 78%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

styled(Image).attrs({
    className: "position-absolute w-100 h-100",
})`
    top: 0;
    left: 0;
`;
export const UserCode = styled.div.attrs({
    className: "fz-sf-regular-subhead tw-text-system-labelLightPrimary",
})`
`;

styled(ListGroup).attrs(() => ({
    className: "border-0 tw-bg-grey-0",
}))`
    padding: 2.8rem 2.4rem;
    border-radius: 1.6rem;
`;

styled.div.attrs(() => ({
    className: "tw-bg-system-primary4"
}))`
    padding: 1rem;
    border-radius: 0.8rem;
`;

export const Icon = styled.div<{
    $bgImg: string
}>`
    width: 2rem;
    padding-bottom: 2rem;
    background-image: url(${({ $bgImg }) => $bgImg});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
`;
styled.div.attrs(() => ({
    className: "fz-sf-regular-body-1 tw-text-system-primary80",
}))`
    margin: 0 0.8rem;
`;
export const Version = styled.div.attrs(() => ({
    className: "p-2 text-center fz-sf-regular-footnote tw-text-system-labelLightSecondary",
}))`
`;

styled.div.attrs({
    className: "fz-sf-regular-subhead text-light-tertiary",
})`
    margin-right: 0.8rem;
`;
export const MobileVerificationStatusIcon = styled.div.attrs<{
    $bgImg: string,
    cursor: string
}>({
    className: "position-absolute",
})`

    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    right: 0.36rem;
    bottom: 0.36rem;
    background-color: #007aff;
    background-image: url(${({ $bgImg }) => $bgImg});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 60% auto;

    cursor: ${({ cursor }) => cursor};
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.09),
    0 0 4px rgba(44, 43, 42, 0.12);
`;

export const MobileUnVerificationStatusIcon = styled(
    MobileVerificationStatusIcon,
)`
    background-color: #c6c6c8;
`;

export const UnVerificationText = styled.div.attrs(() => ({}))`
    padding: 0.2rem 0.2rem;
    width: 9rem;
`;

export const MePlayingRecordWrapper = styled.div`
    margin: 1.6rem 0;
`;
