import { ApiExecuteContext } from "apis/ApiContext";
import { fetchSquad } from "apis/player/PlayerApi";
import { useLoadingToggle } from "components/loading/Loading";
import { calculateRestCreditCent, TEAM_CREDIT_CENT_LIMIT } from "domain/team/OperateTeamHelper";
import ChoosePlayer from "domain/team/player/ChoosePlayer";
import ChooseSpecialPlayer from "domain/team/special/ChooseSpecialPlayer";
import useKycRestriction from "hooks/useCheckKycRestriction/useKycRestriction";
import useDevice from "hooks/useDevice/useDevice";
import { useWarningDialog } from "hooks/useDialog";
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { updateTeamPreviewShow } from "store/actions/Actions";
import { APP_MAX_WIDTH_VALUE } from "styles/Constants";
import { MatchVO } from "types/match/Match";
import { LINEUP, SelectablePlayer } from "types/player/Player";
import { SPORT } from "types/sports/Sport";
import { MyTeamVO } from "types/team/MyTeam";


interface OperateTeamProps {
    OperateTeamBody?: ReactNode
    sport: SPORT
    match: MatchVO
    allPlayers: SelectablePlayer[]
    setAllPlayers: (initialState: SelectablePlayer[] | ((preState: SelectablePlayer[]) => SelectablePlayer[])) => void
    selectedPlayers: SelectablePlayer[]
    specialPlayer: any
    setSpecialPlayer: (player: any) => void
    onSuccess: () => void
    navbarTitle: string
    handleSubmit: () => void
    onBeforeChooseSpecialPlayer: () => void
    showLeavingWarningPopup: () => void
    backToChoosePlayer: () => void
    backToChooseSpecialPlayer: () => void
    nextToChooseSpecialPlayer: () => void
    showPreview: boolean
    previewToggle: () => void
    restCreditCent: number
    loading?: boolean
    handleTimeout: () => void,
    myTeams: MyTeamVO[]
    scrollSpyRootSelector: string | undefined
    isStartingLineupDecided: boolean
}

export const TeamContext = React.createContext<OperateTeamProps>({} as OperateTeamProps);

export const useOperateTeam = ({ sport, match, onNavbarBackClick, beOperatedTeam }: {
    sport: SPORT,
    match: MatchVO,
    onNavbarBackClick: Function,
    beOperatedTeam?: MyTeamVO | null
}) => {
    const apiExecutor = useContext(ApiExecuteContext);
    const toggleLoading = useLoadingToggle();
    const { deviceWidth } = useDevice();

    const [OperateTeamBody, setOperateTeamBody] = useState(() => ChoosePlayer);

    const [allPlayers, setAllPlayers] = useState<SelectablePlayer[]>([]);
    const [specialPlayer, setSpecialPlayer] = useState({ captain: "", viceCaptain: "" });
    const [showPreview, setShowPreview] = useState(deviceWidth >= APP_MAX_WIDTH_VALUE * 2);
    const intl = useIntl();
    const showWarningPopup = useWarningDialog();
    const isRestrictedByKyc = useKycRestriction();

    const selectedPlayers = useMemo(() => {
        return allPlayers.filter(player => player.isSelected).map(player => {
            if (player.id === specialPlayer.captain) {
                player.setCaptain();
            } else if (player.id === specialPlayer.viceCaptain) {
                player.setViceCaptain();
            } else {
                player.setGeneral();
            }

            return player;
        });
    }, [allPlayers, specialPlayer]);

    const isStartingLineupDecided: boolean = allPlayers.some(player => player.lineup === LINEUP.STARTING_LINEUP);
    const dispatch = useDispatch();

    const showLeavingWarningPopup = useCallback(() => {
        showWarningPopup({
            title: intl.formatMessage({ id: "sure_to_go_back" }),
            content: intl.formatMessage({ id: "change_not_saved" }),
            cancelButton: {
                text: intl.formatMessage({ id: "label_cancel" })
            },
            confirmButton: {
                text: intl.formatMessage({ id: "label_sure" }),
                onClick: () => {
                    onNavbarBackClick();
                    dispatch(updateTeamPreviewShow(false));
                }
            }
        });
    }, [showWarningPopup, intl, onNavbarBackClick, dispatch]);

    const backToChoosePlayer = useCallback(() => {
        setOperateTeamBody(() => ChoosePlayer);
    }, []);

    const backToChooseSpecialPlayer = useCallback(() => {
        setOperateTeamBody(() => ChooseSpecialPlayer);
    }, []);

    const nextToChooseSpecialPlayer = useCallback(async () => {
        if (await isRestrictedByKyc()) {
            return;
        }
        setOperateTeamBody(() => ChooseSpecialPlayer);
    }, [isRestrictedByKyc]);

    const previewToggle = useCallback(() => {
        setShowPreview(prevState => !prevState);
    }, []);

    const restCreditCent = calculateRestCreditCent(TEAM_CREDIT_CENT_LIMIT, selectedPlayers);


    useEffect(() => {
        toggleLoading(true);

        apiExecutor(
            fetchSquad(sport, match.matchId),
            {
                onSuccess: (squads) => {
                    const selectablePlayers = SelectablePlayer.fromSquadAllJson(squads, match, sport);

                    if (beOperatedTeam) {
                        setAllPlayers(selectablePlayers.map(player => {
                            player.setSelected(!!beOperatedTeam.players.find(_player => _player.id === player.id));
                            return player;
                        }));
                    } else {
                        setAllPlayers(selectablePlayers);
                    }


                },
                onFinally: () => {
                    toggleLoading(false);
                }
            }
        );
    }, [sport, match, apiExecutor, toggleLoading, beOperatedTeam]);

    return {
        toggleLoading,
        OperateTeamBody,
        selectedPlayers,
        specialPlayer,
        setSpecialPlayer,
        showLeavingWarningPopup,
        backToChoosePlayer,
        backToChooseSpecialPlayer,
        nextToChooseSpecialPlayer,
        showPreview,
        previewToggle,
        allPlayers,
        setAllPlayers,
        restCreditCent,
        isStartingLineupDecided
    };
};
