import React, { ReactNode } from "react";
import { b2bGameHollowLogo, clickToPlay, closeThisTab } from "helpers/IconHelper";
import { cn } from "helpers/cn";

interface B2BErrorPageProps {
    errorMessage: ReactNode;
}

export const B2BErrorPageTemplate = ({ errorMessage }: B2BErrorPageProps) => (
    <div
        className={cn("tw-min-h-screen tw-flex tw-flex-col tw-items-center tw-gap-16", "tw-text-white tw-p-4 tw-pt-[7.8rem]", "tw-bg-gradient-to-b tw-from-[#3D5333] tw-via-[#283D1F] tw-to-[#011913] tw-from-0% tw-via-14% tw-to-100%")}
    >
        <div className="tw-flex tw-justify-center tw-items-center tw-flex-col">
            <img
                src={b2bGameHollowLogo}
                alt="Logo"
                className="tw-w-[15.8rem] tw-h-auto tw-mb-1"
            />
            {errorMessage}
        </div>
        <div className="tw-flex tw-justify-center tw-items-center tw-flex-col">
            <p className="tw-text-h3 tw-font-bold tw-mb-4">Please close this tab.</p>
            <img
                src={closeThisTab}
                alt="Close this tab"
                className="tw-w-48"
            />
        </div>
        <div className="tw-flex tw-justify-center tw-items-center tw-flex-col tw-max-w-[35.8rem]">
            <p className="tw-text-h3 tw-font-bold tw-mb-4 tw-text-center">And reopen <span
                className="tw-text-yellow-400">Power 11</span> from the platform.</p>
            <div className="tw-relative">
                <img
                    src={clickToPlay}
                    alt="clickToPlay"
                    className="tw-w-48 tw-rounded-lg"
                />
            </div>
        </div>
    </div>
);
