import { Scrollspy } from "@makotot/ghostui";
import clsx from "clsx";
import JoinableNavbarMatchInfo from "components/container/navbar/JoinableNavbarMatchInfo";
import Navbar from "components/container/navbar/Navbar";
import PageViewRecorder from "components/ga/PageViewRecorder";
import CustomTab, { TabText } from "components/tab/CustomTab";
import TeamPreview from "containers/teamPreview";
import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";
import { getSportConfig } from "domain/SportConfig";
import { TeamContext } from "domain/team/OperateTeam";
import { isAllValidationPassed, shouldPlayerNotBeSelected } from "domain/team/OperateTeamHelper";
import { sortAndGroupPlayers } from "domain/team/player/ChoosePlayerHelper";
import { Anchor, PlayerContainer, TabWrapper } from "domain/team/player/ChoosePlayerStyle";
import AutoChoosePlayer from "domain/team/player/components/AutoChoosePlayer";
import ChoosePlayerHeader from "domain/team/player/components/ChoosePlayerHeader";
import ChoosePlayerNavbarBottom from "domain/team/player/components/ChoosePlayerNavbarBottom";
import PlayerCard from "domain/team/player/components/playerCard/PlayerCard";
import ScoringPopup from "domain/team/player/components/scoringPopup/ScoringPopup";
import usePlayerInfoDialog from "domain/team/playerInfoDialog/PlayerInfoDialog";
import { PlayerInfoDialogFooterType } from "domain/team/playerInfoDialog/PlayerInfoDialogFooter";
import { PlayerInfoDialogHeaderType } from "domain/team/playerInfoDialog/PlayerInfoDialogHeader";
import { analyticsEvent, logCustomEvent } from "ga";
import { Icon } from "helpers/IconHelper";
import useDevice from "hooks/useDevice/useDevice";
import useMyProfile from "hooks/useMyProfile";
import useSort from "hooks/useSort/useSort";
import useUserIdentity from "hooks/useUserIdentity";
import { flatMap } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { MdHelpOutline } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import { Prompt } from "react-router-dom";
import { SQUAD_RIGHT_SLOT_TYPE } from "store/reducer/teamPreview/types";
import { FixedFooter } from "styles/component/GeneralStyle";
import { APP_MAX_WIDTH_VALUE } from "styles/Constants";

const NavbarBottom = ({ restCreditCent, tabs, selectedPlayers, currentRole }) => {
    const {
        match,
        sport
    } = useContext(TeamContext);

    const onTabClick = (eventKey) => {
        const anchor = document.getElementById(eventKey);
        anchor?.scrollIntoView({ behavior: "smooth" });
    };


    return <>
        <ChoosePlayerNavbarBottom match={match}
                                  sport={sport}
                                  selectedPlayers={selectedPlayers}
                                  restCreditCent={restCreditCent}
        />
        <TabWrapper>
            <CustomTab tabs={tabs}
                       activeTab={currentRole}
                       onClick={onTabClick}
                       isEvenly={true} />
        </TabWrapper>
    </>;
};

const playerSelectionRate = "playerSelectionRate";
const pointAvg = "pointAvg";
const credit = "credit";
const getRangeText = (formatMessage, lowerBound, upperBound) => {
    if (lowerBound === upperBound) {
        return formatMessage({ id: "select_player_page_player_section_label_player_limit_1" }, { number: lowerBound });
    }

    return formatMessage({ id: "select_player_page_player_section_label_player_limit_2" }, {
        lowerBound,
        upperBound
    });
};


const ChoosePlayer = () => {
    const [scoringPopupShow, setScoringPopupShow] = useState(false);
    const { deviceWidth } = useDevice();
    const {
        allPlayers,
        selectedPlayers,
        match,
        setAllPlayers,
        showLeavingWarningPopup,
        handleTimeout,
        sport,
        restCreditCent,
        onBeforeChooseSpecialPlayer,
        nextToChooseSpecialPlayer,
        myTeams,
        scrollSpyRootSelector,
    } = useContext(TeamContext);

    const intl = useIntl();
    const { team } = getSportConfig(sport);
    const { isB2B } = useUserIdentity();
    const { myProfile } = useMyProfile();

    const { sortKey: currentSortKey, sortingDirection, handleSort } = useSort(playerSelectionRate);

    const onNextButtonClicked = () => {
        onBeforeChooseSpecialPlayer();
        window.scrollTo(0, 0);
        nextToChooseSpecialPlayer();
    };

    const handleBlockedRoute = () => {
        showLeavingWarningPopup();
        return false;
    };


    const playersGroupByRole = sortAndGroupPlayers(allPlayers.map(player => {
            player.setDisabled(shouldPlayerNotBeSelected(player, selectedPlayers, restCreditCent, team));
            return player;
        }
    ), currentSortKey, sortingDirection);

    const {
        handlePlayerInfoDialogOpen: openPlayerInfoDialog,
        handlePlayerInfoDialogClose,
        isPlayerInfoOpen,
        PlayerInfoDialog
    } = usePlayerInfoDialog(PlayerInfoDialogHeaderType.CREDIT, PlayerInfoDialogFooterType.WITH_OPERATE);

    const handlePlayerInfoDialogOpen = (player) => {
        openPlayerInfoDialog(player);
        if (scoringPopupShow) {
            setScoringPopupShow(false);
        }
    };

    const teamPreview = TeamPreview.useTeamPreview({
        sport,
        offsetHeight: 13,
        squadRightSlotType: SQUAD_RIGHT_SLOT_TYPE.SUBSTITUTION,
        onAvatarClick: handlePlayerInfoDialogOpen
    });

    const isPreviewActive = useMemo(() => teamPreview.show && !scoringPopupShow && !isPlayerInfoOpen, [teamPreview.show, scoringPopupShow, isPlayerInfoOpen]);

    useEffect(() => {
        if (deviceWidth >= APP_MAX_WIDTH_VALUE * 2) {
            teamPreview.open();
        } else {
            teamPreview.close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        teamPreview.updateData({
            players: selectedPlayers,
        });
    }, [selectedPlayers, teamPreview]);

    const sectionRefs = playersGroupByRole.map(() => React.createRef<HTMLDivElement>());

    const nextStepFlag = useMemo(() => {
        return isAllValidationPassed(selectedPlayers, team);
    }, [selectedPlayers, team]);

    const tabs = playersGroupByRole.map(({ role }) => {
        const count = selectedPlayers.filter(player => player.role === role).length;

        return {
            tabKey: role,
            text: <TabText
                text={team.getRoleAbbr(role)}
                count={count} />
        };
    });


    const getRoleHeaders = (role) => {
        const { LOWER, UPPER } = team.roleLimit[role];

        return [
            {
                key: playerSelectionRate,
                text: getRangeText(intl.formatMessage, LOWER, UPPER) + " " + intl.formatMessage({ id: `player_role_${sport.toLowerCase()}_${role}` })
            },
            {
                key: pointAvg,
                text: intl.formatMessage({ id: "label_score" })
            },
            {
                key: credit,
                text: intl.formatMessage({ id: "label_credits" })
            }
        ];
    };

    const oneClickToChoose = () => {
        setAllPlayers(prevAllPlayers => team.oneClickChoosePlayer(prevAllPlayers));
    };

    const handlePlayerCheckboxOnChange = player => {
        logCustomEvent(analyticsEvent.webClickInfoAdd);
        setAllPlayers(prevAllPlayers => {
            return prevAllPlayers.map(prevPlayer => {
                if (prevPlayer.id === player.id) {
                    prevPlayer.setSelected(!prevPlayer.isSelected);
                }

                return prevPlayer;
            });
        });
    };

    if (allPlayers.length === 0) {
        return null;
    }

    const offsetHeight = 211;
    const anchorHeight = offsetHeight - 1;

    const handleScoringPopupToggle = () => {
        setScoringPopupShow(prev => {
            return !prev;
        });

        if (isPlayerInfoOpen) {
            handlePlayerInfoDialogClose();
        }
    };

    const handleScoringPopupClose = () => {
        setScoringPopupShow(false);
    };

    const handlePreviewClick = () => {
        if (!isPreviewActive) {
            handleScoringPopupClose();
            handlePlayerInfoDialogClose();
            teamPreview.open();
        } else {
            teamPreview.close();
        }
    };

    return (
        <>
            <Scrollspy sectionRefs={sectionRefs} offset={offsetHeight * -1} rootSelector={scrollSpyRootSelector}>
                {({ currentElementIndexInViewport }) => {
                    return (
                        <>
                            <PageViewRecorder title="Choose Player" />
                            <AutoChoosePlayer onButtonClicked={oneClickToChoose} />
                            <Navbar onNavbarBackClick={showLeavingWarningPopup}
                                    navbarStartAppend={<JoinableNavbarMatchInfo match={match}
                                                                                handleTimeout={handleTimeout} />}
                                    endIcon={
                                        isB2B ? null
                                            : <div className="tw-cursor-pointer tw-ml-3"
                                                   data-testid="help-icon"
                                                   onClick={handleScoringPopupToggle}>
                                                <MdHelpOutline size="2.4rem" />
                                            </div>
                                    }
                                    isHeaderLine={false}
                                    shouldCalculatePaddingTop={false}
                                    navbarBottom={<NavbarBottom
                                        currentRole={currentElementIndexInViewport >= 0 ? playersGroupByRole[currentElementIndexInViewport].role : ""}
                                        restCreditCent={restCreditCent}
                                        selectedPlayers={selectedPlayers}
                                        tabs={tabs} />}
                            />
                            <PlayerContainer>
                                {
                                    playersGroupByRole.map(({ role, players }, idx) => {
                                        return <div key={role} ref={sectionRefs[idx]}>
                                            <Anchor id={role} $size={anchorHeight} />
                                            <ChoosePlayerHeader
                                                sortKey={currentSortKey}
                                                orderBy={sortingDirection}
                                                handleSort={handleSort}
                                                headers={getRoleHeaders(role)} />
                                            {
                                                players.map((player) => {

                                                        const { isDisabled, isSelected } = player;

                                                        const containerClassName = clsx(
                                                            {
                                                                "fantasy-select-team-card-bg": (isSelected && !isDisabled)
                                                            },
                                                            isDisabled ? "opacity-50" : "clickable",
                                                        );

                                                        return <PlayerCard key={player.id}
                                                                           player={player}
                                                                           containerClassName={containerClassName}
                                                                           handlePlayerCheckboxOnChange={handlePlayerCheckboxOnChange}
                                                                           onAvatarClick={handlePlayerInfoDialogOpen.bind(null, player)}
                                                        />;
                                                    }
                                                )
                                            }
                                        </div>;
                                    })
                                }
                            </PlayerContainer>
                        </>
                    );
                }}

            </Scrollspy>

            <FixedFooter>
                <Button onClick={handlePreviewClick} variant={BUTTON_VARIANTS.secondary}
                        prefixIcon={isPreviewActive ? Icon.EyeOff : Icon.EyeOn}
                        size={BUTTON_SIZE.lg}
                        pattern={BUTTON_PATTERN.pill} className={"w-100"}>
                    <FormattedMessage id="label_preview" />
                </Button>
                <Button className={"w-100"}
                        disabled={!nextStepFlag}
                        onClick={onNextButtonClicked}
                        size={BUTTON_SIZE.lg}
                        pattern={BUTTON_PATTERN.pill}
                        dataTestId="choose-player-next-button">
                    <FormattedMessage id="button_next" />
                </Button>
            </FixedFooter>

            <PlayerInfoDialog
                match={match}
                players={flatMap(playersGroupByRole, ({ players }) => players)}
                myTeams={myTeams}
                handleSelectedPlayer={handlePlayerCheckboxOnChange}
            />

            <ScoringPopup
                show={scoringPopupShow}
                sport={sport}
                onClose={handleScoringPopupClose}
            />

            <Prompt when={!myProfile.isRestrictedByKyc} message={handleBlockedRoute} />
        </>
    );
};

export default ChoosePlayer;
