import { Image } from "react-bootstrap";
import styled from "styled-components";

export const AvatarWithFrameWrapper = styled.div.attrs<{
    $size?: string
}>({
    className: "position-relative"
})`
  width: ${({ $size }) => $size};
  height: ${({ $size }) => $size};
`;

export const AvatarWrapper = styled.div.attrs<{
    $proportion?: string
}>({
    className: "rounded-circle overflow-hidden position-absolute"
})`
  width: ${({ $proportion }) => $proportion ?? "90%"};
  height: ${({ $proportion }) => $proportion ?? "90%"};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const AvatarImage = styled.img.attrs(() => ({
    className: "mx-auto w-100 mh-100"
}))`
`;

export const FirstLetterIcon = styled.div.attrs(() => ({
    className: "d-flex justify-content-center align-items-center rounded-circle mx-auto my-auto w-100 h-100 tw-text-grey-0"
}))`
`;

export const Frame = styled(Image).attrs({
    className: "position-absolute w-100 h-100"
})`
  top: 0;
  left: 0;
`;
