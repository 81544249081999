import _ from "lodash";

export default class HandlePlayerList {
    constructor(specialRole, checked, selectedPlayer) {
        this.specialRole = specialRole;
        this.checked = checked;
        this.selectedPlayer = selectedPlayer || undefined;
        this.resultPlayerList = [];
        this.resultSpecialPlayer = { captain: "", viceCaptain: "" };
    }

    setSpecialPlayer(specialPlayer) {
        const { specialRole, selectedPlayer, checked } = this;
        const resultSpecialPlayer = { ...specialPlayer };

        if (specialRole === "captain") {
            if (checked) {
                resultSpecialPlayer.captain = selectedPlayer;
                if (resultSpecialPlayer.viceCaptain === selectedPlayer) {
                    resultSpecialPlayer.viceCaptain = "";
                }
            } else {
                resultSpecialPlayer.captain = "";
            }
        }

        if (specialRole === "viceCaptain") {
            if (checked) {
                resultSpecialPlayer.viceCaptain = selectedPlayer;
                if (resultSpecialPlayer.captain === selectedPlayer) {
                    resultSpecialPlayer.captain = "";
                }
            } else {
                resultSpecialPlayer.viceCaptain = "";
            }
        }

        return resultSpecialPlayer;
    }

    execute(playerList, specialPlayer) {
        const { specialRole, checked, selectedPlayer } = this;
        if (!specialRole || !_.isBoolean(checked) || !selectedPlayer) return;

        const resultSpecialPlayer = this.setSpecialPlayer(specialPlayer);
        this.resultSpecialPlayer = resultSpecialPlayer;
    }

    getResult() {
        return {
            resultSpecialPlayer: this.resultSpecialPlayer
        };
    }
}