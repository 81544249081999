import styled from "styled-components";
import { OutlineButton } from "../../styles/component/ButtonsStyle";
import { APP_MAX_WIDTH } from "../../styles/Constants";

export const ErrorPageWrapper = styled.div.attrs(() => ({
    className: "w-100 h-100 d-flex flex-column align-items-center justify-content-center tw-bg-system-bgLightSecondary"
}))``;
export const Header = styled.div.attrs(() => ({
    className: "position-fixed"
}))`
  top: 0;
  left: 0;
  background: linear-gradient(180deg, #02034A 0%, rgba(20, 33, 103, 0.94) 100%);
  height: 7.4rem;
  width: 100%;
  max-width: ${APP_MAX_WIDTH};
`;
export const Logo = styled.div.attrs<{ img: string }>({
    className: "position-absolute"
})`
  bottom: .8rem;
  left: 50%;
  transform: translateX(-50%);
  width: 10.4rem;
  padding-bottom: calc(10.4rem / 475 * 128);
  background-image: url(${({ img }) => img});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
`;

export const FromMobileAppErrorButton = styled(OutlineButton).attrs(() => ({}))`
  margin-top: 1.6rem;
`;
