import styled from "styled-components";

export const PrizeWrapper = styled.div.attrs({
    className: "d-flex justify-content-between w-100 align-items-center"
})`
    padding: .4rem 1.6rem 0;
`;

export const YouText = styled.span.attrs({
    className: "fz-sf-regular-caption-1 fz-sf-bold-subhead"
})`
`;

export const UpcomingWrapper = styled.div.attrs(() => ({
    className: "position-relative d-flex flex-column"
}))`
    z-index: 2;
    padding: 1.8rem 1.6rem;
    gap: .8rem;
`;



