import React from "react";
import { Icon, IconOmitProps } from "../../../helpers/IconHelper";

type QuestPrizeIconProps = {
    coinCent: number,
    rubyCent: number,
    hide?: boolean,
};

export const RegularQuestPrizeIcon: React.FC<QuestPrizeIconProps> = (props) => {

    const params = { hide: false, ...props };

    const className = `tw-mx-auto ${params.hide ? "tw-opacity-0" : ""}`;

    return <QuestPrizeIcon {...props}
                           className={className}
                           iconSet={Icon.QuestPrize.Regular} />;
};

export const MaxQuestPrizeIcon: React.FC<QuestPrizeIconProps> = (props) => {
    return <QuestPrizeIcon {...props}
                           iconSet={Icon.QuestPrize.Max} />;
};

export const VideoQuestPrizeIcon: React.FC<QuestPrizeIconProps> = (props) => {
    return <QuestPrizeIcon {...props}
                           iconSet={Icon.QuestPrize.Video} />;
};

type QuestPrizeIconInnerProps = {
    coinCent: number,
    rubyCent: number,
    className?: string,
    iconSet: {
        CoinHigh: React.FC<IconOmitProps>,
        CoinMedium: React.FC<IconOmitProps>,
        CoinLow: React.FC<IconOmitProps>,
        RubyHigh: React.FC<IconOmitProps>,
        RubyMedium: React.FC<IconOmitProps>,
        RubyLow: React.FC<IconOmitProps>,
    },
};

const QuestPrizeIcon = ({ coinCent, rubyCent, className, iconSet: IconSet }: QuestPrizeIconInnerProps) => {
    if (coinCent > 5000) {
        return <IconSet.CoinHigh data-testid="quest_prize_coin_high" className={className} />;
    }
    if (coinCent > 1500) {
        return <IconSet.CoinMedium data-testid="quest_prize_coin_medium" className={className} />;
    }
    if (coinCent > 0) {
        return <IconSet.CoinLow data-testid="quest_prize_coin_low" className={className} />;
    }

    if (rubyCent > 180000) {
        return <IconSet.RubyHigh data-testid="quest_prize_ruby_high" className={className} />;
    }
    if (rubyCent > 70000) {
        return <IconSet.RubyMedium data-testid="quest_prize_ruby_medium" className={className} />;
    }
    return <IconSet.RubyLow data-testid="quest_prize_ruby_low" className={className} />;
};