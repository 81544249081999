import styled, { css, keyframes } from "styled-components";

export const MatchStatusWrapper = styled.div.attrs(() => ({
    className: "d-flex justify-content-center align-items-center"
}))`
  width: fit-content;
`;

const blink = keyframes`
  to {
    opacity: 0;
  }
`;

function handleBlink(shouldBlink) {
    if (shouldBlink) {
        return css`
          animation: ${blink} .6s ease-in-out infinite alternate;
        `;
    }
}

export const MatchStatusDot = styled.span.attrs(() => ({
    className: "d-inline-block rounded-circle me-2"
}))`
  background-color: currentColor;
  width: 0.7rem;
  height: 0.7rem;
  ${({ $shouldBlink }) => {
    return handleBlink($shouldBlink);
}}


`;

export const MatchStatusText = styled.div.attrs({
    className: "fz-sf-regular-caption-1"
})`
  ${({ $shouldBlink }) => {
    return handleBlink($shouldBlink);
}}
`;

