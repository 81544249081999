export enum WITHDRAW_STEP {
    step1 = 1,
    step2 = 2,
    step3 = 3,
    step4 = 4,
}

export class WithdrawCurrentStepVo {
    static firstStep = WITHDRAW_STEP.step1;
    private static finalStep = WITHDRAW_STEP.step4;

    constructor(currentStep: number) {
        this._value = currentStep;
    }

    private _value: number;

    get value() {
        return this._value;
    }

    isFirstStep() {
        return this._value === WithdrawCurrentStepVo.firstStep;
    }

    isFinalStep() {
        return this._value === WithdrawCurrentStepVo.finalStep;
    }
}
