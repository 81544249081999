import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { FeatureContest } from "officialWebsite/FeatureContest/FeatureContest";

export const OfficialWebsite = () => {
    return (
        <Switch>
            <Route exact path={ROUTER_PATH_CONFIG.officialWebsite.featureContest}>
                <FeatureContest />
            </Route>
        </Switch>
    );
};
