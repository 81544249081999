import { isContestCanceled, isSlateLive, isSlateUpcoming } from "helpers/match/MatchHelper";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import { SLATE_STATUS_FROM_API } from "../../../domain/slate/data/dto";
import { AP_LEVEL } from "../../../types/activityPoint/ApLevel";
import {
    CONTEST_STATUS_FROM_API,
    CONTEST_TYPE_FROM_API,
    ENTRY_FEE_TYPE_FROM_API
} from "../../../types/contest/Contest";
import { MyNineCatDto } from "../../dto/nineCat/MyNineCatDto";
import { MatchComponentStatusEnum } from "../match/MyMatchVo";

export class MyNineCatVo {
    public id: number;
    public code: string;
    public nineCatId: number;
    public slateStartsAt: number;
    public slateStatus: SLATE_STATUS_FROM_API;
    public entryFee: number;
    public entryFeeType: ENTRY_FEE_TYPE_FROM_API;
    public firstPrize: number;
    public entryLimit: number;
    public spots: number;
    public minimumSlip: number;
    public allowDiscountWithRuby: boolean;
    public slipCount: number;
    public status: CONTEST_STATUS_FROM_API;
    public minApLevel: AP_LEVEL;
    public minApLevelWeight: number;
    public myJoinedTeams: string[];
    public contestType: CONTEST_TYPE_FROM_API;

    constructor(dto: MyNineCatDto) {
        this.id = dto.id;
        this.code = dto.code;
        this.nineCatId = dto.nineCatId;
        this.slateStartsAt = dto.slateStartsAt;
        this.slateStatus = dto.slateStatus;
        this.entryFee = divideByOneHundred(dto.entryFeeCent);
        this.entryFeeType = dto.entryFeeType;
        this.firstPrize = divideByOneHundred(dto.firstPrizeCent);
        this.entryLimit = dto.entryLimit;
        this.spots = dto.spots;
        this.minimumSlip = dto.minimumSlip;
        this.allowDiscountWithRuby = dto.allowDiscountWithRuby;
        this.slipCount = dto.slipCount;
        this.status = dto.status;
        this.minApLevel = dto.minApLevel;
        this.minApLevelWeight = dto.minApLevelWeight;
        this.myJoinedTeams = dto.myJoinedTeamsNames;
        this.contestType = dto.type;
    }

    get group(): MatchComponentStatusEnum {
        if (isContestCanceled(this.status)) {
            return MatchComponentStatusEnum.COMPLETED;
        }

        if (isSlateLive(this.slateStatus)) {
            return MatchComponentStatusEnum.LIVE;
        }
        if (isSlateUpcoming(this.slateStatus)) {
            return MatchComponentStatusEnum.UPCOMING;
        }

        return MatchComponentStatusEnum.COMPLETED;
    }
}
