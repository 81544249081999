import { UserId } from "Common";
import { AP_LEVEL } from "types/activityPoint/ApLevel";

export class CurrentApLevelVo {
    private userId: UserId;
    private currentLevel: AP_LEVEL;

    constructor(userId: UserId, currentLevel: AP_LEVEL) {
        this.userId = userId;
        this.currentLevel = currentLevel;
    }

}
