import { OrderStatus, WALLET_API_RESPONSE_EVENT, WALLET_TYPE } from "data/dto/wallet/walletHistoryDto";
import { divideByOneHundred, isNegative } from "helpers/number/NumberHelper";
import { minusIcons, plusIcons } from "domain/wallet/WalletHelper";
import { isNull } from "lodash";

export class WalletHistoryVo {
    public readonly historyId?: number;
    public readonly createTime: number;
    public readonly type: WALLET_TYPE;
    public readonly event: WALLET_API_RESPONSE_EVENT;
    public readonly amountCent: number;

    public readonly totalCent?: number;
    public readonly transferredTo?: string;
    public readonly transferredFrom?: string;
    public readonly expectedAmountCent?: number;
    public readonly orderStatus?: OrderStatus;

    public refereeUserCode?: string;
    public referrerUserCode?: string;

    constructor(
        historyId: number,
        createTime: number,
        type: WALLET_TYPE,
        event: WALLET_API_RESPONSE_EVENT,
        amountCent: number,
        totalCent?: number,
        transferredTo?: string,
        transferredFrom?: string,
        expectedAmountCent?: number,
        orderStatus?: OrderStatus,
        refereeUserCode?: string,
        referrerUserCode?: string
    ) {
        this.historyId = historyId;
        this.createTime = createTime;
        this.type = type;
        this.event = event;
        this.amountCent = amountCent;
        this.totalCent = totalCent;
        this.transferredTo = transferredTo;
        this.transferredFrom = transferredFrom;
        this.expectedAmountCent = expectedAmountCent;
        this.orderStatus = orderStatus;
        this.refereeUserCode = refereeUserCode;
        this.referrerUserCode = referrerUserCode;
    }

    public get icon() {
        return isNegative(this.amountCent) ? minusIcons[this.type] : plusIcons[this.type];
    }

    public get isShowDetail() {

        switch (this.type) {
            case WALLET_TYPE.COIN:
                return [
                    WALLET_API_RESPONSE_EVENT.USER_DEPOSIT,
                    WALLET_API_RESPONSE_EVENT.JOIN_CONTEST,
                    WALLET_API_RESPONSE_EVENT.REFUND_CONTEST,
                    WALLET_API_RESPONSE_EVENT.REDEEM,
                    WALLET_API_RESPONSE_EVENT.USER_WITHDRAW_COIN,
                    WALLET_API_RESPONSE_EVENT.REDEMPTION_CODE_DEPOSIT,
                    WALLET_API_RESPONSE_EVENT.PURCHASE_REWARD_REDEPOSIT,
                    WALLET_API_RESPONSE_EVENT.PURCHASE_REWARD_VOUCHER,
                    WALLET_API_RESPONSE_EVENT.CLAIM_TASK,
                ].includes(this.event);
            case WALLET_TYPE.WINNING:
                return [
                    WALLET_API_RESPONSE_EVENT.WIN_CONTEST,
                    WALLET_API_RESPONSE_EVENT.REDEEM,
                    WALLET_API_RESPONSE_EVENT.PURCHASE_REWARD_VOUCHER,
                    WALLET_API_RESPONSE_EVENT.PURCHASE_REWARD_REDEPOSIT,
                    WALLET_API_RESPONSE_EVENT.REFUND_REWARD_ORDER,
                    WALLET_API_RESPONSE_EVENT.USER_WITHDRAW_WINNING,
                    WALLET_API_RESPONSE_EVENT.CANCELLED_USER_WITHDRAW_WINNING
                ].includes(this.event);
            case WALLET_TYPE.RUBY:
                return [
                    WALLET_API_RESPONSE_EVENT.JOIN_RUBY_CONTEST,
                    WALLET_API_RESPONSE_EVENT.REFUND_RUBY_CONTEST,
                    WALLET_API_RESPONSE_EVENT.WIN_CONTEST,
                    WALLET_API_RESPONSE_EVENT.CLAIM_TASK
                ].includes(this.event) && !isNull(this.historyId);

            default:
                return false;
        }
    }

    public get amount() {
        return divideByOneHundred(this.amountCent);
    }

    public get expectedAmount() {
        return divideByOneHundred(this.expectedAmountCent ?? 0);
    }

    public get isWinningType() {
        return this.type === WALLET_TYPE.WINNING;
    }

    public get isOrderPending() {
        return this.orderStatus === OrderStatus.PENDING;
    }

    public get isOrderCancelled() {
        return this.orderStatus === OrderStatus.CANCELLED;
    }

    public get hasDifferentExpectedAmountCent() {
        return typeof this.expectedAmountCent === "number"
            && this.expectedAmountCent !== this.amountCent;
    };

    public static create({
                             historyId,
                             createTime,
                             type,
                             event,
                             amountCent,
                             totalCent,
                             transferredTo,
                             transferredFrom,
                             expectedAmountCent,
                             orderStatus,
                             refereeUserCode,
                             referrerUserCode
                         }: {
        historyId: number,
        createTime: number,
        type: WALLET_TYPE,
        event: WALLET_API_RESPONSE_EVENT,
        amountCent: number,
        totalCent?: number,
        transferredTo?: string,
        transferredFrom?: string,
        expectedAmountCent?: number,
        orderStatus?: OrderStatus,
        refereeUserCode?: string,
        referrerUserCode?: string
    }) {
        return new WalletHistoryVo(
            historyId,
            createTime,
            type,
            event,
            amountCent,
            totalCent,
            transferredTo,
            transferredFrom,
            expectedAmountCent,
            orderStatus,
            refereeUserCode,
            referrerUserCode
        );
    }
}
