import React from "react";
import { Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";


const TableHead = styled.div.attrs(() => ({
    className: "d-flex justify-content-between fz-sf-regular-body-1 bg-gray-2 text-text-secondary-1 tw-border-system-labelLightQuaternary"
}))`
    padding: .7rem 0;
    border-width: 1px 0 1px 0;
    border-style: solid;

`;
const Th = styled(Col).attrs(() => ({
    className: "border-0 ps-4"
}))`
    font: inherit;
`;

const ThRight = styled(Th).attrs(() => ({
    className: "text-end ps-0 pe-4"
}))``;


export const WinningHead = () => {
    return <TableHead>
        <Th>
            <FormattedMessage id="contest_detail_page_ranking_label_ranking" />
        </Th>
        <ThRight>
            <FormattedMessage id="contest_detail_page_ranking_label_prize" />
        </ThRight>
    </TableHead>;
};
