import { INJURY_PLAYING_CHANCE } from "data/vo/player/profile/BasketballPlayerInfoProfileVo";
import { isEmpty } from "lodash";

export const checkPlayerIsInjured = (injuryPlayingChance: INJURY_PLAYING_CHANCE) => (
    injuryPlayingChance !== INJURY_PLAYING_CHANCE.UNKNOWN
);
export const generatePlayerName = (person: { firstName: string; middleName: string; lastName: string; }) => {
    const result: string[] = [];

    if (!isEmpty(person.firstName)) {
        result.push(
            isEmpty(person.middleName) && isEmpty(person.lastName)
                ? person.firstName
                : `${person.firstName.slice(0, 1)}.`,
        );
    }

    if (!isEmpty(person.middleName)) {
        result.push(person.middleName);
    }

    if (!isEmpty(person.lastName)) {
        result.push(person.lastName);
    }

    return result.join(" ");
};
