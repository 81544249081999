import { ajax } from "apis/ApiUtils";
import { Prize } from "types/contest/ContestDetail";
import { PrizeListVo } from "data/vo/PrizeList/PrizrListVo";

export const fetchPrizeDistribution = async (contestId: number, entries: number) => {
    const response = await ajax<{
        prizeList: Prize[]
    }>({ url: `/contest/${contestId}/prize-distribution-detail?entries=${entries}` });
    return response.data.data.prizeList;
};

export class PrizeDistributionCalculatorRepo {
    async getPrizeDistribution(contestId: number, entries: number): Promise<PrizeListVo[]> {
        const prizes = await fetchPrizeDistribution(contestId, entries);
        return prizes.map(prize => new PrizeListVo(prize));
    }
}
