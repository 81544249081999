import React from "react";
import {
    ContestTypeSeparatorContainer,
    ContestTypeSeparatorIcon,
    ContestTypeSeparatorSubtitle,
    ContestTypeSeparatorTitle
} from "./ContestTypeSeparatorStyle";

export interface ContestTypeTitleProps {
    iconImgSrc: string
    title: string
    subtitle: string | React.ReactNode
}

const ContestTypeSeparator: React.FC<ContestTypeTitleProps> = ({
                                                                   iconImgSrc,
                                                                   title,
                                                                   subtitle
                                                               }) => {
    return (
        <ContestTypeSeparatorContainer data-testid={"contest-type-separator"}>
            <ContestTypeSeparatorIcon src={iconImgSrc} alt={"contest-icon"} />
            <div>
                <ContestTypeSeparatorTitle>{title}</ContestTypeSeparatorTitle>
                <ContestTypeSeparatorSubtitle>{subtitle}</ContestTypeSeparatorSubtitle>
            </div>
        </ContestTypeSeparatorContainer>
    );
};

export default ContestTypeSeparator;
