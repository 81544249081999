import useDevice from "hooks/useDevice/useDevice";
import { findIndex } from "lodash";
import React from "react";
import { SwiperSlide } from "swiper/react";
import Carousel from "../../../components/carousel";
import ResultContestCardSkeleton from "../../../components/skeleton/ContestCardSkeleton/ResultContestCardSkeleton";
import { ContestDetailVo } from "../../../data/vo/contest/ContestDetailVo";
import { ResultContestVO } from "../../../types/contest/Contest";
import { ContestDetailCardWithoutTagList } from "./ContestDetailCard";
import { ContestDetailWrapper } from "./ContestStyle";

export const ResultContestCarousel: React.FC<{
    onSwiperChange: (swiper: {
        realIndex: number
    }) => void;
    contests: ResultContestVO[];
    currentContest?: ContestDetailVo;
}> = ({ onSwiperChange, contests, currentContest }) => {

    const { isDesktop } = useDevice();

    return <>
        <ContestDetailWrapper>
            <Carousel className="custom-swiper"
                      slidesPerView={1}
                      centeredSlides={true}
                      navigation={isDesktop}
                      pagination={!isDesktop && { clickable: true }}
                      onRealIndexChange={onSwiperChange}
                      initialSlide={findIndex(contests, { "code": currentContest?.code })}>
                {
                    contests.map(contest => {
                        return <SwiperSlide key={contest.code} className="rounded-0 px-4">
                            {
                                currentContest ? <ContestDetailCardWithoutTagList
                                    contest={currentContest}
                                /> : <ResultContestCardSkeleton />
                            }
                        </SwiperSlide>;
                    })
                }
            </Carousel>
        </ContestDetailWrapper>

    </>;
};
