import { formatMessageTime } from "domain/chat/ChatRoomHelper";
import {
    MessageWrapper,
    MyMessageText,
    MyMessageTextContainer,
    MyMessageTextWrapper,
    MyMessageTime
} from "domain/chat/ChatRoomStyle";
import DOMPurify from "dompurify";
import React from "react";

export const MyMessages = ({ message, handleClickMessage }) => {

    return <MessageWrapper>
        <MyMessageTextWrapper>
            {
                message.htmls.map((html, idx) => {
                    return <MyMessageTextContainer key={`${message.id}-${idx}`}>
                        <MyMessageText dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(html, { USE_PROFILES: { html: true } })
                        }} onClick={handleClickMessage} />
                    </MyMessageTextContainer>;
                })
            }
            <MyMessageTime>{formatMessageTime(message.created_at)}</MyMessageTime>
        </MyMessageTextWrapper>
    </MessageWrapper>;
};
