import styled from "styled-components";

export const NotificationWrapper = styled.div.attrs({
    className: "text-center tw-bg-system-red300"
})`
  padding: 1.6rem;
`;
export const NotificationText = styled.div.attrs({
    className: "fz-sf-regular-footnote tw-text-system-red"
})`
`;