import { ApiError } from "apis/Api";
import { useLoadingToggle } from "components/loading/Loading";
import { ContestRepository } from "data/repository/contest/ContestRepository";
import { TOAST_TYPE } from "designToken/alert/components/AlertHelper";
import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { isExceedShareContestRateLimit } from "helpers/ErrorHelper";
import useFantasyCommand from "hooks/useFantasyCommand";
import useHandleApiError, { ERROR_HANDLER_FLOW } from "hooks/useHandleApiError";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";

import { addToast } from "store/actions/AlertActions";

interface ToChatRoomProps {
    contestId: number;
    onShared: () => void;
}

export const ToChatRoom: React.FC<ToChatRoomProps> = ({ contestId, onShared }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const handleApiError = useHandleApiError();
    const toggleLoading = useLoadingToggle();

    const contestRepository = new ContestRepository();

    const shareContest = useFantasyCommand<{ contestId: number }>(contestRepository.shareContest, {
        errorHandle: false,
    });

    const handleSubmit = async () => {
        toggleLoading(true);
        try {
            await shareContest.mutateAsync({ contestId });
            dispatch(addToast({
                type: TOAST_TYPE.SUCCESS,
                content: <div className="tw-flex tw-justify-between tw-items-center tw-flex-1 tw-me-8">
                    <div className="tw-text-body-2 tw-text-black">
                        <FormattedMessage id="share_contest_popup_share_success_message" />
                    </div>
                    <NavLink to={ROUTER_PATH_CONFIG.chatRoom}
                             className="tw-text-body-2 tw-font-bold tw-text-success-600 hover:tw-underline tw-underline-offset-4 hover:tw-text-success-600">
                        <FormattedMessage id="label_view" />
                    </NavLink>
                </div>,
                closeAlertMillisecond: 3000
            }));
        } catch (e) {
            handleApiError(e as ApiError, (error) => {
                if (isExceedShareContestRateLimit(error)) {
                    dispatch(addToast({
                        type: TOAST_TYPE.DANGER,
                        content: intl.formatMessage({ id: "share_contest_popup_share_exceed_rate_limit_message" })
                    }));

                    return ERROR_HANDLER_FLOW.STOP;
                }

                return ERROR_HANDLER_FLOW.COUNTINUE;
            });
        } finally {
            toggleLoading(false);
            onShared();
        }
    };

    return <div className="tw-self-stretch tw-flex tw-flex-col tw-gap-3 -tw-mx-2 tw-mt-5">
        <Button
            variant={NEW_BUTTON_VARIANTS.primaryDark}
            size={BUTTON_SIZE.md}
            pattern={BUTTON_PATTERN.rounded}
            onClick={handleSubmit}
            disabled={shareContest.isLoading}
        >
            <FormattedMessage id="share_contest_popup_label_share" />
        </Button>
    </div>;
};
