import { cn } from "helpers/cn";
import React from "react";
import { howToPlayBgBasketballStand, howToPlayStep2 } from "helpers/IconHelper";
import { FormattedMessage } from "react-intl";
import StepWrapper from "domain/howToPlay/StepWrapper";

export const Step2 = () => {
    return <div className="tw-relative tw-w-full tw-flex">
        <StepBackground />
        <StepWrapper
            number={2}
            title={<FormattedMessage id="how_to_play_step_2_title" />}
            subtitle={<FormattedMessage id="how_to_play_step_2_subtitle" />}
        >
            <img
                src={howToPlayStep2}
                alt="howToPlayStep2"
            />
        </StepWrapper>
    </div>;
};

const StepBackground = () => {

    return <img
        alt=""
        src={howToPlayBgBasketballStand}
        className={cn(
            "tw-absolute -tw-left-[2.1rem] -tw-bottom-40",
        )} />;
};
