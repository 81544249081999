import { isMatchJoinable } from "helpers/match/MatchHelper";
import React from "react";
import { FormattedMessage } from "react-intl";
import { LineupsOutWrapper } from "../../../components/matchCards/MatchCardStyle";
import { MATCH_STATUS_FROM_API } from "../../../types/match/Match";

interface LineupsOutProps {
    matchStatus: MATCH_STATUS_FROM_API;
    isLineupsOut: boolean;
}

export const LineupsOut: React.FC<LineupsOutProps> = ({ matchStatus, isLineupsOut }: LineupsOutProps) => {
    if (isMatchJoinable(matchStatus) && isLineupsOut) {
        return <LineupsOutWrapper>
            <FormattedMessage id="match_card_label_lineups_out" />
        </LineupsOutWrapper>;
    }
    return null;
};
