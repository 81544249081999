import { Contest } from "apis/match/type";
import PageViewRecorder from "components/ga/PageViewRecorder";
import ContestCard from "domain/match/pages/upcoming/contest/card/ContestCardFactory";
import ContestTypeSeparator from "domain/match/pages/upcoming/contest/public/ContestTypeSeparator";
import ExplanationModal from "domain/match/pages/upcoming/contest/public/ExplanationModal";
import getContestTypeTitleProps from "domain/match/pages/upcoming/contest/public/getContestTypeTitleProps";
import getPublicContestGroupType from "domain/match/pages/upcoming/contest/public/getPublicContestGroupType";
import {
    B2BCreateTeamButtonWrapper,
    WarningAndExplanation,
    WarningText
} from "domain/match/pages/upcoming/contest/public/PublicContestStyle";
import { isMatchJoinable } from "helpers/match/MatchHelper";
import { contestEntryFeeTypeComparator, contestTypeComparator, entryFeeComparator } from "helpers/SortingHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import { orderBy } from "lodash";
import React, { useState } from "react";
import { MdOutlineInfo } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import { getContestDetailPageUrl } from "router/RouterUrls";
import { MatchVO } from "types/match/Match";
import { MyTeamVO } from "types/team/MyTeam";


const ExpandButton = ({ toCreateTeam }) => {
    return (
        <B2BCreateTeamButtonWrapper onClick={toCreateTeam}>
            <FormattedMessage id="label_create_team" />
        </B2BCreateTeamButtonWrapper>
    );
};

interface PublicContestProps {
    contests: Contest[],
    match: MatchVO,
    toCreateTeam: (team?: MyTeamVO) => void,
    onJoinButtonClick: (e: MouseEvent, contest: Contest) => void
}

function shouldShowTitle(contests: Contest[], index: number) {
    if (index === 0) return true;

    const prevContestGroupType = getPublicContestGroupType(contests[index - 1]);
    const currentContestGroupType = getPublicContestGroupType(contests[index]);

    return prevContestGroupType !== currentContestGroupType;
}

export function sortPublicContests<_Contest extends Pick<Contest, 'type' | 'entryFeeCent' | 'entryFeeType'>>(contests: _Contest[]) {
    return orderBy(contests, [contestTypeComparator, contestEntryFeeTypeComparator, entryFeeComparator]);
}

const PublicContest: React.FC<PublicContestProps> = ({
                                                         contests,
                                                         match,
                                                         toCreateTeam,
                                                         onJoinButtonClick
                                                     }) => {

    const history = useFantasyHistory();
    const [showExplanationModal, setShowExplanationModal] = useState(false);
    const intl = useIntl();
    const { matchStatus } = match;

    const onContestClick = (contest: Contest) => (e) => {
        const { code } = contest;
        history.push(getContestDetailPageUrl(code), {
            from: history.location.pathname,
            navbarBackGoTo: history.location?.state?.navbarBackGoTo,
        });
    };

    const sortedContests = sortPublicContests(contests);
    return <>
        <PageViewRecorder title="Match Detail - Contests" />
        <>
            <WarningAndExplanation>
                <WarningText><FormattedMessage id="match_detail_page_label_prize_is_ideal" /></WarningText>
                <MdOutlineInfo className="clickable text-text-tertiary-1"
                               size="2rem"
                               onClick={() => setShowExplanationModal(true)} />
            </WarningAndExplanation>
            <div data-testid="public-contest"></div>
            <div>
                <div className="d-flex flex-column gap-3">
                    {
                        contests.length > 0
                            ? sortedContests
                                .map((contest, index) => {
                                    return (
                                        <div key={index} className="px-4">
                                            {shouldShowTitle(sortedContests, index) &&
                                                <ContestTypeSeparator {...getContestTypeTitleProps(contest, intl)} />}
                                            <ContestCard
                                                contest={contest}
                                                matchStatus={matchStatus}
                                                onContestClick={onContestClick(contest)}
                                                openContestPanel={onJoinButtonClick} />

                                        </div>
                                    );
                                })
                            : null
                    }
                </div>
            </div>
            {
                isMatchJoinable(matchStatus) &&
                <ExpandButton toCreateTeam={() => toCreateTeam()} />
            }
        </>

        <ExplanationModal show={showExplanationModal}
                          onClose={() => setShowExplanationModal(false)} />
    </>;
};

export default PublicContest;
