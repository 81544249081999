import styled from "styled-components";
import { APP_MAX_WIDTH } from "../Constants";

export const FixedButton = styled.button.attrs(() => ({
    className: "text-center rounded-pill position-fixed fz-sf-regular-title-2"
}))`
  bottom: 12px;
  width: 87.2%;
  left: 50%;
  transform: translateX(-50%);
  max-width: calc(${APP_MAX_WIDTH} * .872);
  @media (min-width: ${APP_MAX_WIDTH}) {
    left: calc(${APP_MAX_WIDTH} * .5);
  }
`;

export const PrimaryFixedButton = styled(FixedButton).attrs(() => ({
    className: "cl-primary-solid-button"
}))`
  bottom: 1.6rem;
`;

export const PrimaryButton = styled.button.attrs(() => ({
    className: "text-center rounded-pill cl-primary-solid-button fz-sf-regular-title-2 d-block mx-auto"
}))``;

export const OutlineButton = styled.button.attrs(() => ({
    className: "text-center rounded-pill cl-outline-button fz-sf-regular-title-2 d-block mx-auto"
}))``;

export const PrimarySolidButton = styled(PrimaryButton).attrs(() => ({
    className: "w-100"
}))``;