import { ORDER_STATUS, TripleAPaymentCallbackUrls } from "domain/coin/AddCoinHelper";
import {
    fetchOrderStatusUrl,
    fetchPaymentMethodUrl,
    firstTimePurchase,
    initializeCryptocurrencyPaymentUrl,
    initializeUpayPaymentUrl,
} from "../ApiUrlConfig";
import { ajax } from "../ApiUtils";

export const fetchTripleAPaymentMethod = () => {
    return new Promise<string[]>((resolve, reject) => {
        ajax<string[]>({ url: fetchPaymentMethodUrl })
            .then(({ data }) => resolve(data.data))
            .catch((error) => reject(error));
    });
};

export interface initializeCryptocurrencyPaymentRequest {
    depositProductId: number;
    cryptocurrency: string;
    query: string;
}

export const initializeCryptocurrencyPayment = ({
                                                    depositProductId,
                                                    cryptocurrency,
                                                    query,
                                                }: initializeCryptocurrencyPaymentRequest) => {
    return new Promise<string>((resolve, reject) => {
        ajax<string>({
            method: "POST",
            url: initializeCryptocurrencyPaymentUrl,
            data: {
                depositProductId,
                cryptocurrency,
                successUrl: `${TripleAPaymentCallbackUrls.successUrl}?${query}`,
                cancelUrl: `${TripleAPaymentCallbackUrls.cancelUrl}?${query}`,
            },
        })
            .then((res) => resolve(res.data.data))
            .catch((error) => reject(error));
    });
};

export interface initializePaymentRequest {
    coinProductId: number;
    mobile: string;
}

export const initializeUpayPayment = async (request: initializePaymentRequest) => {
    const { coinProductId, mobile } = request;

    const response = await ajax<{ qrCode: string }>({
        method: "POST",
        url: initializeUpayPaymentUrl,
        data: {
            coinProductId,
            mobile,
            callbackUrl: "https://google.com/",
        },
    });

    return response.data.data.qrCode;
};

export const fetchTripleAOrderStatus = async (paymentReference: string): Promise<ORDER_STATUS> => {
    const { data: { data: { status } } } = await ajax<{ status: ORDER_STATUS }>({
            url: fetchOrderStatusUrl(paymentReference)
        }
    );

    return status;
};

export interface FirstTimePurchaseDto {
    eligible: boolean;
    minCoinCent: number;
    minCoin?: number;
    rubyPercentage: number;
}

export const fetchFirstTimePurchase = async (): Promise<FirstTimePurchaseDto> => {
    const { data } = await ajax<FirstTimePurchaseDto>({ url: firstTimePurchase });
    return data.data;
};
