import React from "react";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Button from "../../designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, NEW_BUTTON_VARIANTS } from "../../designToken/button/types";
import { DIALOG_SIZE } from "../../designToken/dialog/Dialog";
import { cn } from "../../helpers/cn";
import { Icon } from "../../helpers/IconHelper";
import IconButton, { IconButtonFor } from "../buttons/IconButton";
import { Dialog, DialogContent } from "../ui/dialog";


interface ScrollingBodyPopupProps {
    open: boolean
    onOpenChange: (open: boolean) => void
    title?: string | React.ReactNode,
    body: React.ReactNode
    footer: React.ReactNode
    dialogSize?: DIALOG_SIZE,
    testId?: string
}

interface ScrollingPopupTitleProps {
    title: string,
    children?: React.ReactNode,
    onClose?: () => void,
    className?: string
}

const ScrollingPopupHeader: React.FC<ScrollingPopupTitleProps> = ({ title, children, onClose, className }) => {
    return (
        <div className={cn("tw-pb-6 tw-pt-4 tw-px-6 tw-flex-shrink-0 tw-flex tw-flex-col tw-gap-2", className)}>
            {onClose && (
                <div className="tw-text-right">
                    <IconButton
                        for={IconButtonFor.ON_LIGHT}
                        background={false}
                        icon={Icon.Close}
                        onClick={onClose}
                    />
                </div>
            )}

            <div className="tw-flex tw-flex-col tw-items-center tw-gap-3">
                <div className="tw-text-h6 tw-font-bold tw-text-grey-1000">
                    {title}
                </div>

                {children && children}
            </div>
        </div>
    );
};

const ScrollingPopupBody = React.forwardRef<HTMLDivElement, { children: React.ReactNode, className?: string }>(
    ({ children, className }, ref) => {
        return (
            <div
                ref={ref}
                className={cn("tw-flex-1 tw-relative tw-overflow-scroll tw-text-body-1 tw-text-grey-1000 tw-px-11 tw-whitespace-pre-line", className)}>
                {children}
            </div>
        );
    }
);

const ScrollingPopupFooter: React.FC<{ children: React.ReactNode, className?: string }> = ({ children, className }) => {
    return (
        <div className={cn("tw-pt-3 tw-pb-8 tw-px-6 tw-flex-shrink-0", className)}>
            {children}
        </div>
    );
};

const ScrollingPopupPrimaryButton: React.FC<{
    text?: string,
    onClick: () => void
}> = ({
          text,
          onClick,
      }) => {
    return (
        <Button
            variant={NEW_BUTTON_VARIANTS.primaryDark}
            pattern={BUTTON_PATTERN.rounded}
            size={BUTTON_SIZE.lg}
            className="w-100"
            onClick={onClick}
        >
            {text || <FormattedMessage id="button_got_it" />}
        </Button>
    );
};

const FantasyScrollingPopup: React.FC<ScrollingBodyPopupProps> = ({
                                                                      open,
                                                                      onOpenChange,
                                                                      title,
                                                                      body,
                                                                      footer,
                                                                      dialogSize = DIALOG_SIZE.LG,
                                                                      testId,
                                                                  }) => {
    const dialogSizeMap = {
        [DIALOG_SIZE.LG]: "tw-px-6",
        [DIALOG_SIZE.XL]: "tw-px-4"
    };

    const handleHide = () => {
        onOpenChange(false);
    };

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent
                className={cn("tw-max-h-[calc(100vh-80px)]", dialogSizeMap[dialogSize])}
                onHide={handleHide}
                data-testid={testId}
            >
                <Card className="border-0 tw-bg-grey-50 tw-max-h-[calc(100vh-8rem)]">
                    <Card.Body className="tw-flex tw-flex-col tw-p-0 tw-min-h-0">
                        {title}
                        {body}
                        {footer}
                    </Card.Body>
                </Card>
            </DialogContent>
        </Dialog>
    );
};

export default FantasyScrollingPopup;

export {
    ScrollingPopupHeader,
    ScrollingPopupBody,
    ScrollingPopupFooter,
    ScrollingPopupPrimaryButton
};