import React from "react";
import { cn } from "helpers/cn";

interface CardSquaredProps {
    icon: string;
    name: string;
}

export const CardSquared: React.FC<CardSquaredProps> = ({ icon, name }) => {
    return <div className={cn(
        "tw-flex tw-items-center",
        "tw-font-bold tw-text-[14px] tw-leading-[25.2px] tw-text-[#001042]",
    )}>
        <img
            className={cn(
                "!tw-w-[24px] !tw-h-[24px] tw-mr-[8px]"
            )}
            src={icon}
            alt={name}
        />
        {name}
    </div>;
};
