import styled from "styled-components";


export const InputGroupWrapper = styled.div.attrs({
    className: "input-group-wrapper"
})``;
export const Label = styled.label.attrs({
    className: "form-label fz-sf-regular-footnote"
})`
  padding-left: .4rem;
  margin-bottom: .4rem;
`;
export const TextField = styled.input.attrs({
    className: "form-control fz-sf-regular-body-1"
})``;
export const TextareaField = styled.textarea.attrs({
    className: "form-control fz-sf-regular-body-1"
})``;

export const FormButton = styled.button.attrs({
    className: "btn",
    type: "button"
})``;
export const ErrorMessage = styled.div.attrs({
    className: "fz-sf-regular-footnote error-message"
})`
  padding-left: .4rem;
`;
export const BottomMessage = styled.div.attrs({
    className: "fz-sf-regular-footnote"
})`
  padding-left: .4rem;
`;

export const ContentWrapper = styled.div.attrs({
    className: "dropdown-item-content-wrapper"
})``;
export const ImgContainer = styled.div.attrs({
    className: "img-container"
})``;

