import IconButton, { IconButtonFor } from "components/buttons/IconButton";
import { TaskRepository } from "data/repository/task/TaskRepository";
import { analyticsEvent, logCustomEvent } from "ga";
import { Icon } from "helpers/IconHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import React from "react";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";

export const TaskButton: React.FC = () => {
    const history = useFantasyHistory();
    const taskRepository = new TaskRepository();
    const status = useFantasyQuery([QUERY_KEY.USER_TASK_PROGRESS], taskRepository.getProgress);

    const handleTaskButtonClick = () => {
        logCustomEvent(analyticsEvent.webClickTaskIcon);
        history.push(ROUTER_PATH_CONFIG.task.index);
    };

    return <IconButton for={IconButtonFor.ON_DARK}
                       background={true}
                       icon={Icon.Calendar}
                       notification={status.data && status.data.incompleteQuantity > 0}
                       onClick={handleTaskButtonClick}
                       data-testid="task-button"
    />;
};
