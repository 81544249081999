import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import reducers, { RootStoreState } from "./reducer";
import { TypedUseSelectorHook, useSelector as useReduxSelector } from "react-redux";

const store = createStore(reducers, applyMiddleware(thunk));

export const useSelector: TypedUseSelectorHook<RootStoreState> = useReduxSelector;

export default store;
