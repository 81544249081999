import { LOCAL_STORAGE_KEYS, StorageHelper } from "helpers/localStorage/types";


const redirectStorageHelper = (storageHelper: StorageHelper) => ({
    storage: storageHelper(LOCAL_STORAGE_KEYS.REDIRECT_PATH),

    get() {
        return this.storage.get();
    },

    put(path: string) {
        this.storage.put(path);
    },

    remove() {
        this.storage.remove();
    }
});

export default redirectStorageHelper;