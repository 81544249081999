import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { APP_MAX_WIDTH } from "../../styles/Constants";

export const HeaderRow = styled(Row).attrs({
    className: "fz-sf-regular-caption-1 justify-content-around tw-bg-system-neutral25 tw-text-system-labelLightSecondary tw-border-system-labelLightQuaternary"
})`
    border-width: 0 0 1px 0;
    border-style: solid;
    padding: 1.6rem;
`;

export const HeaderCol = styled(Col).attrs({})`
    &:first-child {
        text-indent: 4.9rem;

        @media (min-width: ${APP_MAX_WIDTH}) {
            text-indent: 9.9rem;
        }
    }

    &:nth-child(2) {
        text-indent: 1.9rem;

        @media (min-width: ${APP_MAX_WIDTH}) {
            text-indent: 4.9rem;
        }
    }
`;

export const TransferLimitCol = styled(Col).attrs({
    className: "d-flex align-items-center"
})`
    padding: 0;
`;

export const BadgeWrapper = styled.div.attrs({})`
    width: 4.9rem;
    height: 4.9rem;
    margin-right: .8rem;

    @media (min-width: ${APP_MAX_WIDTH}) {
        margin-right: 3rem;
    }
`;
export const RequiredCol = styled(Col).attrs({
    className: "d-flex align-items-center justify-content-end"
})`
    padding: 0;
`;

export const Level = styled.div.attrs({
    className: "d-flex flex-column"
})``;

export const RequiredAp = styled.div.attrs({
    className: "w-50 d-flex align-items-center tw-text-body-2 justify-content-end tw-gap-0.5 "
})`
    * {
        font: inherit;
    }
`;
export const LevelTitle = styled.div.attrs({
    className: "fz-sf-bold-body-2 tw-text-system-labelLightPrimary"
})`
`;
export const TransferLimit = styled.div.attrs({
    className: "fz-sf-regular-subhead tw-text-system-labelLightPrimary"
})`
`;

export const ContentRow = styled(Row).attrs({})`
    padding: 2.3rem 2.1rem;

    @media (min-width: ${APP_MAX_WIDTH}) {
        padding: 2.3rem 4.8rem;
    }
`;
