import { Device } from "hooks/useDevice/type";
import { useEffect, useState } from "react";
import { APP_MAX_WIDTH_VALUE, MOBILE_BREAKPOINT } from "styles/Constants";

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
};

const detectIsDesktop = (windowWidth: number) => windowWidth >= MOBILE_BREAKPOINT;


const getDevice = (dimensions: { width: number; height: number }) => {
    if (dimensions.width >= MOBILE_BREAKPOINT) {
        return Device.DESKTOP;
    }
    if (dimensions.width >= APP_MAX_WIDTH_VALUE) {
        return Device.TABLET;
    }
    return Device.MOBILE;
};

const useDevice = () => {
    const dimensions = getWindowDimensions();
    const [windowDimensions, setWindowDimensions] = useState(dimensions);
    const [isDesktop, setIsDesktop] = useState(detectIsDesktop(dimensions.width));
    const [device, setDevice] = useState(getDevice(dimensions));

    useEffect(() => {
        const handleResize = () => {
            const dimensions = getWindowDimensions();
            setWindowDimensions(dimensions);
            setIsDesktop(detectIsDesktop(dimensions.width));
            setDevice(getDevice(dimensions));
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return {
        deviceWidth: windowDimensions.width,
        deviceHeight: windowDimensions.height,
        isDesktop,
        device
    };
};

export default useDevice;
