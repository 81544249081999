import { Callback } from "Common";
import { SlotCardAnimatedPing } from "domain/slate/components/SlotCardAnimatedPing";
import { PlayerStatsSlotCardVO } from "domain/slate/data/ComponentTypes";
import { useCreateSlateTeamContext } from "domain/slate/providers/CreateNineCatTeamProvider";
import { cn } from "helpers/cn";
import { Icon } from "helpers/IconHelper";
import React from "react";
import { MdQuestionMark } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { MouseEventHandler } from "react/index";

export interface PlayerStatsSlotCardItemProps extends PlayerStatsSlotCardVO {
    onSelectStat: Callback;
    onOpenStatDetailModal: Callback;
}

const PlayerStatSlotCardItem: React.FC<PlayerStatsSlotCardItemProps> = ({
                                                                            focused,
                                                                            nineCatStats,
                                                                            onSelectStat,
                                                                            onOpenStatDetailModal,
                                                                        }) => {
    const handleOpenStatDetailModal: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.stopPropagation();
        onOpenStatDetailModal(e);
    };

    const slateContext = useCreateSlateTeamContext();

    function checkShouldShowPingAnimation() {
        const isStatFilledWithPlayer = !!(slateContext.statToPlayerIdMap[slateContext.focusedStat!]);
        const isPlayerFocused = !!(slateContext.focusedPlayerId);

        const isChoosingPlayerOrStat = isStatFilledWithPlayer || isPlayerFocused;
        return !focused && isChoosingPlayerOrStat;
    }

    const shouldShowPingAnimation = checkShouldShowPingAnimation();

    const handleSelectStat: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation();
        onSelectStat();
    };

    return (
        <div
            data-testid={"player-stat-slot-card"}
            className={cn(
                "tw-relative tw-w-[8.4rem] tw-h-[9.2rem] tw-bg-white tw-rounded-[1.6rem]  tw-pt-[3rem] tw-pl-[1.2rem] tw-shadow-md",
                "tw-border-transparent tw-border-solid tw-border-2",
                "tw-cursor-pointer tw-select-none",
                "tw-flex tw-flex-col tw-justify-end tw-pb-2 ",
                focused ? "tw-border-secondary-600 tw-border-solid tw-border-2" : "",
            )}
            onClick={handleSelectStat}
        >
            <div className={"tw-text-[1.1rem] tw-font-bold tw-whitespace-pre-line"}>
                <FormattedMessage id={nineCatStats.key} />
            </div>
            <div className="tw-flex tw-items-center tw-pt-2">
                <Icon.Plus className={"tw-text-gray-400"} />
                {shouldShowPingAnimation && <SlotCardAnimatedPing />}
                <span className={"tw-text-[1.6rem] tw-font-light tw-leading-4 tw-pl-0.5"}>
                    {nineCatStats.statAbbr}
                </span>
            </div>
            <button
                className={cn("tw-absolute",
                    "tw-flex tw-items-center tw-justify-center",
                    "tw-border-gray-50 tw-border-4 tw-border-solid", "tw-w-[20px] tw-h-[20px] tw-bg-gray-200",
                    "tw-box-content tw-rounded-tr-[1.2rem] tw-rounded-bl-[1.2rem]",
                    "tw-text-white tw-text-lg",
                    "tw-top-[-6px] tw-right-[-6px]",
                    focused ? "tw-bg-secondary-600" : "",
                )}
                onClick={handleOpenStatDetailModal}
            >
                <MdQuestionMark size={"1.5rem"} />
            </button>
        </div>
    );
};

export default PlayerStatSlotCardItem;
