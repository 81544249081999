import CricketContestDetailNavbarBottom from "domain/match/components/startedNavbar/CricketContestDetailNavbarBottom";
import FootballContestDetailNavbarBottom from "domain/match/components/startedNavbar/FootballContestDetailNavbarBottom";
import CricketNavbarMatchWithScore from "domain/match/components/startedNavbar/CricketNavbarMatchWithScore";
import FootballNavbarMatchWithScore from "domain/match/components/startedNavbar/FootballNavbarMatchWithScore";
import BasketballContestDetailNavbarBottom
    from "domain/match/components/startedNavbar/BasketballContestDetailNavbarBottom";
import BasketballNavbarMatchWithScore from "domain/match/components/startedNavbar/BasketballNavbarMatchWithScore";
import CricketQuickSummary from "domain/match/components/quickSummary/CricketQuickSummary";
import React from "react";
import { SPORT } from "types/sports/Sport";
import {
    ContestDetailNavbarBottomProps
} from "domain/match/components/startedNavbar/ContestDetailNavbarBottomPropsInterface";
import { MatchVO } from "types/match/Match";

interface Config {
    myContestDetail: {
        navbarBottom: React.FC<ContestDetailNavbarBottomProps>,
        navbarStartAppend: React.FC<{ match: MatchVO }>,
        quickSummary: React.FC<{ match: MatchVO }>
    }
}

const SPORT_PAGE_CONFIG = {
    cricket: {
        myContestDetail: {
            navbarBottom: CricketContestDetailNavbarBottom,
            navbarStartAppend: CricketNavbarMatchWithScore,
            quickSummary: CricketQuickSummary
        }
    },
    football: {
        myContestDetail: {
            navbarBottom: FootballContestDetailNavbarBottom,
            navbarStartAppend: FootballNavbarMatchWithScore,
            quickSummary: () => null
        }
    },
    basketball: {
        myContestDetail: {
            navbarBottom: BasketballContestDetailNavbarBottom,
            navbarStartAppend: BasketballNavbarMatchWithScore,
            quickSummary: () => null

        }
    }
};

export const getSportPageConfig = (sport: SPORT): Config => {
    return SPORT_PAGE_CONFIG[sport.toLowerCase()];
};
