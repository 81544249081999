import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { APP_MAX_WIDTH } from "styles/Constants";

export const PlayerContainer = styled(Container).attrs({
    className: "choose-player-container px-0"
})`
    padding-top: 21.3rem;
`;

export const PlayerHead = styled(Container).attrs({
    className: "bg-gray-2 header-top-line header-bottom-line border-light-primary"
})`
    padding-top: .8rem;
    padding-bottom: .8rem;
    position: sticky;
    top: 21.1rem;
    z-index: 1;

`;

export const PlayerRow = styled(Row).attrs(() => ({
    className: ""
}))`
    padding: 0 0.8rem;
    flex-wrap: nowrap;

    > * {
        padding-left: 0;
        padding-right: 0;
    }
`;
export const SortButton = styled.button.attrs({
    className: "d-flex align-items-center"
})`
    padding: 0;
    width: fit-content;
    background-color: transparent;
    outline: 0;
    border: 0;
    cursor: pointer;
    gap: .4rem;
`;

export const PlayerHeadCol = styled(Col).attrs(() => ({
    className: ""
}))`
    &:first-child {
        padding-left: 0.8rem;
    }
`;


export const TeamIconWrapper = styled.div.attrs(() => ({}))`
    width: 2.4rem;
    height: 2.4rem;
    cursor: pointer;
    margin: 0 0 0 1.2rem;
`;

export const NavbarBottomContainer = styled.div.attrs(() => ({}))`
    padding-top: 1rem;

`;
export const MatchInfoRow = styled.div.attrs(() => ({
    className: "d-flex justify-content-between"
}))``;

export const NavbarBottomSquadAPlayerCol = styled.div.attrs(() => ({
    className: "d-flex align-items-center me-auto"
}))``;
export const NavbarBottomSquadBPlayerCol = styled.div.attrs(() => ({
    className: "d-flex align-items-center justify-content-end ms-auto"
}))``;
export const SquadLogoImgWrapper = styled.div.attrs(() => ({}))`
    width: 3.2rem;
`;
export const SquadCode = styled.div.attrs(() => ({
    className: "fz-sf-regular-footnote text-center text-text-secondary-1"
}))`
    white-space: nowrap;
`;
export const SquadACode = styled(SquadCode).attrs(() => ({
    className: "text-start"
}))``;
export const SquadBCode = styled(SquadCode).attrs(() => ({
    className: "text-end"
}))``;

export const SquadPlayerLimitWrapper = styled.div.attrs(() => ({
    className: "mx-1 text-center"
}))``;
export const SquadPlayerNumber = styled.span.attrs(() => ({
    className: "fz-sf-bold-title-2 text-text-primary-1"
}))``;
export const SquadPlayerLimit = styled.span.attrs(() => ({
    className: "fz-sf-regular-subtitle text-text-secondary-1"
}))``;

export const TeamInfoRow = styled(Row).attrs(() => ({
    className: "flex justify-content-center"
}))`
    padding: 1.6rem 0;
    gap: .4rem;

`;
export const AllPlayerLimitCol = styled.div.attrs(() => ({}))`
    margin-right: 1.6rem;
`;
export const RestrictionTitle = styled.div.attrs(() => ({
    className: "fz-sf-regular-footnote text-center text-text-secondary-1"
}))`
`;
export const RestrictionNumber = styled.div.attrs(() => ({
    className: "text-center"
}))``;
export const AllPlayerNumber = styled.span.attrs(() => ({
    className: "fz-sf-bold-title-2 text-text-primary-1"
}))`
`;
export const AllPlayerLimit = styled.span.attrs(() => ({
    className: "fz-sf-regular-subhead text-text-secondary-1"
}))`
`;

export const TShirt = styled.div.attrs<{
    $bgImg: string
}>({
    className: "d-flex align-items-center justify-content-center"
})`
    width: 2.2rem;
    padding-bottom: calc(2.2rem / 48 * 44);
    background-image: url(${({ $bgImg }) => $bgImg});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
`;

export const CreditCol = styled.div.attrs(() => ({}))`
    margin-left: 1.6rem;
`;
export const CreditNumber = styled.div.attrs(() => ({
    className: "fz-sf-bold-title-2 text-center text-text-primary-1"
}))``;

export const TeamPreviewWrapper = styled.div.attrs(() => ({
    className: "position-fixed"
}))`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    max-width: ${APP_MAX_WIDTH};
    overflow-y: auto;
    @media (min-width: 1100px) {
        left: ${APP_MAX_WIDTH};
    }
`;
export const TabWrapper = styled.div.attrs(() => ({}))``;

export const AutoChoosePlayerWrapper = styled.div.attrs({
    className: "bg-danger text-center text-white py-2 font-weight-bold position-fixed"
})`
    top: 0;
    right: 0;
    width: 100%;
    max-width: 250px;
    z-index: 99;
    cursor: pointer;
`;
export const PreviewNavbarBottomWrapper = styled.div.attrs({
    className: "d-flex align-items-center header-top-line"
})`
    padding: .8rem 0;
    gap: .8rem;

    &.my-team div:first-child {
        margin-left: auto;
    }

    &.my-team div:last-child {
        margin-right: auto;
    }
`;

export const Anchor = styled.div<{
    $size: number
}>`
    ${({ $size }) => `
    height: ${$size}px;
    margin-top: -${$size}px;
  `}
    visibility: hidden;
    pointer-events: none;
`;

export const RemoveIconWrapper = styled.div.attrs(() => ({
    className: "position-absolute w-auto top-50 end-0 translate-middle-y clickable"
}))`
`;
