import { SportConfigDetail } from "domain/SportConfig";
import { getTeamUnselectedIcon, teamSelectedIcon } from "helpers/IconHelper";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import { countBy } from "lodash";
import { SelectablePlayer } from "types/player/Player";
import { ROLE } from "types/sports/Sport";

export const TEAM_CREDIT_LIMIT = 100.0;
export const TEAM_CREDIT_CENT_LIMIT = 10000;
export const CAPTAIN_WEIGHTED_RATE = 2;
export const VICE_CAPTAIN_WEIGHTED_RATE = 1.5;

export enum PLAYER_TYPE {
    CAPTAIN = "captain",
    VICE_CAPTAIN = "viceCaptain",
    GENERAL = "general"
}

export const isCreditInsufficient = (restCredit, playerCredit) => restCredit < playerCredit;

export const isSelectedPlayersMax = (selectedPlayerCount, maxPlayerCount) => selectedPlayerCount === maxPlayerCount;

const isSelectedSquadPlayersMax = (squadPlayerCount, maxSquadPlayerCount) => squadPlayerCount === maxSquadPlayerCount;

const isRoleCountReachUpperLimit = (currentRole: ROLE, selectedPlayers: Pick<SelectablePlayer, "role">[], roleUpperLimit: number): boolean => {
    const selectedRolePlayersLength = selectedPlayers.filter(player => player.role === currentRole).length;

    return roleUpperLimit === selectedRolePlayersLength;
};


export const countBySquadKey = (selectedPlayers: Pick<SelectablePlayer, "squadKey">[]): {
    [key: string]: number
} => { return countBy(selectedPlayers, "squadKey"); };


export const shouldPlayerNotBeSelected = (player: Pick<SelectablePlayer, "id" | "creditCent" | "squadKey" | "role">, selectedPlayers: SelectablePlayer[], restCreditCent: number, teamConfig: SportConfigDetail["team"]) => {
    if (selectedPlayers.some(({ id }) => id === player.id)) return false;

    const squadKeyToPlayers = countBySquadKey(selectedPlayers);

    return isCreditInsufficient(restCreditCent, player.creditCent)
        || isSelectedSquadPlayersMax(squadKeyToPlayers[player.squadKey], teamConfig.maxPlayerCountEachSquad)
        || isRoleCountReachUpperLimit(player.role, selectedPlayers, teamConfig.roleLimit[player.role].UPPER)
        || isSelectedPlayersMax(selectedPlayers.length, teamConfig.maxPlayerCount);
};

export const calculateRestCreditCent = (maxCredit, players) => maxCredit - sumUpPlayerCreditCent(players);

export const sumUpCredit = players => players.reduce((sum, { creditCent }) => sum + divideByOneHundred(creditCent), 0);
export const sumUpPlayerCreditCent = players => players.reduce((sum, { creditCent }) => sum + creditCent, 0);

export const isTeamPlayerMeetRestriction = (selectedPlayers, maxPlayerCount: number) => selectedPlayers.length === maxPlayerCount;

export const isCreditMeetRestriction = selectedPlayers => sumUpCredit(selectedPlayers) <= TEAM_CREDIT_LIMIT;

export const isAllRoleCountBetweenRestriction = (selectedPlayers, roleLimit: {
    key: { LOWER: number; UPPER: number }
}) => {
    return Object.entries(roleLimit).every(([role, limit]) => {
        const length = selectedPlayers.filter(player => player.role === role).length;

        return length >= limit.LOWER && length <= limit.UPPER;
    });
};

export const isAllSquadCountMeetRestriction = (selectedPlayers: SelectablePlayer[], maxPlayerCountEachSquad: number) => {

    const squadKeyToPlayers = countBySquadKey(selectedPlayers);

    return Object.values(squadKeyToPlayers).every((count) => count <= maxPlayerCountEachSquad);

};
export const isAllValidationPassed = (selectedPlayers: SelectablePlayer[], teamConfig) => {

    const { roleLimit, maxPlayerCount, maxPlayerCountEachSquad } = teamConfig;

    return isAllRoleCountBetweenRestriction(selectedPlayers, roleLimit)
        && isTeamPlayerMeetRestriction(selectedPlayers, maxPlayerCount)
        && isCreditMeetRestriction(selectedPlayers)
        && isAllSquadCountMeetRestriction(selectedPlayers, maxPlayerCountEachSquad);

};

export const getTShirtIcons = (selectedPlayerCount, totalShirts) => {
    const result: string[] = [];
    for (let i = 1; i <= totalShirts; i += 1) {
        if (i <= selectedPlayerCount) {
            result.push(teamSelectedIcon);
        } else {
            result.push(getTeamUnselectedIcon(i));

        }
    }

    return result;
};

export const getBeEditedTeamSpecialPlayer = (beEditedCaptainId, beEditedViceCaptainId, selectedPlayers) => {
    const selectedPlayerIds = selectedPlayers.map(player => player.id);

    return {
        captain: selectedPlayerIds.includes(beEditedCaptainId) ? beEditedCaptainId : "",
        viceCaptain: selectedPlayerIds.includes(beEditedViceCaptainId) ? beEditedViceCaptainId : ""
    };

};
