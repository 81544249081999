import styled from "styled-components";
import { APP_MAX_WIDTH } from "../../styles/Constants";

export const SwipeButtonWrapper = styled.div.attrs({
    className: "mx-auto overflow-hidden position-relative"
})`
  width: 87.2%;
  max-width: calc(${APP_MAX_WIDTH} * .872);
  margin-top: 2rem;
  height: 5.6rem;
  border-radius: 9.9rem;
  user-select: none;
  @media (min-width: ${APP_MAX_WIDTH}) {
    margin-top: 8rem;
    width: calc(${APP_MAX_WIDTH} * .594);
  }
`;
export const SwipeButtonText = styled.div.attrs({
    className: "fz-sf-regular-body-1 text-center"
})`
  line-height: 5.6rem;
`;
export const SwipeBall = styled.div.attrs({
    className: "position-absolute "
})`
  top: 50%;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;
export const Progress = styled.div.attrs({
    className: "position-absolute tw-bg-primary-800"
})`
  top: 0;
  left: 0;
  width: ${({ moveX }) => moveX}%;
  height: 100%;
  border-radius: 9.9rem;
`;