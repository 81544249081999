import { ApiError } from "apis/Api";
import { debugErrorMap } from "firebase/auth";
import { errorWithErrorCodeImg, errorWithoutErrorCodeImg } from "helpers/IconHelper";
import { getDataFromLocalStorage } from "helpers/localStorage/LocalStorageHelper";
import { LOCAL_STORAGE_KEYS } from "helpers/localStorage/types";
import { defaultLocale, I18n } from "locales";


export enum ERROR_CODE {
    UNAUTHORIZED = "UNAUTHORIZED",
    LOCKED = "LOCKED",
    DATA_NOT_FOUND = "DATA_NOT_FOUND",
    MATCH_STARTED = "MATCH_STARTED",
    REFERRER_VALIDATE_ERROR = "REFERRER_VALIDATE_ERROR",
    CAN_NOT_USE_SELF_CODE = "CAN_NOT_USE_SELF_CODE",
    BAD_RESPONSE_FORMAT = "BAD_RESPONSE_FORMAT",
    REDEMPTION_USED = "REDEMPTION_USED",
    REDEMPTION_NO_QUOTA = "REDEMPTION_NO_QUOTA",
    REDEMPTION_INSUFFICIENT_AP_LEVEL = "REDEMPTION_INSUFFICIENT_AP_LEVEL",
    DATA_NOT_AVAILABLE_NOW = "DATA_NOT_AVAILABLE_NOW",
    CONTEST_SPOT_FULL = "CONTEST_SPOT_FULL",
    INTERNAL_ERROR = "INTERNAL_ERROR",
    NICKNAME_DUPLICATED = "NICKNAME_DUPLICATED",
    SUSPICIOUS_EMAIL = "SUSPICIOUS_EMAIL",
    DUPLICATE_TEAMS = "DUPLICATE_TEAMS",
    AP_LEVEL_INSUFFICIENT = "AP_LEVEL_INSUFFICIENT",
    CONTEST_JOIN_TEAM_SIZE_INVALID = "CONTEST_JOIN_TEAM_SIZE_INVALID",
    EXCEED_RATE_LIMIT = "EXCEED_RATE_LIMIT",
    FORBIDDEN_BY_KYC_NOT_COMPLETED = "FORBIDDEN_BY_KYC_NOT_COMPLETED",
    OTP_MOBILE_NUMBER_IN_USE = "OTP_MOBILE_NUMBER_IN_USE",
    OTP_SEND_EXCEED_DAILY_LIMIT = "OTP_SEND_EXCEED_DAILY_LIMIT",
    OTP_SEND_EXCEED_INTERVAL_LIMIT = "OTP_SEND_EXCEED_INTERVAL_LIMIT",
    OTP_CODE_EXPIRED = "OTP_CODE_EXPIRED",
    OTP_VERIFY_FAILED = "OTP_VERIFY_FAILED",
    OTP_VERIFY_EXCEED_ATTEMPT_LIMIT = "OTP_VERIFY_EXCEED_ATTEMPT_LIMIT",
    INVALID_RESPONSIBLE_GAMING_REGISTRATION_MOBILE_NUMBER = "INVALID_RESPONSIBLE_GAMING_REGISTRATION_MOBILE_NUMBER",
    INVALID_RESPONSIBLE_GAMING_REGISTRATION_USER_AGE = "INVALID_RESPONSIBLE_GAMING_REGISTRATION_USER_AGE",
}

export interface GameServerError {
    response: {
        status: number
        data: {
            errorCode: ERROR_CODE
        }
    };
}

// 401
export const isUnauthorized = (errorOrStatusCode: ApiError | string | number): boolean => {
    if (typeof errorOrStatusCode === "object" && errorOrStatusCode !== null) {
        return errorOrStatusCode?.response?.status === 401;
    }

    if (typeof errorOrStatusCode === "string") {
        return String(errorOrStatusCode) === "401";
    }

    return errorOrStatusCode === 401;
};
// 403
const isForbidden = (error: ApiError) => error?.response?.status === 403;
export const isLocked = (error: ApiError) => isForbidden(error) && ERROR_CODE.LOCKED === error.response?.data?.errorCode;
export const isSuspiciousEmail = (error: ApiError) => isForbidden(error) && ERROR_CODE.SUSPICIOUS_EMAIL === error.response?.data?.errorCode;
export const isForbiddenByKYCRestriction = (error: ApiError) => isForbidden(error) && ERROR_CODE.FORBIDDEN_BY_KYC_NOT_COMPLETED === error.response?.data?.errorCode;

export const isCanNotUseSelfCode = (error: ApiError) => error?.response?.data?.errorCode === ERROR_CODE.CAN_NOT_USE_SELF_CODE && error?.response?.status === 400;
export const isReferrerValidateError = (error: ApiError) => error?.response?.data?.errorCode === ERROR_CODE.REFERRER_VALIDATE_ERROR && error?.response?.status === 400;
export const isDataNotFound = (error: ApiError) => error?.response?.data?.errorCode === ERROR_CODE.DATA_NOT_FOUND && error?.response?.status === 400;
export const isMatchStarted = (error: ApiError) => error?.response?.data?.errorCode === ERROR_CODE.MATCH_STARTED && error?.response?.status === 400;
export const isContestFull = (error: ApiError) => (
    error?.response?.data?.errorCode === ERROR_CODE.CONTEST_SPOT_FULL && error?.response?.status === 400
);
export const isExceedShareContestRateLimit = (error: ApiError) => error?.response?.data?.errorCode === ERROR_CODE.EXCEED_RATE_LIMIT && error?.response?.status === 400;
export const isTeamsDuplicated = (error: ApiError) => (
    error?.response?.data?.errorCode === ERROR_CODE.DUPLICATE_TEAMS && error?.response?.status === 400
);
export const isPromotionalUsed = (error: ApiError) => error?.response?.data?.errorCode === ERROR_CODE.REDEMPTION_USED && error?.response?.status === 400;
export const isPromotionalNoQuota = (error: ApiError) => error?.response?.data?.errorCode === ERROR_CODE.REDEMPTION_NO_QUOTA && error?.response?.status === 400;
export const isPromotionalInsufficientApLevel = (error: ApiError) => error?.response?.data?.errorCode === ERROR_CODE.REDEMPTION_INSUFFICIENT_AP_LEVEL && error?.response?.status === 400;
export const isOtpMobileInUse = (error: ApiError) => error?.response?.data?.errorCode === ERROR_CODE.OTP_MOBILE_NUMBER_IN_USE && error?.response?.status === 400;
export const isOtpSendExceedDailyLimit = (error: ApiError) => error?.response?.data?.errorCode === ERROR_CODE.OTP_SEND_EXCEED_DAILY_LIMIT && error?.response?.status === 400;
export const isOtpSendExceedIntervalLimit = (error: ApiError) => error?.response?.data?.errorCode === ERROR_CODE.OTP_SEND_EXCEED_INTERVAL_LIMIT && error?.response?.status === 400;

export const OtpSendValidators = [isOtpMobileInUse, isOtpSendExceedIntervalLimit, isOtpSendExceedDailyLimit];

export const isOtpCodeExpired = (error: ApiError) => error?.response?.data?.errorCode === ERROR_CODE.OTP_CODE_EXPIRED && error?.response?.status === 400;
export const isOtpVerifyFailed = (error: ApiError) => error?.response?.data?.errorCode === ERROR_CODE.OTP_VERIFY_FAILED && error?.response?.status === 400;
export const isOtpVerifyExceedAttemptLimit = (error: ApiError) => error?.response?.data?.errorCode === ERROR_CODE.OTP_VERIFY_EXCEED_ATTEMPT_LIMIT && error?.response?.status === 400;

export const OtpVerifyValidators = [isOtpCodeExpired, isOtpVerifyFailed, isOtpVerifyExceedAttemptLimit];

export const isInvalidResponsibleGamingRegistrationMobileNumber = (error: ApiError) => error?.response?.data?.errorCode === ERROR_CODE.INVALID_RESPONSIBLE_GAMING_REGISTRATION_MOBILE_NUMBER && error?.response?.status === 400;
export const isInvalidResponsibleGamingRegistrationUserAge = (error: ApiError) => error?.response?.data?.errorCode === ERROR_CODE.INVALID_RESPONSIBLE_GAMING_REGISTRATION_USER_AGE && error?.response?.status === 400;

// 500
export const isBadResponseFormat = (error: ApiError) => error?.response?.data?.errorCode === ERROR_CODE.BAD_RESPONSE_FORMAT && error?.response?.status === 500;

export type ErrorResponse = {
    response?: Partial<ApiError["response"]>
}

export const createErrorObject = (error?: ErrorResponse) => ({
    message: error?.response?.data?.errorCode?.replace(/_/g, " ") || null,
    status: error?.response?.status || null,
    img: error?.response?.data?.errorCode ? errorWithErrorCodeImg : errorWithoutErrorCodeImg
});

export const isApiAborted = (error) => error.message === "canceled";

export enum FIREBASE_ERROR_CODE {
    EMAIL_ALREADY_IN_USE = "auth/email-already-in-use",
    USER_NOT_FOUND = "auth/user-not-found", //todo: change to auth/invalid-credential
    WRONG_PASSWORD = "auth/wrong-password", //todO: change to auth/invalid-credential
    INVALID_LOGIN_CREDENTIALS = "auth/invalid-login-credentials",
    USER_DISABLED = "auth/user-disabled",
    TOO_MANY_REQUESTS = "auth/too-many-requests",
}

// firebase error handle
export const getFirebaseErrorMessage = (errorCode) => {
    const locale = getDataFromLocalStorage(LOCAL_STORAGE_KEYS.LOCALE) || defaultLocale;

    const errorCodeMap = {
        [FIREBASE_ERROR_CODE.USER_NOT_FOUND]: I18n[locale].login_page_label_account_or_password_invalid,
        [FIREBASE_ERROR_CODE.WRONG_PASSWORD]: I18n[locale].login_page_label_account_or_password_invalid,
        [FIREBASE_ERROR_CODE.INVALID_LOGIN_CREDENTIALS]: I18n[locale].login_page_label_account_or_password_invalid,
        [FIREBASE_ERROR_CODE.USER_DISABLED]: I18n[locale].login_page_label_account_or_password_invalid,
        [FIREBASE_ERROR_CODE.EMAIL_ALREADY_IN_USE]: I18n[locale].login_page_email_not_available,
        [FIREBASE_ERROR_CODE.TOO_MANY_REQUESTS]: I18n[locale].login_page_label_send_email_fail,
    };


    if (errorCode in errorCodeMap) {
        return errorCodeMap[errorCode];
    }

    if (typeof debugErrorMap === "function") {
        return debugErrorMap()[errorCode.replace("auth/", "")] || "Error!";
    }

    return "Error!";
};
