import React from "react";
import { FormattedMessage } from "react-intl";

interface ScoringTableProps {
    title?: string;
    scoring: { [key: string]: string; }
}

const ScoringTable: React.FC<ScoringTableProps> = ({ scoring, title }) => {
    return <div>
        {
            title && <div className="tw-text-subtitle-2 tw-font-medium tw-text-grey-800 tw-py-2.5 tw-mb-2">
                {title}
            </div>
        }
        <div className="tw-px-3 tw-w-full tw-bg-grey-50">
            <div className="tw-flex tw-justify-between tw-text-body-1 tw-font-bold tw-text-grey-700 tw-py-2">
                <FormattedMessage id="scoring_popup_label_type_of_points" tagName="div" />
                <FormattedMessage id="label_points" tagName="div" />
            </div>
        </div>
        <div className="tw-px-3 tw-w-full">
            {
                Object.entries(scoring).map(([type, points]) => (
                    <div
                        key={type}
                        className="tw-flex tw-justify-between tw-text-body-1 tw-text-grey-1000 tw-border-b tw-border-grey-100 tw-py-2 last:tw-border-b-0"
                    >
                        <div className="tw-text-start">{type}</div>
                        <div className="tw-shrink-0">{points}</div>
                    </div>
                ))
            }
        </div>
    </div>;
};

export default ScoringTable;
