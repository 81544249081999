import { DarkTopNavbar, LightTopNavbar } from "components/container/navbar/TopNavbar";
import { NineCatDetailVo } from "domain/slate/data/vo";
import { cn } from "helpers/cn";
import { Icon } from "helpers/IconHelper";
import React from "react";
import NineCatSlateTitle from "./NineCatSlateTitle";

export enum TopNavbarType {
    LIGHT = 'light',
    DARK = 'dark'
}

interface TopNavbarProps {
    type: TopNavbarType;
    onLeaveClick: () => void;
    title?: string | React.ReactNode;
    slate?: NineCatDetailVo;
}

const TopNavbar: React.FC<TopNavbarProps> = ({
                                                 type,
                                                 onLeaveClick,
                                                 title,
                                                 slate
                                             }) => {
    const Component = type === TopNavbarType.LIGHT ? LightTopNavbar : DarkTopNavbar;
    const classNames = type === TopNavbarType.LIGHT ? "tw-text-grey-1000" : "tw-text-white";

    return <Component className={cn("tw-flex", classNames)}>
        <div className="tw-relative">
            <button
                className="tw-cursor-pointer tw-absolute tw-top-[50%] tw-translate-y-[-50%]"
                onClick={onLeaveClick}
                data-testid="leave-slate-button"
            >
                <Icon.ArrowLeft className="tw-text-inherit" size="2.4rem" />
            </button>
        </div>
        <div className="tw-flex tw-items-center tw-justify-center tw-w-full">
            {title}
            <div className="tw-px-2">
                <div className="tw-w-[1rem] tw-h-[2.4rem] tw-bg-primary-600 tw-rounded-full" />
            </div>
            {
                slate && (
                    <NineCatSlateTitle slate={slate} />
                )
            }
        </div>
    </Component>;
};

export default TopNavbar;
