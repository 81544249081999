import { Icon } from "helpers/IconHelper";
import React from "react";

export function EmptySlateCard() {
    return <div
        data-testid="empty-slate-card"
        className="slate-card tw-w-full tw-px-3 tw-h-[13rem] tw-py-2 tw-bg-grey-50 !tw-rounded-[1.6rem] tw-text-left tw-flex tw-flex-col tw-gap-[1rem]"
    >
        <div>
            <div className="tw-text-h6 tw-font-bold tw-text-grey-400">－/－</div>
            <div className="tw-text-caption-1 tw-font-bold tw-text-primary-700">
                Coming Soon
            </div>
        </div>
        <Icon.Flame className="tw-text-grey-400" size="2rem" />
    </div>;
}
