import { Accordion, Card, Table } from "react-bootstrap";
import styled from "styled-components";
import { APP_MAX_WIDTH } from "../../../../styles/Constants";
import { accordionHeaderHeight } from "./Scorecard";

export const ScorecardWrapper = styled.div`
`;

export const StyledAccordion = styled(Accordion).attrs({})`
    > .card {
        overflow: unset;
        border: none;
    }
`;
export const StyledCard = styled(Card)`
    position: ${({ position }) => position || "static"};
    top: ${({ top }) => top}rem;
    z-index: 1;
    border-radius: 0;
`;

export const AccordionCardHeader = styled(Card.Header)`
    position: sticky;
    top: ${({ top }) => top}rem;

    &:first-child {
        border-radius: 0;
    }
`;

export const AccordionHeader = styled.div.attrs({
    className: "d-flex justify-content-between"
})`
    cursor: pointer;
`;
export const SquadCodeWrapper = styled.div.attrs({
    className: "d-flex align-items-center"
})`

`;
export const SquadCode = styled.div.attrs({
    className: "fz-sf-bold-body-1"
})`
    margin-right: .8rem;
`;
export const BattingText = styled.div.attrs({
    className: "fz-sf-regular-caption-2 tw-text-system-red"
})`
`;
export const SquadInfo = styled.div.attrs({
    className: "d-flex align-items-center"
})`

`;
export const SquadScores = styled.div.attrs({
    className: "fz-sf-bold-headline"
})`

`;
export const SquadOvers = styled.div.attrs({
    className: "fz-sf-regular-body-2"
})`
    margin: 0 .8rem;
`;
export const ChevronIcon = styled.div.attrs({})`
    background: url(${({ bgImg }) => bgImg}) center no-repeat;
    background-size: contain;
    width: 1.7rem;
    height: 1.7rem;
`;

export const AccordionCollapse = styled(Accordion.Collapse)`
    &.show {
        table {
            // 方案1 覆蓋

            thead {
                position: sticky;
                top: ${({ top }) => top + accordionHeaderHeight}rem;
            }

            // 方案2 疊加
            // thead:nth-of-type(1) {
            //   position: sticky;
                //   top: ${({ top }) => top + accordionHeaderHeight}rem;
            // }
            //
            // thead:nth-of-type(2) {
            //   position: sticky;
                //   top: ${({ top }) => top + 2 * accordionHeaderHeight}rem;
            // }
            //
            // thead:nth-of-type(3) {
            //   position: sticky;
                //   top: ${({ top }) => top + 3 * accordionHeaderHeight}rem;
            // }
        }
    }
`;

export const ScoreTable = styled(Table).attrs({
    className: "mb-0"
})`
    table-layout: fixed;

    th, td {
        padding: .75rem;
    }

    tbody tr:not(:last-child) td {
        border-style: dotted;
    }

`;
export const ScoreTableHead = styled.thead.attrs({
    className: "fz-sf-regular-subhead tw-bg-system-neutral25"
})`

    th:first-child {
        width: 30%;
        @media (min-width: ${APP_MAX_WIDTH}) {
            width: 50%;
        }
    }
`;
export const ScoreThToRight = styled.th.attrs({
    className: "text-end fz-sf-regular-subhead"
})`
    width: 8%;

    :last-child {
        width: 12%;
    }
`;
export const ScoreTd = styled.td.attrs({
    className: "fz-sf-regular-footnote"
})`
`;
export const ScoreTdToRight = styled(ScoreTd).attrs({
    className: "text-end"
})`

`;
export const ScoreTdBold = styled.td.attrs({
    className: "fz-sf-bold-footnote"
})`
`;
export const ScoreTdBoldToRight = styled(ScoreTdBold).attrs({
    className: "text-end"
})`
`;
export const ScoreTdSpan5 = styled.td.attrs({
    colSpan: "5"
})`

    > div {
        display: flex;
        justify-content: end;
        align-items: center;
    }
`;
export const ScoreThToRightSpan4 = styled(ScoreThToRight).attrs({
    colSpan: "4"
})`
`;
export const ScoreTdBoldToRightSpan4 = styled(ScoreTdBoldToRight).attrs({
    colSpan: "4"
})`
`;
export const PlayerName = styled.div.attrs(({ bold }) => ({
    className: `${bold ? "fz-sf-bold-footnote" : "fz-sf-regular-footnote"} text-truncate w-100`
}))`
`;
export const BatIcon = styled.div.attrs({
    className: "d-inline-block ms-1"
})`
    width: 1.2rem;
    padding-bottom: 1.2rem;
    background-image: url(${({ bgImg }) => bgImg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
`;
export const PlayerHowOut = styled.div.attrs({
    className: "fz-sf-regular-caption-2 ps-1 text-truncate w-100 tw-text-system-labelLightSecondary"
})`
`;
export const SpecialInfoLeft = styled.div.attrs({
    className: "fz-sf-bold-footnote"
})`
`;
export const SpecialInfoRight = styled.div.attrs({
    className: "fz-sf-regular-footnote ps-1 tw-text-system-labelLightSecondary"
})`
`;
