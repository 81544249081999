import { useState } from "react";

export function useSelectTeam(onTeamSelected: () => void) {
    const [selectedTeamIds, setSelectedTeamIds] = useState<number[]>([]);
    const selectTeam = (teamId: number) => {
        setSelectedTeamIds([...selectedTeamIds, teamId]);
        onTeamSelected();
    };

    const unselectTeam = (teamId: number) => {
        setSelectedTeamIds(selectedTeamIds.filter((id) => id !== teamId));
    };

    const handleResetSelectedTeams = () => setSelectedTeamIds([]);

    return { selectedTeamIds, selectTeam, unselectTeam, handleResetSelectedTeams };
}
