import React from "react";
import { useSelector } from "../../../store";
import { BOTTOM_SLOT_TYPE } from "../../../store/reducer/teamPreview/types";
import { TeamPreviewUserProfile } from "../components/TeamPreviewUserProfile";
import { TeamPreviewTeams } from "../components/TeamPreviewTeams";

const TeamPreviewBottomSlotFactory: React.FC = () => {
    const { bottomSlotType } = useSelector(state => state.TeamPreviewState.config);
    if (bottomSlotType === BOTTOM_SLOT_TYPE.TEAMS) {
        return <TeamPreviewTeams />;
    }

    if (bottomSlotType === BOTTOM_SLOT_TYPE.USER_PROFILE) {
        return <TeamPreviewUserProfile />;
    }

    return null;
};

export default TeamPreviewBottomSlotFactory;