import { emailRegexp } from "helpers/RegexHelper";

export const firebaseProvider = Object.freeze({
    google: "google.com",
    password: "password",
});

export const sendEmailFailMessage = "Send email fail. Please log in again.";
export const confirmPasswordNotMatch = "Please make sure your passwords match.";

export const shouldSendCheckEmail = (user) =>
    user &&
    user.providerData[0].providerId === firebaseProvider.password &&
    !user.emailVerified;


// todo: remove email&password validator when login page is done
export const emailValidator = {
    required: {
        value: true,
        message: "Email required.",
    },
    pattern: {
        value: emailRegexp,
        message: "Invalid email format.",
    },
};

export const passwordValidator = {
    required: {
        value: true,
        message: "Password required.",
    },
};

export const validateConfirmPassword = (watch) => {
    return (value) => {
        if (watch("password") !== value) {
            return confirmPasswordNotMatch;
        }
    };
};
