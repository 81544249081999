import { getMatchSnapshotUrl, getMyMatches, getUpcomingMatchesUrl } from "apis/ApiUrlConfig";
import { ajax } from "apis/ApiUtils";
import { UpcomingMatchDto } from "data/dto/match/MatchDto";
import { MatchSnapshotDto } from "data/dto/match/MatchSnapshotDto";
import { MyMatchDto } from "data/dto/match/MyMatchDto";
import { SPORT } from "types/sports/Sport";

export const fetchUpcomingMatches = async (sport: SPORT) => {
    const response = await ajax<UpcomingMatchDto[]>({ url: getUpcomingMatchesUrl(sport) });
    return response.data.data;
};

export const fetchMatchSnapshot = async (sport: SPORT, matchId: string) => {
    const response = await ajax<MatchSnapshotDto>({ url: getMatchSnapshotUrl(sport, matchId) });
    return response.data.data;
};

export const fetchMyMatches = async () => {
    const response = await ajax<MyMatchDto[]>({ url: getMyMatches });
    return response.data.data;
};
