import {
    MatchCard,
    MatchCardFooter,
    MatchCardHeader,
    MatchCardTournament,
    MatchStatus
} from "components/matchCards/MatchCardStyle";
import { StartedMatchCardBody } from "components/matchCards/StartedMatchCardBody";
import MatchInfoSummaryAndMyJoinedTeams from "domain/match/components/myMatches/MatchInfoSummaryAndMyJoinedTeams";
import MatchStatusWithDot from "domain/match/components/status/MatchStatusWithDot";
import { enrichCompletedMatchesWithStartDate, isMatchProcessing } from "helpers/match/MatchHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { getStartedMatchDetailPageUrl } from "router/RouterUrls";
import { MyMatch } from "types/match/MyMatch";
import { MyMatchVo } from "../../../../data/vo/match/MyMatchVo";
import { SPORT } from "../../../../types/sports/Sport";
import MyMatchItemWrapper from "./MyMatchItemWrapper";

interface MatchItemType extends MyMatch {
    getStartDateLabel: Function;
    sport: SPORT;
}

const MatchItem = ({ match }: { match: MatchItemType }) => {
    const history = useFantasyHistory();

    const goToDetail = () => {
        history.push(getStartedMatchDetailPageUrl(match.sport, match.matchId));
    };

    return <Fragment>
        {match.getStartDateLabel()}
        <MatchCard
            className="my-matches-completed-match-card animate__animated animate__fadeIn"
            data-testid={`my-match-${match.matchId}`}
            onClick={goToDetail}
        >

            <MatchCardHeader>
                <MatchCardTournament>{match.tournament}</MatchCardTournament>
            </MatchCardHeader>
            <StartedMatchCardBody match={match}>
                <MatchStatus $matchStatus={match.matchStatus}>
                    <MatchStatusWithDot matchStatus={match.matchStatus}
                                        shouldBlink={isMatchProcessing(match.matchStatus)} />
                </MatchStatus>
            </StartedMatchCardBody>
            <MatchCardFooter>
                <MatchInfoSummaryAndMyJoinedTeams match={match} />
            </MatchCardFooter>
        </MatchCard>
    </Fragment>;
};

interface MyMatchCompletedProps {
    matches: MyMatchVo[];
}

const MyMatchCompleted: React.FC<MyMatchCompletedProps> = ({ matches }) => {
    if (matches.length === 0) {
        return null;
    }

    return (
        <MyMatchItemWrapper
            title={<FormattedMessage id="label_completed_matches" />}
            titleTestId="my-matches-title-completed"
        >
            {
                enrichCompletedMatchesWithStartDate(matches)
                    .map((match) => <MatchItem key={match.matchId} match={match} />)
            }
        </MyMatchItemWrapper>
    );
};

export default MyMatchCompleted;
