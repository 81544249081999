import { KycRepository } from "data/repository/kyc/KycRepository";
import { ERROR_CODE } from "helpers/ErrorHelper";
import useFantasyCommand from "hooks/useFantasyCommand";
import React, { useCallback, useMemo, useState } from "react";
import { ResponsibleGamingRepository } from "data/repository/responsibleGaming/ResponsibleGamingRepository";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";

export enum KYC_OTP_STEP {
    MOBILE_NUMBER,
    OTP_CODE,
}

export interface SendOtpRequest {
    countryCode: string;
    mobileNumber: string;
}

export interface VerifyOtpRequest {
    code: string;
}

interface KycOtpContextState {
    currentStep: KYC_OTP_STEP;
    otpRequest: SendOtpRequest;
    error: {
        isOtpMobileNumberInUse: boolean;
        isOtpSendExceedIntervalLimit: boolean;
        isOtpSendExceedDailyLimit: boolean;
        isOtpCodeExpired: boolean;
        isOtpVerifyFailed: boolean;
        isOtpVerifyExceedAttemptLimit: boolean;
    }
    sendOtp: (data: SendOtpRequest) => Promise<void>;
    verifyOtp: (data: VerifyOtpRequest) => Promise<void>;
    toVerifyOtp: (data: SendOtpRequest) => void;
    toEditMobileNumber: () => void;
    setOtpSendError: (error: ERROR_CODE | null) => void;
    setOtpVerifyError: (error: ERROR_CODE | null) => void;
    responsibleGamingRegistration?: { phoneNumber: string };
}

const KycOtpContext = React.createContext<KycOtpContextState | null>(null);


interface KycOtpProviderProps {
    children: React.ReactNode;
}

const defaultSendOtpRequest = { countryCode: "", mobileNumber: "" };


const responsibleGamingRepository = new ResponsibleGamingRepository();
const kycRepository = new KycRepository();

export const KycOtpProvider: React.FC<KycOtpProviderProps> = ({ children }) => {
    const {
        data: responsibleGamingRegistration
    } = useFantasyQuery([QUERY_KEY.RESPONSIBLE_GAMING_REGISTRATION], responsibleGamingRepository.getResponsibleGamingRegistration);

    const [currentStep, setCurrentStep] = useState<KYC_OTP_STEP>(KYC_OTP_STEP.MOBILE_NUMBER);
    const [otpRequest, setOtpRequest] = useState<SendOtpRequest>({ ...defaultSendOtpRequest });
    const [otpSendError, setOtpSendError] = useState<ERROR_CODE | null>(null);
    const [otpVerifyError, setOtpVerifyError] = useState<ERROR_CODE | null>(null);

    const error = useMemo(() => ({
        isOtpMobileNumberInUse: otpSendError === ERROR_CODE.OTP_MOBILE_NUMBER_IN_USE,
        isOtpSendExceedIntervalLimit: otpSendError === ERROR_CODE.OTP_SEND_EXCEED_INTERVAL_LIMIT,
        isOtpSendExceedDailyLimit: otpSendError === ERROR_CODE.OTP_SEND_EXCEED_DAILY_LIMIT,
        isOtpCodeExpired: otpVerifyError === ERROR_CODE.OTP_CODE_EXPIRED,
        isOtpVerifyFailed: otpVerifyError === ERROR_CODE.OTP_VERIFY_FAILED,
        isOtpVerifyExceedAttemptLimit: otpVerifyError === ERROR_CODE.OTP_VERIFY_EXCEED_ATTEMPT_LIMIT
    }), [otpSendError, otpVerifyError]);


    const sendOtp = useFantasyCommand<SendOtpRequest>(({
                                                           countryCode,
                                                           mobileNumber
                                                       }) => kycRepository.sendOtp(countryCode, mobileNumber), {
        errorHandle: false
    });

    const verifyOtp = useFantasyCommand<VerifyOtpRequest>(({ code }) => kycRepository.verifyOtp(code), {
        errorHandle: false
    });

    const resetError = () => {
        setOtpSendError(null);
        setOtpVerifyError(null);
    };

    const toVerifyOtp = useCallback((data: SendOtpRequest) => {
        setCurrentStep(KYC_OTP_STEP.OTP_CODE);
        resetError();
        setOtpRequest(data);
    }, []);

    const toEditMobileNumber = useCallback(() => {
        setCurrentStep(KYC_OTP_STEP.MOBILE_NUMBER);
        resetError();
        setOtpRequest({ ...defaultSendOtpRequest });
    }, []);

    const value = useMemo(() => ({
        currentStep,
        otpRequest,
        error,
        sendOtp: sendOtp?.mutateAsync,
        verifyOtp: verifyOtp?.mutateAsync,
        toVerifyOtp,
        toEditMobileNumber,
        setOtpSendError,
        setOtpVerifyError,
        responsibleGamingRegistration
    }), [
        currentStep,
        otpRequest,
        error,
        sendOtp?.mutateAsync,
        verifyOtp?.mutateAsync,
        toVerifyOtp,
        toEditMobileNumber,
        responsibleGamingRegistration
    ]);

    return <KycOtpContext.Provider value={value}>
        {children}
    </KycOtpContext.Provider>;
};

export const useKycOtp = () => {
    const context = React.useContext(KycOtpContext);
    if (context === null) {
        throw new Error("useKycOtp must be used within a KycOtpProvider");
    }
    return context;
};
