import { useLockScroll } from "../../../../hooks/useLockScroll";
import { TutorialStep2 } from "./TutorialStep2";
import { TutorialStep1 } from "./TutorialStep1";
import { TutorialStep3 } from "./TutorialStep3";
import React, { useCallback, useState } from "react";
import { useTaskProvider } from "../../TaskProvider";

const steps = [
    TutorialStep1,
    TutorialStep2,
    TutorialStep3
];

export const Tutorial: React.FC = () => {
    const {
        handleTutorialClose,
        isTutorialShow
    } = useTaskProvider();

    useLockScroll(isTutorialShow);

    const [currentStep, setCurrentStep] = useState(0);

    const handleNextStep = useCallback(() => {
        if (currentStep === (steps.length - 1)) {
            handleTutorialClose();
            return;
        }
        setCurrentStep(currentStep + 1);
    }, [currentStep, handleTutorialClose]);

    const StepComponent = steps[currentStep];

    if (!isTutorialShow) {
        return null;
    }

    return <StepComponent handleNextStep={handleNextStep} />;
};


