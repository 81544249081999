import { useSelector } from "react-redux";
import { RootStoreState } from "store/reducer";

const useFantasyConfig = () => {
    const config = useSelector((store: RootStoreState) => store.FantasyGameConfigState);

    return {
        ...config,
        isDaily: config.name === "daily"
    };
};

export default useFantasyConfig;
