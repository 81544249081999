import { LINEUP } from "../../types/player/Player";
import { MyTeamVO } from "../../types/team/MyTeam";
import React, { useContext, useEffect, useState } from "react";
import Panel from "../../components/panel/Panel";
import NonStarterPanelHeader from "./NotStartingPanelHeader";
import NonStarterPanelBody from "./NonStarterPanelBody";
import { TeamContext } from "./OperateTeam";

interface NotStartingProps {
    beOperatedTeam: MyTeamVO | null
}


const NonStarterPanel: React.FC<NotStartingProps> = ({ beOperatedTeam }) => {

    const { isStartingLineupDecided, allPlayers, setAllPlayers } = useContext(TeamContext);

    const notStartingPlayers = allPlayers
        .filter(player => beOperatedTeam?.players.find(_player => _player.id === player.id))
        .filter(player => player.lineup !== LINEUP.STARTING_LINEUP);

    const isAllSelectedPlayersStarting: boolean = notStartingPlayers.length === 0;

    const [hasNotStarting, setHasNotStarting] = useState(false);

    useEffect(() => {

        setHasNotStarting(() => {
            if (allPlayers.length === 0) {
                return false;
            }

            if (!isStartingLineupDecided) {
                return false;
            }

            if (!beOperatedTeam) {
                return false;
            }

            if (isAllSelectedPlayersStarting) {
                return false;
            }

            return true;
        });

    }, [allPlayers.length, beOperatedTeam, isStartingLineupDecided, isAllSelectedPlayersStarting]);

    const closePanel = () => {
        setHasNotStarting(false);
    };

    return <Panel show={hasNotStarting} onHide={closePanel}
                  Header={<NonStarterPanelHeader onHide={closePanel} notStartingPlayers={notStartingPlayers.length} />}
                  Body={<NonStarterPanelBody onHide={closePanel}
                                             setAllPlayers={setAllPlayers}
                                             notStartingPlayers={notStartingPlayers} />} />;
};

export default NonStarterPanel;
