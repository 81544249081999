import { getMonthsAbbr } from "domain/ap/ActivityPointHelper";
import { ApBadge } from "domain/ap/ActivityPointProgramStyle";
import {
    AccumulationPeriodWrapper,
    ActivityPoint,
    ApAddDepositWrapper,
    ApJoinContestWrapper,
    ApRemainingWrapper,
    CurrentApWrapper,
    CurrentPageWrapper,
    StyledRow,
    Text,
    Title,
    TransferWrapper,
    Value
} from "domain/ap/CurrentStyle";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { MyApInfo } from "types/activityPoint/MyApInfo";
import { RootStoreState } from "../../store/reducer";

const apIcon = "/images/ap/ap_icon.png";

interface CurrentProps {
    myApInfo: MyApInfo;
}

const Current: React.FC<CurrentProps> = ({ myApInfo }) => {
    const { currentCurrencyObject: { toFormattedNumber, toWalletNumber } } = useCurrency();
    const intl = useIntl();
    const {
        FantasyGameConfigState: { featureConfig },
    } = useSelector((store: RootStoreState) => store);

    return (
        <CurrentPageWrapper>

            {
                featureConfig.transfer &&
                <TransferWrapper>
                    <StyledRow>
                        <Title>
                            <>{intl.messages["activity_point_page_current_section_label_transfer_winning_limit"]}</>
                        </Title>
                        <Value>
                            <Text>
                                <>{intl.formatMessage({ id: "activity_point_page_current_section_value_transfer_winning_limit" }, {
                                    number: toWalletNumber(divideByOneHundred(myApInfo.transferWinningLimitCent))
                                })}</>
                            </Text>
                        </Value>
                    </StyledRow>
                    <StyledRow>
                        <Title>
                            <>{intl.messages["activity_point_page_current_section_label_today_transferred"]}</>
                        </Title>
                        <Value>
                            <Text>{toWalletNumber(divideByOneHundred(myApInfo.transferredTodayCent))}</Text>
                        </Value>
                    </StyledRow>
                </TransferWrapper>
            }
            <AccumulationPeriodWrapper>
                <StyledRow>
                    <Title>
                        <>{intl.messages["activity_point_page_current_section_label_accumulation_period"]}</>
                    </Title>
                    <Value>
                        <Text>{getMonthsAbbr(myApInfo.accumulationPeriod, intl.messages)}</Text>
                    </Value>
                </StyledRow>
            </AccumulationPeriodWrapper>

            <CurrentApWrapper>
                <StyledRow>
                    <Title>
                        <>{intl.messages["activity_point_page_current_section_label_current_ap"]}</>
                    </Title>
                    <Value>
                        <ActivityPoint>{toFormattedNumber(myApInfo.currentApDeposit + myApInfo.currentApBet)}</ActivityPoint>
                        <ApBadge $bgImg={apIcon} />
                    </Value>
                </StyledRow>
            </CurrentApWrapper>

            <ApAddDepositWrapper>
                <StyledRow>
                    <Title>
                        <>{intl.messages["activity_point_page_current_section_label_ap_add_deposit"]}</>
                    </Title>
                    <Value>
                        <ActivityPoint>{toFormattedNumber(myApInfo.currentApDeposit)}</ActivityPoint>
                        <ApBadge $bgImg={apIcon} />
                    </Value>
                </StyledRow>
            </ApAddDepositWrapper>

            <ApJoinContestWrapper>
                <StyledRow>
                    <Title>
                        <>{intl.messages["activity_point_page_current_section_label_ap_join_contest"]}</>
                    </Title>
                    <Value>
                        <ActivityPoint>{toFormattedNumber(myApInfo.currentApBet)}</ActivityPoint>
                        <ApBadge $bgImg={apIcon} />
                    </Value>
                </StyledRow>
            </ApJoinContestWrapper>

            <ApRemainingWrapper>
                <StyledRow>
                    <Title>
                        <>{intl.messages["activity_point_page_current_section_label_remaining_ap_next_month"]}</>
                    </Title>
                    <Value>
                        <ActivityPoint>{toFormattedNumber(myApInfo.nextMonthRemainingAp)}</ActivityPoint>
                        <ApBadge $bgImg={apIcon} />
                    </Value>
                </StyledRow>
            </ApRemainingWrapper>
        </CurrentPageWrapper>
    );
};

export default Current;
