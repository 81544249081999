import axios, { AxiosError, AxiosRequestConfig, Method } from "axios";
import { auth } from "domain/auth/AuthConfig";
import { signOut } from "firebase/auth";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import customHistory from "../CustomHistory";

export const HOST = "/gs/v2";

export const logOutAndRedirectToEntry = async (): Promise<void> => {
    await signOut(auth);
    customHistory.replace(ROUTER_PATH_CONFIG.auth.index);
};

interface AjaxRequestConfig extends AxiosRequestConfig {
    headers: Record<string, string>;
}

export const headerConfig = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Cache-Control": "no-cache, no-store, max-age=0"
};

export const setTokenToHeader = async (config: AjaxRequestConfig): Promise<AjaxRequestConfig> => {
    try {
        if (auth.currentUser) {
            const accessToken = await auth.currentUser.getIdToken();
            config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
    } catch (error) {
        await logOutAndRedirectToEntry();
        return config;
    }
};

const onRequestFulfilled = async (config: AxiosRequestConfig): Promise<AjaxRequestConfig> => {
    const _config = await setTokenToHeader({
        ...config,
        headers: {
            Authorization: ""
        }
    });

    return {
        ..._config,
        url: `${HOST}${_config.url}`
    };
};

const onRequestRejected = (error: AxiosError) => Promise.reject(error);

interface AjaxConfig {
    url: string,
    method?: Method,
    data?: object,
    signal?: AbortSignal
    customHeaders?: Record<string, string>
}

export interface AjaxResponse<T = any> {
    data: {
        data: T
    }
}

export const ajax = <T>({
                            url,
                            method = "get",
                            data = {},
                            signal,
                            customHeaders
                        }: AjaxConfig): Promise<AjaxResponse<T>> => {

    const apiBaseUrl = process.env.REACT_APP_BASE_URL || "";
    const instance = axios.create({
        baseURL: apiBaseUrl
    });

    instance.interceptors.request.use(onRequestFulfilled, onRequestRejected);

    return instance.request({
        headers: {
            ...headerConfig,
            ...customHeaders
        },
        url,
        method,
        data,
        signal
    });
};
