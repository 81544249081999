import { PrizeListWrapper, StyledRightTd, StyledTd, StyledTr } from "components/prizelist/PrizeListStyle";
import AmountDisplay from "containers/amount/AmountDisplay";
import { getCurrencyIconSuitByEntryFeeType } from "containers/amount/CurrencyIconSuitHelper";
import { PrizeListVo } from "data/vo/PrizeList/PrizrListVo";
import useCurrency from "hooks/useCurrency/useCurrency";
import { sortBy, uniqueId } from "lodash";
import React from "react";
import { Table } from "react-bootstrap";
import { ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";

export const generateRankString = (from, to) => from === to ? `${from}` : `${from}-${to}`;

export interface PrizeListProps {
    prizeList: PrizeListVo[];
    entryFeeType: ENTRY_FEE_TYPE_FROM_API;
}

const PrizeList: React.FC<PrizeListProps> = ({ prizeList, entryFeeType }) => {
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();

    return <PrizeListWrapper>
        <Table>
            <tbody>
            {
                prizeList.length
                    ? sortBy(prizeList, ["from"]).map(({ from, to, prize }, idx) => {

                        const rankTextStyle = idx === 0 ? "fz-sf-bold-subhead" : "fz-sf-regular-subhead";
                        const prizeTextStyle = idx === 0 ? "fz-sf-bold-body-1" : "fz-sf-regular-body-1";

                        return <StyledTr key={uniqueId("winning-")} idx={idx} data-testid={`prize-${idx + 1}`}>
                            <StyledTd className={rankTextStyle}>
                                #{generateRankString(from, to)}
                            </StyledTd>

                            <StyledRightTd className={prizeTextStyle}>
                                <AmountDisplay
                                    currencyIconSuit={getCurrencyIconSuitByEntryFeeType(entryFeeType)}
                                    className="tw-justify-end"
                                >
                                    {toWalletNumber(prize)}
                                </AmountDisplay>
                            </StyledRightTd>
                        </StyledTr>;
                    })
                    : <tr><StyledTd colSpan={2}>No data</StyledTd></tr>
            }
            </tbody>
        </Table>
    </PrizeListWrapper>;
};

export default PrizeList;
