import React from "react";
import { useSelector } from "react-redux";
import { RootStoreState } from "../../../../store/reducer";
import { isStranger } from "../../FriendHelper";
import { ReachedFollowingLimitWrapper } from "../UserProfileStyle";
import { FormattedMessage } from "react-intl";
import Button from "../../../../designToken/button/Button";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";

import useUserProfileContext from "../useUserProfileContext";
import useFantasyHistory from "hooks/useFantasyHistory";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";

export const ReachedFollowingLimit: React.FC = () => {
    const history = useFantasyHistory();

    const { userInfo: otherUser, isReachedFollowingLimit } = useUserProfileContext();

    const { SettingsState: { following: { maxFollowing } } } = useSelector((store: RootStoreState) => store);

    if (isStranger(otherUser.type) && isReachedFollowingLimit) {
        return (
            <ReachedFollowingLimitWrapper data-testid="reach-following-limit-wrapper">
                <div className="fz-sf-regular-footnote text-sys-danger-1">
                    <FormattedMessage id="user_profile_page_friend_full_tip" values={{ maxFollowing: maxFollowing }} />
                </div>
                <Button dataTestId="reach-limit-unfollow-button"
                        variant={BUTTON_VARIANTS.outlinePrimary}
                        size={BUTTON_SIZE.sm}
                        pattern={BUTTON_PATTERN.rounded}
                        onClick={() => history.push(ROUTER_PATH_CONFIG.userProfile.list)}
                ><FormattedMessage id="label_unfollow" /></Button>
            </ReachedFollowingLimitWrapper>
        );

    }

    return null;

};
