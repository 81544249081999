import { BalanceSection } from "domain/contest/components/ConfirmationDialog/ConfirmationModal/BalanceSection";
import { CoinBalance } from "domain/contest/components/ConfirmationDialog/ConfirmationModal/CoinBalance";
import { TermsAndCondition } from "domain/contest/components/ConfirmationDialog/ConfirmationModal/TermsAndCondition";
import { Icon } from "helpers/IconHelper";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import useFirstTimePurchase from "hooks/useFirstTimePurchase";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { OnJoinRubyContestWithClose } from "store/reducer/selectTeamPanel/type";
import { SelectedTeam } from "types/team/SelectedTeam";
import Button from "../../../../designToken/button/Button";
import { BUTTON_PATTERN, NEW_BUTTON_VARIANTS } from "../../../../designToken/button/types";
import Dialog, { DIALOG_SIZE } from "../../../../designToken/dialog/Dialog";
import { cn } from "../../../../helpers/cn";
import useCurrency from "../../../../hooks/useCurrency/useCurrency";
import { useSelector } from "../../../../store";
import { CurrentBalanceDivider } from "./ConfirmationModal/CurrentBalanceDivider";
import { EntrySectionDivider } from "./ConfirmationModal/EntrySectionDivider";
import { RubyBalance } from "./ConfirmationModal/RubyBalance";
import { ConfirmationDialogContestVO } from "./types";

export interface RubyConfirmationDialogProps {
    selectedTeams: SelectedTeam[];
    onHide: () => void;
    onJoinRubyContest: OnJoinRubyContestWithClose;
    contest: ConfirmationDialogContestVO;
}

export const calcJoinRubyContest = (
    entryFeeCent: number,
    walletRubyCent: number,
    selectedTeamCount: number,
) => {
    const totalEntryFeeCent = entryFeeCent * selectedTeamCount;
    const costRubyCent = Math.min(totalEntryFeeCent, walletRubyCent);
    const balanceRubyCent = walletRubyCent - totalEntryFeeCent;

    return {
        totalEntryFeeCent,
        costRubyCent,
        balanceRubyCent,
    };
};

const RubyButtonFactory = ({ isRubySufficient, onConfirmToJoin, onEarnRuby }: {
    isRubySufficient: boolean,
    onConfirmToJoin: () => void,
    onEarnRuby: () => Promise<void>
}) => <>
    {isRubySufficient ? (
        <Button
            className="mx-auto w-100"
            pattern={BUTTON_PATTERN.rounded}
            onClick={onConfirmToJoin}
            dataTestId="join-ruby-contest-confirm-button"
        >
            <FormattedMessage id="button_confirm_and_join" />
        </Button>
    ) : (
        <Button
            className="mx-auto w-100"
            variant={NEW_BUTTON_VARIANTS.greyLight}
            pattern={BUTTON_PATTERN.rounded}
            onClick={onEarnRuby}
            dataTestId="earn-ruby-button"
        >
            <FormattedMessage id="label_earn_ruby" />
        </Button>
    )}
</>;

const RubyConfirmationDialog: React.FC<RubyConfirmationDialogProps> = ({
                                                                           contest,
                                                                           selectedTeams,
                                                                           onHide,
                                                                           onJoinRubyContest,
                                                                       }) => {
    const history = useFantasyHistory();
    const location = useLocation();
    const { featureConfig } = useSelector(state => state.FantasyGameConfigState);
    const { coinCent, rubyCent } = useSelector(state => state.WalletState);
    const selectedTeamCount = selectedTeams.length;
    const { currentCurrencyObject: { toWalletNumber, toFormattedNumber } } = useCurrency();
    const { totalEntryFeeCent, balanceRubyCent } = calcJoinRubyContest(
        contest.entryFeeCent,
        rubyCent,
        selectedTeamCount,
    );

    const firstTimePurchase = useFirstTimePurchase();
    const handleEarnRubyButtonClick = async () => {
        if (firstTimePurchase.eligible && featureConfig.addCoin) {
            history.push(ROUTER_PATH_CONFIG.deposit.select, {
                navbarBackGoTo: location?.state?.navbarBackGoTo,
                addDepositFlowFinishedGoTo: location.pathname,
                contestCode: contest.code,
                selectedTeamsIds: selectedTeams.map((team) => team.id),
            });
            return;
        }

        history.push(ROUTER_PATH_CONFIG.task.index);
    };

    const handleConfirmationClick = useCallback(() => {
        const teamIds = selectedTeams.map((team) => team.id);
        onJoinRubyContest({
            contestId: contest.id,
            selectedTeamIds: teamIds,
            closeConfirmationCb: onHide,
        });
    }, [onJoinRubyContest, onHide, contest, selectedTeams]);

    return (
        <Dialog show={true} animation={false} size={DIALOG_SIZE.LG}>
            <div className={"tw-p-4 tw-bg-grey-50 tw-rounded-[1.6rem]"}>
                <div className={"tw-flex tw-justify-end"}>
                    <button onClick={onHide}>
                        <Icon.Close />
                    </button>
                </div>
                <div className={"tw-pb-3 tw-text-center tw-text-h6 tw-font-bold"}>
                    <FormattedMessage id="confirmation_dialog_title" />
                </div>
                <div className={"tw-flex tw-flex-col tw-gap-2"}>
                    <div data-testid={'currentBalance'}
                         className={cn(
                             "tw-flex tw-flex-col tw-gap-1",
                             "tw-w-[22.4rem] tw-m-auto",
                             "tw-py-3 tw-px-6",
                             "tw-rounded-lg",
                             "tw-bg-grey-100"
                         )}>
                        <CurrentBalanceDivider>
                            <FormattedMessage id="confirmation_dialog_label_current_balance" />
                        </CurrentBalanceDivider>
                        <CoinBalance value={divideByOneHundred(coinCent)} />
                        <RubyBalance value={divideByOneHundred(rubyCent)} />
                    </div>
                    <div className={
                        cn(
                            "tw-flex tw-flex-col tw-gap-2",
                            "tw-px-5 tw-py-3"
                        )
                    }>
                        <div data-testid="entryFee" className={
                            cn("tw-flex tw-justify-between",
                                "tw-text-subtitle-1"
                            )
                        }>
                            <p>
                                <FormattedMessage id={"fantasy_sport_label_entry_fee"} />
                                &nbsp;(
                                <FormattedMessage id={"label_ruby"} />
                                      )
                            </p>
                            <p className={"tw-text-nowrap"}>
                                {toFormattedNumber(divideByOneHundred(contest.entryFeeCent))} × {selectedTeamCount} =&nbsp;
                                {toWalletNumber(divideByOneHundred(contest.entryFeeCent * selectedTeamCount))}
                            </p>
                        </div>
                        <EntrySectionDivider />
                        <div className={"tw-flex tw-justify-between tw-text-h6 tw-font-bold"}>
                            <p>
                                <FormattedMessage id="label_total" />
                            </p>
                            <p data-testid={"total"}>
                                {toWalletNumber(divideByOneHundred(totalEntryFeeCent))}
                            </p>
                        </div>
                    </div>
                    <BalanceSection
                        balanceDepositCent={coinCent}
                        balanceRubyCent={balanceRubyCent}
                    />
                    <TermsAndCondition />
                </div>
                <div className={"tw-pt-4"}>
                    <RubyButtonFactory isRubySufficient={balanceRubyCent >= 0}
                                       onConfirmToJoin={handleConfirmationClick}
                                       onEarnRuby={handleEarnRubyButtonClick} />
                </div>
            </div>
        </Dialog>
    );
};

export default RubyConfirmationDialog;
