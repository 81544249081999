import { MatchResultPlayerVo } from "data/vo/player/matchResult/MatchResultPlayersVo";
import React from "react";
import PlayerAvatar from "components/image/PlayerAvatar";
import { Performance } from "components/Performance";
import { LineUpStatus } from "domain/result/detail/components/PlayerStats/LineUpStatus";
import { SPORT } from "types/sports/Sport";
import { getSportConfig } from "domain/SportConfig";

interface PlayerInfoProps {
    sport: SPORT;
    player: MatchResultPlayerVo;
    onClick: () => void;
}

export const PlayerInfo: React.FC<PlayerInfoProps> = ({ sport, player, onClick }) => {

    return <div className="tw-flex tw-items-center tw-gap-1">
        <div
            data-testid={`player_avatar_${player.id}`}
            className="tw-w-[5rem] tw-h-[5rem] tw-cursor-pointer"
            onClick={onClick}
        >
            <PlayerAvatar id={player.id} url={player.avatar} />
        </div>

        <LineUpStatus lineup={player.lineup} />

        <div className="tw-w-[110px]">
            <div className="tw-text-body-2 tw-font-bold tw-line-clamp-1">
                {player.name}
            </div>
            <div className="tw-text-caption-2 tw-text-grey-750/80">
                {player.squadCode} - {getRoleAbbr(sport, player)}
            </div>
        </div>


        <div className="tw-w-[5rem]">
            <Performance height={20} data={getData(player.recentPointsCents)} />
        </div>

    </div>;
};

const getData = (recentPointsCents: number[]) => {
    if (recentPointsCents.length === 0) {
        return [{ points: 0 }, { points: 0 }];
    }

    if (recentPointsCents.length === 1) {
        return [{ points: recentPointsCents[0] }, { points: recentPointsCents[0] }];
    }

    return recentPointsCents.map(point => ({ points: point }));
};

const getRoleAbbr = (sport: SPORT, player: MatchResultPlayerVo) => {
    const { roles } = getSportConfig(sport);
    return Object.entries(roles)
        .map(([key, value]) => value)
        .find((role) => role.apiValue === player.role)?.abbr;
};
