import React, { ReactNode, useEffect } from "react";
import { useAppPopups } from "../AppPopupsProvider";
import useUserIdentity from "../../../hooks/useUserIdentity";

const B2CPopupWrapper = ({ children }: { children: ReactNode }) => {
    const popups = useAppPopups();
    const { isB2B } = useUserIdentity();

    useEffect(() => {
        if (isB2B) {
            popups.done();
        }
    }, [isB2B, popups]);

    return <>{children}</>;
};

export default B2CPopupWrapper;