import styled from "styled-components";

export const InfoWrapper = styled.div.attrs({
    className: "d-flex"
})``;
export const GrayScaleWrapper = styled.div.attrs<{ grayscale?: number }>({
    className: "position-relative"
})`
    filter: grayscale(${({ grayscale }) => grayscale || 0});
`;
export const PreviewImage = styled.div<{ previewUrl: string }>`
    width: 10rem;
    padding-bottom: 10rem;
    background-image: url(${({ previewUrl }) => previewUrl});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
`;
export const CountryImage = styled.img.attrs({
    className: "img-fluid"
})`
    position: absolute;
    width: 2.4rem;
    height: 2.4rem;
    top: 0;
    left: 0;
    z-index: 1;
`;

export const RewardDetail = styled.div.attrs(() => ({
    className: "d-flex flex-column justify-content-between"
}))`
    max-height: 10rem;
    padding-left: 1.2rem;
`;
export const RewardInfoTitle = styled.div.attrs({
    className: "fz-sf-bold-subhead tw-text-system-labelLightPrimary"
})`
    margin-bottom: .4rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: normal;
    overflow: hidden;
    text-overflow: ellipsis;
`;
export const Country = styled.div.attrs({
    className: "fz-sf-regular-body-2"
})`
`;
