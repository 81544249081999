import { Col, Row } from "react-bootstrap";
import styled from "styled-components";


export const TeamInfoRow = styled(Row).attrs(() => ({
    className: "py-3 align-items-center"
}))`
    border-bottom: 1px dashed #CCCCCC;
`;
export const TeamRank = styled(Col).attrs({
    className: "col-2 text-end fz-sf-regular-body-2"
})`
  order: 3;
`;
export const TeamInfoCol = styled(Col).attrs({})`
  order: 1;
`;
export const TeamScore = styled(Col).attrs({
    className: "col-2 text-end fz-sf-regular-body-2"
})`
  order: 2;
`;

export const TeamInfo = styled.div.attrs(() => ({
    className: "d-flex align-items-center"
}))``;

export const crown = "crown";
export const TeamPlayerCrownIcon = styled.div.attrs<{ $size: number, $bgImg: string }>({
    className: `${crown} ms-auto`
})`
  width: ${({ $size }) => $size}rem;
  padding-bottom: ${({ $size }) => $size}rem;
  background-image: url(${({ $bgImg }) => $bgImg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  //transform: translateX(-.3rem);
`;
export const TeamInfoDetailWrapper = styled.div.attrs(() => ({
    className: "ms-2"
}))``;

export const NameWrapper = styled.div.attrs(() => ({
    className: "d-flex"
}))``;
export const UserName = styled.span.attrs({
    className: " me-2 text-truncate"
})`
  max-width: 20rem;
`;
export const TeamName = styled.div.attrs(() => ({
    className: "fz-sf-regular-footnote tw-text-secondary-600 tw-border-secondary-600"
}))`
    border-width: 1px;
    border-style: solid;
    padding: .2rem .4rem;
    border-radius: .4rem;
    line-height: 1.56rem;
`;
