import { cn } from "helpers/cn";
import FantasyIcon from "helpers/FantasyIcon";
import React from "react";
import { IconBaseProps } from "react-icons";

export enum IconButtonFor {
    ON_LIGHT = "on-light",
    ON_DARK = "on-dark",
}

interface IconButtonProps {
    for: IconButtonFor;
    background: boolean;
    notification?: boolean;
    onClick?: () => void;
    icon: typeof FantasyIcon | React.FC<IconBaseProps>;
    iconSize?: string;
    disabled?: boolean;
    "data-testid"?: string;
    className?: string;
}

const IconButton: React.FC<IconButtonProps> = ({
    for: iconButtonFor,
    background,
    notification,
    disabled,
    onClick,
    icon,
    iconSize = "1.4rem",
    "data-testid": dataTestId,
    className
}) => {
    const Icon = icon;

    return (
        <button className={cn("tw-icon-button", className, {
            "tw-cursor-pointer": !disabled,
            "on-dark": iconButtonFor === IconButtonFor.ON_DARK,
            "on-light": iconButtonFor === IconButtonFor.ON_LIGHT,
            "background": background,
            "notification": notification
        })}
                onClick={onClick}
                disabled={disabled}
                data-testid={dataTestId}
        >
            <Icon size={iconSize} />
        </button>
    );
};

export default IconButton;
