import { useEffect } from "react";

const useTimeout = (callback: () => void, delay: number = 1000) => {

    useEffect(() => {
        const timeout = setTimeout(callback, delay);

        return () => clearTimeout(timeout);
    }, [callback, delay]);

};

export default useTimeout;
