import Badge from "designToken/badge/Badge";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

export const TeamPreviewWrapper = styled.div.attrs<{ height: string }>({
    className: "w-100 position-relative team-preview-wrapper d-flex flex-column"
})`
  height: ${({ height }) => height};
`;
export const TeamPreviewContent = styled.div.attrs<{ $bgImg: string }>({
    className: "flex-1"
})`
  width: 100%;
  height: 100%;
  background-image: url(${({ $bgImg }) => $bgImg});
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  //pointer-events: none;
  overflow-y: auto;
  //padding: 12rem 1.6rem 4.8rem;
`;

export const TeamPreviewTeamsWrapper = styled.div.attrs({
    className: "d-flex align-items-center bg-default-primary-1 gap-2"
})`
  height: 5.4rem;

  &.my-team div:first-child {
    margin-left: auto;
  }

  &.my-team div:last-child {
    margin-right: auto;
  }
`;

export const TeamPreviewContainer = styled.div.attrs<{ scale: number }>({
    className: "d-flex flex-column justify-content-between"
})`
  height: 100%;
  margin: 0 1.6rem;
  transform: scale(${({ scale }) => scale});
`;
export const TitleRow = styled(Row).attrs(() => ({}))`
  padding-top: 0.8rem;
  margin-bottom: .5rem;
`;
export const TitleCol = styled(Col).attrs(() => ({
    className: "fz-sf-bold-subhead text-center tw-text-system-bgLightPrimary"
}))`
  z-index: 1;
`;
export const PlayerRow = styled(Row).attrs(() => ({
    className: "justify-content-around"
}))``;
export const PlayerCol = styled(Col).attrs(() => ({
    className: "col-3 d-flex flex-column align-items-center"
}))`
  margin-bottom: .6rem;
  cursor: pointer;
  @media (min-height: 844px) {
    margin-bottom: 1rem;
  }
`;

export const PlayerIconWrapper = styled.div.attrs(() => ({
    className: "position-relative clickable"
}))`
  width: 5rem;
`;
export const CaptainIconWrapper = styled.div.attrs(() => ({
    className: "rounded-circle d-flex justify-content-center align-items-center position-absolute tw-text-grey-0 tw-bg-system-primary80"
}))`
  width: 2.4rem;
  height: 2.4rem;
  left: -2rem;
  border: solid 2px #9B9BC1;
`;
export const ViceCaptainIconWrapper = styled(CaptainIconWrapper).attrs(() => ({
    className: "tw-bg-system-primary60"
}))`
`;

export const CreditWrapper = styled.div.attrs(() => ({
    className: "d-flex align-items-center justify-content-center mt-n1"
}))``;

export const PlayerNameWrapper = styled(Badge).attrs({
    className: "position-relative"
})`
  width: clamp(6rem, 100%, 9rem);
  z-index: 1;

  span.text {
    width: 100%;
  }
`;

export const Dot = styled.span.attrs({
    className: "rounded-circle border-light-primary"
})`
  border: 1px solid;
  width: 0.7rem;
  height: 0.7rem;
`;
export const PlayerName = styled.div.attrs(() => ({
    className: "text-center text-truncate"
}))``;

export const PlayerState = styled.div.attrs(() => ({
    className: "fz-sf-regular-footnote tw-text-grey-0"
}))`
  z-index: 1;
`;

export const NoPlayersWrapper = styled.div.attrs({
    className: "position-absolute d-flex flex-column align-items-center bg-opacity-secondary-2 text-dark-primary fz-sf-regular-title-2"
})`
  padding: 3.3rem 3.2rem;
  top: 50%;
  left: 50%;
  width: 29rem;
  transform: translate(-50%, -50%);
  border-radius: 1.6rem;
  gap: 1.6rem;

  * {
    font: inherit;
  }
`;

export const TeamPreviewBottom = styled.div.attrs({
    className: ""
})``;

export const SquadWrapper = styled.div.attrs({
    className: "w-100 d-flex justify-content-between bg-opacity-secondary-2 text-dark-primary"
})`
  padding: 1rem 1.6rem;

  > div {
    gap: .8rem;
  }

  span.dot {
    margin-right: .4rem;
  }
`;
export const TeamPreviewTop = styled.div.attrs(() => ({
    className: "position-absolute w-100 d-flex justify-content-between align-items-center top-0"
}))`
  padding: 1.6rem 1.6rem 0;
  z-index: 1;
`;
export const TeamPreviewIconButton = styled.div.attrs(() => ({
    className: "d-flex justify-content-center align-items-center text-text-primary-2 clickable "
}))`
  --diameter: 3.2rem;
  width: var(--diameter);
  height: var(--diameter);
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
`;

export const TeamPreviewUserProfileWrapper = styled.div.attrs({
    className: "d-flex justify-content-between align-items-center px-4 bg-default-primary-1"
})`
  height: 5.4rem;
`;
