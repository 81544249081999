import React from "react";
import Countdown from "./Countdown";
import { isMatchStartsAtExpired } from "./CountdownHelper";

const UpcomingCountDown = ({
                               matchStartsAt,
                               handleTimeout = () => {
                               }
                           }: { matchStartsAt: number, handleTimeout?: Function }) => {
    return <>
        {
            !isMatchStartsAtExpired(matchStartsAt) &&
            <Countdown finishAt={matchStartsAt} handleTimeout={handleTimeout} />
        }
    </>;
};

export default UpcomingCountDown;