import { PrizeListVo } from "../../../../../data/vo/PrizeList/PrizrListVo";
import { Prize } from "../../../../../types/contest/ContestDetail";

export const isWinnerTakesAll = (prizeList: (PrizeListVo | Prize)[] = []) => {
    const from = (prizeList[0] as PrizeListVo)?.from || (prizeList[0] as Prize)?.fromRank;
    const to = (prizeList[0] as PrizeListVo)?.to || (prizeList[0] as Prize)?.toRank;

    return prizeList.length === 1 && from === 1 && to === 1;
};

export const isPracticeContest = (prizeList: (PrizeListVo | Prize)[] = []) => !prizeList?.find(prize => {
    const _prize = (prize as PrizeListVo).prize || (prize as Prize).prizeCent;
    return _prize > 0;
});
