import React from "react";
import { cn } from "helpers/cn";

export interface StepNumberProps {
    number: number;
}

export const StepNumber: React.FC<StepNumberProps> = ({ number }) => {

    return <div className="tw-relative tw-h-[4.4rem] tw-flex tw-items-center">
        <hr className="tw-border-dashed tw-border-2 tw-border-b2b-primary-300 tw-w-full tw-absolute tw-top-1/2 tw-transform tw--translate-y-1/2" />
        <div className="tw-relative tw-w-[2.616rem] tw-h-[2.616rem] tw-mx-auto">
            <div
                className={cn(
                    "tw-bg-b2b-primary-300 tw-absolute tw-inset-0 tw-transform tw-rotate-45",
                    "tw-rounded-[1rem]"
                )}
            />
            <div
                className={cn(
                    "tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center",
                    "tw-text-grey-0 tw-text-h6 tw-font-bold"
                )}
            >
                {number}
            </div>
        </div>
    </div>;
};
