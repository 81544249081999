import { MyProfileVo } from "data/vo/user/MyProfileVo";

// user
export const UPDATE_USER_IDENTITY: string = "UPDATE_USER_IDENTITY";
export const UPDATE_MY_PROFILE: string = "UPDATE_MY_PROFILE";
export const UPDATE_USER_IS_NEW_USER: string = "UPDATE_USER_IS_NEW_USER";

export const updateUserIsNewUser = (payload: boolean) => ({
    type: UPDATE_USER_IS_NEW_USER,
    payload,
});

type UserIdentity = "B" | "C"
export const updateUserIdentity = (payload: UserIdentity) => ({
    type: UPDATE_USER_IDENTITY,
    payload: {
        isB2B: payload === "B",
        isB2C: payload === "C",
    }
});

export const updateMyProfile = (payload: MyProfileVo | null) => ({
    type: UPDATE_MY_PROFILE,
    payload,
});
