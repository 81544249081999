import React from 'react';
import { Callback } from "Common";
import { FormattedMessage } from "react-intl";
import { goToNextApLevelImg } from "../../../helpers/IconHelper";
import { BUTTON_PATTERN, NEW_BUTTON_VARIANTS } from "../../../designToken/button/types";
import { apLevelConfig } from "../../ap/ActivityPointHelper";
import { AP_LEVEL } from "../../../types/activityPoint/ApLevel";
import { FantasyPopupProps, POPUP_SIZE } from "../../../components/popup/FantasyBasicPopup";

export interface InsufficientApLevelDialogProps {
    minApLevel: AP_LEVEL;
    currentApLevel: AP_LEVEL;
    onUpgradeApLevel: Callback
    onClose: Callback
}

export function makeInsufficientApLevelDialogProps(props: InsufficientApLevelDialogProps): FantasyPopupProps {
    return {
        show: true,
        popupSize: POPUP_SIZE.MD,
        title: (
            <FormattedMessage id={"dialog_insufficient_ap_level_title"} />
        ),
        content: (
            <>
                <div className={"tw-m-auto tw-max-w-[8rem]"}>
                    <img alt="go_to_next_ap_level" src={goToNextApLevelImg} />
                </div>
                <p className={"tw-px-5 tw-text-subtitle-1 tw-whitespace-pre-line"}>
                    <FormattedMessage
                        id={"dialog_insufficient_ap_level_content"}
                        values={{
                            currentApLevel: apLevelConfig[props.currentApLevel].text(),
                            minApLevel: apLevelConfig[props.minApLevel].text()
                        }} />
                </p>
            </>
        ),
        buttonSetting: {
            buttonGap: 3,
            confirmButtonVariant: NEW_BUTTON_VARIANTS.tertiaryLight,
            buttonPattern: BUTTON_PATTERN.pill,
            confirmButtonText:
                <FormattedMessage id={"button_upgrade_ap_level"} />,
            onConfirm: props.onUpgradeApLevel,
            cancelButtonText:
                <FormattedMessage id={"button_join_another"} />,
            cancelButtonVariant: NEW_BUTTON_VARIANTS.primaryDark,
            onCancel: props.onClose
        },
        onHide: props.onClose,
    };
}
