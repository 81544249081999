import {
    circleCloseIcon,
    popupDangerIcon,
    popupPrimaryIcon,
    popupSuccessIcon,
    popupWarningIcon
} from "helpers/IconHelper";
import { ModalBody, ModalFooter } from "react-bootstrap";
import styled from "styled-components";
import { APP_MAX_WIDTH } from "../../../styles/Constants";
import { TOAST_TYPE } from "./AlertHelper";

export const CustomAlertWrapper = styled.div.attrs(() => ({
    className: "alert-wrapper position-fixed h-100"
}))`
  top: 0;
  left: 0;
  padding-top: 3rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  width: 100%;
  max-width: ${APP_MAX_WIDTH};
  z-index: 1051;

  @media (min-width: ${APP_MAX_WIDTH}) {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
`;

const notificationTypeToIcon = {
    [TOAST_TYPE.SUCCESS]: popupSuccessIcon,
    [TOAST_TYPE.DANGER]: popupDangerIcon,
    [TOAST_TYPE.WARNING]: popupWarningIcon,
    [TOAST_TYPE.INFO]: popupPrimaryIcon
};

export const ToastWrapper = styled.div.attrs<{
    $type: TOAST_TYPE
}>
(({ $type }) => ({
    className: `alert alert-${$type} d-flex mx-auto`,
    role: "alert"
}))`
  .alert-icon {
    padding-bottom: 2rem;
    margin-right: .8rem;
    width: 2rem;
    background: ${({ $type }) => `url(${notificationTypeToIcon[$type]})`} center no-repeat;
    background-size: 100%;
  }
`;
export const ToastCloseButton = styled.div.attrs(() => ({
    className: "ms-auto"
}))`
  width: 2.4rem;
  padding-bottom: 2.4rem;
  background: url(${circleCloseIcon}) center no-repeat;
  background-size: 100%;
  cursor: pointer;
  align-self: flex-start;
`;

export const ToastContent = styled.div.attrs(() => ({
    className: "fz-sf-regular-body-2 tw-text-system-labelLightPrimary"
}))`
`;

export const DialogImgWrapper = styled.div`
  padding: 2.4rem 2.4rem 1.2rem;
`;
export const DialogImgContainer = styled.div.attrs<{
    $size?: number
}>({
    className: "mx-auto"
})`

  width: ${({ $size }) => $size || 72}rem;

  img {
    width: 100%;
  }
`;

export const DialogBody = styled(ModalBody)`
  padding: 1.2rem 2.4rem 1.2rem;

  &:last-child {
    padding: 1.2rem 2.4rem 2.4rem;
  }

`;

export const DialogTitle = styled.div.attrs({
    className: "fz-sf-bold-title-3 text-light-primary text-center"
})`
  margin-bottom: 1.2rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const DialogContent = styled.div.attrs({
    className: "fz-sf-regular-body-2 text-light-primary text-center"
})`
`;

export const DialogFooter = styled(ModalFooter).attrs({
    className: "d-flex justify-content-center"
})`
  padding: 1.2rem 2.4rem 2.4rem;
  gap: .8rem;
  border-top: none;
`;
