import { Profile } from "data/vo/player/PlayerInfoVo";
import { PlayerInfoProfileVo } from "data/vo/player/profile/PlayerInfoProfileVo";
import { getSportConfig } from "domain/SportConfig";
import { checkPlayerIsInjured } from "helpers/PlayerHelpers";
import { getSentenceWithComma } from "helpers/string/StringHelper";
import { formatBirthdate, formatToFullYearDate } from "helpers/TimeHelper";
import { SPORT } from "types/sports/Sport";


export enum INJURY_PLAYING_CHANCE {
    PROBABLE = "PROBABLE",
    QUESTIONABLE = "QUESTIONABLE",
    DOUBTFUL = "DOUBTFUL",
    OUT = "OUT",
    UNKNOWN = "UNKNOWN"
}

export const INJURY_PLAYING_CHANCE_ABBR = {
    [INJURY_PLAYING_CHANCE.PROBABLE]: "PRB",
    [INJURY_PLAYING_CHANCE.QUESTIONABLE]: "QUE",
    [INJURY_PLAYING_CHANCE.DOUBTFUL]: "DBT",
    [INJURY_PLAYING_CHANCE.OUT]: "O",
};

type DescriptionProfile = { key?: string, title: string, fn?: string };

export class BasketballPlayerInfoProfileVo extends PlayerInfoProfileVo {
    private readonly _height: number | null;
    private readonly _weight: number | null;
    private readonly _injuryStartTime: number | null;
    private readonly _injuryBodyPart: string | null;
    private readonly _injuryPlayingChance: INJURY_PLAYING_CHANCE;

    constructor(profile: Profile) {
        super(profile);
        this._height = profile.height!;
        this._weight = profile.weight!;
        this._injuryStartTime = profile.injuryStartTime!;
        this._injuryBodyPart = profile.injuryBodyPart!;
        this._injuryPlayingChance = profile.injuryPlayingChance!;
    }

    get isInjured(): boolean {
        return checkPlayerIsInjured(this.injuryPlayingChance);
    }

    public get injuryPlayingChance(): INJURY_PLAYING_CHANCE {
        return this._injuryPlayingChance;
    }

    get injuryBodyPart(): string | null {
        return this._injuryBodyPart;
    }

    get injuryStartTime(): string | null {
        if (this._injuryStartTime === null) return null;

        return `${formatToFullYearDate(this._injuryStartTime)}`;
    }

    get weight(): number | null {
        return this._weight;
    }

    get height(): number | null {
        return this._height;
    }

    get roleAbbr(): string {
        return this.role ? getSportConfig(SPORT.BASKETBALL).team.getRoleAbbr(this.role) : "";
    }

    get injuryPlayingChanceLocaleId() {
        if (this.injuryPlayingChance === INJURY_PLAYING_CHANCE.PROBABLE) {
            return "label_injury_playing_chance_probable";
        }
        if (this.injuryPlayingChance === INJURY_PLAYING_CHANCE.QUESTIONABLE) {
            return "label_injury_playing_chance_questionable";
        }
        if (this.injuryPlayingChance === INJURY_PLAYING_CHANCE.DOUBTFUL) {
            return "label_injury_playing_chance_doubtful";
        }
        if (this.injuryPlayingChance === INJURY_PLAYING_CHANCE.OUT) {
            return "label_injury_playing_chance_out";
        }

        throw new Error(`Unknown injury playing chance: ${this.injuryPlayingChance}`);
    }

    get basicInfo(): string {
        return `Bron: ${formatBirthdate(this.birthdate)}, ${this.height}cm, ${this.weight}kg`;
    }

    getDescription(formattedMessage: (id: string) => string): string {
        const profiles: DescriptionProfile[] = [
            {
                key: "roleAbbr",
                title: formattedMessage("role_title")
            },
            {
                key: "height",
                title: formattedMessage("height_title")
            },
            {
                key: "weight",
                title: formattedMessage("weight_title")
            },
        ];

        return `${getSentenceWithComma(
            ...profiles.map((profile) => (
                this.joinTitleDescription(profile.title, this[profile.key!])
            ))
        )}`;
    }

    public getInjuryInfo(formatMessage: (id: string) => string): string | null {
        return this.joinTitleDescription(formatMessage("label_injury"), this.getInjury(formatMessage));
    }

    private getInjury(formattedMessage: (id: string) => string): string | null {
        if (!this.isInjured) return null;

        return `${this.injuryBodyPart} - ${formattedMessage(this.injuryPlayingChanceLocaleId)} (${this.injuryStartTime} ${formattedMessage("label_start")})`;
    }

    private joinTitleDescription(title: string, description: string | null) {
        if (!description) return null;
        return `${title}: ${description}`;
    }
}
