import { showChatRoomUnreadDot } from "components/container/bottom/BottomNavbarHelper";
import { BOTTOM_NAV_ICON_KEY, BottomNavConfig } from "components/container/bottom/types";
import { cn } from "helpers/cn";
import { Icon } from "helpers/IconHelper";
import useFantasyConfig from "hooks/useFantasyConfig";
import useUserIdentity from "hooks/useUserIdentity";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";

const BottomNavDot = () => {
    return <div
        className={cn("tw-w-[6px] tw-h-[6px] tw-bg-tertiary-300 tw-rounded-full tw-absolute tw-top-0 tw-left-full tw-translate-x-[-50%]")} />;
};


export const useBottomNavbar = () => {
    const location = useLocation();
    const currentPage = location.pathname;
    const { isB2C } = useUserIdentity();
    const { featureConfig } = useFantasyConfig();

    const intl = useIntl();

    const b2cConfig = useMemo((): BottomNavConfig => {

        return ({
            bgColor: "tw-bg-primary-950",
            activeColor: "tw-text-primary-900",
            tabs: [
                {
                    key: BOTTOM_NAV_ICON_KEY.HOME,
                    name: intl.formatMessage({ id: "main_page_bottom_bar_tab_home" }),
                    active: currentPage === ROUTER_PATH_CONFIG.home,
                    icon: Icon.Flame,
                    to: ROUTER_PATH_CONFIG.home,
                    isShow: true
                },
                {
                    key: BOTTOM_NAV_ICON_KEY.MATCHES,
                    name: intl.formatMessage({ id: "main_page_bottom_bar_tab_my_games" }),
                    active: currentPage === ROUTER_PATH_CONFIG.myMatch.index,
                    icon: Icon.MyGames,
                    to: ROUTER_PATH_CONFIG.myMatch.index,
                    isShow: true
                },
                {
                    key: BOTTOM_NAV_ICON_KEY.RESULT,
                    name: intl.formatMessage({ id: "main_page_bottom_bar_tab_result" }),
                    active: currentPage === ROUTER_PATH_CONFIG.result.index,
                    icon: Icon.Result,
                    to: ROUTER_PATH_CONFIG.result.index,
                    isShow: true
                },
                {
                    key: BOTTOM_NAV_ICON_KEY.CHAT,
                    name: intl.formatMessage({ id: "main_page_bottom_bar_tab_chat" }),
                    active: currentPage === ROUTER_PATH_CONFIG.chatRoom,
                    icon: Icon.Chatroom,
                    to: ROUTER_PATH_CONFIG.chatRoom,
                    additional: {
                        show: showChatRoomUnreadDot(),
                        component: BottomNavDot
                    },
                    isShow: featureConfig.chatRoom
                },
                {
                    key: BOTTOM_NAV_ICON_KEY.ME,
                    name: intl.formatMessage({ id: "main_page_bottom_bar_tab_me" }),
                    active: currentPage === ROUTER_PATH_CONFIG.me.index,
                    icon: Icon.Me,
                    to: ROUTER_PATH_CONFIG.me.index,
                    isShow: true
                }

            ]
        });
    }, [currentPage, intl]);


    const b2bConfig = useMemo((): BottomNavConfig => {

        return ({
            bgColor: "tw-bg-b2b-primary-400",
            activeColor: "tw-text-b2b-primary-400",
            tabs: [
                {
                    key: BOTTOM_NAV_ICON_KEY.HOME,
                    name: intl.formatMessage({ id: "main_page_bottom_bar_tab_home" }),
                    active: currentPage === ROUTER_PATH_CONFIG.home,
                    icon: Icon.Flame,
                    to: ROUTER_PATH_CONFIG.home,
                    isShow: true
                },
                {
                    key: BOTTOM_NAV_ICON_KEY.MATCHES,
                    name: intl.formatMessage({ id: "main_page_bottom_bar_tab_my_games" }),
                    active: currentPage === ROUTER_PATH_CONFIG.myMatch.index,
                    icon: Icon.MyGames,
                    to: ROUTER_PATH_CONFIG.myMatch.index,
                    isShow: true
                },
                {
                    key: BOTTOM_NAV_ICON_KEY.RESULT,
                    name: intl.formatMessage({ id: "main_page_bottom_bar_tab_result" }),
                    active: currentPage === ROUTER_PATH_CONFIG.result.index,
                    icon: Icon.Result,
                    to: ROUTER_PATH_CONFIG.result.index,
                    isShow: true
                },
                {
                    key: BOTTOM_NAV_ICON_KEY.HISTORY,
                    name: intl.formatMessage({ id: "label_history" }),
                    active: currentPage === ROUTER_PATH_CONFIG.wallet,
                    icon: Icon.History,
                    to: ROUTER_PATH_CONFIG.wallet,
                    isShow: true
                },
                {
                    key: BOTTOM_NAV_ICON_KEY.GUIDE,
                    name: intl.formatMessage({ id: "label_guide" }),
                    active: currentPage === ROUTER_PATH_CONFIG.guide,
                    icon: Icon.Game,
                    to: ROUTER_PATH_CONFIG.guide,
                    isShow: true
                }
            ]
        });
    }, [currentPage, intl]);

    return isB2C ? b2cConfig : b2bConfig;
};
