import Navbar from "components/container/navbar/Navbar";
import { TOAST_TYPE } from "designToken/alert/components/AlertHelper";
import { depositSuccessModal, ORDER_STATUS } from "domain/coin/AddCoinHelper";
import CoinRecords from "domain/wallet/coin/CoinRecords";
import RubyRecords from "domain/wallet/ruby/RubyRecords";
import useUpdateWallet from "domain/wallet/useUpdateWallet";
import { HomeIcon, Title, WalletHistoryWrapper } from "domain/wallet/WalletHistoryStyle";
import WinningRecords from "domain/wallet/winning/WinningRecords";
import { homeIcon } from "helpers/IconHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MdInfoOutline } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { matchPath } from "react-router";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";

import { addToast } from "store/actions/AlertActions";
import { SYSTEM_COLORS } from "styles/Constants";
import useUserIdentity from "../../hooks/useUserIdentity";
import { WalletTabs } from "domain/wallet/components/WalletTabs";
import { WALLET_TYPE } from "data/dto/wallet/walletHistoryDto";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import { WalletRepository } from "data/repository/wallet/walletRepository";
import { WalletHistoriesVo } from "data/vo/wallet/WalletHistoriesVo";
import useFantasyConfig from "hooks/useFantasyConfig";
import { TipDialog } from "domain/wallet/components/TipDialog";

const makeWalletTabs = ({ appName }: { appName: string }) => ({
    [WALLET_TYPE.COIN]: {
        key: WALLET_TYPE.COIN,
        name: <FormattedMessage id="label_deposit" />,
        dialogContent: <FormattedMessage id="deposit_tooltip_dialog_label" />,
        component: CoinRecords
    },
    [WALLET_TYPE.WINNING]: {
        key: WALLET_TYPE.WINNING,
        name: <FormattedMessage id="label_winning" />,
        dialogContent: <FormattedMessage id="winning_tooltip_dialog_label" />,
        component: WinningRecords
    },
    [WALLET_TYPE.RUBY]: {
        key: WALLET_TYPE.RUBY,
        name: <FormattedMessage id="label_ruby" />,
        dialogContent: <FormattedMessage id="ruby_tooltip_dialog_label" values={{ appName }} />,
        component: RubyRecords
    }
});

const findCategoryFromPath = (pathname: string): WALLET_TYPE => {
    const matcher = {
        path: ROUTER_PATH_CONFIG.wallet + "/:category",
        exact: true,
        strict: true,
    };
    return matchPath(pathname, matcher)?.params?.category?.toUpperCase();
};


const walletRepository = new WalletRepository();

const WalletHistory = () => {
    const history = useFantasyHistory();
    const dispatch = useDispatch();
    const { isB2B } = useUserIdentity();
    const { title } = useFantasyConfig();

    const walletTabs = makeWalletTabs({ appName: title });
    const updateWallet = useUpdateWallet();

    const { data: walletHistory } = useFantasyQuery([QUERY_KEY.WALLET_HISTORY], () => {
        updateWallet();
        return walletRepository.getWalletHistories();
    });


    const [activeTab, setActiveTab] = useState<WALLET_TYPE>(() => history.location.state?.category || walletTabs[WALLET_TYPE.COIN].key);
    useEffect(() => {
        const category = findCategoryFromPath(history.location.pathname);
        if (walletTabs.hasOwnProperty(category)) {
            setActiveTab(category);
            history.replace(ROUTER_PATH_CONFIG.wallet);
        }
    }, [history]);

    const [showDialog, setShowDialog] = useState(false);
    const onHide = useCallback(() => setShowDialog(false), []);

    const dialogContent = useMemo(() => walletTabs[activeTab].dialogContent, [activeTab]);

    const RecordLayout = useMemo(() => walletTabs[activeTab].component, [activeTab]);


    useEffect(() => {
        if (history.location?.state?.from === depositSuccessModal
            && history.location?.state?.status === ORDER_STATUS.PENDING) {
            dispatch(addToast({
                type: TOAST_TYPE.WARNING,
                content: <FormattedMessage id="order_processing_dialog_toast_deposit_processing" />
            }));
        }
    }, [history, dispatch]);

    const NavBarCenterComponent = isB2B ? null : (<Title><FormattedMessage id="label_transaction_history" /></Title>);

    return <>
        <Navbar
            onNavbarBackClick={history.goBack}
            navBackIconColor={SYSTEM_COLORS.primary80}
            navbarStart={!isB2B}
            startIcon={
                history.location?.state?.from === depositSuccessModal
                    ? <HomeIcon $icon={homeIcon} onClick={() => history.push(ROUTER_PATH_CONFIG.home)} />
                    : null
            }
            navbarCenter={NavBarCenterComponent}
            endIcon={<MdInfoOutline size="2rem" className="clickable text-text-primary-1"
                                    onClick={() => setShowDialog(true)} />}
            navbarBottom={<WalletTabs
                activeTab={activeTab}
                walletTabs={walletTabs}
                onChangeTab={(key) => {
                    window.scrollTo(0, 0);
                    setActiveTab(key);
                }}
            />}
            isTransparent={true}
            isHeaderLine={false}
            className="tw-bg-gray-50"
        />
        <WalletHistoryWrapper>
            <RecordLayout histories={walletHistory ?? WalletHistoriesVo.empty()} />

            <TipDialog show={showDialog} onHide={onHide} dialogContent={dialogContent} />

        </WalletHistoryWrapper>
    </>;
};

export default WalletHistory;
