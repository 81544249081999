import { divideByOneHundred } from "helpers/number/NumberHelper";
import { SPORT } from "types/sports/Sport";

export class UserProfileStatsVo {
    sport: SPORT;
    topScoreCent: number | null;
    topScore: number | null;
    topScorePrDimi: number;
    topScorePr: number;
    joinedContests: number;
    joinedMatches: number;

    constructor(stat: {
        sport: SPORT,
        topScoreCent?: number,
        topScorePrDimi?: number | null,
        joinedContests?: number,
        joinedMatches?: number
    }) {
        this.sport = stat.sport;
        this.topScoreCent = stat?.topScoreCent ?? null;
        this.topScore = stat?.topScoreCent ? divideByOneHundred(stat.topScoreCent) : null;
        this.topScorePrDimi = stat?.topScorePrDimi ?? 0;
        this.topScorePr = stat.topScorePrDimi ? divideByOneHundred(stat.topScorePrDimi) : 0;
        this.joinedContests = stat?.joinedContests ?? 0;
        this.joinedMatches = stat?.joinedMatches ?? 0;
    }


}
