import { AxiosError } from "axios";
import useDialogProps from "components/dialog/hook/useDialogProps";
import { BUTTON_PATTERN, BUTTON_SIZE } from "designToken/button/types";
import { analyticsEvent, logCustomEvent } from "ga";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import { addErrorPopup } from "store/actions/AlertActions";
import FantasyDialog, { FantasyDialogProps } from "../../../../components/dialog/FantasyDialog";
import Panel from "../../../../components/panel/Panel";
import Button from "../../../../designToken/button/Button";
import { isCoupon, isDeposit, isNoAward, isRuby } from "../../../../helpers/AwardHelper";
import {
    createErrorObject,
    isDataNotFound,
    isPromotionalInsufficientApLevel,
    isPromotionalNoQuota,
    isPromotionalUsed
} from "../../../../helpers/ErrorHelper";
import { useSelector } from "../../../../store";
import { WarningLabel } from "../../../friend/searchUserBar/SearchUserBarStyle";
import { StyledFormControl } from "./B2CPromotionalPanelStyle";
import {
    PromotionalPanelCloseButton,
    PromotionalPanelContainer,
    PromotionalPanelContent,
    PromotionalPanelHeader,
    PromotionalPanelTitle
} from "./B2CPromotionalStyle";
import { useParticipateCampaign } from "./hook/useParticipateCampaign";

interface PromotionalPanelProps {
    isPromotionalPanelOpened: boolean;
    setIsPromotionalPanelOpened: (isOpened: boolean) => void;
    initPromotionalCode: string;
}

const getInputGroupClass = (hasError = false) => {
    if (hasError) {
        return "border-sys-danger text-sys-danger-1";
    }

    return "border-light-tertiary text-text-primary-1";
};

function isPromotionalCodeOverLimit(code) {
    return code.length > 10;
}

function isPromotionalCodeEmpty(promotionalCode: string) {
    return promotionalCode.length === 0;
}

export const B2CPromotionalPanel: React.FC<PromotionalPanelProps> = ({
                                                                         isPromotionalPanelOpened,
                                                                         setIsPromotionalPanelOpened,
                                                                         initPromotionalCode,
                                                                     }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { featureConfig } = useSelector(state => state.FantasyGameConfigState);
    const [promotionalCode, setPromotionalCode] = useState(initPromotionalCode);
    const [promotionalCodeError, setPromotionalCodeError] = useState<string>("");
    const [fantasyDialogShow, setFantasyDialogShow] = useState(false);
    const [fantasyDialogProps, setFantasyDialogProps] = useState<Omit<FantasyDialogProps, "show">>();
    const dialogProps = useDialogProps();

    const handlePromotionalCodeInput = e => {
        const text = e.target.value;
        setPromotionalCode(text);
        setPromotionalCodeError(isPromotionalCodeOverLimit(text)
            ? intl.formatMessage({ id: "redemption_panel_error_limit_10_char" })
            : "");
    };

    const handleCloseDialog = () => {
        setFantasyDialogShow(false);
        setIsPromotionalPanelOpened(false);
    };

    const handleAddCampaignSuccess = (data) => {
        setFantasyDialogShow(true);

        if (isCoupon(data.type)) {
            setFantasyDialogProps(dialogProps.couponDialogProps(handleCloseDialog, data.discountPercentage));
            return;
        }

        if (isDeposit(data.type)) {
            setFantasyDialogProps(dialogProps.depositDialogProps(handleCloseDialog, divideByOneHundred(data.coinCent)));
            return;
        }

        if (isRuby(data.type)) {
            setFantasyDialogProps(dialogProps.rubyDialogProps(handleCloseDialog, divideByOneHundred(data.rubyCent)));
            return;
        }

        if (isNoAward(data.type)) {
            setFantasyDialogProps(dialogProps.noAwardDialogProps(handleCloseDialog));
            return;
        }
    };

    const handleAddCampaignFail = (error: AxiosError<any>) => {
        if (isDataNotFound(error as any)) {
            setPromotionalCodeError(intl.formatMessage({ id: "redemption_panel_error_data_not_found" }));
            return;
        }

        if (isPromotionalUsed(error as any)) {
            setFantasyDialogShow(true);
            setFantasyDialogProps(dialogProps.redeemedDialogProps(handleCloseDialog));
            return;
        }

        if (isPromotionalNoQuota(error as any)) {
            setFantasyDialogShow(true);
            setFantasyDialogProps(dialogProps.noQuotaProps(handleCloseDialog));
            return;
        }

        if (isPromotionalInsufficientApLevel(error as any)) {
            const { minApLevel, userApLevel } = error!.response!.data!.data;
            setFantasyDialogShow(true);
            setFantasyDialogProps(dialogProps.insufficientApDialogProps(handleCloseDialog, userApLevel, minApLevel, featureConfig.addCoin));
            return;
        }

        dispatch(addErrorPopup(createErrorObject(error)));
    };

    const handleParticipateCampaign = useParticipateCampaign(handleAddCampaignSuccess, handleAddCampaignFail);

    const handleAddCampaign = () => {
        logCustomEvent(analyticsEvent.webClickRedeemButton);
        handleParticipateCampaign(promotionalCode.trim());
    };


    return <>
        <Panel show={isPromotionalPanelOpened}
               onHide={() => setIsPromotionalPanelOpened(false)}
               Header={
                   <PromotionalPanelHeader>
                       <PromotionalPanelTitle>
                           <FormattedMessage id="redemption_panel_title" />
                       </PromotionalPanelTitle>
                       <PromotionalPanelCloseButton onClick={() => {
                           setIsPromotionalPanelOpened(false);
                       }} />
                   </PromotionalPanelHeader>
               }
               Body={<PromotionalPanelContainer data-testid="promotional-panel">
                   <PromotionalPanelContent>
                       <FormattedMessage id="redemption_panel_description" />
                   </PromotionalPanelContent>
                   <StyledFormControl type="text"
                                      data-testid="promotional-panel-input"
                                      className={getInputGroupClass(promotionalCodeError !== "")}
                                      placeholder={intl.formatMessage({ id: "redemption_panel_input_hint" })}
                                      onChange={handlePromotionalCodeInput}
                                      value={promotionalCode}
                   />
                   <WarningLabel data-testid="warning-label">{promotionalCodeError}</WarningLabel>
                   <div className="mt-4">
                       <Button dataTestId="promotional-panel-button"
                               disabled={isPromotionalCodeOverLimit(promotionalCode) || isPromotionalCodeEmpty(promotionalCode)}
                               onClick={handleAddCampaign}
                               size={BUTTON_SIZE.lg}
                               pattern={BUTTON_PATTERN.pill}
                               className={"w-100"}>
                           <FormattedMessage id="redemption_panel_button" />
                       </Button>
                   </div>
               </PromotionalPanelContainer>}
        />
        <FantasyDialog show={fantasyDialogShow} onHide={handleCloseDialog} {...fantasyDialogProps} />
    </>;

};
