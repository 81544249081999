import { generatePlayerName } from "helpers/PlayerHelpers";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

import { renderTextWithPunctuationMark } from "./PlayersLayoutHelper";
import {
    BatsmanBalls,
    BowlerOvers,
    EachBallResult,
    LatestResultWrapper,
    PlayerInfo,
    PlayerName,
    PlayersInfoSection,
    PlayersInfoWrapper,
    PlayerWrapper
} from "./PlayersLayoutStyle";

const PlayersLayout = ({ batsmen, bowlers, scores }) => {
    return <PlayerWrapper id="playerWrapper">
        <PlayersInfoWrapper>
            <PlayersInfoSection>
                {
                    batsmen.map((batsman) =>
                        <PlayerInfo key={_.uniqueId("player-batman-")}>
                            <PlayerName>{generatePlayerName(batsman)}</PlayerName>
                            <div>
                                {batsman.runs}
                                <BatsmanBalls>{renderTextWithPunctuationMark(batsman.balls, "(", ")")}</BatsmanBalls>
                            </div>
                        </PlayerInfo>)
                }
            </PlayersInfoSection>
            <PlayersInfoSection>
                {
                    bowlers.map((bowler) =>
                        <PlayerInfo key={_.uniqueId("player-bowler-")}>
                            <PlayerName>{generatePlayerName(bowler)}</PlayerName>
                            <div>
                                {bowler.maidens}{renderTextWithPunctuationMark(bowler.runs, "/")}
                                <BowlerOvers>{renderTextWithPunctuationMark(bowler.overs, "(", ")")}</BowlerOvers>
                            </div>
                        </PlayerInfo>)
                }
            </PlayersInfoSection>
        </PlayersInfoWrapper>

        <LatestResultWrapper>
            {[...scores].reverse().map(score =>
                <EachBallResult key={_.uniqueId("score")}
                                score={score}>{score}</EachBallResult>)}
        </LatestResultWrapper>
    </PlayerWrapper>;
};

export default PlayersLayout;

PlayersLayout.propTypes = {
    batsmen: PropTypes.arrayOf(PropTypes.shape({
        firstName: PropTypes.string,
        middleName: PropTypes.string,
        lastName: PropTypes.string,
        runs: PropTypes.number,
        balls: PropTypes.number
    })).isRequired,
    bowlers: PropTypes.arrayOf(PropTypes.shape({
        firstName: PropTypes.string,
        middleName: PropTypes.string,
        lastName: PropTypes.string,
        maidens: PropTypes.number,
        runs: PropTypes.number,
        overs: PropTypes.string
    })).isRequired,
    scores: PropTypes.array.isRequired
};
