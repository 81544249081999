import { MatchRepository } from "data/repository/match/MatchRepository";
import PlayersLayout from "domain/match/components/players/PlayersLayout";
import {
    CricketQuickSummarySynchronizing
} from "domain/match/components/quickSummary/CricketQuickSummarySynchronizing";
import { Commentary } from "domain/match/pages/started/MyContestDetailStyle";
import { isMatchKickoffOrLive } from "helpers/match/MatchHelper";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import React from "react";
import { SPORT } from "types/sports/Sport";

const CricketQuickSummary = ({ match }) => {
    const matchRepository = new MatchRepository();

    const { data: matchSnapshot } = useFantasyQuery(
        [QUERY_KEY.MATCH_SNAPSHOT, match.matchId],
        () => matchRepository.getMatchSnapshot(SPORT.CRICKET, match.matchId),
        { options: { enabled: isMatchKickoffOrLive(match.matchStatus) } }
    );

    if (!matchSnapshot) {
        return null;
    }

    if (matchSnapshot.isNotReady) {
        return <CricketQuickSummarySynchronizing />;
    }

    return <>
        <Commentary>{matchSnapshot.commentary}</Commentary>
        <PlayersLayout batsmen={matchSnapshot.batsmen}
                       bowlers={matchSnapshot.bowlers}
                       scores={matchSnapshot.scores} />
    </>;
};

export default CricketQuickSummary;
