import { MyProfileVo } from "data/vo/user/MyProfileVo";
import { TOAST_TYPE } from "designToken/alert/components/AlertHelper";
import { StyledTitleBarIcon, } from "domain/me/components/TitleBar/TitleBarStyle";
import useFantasyConfig from "hooks/useFantasyConfig";
import React, { useCallback, useMemo } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { MdOutlineShare } from "react-icons/md";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";

import { addToast } from "store/actions/AlertActions";

export interface ShareButtonProps {
    id: MyProfileVo["id"];
    nickname: MyProfileVo["nickname"];
}

const ShareButton: React.FC<ShareButtonProps> = ({ id, nickname, }) => {
    const dispatch = useDispatch();
    const { firebase, title } = useFantasyConfig();

    const intl = useIntl();

    const url = useMemo(() => {
        const authDomain = firebase.authDomain;
        const domain = `https://${authDomain}`;
        const url = `${domain}${ROUTER_PATH_CONFIG.userProfile.index}/${id}`;
        return url;
    }, [firebase, id]);

    const handleCopy = useCallback(() => {
        dispatch(
            addToast({
                type: TOAST_TYPE.SUCCESS,
                content: intl.messages["copy_referral_code_success_toast_label"],
            }),
        );
    }, [dispatch, intl.messages]);

    const handleShare = useCallback(() => {
        navigator.share({
            text: intl.formatMessage({ id: "me_page_label_share_icon" }, {
                    nickname,
                    appName: title
                },
            ),
            url,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nickname, url]);

    if (!navigator.canShare) {
        return (
            <StyledTitleBarIcon>
                <CopyToClipboard text={url} onCopy={handleCopy}>
                    <MdOutlineShare size="2.4rem" />
                </CopyToClipboard>
            </StyledTitleBarIcon>
        );
    }

    return <StyledTitleBarIcon onClick={handleShare}>
        <MdOutlineShare size="2.4rem" />
    </StyledTitleBarIcon>;
};
export default ShareButton;
