import { ApiExecuteContext } from "apis/ApiContext";
import { transfer } from "apis/user/UserApi";
import clsx from "clsx";
import UserAvatarWithFrame from "components/avatar/UserAvatarWithFrame";
import { useLoadingToggle } from "components/loading/Loading";
import { UserName } from "domain/friend/transfer/amount/TransferAmountStyle";
import { TransferNavbar } from "domain/friend/transfer/TransferFlow";
import { FixedPrimaryButton, TransferWrapper } from "domain/friend/transfer/TransferStyle";
import { createErrorObject } from "helpers/ErrorHelper";
import { divideByOneHundred, multiplyByOneHundred } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import { useTransferSuccessDialog } from "hooks/useDialog";
import useFantasyHistory from "hooks/useFantasyHistory";
import useQueryCurrentLevel from "hooks/useQueryCurrentLevel";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import { addErrorPopup } from "store/actions/AlertActions";
import {
    AmountRow,
    AvatarWrapper,
    BalanceRow,
    ClearNote,
    ConfirmContent,
    ConfirmUserInfo,
    ConfirmWrapper,
    FirstConfirmContent,
    NoteContentWrapper,
    NoteInput,
    NoteLength,
    TransferAmountCol,
    TransferContainer,
    TransferRow,
    TransferTitleCol,
    TransferValueCol,
    WinningIcon,
    WinningText
} from "./ConfirmTransferStyle";

const winningIcon = "/images/winning_icon.png";

const MAX_LENGTH_OF_NOTE = 45;

const ConfirmTransfer = ({ toPrevStep, myTransferred, selectedFriend, inputAmount }) => {
    const intl = useIntl();
    const history = useFantasyHistory();
    const dispatch = useDispatch();
    const apiExecutor = useContext(ApiExecuteContext);
    const toggleLoading = useLoadingToggle();
    const winning = divideByOneHundred(myTransferred.winningCent);
    const noteInputRef = useRef(null);
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();
    const transferSuccessPopup = useTransferSuccessDialog();
    const [note, setNote] = useState("");

    const handleTransfer = () => {
        toggleLoading(true);
        apiExecutor(
            transfer(selectedFriend.userId, multiplyByOneHundred(inputAmount), note),
            {
                onSuccess: () => {
                    toggleLoading(false);
                    history.goBack();
                    transferSuccessPopup(selectedFriend.nickname, toWalletNumber(inputAmount));
                },
                onFail: error => {
                    toggleLoading(false);
                    dispatch(addErrorPopup(createErrorObject(error)));
                }
            }
        );
    };

    const {
        isFetching,
        userIdToCurrentLevel
    } = useQueryCurrentLevel(
        [selectedFriend.userId]
    );

    useEffect(() => {
        if (isFetching) {
            toggleLoading(true);
            return;
        }
        toggleLoading(false);
    }, [isFetching, toggleLoading]);

    return <>
        <TransferNavbar
            text={intl.messages["confirm_transfer_page_title"]}
            handleBack={toPrevStep}
        />
        <TransferWrapper>
            <ConfirmWrapper>
                <FirstConfirmContent>
                    <ConfirmUserInfo>
                        <AvatarWrapper>
                            <UserAvatarWithFrame url={selectedFriend.avatar}
                                                 name={selectedFriend.nickname}
                                                 font="fz-sf-bold-title-1"
                                                 size="5.6rem"
                                                 active
                                                 currentLevel={userIdToCurrentLevel[selectedFriend.userId]} />
                        </AvatarWrapper>
                        <UserName>{selectedFriend.nickname}</UserName>
                    </ConfirmUserInfo>
                    <TransferContainer>
                        <TransferRow>
                            <TransferTitleCol>
                                <WinningIcon icon={winningIcon} />
                                <WinningText><FormattedMessage id="label_winning" /></WinningText>
                            </TransferTitleCol>
                            <TransferValueCol>{toWalletNumber(winning)}</TransferValueCol>
                        </TransferRow>
                        <AmountRow>
                            <TransferTitleCol>
                                <FormattedMessage id="label_amount" />
                            </TransferTitleCol>
                            <TransferAmountCol>{toWalletNumber(inputAmount)}</TransferAmountCol>
                        </AmountRow>
                        <BalanceRow>
                            <TransferTitleCol><FormattedMessage id="label_balance" /></TransferTitleCol>
                            <TransferValueCol>{toWalletNumber(winning - inputAmount)}</TransferValueCol>
                        </BalanceRow>
                    </TransferContainer>
                </FirstConfirmContent>
                <ConfirmContent>
                    <div
                        className={clsx("fz-sf-regular-subhead", {
                            "tw-text-primary-600": note.length,
                            "tw-text-system-labelLightSecondary": !note.length
                        })}
                    >
                        <FormattedMessage id="label_note" />
                    </div>
                    <NoteContentWrapper>
                        <NoteInput ref={noteInputRef}
                                   type="text"
                                   maxLength={MAX_LENGTH_OF_NOTE}
                                   hasNote={!!note.length}
                                   value={note}
                                   onChange={(e) => setNote(e.target.value)} />
                        <ClearNote tabIndex={-1} onClick={() => {
                            setNote("");
                            noteInputRef.current.focus();
                        }} />
                        <NoteLength>{note.length}/{MAX_LENGTH_OF_NOTE}</NoteLength>
                    </NoteContentWrapper>
                </ConfirmContent>
                <FixedPrimaryButton onClick={handleTransfer}>
                    <FormattedMessage id="confirm_transfer_page_button" />
                </FixedPrimaryButton>
            </ConfirmWrapper>
        </TransferWrapper>
    </>;
};

export default ConfirmTransfer;
