import { ContestsProps } from "../../domain/contest/components/ContestCarousel";
import { AP_LEVEL } from "../activityPoint/ApLevel";

export enum CONTEST_STATUS_FROM_API {
    PUBLISHED = "PUBLISHED",
    CANCELLED = "CANCELLED",
    TO_BE_CANCELLED = "TO_BE_CANCELLED",
}

export enum CONTEST_TYPE_FROM_API {
    NORMAL = "PUBLIC_NORMAL",
    PROMOTIONAL = "PUBLIC_PAYABLE_PROMOTIONAL",
    PRIVATE = "PRIVATE",
    PRACTICE = "PUBLIC_PRACTICE",
    PRIZE_HUNTER = "PUBLIC_PRIZE_HUNTER",
}

export enum ENTRY_FEE_TYPE_FROM_API {
    DEPOSIT = "DEPOSIT",
    RUBY = "RUBY",
}


interface MyJoinedTeamVO {
    teamId: number,
    nickname: string
    teamName: string
    scoreCent: number
    rank: number | null,
    wonPrizeCent: number
}

export interface MyContestVO extends ContestsProps {
    id: number
    matchId: string
    name: string
    code: string
    entryFeeCent: number
    spots: number
    regularPoolCent: number
    hostDonatedPoolCent: number
    teams: number
    allowMultiple: boolean
    firstPrizeCent: number
    prizeTeams: number
    status: CONTEST_STATUS_FROM_API
    myJoinedTeams: MyJoinedTeamVO[]
    type: CONTEST_TYPE_FROM_API
    needOtp: boolean
    minApLevel: AP_LEVEL
    minAp: number
    minApLevelWeight: number
}

export interface ResultContestVO {
    matchId: string
    name: string
    code: string
    entryFeeCent: number
    type: CONTEST_TYPE_FROM_API
}
