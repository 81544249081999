import { ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";
import { SPORT } from "types/sports/Sport";

type EntryFeeTypeToStatsList = Record<ENTRY_FEE_TYPE_FROM_API, {
    sport: SPORT;
    topScoreCent: number | null;
    topScore: number | null;
    topScorePrDimi: number;
    topScorePr: number;
    joinedContests: number;
    joinedMatches: number;
}[]>

type UserProfileCareerStatsProps = {
    from: number;
    until: number;
    registeredAt: number;
    entryFeeTypeToStatsList: EntryFeeTypeToStatsList;
};

export class UserProfileCareerStatsVO {
    from: number;
    until: number;
    registeredAt: number;
    entryFeeTypeToStatsList: EntryFeeTypeToStatsList;

    constructor(dto: UserProfileCareerStatsProps) {
        this.from = dto.from;
        this.until = dto.until;
        this.registeredAt = dto.registeredAt;
        this.entryFeeTypeToStatsList = dto.entryFeeTypeToStatsList;
    }

    public getStatsWithRegisteredByEntryFeeType(entryFeeType: ENTRY_FEE_TYPE_FROM_API) {
        return {
            stats: this.entryFeeTypeToStatsList[entryFeeType],
            registeredAt: this.registeredAt
        };
    }


}
