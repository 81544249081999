import clsx from "clsx";
import { omit } from "lodash";
import React, { useState } from "react";
import { Control } from "react-hook-form";
import { FormControl, FormField, FormItem } from "components/ui/form";
import { Input } from "components/ui/input";
import { FormErrorMessage } from "domain/kyc/pages/kycVerificationForm/components/FormErrorMessage";
import { OnChangeValidator, ZodOnChangeValidator } from "./OnChangeValidator";
import { NON_ZERO_POSITIVE_OR_EMPTY_REGEX } from "helpers/RegexHelper";
import { z } from "zod";

export enum TEXT_FIELD_SIZE {
    Small,
    Medium
}

export enum TEXT_FIELD_VARIANT {
    STANDARD,
    FILLED,
    OUTLINED
}

export interface ShadCNTextFieldProps {
    size?: TEXT_FIELD_SIZE;
    variant?: TEXT_FIELD_VARIANT;
    control?: Control<any>;
    name: string;
    inputProps?: Omit<React.InputHTMLAttributes<HTMLInputElement>, "name"> & {
        "data-testid"?: string
    };
    showErrorMessage?: boolean;
    showErrorIcon?: boolean;
    iconLeft?: React.ReactNode;
    iconRight?: React.ReactNode;
    maxLength?: number;
    onChangeValidator?: OnChangeValidator;
    disabled?: boolean;
}

const sizeClassNameMap = {
    [TEXT_FIELD_SIZE.Small]: {
        fontSize: "tw-text-caption-2",
        height: "tw-h-[3.1rem]",
        paddingLeft: "tw-pl-[2.8rem]",
    },
    [TEXT_FIELD_SIZE.Medium]: {
        fontSize: "tw-text-body-1",
        height: "tw-h-[4.8rem]",
        paddingLeft: "tw-pl-[3.4rem]",
    },
};

const variantClassNameMap = {
    [TEXT_FIELD_VARIANT.STANDARD]: {
        borderColor: "tw-border-transparent",
        backgroundColor: "tw-bg-background",
    },
    [TEXT_FIELD_VARIANT.FILLED]: {
        borderColor: "tw-border-transparent focus:tw-border-info-700",
        backgroundColor: "tw-bg-grey-50",
    },
    [TEXT_FIELD_VARIANT.OUTLINED]: {
        borderColor: "tw-border-grey-200",
        backgroundColor: "tw-bg-background",
    },
};

export const FantasyTextField: React.FC<ShadCNTextFieldProps> = ({
                                                                     size = TEXT_FIELD_SIZE.Medium,
                                                                     variant = TEXT_FIELD_VARIANT.STANDARD,
                                                                     control,
                                                                     name,
                                                                     inputProps,
                                                                     showErrorMessage = true,
                                                                     showErrorIcon = true,
                                                                     iconLeft,
                                                                     iconRight,
                                                                     maxLength,
                                                                     onChangeValidator,
                                                                     disabled
                                                                 }) => {
    const [isFocused, setIsFocused] = useState(false);
    const iconBasicClassName = "tw-absolute tw-top-0 tw-bottom-0 tw-flex tw-items-center tw-justify-center";
    const { borderColor, backgroundColor } = variantClassNameMap[variant];
    const sizeClassName = sizeClassNameMap[size] ?? {
        fontSize: "tw-text-body-1",
        paddingLeft: "",
        height: ""
    };

    return (
        <FormField
            control={control}
            name={name}
            render={({ field, fieldState }) => {
                const onChange = inputProps?.onChange || field.onChange;
                const handleChange = onChangeValidator ? (e => {
                    if (onChangeValidator.validate(e.target.value)) {
                        onChange(e);
                    }
                }) : onChange;

                return (
                    <FormItem>
                        <FormControl>
                            <div className={"tw-relative"}>
                                {iconLeft && (
                                    <div
                                        className={clsx(iconBasicClassName, "tw-left-3")}>{iconLeft}</div>
                                )}
                                <Input
                                    {...field}
                                    onFocus={() => setIsFocused(true)}
                                    onBlur={() => {
                                        setIsFocused(false);
                                        field.onBlur();
                                    }}
                                    className={clsx(
                                        "tw-w-full tw-rounded-[0.8rem]",
                                        "tw-px-[1.2rem] tw-py-[0.8rem]",
                                        "focus-visible:tw-outline-0",
                                        "tw-border",
                                        sizeClassName.fontSize,
                                        sizeClassName.height,
                                        iconLeft ? sizeClassName.paddingLeft : "",
                                        backgroundColor,
                                        fieldState.error ? "tw-border-error-700" : borderColor,
                                        inputProps?.className ?? "",
                                    )}
                                    disabled={disabled}
                                    {...omit(inputProps, "className")}
                                    onChange={handleChange}
                                />
                                {iconRight && (
                                    <div
                                        className={clsx(iconBasicClassName, "tw-right-3")}>{iconRight}</div>
                                )}
                            </div>
                        </FormControl>
                        {showErrorMessage &&
                            <FormErrorMessage showIcon={showErrorIcon} />
                        }
                        {
                            maxLength && isFocused && !fieldState.error &&
                            <div className="tw-text-caption-1 tw-text-start tw-text-info-700 tw-pt-1">
                                {field.value.length}/{maxLength}
                            </div>
                        }
                    </FormItem>
                );
            }} />
    );
};


const schema = z.string().regex(NON_ZERO_POSITIVE_OR_EMPTY_REGEX);
const integerAmountValidator = new ZodOnChangeValidator(schema);
export const FantasyIntegerTextField: React.FC<ShadCNTextFieldProps> = (props) => {
    return (
        <FantasyTextField
            {...props}
            onChangeValidator={integerAmountValidator}
        />
    );
};
