import { useSelector } from "store";
import { FormattedMessage } from "react-intl";
import React from "react";

export function TermsAndConditionLink() {
    const { link } = useSelector(store => store.SettingsState);

    return <a className={"tw-text-info-600"} target={"_blank"} href={link.termsAndConditions} rel="noreferrer">
        {"T&Cs"}
    </a>;
}

export function TermsAndCondition() {

    return (
        <p className={"tw-text-center tw-text-grey-600"}>
            <FormattedMessage id="confirmation_dialog_description_policy_1" />
            &nbsp;
            <TermsAndConditionLink />
            &nbsp;
            <FormattedMessage id="confirmation_dialog_description_policy_2" />
            &nbsp;
            <FormattedMessage id="confirmation_dialog_description_policy_3" />
        </p>
    );
}
