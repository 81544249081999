import { cn } from "../../../../helpers/cn";
import React from "react";
import { Icon } from "helpers/IconHelper";

type QuestPrizeAnimationProps = {
    coinCent: number;
    rubyCent: number;
};

const QuestPrizeAnimation: React.FC<QuestPrizeAnimationProps> = ({ coinCent, rubyCent }) => {
    const isOnlyCoin = rubyCent <= 0;
    const isOnlyRuby = coinCent <= 0;

    const renderIcon = (index: number) => {
        if (isOnlyCoin) {
            return <Icon.QuestPrize.Coin />;
        }

        if (isOnlyRuby) {
            return <Icon.QuestPrize.Ruby />;
        }

        return index < 6
            ? <Icon.QuestPrize.Coin />
            : <Icon.QuestPrize.Ruby />;
    };

    return <>
        {
            Array.from({ length: 10 }, (_, index) => (
                <div
                    key={`prize-claim-animation-${index}`}
                    className={cn(
                        "tw-absolute tw-z-50",
                        "tw-left-[var(--centerX)] tw--translate-x-1/2",
                        "tw-top-[var(--prizeCenterY)] tw--translate-y-1/2",
                    )}>
                    <div
                        className={cn(`tw-prize-claim-animation-${index}`)}>
                        {renderIcon(index)}
                    </div>
                </div>)
            )
        }
    </>;
};

export default QuestPrizeAnimation;