import { fetchUserCareerStats } from "data/api/user/UserApi";
import {
    addFriend,
    fetchMyTransferred,
    fetchUserProfile,
    MyTransferredDto,
    unfriend,
    updateMyProfile
} from "../../../apis/user/UserApi";
import { fetchCurrentApLevelList } from "apis/ap/ActivityPointApi";
import { UserProfileVo } from "data/vo/user/UserProfileVo";
import { UserProfileCareerStatsVO } from "data/vo/user/UserProfileCareerStatsVO";
import { UserProfileStatsDto } from "data/dto/user/careerStats/UserProfileStatsDto";
import { groupBy, mapValues } from "lodash";
import { ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";
import { UserProfileStatsVo } from "data/vo/user/UserProfileStatsVo";
import { UpdateProfileRequest } from "../../dto/user/UpdateProfile";


export const getEntryFeeTypeToStatsList = (stats: UserProfileStatsDto[]) => {

    if (stats.length === 0) {
        return mapValues(ENTRY_FEE_TYPE_FROM_API, () => {
            return [];
        });
    }

    const entryFeeTypeToStats = groupBy(stats, (stat) => {
        return stat.entryFeeType;
    });

    return mapValues(ENTRY_FEE_TYPE_FROM_API, (entryFeeType: ENTRY_FEE_TYPE_FROM_API) => {
        const stats = entryFeeTypeToStats[entryFeeType];
        return stats.map((stat) => {
            return new UserProfileStatsVo(stat);
        });
    });
};

export class UserProfileRepository {

    public async getCareerStats(userId: number, myId: number, time: number): Promise<UserProfileCareerStatsVO[]> {
        return Promise.all([
            fetchUserCareerStats(userId, time),
            fetchUserCareerStats(myId, time)
        ])
            .then((data) => {
                return data.map((dto) => {
                    return new UserProfileCareerStatsVO({
                        ...dto,
                        entryFeeTypeToStatsList: getEntryFeeTypeToStatsList(dto.stats)
                    });
                });
            });
    }

    public async getUserInfo(userId: number): Promise<UserProfileVo> {

        const userProfileDto = await fetchUserProfile(userId);

        const userCurrentLevel = await fetchCurrentApLevelList([userId]);

        return {
            ...userProfileDto,
            currentLevel: userCurrentLevel[0].currentLevel
        };
    }

    public async updateUserProfile({ nickname, description }: UpdateProfileRequest): Promise<void> {
        await updateMyProfile({ nickname, description });
    }

    public async getMyTransferred(): Promise<MyTransferredDto> {
        return await fetchMyTransferred();
    }

    public async follow(userId: number): Promise<void> {
        await addFriend(userId);
    }

    public async unfollow(useId: number): Promise<void> {
        await unfriend(useId);
    }
}
