import Dialog from "designToken/dialog/Dialog";
import {
    ListItem,
    ListTitle,
    ListValue,
    ModalClose,
    ModalContainer,
    ModalTitle,
    ModalTitleRow
} from "domain/wallet/components/TransactionDetailStyle";
import { getWalletEventText } from "domain/wallet/WalletHelper";
import { closeIcon } from "helpers/IconHelper";
import { isNegative } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import React from "react";
import { ListGroup } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import useUserIdentity from "../../../hooks/useUserIdentity";
import { cn } from "helpers/cn";
import { WALLET_TYPE } from "data/dto/wallet/walletHistoryDto";
import { TransactionDetailVo } from "data/vo/wallet/TransactionDetailVo";
import { formatToFullYearDateTime } from "helpers/TimeHelper";

export interface TransactionDetailBaseProps {
    transactionData: TransactionDetailVo;
    walletType: WALLET_TYPE;
    extraDetailComponent?: (TransactionDetailVo: TransactionDetailVo) => React.ReactNode;
    amountExtraComponent?: (TransactionDetailVo: TransactionDetailVo) => React.ReactNode;
    noteComponent?: (TransactionDetailVo: TransactionDetailVo) => React.ReactNode;
    onHide: () => void;
}

const TransactionDetailBase: React.FC<TransactionDetailBaseProps> = (props) => {
    const {
        transactionData,
        walletType,
        extraDetailComponent,
        amountExtraComponent,
        noteComponent,
        onHide
    } = props;

    const { isB2B } = useUserIdentity();
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();

    return (
        <Dialog show={true} onHide={onHide}>
            <ModalContainer data-testid="modal-container">
                <ModalTitleRow>
                    <ModalTitle><FormattedMessage id="wallet_history_page_detail_title" /></ModalTitle>
                    <ModalClose $icon={closeIcon} onClick={onHide} />
                </ModalTitleRow>
                <ListGroup variant="flush">
                    {
                        transactionData.historyId && <ListItem>
                            <ListTitle><FormattedMessage id="label_id" /></ListTitle>
                            <ListValue>{transactionData.historyId || "-"}</ListValue>
                        </ListItem>
                    }

                    <ListItem>
                        <ListTitle><FormattedMessage id="label_date" /></ListTitle>
                        <ListValue>{transactionData.createTime ? formatToFullYearDateTime(transactionData.createTime) : "-"}</ListValue>
                    </ListItem>
                    <ListItem>
                        <ListTitle><FormattedMessage id="label_event" /></ListTitle>
                        <ListValue>
                            {getWalletEventText(walletType, transactionData, isB2B)}
                        </ListValue>
                    </ListItem>
                    {extraDetailComponent && extraDetailComponent(transactionData)}
                    <ListItem>
                        <ListTitle>
                            <FormattedMessage id="label_amount" />
                        </ListTitle>
                        <div data-testid="amount-cent"
                             className={cn(
                                 "fz-sf-bold-body-1",
                                 isNegative(transactionData.amountCent)
                                     ? transactionData.isPadding ? "tw-text-warning-900" : "tw-text-error-700"
                                     : transactionData.isPadding ? "tw-text-tertiary-700" : "tw-text-grey-1000",
                                 { "tw-line-through": walletType === WALLET_TYPE.COIN && transactionData.isCancelled }
                             )}
                        >
                            {toWalletNumber(transactionData.amount)}
                        </div>
                    </ListItem>
                    {amountExtraComponent && amountExtraComponent(transactionData)}
                    <ListItem>
                        <ListTitle><FormattedMessage id="label_note" /></ListTitle>
                        <ListValue>
                            {noteComponent && noteComponent(transactionData)}
                        </ListValue>
                    </ListItem>
                </ListGroup>
            </ModalContainer>
        </Dialog>
    );
};

export default TransactionDetailBase;
