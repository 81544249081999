import { Performance } from "components/Performance";
import { PlayerInfoVo } from "data/vo/player/PlayerInfoVo";
import { getSportConfig } from "domain/SportConfig";
import PlayerInfoDialogLoading from "domain/team/playerInfoDialog/loading/PlayerInfoDialogLoading";
import { PerformanceCol } from "domain/team/playerInfoDialog/PlayerInfoDialogStyle";
import { emptyPerformance } from "helpers/IconHelper";
import React from "react";
import { FormattedMessage } from "react-intl";
import { SquadPlayer } from "types/player/Player";


const PerformanceContent: React.FC<{
    player: SquadPlayer;
    playerInfo: PlayerInfoVo;
}> = ({ player, playerInfo }) => {

    const dataKey = "points";
    const recentPointsLength = playerInfo.vsOpponentPerformance?.recentPointsCents.length ?? 0;
    const { performances } = getSportConfig(player.sport);

    return <>
        <Performance
            height={60}
            data={playerInfo.vsOpponentPerformance!.recentPointsCents.map(points => ({ [dataKey]: points }))}
        />
        <div className="fz-sf-regular-caption-1 text-text-primary-1 my-3 text-center">
            Last {recentPointsLength} matches vs {playerInfo.oppositeSquadCode}
        </div>
        <div className="d-flex">
            {
                performances.map(performance => {
                    return <PerformanceCol key={performance.key}>
                        <div
                            className="fz-sf-bold-title-2 text-light-primary">
                            {playerInfo.vsOpponentPerformance![performance.key]}
                        </div>
                        <div
                            className="fz-sf-regular-footnote text-light-secondary">
                            {performance.label}
                        </div>
                    </PerformanceCol>;
                })
            }
        </div>
    </>;

};

interface PerformanceProps {
    player: SquadPlayer;
    playerInfo: PlayerInfoVo | null | undefined;
}

const EmptyPerformance = () => {
    return <div className="tw-flex tw-flex-col tw-items-center">
        <img className="tw-w-[18.8rem]" src={emptyPerformance} alt="empty-performance" />
        <div className="tw-text-overline tw-font-light tw-text-grey-750">
            <FormattedMessage id="label_collecting_data_to_chart" />
        </div>
    </div>;
};

const MatchPlayerPerformance: React.FC<PerformanceProps> = ({ player, playerInfo }) => {
    if (!playerInfo) {
        return <PlayerInfoDialogLoading />;
    }

    const { vsOpponentPerformance } = playerInfo;
    const shouldShowPerformance = vsOpponentPerformance !== null && vsOpponentPerformance.recentPointsCents.length > 1;

    return <div className="border border-light-primary rounded p-3" data-testid="performance">
        <div className="fz-sf-bold-body-1 text-text-primary-1 mb-3">
            <FormattedMessage id="performance_title" />
        </div>
        {
            shouldShowPerformance
                ? <PerformanceContent player={player} playerInfo={playerInfo} />
                : <EmptyPerformance />
        }


    </div>;
};

export default MatchPlayerPerformance;
