import { PerformanceVo } from "data/vo/player/performance/PerformanceVo";
import { Performance } from "data/vo/player/PlayerInfoVo";
import { divideByOneHundred } from "helpers/number/NumberHelper";

export class BasketballPerformanceVo extends PerformanceVo {
    private readonly _averagePointsCent: number;
    private readonly _averageReboundCent: number;
    private readonly _averageStealCent: number;

    constructor(performance: Performance) {
        super(performance);
        this._averagePointsCent = performance.averagePointsCent!;
        this._averageReboundCent = performance.averageReboundCent!;
        this._averageStealCent = performance.averageStealCent!;
    }

    get averageSteals(): number {
        return Math.floor(divideByOneHundred(this._averageStealCent));
    }

    get averagePoints(): number {
        return Math.floor(divideByOneHundred(this._averagePointsCent));
    }

    get averageRebounds(): number {
        return Math.floor(divideByOneHundred(this._averageReboundCent));
    }

}
