import { awardBackground, awardCouponIcon } from "helpers/IconHelper";
import styled from "styled-components";

const AwardCoupon = () => {

    return <CardImg>
        <img src={awardCouponIcon} alt="awardCouponIcon" />
    </CardImg>;
};

const CardImg = styled.div.attrs({
    className: "card-img-top d-flex align-items-center justify-content-center bg-gray-2"
})`
    margin-bottom: -1.2rem;
    padding-top: 4.2rem;
    padding-bottom: 1.2rem;
    background: url(${awardBackground}) no-repeat center;
`;

export default AwardCoupon;
