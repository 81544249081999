import { useAppPopups } from "domain/popup/AppPopupsProvider";
import { storageHelper } from "helpers/localStorage/LocalStorageHelper";
import { LOCAL_STORAGE_KEYS } from "helpers/localStorage/types";
import useFantasyHistory from "hooks/useFantasyHistory";
import useMyProfile from "hooks/useMyProfile";
import moment from "moment";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { useSelector } from "store";
import { isUnsuccessful, isUnverified, isVerified } from "types/kyc/KycStatus";

const KYC_NOT_SHOW_AGAIN_TIME_FORMAT = "YYYY-MM-DD";

export interface KycPopupProps {
    isShowUnverified: boolean;
    handleCloseUnverified: () => void;
    isDoNotShowAgainChecked: boolean,
    setIsDoNotShowAgainChecked: React.Dispatch<React.SetStateAction<boolean>>,
    isShowCompleted: boolean;
    handleCloseCompleted: () => void;
    isShowUnsuccessful: boolean;
    handleCloseUnsuccessful: () => void;
    goToKycForm: () => void;
}

export const KycPopupContext = createContext<KycPopupProps | null>(null);

type KycPopupProviderProps = {
    children: React.ReactNode;
};

function isSameDay(storage: {
    put: (data: any) => void;
    get: () => any;
    remove: () => void;
    clear: () => void
}) {

    if (!storage.get()) {
        return false;
    }

    return moment(storage.get()).isSame(moment().format(KYC_NOT_SHOW_AGAIN_TIME_FORMAT), "day");
}

let isFalse: (value: boolean) => boolean = value => !value;
const KycPopupProvider: React.FC<KycPopupProviderProps> = ({ children }) => {
    const popups = useAppPopups();
    const history = useFantasyHistory();

    const { myProfile } = useMyProfile();
    const { UserState: { isNewUser } } = useSelector(store => store);

    const kycUnverifiedNotShowAgainTimeLocalStorage = storageHelper(`${myProfile?.id}_${LOCAL_STORAGE_KEYS.KYC_UNVERIFIED_NOT_SHOW_AGAIN_TIME}`);
    const kycCompletedShowedLocalStorage = storageHelper(`${myProfile.id}_${LOCAL_STORAGE_KEYS.KYC_COMPLETED_POPUP_SHOWED}`);
    const kycUnsuccessfulShowedLocalStorage = storageHelper(`${myProfile.id}_${LOCAL_STORAGE_KEYS.KYC_UNSUCCESSFUL_POPUP_SHOWED}`);

    const shouldShowUnverifiedPopupToday = !isSameDay(kycUnverifiedNotShowAgainTimeLocalStorage);
    const shouldShowUnsuccessfulPopupToday = !isSameDay(kycUnsuccessfulShowedLocalStorage);

    const [isShowUnverified, setIsShowUnverified] = useState(isUnverified(myProfile.kycStatus) && shouldShowUnverifiedPopupToday);
    const [isShowCompleted, setIsShowCompleted] = useState(isVerified(myProfile.kycStatus) && !kycCompletedShowedLocalStorage.get());
    const [isShowUnsuccessful, setIsShowUnsuccessful] = useState(isUnsuccessful(myProfile.kycStatus) && shouldShowUnsuccessfulPopupToday);
    const [isDoNotShowAgainChecked, setIsDoNotShowAgainChecked] = useState(true);

    const handleCloseUnsuccessful = () => {
        setIsShowUnsuccessful(false);
        if (isDoNotShowAgainChecked) {
            kycUnsuccessfulShowedLocalStorage.put(moment().format(KYC_NOT_SHOW_AGAIN_TIME_FORMAT));
        }
    };

    const handleCloseCompleted = () => {
        setIsShowCompleted(false);
        kycCompletedShowedLocalStorage.put(true);
    };

    const handleCloseUnverified = () => {
        setIsShowUnverified(false);
        if (isDoNotShowAgainChecked) {
            kycUnverifiedNotShowAgainTimeLocalStorage.put(moment().format(KYC_NOT_SHOW_AGAIN_TIME_FORMAT));
        }
    };

    useEffect(() => {
        if ([isShowUnverified, isShowCompleted, isShowUnsuccessful].every(isFalse)) {
            popups.done();
        }

        if (isNewUser) {
            popups.done();
        }
    }, [isShowUnverified, isShowCompleted, isShowUnsuccessful, isNewUser, popups]);

    const goToKycForm = () => history.push(ROUTER_PATH_CONFIG.verifyKyc.form);

    const context = useMemo(() => ({
        isShowUnverified,
        handleCloseUnverified,
        isDoNotShowAgainChecked,
        setIsDoNotShowAgainChecked,
        isShowCompleted,
        handleCloseCompleted,
        isShowUnsuccessful,
        handleCloseUnsuccessful,
        goToKycForm,
    }), [isShowUnverified, isShowCompleted, isShowUnsuccessful, isDoNotShowAgainChecked]);

    if (!myProfile || isNewUser) {
        return null;
    }

    return <KycPopupContext.Provider value={context}>
        {children}
    </KycPopupContext.Provider>;
};

export default KycPopupProvider;

export const useKycPopup = () => {
    const context = useContext(KycPopupContext);

    if (!context) {
        throw new Error("useKycPopup must be used within a KycPopupProvider");
    }

    return context;
};
