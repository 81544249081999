import { KycUnsuccessfulPopup } from "domain/popup/kyc/KycUnsuccessfulPopup";
import { KycUnverifiedPopup } from "domain/popup/kyc/KycUnverifiedPopup";
import { KycVerifiedPopup } from "domain/popup/kyc/KycVerifiedPopup";
import React from "react";
import FeatureConfigPopupWrapper from "../popupWrappers/FeatureConfigPopupWrapper";
import MainPagePopupWrapper from "../popupWrappers/MainPagePopupWrapper";
import KycPopupProvider from "./KycPopupProvider";


const KycPopup = () => {
    return <MainPagePopupWrapper>
        <FeatureConfigPopupWrapper getFeature={(config) => config.kyc}>
            <KycPopupProvider>
                <KycUnverifiedPopup />
                <KycVerifiedPopup />
                <KycUnsuccessfulPopup />
            </KycPopupProvider>
        </FeatureConfigPopupWrapper>
    </MainPagePopupWrapper>;
};

export default KycPopup;
