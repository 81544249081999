import { MyProfileVo } from "data/vo/user/MyProfileVo";
import { createContext, useContext } from "react";
import { FollowDto } from "../../../apis/user/UserApi";
import { UserProfileCareerStatsVO } from "../../../data/vo/user/UserProfileCareerStatsVO";
import { UserProfileVo } from "../../../data/vo/user/UserProfileVo";

export interface UserProfileContextProps {
    myProfile: MyProfileVo;
    userInfo: UserProfileVo;
    refetchUserInfo: () => Promise<any>;
    careerStatsList: UserProfileCareerStatsVO[];
    userFollow: FollowDto;
    isReachedFollowingLimit: boolean;
}

export const UserProfileContext = createContext<UserProfileContextProps>({} as UserProfileContextProps);

const useUserProfileContext = () => {
    return useContext(UserProfileContext);
};

export default useUserProfileContext;
