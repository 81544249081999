import styled from "styled-components";

export const ContestTypeSeparatorContainer = styled.div.attrs(() => ({
    className: "d-flex align-items-start py-3"
}))`
    gap: .8rem;
`;

export const ContestTypeSeparatorWrapper = styled.div.attrs(() => ({
    className: ""
}))``;

export const ContestTypeSeparatorIcon = styled.img.attrs(() => ({
    className: "d-block"
}))`
    width: 3rem;
    height: auto;
`;

export const ContestTypeSeparatorTitle = styled.h3.attrs(() => ({
    className: "fz-sf-bold-title-3"
}))`
    margin-bottom: 0;
`;


export const ContestTypeSeparatorSubtitle = styled.h4.attrs(() => ({
    className: "fz-sf-regular-caption-1 text-text-tertiary-1"
}))`
    margin-bottom: 0;
`;
