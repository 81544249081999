import { groupBy, orderBy } from "lodash";
import { ROLE } from "types/sports/Sport";
import { ASC, lineupComparator, roleComparator, SortingDirection } from "../../../helpers/SortingHelper";
import { SelectablePlayer } from "../../../types/player/Player";
import { BASKETBALL_ROLE } from "../../../types/sports/Basketball";
import { CRICKET_ROLE } from "../../../types/sports/Cricket";
import { FOOTBALL_ROLE } from "../../../types/sports/Football";

export const cricketOneClickChoosePlayer = (oldAllPlayers) => {
    const allPlayers = oldAllPlayers.map(player => {
        player.setSelected(false);
        return player;
    });

    const SC = allPlayers[0].squadCode;

    const WK = allPlayers.findIndex(({
                                         role,
                                         squadCode
                                     }) => role === CRICKET_ROLE.WICKET_KEEPER.apiValue && squadCode === SC);
    allPlayers[WK].setSelected(true);

    const AR = allPlayers.findIndex(({
                                         role,
                                         isSelected,
                                         squadCode
                                     }) => !isSelected && role === CRICKET_ROLE.ALL_ROUNDER.apiValue && squadCode === SC);

    allPlayers[AR].setSelected(true);

    for (let i = 0; i < 2; i++) {
        const BAT = allPlayers.findIndex(({
                                              role,
                                              isSelected,
                                              squadCode
                                          }) => !isSelected && role === CRICKET_ROLE.BATSMAN.apiValue && squadCode === SC);
        allPlayers[BAT].setSelected(true);
    }

    for (let i = 0; i < 2; i++) {
        const BAT = allPlayers.findIndex(({
                                              role,
                                              isSelected,
                                              squadCode
                                          }) => !isSelected && role === CRICKET_ROLE.BATSMAN.apiValue && squadCode !== SC);
        allPlayers[BAT].setSelected(true);
    }

    for (let i = 0; i < 3; i++) {
        const BOWL = allPlayers.findIndex(({
                                               role,
                                               isSelected,
                                               squadCode
                                           }) => !isSelected && role === CRICKET_ROLE.BOWLER.apiValue && squadCode !== SC);
        allPlayers[BOWL].setSelected(true);
    }

    for (let i = 0; i < 2; i++) {
        const BOWL = allPlayers.findIndex(({
                                               role,
                                               isSelected,
                                               squadCode
                                           }) => !isSelected && role === CRICKET_ROLE.BOWLER.apiValue && squadCode === SC);
        allPlayers[BOWL].setSelected(true);
    }

    return [...allPlayers];
};


export const footballOneClickChoosePlayer = (oldAllPlayers) => {
    const allPlayers = oldAllPlayers.map(player => {
        player.setSelected(false);
        return player;
    });

    const SC = allPlayers[0].squadCode;
    const GK = allPlayers.findIndex(({
                                         role,
                                         squadCode
                                     }) => role === FOOTBALL_ROLE.GOAL_KEEPER.apiValue && squadCode === SC);
    allPlayers[GK].setSelected(true);

    const FW = allPlayers.findIndex(({
                                         role,
                                         squadCode,
                                         isSelected
                                     }) => !isSelected && role === FOOTBALL_ROLE.FORWARD.apiValue && squadCode === SC);
    allPlayers[FW].setSelected(true);

    for (let i = 0; i < 2; i++) {
        const DF = allPlayers.findIndex(({
                                             role,
                                             isSelected,
                                             squadCode
                                         }) => !isSelected && role === FOOTBALL_ROLE.DEFENDER.apiValue && squadCode === SC);
        allPlayers[DF].setSelected(true);
    }

    for (let i = 0; i < 2; i++) {
        const DF = allPlayers.findIndex(({
                                             role,
                                             isSelected,
                                             squadCode
                                         }) => !isSelected && role === FOOTBALL_ROLE.DEFENDER.apiValue && squadCode !== SC);
        allPlayers[DF].setSelected(true);
    }

    for (let i = 0; i < 3; i++) {
        const MID = allPlayers.findIndex(({
                                              role,
                                              isSelected,
                                              squadCode
                                          }) => !isSelected && role === FOOTBALL_ROLE.MID_FIELDER.apiValue && squadCode !== SC);
        allPlayers[MID].setSelected(true);
    }

    for (let i = 0; i < 2; i++) {
        const MID = allPlayers.findIndex(({
                                              role,
                                              isSelected,
                                              squadCode
                                          }) => !isSelected && role === FOOTBALL_ROLE.MID_FIELDER.apiValue && squadCode === SC);
        allPlayers[MID].setSelected(true);
    }

    return [...allPlayers];
};

export const basketballOneClickChoosePlayer = (oldAllPlayers) => {
    const allPlayers = oldAllPlayers.map(player => {
        player.setSelected(false);
        return player;
    });

    const SC = allPlayers[0].squadCode;

    const C = allPlayers.findIndex(({
                                        role,
                                        squadCode
                                    }) => role === BASKETBALL_ROLE.CENTER.apiValue && squadCode === SC);

    allPlayers[C].setSelected(true);

    const PF = allPlayers.findIndex(({
                                         role,
                                         squadCode
                                     }) => role === BASKETBALL_ROLE.POWER_FORWARD.apiValue && squadCode === SC);

    allPlayers[PF].setSelected(true);

    const PG = allPlayers.findIndex(({
                                         role,
                                         isSelected,
                                         squadCode
                                     }) => !isSelected && role === BASKETBALL_ROLE.POINT_GUARD.apiValue && squadCode === SC);

    allPlayers[PG].setSelected(true);

    const _PG = allPlayers.findIndex(({
                                          role,
                                          isSelected,
                                          squadCode
                                      }) => !isSelected && role === BASKETBALL_ROLE.POINT_GUARD.apiValue && squadCode !== SC);

    allPlayers[_PG].setSelected(true);

    const SF = allPlayers.findIndex(({
                                         role,
                                         isSelected,
                                         squadCode
                                     }) => !isSelected && role === BASKETBALL_ROLE.SMALL_FORWARD.apiValue && squadCode === SC);

    allPlayers[SF].setSelected(true);

    const _SF = allPlayers.findIndex(({
                                          role,
                                          isSelected,
                                          squadCode
                                      }) => !isSelected && role === BASKETBALL_ROLE.SMALL_FORWARD.apiValue && squadCode !== SC);

    allPlayers[_SF].setSelected(true);


    const SG = allPlayers.findIndex(({
                                         role,
                                         isSelected,
                                         squadCode
                                     }) => !isSelected && role === BASKETBALL_ROLE.SHOOTING_GUARD.apiValue && squadCode === SC);

    allPlayers[SG].setSelected(true);

    const _SG = allPlayers.findIndex(({
                                          role,
                                          isSelected,
                                          squadCode
                                      }) => !isSelected && role === BASKETBALL_ROLE.SHOOTING_GUARD.apiValue && squadCode !== SC);

    allPlayers[_SG].setSelected(true);


    return allPlayers;
};

export const orderPlayers = (allPlayers: SelectablePlayer[], sortingKey: string, sortingDirection: SortingDirection) => orderBy(allPlayers, [lineupComparator, sortingKey, "name"], [ASC, sortingDirection, ASC]);


export const sortAndGroupPlayers = (allPlayers: SelectablePlayer[], sortingKey: string, sortingDirection: SortingDirection): {
    role: ROLE,
    players: SelectablePlayer[]
}[] => {
    const sortedPlayers = orderPlayers(allPlayers, sortingKey, sortingDirection);

    const roleToPlayers = groupBy(sortedPlayers, "role");

    const sortedRoles = orderBy<ROLE>(Object.keys(roleToPlayers) as ROLE[], roleComparator);

    return sortedRoles.map(role => ({ role: role, players: roleToPlayers[role] }));
};