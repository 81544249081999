import { useEffect, useState } from "react";


// This hook is used to detect if the tab is focused or browser is in focus.
export function useDetectTabFocus() {
    const [focus, setFocus] = useState(true);

    useEffect(() => {
        function setBlur() {
            setFocus(false);
        }

        function focus() {
            setFocus(true);
        }

        function visibleChanged() {
            if (document.visibilityState === "visible") {
                focus();
            } else {
                setBlur();
            }
        }

        window.addEventListener("blur", setBlur);
        window.addEventListener("focus", focus);
        window.addEventListener("visibilitychange", visibleChanged);

        return () => {
            window.removeEventListener("blur", setBlur);
            window.removeEventListener("focus", focus);
            window.removeEventListener("visibilitychange", visibleChanged);
        };
    }, []);

    return focus;
}
