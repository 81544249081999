import styled from "styled-components";
import { APP_MAX_WIDTH } from "../Constants";

export const FixedFooter = styled.div.attrs(() => ({
    className: "position-fixed d-flex"
}))`
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: calc(${APP_MAX_WIDTH});
  padding: 2rem 1.6rem 1.6rem 1.6rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) -7.74%, #FFFFFF 21.44%);
  gap: 0.8rem;
`;