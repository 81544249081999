import { fetchMatchSnapshot, fetchMyMatches, fetchUpcomingMatches } from "data/api/match/MatchApi";
import { MatchSnapshotVo } from "data/vo/match/MatchSnapshotVo";
import { MyMatchVo } from "data/vo/match/MyMatchVo";
import { UpcomingMatchVo } from "data/vo/match/UpcomingMatchVo";
import { SPORT } from "types/sports/Sport";
import { MyMatchesVo } from "data/vo/match/MyMatchesVo";

export class MatchRepository {
    public async getUpcomingMatches(sport: SPORT, kickoffDurationMilliseconds: number) {
        const upcomingMatchDtos = await fetchUpcomingMatches(sport);
        const now = new Date().getTime();

        return upcomingMatchDtos
            .filter(match => now <= match.matchStartsAt + kickoffDurationMilliseconds)
            .map(match => new UpcomingMatchVo(match, sport, now));
    }

    public async getMatchSnapshot(sport: SPORT, matchId: string) {
        const matchSnapshotDto = await fetchMatchSnapshot(sport, matchId);
        return new MatchSnapshotVo(matchSnapshotDto);
    }

    public async getMyMatches(): Promise<MyMatchesVo> {
        const matchSnapshotDto = await fetchMyMatches();

        const myMatches = matchSnapshotDto.map(myMatch => new MyMatchVo(myMatch));
        return MyMatchesVo.create(myMatches);
    }
}
