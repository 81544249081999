import PlayerAvatar from "components/image/PlayerAvatar";
import SquadLogo from "components/image/SquadLogo";
import { PlayerInfoVo } from "data/vo/player/PlayerInfoVo";
import { getSportConfig } from "domain/SportConfig";
import { calculateRestCreditCent, TEAM_CREDIT_CENT_LIMIT } from "domain/team/OperateTeamHelper";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import React from "react";
import { FormattedMessage } from "react-intl";
import { SelectablePlayer, SquadPlayer } from "types/player/Player";
import {
    AvatarWrapper,
    AwayRoleText,
    CreditWrapper,
    HomeRoleText,
    PlayerInfoDialogCardHeader,
    SelectRate,
    SquadLogoWrapper,
    SquadPlayerInfoWrapper
} from "./PlayerInfoDialogStyle";

const squadAPlayerColor = {
    title: "text-text-secondary-1",
    maxPlayerCount: "text-text-secondary-1",
    highlight: "text-text-primary-1",
    selectRateTitle: "text-text-secondary-1",
    selectRate: "text-text-tertiary-1"
};
const squadBPlayerColor = {
    title: "text-dark-secondary",
    maxPlayerCount: "text-dark-tertiary",
    highlight: "text-dark-primary",
    selectRateTitle: "text-dark-primary",
    selectRate: "text-dark-secondary"
};
const SquadPlayerInfo = ({ player, profile, children }) => {
    const {
        selectRateTitle,
        selectRate
    } = player.isHome ? squadAPlayerColor : squadBPlayerColor;

    return <SquadPlayerInfoWrapper className="pt-3">
        {children}
        <div className="d-flex gap-3">
            <div>
                <SelectRate className={`${selectRateTitle} me-2`}>%Sel</SelectRate>
                <SelectRate className={selectRate}>{divideByOneHundred(profile.playerSelectionRate)}</SelectRate>
            </div>
            <div>
                <SelectRate className={`${selectRateTitle} me-2`}>%C</SelectRate>
                <SelectRate className={selectRate}>{divideByOneHundred(profile.captainSelectionRate)}</SelectRate>
            </div>
            <div>
                <SelectRate className={`${selectRateTitle} me-2`}>%VC</SelectRate>
                <SelectRate
                    className={selectRate}>{divideByOneHundred(profile.viceCaptainSelectionRate)}</SelectRate>
            </div>
        </div>
    </SquadPlayerInfoWrapper>;
};

export interface PlayerInfoDialogHeaderProps {
    player: SquadPlayer,
    playerInfo: PlayerInfoVo | null | undefined,
    players: SquadPlayer[],
}

const PlayerInfoDialogHeader: React.FC<{
    children: React.ReactNode,
    player: SquadPlayer,
    playerInfo: PlayerInfoVo | null | undefined,
}> = ({
          player,
          playerInfo,
          children
      }) => {
    const { team } = getSportConfig(player.sport);
    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();

    const playerRoleAbbr = team.getRoleAbbr(player.role);

    const playerCredits = playerInfo?.profile.creditCent
        ? toFormattedNumber(divideByOneHundred(playerInfo?.profile.creditCent), 1)
        : 0;

    return <PlayerInfoDialogCardHeader
        className={player.isHome ? "bg-default-secondary-1" : "bg-default-primary-2"}>
        <AvatarWrapper>
            <PlayerAvatar id={player.id} url={player.avatar}
            />
        </AvatarWrapper>
        {children}
        <SquadLogoWrapper>
            <SquadLogo key={player.squadLogoUrl} url={player.squadLogoUrl} name={player.squadCode} />
        </SquadLogoWrapper>
        <CreditWrapper className={player.isHome ? "bg-opacity-tertiary-2" : "bg-opacity-tertiary-1"}>
            {playerCredits}
        </CreditWrapper>
        {
            player.isHome
                ? <HomeRoleText>{playerRoleAbbr}</HomeRoleText>
                : <AwayRoleText>{playerRoleAbbr}</AwayRoleText>
        }
    </PlayerInfoDialogCardHeader>;
};

export const PlayerInfoDialogCreditHeader: React.FC<PlayerInfoDialogHeaderProps> = ({
                                                                                        player,
                                                                                        playerInfo,
                                                                                        players
                                                                                    }) => {
    const maxPlayerCount = getSportConfig(player.sport).team.maxPlayerCount;
    const selectedPlayers = players.filter(player => player instanceof SelectablePlayer ? player.isSelected : true);


    const {
        title,
        maxPlayerCount: maxPlayerCountColor,
        highlight,
    } = player.isHome ? squadAPlayerColor : squadBPlayerColor;

    const restCreditCent = calculateRestCreditCent(TEAM_CREDIT_CENT_LIMIT, selectedPlayers);
    return <PlayerInfoDialogHeader player={player} playerInfo={playerInfo}>
        {
            playerInfo && <SquadPlayerInfo player={player}
                                           profile={playerInfo.profile}>
                <div className="d-flex gap-3">
                    <div>
                        <div className={`${title} fz-sf-regular-caption-2 mb-2`}>
                            <FormattedMessage id="label_players" />
                        </div>
                        <div className={`${maxPlayerCountColor} fz-sf-regular-footnote text-center`}>
                            <span
                                className={`${highlight} fz-sf-bold-title-2`}>{selectedPlayers.length}</span>/{maxPlayerCount}
                        </div>
                    </div>
                    <div>
                        <div className={`${title} fz-sf-regular-caption-2 mb-2`}>
                            <FormattedMessage id="label_credits" />
                        </div>
                        <div className={`${highlight} fz-sf-bold-title-2 text-center`}>
                            {divideByOneHundred(restCreditCent)}
                        </div>
                    </div>
                </div>
            </SquadPlayerInfo>
        }
    </PlayerInfoDialogHeader>;
};

export const PlayerInfoDialogPointHeader: React.FC<PlayerInfoDialogHeaderProps> = ({ player, playerInfo }) => {
    const {
        title,
        highlight,
    } = player.isHome ? squadAPlayerColor : squadBPlayerColor;

    return <PlayerInfoDialogHeader player={player} playerInfo={playerInfo}>
        {
            playerInfo && <SquadPlayerInfo player={player}
                                           profile={playerInfo.profile}>
                <div className="d-flex gap-3">
                    <div>
                        <div className={`${title} fz-sf-regular-caption-2 mb-2`}>
                            <FormattedMessage id="label_score" />
                        </div>
                        <div className={`${highlight} fz-sf-bold-title-2 text-center`}>
                            {divideByOneHundred(playerInfo.profile.pointAvgCent)}
                        </div>
                    </div>
                </div>
            </SquadPlayerInfo>
        }
    </PlayerInfoDialogHeader>;
};

export enum PlayerInfoDialogHeaderType {
    CREDIT = "credit",
    POINT = "point"
}

export const PlayerInfoDialogHeaderMap = {
    [PlayerInfoDialogHeaderType.CREDIT]: PlayerInfoDialogCreditHeader,
    [PlayerInfoDialogHeaderType.POINT]: PlayerInfoDialogPointHeader
};
