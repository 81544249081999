import { cn } from "../../helpers/cn";
import React from "react";

export enum MENU_ITEM_VARIANT {
    default = "default",
    selected = "selected",
}

interface MenuItemProps {
    variant: MENU_ITEM_VARIANT,
    label: string,
    onClick: () => void,
}

export const MenuItem: React.FC<MenuItemProps> = (props) => {
    const classNames = {
        [MENU_ITEM_VARIANT.default]: "tw-bg-grey-150 tw-text-grey-1000",
        [MENU_ITEM_VARIANT.selected]: "tw-bg-primary-500 tw-text-grey-0",
    };

    return <div
        className={cn(
            "tw-px-[1.6rem] tw-py-[1.2rem]",
            "tw-flex tw-justify-center tw-items-center",
            "tw-text-subtitle-1 tw-text-center",
            "tw-cursor-pointer hover:tw-bg-primary-700 hover:tw-text-grey-0",
            classNames[props.variant] ?? "",
        )}
        onClick={props.onClick}>
        {props.label}
    </div>;
};