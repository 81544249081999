import { MatchRepository } from "data/repository/match/MatchRepository";
import { UpcomingMatchVo } from "data/vo/match/UpcomingMatchVo";
import { storageHelper } from "helpers/localStorage/LocalStorageHelper";
import { LOCAL_STORAGE_KEYS } from "helpers/localStorage/types";
import { isMatchPreparing } from "helpers/match/MatchHelper";
import { getSkippedTournaments, matchesToTournaments, Tournament } from "helpers/TournamentHelper";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import { throttle } from "lodash";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useSelector } from "store";
import { RootStoreState } from "store/reducer";
import { ContestRepository } from "../../data/repository/contest/ContestRepository";
import { FeaturedContestVo } from "../../data/vo/contest/FeaturedContestVo";

interface HomeContextProps {
    isTournamentFilterDialogShow: boolean;
    tournaments: Tournament[];
    filteredMatches: UpcomingMatchVo[] | null;
    isTournamentSelectAll: boolean;
    featuredContests?: FeaturedContestVo[];
    isFeaturedContestsLoading?: boolean;
    updateFeaturedContests: () => void;
    updateData: () => void;
    handleFilterTournament: (data: { skippedTournaments: Tournament[]; isPreparingMatchesShowed: boolean; }) => void;
    handleTournamentFilterDialogClose: () => void;
    handleTournamentFilterDialogOpen: () => void;
}

const HomeContext = React.createContext<HomeContextProps | null>(null);

interface HomeProviderProps {
    children: React.ReactNode;
}

export const HomeProvider: React.FC<HomeProviderProps> = ({ children }) => {
    const { SportState: { currentSport }, SettingsState } = useSelector((store: RootStoreState) => store);
    const { match: { kickoffDurationMilliseconds } } = SettingsState;

    const skipUpcomingTournamentsStorage = storageHelper(LOCAL_STORAGE_KEYS.SKIP_UPCOMING_TOURNAMENTS, currentSport);
    const preparingStorage = storageHelper(LOCAL_STORAGE_KEYS.SHOW_PREPARING, currentSport);

    const [isTournamentFilterDialogShow, setIsTournamentFilterDialogShow] = useState(false);

    const matchRepository = new MatchRepository();
    const contestRepository = new ContestRepository();
    const upcomingMatches = useFantasyQuery([QUERY_KEY.UPCOMING_MATCHES], () => matchRepository.getUpcomingMatches(currentSport, kickoffDurationMilliseconds));
    const featuredContests = useFantasyQuery([QUERY_KEY.FEATURED_CONTESTS], () => contestRepository.getFeaturedContests(currentSport));

    const tournaments = useMemo(() => {
        if (!upcomingMatches.data) return [];

        if (upcomingMatches.isFetching) return [];

        return matchesToTournaments(upcomingMatches.data, skipUpcomingTournamentsStorage.get());
    }, [upcomingMatches.data, upcomingMatches.isFetching, skipUpcomingTournamentsStorage]);

    const filteredMatches = useMemo(() => {
        const skippedIds = getSkippedTournaments(tournaments);

        if (!upcomingMatches.data) return null;

        if (upcomingMatches.isFetching) return null;

        return upcomingMatches.data
            .filter(match => (preparingStorage.get() ?? true) || !isMatchPreparing(match.matchStatus))
            .filter(match => !skippedIds.includes(match.tournamentId));
    }, [upcomingMatches.data, upcomingMatches.isFetching, tournaments, preparingStorage]);

    const isTournamentSelectAll = filteredMatches?.length === upcomingMatches.data?.length;

    const handleTournamentFilterDialogClose = useCallback(() => {
        setIsTournamentFilterDialogShow(false);
    }, []);

    const handleTournamentFilterDialogOpen = useCallback(() => {
        setIsTournamentFilterDialogShow(true);
    }, []);

    const handleFilterTournament = useCallback(({ skippedTournaments, isPreparingMatchesShowed }) => {
        skipUpcomingTournamentsStorage.put(getSkippedTournaments(skippedTournaments));
        preparingStorage.put(isPreparingMatchesShowed);
        handleTournamentFilterDialogClose();
    }, [skipUpcomingTournamentsStorage, preparingStorage, handleTournamentFilterDialogClose]);

    const throttledUpdateFeaturedContests = useMemo(() => throttle(featuredContests.refetch, 5 * 1000), [featuredContests.refetch]);

    const value = useMemo(() => ({
        isTournamentFilterDialogShow,
        tournaments,
        filteredMatches,
        isTournamentSelectAll,
        featuredContests: featuredContests.data,
        isFeaturedContestsLoading: featuredContests.isFetching,
        updateFeaturedContests: throttledUpdateFeaturedContests,
        updateData: upcomingMatches.refetch,
        handleFilterTournament,
        handleTournamentFilterDialogClose,
        handleTournamentFilterDialogOpen,
    }), [
        isTournamentFilterDialogShow,
        tournaments,
        filteredMatches,
        isTournamentSelectAll,
        featuredContests.data,
        featuredContests.isFetching,
        throttledUpdateFeaturedContests,
        upcomingMatches.refetch,
        handleFilterTournament,
        handleTournamentFilterDialogClose,
        handleTournamentFilterDialogOpen,
    ]);

    return <HomeContext.Provider value={value}>
        {children}
    </HomeContext.Provider>;
};

export const useHomeProvider = () => {
    const context = useContext(HomeContext);

    if (!context) {
        throw new Error("useHomeProvider must be used within a HomeContext");
    }

    return context;
};
