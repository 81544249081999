import { createContext, useContext, useEffect, useMemo } from "react";
import useFantasyHistory from "hooks/useFantasyHistory";
import useUpdateWallet from "domain/wallet/useUpdateWallet";
import { useWithdrawSettings } from "domain/withdraw/hooks/useWithdrawSettings";
import { useStep } from "domain/withdraw/hooks/useStep";
import {
    FORM_FIELD_ACCOUNT_BANK,
    FORM_FIELD_ACCOUNT_NAME,
    FORM_FIELD_ACCOUNT_NUMBER,
    useWithdrawStep1Form
} from "domain/withdraw/hooks/useWithdrawStep1Form";
import { FORM_FIELD_AMOUNT, useWithdrawStep2Form } from "domain/withdraw/hooks/useWithdrawStep2Form";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import { Callback } from "Common";
import { UseFormReturn } from "react-hook-form";
import { Bank } from "data/vo/payment/banksVo";
import { SendToInfo } from "domain/withdraw/types";
import { WithdrawAmountInfoVo } from "domain/withdraw/WithdrawAmountInfoVo";
import { WithdrawCurrentStepVo } from "domain/withdraw/WithdrawCurrentStepVo";
import { PaymongoWithdrawRepository } from "data/repository/withdraw/PaymongoWithdrawRepository";

interface WithdrawContextState {
    step1Form: UseFormReturn<{
        [FORM_FIELD_ACCOUNT_NAME]: string
        [FORM_FIELD_ACCOUNT_BANK]: string
        [FORM_FIELD_ACCOUNT_NUMBER]: string
    }, any, undefined>;

    step2Form: UseFormReturn<{
        [FORM_FIELD_AMOUNT]: number
    }, any, undefined>;

    banks: Bank[]
    selectedBank: string
    withdrawSettings: {
        transactionFee: number
        minimumWithdrawAmount: number
    }

    sendToInfo: SendToInfo
    withdrawAmountInfos: WithdrawAmountInfoVo

    currentStep: WithdrawCurrentStepVo
    handleStepNext: Callback
    handleGoToStep: (step: number) => void
    handleGoToFirstStep: () => void
    handleGoBack: Callback
}

const WithdrawContext = createContext<WithdrawContextState | null>(null);

interface WithdrawProviderProps {
    children: React.ReactNode;
}

export const WithdrawProvider: React.FC<WithdrawProviderProps> = ({ children }) => {
    const history = useFantasyHistory();

    const updateWallet = useUpdateWallet();
    const withdrawSettings = useWithdrawSettings();

    const { step, setStep, handleStepNext, handleStepBack } = useStep(WithdrawCurrentStepVo.firstStep);
    const currentStep = new WithdrawCurrentStepVo(step);

    const step1Form = useWithdrawStep1Form();
    const step2Form = useWithdrawStep2Form();

    useEffect(() => {
        updateWallet();
    }, []);

    function handleGoBack() {
        if (currentStep.isFinalStep() || currentStep.isFirstStep()) {
            history.goBack();
            return;
        }
        handleStepBack();
    }

    const paymongoReceivingInstitutions = useFantasyQuery([QUERY_KEY.PAYMONGO_RECEIVING_INSTITUTIONS], new PaymongoWithdrawRepository().getPayMongoReceivingInstitutions);
    const banks = paymongoReceivingInstitutions.data?.banks ?? [];
    const selectedBank = banks.find(bank => bank.code === step1Form.watch(FORM_FIELD_ACCOUNT_BANK))?.bank || "";

    const sendToInfo: SendToInfo = {
        accountName: step1Form.watch(FORM_FIELD_ACCOUNT_NAME),
        accountNumber: step1Form.watch(FORM_FIELD_ACCOUNT_NUMBER),
        selectedBank: selectedBank,
    };
    const withdrawAmountInfos = new WithdrawAmountInfoVo(step2Form.watch(FORM_FIELD_AMOUNT), withdrawSettings.transactionFee);

    function handleGoToFirstStep() {
        setStep(WithdrawCurrentStepVo.firstStep);
    }

    const context = useMemo<WithdrawContextState>(() => ({
        step1Form,
        step2Form,
        currentStep: currentStep,
        banks,
        selectedBank,
        sendToInfo,
        withdrawAmountInfos,
        withdrawSettings,
        handleGoBack,
        handleGoToStep: setStep,
        handleGoToFirstStep,
        handleStepNext
    }), [
        step,
        currentStep,
        step1Form,
        step2Form,
        banks,
        sendToInfo,
        withdrawAmountInfos,
        selectedBank,
        withdrawSettings,
        handleStepBack,
        handleGoToFirstStep,
        handleStepNext
    ]);

    return <WithdrawContext.Provider value={context}>
        {children}
    </WithdrawContext.Provider>;
};

export const useWithdrawContext = () => {
    const context = useContext(WithdrawContext);

    if (!context) {
        throw new Error("useWithdrawProvider must be used within a WithdrawContext");
    }

    return context;
};

export const withWithdrawProvider = <TProps extends React.PropsWithChildren>(Component: React.FC<TProps>) => {
    return (props: TProps) => {
        return (
            <WithdrawProvider>
                <Component {...props} />
            </WithdrawProvider>
        );
    };
};
