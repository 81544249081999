import React from "react";
import { FormattedMessage } from "react-intl";
import MySlateNavTitle from "../../components/MySlateNavTitle";
import Prizes from "../../components/Prizes";
import TopNavbar, { TopNavbarType } from "../../components/TopNavbar";
import { useMySlate } from "../../providers/MySlateProvider";

interface MySlateLeaderboardProps {
    onTogglePage: () => void;
}

const MyNineCatLeaderboard: React.FC<MySlateLeaderboardProps> = ({ onTogglePage }) => {
    const { nineCatDetail, contestDetail, contestEntry } = useMySlate();

    return (
        <div className="tw-bg-grey-50 tw-h-full">
            <TopNavbar
                type={TopNavbarType.LIGHT}
                onLeaveClick={onTogglePage}
                title={
                    <MySlateNavTitle
                        contestDetail={contestDetail}
                        contestEntry={contestEntry}
                    />
                }
                slate={nineCatDetail}
            />
            <div className="tw-p-6">
                <div className="tw-mb-6">
                    <div className="tw-text-subtitle-1 tw-font-bold tw-text-grey-1000 tw-mb-2">
                        <FormattedMessage id="contest_detail_page_leaderboard_label_winning_prize_details" />
                    </div>
                    <Prizes prizes={contestDetail!.prizeList} contestEntry={contestEntry} />
                </div>

                {/*<div>*/}
                {/*    <div className="tw-text-subtitle-1 tw-font-bold tw-text-grey-1000 tw-mb-2">Leaderboard</div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default MyNineCatLeaderboard;
