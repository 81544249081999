import { Icon, practicePrizeImg } from "helpers/IconHelper";
import { FormattedMessage } from "react-intl";
import { PrizeListVo } from "../../../data/vo/PrizeList/PrizrListVo";
import useCurrency from "../../../hooks/useCurrency/useCurrency";
import { ContestEntry } from "../helpers/helpers";

interface PrizesProps {
    prizes: PrizeListVo[],
    contestEntry: ContestEntry,
}

const Prizes: React.FC<PrizesProps> = ({
                                           prizes,
                                           contestEntry
                                       }) => {

    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();

    const renderRank = (rank: number) => {
        if (rank === 1) {
            return <div
                className="tw-w-[2.6rem] tw-h-[2.6rem] tw-bg-grey-150 tw-rounded-full tw-flex tw-items-center tw-justify-center">
                <Icon.Crown />
            </div>;
        }

        if (rank === 2 || rank === 3) {
            return <div className="tw-w-[2.6rem] tw-h-[2.6rem] tw-relative tw-flex tw-items-center tw-justify-center">
                <div
                    className="tw-absolute tw-h-10 tw-w-[1px] tw-left-1/2 tw-top-1/2 tw-transfor tw--translate-x-1/2 tw--translate-y-1/2 tw-bg-grey-150"></div>
                <div
                    className="tw-w-4 tw-h-4 tw-bg-grey-150 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-text-primary-800 tw-relative tw-z-1">
                    {rank}
                </div>
            </div>;


        }

        return <div className="tw-w-[2.6rem] tw-h-[2.6rem] tw-relative tw-flex tw-items-center tw-justify-center">
            <div
                className="tw-absolute tw-h-10 tw-w-[1px] tw-left-1/2 tw-top-1/2 tw-transfor tw--translate-x-1/2 tw--translate-y-1/2 tw-bg-grey-150"></div>
            <div className="tw-w-2.5 tw-h-2.5 tw-bg-grey-150 tw-rounded-full tw-relative tw-z-1"></div>
        </div>;
    };

    const renderRanks = (from: number, to: number) => {
        if (from === to) return `No.${from}`;

        return `No.${from} - No.${to}`;
    };

    const renderPrize = (prize: number, from: number, to: number) => {
        if (from === to) {
            return toFormattedNumber(prize);
        }

        return <FormattedMessage
            id={"label_each"}
            values={{
                eachPrize: toFormattedNumber(prize)
            }} />;
    };

    if (contestEntry.isPractice()) {
        return (
            <div className={"tw-flex tw-flex-col tw-items-center tw-gap-6"}>
                <img
                    src={practicePrizeImg}
                    alt="Practice Prize"
                    className="tw-w-[18rem] tw-h-auto"
                    data-testid="practice-prize-img"
                />
                <p className="tw-text-subtitle-2 tw-text-grey-1000">
                    <FormattedMessage id="contest_detail_page_ranking_label_free_contest_ranking" />
                </p>
            </div>
        );
    }

    return (
        <div className="tw-flex tw-gap-3 tw-w-full">
            <div className="tw-flex-1 tw-flex tw-gap-1.5 tw-flex-col tw-overflow-hidden">
                {
                    prizes.map((prize, index) => (
                        <div key={index} className="tw-flex tw-items-center tw-gap-3">
                            {renderRank(index + 1)}
                            <div
                                className="tw-flex tw-items-center tw-bg-grey-100 tw-rounded tw-px-3 tw-py-1 tw-justify-between tw-flex-1"
                            >
                            <span className='tw-text-primary-800 tw-text-caption-1'>
                                {renderRanks(prize.from, prize.to)}
                            </span>
                                <div
                                    className='tw-text-warning-1000 tw-flex tw-items-center tw-gap-1 tw-text-caption-1'>
                                    {renderPrize(prize.prize, prize.from, prize.to)}
                                    {
                                        contestEntry.isRuby()
                                            ? <Icon.PrizeRuby data-testid="prize-ruby-icon" />
                                            : <Icon.PrizeCoin data-testid="prize-coin-icon" />
                                    }
                                </div>

                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default Prizes;
