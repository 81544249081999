import styled, { css } from "styled-components";

export const ProgressBarWrapper = styled.div.attrs(() => ({
    className: "position-relative mx-auto"
}))`
  width: 100%;
  max-width: 7rem;
  height: 7rem;
`;
export const JoinableButton = styled.button.attrs<{
    $boxShadow: string,
    $joinButtonBackground: string
}>(({ $boxShadow, $joinButtonBackground }) => ({
    className: `position-absolute rounded-circle d-flex flex-column justify-content-center align-items-center ${$boxShadow} ${$joinButtonBackground}`
}))`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5.6rem;
  height: 5.6rem;
  outline: none;
  border: none;
`;

export const ProgressBar = styled.div.attrs<{
    $progressBar: string,
    $ratio: number
}>(({ $progressBar }) => ({
    className: `rounded-circle ${$progressBar}`
}))`

    width: 100%;
    height: 100%;

    ${({ $ratio }) => {
    const deg = Math.floor($ratio * 360);
    return css`
      --deg: ${deg}deg;
    `;
}}
`;
export const EntryFeeTitle = styled.div.attrs<{
    $textColorClassName: string
}>(({ $textColorClassName }) => ({
    className: `fz-sf-regular-caption-2 text-center ${$textColorClassName}`
}))`
`;
export const EntryFeeText = styled.div.attrs<{
    $textColorClassName: string
}>(({ $textColorClassName }) => ({
    className: `text-center ${$textColorClassName}`
}))`
`;

export const CancelledProgressbar = styled.div.attrs({
    className: "rounded-circle d-flex justify-content-center align-items-center tw-bg-system-gray100"
})`
  width: 100%;
  height: 100%;
`;
export const CancelledText = styled.div.attrs({
    className: "fz-sf-bold-subhead"
})`
  color: var(--white);
`;

