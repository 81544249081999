import { ContestCardInfo } from "domain/match/pages/upcoming/contest/card/ContestCardStyle";
import styled from "styled-components";


export const Tag = styled.div.attrs({
    className: "d-flex align-items-center"
})`
  gap: 2px;

  .svg-icon {
    width: 1.6rem;
    height: 1.6rem;
  }

  &:not(:last-child) {
    margin-right: 1.2rem;
  }
`;

export const TagValue = styled.div.attrs(() => ({
    className: "fz-sf-regular-footnote text-text-tertiary-1"
}))`
`;

export const TagWrapper = styled(ContestCardInfo).attrs(() => ({
    className: "d-flex"
}))`

`;