import { Col } from "react-bootstrap";
import { IoChevronBack } from "react-icons/io5";
import styled, { css } from "styled-components";
import { APP_MAX_WIDTH } from "styles/Constants";

interface NavbarWrapperProps {
    $transparent: boolean;
    $headerLine: boolean;
    $constantHeight: string | null;
    $bg: string;
    $bgImgPosition: string;
    $backgroundOpacity: number;
}

export const NavbarWrapper = styled.div.attrs<NavbarWrapperProps>(({
                                                                       $headerLine,
                                                                   }) => ({
    className: `navbar-wrapper ${$headerLine ? "header-line" : ""}`
}))`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: ${({ $constantHeight }) => $constantHeight ? $constantHeight : "initial"};
    max-width: ${APP_MAX_WIDTH};
    z-index: 3;
    opacity: ${({ $backgroundOpacity }) => $backgroundOpacity};

    ${({ $transparent }) => {
    if (!$transparent) {
        return css`
                backdrop-filter: blur(10px);
            `;
    }
}}

    ${({ $bg, $bgImgPosition }) => {
    if ($bg) {
        return css`
                background-image: url(${$bg});
                background-position: ${$bgImgPosition};
                background-repeat: no-repeat;
                background-size: 100% auto;
            `;
    }
}}
`;

interface NavbarContainerProps {
    $transparent: boolean;
}

export const NavbarContainer = styled.div.attrs<NavbarContainerProps>(
    ({ $transparent }) => ({
        className: `navbar-container px-4  ${$transparent ? "" : "header-bg-normal"}`
    }))`
`;

export const NavbarLine = styled.div.attrs(() => ({
    className: "position-relative navbar-line d-flex justify-content-between flex-nowrap mx-0"
}))`
    padding: 1rem 0;
`;

export const NavbarStart = styled.div.attrs(() => ({
    className: "position-absolute d-flex justify-content-start align-items-center font-weight-bold top-0 h-100 z-2"
}))``;

export const NavbarCenter = styled.div.attrs(() => ({
    className: "navbar-center w-100"
}))`
    min-height: 2.4rem;
`;

export const NavbarBottom = styled.div.attrs(() => ({
    className: "navbar-center w-100"
}))``;

export const NavbarEnd = styled.div.attrs(() => ({
    className: "position-absolute d-flex justify-content-end align-items-center top-0 end-0 h-100 z-2"
}))``;


export const NavbarBack = styled(IoChevronBack)
    .attrs<{
        $color: string
    }>(({ $color }) => ({
        className: `${$color ? $color : "text-text-primary-1"}`
    }))`
    font-size: 2.4rem;
    cursor: pointer;
`;

export const JoinableNavbarWrapper = styled.div.attrs(() => ({
    className: "my d-flex align-items-center w-100"
}))`
    padding: 0.8rem 0;
`;
export const MatchInfoWrapper = styled.div.attrs(() => ({
    className: "my-info d-flex justify-content-center align-items-center flex-nowrap"
}))`
    margin-left: .2rem;
    margin-right: .2rem;
    gap: .8rem;
`;
export const SquadCode = styled.div.attrs<{
    $highlight?: boolean
}>(({ $highlight = true }) => ({
    className: `fz-sf-bold-body-1 ${$highlight ? "text-text-primary-1" : "text-text-tertiary-1"}`
}))`
`;
export const VSText = styled.div.attrs(() => ({
    className: "text-center px-0 fz-sf-regular-footnote text-text-primary-1"
}))`
`;
export const StyledCountDown = styled(Col).attrs(() => ({
    className: "text-nowrap text-center fz-sf-regular-caption-1 text-sys-danger-1"
}))`
    min-height: 1.8rem;
`;
