import { useMutation } from "@tanstack/react-query";
import { ApiError } from "apis/Api";
import useHandleApiError from "hooks/useHandleApiError";
import { Setting } from "../useFantasyQuery/type";

const initSetting = {
    onFail: undefined,
    onFinally: undefined,
    options: undefined,
    errorHandle: true
};

const useFantasyCommand = <request = void, response = void, T = any>(command: (request: request) => Promise<response>, setting: Setting<T> = initSetting) => {
    const { onFail, onFinally, errorHandle = true } = setting;
    const handleApiError = useHandleApiError();
    return useMutation<response, unknown, request>(
        command,
        {
            onError: errorHandle
                ? async (e) => {
                    await handleApiError(e as ApiError, onFail);
                }
                : undefined,
            onSettled: onFinally
        }
    );

};

export default useFantasyCommand;
