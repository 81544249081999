import { emptyCouponImg } from "helpers/IconHelper";
import { FormattedMessage } from "react-intl";
import { EmptyCouponImageWrapper } from "./CouponStyle";

const EmptyCoupon = () => {
    return <>
        <EmptyCouponImageWrapper>
            <img src={emptyCouponImg} alt="empty-coupon" />
        </EmptyCouponImageWrapper>
        <div className="text-center fz-sf-regular-subhead text-text-primary-1">
            <FormattedMessage id="my_coupon_page_label_empty_coupons" />
        </div>
    </>;
};

export default EmptyCoupon;
