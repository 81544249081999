import styled from "styled-components";
import { APP_MAX_WIDTH } from "../../styles/Constants";

export const LoadingWrap = styled.div.attrs(() => ({
    className: "position-fixed w-100 h-100 d-flex justify-content-center align-items-center flex-column text-white"
}))`
    max-width: ${APP_MAX_WIDTH};
    top: 0;
    left: 0;
    z-index: 999999;
    background-color: var(--black-80)
`;
