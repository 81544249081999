import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import Portal from "../../../../components/portal/Portal";
import {
    ButtonWrapper,
    CancelButton,
    ConfirmButton,
    DetailOrderList,
    DetailRow,
    DetailWrapper,
    Methods,
    NoticeModal,
    NoticeModalBody,
    NoticeModalOverlay,
    NoticeWrapper,
    Title,
    WarningIcon,
    WarningText,
    WarningWrapper
} from "./PaymentNoticeModalStyle";

const warningIcon = "/images/warning_icon.png";

const PaymentNoticeModal = ({ open = false, setOpen, methods, onConfirm }) => {
    return open ? <Portal>
            <NoticeWrapper onClick={() => setOpen(false)}>
                <NoticeModalOverlay />
                <NoticeModal onClick={e => e.stopPropagation()}>
                    <NoticeModalBody>
                        <Title><FormattedMessage id="payment_notice_modal_title" /></Title>
                        <DetailWrapper>
                            <DetailOrderList>
                                <DetailRow>
                                    <FormattedMessage id="payment_notice_modal_rule_1_1" />
                                    &nbsp;<Methods>{methods.join(", ")} </Methods>&nbsp;
                                    <FormattedMessage id="payment_notice_modal_rule_1_2" />
                                </DetailRow>
                                <DetailRow><FormattedMessage id="payment_notice_modal_rule_2" /></DetailRow>
                                <DetailRow><FormattedMessage id="payment_notice_modal_rule_3" /></DetailRow>
                                <DetailRow><FormattedMessage id="payment_notice_modal_rule_4" /></DetailRow>
                                <DetailRow><FormattedMessage id="payment_notice_modal_rule_5" /></DetailRow>
                                <DetailRow><FormattedMessage id="payment_notice_modal_rule_6" /></DetailRow>
                            </DetailOrderList>
                        </DetailWrapper>
                        <WarningWrapper>
                            <WarningIcon src={warningIcon} />
                            <WarningText><FormattedMessage id="payment_notice_modal_warning" /></WarningText>
                        </WarningWrapper>
                        <ButtonWrapper>
                            <ConfirmButton onClick={onConfirm}><FormattedMessage
                                id="dialog_button_understand" /></ConfirmButton>
                            <CancelButton onClick={() => setOpen(false)}><FormattedMessage
                                id="label_cancel" /></CancelButton>
                        </ButtonWrapper>
                    </NoticeModalBody>
                </NoticeModal>
            </NoticeWrapper>
        </Portal>
        : null;
};


export default PaymentNoticeModal;

PaymentNoticeModal.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired
};
