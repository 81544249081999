import { cn } from "helpers/cn";
import useFantasyConfig from "hooks/useFantasyConfig";
import React from "react";
import { CURRENCY_ICON_SUIT_NAME } from "store/reducer/config/FantasyGameConfigState";

export type CurrencyIconSuit = Record<CURRENCY_ICON_SUIT_NAME, React.ReactNode>;

interface AmountDisplayProps {
    currencyIconSuit: CurrencyIconSuit;
    children: React.ReactNode;
    className?: string;
}

const AmountDisplay: React.FC<AmountDisplayProps> = ({
                                                         children,
                                                         currencyIconSuit,
                                                         className = "",
                                                     }) => {

    const { featureConfig: { currencyIconSuitName } } = useFantasyConfig();

    const icon = currencyIconSuit[currencyIconSuitName] || currencyIconSuit[CURRENCY_ICON_SUIT_NAME.DEFAULT];

    return (
        <div
            className={cn("tw-flex tw-items-center tw-justify-center tw-gap-0.5 tw-text-grey-850 tw-leading-none", className)}>
            {icon}
            {children}
        </div>
    );
};

export default AmountDisplay;

