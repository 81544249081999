import { AxiosError } from "axios";
import useHandleApiError, { ERROR_HANDLER_FLOW } from "hooks/useHandleApiError";
import React, { useCallback, useMemo } from "react";
import { ApiExecuteContext, ApiExecutor, Callbacks } from "./ApiContext";
import { ERROR_CODE } from "helpers/ErrorHelper";

export interface ApiError extends AxiosError<ApiErrorData> {
}

interface ApiErrorData {
    readonly errorCode: ERROR_CODE | null;
}

const Api = ({ children }) => {
    const handleApiError = useHandleApiError();

    const defaultCallbacks = useMemo<Callbacks>(() => ({
        onSuccess: () => {},
        onFail: undefined,
        onFinally: () => {}
    }), []);

    const apiExecutor = useCallback(async (apis, {
        onSuccess = defaultCallbacks.onSuccess,
        onFail = defaultCallbacks.onFail,
        onFinally = defaultCallbacks.onFinally,
    } = defaultCallbacks) => {
        try {
            const response = await apis;
            onSuccess(response);
            return response;
        } catch (e) {
            await handleApiError(e as ApiError, onFail && ((e) => {
                onFail(e);
                return ERROR_HANDLER_FLOW.STOP;
            }));
        } finally {
            onFinally();
        }
    }, [defaultCallbacks, handleApiError]);


    return (
        <ApiExecuteContext.Provider value={apiExecutor as ApiExecutor}>
            {children}
        </ApiExecuteContext.Provider>
    );
};


export default Api;
