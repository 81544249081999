import { fetchSettingUrl } from "apis/ApiUrlConfig";
import { ajax } from "apis/ApiUtils";
import { AP_LEVEL } from "types/activityPoint/ApLevel";

export interface ContestDto {
    spotsToMaxEntries: { [key: string]: number };
    defaultMaxEntries: number;
    rubyExchangeRateCent: number;
}

export interface MatchDto {
    kickoffDurationMilliseconds: number;
}

export interface ReferrerDto {
    refereeRubyCent: number;
    maxRubyCentPerReferee: number;
    returnToReferrerPercentage: number;
}

export interface ApLevelDto {
    level: AP_LEVEL,
    weight: number,
    minAp: number
}

interface NewUserGiftsDto {
    rubyCent: number,
    couponDiscountPercentage: number
}

interface LinkDto {
    howToPlay: string,
    termsAndConditions: string,
    privacyPolicy: string
}

interface WithdrawDto {
    provider: string;
    minimumAmountCent: number;
    handleFeeCent: number;
}

export interface SettingDto {
    contest: ContestDto,
    referrer: ReferrerDto,
    match: MatchDto,
    apLevels: ApLevelDto[],
    following: FollowingDto,
    newUserGifts: NewUserGiftsDto,
    link: LinkDto
    withdraw: WithdrawDto
    mpblEnabled: boolean
}

export interface FollowingDto {
    maxFollowing: number,
}

export const fetchSetting = async (): Promise<SettingDto> => {
    const { data } = await ajax<SettingDto>({ url: fetchSettingUrl });

    return data.data;
};
