import { useCompleteYourKycDialog } from "hooks/useDialog";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { removePopup } from "store/actions/AlertActions";

export const KycCompleteYourKYCPopup = ({ show, onConfirm, onCancel }) => {
    const dispatch = useDispatch();
    const handleCompleteYourKycDialog = useCompleteYourKycDialog();

    useEffect(() => {
        if (show) {
            handleCompleteYourKycDialog({
                onConfirm: () => {
                    dispatch(removePopup());
                    onConfirm();
                },
                onCancel: () => {
                    dispatch(removePopup());
                    onCancel();
                }
            });
        }
    }, []);


    return null;
};
