import { logoTertiaryHorizontal } from "helpers/IconHelper";
import React from "react";
import styled from "styled-components";

export const LogoWrapper = styled.div.attrs(() => ({
    className: "mx-auto",
}))`
    width: 20rem;
    height: auto;

    > img {
        -webkit-user-drag: none;
    }
`;

export const Logo: React.FC = () => {
    return <LogoWrapper>
        <img src={logoTertiaryHorizontal} alt="logo" className="img-fluid" />
    </LogoWrapper>;
};