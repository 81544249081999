import { Image, Modal } from "react-bootstrap";
import styled from "styled-components";

export const ModalContainer = styled(Modal.Body).attrs(() => ({
    className: "position-relative"
}))`
  padding: 2.6rem 2.4rem 3.2rem;
`;

export const ModalTitle = styled.div.attrs(() => ({
    className: "fz-sf-bold-title-3 text-light-primary text-center"
}))`
  margin-bottom: 1.2rem;
`;
export const ModalBody = styled.div.attrs({})`
  margin-bottom: 2.6rem;
`;
export const ExplanationDetail = styled.div.attrs({
    className: "d-flex border-light-primary align-items-start"
})`
  padding: .8rem 1.6rem;
  border-width: 1px 1px 0 1px;
  border-style: solid;

  &:last-child {
    border-bottom-width: 1px;
  }
`;
export const ExplanationIconWrapper = styled.div.attrs({})`
  width: 1.6rem;
  margin-right: .8rem;
  flex-shrink: 0;
`;
export const ExplanationIcon = styled(Image).attrs({
    className: "w-100"
})`
`;
export const ExplanationText = styled.div.attrs({
    className: "fz-sf-regular-body-2 text-light-primary"
})`
`;
