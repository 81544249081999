import { fetchMyApOverview } from "apis/ap/ActivityPointApi";
import { ApiExecuteContext } from "apis/ApiContext";
import { joinContest, joinRubyContest, } from "apis/contest/ContestApi";
import { fetchMatchDetail, fetchSortedMyTeam } from "apis/match/MatchApi";
import WinningCost from "components/dialog/WinningCost";
import { useLoadingToggle } from "components/loading/Loading";
import { isFromAddDeposit } from "components/panel/SelectTeamPanelHelper";
import { JoinContestButtonLayout } from "containers/joinContestButtonLayout/JoinContestButtonLayout";
import SelectTeamPanel from "containers/selectTeamPanel";
import { ContestRepository } from "data/repository/contest/ContestRepository";
import ContestInfo from "domain/contest/join/info/ContestInfo";
import CreateTeamFlow from "domain/team/CreateTeamFlow";
import useUpdateWallet from "domain/wallet/useUpdateWallet";
import { analyticsEvent, analyticsParameterKey, logCustomEvent } from "ga";
import { useMinApLevel } from "helpers/ApLevelHelper";
import { cn } from "helpers/cn";
import { hasJoinableTeams, isOnlyOneJoinableTeam, } from "helpers/ContestHelper";
import { isMatchJoinable } from "helpers/match/MatchHelper";
import { isAtMatchDetailPage } from "helpers/RegexHelper";
import useKycRestriction from "hooks/useCheckKycRestriction/useKycRestriction";
import { useStopJoiningDialog, useSuccessDialog, } from "hooks/useDialog";
import useFantasyHistory from "hooks/useFantasyHistory";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import useHandleJoinContestError from "hooks/useHandleJoinContestError/useHandleJoinContestError";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import { getMatchDetailPageUrl } from "router/RouterUrls";

export const componentTag = {
    INFO: "show",
    CREATE_TEAM: "create",
};

const JoinContestFlow = () => {
    const history = useFantasyHistory();
    const handleJoinContestError = useHandleJoinContestError();
    const { contestCode } = useParams();
    const toggleLoading = useLoadingToggle();
    const { isApLevelReached } = useMinApLevel();
    const apiExecutor = useContext(ApiExecuteContext);
    const stopJoiningDialog = useStopJoiningDialog();
    const successPopup = useSuccessDialog();
    const updateWallet = useUpdateWallet();
    const location = useLocation();
    const isRestrictedByKyc = useKycRestriction();
    const [currentComponent, setCurrentComponent] = useState(componentTag.INFO);
    const [match, setMatch] = useState(null);
    const [myTeams, setMyTeams] = useState([]);
    const [myApOverview, setMyApOverview] = useState(null);
    const intl = useIntl();

    const goToCreateTeam = () => setCurrentComponent(componentTag.CREATE_TEAM);

    const contestRepository = new ContestRepository();

    const contestDetail = useFantasyQuery(
        [QUERY_KEY.CONTEST_DETAIL, contestCode],
        async () => contestRepository.getContestDetail(contestCode.toUpperCase()),
        {
            options: {
                enabled: !!contestCode
            }
        }
    );

    const myContestJoinedTeams = useFantasyQuery(
        [QUERY_KEY.MY_CONTEST_JOINED_TEAM, contestCode],
        async () => contestRepository.getMyContestJoinedTeam(contestCode.toUpperCase()),
        {
            options: {
                enabled: !!contestCode
            }
        }
    );

    const contest = contestDetail?.data
        ? {
            ...contestDetail.data,
            myJoinedTeams: myContestJoinedTeams.data || [],
        }
        : {};

    const handleJoinContest = (close) => async ({
                                                    contestId,
                                                    selectedTeamIds,
                                                    couponIds,
                                                    useRuby,
                                                    closeConfirmationCb,
                                                    currentWinningCent,
                                                    balanceWinningCent,
                                                }) => {
        if (await isRestrictedByKyc()) {
            return;
        }
        toggleLoading(true);
        logCustomEvent(analyticsEvent.webConfirmToJoinContest, {
            [analyticsParameterKey.sport]: contest.sport
        });
        apiExecutor(joinContest(contestId, selectedTeamIds, useRuby, couponIds), {
            onSuccess: () => {
                contestDetail.refetch();
                myContestJoinedTeams.refetch();
                updateWallet();
                close();
                successPopup({
                    text: intl.messages["join_contest_success_dialog_title"],
                    bodySlot: (
                        <WinningCost
                            currentWinningCent={currentWinningCent}
                            balanceWinningCent={balanceWinningCent}
                        />
                    ),
                });
            },
            onFail: (_error) => {
                closeConfirmationCb && closeConfirmationCb();
                handleJoinContestError(_error, () => {
                    if (isAtMatchDetailPage(history.location?.state?.from)) {
                        history.goBack();
                        return;
                    }

                    history.replace(
                        getMatchDetailPageUrl(contest.sport, contest.matchId),
                    );
                });
            },
            onFinally: () => {
                toggleLoading(false);
            },
        });
    };
    const handleJoinRubyContest = (close) => async ({ contestId, selectedTeamIds, closeConfirmationCb }) => {
        if (await isRestrictedByKyc()) {
            return;
        }
        toggleLoading(true);

        apiExecutor(joinRubyContest(contestId, selectedTeamIds), {
            onSuccess: () => {
                contestDetail.refetch();
                myContestJoinedTeams.refetch();
                updateWallet();
                close();
                successPopup({
                    text: intl.messages["join_contest_success_dialog_title"],
                });
            },
            onFail: (_error) => {
                closeConfirmationCb && closeConfirmationCb();
                handleJoinContestError(_error, () => {
                    if (isAtMatchDetailPage(history.location?.state?.from)) {
                        history.goBack();
                        return;
                    }

                    history.replace(
                        getMatchDetailPageUrl(contest.sport, contest.matchId),
                    );
                });
            },
            onFinally: () => {
                toggleLoading(false);
            },
        });
    };

    const selectTeamPanel = SelectTeamPanel.useSelectTeamPanel(
        {
            sport: contest.sport,
            goToCreateTeam,
            onSubmit: handleJoinContest,
            onJoinRubyContest: handleJoinRubyContest,
        },
        {
            selectedTeamIds: location?.state?.selectedTeamsIds || [],
            show: isFromAddDeposit(location?.state?.selectedTeamsIds),
            autoOpenConfirmation: isFromAddDeposit(location?.state?.selectedTeamsIds),
        },
    );

    const onChoosePlayerPageReturn = () => {
        if (hasJoinableTeams(myTeams, contest.myJoinedTeams)) {
            selectTeamPanel.open();
        }
        setCurrentComponent(componentTag.INFO);
    };

    const afterCreateTeamSubmit = () => {
        apiExecutor(fetchSortedMyTeam(contest.sport, match.matchId), {
            onSuccess: (_myTeams) => {
                setMyTeams(_myTeams);
                setCurrentComponent(componentTag.INFO);
                selectTeamPanel.open({
                    selectedTeamIds: [_myTeams[0].id],
                    autoOpenConfirmation: isOnlyOneJoinableTeam(
                        _myTeams,
                        contest.myJoinedTeams,
                    ),
                });
            },
        });
    };

    const handleJoinButtonClick = async () => {
        logCustomEvent(analyticsEvent.webTryToJoinContest, {
            [analyticsParameterKey.action]: "buttonJoinContest",
            [analyticsParameterKey.sport]: contest.sport
        });

        if (await isRestrictedByKyc()) {
            return;
        }

        if (!isApLevelReached(myApOverview, contest)) {
            return;
        }

        if (hasJoinableTeams(myTeams, contest.myJoinedTeams)) {
            selectTeamPanel.open();
        } else {
            goToCreateTeam();
        }
    };

    const renderComponent = () => {
        const { INFO, CREATE_TEAM } = componentTag;

        switch (currentComponent) {
            case INFO:
                return (
                    <div className={cn("join-contest-wrapper", "tw-w-full tw-max-w-full tw-px-4 tw-pb-[9rem]")}>
                        <ContestInfo contest={contest} match={match} myJoinedTeams={myContestJoinedTeams.data || []}>
                            <JoinContestButtonLayout
                                myJoinedTeams={myContestJoinedTeams.data || []}
                                match={match}
                                contest={contest}
                                onJoinButtonClick={handleJoinButtonClick}
                            >
                                <FormattedMessage
                                    id="contest_detail_page_button_join_contest"
                                    values={{ contestName: contest.name }}
                                />
                            </JoinContestButtonLayout>
                        </ContestInfo>
                    </div>
                );

            case CREATE_TEAM:
                return (
                    <CreateTeamFlow
                        sport={contest.sport}
                        match={match}
                        myTeams={myTeams}
                        onNavbarBackClick={onChoosePlayerPageReturn}
                        onSuccess={afterCreateTeamSubmit}
                        handleTimeout={stopJoiningDialog}
                        copyTeam={null}
                    />
                );

            default:
                return null;
        }
    };

    useEffect(() => {
        if (contest.matchId) {
            toggleLoading(true);

            apiExecutor(
                Promise.all([
                    fetchMatchDetail(contest.sport, contest.matchId),
                    fetchSortedMyTeam(contest.sport, contest.matchId),
                    fetchMyApOverview(),
                ]),
                {
                    onSuccess: ([_match, _myTeams, _myApOverview]) => {
                        setMatch(_match);
                        setMyTeams(_myTeams);
                        setMyApOverview(_myApOverview);

                        if (!isMatchJoinable(_match.matchStatus)) {
                            stopJoiningDialog();
                        }
                    },
                    onFinally: () => {
                        toggleLoading(false);
                    },
                },
            );
        }
    }, [
        contest.matchId,
        contest.sport,
        apiExecutor,
        stopJoiningDialog,
        toggleLoading,
    ]);

    if (!match) return null;

    return renderComponent();
};

export default JoinContestFlow;
