import { PerformanceVo } from "data/vo/player/performance/PerformanceVo";
import { Performance } from "data/vo/player/PlayerInfoVo";
import { divideByOneHundred } from "helpers/number/NumberHelper";

export class CricketPerformanceVo extends PerformanceVo {
    private readonly _averageEconomyRatePointsCent: number;
    private readonly _averageStrikeRatePointsCent: number;
    private readonly _averageRunPointsCent: number;

    constructor(performance: Performance) {
        super(performance);
        this._averageEconomyRatePointsCent = performance.averageEconomyRatePointsCent!;
        this._averageStrikeRatePointsCent = performance.averageStrikeRatePointsCent!;
        this._averageRunPointsCent = performance.averageRunPointsCent!;
    }

    get averageRunPoints(): number {
        return Math.floor(divideByOneHundred(this._averageRunPointsCent));
    }

    get averageStrikeRatePoints(): number {
        return Math.floor(divideByOneHundred(this._averageStrikeRatePointsCent));
    }

    get averageEconomyRatePoints(): number {
        return Math.floor(divideByOneHundred(this._averageEconomyRatePointsCent));
    }


}
