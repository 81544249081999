import { FormattedMessage } from "react-intl";
import React from "react";
import { FollowInfoDivider, FollowInfoWrapper, FollowTextWrapper } from "./FollowInfoStyle";

import useUserProfileContext from "../../useUserProfileContext";

interface FollowOrFollowingProps {
    labelValue: number;
    i18nLabelId: string;
}

const Info: React.FC<FollowOrFollowingProps> = ({ i18nLabelId, labelValue }) => {
    return <FollowTextWrapper>
        <div className="fz-sf-bold-title-3 text-dark-primary text-center">
            {labelValue}
        </div>
        <div className="fz-sf-regular-footnote text-dark-secondary text-center">
            <FormattedMessage id={i18nLabelId} />
        </div>
    </FollowTextWrapper>;
};

export const FollowInfo: React.FC<{ userId: number }> = ({ userId }) => {

    const { userFollow } = useUserProfileContext();

    return <FollowInfoWrapper>
        <Info i18nLabelId={"label_followers"} labelValue={userFollow.followersCount} />
        <FollowInfoDivider />
        <Info i18nLabelId={"label_following"} labelValue={userFollow.followingCount} />
    </FollowInfoWrapper>;
};
