import JoinContestPanelContainer from "domain/slate/components/contest/JoinContestPanelContainer";
import NineCatDescriptionWithIcon from "domain/slate/components/NineCatDescriptionWithIcon";
import TopNavbar, { TopNavbarType } from "domain/slate/components/TopNavbar";
import { NineCatContestVo } from "domain/slate/data/ComponentTypes";
import { useSelectTeam } from "domain/slate/hooks/useSelectTeam";
import { useSetNotJoinableContestTemporarily } from "domain/slate/hooks/useSetNotJoinableContestTemporarily";
import { useNineCatContext } from "domain/slate/providers/NineCatProvider";
import JoinNineCatContestConfirmationDialogContainer
    from "domain/slate/views/JoinNineCatContestConfirmationDialogContainer";
import MyTeamsSection from "domain/slate/views/MyTeamsSection";
import { cn } from "helpers/cn";
import React, { useState } from "react";

export const JoinNineCatContestPage = () => {
    const {
        slateDetail,
        toCreateSlateTeamPage,
        handleRefetchMyContests,
        handleRefetchPublicContests,
        isRestrictedByKyc
    } = useNineCatContext();
    const [contest, setContest] = useState<NineCatContestVo | null>();

    const {
        notJoinableContestId,
        resetNotJoinable,
        handleSetNotJoinableContestTemporarily
    } = useSetNotJoinableContestTemporarily();

    const { selectedTeamIds, selectTeam, unselectTeam } = useSelectTeam(resetNotJoinable);

    const handleResetAndRefetchAfterJoined = () => {
        setContest(null);
        handleRefetchMyContests();
        handleRefetchPublicContests();
    };

    return (
        <div className={
            cn("tw-bg-grey-50 tw-w-full tw-h-full",
                "tw-flex tw-flex-col tw-justify-between",
                "tw-h-lvh"
            )
        }>
            <div className={
                cn("tw-flex tw-flex-col",
                    "tw-max-h-[37rem]"
                )
            }>
                <TopNavbar
                    type={TopNavbarType.LIGHT}
                    onLeaveClick={toCreateSlateTeamPage}
                    title={
                        <NineCatDescriptionWithIcon>
                            <h2 className={"tw-text-h5 tw-font-bold"}>
                                9-Cat
                            </h2>
                        </NineCatDescriptionWithIcon>
                    }
                    slate={slateDetail}
                />
                <MyTeamsSection selectedTeamIds={selectedTeamIds}
                                notJoinableContestId={notJoinableContestId}
                                onSelectTeam={selectTeam}
                                onUnselectTeam={unselectTeam} />
            </div>
            <JoinContestPanelContainer notJoinableContestId={notJoinableContestId}
                                       selectedTeamIds={selectedTeamIds}
                                       onSetContest={async (contest: NineCatContestVo) => {
                                           if (await isRestrictedByKyc()) {
                                               return;
                                           }
                                           setContest(contest);
                                       }}
                                       onSetNotJoinableContestTemporarily={handleSetNotJoinableContestTemporarily} />
            {contest && (
                <JoinNineCatContestConfirmationDialogContainer
                    contest={contest}
                    selectedTeamIds={selectedTeamIds}
                    onResetAfterJoined={handleResetAndRefetchAfterJoined}
                />
            )}
        </div>
    );
};
