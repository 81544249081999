import React from "react";

interface DividerProps {
    children: React.ReactNode;
}

const Divider: React.FC<DividerProps> = ({ children }) => {
    return <div className="tw-flex tw-items-center tw-gap-5 tw-my-3">
        <hr className="tw-flex-grow tw-opacity-100 border-opaque-primary" />
        <span className="tw-text-body-2 tw-text-grey-1000">
            {children}
        </span>
        <hr className="tw-flex-grow tw-opacity-100 border-opaque-primary" />
    </div>;
};

export default Divider;
