import { Container } from "react-bootstrap";
import styled from "styled-components";

export const HomeIcon = styled.div<{
    $icon: string
}>`
  width: 2rem;
  padding-bottom: 2rem;
  margin: 1rem 0;
  background: url(${({ $icon }) => $icon}) center no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
`;
export const WalletHistoryNavbarCenter = styled.div.attrs(() => ({
    className: "d-flex h-100 align-items-center justify-content-center tw-text-system-primary80"
}))``;
export const Title = styled.div.attrs(() => ({
    className: "fz-sf-bold-title-3 text-center text-text-primary-1"
}))``;

export const WalletInfoIcon = styled.div<{
    $icon: string
}>`
  width: 2rem;
  padding-bottom: 2rem;
  background: url(${({ $icon }) => $icon}) center no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
`;
export const WalletHistoryWrapper = styled(Container).attrs(() => ({
    className: "w-100 mw-100"
}))``;
