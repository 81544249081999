import { SportRoleVO } from "./Sport";

export type FOOTBALL_KEY = "GOAL_KEEPER" | "DEFENDER" | "MID_FIELDER" | "FORWARD";

export const FOOTBALL_ROLE: SportRoleVO<FOOTBALL_KEY> = {
    GOAL_KEEPER: {
        abbr: "GK",
        apiValue: "goalkeeper"
    },
    DEFENDER: {
        abbr: "DEF",
        apiValue: "defender"
    },
    MID_FIELDER: {
        abbr: "MID",
        apiValue: "midfielder"
    },
    FORWARD: {
        abbr: "FW",
        apiValue: "forward"
    }
};