import { MatchCard, MatchCardFooter, MatchCardHeader, MatchCardTournament } from "components/matchCards/MatchCardStyle";
import { MatchInfoSummaryLayout, UpcomingMatchCardBody } from "components/matchCards/UpcomingMatchCardBody";
import { LineupsOut } from "domain/match/components/LineupsOut";
import { MATCH_DETAIL_TAB_KEY } from "domain/match/pages/upcoming/MatchDetailFlow";
import { enrichUpcomingMatchesWithStartDate } from "helpers/match/MatchHelper";
import { formatTo24HrTime } from "helpers/TimeHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { getMatchDetailPageUrl } from "router/RouterUrls";
import { MyMatchVo } from "../../../../data/vo/match/MyMatchVo";
import MyMatchItemWrapper from "./MyMatchItemWrapper";

export const ROUTE_FROM_MY_MATCHES = "fromMyMatches";

interface MyMatchUpcomingProps {
    matches: MyMatchVo[];
    handleTimeout: Function;
}

const MyMatchUpcoming: React.FC<MyMatchUpcomingProps> = ({ matches, handleTimeout }) => {
    const history = useFantasyHistory();

    if (matches?.length === 0) {
        return null;
    }

    return (
        <MyMatchItemWrapper
            title={<FormattedMessage id="label_upcoming_matches" />}
            titleTestId="my-matches-title-upcoming"
        >
            {
                enrichUpcomingMatchesWithStartDate(matches).map((match) => {
                    return <Fragment key={match.matchId}>
                        {match.getStartDateLabel("tw-text-center tw-font-thin")}
                        <MatchCard
                            className="animate__animated animate__fadeIn"
                            onClick={() => {
                                history.push(getMatchDetailPageUrl(match.sport, match.matchId), {
                                    tab: MATCH_DETAIL_TAB_KEY.myContests,
                                    from: ROUTE_FROM_MY_MATCHES
                                });
                            }}
                        >
                            <MatchCardHeader>
                                <MatchCardTournament>{match.tournament}</MatchCardTournament>
                                <LineupsOut matchStatus={match.matchStatus} isLineupsOut={match.isLineupsOut} />
                            </MatchCardHeader>
                            <UpcomingMatchCardBody match={match}
                                                   handleTimeout={handleTimeout}>
                                <div className="text-end fz-sf-regular-subhead text-text-tertiary-1">
                                    {formatTo24HrTime(match.matchStartsAt)}
                                </div>
                            </UpcomingMatchCardBody>
                            <MatchCardFooter>
                                <MatchInfoSummaryLayout teams={match.teams} contests={match.contests}
                                                        joined={match.joined} />
                            </MatchCardFooter>
                        </MatchCard>

                    </Fragment>;
                })
            }
        </MyMatchItemWrapper>
    );
};

export default MyMatchUpcoming;
