import { useSelector } from "../../../store";
import { TOP_LEFT_SLOT_TYPE } from "../../../store/reducer/teamPreview/types";
import TeamPreviewOperationIcons from "../components/TeamPreviewOperationIcons";
import React from "react";

const TeamPreviewTopLeftSlotFactory = () => {
    const {
        topLeftSlotType
    } = useSelector(state => state.TeamPreviewState.config);

    if (topLeftSlotType === TOP_LEFT_SLOT_TYPE.OPERATION) {
        return <TeamPreviewOperationIcons />;
    }

    return null;

};

export default TeamPreviewTopLeftSlotFactory;