import FantasyBasicPopup from "components/popup/FantasyBasicPopup";
import { BUTTON_PATTERN, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { KycToWithdrawPopupProps } from "domain/kyc/components/KycToWithdrawPopupFactory";
import { kycArrowWithdraw, kycInitiationWithdraw, kycWithdraw } from "helpers/IconHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import React from "react";
import { useIntl } from "react-intl";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";


export const KycUnverifiedToWithdrawPopup: React.FC<KycToWithdrawPopupProps> = ({
                                                                                    isShow,
                                                                                    onClose
                                                                                }) => {
    const intl = useIntl();
    const history = useFantasyHistory();

    return <FantasyBasicPopup
        show={isShow}
        onHide={onClose}
        imageSection={<div>
            <img src={kycInitiationWithdraw} alt="kyc-initiation-withdraw" className="tw-max-w-[25%]" />
            <img src={kycArrowWithdraw} alt="kyc-arrow-withdraw" className="tw-max-w-[7%]" />
            <img src={kycWithdraw} alt="kyc-withdraw" className="tw-max-w-[25%]" />
        </div>}
        title={intl.formatMessage({ id: "kyc_verify_to_withdraw" })}
        texts={[{
            text: intl.formatMessage({ id: "kyc_verify_to_withdraw_content_1" }, { discountPercentage: 20 }),
            textClassName: "tw-text-left",
        }, {
            text: intl.formatMessage({ id: "kyc_verify_to_withdraw_content_2" }, { discountPercentage: 20 }),
            textClassName: "tw-text-left",
        }]}
        buttonSetting={{
            confirmButtonVariant: NEW_BUTTON_VARIANTS.primaryDark,
            cancelButtonVariant: NEW_BUTTON_VARIANTS.text,
            buttonPattern: BUTTON_PATTERN.rounded,
            confirmButtonText: intl.formatMessage({ id: "kyc_start_verification" }),
            cancelButtonText: intl.formatMessage({ id: "kyc_do_it_later" }),
            onConfirm: () => {
                onClose();
                history.push(ROUTER_PATH_CONFIG.verifyKyc.form);
            },
            onCancel: () => {
                onClose();
            }
        }}
    />;
};
