import { MyProfileDto } from "data/dto/user/MyProfileDto";
import { isVerified, KYC_STATUS } from "types/kyc/KycStatus";


export class MyProfileVo {
    id: number;
    email: string;
    avatar: string;
    nickname: string;
    description: string;
    registeredAt: number;
    userCode: string;
    hasMobileNumber: boolean;
    hasReferrer: boolean;
    canChangePassword: boolean;
    kycStatus: KYC_STATUS;
    kycCompletedDeadlineTime: number;

    constructor(myProfileDto: MyProfileDto) {
        this.id = myProfileDto.id;
        this.email = myProfileDto.email;
        this.avatar = myProfileDto.avatar;
        this.nickname = myProfileDto.nickname;
        this.description = myProfileDto.description || '';
        this.registeredAt = myProfileDto.registeredAt;
        this.userCode = myProfileDto.userCode;
        this.hasMobileNumber = myProfileDto.hasMobileNumber;
        this.hasReferrer = myProfileDto.hasReferrer;
        this.canChangePassword = myProfileDto.canChangePassword;
        this.kycStatus = myProfileDto.kycStatus;
        this.kycCompletedDeadlineTime = myProfileDto.kycCompletedDeadlineTime;
    }

    get isRestrictedByKyc(): boolean {
        return !isVerified(this.kycStatus) && this.isReachKycCompleteDeadline;
    }

    get isReachKycCompleteDeadline(): boolean {
        return Date.now() >= this.kycCompletedDeadlineTime;
    }

}
