import {
    StyledScrollingTitleBarWrapper,
    StyledTitleBarScrollingTitle,
    StyledTitleBarScrollingWrapper,
    StyledTitleBarTitle,
    StyledTitleBarWrapper,
} from "domain/me/components/TitleBar/TitleBarStyle";
import { useMe } from "domain/me/me/MeProvider";
import React, { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { CSSTransition } from "react-transition-group";
import UserAvatarWithFrame from "../../../../components/avatar/UserAvatarWithFrame";
import useScrollDetector from "../../../../hooks/useScrollDetector";
import ShareButton from "domain/me/components/TitleBar/ShareButton";


const TitleBar: React.FC = () => {
    const { myProfile, userInfoRef } = useMe();
    const { avatar, nickname } = myProfile;
    const scrollNavbar = useRef(null);
    const shouldShowSmallNavbar = useScrollDetector(
        userInfoRef.current?.clientHeight || 0,
    );

    return (
        <>
            <StyledTitleBarWrapper>
                <StyledTitleBarTitle>
                    <FormattedMessage id="main_page_bottom_bar_tab_me" />
                </StyledTitleBarTitle>
                <ShareButton id={myProfile.id} nickname={myProfile.nickname} />
            </StyledTitleBarWrapper>
            <CSSTransition
                in={shouldShowSmallNavbar}
                nodeRef={scrollNavbar}
                classNames="my-matches-navbar"
                timeout={400}
                unmountOnExit
            >
                <StyledScrollingTitleBarWrapper ref={scrollNavbar}>
                    <StyledTitleBarScrollingWrapper>
                        <UserAvatarWithFrame
                            font="fz-sf-regular-footnote"
                            url={avatar}
                            name={nickname}
                            size="2rem"
                        />
                        <StyledTitleBarScrollingTitle>
                            {nickname}
                        </StyledTitleBarScrollingTitle>
                    </StyledTitleBarScrollingWrapper>

                    <ShareButton id={myProfile.id} nickname={myProfile.nickname} />
                </StyledScrollingTitleBarWrapper>
            </CSSTransition>
        </>
    );
};

export default TitleBar;
