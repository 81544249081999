import React from "react";
import { ContestRepository } from "data/repository/contest/ContestRepository";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import { SPORT } from "types/sports/Sport";
import { ERROR_HANDLER_FLOW } from "hooks/useHandleApiError";
import { FeatureContestCarousel } from "officialWebsite/FeatureContest/components/FeatureContestCarousel";

interface FeatureContestCarouselProps {
    selectedSport: SPORT;
}

export const FeatureContestCarouselContainer: React.FC<FeatureContestCarouselProps> = ({ selectedSport }) => {
    const contestRepository = new ContestRepository();


    const featureContests = useFantasyQuery([QUERY_KEY.FEATURED_CONTESTS, selectedSport],
        async () => {
            return await contestRepository.getFeaturedContestsDaily(selectedSport);
        }, {
            onFail: () => ERROR_HANDLER_FLOW.STOP
        }
    );
    const refetchFeatureContests = () => {
        featureContests.refetch();
    };

    return (
        <FeatureContestCarousel
            featureContests={featureContests.data || []}
            refetchFeatureContests={refetchFeatureContests} />
    );

};
