import styled from "styled-components";

export const ContestDetailWrapper = styled.div.attrs(() => ({
    className: "contest-detail-wrapper"
}))`
    padding: 1.2rem 0;
`;

export const ContestCancelledCover = styled.div.attrs({
    className: "position-absolute bg-opacity-secondary-2 w-100 h-100 fz-sf-bold-caption-1 d-flex align-items-center justify-content-center text-text-primary-2"
})``;
