import { ApiExecuteContext } from "apis/ApiContext";
import { fetchMatchSnapshot } from "apis/match/MatchApi";
import MatchSquadLayout from "domain/match/components/squad/MatchSquadLayout";
import { LeftSquadQuickSummary, RightSquadQuickSummary } from "domain/match/components/squad/MatchSquadLayoutStyle";
import { isBasketballSquadCodeHighlighted } from "domain/match/components/startedNavbar/BasketballNavbarMatchWithScore";

import {
    ContestDetailNavbarBottomProps
} from "domain/match/components/startedNavbar/ContestDetailNavbarBottomPropsInterface";
import { Commentary } from "domain/match/pages/started/MyContestDetailStyle";
import { isMatchCompleted, isMatchKickoffOrLive } from "helpers/match/MatchHelper";
import React, { useContext, useEffect, useState } from "react";
import { SPORT } from "types/sports/Sport";

const BasketballContestDetailNavbarBottom: React.FC<ContestDetailNavbarBottomProps> = ({ match }) => {
    const apiExecutor = useContext(ApiExecuteContext);
    const isCompleted = match && isMatchCompleted(match.matchStatus);
    const [commentary, setCommentary] = useState<string | null>(null);

    const squadAHighlighted = isBasketballSquadCodeHighlighted(match.squadACode, match);
    const squadBHighlighted = isBasketballSquadCodeHighlighted(match.squadBCode, match);

    useEffect(() => {
        if (isMatchKickoffOrLive(match.matchStatus)) {
            apiExecutor(fetchMatchSnapshot(SPORT.BASKETBALL, match.matchId),
                { onSuccess: (response) => setCommentary(response.commentary) });
        }
    }, [match, apiExecutor]);

    return <>
        <MatchSquadLayout matchDetail={match}
                          squadAScore={<LeftSquadQuickSummary
                              $highlight={squadAHighlighted}
                              $completed={isCompleted}>
                              {match.squadAScores}
                          </LeftSquadQuickSummary>}
                          squadBScore={<RightSquadQuickSummary
                              $highlight={squadBHighlighted}
                              $completed={isCompleted}>
                              {match.squadBScores}
                          </RightSquadQuickSummary>}
        />
        {
            commentary && <Commentary>{commentary}</Commentary>
        }
    </>;
};

export default BasketballContestDetailNavbarBottom;
