import Dialog from "designToken/dialog/Dialog";
import { FantasyTextField, ShadCNTextFieldProps } from "designToken/textFields/FantasyTextField";
import { cn } from "helpers/cn";
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { IoChevronDown } from "react-icons/io5";
import { MENU_ITEM_VARIANT, MenuItem } from "../menuItem/MenuItem";

const DIALOG_PADDING = 8;
const TOP_PADDING = 32;

export enum FantasySelectAlignment {
    center = "center",
    attach = "attach",
}

interface SelectProps extends ShadCNTextFieldProps {
    options: string[] | number[] | ({ label: string, value: string | number }[]),
    align?: FantasySelectAlignment,
    disabled?: boolean,
}

export const FantasySelect: React.FC<SelectProps> = ({
                                                         name,
                                                         options,
                                                         showErrorMessage,
                                                         inputProps,
                                                         align = FantasySelectAlignment.center,
                                                         variant,
                                                         disabled
                                                     }) => {
    const container = useRef<HTMLDivElement>(null);

    const [showOptions, setShowOptions] = useState(false);
    const [containerWidth, setContainerWidth] = useState(0);
    const [containerCenterX, setContainerCenterX] = useState(0);
    const [containerCenterY, setContainerCenterY] = useState(0);
    const [containerRect, setContainerRect] = useState(container.current?.getBoundingClientRect());

    const updateContainerRect = () => {
        if (container.current) {
            setContainerRect(container.current!.getBoundingClientRect());
        }
    };

    window.addEventListener("scroll", updateContainerRect, true);

    useEffect(() => updateContainerRect(), []);

    useEffect(() => {
        if (containerRect) {
            setContainerWidth(Math.floor(containerRect.width));
            setContainerCenterX(containerRect.x + DIALOG_PADDING);
            setContainerCenterY(containerRect.y - TOP_PADDING);
        }
    }, [containerRect, container]);

    const {
        trigger,
        getValues,
        setValue,
    } = useFormContext();

    const onSelect = (value: string) => {
        setValue(name, value);
        onHide();
        trigger(name);
    };

    const onHide = () => setShowOptions(false);

    const onFocus: React.EventHandler<SyntheticEvent<HTMLInputElement>> = (e) => {
        e.preventDefault();
        if (disabled) return;

        setShowOptions(true);
        (e.target as HTMLInputElement).blur();
    };

    function getLabel() {
        const originValue = getValues(name);
        if (options.length === 0) {
            return undefined;
        }
        if (typeof options[0] === "string") {
            return originValue;
        }
        const found = (options as { label: string, value: string }[]).find(opt => opt.value === originValue);
        if (!found) {
            return originValue;
        }
        return found.label;
    }

    return <div ref={container}>
        <FantasyTextField
            name={name}
            variant={variant}
            showErrorMessage={showErrorMessage}
            inputProps={{
                ...inputProps,
                value: getLabel(),
                onFocus: onFocus,
            }}
            iconRight={<IoChevronDown size={18} onClick={() => {
                if (disabled) return;

                setShowOptions(true);
            }} className={cn({ "tw-text-gray-300": disabled })} />}
            disabled={disabled}
        />
        <Dialog show={showOptions}
                onHide={onHide}
                dialogClassName={"tw-mx-0 tw-max-w-full"}
                contentClassName={"tw-my-[3.2rem] tw-w-min tw-bg-transparent tw-shadow-none tw-border-none"}
                backdropClassName={"tw-bg-transparent"}
                centered={align !== FantasySelectAlignment.attach}
        >
            <div
                className={cn(
                    "tw-rounded-[1.2rem] tw-overflow-auto tw-shadow-[0_8px_64px_0_#0000001A]",
                    "tw-max-h-[var(--dialogHeight)]",
                    "tw-w-[var(--containerWidth)]",
                    "tw-relative tw-left-[var(--containerCenterX)]",
                    align === FantasySelectAlignment.attach ? "tw-top-[var(--containerCenterY)]" : "",
                )}
                style={{
                    "--dialogHeight": `${(window.innerHeight - 64)}px`,
                    "--containerWidth": `${containerWidth - 2 * DIALOG_PADDING}px`,
                    "--containerCenterX": `${containerCenterX}px`,
                    "--containerCenterY": `${containerCenterY}px`,
                } as any}>
                {
                    options.map(((option, i) => {
                        const originValue = getValues(name);
                        const label = typeof option === "object" ? option.label : option;
                        const value = typeof option === "object" ? option.value : option;
                        return <MenuItem
                            key={`select-option-${i}`}
                            variant={originValue === value ? MENU_ITEM_VARIANT.selected : MENU_ITEM_VARIANT.default}
                            onClick={() => onSelect(value)}
                            label={label}
                        />;
                    }))
                }
            </div>
        </Dialog>
    </div>;
};
