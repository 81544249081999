import { ListItem, ListTitle, ListValue } from "../components/TransactionDetailStyle";
import { FormattedMessage } from "react-intl";
import React from "react";

interface TransactionProductDetailProps {
    product: string | undefined
}

const TransactionProductDetail
    = ({ product }: TransactionProductDetailProps) => (
    <ListItem>
        <ListTitle><FormattedMessage id="label_product" /></ListTitle>
        <ListValue>{product || "-"}</ListValue>
    </ListItem>
);
export default TransactionProductDetail;