import React, { Fragment } from "react";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import { useSwiper } from "swiper/react";

export const CarouselArrow = () => {
    const swiper = useSwiper();
    return <Fragment>
        <div
            className="swiper-button-prev !tw-w-[37px] !tw-h-[63px] !tw-bg-[#001042] !tw-top-[calc(50%-30px)] !tw-rounded-r-[12px]"
            onClick={() => swiper.slidePrev()}
        >
            <MdArrowBackIosNew color="#EAD431" size={"1.75rem"} style={{ width: "auto", height: "auto" }} />
        </div>

        <div
            className="swiper-button-next !tw-w-[37px] !tw-h-[63px] !tw-bg-[#001042] !tw-top-[calc(50%-30px)] !tw-rounded-l-[12px]"
            onClick={() => swiper.slideNext()}
        >
            <MdArrowForwardIos color="#EAD431" size={"1.75rem"} style={{ width: "auto", height: "auto" }} />
        </div>
    </Fragment>;

};
