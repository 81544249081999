import _ from "lodash";

export const generateMultipleEntriesInfo = ({ spotsToMaxEntries, defaultMaxEntries }) => {

    const allSpots = Object.keys(spotsToMaxEntries).map(spots => parseInt(spots));

    const isFirstSpots = (index) => (index === 0);
    const getPreviousSpots = (index) => (allSpots[index - 1]);
    const getFinalSpots = () => (allSpots[allSpots.length - 1]);
    const getMaxEntries = (spots) => (spotsToMaxEntries[spots]);

    return [
        ...allSpots.map((spots, index) => ({
            begin: isFirstSpots(index) ? null : getPreviousSpots(index) + 1,
            end: spots,
            entries: getMaxEntries(spots)
        })),
        {
            begin: getFinalSpots() + 1,
            end: null,
            entries: defaultMaxEntries
        }
    ];

};

export const makeSpotsRangeText = (begin, end) => {
    let text = "";

    if (!!begin) {
        text += `${begin} ~ `;
    }

    if (!!end) {
        text += `${end}`;
    }

    return text;
};

export const isNextButtonDisabled = selectedTeamIds => _.isEmpty(selectedTeamIds);

export const getMaxSelectableTeamsCount = ({ spots, teams, maxIdealUserEntries }) => {
    return _.min([maxIdealUserEntries, spots - teams]);
};

export const isDepositGreaterThanOrEqualToTotalEntryFee = (deposit, entryFee, selectedTeamIds) => deposit >= entryFee * selectedTeamIds.length;

export const getSelectedTeamIdsOnTeamSelected = (selectedTeamIds, selectedId) => {
    return selectedTeamIds.includes(selectedId)
        ? selectedTeamIds
        : [...selectedTeamIds, selectedId];
};

export const getSelectedTeamIdsOnTeamUnselected = (selectedTeamIds, unselectedId) => {
    return selectedTeamIds.includes(unselectedId)
        ? selectedTeamIds.filter(selectedTeamId => selectedTeamId !== unselectedId)
        : selectedTeamIds;
};

export const getSelectedTeamsForConfirmation = (selectedTeamIds, myTeams) => {
    return selectedTeamIds.map(selectedTeamId => ({
        id: selectedTeamId,
        name: _.find(myTeams, { "id": selectedTeamId })?.name
    }));
};

export const getIdToTeam = teams => teams.reduce((result, team) => ({ ...result, [team.id]: team }), {});

export const isFromAddDeposit = selectedTeamIds => selectedTeamIds && selectedTeamIds.length > 0;

export function getNewSelectedTeamIdsWhenUserIsB2b(prevSelectedTeamIds, unusedCouponLength) {
    return prevSelectedTeamIds.length > unusedCouponLength ? [] : prevSelectedTeamIds;
}
