import React from "react";
import styled from "styled-components";

const StyledRelationBarMark = styled.div.attrs({
    className:
        "position-absolute rounded-circle bg-sys-danger top-0 start-100 translate-middle-x",
})`
  width: 0.6rem;
  height: 0.6rem;
`;

interface ICountLabel {
    label: string | React.ReactElement;
    count: number | string;
    isUnread?: boolean;
    className?: string;
}

const CountLabel: React.FC<ICountLabel> = ({
                                               label,
                                               count,
                                               isUnread,
                                               className = "",
                                           }) => {
    return (
        <div className={`position-relative text-center ${className}`}>
            <div className="fz-sf-bold-title-3 text-light-primary">{count}</div>
            <div className="fz-sf-regular-footnote text-light-secondary">{label}</div>
            {isUnread && <StyledRelationBarMark data-testid="unread-mark" />}
        </div>
    );
};

export default CountLabel;
