import { FantasyPopupTitle } from "components/popup/FantasyBasicPopup";
import { cn } from "helpers/cn";
import React, { ReactNode } from "react";
import { Card } from "react-bootstrap";

interface TitleProps {
    title?: ReactNode | FantasyPopupTitle;
    titleClassName?: string;
}

export const Title: React.FC<TitleProps> = ({ title, titleClassName }) => {
    if (!title) return null;

    const isFantasyPopupTitle = typeof title === "object" && 'title' in title;

    const _title = isFantasyPopupTitle ? title.title : title;
    const _titleClassName = isFantasyPopupTitle ? title.titleClassName : titleClassName;

    return <Card.Title className={cn("tw-text-h6 tw-font-bold tw-text-grey-1000 tw-m-0", _titleClassName)}>
        {_title}
    </Card.Title>;
};
