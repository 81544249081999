import { FANTASY_DIALOG_VARIANT } from "components/dialog/FantasyDialog";
import { DIALOG_BACKDROP } from "designToken/dialog/Dialog";
import React, { ReactNode } from "react";
import { MessageFormatElement } from "react-intl";
import {
    ADD_ALERT_POPUP,
    ADD_ERROR_POP,
    ADD_TOAST,
    KYC_COMPLETE_POPUP,
    REMOVE_ALERT_POPUP,
    REMOVE_ERROR_POPUP,
    REMOVE_TOAST
} from "store/actions/AlertActions";

export interface PopupProps {
    variant?: FANTASY_DIALOG_VARIANT
    imageSection?: ReactNode,
    title?: string | MessageFormatElement[] | ReactNode,
    content?: ReactNode | string,
    img?: string,
    imgSize?: number,
    cancelButton?: {
        onClick?: () => void,
        variant?: string,
        text?: string
    },
    confirmButton?: {
        onClick?: () => void,
        variant?: string,
        text?: string
    },
    bodySlot?: React.ReactNode,
    footerSlot?: React.ReactNode,
    backdrop?: boolean | DIALOG_BACKDROP,
    onExited?: () => void,
    autoClose?: boolean,
}

interface AlertState {
    popup: PopupProps[];
    notification: any[];
    errors: any[];
    kycCompletePopup: boolean;
}

const initState: AlertState = {
    popup: [],
    notification: [],
    errors: [],
    kycCompletePopup: false
};

const alertState = (prevState = initState, action) => {
    const { type, payload } = action;

    switch (type) {
        case ADD_ALERT_POPUP:
            const popup = [...prevState.popup];
            popup.push(payload);
            return { ...prevState, popup };

        case ADD_TOAST:
            const notification = [...prevState.notification];
            notification.push(payload);
            return { ...prevState, notification };

        case ADD_ERROR_POP:
            const errors = [...prevState.errors];
            errors.push(payload);
            return { ...prevState, errors };

        case REMOVE_ALERT_POPUP:
            return { ...prevState, popup: prevState.popup.slice(1) };

        case REMOVE_TOAST:
            return { ...prevState, notification: prevState.notification.slice(1) };

        case REMOVE_ERROR_POPUP:
            return { ...prevState, errors: prevState.errors.slice(1) };

        case KYC_COMPLETE_POPUP:
            return { ...prevState, kycCompletePopup: payload };

        default:
            return prevState;
    }

};

export default alertState;
