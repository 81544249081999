import ScoringTable from "domain/team/player/components/scoringPopup/components/ScoringTable";
import React from "react";
import ScoringNote from "domain/team/player/components/scoringPopup/components/ScoringNote";


const BasketballScoringView = () => {
    return <div className="tw-flex tw-flex-col tw-gap-6 tw-max-h-[calc(min(91.8rem,85vh)-12.4rem)] overflow-x-auto">
        <ScoringTable
            scoring={{
                "Points Scored": "1",
                "Rebounds": "1.2",
                "Assists": "1.5",
                "Steals": "3",
                "Blocks": "3",
                "Turn Overs": "-1",
            }}
        />
        <ScoringNote />
    </div>;
};

export default BasketballScoringView;
