import useFantasyConfig from "hooks/useFantasyConfig";
import { LiveChatWidget } from "@livechat/widget-react";
import React from "react";
import useLiveChat from "hooks/useLiveChat";
import useMyProfile from "../../hooks/useMyProfile";

export const LiveChat = () => {
    const { liveChatLicense, featureConfig } = useFantasyConfig();
    const { visibility, closeChat } = useLiveChat();
    const { myProfile } = useMyProfile();

    if (!featureConfig.liveChat || !liveChatLicense) return null;

    const handleVisibilityChanged = ({ visibility }) => {
        if (visibility === "hidden") {
            closeChat();
        }
    };

    return <LiveChatWidget
        license={liveChatLicense}
        visibility={visibility}
        onVisibilityChanged={handleVisibilityChanged}
        customerName={myProfile?.nickname}
        customerEmail={myProfile?.email}
        sessionVariables={
            {
                userId: myProfile?.id.toString(),
            }
        }
    />;
};
