import FantasyBasicPopup, { FANTASY_POPUP_BACKGROUND } from "components/popup/FantasyBasicPopup";
import { buyCoins, Icon } from "helpers/IconHelper";
import React from "react";
import { Title } from "components/popup/component/Title";
import { cn } from "helpers/cn";
import Button from "designToken/button/Button";
import { BUTTON_SIZE, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import useFantasyHistory from "hooks/useFantasyHistory";
import { useAddCoinFlow } from "domain/coin/AddCoinFlow";


const OrderPurchasedDialog = () => {
    const { push } = useFantasyHistory();
    const { restartAddDepositFlow } = useAddCoinFlow();

    return <FantasyBasicPopup
        dismissible
        show
        onHide={restartAddDepositFlow}
        buttonSetting={{ cancelButton: false, confirmButton: false }}
        background={FANTASY_POPUP_BACKGROUND.primary}
        imageSection={<OrderPurchasedHeader />}
        content={<OrderPurchasedContent
            onPlayNow={() => push(ROUTER_PATH_CONFIG.home)}
            onBuyMore={restartAddDepositFlow}
        />}
        iconSize="2.4rem"
    />;
};


const OrderPurchasedHeader = () => {
    return <div
        className="tw-flex tw-flex-col tw-items-center tw-gap-3 tw-px-4 tw-pt-4 tw-pb-16">
        <img src={buyCoins} alt="buy-coins" className="tw-w-[7.4rem] tw-h-auto" />
        <Title title={<FormattedMessage id="buy_coin_purchase_title_label" />} titleClassName="tw-text-white" />
        <div className="tw-text-subtitle-1">
            <FormattedMessage id="buy_coin_purchase_content_label" />
        </div>
    </div>;
};

const OrderPurchasedContent = ({ onBuyMore, onPlayNow }: { onPlayNow: () => void, onBuyMore: () => void }) => {

    return <div className={cn(
        "tw-bg-white -tw-mx-10 tw-px-10 -tw-mb-8"
    )}>
        <div className={cn(
            "-tw-translate-y-1/2",
            "tw-flex tw-items-center tw-gap-3",
            "tw-bg-grey-100 tw-w-full tw-text-subtitle-1 tw-text-gray-800",
            "tw-px-4 tw-py-3 tw-rounded-[1.2rem]",
        )}>
            <Icon.ClockFill className="tw-flex-shrink-0 tw-text-primary-500" size="1.8rem" />
            <div className="tw-text-left tw-text-primary-800">
                <FormattedMessage
                    id="buy_coin_purchase_tip_label"
                    values={{
                        data: <Link to={ROUTER_PATH_CONFIG.wallet} className="tw-underline">
                            <FormattedMessage id="label_transaction_history" />
                        </Link>
                    }}
                />
            </div>
        </div>

        <div className="tw-pb-8 tw-flex tw-flex-col tw-gap-3">
            <Button
                className="tw-w-full tw-text-h6 tw-font-medium"
                variant={NEW_BUTTON_VARIANTS.secondaryDark}
                size={BUTTON_SIZE.lg}
                prefixIcon={Icon.FireIcon("2rem")}
                onClick={onPlayNow}
            >
                <FormattedMessage id="label_play_now" />
            </Button>
            <Button
                className="tw-w-full tw-text-h6 tw-font-medium"
                variant={NEW_BUTTON_VARIANTS.text}
                size={BUTTON_SIZE.lg}
                onClick={onBuyMore}
            >
                <FormattedMessage id="label_buy_more_coins" />
            </Button>
        </div>
    </div>;
};

export default OrderPurchasedDialog;
