import { isScrollbarReachDividePoint } from "domain/chat/ChatRoomHelper";
import { useEffect, useRef, useState } from "react";
import { ChannelState } from "stream-chat";

export const useScrollControl = (messages: ReturnType<ChannelState["formatMessage"]>[],
                                 updateMessages: () => void,
                                 queryOldMessages: (lastMessageId: string) => Promise<any>) => {

    const isAnimateInitialized = useRef(false);
    const messagesContainer = useRef<HTMLDivElement>(null);
    const [isBottomButtonShow, setIsBottomButtonShow] = useState(false);
    const scrollToBottomSmoothly = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth"
        });
    };
    const handleScrollReachTop = async () => {
        if (window.scrollY !== 0) return;

        const lastMessageId = messages[0]?.id || "";
        await queryOldMessages(lastMessageId);
        updateMessages();

    };

    useEffect(() => {
        const handleScroll = () => {
            setIsBottomButtonShow(!isScrollbarReachDividePoint(messagesContainer.current, window.innerHeight, window.scrollY));
            handleScrollReachTop();
        };

        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, [handleScrollReachTop]);

    useEffect(() => {
        const initAnimate = () => {
            if (!isAnimateInitialized.current && messages.length > 0) {
                scrollToBottomSmoothly();
                isAnimateInitialized.current = true;
            }
        };

        const receivedNewMessageAnimate = () => {
            if (isScrollbarReachDividePoint(messagesContainer.current, window.innerHeight, window.scrollY)) {
                scrollToBottomSmoothly();
            }
        };

        initAnimate();

        receivedNewMessageAnimate();
    }, [messages]);

    return {
        messagesContainer,
        isBottomButtonShow,
        scrollToBottomSmoothly
    };
};
