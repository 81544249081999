import { EmptyGeneral } from "components/empty/MyEmptyMatch";
import { NoHistoryDescription } from "domain/wallet/components/walletHistoryRow/WalletHistoryRowStyle";
import { FormattedMessage } from "react-intl";
import React from "react";

export const NoHistory = () => {
    return <div className={"tw-flex tw-flex-col tw-justify-center tw-items-center"}>
        <EmptyGeneral />
        <NoHistoryDescription><FormattedMessage id="wallet_history_page_label_empty_record" /></NoHistoryDescription>
    </div>;
};
