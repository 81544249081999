import { fetchSupportedRegionUrl } from "../ApiUrlConfig";
import { ajax } from "../ApiUtils";
import { SupportedRegion } from "../user/UserApi";

export const fetchSupportedRegions = async () => {
    const { data } = await ajax<SupportedRegion[]>({ url: fetchSupportedRegionUrl });
    return data.data;
};


export const sendKycVerification = async (data: FormData): Promise<void> => {
    await ajax({
        url: "/kyc/application",
        method: "POST",
        data,
        customHeaders: {
            "Content-Type": "multipart/form-data",
        }
    });
};
