import {
    claimTask,
    completeQuest,
    fetchDailyCheckInQuest,
    fetchTaskPrizeClaimed,
    fetchTaskProgress,
    fetchTasks,
    finishTask
} from "data/api/task/TaskApi";

import { OperateTaskRequest } from "data/dto/task/OperateTaskRequest";
import { TaskInfoDto } from "data/dto/task/TaskDto";
import { TaskPrizeClaimedVo } from "data/vo/task/TaskPrizeClaimedVo";
import { TaskInfoVoMap, TaskVo } from "data/vo/task/TaskVo";
import { TaskProgressVo, UserTaskProgressVo } from "data/vo/task/UserTaskProgressVo";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import { mapValues } from "lodash";
import { isKnownTaskType, TASK_PROGRESS, TASK_TYPE } from "types/task/TaskType";
import { DailyCheckInQuestVo } from "../../vo/task/DailyCheckInQuestVo";

export class TaskRepository {

    public async getTasks() {
        const taskDtos = await fetchTasks();
        return taskDtos
            .filter(taskDto => taskDto.taskType !== TASK_TYPE.WATCH_AD)
            .filter(taskDto => isKnownTaskType(taskDto.taskType))
            .map(taskDto => {
                const taskInfo: TaskInfoVoMap = mapValues(taskDto.taskInfo, (taskInfoDto: TaskInfoDto) => ({
                    ruby: divideByOneHundred(taskInfoDto.rubyCent),
                    coin: divideByOneHundred(taskInfoDto.coinCent),
                    maxClaimedLimit: taskInfoDto.maxClaimedLimit,
                    apLevelWeight: taskInfoDto.apLevelWeight,
                }));

                return new TaskVo(taskInfo, taskDto.taskType, taskDto.taskResetType, taskDto.code, taskDto.taskConfig);
            });
    }

    public async getProgress() {
        const taskProgressDtos = await fetchTaskProgress();
        const incompleteQuantity = taskProgressDtos
            .filter(taskProgressDto => taskProgressDto.taskType !== TASK_TYPE.WATCH_AD && taskProgressDto.progress !== TASK_PROGRESS.COMPLETED)
            .filter(taskProgressDto => isKnownTaskType(taskProgressDto.taskType))
            .length;

        const taskProgressVos: TaskProgressVo[] = taskProgressDtos
            .filter(taskProgressDto => isKnownTaskType(taskProgressDto.taskType))
            .map(taskProgressDto => new TaskProgressVo(taskProgressDto));

        return new UserTaskProgressVo(incompleteQuantity, taskProgressVos);
    }

    public async getPrizeClaimed() {
        const { coinCent, rubyCent } = await fetchTaskPrizeClaimed();
        return new TaskPrizeClaimedVo(divideByOneHundred(coinCent), divideByOneHundred(rubyCent));
    }

    public async finishTask({ taskType, code }: OperateTaskRequest) {
        await finishTask(taskType, code);
    }

    public async claimTask({ taskType, code }: OperateTaskRequest) {
        await claimTask(taskType, code);
    }

    public async completeQuest({ taskType, code }: OperateTaskRequest) {
        await completeQuest(taskType, code);
    }

    public async getDailyCheckInQuest(): Promise<DailyCheckInQuestVo> {
        return await fetchDailyCheckInQuest();
    }
}

