// app
export const UPDATE_UNAUTHORIZED_PAGE: string = "UPDATE_UNAUTHORIZED_PAGE";
export const UPDATE_FEATURE_CONFIG: string = "UPDATE_FEATURE_CONFIG";

export const SET_FIRST_TIME_PURCHASE: string = "SET_FIRST_TIME_PURCHASE";

export const UPDATE_SITE_ID: string = "UPDATE_SITE_ID";
// wallet
export const UPDATE_WALLET_DATA: string = "UPDATE_WALLET_DATA";

// coupon
export const UPDATE_COUPON_DATA: string = "UPDATE_COUPON_DATA";

// live chat
export const UPDATE_LIVE_CHAT_VISIBILITY: string = "UPDATE_LIVE_CHAT_VISIBILITY";

// settings
export const UPDATE_SETTING: string = "UPDATE_SETTING";

// team preview
export const UPDATE_TEAM_PREVIEW_SHOW: string = "UPDATE_TEAM_PREVIEW_SHOW";
export const UPDATE_TEAM_PREVIEW_CONFIG: string = "UPDATE_TEAM_PREVIEW_CONFIG";
export const UPDATE_TEAM_PREVIEW_DATA: string = "UPDATE_TEAM_PREVIEW_DATA";

// select team
export const UPDATE_SELECT_TEAM_PANEL_SHOW = "UPDATE_SELECT_TEAM_PANEL_SHOW";
export const UPDATE_SELECT_TEAM_PANEL_CONFIG =
    "UPDATE_SELECT_TEAM_PANEL_CONFIG";
export const UPDATE_SELECT_TEAM_PANEL_DATA = "UPDATE_SELECT_TEAM_PANEL_DATA";

// sport
export const UPDATE_CURRENT_SPORT: string = "UPDATE_CURRENT_SPORT";
