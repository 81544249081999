import PageViewRecorder from "components/ga/PageViewRecorder";
import { MatchesSkeleton } from "components/skeleton/MatchesSkeleton";
import B2CWrapper from "containers/b2cWrapper/B2CWrapper";
import B2CAdCarousel from "domain/home/components/ad/carousel/B2CAdCarousel";
import { FantasyHomeNavbar } from "domain/home/components/FantasyHomeNavbar/FantasyHomeNavbar";
import FeaturedContest from "domain/home/components/featuredContest/FeaturedContest";
import B2CPromotional from "domain/home/components/Promotional/B2CPromotional";
import TournamentFilterDialog from "domain/home/components/tournamentFilterDialog/TournamentFilterDialog";
import UpcomingMatches from "domain/home/components/upcoming/UpcomingMatches";
import { UpcomingWrapper } from "domain/home/components/upcoming/UpcomingMatchesStyle";
import { HomeNavWrapper } from "domain/home/HomeNavWrapper";
import { HomeProvider, useHomeProvider } from "domain/home/HomeProvider";
import { HomeWrapper } from "domain/home/HomeStyle";
import { SportTabs } from "domain/home/SportTabs";
import HomeNineCat from "domain/slate/views/HomeNineCat";
import { analyticsEvent, logCustomEvent } from "ga";
import { cn } from "helpers/cn";
import useFantasyConfig from "hooks/useFantasyConfig";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootStoreState } from "store/reducer";
import { SPORT } from "types/sports/Sport";


const HomeContext = () => {
    const { SportState: { currentSport } } = useSelector((store: RootStoreState) => store);
    const {
        tournaments,
        updateData,
        handleTournamentFilterDialogOpen,
        isTournamentSelectAll,
        filteredMatches,
    } = useHomeProvider();

    // TODO: 9-Cat
    const { featureToggle } = useFantasyConfig();

    useEffect(() => {
        logCustomEvent(analyticsEvent.webMatchPage);
    }, []);

    return <>
        <PageViewRecorder title="Home" />
        <TournamentFilterDialog />
        <HomeWrapper>
            <HomeNavWrapper>
                <FantasyHomeNavbar />
                <div className={cn(
                    "d-flex justify-content-between align-items-center px-4 pt-3",
                    "header-bg-normal tw-shadow-navbar"
                )}>
                    <SportTabs
                        onClick={handleTournamentFilterDialogOpen}
                        shouldShowDot={!isTournamentSelectAll}
                    />
                </div>
            </HomeNavWrapper>
            <B2CAdCarousel />
            <FeaturedContest />
            {
                (featureToggle && currentSport === SPORT.BASKETBALL) && <B2CWrapper>
                    <HomeNineCat />
                </B2CWrapper>
            }
            <UpcomingWrapper>
                {
                    filteredMatches
                        ? <UpcomingMatches
                            tournaments={tournaments}
                            matches={filteredMatches}
                            handleMatchTimeout={updateData}
                        />
                        : <MatchesSkeleton />
                }
            </UpcomingWrapper>
            <B2CPromotional />
        </HomeWrapper>
    </>;
};


const Home = () => (
    <HomeProvider>
        <HomeContext />
    </HomeProvider>
);

export default Home;
