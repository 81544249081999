import { BOTTOM_NAV_ICON_KEY, NavbarBottomType } from "components/container/bottom/types";
import { useBottomNavbar } from "components/container/bottom/useBottomNavbar";
import { analyticsEvent, logCustomEvent } from "ga";
import { cn } from "helpers/cn";
import useFantasyHistory from "hooks/useFantasyHistory";
import React from "react";

const logBottomNavEvent = (key: BOTTOM_NAV_ICON_KEY) => {
    switch (key) {
        case BOTTOM_NAV_ICON_KEY.HOME:
            return logCustomEvent(analyticsEvent.webHomeIcon);
        case BOTTOM_NAV_ICON_KEY.RESULT:
            return logCustomEvent(analyticsEvent.webClickResultIcon);
        default:
            break;
    }
};

const BottomNavbar = () => {
    const history = useFantasyHistory();
    const { tabs, activeColor, bgColor } = useBottomNavbar();
    const showBottomNavbarPages = tabs.map(({ to }) => to);

    if (!showBottomNavbarPages.includes(history.location.pathname)) {
        return null;
    }

    return <div className={cn("tw-bottom-nav-wrapper", bgColor)}>
        {
            tabs.map(({ key, name, active, icon, to, additional, isShow }: NavbarBottomType) => {


                const AdditionComponent = additional?.show
                    ? additional.component
                    : () => null;

                const IconComponent = icon as React.FC<{ className: string }>;

                const color = active ? activeColor : "tw-text-primary-50";

                return isShow
                    ? <div key={name}
                           className={cn("tw-flex tw-flex-col tw-items-center tw-justify-center tw-flex-1 tw-cursor-pointer tw-py-2", {
                               "tw-bg-gray-50": active,
                           })}
                           onClick={() => {
                               logBottomNavEvent(key);
                               history.push(to);
                           }}>
                        <div className={cn("tw-relative tw-w-6 tw-h-6 tw-mb-[1px]")}>
                            <AdditionComponent />
                            {typeof icon === "function" &&
                                <IconComponent className={color} />}
                        </div>
                        <span className={cn(
                            "tw-text-caption-2 tw-font-bold",
                            color,
                        )}>
                            {name}
                        </span>
                    </div>
                    : null;
            })
        }
    </div>;
};

export default BottomNavbar;
