import { cn } from "helpers/cn";
import React, { ReactNode } from "react";

interface SegmentedTabProps<T extends any> {
    tabs: { key: T; name: ReactNode, icon?: ReactNode }[];
    currentTab: string;
    onChangeTab: (tabKey: T) => void;
    className?: string;
}

export const SegmentedTab = <T extends string>({ tabs, currentTab, onChangeTab, className }: SegmentedTabProps<T>) => {

    return <div
        className={cn("tw-w-full tw-mx-auto tw-p-1.5 tw-flex tw-rounded-[3.2rem] tw-bg-grey-100", className)}>
        {
            tabs.map(({ key, name, icon }) => (
                <div
                    key={key}
                    className={cn("tw-flex-1 tw-py-1 tw-px-3 tw-flex tw-items-center tw-justify-center tw-gap-1.5 tw-rounded-[3.2rem] tw-text-subtitle-2 tw-cursor-pointer", {
                        "tw-bg-grey-1000 tw-text-white tw-font-bold": key === currentTab
                    })}
                    onClick={() => onChangeTab(key)}
                    data-testid={`tab-${key}`}
                >
                    {icon}
                    {name}
                </div>
            ))
        }
    </div>;
};
