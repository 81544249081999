import React from "react";
import AlertDialog from "./components/dialog/AlertDialog";
import ErrorDialog from "./components/error/ErrorDialog";
import AlertToast from "./components/toast/AlertToast";


const Alert = () => {
    return (
        <>
            <AlertDialog />
            <ErrorDialog />
            <AlertToast />
        </>
    );
};

export default Alert;
