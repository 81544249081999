import styled from "styled-components";

export const UserDeleteSubmitContainer = styled.div.attrs({
    className: "d-flex flex-column justify-content-center px-4",

})`
  margin: auto;
  padding-top: 140px;
  max-width: 400px;
`;

export const UserDeleteSubmitParagraph = styled.p.attrs({
    className: "fz-sf-regular-body-1"
})``;

export const EmailImageContainer = styled.div.attrs({
    className: "m-auto"
})``;

export const CheckEmailTitle = styled.div.attrs({
    className: "pt-2 pb-2 fz-sf-bold-title-3 text-center"
})``;

export const CheckEmailParagraph = styled.p.attrs({
    className: "fz-sf-regular-body-2 px-2 py-4 text-center"
})`
`;