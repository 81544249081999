import { CONTEST_TYPE_FROM_API, ENTRY_FEE_TYPE_FROM_API } from "../../../types/contest/Contest";
import { ContestDto } from "../../dto/contest/ContestDto";

export class ContestVo {
    allowDiscountWithRuby: boolean;
    allowMultiple: boolean;
    code: string;
    regularPoolCent: number;
    hostDonatedPoolCent: number;
    entryFeeCent: number;
    entryFeeType: ENTRY_FEE_TYPE_FROM_API;
    firstPrizeCent: number;
    id: number;
    matchId: string;
    minApLevel: string;
    minApLevelWeight: number;
    minimumSlip: number;
    myJoinedTeamsNames: string[];
    name: string;
    needOtp: boolean;
    prizeTeams: number;
    spots: number;
    teams: number;
    type: CONTEST_TYPE_FROM_API;

    constructor(contestDto: ContestDto) {
        this.allowDiscountWithRuby = contestDto.allowDiscountWithRuby;
        this.allowMultiple = contestDto.allowMultiple;
        this.code = contestDto.code;
        this.regularPoolCent = contestDto.regularPoolCent;
        this.hostDonatedPoolCent = contestDto.hostDonatedPoolCent;
        this.entryFeeCent = contestDto.entryFeeCent;
        this.entryFeeType = contestDto.entryFeeType;
        this.firstPrizeCent = contestDto.firstPrizeCent;
        this.id = contestDto.id;
        this.matchId = contestDto.matchId;
        this.minApLevel = contestDto.minApLevel;
        this.minApLevelWeight = contestDto.minApLevelWeight;
        this.minimumSlip = contestDto.minimumSlip;
        this.myJoinedTeamsNames = contestDto.myJoinedTeamsNames;
        this.name = contestDto.name;
        this.needOtp = contestDto.needOtp;
        this.prizeTeams = contestDto.prizeTeams;
        this.spots = contestDto.spots;
        this.teams = contestDto.teams;
        this.type = contestDto.type;
    }
}
