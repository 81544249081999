import { DailyTaskTitle, DailyTaskWrapper } from "./DailyTaskStyle";
import { FormattedMessage } from "react-intl";
import { DailyTasks } from "./DailyTasks";
import React from "react";

export const DailyTaskSection = () => {

    return <>
        <DailyTaskTitle>
            <FormattedMessage id="daily_task_panel_title" />
        </DailyTaskTitle>

        <DailyTaskWrapper>
            <DailyTasks />
        </DailyTaskWrapper>
    </>;
};
