import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { NAVBAR_Y_OFFSET } from "../../components/container/NavbarHelper";
import { APP_MAX_WIDTH, SYSTEM_COLORS } from "../../styles/Constants";

const friendListIcon = "/images/friend_list_icon.png";
const sendMessageIcon = "/images/send_message_icon.png";
const toBottomIcon = "/images/chat_to_bottom_icon.png";

export const ChatRoomWrapper = styled.div.attrs({
    className: "w-100 h-100"
})``;

export const ChatRoomTitleWrapper = styled.div.attrs({
    className: "position-sticky w-100 bg-white header-line"
})`
    padding: 2rem 1.2rem .9rem;
    top: 0;
    left: 0;
`;
export const ChatRoomTitle = styled.div.attrs({
    className: "fz-sf-bold-title-2 text-start text-text-primary-1"
})`
`;
export const FriendListIcon = styled.div`
    position: absolute;
    bottom: .9rem;
    right: .9rem;
    width: 2.4rem;
    padding-bottom: 2.4rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    background-image: url(${friendListIcon});
    //cursor: pointer;
`;
export const AllMessageWrapper = styled.div`
    padding: 1.2rem 1.2rem 0;
`;
export const LoadingWrapper = styled.div.attrs({
    className: "d-flex justify-content-center"
})``;
export const MessageWrapper = styled.div.attrs({
    className: "d-flex align-items-end"
})`
    margin-bottom: 1.6rem;

`;
export const MessageAvatar = styled(NavLink).attrs({
    className: "text-center fz-sf-bold-body-2 tw-text-grey-0 hover:tw-text-grey-0 tw-bg-primary-400"
})`
    margin-right: .4rem;
    width: 3.2rem;
    height: 3.2rem;
    line-height: 3.2rem;
    border-radius: 50%;

    &:hover {
        text-decoration: none;
    }
`;
export const MessageTextWrapper = styled.div`
    width: calc(100% - 3.2rem - .4rem);
`;
export const MemberName = styled.div.attrs({
    className: "fz-sf-regular-body-1 tw-text-system-labelLightSecondary"
})`
    padding: .45rem 0 0;
`;
export const MessageTextContainer = styled.div.attrs({
    className: "message-text-container d-flex flex-column"
})`
    + .message-text-container {
        margin-top: .4rem;
    }

    &:first-child {
        .message-text {
            border-radius: 12px 12px 12px 0;
        }
    }
`;
export const MessageText = styled.div.attrs({
    className: "message-text fz-sf-regular-body-2 tw-text-system-labelLightPrimary"
})`
    width: fit-content;
    max-width: calc(100% - 5.5rem);
    padding: .8rem;
    border-radius: 0 12px 12px 0;

    * {
        color: inherit;
        font-size: inherit;
        margin-bottom: 0;
        word-break: break-word;
    }

    a {
        color: #0E67AD;
    }
`;
export const MessageTime = styled.div.attrs({
    className: "fz-sf-regular-caption-1 tw-text-system-labelLightTertiary"
})`
    width: 10rem;
    margin-left: .4rem;
    align-self: flex-end;
`;
export const MyMessageTextWrapper = styled(MessageTextWrapper)`
    margin-left: auto;
`;
export const MyMessageTextContainer = styled.div.attrs({
    className: "my-message-text-container d-flex flex-row-reverse"
})`
    + .my-message-text-container {
        margin-top: .4rem;
    }

    &:first-child {
        .message-text {
            border-radius: 12px 12px 0 12px;
        }
    }
`;
export const SystemMessageContainer = styled.div.attrs({
    className: "w-100 d-flex tw-bg-system-danger50"
})`
    margin-bottom: 2rem;
    padding: .8rem;
    width: calc((375 - 16 * 2) / 375) *100%;
    border-radius: 1.2rem;
    gap: 1rem;
`;

export const SystemMessageText = styled.div.attrs({
    className: "fz-sf-regular-body-2 tw-text-system-labelLightPrimary"
})`
`;

export const Logo = styled.img.attrs(() => ({
    className: "mx-auto "
}))`
    width: 3rem;
    height: 3rem;
`;

export const ThaiLandLine = styled.a.attrs({
    className: "fz-sf-bold-body-2 ms-1 text-sys-info-1"
})`
    text-decoration: underline;
    cursor: pointer;
`;

export const LearnMore = styled.a.attrs({
    className: "fz-sf-bold-body-2 ms-1 tw-text-system-danger500 hover:tw-text-system-danger500"
})`
    text-decoration: underline;
    cursor: pointer;
`;

export const MyMessageText = styled(MessageText).attrs({
    className: "message-text tw-bg-secondary-150"
})`
    border-radius: 12px 0 0 12px;
`;
export const MyMessageTime = styled(MessageTime).attrs({
    className: "text-end"
})`
    margin-top: .4rem;
    margin-left: auto;
`;


export const InputWrapper = styled.div.attrs({
    className: "position-fixed d-flex align-items-center tw-bg-grey-0"
})`
    left: 0;
    bottom: ${NAVBAR_Y_OFFSET + 1}px;
    padding: .8rem .8rem .8rem 1.2rem;
    width: 100%;
    max-width: ${APP_MAX_WIDTH};
    z-index: 3;

    + div {
        padding-bottom: 6.8rem;
    }
`;

export const MessageTextSection = styled.div.attrs({
    className: "fz-sf-regular-body-2 tw-text-system-labelLightPrimary tw-bg-system-primary4"
})`
    padding: 1.4rem 1.2rem;
    width: calc(100% - 3.3rem);
    border-radius: 1.2rem;
    max-height: 10rem;
    min-height: 4.8rem;
    overflow-y: auto;

    &:focus-visible {
        border: 0;
        outline: 0;
    }

    &:empty:before {
        content: attr(placeholder);
        color: ${SYSTEM_COLORS.labelLightTertiary};
        font-size: inherit;
    }

    * {
        font-size: inherit;
    }
`;

export const SendMessageButton = styled(FriendListIcon)`
    position: static;
    padding-bottom: 3.3rem;
    width: 3.3rem;
    height: 0;
    background-image: url(${sendMessageIcon});
    align-self: flex-end;
`;

export const ToBottomButton = styled(FriendListIcon).attrs({})`
    position: fixed;
    bottom: ${NAVBAR_Y_OFFSET + 80}px;
    left: calc(100vw - 6rem);
    z-index: 3;
    width: 4.4rem;
    padding-bottom: 4.4rem;
    background-image: url(${toBottomIcon});
    cursor: pointer;
    @media (min-width: ${APP_MAX_WIDTH}) {
        left: calc(${APP_MAX_WIDTH} - 6rem);
    }
`;

export const MessageSentToast = styled.div.attrs({
    className: "fz-sf-bold-caption-1 tw-text-grey-0 tw-bg-system-primary"
})`
    position: absolute;
    top: -4rem;
    left: 50%;
    transform: translateX(-50%);
    opacity: .85;
    border-radius: 1.2rem;
    padding: .4rem .8rem;
`;

export const ErrorContentWrapper = styled.div.attrs({
    className: "text-center"
})`
    padding-top: 20%;
`;

export const NameAndTimeWrapper = styled.div.attrs({
    className: "d-flex align-items-center"
})``;


export const InvalidMessageToast = styled(MessageSentToast).attrs({
    className: "d-inline-block mx-auto text-center text-nowrap tw-bg-system-red300"
})`
    padding: .4rem .8rem;
    top: -3rem;
    color: ${SYSTEM_COLORS.red};
    border-radius: 1.2rem;
`;
