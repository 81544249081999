import React from "react";
import { IconOmitProps } from "helpers/IconHelper";

interface ShareOptionProps {
    icon: React.FC<IconOmitProps>;
    label: string;
    onClick?: () => void;
}

export const ShareOption: React.FC<ShareOptionProps> = ({ icon: Icon, label, onClick }) => {
    return <div className="tw-py-2.5 -tw-mx-2 tw-flex tw-gap-2 tw-justify-start tw-items-center tw-cursor-pointer"
                onClick={onClick}>
        <div className="tw-rounded-lg tw-p-2.5 tw-bg-primary-50">
            <Icon />
        </div>
        <div className="tw-text-body-1 tw-text-primary-700">
            {label}
        </div>
    </div>;
};
