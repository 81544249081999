import { en } from "./en";
import { LocaleContext, SetLocaleContext } from "./LocaleContext";
import { th } from "./th";
import { zh } from "./zh";
import { fil } from "./fil";
import { bn } from "./bn";
import { createIntl } from "react-intl";

export enum LOCALE {
    EN = "en",
    FIL = "fil",
    BN = "bn",
    TH = "th",
    ZH = "zh"
}

const languages = [
    {
        key: LOCALE.EN,
        text: en.language_name
    },
    {
        key: LOCALE.TH,
        text: th.language_name
    },
    {
        key: LOCALE.ZH,
        text: zh.language_name
    },
    {
        key: LOCALE.FIL,
        text: fil.language_name
    },
    {
        key: LOCALE.BN,
        text: bn.language_name
    }
];


const defaultLocale = languages[0].key;

const I18n = {
    en,
    th,
    zh,
    fil,
    bn,
};

export const enIntl = createIntl({
    locale: LOCALE.EN, messages: I18n.en
});

export {
    LocaleContext,
    SetLocaleContext,
    I18n,
    languages,
    defaultLocale
};
