import { FantasyCheckbox } from "components/checkbox/FantasyCheckbox";
import { CHECKBOX_VARIANTS } from "components/checkbox/types";
import { FormControl, FormField, FormItem } from "components/ui/form";
import { FONT } from "designToken/font";
import { FormErrorMessage } from "domain/kyc/pages/kycVerificationForm/components/FormErrorMessage";
import React from "react";

interface FantasyFormCheckboxProps {
    children?: React.ReactNode;
    name: string;
    variant: CHECKBOX_VARIANTS;
    textFont?: FONT;
    showErrorMessage?: boolean;
}

export const FantasyFormCheckbox: React.FC<FantasyFormCheckboxProps> = ({
                                                                            name,
                                                                            variant,
                                                                            textFont,
                                                                            showErrorMessage = true,
                                                                            children
                                                                        }) => {
    return <FormField
        name={name}
        render={({ field }) =>
            <FormItem>
                <FormControl>
                    <FantasyCheckbox
                        variant={variant}
                        checked={field.value}
                        textFont={textFont}
                        onCheckedChange={(checked) => field.onChange(checked)}
                    >
                        {children}
                    </FantasyCheckbox>
                </FormControl>

                {showErrorMessage && <FormErrorMessage />}
            </FormItem>
        }
    />;
};
