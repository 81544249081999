import { SPORT } from "../../../../types/sports/Sport";
import { useEffect, useState } from "react";
import { getSelectedTeamsForConfirmation, isFromAddDeposit } from "../../../../components/panel/SelectTeamPanelHelper";
import { useLocation } from "react-router-dom";
import { DepositConfirmationDialogProps } from "./DepositConfirmationDialog";
import { useUpdateCouponAndWallet } from "./useUpdateCouponAndWallet";
import { ENTRY_FEE_TYPE_FROM_API } from "../../../../types/contest/Contest";
import { SelectedTeam } from "../../../../types/team/SelectedTeam";
import { ConfirmationDialogContestVO } from "./types";
import { Callback } from "Common";

export interface ConfirmationDialogSubmitParams {
    sport: SPORT
    contest: ConfirmationDialogContestVO
    contestId: ConfirmationDialogContestVO['id']
    selectedTeamIds: number[]
    couponIds: number[]
    useRuby: boolean
    closeConfirmationCb: Callback
    currentWinningCent: number
    balanceWinningCent: number
}

export interface UseConfirmationModalProps {
    sport: SPORT,
    contest: ConfirmationDialogContestVO,
    autoOpenConfirmation: boolean,
    selectedTeamIds: number[],
    myTeams: SelectedTeam[],
    onSubmit: Callback<[ConfirmationDialogSubmitParams]>
}

interface UseConfirmationModalResult {
    props: DepositConfirmationDialogProps
    openConfirmation: () => void
    isDepositConfirmationModalOpened: boolean
    isRubyConfirmationModalOpened: boolean
}

export const useConfirmationDialog = ({
                                          sport,
                                          contest,
                                          autoOpenConfirmation,
                                          selectedTeamIds,
                                          myTeams,
                                          onSubmit
                                      }: UseConfirmationModalProps): UseConfirmationModalResult => {
    const location = useLocation();
    const [isConfirmationModalOpened, setIsConfirmationModalOpened] = useState(isFromAddDeposit(location?.state?.selectedTeamsIds));
    const { myUnusedCoupon } = useUpdateCouponAndWallet({ contestEntryFeeCent: contest.entryFeeCent, });
    useEffect(() => {
        setIsConfirmationModalOpened(autoOpenConfirmation);
    }, [autoOpenConfirmation]);


    const openConfirmation = () => {
        setIsConfirmationModalOpened(true);
    };

    const closeConfirmation = () => {
        setIsConfirmationModalOpened(false);
    };

    const onConfirmButtonClicked = (selectedTeamIds, couponIds, useRuby, currentWinningCent, balanceWinningCent) => {
        onSubmit({
            sport,
            contest,
            contestId: contest!.id,
            selectedTeamIds,
            couponIds,
            useRuby,
            closeConfirmationCb: closeConfirmation,
            currentWinningCent,
            balanceWinningCent
        });
    };

    const isDepositConfirmationModalOpened = isConfirmationModalOpened && contest.entryFeeType !== ENTRY_FEE_TYPE_FROM_API.RUBY;

    const isRubyConfirmationModalOpened = isConfirmationModalOpened && contest.entryFeeType === ENTRY_FEE_TYPE_FROM_API.RUBY;

    return {
        props: {
            onHide: closeConfirmation,
            myUnusedCoupon,
            selectedTeams: getSelectedTeamsForConfirmation(selectedTeamIds, myTeams),
            onSubmit: onConfirmButtonClicked,
            contest
        },
        openConfirmation,
        isDepositConfirmationModalOpened,
        isRubyConfirmationModalOpened
    };
};
