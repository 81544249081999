import { isMatchStarted } from "helpers/match/MatchHelper";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import React, { useEffect, useMemo, useRef } from "react";
import Badge, { BADGE_PATTERN, BADGE_SIZE, BADGE_VARIANTS } from "../../../designToken/badge/Badge";
import { sortByIdAsc } from "../../../helpers/SortingHelper";
import { useSelector } from "../../../store";
import useTeamPreview from "../useTeamPreview";
import { TeamPreviewTeamsWrapper } from "./TeamPreviewStyle";


export const TeamPreviewTeams: React.FC = () => {
    const {
        TeamPreviewState: {
            data: {
                myTeams: teams,
                match
            }
        }
    } = useSelector(state => state);
    const teamPreview = useTeamPreview();
    const currentTeam = useMemo(() => teamPreview.getCurrentTeam(), [teamPreview]);

    const renderTeams = useMemo(() => sortByIdAsc(teams), [teams]);

    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();

    const navbarBottomRef = useRef<HTMLDivElement | null>(null);
    const currentBadgeRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!currentBadgeRef.current || !navbarBottomRef.current) return;

        const { width: parentWidth } = navbarBottomRef.current.getBoundingClientRect();
        const { width } = currentBadgeRef.current.getBoundingClientRect();

        const x = currentBadgeRef.current.offsetLeft - parentWidth / 2 + width / 2;
        navbarBottomRef.current.scrollTo(x, 0);
    }, [currentTeam]);

    if (!currentTeam || !match) {
        return null;
    }

    return <TeamPreviewTeamsWrapper className="overflow-auto my-team" ref={navbarBottomRef}
                                    data-testid="team-preview-teams">
        {
            renderTeams.map(team => {

                if (team.id === currentTeam.id) {
                    return <Badge key={team.id}
                                  ref={currentBadgeRef}
                                  variant={BADGE_VARIANTS.SECONDARY}
                                  size={BADGE_SIZE.MD}
                                  pattern={BADGE_PATTERN.ROUNDED}
                    >
                        {team.name}{isMatchStarted(match.matchStatus) && ` (${toFormattedNumber(divideByOneHundred(currentTeam.totalScoreCent))} Pts)`}
                    </Badge>;
                }

                return <Badge key={team.id}
                              variant={BADGE_VARIANTS.OUTLINE_SECONDARY}
                              size={BADGE_SIZE.MD}
                              pattern={BADGE_PATTERN.ROUNDED}
                              className="clickable"
                              onClick={() => teamPreview.updateCurrentTeam(team)}
                >
                    {team.name}
                </Badge>;
            })
        }
    </TeamPreviewTeamsWrapper>;
};
