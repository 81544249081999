import { fetchDailyCheckInReminderUrl, fetchRubyContestChampionReminderUrl } from "apis/ApiUrlConfig";
import { DailyCheckInReminderDto } from "data/dto/reminder/DailyCheckInReminderDto";
import { ajax } from "../ApiUtils";

export const fetchRubyContestChampionReminder = async (): Promise<any> => {
    try {
        let result0 = await ajax({ url: fetchRubyContestChampionReminderUrl, method: "POST" });
        const { data } = result0;
        return data.data;
    } catch (error) {
        throw error;
    }
};

export const fetchDailyCheckInReminder = async () => {
    const response = await ajax<DailyCheckInReminderDto>({
        url: fetchDailyCheckInReminderUrl,
        method: "POST"
    });
    return response.data.data;
};

