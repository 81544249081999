import { getSportConfig } from "domain/SportConfig";
import { GlossaryItem, GlossaryItemTitle } from "domain/team/playerInfoDialog/PlayerInfoDialogStyle";
import React from "react";
import { Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { SPORT } from "types/sports/Sport";

interface GlossaryProps {
    sport: SPORT;
}

export const Glossary: React.FC<GlossaryProps> = ({ sport }) => {
    const { glossary } = getSportConfig(sport);

    if (glossary.length === 0) {
        return null;
    }

    return <div>
        <div className="text-text-primary-1 fz-sf-bold-body-1 mb-3">
            <FormattedMessage id="glossary_title" />
        </div>
        <div className="d-flex flex-wrap">
            {
                glossary.map(item => {
                    return <Col key={item.abbr} className="col-6">
                        <GlossaryItem>
                            <GlossaryItemTitle>{item.abbr}:&nbsp;</GlossaryItemTitle>
                            {item.fullName}
                        </GlossaryItem>
                    </Col>;
                })
            }
        </div>
    </div>;
};
