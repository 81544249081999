import styled from "styled-components";
import { SwiperSlide } from "swiper/react";

export const Circle = styled.div.attrs<{ scale: number }>({
    className: "rounded-circle d-flex justify-content-center align-items-center"
})`
    ${({ scale }) => (
            `width: ${2 * scale}rem;
           height: ${2 * scale}rem;`
    )}
`;
export const CarouselWrapper = styled.div.attrs({
    className: "position-relative overflow-hidden"
})`
    user-select: none;
    min-height: 12rem;

    .swiper {
        height: 12rem;
    }

    .swiper-pagination {
        bottom: 5rem;
    }

    .swiper-slide {
        width: auto;
        min-width: auto;
        align-items: start;
    }
`;
export const ApLevelSwiperSlide = styled(SwiperSlide).attrs<{ $offsetY: number }>(({ $offsetY }) => ({
    className: "clickable",
    style: {
        transform: `translateY(${$offsetY}px)`
    }
}))``;
ApLevelSwiperSlide.displayName = "SwiperSlide";
