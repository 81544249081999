import { StartedMatchEmptyContest } from "components/empty/StartedMatchEmptyContest";
import TeamPreview from "containers/teamPreview";
import { ContestRepository } from "data/repository/contest/ContestRepository";
import { ContestCarousel } from "domain/contest/components/ContestCarousel";
import ContestInfoDetail from "domain/contest/join/info/detail/ContestInfoDetail";
import { MyContestWrapper } from "domain/match/components/myContest/MyContestStyle";
import { STICK_TOP } from "domain/match/pages/started/MyContestDetailStyle";
import { contestTypeComparator, entryFeeComparator } from "helpers/SortingHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import { orderBy } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { MyContestVO } from "types/contest/Contest";
import { MatchVO } from "types/match/Match";
import { ApiExecuteContext } from "../../../../apis/ApiContext";
import { useLoadingToggle } from "../../../../components/loading/Loading";
import { ContestDetailVo } from "../../../../data/vo/contest/ContestDetailVo";

const MyContestsContent: React.FC<{
    myContests: MyContestVO[];
    match: MatchVO;
}> = ({ myContests, match }) => {
    const history = useFantasyHistory();
    const apiExecutor = useContext(ApiExecuteContext);
    const toggleLoading = useLoadingToggle();
    const sortedContests = useMemo(() => orderBy(myContests, [contestTypeComparator, entryFeeComparator]), [myContests]);
    const teamPreview = TeamPreview.useTeamPreview();
    const getInitCurrentIndex = () => {
        if (!history.location?.state?.contestId) {
            return teamPreview.getContestInitIndex(myContests);
        }

        return sortedContests.findIndex((contest) => contest.id === history.location?.state?.contestId) ?? 0;
    };

    const [currentContestIdx, setCurrentContestIdx] = useState(getInitCurrentIndex());

    const onContestChange = (swiper) => {
        setCurrentContestIdx(swiper.realIndex);
        teamPreview.close();
    };

    const contestRepository = new ContestRepository();

    const contestDetail = useFantasyQuery(
        [QUERY_KEY.CONTEST_DETAIL, sortedContests[currentContestIdx]?.code],
        async () => contestRepository.getContestDetail(sortedContests[currentContestIdx]?.code),
        {
            options: {
                enabled: !!sortedContests[currentContestIdx]?.code
            }
        }
    );

    const myContestJoinedTeams = useFantasyQuery(
        [QUERY_KEY.MY_CONTEST_JOINED_TEAM, sortedContests[currentContestIdx]?.code],
        async () => contestRepository.getMyContestJoinedTeam(sortedContests[currentContestIdx]?.code),
        {
            options: {
                enabled: !!sortedContests[currentContestIdx]?.code
            }
        }
    );

    useEffect(() => {
        if (sortedContests.length === 0) return;

        teamPreview.updateContestCode(sortedContests[currentContestIdx]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiExecutor, toggleLoading, sortedContests, currentContestIdx]);

    useEffect(() => {
        if (history.location?.state?.contestId) {
            history.replace({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <MyContestWrapper>

        {
            sortedContests.length > 0
                ? <>
                    <ContestCarousel
                        currentContest={contestDetail?.data || {} as ContestDetailVo}
                        isContestLoading={contestDetail.isLoading}
                        contests={sortedContests}
                        handleCardChange={onContestChange}
                        matchStatus={match.matchStatus}
                    />

                    {contestDetail?.data && (
                        <div className="px-4">
                            <ContestInfoDetail
                                contest={contestDetail?.data}
                                myJoinedTeams={myContestJoinedTeams.data || []}
                                stickyTop={STICK_TOP}
                            />
                        </div>
                    )}
                </>
                : <StartedMatchEmptyContest />
        }


    </MyContestWrapper>;
};

export default MyContestsContent;
