import RubyConfirmationDialog from "domain/contest/components/ConfirmationDialog/RubyConfirmationDialog";
import React from "react";
import { OnJoinRubyContestWithClose } from "../../../store/reducer/selectTeamPanel/type";
import { DepositConfirmationDialogProps } from "../../contest/components/ConfirmationDialog/DepositConfirmationDialog";
import { NineCatContestVo } from "../data/ComponentTypes";
import { NineCatDepositConfirmationDialog } from "./NineCatDepositConfirmationDialog";

export interface JoinNineCatContestConfirmationDialogProps {
    depositConfirmationModalOpened: boolean,
    rubyConfirmationModalOpened: boolean,
    contest: NineCatContestVo
    confirmationDialogProps: DepositConfirmationDialogProps,
    onJoinRubyContest: OnJoinRubyContestWithClose
}

export const JoinNineCatContestConfirmationDialog: React.FC<JoinNineCatContestConfirmationDialogProps> = (props) => {
    return (<>
        {props.depositConfirmationModalOpened && (
            <NineCatDepositConfirmationDialog
                {...props.confirmationDialogProps}
                contest={props.contest}
            />
        )}
        {props.rubyConfirmationModalOpened && (
            <RubyConfirmationDialog
                {...props.confirmationDialogProps}
                onJoinRubyContest={props.onJoinRubyContest}
            />
        )}
    </>);
};
