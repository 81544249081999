import { appStoreQRCode, googlePlayQRCode } from "helpers/IconHelper";
import { useSelector } from "react-redux";
import { RootStoreState } from "../../store/reducer";
import { QRCodeWrapper } from "./AppQrCodeStyle";

const AppQrCode = () => {
    const { featureConfig} = useSelector((store: RootStoreState) => store.FantasyGameConfigState);

    if (!featureConfig.hasApp) return null;

    return (
        <QRCodeWrapper>
            <img data-testid="app-store-icon" src={appStoreQRCode} alt="app-store" className="me-5" />
            <img data-testid="google-play-icon" src={googlePlayQRCode} alt="google-play" />
        </QRCodeWrapper>
    );
};
export default AppQrCode;
