import React, { useState } from "react";
import { SPORT } from "types/sports/Sport";
import { FeatureContestTabs } from "officialWebsite/FeatureContest/components/FeatureContestTabs";
import { FeatureContestContext } from "officialWebsite/FeatureContest/components/FeatureContestContext";

export const FeatureContest = () => {
    const [selectedSport, setSelectedSport] = useState(SPORT.BASKETBALL);

    const changeSport = (sport: SPORT) => {
        setSelectedSport(sport);
    };

    return <div className="w-100 h-100">
        <FeatureContestTabs selectedSport={selectedSport} changeSport={changeSport} />
        <FeatureContestContext selectedSport={selectedSport} />
    </div>;
};

