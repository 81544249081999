import React from "react";
import useCurrency from "hooks/useCurrency/useCurrency";
import { HeaderTitle, WalletHistoryHeader } from "domain/wallet/components/walletHistoryRow/WalletHistoryRowStyle";
import { FormattedMessage } from "react-intl";
import AmountDisplay from "containers/amount/AmountDisplay";
import { getCurrencyIconSuitByWalletType } from "containers/amount/CurrencyIconSuitHelper";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import { WALLET_TYPE } from "data/dto/wallet/walletHistoryDto";

interface TotalAmountRowProps {
    totalCent: number,
    walletType: WALLET_TYPE,
}

export const TotalAmountRow: React.FC<TotalAmountRowProps> = ({ totalCent, walletType }) => {
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();
    return <WalletHistoryHeader>
        <HeaderTitle>
            <FormattedMessage id="label_total" />
        </HeaderTitle>
        <AmountDisplay currencyIconSuit={getCurrencyIconSuitByWalletType(walletType)}
                       className="fz-sf-bold-title-2 tw-gap-1">
            {toWalletNumber(divideByOneHundred(totalCent))}
        </AmountDisplay>
    </WalletHistoryHeader>;
};
