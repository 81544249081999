import { TaskInfoVo, TaskVo } from "data/vo/task/TaskVo";
import { TaskProgressVo } from "data/vo/task/UserTaskProgressVo";
import { WatchAdButtonFactory } from "domain/task/components/DailyTask/ButtonFactory/WatchAdButtonFactory";
import { WatchTutorialButtonFactory } from "domain/task/components/DailyTask/ButtonFactory/WatchTutorialButtonFactory";
import { Reward } from "domain/task/components/DailyTask/Reward";
import { useTaskProvider } from "domain/task/TaskProvider";
import React from "react";
import { FormattedMessage } from "react-intl";
import { AP_LEVEL } from "types/activityPoint/ApLevel";
import { TASK_TYPE } from "types/task/TaskType";
import { WatchVideoButtonFactory } from "domain/task/components/DailyTask/ButtonFactory/WatchVideoButtonFactory";
import { TaskConfig } from "data/dto/task/TaskDto";

interface DailyTaskCardProps {
    task: TaskVo;
    apLevel: AP_LEVEL;
}

export interface ButtonFactoryProps {
    apLevel: AP_LEVEL;
    taskProgress: TaskProgressVo | null;
    taskInfo?: TaskInfoVo;
    taskConfig: TaskConfig | null;
    taskCode: string;
}

type ButtonFactoryMap = {
    [key in TASK_TYPE]: React.FC<ButtonFactoryProps>
}

const buttonFactoryMap: ButtonFactoryMap = {
    [TASK_TYPE.WATCH_AD]: WatchAdButtonFactory,
    [TASK_TYPE.WATCH_TUTORIAL]: WatchTutorialButtonFactory,
    [TASK_TYPE.WATCH_VIDEO]: WatchVideoButtonFactory,
};

export const DailyTaskCard: React.FC<DailyTaskCardProps> = ({ task, apLevel }) => {
    const { userTaskProgress } = useTaskProvider();
    const taskInfo = task.getTaskInfo(apLevel);
    const taskProgress = userTaskProgress ? userTaskProgress.getTaskProgress(task.taskType, task.code) : null;
    const ButtonFactory = buttonFactoryMap[task.taskType];
    const getIsGrayscale = () => {
        if (!taskProgress) {
            return false;
        }

        if (task.taskType === TASK_TYPE.WATCH_AD) {
            return false;
        }

        return taskProgress.isCompleted;
    };

    if (!taskInfo) {
        return null;
    }

    return <div
        className="d-flex flex-column gap-3 border-bottom border-light-secondary py-3"
        data-testid={`daily-task-card-${apLevel}`}
    >
        <div className="d-flex gap-3 justify-content-between">
            <div>
                <div className="fz-sf-bold-body-1 text-text-primary-1">
                    <FormattedMessage id={task.getTaskTitleLocaleId()} />
                </div>
                <div className="fz-sf-regular-footnote text-light-secondary">
                    <FormattedMessage id={task.getTaskDescriptionLocaleId()} />
                </div>
            </div>
            <div>
                <ButtonFactory
                    apLevel={apLevel}
                    taskProgress={taskProgress}
                    taskInfo={taskInfo}
                    taskConfig={task.taskConfig}
                    taskCode={task.code} />
            </div>
        </div>
        <Reward depositAmount={taskInfo.coin} rubyAmount={taskInfo.ruby} isGrayscale={getIsGrayscale()} />
    </div>;
};
