import { useLoadingToggle } from "components/loading/Loading";
import { UserRepository } from "data/repository/user/UserRepository";
import { MyProfileVo } from "data/vo/user/MyProfileVo";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "store";

import { updateMyProfile } from "store/actions/UserActions";

interface IUseMyProfile {
    myProfile: MyProfileVo;
    getMyProfile: (loading?: boolean) => Promise<null>;
}

function useMyProfile(): IUseMyProfile {
    const dispatch = useDispatch();
    const { UserState: { myProfile }, } = useSelector((store) => store);
    const toggleLoading = useLoadingToggle();
    const userRepository = new UserRepository();

    const { refetch } = useFantasyQuery([QUERY_KEY.MY_PROFILE], async () => {
        const myProfileVo = await userRepository.getMyProfile();
        dispatch(updateMyProfile(myProfileVo));
        return myProfileVo;
    }, {
        options: {
            enabled: false
        }
    });

    const getMyProfile = useCallback(async (loading: boolean = true) => {
        toggleLoading(loading);
        await refetch();
        toggleLoading(false);
        return Promise.resolve(null);
    }, []);

    return {
        myProfile,
        getMyProfile,
    };
}

export default useMyProfile;
