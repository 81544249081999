import ContestCardSkeleton from "../ContestCardSkeleton";
import { WarningAndExplanation } from "../../../domain/match/pages/upcoming/contest/public/PublicContestStyle";
import { CirclePlaceHolder, TextPlaceHolder } from "../SkeletonStyle";
import React from "react";
import { Placeholder } from "react-bootstrap";
import { MatchDetailWrapper } from "../../../domain/match/pages/upcoming/MatchDetailStyle";

const ContestsSkeleton = () => {
    return <MatchDetailWrapper>
        <Placeholder animation="glow">
            <WarningAndExplanation className="align-items-center">
                <TextPlaceHolder $width={12} />
                <CirclePlaceHolder $size={2} />
            </WarningAndExplanation>
        </Placeholder>
        {
            [...Array(5)].map((_, index) => <ContestCardSkeleton key={index} />)
        }
    </MatchDetailWrapper>;
};

export default ContestsSkeleton;
