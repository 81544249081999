import { zodResolver } from "@hookform/resolvers/zod";
import React, { useState } from "react";
import { DeepRequired, FieldErrorsImpl, FormProvider, GlobalError, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { z } from "zod";
import { FantasySelect } from "designToken/textFields/FantasySelect";
import { FantasyTextArea } from "designToken/textFields/FantasyTextArea";
import { Icon } from "helpers/IconHelper";
import { KycVerificationVo } from "domain/kyc/types";
import { KycVerificationProps } from "domain/kyc/pages/kycVerificationForm/type/kyc_verification_props";
import { KycVerificationFormActions } from "domain/kyc/pages/kycVerificationForm/components/KycVerificationFormActions";
import { FantasyCheckbox } from "components/checkbox/FantasyCheckbox";
import { CHECKBOX_VARIANTS } from "components/checkbox/types";
import { Input } from "components/ui/input";
import { cn } from "helpers/cn";

type Step2FormInput = Pick<
    KycVerificationVo,
    "currentAddress" | "permanentAddress" | "countryCode" | "phoneNumber" | "natureOfWork">;

function ContactInfoErrorMessage(props: {
    errors: Partial<FieldErrorsImpl<DeepRequired<Step2FormInput>>> & {
        root?: Record<string, GlobalError> & GlobalError
    },
    phoneNumberVal,
}) {
    if (props.errors.countryCode && !props.phoneNumberVal) {
        return <div className={"tw-flex tw-gap-2 tw-text-caption-1 tw-text-error-700"}>
            <Icon.FormMessageError className={"tw-text-error-700"} />
            <FormattedMessage id="required_error_contact_information" />
        </div>;
    }
    if (props.errors.phoneNumber) {
        return <div className={"tw-flex tw-gap-2 tw-text-caption-1 tw-text-error-700"}>
            <Icon.FormMessageError className={"tw-text-error-700"} />
            <FormattedMessage id="invalid_type_error_contact_information" />
        </div>;
    }
    return null;
}

export const Step2: React.FC<KycVerificationProps> = ({ kycData, onBack, onNext, supportedRegions }) => {
    const intl = useIntl();
    const [sameAboveChecked, setSameAboveChecked] = useState(false);
    const form = useForm<Step2FormInput>({
        resolver: zodResolver(z.object({
            currentAddress: z.string().min(1, {
                message: intl.formatMessage({
                    id: "required_error_current_address"
                })
            }),
            permanentAddress: z.string().min(1, {
                message: intl.formatMessage({
                    id: "required_error_permanent_address"
                })
            }),
            countryCode: z.coerce.string().min(1),
            phoneNumber: z.coerce.number().min(1).positive({
                message: intl.formatMessage({
                    id: "invalid_type_error_contact_information"
                })
            }),
            natureOfWork: z.string().min(1, {
                message: intl.formatMessage({
                    id: "required_error_nature_of_work"
                })
            }),
        })),
        defaultValues: kycData as Step2FormInput,
    });

    const errors = form.formState.errors;

    const validateAndNext = form.handleSubmit(onNext);

    const natureOfWorkOptions = [
        "Administrative & Managerial",
        "Agriculture & Fishing",
        "Creative, Arts & Design",
        "Finance & Accounting",
        "Healthcare, Education & Training",
        "Legal Services",
        "Operations, Logistics & Transportation",
        "Sales, Marketing & Customer Service",
        "Security Services",
        "Technical, Engineering & R&D",
        "Other"
    ];

    return <>
        <FormProvider {...form}>
            <div data-testid="kyc-verification-step2" className={"tw-flex tw-flex-col tw-gap-y-[1.2rem]"}>
                <div className={"tw-text-subtitle-1 tw-text-grey-1000"}>
                    <FormattedMessage id="kyc_verification_form_title_part_with_var" values={{
                        part: 2
                    }} />
                </div>

                <div>
                    <div className={"tw-text-body-1 tw-text-grey-1000 tw-pb-[0.4rem]"}>
                        <FormattedMessage id="label_current_address" />
                        *
                    </div>
                    <FantasyTextArea
                        name="currentAddress"
                        onFocus={() => setSameAboveChecked(false)}
                    />
                </div>

                <div>
                    <div className={"tw-text-body-1 tw-text-grey-1000 tw-pb-[0.4rem]"}>
                        <FormattedMessage id="label_permanent_address" />
                        *
                    </div>
                    <FantasyTextArea
                        name="permanentAddress"
                        onFocus={() => setSameAboveChecked(false)}
                    />
                </div>
                <div className="tw-text-caption-1">
                    <FantasyCheckbox
                        checked={sameAboveChecked}
                        onClick={() => {
                            const currentAddress = form.watch("currentAddress");
                            if (!currentAddress.trim()) {
                                return;
                            }
                            form.setValue("permanentAddress", currentAddress);
                            setSameAboveChecked(true);
                        }}
                        variant={CHECKBOX_VARIANTS.primary}
                    >
                        <FormattedMessage id="label_same_as_above" />

                    </FantasyCheckbox>
                </div>
                <div className={"tw-w-full tw-flex tw-flex-col tw-gap-[0.4rem]"}>
                    <div className={"tw-text-body-1 tw-text-grey-1000"}>
                        <FormattedMessage id="label_contact_information" />
                    </div>
                    <div className={"tw-flex tw-gap-[0.4rem]"}>
                        <Input
                            value={`${form.watch("countryCode")} ${form.watch("phoneNumber")}`}
                            disabled
                            name="contactInformation"
                            className={cn(
                                "tw-w-full tw-rounded-[0.8rem]",
                                "tw-px-[1.2rem] tw-py-[0.8rem]",
                                "focus-visible:tw-outline-0",
                                "tw-border",
                                "tw-placeholder-grey-600 tw-bg-grey-100",
                                "tw-text-body-1 tw-h-[4.8rem]"
                            )}
                        />
                    </div>
                    <ContactInfoErrorMessage errors={errors} phoneNumberVal={form.getValues("phoneNumber")} />
                </div>

                <div>
                    <div className={"tw-text-body-1 tw-text-grey-1000 tw-pb-[0.4rem]"}>
                        <FormattedMessage id="label_nature_of_work" />
                        *
                    </div>
                    <FantasySelect name="natureOfWork" options={natureOfWorkOptions} />
                </div>
            </div>
            <div className={"tw-pt-[2.8rem]"}>
                <KycVerificationFormActions onBack={onBack} onNext={validateAndNext} />
            </div>
        </FormProvider>
    </>;
};

