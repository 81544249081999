import { divideByOneHundred } from "helpers/number/NumberHelper";

export enum SALE_TYPE {
    STANDARD = "STANDARD",
    ON_SALE = "ON_SALE",
}

export class CoinProductVo {
    public readonly id: number;
    public readonly coinCent: number;
    public readonly currencyName: string;
    public readonly currencyCent: number;
    public readonly saleType: SALE_TYPE;
    public readonly image: string;

    private constructor(id: number, coinCent: number, currencyName: string, currencyCent: number, saleType: SALE_TYPE, image: string) {
        this.id = id;
        this.coinCent = coinCent;
        this.currencyName = currencyName;
        this.currencyCent = currencyCent;
        this.saleType = saleType;
        this.image = image;
    }

    get coin() {
        return divideByOneHundred(this.coinCent);
    }

    get currency() {
        return divideByOneHundred(this.currencyCent);
    }

    get isOnSale() {
        return this.saleType === SALE_TYPE.ON_SALE;
    }

    get isStandard() {
        return this.saleType === SALE_TYPE.STANDARD;
    }

    public static create(id: number, coinCent: number, currencyName: string, currencyCent: number, saleType: SALE_TYPE, productImage: string): CoinProductVo {
        return new CoinProductVo(id, coinCent, currencyName, currencyCent, saleType, productImage);
    }

}
