import { bgGradientStyle } from "domain/home/HomeStyle";
import styled from "styled-components";

export const ResultMatchesWrapper = styled.div.attrs({
    className: "d-flex flex-column"
})`
    padding: .8rem 1.6rem;
    min-height: 100%;
    gap: .8rem;
`;

export const ResultMatchesContainer = styled.div.attrs({})`
    ${bgGradientStyle};
`;
