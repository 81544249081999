import FantasyScrollingPopup, {
    ScrollingPopupBody,
    ScrollingPopupFooter,
    ScrollingPopupHeader,
    ScrollingPopupPrimaryButton
} from "components/popup/FantasyScrollingPopup";
import React from "react";
import PlayerAvatar from "../../../components/image/PlayerAvatar";
import { MySlateTeamVo } from "../data/vo";

interface SlateTeamPopupProps {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    team: MySlateTeamVo;
    username: string;
}

const SlateTeamPopup: React.FC<SlateTeamPopupProps> = ({ open, onOpenChange, team, username }) => {
    const handleClose = () => {
        onOpenChange(false);
    };

    return (
        <FantasyScrollingPopup
            open={open}
            onOpenChange={onOpenChange}
            title={<ScrollingPopupHeader title={`${username}'s ${team.name}`} onClose={handleClose} />}
            body={
                <ScrollingPopupBody>
                    {
                        Object.entries(team.teamPlayers).map(([nineCatStat, player]) => {
                            return <div
                                key={player.id}
                                className="tw-mb-3 tw-bg-grey-100 tw-flex tw-items-center tw-px-4 tw-py-2 tw-rounded-lg tw-gap-4 tw-text-grey-1000"
                            >
                                <div className="tw-text-h6 tw-w-10 tw-flex-shrink-0">
                                    {nineCatStat}
                                </div>
                                <div className="tw-flex-1 tw-text-body-2">
                                    {player.firstName} {player.lastName}
                                </div>

                                <div
                                    className="tw-w-[4.8rem] tw-h-[4.8rem] tw-shrink-0 tw-rounded-full tw-overflow-clip tw-bg-primary-800"
                                >
                                    <PlayerAvatar
                                        id={player.id}
                                        url={player.avatar}
                                    />
                                </div>
                            </div>;
                        })
                    }
                </ScrollingPopupBody>
            }
            footer={
                <ScrollingPopupFooter>
                    <ScrollingPopupPrimaryButton onClick={handleClose} />
                </ScrollingPopupFooter>
            }
        />
    );
};

export default SlateTeamPopup;
