import { Col } from "react-bootstrap";
import styled from "styled-components";

export const SpecialPlayerContainer = styled.div.attrs({
    className: "px-0 choose-special-player-container"
})`
`;
export const SpecialPlayerHead = styled.div.attrs({
    className: "p-3 header-bottom-line bg-gray-1 position-sticky"
})`
    top: 13.6rem;
    z-index: 1;
`;
export const SpecialPlayerHeadRow = styled.div.attrs({
    className: "d-flex"
})`
`;

export const SelectedByHeadCol = styled(Col).attrs(() => ({
    className: "col-6 d-flex align-items-center ps-3"
}))`
`;
export const SelectedByHeadText = styled.div.attrs({
    className: "fz-sf-regular-body-1 text-light-secondary"
})`
`;
export const SpecialPlayerHeadCol = styled(Col).attrs(() => ({
    className: "d-flex align-items-center px-0"
}))`
`;
export const SpecialPlayerHeadText = styled.div.attrs({
    className: "fz-sf-regular-subhead text-light-secondary"
})`
`;

export const SelectedSpecialPlayerWrapper = styled.div.attrs({
    className: "d-flex"
})`
    margin: 0 -1.6rem;
`;

export const SelectedSpecialPlayer = styled.div.attrs({
    className: "d-flex flex-grow-1 p-3 gap-3 position-relative overflow-hidden tw-z-1"
})`
`;
