import React from "react";
import {
    ImageWrapper,
    MatchSquadWrapper,
    SquadCrown,
    SquadInfoLeftWrapper,
    SquadInfoRightWrapper,
    SquadLeftName,
    SquadLogoWrapper,
    SquadRightName
} from "domain/match/components/squad/MatchSquadLayoutStyle";
import SquadLogo from "../../../../components/image/SquadLogo";
import { crown } from "helpers/IconHelper";
import { MatchStatusContainer } from "../../pages/started/MyContestDetailStyle";
import MatchStatusWithDot from "../status/MatchStatusWithDot";
import { MATCH_STATUS_FROM_API } from "../../../../types/match/Match";

const MatchSquadLayout = ({ matchDetail, squadAScore, squadBScore }) => {
    const {
        matchStatus,
        winningSquadCode,

        squadAName,
        squadALogoUrl,
        squadACode,

        squadBName,
        squadBLogoUrl,
        squadBCode
    } = matchDetail;

    const renderCompletedCrown = squadCode =>
        matchStatus === MATCH_STATUS_FROM_API.COMPLETED && winningSquadCode === squadCode
            ? <SquadCrown $bgImg={crown} />
            : null;

    return <MatchSquadWrapper>
        <SquadInfoLeftWrapper>
            <div>
                <SquadLeftName>{squadAName}</SquadLeftName>
                {squadAScore}
            </div>
            <ImageWrapper>
                <SquadLogoWrapper>
                    {renderCompletedCrown(squadACode)}
                    <SquadLogo url={squadALogoUrl} name={squadAName} />
                </SquadLogoWrapper>
            </ImageWrapper>
        </SquadInfoLeftWrapper>
        <MatchStatusContainer>
            <MatchStatusWithDot matchStatus={matchStatus} />
        </MatchStatusContainer>
        <SquadInfoRightWrapper>
            <ImageWrapper>
                <SquadLogoWrapper>
                    {renderCompletedCrown(squadBCode)}
                    <SquadLogo url={squadBLogoUrl} name={squadBName} />
                </SquadLogoWrapper>
            </ImageWrapper>
            <div>
                <SquadRightName>{squadBName}</SquadRightName>
                {squadBScore}
            </div>
        </SquadInfoRightWrapper>
    </MatchSquadWrapper>;
};

export default MatchSquadLayout;
