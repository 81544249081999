import { analyticsEvent, logCustomEvent } from "ga";
import { circleCloseIcon, promotionalCodeIcon } from "helpers/IconHelper";
import { getDataFromLocalStorage, setDataToLocalStorage } from "helpers/localStorage/LocalStorageHelper";
import { LOCAL_STORAGE_KEYS } from "helpers/localStorage/types";
import React, { useRef, useState } from "react";
import { MdOutlineRedeem } from "react-icons/md";
import { CSSTransition } from "react-transition-group";
import {
    ClosePromotionalButton,
    PromotionalCode,
    PromotionalCodeWrapper,
    PromotionalToggle
} from "./B2CPromotionalStyle";

interface PromotionalIconProps {
    setIsPromotionalPanelOpened: (isOpened: boolean) => void
}

export const B2CPromotionalIcon: React.FC<PromotionalIconProps> = ({
                                                                       setIsPromotionalPanelOpened,
                                                                   }) => {
    const [isPromotionalCodeOpened, setIsPromotionalCodeOpened] = useState(getDataFromLocalStorage(LOCAL_STORAGE_KEYS.PROMOTIONAL_CODE_OPENED) ?? true);
    const promotionalIconRef = useRef(null);
    const promotionalToggleRef = useRef(null);
    const initClass = useRef(isPromotionalCodeOpened
        ? {
            icon: "promotional-icon-exit",
            toggle: "promotional-icon-enter"
        }
        : {
            icon: "promotional-icon-enter",
            toggle: "promotional-icon-exit"
        }
    );

    return <>
        <CSSTransition
            in={isPromotionalCodeOpened}
            classNames="promotional-icon"
            nodeRef={promotionalIconRef}
            timeout={400}
        >
            <PromotionalCodeWrapper
                data-testid="promotional-code-wrapper"
                ref={promotionalIconRef}
                className={initClass.current.icon}
            >

                <PromotionalCode
                    data-testid="promotional-code"
                    src={promotionalCodeIcon}
                    onClick={() => {
                        logCustomEvent(analyticsEvent.webClickPromotionalIcon);
                        setIsPromotionalPanelOpened(true);
                    }}
                />
                <ClosePromotionalButton
                    data-testid="promotional-code-close"
                    src={circleCloseIcon}
                    onClick={() => {
                        setIsPromotionalCodeOpened(false);
                        setDataToLocalStorage(LOCAL_STORAGE_KEYS.PROMOTIONAL_CODE_OPENED, false);
                    }}
                />

            </PromotionalCodeWrapper>
        </CSSTransition>
        <CSSTransition
            in={!isPromotionalCodeOpened}
            classNames="promotional-icon"
            nodeRef={promotionalToggleRef}
            timeout={400}
        >
            <PromotionalToggle
                data-testid="promotional-close-icon"
                ref={promotionalToggleRef}
                className={initClass.current.toggle}
                onClick={() => {
                    logCustomEvent(analyticsEvent.webClickPromotionalIcon);
                    setIsPromotionalCodeOpened(true);
                    setDataToLocalStorage(LOCAL_STORAGE_KEYS.PROMOTIONAL_CODE_OPENED, true);
                }}
            >
                <MdOutlineRedeem size="2rem" />
            </PromotionalToggle>
        </CSSTransition>
    </>;
};
