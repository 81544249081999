import { cricketQuickSummarySyncBg, Icon } from "helpers/IconHelper";
import { FormattedMessage } from "react-intl";
import React from "react";

export const CricketQuickSummarySynchronizing = () => <div className="tw-relative -tw-mx-4 tw-mt-2 -tw-mb-1.5">
    <img
        src={cricketQuickSummarySyncBg}
        alt="cricket-quick-summary-sync-bg"
        className="tw-w-full tw-h-auto"
        draggable={false}
    />
    <div
        className="tw-absolute tw-top-[50%] tw-left-[50%] -tw-translate-x-1/2 -tw-translate-y-1/2 tw-flex tw-gap-1 tw-flex-col tw-items-center">
        <Icon.Loader />
        <p className="tw-text-gray-700 tw-text-body-2">
            <FormattedMessage id="cricket_quick_summary_snapshot_sync" />
        </p>
    </div>
</div>;
