import React from "react";
import { Placeholder } from "react-bootstrap";
import { TextPlaceHolder } from "../SkeletonStyle";

const ResultContestCardSkeleton = () => {

    return <Placeholder animation="glow" className="tw-w-full tw-border tw-bg-white">
        <div className="tw-space-y-2 tw-p-4 tw-text-left ">
            <div className="tw-flex">
                <TextPlaceHolder $height={1.2} $width={10.8} $radius={999} $bg="tw-bg-grey-100" />
            </div>
            <div className="tw-space-y-1">
                <div className="tw-flex tw-justify-between">
                    <TextPlaceHolder $height={1.2} $width={4} $radius={999} $bg="tw-bg-grey-100" />
                    <TextPlaceHolder $height={1.2} $width={4} $radius={999} $bg="tw-bg-grey-100" />
                </div>
                <div className="tw-flex tw-justify-between">
                    <TextPlaceHolder $height={1.2} $width={4} $radius={999} $bg="tw-bg-grey-100" />
                    <TextPlaceHolder $height={1.2} $width={4} $radius={999} $bg="tw-bg-grey-100" />
                </div>
            </div>
            <div className="tw-space-y-1">
                <div className="tw-flex">
                    <TextPlaceHolder $height={0.4} $width={50} $radius={999} $bg="tw-bg-grey-100" />
                </div>
                <div className="tw-flex tw-justify-between">
                    <TextPlaceHolder $height={1.2} $width={4} $radius={999} $bg="tw-bg-grey-100" />
                    <TextPlaceHolder $height={1.2} $width={4} $radius={999} $bg="tw-bg-grey-100" />
                </div>
            </div>


        </div>
    </Placeholder>;
};

export default ResultContestCardSkeleton;
