import useCurrency from "hooks/useCurrency/useCurrency";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";

interface ClaimedCountUpProps {
    amount: number;
}

export const ClaimedCountUp: React.FC<ClaimedCountUpProps> = ({ amount }) => {
    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();

    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(0);

    useEffect(() => {
        setEnd(preEnd => {
            setStart(preEnd);
            return amount;
        });
    }, [amount]);

    return <CountUp
        className="fz-sf-bold-title-lg"
        start={start}
        end={end}
        formattingFn={(amount) => toFormattedNumber(amount)}
    />;
};
