import { multiplyByOneHundred } from "helpers/number/NumberHelper";
import { Image } from "react-bootstrap";
import styled from "styled-components";
import { APP_MIN_WIDTH_VALUE } from "../../styles/Constants";

export const EmptyWrapper = styled.div.attrs({
    className: "d-flex flex-column justify-content-center align-items-center"
})`
`;
export const EmptyImg = styled(Image).attrs({})`
    width: ${Math.round(multiplyByOneHundred(200 / APP_MIN_WIDTH_VALUE))}%;
`;
export const EmptyText = styled.div.attrs({
    className: "fz-sf-regular-subhead text-center text-text-primary-1"
})`
  * {
    font-size: inherit;
  }
`;
