import { MyApOverview } from "types/activityPoint/MyApOverview";

export const getSlideStyle = (myApOverview: MyApOverview | undefined, apLevel: {
    weight: number
}, isCurrent: boolean) => {
    if (!myApOverview) {
        return {
            gap: "",
            circle: "",
            text: "",
            lock: ""
        };
    }

    const isReachLevel = myApOverview.currentLevelWeight >= apLevel.weight;

    if (isReachLevel && isCurrent) {
        return {
            gap: "gap-3",
            circle: "bg-sys-special-ap shadow-reach-ap-level",
            text: "fz-sf-bold-caption-1 text-sys-special-ap",
            lock: "d-none"
        };
    }

    if (isReachLevel && !isCurrent) {
        return {
            gap: "gap-2",
            circle: "bg-sys-special-ap",
            text: "fz-sf-regular-caption-1 text-sys-special-ap",
            lock: "d-none"
        };
    }

    if (!isReachLevel && isCurrent) {
        return {
            gap: "gap-3",
            circle: "bg-default-tertiary-2 shadow-none-reach-ap-level",
            text: "fz-sf-bold-caption-1 text-text-tertiary-1",
            lock: "text-text-secondary-2"
        };
    }


    return {
        gap: "gap-2",
        circle: "bg-default-secondary-1",
        text: "fz-sf-regular-caption-1 text-text-quaternary-1",
        lock: "text-text-quaternary-1"
    };
};
