import { Image } from "react-bootstrap";
import styled from "styled-components";
import { APP_MAX_WIDTH, APP_MIN_WIDTH_VALUE } from "styles/Constants";

export const FirstPrizeWrapper = styled.div.attrs({
    className: "d-flex ps-1 fz-sf-bold-body-2"
})`
  span.us-dollar {
    order: 1;
    font: inherit;
  }
`;
export const FirstPrizeText = styled.div.attrs({
    className: "pe-1"
})`
  font: inherit;
`;

export const RankingWrapper = styled.div.attrs({
    className: "w-100 d-flex flex-column align-items-center"
})``;
export const RankingImageWrapper = styled.div.attrs({
    className: "text-center"
})`
  // image width 200px / container paddingX 8px
  width: calc(200 / (${APP_MIN_WIDTH_VALUE} - 16) * 100%);
  padding-top: 1.6rem;
  @media (min-width: ${APP_MAX_WIDTH}) {
    padding-top: 4.8rem;
  }
`;
export const RankingImage = styled(Image).attrs({
    className: "w-100"
})`
`;
export const RankingText = styled.div.attrs({
    className: "tw-text-subtitle-2 d-flex"
})`
`;
