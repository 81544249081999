// carousel

import { SPORT } from "types/sports/Sport";
import { TASK_TYPE } from "types/task/TaskType";

export const fetchAdUrl = (sport: SPORT, language: string): string => `/${sport}/ad?platform=WEB&language=${language}`;

// ap
export const fetchMyApOverviewUrl = "/ap/my/overview";
export const fetchMyApInfoUrl = "/ap/my/info";
export const fetchCurrentApLevelListUrl = "/ap/level";

// chat
export const fetchChatTokenUrl = "/chat/token";

// contest
export const getContestCodeDetailUrl = (contestCode: string) => `/contest/${contestCode}/detail`;
export const createContestUrl = "/contest/private";
export const getJoinContestUrl = (contestId) => `/teams/join/contest/${contestId}`;
export const getRubyJoinContestUrl = (contestId) => `/teams/join/ruby/contest/${contestId}`;

export const getFeaturedContestsUrl = (sport: SPORT) => `/${sport}/contest/featured`;
export const getFeaturedContestsDailyUrl = (sport: SPORT) => `/${sport}/contest/featured/daily`;
export const getShareContestUrl = (contestId: number) => `/contest/${contestId}/share`;

//season
export const fetchBasketballCurrentSeasonUrl = '/basketball/season/current';

// deposit
export const fetchCoinAmountUrl = "/product/coin";
export const fetchPaymentMethodUrl = "/payment/triplea";
export const initializeCryptocurrencyPaymentUrl = "/order/triplea/init";
export const initializeUpayPaymentUrl = "/order/upay/init";
export const fetchOrderStatusUrl = (paymentReference) => `/order/triplea/${paymentReference}`;
export const firstTimePurchase = "/offering/first-time-purchase/my";

// login
export const loginUrl = "/login";

// my
export const getUpcomingMatchesUrl = (sport) => `/${sport}/match/upcoming`;
export const getPublicContestUrl = (sport, matchId) =>
    `/${sport}/match/${matchId}/contest/public`;
export const getMyContestUrl = (sport, matchId) =>
    `/${sport}/match/${matchId}/contest/my`;
export const getMatchSnapshotUrl = (sport, matchId) =>
    `/${sport}/match/${matchId}/snapshot`;
export const getMyTeamUrl = (sport, matchId) =>
    `/${sport}/match/${matchId}/team/my`;
export const getMatchDetailUrl = (sport, matchId) =>
    `/${sport}/match/${matchId}/detail`;
export const getSquadUrl = (sport, matchId) =>
    `/${sport}/match/${matchId}/squad/all`;
export const getMyMatchesUrl = (sport) => `/${sport}/matches/my`;
export const getScorecardUrl = (matchId) => `/match/${matchId}/scorecard`;
export const getPlayerStatsUrl = (sport: SPORT, matchId: string) => `/${sport}/match/${matchId}/player-stats`;
export const getMyMatches = "/matches/my";
export const getMyNineCatSlates = (sport) => `/${sport}/nine-cat/contest/my`;

// referral
export const fetchAvailableReferralCodeUrl = "/referral-code/available";
export const fetchMyReferralCodeUrl = "/referral-code/my";
export const sendReferralCodeUrl = "/referral-code";
export const fetchRefereeRubySummaryUrl = "/referee/ruby-summary";
export const fetchRefereeUrl = "/referee";

// reward
export const fetchRewardListUrl = "/reward";
export const getRewardUrl = (rewardId) => `/reward/${rewardId}`;
export const getBuyRewardUrl = (rewardId) => `/reward/${rewardId}/purchase`;
export const fetchRewardOrdersUrl = "/reward/order";

// setting
export const fetchSettingUrl = "/setting";

// team
// export const getCreateTeamUrl = (sport: SPORT): string => `/${sport}/team`;
export const getCreateTeamUrl = (sport: SPORT, matchId: string): string => `/${sport}/match/${matchId}/team`;
export const getEditTeamUrl = (sport: SPORT, teamId: number) => `/${sport}/team/${teamId}`;
export const getTeamUrl = (teamId: number) => `/team/${teamId}`;

// user
export const fetchMyProfileUrl = "/user/profile";
export const fetchFriendsUrl = "/friend";
export const getUserProfileUrl = (userId) => `/user/${userId}/profile`;
export const getPlayingRecordUrl = (userId) => `/user/${userId}/playing-record`;
export const updateMyProfileUrl = "/user/profile";
export const getCareerStats = "/user/career-stats";
export const fetchUserStatsUrl = (userId: number, time: number) => `/user/${userId}/career-stats?time=${time}`;

export const getRubyTransactionDetailUrl = (historyId: number): string => `/wallet/ruby/history/${historyId}`;

export const getAddFriendUrl = (userId): string => `/friend/${userId}`;
export const getUnfriendUrl = (userId): string => `/friend/${userId}`;
export const getFollowUrl = (userId: number) => `/user/${userId}/follow`;
export const fetchMyTransferredUrl = "/winning/transfer/my";
export const getTransferUrl = (friendId: number): string => `/winning/transfer/${friendId}`;
// wallet
export const fetchWalletUrl = "/wallet";
export const fetchWalletHistoryUrl = "/wallet/history";
export const getTransactionDetailUrl = (historyId: number): string => `/wallet/history/${historyId}`;

export const transferWinningToDepositUrl = "/winning/transfer/deposit";


export const fetchMyCouponUrl = "/coupon/my";

// reminder
export const fetchRubyContestChampionReminderUrl = "/user/reminder/ruby-contest-champion";
export const fetchDailyCheckInReminderUrl = "/user/reminder/daily-check-in";

// task

export const fetchTasksUrl = "/tasks";

export const fetchTaskPrizeClaimedUrl = "/task/prize/claimed";

export const fetchTaskProgressUrl = "/task/progress";


export const getTaskFinishUrl = (taskType: TASK_TYPE, code: string) => `/task/${taskType}/${code}/finish`;
export const getTaskClaimUrl = (taskType: TASK_TYPE, code: string) => `/task/${taskType}/${code}/claim`;
export const getQuestCompleteUrl = (taskType: TASK_TYPE, code: string) => `/quest/${taskType}/${code}/complete`;
export const dailyCheckInQuestUrl = `/quest/daily-check-in`;

export const deleteUserSubmitUrl = "/user/delete/submission";
export const getDeleteUserUrl = (code: string) => `/user/delete/${code}`;
export const fetchSupportedRegionUrl = "/supported-regions";

export const fetchResponsibleGamingRegistrationUrl = "/responsible-gaming-registration";

// auth
export const fetchAuthConfigUrl = "/auth/config";
export const validateSession = "/user/validate-session";
