import { divideByOneHundred } from "helpers/number/NumberHelper";
import React from "react";
import { FormattedMessage } from "react-intl";
import Button from "../../../../designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, NEW_BUTTON_VARIANTS } from "../../../../designToken/button/types";
import { AP_LEVEL } from "../../../../types/activityPoint/ApLevel";
import DailyCheckInDefaultPopup, {
    dailyCheckInDefaultPopupConfirmButton
} from "../../../quest/DailyCheckInPopup/component/DailyCheckInDefaultPopup";
import DailyCheckInUpgradePopup, {
    dailyCheckInUpgradePopupConfirmButton
} from "../../../quest/DailyCheckInPopup/component/DailyCheckInUpgradePopup";
import DailyCheckInPopupProvider, {
    useDailyCheckInPopup,
} from "../../../quest/DailyCheckInPopup/DailyCheckInPopupProvider";
import { Reward } from "./Reward";

export const DailyCheckInTaskCardWithPopup = ({ apLevel }: { apLevel: AP_LEVEL }) => {
    return <DailyCheckInPopupProvider>
        <DailyCheckInTaskCard apLevel={apLevel} />
        <QuestDailyCheckInDefaultPopup />
        <DailyCheckInUpgradePopup
            confirmButtonSetting={dailyCheckInUpgradePopupConfirmButton.none}
        />
    </DailyCheckInPopupProvider>;
};

const DailyCheckInTaskCard = ({ apLevel }: { apLevel: AP_LEVEL }) => {
    const context = useDailyCheckInPopup();

    const params = context.myApLevel.currentLevel === apLevel
        ? {
            daysInRow: context.myDaysInRow,
            rubyCent: context.todayPrize.rubyCent,
            coinCent: context.todayPrize.coinCent,
            isGrayScale: true,
            disabled: false,
        }
        : {
            daysInRow: context.myDaysInRow,
            ...context.getPrize(apLevel, context.todayDaysInRow),
            isGrayScale: false,
            disabled: true,
        };

    return <div
        className="d-flex flex-column gap-3 border-bottom border-light-secondary py-3">
        <div className="d-flex gap-3 justify-content-between">
            <div>
                <div className="fz-sf-bold-body-1 text-text-primary-1">
                    <FormattedMessage id="daily_quest_item_title_daily_check_in" />
                </div>
                <div className="fz-sf-regular-footnote text-light-secondary">
                    <FormattedMessage
                        id="daily_quest_item_description_daily_check_in"
                        values={{ days: params.daysInRow }} />
                </div>
            </div>
            <Button
                disabled={params.disabled}
                className="position-relative text-nowrap px-0 tw-w-[120px]"
                variant={NEW_BUTTON_VARIANTS.primaryUltraLight}
                pattern={BUTTON_PATTERN.pill}
                size={BUTTON_SIZE.md}
                onClick={() => context.setIsShow(true)}
            >
                <FormattedMessage id="label_view" />
            </Button>
        </div>
        <Reward depositAmount={divideByOneHundred(params.coinCent)}
                rubyAmount={divideByOneHundred(params.rubyCent)}
                isGrayscale={params.isGrayScale} />
    </div>;
};

const QuestDailyCheckInDefaultPopup = () => {
    const { isShowDefaultPopup: show, setIsShow } = useDailyCheckInPopup();

    return <DailyCheckInDefaultPopup
        confirmButtonSetting={dailyCheckInDefaultPopupConfirmButton.none}
        show={show}
        onHide={() => setIsShow(false)}
    />;
};
