import { useWithdrawContext } from "domain/withdraw/WithdrawProvider";
import useFantasyConfig from "hooks/useFantasyConfig";
import {
    FORM_FIELD_ACCOUNT_BANK,
    FORM_FIELD_ACCOUNT_NAME,
    FORM_FIELD_ACCOUNT_NUMBER
} from "domain/withdraw/hooks/useWithdrawStep1Form";

export function useDevAutofillAccount() {
    const { devToggle } = useFantasyConfig();

    const { step1Form, step2Form } = useWithdrawContext();

    function handleDevAutoFillAccount() {
        if (!devToggle) return;
        step1Form.setValue(FORM_FIELD_ACCOUNT_NAME, "Juan Dela Cruz");
        step1Form.setValue(FORM_FIELD_ACCOUNT_NUMBER, "999999990001");
        step1Form.setValue(FORM_FIELD_ACCOUNT_BANK, "UBPHPHMMXXX");
        step2Form.setValue("withdrawAmount", 50);
    }

    return handleDevAutoFillAccount;
}
