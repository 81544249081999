import React from "react";
import { MdOutlineContentCopy, MdOutlineEdit } from "react-icons/md";
import { useSelector } from "../../../store";
import useTeamPreview from "../useTeamPreview";
import { TeamPreviewIconButton } from "./TeamPreviewStyle";

const TeamPreviewOperationIcons: React.FC = () => {
    const {
        TeamPreviewState: {
            config: {
                onEditTeam,
                onCreateTeam,
            }
        }
    } = useSelector(state => state);
    const teamPreview = useTeamPreview();

    const handleEdit = () => {
        onEditTeam(teamPreview.getCurrentTeam());
    };

    const handleCopy = () => {
        onCreateTeam(teamPreview.getCurrentTeam());
    };

    return <div className="d-flex gap-3 text-text-primary-2" data-testid="team-preview-operation-icons">
        <TeamPreviewIconButton
            onClick={handleEdit}
        >
            <MdOutlineEdit
                size="2rem"
            />
        </TeamPreviewIconButton>
        <TeamPreviewIconButton
            onClick={handleCopy}
        >
            <MdOutlineContentCopy
                size="2rem"
            />
        </TeamPreviewIconButton>
    </div>;
};

export default TeamPreviewOperationIcons;
