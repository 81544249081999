import { Container } from "react-bootstrap";
import styled, { css } from "styled-components";
import { SYSTEM_COLORS } from "styles/Constants";

export const RewardOrderHistoryWrapper = styled.div.attrs({
    className: "w-100 bg-light-primary"
})`
  min-height: calc(100vh - 11.7rem);
  padding: 1.6rem 1.4rem;
  border-radius: 1.6rem 1.6rem 0 0;
`;
export const RewardOrderCard = styled(Container).attrs(() => ({}))`
  padding: 1.6rem;
  border: 1px solid #E5E5E5;
  border-radius: .5rem;
  margin-bottom: 1.2rem;
`;
export const RewardOrderCardRow = styled.div.attrs(() => ({
    className: "d-flex flex-column"
}))`
  border-bottom: 1px dashed #CCCCCC;
  padding: 1.2rem 0;
  margin-bottom: 1.2rem;
`;
export const RewardOrderCardTitle = styled.div.attrs(() => ({
    className: "d-flex justify-content-between"
}))`
  padding-bottom: 1.2rem;
`;
export const RewardOrderCardCode = styled.div.attrs(() => ({
    className: "fz-sf-bold-body-2"
}))`
`;
export const RewardOrderCardStatus = styled.div.attrs(() => ({
    className: "fz-sf-regular-subhead"
}))`
`;
export const RewardOrderCardTime = styled.div.attrs(() => ({
    className: "fz-sf-regular-subhead"
}))`
`;
export const RewardOrderHistoryTabWrapper = styled.div.attrs({
    className: "d-flex justify-content-between align-items-center tw-bg-system-bgLightTertiary"
})`
  margin: 0 1.6rem 2rem;
  padding: .2rem .3rem;
  border-radius: .8rem;
`;
export const RewardOrderHistoryTab = styled.div.attrs<{ $active: boolean }>(({ $active }) => ({
    className: `d-flex justify-content-center align-items-center w-100 
    ${$active ? "fz-sf-bold-body-2 text-primary-2" : "fz-sf-regular-body-2"}`
}))`
  padding: .4rem 0;
  cursor: pointer;
  border-radius: .6rem;
  ${({ $active }) => {
    if ($active) {
        return css`
        background: linear-gradient(180deg, ${SYSTEM_COLORS.bgLightPrimary} 0%, ${SYSTEM_COLORS.bgLightSecondary} 100%);
      `;
    }
}};
`;
