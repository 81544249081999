import { divideByOneHundred } from "helpers/number/NumberHelper";
import { ENTRY_FEE_TYPE_FROM_API } from "../../../types/contest/Contest";
import { MATCH_STATUS_FROM_API } from "../../../types/match/Match";

export class FeaturedContestVo {
    code: string;
    entryFeeType: ENTRY_FEE_TYPE_FROM_API;
    entryFee: number;
    squadALogoUrl: string;
    squadAName: string;
    squadBLogoUrl: string;
    squadBName: string;
    matchStartsAt: number;
    matchStatus: MATCH_STATUS_FROM_API.SCHEDULED | MATCH_STATUS_FROM_API.KICKOFF;

    constructor(featuredContestDto) {
        this.code = featuredContestDto.code;
        this.entryFeeType = featuredContestDto.entryFeeType;
        this.entryFee = divideByOneHundred(featuredContestDto.entryFeeCent);
        this.squadALogoUrl = featuredContestDto.squadALogoUrl;
        this.squadAName = featuredContestDto.squadAName;
        this.squadBLogoUrl = featuredContestDto.squadBLogoUrl;
        this.squadBName = featuredContestDto.squadBName;
        this.matchStartsAt = featuredContestDto.matchStartsAt;
        this.matchStatus = featuredContestDto.matchStartsAt > Date.now() ? MATCH_STATUS_FROM_API.SCHEDULED : MATCH_STATUS_FROM_API.KICKOFF;
    }
}
