import { FriendActionButton } from "domain/friend/profile/components/follow/FriendActionButton";
import { mobileUnverifiedIcon, mobileVerifiedIcon } from "helpers/IconHelper";
import { hasText } from "helpers/string/StringHelper";
import useScrollDetector from "hooks/useScrollDetector";
import React, { useRef, useState } from "react";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { CSSTransition } from "react-transition-group";
import UserAvatarWithFrame from "../../../../components/avatar/UserAvatarWithFrame";
import Navbar from "../../../../components/container/navbar/Navbar";
import { UserProfileVo } from "../../../../data/vo/user/UserProfileVo";
import { AP_LEVEL } from "../../../../types/activityPoint/ApLevel";
import { apLevelConfig } from "../../../ap/ActivityPointHelper";
import { MobileUnVerificationStatusIcon, MobileVerificationStatusIcon } from "../../../me/me/MeStyle";
import {
    LevelTag,
    Nickname,
    UserDescriptionAll,
    UserDescriptionShort,
    UserInfoDetailWrapper,
    UserInfoFollowWrapper,
    UserInfoTextWrapper,
    UserProfileAvatarWrapper
} from "../UserProfileStyle";
import useUserProfileContext from "../useUserProfileContext";
import { FollowInfo } from "./follow/FollowInfo";


interface UserMobileVerificationIconProps {
    hasMobileNumber: boolean;
}

const UserMobileVerificationIcon: React.FC<UserMobileVerificationIconProps> = ({
                                                                                   hasMobileNumber,
                                                                               }) => {
    return hasMobileNumber
        ? <MobileVerificationStatusIcon className="z-2" $bgImg={mobileVerifiedIcon} cursor="auto" />
        : <MobileUnVerificationStatusIcon className="z-2" $bgImg={mobileUnverifiedIcon} cursor="auto" />;
};


interface DescriptionProps {
    description: string;
}

const Description: React.FC<DescriptionProps> = ({ description }) => {
    const [isDescriptionOpened, setIsDescriptionOpened] = useState(false);

    const handleExpandMore = () => {
        setIsDescriptionOpened(true);
    };
    const handleExpandLess = () => {
        setIsDescriptionOpened(false);
    };

    return <div className="d-flex mt-2 text-dark-primary  justify-content-between">
        {
            isDescriptionOpened
                ? <>
                    <UserDescriptionAll>{description}</UserDescriptionAll>
                    <div>
                        <MdExpandLess className="text-dark-primary clickable"
                                      size="1.6rem"
                                      onClick={handleExpandLess}
                        />
                    </div>
                </>
                : <>
                    <UserDescriptionShort>{description}</UserDescriptionShort>
                    <div>
                        <MdExpandMore className="text-dark-primary clickable"
                                      size="1.6rem"
                                      onClick={handleExpandMore}
                        />
                    </div>
                </>
        }
    </div>;
};

const ScrolledNavbar: React.FC<{ user: UserProfileVo }> = ({ user }) => {
    return <div className="ms-2 d-flex align-items-center">
        <UserAvatarWithFrame
            font="fz-sf-regular-footnote"
            url={user.avatar}
            name={user.nickname}
            size="2rem"
        />
        <div className="ms-2 text-text-primary-1 fz-sf-bold-body-1">{user.nickname}</div>
    </div>;
};

export const UserProfileDetail: React.FC = () => {
    const { userInfo: user, isReachedFollowingLimit, refetchUserInfo } = useUserProfileContext();

    const smallNavbarRef = useRef(null);

    const shouldShowSmallNavbar = useScrollDetector(30);

    return <UserInfoDetailWrapper $bgImg={apLevelConfig[user?.currentLevel || AP_LEVEL.BEGINNER]?.bg}>
        <CSSTransition in={shouldShowSmallNavbar}
                       nodeRef={smallNavbarRef}
                       classNames="my-matches-navbar"
                       timeout={400}
                       unmountOnExit
        >
            <Navbar ref={smallNavbarRef}
                    className="base-shadow-light-60"
                    isTransparent={false}
                    isHeaderLine={false}
                    navBackIconColor="text-light-primary"
                    shouldCalculatePaddingTop={false}
                    navbarStartAppend={<ScrolledNavbar user={user} />}
            />
        </CSSTransition>
        <Navbar className="position-relative"
                isTransparent={true}
                isHeaderLine={false}
                navBackIconColor="text-text-primary-2"
                shouldCalculatePaddingTop={false}
        />
        <div className="px-4">
            <div className="d-flex">
                <div className="me-3">
                    <UserProfileAvatarWrapper $isBeginner={user.currentLevel === AP_LEVEL.BEGINNER}>
                        <UserAvatarWithFrame
                            font="fz-sf-regular-title-lg"
                            url={user.avatar}
                            name={user.nickname}
                            size="8rem"
                            currentLevel={user.currentLevel}
                        />
                        <UserMobileVerificationIcon
                            hasMobileNumber={user.hasMobileNumber}
                        />
                    </UserProfileAvatarWrapper>
                </div>
                <UserInfoTextWrapper>
                    <div className="d-flex">
                        <Nickname>{user.nickname}</Nickname>
                        <LevelTag>{user.currentLevel}</LevelTag>
                    </div>
                    <div className="fz-sf-regular-subhead text-dark-secondary">
                        {user.userCode}
                    </div>

                    {
                        hasText(user.description) &&
                        <Description description={user.description} />
                    }
                </UserInfoTextWrapper>
            </div>
            <UserInfoFollowWrapper>
                <FollowInfo userId={user.id} />
                <div className="align-self-center">
                    <FriendActionButton user={user}
                                        isReachedFollowingLimit={isReachedFollowingLimit}
                                        onRefetchUserInfo={refetchUserInfo} />
                </div>
            </UserInfoFollowWrapper>
        </div>
    </UserInfoDetailWrapper>;

};
