import styled from "styled-components";
import { walletWithdrawLockedImg } from "../../helpers/IconHelper";

export const StyledCircleButton = styled.button.attrs({
    className: "position-relative border-0 p-0 d-flex align-items-center"
})`
    background-color: transparent;
    flex-shrink: 0;
    flex-direction: column;
    width: 25%;
`;

export const StyledCircleButtonImg = styled.img.attrs<{ $image: string }>({
    className: ""
})`
    width: 4.8rem;
    padding-bottom: 4.8rem;
    background: url(${({ $image }) => $image}) center no-repeat;
    background-size: 100%;
`;

export const StyledCircleButtonLabel = styled.p.attrs({
    className: "mb-0 fz-sf-regular-subhead text-light-primary"
})``;

export const StyledCircleButtonBadge = styled.div.attrs({
    className: "fz-sf-regular-footnote tw-absolute tw-bg-system-danger500 tw-text-grey-0 tw-top-0 tw-right-0 tw-translate-x-2/3 -tw-translate-y-1/2 tw-rounded-full tw-w-[4.8rem] tw-text-center tw-py-0.5 tw-px-0"
})``;

export const StyledWithdrawLockedBadge = styled.div.attrs({
    className: "tw-absolute tw-top-0 tw-right-0 tw-translate-x-[40%] -tw-translate-y-[30%] tw-rounded-full tw-h-[2.2rem] tw-w-[2.2rem]"
})`
    background: url(${walletWithdrawLockedImg}) center no-repeat;
`;
