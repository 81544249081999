import { KycLeavePopupProvider } from "domain/kyc/components/kycLeavePopup/KycLeavePopupProvider";
import KycOtp from "domain/kyc/pages/kycOtp/KycOtp";
import KycVerificationForm from "domain/kyc/pages/kycVerificationForm/KycVerificationForm";
import KycVerificationProgress from "domain/kyc/pages/KycVerificationProgress";
import KycVerificationSuccess from "domain/kyc/pages/KycVerificationSuccess";
import useMyProfile from "hooks/useMyProfile";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";

const Kyc = () => {
    const { myProfile } = useMyProfile();

    if (!myProfile) return null;

    return (
        <Switch>
            <Route exact path={ROUTER_PATH_CONFIG.verifyKyc.form}>
                <KycLeavePopupProvider>
                    {
                        myProfile.hasMobileNumber
                            ? <KycVerificationForm />
                            : <KycOtp />
                    }
                </KycLeavePopupProvider>
            </Route>
            <Route exact path={ROUTER_PATH_CONFIG.verifyKyc.progress}>
                <KycVerificationProgress />
            </Route>
            <Route exact path={ROUTER_PATH_CONFIG.verifyKyc.success}>
                <KycVerificationSuccess />
            </Route>
            <Redirect to={ROUTER_PATH_CONFIG.home} />
        </Switch>
    );
};

export default Kyc;
