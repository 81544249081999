export interface SendToInfo {
    selectedBank: string;
    accountName: string;
    accountNumber: string;
}

export enum WithdrewResult {
    PROGRESS = "PROGRESS",
    DECLINED = "DECLINED"
}
