import { Col } from "react-bootstrap";
import styled from "styled-components";

export const SpecialPlayerCardContainer = styled.div.attrs({
    className: "px-3 pt-2 border-light-secondary"
})`
    border-bottom: 1px solid;
`;
export const SpecialPlayerCardRow = styled.div.attrs({
    className: "d-flex align-items-center"
})`
`;
export const PlayerInfoCol = styled(Col).attrs({
    className: "col-6 d-flex"
})``;

export const SpecialPoints = styled.div.attrs({
    className: "text-end fz-sf-regular-subhead"
})`
    margin-right: 1.4rem;
`;

export const SpecialRoleCol = styled(Col).attrs({
    className: "d-flex flex-column align-items-center px-0"
})``;
export const SpecialRoleCircle = styled.div.attrs(() => ({
    className: "rounded-circle mx-auto text-center fz-sf-regular-title-3 tw-text-primary-700 tw-bg-primary-100"
}))`
    width: 40px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
`;
export const SpecialSelectionRate = styled.div.attrs({
    className: "fz-sf-regular-caption-1 text-light-secondary"
})`
    padding-top: .4rem;
`;
export const SpecialRoleCircleSelected = styled(SpecialRoleCircle).attrs(() => ({
    className: "tw-text-grey-0 tw-bg-system-primary !tw-text-grey-0"
}))`
`;
