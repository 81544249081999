import SelectTeamPanelComponent from "../../components/panel/SelectTeamPanel";
import { useSelector } from "../../store";
import useSelectTeamPanel from "./useSelectTeamPanel";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { ROUTER_PATH_CONFIG } from "../../router/RouterPathConfig";

const SelectTeamPanel = () => {
    const location = useLocation();
    const {
        show,
        data,
        config
    } = useSelector((store) => store.SelectTeamPanelState);

    const isFromAddDeposit = useMemo(() => location?.state?.selectedTeamsIds && location?.state?.selectedTeamsIds.length > 0 && location?.pathname !== ROUTER_PATH_CONFIG.deposit.select
        , [location?.pathname, location?.state?.selectedTeamsIds]);

    const { updateData, close } = useSelectTeamPanel();
    const setSelectedTeamIds = (selectedTeamIds) => {
        updateData({
            selectedTeamIds
        });
    };

    const handleClosePanel = () => {
        close();
        config.closePanel();
    };

    useEffect(() => {
        return () => {
            handleClosePanel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, isFromAddDeposit]);

    if (!show) {
        return null;
    }

    if (!data.contest || !data.match) {
        return null;
    }

    return <SelectTeamPanelComponent {...data}
                                     {...config}
                                     setSelectedTeamIds={setSelectedTeamIds}
                                     closePanel={handleClosePanel}
                                     matchStatus={data.match.matchStatus}
                                     onSubmit={config.onSubmit(handleClosePanel)}
                                     onJoinRubyContest={config.onJoinRubyContest(handleClosePanel)}
    />;
};

export default SelectTeamPanel;
