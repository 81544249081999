import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import Button from "../../designToken/button/Button";
import { APP_MAX_WIDTH } from "../../styles/Constants";
import { SelectablePlayer } from "../../types/player/Player";
import PlayerCard from "./player/components/playerCard/PlayerCard";

export const NotStartingBodyWrapper: React.FC<React.HtmlHTMLAttributes<HTMLDivElement>> = styled.div.attrs({
    className: "position-relative"
})`
    padding-bottom: 9.3rem;
    height: 49.3rem;
    overflow-y: auto;
`;

const ButtonWrapper = styled.div.attrs({
    className: "position-fixed"
})`
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: ${APP_MAX_WIDTH};
    padding: 2.4rem 1.6rem 1.6rem;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) -7.74%, #FFFFFF 21.44%);
`;

interface NotStartingPanelBodyProps {
    notStartingPlayers: SelectablePlayer[]
    setAllPlayers: (players: (prevPlayers) => SelectablePlayer[]) => void
    onHide: () => void
}

const NonStarterPanelBody: React.FC<NotStartingPanelBodyProps> = ({ notStartingPlayers, setAllPlayers, onHide }) => {
    const [players, setPlayers] = useState<SelectablePlayer[]>(() => notStartingPlayers.map(player => new SelectablePlayer(player)));
    const onPlayerClick = ({ id, isSelected }) => {
        setPlayers(prevPlayers => {
            return prevPlayers.map(player => {
                if (player.id === id) {
                    player.setSelected(!isSelected);
                }
                return player;
            });
        });

    };

    const onRemovePlayers = () => {
        const beClearPlayerIds = players
            .filter(player => player.isSelected)
            .map(player => player.id);

        setAllPlayers((prevPlayers) => {
            return prevPlayers.map(player => {
                if (beClearPlayerIds.includes(player.id)) {
                    player.setSelected(false);
                }
                return player;
            });
        });

        onHide();
    };

    return <NotStartingBodyWrapper>
        {
            players.map(player => {
                const customPlayerInfoText = `${player.squadCode} - ${player.roleAbbr}`;
                return <PlayerCard key={player.id}
                                   player={player}
                                   customPlayerInfoText={
                                       customPlayerInfoText
                                   }
                                   handlePlayerCheckboxOnChange={onPlayerClick}
                                   onAvatarClick={() => {
                                       onPlayerClick({ id: player.id, isSelected: player.isSelected });
                                   }}
                                   containerClassName="clickable"
                />;
            })
        }
        <ButtonWrapper>
            <Button className="w-100"
                    variant={BUTTON_VARIANTS.primary}
                    size={BUTTON_SIZE.lg}
                    pattern={BUTTON_PATTERN.pill}
                    onClick={onRemovePlayers}
                    dataTestId="not-starting-panel-remove-button"
            >
                <FormattedMessage id="dialog_button_remove" />&nbsp;
                {players.filter(player => player.isSelected).length}&nbsp;
                <FormattedMessage id="label_players" />
            </Button>
        </ButtonWrapper>
    </NotStartingBodyWrapper>;
};

export default NonStarterPanelBody;
