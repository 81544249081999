import styled, { css } from "styled-components";
import { APP_MAX_WIDTH } from "styles/Constants";


export const UserInfoDetailWrapper = styled.div<{
    $bgImg: string
}>`
    padding-bottom: 2rem;
    margin-bottom: -1.4rem;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 6%, rgba(0, 0, 0, 0.4) 100%), url(${({ $bgImg }) => $bgImg}) no-repeat top center / 100% 100%;
`;

export const UserInfoPanelWrapper = styled.div.attrs({
    className: "position-relative  d-flex flex-column p-4 bg-light-primary flex-grow-1"
})`
    border-radius: 1.6rem 1.6rem 0 0;
`;

export const Nickname = styled.div.attrs({
    className: "fz-sf-bold-title-3 text-dark-primary"
})`
    margin-right: 1rem;
`;

export const LevelTag = styled.div.attrs({
    className: "fz-sf-regular-footnote align-self-center text-dark-primary bg-sys-special-ap"
})`
    border-radius: 0.4rem;
    padding: 0.1rem 0.4rem;
`;

const UserDescription = styled.div.attrs({
    className: "me-2 fz-sf-regular-footnote"
})`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
`;

export const UserDescriptionAll = styled(UserDescription).attrs({})`

`;
export const UserDescriptionShort = styled(UserDescription).attrs({})`
    -webkit-line-clamp: 2;
`;

export const CareerStatsTabInItemWrapper = styled.div.attrs({
    className: "d-flex justify-content-center align-items-center border-bottom border-light-primary"
})`
    margin: 0 -1.6rem;
`;
export const CareerStatsTabInItem = styled.div.attrs({
    className: "position-relative fz-sf-bold-body-1 clickable"
})`
    padding: .4rem 0 .8rem;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: .4rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }
`;

export const UserInfoWrapper = styled.div.attrs({
    className: "d-flex flex-column flex-grow-1 user-info-wrapper"
})`
    &:has(.reached-following-limit-wrapper) {
        padding-bottom: 6rem;
    }
`;

export const ReachedFollowingLimitWrapper = styled.div.attrs({
    className: "reached-following-limit-wrapper position-fixed bottom-0 start-0 bg-sys-light-danger py-3 px-4 w-100 d-flex justify-content-between align-items-center"
})`
    max-width: ${APP_MAX_WIDTH};
`;

export const UserInfoTextWrapper = styled.div.attrs({
    className: "position-relative flex-fill"
})`
    padding-top: 2rem;
`;
export const UserInfoFollowWrapper = styled.div.attrs({
    className: "d-flex position-relative justify-content-between mt-3"
})`
`;


export const UserProfileAvatarWrapper = styled.div.attrs<{
    $isBeginner: boolean
}>({
    className: "position-relative"
})`

    ${({ $isBeginner }) => {
        if ($isBeginner) {
            return css`
                &:after {
                    content: '';
                    top: 50%;
                    left: 50%;
                    position: absolute;
                    width: 90%;
                    height: 90%;
                    border-radius: 50%;
                    border: 2px solid var(--border-light-secondary);
                    pointer-events: none;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                }
            `;
        }

    }}

`;
