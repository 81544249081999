import React from "react";
import { useIntl } from "react-intl";
import { FantasySelect, FantasySelectAlignment } from "designToken/textFields/FantasySelect";

export const DocumentTypeSelector: React.FC = () => {
    const intl = useIntl();
    const DOCUMENT_TYPE_OPTIONS: ({ label: string, value: string }[]) = [
        {
            label: intl.formatMessage({
                id: "selector_option_passport"
            }),
            value: "Passport"
        },
        {
            label: intl.formatMessage({
                id: "selector_option_drivers_license"
            }),
            value: "Driver's License"
        },
        {
            label: intl.formatMessage({
                id: "selector_option_id"
            }),
            value: "Government-issued Identity Card"
        },
        {
            label: intl.formatMessage({
                id: "selector_option_other"
            }),
            value: "Other"
        },
    ];

    return <div
        className={"tw-rounded-xl tw-bg-white tw-w-full tw-h-[48px]"}>
        <FantasySelect showErrorMessage={false}
                       options={DOCUMENT_TYPE_OPTIONS}
                       name={"documentType"}
                       inputProps={{
                           placeholder: intl.formatMessage({
                               id: "placeholder_document_type"
                           })
                       }}
                       align={FantasySelectAlignment.attach}
        />
    </div>;
};
