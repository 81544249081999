import { MatchInfoWrapper, SquadCode, VSText } from "./NavbarStyle";
import React from "react";

interface MatchInfoProps {
    data: {
        squadACode: string;
        squadBCode: string;
    }
}

const MatchInfo = ({ data }: MatchInfoProps) => {
    return <MatchInfoWrapper>
        <SquadCode>{data.squadACode}</SquadCode>
        <VSText>VS</VSText>
        <SquadCode>{data.squadBCode}</SquadCode>
    </MatchInfoWrapper>;
};

export default MatchInfo;