import React, { useEffect } from "react";
import { TodayPrizeOverlay } from "../../quest/DailyCheckInPopup/component/TodayPrizeOverlay";
import DailyCheckInPopupProvider from "../../quest/DailyCheckInPopup/DailyCheckInPopupProvider";
import DailyCheckInUpgradePopup, {
    dailyCheckInUpgradePopupConfirmButton,
} from "../../quest/DailyCheckInPopup/component/DailyCheckInUpgradePopup";
import MainDailyCheckInDefaultPopup from "./components/MainDailyCheckInDefaultPopup";
import { useAppPopups } from "../AppPopupsProvider";
import { ReminderRepository } from "../../../data/repository/reminder/ReminderRepository";
import useFantasyQuery from "../../../hooks/useFantasyQuery";
import { QUERY_KEY } from "../../../hooks/useFantasyQuery/type";
import B2CPopupWrapper from "../popupWrappers/B2CPopupWrapper";
import MainPagePopupWrapper from "../popupWrappers/MainPagePopupWrapper";

const MainDailyCheckInPopup = () => {
    const reminderRepository = new ReminderRepository();

    const popups = useAppPopups();
    const dailyCheckInReminder = useFantasyQuery([QUERY_KEY.DAILY_CHECK_IN_REMINDER], reminderRepository.getDailyCheckInReminder, {
        options: { cacheTime: 0 }
    });

    useEffect(() => {
        const isRead = dailyCheckInReminder.data?.unread === false;
        
        if (isRead) {
            popups.keep();
        }
    }, [dailyCheckInReminder]);

    const shouldNotShow = !dailyCheckInReminder.data || !dailyCheckInReminder.data.unread;

    if (shouldNotShow) {
        return null;
    }

    return <DailyCheckInPopupProvider>
        <MainDailyCheckInDefaultPopup />
        <DailyCheckInUpgradePopup
            confirmButtonSetting={dailyCheckInUpgradePopupConfirmButton.compare}
        />
        <TodayPrizeOverlay />
    </DailyCheckInPopupProvider>;
};

const MainDailyCheckInPopupWithWrapper = () => {
    return <B2CPopupWrapper>
        <MainPagePopupWrapper>
            <MainDailyCheckInPopup />
        </MainPagePopupWrapper>
    </B2CPopupWrapper>;
};

export default MainDailyCheckInPopupWithWrapper;
