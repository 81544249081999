import clsx from "clsx";
import { apLevelConfig } from "domain/ap/ActivityPointHelper";
import React from "react";
import { MdLockOutline } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { AP_LEVEL } from "../../../../types/activityPoint/ApLevel";
import { getSlideStyle } from "./ApLevelCarouselHelper";
import { Circle } from "./ApLevelStyle";
import { useApLevelCarouselConfig } from "./hooks/useApLevelCarouselConfig";
import { MyApOverview } from "../../../../types/activityPoint/MyApOverview";

interface ApLevelCarouselItemProps {
    apLevel: {
        level: AP_LEVEL,
        weight: number,
        minAp: number,
    };
    currentIndexDiff: number;
    apOverview: MyApOverview | undefined;
}

export const ApLevelCarouselItem: React.FC<ApLevelCarouselItemProps> = ({
                                                                            apLevel,
                                                                            currentIndexDiff,
                                                                            apOverview,
                                                                        }) => {
    const isCurrent = currentIndexDiff === 0;

    const {
        gap: gapClassName,
        circle: circleClassName,
        text: textClassName,
        lock: lockClassName
    } = getSlideStyle(apOverview, apLevel, isCurrent);

    const {
        getScaleSize,
    } = useApLevelCarouselConfig();

    return <>
        <div className={clsx("d-flex flex-column align-items-center", gapClassName)}>
            <Circle
                className={circleClassName}
                scale={getScaleSize(currentIndexDiff)}
            >
                <MdLockOutline className={lockClassName}
                               size={10 * getScaleSize(currentIndexDiff)} />
            </Circle>
            <div className={textClassName} data-testid={`ap-level-carousel-text-${apLevel.level}`}>
                {isCurrent ?
                    <div className="animate__animated animate__fadeInRight text-nowrap">
                        {apLevelConfig[apLevel.level].apLevelComponent(apLevel.minAp)}
                    </div>
                    : apLevel.level === AP_LEVEL.STEEL ?
                        <FormattedMessage id="task_label_buy_coins" /> : apLevel.minAp}
            </div>
        </div>
    </>;
};
