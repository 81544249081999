import { FormattedMessage } from "react-intl";
import React from "react";

const ScoringNote = () => {
    return <div className="tw-pb-6 tw-px-2 tw-flex tw-flex-col tw-gap-2 tw-w-full">
        <div
            className="tw-w-full tw-text-body-1 tw-text-grey-400 tw-pb-2 tw-border-b tw-border-grey-100 tw-text-start">
            <FormattedMessage id="label_note" />
        </div>
        <div className="tw-w-full tw-flex tw-text-body-2 tw-text-grey-400 tw-text-start">
            <div className="tw-w-5 tw-shrink-0 tw-text-center">1.</div>
            <FormattedMessage id="scoring_popup_note_1" />
        </div>
        <div className="tw-w-full tw-flex tw-text-body-2 tw-text-grey-400 tw-text-start">
            <div className="tw-w-5 tw-shrink-0 tw-text-center">2.</div>
            <FormattedMessage id="scoring_popup_note_2" />
        </div>
    </div>;
};

export default ScoringNote;
