import React, { useEffect } from "react";
import useMyProfile from "../../hooks/useMyProfile";
import B2CRubyContestChampionDialog from "../home/components/rubyContestChampionDialog/B2CRubyContestChampionDialog";
import AppPopupsProvider from "./AppPopupsProvider";
import MainDailyCheckInPopupWithWrapper from "./dailyCheckIn/MainDailyCheckInPopupWithWrapper";
import KycPopup from "./kyc/KycPopup";
import NewUserPopupFlow from "./newUser/NewUserPopupFlow";
import ResponsibleGamingRegistrationPopup from "domain/responsibleGaming/ResponsibleGamingRegistrationPopup";

const AppPopupsFlow = () => {
    const { myProfile, getMyProfile } = useMyProfile();

    useEffect(() => {
        getMyProfile(false);
    }, []);

    if (!myProfile) return null;

    return <AppPopupsProvider>
        <ResponsibleGamingRegistrationPopup />
        <NewUserPopupFlow />
        <MainDailyCheckInPopupWithWrapper />
        <B2CRubyContestChampionDialog />
        <KycPopup />
    </AppPopupsProvider>;
};

export default AppPopupsFlow;
