import _ from "lodash";
import { FormattedMessage } from "react-intl";

export const REWARD_COUNTRY_COINS = "COINS";
export const PRIORITY_REWARD_COUNTRIES = [REWARD_COUNTRY_COINS];

export const getLabelI18nText = (label) => {
    const lowerCaseText = label.toLowerCase();
    return <FormattedMessage id={`label_${lowerCaseText}`} defaultMessage={_.startCase(lowerCaseText)} />;
};
export const REWARD_ORDER_TAB_ALL = "ALL";
export const filterByCountry = (rewards, country) => rewards.filter(reward => reward.country === country);
export const isCoinReward = country => country === REWARD_COUNTRY_COINS;

export const isSufficient = (winning, price) => winning >= price;

export const isOutOfStock = quantity => quantity <= 0;

export const isInsufficientRewardError = error => error?.response?.status === 400 &&
    error?.response?.data?.errorCode === "INSUFFICIENT_REWARD";
export const isInsufficientWinningError = error => error?.response?.status === 400 &&
    error?.response?.data?.errorCode === "INSUFFICIENT_WINNING";

export const getRewardPreviewUrls = (previewUrls, defaultRewardImage) =>
    previewUrls.length === 0 ? [defaultRewardImage] : previewUrls;

export const showTextViaQuantity = quantity => quantity > 10
    ? <FormattedMessage id="label_more_than" values={{ number: 10 }} />
    : quantity;
