import React, { ReactNode } from "react";
import {
    MatchCardBodyLeft,
    MatchCardBodyRight,
    MatchCardBodyWrapper,
    SquadInfoWrapper,
    SquadLogoSection,
    SquadLogoWrapper,
    SquadName,
    SquadScoreSection
} from "./MatchCardStyle";
import SquadLogo from "../image/SquadLogo";
import { FormattedMessage } from "react-intl";
import { MyMatch } from "../../types/match/MyMatch";
import { leftArrowIcon } from "helpers/IconHelper";

const handleSquadSummary = (squadScores, squadOvers) => {
    if (squadScores === null && squadOvers === null) {
        return <FormattedMessage id="my_contest_detail_page_quick_summary_label_yet_to_bat" />;
    }
    return (squadScores || "") + (squadOvers ? ` (${squadOvers})` : "");
};


export const StartedMatchCardBody = ({ match, children }: { match: MyMatch, children?: ReactNode }) => {

    const squadAScoreFont = match.winningSquadCode === match.squadACode
        ? "fz-sf-bold-body-2"
        : "fz-sf-regular-body-2";
    const squadAWinningArrowStyle = match.squadACode === match.winningSquadCode ? "" : "invisible";

    const squadBScoreFont = match.winningSquadCode === match.squadBCode
        ? "fz-sf-bold-body-2"
        : "fz-sf-regular-body-2";
    const squadBWinningArrowStyle = match.squadBCode === match.winningSquadCode ? "" : "invisible";


    return <>
        <MatchCardBodyWrapper>
            <MatchCardBodyLeft>
                <SquadInfoWrapper>
                    <SquadLogoSection>
                        <SquadLogoWrapper className="me-2">
                            <SquadLogo name={match.squadAName} url={match.squadALogoUrl} />
                        </SquadLogoWrapper>
                        <SquadName>{match.squadAName}</SquadName>
                    </SquadLogoSection>
                    <SquadScoreSection className={squadAScoreFont}>
                        {handleSquadSummary(match.squadAScores, match.squadAOvers)}
                        <div className={`arrow ${squadAWinningArrowStyle}`}>
                            <img className="img-fluid" src={leftArrowIcon} alt="winner-a" />
                        </div>
                    </SquadScoreSection>
                </SquadInfoWrapper>

                <SquadInfoWrapper>
                    <SquadLogoSection>
                        <SquadLogoWrapper className="me-2">
                            <SquadLogo name={match.squadBName} url={match.squadBLogoUrl} />
                        </SquadLogoWrapper>
                        <SquadName>{match.squadBName}</SquadName>
                    </SquadLogoSection>
                    <SquadScoreSection className={squadBScoreFont}>
                        {handleSquadSummary(match.squadBScores, match.squadBOvers)}
                        <div className={`arrow ${squadBWinningArrowStyle}`}>
                            <img className="img-fluid" src={leftArrowIcon} alt="winner-b" />
                        </div>
                    </SquadScoreSection>
                </SquadInfoWrapper>
            </MatchCardBodyLeft>
            <MatchCardBodyRight>
                {children}
            </MatchCardBodyRight>

        </MatchCardBodyWrapper>
    </>;
};
