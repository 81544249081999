import MatchSquadLayout from "domain/match/components/squad/MatchSquadLayout";
import { LeftSquadQuickSummary, RightSquadQuickSummary } from "domain/match/components/squad/MatchSquadLayoutStyle";
import {
    ContestDetailNavbarBottomProps
} from "domain/match/components/startedNavbar/ContestDetailNavbarBottomPropsInterface";
import { isCricketSquadCodeHighlighted } from "domain/match/components/startedNavbar/CricketNavbarMatchWithScore";
import { isMatchCompleted } from "helpers/match/MatchHelper";
import React from "react";
import { FormattedMessage } from "react-intl";

const handleSquadSummary = (squadScores, squadOvers) => {
    if (squadScores === null && squadOvers === null) {
        return <FormattedMessage id="my_contest_detail_page_quick_summary_label_yet_to_bat" />;
    }

    return <>{squadScores ?? ""}{squadOvers && <span className="fz-sf-bold-subhead">({squadOvers})</span>}</>;
};
const CricketContestDetailNavbarBottom: React.FC<ContestDetailNavbarBottomProps> = ({ match, children }) => {
    const isCompleted = match && isMatchCompleted(match.matchStatus);
    const squadAHighlighted = isCricketSquadCodeHighlighted(match.squadACode, match);
    const squadBHighlighted = isCricketSquadCodeHighlighted(match.squadBCode, match);
    return <>
        <MatchSquadLayout matchDetail={match}
                          squadAScore={<LeftSquadQuickSummary
                              $highlight={squadAHighlighted}
                              $completed={isCompleted}>
                              {handleSquadSummary(match.squadAScores, match.squadAOvers)}
                          </LeftSquadQuickSummary>}
                          squadBScore={<RightSquadQuickSummary
                              $highlight={squadBHighlighted}
                              $completed={isCompleted}>
                              {handleSquadSummary(match.squadBScores, match.squadBOvers)}
                          </RightSquadQuickSummary>}
        />
        {children}
    </>;

};

export default CricketContestDetailNavbarBottom;
