import TagIcon from "domain/match/pages/upcoming/contest/card/tag/TagIcon";
import React from "react";
import styled from "styled-components";
import useUserIdentity from "../../hooks/useUserIdentity";

const CopyButtonWrapper = styled.div.attrs(() => ({
    className: "ms-auto clickable"
}))`
  .svg-icon {
    width: 2rem;
    height: 2rem;
  }
`;

export function CopyInvitationCodeButton({ onCopyModalShow, icon }: { onCopyModalShow: Function, icon: string }) {
    const { isB2B } = useUserIdentity();

    if (isB2B) return null;

    return <CopyButtonWrapper onClick={(e) => {
        onCopyModalShow(e);
    }}>
        <TagIcon iconImg={icon} />
    </CopyButtonWrapper>;
}
