// svg
// png
// 統一在這裡管理

import clsx from "clsx";
import { isSvgSrc } from "helpers/RegexHelper";
import React, { HTMLAttributes } from "react";
import { Props, ReactSVG } from "react-svg";
import styled from "styled-components";

const DEFAULT_PNG_ICON_SIZE = 20;

export interface PngIconProps extends HTMLAttributes<HTMLDivElement> {
    src: string;
    size?: number | string;
}

const Element = styled.div.attrs<{
    $icon: string;
    $size?: number | string
}>(() => ({
    className: "d-flex align-items-center justify-content-center "
}))`
    width: ${({ $size }) => $size};
    padding-bottom: ${({ $size }) => $size};
    background: url(${({ $icon }) => $icon}) center no-repeat;
    background-size: 100%;
`;

const PngIcon: React.FC<PngIconProps> = (props) => {

    return (
        <Element $icon={props.src} $size={props.size} {...props} />
    );
};

export enum Colorize {
    Overwrite = "overwrite",
}


export interface FantasyIconProps extends Omit<Props, "src"> {
    src?: string,
    ref?: React.ClassAttributes<any>["ref"],
    size?: number | string,
    colorize?: Colorize.Overwrite,
}

// 不要直接用這個
const FantasyIcon: React.FC<FantasyIconProps> = (props) => {
    const {
        src,
    } = props;

    if (!src) return null;

    if (isSvgSrc(src)) {
        return (
            <ReactSVG
                {...props}
                afterInjection={svg => {
                    if (props.colorize !== Colorize.Overwrite) return;

                    const paths = svg.querySelectorAll("path");
                    const rects = svg.querySelectorAll("rect");

                    [...paths, ...rects].forEach(path => {
                        if (path.hasAttribute("fill")) {
                            path.setAttribute("fill", "currentColor");
                        }

                        if (path.hasAttribute("stroke")) {
                            path.setAttribute("stroke", "currentColor");
                        }
                    });
                }}
                src={src}
                className={clsx("svg-icon", props.className)}
                style={{
                    ...props.style,
                    width: props.size,
                }} />
        );
    }

    return (
        <PngIcon {...props} src={src} size={props.size || DEFAULT_PNG_ICON_SIZE} />
    );

};


export default FantasyIcon;
