import React from "react";
import HistoryRecordRow from "domain/wallet/components/HistoryRecordRow";
import { WalletHistoryVo } from "data/vo/wallet/WalletHistoryVo";
import { NoHistory } from "domain/wallet/components/walletHistoryRow/components/NoHistory";

interface HistoryContentProps {
    records: (WalletHistoryVo)[],
    handleHistoryRecordRowClick: (walletHistory: WalletHistoryVo) => void;
}

export const HistoryContent: React.FC<HistoryContentProps> = ({
                                                                  records,
                                                                  handleHistoryRecordRowClick
                                                              }) => {
    if (records.length === 0) {
        return <NoHistory />;
    }

    return <>{
        records.map((record, index) =>
            <HistoryRecordRow
                key={index}
                record={record}
                onRowClicked={handleHistoryRecordRowClick}
            />)
    }</>;
};
