import React, { createContext, useContext, useMemo } from "react";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import { fetchMatchDetail, fetchSortedMyTeam } from "apis/match/MatchApi";
import { useParams } from "react-router-dom";
import { orderBy } from "lodash";
import { contestTypeComparator, entryFeeComparator } from "helpers/SortingHelper";
import { MatchVO } from "types/match/Match";
import { ResultContestVO } from "types/contest/Contest";
import { SPORT } from "types/sports/Sport";

import { fetchResultContests } from "data/api/contest/ContestApi";
import { MatchResultPlayersVo } from "data/vo/player/matchResult/MatchResultPlayersVo";
import { PlayerRepository } from "data/repository/player/PlayerRepository";

interface ResultMatchDetail {
    sport: SPORT;
    matchId: string;
    match: MatchVO | undefined;
    completedContests: ResultContestVO[] | undefined;
    matchResultPlayers: MatchResultPlayersVo | undefined;
}

const ResultMatchContext = createContext<ResultMatchDetail | null>(null);

interface ResultMatchDetailContextProps {
    children: React.ReactNode;
}

export const ResultMatchDetailProvider: React.FC<ResultMatchDetailContextProps> = ({ children }) => {

    const playerRepository = new PlayerRepository();

    const { sport, matchId } = useParams();

    const { data: match } = useFantasyQuery([QUERY_KEY.MATCH_DETAIL, sport, matchId],
        async () => await fetchMatchDetail(sport, matchId));

    const { data: completedContests } = useFantasyQuery([QUERY_KEY.COMPLETED_CONTESTS, sport, matchId],
        async () => {
            const contests = await fetchResultContests(sport, matchId);
            return orderBy(contests, [contestTypeComparator, entryFeeComparator]);
        });

    const { data: matchResultPlayers } = useFantasyQuery(
        [QUERY_KEY.MATCH_RESULT_PLAYERS, sport, matchId], async () => {
            return await playerRepository.getMatchResultPlayers(sport, matchId);
        });

    useFantasyQuery([QUERY_KEY.SORTED_MY_TEAM], async () => {
        return await fetchSortedMyTeam(sport, matchId);
    });

    const value = useMemo(() => ({
        sport,
        matchId,
        match,
        completedContests,
        matchResultPlayers,
    }), [sport, matchId, match, completedContests, matchResultPlayers]);

    return <ResultMatchContext.Provider value={value}>{children}</ResultMatchContext.Provider>;
};

export const useResultMatchDetailContext = () => {
    const provider = useContext(ResultMatchContext);

    if (!provider) {
        throw new Error("useResultMatchDetail must be used within ResultMatchDetailProvider");
    }

    return provider;
};
