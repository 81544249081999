import { SPORT } from "../../../types/sports/Sport";
import { Reducer } from "redux";
import {
    UPDATE_SELECT_TEAM_PANEL_CONFIG,
    UPDATE_SELECT_TEAM_PANEL_DATA,
    UPDATE_SELECT_TEAM_PANEL_SHOW,
} from "../../Constants";
import { SelectTeamPanelAction, SelectTeamPanelState } from "./type";

const initState: SelectTeamPanelState = {
    show: false,
    data: {
        contest: null,
        match: null,
        myTeams: [],
        joinedTeamIds: [],
        selectedTeamIds: [],
    },
    config: {
        sport: SPORT.CRICKET,
        onSubmit: () => {},
        onJoinRubyContest: () => () => {},
        closePanel: () => {},
        goToCreateTeam: () => {},
    }
};

const selectTeamPanelState: Reducer<SelectTeamPanelState, SelectTeamPanelAction> =
    (preState = initState, action): SelectTeamPanelState => {
        const { type, payload } = action;

        switch (type) {
            case UPDATE_SELECT_TEAM_PANEL_DATA:
                return {
                    ...preState,
                    data: {
                        ...preState.data,
                        ...payload,
                    }
                };
            case UPDATE_SELECT_TEAM_PANEL_CONFIG:
                return {
                    ...preState,
                    config: {
                        ...preState.config,
                        ...payload,
                    }
                };
            case UPDATE_SELECT_TEAM_PANEL_SHOW: {
                return {
                    ...preState,
                    show: payload
                };
            }
            default:
                return preState;
        }
    };

export default selectTeamPanelState;
