import { ID } from "Common";
import { DEFAULT_PLAYER_AVATAR_SIZE, playerDefaultAvatar } from "helpers/IconHelper";
import React, { useEffect, useState } from "react";
import { StyledImage } from "./ImageStyle";


const defaultAvatarIndex = (id: string) => {
    return Array.from(id).reduce((sum, char) => sum + (char.codePointAt(0) || 0), 0) % DEFAULT_PLAYER_AVATAR_SIZE;
};

export interface PlayerAvatarProps {
    id: ID
    url: string
}

const PlayerAvatar: React.FC<PlayerAvatarProps> = ({ id, url }) => {
    const [src, setSrc] = useState(() => url || "");

    useEffect(() => {
        if (url) setSrc(url);
    }, [url]);

    return <StyledImage data-testid={"playerAvatar"} key={src} src={src} alt={"player"}
                        onError={() => setSrc(playerDefaultAvatar(defaultAvatarIndex(id.toString())))} />;
};

export default PlayerAvatar;
