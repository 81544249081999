import { Callback } from "Common";
import { DoneButton } from "domain/slate/components/DoneButton";
import PlayerStatSlotCardFactory from "domain/slate/components/PlayerStatSlotCardFactory";
import SlateRulePopup from "domain/slate/components/SlateRulePopup";
import { FilledPlayerInfoVO, NINE_CAT_STAT, NineCatAllStatsVO, NineCatStatsVO } from "domain/slate/data/ComponentTypes";
import React, { useState } from "react";
import { MdDelete } from "react-icons/md";
import { FormattedMessage } from "react-intl";


export interface CreateTeamPanelProps {
    focusedStat?: string | undefined;
    filledPlayerInfoList: FilledPlayerInfoVO[];
    onSelectStat: (stat: NINE_CAT_STAT | undefined) => void;
    onClearAll: Callback;
    onDone: Callback;
}

export const NINE_CAT_ALL_STATS: NineCatAllStatsVO = [
    {
        key: "label_points_scored",
        statAbbr: NINE_CAT_STAT.PTS
    },
    {
        key: "label_field_goals_efficiency",
        statAbbr: NINE_CAT_STAT.FGE,
    },
    {
        key: "label_free_throws_efficiency",
        statAbbr: NINE_CAT_STAT.FTE,
    },
    {
        key: "label_offensive_rebounds",
        statAbbr: NINE_CAT_STAT.OR,
    },
    {
        key: "label_defensive_rebounds",
        statAbbr: NINE_CAT_STAT.DR,
    },
    {
        key: "label_assists",
        statAbbr: NINE_CAT_STAT.AST,
    },
    {
        key: "label_steals",
        statAbbr: NINE_CAT_STAT.ST,
    },
    {
        key: "label_blocks",
        statAbbr: NINE_CAT_STAT.BLK,
    },
    {
        key: "label_three_pointers_made",
        statAbbr: NINE_CAT_STAT["3PM"],
    },
];
const NINE_CAT_STATS_AMOUNT = NINE_CAT_ALL_STATS.length;

const ClearAllButton = (props: { onClearAll: Callback }) => <button
    className={"tw-flex tw-justify-center tw-p-1.5 tw-bg-gray-100 tw-rounded-2xl"}
    onClick={props.onClearAll}>
    <MdDelete size={"1.2rem"} />
    <p className={"tw-pl-1"}>
        <FormattedMessage id="label_clear_all" />
    </p>
</button>;

const CreateTeamPanel: React.FC<CreateTeamPanelProps> = ({
                                                             focusedStat,
                                                             filledPlayerInfoList,
                                                             onSelectStat,
                                                             onClearAll,
                                                             onDone,
                                                         }) => {

    const allSelected = filledPlayerInfoList.length === NINE_CAT_STATS_AMOUNT;
    const [currentPopupType, setCurrentPopupType] = useState<NINE_CAT_STAT | null>(null);
    const [openStatRulePopupModal, setOpenStatRulePopupModal] = useState(false);

    // const choosingPlayerOrStat = !!focusedStat || !!focusedPlayer;

    const getPlayerInfo = (nineCatStats: NineCatStatsVO) => (
        filledPlayerInfoList.find(player => player.stat === nineCatStats.statAbbr)?.playerInfo
    );

    const handleOpenStatRulePopup = (type: NINE_CAT_STAT) => {
        setCurrentPopupType(type);
        setOpenStatRulePopupModal(true);
    };

    return (
        <div className={"tw-grid tw-grid-cols-4 tw-grid-rows-3 tw-gap-2"}>
            <div className={"tw-col-span-2"}>
                <h2 className={"tw-text-subtitle-1 tw-font-bold"}>
                    <FormattedMessage id="label_create_a_new_team_style_1" />
                </h2>
                <p className={"tw-text-overline tw-text-grey-600"}>
                    <FormattedMessage id="create_nine_cat_slate_team_description" />
                </p>
            </div>
            {NINE_CAT_ALL_STATS.map((nineCatStats) => {
                const playerInfo = getPlayerInfo(nineCatStats);
                return (
                    <PlayerStatSlotCardFactory
                        key={nineCatStats.statAbbr}
                        focused={focusedStat === nineCatStats.statAbbr}
                        nineCatStats={nineCatStats}
                        playerInfo={playerInfo}
                        onSelectStatPlayer={() => onSelectStat(nineCatStats.statAbbr)}
                        onOpenStatRulePopup={(e) => {
                            e.stopPropagation();
                            handleOpenStatRulePopup(nineCatStats.statAbbr);
                        }} />
                );
            })}
            <div className={"tw-flex tw-flex-col tw-justify-end tw-gap-2"}>
                <ClearAllButton onClearAll={onClearAll} />
                <DoneButton disabled={!allSelected} onDone={onDone} />
            </div>
            <SlateRulePopup
                open={openStatRulePopupModal}
                onOpenChange={setOpenStatRulePopupModal}
                type={currentPopupType!}
            />
        </div>
    );
};

export default CreateTeamPanel;
