import BottomNavbar from "components/container/bottom/BottomNavbar";
import ErrorContent from "components/error/ErrorContent";
import { ErrorPageWrapper, Header, Logo } from "domain/error/ErrorPageStyle";
import { errorWithErrorCodeImg, errorWithoutErrorCodeImg, logoPrimaryHorizontal } from "helpers/IconHelper";
import React from "react";
import { useLocation } from "react-router-dom";
import useUserIdentity from "../../hooks/useUserIdentity";

const ErrorPage = () => {
    const location = useLocation();
    const { isB2C } = useUserIdentity();

    const error = {
        message: location?.state?.error?.message || null,
        status: location?.state?.error?.status || null,
        img: location?.state?.error?.status ? errorWithErrorCodeImg : errorWithoutErrorCodeImg
    };

    return (
        <ErrorPageWrapper>
            {
                isB2C && <>
                    <Header>
                        <Logo img={logoPrimaryHorizontal} />
                    </Header>
                    <BottomNavbar />
                </>
            }
            <ErrorContent error={error}
                          title={"fz-sf-bold-title-1"} />
        </ErrorPageWrapper>
    );
};

export default ErrorPage;
