import { KYC_STEP } from "domain/kyc/pages/kycVerificationForm/KycVerificationForm";
import { cn } from "helpers/cn";
import { useMemo } from "react";

export const StepLabel = ({
                              step,
                              currentStep,
                              children
                          }) => {
    const text = useMemo(() => {
        if (step === KYC_STEP.OTP) {
            return "OTP";
        }

        return `Steps (${step}/4)`;
    }, [step]);

    return (
        <div className="tw-relative">
            {
                step === currentStep && (
                    <div
                        className={cn("tw-text-caption-1 tw-text-primary-800 tw-absolute -tw-top-[80%] -tw-left-1/2 tw-whitespace-pre", {
                            "-tw-left-0": step === KYC_STEP.OTP,
                            "-tw-left-auto tw-right-0": step === KYC_STEP.STEP_4,
                        })}>
                        {text}
                    </div>
                )
            }

            {children}
        </div>
    );
};
