import { emptyNewsImg } from "helpers/IconHelper";
import React from "react";
import { FormattedMessage } from "react-intl";

const BasketballNewsEmpty = () => {
    return (
        <div className="tw-w-[21rem] tw-mx-auto tw-text-center" data-testid="empty-basketball-news">
            <p className="tw-text-body-2 tw-font-light tw-text-gray-500 tw-my-2">
                <FormattedMessage id="label_basketball_empty_news" />
            </p>
            <img className='tw-w-[12.5rem]' src={emptyNewsImg} alt="No News" />
        </div>
    );
};

export default BasketballNewsEmpty;
