import { FantasyTextField, ShadCNTextFieldProps } from "designToken/textFields/FantasyTextField";
import React, { useCallback, useMemo, useState } from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { Icon } from "helpers/IconHelper";

export const FantasyPasswordField = (props: ShadCNTextFieldProps) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
    const type = useMemo(() => isPasswordVisible ? "text" : "password", [isPasswordVisible]);
    const SuffixIcon = useMemo(() => isPasswordVisible ? MdVisibility : MdVisibilityOff, [isPasswordVisible]);

    const handleToggle = useCallback(() => {
        setIsPasswordVisible(prevState => !prevState);
    }, []);

    return <FantasyTextField
        {...props}
        inputProps={{
            ...props.inputProps,
            type
        }}
        iconLeft={<Icon.KeyHoleLock size="1.8rem"
                                    className="tw-text-grey-250" />}
        iconRight={<SuffixIcon size="1.8rem"
                               onClick={handleToggle}
                               className="tw-text-grey-250 clickable" />}
    />;
};
