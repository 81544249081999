import { NavbarCenter } from "domain/me/profile/ProfileStyle";
import { TaskVideoPlayer } from "domain/task/components/TaskVideoPlayer";
import React from "react";
import { FormattedMessage } from "react-intl";
import Navbar from "../../components/container/navbar/Navbar";
import ApLevelCarousel from "./components/ApLevel/ApLevelCarousel";
import { ClaimedSection } from "./components/Claimed/ClaimedSection";
import { DailyTaskSection } from "./components/DailyTask/DailyTaskSection";
import { Tutorial } from "./components/Tutorial/Tutorial";
import { TaskProvider } from "./TaskProvider";
import { UpgradeSection } from "./Upgrade/UpgradeSection";


const Task = () => {
    return <TaskProvider>
        <Navbar
            isHeaderLine={false}
            navbarCenter={<NavbarCenter><FormattedMessage id="daily_task_page_title" /></NavbarCenter>}
            className="bg-body-body-1"
        />
        <ClaimedSection />

        <ApLevelCarousel />

        <UpgradeSection />

        <DailyTaskSection />

        <Tutorial />

        <TaskVideoPlayer />
    </TaskProvider>;
};

export default Task;
