import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledUserInfoWrapper = styled.div.attrs({
    className: "d-flex align-items-center gap-3 mb-4",
})`
    height: 80px;
`;

export const StyledUserInfoAvatar = styled.div.attrs({
    className: "position-relative",
})``;

export const StyledUserInfoContentWrapper = styled.div.attrs({
    className: "flex-grow-1",
})`
    width: 100%;
    overflow: hidden;
`;

export const StyledUserInfoDetailWrapper = styled.div.attrs({
    className: "d-flex align-items-center justify-content-between gap-2 mb-2",
})`
    width: 100%;
`;

export const StyledUserInfoDetail = styled.div.attrs({
    className: "",
})`
    width: calc(100% - 1.6rem);
`;

export const StyledUserInfoNicknameWrapper = styled.div.attrs({
    className: "d-flex gap-3 align-items-center",
})`
    min-width: 0;
    overflow: hidden;
`;

export const StyledUserInfoNickname = styled.span.attrs({
    className: "fz-sf-bold-title-3 text-light-primary",
})`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const StyledCurrentLevel = styled(Link).attrs({
    className: "fz-sf-regular-footnote flex-shrink-0 tw-text-grey-0 hover:tw-text-grey-0 tw-bg-system-special-ap",
})`
    padding: 0.1rem 0.4rem;
    border-radius: 0.4rem;
    text-transform: uppercase;
`;

export const StyledUserInfoUserCodeWrapper = styled.div.attrs({
    className: "d-flex gap-2 align-items-center",
})``;

export const StyledUserInfoUserCode = styled.span.attrs({
    className: "text-text-secondary-1 fz-sf-regular-subhead",
})``;

export const StyledUserInfoProgressWrapper = styled.div.attrs({
    className: "pr-4",
})``;

export const StyledUserInfoProgressValue = styled.div.attrs({
    className: "fz-sf-regular-footnote text-light-secondary text-end",
})``;

export const StyledUserInfoProgressBarContainer = styled.div.attrs({
    className: "w-100 d-flex align-items-center",
})`
    height: 0.5rem;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 1.6rem;
`;

export const StyledUserInfoProgressBarValue = styled.div.attrs({
    className: "tw-bg-gradient-to-r tw-from-system-special-ap/60 tw-to-system-special-ap/100"
})<{
    $currentApProgress: number;
}>`
    width: ${({ $currentApProgress }) => 100 * $currentApProgress}%;
    min-width: 0.3rem;
    height: 0.5rem;
    border-radius: 1.6rem;
`;
