import { analyticsEvent, logCustomEvent } from "ga";
import useFantasyHistory from "hooks/useFantasyHistory";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import B2CWrapper from "containers/b2cWrapper/B2CWrapper";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { PrimaryFixedButton } from "styles/component/ButtonsStyle";
import { WalletHistoryTableContainer } from "domain/wallet/components/walletHistoryRow/WalletHistoryRowStyle";
import useWalletHistoryRecords from "domain/wallet/hooks/useWalletHistoryRecords";
import WinningTransactionDetail from "domain/wallet/winning/WinningTransactionDetail";
import { WALLET_TYPE } from "data/dto/wallet/walletHistoryDto";
import { WalletHistoriesVo } from "data/vo/wallet/WalletHistoriesVo";
import { HistoryContent } from "domain/wallet/components/HistoryContent";
import { TotalAmountRow } from "domain/wallet/components/walletHistoryRow/components/TotalAmountRow";


interface WinningRecordsProps {
    histories: WalletHistoriesVo
}

const WinningRecords: React.FC<WinningRecordsProps> = ({ histories }) => {
    const history = histories.winning;
    const routeHistory = useFantasyHistory();
    const {
        transactionDetailData,
        handleHideTransaction,
        WalletState: { winningCent },
        handleHistoryRecordRowClick,
    } = useWalletHistoryRecords();

    const handleDirectToHome = useCallback(() => {
        logCustomEvent(analyticsEvent.webTryToJoinContest);
        routeHistory.push(ROUTER_PATH_CONFIG.home);
    }, [routeHistory]);

    return <>
        <WalletHistoryTableContainer>
            <TotalAmountRow totalCent={winningCent}
                            walletType={WALLET_TYPE.WINNING}
            />

            <HistoryContent records={history}
                            handleHistoryRecordRowClick={handleHistoryRecordRowClick} />

            <B2CWrapper>
                <PrimaryFixedButton onClick={handleDirectToHome}>
                    <FormattedMessage id="wallet_history_page_button_join_contest" />
                </PrimaryFixedButton>
            </B2CWrapper>
        </WalletHistoryTableContainer>
        <WinningTransactionDetail transactionDetailData={transactionDetailData} onHide={handleHideTransaction} />
    </>;
};

export default WinningRecords;
