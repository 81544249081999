import { Container } from "react-bootstrap";
import styled from "styled-components";

export const Contest = styled(Container).attrs(() => ({
    className: "overflow-hidden px-0 position-relative fantasy-card-bg base-shadow-light-60"
}))`
    border-radius: 1.2rem; // TODO should remove border-radius in fantasy-card-border
`;
export const ContestBodyRow = styled.div.attrs(() => ({
    className: "d-flex align-items-center justify-content-between"
}))`
    padding: 0.4rem 1.6rem;
`;
export const ContestFooterRow = styled(ContestBodyRow).attrs({
    className: "tw-bg-grey-50"
})``;

export const ContestTeamsRow = styled(ContestBodyRow).attrs({
    className: "bg-gray-1 tw-border-system-neutral100"
})`
    border-width: 1px 0 0 0;
    border-style: dashed;
`;

export const ContestCardInfo = styled.div.attrs(() => ({
    className: "text-start"
}))`
    width: 0;
    flex-grow: 1;
`;

export const JoinButtonWrapper = styled.div`
    flex-basis: 7rem;
`;

export const ContestName = styled.div.attrs(() => ({
    className: "pb-2 text-truncate"
}))`
    word-break: break-word;
`;

export const PracticeContestName = styled.div.attrs(() => ({
    className: "fz-sf-bold-body-1 pb-2"
}))`
`;

export const ContestInfoRow = styled.div.attrs(() => ({
    className: "d-flex"
}))`
`;

export const SpotWrapper = styled.div.attrs(() => ({
    className: "d-flex justify-content-center"
}))`
    margin-top: .4rem;
`;
export const TeamsCount = styled.div.attrs <{
    $textColorClassName?: string
}>(({ $textColorClassName }) => ({
    className: `fz-sf-bold-footnote ${$textColorClassName}`
}))`
`;
export const SpotNumber = styled.div.attrs(() => ({
    className: "fz-sf-regular-footnote tw-text-system-labelLightSecondary"
}))`
`;
