import { isUltimateMaster } from "domain/ap/ActivityPointHelper";
import styled from "styled-components";
import { APP_MAX_WIDTH, SYSTEM_COLORS } from "styles/Constants";
import { AP_LEVEL } from "types/activityPoint/ApLevel";

export const ActivityPointProgramContainer = styled.div.attrs(() => ({
    className: "position-relative"
}))``;
export const BottomNavbarWrapper = styled.div.attrs(() => ({
    className: "position-absolute w-100"
}))`
  left: 0;
  height: 16.4rem;
  max-width: ${APP_MAX_WIDTH};
`;
export const ActivityPointProgressWrapper = styled.div.attrs(() => ({
    className: ""
}))`
  padding: 0 1.7rem 0 1rem;

  @media (min-width: ${APP_MAX_WIDTH}) {
    padding: 0 4.4rem 0 3.4rem;
  }
`;
export const ActivityPointProgramTabWrapper = styled.div.attrs(() => ({
    className: "position-absolute w-100"
}))`
  bottom: 0;
  max-width: ${APP_MAX_WIDTH};
`;

export const ApBadge = styled.div<{
    $bgImg: string
}>`
  background: url(${({ $bgImg }) => $bgImg}) center no-repeat;
  background-size: contain;
  width: 1.6rem;
  padding-bottom: 1.6rem;
`;

export const Title = styled.div.attrs<{
    $currentLevel: AP_LEVEL
}>({
    className: "d-flex justify-content-center align-items-center fz-sf-bold-title-3 h-100"
})`
  font-size: 1.8rem;
  color: ${({ $currentLevel }) => isUltimateMaster($currentLevel) ? SYSTEM_COLORS.labelDarkPrimary : SYSTEM_COLORS.primary80};
`;

export const InfoButton = styled.a<{
    $bgImg: string
}>`
  background: url(${({ $bgImg }) => $bgImg}) center no-repeat;
  background-size: contain;
  width: 2rem;
  padding-bottom: 2rem;
`;

