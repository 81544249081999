import { Card } from "react-bootstrap";
import styled from "styled-components";

export const CardBody = styled(Card.Body).attrs(() => ({
    className: "text-center"
}))`
  padding: 2.4rem;
`;
export const CardText = styled(Card.Text).attrs({
    className: "fz-sf-regular-body-2 text-light-primary"
})`
  margin-bottom: 2.4rem;
`;
