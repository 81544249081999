import styled from "styled-components";

export const STICK_TOP = 10.4;
export const StickySection = styled.div.attrs<{
    $top?: number
}>({
    className: "position-sticky header-bg-normal "
})`
    top: ${({ $top = 0 }) => $top}rem;
    z-index: 2;
    backdrop-filter: blur(10px);

`;

export const TabsWrapper = styled.div.attrs({
    className: "header-line base-shadow-light-60"
})`
    padding: 0 1.6rem;
`;

export const MatchStatusContainer = styled.div.attrs(() => ({
    className: "d-flex justify-content-center align-items-center"
}))`
`;
export const NavbarBottomWrapper = styled.div.attrs(() => ({}))`
    padding-bottom: .6rem;
`;
export const Commentary = styled.div.attrs(() => ({
    className: "fz-sf-regular-footnote text-center text-text-secondary-1"
}))`
    text-align: center;
    padding: .8rem 0;
`;
export const SpinnerWrapper = styled.div.attrs(() => ({
    className: "d-flex justify-content-center align-items-center mt-5"
}))`
`;
