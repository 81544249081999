export const referralCodeRegexp = /^[A-Z\d]{10}$/;
export const emailRegexp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const nicknameRegexp = /^[a-zA-Z\d._]*$/;
export const ALL_DIGIT_NUMBER_REGEX = /^\d+$/;
export const NON_ZERO_POSITIVE_OR_EMPTY_REGEX = /^$|^[1-9]\d*$/;
export const ALL_DIGIT_NUMBER_OR_EMPTY_REGEX = /^$|^\d*$/;
export const OTP_MOBILE_NUMBER_REGEXES = [/^9\d{9}$/, /^09\d{9}$/];

export const isAtMatchDetailPage = (path: string) => /^\/\w+\/(match\/)(\d+)\/(detail)$/.test(path);
export const isContestInviteCodeValid = (code: string) => /^[A-Z\d]{10}$/g.test(code);
export const isReferralCodeValid = (code: string) => referralCodeRegexp.test(code);
export const isNicknameLettersAndNumbers = (nickname: string) => nicknameRegexp.test(nickname);
export const isNicknameLengthValid = (nickname: string) => /^.{6,30}$/g.test(nickname);
export const isSvgSrc = (src: string) => /.+\.svg$/g.test(src);
export const isAllDigit = (val) => ALL_DIGIT_NUMBER_REGEX.test(val);
export const isOtpMobileNumber = (val) => OTP_MOBILE_NUMBER_REGEXES.some((regex) => regex.test(val));
