import styled from "styled-components";


export const FollowInfoWrapper = styled.div.attrs({
    className: "d-flex align-items-center"
})`
`;
export const FollowTextWrapper = styled.div.attrs({})`
  padding: .8rem 2rem 0;
  min-width: 9.5rem;
`;

export const FollowInfoDivider = styled.div.attrs({
    className: "border-start border-light-tertiary align-self-center"
})`
  flex: 0 0 1px;
  height: 53%;
`;
