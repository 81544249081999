import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import IconButton, { IconButtonFor } from "components/buttons/IconButton";
import { UpdateProfileRequest } from "data/dto/user/UpdateProfile";
import { UserProfileRepository } from "data/repository/user/UserProfileRepository";
import Button from "designToken/button/Button";
import { BUTTON_PATTERN } from "designToken/button/types";
import Dialog from "designToken/dialog/Dialog";
import {
    ChangeNicknameDialogContent
} from "domain/home/components/FantasyHomeNavbar/B2BHomeNavbar/ChangeNicknameDialog/ChangeNicknameDialogContent";
import { ERROR_CODE } from "helpers/ErrorHelper";
import { Icon } from "helpers/IconHelper";
import { nicknameRegexp } from "helpers/RegexHelper";
import useFantasyCommand from "hooks/useFantasyCommand";
import useMyProfile from "hooks/useMyProfile";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { z } from "zod";
import { ERROR_HANDLER_FLOW } from "hooks/useHandleApiError";

interface ChangeNicknameDialogProps {
    onClose: () => void;
    show: boolean;
}

export const ChangeNicknameDialog: React.FC<ChangeNicknameDialogProps> = ({ onClose, show }) => {
    const userProfileRepository = new UserProfileRepository();
    const updateUserProfile = useFantasyCommand<UpdateProfileRequest>(userProfileRepository.updateUserProfile, {
        onFail: () => ERROR_HANDLER_FLOW.STOP
    });

    const { myProfile, getMyProfile } = useMyProfile();

    const intl = useIntl();

    const form = useForm({
        mode: "onChange",
        resolver: zodResolver(z.object({
            nickname: z.string()
                .min(6, { message: intl.formatMessage({ id: "nickname_input_error_char_lengths" }) })
                .max(30, { message: intl.formatMessage({ id: "nickname_input_error_char_lengths" }) })
                .regex(nicknameRegexp, { message: intl.formatMessage({ id: "nickname_input_error_format_letters_and_numbers" }) })
        })),
        defaultValues: {
            nickname: ""
        }
    });


    const handleSubmit = form.handleSubmit(async (data) => {
        try {
            await updateUserProfile.mutateAsync({
                nickname: data.nickname,
                description: myProfile.description,
            });

            getMyProfile();
            handleCloseDialog();

        } catch (error) {
            if (!axios.isAxiosError(error)) {
                return;
            }

            if (error.response?.data["errorCode"] === ERROR_CODE.NICKNAME_DUPLICATED) {
                form.setError("nickname", { message: intl.formatMessage({ id: "nickname_input_error_duplicate" }) });
            }
        }
    });

    const handleCloseDialog = () => {
        onClose();
        form.reset();
    };

    return <Dialog show={show} backdrop onHide={handleCloseDialog} data-testid="chang-nickname-dialog">
        <FormProvider {...form}>
            <form className="px-4 pt-4 pb-5 tw-flex tw-flex-col gap-3" onSubmit={handleSubmit}>
                <div className="tw-w-full tw-flex tw-flex-row-reverse">
                    <IconButton for={IconButtonFor.ON_LIGHT}
                                background={false}
                                icon={Icon.Close}
                                data-testid="close-dialog"
                                onClick={handleCloseDialog} />
                </div>
                <div className="px-3 tw-flex tw-flex-col gap-5">
                    <ChangeNicknameDialogContent />
                    <Button
                        pattern={BUTTON_PATTERN.pill}
                    >
                        <FormattedMessage id="label_save" />
                    </Button>
                </div>
            </form>
        </FormProvider>
    </Dialog>;
};
