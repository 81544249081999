import { getIdToTeam } from "../SelectTeamPanelHelper";
import { SelectedTeamText, SelectedTeamWrapper } from "../SelectTeamPanelStyle";
import { FormattedMessage } from "react-intl";
import { sortByIdAsc } from "../../../helpers/SortingHelper";
import Badge, { BADGE_PATTERN, BADGE_SIZE, BADGE_VARIANTS } from "../../../designToken/badge/Badge";
import React from "react";

export const JoinedTeamNameBadges = ({ myTeams, joinedTeamIds }) => {
    const teamIdToName = getIdToTeam(myTeams);

    const joinedTeams = joinedTeamIds.map(joinedTeamId => teamIdToName[joinedTeamId]);

    return joinedTeams.length > 0
        ? <SelectedTeamWrapper>
            <SelectedTeamText><FormattedMessage id="select_team_page_label_have_joined_team" /></SelectedTeamText>
            {
                sortByIdAsc(joinedTeams).map(team =>
                    <Badge key={team.id}
                           className="me-2"
                           variant={BADGE_VARIANTS.OUTLINE_SECONDARY}
                           size={BADGE_SIZE.SM}
                           pattern={BADGE_PATTERN.ROUNDED}>{team.name}</Badge>
                )
            }
        </SelectedTeamWrapper>
        : null;
};