import B2CWrapper from "containers/b2cWrapper/B2CWrapper";
import { B2CPromotionalIcon } from "domain/home/components/Promotional/B2CPromotionalIcon";
import { B2CPromotionalPanel } from "domain/home/components/Promotional/B2CPromotionalPanel";
import { CampaignStatus, useCampaignStatus } from "domain/home/components/Promotional/hook/useCampaignStatus";
import { getQuery } from "helpers/queryString";
import useFantasyHistory from "hooks/useFantasyHistory";
import React, { useEffect, useState } from "react";

const Promotional = () => {
    const [isPromotionalPanelOpened, setIsPromotionalPanelOpened] = useState(false);
    const campaignStatus = useCampaignStatus();
    const history = useFantasyHistory();

    const promotionalCodeQueryKey = "promotional-code";
    const { [promotionalCodeQueryKey]: promotionalCode }: { [promotionalCodeQueryKey]?: string } = getQuery();

    useEffect(() => {
        if (promotionalCode) {
            history.replace({});
            setIsPromotionalPanelOpened(true);
        }
    }, [promotionalCode]);

    return <>
        {
            campaignStatus === CampaignStatus.ACTIVE
            && <B2CPromotionalIcon
                setIsPromotionalPanelOpened={setIsPromotionalPanelOpened}
            />
        }
        <B2CPromotionalPanel isPromotionalPanelOpened={isPromotionalPanelOpened}
                             setIsPromotionalPanelOpened={setIsPromotionalPanelOpened}
                             initPromotionalCode={promotionalCode ?? ""}
        />
    </>;
};

const B2CPromotional = () => (
    <B2CWrapper>
        <Promotional />
    </B2CWrapper>
);
export default B2CPromotional;
