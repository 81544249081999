import { PlayerInfoVo } from "data/vo/player/PlayerInfoVo";
import { getSportConfig } from "domain/SportConfig";
import { GameLogDetail } from "domain/team/playerInfoDialog/PlayerInfoDialogStyle";
import { emptyGameLogsImg } from "helpers/IconHelper";
import { ASC, DESC } from "helpers/SortingHelper";
import { useDragToScrollX } from "hooks/useDragToScrollX";
import { orderBy } from "lodash";
import React, { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { SquadPlayer } from "types/player/Player";

interface GameLogProps {
    player: SquadPlayer;
    playerInfo: PlayerInfoVo;
    showTitle?: boolean;
}


const GameLogContent = ({ player, playerInfo }) => {
    const { gameLogs } = getSportConfig(player.sport);
    const tableWrapperRef = useRef<HTMLDivElement>(null);
    useDragToScrollX(tableWrapperRef);

    return <div className="table-responsive border border-light-primary" ref={tableWrapperRef}>
        <table className="table table-borderless mb-0">
            <thead>
            <tr>
                {
                    gameLogs.map(({ key, label, className = "text-center" }) => {
                        return <GameLogDetail
                            key={key}
                            className={`${className} bg-light-body text-primary-1 fz-sf-bold-footnote`}
                            as="th"
                        >
                            {label}
                        </GameLogDetail>;
                    })
                }
            </tr>
            </thead>
            <tbody>
            {
                orderBy(playerInfo.gameLogs, ["matchStartsAt", "oppositeSquadCode"], [DESC, ASC])
                    .map((log, Idx) => {

                        const diffOppositeBgs = ["bg-light-primary", "bg-primary-7"];
                        const sameOppositeBgs = ["bg-secondary-7", "bg-secondary-6"];

                        const bgColor = (log.oppositeSquadCode === playerInfo.oppositeSquadCode ? sameOppositeBgs : diffOppositeBgs)[Idx % 2];

                        return <tr key={log.matchStartsAt}>
                            {
                                gameLogs.map(({ key, className = "text-center" }) => {
                                    return <GameLogDetail
                                        className={`${bgColor} ${className} fz-sf-regular-footnote`}
                                        key={key}>
                                        {log[key]}
                                    </GameLogDetail>;
                                })
                            }
                        </tr>;
                    })
            }
            </tbody>
        </table>
    </div>;
};


const EmptyGameLog = () => {
    return <div className="tw-text-center">
        <img className="tw-w-[12.5rem]" src={emptyGameLogsImg} alt="No Game Logs" />
        <p className="tw-text-overline tw-font-light tw-text-grey-750 tw-mt-2">
            <FormattedMessage id="player_info_popup_label_no_game_data" />
        </p>
    </div>;
};

export const MatchGameLog: React.FC<GameLogProps> = ({
                                                         player,
                                                         playerInfo,
                                                         showTitle = true,
                                                     }) => {
    const shouldShowGameLog = playerInfo.gameLogs.length > 0;

    return <>
        <div data-testid="gameLog">
            {
                showTitle &&
                <div className="fz-sf-bold-body-1 text-text-primary-1 mb-3">
                    <FormattedMessage id="game_log_title" />
                </div>
            }
            {
                shouldShowGameLog
                    ? <GameLogContent player={player} playerInfo={playerInfo} />
                    : <EmptyGameLog />
            }
        </div>
    </>;
};
