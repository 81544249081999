import {
    fetchMyReferralCode,
    fetchReferee,
    fetchRefereeRubySummary,
    sendReferralCode
} from "data/api/referral/ReferralApi";
import { MyRefereeSummaryVo } from "data/vo/referral/MyRefereeSummaryVo";
import { RefereeVo } from "data/vo/referral/RefereeVo";

export class ReferralRepository {
    public async getMyReferralCode(): Promise<string> {
        return await fetchMyReferralCode();
    }

    public async getMyRefereeRubySummaries(): Promise<MyRefereeSummaryVo[]> {
        const summaries = await fetchRefereeRubySummary();
        return summaries.map(summary => new MyRefereeSummaryVo(summary));
    }

    public async getReferee() {
        const refereeDto = await fetchReferee();

        return new RefereeVo(refereeDto.lastRefereeJoinTime, refereeDto.refereeCount);
    }

    public async sendReferralCode(code: string) {
        await sendReferralCode(code);
    }
}
