import { cn } from "helpers/cn";
import React from "react";
import { getRenderElementsWithRemains } from "../helpers/helpers";

interface SlateCardSquadLogoProps {
    text?: string,
    zIndex: number,
    url?: string
    className?: string
}

const SlateCardSquadLogo: React.FC<SlateCardSquadLogoProps> = ({
                                                                   text,
                                                                   zIndex,
                                                                   url,
                                                                   className
                                                               }) => {
    const sharedClass = cn("tw-w-[2rem] tw-h-[2rem] tw-rounded-full tw-relative tw-flex tw-items-center tw-justify-center",
        "tw-z-[var(--zIndex)]",
        className
    );

    if (url) {
        return <div className={cn(sharedClass, "tw-bg-grey-150")}
                    style={{ "--zIndex": `${zIndex}` } as any}
                    data-testid="slate-card-squad-logo">
            <img className="tw-w-full tw-h-full tw-object-cover" src={url} alt="squad-logo" />
        </div>;
    }

    return <div className={cn(sharedClass, "tw-bg-grey-50 tw-text-caption-2")}
                style={{ "--zIndex": `${zIndex}`, } as any}
                data-testid="slate-card-squad-syntax"
    >{text}</div>;
};
const MAX_SQUAD_AMOUNT_ON_SCREEN = 4;

export const SlateCardSquadLogos: React.FC<{ squads: { logoUrl: string }[] }> = ({ squads }) => {
    const {
        shouldRenderRemainsAmount,
        renderElements,
        remainsAmount,
    } = getRenderElementsWithRemains(squads, MAX_SQUAD_AMOUNT_ON_SCREEN);

    return <div className="tw-flex tw-pt-1">
        {
            renderElements.map(({ logoUrl }, idx: number) => {
                return <SlateCardSquadLogo key={logoUrl}
                                           url={logoUrl}
                                           className={cn({ "tw--ml-1": idx !== 0 })}
                                           zIndex={renderElements.length - idx}
                />;
            })
        }
        {
            shouldRenderRemainsAmount &&
            <SlateCardSquadLogo text={`+${remainsAmount}`} zIndex={0} className={"tw--ml-[0.2rem]"} />
        }
    </div>;

};
