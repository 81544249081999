import React, { useEffect, useRef, useState } from "react";
import { HomeNavBarContainer, HomeNavbarWrapper } from "./HomeStyle";

interface HomeNavWrapperProps {
    children: React.ReactNode;
}

export const HomeNavWrapper: React.FC<HomeNavWrapperProps> = ({ children }) => {
    const HomeNavbarWrapperRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        setHeight(prevState => HomeNavbarWrapperRef.current?.clientHeight ?? prevState);
    }, [HomeNavbarWrapperRef.current?.clientHeight]);

    return (
        <HomeNavBarContainer $height={height}>
            <HomeNavbarWrapper ref={HomeNavbarWrapperRef}>
                {children}
            </HomeNavbarWrapper>
        </HomeNavBarContainer>
    );
};
