import { MatchSnapshotDto } from "data/dto/match/MatchSnapshotDto";

class BatsmanVo {
    firstName: string;
    middleName: string;
    lastName: string;
    balls: number;
    runs: number;

    constructor(firstName: string, middleName: string, lastName: string, balls: number, runs: number) {
        this.firstName = firstName;
        this.middleName = middleName;
        this.lastName = lastName;
        this.balls = balls;
        this.runs = runs;
    }
}

class BowlerVo {
    firstName: string;
    middleName: string;
    lastName: string;
    maidens: number;
    overs: string;
    runs: number;

    constructor(firstName: string, middleName: string, lastName: string, maidens: number, overs: string, runs: number) {
        this.firstName = firstName;
        this.middleName = middleName;
        this.lastName = lastName;
        this.maidens = maidens;
        this.overs = overs;
        this.runs = runs;
    }
}

export class MatchSnapshotVo {
    commentary: string;
    batsmen: BatsmanVo[];
    bowlers: BowlerVo[];
    scores: string[];
    isReady: boolean;

    constructor(dto: MatchSnapshotDto) {
        this.commentary = dto.commentary;
        this.batsmen = dto.batsmen.map(({ balls, firstName, lastName, middleName, runs }) => new BatsmanVo(
            firstName,
            middleName,
            lastName,
            balls,
            runs
        ));
        this.bowlers = dto.bowlers.map(({ firstName, lastName, middleName, maidens, overs, runs }) => new BowlerVo(
            firstName,
            middleName,
            lastName,
            maidens,
            overs,
            runs
        ));
        this.scores = dto.scores;
        this.isReady = dto.isReady;
    }

    get isNotReady() {
        return !this.isReady;
    }
}
