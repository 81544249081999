import React from "react";
import { InputGroup } from "react-bootstrap";
import { ActiveItemProps, SelectionDropdown } from "./Select";
import { ErrorMessage, FormButton, InputGroupWrapper, Label, TextField } from "./Styles";
import { hasError } from "./TextFields.helper";

const SelectInput: React.FC<SelectInputProps> = ({
                                                     label = "",
                                                     value,
                                                     inputType = "text",
                                                     onChange,
                                                     placeholder = "",
                                                     button = "",
                                                     onButtonClick = () => {},
                                                     errorMessage = "",
                                                     items,
                                                     activeItem,
                                                     disabledItemIds,
                                                     onItemClick
                                                 }) => {


    return <InputGroupWrapper className={hasError(errorMessage)}>
        <Label>{label}</Label>
        <InputGroup>
            <SelectionDropdown items={items}
                               activeItem={activeItem}
                               disabledItemIds={disabledItemIds}
                               onItemClick={onItemClick} />
            <TextField value={value}
                       type={inputType}
                       onChange={onChange}
                       placeholder={placeholder}
            />
            {
                button
                && <FormButton onClick={onButtonClick}>{button}</FormButton>
            }
        </InputGroup>
        {
            errorMessage
            && <ErrorMessage>{errorMessage}</ErrorMessage>
        }
    </InputGroupWrapper>;
};


interface SelectInputProps {
    label: string,
    inputType?: string,
    placeholder?: string,
    value: string,
    onChange?: () => void,
    button?: string | React.ReactNode,
    onButtonClick?: void,
    errorMessage?: string,
    items: any[],
    activeItem?: ActiveItemProps,
    disabledItemIds?: string[] | number[]
    onItemClick?: () => void
}

export default SelectInput;
