import { Col, Container } from "react-bootstrap";
import styled, { css } from "styled-components";
import { PLAYER_AVATAR_WIDTH } from "styles/Constants";

export const PlayerCardContainer = styled(Container).attrs({
    className: "border-light-secondary"
})`
    padding-top: .4rem;
    border-bottom-width: 1px;
    border-bottom-style: solid;
`;

export const PlayerInfoCol = styled(Col).attrs(() => ({
    className: "d-flex me-auto overflow-hidden"
}))`
`;

export const PlayerUrlWrapper = styled.div.attrs(() => ({
    className: "position-relative flex-shrink-0 clickable"
}))`
    width: ${PLAYER_AVATAR_WIDTH};
    height: ${PLAYER_AVATAR_WIDTH};
`;

export const PlayerInfoWrapper = styled.div.attrs(() => ({
    className: "h-100 player-info"
}))`
    padding-top: .8rem;
    padding-left: .4rem;
    width: calc(100% - 6.4rem);
`;

export const PlayerName = styled.div.attrs(() => ({
    className: "fz-sf-bold-body-2 text-truncate"
}))`
`;
export const PlayerText = styled.div.attrs(() => ({
    className: "fz-sf-regular-footnote"
}))`
`;
const dot = css`
    &:before {
        content: '';
        display: inline-block;
        background-color: currentColor;
        width: .8rem;
        height: .8rem;
        border-radius: 50%;
        margin-right: .2rem;
    }
`;
export const Starting = styled.div.attrs(() => ({
    className: "fz-sf-regular-caption-1 text-sys-success-1"
}))`
    ${dot}
`;
export const Substitute = styled.div.attrs(() => ({
    className: "fz-sf-regular-caption-1 text-sys-danger-1"
}))`
    ${dot}
`;
export const PlayerStatsCol = styled(Col).attrs(() => ({
    className: "d-flex justify-content-end align-items-center fz-sf-regular-subhead"
}))`
    padding-right: 1.4rem;
    font-weight: 500;
`;

export const CheckboxCol = styled(Col).attrs(() => ({
    className: "d-flex justify-content-center align-items-center"
}))`
`;
