import React from "react";
import { NoPlayersWrapper } from "./TeamPreviewStyle";
import { FormattedMessage } from "react-intl";
import Button from "../../../designToken/button/Button";
import useTeamPreview from "../useTeamPreview";
import { BUTTON_PATTERN, BUTTON_VARIANTS } from "designToken/button/types";

export const TeamPreviewWithoutPlayer: React.FC = () => {
    const teamPreview = useTeamPreview();

    return <NoPlayersWrapper data-testid="team-preview-without-player">
        <FormattedMessage id="no_player_selected_tip" />
        <Button className="d-xl-none"
                variant={BUTTON_VARIANTS.light}
                pattern={BUTTON_PATTERN.rounded}
                onClick={teamPreview.close}
        >
            <FormattedMessage id="label_start_selecting" />
        </Button>
    </NoPlayersWrapper>;
};
