import React, { useMemo } from "react";
import { Icon } from "helpers/IconHelper";
import { MY_MATCH_TAB, useMyMatch } from "domain/match/components/providers/MyMatchProvider";
import { SegmentedTab } from "components/SegmentedTab/SegmentedTab";

const MyMatchTab = () => {
    const { currentTab, setCurrentTab } = useMyMatch();
    const tabs = useMemo(() => [
        {
            key: MY_MATCH_TAB.FANTASY_SPORTS,
            name: "Fantasy Sports",
            icon: <Icon.FantasySports />
        },
        {
            key: MY_MATCH_TAB.NINE_CAT,
            name: "9-CAT",
            icon: <Icon.NineCat />
        }
    ], []);

    return <div className="tw-py-4 tw-bg-grey-50">
        <SegmentedTab tabs={tabs} currentTab={currentTab} onChangeTab={setCurrentTab} className="tw-w-[36rem]" />
    </div>;
};

export default MyMatchTab;
