import React from "react";
import { MdOutlineChevronRight } from "react-icons/md";
import { HistoryRecordItem } from "domain/wallet/components/walletHistoryRow/WalletHistoryRow";
import {
    HistoryRecordItemWithChevronButtonWrapper,
    HistoryRecordItemWrapper
} from "domain/wallet/components/walletHistoryRow/WalletHistoryRowStyle";
import { WalletHistoryVo } from "data/vo/wallet/WalletHistoryVo";

interface HistoryRecordRowProps {
    record: WalletHistoryVo,
    onRowClicked: (walletHistory: WalletHistoryVo) => void;
}

const HistoryRecordRow: React.FC<HistoryRecordRowProps> = ({ record, onRowClicked }) => {
    if (record.isShowDetail)
        return (
            <HistoryRecordItemWithChevronButtonWrapper onClick={() => onRowClicked(record)}>
                <HistoryRecordItem record={record} />
                <MdOutlineChevronRight data-testid="chevron-icon" size={24} className={"text-text-tertiary-1"} />
            </HistoryRecordItemWithChevronButtonWrapper>
        );

    return (
        <HistoryRecordItemWrapper>
            <HistoryRecordItem record={record} />
        </HistoryRecordItemWrapper>
    );
};
export default HistoryRecordRow;
