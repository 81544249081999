import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { useMobileApp } from "../useMobileApp";

interface State {
    [key: string]: any;
}

interface UseFantasyHistory {
    push: (path: string | object, state?: State) => void;
    replace: (path: string | object, state?: State) => void;
    goBack: () => void;
    getState: (key: string) => any;
    location: {
        pathname: string;
        search: string;
        state: State;
    }
}

// 若有歷史記錄，location.key會有值
export const checkNoHistory = (location) => !location.key;

const useFantasyHistory = (): UseFantasyHistory => {
    const history = useHistory();
    const { isFromMobileApp, goToApp } = useMobileApp();

    const push = useCallback((...args) => {
        history.push(...args);
    }, [history]);

    const replace = useCallback((path: string | object, state?: State) => {
        history.replace(path, state);
    }, [history]);

    const location = history.location;

    const goBack = useCallback(() => {
        if (isFromMobileApp()) {
            goToApp();
            return;
        }

        if (checkNoHistory(history.location)) {
            history.replace(ROUTER_PATH_CONFIG.home);
            return;
        }

        history.goBack();
    }, [history]);

    const getState = useCallback((key: string) => {
        const { state } = history.location;
        return state ? state[key] : null;
    }, [history.location]);

    return useMemo(() => ({
        location,
        push,
        replace,
        goBack,
        getState,
    }), [location, push, replace, goBack, getState]);
};

export default useFantasyHistory;
