import { BezierConfig } from "domain/task/components/ApLevel/BezierHelper";
import useDevice from "hooks/useDevice/useDevice";
import { APP_MAX_WIDTH_VALUE } from "styles/Constants";

export const useApLevelCarouselConfig = () => {
    const baseWidth = 440;
    const { deviceWidth } = useDevice();
    const width = Math.min(deviceWidth, APP_MAX_WIDTH_VALUE);
    const scale = width / baseWidth;
    const scaleSizes = [1.2, 0.8, 0.7, 0.6];
    const slidesPerView = 7;
    const slideWidth = width / slidesPerView;

    const leftBezierConfig: BezierConfig = {
        startPoint: [0, 0],
        endPoint: [210 * scale, 51 * scale],
        curvePoint1: [0, 28.1665 * scale],
        curvePoint2: [98.4974 * scale, 51 * scale],

    };

    const rightBezierConfig: BezierConfig = {
        startPoint: [210 * scale, 51 * scale],
        endPoint: [440 * scale, 0],
        curvePoint1: [341.503 * scale, 51 * scale],
        curvePoint2: [440 * scale, 28.1665 * scale],
    };

    const getScaleSize = (indexDiff: number) => {
        return scaleSizes[Math.min(scaleSizes.length - 1, Math.abs(indexDiff))];
    };


    return {
        scale,
        width,
        slidesPerView,
        leftBezierConfig,
        rightBezierConfig,
        slideWidth,
        getScaleSize
    };
};
