import React from "react";
import { Placeholder } from "react-bootstrap";
import { TextPlaceHolder } from "../SkeletonStyle";
import NineCatCardSkeleton from "./NineCatCardSkeleton";

export const MyNineCatSkeleton = () => {
    const renderTextPlaceHolder = () => {
        return <TextPlaceHolder $width={8} $height={1.2} $radius={0.8} className="tw-bg-grey-200" />;
    };

    return <Placeholder animation="glow" data-testid="my-match-9-cat-loading">
        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2 tw-py-6 tw-px-4">
            {renderTextPlaceHolder()}
            {renderTextPlaceHolder()}
            <NineCatCardSkeleton />
            <NineCatCardSkeleton />
        </div>
        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2 tw-py-6 tw-px-4">
            {renderTextPlaceHolder()}
            {renderTextPlaceHolder()}
            <NineCatCardSkeleton />
            <NineCatCardSkeleton />
            <NineCatCardSkeleton type="secondary" />
            <NineCatCardSkeleton type="secondary" />
        </div>
    </Placeholder>;
};
