import { useEffect } from "react";
import useFantasyCommand from "hooks/useFantasyCommand";
import { useParams } from "react-router-dom";
import { LoadingPage } from "components/loading/Loading";
import { MdCheckCircleOutline } from "react-icons/md";
import { UserRepository } from "data/repository/user/UserRepository";
import { FormattedMessage } from "react-intl";

const UserDeleteCheck = () => {
    const { code } = useParams();

    const userRepository = new UserRepository();

    const { mutate, isLoading, isIdle } = useFantasyCommand<void>(() => userRepository.deleteUser(code));

    useEffect(() => {
        mutate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading || isIdle) {
        return <LoadingPage isFullWidth={true} />;
    }

    return (
        <div className="d-flex flex-column justify-content-center align-items-center pt-4 text-center h-100">
            <MdCheckCircleOutline size={"10rem"} className="text-sys-success-2 mb-2" />
            <span className="fz-sf-bold-body-2">
                <FormattedMessage id="user_account_been_deleted" />
            </span>
        </div>
    );

};
export default UserDeleteCheck;
