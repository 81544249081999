import { CollapseIcon, ExpandIcon } from "components/matchCards/MatchCardStyle";
import { MatchInfoSummaryLayout } from "components/matchCards/UpcomingMatchCardBody";
import MyJoinedTeams from "domain/match/components/myMatches/MyJoinedTeams";
import { MY_STARTED_MATCH_SUB_TAB } from "domain/match/pages/started/MyStartedMatchHelper";
import { useSportProvider } from "domain/sport/SportProvider";
import useFantasyHistory from "hooks/useFantasyHistory";
import React, { useState } from "react";
import { getStartedMatchDetailPageUrl } from "router/RouterUrls";

const MatchInfoSummaryAndMyJoinedTeams = ({ match }) => {
    const { currentSport } = useSportProvider();
    const history = useFantasyHistory();
    const [shouldShowJoinedTeams, setShouldShowJoinedTeams] = useState(true);
    const hasMyJoinedTeams = match.rankedTeams.length > 0;

    const showJoinedTeamsToggle = (e) => {
        if (hasMyJoinedTeams) {
            e.stopPropagation();
            setShouldShowJoinedTeams(prev => !prev);
        }
    };

    const goToDetail = (e) => {
        e.stopPropagation();
        history.push(getStartedMatchDetailPageUrl(currentSport, match.matchId), {
            activeTab: MY_STARTED_MATCH_SUB_TAB.myTeams
        });
    };

    return <>
        <MatchInfoSummaryLayout teams={match.teams} contests={match.contests}
                                joined={match.joined} onClick={showJoinedTeamsToggle} />
        {
            hasMyJoinedTeams && <div onClick={goToDetail} data-testid={`${match.matchId}-my-joined-teams`}>
                {
                    shouldShowJoinedTeams
                        ? <>
                            <MyJoinedTeams match={match} />
                            <CollapseIcon />
                        </>
                        : <ExpandIcon />
                }
            </div>
        }
    </>;
};

export default MatchInfoSummaryAndMyJoinedTeams;
