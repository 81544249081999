import React from "react";
import { StyledCircleButton, StyledCircleButtonImg, StyledCircleButtonLabel, } from "./CircleButtonStyle";

interface ICircleButtonProps {
    image: string;
    text: any;
    onClick: () => void;
    badgeComponent?: any;
}

const CircleButton: React.FC<ICircleButtonProps> = ({
                                                        image,
                                                        text,
                                                        onClick,
                                                        badgeComponent,
                                                    }) => {
    return (
        <StyledCircleButton onClick={onClick}>
            <div className="tw-relative">
                <StyledCircleButtonImg $image={image}></StyledCircleButtonImg>
                {badgeComponent}
            </div>
            <StyledCircleButtonLabel>{text}</StyledCircleButtonLabel>
        </StyledCircleButton>
    );
};

export default CircleButton;
