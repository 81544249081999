import Panel from "components/panel/Panel";
import {
    Dot,
    SquadWrapper,
    TeamPreviewBottom,
    TeamPreviewContent,
    TeamPreviewIconButton,
    TeamPreviewTop,
    TeamPreviewWrapper
} from "containers/teamPreview/components/TeamPreviewStyle";
import useTeamPreview from "containers/teamPreview/useTeamPreview";
import { getSportConfig } from "domain/SportConfig";
import useDevice from "hooks/useDevice/useDevice";
import React, { useEffect, useMemo, useState } from "react";
import { MdClose } from "react-icons/md";
import { useSelector } from "store";
import { APP_MAX_WIDTH_VALUE } from "styles/Constants";

interface TeamPreviewLayoutProps {
    topLeftSlot?: React.ReactNode;
    bottomSlot?: React.ReactNode;
    squadRightSlot?: React.ReactNode;
    children: React.ReactNode;
}

export const TeamPreviewLayout: React.FC<TeamPreviewLayoutProps> = ({
                                                                        topLeftSlot,
                                                                        bottomSlot,
                                                                        squadRightSlot,
                                                                        children
                                                                    }) => {
    const {
        TeamPreviewState: {
            show,
            data: {
                match,
            },
            config: {
                sport,
                offsetHeight,
            }
        }
    } = useSelector(store => store);
    const teamPreview = useTeamPreview();

    const { team: { teamPreviewBg } } = getSportConfig(sport);
    const { deviceWidth, deviceHeight } = useDevice();
    const [windowInnerHeight, setWindowInnerHeight] = useState(0);

    const { backdrop, height } = useMemo(() => {
        const isMobile = deviceWidth < APP_MAX_WIDTH_VALUE * 2;
        const backdrop = isMobile;
        const height = isMobile ? `${windowInnerHeight - offsetHeight * 10}px` : `${windowInnerHeight}px`;

        return {
            backdrop,
            height
        };
    }, [offsetHeight, deviceWidth, windowInnerHeight]);

    useEffect(() => {
        setWindowInnerHeight(deviceHeight);
    }, [deviceHeight]);


    if (!match) {
        return null;
    }

    return (
        <Panel
            show={show}
            onHide={teamPreview.close}
            className="offset-left"
            backdrop={backdrop}
            scroll={!backdrop}
            backdropClassName="bg-preview-backdrop"
            Body={
                <TeamPreviewWrapper height={height} data-testid="team-preview-layout">
                    <TeamPreviewTop>
                        <div>
                            {topLeftSlot}
                        </div>

                        <TeamPreviewIconButton onClick={teamPreview.close}>
                            <MdClose
                                size={"2.4rem"}
                            />
                        </TeamPreviewIconButton>
                    </TeamPreviewTop>

                    <TeamPreviewContent $bgImg={teamPreviewBg}>
                        {children}
                    </TeamPreviewContent>

                    <TeamPreviewBottom>
                        <SquadWrapper>
                            <div className="d-flex align-items-center">
                                <div>
                                    <Dot className="d-inline-block text-dark-primary bg-badge-light dot" />
                                    {match.squadACode}
                                </div>
                                <div>
                                    <Dot className="d-inline-block text-dark-primary bg-badge-dark dot" />
                                    {match.squadBCode}
                                </div>
                            </div>
                            {squadRightSlot}
                        </SquadWrapper>
                        {bottomSlot}
                    </TeamPreviewBottom>
                </TeamPreviewWrapper>
            } />
    );
};
