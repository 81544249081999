import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { cn } from "helpers/cn";
import { IconOmitProps } from "helpers/IconHelper";
import { camelCase } from "lodash";
import React from "react";
import { IconBaseProps } from "react-icons";
import styled from "styled-components";

const patternToClass = {
    [BUTTON_PATTERN.rounded]: "normal",
    [BUTTON_PATTERN.pill]: "rounded-pill"
};

const patternToTailwindClass = {
    [BUTTON_PATTERN.rounded]: "",
    [BUTTON_PATTERN.pill]: "tw-rounded-full"
};

export const StyledButton = styled.button.attrs<{
    $variant: BUTTON_VARIANTS | NEW_BUTTON_VARIANTS,
    $size: BUTTON_SIZE,
    $pattern: BUTTON_PATTERN
}>(({ $variant, $size, $pattern }) => ({
    className: `d-flex justify-content-center align-items-center gap-3 btn btn-${$variant} btn-${$size} ${patternToClass[$pattern]} ${BUTTON_SIZE.lg === $size ? "fz-sf-regular-title-2" : "fz-sf-regular-body-1"}`
}))`
    .text {
        font: inherit;
    }
`;

interface ButtonProps {
    variant?: BUTTON_VARIANTS | NEW_BUTTON_VARIANTS
    size?: BUTTON_SIZE
    pattern?: BUTTON_PATTERN
    disabled?: boolean
    prefixIcon?: React.FC<IconOmitProps> | React.FC<IconBaseProps>;
    suffixIcon?: React.FC<IconOmitProps> | React.FC<IconBaseProps>;
    className?: string
    onClick?: () => void
    children?: React.ReactNode
    style?: Object
    dataTestId?: string,
    id?: string
}

const BtnIcon: React.FC<{ icon?: React.FC<IconOmitProps> | React.FC<IconBaseProps> }> = ({ icon: Icon }) => {
    if (!Icon) return null;

    return <Icon />;
};

const Button = React.forwardRef(({
                                     variant = BUTTON_VARIANTS.primary,
                                     size = BUTTON_SIZE.md,
                                     pattern = BUTTON_PATTERN.rounded,
                                     disabled = false,
                                     onClick,
                                     children,
                                     className,
                                     dataTestId,
                                     id,
                                     suffixIcon,
                                     prefixIcon,
                                 }: ButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    if (NEW_BUTTON_VARIANTS[camelCase(variant)]) {
        return <button
            ref={ref}
            className={cn(`tw-btn tw-btn-${size} tw-btn-${variant} ${patternToTailwindClass[pattern]}`, className)}
            disabled={disabled}
            onClick={onClick}
            data-testid={dataTestId}
            id={id}
        >
            <BtnIcon icon={prefixIcon} />
            <span className="text">{children}</span>
            <BtnIcon icon={suffixIcon} />
        </button>;
    }

    return <StyledButton
        ref={ref}
        className={className}
        $variant={variant}
        $size={size}
        $pattern={pattern}
        disabled={disabled}
        onClick={onClick}
        data-testid={dataTestId}
        id={id}
    >
        <BtnIcon icon={prefixIcon} />
        <span className="text">{children}</span>
        <BtnIcon icon={suffixIcon} />
    </StyledButton>;
});

export default Button;
