import { isNumber } from "lodash";
import moment from "moment/moment";

const minute = 60 * 1_000;
const hour = 60 * minute;
const day = 24 * hour;

export const tenMinutes = 10 * minute;

export const twentyFourHours = 24 * hour;

export const hourToMillisecond = (hour) => hour * 60 * 60 * 1_000;

export const isExpired = (expiresAt) => new Date().getTime() >= expiresAt;

export const getAccessTokenExpiresAt = (expiresIn) =>
    new Date().getTime() + expiresIn * 1_000;

export const getReferralCodeExpiresAt = (createTime, validHour) =>
    createTime + hourToMillisecond(validHour);

export const formatToFullYearDateTime = (time: number | null) => {
    if (!isNumber(time)) {
        return "";
    }
    return moment(time).format("MMM DD, YYYY HH:mm");
};

export const formatToFullYearDate = (time) => {
    return moment(time).format("MMM DD, YYYY");
};

export const formatBirthdate = (time) => {
    return moment(time).format("YYYY/MM/DD");
};

export const formatTo24HrTime = (time) => {
    return moment(time).format("HH:mm");
};

export const formatToMMMDD = (time: number) => moment(time).format("MMM DD");

export const formatTimeRange = (startTime, endTime) => {
    return `${formatToMMMDD(startTime)} - ${formatToFullYearDate(endTime)}`;
};

export function formatToMMMYYYY(time: number) {
    return moment(time).format("MMM, YYYY");
}

export function getPeriodFormat(from: number, until: number) {
    const lastDay = moment(until - 1)
        .utc()
        .format("DD");
    return moment(from).utc().format(`MMM DD ~ ${lastDay}, YYYY`);
}

export const durationFormat = (seconds: number) => {
    const duration = moment().startOf("day").add(seconds, "seconds");
    let format = "";

    if (duration.hour() > 0) {
        format += "H[h] ";
    }

    if (duration.minute() > 0) {
        format += "m[m] ";
    }

    format += "s[s]";

    return duration.format(format);
};

export const isRegisteredIn24Hours = (registeredAt: number) => {
    return registeredAt + twentyFourHours > new Date().getTime();
};

export const formatTimeAgo = (time: number) => {
    let now = new Date().getTime();
    let diff = now - time;
    if (diff < minute) {
        return `${Math.floor(diff / 1000)} second(s)`;
    }
    if (diff < hour) {
        return `${Math.floor(diff / (minute))} minute(s)`;
    }
    if (diff < day) {
        return `${Math.floor(diff / (hour))} hour(s)`;
    }
    return `${Math.floor(diff / (day))} day(s)`;

};

function getCompletedDatePattern(duration: number) {
    return `${["[Today]", "[Yesterday]", "dddd"][Math.min(duration, 2)]}, MMM DD`;
}

export const getMyMatchCompletedDate = (time: number) => {
    const today = moment().startOf("day");
    const timeAtMoment = moment(time);
    const duration = Math.abs(Math.floor(moment.duration(timeAtMoment.diff(today)).as("days")));
    return timeAtMoment.format(getCompletedDatePattern(duration));
};

function getUpcomingDatePattern(duration) {
    return `${["[Yesterday]", "[Today]", "[Tomorrow]", "dddd"][Math.min(duration, 3)]}, MMM DD`;
}

export const getMyMatchUpcomingDate = (time: number) => {
    const today = moment().startOf("day");
    const timeAtMoment = moment(time);
    const duration = Math.floor(moment.duration(timeAtMoment.diff(today)).as("days")) + 1;
    return timeAtMoment.format(getUpcomingDatePattern(duration));
};


