import React from "react";
import { cn } from "helpers/cn";

interface BorderProps {
    children: React.ReactNode;
}

export const Border: React.FC<BorderProps> = ({ children }) => {
    return <div className={cn(
        "tw-p-[1.5px] !tw-rounded-[31px]",
        "tw-bg-gradient-to-r tw-from-[#A31428] tw-to-[#EAD431]",
        "tw-shadow-md",
    )}>
        <div className={cn(
            "tw-w-[246px] tw-h-[192px] tw-bg-white tw-pt-[54px] !tw-rounded-[30px]",
            "tw-relative tw-overflow-hidden",
            "tw-flex tw-flex-col tw-items-center",
        )}>
            {children}
        </div>
    </div>;
};
