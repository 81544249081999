import { DarkTopNavbar } from "components/container/navbar/TopNavbar";
import { TaskButton } from "domain/home/components/taskButton";
import NewWalletButton from "domain/wallet/components/button/TopNavbarWalletButton";
import { cn } from "helpers/cn";
import { logoPrimaryHorizontal } from "helpers/IconHelper";
import useScrollDetector from "hooks/useScrollDetector";
import React from "react";


const B2CHomeNavbar = () => {
    const isScrolled = useScrollDetector(10);

    return <DarkTopNavbar
        className={cn({
            "tw-flex tw-items-center tw-gap-2": true,
            "tw-shadow-navbar": !isScrolled,
        })}
        data-testid="b2c-home-navbar"
    >
        <div className="tw-h-[2.8rem] tw-mr-auto">
            <img className="tw-h-full" src={logoPrimaryHorizontal} alt="logo" />
        </div>
        <NewWalletButton />
        <TaskButton />
    </DarkTopNavbar>;
};

export default B2CHomeNavbar;
