import { circleCloseIcon } from "helpers/IconHelper";
import styled from "styled-components";

const addFriendIcon = "/images/add_friend_icon.png";
const transferIcon = "/images/transfer_icon.png";

export const SearchUserBarForm = styled.form.attrs({
    className: "position-relative"
})`
  padding-top: 2rem;

  & .clear-button {
    display: none;
  }

  &:focus-within {
    & .search-friend-wrapper {
      display: flex;
    }

    & .clear-button {
      display: block;
    }

    & .input-label {
      top: 0;
    }

    & input {
      padding: .55rem 2.5rem .55rem .8rem;
    }
  }
`;
export const SearchUserInput = styled.input.attrs({
    className: "fz-sf-regular-body-1 tw-text-system-labelLightPrimary"
})`
  padding: .55rem .8rem;
  width: 100%;
  border: none;
  border-bottom: 1px solid ${({ borderColor }) => borderColor};
  background-color: transparent;

  &:focus-visible {
    outline: none;
  }
`;
export const ClearButton = styled.div.attrs({
    className: "position-absolute clear-button"
})`
  top: 50%;
  right: 0;
  width: 2.4rem;
  padding-bottom: 2.4rem;
  background-image: url(${circleCloseIcon});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const InputLabel = styled.div.attrs({
    className: "fz-sf-regular-subhead-1 position-absolute input-label"
})`
  transition: top 0.3s ease;
  top: 1.5rem;
  color: ${({ color }) => color};

  &.nonempty-input {
    top: 0;
  }
`;

export const WarningLabel = styled.div.attrs({
    className: "fz-sf-regular-footnote tw-text-system-red"
})`
  height: 1.6rem;
`;
export const SearchFriendWrapper = styled.div.attrs({
    className: "align-items-center position-absolute search-friend-wrapper tw-bg-system-bgLightSecondary"
})`
  display: none;
  padding: .8rem;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  cursor: pointer;
  z-index: 1;
`;

export const NameWrapper = styled.div.attrs({})`
  margin-left: .8rem;
`;

export const SearchFriendNickName = styled.div.attrs({
    className: "fz-sf-bold-body-2 tw-text-system-primary80"
})``;
export const SearchFriendId = styled.div.attrs({
    className: "fz-sf-regular-subhead tw-text-system-labelLightSecondary"
})`
`;
export const ButtonWrapper = styled.div.attrs({
    className: "ms-auto"
})``;

export const AddFriendIcon = styled.div.attrs({})`
  margin-right: .8rem;
  width: 2.4rem;
  padding-bottom: 2.4rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(${addFriendIcon});
  cursor: pointer;
`;
export const TransferIcon = styled(AddFriendIcon)`
  background-image: url(${transferIcon});
`;
