import { ApiError } from "apis/Api";
import { useWarningDialog } from "hooks/useDialog";
import useSignOut from "hooks/useSignOut";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { createErrorObject, isLocked, isSuspiciousEmail, isUnauthorized } from "helpers/ErrorHelper";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { DIALOG_BACKDROP } from "designToken/dialog/Dialog";
import useFantasyHistory from "hooks/useFantasyHistory";

export enum ERROR_HANDLER_FLOW {
    COUNTINUE,
    STOP
}

const useHandleApiError = () => {

    const history = useFantasyHistory();
    const intl = useIntl();
    const warningDialog = useWarningDialog();
    const signOut = useSignOut();

    const goToCommonErrorPage = (error: ApiError) => {
        history.push(ROUTER_PATH_CONFIG.error, {
            error: createErrorObject(error)
        });
    };

    const showSuspiciousEmailDialog = useCallback(() => {
        warningDialog({
            title: intl.formatMessage({ id: "suspicious_email_dialog_label_1" }),
            content: intl.formatMessage({ id: "suspicious_email_dialog_label_2" }),
            cancelButton: {
                onClick: signOut,
                text: intl.formatMessage({ id: "dialog_button_back" })
            },
            backdrop: DIALOG_BACKDROP.STATIC
        });
    }, [intl, warningDialog, signOut]);

    const showLockDialog = useCallback(() => {
        warningDialog({
            title: intl.formatMessage({ id: "user_locked_dialog_label_1" }),
            content: intl.formatMessage({ id: "user_locked_dialog_label_2" }),
            cancelButton: {
                onClick: signOut,
                text: intl.formatMessage({ id: "dialog_button_back" })
            },
            backdrop: DIALOG_BACKDROP.STATIC
        });
    }, [intl, warningDialog, signOut]);

    return useCallback(async (error: ApiError, onFail?: (e: ApiError) => ERROR_HANDLER_FLOW) => {
        if (isUnauthorized(error)) {
            await signOut();
            return;
        }

        if (isLocked(error)) {
            showLockDialog();
            return;
        }

        if (isSuspiciousEmail(error)) {
            showSuspiciousEmailDialog();
            return;
        }

        if (onFail && onFail(error) === ERROR_HANDLER_FLOW.STOP) {
            return;
        }

        goToCommonErrorPage(error);
    }, [showLockDialog, showSuspiciousEmailDialog, signOut]);

};

export default useHandleApiError;
