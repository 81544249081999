import cx from "classnames";
import { apLevelConfig, getAvailableUpgrades } from "domain/ap/ActivityPointHelper";
import { ApBadge } from "domain/ap/ActivityPointProgramStyle";
import { LevelBadge } from "domain/ap/ActivityPointProgressStyle";
import {
    BadgeWrapper,
    ContentRow,
    HeaderCol,
    HeaderRow,
    Level,
    LevelTitle,
    RequiredAp,
    RequiredCol,
    TransferLimit,
    TransferLimitCol
} from "domain/ap/UpgradeStyle";
import { WalletCircleButtonWrapper } from "domain/wallet/components/button/WalletButtonStyle";
import { getCurrentAp, getRequiredDeposit } from "helpers/ApLevelHelper";
import { apIcon, buyCoinIcon, Icon } from "helpers/IconHelper";
import { divideByOneHundred, multiplyByOneHundred } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import useFantasyHistory from "hooks/useFantasyHistory";
import PropTypes from "prop-types";
import React from "react";
import { Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";

import { AP_LEVEL } from "types/activityPoint/ApLevel";
import { MyApInfo } from "types/activityPoint/MyApInfo";
import { useSelector } from "../../store";

interface UpgradeProps {
    myApInfo: MyApInfo;
}

const Upgrade = ({ myApInfo }: UpgradeProps) => {
    const history = useFantasyHistory();
    const { currentCurrencyObject: { toWalletNumber, toFormattedNumber } } = useCurrency();
    const { featureConfig } = useSelector((store) => store.FantasyGameConfigState);

    const navbarBackGoTo = history.location?.state?.navbarBackGoTo;

    return <>
        <Container>
            <HeaderRow>
                <HeaderCol>
                    <FormattedMessage id="activity_point_page_upgrade_section_label_level" />
                </HeaderCol>
                <HeaderCol>
                    <FormattedMessage id="activity_point_page_upgrade_section_label_ap_needed" />
                </HeaderCol>
            </HeaderRow>
            {
                getAvailableUpgrades(myApInfo.upgrades).map((upgrade) => {
                    return <ContentRow key={upgrade.level}>
                        <TransferLimitCol>
                            <BadgeWrapper>
                                <LevelBadge src={apLevelConfig[upgrade.level]?.image} />
                            </BadgeWrapper>
                            <Level>
                                <LevelTitle>{apLevelConfig[upgrade.level]?.text()}</LevelTitle>
                                {
                                    featureConfig.transfer &&
                                    <TransferLimit>
                                        <FormattedMessage
                                            id="activity_point_page_upgrade_section_label_transfer_limit_by_level"
                                            values={{ number: toWalletNumber(divideByOneHundred(upgrade.transferWinningLimitCent)) }}
                                        />
                                    </TransferLimit>
                                }
                            </Level>
                        </TransferLimitCol>
                        <RequiredCol>
                            {
                                upgrade.level === AP_LEVEL.STEEL ? (
                                    <RequiredAp>
                                        <FormattedMessage id="task_label_buy_any_coins" />
                                        <ApBadge $bgImg={buyCoinIcon} />
                                    </RequiredAp>
                                ) : (
                                    <RequiredAp>
                                        {toFormattedNumber(upgrade.minAp)}
                                        <ApBadge $bgImg={apIcon} className="tw-mb-1" />
                                    </RequiredAp>
                                )
                            }

                            <NavLink className={cx("ms-auto", { "tw-invisible": !featureConfig.addCoin })}
                                     id="gtm_add_deposit_button"
                                     to={{
                                         pathname: ROUTER_PATH_CONFIG.deposit.select,
                                         state: {
                                             requiredDepositCent: multiplyByOneHundred(getRequiredDeposit(getCurrentAp(myApInfo), upgrade.minAp)),
                                             navbarBackGoTo,
                                             addDepositFlowFinishedGoTo: `${history.location.pathname}?tab=Upgrade`
                                         }
                                     }}>
                                <WalletCircleButtonWrapper>
                                    <Icon.PlusPiggyBank />
                                </WalletCircleButtonWrapper>
                            </NavLink>
                        </RequiredCol>
                    </ContentRow>;
                })}
        </Container>
    </>;
};

export default Upgrade;


Upgrade.propTypes = {
    myApInfo: PropTypes.shape({
        accumulationPeriod: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number])).isRequired,
        currentApBet: PropTypes.number.isRequired,
        currentApDeposit: PropTypes.number.isRequired,
        nextMonthRemainingAp: PropTypes.number.isRequired,
        transferWinningLimitCent: PropTypes.number.isRequired,
        transferredTodayCent: PropTypes.number.isRequired,
        upgrades: PropTypes.arrayOf(PropTypes.shape({
            level: PropTypes.string.isRequired,
            minAp: PropTypes.number.isRequired,
            transferWinningLimitCent: PropTypes.number.isRequired
        })).isRequired
    }).isRequired
};
