import { Container } from "react-bootstrap";
import styled from "styled-components";
import { APP_MAX_WIDTH } from "../../../styles/Constants";
import { UserCode } from "../../me/me/MeStyle";

export const FriendListWrapper = styled(Container).attrs({})`
    padding: 1.4rem 0.8rem;
`;
export const FriendListItem = styled.div.attrs({
    className: "d-flex align-items-center tw-border-system-neutral200",
})`
    border-width: 0 0 1px 0;
    border-style: dashed;
    padding: 1.2rem 0.4rem;
    cursor: pointer;

    &:last-child {
        border-bottom: none;
    }
`;

export const FriendListNickname = styled.div.attrs({
    className: "fz-barlow-regular-body-2 text-truncate tw-text-system-labelLightPrimary",
})`
    max-width: 13.4rem;
`;

export const TopFriendListFullNotification = styled.div.attrs({
    className: "d-flex align-items-center justify-content-start tw-bg-system-red300",
})`
    padding: 1.6rem;
    width: 100%;
    max-width: ${APP_MAX_WIDTH};
    position: sticky;
    top: 6.4rem;
    left: 0;
    z-index: 2;
`;
export const FriendListNicknameWrapper = styled.div.attrs({
    className: "d-flex align-items-baseline",
})`
    margin-left: 0.8rem;
`;

export const FriendCode = styled(UserCode).attrs({
    className: "ms-2 tw-text-system-labelLightSecondary",
})`
`;

export const SearchUserIcon = styled.span<{ bgImg: string }>`
    background: url(${({ bgImg }) => bgImg}) center no-repeat;
    background-size: contain;
    width: 2.4rem;
    padding-bottom: 2.4rem;
    cursor: pointer;
`;
export const NotificationText = styled.div.attrs({
    className: "fz-sf-regular-footnote tw-text-system-red"
})`
    width: calc(100% - 12.2rem);
`;
