import { ApiError } from "apis/Api";
import { useLoadingToggle } from "components/loading/Loading";
import { PayMongoRepository } from "data/repository/payment/PayMongoRepository";
import { PAYMENT_STATUS, toSelectedTeamIdsString } from "domain/coin/AddCoinHelper";
import { PaymentMethods } from "domain/coin/pages/SelectPaymentMethod/components/PaymentMethods";
import { createErrorObject } from "helpers/ErrorHelper";
import { setQuery } from "helpers/queryString/set/SetQuery";
import useFantasyCommand from "hooks/useFantasyCommand";
import useFantasyConfig from "hooks/useFantasyConfig";
import useFantasyHistory from "hooks/useFantasyHistory";
import useHandleApiError, { ERROR_HANDLER_FLOW } from "hooks/useHandleApiError";
import React from 'react';
import { useDispatch } from "react-redux";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";

import { addErrorPopup } from "store/actions/AlertActions";

interface PayMongoPaymentMethodProps {
    productId: number;
}

const paymentMethodDetails = {
    "PayMongo": {
        name: "Pay Mongo",
        image: "/images/pay_mongo.png"
    }
};

const PayMongoPaymentMethods: React.FC<PayMongoPaymentMethodProps> = ({ productId }) => {
    const history = useFantasyHistory();
    const dispatch = useDispatch();
    const handleApiError = useHandleApiError();
    const { gameUrl } = useFantasyConfig();
    const payMongoRepository = new PayMongoRepository();
    const toggleLoading = useLoadingToggle();


    const successUrl = `${gameUrl}${ROUTER_PATH_CONFIG.deposit.index.replace(':status', PAYMENT_STATUS.SUCCESS)}`;
    const cancelUrl = `${gameUrl}${ROUTER_PATH_CONFIG.deposit.index.replace(':status', PAYMENT_STATUS.CANCEL)}`;
    const queryString = setQuery({
        ...history.location?.state,
        addDepositFlowFinishedGoTo: history.location?.state?.addDepositFlowFinished ?? ROUTER_PATH_CONFIG.home,
        selectedTeamsIds: toSelectedTeamIdsString(
            history.location?.state?.selectedTeamsIds,
        ),
    });

    const { mutateAsync } = useFantasyCommand(() => payMongoRepository.initPayment({
        productId,
        successUrl: `${successUrl}?${queryString}`,
        cancelUrl: `${cancelUrl}?${queryString}`,
    }), {
        errorHandle: false
    });

    const onMethodClick = async () => {
        toggleLoading(true);
        try {
            window.location.href = await mutateAsync();
        } catch (error) {
            await handleApiError(error as ApiError, (error) => {
                dispatch(addErrorPopup(createErrorObject(error)));
                return ERROR_HANDLER_FLOW.STOP;
            });
            toggleLoading(false);
        }
    };

    return (<>
            <PaymentMethods title={"Payment Method"}
                            methods={["PayMongo"]}
                            paymentMethodDetails={paymentMethodDetails}
                            onMethodClick={onMethodClick} />
        </>
    );
};

export default PayMongoPaymentMethods;
