import { formatToMMMYYYY, getPeriodFormat, } from "helpers/TimeHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import { flow, groupBy, orderBy } from "lodash";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdInfoOutline } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import UserAvatarWithFrame from "../../../../../components/avatar/UserAvatarWithFrame";
import { UserProfileStatsVo } from "../../../../../data/vo/user/UserProfileStatsVo";
import { sportComparator } from "../../../../../helpers/SortingHelper";
import { SPORT } from "../../../../../types/sports/Sport";
import useUserProfileContext from "../../useUserProfileContext";
import { UserProfileCareerStatsRowWrapper } from "./UserProfileCareerStatsStyle";

interface CareerStatsProps {
    myCareerStats: {
        registeredAt: number;
        stats: UserProfileStatsVo[];
    };
    otherCareerStats: {
        registeredAt: number;
        stats: UserProfileStatsVo[];
    };
    from: number;
    until: number;
}

interface RowTopScoreProps {
    other: UserProfileStatsVo;
    i18nSportId: string;
    my: UserProfileStatsVo;
}

interface TopScoreProps {
    targetTopScore: number | null;
    compareWith: number;
}

interface RowAvatarProps {
    my: {
        avatar: string;
        nickname: string;
    };
    other: {
        avatar: string;
        nickname: string;
    };
}

const TopScore: React.FC<TopScoreProps> = ({
                                               compareWith,
                                               targetTopScore,
                                           }) => {
    if (targetTopScore === null) {
        return <div className="text-light-primary fz-sf-bold-title-3">-</div>;
    }

    return (
        <>
            {
                targetTopScore > compareWith
                    ? (<div className="text-secondary-2 fz-sf-bold-title-3">
                        {targetTopScore}
                    </div>)
                    : (<div className="text-light-primary fz-sf-bold-title-3">
                        {targetTopScore}
                    </div>)
            }
        </>
    );
};

const LabelPR: React.FC<{ userInfo: UserProfileStatsVo }> = ({ userInfo }) => {
    const { topScore, topScorePr } = userInfo;

    return (
        <div className="text-light-secondary">
            <span className="fz-sf-regular-footnote">PR</span>
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip>
                        <FormattedMessage
                            id="me_page_career_stats_label_description"
                            values={{
                                topScore: topScore ?? "-",
                                pr: `${topScorePr}`,
                            }}
                        ></FormattedMessage>
                    </Tooltip>
                }
            >
                <span className="fz-sf-regular-footnote ms-1">
                    <MdInfoOutline size="1.2rem"></MdInfoOutline>
                </span>
            </OverlayTrigger>
            <span className="fz-sf-regular-footnote ms-1">: {topScorePr}%</span>
        </div>
    );
};

const RowTopScore: React.FC<RowTopScoreProps> = ({
                                                     i18nSportId,
                                                     my,
                                                     other,
                                                 }) => {
    return (
        <>
            <div className="col-4">
                <TopScore targetTopScore={other.topScore} compareWith={my.topScore ?? 0} />
                <LabelPR userInfo={other} />
            </div>
            <div className="col-4">
                <div className="text-light-primary fz-sf-bold-subhead">
                    <FormattedMessage id={i18nSportId} />
                </div>
                <div className="text-light-secondary fz-sf-regular-footnote">
                    <FormattedMessage id="me_page_career_stats_label_top_score" />
                </div>
            </div>
            <div className="col-4">
                <TopScore targetTopScore={my.topScore} compareWith={other.topScore ?? 0} />
                <LabelPR userInfo={my} />
            </div>
        </>
    );
};

const RowInfo = (props: { other: number; i18nLabelId: string; my: number }) => {
    const {
        currentCurrencyObject: { toFormattedNumber },
    } = useCurrency();

    return (
        <>
            <div className="text-light-primary fz-sf-regular-body-1 col-4">
                {toFormattedNumber(props.other)}
            </div>
            <div className="text-light-secondary fz-sf-regular-footnote col-4">
                <FormattedMessage id={props.i18nLabelId} />
            </div>
            <div className="text-light-primary fz-sf-regular-body-1 col-4">
                {toFormattedNumber(props.my)}
            </div>
        </>
    );
};

const RowSince = (props: {
    other: string;
    i18nLabelId: string;
    my: string;
}) => {
    return (
        <>
            <div className="text-light-primary fz-sf-regular-body-1 text-center col-4">
                {props.other}
            </div>
            <div className="text-light-secondary fz-sf-regular-footnote col-4">
                <FormattedMessage id={props.i18nLabelId} />
            </div>
            <div className="text-light-primary fz-sf-regular-body-1 col-4">
                {props.my}
            </div>
        </>
    );
};

const RowAvatar: React.FC<RowAvatarProps> = ({ my, other }) => {
    return (
        <>
            <div className="d-flex flex-column align-items-center mb-2 col-4">
                <UserAvatarWithFrame
                    font="fz-sf-regular-subhead"
                    url={other.avatar}
                    name={other.nickname}
                    size="3.6rem"
                />
                <div className="fz-sf-bold-footnote">{other.nickname}</div>
            </div>
            <div className="d-flex flex-column align-items-center mb-2 col-4 offset-4">
                <UserAvatarWithFrame
                    font="fz-sf-regular-subhead"
                    url={my.avatar}
                    name={my.nickname}
                    size="3.6rem"
                />
                <div className="fz-sf-bold-footnote">
                    <FormattedMessage id="label_you" />
                </div>
            </div>
        </>
    );
};

const DataFrom = ({ from, until }) => {
    return (
        <div className="d-flex justify-content-end text-light-secondary fz-sf-regular-subhead mt-2">
            <FormattedMessage id="me_page_career_stats_label_data_from" />
            {getPeriodFormat(from, until)}
        </div>
    );
};

function calculateJoined(statsDto: UserProfileStatsVo[], key: string) {
    return statsDto.reduce((total, userInfoStatsVO) => {
        return total + userInfoStatsVO[key];
    }, 0);
}


export const UserProfileCareerStats: React.FC<CareerStatsProps> = ({
                                                                       myCareerStats,
                                                                       otherCareerStats,
                                                                       from,
                                                                       until,
                                                                   }) => {
    const { myProfile, userInfo: otherUser } = useUserProfileContext();

    const careerStatsList = flow(
        (stat: UserProfileStatsVo[]) => groupBy(stat, (stat: { sport: SPORT; }) => stat.sport),
        (sportToStatsList: { [key in SPORT]: UserProfileStatsVo[] }) => orderBy(Object.entries(sportToStatsList), ([sport]) => sportComparator(sport)),
    )([...otherCareerStats.stats, ...myCareerStats.stats]);

    return (
        <div className="pt-4">
            <div className="fz-sf-bold-body-2 text-light-primary mb-2">
                <FormattedMessage id="me_page_career_stats_title" />
            </div>

            <div className="border rounded border-light-primary px-5">
                <UserProfileCareerStatsRowWrapper className="text-light-primary border-bottom">
                    <RowAvatar
                        my={{ avatar: myProfile.avatar, nickname: myProfile.nickname }}
                        other={otherUser}
                    />
                </UserProfileCareerStatsRowWrapper>
                {
                    careerStatsList.map(([sport, [otherStat, myStats]]) => {
                        return <UserProfileCareerStatsRowWrapper key={sport} className="border-bottom">
                            <RowTopScore
                                i18nSportId={`sport_tab_label_${sport.toLowerCase()}`}
                                other={otherStat ?? new UserProfileStatsVo({ sport })}
                                my={myStats ?? new UserProfileStatsVo({ sport })}
                            />
                        </UserProfileCareerStatsRowWrapper>;
                    })
                }

                <UserProfileCareerStatsRowWrapper className="border-bottom">
                    <RowInfo
                        i18nLabelId="career_stats_contests"
                        other={calculateJoined(otherCareerStats.stats, "joinedContests")}
                        my={calculateJoined(myCareerStats.stats, "joinedContests")}
                    />
                </UserProfileCareerStatsRowWrapper>

                <UserProfileCareerStatsRowWrapper className="border-bottom">
                    <RowInfo
                        i18nLabelId="career_stats_matches"
                        other={calculateJoined(otherCareerStats.stats, "joinedMatches")}
                        my={calculateJoined(myCareerStats.stats, "joinedMatches")}
                    />
                </UserProfileCareerStatsRowWrapper>

                <UserProfileCareerStatsRowWrapper>
                    <RowSince
                        i18nLabelId="career_stats_playing_since"
                        other={formatToMMMYYYY(otherCareerStats.registeredAt)}
                        my={formatToMMMYYYY(myCareerStats.registeredAt)}
                    />
                </UserProfileCareerStatsRowWrapper>
            </div>
            <DataFrom from={from} until={until} />
        </div>
    );
};
