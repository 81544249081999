import { LoadingPage } from "components/loading/Loading";
import { auth } from "domain/auth/AuthConfig";
import { signInWithCustomToken, signOut } from "firebase/auth";
import { getQuery } from "helpers/queryString";
import { setQuery } from "helpers/queryString/set/SetQuery";
import { hasText } from "helpers/string/StringHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import { mobileAppKey } from "hooks/useMobileApp";
import { LOCALE, SetLocaleContext } from "locales";
import { useCallback, useContext, useEffect } from "react";
import { matchPath } from "react-router";
import { useParams } from "react-router-dom";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";

const nonLoginPageMatchers = [
    ROUTER_PATH_CONFIG.deleteUser.index,
    ROUTER_PATH_CONFIG.deleteUser.check,
].map((path) => ({ path: path, exact: true, strict: true }));

const MobileAppLogin = () => {
    const history = useFantasyHistory();
    const { path: redirectPath } = useParams();
    const { token: customToken, locale, ...redirectQuery } = getQuery();
    const setLocale = useContext(SetLocaleContext);

    const redirect = useCallback(() => {
        history.replace(`/${redirectPath}?${setQuery({
            ...redirectQuery,
            from: mobileAppKey
        })}`);
    }, [history, redirectPath, redirectQuery]);

    useEffect(() => {
        (async () => {
            for (const matcher of nonLoginPageMatchers) {
                if (matchPath(`/${redirectPath}`, matcher) !== null) {
                    redirect();
                    return;
                }
            }

            if (!hasText(customToken)) {
                history.replace(ROUTER_PATH_CONFIG.fromMobileAppError);
                return;
            }

            try {
                await signOut(auth);
                await signInWithCustomToken(auth, customToken as string);
            } catch {
                history.replace(ROUTER_PATH_CONFIG.fromMobileAppError);
                return;
            }

            redirect();
        })();
    }, [customToken, redirectPath, redirectQuery, history, redirect]);

    useEffect(() => {
        if (hasText(locale)) {
            setLocale(locale as LOCALE);
        }
    }, [locale, setLocale]);

    return <LoadingPage />;
};

export default MobileAppLogin;
