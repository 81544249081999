import styled from "styled-components";

interface ResultMatchesNavbarWrapperProps {
    $height: number;
}

export const ResultMatchesNavbarWrapper = styled.div<ResultMatchesNavbarWrapperProps>`
    ${({ $height }) => {
        return `
            height: ${$height}px;
        `;
    }}
`;
