import {
    isMatchCompleted,
    isMatchLive,
    isMatchPreparing,
    isMatchProcessing,
    isMatchUpcoming
} from "helpers/match/MatchHelper";
import React from "react";
import { MY_STARTED_MATCH_SUB_TAB } from "../../pages/started/MyStartedMatchHelper";
import { useStartedMatchDetailData } from "../../providers/MyStartedMatchDataProvider";
import { useStartedMatchDetailFlow } from "../../providers/MyStartedMatchFlowProvider";
import MyContestsContent from "./MyContestContent";
import MyContestContentWithJoinButton from "./MyContestContentWithJoinButton";

const MyContestSwitcher = ({ onChangeTab }) => {
    const { onJoinButtonClick } = useStartedMatchDetailFlow();
    const startedMatchDetailData = useStartedMatchDetailData();
    const myContests = startedMatchDetailData.myContests!;
    const match = startedMatchDetailData.match!;

    if (isMatchUpcoming(match.matchStatus) || isMatchPreparing(match.matchStatus)) return null;

    if (isMatchLive(match.matchStatus) || isMatchProcessing(match.matchStatus) || isMatchCompleted(match.matchStatus)) {
        return (
            <MyContestsContent myContests={myContests} match={match} />
        );
    }

    return <MyContestContentWithJoinButton
        contests={myContests}
        match={match}
        onJoinButtonClick={onJoinButtonClick}
        onNoContestButtonClick={() => {
            onChangeTab(MY_STARTED_MATCH_SUB_TAB.contests);
        }}
    />;
};


export default MyContestSwitcher;
