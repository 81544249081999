import { TeamPreviewLayout } from "./components/TeamPreviewLayout";
import React, { useEffect } from "react";
import useTeamPreview from "./useTeamPreview";
import { useLocation } from "react-router-dom";
import TeamPreviewContentFactory from "./factories/TeamPreviewContentFactory";
import TeamPreviewBottomSlotFactory from "./factories/TeamPreviewBottomSlotFactory";
import TeamPreviewSquadRightSlotFactory from "./factories/TeamPreviewSquadRightSlotFactory";
import TeamPreviewTopLeftSlotFactory from "./factories/TeamPreviewTopLeftSlotFactory";

const TeamPreview = () => {
    const location = useLocation();

    const teamPreview = useTeamPreview();

    useEffect(() => {
        return () => {
            teamPreview.close();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return <TeamPreviewLayout
        topLeftSlot={<TeamPreviewTopLeftSlotFactory />}
        squadRightSlot={<TeamPreviewSquadRightSlotFactory />}
        bottomSlot={<TeamPreviewBottomSlotFactory />}
    >
        <TeamPreviewContentFactory />
    </TeamPreviewLayout>;
};
export default TeamPreview;
