import { Profile } from "data/vo/player/PlayerInfoVo";
import { LINEUP } from "types/player/Player";
import { ROLE } from "types/sports/Sport";

export class PlayerInfoProfileVo {
    private readonly _lastName: string | null;
    private readonly _firstName: string | null;
    private readonly _nationality: string | null;
    private readonly _lineup: LINEUP;
    private readonly _playerSelectionRate: number;
    private readonly _captainSelectionRate: number;
    private readonly _viceCaptainSelectionRate: number;
    private readonly _pointAvgCent: number;
    private readonly _creditCent: number;
    private readonly _role: ROLE | null;
    private readonly _middleName: string | null;
    private readonly _birthdate: string | null;

    constructor(profile: Profile) {
        this._birthdate = profile.birthdate;
        this._creditCent = profile.creditCent;
        this._firstName = profile.firstName;
        this._middleName = profile.middleName;
        this._lastName = profile.lastName;
        this._lineup = profile.lineup;
        this._nationality = profile.nationality;
        this._pointAvgCent = profile.pointAvgCent;
        this._role = profile.role;
        this._playerSelectionRate = profile.playerSelectionRate;
        this._captainSelectionRate = profile.captainSelectionRate;
        this._viceCaptainSelectionRate = profile.viceCaptainSelectionRate;
    }

    get birthdate(): string | null {
        return this._birthdate;
    }

    get middleName(): string | null {
        return this._middleName;
    }

    get lastName(): string | null {
        return this._lastName;
    }

    get role(): ROLE | null {
        return this._role;
    }

    get creditCent(): number {
        return this._creditCent;
    }

    get pointAvgCent(): number {
        return this._pointAvgCent;
    }

    get viceCaptainSelectionRate(): number {
        return this._viceCaptainSelectionRate;
    }

    get captainSelectionRate(): number {
        return this._captainSelectionRate;
    }

    get playerSelectionRate(): number {
        return this._playerSelectionRate;
    }

    get lineup(): LINEUP {
        return this._lineup;
    }

    get nationality(): string | null {
        return this._nationality;
    }

    get firstName(): string | null {
        return this._firstName;
    }

    get roleAbbr(): string {
        return "";
    }

    get basicInfo(): string {
        return "";
    }

    getDescription(formatMessage: (id: string) => string): string {
        return "";
    }

    getInjuryInfo(formatMessage: (id: string) => string): string | null {
        return "";
    }
}


