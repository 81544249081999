import RankedLeaderboard from "components/leaderboard/RankedLeaderboard";
import TeamPreview from "containers/teamPreview";
import { ContestRepository } from "data/repository/contest/ContestRepository";
import { LeaderboardRepository } from "data/repository/leaderboard/LeaderboardRepository";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ResultContestVO, } from "types/contest/Contest";
import { ResultContestCarousel } from "../../../../contest/components/ResultContestCarousel";

interface ResultContestLeaderboardProps {
    contests: ResultContestVO[];
}

const ResultContestLeaderboard: React.FC<ResultContestLeaderboardProps> = ({
                                                                               contests,
                                                                           }) => {
    const { sport } = useParams();
    const teamPreview = TeamPreview.useTeamPreview();
    const [selectedContest, setSelectedContest] = useState(
        contests[teamPreview.getContestInitIndex(contests)],
    );
    const leaderboardRepository = new LeaderboardRepository();
    const contestRepository = new ContestRepository();

    const contestDetail = useFantasyQuery(
        [QUERY_KEY.CONTEST_DETAIL, selectedContest.code],
        async () => contestRepository.getContestDetail(selectedContest.code),
        {
            options: {
                enabled: !!selectedContest.code,
                cacheTime: 60 * 1000
            }
        }
    );

    const myContestJoinedTeam = useFantasyQuery(
        [QUERY_KEY.MY_CONTEST_JOINED_TEAM, selectedContest.code],
        async () => contestRepository.getMyContestJoinedTeam(selectedContest.code),
        {
            options: {
                enabled: !!selectedContest.code,
                cacheTime: 60 * 1000
            }
        }
    );

    const leaderboard = useFantasyQuery(
        [QUERY_KEY.GET_LEADERBOARD, contestDetail.data?.id],
        async () => leaderboardRepository.getLeaderboard(contestDetail.data?.id!),
        {
            options: {
                enabled: !!contestDetail.data?.id,
                cacheTime: 60 * 1000
            }
        }
    );

    const handleSwiperChange = (swiper) => {
        const currentContest = contests[swiper.realIndex];
        setSelectedContest(currentContest);
    };

    useEffect(() => {
        teamPreview.updateContestCode(selectedContest.code);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedContest.code]);

    useEffect(() => {
        return () => {
            teamPreview.close();
        };
    }, []);


    return (
        <>
            <ResultContestCarousel
                contests={contests}
                currentContest={contestDetail.data}
                onSwiperChange={handleSwiperChange}
            />
            <RankedLeaderboard
                dataSource={leaderboard.data}
                myJoinedTeams={myContestJoinedTeam.data}
                sport={sport}
                entryFeeType={contestDetail.data?.entryFeeType}
                matchStatus={contestDetail.data?.matchStatus}
            />
        </>
    );
};

export default ResultContestLeaderboard;
