import useMyProfile from "hooks/useMyProfile";
import { storageHelper } from "helpers/localStorage/LocalStorageHelper";
import { LOCAL_STORAGE_KEYS } from "helpers/localStorage/types";
import React, { useState } from "react";
import Banner, { BANNER_VARIANTS } from "designToken/banner/Banner";
import { Icon } from "helpers/IconHelper";
import { FormattedMessage } from "react-intl";

export const CompletedBanner = () => {
    const { myProfile: { id: userId } } = useMyProfile();
    const kycCompletedLocalStorage = storageHelper(`${userId}_${LOCAL_STORAGE_KEYS.KYC_COMPLETED_HINT_NOT_SHOW_AGAIN}`);
    const [showCompletedHint, setShowCompletedHint] = useState<boolean>(!kycCompletedLocalStorage.get());

    if (showCompletedHint) {
        return <Banner
            variant={BANNER_VARIANTS.success}
            prefixIcon={Icon.Checked}
            onClick={() => {
                setShowCompletedHint(false);
                kycCompletedLocalStorage.put(true);
            }}
            suffixIcon={Icon.Close}
        >
            <FormattedMessage id="kyc_completed_hint" />
        </Banner>;
    }

    return null;
};
