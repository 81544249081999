import MdCloseButton from "components/buttons/MdCloseButton";
import { useKycLeavePopup } from "domain/kyc/components/kycLeavePopup/KycLeavePopupProvider";
import { KYC_OTP_STEP, KycOtpProvider, useKycOtp } from "domain/kyc/pages/kycOtp/KycOtpProvider";
import { MobileNumber } from "domain/kyc/pages/kycOtp/MobileNumber";
import { OtpCode } from "domain/kyc/pages/kycOtp/OtpCode";
import React, { useMemo } from "react";

const KYC_COMPONENT_MAP: Record<KYC_OTP_STEP, React.FC> = {
    [KYC_OTP_STEP.MOBILE_NUMBER]: MobileNumber,
    [KYC_OTP_STEP.OTP_CODE]: OtpCode,
};


const KycOtp = () => {
    const { currentStep, responsibleGamingRegistration } = useKycOtp();
    const kycLeavePopup = useKycLeavePopup();

    const Component = useMemo(() => KYC_COMPONENT_MAP[currentStep], [currentStep]);

    if (!responsibleGamingRegistration) {
        return null;
    }

    return <div className="tw-flex tw-flex-col tw-px-[2rem] tw-pt-[1.2rem] tw-pb-[4rem] tw-gap-y-[2.8rem]">
        <div className="tw-flex tw-flex-row-reverse">
            <MdCloseButton onClose={kycLeavePopup.open} />
        </div>
        <Component />
    </div>;
};

export default () => (
    <KycOtpProvider>
        <KycOtp />
    </KycOtpProvider>
);
