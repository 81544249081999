import { FANTASY_DIALOG_VARIANT } from "components/dialog/FantasyDialog";
import { makeContestSpotsFilledProps } from "domain/contest/components/SpotsFilledDialog";
import useUpdateWallet from "domain/wallet/useUpdateWallet";
import { isInsufficientDepositError } from "helpers/ContestHelper";
import { createErrorObject, isContestFull, isForbiddenByKYCRestriction, isMatchStarted } from "helpers/ErrorHelper";
import { useCompleteYourKycDialog, useStopJoiningDialog } from "hooks/useDialog";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { addErrorPopup, addPopup } from "store/actions/AlertActions";

const useHandleJoinContestError = () => {
    const dispatch = useDispatch();
    const updateWallet = useUpdateWallet();
    const stopJoiningDialog = useStopJoiningDialog();
    const intl = useIntl();
    const completeYourKycDialog = useCompleteYourKycDialog();


    return useCallback((_error, onConfirmContestFull = () => {
        window.location.reload();
    }) => {
        if (isInsufficientDepositError(_error)) {
            updateWallet();
            return;
        }
        if (isMatchStarted(_error)) {
            stopJoiningDialog();
            return;
        }
        if (isContestFull(_error)) {
            const contestSpotsFilledProps = makeContestSpotsFilledProps({ onClose: onConfirmContestFull, });
            dispatch(
                addPopup({
                    ...contestSpotsFilledProps,
                    variant: FANTASY_DIALOG_VARIANT.FANTASY_BASIC_POPUP,
                    title: contestSpotsFilledProps.title,
                    content: contestSpotsFilledProps.content,
                    onExited: contestSpotsFilledProps.onHide,
                })
            );
            return;
        }
        if (isForbiddenByKYCRestriction(_error)) {
            completeYourKycDialog();
            return;
        }

        dispatch(addErrorPopup(createErrorObject(_error)));
    }, [intl, dispatch, stopJoiningDialog, updateWallet]);
};
export default useHandleJoinContestError;
