import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import FantasyBasicPopup, { POPUP_SIZE } from "components/popup/FantasyBasicPopup";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { BUTTON_PATTERN, BUTTON_SIZE, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { cn } from "helpers/cn";

interface SupplierLinkPopupProps {
    show: boolean,
    handleClose: () => void,
    supplierInfo: {
        name: string,
        link: string,
        logo: string
    },
    offset?: boolean
}

export const SupplierLinkPopup: React.FC<SupplierLinkPopupProps> = ({
    show,
    handleClose,
    supplierInfo,
    offset = false
}) => {
    const intl = useIntl();
    const [isCopied, setIsCopied] = useState(false);

    const { logo, name, link } = supplierInfo;

    const onCopy = () => {
        setIsCopied(true);
    };

    useEffect(() => {
        if (!show) setIsCopied(false);
    }, [show]);

    return <FantasyBasicPopup
        show={show}
        offset={offset}
        popupSize={POPUP_SIZE.SM}
        onHide={handleClose}
        imageSection={<img src={logo} alt="sportsdataio-logo" className="tw-h-[2.8rem]" />}
        content={<div className="tw-px-3 tw-flex tw-flex-col tw-gap-3">
            <p className="tw-text-body-1 tw-text-grey-1000 tw-whitespace-pre-line">
                {intl.formatMessage({ id: "supplier_link_popup_label_1" }, { supplierName: name })}
                <CopyToClipboard
                    text={link}
                    onClick={e => e.preventDefault()}
                    onCopy={onCopy}
                >
                    <span className="tw-cursor-pointer tw-text-info-700">{link}</span>
                </CopyToClipboard>
                {intl.formatMessage({ id: "supplier_link_popup_label_2" })}
            </p>
            <p className={cn("tw-text-success-700 tw-text-body-1 tw-font-medium tw-invisible", {
                "tw-visible": isCopied
            })}>
                {intl.formatMessage({ id: "label_link_copied" })}
            </p>
        </div>}
        buttonSetting={{
            buttonPattern: BUTTON_PATTERN.rounded,
            confirmButton: false,
            cancelButtonVariant: NEW_BUTTON_VARIANTS.primaryLight,
            cancelButtonText: intl.formatMessage({ id: "button_got_it" }),
            buttonSize: BUTTON_SIZE.md,
            onCancel: handleClose
        }}
    />;
};
