import { RankingImage, RankingImageWrapper, RankingText, RankingWrapper, } from "components/ranking/RankingBodyStyle";
import { getCustomIconSuit } from "containers/amount/CurrencyIconSuitHelper";
import { PrizeListVo } from "data/vo/PrizeList/PrizrListVo";
import { freeContestImage, Icon, winnerTakesAllRuby, winnerTakesAllWinning, } from "helpers/IconHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { CURRENCY_ICON_SUIT_NAME } from "store/reducer/config/FantasyGameConfigState";
import { ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";
import AmountDisplay from "../../containers/amount/AmountDisplay";

export const PracticeContestWinningLayout = () => {
    return (
        <RankingWrapper>
            <RankingImageWrapper>
                <RankingImage src={freeContestImage} />
            </RankingImageWrapper>
            <RankingText>
                <FormattedMessage id="contest_detail_page_ranking_label_free_contest_ranking" />
            </RankingText>
        </RankingWrapper>
    );
};

interface WinnerTakesAllRankingLayoutProps {
    prizeList: PrizeListVo[];
    entryFeeType: ENTRY_FEE_TYPE_FROM_API;
}

export const WinnerTakesAllRankingLayout: React.FC<WinnerTakesAllRankingLayoutProps> = ({
                                                                                            prizeList,
                                                                                            entryFeeType
                                                                                        }) => {
    const isRuby = useMemo(
        () => entryFeeType === ENTRY_FEE_TYPE_FROM_API.RUBY,
        [entryFeeType],
    );
    const {
        currentCurrencyObject: { toWalletNumber },
    } = useCurrency();

    const suitMap = useMemo(() => getCustomIconSuit(entryFeeType, {
        [CURRENCY_ICON_SUIT_NAME.DEFAULT]: {
            [ENTRY_FEE_TYPE_FROM_API.DEPOSIT]: <Icon.Winning size="1.8rem" data-testid="coin-icon"
                                                             className="tw-mx-1" />,
            [ENTRY_FEE_TYPE_FROM_API.RUBY]: <Icon.CurrencyRuby size="1.8rem" data-testid="ruby-icon"
                                                               className="tw-mx-1" />,
        },
        [CURRENCY_ICON_SUIT_NAME.PESO]: {
            [ENTRY_FEE_TYPE_FROM_API.DEPOSIT]: <Icon.Peso
                size="1.3rem"
                data-testid="coin-icon"
                className="tw-text-warning-900 tw-mx-1"
            />,
            [ENTRY_FEE_TYPE_FROM_API.RUBY]: <Icon.DailyRuby
                size="1.3rem"
                data-testid="ruby-icon"
                className="tw-text-error-600 tw-mx-1"
            />
        },
    }), [entryFeeType]);

    return (
        <RankingWrapper data-testid="winner-takes-all">
            <RankingImageWrapper>
                <RankingImage
                    src={isRuby ? winnerTakesAllRuby : winnerTakesAllWinning}
                />
            </RankingImageWrapper>
            <RankingText>
                <FormattedMessage id="contest_detail_page_ranking_label_winner_takes_all" />
                <AmountDisplay
                    currencyIconSuit={suitMap}
                    className="fz-sf-bold-body-2 tw-gap-0 tw-leading-5"
                >
                    {toWalletNumber(prizeList[0]?.prize)}
                </AmountDisplay>
            </RankingText>
        </RankingWrapper>
    );
};
