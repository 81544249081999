import { Callback } from "Common";
import {
    ConfirmationProps
} from "domain/contest/components/ConfirmationDialog/ConfirmationModal/CoinConfirmationModal";
import { UseJoinContestCalc } from "domain/contest/hooks/useJoinContestCalc";
import { storageHelper } from "helpers/localStorage/LocalStorageHelper";
import { LOCAL_STORAGE_KEYS } from "helpers/localStorage/types";
import useFantasyHistory from "hooks/useFantasyHistory";
import useUserIdentity from "hooks/useUserIdentity";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { useSelector } from "store";
import { ContestDetailVO } from "types/contest/ContestDetail";
import { EnrichedCoupon } from "types/coupon/Coupon";
import { SelectedTeam } from "types/team/SelectedTeam";
import { JoinContestOnSubmitType } from "../DepositConfirmationDialog";
import { DISCOUNT_TYPE } from "../types";

interface UseConfirmationModalProps {
    setDiscountType: (DISCOUNT_TYPE) => void,
    contest: Pick<ContestDetailVO, 'code' | 'entryFeeCent' | 'type'>
    selectedTeams: SelectedTeam[],
    selectedCoupons: EnrichedCoupon[],
    isUseRuby: boolean,
    isUseCoupon: boolean,
    canUseRuby: boolean,
    joinContestCalc: UseJoinContestCalc,
    onSubmit: JoinContestOnSubmitType,
    onHide: () => void,
    myUnusedCoupon: EnrichedCoupon[],
    onOpenSelectCouponModal: () => void
    onAddDeposit?: Callback
}

interface useConfirmationModalResult {
    props: ConfirmationProps;
}

export const useCoinConfirmationModal = ({
                                             setDiscountType,
                                             contest,
                                             selectedTeams,
                                             selectedCoupons,
                                             isUseRuby,
                                             isUseCoupon,
                                             canUseRuby,
                                             joinContestCalc,
                                             onSubmit,
                                             onHide,
                                             myUnusedCoupon,
                                             onOpenSelectCouponModal,
                                             onAddDeposit,
                                         }: UseConfirmationModalProps): useConfirmationModalResult => {
    const {
        FantasyGameConfigState: { addDepositUrl },
        WalletState: { winningCent }
    } = useSelector(state => state);
    const history = useFantasyHistory();
    const location = history.location;

    const { isB2B } = useUserIdentity();

    const rubyStorage = storageHelper(LOCAL_STORAGE_KEYS.USE_RUBY);

    const handleToggleRuby = () => {
        setDiscountType((discountType) => discountType === DISCOUNT_TYPE.RUBY ? DISCOUNT_TYPE.COUPON : DISCOUNT_TYPE.RUBY);
    };

    const onAddDepositButtonClick = () => {
        // TODO, B2B儲值(Add deposit）按鈕流程待確認
        if (isB2B) {
            window.open(addDepositUrl, "_self");
            return;
        }

        if (onAddDeposit) {
            onAddDeposit();
            return;
        }

        history.replace(history.location.pathname, {
            navbarBackGoTo: location?.state?.navbarBackGoTo,
            contestCode: contest.code,
            selectedTeamsIds: selectedTeams.map(team => team.id),
        });

        history.push(ROUTER_PATH_CONFIG.deposit.select, {
            requiredDepositCent: Math.abs(joinContestCalc.balanceDepositCent),
        });

    };


    const onConfirmationButtonClick = () => {
        const selectedTeamIds = selectedTeams.map(team => team.id);
        const couponIds = selectedCoupons.map((coupon) => coupon.id);

        onSubmit(selectedTeamIds, isUseRuby ? [] : couponIds, isUseRuby, winningCent, joinContestCalc.balanceWinningCent);

        if (canUseRuby) {
            rubyStorage.put(isUseRuby);
        }
    };


    return {
        props: {
            onToggleRuby: handleToggleRuby,
            onAddDepositButtonClick,
            onConfirmationButtonClick,
            onClose: onHide,
            myUnusedCoupon,
            canUseRuby,
            selectedCoupons,
            contest,
            selectedTeamCount: selectedTeams.length,
            useCoupon: isUseCoupon,
            useRuby: isUseRuby,
            onOpenSelectCouponModal,
            onSetDiscountType: setDiscountType,
            ...joinContestCalc
        }
    };
};
