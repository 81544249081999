import React, { ReactNode } from "react";

interface MyMatchItemWrapperProps {
    children: React.ReactNode,
    titleTestId?: string,
    title: string | ReactNode,
}

const MyMatchItemWrapper: React.FC<MyMatchItemWrapperProps> = ({
                                                                   children,
                                                                   titleTestId,
                                                                   title
                                                               }) => {
    return (
        <div className="tw-py-6 tw-px-4 even:tw-bg-grey-100 tw-flex tw-flex-col tw-gap-2" key={titleTestId}>
            <div
                className="tw-text-subtitle-1 tw-text-center tw-font-bold tw-text-grey-1000 tw-leading-1 -tw-mb-2"
                data-testid={titleTestId}
            >
                {title}
            </div>
            {children}
        </div>
    );
};

export default MyMatchItemWrapper;