import React from "react";
import { Icon } from "helpers/IconHelper";

interface LineUpStatusProps {
    lineup: string;
}

export const LineUpStatus: React.FC<LineUpStatusProps> = ({ lineup }) => {

    return lineup === "STARTING_LINEUP"
        ? <Icon.CheckCircle className="tw-text-green-500" />
        : <Icon.Cancel className="tw-text-red-500" />;
};
