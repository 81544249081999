import { generateMultipleEntriesInfo, makeSpotsRangeText } from "../SelectTeamPanelHelper";
import { Table } from "react-bootstrap";
import { MultipleEntriesInfoTableCell, MultipleEntriesInfoTableHead } from "../SelectTeamPanelStyle";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
import React from "react";

export const MaxEntriesInfoTable = ({ spotsToMaxEntries, defaultMaxEntries }) => {
    const multipleEntriesInfo = generateMultipleEntriesInfo({ spotsToMaxEntries, defaultMaxEntries });

    return <Table bordered>
        <thead>
        <tr>
            <MultipleEntriesInfoTableHead>
                <FormattedMessage id="fantasy_sport_label_spots" />
            </MultipleEntriesInfoTableHead>
            <MultipleEntriesInfoTableHead>
                <FormattedMessage id="max_entries_dialog_label_max_entries" />
            </MultipleEntriesInfoTableHead>
        </tr>
        </thead>
        <tbody>
        {
            multipleEntriesInfo.map(({ begin, end, entries }) =>
                <tr key={_.uniqueId("max_entries_")}>
                    <MultipleEntriesInfoTableCell>{makeSpotsRangeText(begin, end)}</MultipleEntriesInfoTableCell>
                    <MultipleEntriesInfoTableCell>{entries}</MultipleEntriesInfoTableCell>
                </tr>
            )
        }
        </tbody>
    </Table>;
};
