import { ajax } from "apis/ApiUtils";

export const sendOtp = async (countryCode: string, mobileNumber: string) => {
    await ajax({
        url: `/kyc/otp/send`,
        method: "POST",
        data: {
            countryCode,
            phoneNumber: mobileNumber
        }
    });
};

export const verifyOtp = async (code: string) => {
    await ajax({
        url: `/kyc/otp/verify`,
        method: "POST",
        data: { code }
    });
};
