import { useCallback, useMemo } from "react";
import { storageHelper } from "helpers/localStorage/LocalStorageHelper";
import { LOCAL_STORAGE_KEYS } from "helpers/localStorage/types";

interface UseLatestJoinedRefereeResponse {
    hasNewRefereeJoined: boolean,
    saveLatestReferralTime: () => void
}

const useLatestJoinedReferee = (latestReferralTime: number = 0): UseLatestJoinedRefereeResponse => {
    const lastRefereeJoinTimeStorage = useMemo(() => {
        return storageHelper(LOCAL_STORAGE_KEYS.LAST_REFEREE_JOIN_TIME);
    }, []);


    const saveLatestReferralTime = useCallback(() => {
        if (latestReferralTime === 0) return;
        lastRefereeJoinTimeStorage.put(latestReferralTime);
    }, [lastRefereeJoinTimeStorage, latestReferralTime]);

    const hasNewRefereeJoined = useMemo(() => {
        return latestReferralTime > lastRefereeJoinTimeStorage.get();
    }, [lastRefereeJoinTimeStorage, latestReferralTime]);


    return {
        saveLatestReferralTime,
        hasNewRefereeJoined
    };

};

export default useLatestJoinedReferee;
