export interface TutorialStepProps {
    handleNextStep: () => void;
}

export enum HIGHLIGHT_PATTERN {
    circle, pill
}

export const HighlightPatternClassMap = {
    [HIGHLIGHT_PATTERN.circle]: "rounded-circle",
    [HIGHLIGHT_PATTERN.pill]: "rounded-pill"
};

export interface HighlightProps {
    width: number;
    height: number;
    pattern: HIGHLIGHT_PATTERN;
}

export interface PositionProps {
    top: number;
    left: number;
    zIndex?: number;
}