import React from "react";
import styled from "styled-components";

export const UpgradeWrapper = styled.div.attrs({
    className: "text-secondary-2 d-flex justify-content-between clickable tw-bg-gradient-to-r tw-from-secondary-200 tw-to-secondary-50"
})`
    height: 7.5rem;
    border-radius: 1.6rem 1.6rem 0 0;
    padding: 1.2rem 1.6rem;
    box-shadow: 0 -1rem 1rem 0 #B0B0B033;
    margin-bottom: -3.7rem;
`;

interface IconProps {
    $src: string
    $isGrayscale?: boolean
}

export const Icon: React.FC<IconProps> = styled.div`
    width: 1.6rem;
    height: 1.6rem;
    background-image: ${({ $src }) => `url(${$src})`};
    background-size: cover;
    background-position: center;
    ${({ $isGrayscale }) => ($isGrayscale ? "filter: grayscale(1);" : "")}
`;
