import { cn } from "helpers/cn";
import { IconOmitProps } from "helpers/IconHelper";
import React from "react";
import { IconBaseProps } from "react-icons";

export enum BANNER_VARIANTS {
    primary = "primary",
    warning = "warning",
    success = "success",
}

interface BannerProps {
    variant: BANNER_VARIANTS;
    onClick?: () => void;
    prefixIcon?: React.FC<IconOmitProps> | React.FC<IconBaseProps>;
    suffixIcon?: React.FC<IconOmitProps> | React.FC<IconBaseProps>;
    children?: React.ReactNode;
}

const variantColorMap = {
    [BANNER_VARIANTS.primary]: {
        bgColor: "tw-bg-primary-50",
        contentColor: "tw-text-primary-800",
    },
    [BANNER_VARIANTS.warning]: {
        bgColor: "tw-bg-warning-50",
        contentColor: "tw-text-warning-900",
    },
    [BANNER_VARIANTS.success]: {
        bgColor: "tw-bg-success-50 tw-bg-opacity-30",
        contentColor: "tw-text-success-800",
    },
};
const Banner: React.FC<BannerProps> = ({
                                           variant,
                                           onClick,
                                           prefixIcon: PrefixIcon,
                                           suffixIcon: SuffixIcon,
                                           children,
                                       }) => {

    const { bgColor, contentColor } = variantColorMap[variant];
    return (
        <div
            className={cn("tw-flex tw-mb-4 tw-rounded-lg tw-py-2 tw-px-3", { "tw-cursor-pointer": !!onClick }, bgColor, contentColor)}
            onClick={onClick}
        >
            {PrefixIcon && <PrefixIcon size="2rem" />}
            <div className="tw-ml-2 tw-flex-auto tw-text-body-1">
                <span className="text">{children}</span>
            </div>
            {SuffixIcon && <SuffixIcon size="2rem" />}
        </div>
    );
};

export default Banner;
