import { SPORT } from "types/sports/Sport";

export const getStartedMatchDetailPageUrl = (sport: SPORT, matchId: string): string => `/${sport.toLowerCase()}/started/match/${matchId}/detail`;
export const getMatchDetailPageUrl = (sport: SPORT, matchId: string): string => `/${sport.toLowerCase()}/match/${matchId}/detail`;
export const getResultDetailPageUrl = (sport: SPORT, matchId: string): string => `/${sport.toLowerCase()}/result/${matchId}`;
export const getContestDetailPageUrl = (contestCode: String): string => `/contest/${contestCode}/detail`;
export const getSlateContestDetailPageUrl = (sport: SPORT, nineCatId: number, contestCode: String): string => `/slate/${sport.toLowerCase()}/${nineCatId}/${contestCode}`;

export const getUserInfoPageUrl = (userId: number): string => `/user-profile/${userId}`;

