import { divideByOneHundred } from "helpers/number/NumberHelper";
import { chain } from "lodash";
import { SLATE_STATUS_FROM_API } from "../../../domain/slate/data/dto";
import {
    CONTEST_STATUS_FROM_API,
    CONTEST_TYPE_FROM_API,
    ENTRY_FEE_TYPE_FROM_API
} from "../../../types/contest/Contest";
import { SLATE_TYPE } from "../../../types/Slate/Slate";
import { SPORT } from "../../../types/sports/Sport";
import { NineCatContestDetailDto } from "../../dto/nineCat/NineCatContestDetailDto";
import { PrizeListVo } from "../PrizeList/PrizrListVo";

export class NineCatContestDetailVo {
    public allowDiscountWithRuby: boolean;
    public code: string;
    public entryFee: number;
    public entryFeeType: ENTRY_FEE_TYPE_FROM_API;
    public entryLimit: number;
    public firstPrize: number;
    public id: number;
    public joinedTeams: number;
    public minimumSlip: number;
    public myJoinedTeams: number[];
    public name: string;
    public prizeList: PrizeListVo[];
    public slateStatus: SLATE_STATUS_FROM_API;
    public slateType: SLATE_TYPE;
    public sport: SPORT;
    public spots: number;
    public status: CONTEST_STATUS_FROM_API;
    public type: CONTEST_TYPE_FROM_API;

    constructor(dto: NineCatContestDetailDto) {
        this.allowDiscountWithRuby = dto.allowDiscountWithRuby;
        this.code = dto.code;
        this.entryFee = divideByOneHundred(dto.entryFeeCent);
        this.entryFeeType = dto.entryFeeType;
        this.entryLimit = dto.entryLimit;
        this.firstPrize = divideByOneHundred(dto.firstPrizeCent);
        this.id = dto.id;
        this.joinedTeams = dto.joinedTeams;
        this.minimumSlip = dto.minimumSlip;
        this.myJoinedTeams = dto.myJoinedTeams;
        this.name = dto.name;
        this.prizeList = chain(dto.prizeList).map((prize) => new PrizeListVo(prize)).value();
        this.slateStatus = dto.slateStatus;
        this.slateType = dto.slateType;
        this.sport = dto.sport;
        this.spots = dto.spots;
        this.status = dto.status;
        this.type = dto.type;
    }
}
