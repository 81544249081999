import { cn } from "helpers/cn";
import { howToPlayStep1 } from "helpers/IconHelper";
import React from "react";
import { FormattedMessage } from "react-intl";
import StepWrapper from "domain/howToPlay/StepWrapper";

export const Step1 = () => {
    return <div className="tw-relative tw-w-full tw-flex">
        <StepBackground />
        <StepWrapper
            number={1}
            title={<FormattedMessage id="how_to_play_step_1_title" />}
            subtitle={<FormattedMessage id="how_to_play_step_1_subtitle" />}
        >
            <img src={howToPlayStep1} alt="howToPlayStep1" className="tw-w-full" />
        </StepWrapper>
    </div>;
};

const StepBackground = () => {
    return <div
        className={cn(
            "tw-absolute tw-top-[4.4rem] tw-right-[-21rem]",
            "tw-w-[42rem] tw-h-[42rem] tw-rounded-full",
            "tw-bg-[radial-gradient(rgba(148,73,156,0.1)_0%,transparent_80%)]"
        )}
    />;
};
