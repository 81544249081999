import { CONTEST_TYPE_FROM_API, ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";
import { JoinedTeam } from "./types";

export enum SLATE_STATUS_FROM_API {
    PROCESSING = "PROCESSING",
    UPCOMING = "UPCOMING",
    LIVE = "LIVE",
    COMPLETED = "COMPLETED",
}

export interface UpcomingNineCatDto {
    nineCatId: number;
    name: string;
    tournament: string;
    joined: number;
    squads: { logoUrl: string }[];
}

export interface NineCatMatchDto {
    squadACode: string;
    squadALogoUrl: string;
    squadBCode: string;
    squadBLogoUrl: string;
    matchStartsAt: number;
}

export interface MyNineCatDetailDto {
    nineCatId: number;
    title: string;
    subTitle: string;
    startsAt: number;
    slateMatches: NineCatMatchDto[];
    slateStatus: SLATE_STATUS_FROM_API;
}

export interface MyNineCatTeamDto {
    id: number;
    name: string;
    points: string;
    fieldGoalsEfficiency: string;
    freeThrowsEfficiency: string;
    offensiveRebounds: string;
    defensiveRebounds: string;
    assists: string;
    steals: string;
    blocks: string;
    threePointersMade: string;

}

export interface CreateTeamRequest {
    slateId: string;
    points: string;// PTS
    fieldGoalsEfficiency: string;// FGE
    freeThrowsEfficiency: string;// FTE
    offensiveRebounds: string;// OR
    defensiveRebounds: string;// DR
    assists: string;// AST
    steals: string;// STL
    blocks: string;// BLK
    threePointersMade: string;
}

export interface NineCatContestDto {
    id: number;
    code: string;
    allowDiscountWithRuby: boolean;
    entryFeeCent: number;
    nineCatId: number;
    entryFeeType: ENTRY_FEE_TYPE_FROM_API;
    firstPrizeCent: number;
    minApLevel: string;
    minApLevelWeight: number;
    minimumSlip: number;
    myJoinedTeamsNames: string[];
    name: string;
    needOtp: boolean;
    spots: number;
    myJoinedTeams: JoinedTeam[];
    type: CONTEST_TYPE_FROM_API;
    entryLimit: number;
    slipCount: number;
}
