import { cn } from "helpers/cn";
import { Icon } from "helpers/IconHelper";
import React, { useState } from "react";


interface PaymentMethodProps {
    title: string | React.ReactNode;
    methods: string[];
    paymentMethodDetails: Record<string, { name: string, image: string }>;
    onMethodClick: (method: string) => void;
}

export const PaymentMethods: React.FC<PaymentMethodProps> = ({
                                                                 title,
                                                                 methods,
                                                                 paymentMethodDetails,
                                                                 onMethodClick
                                                             }) => {
    const [openAccordion, setOpenAccordion] = useState(true);

    return <div className={cn("tw-p-4", {
        "tw-bg-system-bgLightSecondary tw-rounded-[1.6rem]": openAccordion,
        "tw-pb-[2.4rem] tw-border-dashed tw-border-b tw-border-grey-200": !openAccordion,
    })}>
        <div className="tw-flex tw-justify-between tw-items-center tw-py-0 tw-px-2">
            <div className="fz-sf-regular-title-1 tw-text-system-primary80">
                {title}
            </div>
            <Icon.ArrowRight size={"2.4rem"}
                             className={cn("tw-cursor-pointer",
                                 openAccordion ? "-tw-rotate-90" : "tw-rotate-90"
                             )}
                             onClick={() => setOpenAccordion(!openAccordion)}
            />
        </div>

        <div className={cn("tw-pt-[2.8rem] tw-py-[1.2rem]", openAccordion ? "tw-block" : "tw-hidden")}>
            {methods?.map(
                (method) =>
                    paymentMethodDetails[method]?.name && (
                        <div className={cn("tw-flex tw-justify-between tw-items-center tw-bg-grey-0",
                            "tw-p-3 tw-cursor-pointer tw-rounded-xl tw-mb-[2rem] last:tw-mb-0")}
                             key={method}
                             onClick={() => {
                                 onMethodClick(method);
                             }}
                        >
                            <div className="tw-flex tw-items-center">
                                <div className="tw-w-[3.2rem] tw-h-[3.2rem]">
                                    <img className="tw-w-full tw-h-full tw-object-contain"
                                         src={paymentMethodDetails[method].image}
                                         alt="payment-method-logo" />
                                </div>
                                <div className="fz-sf-regular-title-2 tw-pl-2">
                                    {paymentMethodDetails[method].name}
                                </div>
                            </div>
                            <Icon.ArrowRight size="2.4rem" className="tw-text-grey-300" />
                        </div>
                    ),
            )}
        </div>
    </div>;
};
