import { QueryKey, useQuery } from "@tanstack/react-query";
import { ApiError } from "apis/Api";
import useHandleApiError from "hooks/useHandleApiError";
import { useEffect } from "react";
import { Setting } from "./type";

const initSetting = {
    onFail: undefined,
    options: undefined
};

const defaultOptions = {
    refetchOnWindowFocus: false
};

const useFantasyQuery = <T>(keys: QueryKey, fetch: () => Promise<T>, setting: Setting<T> | undefined = initSetting) => {
    const { onFail, options } = setting;
    const handleApiError = useHandleApiError();

    const data = useQuery<T>(keys, fetch, { ...defaultOptions, ...options });

    useEffect(() => {
        (async () => {
            if (data?.error) {
                await handleApiError(data.error as ApiError, onFail);
            }
        })();
    }, [data?.error, handleApiError, onFail]);

    return { ...data };
};

export default useFantasyQuery;
