import React from "react";
import { FormattedMessage } from "react-intl";
import { cn } from "../../../../helpers/cn";
import { ContestEntry } from "../../helpers/helpers";

export const ContestEntryFeeText: React.FC<{ contestEntry: ContestEntry, entryFee: number }> = ({
                                                                                                    contestEntry,
                                                                                                    entryFee
                                                                                                }) => {
    return (<div className={cn({ "tw-text-subtitle-1 tw-font-semibold": contestEntry.isPractice() })}>
        {contestEntry.isPractice() ? <FormattedMessage id="contest_card_label_entry_fee_free" /> : entryFee}
    </div>);
};
