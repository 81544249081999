import UserAvatarWithFrame from "components/avatar/UserAvatarWithFrame";
import { LeaderboardTeamVo } from "data/vo/leaderboard/LeaderboardTeamVo";
import { cn } from "helpers/cn";
import { crown, Icon } from "helpers/IconHelper";
import { isMatchLive } from "helpers/match/MatchHelper";
import { toUniversalFormattedNumber } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";
import { MATCH_STATUS_FROM_API } from "../../types/match/Match";
import { TeamPlayerCrownIcon } from "./LeaderboardBodyStyle";

interface LeaderboardItemProps {
    entryFeeType?: ENTRY_FEE_TYPE_FROM_API;
    team: LeaderboardTeamVo;
    isSelf: boolean;
    onPreviewTeam: (team: LeaderboardTeamVo) => void;
    matchStatus: MATCH_STATUS_FROM_API;
}

const LARGE_FONT_MAX_RANK = 9999;

const LeaderboardItem: React.FC<LeaderboardItemProps> = ({
                                                             team,
                                                             isSelf,
                                                             entryFeeType = ENTRY_FEE_TYPE_FROM_API.DEPOSIT,
                                                             onPreviewTeam,
                                                             matchStatus
                                                         }) => {
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();
    const { bgColor, teamBgColor } = useMemo(() => isSelf ? {
        bgColor: 'tw-bg-gradient-to-r tw-from-tertiary-50 tw-to-primary-50',
        teamBgColor: 'tw-bg-primary-600',
    } : {
        bgColor: 'tw-bg-white',
        teamBgColor: 'tw-bg-secondary-500',
    }, [isSelf]);

    const handlePreviewTeam = () => {
        onPreviewTeam(team);
    };

    const renderRank = (rank: number) => {
        if (rank === 1) {
            return <TeamPlayerCrownIcon $bgImg={crown} $size={2} className="!tw-ml-0" />;
        }

        if (rank > LARGE_FONT_MAX_RANK) {
            return <span className="tw-text-overline tw-text-grey-1000">{toUniversalFormattedNumber(rank)}</span>;
        }

        return <span className="tw-text-body-1 tw-text-grey-1000">
            {rank ? toUniversalFormattedNumber(rank) : "-"}
        </span>;
    };

    const renderWonPrize = (wonPrize: number) => {
        if (!wonPrize) return null;
        if (isMatchLive(matchStatus)) return null;

        return (
            <div className="tw-flex tw-items-center tw-gap-1">
                <div className="tw-w-2 tw-h-[1px] tw-bg-grey-250" />
                <span className="tw-text-caption-1 tw-text-warning-1000 tw-font-bold">
                    <FormattedMessage id="contest_leaderboard_view_team_card_label_won" /> {toWalletNumber(wonPrize)}
                </span>
                {
                    entryFeeType === ENTRY_FEE_TYPE_FROM_API.DEPOSIT
                        ? <Icon.PrizeCoin data-testid="deposit-icon" size="1.4rem" />
                        : <Icon.PrizeRuby data-testid="ruby-icon" size="1.4rem" />
                }
                <div className="tw-w-2 tw-h-[1px] tw-bg-grey-250" />
            </div>
        );
    };

    return (
        <div
            className={cn("tw-flex tw-items-center tw-gap-4 tw-px-3 tw-py-2 tw-h-[5.4rem] tw-rounded-lg tw-shadow-slate-card tw-cursor-pointer", bgColor)}
            onClick={handlePreviewTeam}
            data-testid={`${isSelf ? 'my' : 'other'}-leaderboard-item`}
        >
            <div className="tw-flex-1 tw-flex tw-items-center tw-gap-1">
                <div className="tw-flex-shrink-0 tw-min-w-8">
                    {renderRank(team.rank)}
                </div>
                <UserAvatarWithFrame
                    name={team.nickname}
                    size="2.4rem"
                    font="tw-text-subtitle-2 tw-font-bold"
                    hasFrame={false}
                />
                <div>
                    <span className="text-truncate tw-text-body-1">
                        {team.nickname}
                    </span>
                    {renderWonPrize(team.wonPrize)}
                </div>
            </div>
            <div
                className={cn("tw-flex-shrink-0 tw-px-1.5 tw-py-1 tw-rounded tw-text-grey-100 tw-text-overline", teamBgColor)}>
                {team.teamName}
            </div>
            <div className="tw-flex-shrink-0 tw-text-body-1 tw-text-grey-1000 tw-min-w-10 tw-text-right">
                {team.score}
            </div>
        </div>
    );
};

export default LeaderboardItem;
