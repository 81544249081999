import FantasyBasicPopup from "components/popup/FantasyBasicPopup";
import { buyCoinUnsuccessful, Icon } from "helpers/IconHelper";
import React from "react";
import { Title } from "components/popup/component/Title";
import { BUTTON_SIZE, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { cn } from "helpers/cn";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { useAddCoinFlow } from "domain/coin/AddCoinFlow";
import Button from "designToken/button/Button";

const OrderCancelledDialog = () => {
    const { restartAddDepositFlow } = useAddCoinFlow();

    return <FantasyBasicPopup
        dismissible
        show
        onHide={restartAddDepositFlow}
        buttonSetting={{ cancelButton: false, confirmButton: false }}
        imageSection={<OrderCancelledHeader />}
        content={<OrderCancelledContent onRetry={restartAddDepositFlow} />}
        iconSize="2.4rem"
    />;
};


const OrderCancelledHeader = () => {
    return <div
        className="tw-flex tw-flex-col tw-items-center tw-gap-3 tw-px-4 tw-pt-4 tw-pb-4">
        <img src={buyCoinUnsuccessful} alt="buy-coin-unsuccessful" className="tw-w-[5.6rem] tw-h-auto" />
        <Title title={<FormattedMessage id="buy_coin_purchase_unsuccessful_title_label" />} />
        <div className="tw-text-subtitle-1">
            <FormattedMessage id="buy_coin_purchase_unsuccessful_content_label" />
        </div>
    </div>;
};

const OrderCancelledContent = ({ onRetry }: { onRetry: () => void }) => {
    return <div
        className="tw-flex tw-flex-col tw-items-center tw-gap-6">
        <div className={cn(
            "tw-flex tw-items-center tw-gap-3 tw-mb-3",
            "tw-bg-grey-150 tw-w-full tw-text-subtitle-1 tw-text-gray-800",
            "tw-px-4 tw-py-3 tw-rounded-[1.2rem]"
        )}>
            <Icon.Ban className="tw-flex-shrink-0" />
            <div className="tw-text-left tw-text-grey-800">
                <FormattedMessage
                    id="buy_coin_purchase_unsuccessful_tip_label"
                    values={{
                        data: <Link to={ROUTER_PATH_CONFIG.wallet} className="tw-underline">
                            <FormattedMessage id="label_transaction_history" />
                        </Link>
                    }}
                />
            </div>
        </div>

        <Button
            size={BUTTON_SIZE.lg}
            prefixIcon={Icon.FireIcon("2rem")}
            className="tw-w-full tw-rounded-[0.8rem]"
            variant={NEW_BUTTON_VARIANTS.secondaryDark}
            onClick={onRetry}
        >
            <FormattedMessage id="try_again" />
        </Button>
    </div>;
};

export default OrderCancelledDialog;
