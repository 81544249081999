import useFantasyConfig from "hooks/useFantasyConfig";
import React from "react";
import { AutoChoosePlayerWrapper } from "../ChoosePlayerStyle";

const AutoChoosePlayer = ({ onButtonClicked }) => {
    const { devToggle } = useFantasyConfig();

    return devToggle
        ? <AutoChoosePlayerWrapper
            onClick={onButtonClicked}
            data-testid="one-click-choose-player"
        >
            One Click Choose Player
        </AutoChoosePlayerWrapper>
        : null;
};

export default AutoChoosePlayer;
