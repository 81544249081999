import styled from "styled-components";

export const Wrapper = styled.div.attrs<{
    $stickyTop: number
}>({
    className: "bg-body-body-1"
})`
  position: sticky;
  top: ${({ $stickyTop = 6.5 }) => $stickyTop}rem;
  left: 0;
  //padding-top: 1.6rem;
  z-index: 1;
`;
export const RankingText = styled.div.attrs({
    className: "tw-text-body-2"
})`
`;
export const RemindText = styled.div.attrs({
    className: "fz-sf-regular-caption-1 text-light-secondary text-center"
})`
  margin-top: .2rem;
`;
