import { ReactElement } from "react";

export enum COUPON_ACTION {
    REGISTER = "REGISTER",
    REFERRED = "REFERRED",
    PROMOTIONAL = "PROMOTIONAL",
    REDEMPTION_CODE = "REDEMPTION_CODE",
    MANUAL_ADD_COUPON = "MANUAL_ADD_COUPON"
}

export interface Coupon {
    id: number,
    discountPercentage: number,
    minEntryFeeCent: number,
    from: number,
    to: number | null,
    couponAction: COUPON_ACTION
}

export interface EnrichedCoupon {
    id: number;
    discountPercentage: number;
    minEntryFeeCent: number;
    from: number;
    to: number | null;
    couponAction: COUPON_ACTION;
    startDate: string;
    endDate: string;
    icon: ReactElement;
    contestDiscountCent: (contestEntryFeeCent: number) => number
    isFree: boolean;
}

export interface Coupons {
    unused: Coupon[]
    used: Coupon[]
    expired: Coupon[]
}

export interface EnrichedCoupons {
    unused: EnrichedCoupon[]
    used: EnrichedCoupon[]
    expired: EnrichedCoupon[]
}
