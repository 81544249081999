import { SPORT } from "types/sports/Sport";
import React from "react";
import { cn } from "helpers/cn";
import {
    FeatureContestCarouselContainer
} from "officialWebsite/FeatureContest/components/FeatureContestCarouselContainer";

interface FeatureContestContextProps {
    selectedSport: SPORT;
}

export const FeatureContestContext: React.FC<FeatureContestContextProps> = ({ selectedSport }) => {

    return <div>
        <FeaturedGameText />
        <FeatureContestCarouselContainer selectedSport={selectedSport} />
    </div>;
};

const FeaturedGameText = () => <div className="tw-pt-[36px] tw-flex tw-items-center tw-mx-[62px] tw-gap-[34px]">
    <p className="tw-text-[#001042]">
        FEATURED GAMES
    </p>
    <div className={cn(
        "tw-flex-1 tw-h-[1px]",
        "tw-bg-gradient-to-r tw-from-[#A31428] tw-to-[#EAD431]"
    )}></div>
</div>;
