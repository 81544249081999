import { fetchFirstTimePurchase, FirstTimePurchaseDto, } from "apis/deposit/DepositApi";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "store";
import { setFirstTimePurchase } from "store/actions/Actions";
import useUserIdentity from "../useUserIdentity";

function useFirstTimePurchase(): FirstTimePurchaseDto {
    const dispatch = useDispatch();

    const { firstTimePurchase } = useSelector((store) => store.UserState);
    const { isB2C } = useUserIdentity();

    const { data } = useFantasyQuery([QUERY_KEY.MY_FIRST_TIME_PURCHASE], fetchFirstTimePurchase,
        {
            options: {
                enabled: firstTimePurchase?.eligible !== false && isB2C,
            },
        },
    );

    useEffect(() => {
        if (data) {
            dispatch(setFirstTimePurchase(data));
        }
    }, [dispatch, data]);

    return firstTimePurchase;
}

export default useFirstTimePurchase;
