import { Contest } from "apis/match/type";
import { useShowInsufficientApDialog } from "hooks/useDialog";
import { find, findKey, upperFirst } from "lodash";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { AP_LEVEL } from "types/activityPoint/ApLevel";
import { MyApOverview } from "types/activityPoint/MyApOverview";


export const findApLevelIndex = (apLevel: AP_LEVEL) => findKey(Object.keys(AP_LEVEL), (key) => key === apLevel);
export const shouldContestShowApLevelIcon = contest => contest.minApLevel !== AP_LEVEL.BEGINNER;
export const findApLevel = (targetApLevel: AP_LEVEL) => find(AP_LEVEL, apLevel => apLevel === targetApLevel)!;
export const apLevelName = (apLevel: AP_LEVEL) => findApLevel(apLevel).split("_").map((word) => upperFirst(word.toLowerCase())).join(" ");

export const useMinApLevel = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const showInsufficientApDialog = useShowInsufficientApDialog();

    const isApLevelReached = useCallback((myApOverview: MyApOverview | null, contest: Contest) => {
        if (!myApOverview) {
            return true;
        }

        if (contest.minApLevelWeight <= myApOverview.currentLevelWeight) {
            return true;
        }

        showInsufficientApDialog(
            myApOverview.currentLevel,
            AP_LEVEL[contest.minApLevel]
        );

        return false;
    }, [dispatch, intl]);

    return { isApLevelReached };
};

export const getCurrentAp = myApInfo => myApInfo.currentApDeposit + myApInfo.currentApBet;

export const getRequiredDeposit = (currentAp: number, minAp: number) => Math.max(1, minAp - currentAp);
