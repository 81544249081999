import { Image } from "react-bootstrap";
import styled from "styled-components";
import { APP_MAX_WIDTH } from "../../styles/Constants";

export const LayoutWrapper = styled.div.attrs(() => ({
    className: "layout-wrapper d-flex"
}))`
  min-height: 100vh;
`;

export const LayoutMainWrapper = styled.div.attrs({
    className: "d-flex flex-column"
})`
    max-width: ${APP_MAX_WIDTH};
    width: 100%;
`;

export const BackgroundWrapper = styled.div.attrs(() => ({
    className: "h-100 position-fixed"
}))`
    right: 0;
    width: 100%;
    display: none;
    z-index: -1;

    @media (min-width: ${APP_MAX_WIDTH}) {
        display: block;
        width: calc(100% - ${APP_MAX_WIDTH});
    }
`;

export const Background = styled.div.attrs<{ $bg: string }>({
    className: "position-absolute"
})`
    width: 100%;
    height: 100%;
    background-image: url(${({ $bg }) => $bg});
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    z-index: -1;
`;

export const LogoWrapper = styled.div.attrs(() => ({
    className: "mx-auto"
}))`
  display: none;
  padding-top: 150px;
  margin-bottom: 42vh;
  width: 237.44px;
  @media (min-width: 480px) {
    padding-top: 180px;
  }
  @media (min-width: 768px) {
    display: block;
  }
`;
export const Logo = styled(Image).attrs(() => ({
    className: "mw-100 mh-100"
}))`
`;