import { QueryObserverResult } from "@tanstack/react-query";
import { PlayerRepository } from "data/repository/player/PlayerRepository";
import { BasketballPlayerNewsVo } from "data/vo/player/news/BasketballPlayerNewsVo";
import { PlayerInfoVo } from "data/vo/player/PlayerInfoVo";
import { sortPublicContests } from "domain/match/pages/upcoming/contest/public/PublicContest";
import { NineCatContestVo, NineCatPlayerDetailVO } from "domain/slate/data/ComponentTypes";
import { MySlateTeamVo, NineCatDetailVo } from "domain/slate/data/vo";
import { useNineCatDetail } from "domain/slate/hooks/useNineCatDetail";
import { QueryMySlateTeamsResponse, useQueryMyNineCatTeams } from "domain/slate/hooks/useQueryMyNineCatTeams";
import { useQueryPublicContests } from "domain/slate/hooks/useQueryPublicContests";
import SlateRepository from "domain/slate/repository/SlateRepository";
import { CreateNineCatTeamPage } from "domain/slate/views/CreateNineCatTeamPage";
import { JoinNineCatContestPage } from "domain/slate/views/JoinNineCatContestPage";
import useKycRestriction from "hooks/useCheckKycRestriction/useKycRestriction";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { SPORT } from "types/sports/Sport";

interface SlateContextProps {
    currentPage: React.FC;
    handleOpenPlayerPopup: (playerId: NineCatPlayerDetailVO) => void;
    playerState: [boolean, (value: boolean) => void];
    selectedPlayer: NineCatPlayerDetailVO | null;
    playerInfo?: PlayerInfoVo;
    playerNews?: BasketballPlayerNewsVo[];
    isPlayerDetailLoading: boolean;
    toJoinSlateContestPage: () => void;
    toCreateSlateTeamPage: () => void;
    slateDetail: NineCatDetailVo | undefined;
    myTeams: MySlateTeamVo[];
    refetchMyTeams: QueryMySlateTeamsResponse["refetch"];
    publicContests: NineCatContestVo[];
    myContests: NineCatContestVo[];
    myCurrentSlateContests: NineCatContestVo[];
    handleRefetchMyContests: () => Promise<QueryObserverResult<NineCatContestVo[]>>;
    handleRefetchPublicContests: () => Promise<QueryObserverResult<NineCatContestVo[]>>;
    isRestrictedByKyc: () => Promise<boolean>;
}

const SlateContext = createContext<SlateContextProps | null>(null);

const NineCatPages = {
    CreateNineCatTeam: () => CreateNineCatTeamPage,
    JoinNineCatContest: () => JoinNineCatContestPage,
};

interface SlateProviderProps {
    children: React.ReactNode;
}

const slateRepository = new SlateRepository();

function useQueryMyJoinedContests() {
    const queryMyJoinedContests = useFantasyQuery<NineCatContestVo[]>([QUERY_KEY.GET_MY_JOINED_CONTESTS], () => slateRepository.getMyContests(), {
        options: {
            placeholderData: () => []
        }
    });

    return ({
        ...queryMyJoinedContests,
        data: queryMyJoinedContests.data || []
    });
}

export const NineCatProvider: React.FC<SlateProviderProps> = ({ children }) => {
    const { slateId } = useParams();
    const isRestrictedByKyc = useKycRestriction();
    const [openPlayerPopup, setOpenPlayerPopup] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState<NineCatPlayerDetailVO | null>(null);

    const [currentPage, setCurrentPage] = useState(NineCatPages.CreateNineCatTeam);

    const repository = new PlayerRepository();

    const { data: nineCatDetail } = useNineCatDetail(slateId);

    const { data: publicContests, refetch: handleRefetchPublicContests } = useQueryPublicContests();
    const { data: myTeams, refetch: refetchMyTeams } = useQueryMyNineCatTeams(slateId);

    const { data: myContests, refetch: handleRefetchMyContests } = useQueryMyJoinedContests();

    const myCurrentSlateContests = useMemo(() => {
        const filteredBySlateId = myContests.filter(contest => String(contest.nineCatId) === slateId);
        return sortPublicContests(filteredBySlateId);
    }, [myContests]);

    const playerInfo = useFantasyQuery([QUERY_KEY.BASKETBALL_PLAYER_INFO, selectedPlayer?.id], async () => {
        return repository.getPlayerInfo(SPORT.BASKETBALL, selectedPlayer!.matchId, selectedPlayer!.id as string);
    }, {
        options: {
            enabled: !!selectedPlayer?.id
        }
    });

    const playerNews = useFantasyQuery([QUERY_KEY.BASKETBALL_PLAYER_NEWS, selectedPlayer?.id], () => {
        return repository.getBasketballPlayerNews(selectedPlayer!.id as string);
    }, {
        options: {
            enabled: !!selectedPlayer?.id
        }
    });

    const handleOpenPlayerPopup = useCallback((player: NineCatPlayerDetailVO) => {
        setSelectedPlayer(player);
        setOpenPlayerPopup(true);
    }, []);

    const toJoinSlateContestPage = useCallback(async () => {
        if (await isRestrictedByKyc()) {
            return;
        }
        setCurrentPage(NineCatPages.JoinNineCatContest);
    }, [isRestrictedByKyc]);

    const toCreateSlateTeamPage = useCallback(async () => {
        if (await isRestrictedByKyc()) {
            return;
        }
        setCurrentPage(NineCatPages.CreateNineCatTeam);
    }, [isRestrictedByKyc]);

    const value = useMemo<SlateContextProps>(() => ({
        currentPage,
        playerState: [openPlayerPopup, setOpenPlayerPopup],
        selectedPlayer,
        playerInfo: playerInfo.data,
        playerNews: playerNews.data,
        isPlayerDetailLoading: playerInfo.isLoading || playerNews.isLoading,
        handleOpenPlayerPopup,
        toJoinSlateContestPage,
        toCreateSlateTeamPage,
        slateDetail: nineCatDetail,
        myTeams,
        refetchMyTeams,
        publicContests,
        myContests,
        myCurrentSlateContests,
        handleRefetchMyContests,
        handleRefetchPublicContests,
        isRestrictedByKyc: isRestrictedByKyc
    }), [
        currentPage,
        openPlayerPopup,
        setOpenPlayerPopup,
        selectedPlayer,
        playerInfo,
        playerNews,
        handleOpenPlayerPopup,
        toJoinSlateContestPage,
        toCreateSlateTeamPage,
        nineCatDetail,
        myTeams,
        refetchMyTeams,
        publicContests,
        myContests,
        myCurrentSlateContests,
        handleRefetchMyContests,
        handleRefetchPublicContests,
        isRestrictedByKyc
    ]);

    return (
        <SlateContext.Provider value={value}>
            {children}
        </SlateContext.Provider>
    );
};


export const useNineCatContext = () => {
    const context = useContext(SlateContext);
    if (!context) {
        throw new Error("useSlate must be used within a SlateProvider");
    }
    return context;
};
