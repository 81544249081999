import { fetchAuthConfig } from "data/api/auth/AuthApi";
import { AuthConfigVo } from "data/vo/auth/AuthConfigVo";

export class AuthRepository {
    public async getAuthConfig() {
        const response = await fetchAuthConfig();

        return new AuthConfigVo(response);
    };
}
