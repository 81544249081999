import { FirstTimePurchaseDto } from "apis/deposit/DepositApi";
import clsx from "clsx";
import { CoinProductVo } from "data/vo/coin/CoinProductVo";
import { useAddCoinFlow } from "domain/coin/AddCoinFlow";
import { isProductSelected } from "domain/coin/AddCoinHelper";
import {
    AmountOption,
    AmountOptionCol,
    FirstDepositBonusIcon,
    FirstDepositRubyWrapper
} from "domain/coin/component/product/CoinProductsStyle";
import { cn } from "helpers/cn";
import { divideByOneHundred, toUniversalFormattedNumber } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import useFirstTimePurchase from "hooks/useFirstTimePurchase";
import React from "react";

interface ProductsProps {
    data: CoinProductVo[];
}

export const Products: React.FC<ProductsProps> = ({
                                                      data
                                                  }) => {
    return <>{
        data.map((product) => {
            return <Product key={product.id} product={product} />;
        })
    }</>;
};

interface ProductProps {
    product: CoinProductVo,
}

const SaleRibbon = () => {
    return <div
        className={cn(
            "tw-absolute tw-w-full tw-overflow-hidden tw-h-full",
        )}
    >
        <div
            className={cn(
                "tw-absolute -tw-rotate-45 tw-bg-secondary-700 tw-w-[97px]",
                "-tw-translate-x-1/2 -tw-translate-y-1/2 tw-top-4 tw-left-4",
                "tw-text-white tw-text-center tw-text-caption-2 tw-font-bold",
                "tw-bg-gradient-to-r tw-from-[#02295C] tw-from-30% tw-via-[#772B80] tw-via-69.33% tw-to-[#EBAFF1] tw-to-102.24%",
            )}
        >
            Sale
        </div>
    </div>;
};

const Product: React.FC<ProductProps> = ({
                                             product,
                                         }) => {
    const { currentProduct, setCurrentProduct } = useAddCoinFlow();
    const firstTimePurchase = useFirstTimePurchase();
    const { currentCurrencyObject: { toWalletNumber, toFormattedNumber } } = useCurrency();
    const { ruby: rubyLayout } =
        getFirstTimePurchaseHintLayout(firstTimePurchase, product, toFormattedNumber);

    return (
        <AmountOptionCol onClick={() => setCurrentProduct(product)}>
            {product.isOnSale && <SaleRibbon />}

            <AmountOption
                className={clsx({
                    "tw-bg-secondary-200 tw-border-secondary-500": isProductSelected(currentProduct, product),
                    "tw-bg-system-primary4 tw-border-system-primary8": !isProductSelected(currentProduct, product),
                })}
                data-testid={`coin-product-${product.id}`}
            >
                <div className="tw-flex tw-items-center">
                    <div
                        className="tw-w-[5.6rem] tw-h-[5.6rem] tw-bg-cover tw-bg-no-repeat tw-bg-center"
                        style={{
                            backgroundImage: `url(${product.image})`
                        }} />
                    <div
                        className="tw-flex tw-justify-start tw-items-baseline tw-flex-wrap tw-pl-4">
                        <div
                            className={cn("fz-sf-bold-title-2 tw-pr-[0.2rem]", {
                                "tw-text-secondary-500": isProductSelected(currentProduct, product),
                                "tw-text-gray-0": !isProductSelected(currentProduct, product),
                            })}
                        >
                            {toWalletNumber(product.coin)}
                        </div>
                        {rubyLayout}
                    </div>
                </div>
                <div className={cn("text-end",
                    isProductSelected(currentProduct, product)
                        ? "fz-sf-bold-body-1 text-secondary-1"
                        : "fz-sf-regular-body-1 text-light-secondary"
                )}>
                    ({product.currencyName} {toWalletNumber(product.currency)})
                </div>
            </AmountOption>
        </AmountOptionCol>
    );
};

const getFirstTimePurchaseHintLayout = (firstTimePurchase: FirstTimePurchaseDto,
                                        product: CoinProductVo,
                                        formatNumber: typeof toUniversalFormattedNumber) => {
    if (firstTimePurchase.eligible && product.coinCent >= firstTimePurchase.minCoinCent) {
        return {
            ruby: (
                <FirstDepositRubyWrapper data-testid="ruby">
                    {formatNumber(product.coin * divideByOneHundred(firstTimePurchase.rubyPercentage),)}
                    <FirstDepositBonusIcon />
                </FirstDepositRubyWrapper>
            ),
        };
    }

    return {
        ruby: null,
    };
};
