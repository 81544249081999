import { Image } from "react-bootstrap";
import styled from "styled-components";
import { PrimarySolidButton } from "../../../../styles/component/ButtonsStyle";

export const NoticeWrapper = styled.div.attrs(() => ({
    className: "position-fixed w-100 h-100 d-flex justify-content-center align-items-center"
}))`
    top: 0;
    left: 0;
    z-index: 99;
    max-width: 55rem;
`;
export const NoticeModalOverlay = styled.div.attrs(() => ({
    className: "position-absolute w-100 h-100"
}))`
    background-color: rgba(0, 0, 0, .8);

`;
export const NoticeModal = styled.div.attrs(() => ({
    className: "position-absolute tw-bg-system-bgLightPrimary"
}))`
    z-index: 3;
    width: calc((1 - 35 * 2 / 375) * 100%);
    border-radius: 1.6rem;
    padding: 3.2rem 2.4rem;
`;
export const NoticeModalBody = styled.div.attrs(() => ({
    className: "d-flex flex-column align-items-center"
}))``;

export const Title = styled.div.attrs({
    className: "fz-sf-bold-title-3"
})``;
export const DetailWrapper = styled.div.attrs({})`
    padding: 1.2rem 0;
`;
export const DetailOrderList = styled.ol.attrs({})`
    padding-left: 2rem;
`;
export const DetailRow = styled.li.attrs({
    className: "fz-sf-regular-body-2"
})`
`;
export const Methods = styled.span.attrs({
    className: "fz-sf-bold-body-2"
})``;
export const WarningWrapper = styled.div.attrs({
    className: "d-flex align-items-start"
})`
    margin-bottom: 2.4rem;
`;
export const WarningIcon = styled(Image).attrs({})`
    width: 1.6rem;
    height: 1.6rem;
`;
export const WarningText = styled.div.attrs({
    className: "fz-sf-regular-body-2 tw-text-system-red"
})`
`;
export const ButtonWrapper = styled.div.attrs({
    className: "d-flex flex-column align-items-center"
})`
    display: flex;
    flex-direction: column;
`;
export const ConfirmButton = styled(PrimarySolidButton).attrs({
    className: "fz-sf-regular-title-3"
})`
    margin-bottom: 2.4rem;
`;
export const CancelButton = styled.div.attrs({
    className: "fz-sf-bold-headline tw-text-system-primary80"
})`
    cursor: pointer;
`;
