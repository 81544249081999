import { CirclePlaceHolder, TextPlaceHolder } from "../SkeletonStyle";
import React from "react";
import { Placeholder } from "react-bootstrap";
import { TeamPreviewUserProfileWrapper } from "../../../containers/teamPreview/components/TeamPreviewStyle";

const TeamPreviewUserProfileSkeleton = () => {

    return <Placeholder animation="glow">
        <TeamPreviewUserProfileWrapper>
            <div className="d-flex gap-2">
                <div className="p-1">
                    <CirclePlaceHolder $size={3.4} />
                </div>
                <div>
                    <div>
                        <TextPlaceHolder $width={8} />
                    </div>
                    <div>
                        <TextPlaceHolder $width={12} />
                    </div>
                </div>
            </div>
        </TeamPreviewUserProfileWrapper>
    </Placeholder>;
};

export default TeamPreviewUserProfileSkeleton;
