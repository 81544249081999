import React from "react";
import { FormattedMessage } from "react-intl";
import CountLabel from "../../../../components/relation/CountLabel";
import { ROUTER_PATH_CONFIG } from "../../../../router/RouterPathConfig";
import { useSelector } from "../../../../store";
import { useMe } from "../../me/MeProvider";
import { StyledDivider, StyledRelationBar, StyledRelationBarItemWrapper, } from "./RelationBarStyle";

const RelationBar: React.FC = () => {
    const { referee, follow, hasLastRefereeJoin } = useMe();
    const {featureConfig} = useSelector(state => state.FantasyGameConfigState);

    return (
        <StyledRelationBar>
            <StyledRelationBarItemWrapper className="tw-pointer-events-none" to={""}>
                <CountLabel
                    label={<FormattedMessage id="label_followers" />}
                    count={follow?.followersCount || 0}
                />

            </StyledRelationBarItemWrapper>
            <StyledRelationBarItemWrapper to={ROUTER_PATH_CONFIG.userProfile.list}>
                <StyledDivider />
                <CountLabel
                    label={<FormattedMessage id="label_following" />}
                    count={follow?.followingCount || 0}
                />
            </StyledRelationBarItemWrapper>
            {
                featureConfig.referral && (
                    <StyledRelationBarItemWrapper to={ROUTER_PATH_CONFIG.me.referral.index}>
                        <StyledDivider />
                        <CountLabel
                            label={<FormattedMessage id="label_referrals" />}
                            count={referee?.refereeCount || 0}
                            isUnread={hasLastRefereeJoin}
                        />
                    </StyledRelationBarItemWrapper>
                )
            }
        </StyledRelationBar>
    );
};

export default RelationBar;
