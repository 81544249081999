import { cn } from "helpers/cn";
import { Icon } from "helpers/IconHelper";
import React from "react";

interface ErrorMessageProps {
    errorMessage?: string;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ errorMessage }) => <div
    className={cn("tw-flex tw-gap-2 tw-items-center", {
        "tw-invisible": !errorMessage,
    })}>
    <Icon.FormMessageError className="tw-text-error-700 tw-flex-shrink-0" />
    <p className="tw-text-error-700 tw-text-caption-1 tw-font-normal text-start">
        {errorMessage} &nbsp;
    </p>
</div>;