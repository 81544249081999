import { useState } from "react";

export const useContestCodePanel = () => {
    const [isPanelOpened, setIsPanelOpened] = useState(false);

    const open = () => setIsPanelOpened(true);
    const hide = () => setIsPanelOpened(false);

    return {
        isPanelOpened,
        open,
        hide
    };
};
