import { useMemo } from 'react';
import { NineCatRepository } from "../../../data/repository/nineCat/NineCatRepository";
import { NineCatContestDetailVo } from "../../../data/vo/nineCat/NineCatContestDetailVo";
import useFantasyQuery from "../../../hooks/useFantasyQuery";
import { QUERY_KEY } from "../../../hooks/useFantasyQuery/type";
import { CONTEST_TYPE_FROM_API, ENTRY_FEE_TYPE_FROM_API } from "../../../types/contest/Contest";
import { NineCatContestVo } from "../data/ComponentTypes";
import { ContestEntry } from "../helpers/helpers";

export interface PrizesContainerProps {
    contestCode: NineCatContestVo['code'];
}

export function useQueryPrizeList(props: PrizesContainerProps) {
    const nineCatRepository = new NineCatRepository();
    const contestDetail = useFantasyQuery<NineCatContestDetailVo>(
        [QUERY_KEY.SLATE_CONTEST_DETAIL, props.contestCode],
        () => nineCatRepository.getNineCatContestDetail(props.contestCode), {
            options: {
                enabled: true,
            }
        }
    );

    const contestEntry = useMemo(() => {
        if (!contestDetail.data) return new ContestEntry(ENTRY_FEE_TYPE_FROM_API.DEPOSIT, CONTEST_TYPE_FROM_API.NORMAL);
        return new ContestEntry(contestDetail.data.entryFeeType, contestDetail.data.type);
    }, [contestDetail.data]);

    return {
        ...contestDetail,
        prizeList: contestDetail.data?.prizeList || [],
        contestEntry
    };
}
