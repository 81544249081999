import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import { UserIdToCurrentApLevel, UserRepository } from "data/repository/user/UserRepository";
import { useMemo } from "react";
import { UserId } from "Common";

const useQueryCurrentLevel = (userIds: UserId[]) => {
    const userRepository = useMemo(() => new UserRepository(), []);

    const queryCurrentLevel = useFantasyQuery<UserIdToCurrentApLevel>([QUERY_KEY.AP_LEVEL_LIST], () => {
        return userRepository.getCurrentApLevelList(userIds);
    }, {
        options: {
            initialData: () => ({}),
            enabled: !!userIds.length,
        }
    });

    return ({
        ...queryCurrentLevel,
        userIdToCurrentLevel: queryCurrentLevel.data,
    });
};

export default useQueryCurrentLevel;
