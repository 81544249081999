import { Icon } from "helpers/IconHelper";
import React from "react";
import useLiveChat from "hooks/useLiveChat";
import { useSelector } from "store";
import { FormattedMessage } from "react-intl";

export const SupportLink = () => {
    const { openChat } = useLiveChat();
    const { FantasyGameConfigState: { featureConfig } } = useSelector(store => store);
    if (!featureConfig.liveChat) {
        return null;
    }

    return <div onClick={() => openChat()}
                className="tw-px-8 tw-py-3 tw-text-primary-700 tw-text-body-2 tw-bg-grey-50 tw-flex tw-justify-center tw-gap-2 tw-cursor-pointer">
        <Icon.CustomerService />
        <FormattedMessage id="wallet_view_support_link" />
    </div>;
};
