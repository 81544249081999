import React, { useCallback, useContext, useState } from "react";
import { LoadingWrap } from "./LoadingStyle";
import { Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";

const LoadingContext = React.createContext(false);

const LoadingToggleContext = React.createContext((status) => {});


export const LoadingPage = ({
                                isFullWidth = false
                            }) => {
    return (
        <LoadingWrap className={clsx("loading", isFullWidth ? "mw-100" : "")} data-testid="loading">
            <Spinner animation="border" role="status" />
            <span className="visually-hidden">
                <FormattedMessage id="loading_dialog_label" />
            </span>
        </LoadingWrap>
    );
};

const LoadingProvider = ({ children }) => {
    const [loadings, setLoadings] = useState([]);

    const loadingToggle = useCallback((status) => {
        setLoadings(prev => {
            if (status) {
                return [...prev, status];
            } else {
                return prev.length > 0 ? prev.slice(1) : [];
            }
        });
    }, []);

    return (
        <LoadingContext.Provider value={loadings.length > 0}>
            <LoadingToggleContext.Provider value={loadingToggle}>
                {
                    loadings.length > 0 ? <LoadingPage /> : null
                }
                {children}
            </LoadingToggleContext.Provider>
        </LoadingContext.Provider>
    );
};


const useLoadingToggle = () => useContext(LoadingToggleContext);
const useLoading = () => useContext(LoadingContext);

export { useLoading, LoadingProvider, useLoadingToggle };
