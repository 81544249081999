import { fetchMyApInfo, fetchMyApOverview } from "apis/ap/ActivityPointApi";
import { ApiExecuteContext } from "apis/ApiContext";
import Navbar from "components/container/navbar/Navbar";
import { apLevelConfig, bgImgPosition, headerHeight, isUltimateMaster } from "domain/ap/ActivityPointHelper";
import {
    ActivityPointProgramContainer,
    ActivityPointProgramTabWrapper,
    ActivityPointProgressWrapper,
    BottomNavbarWrapper,
    InfoButton,
    Title
} from "domain/ap/ActivityPointProgramStyle";
import ActivityPointProgramTab from "domain/ap/ActivityPointProgramTab";
import ActivityPointProgress from "domain/ap/ActivityPointProgress";
import Current from "domain/ap/Current";
import Upgrade from "domain/ap/Upgrade";
import SYSTEM_LINKS from "helpers/LinkHelper";
import { getQuery } from "helpers/queryString";
import useFantasyHistory from "hooks/useFantasyHistory";
import { useLocaleProvider } from "LocaleProvider";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { IntlShape } from "react-intl/lib";
import { AP_LEVEL } from "types/activityPoint/ApLevel";
import { MyApInfo } from "types/activityPoint/MyApInfo";

const infoIconPrimary = "/images/info_icon_primary.png";
const infoIconWhite = "/images/info_icon_white.png";

const getTabs = (myApInfo: MyApInfo, formatMessage: IntlShape["formatMessage"]) => [
    {
        tabKey: "Current",
        text: formatMessage({ id: "activity_point_page_tab_current" }),
        layout: Current,
        props: {
            myApInfo
        }
    },
    {
        tabKey: "Upgrade",
        text: formatMessage({ id: "activity_point_page_tab_upgrade" }),
        layout: Upgrade,
        props: {
            myApInfo
        }
    }
];

export const getNavBackIconColor = (currentLevel: AP_LEVEL) => isUltimateMaster(currentLevel) ? "tw-text-gray-0" : "tw-text-primary-600";
export const getInfoIcon = (currentLevel: AP_LEVEL) => isUltimateMaster(currentLevel) ? infoIconWhite : infoIconPrimary;

const BottomNavbarLayout = ({ tabs, myApOverview, setActiveTab, activeTab }) => {
    return <BottomNavbarWrapper>
        <ActivityPointProgressWrapper>
            <ActivityPointProgress myApOverview={myApOverview} />
        </ActivityPointProgressWrapper>
        {
            !isUltimateMaster(myApOverview.currentLevel) &&
            <ActivityPointProgramTabWrapper>
                <ActivityPointProgramTab tabs={tabs} setActiveTab={setActiveTab} activeTab={activeTab}
                                         currentLevel={myApOverview.currentLevel} />
            </ActivityPointProgramTabWrapper>
        }
    </BottomNavbarWrapper>;
};

const ActivityPointProgram: React.FC = () => {
    const history = useFantasyHistory();
    const { tab: queryTab } = getQuery();

    const { locale } = useLocaleProvider();
    const [myApInfo, setMyApInfo] = useState({
        transferWinningLimitCent: 0,
        transferredTodayCent: 0,
        accumulationPeriod: [],
        currentApDeposit: 0,
        currentApBet: 0,
        nextMonthRemainingAp: 0,
        upgrades: []
    });
    const [myApOverview, setMyApOverview] = useState({
        currentLevel: AP_LEVEL.BEGINNER,
        currentLevelMinAp: 0,
        currentAp: 0,
        nextLevel: "",
        nextLevelMinAp: 0
    });
    const intl = useIntl();
    const tabs = useMemo(() => getTabs(myApInfo, intl.formatMessage), [myApInfo, intl]);

    const apiExecutor = useContext(ApiExecuteContext);

    const [activeTab, setActiveTab] = useState(() => queryTab || tabs[0].tabKey);


    const onNavbarBackClick = useCallback(() => {
        if (history.location?.state?.navbarBackGoTo) {
            history.push(history.location.state.navbarBackGoTo);
        } else {
            history.goBack();
        }
    }, [history]);

    useEffect(() => {
        const onSuccess = ([_myApOverview, _myApInfo]) => {
            setMyApOverview(_myApOverview);
            setMyApInfo(_myApInfo);
        };

        apiExecutor(
            Promise.all([fetchMyApOverview(), fetchMyApInfo()]),
            { onSuccess }
        );
    }, [history, apiExecutor]);


    const { layout: Content, props } = tabs.find(({ tabKey }) => tabKey === activeTab)!;

    return <>
        <Navbar
            isTransparent
            onNavbarBackClick={onNavbarBackClick}
            navBackIconColor={getNavBackIconColor(myApOverview.currentLevel)}
            navbarCenter={<Title $currentLevel={myApOverview.currentLevel}>
                <FormattedMessage id="activity_point_page_title" />
            </Title>}
            endIcon={
                <InfoButton
                    $bgImg={getInfoIcon(myApOverview.currentLevel)}
                    href={SYSTEM_LINKS.apProgram(locale)}
                    target="_blank"
                />
            }
            navbarBottom={<BottomNavbarLayout tabs={tabs}
                                              activeTab={activeTab}
                                              setActiveTab={setActiveTab}
                                              myApOverview={myApOverview} />}
            navbarBackgroundImg={apLevelConfig[myApOverview.currentLevel]?.bg}
            constantHeight={headerHeight}
            bgImgPosition={bgImgPosition}
        />
        <ActivityPointProgramContainer>
            <Content {...props} />
        </ActivityPointProgramContainer>
    </>;
};

export default ActivityPointProgram;
