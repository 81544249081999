import React from "react";
import useFantasyConfig from "../../../hooks/useFantasyConfig";
import { NINE_CAT_STAT, NineCatPlayerDetailVO } from "../data/ComponentTypes";

export function AutoChooseSlateTeamPlayersButton(props: {
    players: NineCatPlayerDetailVO[],
    onClick: (map: any) => void
}) {
    const { devToggle } = useFantasyConfig();

    if (!devToggle) return null;

    function handleAutoChoosePlayers() {
        const players = props.players;
        if (players.length === 0) return;

        props.onClick({
            [NINE_CAT_STAT.PTS]: String(players[0].id),
            [NINE_CAT_STAT.FGE]: String(players[1].id),
            [NINE_CAT_STAT.FTE]: String(players[2].id),
            [NINE_CAT_STAT.OR]: String(players[3].id),
            [NINE_CAT_STAT.DR]: String(players[4].id),
            [NINE_CAT_STAT.AST]: String(players[5].id),
            [NINE_CAT_STAT.ST]: String(players[6].id),
            [NINE_CAT_STAT.BLK]: String(players[7].id),
            [NINE_CAT_STAT["3PM"]]: String(players[8].id),

            [String(players[0].id)]: NINE_CAT_STAT.PTS,
            [String(players[1].id)]: NINE_CAT_STAT.FGE,
            [String(players[2].id)]: NINE_CAT_STAT.FTE,
            [String(players[3].id)]: NINE_CAT_STAT.OR,
            [String(players[4].id)]: NINE_CAT_STAT.DR,
            [String(players[5].id)]: NINE_CAT_STAT.AST,
            [String(players[6].id)]: NINE_CAT_STAT.ST,
            [String(players[7].id)]: NINE_CAT_STAT.BLK,
            [String(players[8].id)]: NINE_CAT_STAT["3PM"],
        });
    }


    return <div className={"tw-absolute tw-z-2 tw-right-0 tw-top-0 tw-bg-slate-200"}>
        <button onClick={handleAutoChoosePlayers}>Auto Choose</button>
    </div>;
}
