import {
    basketballBlackIcon,
    basketballGrayIcon,
    cricketBlackIcon,
    cricketGrayIcon,
    footballBlackIcon,
    footballGrayIcon,
    systemBackgroundBasketball,
    systemBackgroundCricket,
    systemBackgroundFootball,
    teamPreviewBasketballBg,
    teamPreviewCricketBg,
    teamPreviewFootballBg
} from "helpers/IconHelper";
import { findKey, startCase } from "lodash";
import { BASKETBALL_ROLE } from "types/sports/Basketball";
import { CRICKET_ROLE } from "types/sports/Cricket";
import { FOOTBALL_ROLE } from "types/sports/Football";
import { ROLE, SPORT, SportRoleVO } from "types/sports/Sport";
import {
    basketballOneClickChoosePlayer,
    cricketOneClickChoosePlayer,
    footballOneClickChoosePlayer
} from "domain/team/player/ChoosePlayerHelper";

export interface SportConfigDetail {
    name: string
    key: string
    bg: {
        layout: string
    },
    icon: {
        gray: string
        black: string
    },
    roles: SportRoleVO<any>,
    team: {
        teamPreviewBg: string
        maxPlayerCount: number
        maxPlayerCountEachSquad: number
        getTeamRolesCount: (team) => {
            name: string,
            count: number
        }[]
        getRoleAbbr: (playerRole: ROLE) => string
        oneClickChoosePlayer: any
        roleLimit: {
            [key: string]: {
                UPPER: number
                LOWER: number
            }
        }
    }
    glossary: {
        abbr: string
        fullName: string
    }[],
    performances: {
        key: string,
        label: string
    }[]
    gameLogs: {
        key: string
        label: string,
        className?: string
    }[],
    scoring: {
        key: string
        label: string,
        point: string,
        className?: string
    }[]
}

export type SportConfig = {
    [key in SPORT]: SportConfigDetail
}

const SPORT_CONFIG: SportConfig = {
    cricket: {
        name: startCase(SPORT.CRICKET),
        key: SPORT.CRICKET.toUpperCase(),
        bg: {
            layout: systemBackgroundCricket,
        },
        icon: {
            gray: cricketGrayIcon,
            black: cricketBlackIcon
        },
        roles: CRICKET_ROLE,
        team: {
            teamPreviewBg: teamPreviewCricketBg,
            maxPlayerCount: 11,
            maxPlayerCountEachSquad: 10,
            getTeamRolesCount: (team) => [
                { name: CRICKET_ROLE.WICKET_KEEPER.abbr, count: team.wicketKeeperCount },
                { name: CRICKET_ROLE.BATSMAN.abbr, count: team.batsmanCount },
                { name: CRICKET_ROLE.ALL_ROUNDER.abbr, count: team.allRounderCount },
                { name: CRICKET_ROLE.BOWLER.abbr, count: team.bowlerCount }
            ],
            getRoleAbbr: (playerRole) => {
                return CRICKET_ROLE[findKey(CRICKET_ROLE, (role) => role.apiValue === playerRole)!].abbr;
            },
            oneClickChoosePlayer: cricketOneClickChoosePlayer,
            roleLimit: {
                [CRICKET_ROLE.WICKET_KEEPER.apiValue]: { UPPER: 8, LOWER: 1 },
                [CRICKET_ROLE.BATSMAN.apiValue]: { UPPER: 8, LOWER: 1 },
                [CRICKET_ROLE.ALL_ROUNDER.apiValue]: { UPPER: 8, LOWER: 1 },
                [CRICKET_ROLE.BOWLER.apiValue]: { UPPER: 8, LOWER: 1 }
            }
        },
        glossary: [
            {
                abbr: "Start",
                fullName: "Announced",
            },
            {
                abbr: "Runs",
                fullName: "Runs",
            },
            {
                abbr: "4's",
                fullName: "Fours",
            },
            {
                abbr: "6's",
                fullName: "Six",
            },
            {
                abbr: "S/R",
                fullName: "Strike Rate",
            },
            {
                abbr: "30",
                fullName: "Runs milestone",
            },
            {
                abbr: "50",
                fullName: "Runs milestone",
            },
            {
                abbr: "Duck",
                fullName: "Duck",
            },
            {
                abbr: "Wkts",
                fullName: "Wickets",
            },
            {
                abbr: "LBW",
                fullName: "Leg Before Wicket",
            },
            {
                abbr: "MO",
                fullName: "Maiden Over",
            },
            {
                abbr: "E/R",
                fullName: "Economy Rate",
            },
            {
                abbr: "Catch",
                fullName: "Catch",
            },
            {
                abbr: "CB",
                fullName: "Catch Bonus",
            },
            {
                abbr: "ST",
                fullName: "Stumping",
            },
            {
                abbr: "RO",
                fullName: "Run Out",
            }
        ],
        performances: [
            {
                key: "averageEconomyRatePoints",
                label: "E/R"
            },
            {
                key: "averageStrikeRatePoints",
                label: "S/R"
            },
            {
                key: "averageRunPoints",
                label: "Runs"
            },
            {
                key: "averagePlayerPoints",
                label: "Score"
            }
        ],
        gameLogs: [
            {
                key: "matchDate",
                label: "Date",
                className: "text-start",
            },
            {
                key: "oppositeSquadCode",
                label: "Match",
            },
            {
                key: "totalPoint",
                label: "Score",
                className: "text-end",
            },
            {
                key: "starting",
                label: "Start",
            },
            {
                key: "run",
                label: "Runs",
            },
            {
                key: "four",
                label: "4's",
            },
            {
                key: "six",
                label: "6's",
            },
            {
                key: "strikeRate",
                label: "S/R",
            },
            {
                key: "thirty",
                label: "30",
            },
            {
                key: "fifty",
                label: "50",
            },
            {
                key: "duck",
                label: "Duck",
            },
            {
                key: "wickets",
                label: "Wkts",
            },
            {
                key: "bonusBowedLBW",
                label: "LBW",
            },
            {
                key: "maidenOver",
                label: "MO",
            },
            {
                key: "economyRate",
                label: "E/R",
            },
            {
                key: "catches",
                label: "Catch",
            },
            {
                key: "bonusCatch",
                label: "CB",
            },
            {
                key: "stumping",
                label: "ST",
            },
            {
                key: "directRunOut",
                label: "RO",
            },
        ],
        scoring: []
    },
    football: {
        name: startCase(SPORT.FOOTBALL),
        key: SPORT.FOOTBALL.toUpperCase(),
        bg: {
            layout: systemBackgroundFootball,
        },
        icon: {
            gray: footballGrayIcon,
            black: footballBlackIcon
        },
        roles: FOOTBALL_ROLE,
        team: {
            teamPreviewBg: teamPreviewFootballBg,
            maxPlayerCount: 11,
            maxPlayerCountEachSquad: 7,
            getTeamRolesCount: (team) => [
                { name: FOOTBALL_ROLE.GOAL_KEEPER.abbr, count: team.goalkeeperCount },
                { name: FOOTBALL_ROLE.DEFENDER.abbr, count: team.defenderCount },
                { name: FOOTBALL_ROLE.MID_FIELDER.abbr, count: team.midfielderCount },
                { name: FOOTBALL_ROLE.FORWARD.abbr, count: team.forwardCount }
            ],
            getRoleAbbr: (playerRole) => {
                return FOOTBALL_ROLE[findKey(FOOTBALL_ROLE, (role) => role.apiValue === playerRole)!].abbr;
            },
            oneClickChoosePlayer: footballOneClickChoosePlayer,
            roleLimit: {
                [FOOTBALL_ROLE.GOAL_KEEPER.apiValue]: { UPPER: 1, LOWER: 1 },
                [FOOTBALL_ROLE.DEFENDER.apiValue]: { UPPER: 5, LOWER: 3 },
                [FOOTBALL_ROLE.MID_FIELDER.apiValue]: { UPPER: 5, LOWER: 3 },
                [FOOTBALL_ROLE.FORWARD.apiValue]: { UPPER: 3, LOWER: 1 }
            }
        },
        glossary: [
            {
                abbr: "G",
                fullName: "Goal",
            },
            {
                abbr: "A",
                fullName: "Assists",
            },
            {
                abbr: "SOT",
                fullName: "Shots on target",
            },
            {
                abbr: "CC",
                fullName: "Chances created",
            },
            {
                abbr: "PC",
                fullName: "Passes completed",
            },
            {
                abbr: "TW",
                fullName: "Tackles won",
            },
            {
                abbr: "IW",
                fullName: "Interceptions won",
            },
            {
                abbr: "S",
                fullName: "Saves",
            },
            {
                abbr: "PS",
                fullName: "Penalties saved",
            },
            {
                abbr: "CS",
                fullName: "Clean sheet",
            },
            {
                abbr: "SU",
                fullName: "Substitute",
            },
            {
                abbr: "YC",
                fullName: "Yellow card",
            },
            {
                abbr: "RC",
                fullName: "Red card",
            },
            {
                abbr: "OG",
                fullName: "Own goals",
            },
            {
                abbr: "GC",
                fullName: "Goals conceded",
            },
            {
                abbr: "PM",
                fullName: "Penalties missed",
            },
            {
                abbr: "ST",
                fullName: "Starting",
            },
        ],
        performances: [
            {
                key: "averageGoalPoints",
                label: "Goals"
            },
            {
                key: "averageAssistPoints",
                label: "Assists"
            },
            {
                key: "averagePlayerPoints",
                label: "Score"
            }
        ],
        gameLogs: [
            {
                key: "matchDate",
                label: "Date",
                className: "text-start",
            },
            {
                key: "oppositeSquadCode",
                label: "Match",
            },
            {
                key: "totalPoint",
                label: "Score",
                className: "text-end",
            },
            {
                key: "goal",
                label: "G",
            },
            {
                key: "assist",
                label: "A",
            },
            {
                key: "shotsOnTarget",
                label: "SOT",
            },
            {
                key: "chanceCreated",
                label: "CC",
            },
            {
                key: "passesCompleted",
                label: "PC",
            },
            {
                key: "tackleWon",
                label: "TW",
            },
            {
                key: "interceptionWon",
                label: "IW",
            },
            {
                key: "save",
                label: "S",
            },
            {
                key: "penaltiesSaved",
                label: "PS",
            },
            {
                key: "cleanSheet",
                label: "CS",
            },
            {
                key: "yellowCard",
                label: "YC",
            },
            {
                key: "redCard",
                label: "RC",
            },
            {
                key: "ownGoal",
                label: "OG",
            },
            {
                key: "goalsConceded",
                label: "GC",
            },
            {
                key: "penaltyMissed",
                label: "PM",
            },
            {
                key: "starting11",
                label: "ST",
            },
            {
                key: "substitute",
                label: "SU",
            },
        ],
        scoring: []
    },
    basketball: {
        name: startCase(SPORT.BASKETBALL),
        key: SPORT.BASKETBALL.toUpperCase(),
        icon: {
            gray: basketballGrayIcon,
            black: basketballBlackIcon
        },
        bg: {
            layout: systemBackgroundBasketball
        },
        roles: BASKETBALL_ROLE,
        team: {
            teamPreviewBg: teamPreviewBasketballBg,
            maxPlayerCount: 8,
            maxPlayerCountEachSquad: 5,
            getTeamRolesCount: (team) => [
                { name: BASKETBALL_ROLE.POINT_GUARD.abbr, count: team.pointGuardCount },
                { name: BASKETBALL_ROLE.SHOOTING_GUARD.abbr, count: team.shootingGuardCount },
                { name: BASKETBALL_ROLE.SMALL_FORWARD.abbr, count: team.smallForwardCount },
                { name: BASKETBALL_ROLE.POWER_FORWARD.abbr, count: team.powerForwardCount },
                { name: BASKETBALL_ROLE.CENTER.abbr, count: team.centerCount },
            ],
            getRoleAbbr: (playerRole) => {
                return BASKETBALL_ROLE[findKey(BASKETBALL_ROLE, (role) => role.apiValue === playerRole)!].abbr;
            },
            oneClickChoosePlayer: basketballOneClickChoosePlayer,
            roleLimit: {
                [BASKETBALL_ROLE.POINT_GUARD.apiValue]: { UPPER: 4, LOWER: 1 },
                [BASKETBALL_ROLE.SHOOTING_GUARD.apiValue]: { UPPER: 4, LOWER: 1 },
                [BASKETBALL_ROLE.SMALL_FORWARD.apiValue]: { UPPER: 4, LOWER: 1 },
                [BASKETBALL_ROLE.POWER_FORWARD.apiValue]: { UPPER: 4, LOWER: 1 },
                [BASKETBALL_ROLE.CENTER.apiValue]: { UPPER: 4, LOWER: 1 },
            },
        },
        glossary: [],
        performances: [
            {
                key: "averagePoints",
                label: "PT"
            },
            {
                key: "averageRebounds",
                label: "REB"
            },
            {
                key: "averageSteals",
                label: "ST"
            },
            {
                key: "averagePlayerPoints",
                label: "Score"
            }
        ],
        gameLogs: [
            {
                key: "matchDate",
                label: "Date",
                className: "text-start",
            },
            {
                key: "oppositeSquadCode",
                label: "Match",
            },
            {
                key: "totalPoint",
                label: "Score",
                className: "text-end",
            },
            {
                key: "minutesPlayed",
                label: "MIN",
            },
            {
                key: "personalFouls",
                label: "PF",
            },
            {
                key: "points",
                label: "PT",
            },
            {
                key: "rebounds",
                label: "REB",
            },
            {
                key: "assists",
                label: "AST",
            },
            {
                key: "steals",
                label: "ST",
            },
            {
                key: "blocks",
                label: "BLK",
            },
            {
                key: "turnovers",
                label: "TO",
            },
        ],
        scoring: [
            {
                key: "points",
                label: "Points (PT)",
                point: "1"
            },
            {
                key: "rebounds",
                label: "Rebounds (REB)",
                point: "1.2"
            },
            {
                key: "assists",
                label: "Assists (AST)",
                point: "1.5"
            },
            {
                key: "steals",
                label: "Steals (ST)",
                point: "3"
            },
            {
                key: "blocks",
                label: "Blocks (BLK)",
                point: "3"
            },
            {
                key: "turnovers",
                label: "Turnovers (TO)",
                point: "-1"
            },
        ]
    },
};

export const getSportConfig = (sport: SPORT = SPORT.CRICKET): SportConfigDetail => {
    return SPORT_CONFIG[sport.toLowerCase()];
};

export const getAllSportConfig = () => {
    return SPORT_CONFIG;
};
