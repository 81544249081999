import { SlateCardSquadLogos } from "domain/slate/components/SlateCardSquadLogos";
import { UpcomingNineCatVo } from "domain/slate/data/vo";
import { cn } from "helpers/cn";
import { Icon } from "helpers/IconHelper";
import useKycRestriction from "hooks/useCheckKycRestriction/useKycRestriction";
import useCurrency from "hooks/useCurrency/useCurrency";
import useFantasyHistory from "hooks/useFantasyHistory";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";


interface SlateCardProps {
    slate: UpcomingNineCatVo;
    className?: string;
}

export const HomeSlateCard = ({ slate, className }: SlateCardProps) => {
    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();
    const history = useFantasyHistory();
    const isRestrictedByKyc = useKycRestriction();

    const handleClick = async () => {
        if (await isRestrictedByKyc()) {
            return;
        }
        history.push(ROUTER_PATH_CONFIG.slate.index.replace(":slateId", slate.id.toString()));
    };

    return <div
        data-testid="slate-card"
        className={cn("slate-card tw-w-full tw-px-3 tw-py-2 tw-bg-grey-0 !tw-rounded-[1.6rem] tw-flex tw-flex-col tw-gap-[1rem] tw-cursor-pointer tw-shadow-slate-card", className)}
        onClick={handleClick}
    >
        <div className="tw-text-left">
            <div className="tw-text-h6 tw-font-bold tw-text-grey-1000">{slate.name}</div>
            <div className="tw-text-caption-1 tw-text-secondary-800 tw-font-bold">{slate.tournament}</div>
            <div className="tw-text-caption-1 tw-font-bold tw-text-primary-700">
                {toFormattedNumber(slate.joined)}&nbsp;
                <FormattedMessage id="match_card_label_joined" />
            </div>
            <SlateCardSquadLogos squads={slate.squads} />
        </div>
        <Icon.ArrowMoveRightGradient className="tw-ml-auto" />
    </div>;
};
