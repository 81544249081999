import styled from "styled-components";
import { loginBackground } from "helpers/IconHelper";
import { APP_MAX_WIDTH } from "../../styles/Constants";

export const LayoutWrapper = styled.div.attrs(() => ({
    className: "d-flex",
}))`
  min-height: 100vh;
  background-image: url(${loginBackground});
  background-size: cover;
  background-position: right bottom;
  background-repeat: no-repeat;
`;

export const AuthPageWrapper = styled.div.attrs({
    className: "d-flex flex-column bg-light-primary",
})`
  max-width: ${APP_MAX_WIDTH};
  width: 100%;
`;

