import React from 'react';
import { cn } from "helpers/cn";
import { Step4 } from 'domain/howToPlay/Step4';
import { Step1 } from "domain/howToPlay/Step1";
import { Step2 } from "domain/howToPlay/Step2";
import { Step5 } from "domain/howToPlay/Step5";
import { Step3 } from "domain/howToPlay/Step3";
import { FormattedMessage } from "react-intl";

const HowToPlayPage: React.FC<{}> = (props) => {
    return (
        <div>
            <NavBar />
            <div className={cn(
                "tw-flex tw-flex-col tw-gap-[6rem] tw-overflow-hidden",
                "tw-px-[2.1rem] tw-pt-[3rem] tw-pb-[15rem]"
            )}>
                <Step1 />
                <Step2 />
                <Step3 />
                <Step4 />
                <Step5 />
            </div>
        </div>
    );
};

const NavBar = () => {
    return <div
        className={cn(
            "tw-w-full tw-bg-b2b-primary-400 tw-py-2 tw-h-[6rem]",
            "tw-flex tw-items-center tw-justify-center",
            "tw-text-grey-0 tw-text-h6 tw-font-bold",
            "tw-sticky tw-top-0 tw-z-10"
        )}
    >
        <FormattedMessage id={"me_page_menu_label_how_to_play"} />
    </div>;
};

export default HowToPlayPage;
