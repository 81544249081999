import FantasyIcon from "helpers/FantasyIcon";
import React from "react";
import {
    Date,
    ListItem,
    WalletEvent,
    WalletHistoryEventWrapper,
    WalletHistoryInfo
} from "domain/wallet/components/walletHistoryRow/WalletHistoryRowStyle";
import useUserIdentity from "hooks/useUserIdentity";
import { formatToFullYearDateTime } from "helpers/TimeHelper";
import { AmountView } from "domain/wallet/components/walletHistoryRow/components/AmountView";
import { getWalletEventText } from "domain/wallet/WalletHelper";
import { WalletHistoryVo } from "data/vo/wallet/WalletHistoryVo";

export interface HistoryRecordItemProps {
    record: WalletHistoryVo
}

export const HistoryRecordItem: React.FC<HistoryRecordItemProps> = ({ record }) => {
    const { isB2B } = useUserIdentity();

    return <ListItem>
        <WalletHistoryInfo>
            <FantasyIcon
                src={record.icon}
                size="2.4rem"
                className="tw-mr-[1.4rem]"
            />
            <WalletHistoryEventWrapper>
                <Date>{formatToFullYearDateTime(record.createTime)}</Date>
                <WalletEvent>
                    {getWalletEventText(record.type, record, isB2B)}
                </WalletEvent>
            </WalletHistoryEventWrapper>
        </WalletHistoryInfo>

        <AmountView record={record} />
    </ListItem>;
};
