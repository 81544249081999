import React, {
    createContext,
    JSXElementConstructor,
    ReactElement,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import useFantasyHistory from "../../hooks/useFantasyHistory";
import { useDispatch } from "react-redux";
import { updateUserIsNewUser } from "../../store/actions/UserActions";

export interface AppPopupsProps {
    done: () => void;
    keep: () => void;
}

export const AppPopupsContext = createContext<AppPopupsProps | null>(null);

function queueIncludes(queue: React.ReactElement<any, React.JSXElementConstructor<any>>[],
                       node: React.ReactElement<any, React.JSXElementConstructor<any>>) {
    return queue.some((el) => el.type.name === node.type.name);
}

type AppPopupsProviderProps = {
    children: ReactElement<any, JSXElementConstructor<any>>[];
};

const AppPopupsProvider: React.FC<AppPopupsProviderProps> = ({ children }) => {
    const [popupList, setPopupList] = useState(children);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [shouldRemoveCurrent, setShouldRemoveCurrent] = useState(false);
    const [shouldIncreaseIndex, setShouldIncreaseIndex] = useState(false);

    const history = useFantasyHistory();
    const dispatch = useDispatch();

    const done = () => setShouldRemoveCurrent(true);
    const keep = () => setShouldIncreaseIndex(true);

    useEffect(() => {
        if (shouldRemoveCurrent) {
            setPopupList([
                ...popupList.slice(0, currentIndex),
                ...popupList.slice(currentIndex + 1),
            ]);
            setShouldRemoveCurrent(false);
            setShouldIncreaseIndex(false);
        }
    }, [shouldRemoveCurrent]);

    useEffect(() => {
        if (shouldIncreaseIndex) {
            setCurrentIndex(currentIndex + 1);
            setShouldIncreaseIndex(false);
        }
    }, [shouldIncreaseIndex]);

    useEffect(() => {
        if (currentIndex >= popupList.length) {
            dispatch(updateUserIsNewUser(false));
        }
    }, [currentIndex, popupList]);

    useEffect(() => {
        setCurrentIndex(0);
        setPopupList([...children.filter((node) => queueIncludes(popupList, node))]);
    }, [history.location.pathname]);

    const context = useMemo(() => ({
        done,
        keep,
    }), []);

    if (currentIndex >= popupList.length) {
        return null;
    }

    return <AppPopupsContext.Provider value={context}>
        {popupList[currentIndex]}
    </AppPopupsContext.Provider>;
};

export default AppPopupsProvider;

export const useAppPopups = () => {
    const context = useContext(AppPopupsContext);

    if (!context) {
        throw new Error("useAppPopups must be used within a AppPopupsProvider");
    }

    return context;
};
