import { sendOtp, verifyOtp } from "data/api/kyc/KycApi";

export class KycRepository {
    public async sendOtp(countryCode: string, mobileNumber: string) {
        await sendOtp(countryCode, mobileNumber);
    };

    public async verifyOtp(code: string) {
        await verifyOtp(code);
    };
}

