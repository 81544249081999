import { divideByOneHundred } from "helpers/number/NumberHelper";
import { AP_LEVEL } from "../../../types/activityPoint/ApLevel";
import {
    CONTEST_STATUS_FROM_API,
    CONTEST_TYPE_FROM_API,
    ENTRY_FEE_TYPE_FROM_API
} from "../../../types/contest/Contest";
import { MATCH_STATUS_FROM_API } from "../../../types/match/Match";
import { SLATE_TYPE } from "../../../types/Slate/Slate";
import { SPORT } from "../../../types/sports/Sport";
import { ContestDetailDto } from "../../dto/contest/ContestDetailDto";
import { PrizeListVo } from "../PrizeList/PrizrListVo";

export class ContestDetailVo {
    id: number;
    matchStatus: MATCH_STATUS_FROM_API;
    slateType: SLATE_TYPE;
    matchId: string;
    sport: SPORT;
    name: string;
    code: string;
    type: CONTEST_TYPE_FROM_API;
    status: CONTEST_STATUS_FROM_API;
    entryFeeType: ENTRY_FEE_TYPE_FROM_API;
    spots: number;
    entryFee: number;
    allowMultiple: boolean;
    allowDiscountWithRuby: boolean;
    minApLevel: AP_LEVEL;
    minApLevelWeight: number;
    minimumSlip: number;
    entryLimit: number;
    firstPrize: number;
    prizeList: PrizeListVo[];
    prizeTeams: number;
    hostDonatedPool: number;
    regularPool: number;
    joinedTeams: number;
    entryFeeCent: number;
    firstPrizeCent: number;
    regularPoolCent: number;
    hostDonatedPoolCent: number;

    constructor(dto: ContestDetailDto) {
        this.id = dto.id;
        this.matchStatus = dto.matchStatus;
        this.slateType = dto.slateType;
        this.matchId = dto.matchId;
        this.sport = SPORT[dto.sport];
        this.name = dto.name;
        this.code = dto.code;
        this.type = dto.type;
        this.status = dto.status;
        this.entryFeeType = dto.entryFeeType;
        this.spots = dto.spots;
        this.entryFee = divideByOneHundred(dto.entryFeeCent);
        this.allowMultiple = dto.allowMultiple;
        this.allowDiscountWithRuby = dto.allowDiscountWithRuby;
        this.minApLevel = dto.minApLevel;
        this.minApLevelWeight = dto.minApLevelWeight;
        this.minimumSlip = dto.minimumSlip;
        this.entryLimit = dto.entryLimit;
        this.firstPrize = divideByOneHundred(dto.firstPrizeCent);
        this.prizeList = dto.prizeList.map((prize) => new PrizeListVo(prize));
        this.prizeTeams = dto.prizeTeams;
        this.hostDonatedPool = divideByOneHundred(dto.hostDonatedPoolCent);
        this.regularPool = divideByOneHundred(dto.regularPoolCent);
        this.joinedTeams = dto.joinedTeams;
        this.entryFeeCent = dto.entryFeeCent;
        this.firstPrizeCent = dto.firstPrizeCent;
        this.regularPoolCent = dto.regularPoolCent;
        this.hostDonatedPoolCent = dto.hostDonatedPoolCent;
    }
}
