import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { IntlShape } from "react-intl/lib";
import { cn } from "../../../../helpers/cn";
import { Icon } from "../../../../helpers/IconHelper";
import { AP_LEVEL } from "../../../../types/activityPoint/ApLevel";
import { CONTEST_TYPE_FROM_API, ENTRY_FEE_TYPE_FROM_API } from "../../../../types/contest/Contest";
import { apLevelConfig } from "../../../ap/ActivityPointHelper";
import { CONTEST_CARD_VARIANT } from "../../data/ComponentTypes";
import { ContestEntry } from "../../helpers/helpers";

export enum AP_LEVEL_VARIANT {
    OUTLINED,
    FILLED,
}

interface ContestTitleProps {
    variant?: CONTEST_CARD_VARIANT;
    apLevelVariant?: AP_LEVEL_VARIANT;
    entryFee: number;
    entryFeeType: ENTRY_FEE_TYPE_FROM_API;
    contestType: CONTEST_TYPE_FROM_API;
    minApLevel: AP_LEVEL;
}

function getTitle(intl: IntlShape, contestEntry: ContestEntry) {
    if (contestEntry.isCoin()) {
        return intl.formatMessage({
            id: "contest_card_title_deposit_normal"
        });
    }
    if (contestEntry.isRuby()) {
        return intl.formatMessage({
            id: "contest_card_title_ruby_normal"
        });
    }
    if (contestEntry.isPractice()) {
        return intl.formatMessage({
            id: "contest_card_title_practice"
        });
    }
    return "";
}

export const ContestTitle: React.FC<ContestTitleProps> = ({
                                                              apLevelVariant,
                                                              contestType,
                                                              entryFeeType,
                                                              minApLevel,
                                                              variant
                                                          }) => {
    const intl = useIntl();

    const shouldShowMinApLevel = minApLevel !== AP_LEVEL.BEGINNER;

    const title = useMemo(() => (
        getTitle(intl, new ContestEntry(entryFeeType, contestType))
    ), [entryFeeType, contestType]);

    const myGameStyle = "tw-text-grey-650";
    const joinContestStyle = apLevelVariant === AP_LEVEL_VARIANT.OUTLINED ? "tw-text-grey-200" : "tw-text-tertiary-700 tw-bg-white";

    return <div className={"tw-flex tw-gap-1 tw-items-center"}>
        <p className={"tw-text-subtitle-1 tw-font-medium"}>
            {title}
        </p>
        {shouldShowMinApLevel && (
            <div
                className={cn("tw-flex tw-items-center tw-h-4 tw-pl-1",
                    "tw-rounded-full",
                    "tw-pr-1",
                    variant === CONTEST_CARD_VARIANT.MyGame ? myGameStyle : joinContestStyle,
                )}>
                <Icon.ApLevel />
                {apLevelConfig[minApLevel].text()}
            </div>
        )}
    </div>;
};
