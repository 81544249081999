import { LeftSquadQuickSummary, RightSquadQuickSummary } from "domain/match/components/squad/MatchSquadLayoutStyle";
import { isMatchCompleted } from "helpers/match/MatchHelper";
import React from "react";
import MatchSquadLayout from "../squad/MatchSquadLayout";
import { ContestDetailNavbarBottomProps } from "./ContestDetailNavbarBottomPropsInterface";
import { isFootballSquadCodeHighlighted } from "./FootballNavbarMatchWithScore";


const FootballContestDetailNavbarBottom: React.FC<ContestDetailNavbarBottomProps> = ({ match }) => {
    const isCompleted = match && isMatchCompleted(match.matchStatus);

    const squadAHighlighted = isFootballSquadCodeHighlighted(match.squadACode, match);
    const squadBHighlighted = isFootballSquadCodeHighlighted(match.squadBCode, match);

    return <MatchSquadLayout matchDetail={match}
                             squadAScore={<LeftSquadQuickSummary
                                 $highlight={squadAHighlighted}
                                 $completed={isCompleted}>
                                 {match.squadAScores}
                             </LeftSquadQuickSummary>}
                             squadBScore={<RightSquadQuickSummary
                                 $highlight={squadBHighlighted}
                                 $completed={isCompleted}>
                                 {match.squadBScores}
                             </RightSquadQuickSummary>}
    />;
};

export default FootballContestDetailNavbarBottom;
