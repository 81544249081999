import NineCatLeavePopup from "domain/slate/components/NineCatLeavePopup";
import { useCreateSlateTeamContext } from "domain/slate/providers/CreateNineCatTeamProvider";
import { useNineCatContext } from "domain/slate/providers/NineCatProvider";
import { cn } from "helpers/cn";
import { Icon } from "helpers/IconHelper";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import useFantasyHistory from "../../../hooks/useFantasyHistory";
import { ROUTER_PATH_CONFIG } from "../../../router/RouterPathConfig";
import { MY_MATCH_TAB } from "../../match/components/providers/MyMatchProvider";
import { CONTEST_CARD_STYLE_MAP } from "../components/ContestCardItem";
import { ContestEntry, getContestEntryType, getRenderElementsWithRemains } from "../helpers/helpers";

const MAX_CONTESTS_AMOUNT_FOR_RENDER = 4;

const MoreContestsSyntax = ({ text }) => {
    return <div
        className={cn("tw-w-[2.8rem] tw-h-[2.8rem] tw-rounded-full tw-relative tw-flex tw-items-center tw-justify-center tw-bg-grey-50 tw-text-caption-2 tw-z-[var(--zIndex)] tw--ml-1")}
        style={{ "--zIndex": 0 } as any}>
        {text}
    </div>;
};

function getContestBgStyle(contestEntry: ContestEntry) {
    return CONTEST_CARD_STYLE_MAP.entryFeeInner[getContestEntryType(contestEntry)];
}


export const MyNineCatContests = () => {
    const history = useFantasyHistory();
    const { myCurrentSlateContests, isRestrictedByKyc } = useNineCatContext();
    const { hasSelectPlayer, handleClearStatPlayerIdMap } = useCreateSlateTeamContext();
    const [openSlateLeaveModal, setOpenSlateLeaveModal] = useState(false);

    if (myCurrentSlateContests.length === 0) return null;

    const {
        remainsAmount,
        renderElements,
        shouldRenderRemainsAmount
    } = getRenderElementsWithRemains(myCurrentSlateContests, MAX_CONTESTS_AMOUNT_FOR_RENDER);

    const handleDirectToMyGames = async () => {
        if (await isRestrictedByKyc()) {
            return;
        }
        history.push(ROUTER_PATH_CONFIG.myMatch.index, {
            myMatchTab: MY_MATCH_TAB.NINE_CAT,
        });
    };

    const handleLeave = () => {
        if (!hasSelectPlayer) {
            handleDirectToMyGames();
            return;
        }

        setOpenSlateLeaveModal(true);
    };

    const handleClickLeavePopupButton = () => {
        handleClearStatPlayerIdMap();
        handleDirectToMyGames();
    };

    return <div>
        <div onClick={handleLeave}
             className={cn("tw-rounded-[.8rem] tw-py-[.6rem] tw-px-4",
                 "tw-bg-gradient-to-r tw-from-grey-0 tw-via-tertiary-50 tw-via-[34.5%] tw-to-primary-100",
                 "tw-flex tw-items-center tw-justify-between tw-cursor-pointer"
             )}>
            <div className="tw-flex">
                {
                    renderElements.map((contest, idx) => {
                        const contestEntry = new ContestEntry(contest.entryFeeType, contest.type);

                        return <div
                            key={contest.id}
                            className={cn("tw-w-[2.8rem] tw-h-[2.8rem] tw-rounded-full tw-relative tw-flex tw-items-center tw-justify-center tw-bg-grey-50 tw-text-caption-2 tw-z-[var(--zIndex)]",
                                { "tw--ml-1": idx !== 0 },
                                getContestBgStyle(contestEntry),
                            )}
                            style={{ "--zIndex": `${renderElements.length - idx}` } as any}
                        >
                            {contestEntry.isPractice() ?
                                <FormattedMessage id="contest_card_label_entry_fee_free" /> : contest.entryFee}
                        </div>;
                    })
                }
                {shouldRenderRemainsAmount &&
                    <MoreContestsSyntax text={`+${remainsAmount}`} />
                }
            </div>
            <div className="tw-text-caption-2 tw-text-grey-1000 tw-font-medium flex-grow-1 tw-ml-4">
                <FormattedMessage id={"label_view_my_contests"} />
                &nbsp;({myCurrentSlateContests!.length})
            </div>
            <Icon.ArrowRight size={"2rem"} />
        </div>

        <NineCatLeavePopup
            open={openSlateLeaveModal}
            onOpenChange={setOpenSlateLeaveModal}
            onLeaveButtonClick={handleClickLeavePopupButton}
        />
    </div>;
};
