import { ZodType } from "zod";

type ValidatorCallback = (value: any) => boolean;

export interface OnChangeValidator {
    validate: ValidatorCallback
}

export class ZodOnChangeValidator implements OnChangeValidator {
    private _schema: ZodType;

    constructor(schema: ZodType) {
        this._schema = schema;
    }

    validate(value: any): boolean {
        return this._schema.safeParse(value).success;
    }
}
