import { fetchCurrentApLevelListUrl, fetchMyProfileUrl, fetchUserStatsUrl } from "apis/ApiUrlConfig";
import { ajax } from "apis/ApiUtils";
import { UserId } from "Common";
import { UserProfileCareerStatsDto } from "data/dto/user/careerStats/UserProfileCareerStatsDto";
import { MyProfileDto } from "data/dto/user/MyProfileDto";
import { CurrentApLevelDto } from "types/activityPoint/CurrentApLevelDto";


export const fetchUserCareerStats = async (userId: number, time: number): Promise<UserProfileCareerStatsDto> => {
    const response = await ajax<UserProfileCareerStatsDto>({ url: fetchUserStatsUrl(userId, time) });
    return response.data.data;
};


export const fetchCurrentApLevelList = async (userIds: UserId[]): Promise<CurrentApLevelDto[]> => {
    const { data } = await ajax<CurrentApLevelDto[]>({
        method: "POST",
        url: fetchCurrentApLevelListUrl,
        data: { userIds }
    });
    return data.data;
};

export const fetchMyProfile = async () => {
    const { data } = await ajax<MyProfileDto>({ url: fetchMyProfileUrl });

    return data.data;
};
