import { StepLabel } from "domain/kyc/components/stepProgressBar/StepLabel";
import { KYC_STEP } from "domain/kyc/pages/kycVerificationForm/KycVerificationForm";
import { IconOmitProps } from "helpers/IconHelper";

interface IconDotProps {
    step: KYC_STEP,
    currentStep: KYC_STEP,
    icon: React.FC<IconOmitProps>
}

export const IconDot: React.FC<IconDotProps> = ({ icon: Icon, step, currentStep }) => (
    <StepLabel step={step} currentStep={currentStep}>
        <div
            className="tw-text-caption-1 tw-font-bold tw-flex tw-items-center tw-justify-center tw-rounded-full tw-w-[2.8rem] tw-h-[2.8rem] tw-pa-auto tw-bg-primary-800 tw-text-grey-0">
            <Icon />
        </div>
    </StepLabel>
);
