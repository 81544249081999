import { formatMessageTime } from "domain/chat/ChatRoomHelper";
import {
    MemberName,
    MessageAvatar,
    MessageText,
    MessageTextContainer,
    MessageTextWrapper,
    MessageTime,
    MessageWrapper,
    NameAndTimeWrapper
} from "domain/chat/ChatRoomStyle";
import DOMPurify from "dompurify";
import { getFirstLetterAndToUppercase } from "helpers/string/StringHelper";
import React from "react";

export const OthersMessages = ({ message, handleClickMessage }) => {
    return <MessageWrapper>
        <MessageAvatar to={`/user-profile/${message.userId}`}>
            {getFirstLetterAndToUppercase(message.name)}
        </MessageAvatar>
        <MessageTextWrapper>
            {
                message.htmls.map((html, idx) => {
                    return <MessageTextContainer key={`${message.id}-${idx}`}>
                        <MessageText dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(html, { USE_PROFILES: { html: true } })
                        }} onClick={handleClickMessage} />
                    </MessageTextContainer>;
                })
            }
            <NameAndTimeWrapper>
                <MemberName>{message.name}</MemberName>
                <MessageTime>{formatMessageTime(message.created_at)}</MessageTime>
            </NameAndTimeWrapper>
        </MessageTextWrapper>
    </MessageWrapper>;
};
