import styled from "styled-components";

export enum FANTASY_INPUT_STATUS {
    DEFAULT = "default",
    FILL = "fill",
    FOCUS = "focus",
    ERROR = "error",
    DISABLED = "disabled",
}

interface ColorMap {
    borderColor: string,
    iconColor: string,
    labelColor: string,
    textColor: string,
    bgColor?: string
}

export const FantasyInputStatusColorMap: Record<string, ColorMap> = {
    [FANTASY_INPUT_STATUS.DEFAULT]: {
        borderColor: "border-quaternary-1",
        iconColor: "text-text-quaternary-1",
        labelColor: "text-text-quaternary-1",
        textColor: "text-text-quaternary-1"
    },
    [FANTASY_INPUT_STATUS.FILL]: {
        borderColor: "border-tertiary-1",
        iconColor: "text-text-secondary-1",
        labelColor: "text-text-tertiary-1",
        textColor: "text-text-primary-1"
    },
    [FANTASY_INPUT_STATUS.FOCUS]: {
        borderColor: "border-primary-primary",
        iconColor: "text-primary-1",
        labelColor: "text-primary-3",
        textColor: "text-text-primary-1"
    },
    [FANTASY_INPUT_STATUS.ERROR]: {
        borderColor: "border-sys-danger",
        iconColor: "text-sys-danger-1",
        labelColor: "text-sys-danger-1",
        textColor: "text-text-primary-1"
    },
    [FANTASY_INPUT_STATUS.DISABLED]: {
        borderColor: "border-opaque-tertiary",
        iconColor: "text-text-tertiary-1",
        labelColor: "text-text-quaternary-1",
        textColor: "text-text-tertiary-1",
        bgColor: "bg-default-secondary-1"
    },
};

interface FantasyInputWrapperProps {
    $borderColor?: string,
    $iconColor?: string,
    $bgColor?: string
    $isDisabled?: boolean
}

export const FantasyInputWrapper = styled.div.attrs<FantasyInputWrapperProps>(({
                                                                                   $iconColor,
                                                                                   $borderColor,
                                                                                   $bgColor
                                                                               }) => ({
    className: `border d-flex gap-3 align-items-center ${$iconColor} ${$borderColor} ${$bgColor}`
}))`
    border-radius: 1.6rem;
    padding: 0 1.6rem;
    cursor: ${({ $isDisabled }) => $isDisabled ? " not-allowed" : "auto"};
`;

interface FantasyInputStyleProps {
    $textColor?: string,
    $bgColor?: string,
    $isDisabled?: boolean
}

export const FantasyInputStyle = styled.input.attrs<FantasyInputStyleProps>(({ $textColor, $bgColor }) => ({
    className: `form-control w-100 fz-sf-regular-body-1 ${$textColor} ${$bgColor}`,
    placeholder: ""
}))`
  height: 5.4rem;
  padding: 1.6rem 0;
  cursor: ${({ $isDisabled }) => $isDisabled ? " not-allowed" : "auto"};

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }

  &:focus, &:not(:placeholder-shown) {
    padding-top: 2.4rem;
    padding-bottom: 0.8rem;

    & ~ label {
      transform: scale(0.85) translateY(-0.8rem) translateX(0.15rem);
    }
  }
`;

interface FantasyInputLabelProps {
    $labelColor?: string,
}

export const FantasyInputLabel = styled.label.attrs<FantasyInputLabelProps>(({ $labelColor }) => ({
    className: `fz-sf-regular-body-1 ${$labelColor}`
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1.6rem 0;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
`;

