import { UPDATE_LIVE_CHAT_VISIBILITY } from "../../Constants";

export type LiveChatVisibility = "hidden" | "maximized" | "minimized"

const init: { visibility: LiveChatVisibility } = {
    visibility: "hidden",
};

const LiveChatState = (prevState = init, action) => {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_LIVE_CHAT_VISIBILITY:
            return { visibility: payload };
        default:
            return prevState;
    }
};

export default LiveChatState;
