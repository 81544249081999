import React, { useEffect } from "react";
import WithdrawStep4 from "domain/withdraw/WithdrawStep4";
import { useWithdrawContext } from "domain/withdraw/WithdrawProvider";
import { FORM_FIELD_AMOUNT } from "domain/withdraw/hooks/useWithdrawStep2Form";
import { WithdrewResult } from "domain/withdraw/types";
import { useLoadingToggle } from "components/loading/Loading";
import { PaymongoWithdrawFormVo } from "data/vo/payment/PaymongoWithdrawFormVo";
import {
    FORM_FIELD_ACCOUNT_BANK,
    FORM_FIELD_ACCOUNT_NAME,
    FORM_FIELD_ACCOUNT_NUMBER
} from "domain/withdraw/hooks/useWithdrawStep1Form";
import useUpdateWallet from "domain/wallet/useUpdateWallet";
import useFantasyCommand from "hooks/useFantasyCommand";
import { removeAllSpaces } from "helpers/stringFormatHelpers";
import { PaymongoWithdrawRepository } from "data/repository/withdraw/PaymongoWithdrawRepository";


const paymongoWithdrawRepository = new PaymongoWithdrawRepository();

export function WithdrawStep4Container() {
    const withdrawContext = useWithdrawContext();

    const toggleLoading = useLoadingToggle();
    const updateWallet = useUpdateWallet();

    const step1FormValues = withdrawContext.step1Form.getValues();
    const step2FormValues = withdrawContext.step2Form.getValues();

    const formData: PaymongoWithdrawFormVo = {
        amount: step2FormValues[FORM_FIELD_AMOUNT],
        account: {
            bankCode: step1FormValues[FORM_FIELD_ACCOUNT_BANK],
            number: removeAllSpaces(step1FormValues[FORM_FIELD_ACCOUNT_NUMBER]),
            name: step1FormValues[FORM_FIELD_ACCOUNT_NAME],
        }
    };

    const handleWithdraw = async (): Promise<WithdrewResult> => {
        toggleLoading(true);

        try {
            await paymongoWithdrawRepository.withdrawBegin(formData);
            await updateWallet();

            return WithdrewResult.PROGRESS;
        } catch (e) {
            return WithdrewResult.DECLINED;
        } finally {
            toggleLoading(false);
        }
    };

    const submitCommand = useFantasyCommand(handleWithdraw, { errorHandle: false });
    const withdrewResult = submitCommand.data;

    useEffect(() => {
        submitCommand.mutate();
    }, []);

    const handleResetAndBackToFirstStep = () => {
        withdrawContext.step1Form.reset();
        withdrawContext.step2Form.reset();
        withdrawContext.handleGoToFirstStep();
    };

    if (!withdrewResult) return null;

    return <WithdrawStep4
        withdrewResult={withdrewResult}
        sendToInfo={withdrawContext.sendToInfo}
        withdrawAmountInfo={withdrawContext.withdrawAmountInfos}
        onResetAndBackToFirstStep={handleResetAndBackToFirstStep}
        onBackToGame={withdrawContext.handleGoBack} />;
}
