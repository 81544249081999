import clsx from "clsx";
import { SortingIcon } from "domain/match/components/stats/PlayerStatsStyle";
import { sortDownIcon, sortUpIcon } from "helpers/IconHelper";
import { DESC, SortingDirection } from "helpers/SortingHelper";
import React, { useMemo } from "react";
import { PlayerHead, PlayerHeadCol, PlayerRow, SortButton } from "../ChoosePlayerStyle";


interface ChoosePlayerHeaderProps {
    id?: string
    sortKey: string
    orderBy: SortingDirection
    handleSort: (key: string) => void
    headers: Array<{ key: string, text: string }>
}

const ChoosePlayerHeader: React.FC<ChoosePlayerHeaderProps> = ({
                                                                   id,
                                                                   sortKey,
                                                                   orderBy,
                                                                   handleSort,
                                                                   headers
                                                               }) => {
    const [firstHeader, ...restHeaders] = headers;

    const textAppearance = (_sortKey: string) => {
        return sortKey === _sortKey ? "text-secondary-2" : "text-light-secondary";
    };

    const iconAppearance = (_sortKey: string) => {
        return sortKey === _sortKey ? "visible" : "invisible";
    };

    const sortIcon = useMemo(() => {
        return orderBy === DESC ? sortDownIcon : sortUpIcon;
    }, [orderBy]);

    return (
        <PlayerHead id={id}>
            <PlayerRow>
                <PlayerHeadCol className="col-7">
                    <SortButton onClick={() => handleSort(firstHeader.key)}>
                        <div className={`fz-sf-regular-body-1 ${textAppearance(firstHeader.key)}`}>
                            {firstHeader.text}
                        </div>
                        <SortingIcon className={iconAppearance(firstHeader.key)} $bgImg={sortIcon} />
                    </SortButton>
                </PlayerHeadCol>
                <div className="col d-flex text-nowrap">
                    {
                        restHeaders.map(({ key, text }) => {
                            return <PlayerHeadCol key={key}
                                                  className={clsx("text-end", "col-5")}>
                                <SortButton className="ms-auto" onClick={() => handleSort(key)}>
                                    <div className={`fz-sf-regular-subhead ${textAppearance(key)}`}>
                                        {text}
                                    </div>
                                    <SortingIcon className={iconAppearance(key)} $bgImg={sortIcon} />
                                </SortButton>
                            </PlayerHeadCol>;
                        })
                    }
                </div>
            </PlayerRow>
        </PlayerHead>
    );
};


export default ChoosePlayerHeader;

