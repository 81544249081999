import Badge, { BADGE_PATTERN, BADGE_SIZE, BADGE_VARIANTS } from "designToken/badge/Badge";
import { orderBy } from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { SquadPlayer } from "types/player/Player";
import { MyTeamVO } from "types/team/MyTeam";

interface InYourTeamsProps {
    myTeams: MyTeamVO[];
    player: SquadPlayer;
}

export const InYourTeams: React.FC<InYourTeamsProps> = ({
                                                            myTeams,
                                                            player
                                                        }) => {
    const inMyTeams = myTeams.filter(team => team.players.some(p => p.id === player.id));
    return <div>
        <div className="fz-sf-bold-body-1 text-text-primary-1 mb-2">
            <FormattedMessage id="in_your_teams_title" />
        </div>
        <div className="fz-sf-regular-footnote text-light-secondary d-flex gap-2 flex-wrap"
             data-testid="in-your-teams">
            {
                inMyTeams.length > 0
                    ? orderBy(inMyTeams, "id").map(team => {
                        return <Badge key={team.id}
                                      size={BADGE_SIZE.SM}
                                      variant={BADGE_VARIANTS.LIGHT}
                                      pattern={BADGE_PATTERN.ROUNDED}>
                            {team.name}
                        </Badge>;
                    })
                    : <FormattedMessage id="player_info_no_add_player_in_any_team" />
            }
        </div>
    </div>;
};
