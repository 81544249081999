import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormField, FormItem } from "components/ui/form";
import { FantasyTextField } from "designToken/textFields/FantasyTextField";
import { DEFAULT_IMG_MAX_FILE_SIZE_MB, ImageUploader } from "domain/kyc/components/ImageUploader";
import { DocumentTypeSelector } from "domain/kyc/pages/kycVerificationForm/components/DocumentTypeSelector";
import { FormErrorMessage } from "domain/kyc/pages/kycVerificationForm/components/FormErrorMessage";
import { KycVerificationProps } from "domain/kyc/pages/kycVerificationForm/type/kyc_verification_props";
import { KycVerificationFormActions } from "domain/kyc/pages/kycVerificationForm/components/KycVerificationFormActions";
import { Icon } from "helpers/IconHelper";
import React from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { z, ZodString, ZodType, ZodTypeDef } from "zod";
import { ZodRawShape } from "zod/lib/types";
import { KycVerificationVo } from "domain/kyc/types";

type Step3FormInput = Pick<
    KycVerificationVo,
    "documentType" | "idNumber" | "idFrontSideImage" | "idBackSideImage">;

interface Step3FormSchema extends Record<keyof Step3FormInput, ZodString | ZodType<File, ZodTypeDef, File>>, ZodRawShape {
}

export const Step3: React.FC<KycVerificationProps> = ({
                                                          kycData,
                                                          onBack,
                                                          onNext
                                                      }) => {
    const intl = useIntl();

    const frontImgErrorMessage = intl.formatMessage({ id: "required_error_upload_front_side_id" });
    const backImgErrorMessage = intl.formatMessage({ id: "required_error_upload_back_side_id" });

    const resolver = zodResolver(z.object<Step3FormSchema>({
        documentType: z.string({}).min(1,),
        idNumber: z.string().min(1,),
        idFrontSideImage: z.instanceof(File, {
            message: frontImgErrorMessage
        }),
        idBackSideImage: z.instanceof(File, {
            message: backImgErrorMessage
        }),
    }));

    const form = useForm<Step3FormInput>({
        resolver: resolver,
        defaultValues: kycData as Step3FormInput,
    });
    const {
        formState: {
            errors
        },
        control,
        getValues,
        setValue,
        setError,
        handleSubmit,
    } = form;

    const validateAndNext = handleSubmit(onNext);

    const isIdNumberValidateError = errors.documentType || errors.idNumber;

    return (
        <Form {...form}>
            <div data-testid="kyc-verification-step3" className={"tw-flex-col tw-flex tw-gap-[2.8rem]"}>
                <div>
                    <h2 className={"tw-text-subtitle-1 tw-pb-1"}>
                        <FormattedMessage id={"kyc_verification_form_title_id_verification"} />
                    </h2>
                    <p className={"tw-text-subtitle-2"}>
                        <FormattedMessage id={"kyc_verification_form_description_id_verification"} />
                    </p>
                </div>
                <div className={"tw-flex tw-flex-col"}>
                    <h3 className={"tw-text-body-1 tw-pb-[4px]"}>
                        <FormattedMessage id={"label_enter_your_id_number"} />
                        *
                    </h3>
                    <div className={"tw-flex tw-gap-[4px]"}>
                        <div className={"tw-flex-grow"}>
                            <DocumentTypeSelector />
                        </div>
                        <div className={"tw-w-[20rem]"}>
                            <FantasyTextField
                                name={"idNumber"}
                                showErrorMessage={false}
                                inputProps={{
                                    placeholder: intl.formatMessage({
                                        id: "placeholder_id_number"
                                    }),
                                }} />
                        </div>
                    </div>
                    {isIdNumberValidateError && (
                        <div
                            className={"tw-flex tw-gap-2 tw-items-center tw-pt-1"}>
                            <Icon.FormMessageError className={"tw-text-error-700"} />
                            <p className={"tw-text-error-700 tw-text-caption-1"}>
                                <FormattedMessage id={"required_error_id_number"} />
                            </p>
                        </div>
                    )}
                </div>
                <FormField control={control} name={"idFrontSideImage"} render={() => (
                    <FormItem className={"tw-flex tw-flex-col"}>
                        <ImageUploader
                            name={"idFrontSideImage"}
                            initFile={getValues("idFrontSideImage")}
                            label={<FormattedMessage id={"placeholder_upload_front_side_id"} />}
                            isError={!!errors.idFrontSideImage}
                            onFileChanged={(e) => {
                                setValue("idFrontSideImage", e, { shouldValidate: true });
                            }}
                            onSetError={() => {
                                setError("idFrontSideImage", {
                                    message: intl.formatMessage({
                                        id: "invalid_type_error_upload_image"
                                    }, {
                                        fileMaxSizeMB: DEFAULT_IMG_MAX_FILE_SIZE_MB
                                    })
                                });
                            }} />
                        <FormErrorMessage />
                    </FormItem>
                )} />
                <FormField control={control} name={"idBackSideImage"} render={() => (
                    <FormItem className={"tw-flex tw-flex-col"}>
                        <ImageUploader
                            name={"idBackSideImage"}
                            initFile={getValues("idBackSideImage")}
                            label={<FormattedMessage id={"placeholder_upload_back_side_id"} />}
                            isError={!!errors.idBackSideImage}
                            onFileChanged={(e) => {
                                setValue("idBackSideImage", e, { shouldValidate: true });
                            }}
                            onSetError={() => {
                                setError("idBackSideImage", {
                                    message: intl.formatMessage({
                                        id: "invalid_type_error_upload_image"
                                    }, {
                                        fileMaxSizeMB: DEFAULT_IMG_MAX_FILE_SIZE_MB
                                    })
                                });
                            }}
                        />
                        <FormErrorMessage />
                    </FormItem>
                )} />
                <KycVerificationFormActions onBack={onBack} onNext={validateAndNext} />
            </div>
        </Form>
    );
};
