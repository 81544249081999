import { isMatchUpcoming } from "helpers/match/MatchHelper";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import MatchStatusWithDot from "../../../domain/match/components/status/MatchStatusWithDot";
import { MATCH_STATUS_FROM_API } from "../../../types/match/Match";
import UpcomingCountDown from "../../countdown/UpcomingCountDown";
import MatchInfo from "./MatchInfo";
import { JoinableNavbarWrapper, StyledCountDown } from "./NavbarStyle";

const JoinableNavbarMatchInfo = ({ match = {}, handleTimeout }) => {
    const { matchStartsAt } = match;
    const [matchStatus, setMatchStatus] = useState(match.matchStatus);
    const { SettingsState } = useSelector(store => store);

    return <JoinableNavbarWrapper>
        <MatchInfo data={match} />
        <StyledCountDown>
            {
                isMatchUpcoming(matchStatus)
                    ? <UpcomingCountDown matchStartsAt={matchStartsAt}
                                         handleTimeout={() => {setMatchStatus(MATCH_STATUS_FROM_API.KICKOFF);}} />
                    : <div className="d-flex ms-2">
                        <MatchStatusWithDot matchStatus={matchStatus} /> &nbsp;
                        <UpcomingCountDown matchStartsAt={matchStartsAt + SettingsState.match.kickoffDurationMilliseconds}
                                           handleTimeout={handleTimeout} />
                    </div>
            }
        </StyledCountDown>
    </JoinableNavbarWrapper>;
};

export default JoinableNavbarMatchInfo;

JoinableNavbarMatchInfo.propTypes = {
    match: PropTypes.shape({
        matchId: PropTypes.string,
        tournament: PropTypes.string,
        tournamentPeriodStr: PropTypes.string,
        venue: PropTypes.string
    }).isRequired,
    handleTimeout: PropTypes.func
};
