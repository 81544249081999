import { cn } from "helpers/cn";
import React from "react";

interface TopNavbarProps {
    className?: string;
    children: React.ReactNode;
}

export const LightTopNavbar = React.forwardRef((props: TopNavbarProps, ref?: React.ForwardedRef<HTMLDivElement>) => {
    return (
        <nav {...props} ref={ref}
             className={cn("top-navbar tw-pt-[3.2rem] tw-pb-2 tw-px-4 tw-bg-grey-50", props.className)}>
            {props.children}
        </nav>
    );
});

export const DarkTopNavbar = React.forwardRef((props: TopNavbarProps, ref?: React.ForwardedRef<HTMLDivElement>) => {
    return (
        <nav {...props} ref={ref}
             className={cn("top-navbar tw-pt-8 tw-pb-2 tw-px-4 tw-bg-primary-900", props.className)}>
            {props.children}
        </nav>
    );
});
