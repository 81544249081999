import { fetchCoinAmountUrl } from "apis/ApiUrlConfig";
import { ajax } from "apis/ApiUtils";
import { CoinProductDto } from "data/dto/coin/CoinProductDto";

export const fetchCoinProducts = async (): Promise<CoinProductDto[]> => {
    const { data: { data } } = await ajax<CoinProductDto[]>({
        url: fetchCoinAmountUrl
    });
    return data;
};
