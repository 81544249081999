import { SubpageTopNavbar } from "components/container/navbar/SubpageTopNavbar";
import DailyQuests from "domain/quest/DailyQuests";
import QuestPrizeClaimed from "domain/quest/QuestPrizeClaimed";
import { QuestProvider } from "domain/quest/QuestProvider";
import React from "react";
import { FormattedMessage } from "react-intl";


const Quest = () => {
    return <QuestProvider>
        <SubpageTopNavbar title={<FormattedMessage id="daily_quest_page_title" />} />

        <QuestPrizeClaimed />

        <DailyQuests />
    </QuestProvider>;
};

export default Quest;
