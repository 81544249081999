import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

export const CurrentPageWrapper = styled.div.attrs({})`
  padding: 2.4rem 1.6rem;
`;
export const StyledContainer = styled(Container).attrs({})`
  padding: .8rem 0;
`;
export const StyledRow = styled(Row).attrs({})`
  padding: .8rem 0;
`;
export const TransferWrapper = styled(StyledContainer).attrs({
    className: "tw-border-system-labelLightQuaternary"
})`
    border-width: 0 0 1px 0;
    border-style: solid;
`;
export const Title = styled(Col).attrs({
    className: "fz-sf-regular-subhead tw-text-system-labelLightSecondary"
})`
`;
export const Value = styled(Col).attrs({
    className: "d-flex justify-content-end align-items-center tw-text-system-labelLightPrimary"
})`
`;
export const AccumulationPeriodWrapper = styled(StyledContainer).attrs({
    className: "tw-border-system-labelLightQuaternary"
})`
    border-width: 0 0 1px 0;
    border-style: solid;

`;
export const CurrentApWrapper = styled(StyledContainer).attrs({
    className: "tw-border-system-labelLightQuaternary"
})`
    border-width: 0 0 1px 0;
    border-style: solid;
`;
export const ApAddDepositWrapper = styled(StyledContainer).attrs({
    className: "tw-border-system-labelLightQuaternary"
})`
    border-width: 0 0 1px 0;
    border-style: solid;
`;

export const ApJoinContestWrapper = styled(StyledContainer).attrs({
    className: "tw-border-system-labelLightQuaternary"
})`
    border-width: 0 0 1px 0;
    border-style: solid;
`;
export const ApRemainingWrapper = styled(StyledContainer).attrs({})``;
export const ActivityPoint = styled.div.attrs({
    className: "fz-sf-bold-body-1 tw-text-system-special-ap"
})``;

export const Text = styled.div.attrs({
    className: "fz-sf-regular-body-1 me-1"
})``;
