import React, { useEffect, useState } from "react";
import FantasyBasicPopup, { POPUP_SIZE } from "components/popup/FantasyBasicPopup";
import { FormProvider, useForm } from "react-hook-form";
import { FantasyTextField, TEXT_FIELD_VARIANT } from "designToken/textFields/FantasyTextField";
import { BUTTON_PATTERN, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useIntl } from "react-intl";
import { FantasySelect } from "designToken/textFields/FantasySelect";
import { responsibleGamingGiftImg } from "helpers/IconHelper";
import { isOtpMobileNumber } from "helpers/RegexHelper";
import moment from "moment";
import { BirthFormField } from "domain/responsibleGaming/components/BirthFormField";
import {
    isDataNotFound,
    isInvalidResponsibleGamingRegistrationMobileNumber,
    isInvalidResponsibleGamingRegistrationUserAge,
    isOtpMobileInUse
} from "helpers/ErrorHelper";
import useHandleApiError, { ERROR_HANDLER_FLOW } from "hooks/useHandleApiError";
import { useAppPopups } from "domain/popup/AppPopupsProvider";
import { useResponsibleGamingRegistration } from "domain/responsibleGaming/hooks/UseResponsibleGamingRegistration";
import { ApiError } from "apis/Api";
import FeatureConfigPopupWrapper from "domain/popup/popupWrappers/FeatureConfigPopupWrapper";
import { getRightSubString } from "helpers/string/StringHelper";

const MOBILE_NUMBER_LENGTH = 10;

export interface ResponsibleGamingRegistrationForm {
    firstName: string;
    lastName: string;
    birth: {
        yearOfBirth: string;
        monthOfBirth: string;
        dayOfBirth: string;
    }
    countryCode: string;
    mobileNumber: string;
}

const responsibleGamingRegistrationFormSchema = (formatMessage: (param: { id: string }) => string) => {
    return zodResolver(z.object({
            firstName: z.string().min(1, {
                message: formatMessage({ id: "required_error_first_name" })
            }),
            lastName: z.string().min(1, {
                message: formatMessage({ id: "required_error_last_name" })
            }),
            birth: z.object({
                yearOfBirth: z.coerce.number().min(1900).max(9999),
                monthOfBirth: z.coerce.number().min(1).max(12),
                dayOfBirth: z.coerce.number().min(1).max(31),
            }),
            countryCode: z.string(),
            mobileNumber: z.string()
                .min(1, {
                    message: formatMessage({ id: "required_error_mobile_number" })
                })
                .refine(isOtpMobileNumber, formatMessage({ id: "otp_error_mobile_number_incorrect" }))
        }).superRefine(({ birth }, ctx) => {
            const birthDayMoment = moment({
                year: birth.yearOfBirth,
                month: birth.monthOfBirth - 1,
                day: birth.dayOfBirth
            });

            if (!birthDayMoment.isValid()) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: formatMessage({ id: "required_error_date_of_birth" }),
                    path: ["birth"]
                });

                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ["birth", "yearOfBirth"]
                });
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ["birth", "monthOfBirth"]
                });
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ["birth", "dayOfBirth"]
                });
            }

            if (moment().diff(birthDayMoment, "years") < 21) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: formatMessage({ id: "required_error_age_under_21" }),
                    path: ["birth"]
                });

                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ["birth", "yearOfBirth"]
                });
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ["birth", "monthOfBirth"]
                });
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ["birth", "dayOfBirth"]
                });
            }
        })
    );
}
const ResponsibleGamingRegistrationPopup = () => {
    const intl = useIntl();
    const handleApiError = useHandleApiError();
    const popups = useAppPopups();
    const [showRegistration, setShowRegistration] = useState(false);
    const {
        responsibleGamingRegistration,
        addRegistration
    } = useResponsibleGamingRegistration({
        onQueryFail: (error) => {
            if (isDataNotFound(error)) {
                setShowRegistration(true);
                return ERROR_HANDLER_FLOW.STOP;
            }

            return ERROR_HANDLER_FLOW.COUNTINUE;
        }
    });

    useEffect(() => {
        if (responsibleGamingRegistration) {
            popups.done();
        }
    }, [responsibleGamingRegistration]);

    const form = useForm<ResponsibleGamingRegistrationForm>({
        resolver: responsibleGamingRegistrationFormSchema(({ id }) => intl.formatMessage({ id })),
        defaultValues: {
            firstName: "",
            lastName: "",
            birth: {
                yearOfBirth: "",
                monthOfBirth: "",
                dayOfBirth: "",
            },
            countryCode: "+63",
            mobileNumber: "",
        },
        mode: "onChange"
    });

    const handleSubmit = form.handleSubmit(async (data) => {
        try {
            await addRegistration({
                firstName: data.firstName,
                lastName: data.lastName,
                yearOfBirth: parseInt(data.birth.yearOfBirth, 10),
                monthOfBirth: parseInt(data.birth.monthOfBirth, 10),
                dayOfBirth: parseInt(data.birth.dayOfBirth, 10),
                phoneNumber: getRightSubString(data.mobileNumber, MOBILE_NUMBER_LENGTH),
            })
        } catch (error) {
            await handleApiError(error as ApiError, (error) => {
                if (isOtpMobileInUse(error) || isInvalidResponsibleGamingRegistrationMobileNumber(error)) {
                    form.setError("mobileNumber", {
                        message: intl.formatMessage({ id: "error_mobile_number_in_use" })
                    });
                    return ERROR_HANDLER_FLOW.STOP;
                }

                if (isInvalidResponsibleGamingRegistrationUserAge(error)) {
                    form.setError("birth", {
                        message: intl.formatMessage({ id: "required_error_age_under_21" })
                    });

                    form.setError("birth.yearOfBirth", {})
                    form.setError("birth.monthOfBirth", {})
                    form.setError("birth.dayOfBirth", {})
                    return ERROR_HANDLER_FLOW.STOP;
                }

                return ERROR_HANDLER_FLOW.COUNTINUE;
            });
        }
    });

    return <FantasyBasicPopup
        show={showRegistration}
        onHide={handleSubmit}
        dismissible={true}
        popupSize={POPUP_SIZE.LG}
        imageSection={<img src={responsibleGamingGiftImg} alt="responsible-gaming-gift" className="tw-h-[9rem]" />}
        content={
            <FormProvider {...form}>
                <div className="tw-flex tw-flex-col tw-gap-3">

                    <FantasyTextField
                        name="firstName"
                        variant={TEXT_FIELD_VARIANT.STANDARD}
                        inputProps={{
                            type: "text",
                            placeholder: intl.formatMessage({ id: "label_first_name" }),
                            className: "tw-placeholder-grey-600",
                            autoComplete: "given-name",
                        }}
                    />


                    <FantasyTextField
                        name="lastName"
                        variant={TEXT_FIELD_VARIANT.STANDARD}
                        inputProps={{
                            type: "text",
                            placeholder: intl.formatMessage({ id: "label_last_name" }),
                            className: "tw-placeholder-grey-600",
                            autoComplete: "family-name",
                        }}
                    />

                    <BirthFormField />

                    <div className="tw-flex tw-gap-2">
                        <FantasySelect
                            name="countryCode"
                            variant={TEXT_FIELD_VARIANT.STANDARD}
                            inputProps={{
                                className: "tw-placeholder-grey-600 tw-w-20 tw-flex-shrink-0 tw-bg-grey-100",
                            }}
                            options={[{
                                label: "+63",
                                value: "+63"
                            }]}
                            showErrorMessage={false}
                            disabled
                        />

                        <div className="tw-flex-1">
                            <FantasyTextField
                                name="mobileNumber"
                                variant={TEXT_FIELD_VARIANT.STANDARD}
                                inputProps={{
                                    type: "text",
                                    placeholder: intl.formatMessage({ id: "label_mobile_number" }),
                                    className: "tw-placeholder-grey-600",
                                }}
                            />
                        </div>
                    </div>

                </div>
            </FormProvider>
        }
        texts={[{
            text: intl.formatMessage({ id: "responsible_gaming_dialog_description" }),
            textClassName: "tw-text-grey-1000 tw-text-h6 tw-font-bold",
        }]}
        buttonSetting={{
            buttonPattern: BUTTON_PATTERN.rounded,
            confirmButtonVariant: NEW_BUTTON_VARIANTS.tertiaryLight,
            confirmButtonText: intl.formatMessage({ id: "label_start_play" }),
            onConfirm: handleSubmit,
            cancelButton: false
        }}
    />;
};

export default () => {
    return <FeatureConfigPopupWrapper getFeature={(config) => config.kyc}>
        <ResponsibleGamingRegistrationPopup />
    </FeatureConfigPopupWrapper>;
}
