import { NINE_CAT_STAT } from "../data/ComponentTypes";
import { StatToPlayerIdMap } from "../data/StatToPlayerIdMap";

export enum SWAP_ACTION {
    DO_NOTHING,
    SWAP_STAT,
    SWAP_PLAYER_BY_STAT,
    SWAP_STAT_BY_PLAYER_ID,
    SWAP_PLAYER_ID,
}

export class SwapCardAction {
    private prevPlayerId: string | undefined;
    private prevStat: string | undefined;
    private nextStat: string | undefined;
    private nextPlayerId: string | undefined;

    constructor(param: {
        prevPlayerId?: string | undefined;
        nextPlayerId?: string | undefined;
        prevStat?: NINE_CAT_STAT | undefined
        nextStat?: string;
    }) {
        this.prevPlayerId = param.prevPlayerId;
        this.nextPlayerId = param.nextPlayerId;
        this.prevStat = param.prevStat;
        this.nextStat = param.nextStat;
    }

    updateMap(_map: Record<string, string>) {
        const statToPlayerIdMap = new StatToPlayerIdMap(_map);
        switch (this.getAction()) {
            case SWAP_ACTION.SWAP_STAT:
                return statToPlayerIdMap.exchangeStat(this.prevStat!, this.nextStat!).getMap();
            case SWAP_ACTION.SWAP_PLAYER_BY_STAT:
                return statToPlayerIdMap.exchangePlayerIdByStat(this.prevPlayerId!, this.nextStat!).getMap();
            case SWAP_ACTION.SWAP_STAT_BY_PLAYER_ID:
                return statToPlayerIdMap.exchangeStatByPlayerId(this.prevStat!, this.nextPlayerId!).getMap();
            case SWAP_ACTION.SWAP_PLAYER_ID:
                return statToPlayerIdMap.exchangePlayerId(this.prevPlayerId!, this.nextPlayerId!).getMap();
            default:
                return statToPlayerIdMap.getMap();
        }
    }

    getAction() {
        if (this.prevStat && this.nextStat) {
            return SWAP_ACTION.SWAP_STAT;
        }
        if (this.prevPlayerId && this.nextStat) {
            return SWAP_ACTION.SWAP_PLAYER_BY_STAT;
        }
        if (this.prevStat && this.nextPlayerId) {
            return SWAP_ACTION.SWAP_STAT_BY_PLAYER_ID;
        }
        if (this.prevPlayerId && this.nextPlayerId) {
            return SWAP_ACTION.SWAP_PLAYER_ID;
        }
        return SWAP_ACTION.DO_NOTHING;
    }
}
