import { fetchResponsibleGamingRegistrationUrl, } from "apis/ApiUrlConfig";
import { ajax } from "apis/ApiUtils";
import { ResponsibleGamingRegistrationDto } from "data/dto/responsibleGaming/ResponsibleGamingRegistrationDto";

export const fetchResponsibleGamingRegistration = async () => {
    const response = await ajax<ResponsibleGamingRegistrationDto>({ url: fetchResponsibleGamingRegistrationUrl });
    return response.data.data;
};

export const addResponsibleGamingRegistration = async (request: {
    firstName: string,
    lastName: string,
    yearOfBirth: number,
    monthOfBirth: number,
    dayOfBirth: number,
    phoneNumber: string,
}) => {
    await ajax({
        url: fetchResponsibleGamingRegistrationUrl, method: "POST", data: {
            ...request,
            countryCode: "+63"
        }
    });
};
