import { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
    updateSelectTeamPanelConfig,
    updateSelectTeamPanelData,
    updateSelectTeamPanelShow
} from "store/actions/Actions";
import { SelectTeamPanelConfig, SelectTeamPanelDate } from "../../store/reducer/selectTeamPanel/type";

const useSelectTeamPanel = (config?: Partial<SelectTeamPanelConfig>, initData?: Partial<SelectTeamPanelDate & {
    show: boolean
}>) => {
    const dispatch = useDispatch();

    const updateData = useCallback((data: Partial<SelectTeamPanelDate>) => {
        dispatch(updateSelectTeamPanelData(data));
    }, [dispatch]);

    const open = useCallback((data?: Partial<SelectTeamPanelDate>) => {
        window.history.replaceState({}, document.title);
        if (data) {
            updateData(data);
        }
        dispatch(updateSelectTeamPanelShow(true));
    }, [dispatch, updateData]);

    const close = useCallback(() => {
        dispatch(updateSelectTeamPanelShow(false));
        dispatch(updateSelectTeamPanelData({
            autoOpenConfirmation: false,
            selectedTeamIds: []
        }));
    }, [dispatch]);

    useEffect(() => {
        if (config) {
            dispatch(updateSelectTeamPanelConfig(config));
        }
        if (initData) {
            const { show, ...data } = initData;
            if (show) {
                open();
            }

            if (data) {
                updateData(data);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return useMemo(() => ({
        open,
        close,
        updateData
    }), [
        open,
        close,
        updateData]);
};

export default useSelectTeamPanel;
