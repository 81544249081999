import { Contest } from "apis/match/type";
import { IntlShape } from "react-intl/lib";
import { ContestTypeTitleProps } from "./ContestTypeSeparator";
import {
    contestCoinNormalIcon,
    contestPracticeIcon,
    contestPrizeHunterIcon,
    contestPromotionalIcon,
    contestRubyNormalIcon
} from "helpers/IconHelper";
import getPublicContestGroupType, { PUBLIC_CONTEST_GROUP_TYPE } from "./getPublicContestGroupType";
import { Link } from "react-router-dom";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";

function getContestTypeTitleProps(contest: Contest, intl: IntlShape): ContestTypeTitleProps {
    const format = (id: string) => intl.formatMessage({ id });

    switch (getPublicContestGroupType(contest)) {
        case PUBLIC_CONTEST_GROUP_TYPE.PROMOTIONAL:
            return ({
                title: format("contest_promotional_title"),
                subtitle: format("contest_promotional_subtitle"),
                iconImgSrc: contestPromotionalIcon
            });

        case PUBLIC_CONTEST_GROUP_TYPE.PRIZE_HUNTER:
            return ({
                title: format("contest_prize_hunter_title"),
                subtitle: format("contest_prize_hunter_subtitle"),
                iconImgSrc: contestPrizeHunterIcon
            });

        case PUBLIC_CONTEST_GROUP_TYPE.PRACTICE:
            return ({
                title: format("contest_practice_title"),
                subtitle: format("contest_practice_subtitle"),
                iconImgSrc: contestPracticeIcon
            });

        case PUBLIC_CONTEST_GROUP_TYPE.RUBY_NORMAL:
            return ({
                title: format("contest_ruby_normal_title"),
                subtitle: <Link
                    className="tw-cursor-pointer"
                    to={ROUTER_PATH_CONFIG.task.index}
                >
                    {format("contest_ruby_normal_subtitle")}
                </Link>,
                iconImgSrc: contestRubyNormalIcon
            });

        default:
            return ({
                title: format("contest_deposit_normal_title"),
                subtitle: format("contest_deposit_normal_subtitle"),
                iconImgSrc: contestCoinNormalIcon
            });
    }
}

export default getContestTypeTitleProps;
