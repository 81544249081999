import { awardBackground, awardRubyIcon } from "helpers/IconHelper";
import styled from "styled-components";

const AwardRuby = () => {

    return <CardImg>
        <img src={awardRubyIcon} alt="awardRubyIcon" />
    </CardImg>;
};

const CardImg = styled.div.attrs({
    className: "card-img-top d-flex align-items-center justify-content-center bg-gray-2"
})`
    margin-bottom: -1.2rem;
    padding-top: 4.2rem;
    padding-bottom: 1.2rem;
    background: url(${awardBackground}) no-repeat center;
`;

export default AwardRuby;
