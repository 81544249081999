import { TeamCard } from "components/card/TeamCardStyle";
import TeamCardContent, { TeamCardBodyType } from "components/team/TeamCardContent";
import { TeamName, TeamNameWrapper } from "components/team/TeamCardStyle";
import TeamPreview from "containers/teamPreview";
import usePlayerInfoDialog from "domain/team/playerInfoDialog/PlayerInfoDialog";
import { PlayerInfoDialogFooterType } from "domain/team/playerInfoDialog/PlayerInfoDialogFooter";
import { PlayerInfoDialogHeaderType } from "domain/team/playerInfoDialog/PlayerInfoDialogHeader";
import React from "react";
import { BOTTOM_SLOT_TYPE } from "store/reducer/teamPreview/types";
import styled from "styled-components";
import { MyTeamCardWrapper } from "./MyTeamStyle";

const MyTeamContentWrapper = styled.div`
    padding: 1.6rem;
`;

const StartedMatchMyTeams = ({ sport, myTeams, match }) => {
    const {
        PlayerInfoDialog,
        handlePlayerInfoDialogOpen
    } = usePlayerInfoDialog(PlayerInfoDialogHeaderType.POINT, PlayerInfoDialogFooterType.ONLY_CLOSE);

    const teamPreview = TeamPreview.useTeamPreview({
        sport,
        onAvatarClick: handlePlayerInfoDialogOpen,
        bottomSlotType: BOTTOM_SLOT_TYPE.TEAMS
    });

    const handlePreviewTeam = (team) => {
        teamPreview.updateCurrentTeam(team);
        teamPreview.open();
    };

    return <MyTeamContentWrapper>
        {
            myTeams.map(team => <MyTeamCardWrapper key={team.id}>
                <TeamCard onClick={() => handlePreviewTeam(team)}>
                    <TeamCardContent team={team} bodyType={TeamCardBodyType.POINTS} matchStatus={match.matchStatus}>
                        <TeamNameWrapper>
                            <TeamName>{team.name}</TeamName>
                        </TeamNameWrapper>
                    </TeamCardContent>
                </TeamCard>
            </MyTeamCardWrapper>)
        }

        <PlayerInfoDialog players={teamPreview.players}
                          myTeams={myTeams}
                          match={match} />
    </MyTeamContentWrapper>;
};

export default StartedMatchMyTeams;
