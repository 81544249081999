import clsx from "clsx";
import { getSportConfig } from "domain/SportConfig";
import { ScoringRowWrapper, ScoringTitleWrapper } from "domain/team/playerInfoDialog/scoringTable/ScoringTableStyle";
import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { SPORT } from "types/sports/Sport";

interface ScoringProps {
    sport: SPORT,
    children?: ReactNode
}

export const ScoringTable: React.FC<ScoringProps> = ({ sport, children }) => {

    const { scoring } = getSportConfig(sport);

    if (scoring.length === 0) {
        return null;
    }

    return (
        <div>
            {children}
            <div data-testid="scoring-table">
                <ScoringTitleWrapper>
                    <div className="col fz-sf-regular-body-1 text-text-secondary-1">
                        <FormattedMessage id="label_player_stat" />
                    </div>
                    <div className="col fz-sf-regular-body-1 text-text-secondary-1 text-end">
                        <FormattedMessage id="label_score"></FormattedMessage>
                    </div>
                </ScoringTitleWrapper>
                <ScoringRowWrapper>
                    {
                        scoring.map(({ key, label, point }, index) => {
                            const className = clsx("py-3 d-flex", {
                                "border-light-primary border-bottom": index !== scoring.length - 1,
                            });

                            return <div key={key}
                                        className={className}>
                                <div className="col text-light-primary fz-sf-regular-subhead">{label}</div>
                                <div className="col text-light-primary fz-sf-regular-body-1 text-end">{point}</div>
                            </div>;
                        })
                    }
                </ScoringRowWrapper>
            </div>
        </div>);
};
