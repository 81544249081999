import { JoinedCard } from "./JoinedCard";
import { SelectedCard } from "./SelectedCard";
import { DisabledCard } from "./DisabledCard";
import { UnselectedCard } from "./UnselectedCard";
import React from "react";

export const StatusTeamCardFactory = ({
                                          team,
                                          selectedTeamIds,
                                          joinedTeamIds,
                                          maxUserEntries,
                                          onTeamSelected,
                                          onTeamUnselected,
                                          matchStatus,
                                      }) => {

    if (joinedTeamIds.includes(team.id)) {
        return <JoinedCard team={team} matchStatus={matchStatus} />;
    }

    if (selectedTeamIds.includes(team.id)) {
        return <SelectedCard team={team} onTeamUnselected={onTeamUnselected} matchStatus={matchStatus} />;
    }

    if (selectedTeamIds.length + joinedTeamIds.length >= maxUserEntries) {
        return <DisabledCard team={team} matchStatus={matchStatus} />;
    }

    return <UnselectedCard team={team} onTeamSelected={onTeamSelected} matchStatus={matchStatus} />;
};