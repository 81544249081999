import { MdOutlineErrorOutline } from "react-icons/md";
import styled from "styled-components";

export const ContestCodePanelHeader = styled.div.attrs({
    className: "w-100 d-flex justify-content-between align-items-center"
})`
  padding: 2.4rem 1.6rem 0;
`;
export const ContestCodePanelTitle = styled.div.attrs({
    className: "fz-sf-bold-title-1 text-primary-1"
})``;

export const ContestCodePanelBody = styled.form.attrs({
    className: "overflow-auto"
})`
  padding: 1.6rem 1.6rem 2.4rem;

  &.has-error {
    .error-label {
      opacity: 1;
      visibility: visible;
    }

    .error-icon {
      display: block;
    }

    .contest-input {
      border-color: var(--border-sys-danger) !important;
      color: var(--danger);
    }
  }

`;
export const ContestCodeInput = styled.input.attrs({
    className: "form-control border-light-tertiary fz-sf-regular-body-1 contest-input"
})`
  border: solid 0.1rem;
  border-radius: 0.4rem;
  padding: 0.8rem 3.2rem 0.8rem 1.6rem;
`;

export const ErrorIcon = styled(MdOutlineErrorOutline).attrs({
    size: "1.6rem",
    className: "position-absolute top-50 translate-middle-y text-sys-danger-1 error-icon"
})`
  right: 1.6rem;
  display: none;
`;

export const ErrorLabel = styled.div.attrs({
    className: "error-label mt-1 text-sys-danger-1 fz-sf-regular-footnote"
})`
  min-height: 2rem;
  opacity: 0;
  visibility: hidden;
`;
