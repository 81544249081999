import { HighlightPatternClassMap, HighlightProps, PositionProps } from "domain/task/components/Tutorial/type";
import styled from "styled-components";
import { APP_MAX_WIDTH } from "styles/Constants";

export const TutorialBackground = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  max-width: ${APP_MAX_WIDTH};
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  mix-blend-mode: hard-light;
  overflow: hidden;
`;

export const Position = styled.div.attrs<PositionProps>({
    className: "d-flex flex-column align-items-center justify-content-center position-absolute"
})`
  width: 20rem;
  top: ${({ top }: { top: number }) => top}rem;
  left: ${({ left }: { left: number }) => left}rem;
  z-index: ${({ zIndex = 0 }) => zIndex};
`;
export const Highlight = styled.div.attrs<HighlightProps>(({ pattern }) => ({
    className: HighlightPatternClassMap[pattern]
}))`
  width: ${({ width }: { width: number }) => width}rem;
  height: ${({ height }: { height: number }) => height}rem;
  background-color: gray;
`;