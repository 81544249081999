import styled, { css } from "styled-components";
import { Nav } from "react-bootstrap";
import { SYSTEM_COLORS } from "../Constants";

export const TabWrapper = styled(Nav).attrs(() => ({
    className: "justify-content-between flex-nowrap w-100 p-1 header-tab-bar-bg"
}))`
  margin-bottom: 1.2rem;
  border-radius: .8rem;
`;
export const TabItem = styled(Nav.Item).attrs(() => ({
    className: "text-center w-100 clickable header-tab-text-off fz-sf-regular-body-2"
}))`
  border-radius: .6rem;
  padding: .5rem 1rem;
`;

export const ActiveTabItem = styled(Nav.Item).attrs(() => ({
    className: "text-center w-100 clickable header-tab-text-on header-tab-bg-on fz-sf-bold-body-2"
}))`
  border-radius: .6rem;
  padding: .5rem 1rem;
`;


export const TabLink = styled(Nav.Link).attrs(() => ({
    className: "fz-sf-regular-body-2 header-tab-text-off"
}))``;
export const ActiveTabLink = styled(Nav.Link).attrs(() => ({
    className: "fz-sf-bold-body-2 header-tab-text-on"
}))``;


export const RewardTab = styled.div.attrs(({ $active }) => ({
    className: `d-flex justify-content-center align-items-center w-100 
    ${$active ? "fz-sf-bold-body-2 text-primary-2" : "fz-sf-regular-body-2"}`
}))`
  padding: .4rem 0;
  cursor: pointer;
  border-radius: .6rem;
  ${({ $active }) => {
    if ($active) {
        return css`
        background: linear-gradient(180deg, ${SYSTEM_COLORS.bgLightPrimary} 0%, ${SYSTEM_COLORS.bgLightSecondary} 100%);
      `;
    }
}};
`;
