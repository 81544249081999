import clsx from "clsx";
import { chevronDownSecondaryIcon, chevronUpIcon } from "helpers/IconHelper";
import { isMatchLive } from "helpers/match/MatchHelper";
import { generatePlayerName } from "helpers/PlayerHelpers";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Spinner, useAccordionButton } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { ApiExecuteContext } from "../../../../apis/ApiContext";
import { fetchScorecard } from "../../../../apis/match/MatchApi";
import { StartedMatchEmptyScoreCard } from "../../../../components/empty/StartedMatchEmptyScoreCard";
import { SpinnerWrapper, STICK_TOP } from "../../pages/started/MyContestDetailStyle";
import { useStartedMatchDetailData } from "../../providers/MyStartedMatchDataProvider";
import {
    getAccordionActiveKey,
    getDefaultAccordionActiveKey,
    getTotalExtra,
    isCurrentBatsman,
    squadAKey,
    squadBKey
} from "./ScorecardHelper";
import {
    AccordionCardHeader,
    AccordionCollapse,
    AccordionHeader,
    BattingText,
    ChevronIcon,
    PlayerHowOut,
    PlayerName,
    ScorecardWrapper,
    ScoreTable,
    ScoreTableHead,
    ScoreTd,
    ScoreTdBold,
    ScoreTdBoldToRight,
    ScoreTdBoldToRightSpan4,
    ScoreTdSpan5,
    ScoreTdToRight,
    ScoreThToRight,
    ScoreThToRightSpan4,
    SpecialInfoLeft,
    SpecialInfoRight,
    SquadCode,
    SquadCodeWrapper,
    SquadInfo,
    SquadOvers,
    SquadScores,
    StyledAccordion,
    StyledCard
} from "./ScorecardStyle";


const SquadLayout = ({
                         accordionKey,
                         accordionActiveKey,
                         squad,
                         battingSquadCode,
                         currentBatsmen,
                         matchStatus,
                         stickyTop
                     }) => {
    const decoratedOnClick = useAccordionButton(accordionKey);

    const isActive = accordionActiveKey === accordionKey;
    const isSquadBatting = isMatchLive(matchStatus) && squad?.code === battingSquadCode;

    const scrollToTop = () => {
        decoratedOnClick();
        window.scrollTo(0, 0);
    };

    return <>
        <AccordionCardHeader className={clsx({
            "tw-bg-primary-50": isActive,
            "tw-bg-secondary-150": !isActive,
        })} top={stickyTop} onClick={scrollToTop}>
            <AccordionHeader>
                <SquadCodeWrapper>
                    <SquadCode className={clsx({
                        "tw-text-primary-600": isActive,
                        "tw-text-secondary-500": !isActive,
                    })}
                    >{squad.code}</SquadCode>
                    {isSquadBatting && <BattingText>batting</BattingText>}
                </SquadCodeWrapper>
                <SquadInfo>
                    <SquadScores>{squad.scores || 0}</SquadScores>
                    <SquadOvers>( {squad.overs || 0} )</SquadOvers>
                    {/* TODO change to use svg icon */}
                    <ChevronIcon
                        bgImg={isActive ? chevronUpIcon : chevronDownSecondaryIcon} />
                </SquadInfo>
            </AccordionHeader>
        </AccordionCardHeader>

        <AccordionCollapse eventKey={accordionKey} top={stickyTop}>
            <ScoreTable>
                <ScoreTableHead>
                    <tr>
                        <th className="fz-sf-regular-subhead"><FormattedMessage
                            id="my_matches_page_scorecard_label_batter" /></th>
                        <ScoreThToRight>R</ScoreThToRight>
                        <ScoreThToRight>B</ScoreThToRight>
                        <ScoreThToRight>4s</ScoreThToRight>
                        <ScoreThToRight>6s</ScoreThToRight>
                        <ScoreThToRight>SR</ScoreThToRight>
                    </tr>
                </ScoreTableHead>
                <tbody>
                {
                    _.isArray(squad.batsmen) && squad.batsmen.map(batsman => {
                            return <tr key={batsman.playerId}>
                                <ScoreTd>
                                    <PlayerName bold={isCurrentBatsman(batsman.playerId, currentBatsmen)}>
                                        {generatePlayerName(batsman)}
                                    </PlayerName>
                                    {
                                        isSquadBatting && isCurrentBatsman(batsman.playerId, currentBatsmen)
                                            ? <BattingText>
                                                <FormattedMessage id="my_matches_page_scorecard_label_batting" />
                                            </BattingText>
                                            : <PlayerHowOut>{batsman.howOut}</PlayerHowOut>
                                    }
                                </ScoreTd>
                                <ScoreTdBoldToRight>{batsman.runs}</ScoreTdBoldToRight>
                                <ScoreTdToRight>{batsman.ballsFaced}</ScoreTdToRight>
                                <ScoreTdToRight>{batsman.fours}</ScoreTdToRight>
                                <ScoreTdToRight>{batsman.sixes}</ScoreTdToRight>
                                <ScoreTdToRight>{batsman.strikeRate}</ScoreTdToRight>
                            </tr>;
                        }
                    )
                }
                <tr>
                    <ScoreTdBold><FormattedMessage id="my_matches_page_scorecard_label_title_extras" /></ScoreTdBold>
                    <ScoreTdSpan5>
                        <div>
                            <SpecialInfoLeft>{getTotalExtra(squad)}</SpecialInfoLeft>
                            <SpecialInfoRight>(wd {squad.extraRunWides || 0},
                                              nb {squad.extraRunNoBalls || 0},
                                              b {squad.extraRunByes || 0},
                                              lb {squad.extraRunLegByes || 0})
                            </SpecialInfoRight>
                        </div>
                    </ScoreTdSpan5>
                </tr>
                <tr>
                    <ScoreTdBold><FormattedMessage id="label_total" /></ScoreTdBold>
                    <ScoreTdSpan5>
                        <div>
                            <SpecialInfoLeft>{squad.totalRuns || 0}</SpecialInfoLeft>
                            <SpecialInfoRight>({squad.totalWickets || 0} wickets, {squad.totalOvers || 0} over)</SpecialInfoRight>
                        </div>
                    </ScoreTdSpan5>
                </tr>
                </tbody>

                <ScoreTableHead>
                    <tr>
                        <th className="fz-sf-regular-subhead">
                            <FormattedMessage id="my_matches_page_scorecard_label_bowler" />
                        </th>
                        <ScoreThToRight>O</ScoreThToRight>
                        <ScoreThToRight>M</ScoreThToRight>
                        <ScoreThToRight>R</ScoreThToRight>
                        <ScoreThToRight>W</ScoreThToRight>
                        <ScoreThToRight>Eco</ScoreThToRight>
                    </tr>
                </ScoreTableHead>
                <tbody>
                {
                    _.isArray(squad.bowlers) && squad.bowlers.map(bowler =>
                        <tr key={bowler.playerId}>
                            <ScoreTd><PlayerName>{generatePlayerName(bowler)}</PlayerName></ScoreTd>
                            <ScoreTdToRight>{bowler.overs}</ScoreTdToRight>
                            <ScoreTdToRight>{bowler.maidens}</ScoreTdToRight>
                            <ScoreTdToRight>{bowler.runsConceded}</ScoreTdToRight>
                            <ScoreTdBoldToRight>{bowler.wickets}</ScoreTdBoldToRight>
                            <ScoreTdToRight>{bowler.econ}</ScoreTdToRight>
                        </tr>
                    )
                }
                </tbody>

                <ScoreTableHead>
                    <tr>
                        <th className="fz-sf-regular-subhead">
                            <FormattedMessage id="my_matches_page_scorecard_label_fall_of_wicket" />
                        </th>
                        <ScoreThToRightSpan4>
                            <FormattedMessage id="my_matches_page_scorecard_label_score" />
                        </ScoreThToRightSpan4>
                        <ScoreThToRight>
                            <FormattedMessage id="my_matches_page_scorecard_label_over" />
                        </ScoreThToRight>
                    </tr>
                </ScoreTableHead>
                <tbody>
                {
                    _.isArray(squad.fallOfWickets) && squad.fallOfWickets.map((wicket) =>
                        <tr key={wicket.playerId}>
                            <ScoreTd>
                                <PlayerName>{generatePlayerName(wicket)}</PlayerName>
                            </ScoreTd>
                            <ScoreTdBoldToRightSpan4>{wicket.scoreAtDismissal}</ScoreTdBoldToRightSpan4>
                            <ScoreTdToRight>{wicket.oversAtDismissal}</ScoreTdToRight>
                        </tr>
                    )
                }
                </tbody>
            </ScoreTable>
        </AccordionCollapse>
    </>;
};

export const accordionHeaderHeight = 3.4;

const Scorecard = () => {
    const startedMatchDetailData = useStartedMatchDetailData();
    const match = startedMatchDetailData.match;

    const apiExecutor = useContext(ApiExecuteContext);
    const [scorecard, setScorecard] = useState(null);
    const [accordionActiveKey, setAccordionActiveKey] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const squadACardPosition = squadBKey === accordionActiveKey ? "sticky" : undefined;

    const onAccordionSelect = async selectedKey => {
        setAccordionActiveKey(getAccordionActiveKey(selectedKey, accordionActiveKey));

        if (isMatchLive(match.matchStatus)) {
            await getAndSetScorecard(match.matchId);
        }
    };

    const getAndSetScorecard = useCallback((matchId) => {
        return apiExecutor(
            fetchScorecard(matchId),
            {
                onSuccess: res => {
                    setScorecard(res);
                    return res;
                }
            }
        );
    }, [apiExecutor, setScorecard]);

    const ScorecardLayout = () => {

        if (isLoading) {
            return <SpinnerWrapper>
                <Spinner animation="border" />
            </SpinnerWrapper>;
        }

        if (_.isEmpty(scorecard)) {
            return <StartedMatchEmptyScoreCard />;
        }

        return <StyledAccordion activeKey={accordionActiveKey}
                                onSelect={onAccordionSelect}>
            <StyledCard position={squadACardPosition} top={STICK_TOP}>
                <SquadLayout accordionKey={squadAKey}
                             accordionActiveKey={accordionActiveKey}
                             squad={scorecard.squadA}
                             battingSquadCode={scorecard.battingSquadCode}
                             currentBatsmen={scorecard.currentBatsmen}
                             matchStatus={match.matchStatus}
                             stickyTop={STICK_TOP}
                />
            </StyledCard>
            <StyledCard>
                <SquadLayout accordionKey={squadBKey}
                             accordionActiveKey={accordionActiveKey}
                             squad={scorecard.squadB}
                             battingSquadCode={scorecard.battingSquadCode}
                             currentBatsmen={scorecard.currentBatsmen}
                             matchStatus={match.matchStatus}
                             stickyTop={STICK_TOP + accordionHeaderHeight}
                />
            </StyledCard>
        </StyledAccordion>;
    };

    useEffect(() => {
        setScorecard(null);

        setIsLoading(true);

        const { matchId } = match;

        getAndSetScorecard(matchId)
            .then(res => {
                setAccordionActiveKey(getDefaultAccordionActiveKey(match.matchStatus, res));
            })
            .finally(() => {
                setIsLoading(false);
            });

    }, [match, getAndSetScorecard]);


    return <ScorecardWrapper>
        <ScorecardLayout />
    </ScorecardWrapper>;
};

export default Scorecard;


const squadPropTypes = PropTypes.shape({
    batsmen: PropTypes.arrayOf(PropTypes.shape({
        ballsFaced: PropTypes.string.isRequired,
        howOut: PropTypes.string,
        fours: PropTypes.string.isRequired,
        playerId: PropTypes.string.isRequired,
        runs: PropTypes.number.isRequired,
        sixes: PropTypes.string.isRequired,
        strikeRate: PropTypes.string.isRequired
    }).isRequired),
    bowlers: PropTypes.arrayOf(PropTypes.shape({
        econ: PropTypes.string.isRequired,
        maidens: PropTypes.string.isRequired,
        overs: PropTypes.string.isRequired,
        playerId: PropTypes.string.isRequired,
        runsConceded: PropTypes.string.isRequired,
        wickets: PropTypes.string.isRequired
    }).isRequired),
    fallOfWickets: PropTypes.arrayOf(PropTypes.shape({
        playerId: PropTypes.string.isRequired,
        scoreAtDismissal: PropTypes.number.isRequired,
        oversAtDismissal: PropTypes.string.isRequired
    }).isRequired),
    code: PropTypes.string,
    extraRunByes: PropTypes.number,
    extraRunLegByes: PropTypes.number,
    extraRunNoBalls: PropTypes.number,
    extraRunWides: PropTypes.number,
    overs: PropTypes.string,
    scores: PropTypes.string,
    totalOvers: PropTypes.string,
    totalRuns: PropTypes.number,
    totalWickets: PropTypes.number
});

SquadLayout.propTypes = {
    accordionKey: PropTypes.oneOf([squadAKey, squadBKey]).isRequired,
    accordionActiveKey: PropTypes.oneOf([squadAKey, squadBKey]),
    squad: squadPropTypes,
    battingSquadCode: PropTypes.string.isRequired,
    currentBatsmen: PropTypes.arrayOf(PropTypes.string).isRequired,
    matchStatus: PropTypes.string.isRequired,
    stickyTop: PropTypes.number.isRequired
};

Scorecard.propTypes = {
    match: PropTypes.shape({
        matchId: PropTypes.string.isRequired,
        matchStatus: PropTypes.string.isRequired
    }).isRequired,
    scorecard: PropTypes.shape({
        squadA: squadPropTypes,
        squadB: squadPropTypes,
        battingSquadCode: PropTypes.string.isRequired,
        currentBatsmen: PropTypes.arrayOf(PropTypes.string).isRequired
    })
};
