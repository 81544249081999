import { ResponsibleGamingRepository } from "data/repository/responsibleGaming/ResponsibleGamingRepository";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import { useEffect, useState } from "react";
import { KycVerificationVo } from "domain/kyc/types";

const initialState = {
    firstName: "",
    lastName: "",
    yearOfBirth: "",
    monthOfBirth: "",
    dayOfBirth: "",
    nationality: "",
    placeOfBirth: "",
    currentAddress: "",
    permanentAddress: "",
    countryCode: "",
    phoneNumber: "",
    natureOfWork: "",
    documentType: "",
    idNumber: "",
    idFrontSideImage: null,
    idBackSideImage: null,
    identityProofPhoto: null,
};

const responsibleGamingRepository = new ResponsibleGamingRepository();

export const useKycData = () => {

    const {
        data: responsibleGamingRegistration
    } = useFantasyQuery([QUERY_KEY.RESPONSIBLE_GAMING_REGISTRATION], responsibleGamingRepository.getResponsibleGamingRegistration);


    const [kycData, setKycData] = useState<KycVerificationVo | null>(null);

    useEffect(() => {
        if (responsibleGamingRegistration) {
            setKycData(prevState => ({
                ...prevState ? prevState : initialState,
                firstName: responsibleGamingRegistration.firstName,
                lastName: responsibleGamingRegistration.lastName,
                yearOfBirth: responsibleGamingRegistration.yearOfBirth.toString(),
                monthOfBirth: responsibleGamingRegistration.monthOfBirth.toString(),
                dayOfBirth: responsibleGamingRegistration.dayOfBirth.toString(),
                countryCode: responsibleGamingRegistration.countryCode,
                phoneNumber: responsibleGamingRegistration.phoneNumber
            }));
        }
    }, [responsibleGamingRegistration]);

    const updateKycData = (data: Partial<KycVerificationVo>) => {
        setKycData(prevState => {
            if (!prevState) {
                return prevState;
            }

            return ({ ...prevState, ...data });
        });
    };

    return {
        kycData,
        updateKycData
    };
};
