import { EmptyImg, EmptyText, EmptyWrapper } from "components/empty/EmptyStyle";
import { emptyMatchUpcoming } from "helpers/IconHelper";
import { FormattedMessage } from "react-intl";
import React from "react";

function EmptyResultMatch() {
    return <EmptyWrapper>
        <EmptyImg src={emptyMatchUpcoming} />
        <div className="fz-sf-bold-body-2 text-primary-2 mb-3">
            <FormattedMessage id="result_match_missed" />
        </div>
        <EmptyText>
            <FormattedMessage id="result_empty_match" />
        </EmptyText>
    </EmptyWrapper>;
}

export default EmptyResultMatch;
