import Navbar from "components/container/navbar/Navbar";
import React from "react";

interface AuthWrapperProps {
    onNavBack: () => void;
    children: React.ReactNode;
}

export const AuthWrapper: React.FC<AuthWrapperProps> = ({ children, onNavBack }) => {
    return <>
        <Navbar isHeaderLine={false} onNavbarBackClick={onNavBack} />
        <div className="tw-px-7 tw-py-4 tw-flex tw-flex-col tw-gap-9 tw-justify-between tw-h-full tw-mt-[6.5rem]">
            {children}
        </div>
    </>;
};
