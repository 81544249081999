import { useEffect, useState } from "react";

const useScrollDetector = (detectedTop = 50): boolean => {

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        function handleScroll() {
            setIsScrolled(window.scrollY > detectedTop);
        }


        window.addEventListener("scroll", handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };

    }, [detectedTop]);


    return isScrolled;
};

export default useScrollDetector;
