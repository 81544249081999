import React from "react";
import { Offcanvas } from "react-bootstrap";

interface PanelProps {
    show: boolean
    onHide: () => void
    Header?: React.ReactNode
    Body?: React.ReactNode,
    backdrop?: boolean
    backdropClassName?: string
    className?: string
    scroll?: boolean
    onEntered?: () => void
    bsPrefix?: string
}

const Panel: React.FC<PanelProps> = ({ Header = null, Body = null, ...props }) => {
    return (
        <Offcanvas bsPrefix={props.bsPrefix} placement="bottom" {...props}>
            <Offcanvas.Header className="position-relative p-0">
                {Header}
            </Offcanvas.Header>
            <Offcanvas.Body className="p-0">
                {Body}
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default Panel;
