import styled from "styled-components";

export const PrizeListWrapper = styled.div.attrs(() => ({
    className: "d-flex flex-column align-items-center"
}))`
`;
export const StyledTr = styled.tr.attrs<{ idx: number }>(({ idx }) => ({
    className: `${idx === 0 && "font-weight-bold"}`
}))`
    border-top: none;

    &:not(:first-child) {
        border-top: 1px dashed #CCCCCC;
    }
`;
export const StyledTd = styled.td.attrs(() => ({
    className: "border-0 ps-4"
}))``;
export const StyledRightTd = styled(StyledTd).attrs(() => ({
    className: "text-end ps-0 pe-4"
}))``;
