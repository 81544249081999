import React from "react";
import { Placeholder } from "react-bootstrap";
import { TextPlaceHolder } from "../skeleton/SkeletonStyle";

const BasketballNewsSkeleton = () => {
    return <Placeholder animation="glow">
        <div className="tw-flex tw-gap-2 tw-py-2 tw-flex-col">
            <TextPlaceHolder $radius={1.6} $height={1.2} className="!tw-w-[75%]" />
            <TextPlaceHolder $radius={1.6} $height={1.2} className="!tw-w-1/2" />
            <TextPlaceHolder $radius={1.6} $height={1.2} className="!tw-w-[25%]" />
        </div>
        <div className="tw-flex tw-gap-2 tw-py-2 tw-flex-col">
            <TextPlaceHolder $radius={1.6} $height={1.2} className="!tw-w-full" />
            <TextPlaceHolder $radius={1.6} $height={1.2} className="!tw-w-full" />
            <TextPlaceHolder $radius={1.6} $height={1.2} className="!tw-w-full" />
            <TextPlaceHolder $radius={1.6} $height={1.2} className="!tw-w-full" />
            <TextPlaceHolder $radius={1.6} $height={1.2} className="!tw-w-full" />
        </div>

        <hr className="tw-my-2 tw-border-t tw-border-system-gray100" />

        <div className="tw-flex tw-gap-2 tw-py-2 tw-flex-col">
            <TextPlaceHolder $radius={1.6} $height={1.2} className="!tw-w-[75%]" />
            <TextPlaceHolder $radius={1.6} $height={1.2} className="!tw-w-1/2" />
            <TextPlaceHolder $radius={1.6} $height={1.2} className="!tw-w-[25%]" />
        </div>
        <div className="tw-flex tw-gap-2 tw-py-2 tw-flex-col">
            <TextPlaceHolder $radius={1.6} $height={1.2} className="!tw-w-full" />
            <TextPlaceHolder $radius={1.6} $height={1.2} className="!tw-w-full" />
            <TextPlaceHolder $radius={1.6} $height={1.2} className="!tw-w-full" />
            <TextPlaceHolder $radius={1.6} $height={1.2} className="!tw-w-full" />
            <TextPlaceHolder $radius={1.6} $height={1.2} className="!tw-w-full" />
        </div>
    </Placeholder>;
};

export default BasketballNewsSkeleton;