import { useCallback, useContext, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { updateTeamPreviewConfig, updateTeamPreviewData, updateTeamPreviewShow } from "store/actions/Actions";
import { ApiExecuteContext } from "../../apis/ApiContext";
import { fetchTeam } from "../../apis/team/TeamApi";
import { fetchUserProfile } from "../../apis/user/UserApi";
import { useSelector } from "../../store";
import { TeamPreviewConfig, TeamPreviewData } from "../../store/reducer/teamPreview/types";
import { SquadPlayer } from "../../types/player/Player";
import { SPORT } from "../../types/sports/Sport";

const useTeamPreview = (config?: Partial<TeamPreviewConfig>) => {
    const {
        TeamPreviewState: {
            show,
            data: {
                players,
                match,
                myTeams,
                currentTeamId,
                isOpenWhenBack,
                currentContestCode,
                teamInfoForUserProfile
            },
            config: { sport }
        }
    } = useSelector(store => store);
    const apiExecutor = useContext(ApiExecuteContext);
    const dispatch = useDispatch();

    const open = useCallback(() => {
        dispatch(updateTeamPreviewShow(true));
    }, [dispatch]);

    const close = useCallback(() => {
        dispatch(updateTeamPreviewShow(false));
    }, [dispatch]);

    const toggle = useCallback(() => {
        dispatch(updateTeamPreviewShow(!show));
    }, [dispatch, show]);

    const updateData = useCallback((data: Partial<TeamPreviewData>) => {
        dispatch(updateTeamPreviewData(data));
    }, [dispatch]);

    const getCurrentTeam = useCallback(() => {
        return myTeams.find(team => team.id === currentTeamId);
    }, [currentTeamId, myTeams]);

    const getPlayers = useCallback((team) => {
        return team.players.map(player => {
            const _player = new SquadPlayer({
                ...player,
                isHome: player.squadAKey === match!.squadAKey,
                squadLogoUrl: player.squadKey === match!.squadAKey ? match!.squadALogoUrl : match!.squadBLogoUrl,
                sport
            });

            if (team.captain.id === player.id) _player.setCaptain();
            if (team.viceCaptain.id === player.id) _player.setViceCaptain();

            return _player;
        });
    }, [match, sport]);

    const updateCurrentTeam = useCallback((team) => {
        updateData({
            players: getPlayers(team),
            currentTeamId: team.id
        });
    }, [getPlayers, updateData]);

    const updateTeamInfoForUserProfile = useCallback((teamInfoForUserProfile) => {
        updateData({
            teamInfoForUserProfile
        });
    }, [updateData]);

    const updateUserProfile = useCallback(async (userId, clearPrevState = false) => {
        if (clearPrevState) {
            updateData({
                userProfile: null
            });
        }
        const userProfile = await apiExecutor(fetchUserProfile(userId));
        updateData({ userProfile });
    }, [apiExecutor, updateData]);

    const updatePlayersByTeamId = useCallback(async (teamId) => {
        const team = await apiExecutor(fetchTeam(teamId));
        updateData({
            players: getPlayers(team),
        });
    }, [apiExecutor, getPlayers, updateData]);

    const updateContestCode = useCallback((currentContestCode) => {
        updateData({
            currentContestCode
        });
    }, [updateData]);

    const updateIsOpenWhenBack = useCallback((isOpenWhenBack) => {
        updateData({
            isOpenWhenBack
        });
    }, [updateData]);

    const getContestInitIndex = useCallback((contests) => {
        if (!isOpenWhenBack) return 0;
        return Math.max(contests.findIndex(contest => contest.code === currentContestCode), 0);
    }, [isOpenWhenBack, currentContestCode]);

    const getTeamInitIndex = useCallback((winTeams) => {
        if (!isOpenWhenBack) return 0;
        return Math.max(winTeams.findIndex(team => team.teamId === teamInfoForUserProfile.teamId), 0);
    }, [isOpenWhenBack, teamInfoForUserProfile.teamId]);

    useEffect(() => {
        if (config) {
            dispatch(updateTeamPreviewConfig({
                ...config,
                sport: config.sport && SPORT[config.sport.toUpperCase()]
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return useMemo(() => ({
        show,
        players,
        open,
        close,
        toggle,
        updateData,
        updateCurrentTeam,
        updateUserProfile,
        updatePlayersByTeamId,
        updateTeamInfoForUserProfile,
        updateContestCode,
        updateIsOpenWhenBack,
        getContestInitIndex,
        getTeamInitIndex,
        getCurrentTeam,
    }), [
        show,
        players,
        open,
        close,
        toggle,
        updateData,
        updateCurrentTeam,
        updateUserProfile,
        updatePlayersByTeamId,
        updateTeamInfoForUserProfile,
        updateContestCode,
        updateIsOpenWhenBack,
        getContestInitIndex,
        getTeamInitIndex,
        getCurrentTeam,
    ]);

};

export default useTeamPreview;
