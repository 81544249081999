import React, { useMemo } from "react";
import { cn } from "../../../helpers/cn";
import { AP_LEVEL } from "../../../types/activityPoint/ApLevel";
import {
    CONTEST_STATUS_FROM_API,
    CONTEST_TYPE_FROM_API,
    ENTRY_FEE_TYPE_FROM_API
} from "../../../types/contest/Contest";
import { MATCH_STATUS_FROM_API } from "../../../types/match/Match";
import { CONTEST_ENTRY_TYPE } from "../data/ComponentTypes";
import { SLATE_STATUS_FROM_API } from "../data/dto";
import { ContestEntry, getContestEntryType } from "../helpers/helpers";
import { ContestEntryFeeText } from "./contest/ContestEntryFeeText";
import { ContestTitle } from "./contest/ContestTitle";
import { JoinedContestStatus } from "./contest/JoinedContestStatus";
import { Participants } from "./contest/Participants";
import { ContestDetailInfos } from "./ContestDetailInfos";
import { JoinContestCardTeams } from "./JoinContstCardTeams";

export interface JoinedContestCardItemProps {
    entryFee: number;
    entryFeeType: ENTRY_FEE_TYPE_FROM_API;
    firstPrize: number;
    entryLimit: number;
    spots: number;
    allowDiscountWithRuby: boolean;
    minApLevel: AP_LEVEL;
    myJoinedTeams: string[];
    contestType: CONTEST_TYPE_FROM_API;
    slipCount: number;
    slateStatus: SLATE_STATUS_FROM_API;
    status: CONTEST_STATUS_FROM_API;
    slateStartsAt: number;
    onClick: () => void;
}

const styleMap = {
    entryFee: {
        [CONTEST_ENTRY_TYPE.COIN]: "tw-bg-primary-800",
        [CONTEST_ENTRY_TYPE.RUBY]: "tw-bg-secondary-700",
        [CONTEST_ENTRY_TYPE.FREE]: "tw-bg-primary-50 tw-text-black tw-border-none",
    },
    blurCircle: {
        [MATCH_STATUS_FROM_API.LIVE]: "tw-bg-success-200",
        [MATCH_STATUS_FROM_API.SCHEDULED]: "tw-bg-secondary-200",
        [MATCH_STATUS_FROM_API.COMPLETED]: "tw-bg-tertiary-150",
    }
};

const JoinedContestCardItem: React.FC<JoinedContestCardItemProps> = (props) => {
    const {
        entryFee,
        entryFeeType,
        firstPrize,
        entryLimit,
        spots,
        allowDiscountWithRuby,
        minApLevel,
        myJoinedTeams,
        contestType,
        slipCount,
        slateStatus,
        status,
        slateStartsAt,
        onClick,
    } = props;

    const contestEntry = useMemo(() => new ContestEntry(entryFeeType, contestType), [entryFeeType, contestType]);
    const contestEntryType = getContestEntryType(contestEntry);

    const isCancelled = status === CONTEST_STATUS_FROM_API.CANCELLED;

    return (
        <div
            className={cn(
                "tw-relative tw-cursor-pointer",
                "tw-w-full",
                "tw-rounded-[1.6rem] tw-overflow-clip",
                "tw-backdrop-blur-lg",
                "tw-border-[1px] tw-border-solid tw-border-white",
                isCancelled ? "tw-bg-grey-150" : "tw-bg-gradient-to-br tw-from-[#D8D7E9]/[0.8] tw-to-[#EEEBD]/[0.87]",
                isCancelled ? "tw-border-none" : "",
                "tw-select-none"
            )}
            onClick={onClick}
        >
            {isCancelled ? null : (
                <div className={
                    cn("tw-absolute tw-left-0 tw-top-0 tw-m-auto tw-w-full tw-h-full",
                        "tw-z-[-1]",
                        "tw-blur-3xl",
                    )
                }>
                    <div className={
                        cn("tw-w-[84px] tw-h-[84px] tw-rounded-full",
                            "tw-absolute tw-top-[-22px] tw-right-[-6px]",
                            styleMap.blurCircle[status],
                        )
                    } />
                    <div className={
                        cn("tw-w-[128px] tw-h-[128px] tw-rounded-full",
                            "tw-absolute tw-top-[35px] tw-right-[-24px]",
                            "tw-bg-[#EAE6BA]"
                        )
                    } />
                </div>
            )}
            <div className={cn("tw-py-2 tw-px-4 tw-pb-4",)}>
                <div className={"tw-flex tw-justify-end"}>
                    <JoinedContestStatus
                        contestStatus={status}
                        slateStatus={slateStatus}
                        slateStartsAt={slateStartsAt}
                    />
                </div>
                <div className="tw-flex tw-items-center tw-gap-2">
                    <div
                        className={
                            cn("tw-w-12 tw-h-12 tw-rounded-full",
                                "tw-text-white tw-text-h6 tw-font-bold",
                                "tw-flex tw-items-center tw-justify-center",
                                "tw-border-solid tw-border-[3px] tw-border-grey-150",
                                styleMap.entryFee[contestEntryType],
                            )}
                    >
                        <ContestEntryFeeText
                            contestEntry={contestEntry}
                            entryFee={entryFee} />
                    </div>
                    <div className="tw-flex-1">
                        <ContestTitle
                            minApLevel={minApLevel}
                            contestType={contestType}
                            entryFeeType={entryFeeType}
                            entryFee={entryFee} />
                        <Participants
                            participants={slipCount}
                            spots={spots} />
                    </div>
                    <JoinContestCardTeams teams={myJoinedTeams} />
                </div>
                <ContestDetailInfos
                    firstPrize={firstPrize}
                    hasJoinedTeams={false}
                    entryLimit={entryLimit}
                    spots={spots}
                    rubyEligible={allowDiscountWithRuby} />
            </div>
        </div>
    );
};

export default JoinedContestCardItem;
