import NineCatLeavePopup from "domain/slate/components/NineCatLeavePopup";
import { SlatePlayerAvatar } from "domain/slate/components/SlatePlayerAvatar";
import { useCreateSlateTeamContext } from "domain/slate/providers/CreateNineCatTeamProvider";
import { useNineCatContext } from "domain/slate/providers/NineCatProvider";
import { cn } from "helpers/cn";
import { Icon } from "helpers/IconHelper";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";


const NineCatPlayerSyntax = ({ text }) => {
    return <div
        className={cn("tw-w-[2.8rem] tw-h-[2.8rem] tw-rounded-full tw-relative tw-flex tw-items-center tw-justify-center tw-bg-grey-50 tw-text-caption-2 tw-z-[var(--zIndex)] tw--ml-1")}
        style={{ "--zIndex": 0 } as any}>
        {text}
    </div>;
};

export const MyNineCatTeams = () => {
    const { toJoinSlateContestPage, myTeams, isRestrictedByKyc } = useNineCatContext();
    const [openSlateLeaveModal, setOpenSlateLeaveModal] = useState(false);
    const { hasSelectPlayer, handleClearStatPlayerIdMap } = useCreateSlateTeamContext();

    if (myTeams.length === 0) {
        return null;
    }

    const myTeamAvatars = myTeams![0].playerAvatars.slice(0, 3);

    const handleLeave = async () => {
        if (await isRestrictedByKyc()) {
            return;
        }
        if (!hasSelectPlayer) {
            return toJoinSlateContestPage();
        }
        setOpenSlateLeaveModal(true);
    };

    return <div>
        <div onClick={handleLeave}
             className={cn("tw-rounded-[.8rem] tw-py-[.6rem] tw-px-4",
                 "tw-bg-gradient-to-r tw-from-grey-0 tw-via-secondary-50 tw-via-[34.5%] tw-to-primary-100",
                 "tw-flex tw-items-center tw-justify-between tw-cursor-pointer"
             )}>
            <div className="tw-flex">
                {
                    myTeamAvatars.map((avatar, idx) => {
                        return <SlatePlayerAvatar key={avatar}
                                                  url={avatar}
                                                  className={cn("tw-z-[var(--zIndex)]", { "tw--ml-1": idx !== 0 })}
                                                  selected={true}
                                                  style={{ "--zIndex": `${myTeamAvatars.length - idx}` } as any} />;
                    })
                }
                <NineCatPlayerSyntax text={"+6"} />
            </div>
            <div className="tw-text-caption-2 tw-text-grey-1000 tw-font-medium flex-grow-1 tw-ml-4">
                <FormattedMessage id={"label_view_my_team"} />
                &nbsp;({myTeams!.length})
            </div>
            <Icon.ArrowRight size={"2rem"} />
        </div>

        <NineCatLeavePopup
            open={openSlateLeaveModal}
            onOpenChange={setOpenSlateLeaveModal}
            onLeaveButtonClick={() => {
                handleClearStatPlayerIdMap();
                toJoinSlateContestPage();
            }}
        />
    </div>;
};
