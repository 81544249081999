import React, { ReactNode } from 'react';
import { cn } from "helpers/cn";
import WithdrawLayout from "domain/withdraw/WithdrawLayout";
import { SendToInfo, WithdrewResult } from "domain/withdraw/types";
import { Icon, withdrawDeclined, withdrawInProgress } from "helpers/IconHelper";
import { FormattedMessage } from "react-intl";
import { useSelector } from "store";
import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { WithdrawAmountInfoVo } from "domain/withdraw/WithdrawAmountInfoVo";
import { Callback } from "Common";
import Step4ReceiptLayout from "domain/withdraw/Step4ReceiptLayout";
import useCurrency from "hooks/useCurrency/useCurrency";

export interface WithdrawStep4Props {
    withdrewResult: WithdrewResult
    sendToInfo: SendToInfo
    withdrawAmountInfo: WithdrawAmountInfoVo
    onBackToGame: Callback
    onResetAndBackToFirstStep: Callback
}

const WithdrawStep4: React.FC<WithdrawStep4Props> = (props) => {
    const { withdrewResult } = props;
    if (withdrewResult === WithdrewResult.PROGRESS) {
        return <WithdrewResultProgress {...props} />;
    }

    if (withdrewResult === WithdrewResult.DECLINED) {
        return <WithdrewResultDeclined {...props} />;
    }

    return null;
};


const WithdrawInfoRow = (props: {
    amount: number;
    label: ReactNode;
}) => {
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();

    return <div
        className={cn("tw-flex tw-justify-between tw-items-center", "tw-text-body-2 xsm:tw-text-subtitle-1")}>
        <h3>
            {props.label}
        </h3>
        <div className={cn("tw-flex tw-items-center tw-gap-0.5")}>
            <span className="tw-text-overline tw-font-light">₱</span>
            <span> {toWalletNumber(props.amount)} </span>
        </div>
    </div>;
};

const ButtonsSection: React.FC<{
    onBackToGame: Callback;
    onBackToFirstStep: Callback;
}> = props => (
    <div className={cn("tw-flex tw-flex-col tw-gap-4 tw-justify-center", "tw-w-full tw-px-7 tw-pt-14 xsm:tw-pt-12")}>
        <Button
            variant={NEW_BUTTON_VARIANTS.primaryUltraLight}
            pattern={BUTTON_PATTERN.pill}
            size={BUTTON_SIZE.lg}
            className={"tw-w-full"}
            onClick={props.onBackToFirstStep}
        >
            <FormattedMessage id={"button_withdraw_another"} />
        </Button>
        <Button
            dataTestId={"backToGameButton"}
            variant={NEW_BUTTON_VARIANTS.tertiaryLight}
            pattern={BUTTON_PATTERN.pill}
            size={BUTTON_SIZE.lg}
            className={"tw-w-full"}
            onClick={props.onBackToGame}
        >
            <FormattedMessage id={"button_back_to_game"} />
        </Button>
    </div>
);

function TopSpare(props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    return <div {...props} className={cn("tw-min-h-[28rem] xsm:tw-min-h-[30rem]", props.className)} />;
}

function WithdrewResultProgress(props: {
    sendToInfo: SendToInfo,
    withdrawAmountInfo: WithdrawAmountInfoVo,
    onResetAndBackToFirstStep: Callback,
    onBackToGame: Callback
}) {
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();
    const winning = useSelector(state => state.WalletState.winning);

    return <WithdrawLayout
        topSlot={<TopSpare />}
        bottomBgColorClassName={"tw-bg-[#D5E5E7]"}
        bottomSlot={
            <div
                className={cn("tw-h-lvh", "tw-flex tw-flex-col tw-items-center", "-tw-translate-y-[33rem] xsm:-tw-translate-y-[35rem]")}>
                <div className={"tw-flex tw-flex-col tw-items-center tw-gap-2 tw-max-w-[23rem]"}>
                    <div
                        className={cn("tw-w-14 tw-h-14 tw-rounded-full", "tw-bg-white", "tw-flex tw-items-center tw-justify-center")}>
                        <img src={withdrawInProgress} alt={"withdrawInProgress"} />
                    </div>
                    <div className={"tw-w-full tw-text-center"}>
                        <h1 className={"tw-text-h6 tw-font-bold"}>
                            <FormattedMessage id={"withdrew_result_progress_title"} />
                        </h1>
                        <p className={"tw-text-body-2 tw-font-light"}>
                            <FormattedMessage id={"withdrew_result_progress_description"} />
                        </p>
                    </div>
                </div>
                <div className={"tw-h-5 tw-flex-shrink-0"} />
                <Step4ReceiptLayout
                    sendToInfo={props.sendToInfo}
                    withdrawInfoSlot={
                        <>
                            <WithdrawInfoRow
                                label={<FormattedMessage id="label_withdrawal" />}
                                amount={props.withdrawAmountInfo.withdrawAmount} />
                            <WithdrawInfoRow
                                label={<FormattedMessage id="label_transaction_fee" />}
                                amount={props.withdrawAmountInfo.transactionFee} />
                            <WithdrawInfoRow
                                label={<FormattedMessage id="label_receive_amount" />}
                                amount={props.withdrawAmountInfo.receiveAmount} />
                            <div className={"tw-pt-2"}>
                                <div
                                    className={cn("tw-flex tw-justify-between tw-items-center", "tw-text-body-2 xsm:tw-text-subtitle-1")}>
                                    <div className={"tw-flex tw-items-center tw-gap-1"}>
                                        <div className={"tw-w-[1.2rem] xsm:tw-w-[1.6rem]"}>
                                            <Icon.Winning
                                                size="100%"
                                                data-testid="coin-icon"
                                            />
                                        </div>
                                        <h3>
                                            <FormattedMessage id={"label_balance"} />
                                        </h3>
                                    </div>
                                    <div
                                        className={cn("tw-flex tw-items-center tw-gap-0.5", "tw-bg-grey-50 tw-rounded-full tw-px-2", "-tw-mr-2")}>
                                        <span className="tw-text-overline tw-font-light">₱</span>
                                        <span> {toWalletNumber(winning)} </span>
                                    </div>
                                </div>
                            </div>
                        </>
                    } />
                <ButtonsSection
                    onBackToFirstStep={props.onResetAndBackToFirstStep}
                    onBackToGame={props.onBackToGame} />
            </div>
        }
    />;
}

function WithdrewResultDeclined(props: {
    sendToInfo: SendToInfo,
    withdrawAmountInfo: WithdrawAmountInfoVo,
    onResetAndBackToFirstStep: Callback,
    onBackToGame: Callback
}) {
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();

    return <WithdrawLayout
        topSlot={<TopSpare className={"tw-min-h-[30rem] xsm:tw-min-h-[33rem]"} />}
        bottomBgColorClassName={"tw-bg-grey-100"}
        bottomSlot={
            <div
                className={cn("tw-h-lvh", "tw-flex tw-flex-col tw-items-center", "-tw-translate-y-[35rem] xsm:-tw-translate-y-[38rem]")}>
                <div
                    className={cn("tw-flex tw-flex-col tw-items-center tw-gap-2 tw-max-w-[23rem]", "tw-w-auto")}>
                    <div
                        className={cn("tw-w-14 tw-h-14 tw-rounded-full", "tw-bg-white", "tw-flex tw-items-center tw-justify-center")}>
                        <img src={withdrawDeclined} alt={"withdrawDeclined"} />
                    </div>
                    <div className={"tw-w-full tw-text-center"}>
                        <h1 className={"tw-text-h6 tw-font-bold"}>
                            <FormattedMessage id={"withdrew_result_declined_title"} />
                        </h1>
                        <p className={"tw-text-body-2 tw-font-light"}>
                            <FormattedMessage id={"withdrew_result_declined_description"} />
                        </p>
                    </div>
                </div>
                <div className={"tw-h-5 tw-flex-shrink-0"} />
                <Step4ReceiptLayout
                    sendToInfo={props.sendToInfo}
                    withdrawInfoSlot={
                        <div
                            className="tw-flex tw-justify-between tw-items-start tw-text-body-2 xsm:tw-text-subtitle-1">
                            <h3 className="tw-w-[50%]  tw-leading-none">
                                <FormattedMessage id="label_withdrew_declined" />
                            </h3>
                            <div className="tw-flex tw-flex-col tw-items-center tw-gap-1">
                                <div>
                                    <span className="tw-text-overline tw-font-light">₱</span>
                                    <span> {toWalletNumber(props.withdrawAmountInfo.withdrawAmount)} </span>
                                </div>
                                <div
                                    className={cn("tw-bg-error-50 tw-text-error-900", "tw-px-1 tw-py-0.5", "tw-font-bold tw-text-overline", "tw-rounded")}
                                >
                                    <FormattedMessage id="label_cancelled" />
                                </div>
                            </div>
                        </div>
                    } />
                <ButtonsSection
                    onBackToFirstStep={props.onResetAndBackToFirstStep}
                    onBackToGame={props.onBackToGame} />
            </div>
        }
    />;
}

export default WithdrawStep4;
