import { useCompleteYourKycDialog } from "hooks/useDialog";
import useFantasyConfig from "hooks/useFantasyConfig";
import useFantasyHistory from "hooks/useFantasyHistory";
import useMyProfile from "hooks/useMyProfile";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { removePopup } from "store/actions/AlertActions";

const useKycRestriction = () => {
    const history = useFantasyHistory();
    const dispatch = useDispatch();
    const { featureConfig: { kyc } } = useFantasyConfig();
    const { myProfile, getMyProfile } = useMyProfile();
    const handleCompleteYourKycDialog = useCompleteYourKycDialog();

    const onCancel = () => {
        dispatch(removePopup());
        history.push(ROUTER_PATH_CONFIG.home);
    };

    const onConfirm = () => {
        dispatch(removePopup());
        history.push(ROUTER_PATH_CONFIG.verifyKyc.form);
    };

    return useCallback(async () => {
        if (kyc) {
            await getMyProfile();
            if (myProfile.isRestrictedByKyc) {
                handleCompleteYourKycDialog({
                    onCancel: onCancel,
                    onConfirm: onConfirm,
                });
                return true;
            }
        }

        return false;

    }, [myProfile]);

};

export default useKycRestriction;
