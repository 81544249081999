import { getContestCodeDetailUrl, getFeaturedContestsDailyUrl, getFeaturedContestsUrl } from "apis/ApiUrlConfig";
import { ajax } from "apis/ApiUtils";
import { FeaturedContestDto } from "data/dto/contest/FeaturedContestDto";
import { LeaderboardTeamDto } from "data/dto/leaderboard/LeaderboardTeamDto";
import { ResultContestVO } from "types/contest/Contest";
import { ResultContestDetailVO } from "types/contest/ContestDetail";
import { SPORT } from "types/sports/Sport";
import { ContestDetailDto } from "../../dto/contest/ContestDetailDto";

export const fetchFeaturedContests = async (sport: SPORT) => {
    const response = await ajax<{ featuredContests: FeaturedContestDto[] }>({ url: getFeaturedContestsUrl(sport) });
    return response.data.data.featuredContests;
};

export const fetchFeaturedContestsDaily = async (sport: SPORT) => {
    const response = await ajax<{
        featuredContests: FeaturedContestDto[]
    }>({ url: getFeaturedContestsDailyUrl(sport) });
    return response.data.data.featuredContests;
};
export const fetchResultContests = async (sport: SPORT, matchId: string): Promise<ResultContestVO[]> => {
    const res = await ajax<ResultContestVO[]>({
        url: `/${sport}/match/${matchId}/contest/result`
    });
    return res.data.data;
};
export const fetchResultContestDetail = async (contestCode: string): Promise<ResultContestDetailVO> => {
    const res = await ajax<ResultContestDetailVO>({
        url: `/contest/leaderboard/${contestCode}`
    });
    return res.data.data;
};

export const fetchMyContestJoinedTeam = async (contestCode: string): Promise<LeaderboardTeamDto[]> => {
    const res = await ajax<LeaderboardTeamDto[]>({
        url: `/contest/${contestCode}/team/my`
    });
    return res.data.data;
};

export const fetchContestDetail = async (contestCode: string): Promise<ContestDetailDto> => {
    const res = await ajax<ContestDetailDto>({
        url: getContestCodeDetailUrl(contestCode)
    });

    return res.data.data;
};
