import { NineCatDetailVo } from "domain/slate/data/vo";
import SlateRepository from "domain/slate/repository/SlateRepository";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";

export function useNineCatDetail(slateId: number) {
    return useFantasyQuery<NineCatDetailVo>([QUERY_KEY.SLATE_DETAIL, slateId], () => {
        return new SlateRepository().getDetail(slateId);
    }, {
        options: {
            enabled: slateId !== undefined
        }
    });
}
