import { GeneralAdWrapper } from "domain/home/components/ad/carousel/B2CAdCarouselStyle";
import React from "react";
import { analyticsEvent, logCustomEvent } from "ga";
import { GeneralAdListVO } from "apis/ad/AdApi";


interface GeneralAdProps {
    ad: GeneralAdListVO
}

const GeneralAd = ({ ad }: GeneralAdProps) => {
    const {
        openInNewWindow,
        linkUrl,
        imageUrl,
        title
    } = ad;

    const onClick = () => {
        logCustomEvent(analyticsEvent.webBanner, { title });

        if (linkUrl && linkUrl.trim().length !== 0) {
            openInNewWindow
                ? window.open(linkUrl, "_blank")
                : window.location.href = linkUrl;
        }
    };

    return <GeneralAdWrapper
        data-testid="general-ad"
        onClick={onClick}
    >
        <img src={imageUrl} alt="ad" />
    </GeneralAdWrapper>;
};

export default GeneralAd;
