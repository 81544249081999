import React, { createContext, useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { NineCatRepository } from "../../../data/repository/nineCat/NineCatRepository";
import { NineCatContestDetailVo } from "../../../data/vo/nineCat/NineCatContestDetailVo";
import useFantasyQuery from "../../../hooks/useFantasyQuery";
import { QUERY_KEY } from "../../../hooks/useFantasyQuery/type";
import { CONTEST_TYPE_FROM_API, ENTRY_FEE_TYPE_FROM_API } from "../../../types/contest/Contest";
import { SPORT } from "../../../types/sports/Sport";
import { NineCatPlayerDetailVO } from "../data/ComponentTypes";
import { MySlateTeamVo, NineCatDetailVo } from "../data/vo";
import { ContestEntry } from "../helpers/helpers";
import SlateRepository from "../repository/SlateRepository";

interface MySlateContextProps {
    players?: NineCatPlayerDetailVO[],
    nineCatDetail?: NineCatDetailVo,
    myTeams: MySlateTeamVo[],
    contestDetail?: NineCatContestDetailVo,
    isLoading: boolean,
    handleTimeoutRefetch: () => void,
    contestEntry: ContestEntry
}

const MySlateContext = createContext<MySlateContextProps | null>(null);

interface MySlateProviderProps {
    children: React.ReactNode;
}

export const MySlateProvider: React.FC<MySlateProviderProps> = ({ children }) => {
    const { contestCode, nineCatId } = useParams();

    const slateRepository = new SlateRepository();
    const nineCatRepository = new NineCatRepository();

    const slatePlayers = useFantasyQuery<NineCatPlayerDetailVO[]>(
        [QUERY_KEY.GET_SLATE_PLAYERS, nineCatId],
        () => slateRepository.getSlatePlayers(nineCatId), {
            options: {
                staleTime: 1000 * 60 * 5,
                enabled: !!nineCatId
            }
        });

    const slateDetail = useFantasyQuery<NineCatDetailVo>(
        [QUERY_KEY.SLATE_DETAIL, nineCatId],
        () => slateRepository.getDetail(nineCatId), {
            options: {
                enabled: !!nineCatId
            }
        });

    const myTeamsResponse = useFantasyQuery(
        [QUERY_KEY.GET_MY_NINE_CAT_SLATE_TEAM, nineCatId],
        () => slateRepository.getMyTeam(nineCatId, SPORT.BASKETBALL), {
            options: {
                enabled: !!nineCatId
            }
        });

    const contestDetail = useFantasyQuery<NineCatContestDetailVo>(
        [QUERY_KEY.SLATE_CONTEST_DETAIL, contestCode],
        () => nineCatRepository.getNineCatContestDetail(contestCode), {
            options: {
                enabled: !!contestCode,
            }
        }
    );

    const handleTimeoutRefetch = useCallback(() => {
        window.location.reload();
    }, []);

    const isLoading = useMemo(() => {
        return slatePlayers.isFetching || slateDetail.isFetching || myTeamsResponse.isFetching || contestDetail.isFetching;
    }, [
        slatePlayers.isFetching,
        slateDetail.isFetching,
        myTeamsResponse.isFetching,
        contestDetail.isFetching
    ]);


    const playerIdsToPlayer = useMemo(() => {
        if (!slatePlayers.data) return {};

        return slatePlayers.data.reduce((idToPlayer: Record<number, NineCatPlayerDetailVO>, player) => {
            idToPlayer[player.id] = player;
            return idToPlayer;
        }, {});
    }, [slatePlayers.data]);


    const myTeams = useMemo(() => {
        if (!myTeamsResponse.data) return [];

        if (Object.keys(playerIdsToPlayer).length === 0) return [];

        return myTeamsResponse.data.map(team => new MySlateTeamVo({
            id: team.id,
            name: team.name,
            points: playerIdsToPlayer[team.points],
            fieldGoalsEfficiency: playerIdsToPlayer[team.fieldGoalsEfficiency],
            freeThrowsEfficiency: playerIdsToPlayer[team.freeThrowsEfficiency],
            offensiveRebounds: playerIdsToPlayer[team.offensiveRebounds],
            defensiveRebounds: playerIdsToPlayer[team.defensiveRebounds],
            assists: playerIdsToPlayer[team.assists],
            steals: playerIdsToPlayer[team.steals],
            blocks: playerIdsToPlayer[team.blocks],
            threePointersMade: playerIdsToPlayer[team.threePointersMade]
        }));
    }, [myTeamsResponse.data, playerIdsToPlayer]);

    const contestEntry = useMemo(() => {
        if (!contestDetail.data) return new ContestEntry(ENTRY_FEE_TYPE_FROM_API.DEPOSIT, CONTEST_TYPE_FROM_API.NORMAL);
        return new ContestEntry(contestDetail.data.entryFeeType, contestDetail.data.type);
    }, [contestDetail.data]);

    const value = useMemo<MySlateContextProps>(() => ({
        players: slatePlayers.data,
        nineCatDetail: slateDetail.data,
        myTeams,
        contestDetail: contestDetail.data,
        isLoading,
        handleTimeoutRefetch,
        contestEntry,
    }), [
        slatePlayers.data,
        slateDetail.data,
        myTeams,
        contestDetail.data,
        isLoading,
        handleTimeoutRefetch,
        contestEntry
    ]);


    return (
        <MySlateContext.Provider value={value}>
            {children}
        </MySlateContext.Provider>
    );
};


export const useMySlate = () => {
    const context = useContext(MySlateContext);
    if (!context) {
        throw new Error("useMySlate must be used within a MySlateProvider");
    }
    return context;
};
