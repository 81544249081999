import { useAppPopups } from "domain/popup/AppPopupsProvider";
import React from "react";
import { useSelector } from "store";
import { FeatureConfig } from "store/reducer/config/FantasyGameConfigState";

type FeatureConfigPopupWrapperProps = {
    children: React.ReactNode;
    getFeature: (config: FeatureConfig) => boolean;
}

const FeatureConfigPopupWrapper: React.FC<FeatureConfigPopupWrapperProps> = ({
                                                                                 getFeature,
                                                                                 children
                                                                             }) => {

    const popups = useAppPopups();
    const { FantasyGameConfigState: { featureConfig } } = useSelector(store => store);

    if (!getFeature(featureConfig)) {
        popups.done();
        return null;
    }

    return <>{children}</>;
};

export default FeatureConfigPopupWrapper;
