import React from "react";
import { Image } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { APP_MIN_WIDTH_VALUE } from "../../../styles/Constants";

export const EmptyFriendListWrapper = styled.div.attrs({
    className: "d-flex flex-column align-items-center"
})`
  // container paddingY 14px & paddingX 8px
  padding-top: calc((80 - 14) / (${APP_MIN_WIDTH_VALUE} - 16) * 100%);
`;

export const EmptyFriendListImage = styled(Image).attrs({})`
  // image width 200px / container paddingX 8px
  width: calc(200 / (${APP_MIN_WIDTH_VALUE} - 16) * 100%);
`;

export const EmptyFriendListText = styled.div.attrs({
    className: "fz-sf-regular-subhead"
})``;

const emptyFriendListImage = "/images/empty_friend_list.png";

const EmptyFriendList = () => {
    return <EmptyFriendListWrapper>
        <EmptyFriendListImage src={emptyFriendListImage} />
        <EmptyFriendListText><FormattedMessage id="friend_list_page_label_empty_friends" /></EmptyFriendListText>
    </EmptyFriendListWrapper>;
};

export default EmptyFriendList;
