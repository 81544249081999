import { ApiError } from "apis/Api";
import { useLoadingToggle } from "components/loading/Loading";
import { UserProfileRepository } from "data/repository/user/UserProfileRepository";
import { UserProfileVo } from "data/vo/user/UserProfileVo";
import { TOAST_TYPE } from "designToken/alert/components/AlertHelper";
import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";
import { isFriend, isMe, isStranger } from "domain/friend/FriendHelper";
import { createErrorObject } from "helpers/ErrorHelper";
import useFantasyCommand from "hooks/useFantasyCommand";
import { ERROR_HANDLER_FLOW } from "hooks/useHandleApiError";
import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { addErrorPopup, addToast } from "store/actions/AlertActions";
import { UserStatus } from "store/reducer/teamPreview/types";


interface FriendActionButtonProps {
    user: Pick<UserProfileVo, "id" | "status" | "type">;
    isReachedFollowingLimit: boolean;
    unFollowButtonVariant?: BUTTON_VARIANTS;
    onRefetchUserInfo: () => Promise<void>;
}

export const FriendActionButton: React.FC<FriendActionButtonProps> = ({
                                                                          user,
                                                                          isReachedFollowingLimit,
                                                                          onRefetchUserInfo,
                                                                          unFollowButtonVariant = BUTTON_VARIANTS.outlineOpacityLight
                                                                      }) => {
    const toggleLoading = useLoadingToggle();
    const dispatch = useDispatch();
    const intl = useIntl();

    const isUserDeleted = user.status === UserStatus.DELETED;

    const repository = useMemo(() => new UserProfileRepository(), []);

    const commandErrorSetting = {
        onFail: (error: ApiError) => {
            toggleLoading(false);
            dispatch(addErrorPopup(createErrorObject(error)));
            return ERROR_HANDLER_FLOW.STOP;
        }
    };
    const follow = useFantasyCommand<number>(repository.follow, commandErrorSetting);

    const unfollow = useFantasyCommand<number>(repository.unfollow, commandErrorSetting);
    const handleSuccessMessage = (message: string) => {
        dispatch(
            addToast({
                type: TOAST_TYPE.SUCCESS,
                content:
                message,
            }),
        );
    };

    const wrapLoading = async (asyncFetchCb: () => Promise<void>) => {
        toggleLoading(true);
        await asyncFetchCb();
        await onRefetchUserInfo();
        toggleLoading(false);
    };

    const handleUnfollow = async () => {
        await wrapLoading(() => unfollow.mutateAsync(user.id));

        handleSuccessMessage(
            intl.formatMessage({
                id: "user_profile_page_toast_remove_friend_success"
            }),
        );

    };
    const handleFollow = async () => {
        await wrapLoading(() => follow.mutateAsync(user.id));

        handleSuccessMessage(
            intl.formatMessage({
                id: "user_profile_page_toast_add_friend_success"
            }),
        );
    };


    if (isMe(user.type) || (isStranger(user.type) && isUserDeleted)) {
        return null;
    }

    if (isFriend(user.type)) {
        return <Button size={BUTTON_SIZE.sm}
                       pattern={BUTTON_PATTERN.pill}
                       variant={unFollowButtonVariant}
                       onClick={handleUnfollow}>
            <FormattedMessage id="label_unfollow" />
        </Button>;
    }

    if (isReachedFollowingLimit) {
        return <Button size={BUTTON_SIZE.sm}
                       pattern={BUTTON_PATTERN.pill}
                       variant={BUTTON_VARIANTS.secondary}
                       dataTestId="reached-following-limit-button"
                       disabled={true}>
            <FormattedMessage id="label_follow" />
        </Button>;
    }

    return <Button size={BUTTON_SIZE.sm}
                   pattern={BUTTON_PATTERN.pill}
                   variant={BUTTON_VARIANTS.secondary}
                   onClick={handleFollow}>
        <FormattedMessage id="label_follow" />
    </Button>;
};

