export enum TOAST_TYPE {
    SUCCESS = "success",
    DANGER = "danger",
    WARNING = "warning",
    INFO = "info"
}


export enum DIALOG_IMG_SIZE {
    SM = 7.2,
    MD = 15,
    LG = 22
}

export const closeAlertMillisecond = 1600;
