import { Icon } from "helpers/IconHelper";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { cn } from "../../../../helpers/cn";
import { apLevelConfig } from "../../../ap/ActivityPointHelper";
import { useDailyCheckInPopup } from "../DailyCheckInPopupProvider";
import { RegularQuestPrizeIcon } from "../../components/QuestPrizeIcon";

const linkBarSize = "tw-w-3";
const linkBarSpacing = "tw-ml-3";

const itemWidth = "tw-w-16 lg:tw-w-24";
const tomorrowClassName = "tw-border-2 tw-border-warning-300 tw-shadow-check-in-popup-tomorrow";


type DailyCheckInPopupItemProps = {
    leading: boolean;
    itemDaysInRow: number;
};


export const DailyCheckInPopupItem: React.FC<DailyCheckInPopupItemProps> = ({ itemDaysInRow, leading }) => {
    const context = useDailyCheckInPopup();

    const isBeforeToday = itemDaysInRow < context.todayDaysInRow;

    const isToday = itemDaysInRow === context.todayDaysInRow;

    const isTomorrow = itemDaysInRow === context.todayDaysInRow + 1;

    const prizeItem = (() => {
        const prize = context.getPrize(context.myApLevel.currentLevel, itemDaysInRow);
        const prizeIcon = <RegularQuestPrizeIcon {...prize} />;

        if (isBeforeToday) {
            return <CompletedDailyCheckInPopupItem leading={leading}
                                                   prizeIcon={prizeIcon} />;
        }

        if (isToday) {
            return <CompletedDailyCheckInPopupItem leading={leading}
                                                   ref={context.todayTarget}
                                                   prizeIcon={prizeIcon} />;
        }

        if (isTomorrow) {
            return <TomorrowDailyCheckInPopupItem leading={leading}
                                                  prizeIcon={prizeIcon}
                                                  {...prize} />;
        }

        return <UncompletedDailyCheckInPopupItem leading={leading}
                                                 prizeIcon={prizeIcon}
                                                 {...prize} />;
    })();

    const recommendedItem = (() => {
        const recommendedApLevel = apLevelConfig[context.myApLevel.currentLevel].recommendedUpgradeLevel;
        if (recommendedApLevel === null) {
            return null;
        }

        const recommendedPrize = context.getPrize(recommendedApLevel, itemDaysInRow);
        const recommendedPrizeIcon = <RegularQuestPrizeIcon {...recommendedPrize} />;

        if (isTomorrow) {
            return <TomorrowRecommendedDailyCheckInPopupItem leading={leading}
                                                             prize={recommendedPrizeIcon} />;
        }

        return <RecommendedDailyCheckInPopupItem leading={leading}
                                                 prize={recommendedPrizeIcon} />;
    })();

    return <div data-testid="daily_check_in_popup_item"
                className="tw-flex tw-flex-col tw-items-center tw-gap-2">
        {prizeItem}
        {recommendedItem}
    </div>;
};

type DailyCheckInPopupItemBasicProps = {
    leading?: boolean;
    prizeIcon?: ReactNode;
    rubyCent?: number;
    coinCent?: number;
    addSign?: boolean;
};

const CompletedDailyCheckInPopupItem = React.forwardRef<HTMLDivElement, DailyCheckInPopupItemBasicProps>((
    { leading = false, prizeIcon }, ref) => {

    return <div className="tw-pt-4">
        <div className={cn(
            "tw-flex tw-flex-row tw-items-center",
        )}>
            {
                !leading && <CompletedCheckInLinkBar />
            }
            <div
                ref={ref}
                className={cn(
                    itemWidth,
                    "tw-h-17 tw-bg-grey-100 tw-rounded-[1.2rem]",
                    "tw-flex tw-flex-col tw-items-center tw-justify-center")}>
                {prizeIcon}
                <Icon.CheckThick size="1.4rem" />
            </div>
        </div>
    </div>;
});

const TomorrowDailyCheckInPopupItem: React.FC<DailyCheckInPopupItemBasicProps> = (
    { leading = false, prizeIcon, coinCent = 0, rubyCent = 0 }) => {

    return <div>
        <div className={cn(
            "tw-mb-1 tw-text-overline tw-font-bold tw-text-warning-1000",
            leading ? "" : linkBarSpacing,
        )}>
            <FormattedMessage id="label_tomorrow" />
        </div>
        <div className={cn(
            "tw-flex tw-flex-row tw-items-center",
        )}>
            {
                !leading && <TomorrowCheckInLinkBar />
            }
            <div
                className={cn(
                    itemWidth,
                    "tw-h-17 tw-bg-grey-0 tw-rounded-[1.2rem]",
                    tomorrowClassName,
                    "tw-flex tw-flex-col tw-items-center tw-justify-center")}>
                {prizeIcon}
                <div
                    className={cn(
                        "tw-h-[1.4rem] tw-place-content-center",
                        "tw-text-caption-2 tw-font-bold tw-leading-none",
                    )}>
                    {coinCent > 0 ? divideByOneHundred(coinCent) : divideByOneHundred(rubyCent)}
                </div>
            </div>
        </div>
    </div>;
};

export const UncompletedDailyCheckInPopupItem: React.FC<DailyCheckInPopupItemBasicProps> = (
    { leading = false, prizeIcon, coinCent = 0, rubyCent = 0, addSign = false }) => {

    return <div className="tw-pt-4">
        <div className={cn(
            "tw-flex tw-flex-row tw-items-center",
        )}>
            {
                !leading && <UncompletedCheckInLinkBar />
            }
            <div
                className={cn(
                    itemWidth,
                    "tw-h-17 tw-bg-grey-0 tw-rounded-[1.2rem]",
                    "tw-flex tw-flex-col tw-items-center tw-justify-center",
                )}>
                {prizeIcon}
                <div
                    className={cn(
                        "tw-h-[1.4rem] tw-place-content-center",
                        "tw-text-caption-2 tw-font-bold tw-leading-none",
                    )}>
                    {addSign && "+"}{coinCent > 0 ? divideByOneHundred(coinCent) : divideByOneHundred(rubyCent)}
                </div>
            </div>
        </div>
    </div>;
};


const CompletedCheckInLinkBar = () => <div className={cn(linkBarSize, "tw-h-1.5", "tw-bg-grey-100")} />;
const TomorrowCheckInLinkBar = () => <div className={cn(linkBarSize, "tw-h-[0.3rem]", "tw-bg-warning-300")} />;
const UncompletedCheckInLinkBar = () => <div className={cn(linkBarSize, "tw-h-[0.3rem]", "tw-bg-grey-0")} />;

export const RecommendedDailyCheckInPopupItem = ({ leading, className = "", prize }: {
    leading?: boolean,
    className?: string,
    prize?: ReactNode,
}) => {
    const { changeToUpgrade } = useDailyCheckInPopup();

    return <div
        data-testid="daily_check_in_popup_recommended_item"
        className={cn(
            "tw-relative",
            itemWidth,
            "tw-h-11 tw-rounded-[1.2rem]",
            "tw-bg-primary-900",
            leading ? "" : linkBarSpacing,
            "tw-cursor-pointer",
            className,
        )}
        onClick={changeToUpgrade}>
        <Icon.KeyHoleLock
            className="tw-absolute tw-right-1 tw-top-1 tw-text-tertiary-100"
            size="1.4rem"
        />
        <div className="tw-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-place-content-center">
            {prize}
        </div>
    </div>;
};

const TomorrowRecommendedDailyCheckInPopupItem = (props: { leading?: boolean, prize?: ReactNode }) => {
    return <RecommendedDailyCheckInPopupItem className={tomorrowClassName} {...props} />;
};
