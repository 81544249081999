import useFantasyConfig from "hooks/useFantasyConfig";
import { FormatMessageResponse, StreamChat } from "stream-chat";
import { fetchChatToken } from "apis/chat/ChatApi";
import { setDataToLocalStorage } from "helpers/localStorage/LocalStorageHelper";
import { LOCAL_STORAGE_KEYS } from "helpers/localStorage/types";
import moment from "moment";
import useFantasyQuery from "hooks/useFantasyQuery";
import useFantasyCommand from "hooks/useFantasyCommand";
import { useEffect, useState } from "react";
import { Chat } from "store/reducer/config/FantasyGameConfigState";
import { ERROR_HANDLER_FLOW } from "hooks/useHandleApiError";

export const useConnectChannel = () => {
    const { chat } = useFantasyConfig();
    const [messages, setMessages] = useState<FormatMessageResponse[]>([]);

    const chatObjectData = useFantasyQuery(["fetchChatToken"], connectChannel(chat), { onFail: () => ERROR_HANDLER_FLOW.STOP });
    const { data: chatObject } = chatObjectData;

    const updateMessages = () => {
        setMessages(chatObject?.channel.state.messages ?? []);
    };

    const queryOldMessages = useFantasyCommand(async (lastMessageId: string) => {
        await chatObject?.channel.query({
            messages: {
                limit: 20,
                id_lt: lastMessageId
            }
        });
    });

    useEffect(() => {
        updateMessages();
        chatObject?.channel?.on("message.new", () => {
            updateMessages();
        });

        return () => chatObject?.channel?.off("message.new", updateMessages);
    }, [chatObject?.channel]);

    return {
        chatObjectData,
        queryOldMessages,
        messages,
        updateMessages
    };
};

const connectChannel = (chat: Chat) => async () => {
    const chatClient = StreamChat.getInstance(chat.key);

    const chatToken = await fetchChatToken();
    await chatClient.connectUser(
        { id: chatToken.chatId },
        chatToken.chatToken
    );

    const channel = chatClient.channel(chat.channelType, chat.channelId);

    await channel.addMembers([chatToken.chatId]);
    await channel.watch();

    setDataToLocalStorage(LOCAL_STORAGE_KEYS.LAST_CHAT_ROOM_READ_TIME, moment().format("YYYY-MM-DD"));
    return {
        id: chatToken.chatId,
        channel
    };
};
