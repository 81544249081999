import FantasyScrollingPopup, {
    ScrollingPopupBody,
    ScrollingPopupFooter,
    ScrollingPopupHeader,
    ScrollingPopupPrimaryButton
} from "components/popup/FantasyScrollingPopup";
import { NINE_CAT_STAT } from "domain/slate/data/ComponentTypes";
import React from "react";
import { FormattedMessage } from "react-intl";

interface SlateRulePopupProps {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    type: NINE_CAT_STAT;
}

const SlateRulePopup: React.FC<SlateRulePopupProps> = ({
                                                           open,
                                                           onOpenChange,
                                                           type
                                                       }) => {

    const statMap = {
        [NINE_CAT_STAT.PTS]: {
            title: "PTS",
            points: 1,
            description: <FormattedMessage id="nine_cat_scoring_description_pts" />
        },
        [NINE_CAT_STAT.FGE]: {
            title: "FGE",
            points: 3,
            description: <FormattedMessage id="nine_cat_scoring_description_fge" />
        },
        [NINE_CAT_STAT.FTE]: {
            title: "FTE",
            points: 3,
            description: <FormattedMessage id="nine_cat_scoring_description_fte" />
        },
        [NINE_CAT_STAT.OR]: {
            title: "OR",
            points: 6,
            description: <FormattedMessage id="nine_cat_scoring_description_or" />
        },
        [NINE_CAT_STAT.DR]: {
            title: "DR",
            points: 3,
            description: <FormattedMessage id="nine_cat_scoring_description_dr" />
        },
        [NINE_CAT_STAT.AST]: {
            title: "AST",
            points: 2.5,
            description: <FormattedMessage id="nine_cat_scoring_description_ast" />
        },
        [NINE_CAT_STAT.ST]: {
            title: "ST",
            points: 7.5,
            description: <FormattedMessage id="nine_cat_scoring_description_st" />
        },
        [NINE_CAT_STAT.BLK]: {
            title: "BLK",
            points: 7.5,
            description: <FormattedMessage id="nine_cat_scoring_description_blk" />
        },
        [NINE_CAT_STAT["3PM"]]: {
            title: "3PM",
            points: 4,
            description: <FormattedMessage id="nine_cat_scoring_description_3pm" />
        }
    };

    const { title, points, description } = statMap[type] || {
        title: "",
        points: 0,
        description: null
    };

    const handleClose = () => {
        onOpenChange(false);
    };

    return (
        <FantasyScrollingPopup
            open={open}
            onOpenChange={onOpenChange}
            title={
                <ScrollingPopupHeader
                    title={title}
                    onClose={handleClose}
                >
                    <div
                        className="tw-text-h6 tw-text-grey-1000 tw-bg-grey-100 tw-text-center tw-w-full tw-py-2 tw-px-4 tw-rounded-lg"
                    >
                        1 {title} = {points} <FormattedMessage id="label_points" />
                    </div>
                </ScrollingPopupHeader>
            }
            body={
                <ScrollingPopupBody>
                    {description}
                </ScrollingPopupBody>
            }
            footer={
                <ScrollingPopupFooter>
                    <ScrollingPopupPrimaryButton onClick={handleClose} />
                </ScrollingPopupFooter>
            }
        />
    );
};

export default SlateRulePopup;
