import React, { ReactNode } from "react";
import { cn } from "../../../helpers/cn";
import { FormattedMessage } from "react-intl";

interface ContestInfoProps {
    info: ReactNode
    title: ReactNode
}

const ContestInfo: React.FC<ContestInfoProps> = ({
                                                     info,
                                                     title,
                                                 }) => <div>
    <h6 className={"tw-text-h6"}>{info}</h6>
    <span className={"tw-text-caption-2 tw-font-light"}>{title}</span>
</div>;

const VerticalDivider: React.FC<{ hasJoinedTeams: boolean }> = (props) => <span className={cn(
    "tw-w-[1px] tw-h-[3rem]",
    props.hasJoinedTeams ? "tw-bg-grey-200" : "tw-bg-grey-550"
)} />;

interface ContestDetailInfosProps {
    isJoinable?: boolean
    firstPrize: number;
    hasJoinedTeams: boolean;
    entryLimit: number;
    spots: number;
    rubyEligible: boolean;
    classNames?: string
}

export const ContestDetailInfos: React.FC<ContestDetailInfosProps> = ({
                                                                          isJoinable = true,
                                                                          entryLimit,
                                                                          firstPrize,
                                                                          hasJoinedTeams,
                                                                          rubyEligible,
                                                                          spots,
                                                                          classNames
                                                                      }) => <div className={cn(
    "tw-flex tw-items-center tw-gap-2",
    "tw-pt-1 tw-pl-14",
    classNames
)}>
    <ContestInfo info={firstPrize} title={
        <FormattedMessage id={"contest_card_label_first_prize"} />
    } />
    <VerticalDivider hasJoinedTeams={hasJoinedTeams} />
    <div className={
        cn(
            !isJoinable ? "tw-text-error-700" : "",
            !isJoinable && hasJoinedTeams ? "tw-text-error-100" : "",
        )
    }>
        <ContestInfo info={entryLimit} title={
            <FormattedMessage id={"contest_card_label_entry_limit"} />
        } />
    </div>
    <VerticalDivider hasJoinedTeams={hasJoinedTeams} />
    <ContestInfo info={`<${spots}`} title={
        <FormattedMessage id={"contest_card_label_cancelled"} />
    } />
    {rubyEligible &&
        <>
            <VerticalDivider hasJoinedTeams={hasJoinedTeams} />
            <ContestInfo
                info={
                    <FormattedMessage id={"label_ruby"} />
                }
                title={
                    <FormattedMessage id={"contest_card_label_eligible"} />
                } />
        </>
    }
</div>;
