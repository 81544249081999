import { useAddCoinFlow } from "domain/coin/AddCoinFlow";
import { isOrderCancelled, isPaymentCancelled } from "domain/coin/AddCoinHelper";
import OrderCancelledDialog from "domain/coin/component/result/OrderCancelledDialog";
import OrderPurchasedDialog from "domain/coin/component/result/OrderPurchasedDialog";
import React from "react";

const ResultView = ({ orderStatus }) => {

    const { status: paymentStatus } = useAddCoinFlow();


    if (isPaymentCancelled(paymentStatus)) {
        return <OrderCancelledDialog />;
    }

    if (isOrderCancelled(orderStatus)) {
        return <OrderCancelledDialog />;
    }

    return <OrderPurchasedDialog />;

};

export default ResultView;
