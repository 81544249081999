import { SPORT } from "types/sports/Sport";
import { fetchMyNineCatSlates, fetchMyNineCatSlatesDetail } from "data/api/nineCat/NIneCatApi";
import { MyNineCatVo } from "data/vo/nineCat/MyNineCatVo";
import { NineCatContestDetailVo } from "data/vo/nineCat/NineCatContestDetailVo";

export class NineCatRepository {

    public async getMyNineCat(): Promise<MyNineCatVo[]> {
        const matchSnapshotDto = await fetchMyNineCatSlates(SPORT.BASKETBALL);

        return matchSnapshotDto.map((dto) => new MyNineCatVo(dto));
    }

    public async getNineCatContestDetail(contestCode: string): Promise<NineCatContestDetailVo> {
        const data = await fetchMyNineCatSlatesDetail(contestCode);
        return new NineCatContestDetailVo(data);
    }
}
