import PlayerAvatar from "components/image/PlayerAvatar";
import { isMatchJoinable } from "helpers/match/MatchHelper";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import { generatePlayerName } from "helpers/PlayerHelpers";
import useCurrency from "hooks/useCurrency/useCurrency";
import React, { Fragment } from "react";
import { Container, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { MATCH_STATUS_FROM_API } from "../../types/match/Match";
import { LINEUP, PlayerVO } from "../../types/player/Player";
import { MyTeamVO } from "../../types/team/MyTeam";
import { TeamCardBody, TeamCardHeader } from "../card/TeamCardStyle";
import SquadLogo from "../image/SquadLogo";
import {
    CaptainAndViceCaptainIcon,
    CaptainIconWrapper,
    Dot,
    PointsCol,
    PointsRow,
    PointsTitle,
    PointsValue,
    RoleCount,
    RoleInfo,
    RoleName,
    SpecialPlayerImageWrapper,
    SpecialPlayerName,
    SpecialPlayerWrapper,
    SquadAndRoleInfo,
    SquadLeftInfo,
    SquadLogoWrapper,
    SquadPlayerCount,
    SquadRightInfo,
    SquadRow,
    TeamInfo,
    ViceCaptainIconWrapper
} from "./TeamCardStyle";

interface TeamCardCaptainProps {
    captain: PlayerVO;
    matchStatus: MATCH_STATUS_FROM_API;
}

export const TeamCardCaptain: React.FC<TeamCardCaptainProps> = ({ captain, matchStatus }) => {
    const showDot = () => captain.lineup !== LINEUP.STARTING_LINEUP && isMatchJoinable(matchStatus);

    return <SpecialPlayerWrapper>
        <CaptainIconWrapper>
            <CaptainAndViceCaptainIcon>C</CaptainAndViceCaptainIcon>
        </CaptainIconWrapper>
        <SpecialPlayerImageWrapper>
            <PlayerAvatar id={captain.id} url={captain.avatar} />
            {
                showDot() && <Dot className="position-absolute bg-sys-danger top-0 end-0"
                                  data-testid="lineupDot" />
            }
        </SpecialPlayerImageWrapper>
        <SpecialPlayerName>{generatePlayerName(captain)}</SpecialPlayerName>
    </SpecialPlayerWrapper>;
};

interface TeamCardViceCaptainProps {
    viceCaptain: PlayerVO;
    matchStatus: MATCH_STATUS_FROM_API;
}

export const TeamCardViceCaptain: React.FC<TeamCardViceCaptainProps> = ({ viceCaptain, matchStatus }) => {
    const showDot = () => viceCaptain.lineup !== LINEUP.STARTING_LINEUP && isMatchJoinable(matchStatus);

    return <SpecialPlayerWrapper>
        <ViceCaptainIconWrapper>
            <CaptainAndViceCaptainIcon>VC</CaptainAndViceCaptainIcon>
        </ViceCaptainIconWrapper>
        <SpecialPlayerImageWrapper>
            <PlayerAvatar id={viceCaptain.id} url={viceCaptain.avatar} />
            {
                showDot() && <Dot className="position-absolute bg-sys-danger top-0 end-0"
                                  data-testid="lineupDot" />
            }
        </SpecialPlayerImageWrapper>
        <SpecialPlayerName>{generatePlayerName(viceCaptain)}</SpecialPlayerName>
    </SpecialPlayerWrapper>;
};

interface TeamCardSquadCountProps {
    team: MyTeamVO;
}

export const TeamCardSquadCount: React.FC<TeamCardSquadCountProps> = ({ team }) => {
    return <SquadRow>
        <SquadLeftInfo>
            <SquadLogoWrapper>
                <SquadLogo url={team.squadALogoUrl} />
            </SquadLogoWrapper>
            <SquadPlayerCount>{team.squadAPlayerCount}</SquadPlayerCount>
        </SquadLeftInfo>
        <SquadRightInfo>
            <SquadLogoWrapper>
                <SquadLogo url={team.squadBLogoUrl} />
            </SquadLogoWrapper>
            <SquadPlayerCount>{team.squadBPlayerCount}</SquadPlayerCount>
        </SquadRightInfo>
    </SquadRow>;
};

interface TeamRole {
    name: string,
    count: number
}

interface TeamCardRolesProps {
    teamRoles: TeamRole[];
}

export const TeamCardRoles: React.FC<TeamCardRolesProps> = ({ teamRoles }) => {
    return <Row>
        {
            teamRoles.map(role => <RoleInfo key={role.name}>
                <RoleName>{role.name}</RoleName>
                <RoleCount>{role.count}</RoleCount>
            </RoleInfo>)
        }
    </Row>;
};

const TeamCardPoints = ({ team }) => {
    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();

    return <PointsRow>
        <PointsCol>
            <PointsTitle><FormattedMessage id={"label_score"} /></PointsTitle>
            <PointsValue>{toFormattedNumber(divideByOneHundred(team.totalScoreCent))}</PointsValue>
        </PointsCol>
    </PointsRow>;
};

export enum TeamCardBodyType {
    SQUAD_COUNT = "SQUAD_COUNT",
    POINTS = "POINTS"
}

const BodyTypeToContext = {
    [TeamCardBodyType.SQUAD_COUNT]: TeamCardSquadCount,
    [TeamCardBodyType.POINTS]: TeamCardPoints
};

interface TeamCardContentProps {
    team: MyTeamVO,
    matchStatus: MATCH_STATUS_FROM_API
    bodyType?: TeamCardBodyType
    children?: React.ReactNode
}


const TeamCardContent: React.FC<TeamCardContentProps> = ({
                                                             team,
                                                             matchStatus,
                                                             children,
                                                             bodyType = TeamCardBodyType.SQUAD_COUNT
                                                         }) => {
    const BodyContext = BodyTypeToContext[bodyType];

    const captain = team.players.find((player) => player.id === team.captain.id)!;

    const viceCaptain = team.players.find((player) => player.id === team.viceCaptain.id)!;

    return (
        <Fragment>
            <TeamCardHeader>
                {children}
            </TeamCardHeader>
            <TeamCardBody>
                <TeamInfo>
                    <TeamCardCaptain captain={captain} matchStatus={matchStatus} />
                    <TeamCardViceCaptain viceCaptain={viceCaptain} matchStatus={matchStatus} />
                    <SquadAndRoleInfo>
                        <Container>
                            <BodyContext team={team} />
                            <TeamCardRoles teamRoles={team.teamRolesCount} />
                        </Container>
                    </SquadAndRoleInfo>
                </TeamInfo>
            </TeamCardBody>
        </Fragment>

    );
};

export default TeamCardContent;
