import { SLATE_STATUS_FROM_API } from "domain/slate/data/dto";
import { cn } from "helpers/cn";
import { sortByMatchStartsAt, sortByMatchStartsAtDesc } from "helpers/SortingHelper";
import { getMyMatchCompletedDate, getMyMatchUpcomingDate } from "helpers/TimeHelper";
import { groupBy } from "lodash";
import moment from "moment";
import { CONTEST_STATUS_FROM_API } from "types/contest/Contest";
import { MATCH_STATUS_FROM_API, ResultMatchesGroupByStartDate } from "types/match/Match";

const MATCH_STATUS_TEXT = {
    [MATCH_STATUS_FROM_API.SCHEDULED]: "Upcoming",
    [MATCH_STATUS_FROM_API.KICKOFF]: "Live",
    [MATCH_STATUS_FROM_API.LIVE]: "Live",
    [MATCH_STATUS_FROM_API.PROCESSING]: "Processing",
    [MATCH_STATUS_FROM_API.COMPLETED]: "Completed"
};

export const parseMatchStatus = (matchStatus: MATCH_STATUS_FROM_API) => {
    return MATCH_STATUS_TEXT[matchStatus];
};

export const isMatchProcessingOrCompleted = (matchStatus: MATCH_STATUS_FROM_API) => [
    MATCH_STATUS_FROM_API.PROCESSING,
    MATCH_STATUS_FROM_API.COMPLETED].includes(matchStatus);

export const isMatchStarted = (matchStatus: MATCH_STATUS_FROM_API) => [
    MATCH_STATUS_FROM_API.KICKOFF,
    MATCH_STATUS_FROM_API.LIVE,
    MATCH_STATUS_FROM_API.PROCESSING,
    MATCH_STATUS_FROM_API.COMPLETED].includes(matchStatus);
export const isMatchJoinable = (matchStatus: MATCH_STATUS_FROM_API) => [MATCH_STATUS_FROM_API.SCHEDULED, MATCH_STATUS_FROM_API.KICKOFF].includes(matchStatus);

export const isMatchPreparing = (matchStatus: MATCH_STATUS_FROM_API) => [MATCH_STATUS_FROM_API.PREPARING].includes(matchStatus);
export const isMatchUpcoming = (matchStatus: MATCH_STATUS_FROM_API) => [MATCH_STATUS_FROM_API.SCHEDULED].includes(matchStatus);
export const isMatchKickoff = (matchStatus: MATCH_STATUS_FROM_API) => [MATCH_STATUS_FROM_API.KICKOFF].includes(matchStatus);
export const isMatchLive = (matchStatus: MATCH_STATUS_FROM_API) => [MATCH_STATUS_FROM_API.LIVE].includes(matchStatus);
export const isMatchKickoffOrLive = (matchStatus: MATCH_STATUS_FROM_API) => [MATCH_STATUS_FROM_API.KICKOFF, MATCH_STATUS_FROM_API.LIVE].includes(matchStatus);
export const isMatchCompleted = (matchStatus: MATCH_STATUS_FROM_API) => [MATCH_STATUS_FROM_API.COMPLETED].includes(matchStatus);
export const isMatchProcessing = (matchStatus: MATCH_STATUS_FROM_API) => [MATCH_STATUS_FROM_API.PROCESSING].includes(matchStatus);
export const isSlateUpcoming = (slateStatus: SLATE_STATUS_FROM_API) => [SLATE_STATUS_FROM_API.UPCOMING].includes(slateStatus);
export const isSlateLive = (slateStatus: SLATE_STATUS_FROM_API) => [SLATE_STATUS_FROM_API.LIVE].includes(slateStatus);
export const isContestCanceled = (contestStatus: CONTEST_STATUS_FROM_API) => [CONTEST_STATUS_FROM_API.CANCELLED].includes(contestStatus);

export const getMatchColor = (matchStatus: MATCH_STATUS_FROM_API) => {
    if (isMatchCompleted(matchStatus)) {
        return "text-sys-success-1";
    }
    if (isMatchProcessing(matchStatus)) {
        return "text-sys-warning-1";
    }

    return "text-sys-danger-1";
};
export const enrichUpcomingMatchesWithStartDate = (matches) => {
    const shouldShowStartDate = (match, lastMatch) => !lastMatch || match.matchStartDate !== lastMatch.matchStartDate;

    return sortByMatchStartsAt(matches).map((match, index, _matches) => {
        const matchStartsAtMoment = moment(match.matchStartsAt);
        const startDate = getMyMatchUpcomingDate(match.matchStartsAt);

        match.matchStartDate = matchStartsAtMoment.format("MMM DD");
        match.getStartDateLabel = (className?: string) => {
            if (shouldShowStartDate(match, _matches[index - 1])) {
                return <div
                    className={cn("tw-text-body-2 tw-mt-2", className)}
                    data-testid={`upcoming-date-matchId-${match.matchId}`}
                >
                    {startDate}
                </div>;
            }
        };
        return match;
    });

};

interface ResultMatchesReduce {
    offset: number;
    resultMatchesGroupByStartDate: ResultMatchesGroupByStartDate[];
}

export const getResultMatchesGroupByStartDate = (matches) => {
    const today = moment().startOf("day");

    function getStartDatePattern(duration) {
        return `${["[Today]", "[Yesterday]", "dddd"][Math.min(duration, 2)]}, MMM DD`;
    }

    function getStartDateDurationStr(duration) {
        return String(duration);
    }

    function groupByIteratee(match) {
        const matchStartsAtMoment = moment(match.matchStartsAt);
        const duration = Math.abs(Math.floor(moment.duration(matchStartsAtMoment.diff(today)).as("days")));
        return matchStartsAtMoment.format(getStartDateDurationStr(duration));
    }

    const startDateToMatches = groupBy(sortByMatchStartsAt(matches), groupByIteratee);

    return Object.keys(startDateToMatches)
        .sort((a, b) => {
            return Number(a) - Number(b);
        })
        .map((duration) => {
            const matches = startDateToMatches[duration];
            const firstMatch = matches[0];
            return {
                matchStartDate: firstMatch ? moment(firstMatch.matchStartsAt).format(getStartDatePattern(duration)) : "",
                matches: startDateToMatches[duration]
            };
        }).reduce<ResultMatchesReduce>((matchAccumulator, { matchStartDate, matches }) => {
            matchAccumulator.resultMatchesGroupByStartDate.push({
                matchStartDate,
                matches,
                offset: matchAccumulator.offset
            });
            matchAccumulator.offset += matches.length;
            return matchAccumulator;
        }, { offset: 0, resultMatchesGroupByStartDate: [] })
        .resultMatchesGroupByStartDate;

};
export const enrichCompletedMatchesWithStartDate = (matches) => {
    const shouldShowStartDate = (match, lastMatch) => !lastMatch || match.matchStartDate !== lastMatch.matchStartDate;

    return sortByMatchStartsAtDesc(matches).map((match, index, _matches) => {
        const matchStartsAtMoment = moment(match.matchStartsAt);
        const startDate = getMyMatchCompletedDate(match.matchStartsAt);

        match.matchStartDate = matchStartsAtMoment.format("MMM DD");
        match.getStartDateLabel = () => {
            if (shouldShowStartDate(match, _matches[index - 1])) {
                return <div
                    className="tw-text-body-2 tw-text-center tw-font-thin tw-mt-2"
                    data-testid={`completed-date-matchId-${match.matchId}`}
                >
                    {startDate}
                </div>;
            }
        };
        return match;
    });

};
