import { FANTASY_DIALOG_VARIANT } from "components/dialog/FantasyDialog";
import { POPUP_SIZE } from "components/popup/FantasyBasicPopup";
import { BUTTON_PATTERN, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import {
    PrizeDistributionCalculator,
    PrizeDistributionCalculatorProps
} from "domain/contest/join/info/detail/PrizeDistributionCalculator";
import { Icon } from "helpers/IconHelper";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { addPopup, removePopup } from "store/actions/AlertActions";

export interface PrizeDistributionButtonProps extends PrizeDistributionCalculatorProps {
}

export const PrizeDistributionButton: React.FC<PrizeDistributionButtonProps> = (props) => {
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(removePopup());
    };

    function handleOpenCalculator() {
        dispatch(addPopup({
            show: true,
            variant: FANTASY_DIALOG_VARIANT.FANTASY_BASIC_POPUP,
            popupSize: POPUP_SIZE.MD,
            title: (
                <h1>
                    <FormattedMessage id="prize_distribution_calculator_title" />
                </h1>
            ),
            content: (
                <PrizeDistributionCalculator {...props} />
            ),
            buttonSetting: {
                confirmButtonVariant: NEW_BUTTON_VARIANTS.text,
                buttonPattern: BUTTON_PATTERN.pill,
                confirmButtonText:
                    <FormattedMessage id={"button_got_it"} />,
                onConfirm: handleClose,
                cancelButton: false,
            },
        }));
    }

    return (
        <button className="tw-inline-block tw-relative tw-top-0.5 tw-left-0.5"
                data-testid="prize-distribution-calculator-button"
                onClick={handleOpenCalculator}
        >
            <Icon.InfoOutlined />
        </button>
    );
};
