export enum AuthModeEnum {
    NORMAL = 'NORMAL',
    PAGCOR = 'PAGCOR',
}

export interface SiteDto {
    id: number;
    name: string;
}

export interface AuthConfigDto {
    mode: AuthModeEnum;
    sites: SiteDto[];
}