export const hasText = (text) => {
    return text !== null && text !== undefined && text.toString().trim() !== "";
};

export const getSentenceWithComma = (...args) => {
    return args.filter(item => hasText(item)).join(", ");
};

export const getFirstLetterAndToUppercase = (name) => {
    if (typeof name !== "string") {
        return "";
    }
    return name.slice(0, 1).toUpperCase();
};

export const getRightSubString = (text: string, length: number) => text.slice(-length);
