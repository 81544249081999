import { storageHelper } from "helpers/localStorage/LocalStorageHelper";
import { LOCAL_STORAGE_KEYS } from "helpers/localStorage/types";
import { defaultLocale, I18n, LOCALE } from "locales";
import React, { useCallback, useMemo, useState } from "react";
import { IntlProvider } from "react-intl";

interface LocaleContextProps {
    locale: LOCALE,
    setLocale: (locale: LOCALE) => void,
    isLanguageInitialized: boolean
}

const NewLocaleContext = React.createContext<LocaleContextProps | null>(null);
export const LocaleProvider = ({ children }) => {
    const localeStorage = storageHelper(LOCAL_STORAGE_KEYS.LOCALE);
    const [locale, setLocale] = useState<LOCALE>(localeStorage.get() ?? defaultLocale);
    const [isLanguageInitialized, setIsLanguageInitialized] = useState<boolean>(localeStorage.get() !== null);

    const changeLocale = useCallback((language: LOCALE) => {
        setIsLanguageInitialized(true);
        setLocale(language);
        localeStorage.put(language);
    }, [localeStorage]);

    const value = useMemo<LocaleContextProps>(() => {
        return {
            locale,
            setLocale: changeLocale,
            isLanguageInitialized
        };
    }, [changeLocale, locale, isLanguageInitialized]);

    return (
        <IntlProvider locale={locale} messages={I18n[locale]}>
            <NewLocaleContext.Provider value={value}>
                {children}
            </NewLocaleContext.Provider>
        </IntlProvider>
    );
};
export const useLocaleProvider = () => {
    const context = React.useContext(NewLocaleContext);

    if (context === null) {
        throw new Error("useLocaleProvider must be used within a LocaleProvider");
    }

    return context;
};