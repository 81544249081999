import React from "react";
import { FormattedMessage } from "react-intl";
import Countdown from "../../../../components/countdown/Countdown";
import { cn } from "../../../../helpers/cn";
import { Icon } from "../../../../helpers/IconHelper";
import { CONTEST_STATUS_FROM_API } from "../../../../types/contest/Contest";
import { SLATE_STATUS_FROM_API } from "../../data/dto";

interface JoinedContestStatusProps {
    slateStatus: SLATE_STATUS_FROM_API
    contestStatus: CONTEST_STATUS_FROM_API
    slateStartsAt: number
}

export const JoinedContestStatus: React.FC<JoinedContestStatusProps> = ({
                                                                            slateStatus,
                                                                            contestStatus,
                                                                            slateStartsAt
                                                                        }) => {
    if (contestStatus === CONTEST_STATUS_FROM_API.CANCELLED) {
        return (
            <p className={"tw-text-caption-2 tw-font-bold tw-text-grey-700"}>
                <FormattedMessage id="label_cancelled" />
            </p>
        );
    }
    switch (slateStatus) {
        case SLATE_STATUS_FROM_API.COMPLETED: {
            return (
                <p className={"tw-text-caption-2 tw-font-bold tw-text-primary-600"}>
                    <FormattedMessage id="label_completed_matches" />
                </p>
            );
        }
        case SLATE_STATUS_FROM_API.LIVE:
            return (
                <span className={
                    cn("tw-text-caption-1 tw-font-bold tw-text-success-900",
                        "tw-flex tw-items-center tw-gap-1"
                    )
                }>
                    <Icon.Live />
                    <FormattedMessage id="label_live_matches" />
                </span>
            );
        case SLATE_STATUS_FROM_API.UPCOMING:
            return (
                <div className={"tw-text-caption-2 tw-font-bold tw-text-secondary-600"}>
                    <Countdown finishAt={slateStartsAt} />
                </div>
            ); // TODO, kickoff 時間？... 以及 handleTimeout?
        default:
            return (
                <div>{slateStatus}</div>
            );
    }
};
