import { cn } from "helpers/cn";
import React from "react";

interface PlayerAvatarProps {
    className?: string,
    url: string
    style?: React.CSSProperties
    selected?: boolean
}

export const SlatePlayerAvatar: React.FC<PlayerAvatarProps> = ({ className, url, style, selected }) => {
    return <div
        className={cn("tw-w-[2.8rem] tw-h-[2.8rem] tw-rounded-full tw-relative tw-flex tw-items-center tw-justify-center tw-overflow-clip tw-transition-all",
            selected ? "tw-bg-secondary-600" : "tw-bg-grey-150",
            className)}
        style={{
            ...style,
            boxShadow: selected ? "-5.83px -2.33px 5.83px 0px #00000040 inset" : "none"
        }}
    >
        <img className="tw-w-[90%] tw-h-[90%] tw-object-cover"
             src={url}
             alt="player-avatar" />
    </div>;
};
