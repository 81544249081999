import { ClaimedCountUp } from "domain/task/components/Claimed/ClaimedCountUp";
import { analyticsEvent, logCustomEvent } from "ga";
import { Icon } from "helpers/IconHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";
import AmountDisplay from "../../../../containers/amount/AmountDisplay";
import { getCurrencyIconSuitByEntryFeeType } from "../../../../containers/amount/CurrencyIconSuitHelper";
import { WALLET_TYPE } from "data/dto/wallet/walletHistoryDto";

export enum CLAIMED_BLOCK_TYPE {
    COIN = "coin",
    RUBY = "ruby"
}

interface ClaimedBlockProps {
    amount: number
    type: CLAIMED_BLOCK_TYPE
}

const ClaimedInfoMap = {
    [CLAIMED_BLOCK_TYPE.COIN]: {
        icon: Icon.Coin,
        title: <FormattedMessage id="label_deposit" />,
        walletType: WALLET_TYPE.COIN,
        entryFeeType: ENTRY_FEE_TYPE_FROM_API.DEPOSIT
    },
    [CLAIMED_BLOCK_TYPE.RUBY]: {
        icon: Icon.Ruby,
        title: <FormattedMessage id="label_ruby" />,
        walletType: WALLET_TYPE.RUBY,
        entryFeeType: ENTRY_FEE_TYPE_FROM_API.RUBY
    }
};

const logClaimedBlockCustomEvent = (walletType) => {
    switch (walletType) {
        case WALLET_TYPE.COIN:
            return logCustomEvent(analyticsEvent.webTaskDeposit);
        case WALLET_TYPE.RUBY:
            return logCustomEvent(analyticsEvent.webTaskRuby);
        default:
            return;
    }
};

const ClaimedBlock: React.FC<ClaimedBlockProps> = ({ amount, type }) => {
    const { icon: RewardIcon, title, walletType, entryFeeType } = ClaimedInfoMap[type];
    const history = useFantasyHistory();

    const handleClick = () => {
        logClaimedBlockCustomEvent(walletType);
        history.push(ROUTER_PATH_CONFIG.wallet, {
            category: walletType,
            navbarBackGoTo: history.location.pathname
        });
    };

    return <div
        data-testid={`claimed-block-${type}`}
        className="clickable"
        style={{ flex: "1 1 0" }}
        onClick={handleClick}
    >
        <AmountDisplay currencyIconSuit={getCurrencyIconSuitByEntryFeeType(entryFeeType)}
                       className="tw-items-baseline tw-gap-0">
            <ClaimedCountUp amount={amount} />
        </AmountDisplay>
        <div className="d-flex align-items-center justify-content-center gap-2 text-text-tertiary-1">
            <RewardIcon size={16} />
            <div className="fz-sf-regular-subhead">{title}</div>
            <Icon.ArrowRight />
        </div>
    </div>;
};

export default React.memo(ClaimedBlock);
