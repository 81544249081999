import MdCloseButton from "components/buttons/MdCloseButton";
import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { Icon, kycVerificationSuccessful } from "helpers/IconHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import React from "react";
import { FormattedMessage } from "react-intl";

const KycVerificationSuccess = () => {

    const history = useFantasyHistory();

    const handleClose = () => {
        history.goBack();
    };

    return (
        <div
            className="tw-flex tw-flex-col tw-px-[2rem] tw-pt-[1.2rem] tw-gap-y-[2.8rem] tw-bg-grey-50 tw-h-full">
            <div className="tw-flex tw-justify-end">
                <MdCloseButton onClose={handleClose} />
            </div>
            <div className="tw-flex tw-items-start">
                <Icon.DailyLogo className={"tw-px-[0.4rem] tw-py-[0.6rem] tw-mr-[0.8rem]"} />
                <div className={"tw-text-h6-medium"}>
                    <FormattedMessage id="kyc_verification_process" />
                </div>
            </div>
            <div>
                <div className="tw-text-h6 tw-font-bold tw-text-success-800 tw-text-center tw-mb-2">
                    <FormattedMessage id="label_verification_successful" />
                </div>
                <div className="tw-w-[18.4rem] tw-mx-auto">
                    <img className="tw-w-full tw-h-auto"
                         src={kycVerificationSuccessful}
                         alt="verification-in-progress" />
                </div>
            </div>
            <div className="tw-px-[1.2rem]">
                <div className="tw-text-subtitle-1 tw-flex tw-flex-col tw-whitespace-pre-line">
                    <FormattedMessage id="verfication_successful_content" />
                </div>
            </div>
            <Button variant={NEW_BUTTON_VARIANTS.primaryDark}
                    pattern={BUTTON_PATTERN.rounded}
                    size={BUTTON_SIZE.lg}
                    onClick={handleClose}
            >
                <div className="tw-flex tw-justify-center tw-items-center tw-text-h6-medium tw-gap-2">
                    <FormattedMessage id="kyc_verification_successful_enjoy" />
                    <Icon.Congrats />
                </div>
            </Button>
        </div>
    );
};

export default KycVerificationSuccess;
