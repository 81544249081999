import Chip from "components/chip/Chip";
import { ChipFor, ChipStyle } from "components/chip/type";
import ContestCodePanel from "components/panel/contestCode";
import AmountDisplay from "containers/amount/AmountDisplay";
import { getCurrencyIconSuitByEntryFeeType } from "containers/amount/CurrencyIconSuitHelper";
import { WalletPanel } from "domain/wallet/components/WalletPanel";
import useUpdateWallet from "domain/wallet/useUpdateWallet";
import { DISPLAY_DEPOSIT_LIMIT, isExceedDisplayDepositLimit } from "domain/wallet/WalletHelper";
import { Icon } from "helpers/IconHelper";
import { isNegative } from "helpers/number/NumberHelper";
import { getQuery } from "helpers/queryString";
import useCurrency from "hooks/useCurrency/useCurrency";
import useFantasyHistory from "hooks/useFantasyHistory";
import React, { CSSProperties, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootStoreState } from "store/reducer";
import { ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";

interface TopNavbarWalletButtonProps {
    chipFor?: ChipFor;
}

const DisplayWalletTotal: React.FC<{ walletTotal: number }> = ({ walletTotal }) => {
    const { currentCurrencyObject: { toWalletNumber, toFormattedNumber } } = useCurrency();

    const displayDeposit = isExceedDisplayDepositLimit(walletTotal)
        ? `${toFormattedNumber(DISPLAY_DEPOSIT_LIMIT)}+`
        : toWalletNumber(walletTotal);

    return <AmountDisplay currencyIconSuit={getCurrencyIconSuitByEntryFeeType(ENTRY_FEE_TYPE_FROM_API.DEPOSIT)}
                          className="tw-text-inherit tw-gap-0">
        {displayDeposit}
    </AmountDisplay>;
};

const TopNavbarWalletButton: React.FC<TopNavbarWalletButtonProps> = ({
                                                                         chipFor = ChipFor.ON_DARK
                                                                     }) => {
    const history = useFantasyHistory();
    const updateWallet = useUpdateWallet();
    const { WalletState: { total } } = useSelector((store: RootStoreState) => store);
    const { wallet: queryOpenWallet } = getQuery();
    const [openWallet, setOpenWallet] = useState(() => !!queryOpenWallet);

    const [walletPanelStyle, setWalletPanelStyle] = useState<CSSProperties>({});

    useEffect(() => {
        if (queryOpenWallet) {
            history.replace({
                search: ""
            });
        }
    }, [history, queryOpenWallet]);

    const onWalletButtonClick = () => {
        setOpenWallet(!openWallet);
    };

    const contestCodePanel = ContestCodePanel.useContestCodePanel();

    const handleWithdrawButtonClick = () => {
        setOpenWallet(false);

        history.push(ROUTER_PATH_CONFIG.withdraw);
    };

    const handleInvitationCircleButtonClick = () => {
        setOpenWallet(false);
        contestCodePanel.open();
    };

    const handleWalletOverlayClick = () => {
        setOpenWallet(false);
    };

    useEffect(() => {
        const rect = document.querySelector(".top-navbar")?.getBoundingClientRect();

        if (!rect) return;

        const rectFromTopDistance = rect.y + rect.height;
        setWalletPanelStyle({
            left: 0,
            top: rectFromTopDistance,
            height: `calc(100vh - ${rectFromTopDistance}px)`
        });

    }, []);

    useEffect(() => {
        updateWallet();
    }, [updateWallet]);

    return <>
        <Chip for={chipFor}
              style={isNegative(total) ? ChipStyle.ERROR : ChipStyle.PRIMARY}
              onClick={onWalletButtonClick}
              prefixIcon={Icon.Wallet}
        >
            <DisplayWalletTotal walletTotal={total} />
        </Chip>
        {
            openWallet && <WalletPanel
                walletPanelStyle={walletPanelStyle}
                handleWalletOverlayClick={handleWalletOverlayClick}
                handleInvitationCircleButtonClick={handleInvitationCircleButtonClick}
                handleWithdrawButtonClick={handleWithdrawButtonClick}
            />

        }
        <ContestCodePanel {...contestCodePanel} />
    </>;
};

export default TopNavbarWalletButton;
