import { isMatchKickoff, isMatchPreparing, isMatchUpcoming } from "helpers/match/MatchHelper";
import React from "react";
import { useStartedMatchDetailData } from "../../providers/MyStartedMatchDataProvider";
import { useStartedMatchDetailFlow } from "../../providers/MyStartedMatchFlowProvider";
import StartedMatchMyTeams from "./StartedMatchMyTeams";
import UpcomingMatchMyTeams from "./UpcomingMatchMyTeams";

const MyTeamsSwitcher = () => {
    const startedMatchDetailData = useStartedMatchDetailData();
    const match = startedMatchDetailData.match!;
    const myTeams = startedMatchDetailData.myTeams!;

    const { handleCreateTeam, handleEditTeam, sport } = useStartedMatchDetailFlow();

    if (isMatchUpcoming(match.matchStatus) || isMatchPreparing(match.matchStatus)) return null;

    if (isMatchKickoff(match.matchStatus)) {
        return <UpcomingMatchMyTeams match={match}
                                     teams={myTeams}
                                     toEditTeam={handleEditTeam}
                                     toCreateTeam={handleCreateTeam} />;
    }

    return <StartedMatchMyTeams match={match} myTeams={myTeams} sport={sport} />;
};

export default MyTeamsSwitcher;
