import { useLocaleProvider } from "LocaleProvider";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Navbar from "components/container/navbar/Navbar";
import { checkMarkPrimaryIcon } from "helpers/IconHelper";
import { languages, LOCALE } from "locales";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { SYSTEM_COLORS } from "styles/Constants";
import {
    ActiveSelection,
    ActiveSelectionText,
    CheckWrapper,
    CurrencyAndLocalePageWrapper,
    Selection,
    SelectionText,
    SubmitButton,
    Text,
    Title
} from "domain/currency/CurrencyStyle";
import useFantasyHistory from "hooks/useFantasyHistory";

const Language = () => {
    const history = useFantasyHistory();
    const { locale, setLocale } = useLocaleProvider();

    const [selectedLanguage, setSelectedLanguage] = useState(locale);

    const onSubmit = (language: LOCALE) => {
        setLocale(language);
        history.push(ROUTER_PATH_CONFIG.me.index);
    };

    return <>
        <Navbar
            navBackIconColor={SYSTEM_COLORS.primary80}
            navbarCenter={<Title><FormattedMessage id="language_setting_page_title" /></Title>}
        />
        <CurrencyAndLocalePageWrapper>
            <Text><FormattedMessage id="language_setting_page_label" /></Text>
            <div>
                {
                    languages.map(({ key, text }) => {
                        return selectedLanguage === key
                            ? <ActiveSelection key={key}>
                                <ActiveSelectionText>{text}</ActiveSelectionText>
                                <CheckWrapper>
                                    <img src={checkMarkPrimaryIcon} alt="check" />
                                </CheckWrapper>
                            </ActiveSelection>
                            : <Selection key={key} onClick={() => setSelectedLanguage(key)}>
                                <SelectionText>{text}</SelectionText>
                            </Selection>;
                    })
                }
            </div>
            <SubmitButton onClick={() => onSubmit(selectedLanguage)}>
                <FormattedMessage id="setting_button_save_changed" />
            </SubmitButton>
        </CurrencyAndLocalePageWrapper>
    </>;
};

export default Language;
