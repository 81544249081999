import { TOAST_TYPE } from "designToken/alert/components/AlertHelper";
import { auth } from "domain/auth/AuthConfig";
import { emailSentMessage } from "domain/me/profile/Profile";
import {
    ChangePasswordButton,
    ChangePasswordContainer,
    ChangePasswordContent,
    ChangePasswordDivider,
    ChangePasswordInput,
    ChangePasswordOverlay,
    ChangePasswordTitle,
    ChangePasswordWrapper,
    FormContainer,
    PasswordContainer,
    StyledFormLabel,
    StyledPassword
} from "domain/me/profile/ProfileStyle";
import { sendPasswordResetEmail } from "firebase/auth";
import React, { useState } from "react";
import { MdOutlineClose, MdOutlineLockReset } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { addErrorPopup, addToast } from "store/actions/AlertActions";
import Portal from "../../../components/portal/Portal";
import { createErrorObject } from "../../../helpers/ErrorHelper";
import { SYSTEM_COLORS } from "../../../styles/Constants";

export interface PasswordProps {
    email: string,
    canChangePassword: boolean
}

const Password: React.FC<PasswordProps> = ({
                                               email,
                                               canChangePassword
                                           }) => {
    const dispatch = useDispatch();

    const intl = useIntl();

    const [openModal, setOpenModal] = useState(false);

    const handleSendEmail = () => {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                setOpenModal(false);
                dispatch(addToast({
                    type: TOAST_TYPE.SUCCESS,
                    content: emailSentMessage
                }));
            })
            .catch((_error) => {
                dispatch(addErrorPopup(createErrorObject(_error)));
            });
    };

    return <>
        <FormContainer data-testid="change-password-button">
            <StyledFormLabel><FormattedMessage id="label_password" /></StyledFormLabel>
            <PasswordContainer>
                <StyledPassword
                    type="text"
                    defaultValue="*********"
                    disabled
                    color={canChangePassword
                        ? SYSTEM_COLORS.primary80
                        : SYSTEM_COLORS.labelLightQuaternary}
                />
                {
                    canChangePassword &&
                    <MdOutlineLockReset
                        className="tw-text-primary-600 tw-cursor-pointer"
                        size={"1.6rem"}
                        onClick={() => setOpenModal(true)} />
                }
            </PasswordContainer>
        </FormContainer>
        {
            openModal && <Portal>
                <ChangePasswordWrapper>
                    <ChangePasswordOverlay onClick={() => setOpenModal(false)} />
                    <ChangePasswordContainer>
                        <MdOutlineClose size={"2rem"}
                                        className={"ms-auto d-block"} onClick={() => setOpenModal(false)} />
                        <ChangePasswordTitle>
                            <FormattedMessage id="change_password_dialog_title" />
                        </ChangePasswordTitle>
                        <ChangePasswordContent>
                            <FormattedMessage id="change_password_dialog_label" />
                        </ChangePasswordContent>
                        <StyledFormLabel>
                            <FormattedMessage id="label_email" />
                        </StyledFormLabel>
                        <ChangePasswordInput type="email" placeholder={intl.formatMessage({ id: "label_email" })}
                                             defaultValue={email}
                                             disabled />
                        <ChangePasswordDivider />
                        <ChangePasswordButton onClick={handleSendEmail}>
                            <FormattedMessage id="change_password_dialog_button_send_email" />
                        </ChangePasswordButton>
                    </ChangePasswordContainer>
                </ChangePasswordWrapper>
            </Portal>
        }
    </>;
};

export default Password;
