import Carousel from "components/carousel";
import ShareContestPopup from "components/popup/shareContestPopup/ShareContestPopup";
import ResultContestCardSkeleton from "components/skeleton/ContestCardSkeleton/ResultContestCardSkeleton";
import { ContestDetailVo } from "data/vo/contest/ContestDetailVo";
import { ContestDetailCard } from "domain/contest/components/ContestDetailCard";
import { ContestDetailWrapper } from "domain/contest/components/ContestStyle";
import useDevice from "hooks/useDevice/useDevice";
import { findIndex } from "lodash";
import React, { useState } from "react";
import { SwiperSlide } from "swiper/react";
import { AP_LEVEL } from "types/activityPoint/ApLevel";
import { CONTEST_STATUS_FROM_API, CONTEST_TYPE_FROM_API, ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";
import { ContestDetailVO } from "types/contest/ContestDetail";
import { MATCH_STATUS_FROM_API } from "types/match/Match";

export interface ContestsProps {
    id: number;
    teams: number;
    regularPoolCent: number;
    hostDonatedPoolCent: number;
    entryFeeCent: number;
    entryFeeType: ENTRY_FEE_TYPE_FROM_API;
    name: string;
    spots: number;
    status: CONTEST_STATUS_FROM_API;
    type: CONTEST_TYPE_FROM_API;
    minApLevel: AP_LEVEL;
}

export const ContestCarousel: React.FC<{
    handleCardChange: (swiper: any) => void;
    contests: ContestsProps[];
    currentContest: ContestDetailVo | ContestDetailVO;
    isContestLoading?: boolean;
    matchStatus: MATCH_STATUS_FROM_API;
}> = ({ handleCardChange, contests, currentContest, matchStatus, isContestLoading }) => {

    const { isDesktop } = useDevice();


    const [copyModalShow, setCopyModalShow] = useState(false);

    function onCopyModalShow() {
        setCopyModalShow(true);
    }

    function onCopyModalHide() {
        setCopyModalShow(false);
    }

    return <>
        <ContestDetailWrapper>
            <Carousel className="custom-swiper"
                      slidesPerView={1}
                      centeredSlides={true}
                      navigation={isDesktop}
                      pagination={!isDesktop && { clickable: true }}
                      onRealIndexChange={handleCardChange}
                      initialSlide={findIndex(contests, { "id": currentContest?.id })}>
                {
                    contests.map(contest => {
                        return <SwiperSlide key={contest.id} className="rounded-0 px-4">
                            {
                                isContestLoading ? <ResultContestCardSkeleton /> : (
                                    <ContestDetailCard
                                        contest={contest}
                                        onCopyModalShow={onCopyModalShow}
                                        matchStatus={matchStatus}
                                    />
                                )
                            }

                        </SwiperSlide>;
                    })
                }
            </Carousel>
        </ContestDetailWrapper>

        <ShareContestPopup
            show={copyModalShow}
            code={currentContest.code}
            onHide={onCopyModalHide}
            contestId={currentContest.id}
        />
    </>;
};
