import { LeaderboardDto } from "../../dto/leaderboard/LeaderboardDto";
import { LeaderboardTeamDto } from "../../dto/leaderboard/LeaderboardTeamDto";
import { LeaderboardTeamVo } from "./LeaderboardTeamVo";

export interface LeaderboardRenderItem {
    isLastPrizesTeam: boolean;
    participants: number;
    team: LeaderboardTeamVo;
}

export class LeaderboardVo {
    joinedTeams: number;
    prizeTeams: number;
    lastPrizesTeam: LeaderboardTeamVo | null;
    topTeams: LeaderboardTeamVo[];

    constructor(dto: LeaderboardDto) {
        this.joinedTeams = dto.joinedTeams;
        this.prizeTeams = dto.prizeTeams;
        this.lastPrizesTeam = dto.lastPrizesTeam && new LeaderboardTeamVo(dto.lastPrizesTeam);
        this.topTeams = dto.topTeams.map((team: LeaderboardTeamDto) => new LeaderboardTeamVo(team));
    }

    get isPractice(): boolean {
        return this.lastPrizesTeam === null;
    }

    get mergedTeams(): LeaderboardRenderItem[] {
        const lastPrizesTeamInTopTeams =
            !!this.lastPrizesTeam &&
            this.topTeams.find(team => team.teamId === this.lastPrizesTeam?.teamId);

        const hasMoreParticipants = this.prizeTeams - this.topTeams.length > 0;

        const mergedTeams = !lastPrizesTeamInTopTeams && !!this.lastPrizesTeam ? [...this.topTeams, this.lastPrizesTeam] : this.topTeams;

        return mergedTeams.map(team => ({
            isLastPrizesTeam: !this.isPractice && team.teamId === this.lastPrizesTeam?.teamId,
            participants: hasMoreParticipants && team.teamId === this.lastPrizesTeam?.teamId ? this.prizeTeams - this.topTeams.length - 1 : 0,
            team,
        }));
    }
}