import styled from "styled-components";

export const DailyTaskTitle = styled.div.attrs({
    className: "fz-sf-bold-title-3 text-text-primary-1 bg-default-primary-1 position-sticky"
})`
  top: 6.4rem;
  z-index: 2;
  padding: 2.4rem 1.6rem 0.8rem 1.6rem;
  border-radius: 1.6rem 1.6rem 0 0;
`;
export const DailyTaskWrapper = styled.div.attrs({
    className: "bg-default-primary-1 d-flex flex-column gap-3"
})`
  min-height: calc(100vh - 12.1rem);
  padding: 0 1.6rem 2.4rem 1.6rem;
`;
export const CarouselWrapper = styled.div.attrs({
    className: "position-relative overflow-hidden"
})`
  user-select: none;

  .swiper {
  }

  .swiper-pagination {
    bottom: 5rem;
  }

  .swiper-slide {
    width: auto;
    min-width: auto;
    align-items: start;
    text-align: start;
    display: block;
  }
`;
