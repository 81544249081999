import styled from "styled-components";

export const ErrorTitle = styled.div.attrs(() => ({
    className: "tw-text-system-primary80"
}))`
    margin-bottom: .8rem;
`;
export const ErrorStatus = styled.div.attrs(() => ({
    className: "tw-text-system-primary80"
}))`
    font-size: 9.9rem;
    font-weight: 600;
    font-family: 'Barlow', sans-serif;
    line-height: 1;
    margin-bottom: 2.4rem;
`;
export const ErrorParameterImg = styled.div`
    width: 90%;
    padding-bottom: calc(90% * 246 / 350);
    background-image: url(${({ img }) => img});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin: 0 auto 0.8rem auto;
`;
export const ErrorText = styled.div.attrs(() => ({
    className: "fz-sf-regular-subhead tw-text-system-labelLightSecondary"
}))`
`;
