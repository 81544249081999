import { Callback } from "Common";
import { NINE_CAT_STAT } from "domain/slate/data/ComponentTypes";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { SWAP_ACTION, SwapCardAction } from "./SwapCardAction";

interface CreateSlateTeamContextProps {
    handleToggleFocusPlayer: (playerId: string) => void;
    handleToggleFocusStat: (stat: NINE_CAT_STAT | undefined) => void;
    handleResetFocus: Callback;
    handleClearStatPlayerIdMap: Callback;
    setStatToPlayerIdMap: (statToPlayerIdMap: Record<string, string>) => void;

    focusedStat: NINE_CAT_STAT | undefined;
    focusedPlayerId: string | undefined;
    statToPlayerIdMap: Record<string, string>;
    hasSelectPlayer: boolean;

    getFocusedStatPlayerId(): any;
}

const CreateSlateTeamContext = createContext<CreateSlateTeamContextProps | null>(null);

interface CreateSlateTeamProviderProps {
    children: React.ReactNode;
}

const initFocusedStat = undefined;
const initFocusedPlayerId = undefined;

export const CreateNineCatTeamProvider: React.FC<CreateSlateTeamProviderProps> = ({ children }) => {
    const [statToPlayerIdMap, setStatToPlayerIdMap] = useState<Record<string, string>>({});
    const [focusedStat, setFocusedStat] = useState<NINE_CAT_STAT | undefined>(initFocusedStat);
    const [focusedPlayerId, setFocusedPlayerId] = useState<string | undefined>(initFocusedPlayerId);

    const hasSelectPlayer = useMemo(() => {
        return Object.keys(statToPlayerIdMap).length > 0;
    }, [statToPlayerIdMap]);

    function checkCardsDiffAndNotAssigned(prev: string | undefined, next: string) {
        return prev && prev !== next && (!statToPlayerIdMap[prev] && !statToPlayerIdMap[next]);
    }

    function getFocusedStatPlayerId() {
        return statToPlayerIdMap[focusedStat!];
    }

    function handleToggleFocusAndUpdateIdMap({
                                                 prevId, nextId, swapCardAction, onFocus, checkFocusedInOtherPart
                                             }: {
        prevId: string | undefined,
        nextId: string,
        swapCardAction: SwapCardAction,
        onFocus: Callback<[string]>,
        checkFocusedInOtherPart: Callback<[], boolean>
    }) {
        if (checkCardsDiffAndNotAssigned(prevId, nextId)) return;
        if (checkFocusedInOtherPart()) return;

        if (prevId === nextId) {
            handleResetFocus();
            return;
        }

        if (swapCardAction.getAction() === SWAP_ACTION.DO_NOTHING) {
            onFocus(nextId);
            return;
        }

        handleResetFocus();
        setStatToPlayerIdMap(_statToPlayerIdMap => (
            swapCardAction.updateMap(_statToPlayerIdMap)
        ));

    }

    function handleToggleFocusPlayer(nextPlayerId: string) {
        handleToggleFocusAndUpdateIdMap({
            prevId: focusedPlayerId,
            nextId: nextPlayerId,
            swapCardAction: new SwapCardAction({
                prevStat: focusedStat,
                prevPlayerId: focusedPlayerId,
                nextPlayerId: nextPlayerId,
            }),
            onFocus: setFocusedPlayerId,
            checkFocusedInOtherPart: () => getFocusedStatPlayerId() === nextPlayerId
        });
    }

    function handleToggleFocusStat(nextStat: NINE_CAT_STAT | undefined) {
        handleToggleFocusAndUpdateIdMap({
            prevId: focusedStat,
            nextId: nextStat!,
            swapCardAction: new SwapCardAction({
                prevStat: focusedStat,
                nextStat,
                prevPlayerId: focusedPlayerId,
            }),
            onFocus: (stat: string) => setFocusedStat(stat as NINE_CAT_STAT),
            checkFocusedInOtherPart: () => statToPlayerIdMap[focusedPlayerId!] === nextStat
        });
    }


    function handleResetFocus() {
        setFocusedStat(undefined);
        setFocusedPlayerId(undefined);
    }

    function handleClearStatPlayerIdMap() {
        setStatToPlayerIdMap({});
    }

    useEffect(() => {
        handleResetFocus();
    }, [JSON.stringify(statToPlayerIdMap)]);

    const value = useMemo<CreateSlateTeamContextProps>(() => ({
        focusedStat,
        focusedPlayerId,
        statToPlayerIdMap,
        hasSelectPlayer,

        handleToggleFocusPlayer,
        handleToggleFocusStat,
        handleResetFocus,
        handleClearStatPlayerIdMap,
        setStatToPlayerIdMap,
        getFocusedStatPlayerId
    }), [
        focusedStat,
        focusedPlayerId,
        statToPlayerIdMap,
        hasSelectPlayer,

        handleToggleFocusPlayer,
        handleToggleFocusStat,
        handleResetFocus,
        handleClearStatPlayerIdMap,
        setStatToPlayerIdMap
    ]);

    return (
        <CreateSlateTeamContext.Provider value={value}>
            {children}
        </CreateSlateTeamContext.Provider>
    );
};


export const useCreateSlateTeamContext = () => {
    const context = useContext(CreateSlateTeamContext);
    if (!context) {
        throw new Error("useCreateSlateTeamCtx must be used within a CreateSlateTeamProvider");
    }
    return context;
};
