import { GameLogDto, PlayerInfoDto } from "data/dto/player/PlayerInfoDto";
import { BasketballGameLogVo } from "data/vo/player/gameLog/BasketballGameLogVo";
import { CricketGameLogVo } from "data/vo/player/gameLog/CricketGameLogVo";
import { FootballGameLogVo } from "data/vo/player/gameLog/FootballGameLogVo";
import { BasketballPerformanceVo } from "data/vo/player/performance/BasketballPerformanceVo";
import { CricketPerformanceVo } from "data/vo/player/performance/CricketPerformanceVo";
import { FootballPerformanceVo } from "data/vo/player/performance/FootballPerformanceVo";
import { PlayerInfoVo } from "data/vo/player/PlayerInfoVo";
import { BasketballPlayerInfoProfileVo } from "data/vo/player/profile/BasketballPlayerInfoProfileVo";
import { CricketPlayerInfoProfileVo } from "data/vo/player/profile/CricketPlayerInfoProfileVo";
import { FootballPlayerInfoProfileVo } from "data/vo/player/profile/FootballPlayerInfoProfileVo";
import { omit } from "lodash";
import { SPORT } from "types/sports/Sport";

export const playerInfoFactory = (sport: SPORT, playerInfo: PlayerInfoDto) => {
    const sportToProfileMap = {
        [SPORT.CRICKET]: CricketPlayerInfoProfileVo,
        [SPORT.FOOTBALL]: FootballPlayerInfoProfileVo,
        [SPORT.BASKETBALL]: BasketballPlayerInfoProfileVo
    };

    const sportToPerformanceMap = {
        [SPORT.CRICKET]: CricketPerformanceVo,
        [SPORT.FOOTBALL]: FootballPerformanceVo,
        [SPORT.BASKETBALL]: BasketballPerformanceVo
    };

    const sportToGameLogMap = {
        [SPORT.CRICKET]: CricketGameLogVo,
        [SPORT.FOOTBALL]: FootballGameLogVo,
        [SPORT.BASKETBALL]: BasketballGameLogVo
    };

    const flatGameLogs = (gameLogs: GameLogDto[]) => {
        return gameLogs.map(log => new sportToGameLogMap[sport]({
            ...omit(log, "pointCentDetail"),
            ...log.pointCentDetail
        }));
    };


    const profile = new sportToProfileMap[sport](playerInfo.profile);
    const performance = playerInfo.vsOpponentPerformance ? new sportToPerformanceMap[sport](playerInfo.vsOpponentPerformance) : null;
    const gameLogs = flatGameLogs(playerInfo.gameLogs);

    return new PlayerInfoVo(sport,
        playerInfo.oppositeSquadCode,
        profile,
        performance,
        gameLogs,
        playerInfo.tournament
    );


};
