export class WithdrawAmountInfoVo {
    withdrawAmount: number;
    transactionFee: number;

    constructor(withdrawAmount: number, transactionFee: number) {
        this.withdrawAmount = withdrawAmount;
        this.transactionFee = transactionFee;
    }

    get receiveAmount() {
        return this.withdrawAmount - this.transactionFee;
    }
}
