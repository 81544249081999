import Carousel from "components/carousel";
import { EmptySlateCard } from "domain/slate/components/EmptySlateCard";
import { HomeSlateCard } from "domain/slate/components/HomeSlateCard";
import { UpcomingNineCatVo } from "domain/slate/data/vo";
import SlateRepository from "domain/slate/repository/SlateRepository";
import { cn } from "helpers/cn";
import { slateBasketballBg } from "helpers/IconHelper";
import { Device } from "hooks/useDevice/type";
import useDevice from "hooks/useDevice/useDevice";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import React from "react";
import { Pagination } from "swiper/modules";
import { SwiperSlide } from "swiper/react";


const SingleNineCatSection: React.FC<{ slate: UpcomingNineCatVo | undefined }> = ({ slate }) => {
    return <div className="tw-px-4 tw-flex tw-gap-4" data-testid="single-slate-section">
        <div style={{ flex: 5 }}>
            {
                slate
                    ? <HomeSlateCard slate={slate} />
                    : <EmptySlateCard />
            }
        </div>
        <div className="tw-relative" style={{ flex: 4 }}>
            <div className="tw-w-[14.6rem] tw-h-[14.6rem] tw-absolute tw-right-[-1.6rem] tw-bottom-[-2.4rem]">
                <img className="tw-w-full tw-h-full tw-object-cover" src={slateBasketballBg} alt="slate-bg" />
            </div>
        </div>
    </div>;
};

const MultipleNineCatSection: React.FC<{ slates: UpcomingNineCatVo[] }> = ({ slates }) => {
    const { device } = useDevice();
    const isDeviceOverMobile = device !== Device.MOBILE;

    return <Carousel className="tw-px-4 custom-swiper"
                     slidesPerView={2.7}
                     navigation={false}
                     pagination={isDeviceOverMobile && { clickable: true }}
                     spaceBetween={isDeviceOverMobile ? -24 : 16}
                     modules={[Pagination]}>
        {
            slates.map((slate, key) => {
                return <SwiperSlide key={key} className={cn({
                    "!tw-justify-start": isDeviceOverMobile,
                })}>
                    <HomeSlateCard slate={slate} className="tw-max-w-[16rem]" />
                </SwiperSlide>;
            })
        }
    </Carousel>;

};


const HomeNineCat = () => {
    const slateRepository = new SlateRepository();

    const { data: nineCats, isLoading } = useFantasyQuery([QUERY_KEY.UPCOMING_SLATE], () => {
        return slateRepository.getUpcomingSlates();
    });

    if (isLoading || !nineCats) {
        return null;
    }

    return (
        // TODO: marginTop is temp, will be removed after 9-Cat final
        <section className="tw-py-6 tw-bg-grey-100 tw-mt-[2.8rem]">
            <h6 className="tw-px-4 tw-text-subtitle-1 tw-font-bold tw-text-grey-1000 tw-mb-1">9-Cat</h6>
            {
                nineCats.length <= 1
                    ? <SingleNineCatSection slate={nineCats[0]} />
                    : <MultipleNineCatSection slates={nineCats} />
            }
        </section>
    );
};

export default HomeNineCat;
