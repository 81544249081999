import { useContext } from "react";
import { ApiExecuteContext } from "../../../../../apis/ApiContext";
import { useLoadingToggle } from "../../../../../components/loading/Loading";
import { addCampaign } from "../../../../../apis/campaign/CampaignApi";

export const useParticipateCampaign = (onAddCampaignSuccess, onAddCampaignFail) => {
    const apiExecutor = useContext(ApiExecuteContext);
    const toggleLoading = useLoadingToggle();

    return (redemptionCode) => {
        toggleLoading(true);
        apiExecutor(
            addCampaign(redemptionCode),
            {
                onSuccess: onAddCampaignSuccess,
                onFail: onAddCampaignFail,
                onFinally: () => {
                    toggleLoading(false);
                }
            }
        );
    };
};
