import { PerformanceVo } from "data/vo/player/performance/PerformanceVo";
import { Performance } from "data/vo/player/PlayerInfoVo";
import { divideByOneHundred } from "helpers/number/NumberHelper";

export class FootballPerformanceVo extends PerformanceVo {
    private readonly _averageGoalPointsCent: number;
    private readonly _averageAssistPointsCent: number;

    constructor(performance: Performance) {
        super(performance);
        this._averageGoalPointsCent = performance.averageGoalPointsCent!;
        this._averageAssistPointsCent = performance.averageAssistPointsCent!;
    }

    get averageAssistPoints(): number {
        return Math.floor(divideByOneHundred(this._averageAssistPointsCent));
    }

    get averageGoalPoints(): number {
        return Math.floor(divideByOneHundred(this._averageGoalPointsCent));
    }

}
