import { MatchComponentStatusEnum, MyMatchVo } from "data/vo/match/MyMatchVo";
import { SPORT } from "types/sports/Sport";
import { chain } from "lodash";

export class MyMatchesVo {
    public readonly datas: MyMatchVo[];

    private constructor(myMatches: MyMatchVo[]) {
        this.datas = myMatches;
    }

    public static create(myMatches: MyMatchVo[]) {
        return new MyMatchesVo(myMatches);
    }

    public static empty() {
        return new MyMatchesVo([]);
    }

    public select = (sports: SPORT[]): MyMatchVo[] => {
        return this.datas.filter(match => sports.includes(match.sport));
    };

    public group = (sports: SPORT[]) => {
        const data = chain(this.select(sports))
            .groupBy("group")
            .value();

        return {
            live: data[MatchComponentStatusEnum.LIVE] || [],
            upcoming: data[MatchComponentStatusEnum.UPCOMING] || [],
            completed: data[MatchComponentStatusEnum.COMPLETED] || []
        };
    };
}
