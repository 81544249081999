import SlateRepository from "domain/slate/repository/SlateRepository";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import { NineCatPlayerDetailVO } from "../data/ComponentTypes";

export function useQuerySlatePlayers(slateId: number) {

    const players = useFantasyQuery<NineCatPlayerDetailVO[]>([QUERY_KEY.GET_SLATE_PLAYERS, slateId], () => {
        return new SlateRepository().getSlatePlayers(slateId);
    }, {
        options: {
            staleTime: 1000 * 60 * 5,
            enabled: slateId !== undefined
        }
    });
    return ({
        ...players,
        data: players.data || []
    });
}
