import { Callback } from "Common";
import PlayerAvatar from "components/image/PlayerAvatar";
import { InjuryLabel } from "domain/slate/components/InjuryLabel";
import { NINE_CAT_STAT, NineCatPlayerDetailVO, NineCatPlayerInfoVO } from "domain/slate/data/ComponentTypes";
import { useCreateSlateTeamContext } from "domain/slate/providers/CreateNineCatTeamProvider";
import { cn } from "helpers/cn";
import { Icon } from "helpers/IconHelper";
import React from "react";
import { MouseEventHandler } from "react/index";
import { PlayerCardAnimatedPing } from "./PlayerCardAnimatedPing";

export interface NineCatPlayerCardProps {
    focused?: boolean;
    selectedStat?: NINE_CAT_STAT;
    statForChosen?: NINE_CAT_STAT;
    sortSeq?: string;
    playerDetail: NineCatPlayerDetailVO;

    onFocusStatPlayer: Callback<[NineCatPlayerInfoVO["id"]]>;
    onOpenPlayerInfo: Callback<[NineCatPlayerInfoVO]>;
}

const NineCatPlayerCard: React.FC<NineCatPlayerCardProps> = (props) => {
    const {
        focused,
        statForChosen,
        selectedStat,
        playerDetail,
        onFocusStatPlayer,
        onOpenPlayerInfo,
        sortSeq,
    } = props;

    const handleOpenPlayerInfo: MouseEventHandler<HTMLElement> = e => {
        e.stopPropagation();
        onOpenPlayerInfo(playerDetail);
    };

    const handleChoosePlayer: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation();
        onFocusStatPlayer(playerDetail.id);
    };

    const statText = selectedStat ? selectedStat : statForChosen;

    const slateContext = useCreateSlateTeamContext();

    function checkShouldShowAnimation(): boolean {
        if (focused) return false;
        if (slateContext.getFocusedStatPlayerId() === playerDetail.id) {
            return false;
        }

        return !!(slateContext.focusedPlayerId || slateContext.focusedStat);
    }

    const shouldShowAnimation = checkShouldShowAnimation();

    return (
        <div
            data-testid={"nine-cat-player-card"}
            className={cn(
                "tw-relative",
                "tw-flex tw-flex-col tw-justify-between",
                "tw-max-w-[17.6rem] tw-rounded-3xl tw-overflow-clip",
                "tw-cursor-pointer tw-select-none",
                "tw-outline tw-outline-2",
                selectedStat ? "tw-bg-tertiary-850/25" : "tw-bg-grey-150 ",
                focused ? "tw-outline-secondary-600" : "tw-outline-transparent",
                selectedStat && focused ? "tw-outline-tertiary-600" : "",
            )}
            onClick={handleChoosePlayer}
        >
            <div className={"tw-p-2 tw-flex tw-items-center tw-gap-1 tw-justify-between"}>
                <div className="tw-w-[4.8rem] tw-h-[4.8rem] tw-shrink-0" onClick={handleOpenPlayerInfo}
                     data-testid="player-info-button">
                    <div
                        className={cn("tw-rounded-full tw-overflow-clip",
                            !!selectedStat ? "tw-bg-tertiary-700" : "tw-bg-primary-800",
                        )}
                    >
                        <PlayerAvatar id={playerDetail.id} url={playerDetail.avatar} />
                    </div>
                </div>
                <div className={"tw-text-body-2 tw-font-medium tw-text-center tw-max-w-[6rem]"}>
                    <p className={"tw-truncate"}>
                        {playerDetail.firstName}
                    </p>
                    <p className={"tw-truncate"}>
                        {`${playerDetail.middleName}${playerDetail.lastName}`}
                    </p>
                </div>
                <button
                    className={cn("tw-flex tw-flex-col tw-items-center tw-shrink-0 tw-justify-center tw-w-9 tw-h-9 tw-rounded-xl ",
                        selectedStat ? "tw-text-tertiary-700" : "",
                        focused ? "tw-bg-secondary-600 tw-text-white" : "tw-bg-grey-100",
                        selectedStat && focused ? "tw-bg-tertiary-600 tw-text-white" : "",
                    )}
                >
                    {selectedStat ? (
                        <Icon.Refresh size={"1.4rem"} />
                    ) : (
                        <Icon.Plus size={"2rem"} />
                    )}
                    {shouldShowAnimation && <PlayerCardAnimatedPing />}
                    {statText && (
                        <span className={cn(
                            "tw-text-caption-2 tw-leading-none tw-font-light",
                            selectedStat ? "tw-font-bold" : ""
                        )}>
                            {statText}
                        </span>
                    )}
                </button>
            </div>
            <div className={cn(
                "tw-px-3 tw-py-1 tw-flex tw-justify-between",
                !!selectedStat ? "tw-bg-white/50" : "tw-bg-grey-50"
            )}>
                <div className={"tw-flex tw-items-center tw-text-caption-2"}>
                    <div
                        className={"tw-flex tw-items-center tw-gap-1 tw-pr-2 "}>
                        <img className={"tw-w-3 tw-h-3"} alt={playerDetail.squadName} src={playerDetail.squadLogoUrl} />
                        <span>{playerDetail.squadName}</span>
                    </div>
                    <div className={"tw-px-1 tw-border-grey-150 tw-border-solid tw-border-x-2"}>
                        {playerDetail.roleAbbr}
                    </div>
                    {sortSeq && (
                        <div className={"tw-pl-1"}>
                            {sortSeq}
                        </div>
                    )}
                </div>
                <InjuryLabel injuryPlayingChange={playerDetail.injuryPlayingChance} />
            </div>
        </div>
    );
};

export default NineCatPlayerCard;
