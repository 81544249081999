import { loginUrl } from "apis/ApiUrlConfig";
import { ajax } from "apis/ApiUtils";
import { FeatureConfig } from "store/reducer/config/FantasyGameConfigState";

export interface LoginData {
    siteId: number
}

export const login = async (data?: LoginData): Promise<LoginResultDto> => {
    return new Promise((resolve, reject) => {
        ajax<LoginResultDto>({
            method: "POST",
            url: loginUrl,
            data
        })
            .then(({ data }) => resolve(data.data))
            .catch(error => reject(error));
    });
};


interface LoginResultDto {
    id: string,
    loginType: string,
    newUser: boolean,
    featureConfig: FeatureConfig
}
