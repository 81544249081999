import { UserTaskProgressVo } from "data/vo/task/UserTaskProgressVo";
import { orderBy } from "lodash";
import { AP_LEVEL } from "types/activityPoint/ApLevel";
import { TASK_RESET_TYPE, TASK_TYPE } from "types/task/TaskType";
import { TaskConfig } from "data/dto/task/TaskDto";
import { en } from "locales/en";

export interface TaskInfoVo {
    ruby: number;
    coin: number;
    maxClaimedLimit: number;
    apLevelWeight: number;
}

export type TaskInfoVoMap = {
    [key in AP_LEVEL]: TaskInfoVo
}

export class TaskVo {
    public readonly taskInfo: TaskInfoVoMap;
    public readonly taskType: TASK_TYPE;
    public readonly taskResetType: TASK_RESET_TYPE;
    public readonly code: string;
    public readonly taskConfig: TaskConfig | null;

    constructor(taskInfo: TaskInfoVoMap, taskType: TASK_TYPE, taskResetType: TASK_RESET_TYPE, code: string, taskConfig: TaskConfig | null) {
        this.taskInfo = taskInfo;
        this.taskType = taskType;
        this.taskResetType = taskResetType;
        this.code = code;
        this.taskConfig = taskConfig;
    }

    public static sortTask = (tasks: TaskVo[], userTaskProgress: UserTaskProgressVo, formatMessage: (id: string) => string) => {
        const sortByStatus = (task: TaskVo) => {
            return userTaskProgress.getTaskProgress(task.taskType, task.code).isCompleted;
        };

        const sortByResetType = (task: TaskVo) => {
            const taskProgress = userTaskProgress.getTaskProgress(task.taskType, task.code);

            if (taskProgress.isCompleted) {
                return task.taskResetType !== TASK_RESET_TYPE.DAILY;
            }

            return task.taskResetType !== TASK_RESET_TYPE.NEVER;
        };

        const sortByType = (task: TaskVo) => {
            return task.taskType;
        };

        const sortByCode = (task: TaskVo) => {
            return task.code;
        };

        return orderBy(tasks, [sortByStatus, sortByResetType, sortByType, sortByCode]);
    };

    public getTaskInfo(apLevel: AP_LEVEL) {
        return this.taskInfo[apLevel];
    }

    public getTaskTitleLocaleId() {
        if (en[`daily_task_item_title_${this.taskType.toLowerCase()}_${this.code.toLowerCase()}`]) {
            return `daily_task_item_title_${this.taskType.toLowerCase()}_${this.code.toLowerCase()}`;
        }

        return `daily_task_item_title_${this.taskType.toLowerCase()}`;
    }

    public getTaskDescriptionLocaleId() {
        if (en[`daily_task_item_description_${this.taskType.toLowerCase()}_${this.code.toLowerCase()}`]) {
            return `daily_task_item_description_${this.taskType.toLowerCase()}_${this.code.toLowerCase()}`;
        }

        return `daily_task_item_description_${this.taskType.toLowerCase()}`;
    }
}

