import {
    ButtonWrapper,
    CloseButton,
    ImageWrapper,
    TextWrapper
} from "components/appDownloadDialog/AppDownloadDialogStyle";
import MdCloseButton from "components/buttons/MdCloseButton";
import Dialog from "designToken/dialog/Dialog";
import { downloadOnAppStoreImg, downloadOnGooglePlayImg, welcomeImg } from "helpers/IconHelper";
import React from "react";
import { FormattedMessage } from "react-intl";
import useFantasyConfig from "../../hooks/useFantasyConfig";

interface AppDownloadDialogProps {
    show: boolean,
    onHide: () => void
}

const AppDownloadDialog: React.FC<AppDownloadDialogProps> = ({ show, onHide }) => {
    const { googlePlay, appStore } = useFantasyConfig().appDownloadLink;

    return <Dialog
        show={show}
        onHide={onHide}
    >
        <CloseButton>
            <MdCloseButton
                customClassname={"text-text-primary-1"}
                onClose={onHide} />
        </CloseButton>

        <ImageWrapper>
            <img src={welcomeImg} className="img-fluid" alt="welcome" />
        </ImageWrapper>

        <TextWrapper>
            <FormattedMessage id="download_app_dialog_context" />
        </TextWrapper>

        <ButtonWrapper>
            {
                googlePlay && <a href={googlePlay} target="_blank" rel="noreferrer" className="text-center">
                    <img src={downloadOnGooglePlayImg} className="img-fluid" alt="download-on-google-play" />
                </a>
            }
            {
                appStore && <a href={appStore} target="_blank" rel="noreferrer" className="text-center">
                    <img src={downloadOnAppStoreImg} className="img-fluid" alt="download-on-app-store" />
                </a>
            }
        </ButtonWrapper>
    </Dialog>;
};

export default AppDownloadDialog;
