import Banner, { BANNER_VARIANTS } from "designToken/banner/Banner";
import { Icon } from "helpers/IconHelper";
import { FormattedMessage } from "react-intl";
import React from "react";

export const AwaitBanner: React.FC = () => {
    return <Banner
        variant={BANNER_VARIANTS.primary}
        prefixIcon={Icon.Verifying}
    >
        <FormattedMessage id="kyc_await_hint" />
    </Banner>;
};
