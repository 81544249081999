import UserAvatarWithFrame from "components/avatar/UserAvatarWithFrame";
import Navbar from "components/container/navbar/Navbar";
import { MyRefereeSummaryVo } from "data/vo/referral/MyRefereeSummaryVo";
import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";
import ReferralCounterBar from "domain/me/referral/components/ReferralCounterBar";
import {
    RefereeListItemWrapper,
    RefereeListPromotionText,
    RefereeListWrapper,
    ReferralButtonWrapper,
    RubyProgressBar
} from "domain/me/referral/components/ReferralStyle";
import { useReferralProvider } from "domain/me/referral/ReferralProvider";
import SYSTEM_LINKS from "helpers/LinkHelper";
import { multiplyByOneHundred, } from "helpers/number/NumberHelper";
import { sortByRubyAndNickname } from "helpers/SortingHelper";
import { formatToFullYearDate } from "helpers/TimeHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import useFantasyHistory, { checkNoHistory } from "hooks/useFantasyHistory";
import useLatestJoinedReferee from "hooks/useLatestRefereeJoin";
import useQueryCurrentLevel from "hooks/useQueryCurrentLevel";
import useScrollDetector from "hooks/useScrollDetector";
import { useLocaleProvider } from "LocaleProvider";
import React, { useCallback, useRef } from "react";
import { MdInfoOutline } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { Link, Redirect } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { getUserInfoPageUrl } from "router/RouterUrls";
import { AP_LEVEL } from "types/activityPoint/ApLevel";

const NavbarEndIcon = () => {
    const { locale } = useLocaleProvider();

    return <a href={SYSTEM_LINKS.referAFriend(locale)}
              target="_blank" rel="noreferrer">
        <MdInfoOutline size="2.4rem" className="text-text-primary-1" />
    </a>;
};

const ScrolledNavbar = () => {
    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();

    const scrolledNavbar = useRef(null);

    const shouldShowScrolledNavbar = useScrollDetector();

    const { myRefereeSummaries, totalReceivedRuby } = useReferralProvider();

    return <CSSTransition in={shouldShowScrolledNavbar}
                          nodeRef={scrolledNavbar}
                          classNames="my-matches-navbar"
                          timeout={400}
                          unmountOnExit
    >
        <Navbar ref={scrolledNavbar}
                isTransparent={false}
                isHeaderLine={true}
                navBackIconColor="text-light-primary"
                shouldCalculatePaddingTop={false}
                navbarStartAppend={<div className="d-flex fz-sf-bold-caption-1 text-text-primary-1">
                    <div>
                        <FormattedMessage id="label_referrals" />: {myRefereeSummaries.length}
                    </div>
                    <div className="mx-2">|</div>
                    <div>
                        <FormattedMessage
                            id="invite_friend_page_label_received" />: {toFormattedNumber(totalReceivedRuby)}
                    </div>

                </div>}
                endIcon={<NavbarEndIcon />}
        />
    </CSSTransition>;
};

interface RefereeItemProps {
    user: MyRefereeSummaryVo;
    currentLevel: AP_LEVEL;
}

const RefereeItem: React.FC<RefereeItemProps> = ({ user, currentLevel }) => {
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();
    const { maxRubyPerReferee } = useReferralProvider();

    return (
        <RefereeListItemWrapper data-referee-id={user.userId}>
            <Link to={getUserInfoPageUrl(user.userId)}>
                <UserAvatarWithFrame
                    url={user.avatar}
                    name={user.nickname}
                    size="5.8rem"
                    font="fz-sf-bold-title-1"
                    currentLevel={currentLevel}
                />
            </Link>

            <div className="flex-grow-1">
                <div className="fz-sf-bold-body-2 text-truncate w-100 text-light-primary">
                    {user.nickname}
                </div>
                <RubyProgressBar now={multiplyByOneHundred(user.ruby / maxRubyPerReferee)} />
                <div className="d-flex justify-content-between fz-sf-regular-caption-1">
                    <div className="text-light-secondary">
                        <FormattedMessage
                            id="ruby_list_page_ruby_item_label_received"
                            values={{
                                number: toWalletNumber(user.ruby)
                            }}
                        ></FormattedMessage>
                    </div>
                    <div className="text-primary-3">
                        {formatToFullYearDate(user.registeredAt)}
                    </div>
                </div>
            </div>
        </RefereeListItemWrapper>
    );
};

export const RefereeList: React.FC = () => {
    const history = useFantasyHistory();

    const { myRefereeSummaries, maxRubyPerRefereeText, latestReferralTime } = useReferralProvider();

    const { saveLatestReferralTime } = useLatestJoinedReferee(latestReferralTime);

    saveLatestReferralTime();

    const {
        userIdToCurrentLevel,
    } = useQueryCurrentLevel(myRefereeSummaries.map((user) => user.userId));

    const handleGoToInviteFriends = useCallback(() => {
        if (checkNoHistory(history.location)) {
            history.push(ROUTER_PATH_CONFIG.me.referral.index);
            return;
        }
        history.goBack();
    }, [history]);

    if (myRefereeSummaries.length === 0) {
        return <Redirect to={ROUTER_PATH_CONFIG.me.referral.index} />;
    }

    return (
        <>
            <ScrolledNavbar />
            <Navbar className="position-relative"
                    navbarCenter={
                        <div className={"text-center fz-sf-bold-title-3 text-text-primary-1"}>
                            <FormattedMessage id="label_referral_program" />
                        </div>
                    }
                    endIcon={<NavbarEndIcon />}
                    navBackIconColor="text-text-primary-1"
                    isTransparent={false}
                    shouldCalculatePaddingTop={false}
            />
            <RefereeListWrapper>
                <ReferralCounterBar />
                <RefereeListPromotionText>
                    <FormattedMessage id="ruby_list_page_label_get_more_bonus" values={{
                        maxRuby: maxRubyPerRefereeText
                    }} />
                </RefereeListPromotionText>
                <div>
                    {sortByRubyAndNickname(myRefereeSummaries).map((user) => {
                        const currentLevel = userIdToCurrentLevel ? userIdToCurrentLevel[user.userId] : AP_LEVEL.BEGINNER;

                        return <RefereeItem key={user.userId}
                                            user={user}
                                            currentLevel={currentLevel} />;
                    })}
                </div>
                <ReferralButtonWrapper>
                    <Button size={BUTTON_SIZE.lg}
                            className="w-100"
                            variant={BUTTON_VARIANTS.outlinePrimary}
                            pattern={BUTTON_PATTERN.pill}
                            onClick={handleGoToInviteFriends}
                    >
                        <FormattedMessage id="ruby_list_page_button_invite_more_friends" />
                    </Button>
                </ReferralButtonWrapper>
            </RefereeListWrapper>
        </>
    );
};
