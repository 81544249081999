import PlayerAvatar from "components/image/PlayerAvatar";
import { isMatchJoinable } from "helpers/match/MatchHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import React from "react";
import { BADGE_PATTERN, BADGE_SIZE, BADGE_VARIANTS } from "../../../designToken/badge/Badge";
import { PLAYER_TYPE } from "../../../domain/team/OperateTeamHelper";
import { MATCH_STATUS_FROM_API } from "../../../types/match/Match";
import { LINEUP, SquadPlayer } from "../../../types/player/Player";
import {
    CaptainIconWrapper,
    CreditWrapper,
    Dot,
    PlayerCol,
    PlayerIconWrapper,
    PlayerName,
    PlayerNameWrapper,
    PlayerState,
    ViceCaptainIconWrapper
} from "./TeamPreviewStyle";

const typeToIcon = {
    [PLAYER_TYPE.CAPTAIN]: () => <CaptainIconWrapper>C</CaptainIconWrapper>,
    [PLAYER_TYPE.VICE_CAPTAIN]: () => <ViceCaptainIconWrapper>VC</ViceCaptainIconWrapper>,
    [PLAYER_TYPE.GENERAL]: () => null
};


interface PreviewPlayerProps {
    player: SquadPlayer;
    matchStatus: MATCH_STATUS_FROM_API;
    squadACode?: string;
    onAvatarClick: Function;
}

const PreviewPlayer = ({ player, matchStatus, squadACode, onAvatarClick }: PreviewPlayerProps) => {
    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();
    const showDot = () => {
        if (isMatchJoinable(matchStatus)) {
            return player.lineup !== LINEUP.STARTING_LINEUP;
        }

        return false;
    };

    const state = isMatchJoinable(matchStatus)
        ? `${player.credit} Cr`
        : `${toFormattedNumber(player.score)} Pts`;

    return <PlayerCol key={player.id} onClick={() => onAvatarClick(player)}>
        <PlayerIconWrapper>
            {typeToIcon[player.type]()}
            <PlayerAvatar id={player.id} url={player.avatar} />
        </PlayerIconWrapper>
        <PlayerNameWrapper variant={player.squadCode === squadACode ? BADGE_VARIANTS.LIGHT : BADGE_VARIANTS.DARK}
                           size={BADGE_SIZE.MD} pattern={BADGE_PATTERN.ROUNDED}>
            {
                showDot() && <Dot className="position-absolute bg-sys-danger top-0 start-0 translate-middle"
                                  data-testid="lineupDot" />
            }
            <PlayerName>{player.name}</PlayerName>
        </PlayerNameWrapper>
        <CreditWrapper>
            <PlayerState>{state}</PlayerState>
        </CreditWrapper>
    </PlayerCol>;
};

export default PreviewPlayer;

