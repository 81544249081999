import React from "react";
import { Area, AreaChart, ResponsiveContainer } from "recharts";

interface PerformanceProps {
    data: { points: number }[],
    height: number;
}

export const Performance: React.FC<PerformanceProps> = ({ data, height }) => {
    const dataKey = "points";

    return <ResponsiveContainer width={"100%"} height={height}>
        <AreaChart
            data={data}>
            <defs>
                <linearGradient id={dataKey} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="45%" stopColor="#93AAFD"
                          stopOpacity={0.33} />
                    <stop offset="70%" stopColor="#C6D2FD"
                          stopOpacity={0.33} />
                    <stop offset="100%" stopColor="#E5EAFC4F"
                          stopOpacity={0.1} />
                </linearGradient>
            </defs>
            <Area dataKey={dataKey} stroke="#353781" strokeWidth={2}
                  fillOpacity={1}
                  fill="url(#points)" />
        </AreaChart>
    </ResponsiveContainer>;
};


