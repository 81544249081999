import useFantasyQuery from "../../../hooks/useFantasyQuery";
import { useParams } from "react-router-dom";
import SlateRepository from "../repository/SlateRepository";
import { sortPublicContests } from "../../match/pages/upcoming/contest/public/PublicContest";

export function useQueryPublicContests() {
    const { slateId } = useParams();
    const query = useFantasyQuery([slateId], () => new SlateRepository().getContests(slateId));
    const contests = query.data || [];
    return ({
        ...query,
        data: sortPublicContests(contests),
    });

}
