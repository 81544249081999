import React, { useEffect, useRef } from "react";
import { cn } from "../../helpers/cn";
import { howToPlayBgCricketPlayer, howToPlayStep4Video } from "../../helpers/IconHelper";
import { StepNumber } from "domain/howToPlay/components/StepNumber";
import { StepText } from "domain/howToPlay/components/StepText";
import { FormattedMessage } from "react-intl";

const checkElScrolled = (step4WrapperElRef: HTMLElement) => {
    const elClientY = step4WrapperElRef.getBoundingClientRect().y;
    const containerHeight = document.scrollingElement?.clientHeight || 0;
    return elClientY < containerHeight;
};

export const Step4 = () => {
    const videoElRef = useRef<HTMLVideoElement>(null);
    const step4WrapperElRef = useRef<HTMLDivElement>(null);

    const handlePlayStep4Video = () => {
        if (!videoElRef.current) return;
        videoElRef.current.play();
    };

    const handleScrollToPlayVideo = () => {
        if (!step4WrapperElRef.current) return;
        if (!videoElRef.current?.paused) return;

        const isScrolledToStep4 = checkElScrolled(step4WrapperElRef.current);
        if (isScrolledToStep4) {
            handlePlayStep4Video();
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScrollToPlayVideo);
        return () => {
            window.removeEventListener('scroll', handleScrollToPlayVideo);
        };
    }, []);

    return <div ref={step4WrapperElRef} className="tw-relative tw-w-full tw-flex tw-flex-col">
        <StepBackground />

        <div className="tw-z-1 tw-flex tw-flex-col tw-gap-6">
            <StepNumber number={4} />
            <div className={"tw-max-w-[30rem] tw-m-auto"}>
                <StepText
                    title={<FormattedMessage id={'how_to_play_step_4_title'} />}
                    subtitle={<FormattedMessage id={'how_to_play_step_4_subtitle'} />}
                />
            </div>
            <div className={cn(
                "tw-px-[3.6rem] xs:tw-px-[4.6rem] app-max:tw-px-[5rem]",
            )}>
                <video
                    className={cn(
                        "tw-rounded-[1rem] tw-overflow-clip",
                        "tw-border-grey-200 tw-border-solid tw-border-[1px]"
                    )}
                    ref={videoElRef}
                    controls={false}
                    loop={true}
                    autoPlay={false}
                    muted={true}
                    src={howToPlayStep4Video}
                />
            </div>
        </div>
    </div>;
};

const StepBackground = () => {
    return <img
        alt=""
        src={howToPlayBgCricketPlayer}
        className={cn(
            "tw-absolute -tw-right-[2.1rem]"
        )}
    />;
};
