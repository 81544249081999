import { GameLogVo } from "data/vo/player/gameLog/GameLogVo";
import { FlattedGameLogProps } from "data/vo/player/PlayerInfoVo";
import { divideByOneHundred } from "helpers/number/NumberHelper";

export class BasketballGameLogVo extends GameLogVo {
    private readonly _personalFoulsCent: number;
    private readonly _minutesPlayed: number;
    private readonly _pointsCent: number;
    private readonly _assistsCent: number;
    private readonly _stealsCent: number;
    private readonly _blocksCent: number;
    private readonly _turnoversCent: number;
    private readonly _reboundsCent: number;
    private readonly _fieldGoalsMadeCent: number;
    private readonly _fieldGoalsAttemptedCent: number;
    private readonly _freeThrowsMadeCent: number;
    private readonly _freeThrowsAttemptedCent: number;
    private readonly _offensiveReboundsCent: number;
    private readonly _defensiveReboundsCent: number;
    private readonly _threePointersMadeCent: number;

    constructor(gameLog: FlattedGameLogProps) {
        super(gameLog);
        this._personalFoulsCent = gameLog.personalFoulsCent ?? 0;
        this._minutesPlayed = gameLog.minutesPlayed ?? 0;
        this._pointsCent = gameLog.pointsCent ?? 0;
        this._reboundsCent = gameLog.reboundsCent ?? 0;
        this._assistsCent = gameLog.assistsCent ?? 0;
        this._stealsCent = gameLog.stealsCent ?? 0;
        this._blocksCent = gameLog.blocksCent ?? 0;
        this._turnoversCent = gameLog.turnoversCent ?? 0;
        this._fieldGoalsMadeCent = gameLog.fieldGoalsMadeCent ?? 0;
        this._fieldGoalsAttemptedCent = gameLog.fieldGoalsAttemptedCent ?? 0;
        this._freeThrowsMadeCent = gameLog.freeThrowsMadeCent ?? 0;
        this._freeThrowsAttemptedCent = gameLog.freeThrowsAttemptedCent ?? 0;
        this._offensiveReboundsCent = gameLog.offensiveReboundsCent ?? 0;
        this._defensiveReboundsCent = gameLog.defensiveReboundsCent ?? 0;
        this._threePointersMadeCent = gameLog.threePointersMadeCent ?? 0;
    }

    get rebounds(): number {
        return divideByOneHundred(this._reboundsCent);
    }

    get turnovers(): number {
        return divideByOneHundred(this._turnoversCent);
    }

    get blocks(): number {
        return divideByOneHundred(this._blocksCent);
    }

    get steals(): number {
        return divideByOneHundred(this._stealsCent);
    }

    get assists(): number {
        return divideByOneHundred(this._assistsCent);
    }

    get points(): number {
        return divideByOneHundred(this._pointsCent);
    }

    get minutesPlayed(): number | null {
        return this._minutesPlayed;
    }

    get personalFouls(): number | null {
        return divideByOneHundred(this._personalFoulsCent);
    }

    get fieldGoalsMade(): number {
        return divideByOneHundred(this._fieldGoalsMadeCent);
    }

    get fieldGoalsPercentage(): string {
        if (this._fieldGoalsAttemptedCent === 0) return "0%";

        return `${(this._fieldGoalsMadeCent / this._fieldGoalsAttemptedCent * 100).toFixed(1)}`;
    }

    get freeThrowsMade(): number {
        return divideByOneHundred(this._freeThrowsMadeCent);
    }

    get freeThrowsPercentage(): string {
        if (this._freeThrowsAttemptedCent === 0) return "0%";

        return `${(this._freeThrowsMadeCent / this._freeThrowsAttemptedCent * 100).toFixed(1)}`;
    }

    get offensiveRebounds(): number {
        return divideByOneHundred(this._offensiveReboundsCent);
    }

    get defensiveRebounds(): number {
        return divideByOneHundred(this._defensiveReboundsCent);
    }

    get threePointersMade(): number {
        return divideByOneHundred(this._threePointersMadeCent);
    }

}
