export interface BasketballPlayerNewsDto {
    title: string
    content: string
    source: string
    date: number
}

export class BasketballPlayerNewsVo {
    public readonly title: string;
    public readonly content: string;
    public readonly source: string;
    public readonly date: number;

    constructor(news: BasketballPlayerNewsDto) {
        this.title = news.title;
        this.content = news.content;
        this.source = news.source;
        this.date = news.date;
    }
}