import RewardCoins from "domain/reward/component/RewardCoins";
import {
    Country,
    CountryImage,
    GrayScaleWrapper,
    InfoWrapper,
    PreviewImage,
    RewardDetail,
    RewardInfoTitle
} from "domain/reward/component/RewardInfoStyle";
import {
    EmptyRewardOrderImage,
    EmptyRewardOrderText,
    EmptyRewardOrderWrapper
} from "domain/reward/orders/RewardOrderHistoryListStyle";
import {
    RewardOrderCard,
    RewardOrderCardCode,
    RewardOrderCardRow,
    RewardOrderCardStatus,
    RewardOrderCardTime,
    RewardOrderCardTitle
} from "domain/reward/orders/RewardOrderHistoryStyle";
import { getLabelI18nText, isCoinReward } from "domain/reward/RewardHelper";
import { RewardOrderCardWrapperProps, RewardStatusStyle } from "domain/reward/type/type";
import { getCountryIcon, noRewardImage } from "helpers/IconHelper";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import { formatToFullYearDateTime } from "helpers/TimeHelper";
import React from "react";
import { FormattedMessage } from "react-intl";
import { REWARD_ORDER_STATUS } from "types/reward/Reward";

const statusMap: { [key in REWARD_ORDER_STATUS]: RewardStatusStyle } = {
    [REWARD_ORDER_STATUS.COMPLETED]: {
        text: <FormattedMessage id="label_completed" />,
        statusColor: "text-light-secondary",
        codeColor: "text-light-secondary",
        createTimeColor: "text-light-secondary",
        priceColor: "text-light-secondary",
        imageGrayScale: 1
    },
    [REWARD_ORDER_STATUS.PENDING]: {
        text: <FormattedMessage id="label_pending" />,
        statusColor: "text-sys-warning-1",
        codeColor: "text-primary-2",
        createTimeColor: "text-light-secondary",
        priceColor: "text-secondary-1",
        imageGrayScale: 0
    },
    [REWARD_ORDER_STATUS.CANCELLED]: {
        text: <FormattedMessage id="label_cancelled" />,
        statusColor: "text-sys-danger-1",
        codeColor: "text-sys-danger-1",
        createTimeColor: "text-sys-danger-1",
        priceColor: "text-light-secondary",
        imageGrayScale: 1
    }
};

const RewardInfo = ({ reward, statusStyle }) => {
    if (isCoinReward(reward.country)) {
        return <InfoWrapper>
            <GrayScaleWrapper grayscale={statusStyle.imageGrayScale}>
                <PreviewImage previewUrl={reward.previewUrl} />
            </GrayScaleWrapper>
            <RewardDetail>
                <RewardInfoTitle>{reward.title}</RewardInfoTitle>
                <GrayScaleWrapper grayscale={statusStyle.imageGrayScale}>
                    <RewardCoins price={divideByOneHundred(reward.priceCent)}
                                 numberFontStyle="fz-sf-bold-title-3"
                    />
                </GrayScaleWrapper>
            </RewardDetail>
        </InfoWrapper>;
    }


    return <InfoWrapper>
        <GrayScaleWrapper grayscale={statusStyle.imageGrayScale}>
            <CountryImage src={getCountryIcon(reward.country)} />
            <PreviewImage previewUrl={reward.previewUrl} />
        </GrayScaleWrapper>
        <RewardDetail>
            <div>
                <RewardInfoTitle>{reward.title}</RewardInfoTitle>
                <Country>
                    <FormattedMessage id="reward_order_country" />
                    :&nbsp;
                    {getLabelI18nText(reward.country)}
                </Country>
            </div>
            <GrayScaleWrapper grayscale={statusStyle.imageGrayScale}>
                <RewardCoins price={divideByOneHundred(reward.priceCent)}
                             numberFontStyle="fz-sf-bold-title-3"
                />
            </GrayScaleWrapper>
        </RewardDetail>
    </InfoWrapper>;

};

const RewardOrderCardWrapper: React.FC<RewardOrderCardWrapperProps> = ({ reward, statusStyle }) => {

    return <RewardOrderCard data-testid="reward-item">
        <RewardOrderCardRow>
            <RewardOrderCardTitle>
                <RewardOrderCardCode className={statusStyle.codeColor}>{reward.code}</RewardOrderCardCode>
                <RewardOrderCardStatus
                    className={statusStyle.statusColor}>{statusStyle.text}</RewardOrderCardStatus>
            </RewardOrderCardTitle>
            <RewardOrderCardTime
                className={statusStyle.createTimeColor}>
                <FormattedMessage
                    id="reward_order_history_page_label_order_date" />: {formatToFullYearDateTime(reward.createTime)}
            </RewardOrderCardTime>
        </RewardOrderCardRow>
        <RewardInfo reward={{
            ...reward,
            title: reward.rewardTitle,
            priceCent: reward.rewardPriceCent
        }} statusStyle={statusStyle} />
    </RewardOrderCard>;
};

const EmptyRewardOrderLayout = ({ text }) => {
    return <EmptyRewardOrderWrapper>
        <EmptyRewardOrderImage defaultImg={noRewardImage} />
        <EmptyRewardOrderText>{text}</EmptyRewardOrderText>
    </EmptyRewardOrderWrapper>;
};

const RewardOrderHistoryList = ({ rewardOrderList, emptyText }) => {
    return rewardOrderList?.length
        ? rewardOrderList.map((reward, index) => {
            return <RewardOrderCardWrapper key={index}
                                           reward={reward}
                                           statusStyle={statusMap[reward.status]} />;
        })
        : <EmptyRewardOrderLayout text={emptyText} />;
};

export default RewardOrderHistoryList;
