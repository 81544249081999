import { FormattedMessage } from "react-intl";
import { TextAreaField } from "components/reactHookFormField/TextAreaField";
import React from "react";

export const ChangeNicknameDialogContent = () => {
    return <div className="tw-gap-[1.2rem] tw-flex tw-flex-col">
        <div className="tw-w-full tw-text-center tw-text-h3 tw-font-bold tw-text-primary-800">
            <FormattedMessage id="change_nickname_dialog_title" />
        </div>
        <div className="tw-flex tw-flex-col gap-3 tw-text-center">
            <div className="tw-text-subtitle-2 tw-font-light tw-text-grey-1000">
                <FormattedMessage id="change_nickname_dialog_content" />
            </div>
            <TextAreaField
                data-testid="nickname-input"
                maxLength={30}
                name="nickname"
                className="tw-w-full tw-py-[0.8rem] tw-px-[1.2rem] rounded-3 tw-text-subtitle-1 tw-text-grey-1000 tw-bg-grey-50"
            />
        </div>
    </div>;
};
