import ErrorContent from "components/error/ErrorContent";
import { ErrorPageWrapper, FromMobileAppErrorButton, Header, Logo } from "domain/error/ErrorPageStyle";
import { errorWithErrorCodeImg, logoPrimaryHorizontal } from "helpers/IconHelper";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useMobileApp } from "../../hooks/useMobileApp";

const FromMobileAppErrorPage = () => {
    const { goToApp } = useMobileApp();

    const error = {
        message: "Parameter Error",
        status: 401,
        img: errorWithErrorCodeImg
    };

    return (
        <ErrorPageWrapper>
            <Header>
                <Logo img={logoPrimaryHorizontal} />
            </Header>
            <ErrorContent error={error}
                          title={"fz-sf-bold-title-1"} />
            <FromMobileAppErrorButton onClick={() => goToApp()}>
                <FormattedMessage id="return_to_app" />
            </FromMobileAppErrorButton>
        </ErrorPageWrapper>
    );
};

export default FromMobileAppErrorPage;
