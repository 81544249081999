import { MY_MATCH_TAB, useMyMatch } from "domain/match/components/providers/MyMatchProvider";
import MyMatchFantasySports from "domain/match/components/myMatches/MyMatchFantasySports";
import MyMatchNineCat from "domain/match/components/myMatches/MyMatchNineCat";
import React from "react";

export const MyMatchContent: React.FC = () => {
    const {
        currentTab,
        refetchMyMatches,
        isLoading,
        myNineCatSlates
    } = useMyMatch();

    if (currentTab === MY_MATCH_TAB.FANTASY_SPORTS) {
        return <MyMatchFantasySports
            isLoading={isLoading}
            getMyMatches={refetchMyMatches}
        />;
    }

    return <MyMatchNineCat
        isLoading={isLoading}
        myNineCatSlates={myNineCatSlates}
    />;
};
