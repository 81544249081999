import {
    NavbarBack,
    NavbarBottom,
    NavbarCenter,
    NavbarContainer,
    NavbarEnd,
    NavbarLine,
    NavbarStart,
    NavbarWrapper
} from "components/container/navbar/NavbarStyle";
import WalletButton from "domain/wallet/components/button/WalletButton";
import { cn } from "helpers/cn";
import useFantasyHistory from "hooks/useFantasyHistory";
import React, { ReactNode, useEffect } from "react";

const setPaddingTop = () => {
    const nav = document.querySelector(".navbar-wrapper");
    const pageWrapper = document.querySelector(".page-wrapper");
    if (nav && pageWrapper && pageWrapper instanceof HTMLElement) {
        const { height } = nav.getBoundingClientRect();
        pageWrapper.style.paddingTop = height + "px";
    }
};

const clearPaddingTop = () => {
    const div = document.querySelector(".page-wrapper") as HTMLElement;
    if (div && div instanceof HTMLElement) {
        div.style.paddingTop = "0";
    }
};

const DefaultStartIcon = ({ navBackIconColor, onNavbarBackClick }) => {
    const history = useFantasyHistory();

    return <NavbarBack
        $color={navBackIconColor}
        onClick={() => onNavbarBackClick === null ? history.goBack() : onNavbarBackClick()}
    />;
};

interface NavbarProps {
    navbarStartAppend?: ReactNode
    navbarStart?: boolean
    showWallet?: boolean
    startIcon?: ReactNode
    navbarCenter?: ReactNode
    navbarBottom?: ReactNode
    onNavbarBackClick?: Function | null,
    endIcon?: ReactNode
    navbarBackgroundImg?: string
    navBackIconColor?: string
    isTransparent?: boolean
    isNavbarBottomTransparent?: boolean
    isHeaderLine?: boolean
    constantHeight?: string | null
    bgImgPosition?: string
    className?: string
    shouldCalculatePaddingTop?: boolean
    backgroundOpacity?: number
    isConstantShadow?: boolean
}

const Navbar = React.forwardRef(({
                                     navbarStartAppend = null,
                                     navbarStart = true,
                                     startIcon = null,
                                     navbarCenter = null,
                                     navbarBottom = null,
                                     onNavbarBackClick = null,
                                     showWallet = false,
                                     endIcon = null,
                                     navbarBackgroundImg = "",
                                     navBackIconColor = "",
                                     isTransparent = false,
                                     isNavbarBottomTransparent = false,
                                     isHeaderLine = true,
                                     constantHeight = null,
                                     bgImgPosition = "center top",
                                     className = "",
                                     shouldCalculatePaddingTop = true,
                                     backgroundOpacity = 1,
                                     isConstantShadow = false
                                 }: NavbarProps, ref: React.ForwardedRef<HTMLDivElement>) => {

    useEffect(() => {
        if (shouldCalculatePaddingTop) {
            setPaddingTop();
            window.addEventListener("resize", setPaddingTop);
        }


        return () => {
            if (shouldCalculatePaddingTop) {
                clearPaddingTop();
                window.removeEventListener("resize", setPaddingTop);
            }
        };
    }, [shouldCalculatePaddingTop]);

    return (
        <NavbarWrapper ref={ref}
                       className={className}
                       $constantHeight={constantHeight}
                       $bg={navbarBackgroundImg}
                       $bgImgPosition={bgImgPosition}
                       $transparent={isTransparent}
                       $headerLine={isHeaderLine}
                       $backgroundOpacity={backgroundOpacity}
        >
            <NavbarContainer
                $transparent={isTransparent}
                className={cn(
                    "tw-pt-[2rem]",
                    { "tw-shadow-navbar": isConstantShadow }
                )}
            >
                <NavbarLine>
                    {
                        navbarStart
                            ? <NavbarStart>
                                {
                                    startIcon || <DefaultStartIcon navBackIconColor={navBackIconColor}
                                                                   onNavbarBackClick={onNavbarBackClick} />
                                }
                                {navbarStartAppend}
                            </NavbarStart>
                            : null
                    }
                    <NavbarCenter>{navbarCenter}</NavbarCenter>
                    <NavbarEnd>
                        {showWallet ? <WalletButton /> : endIcon}
                    </NavbarEnd>
                </NavbarLine>
            </NavbarContainer>
            <NavbarContainer $transparent={isTransparent || isNavbarBottomTransparent}>
                {
                    navbarBottom
                    && <NavbarBottom>{navbarBottom}</NavbarBottom>
                }
            </NavbarContainer>


        </NavbarWrapper>
    );
});

export default Navbar;
