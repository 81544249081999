import { FantasyCheckbox } from "components/checkbox/FantasyCheckbox";
import { CHECKBOX_VARIANTS } from "components/checkbox/types";
import { PlayerRow } from "domain/team/player/ChoosePlayerStyle";
import PlayerCardInfo from "domain/team/player/components/playerCard/PlayerCardInfo";
import {
    CheckboxCol,
    PlayerCardContainer,
    PlayerInfoCol,
    PlayerStatsCol
} from "domain/team/player/components/playerCard/PlayerCardStyle";
import useCurrency from "hooks/useCurrency/useCurrency";
import React from "react";
import { SelectablePlayer } from "types/player/Player";

interface PlayerCardProps {
    customPlayerInfoText?: string;
    player: SelectablePlayer;
    containerClassName?: string;
    handlePlayerCheckboxOnChange: Function;
    onAvatarClick?: Function;
}


const PlayerCard = ({
                        player,
                        customPlayerInfoText = player.squadCode,
                        handlePlayerCheckboxOnChange,
                        containerClassName,
                        onAvatarClick
                    }: PlayerCardProps) => {
    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();
    const {
        id,
        pointAvg,
        credit,
        isSelected,
        isDisabled
    } = player;

    const onPlayerClicked = () => {
        if (isDisabled) return;
        handlePlayerCheckboxOnChange(player);
    };

    return (
        <PlayerCardContainer data-testid={`player-card-${id}`}
                             className={containerClassName}
                             disabled={isDisabled}>
            <PlayerRow>
                <PlayerInfoCol className="col-7 clickable" onClick={(e) => {
                    e.preventDefault();
                    if (onAvatarClick) {
                        onAvatarClick();
                    }
                }}>
                    <PlayerCardInfo player={player} text={customPlayerInfoText} selectionRateTitle={"Sel: "} />
                </PlayerInfoCol>
                <div className="col d-flex" onClick={onPlayerClicked} data-testid={`select-player-${id}`}>
                    <PlayerStatsCol className="col-5">
                        {toFormattedNumber(pointAvg)}
                    </PlayerStatsCol>
                    <PlayerStatsCol className="col-5">
                        {credit}
                    </PlayerStatsCol>
                    <CheckboxCol className="col-2">
                        <FantasyCheckbox variant={CHECKBOX_VARIANTS.secondary} checked={isSelected && !isDisabled} />
                    </CheckboxCol>
                </div>
            </PlayerRow>
        </PlayerCardContainer>
    );
};

export default PlayerCard;
