import { CurrencyIconSuit } from "../../containers/amount/AmountDisplay";
import useFantasyConfig from "../useFantasyConfig";
import { CURRENCY_ICON_SUIT_NAME } from "../../store/reducer/config/FantasyGameConfigState";

const useHasCurrencyIcon = (currencyIconSuit: CurrencyIconSuit): boolean => {
    const { featureConfig: { currencyIconSuitName } } = useFantasyConfig();
    const icon = currencyIconSuit[currencyIconSuitName] || currencyIconSuit[CURRENCY_ICON_SUIT_NAME.DEFAULT];
    return icon !== null;
};

export default useHasCurrencyIcon;
