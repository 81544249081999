import { isMatchUpcoming } from "helpers/match/MatchHelper";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ROUTE_FROM_MY_MATCHES } from "../../../domain/match/components/myMatches/MyMatchUpcoming";
import MatchStatusWithDot from "../../../domain/match/components/status/MatchStatusWithDot";
import { useMatchKickoffDialog } from "../../../hooks/useDialog";
import { MATCH_STATUS_FROM_API } from "../../../types/match/Match";
import UpcomingCountDown from "../../countdown/UpcomingCountDown";
import { getTournamentInfoTooltip } from "../../tooltip/GetTournamentInfoTooltip";
import MatchInfo from "./MatchInfo";
import { JoinableNavbarWrapper, StyledCountDown } from "./NavbarStyle";


const JoinableNavbarMatchInfoWithTooltip = ({ sport, match = {}, handleTimeout = () => {} }) => {
    const location = useLocation();
    const handleKickoffDialog = useMatchKickoffDialog();
    const [matchStatus, setMatchStatus] = useState(match.matchStatus);
    const { matchStartsAt } = match;
    const { SettingsState: { match: { kickoffDurationMilliseconds } } } = useSelector(store => store);

    return <JoinableNavbarWrapper>
        <OverlayTrigger
            placement="bottom"
            overlay={getTournamentInfoTooltip(match)}
            transition={false}
        >
            {MatchInfo({ data: match })}
        </OverlayTrigger>
        <StyledCountDown>
            {
                isMatchUpcoming(matchStatus)
                    ? <UpcomingCountDown matchStartsAt={matchStartsAt}
                                         handleTimeout={() => {

                                             if (location.state?.from === ROUTE_FROM_MY_MATCHES) {
                                                 handleKickoffDialog(sport, match.matchId, kickoffDurationMilliseconds / (60 * 1000));
                                                 return;
                                             }

                                             setMatchStatus(MATCH_STATUS_FROM_API.KICKOFF);
                                         }} />
                    : <div className="d-flex ms-2 fz-sf-bold-subhead">
                        <MatchStatusWithDot matchStatus={matchStatus} /> &nbsp;
                        <UpcomingCountDown matchStartsAt={matchStartsAt + kickoffDurationMilliseconds}
                                           handleTimeout={handleTimeout} />
                    </div>
            }
        </StyledCountDown>
    </JoinableNavbarWrapper>;
};
export default JoinableNavbarMatchInfoWithTooltip;

JoinableNavbarMatchInfoWithTooltip.propTypes = {
    sport: PropTypes.string.isRequired,
    match: PropTypes.shape({
        matchId: PropTypes.string.isRequired,
        tournament: PropTypes.string.isRequired,
        tournamentPeriodStr: PropTypes.string,
        venue: PropTypes.string.isRequired
    }).isRequired,
    handleTimeout: PropTypes.func
};
