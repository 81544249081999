import { googleIcon } from "helpers/IconHelper";
import React from "react";
import { AiFillApple } from "react-icons/ai";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import FantasyIcon from "helpers/FantasyIcon";

const GoogleIcon = () => (
    <FantasyIcon src={googleIcon} size={"1.8rem"} />
);

interface ThirdPartyButtonProps {
    $textColor: string;
    $bgColor: string;
}

const ThirdPartyButton = styled.button.attrs<ThirdPartyButtonProps>(({ $bgColor, $textColor }) => ({
    className: `btn d-flex align-items-center justify-content-center w-100 border border-light-tertiary ${$bgColor} ${$textColor} fz-sf-regular-body-1`
}))`
    border-radius: 1rem;
    height: 5rem;
`;
const ThirdPartyButtonText = styled.div`
    margin-left: 1.0rem;
`;

interface ThirdPartyButtonsProps {
    disabled: boolean;
    onGoogleClick: () => Promise<void>;
    onAppleClick: () => Promise<void>;
    googleLabelId: string;
    appleLabelId: string;
}

export const ThirdPartyButtons: React.FC<ThirdPartyButtonsProps> = ({
    disabled,
    onGoogleClick,
    onAppleClick,
    googleLabelId,
    appleLabelId
}) => {
    return <>
        <ThirdPartyButton
            disabled={disabled}
            onClick={onGoogleClick}
            $bgColor="bg-light-primary"
            $textColor="text-light-primary"
        >
            <GoogleIcon />
            <ThirdPartyButtonText>
                <FormattedMessage id={googleLabelId} />
            </ThirdPartyButtonText>
        </ThirdPartyButton>

        <ThirdPartyButton
            disabled={disabled}
            onClick={onAppleClick}
            $bgColor="bg-dark-primary"
            $textColor="text-dark-primary"
            data-testid="apple-login-button"
        >
            <AiFillApple size="2.4rem" />
            <ThirdPartyButtonText>
                <FormattedMessage id={appleLabelId} />
            </ThirdPartyButtonText>
        </ThirdPartyButton>
    </>;
};
