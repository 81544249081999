import { AuthConfigDto, AuthModeEnum, SiteDto } from "../../dto/auth/AuthConfigDto";

export class AuthConfigVo {
    mode: AuthModeEnum;
    sites: SiteVo[];

    constructor(data: AuthConfigDto) {
        this.mode = data.mode;
        this.sites = data.sites && data.sites.map(site => new SiteVo(site));
    }

    get isPagcor() {
        return this.mode === AuthModeEnum.PAGCOR;
    }

    get isNormal() {
        return this.mode === AuthModeEnum.NORMAL;
    }
}


class SiteVo {
    id: number;
    name: string;

    constructor(site: SiteDto) {
        this.id = site.id;
        this.name = site.name;
    }
}
