import React, { useEffect, useState } from "react";
import MatchInfo from "components/container/navbar/MatchInfo";
import Navbar from "components/container/navbar/Navbar";
import { getSportPageConfig } from "domain/SportPageConfig";
import CustomTab from "components/tab/CustomTab";
import { LeaderboardContent } from "domain/result/detail/components/Leaderboard/LeaderboardContent";
import { ResultMatchDetailProvider, useResultMatchDetailContext } from "domain/result/detail/ResultMatchDetailProvider";
import { InfoContent } from "domain/result/detail/components/Info/InfoContent";
import { PlayerStatsContent } from "domain/result/detail/components/PlayerStats/PlayerStatsContent";
import { FormattedMessage } from "react-intl";
import { ResultContestVO } from "../../../types/contest/Contest";

const ResultMatchDetailTabs = (completedContests: ResultContestVO[] | undefined) => {
    const tabs = [
        {
            condition: () => completedContests === undefined ? false : completedContests.length > 0,
            getTab: {
                tabKey: "leaderboard",
                text: <FormattedMessage id="result_tab_info_leaderboard" />,
                content: LeaderboardContent
            }
        },
        {
            condition: () => true,
            getTab: {
                tabKey: "info",
                text: <FormattedMessage id="result_tab_info" />,
                content: InfoContent
            }
        },
        {
            condition: () => true,
            getTab: {
                tabKey: "playerStats",
                text: <FormattedMessage id="result_tab_player_stats" />,
                content: PlayerStatsContent
            }
        },
    ];

    return tabs.filter((t) => t.condition()).map((t) => t.getTab);
};

const ResultMatchDetail = () => {
    const { sport, match, completedContests } = useResultMatchDetailContext();

    const tabs = ResultMatchDetailTabs(completedContests);
    const [activeTab, setActiveTab] = useState(tabs[0].tabKey);

    useEffect(() => setActiveTab(tabs[0].tabKey), [completedContests]);

    if (!match) {
        return null;
    }

    const NavbarBottom = getSportPageConfig(sport).myContestDetail.navbarBottom;

    const Content = tabs.find(tab => tab.tabKey === activeTab)?.content ?? tabs[0].content;
    return <>
        <Navbar navbarStartAppend={<MatchInfo data={match} />}
                navbarBottom={<div>
                    <NavbarBottom match={match} />
                    <CustomTab
                        tabs={tabs}
                        onClick={setActiveTab}
                        activeTab={activeTab}
                    />
                </div>}
        />
        <Content />
    </>;
};

const ResultMatchDetailPage = () => {
    return <ResultMatchDetailProvider>
        <ResultMatchDetail />
    </ResultMatchDetailProvider>;
};

export default ResultMatchDetailPage;
