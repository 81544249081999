import { ApiExecuteContext } from "apis/ApiContext";
import { fetchReward } from "apis/reward/RewardApi";
import NegativeDepositAlert from "components/alert/NegativeDepositAlert";
import Carousel from "components/carousel";
import Navbar from "components/container/navbar/Navbar";
import { useLoadingToggle } from "components/loading/Loading";
import PurchaseModal from "domain/reward/component/PurchaseModal";
import RewardCoins from "domain/reward/component/RewardCoins";
import RewardQuantity from "domain/reward/component/RewardQuantity";
import { getLabelI18nText, getRewardPreviewUrls, isCoinReward, isOutOfStock } from "domain/reward/RewardHelper";
import {
    CountryTag,
    RewardButtonWrapper,
    RewardBuyButton,
    RewardItemCarousel,
    RewardItemContainer,
    RewardItemContentInfo,
    RewardItemContentTitle,
    RewardItemContentWrapper,
    RewardItemInfo,
    RewardItemLink,
    RewardItemLinkWrapper,
    RewardItemTitle
} from "domain/reward/RewardItemStyle";
import useUpdateWallet from "domain/wallet/useUpdateWallet";
import { analyticsEvent, logCustomEvent } from "ga";
import { getCountryIcon, winningRounded } from "helpers/IconHelper";
import { divideByOneHundred, isNegative } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import useDevice from "hooks/useDevice/useDevice";
import useFantasyHistory from "hooks/useFantasyHistory";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { RootStoreState } from "store/reducer";
import { SYSTEM_COLORS } from "styles/Constants";
import { Pagination } from "swiper/modules";
import { SwiperSlide } from "swiper/react";
import { REWARD_TYPE } from "types/reward/Reward";

const defaultRewardImage = "/images/store_no_order.png";

const RewardItem = () => {
    const history = useFantasyHistory();
    const apiExecutor = useContext(ApiExecuteContext);
    const updateWallet = useUpdateWallet();
    const { rewardId } = useParams();
    const toggleLoading = useLoadingToggle();
    const { isDesktop } = useDevice();

    const { WalletState: { coinCent, winning } } = useSelector((store: RootStoreState) => store);
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();

    const [openModal, setOpenModal] = useState(false);

    const [rewardItem, setRewardItem] = useState({
        id: null,
        previewUrls: [],
        title: "",
        priceCent: 0,
        quantity: 0,
        description: "",
        externalLink: "",
        termsLink: "",
        country: "",
        type: ""
    });

    const rewardPreviewUrls = useMemo(() => getRewardPreviewUrls(rewardItem.previewUrls, defaultRewardImage), [rewardItem.previewUrls]);

    const backToRewardList = useCallback(() => history.push(ROUTER_PATH_CONFIG.reward.index), [history]);

    const onPurchasedSuccess = () => {
        history.goBack();
    };

    const getReward = useCallback(() => {
        apiExecutor(
            fetchReward(rewardId),
            {
                onSuccess: (res) => {
                    if (isOutOfStock(res.quantity)) backToRewardList();
                    setRewardItem(res);
                },
                onFinally: () => {
                    toggleLoading(false);
                }
            }
        );
    }, [apiExecutor, rewardId, backToRewardList, setRewardItem, toggleLoading]);

    useEffect(() => {
        (async () => {
            toggleLoading(true);
            await updateWallet();
            getReward();
        })();
    }, [toggleLoading, rewardId, getReward, updateWallet]);

    if (!rewardItem.id) {
        return null;
    }

    const onBuy = () => {
        if (rewardItem.type === REWARD_TYPE.REDEPOSIT) {
            logCustomEvent(analyticsEvent.webClickAddDeposit02);
        }
        setOpenModal(true);
    };

    return (
        <>
            <Navbar shouldCalculatePaddingTop={false}
                    navBackIconColor={SYSTEM_COLORS.primary80}
                    endIcon={
                        <div className="d-flex align-items-center">
                            <div className="fz-sf-bold-footnote me-2">{toWalletNumber(winning)}</div>
                            <div>
                                <img src={winningRounded} alt="winning-rounded" />
                            </div>
                        </div>}
            />
            <RewardItemContainer>
                {
                    isNegative(coinCent) && <NegativeDepositAlert />
                }
                <RewardItemCarousel>
                    <Carousel
                        slidesPerView={1}
                        centeredSlides={true}
                        navigation={isDesktop}
                        pagination={!isDesktop && { clickable: true }}
                        modules={[Pagination]}
                    >
                        {
                            rewardPreviewUrls.map((url, idx) =>
                                <SwiperSlide key={idx}>
                                    <div className="w-100">
                                        <img className="img-fluid" src={url} alt="reward-img" />
                                    </div>
                                </SwiperSlide>)
                        }
                    </Carousel>
                </RewardItemCarousel>

                <RewardItemContentWrapper>

                    {
                        !isCoinReward(rewardItem.country) &&
                        <CountryTag>
                            <img src={getCountryIcon(rewardItem.country)} className={"img-fluid"}
                                 alt="rewardItem.country" />
                            {getLabelI18nText(rewardItem.country)}
                        </CountryTag>
                    }

                    <RewardItemTitle>{rewardItem.title}</RewardItemTitle>

                    <RewardItemInfo>
                        <RewardCoins price={divideByOneHundred(rewardItem.priceCent)}
                                     numberFontStyle={"fz-sf-bold-title-1"} />
                        <RewardQuantity quantity={rewardItem.quantity}
                                        iconSize="2.4rem"
                                        fontStyle="fz-sf-regular-body-2" />
                    </RewardItemInfo>
                    <RewardItemContentTitle>
                        <FormattedMessage id="label_about_item" />
                    </RewardItemContentTitle>
                    <RewardItemContentInfo>
                        {rewardItem.description}
                    </RewardItemContentInfo>
                    <RewardItemLinkWrapper>
                        {
                            rewardItem.externalLink &&
                            <RewardItemLink href={rewardItem.externalLink} target="_blank">
                                <FormattedMessage id="label_description" />
                            </RewardItemLink>
                        }
                        {
                            rewardItem.termsLink &&
                            <RewardItemLink href={rewardItem.termsLink} target="_blank">
                                <FormattedMessage id="me_page_menu_label_terms_and_conditions" />
                            </RewardItemLink>
                        }
                    </RewardItemLinkWrapper>
                </RewardItemContentWrapper>
                <RewardButtonWrapper pointerEvents={isNegative(coinCent) ? "none" : "auto"}>
                    <RewardBuyButton onClick={onBuy}>
                        <FormattedMessage id="reward_list_page_button_buy_now" />
                    </RewardBuyButton>
                </RewardButtonWrapper>
            </RewardItemContainer>

            {
                openModal &&
                <PurchaseModal rewardItem={{
                    ...rewardItem,
                    previewUrl: rewardItem.previewUrls[0]
                }}
                               onSuccess={onPurchasedSuccess}
                               onSoldOut={backToRewardList}
                               closeModal={() => setOpenModal(false)} />
            }

        </>
    );
};

export default RewardItem;
