import { MyNineCatDetailDto, NineCatMatchDto, SLATE_STATUS_FROM_API, UpcomingNineCatDto } from "domain/slate/data/dto";
import { NINE_CAT_STAT, NineCatPlayerDetailVO } from "./ComponentTypes";

export interface UpcomingNineCatSquadVo {
    logoUrl: string;
}

export class UpcomingNineCatVo {
    id: number;
    name: string;
    tournament: string;
    joined: number;
    squads: UpcomingNineCatSquadVo[];

    constructor(dto: UpcomingNineCatDto) {
        this.id = dto.nineCatId;
        this.name = dto.name;
        this.tournament = dto.tournament;
        this.joined = dto.joined;
        this.squads = dto.squads;
    }
}

export class MySlateTeamVo {
    id: number;
    name: string;
    points: NineCatPlayerDetailVO;
    fieldGoalsEfficiency: NineCatPlayerDetailVO;
    freeThrowsEfficiency: NineCatPlayerDetailVO;
    offensiveRebounds: NineCatPlayerDetailVO;
    defensiveRebounds: NineCatPlayerDetailVO;
    assists: NineCatPlayerDetailVO;
    steals: NineCatPlayerDetailVO;
    blocks: NineCatPlayerDetailVO;
    threePointersMade: NineCatPlayerDetailVO;

    constructor({
                    id,
                    name,
                    points,
                    fieldGoalsEfficiency,
                    freeThrowsEfficiency,
                    offensiveRebounds,
                    defensiveRebounds,
                    assists,
                    steals,
                    blocks,
                    threePointersMade
                }) {
        this.id = id;
        this.name = name;
        this.points = points;
        this.fieldGoalsEfficiency = fieldGoalsEfficiency;
        this.freeThrowsEfficiency = freeThrowsEfficiency;
        this.offensiveRebounds = offensiveRebounds;
        this.defensiveRebounds = defensiveRebounds;
        this.assists = assists;
        this.steals = steals;
        this.blocks = blocks;
        this.threePointersMade = threePointersMade;
    }

    get teamPlayers() {
        return {
            [NINE_CAT_STAT.PTS]: this.points,
            [NINE_CAT_STAT.FGE]: this.fieldGoalsEfficiency,
            [NINE_CAT_STAT.FTE]: this.freeThrowsEfficiency,
            [NINE_CAT_STAT.OR]: this.offensiveRebounds,
            [NINE_CAT_STAT.DR]: this.defensiveRebounds,
            [NINE_CAT_STAT.AST]: this.assists,
            [NINE_CAT_STAT.ST]: this.steals,
            [NINE_CAT_STAT.BLK]: this.blocks,
            [NINE_CAT_STAT["3PM"]]: this.threePointersMade
        };
    }

    get playerAvatars() {
        return Object.values(this.teamPlayers).map(player => player.avatar);
    }

}

export class NineCatMatchVo {
    squadACode: string;
    squadALogoUrl: string;
    squadBCode: string;
    squadBLogoUrl: string;
    matchStartsAt: number;

    constructor(dto: NineCatMatchDto) {
        this.squadACode = dto.squadACode;
        this.squadALogoUrl = dto.squadALogoUrl;
        this.squadBCode = dto.squadBCode;
        this.squadBLogoUrl = dto.squadBLogoUrl;
        this.matchStartsAt = dto.matchStartsAt;
    }
}

export class NineCatDetailVo {
    nineCatId: number;
    title: string;
    subTitle: string;
    startsAt: number;
    slateMatches: NineCatMatchVo[];
    slateStatus: SLATE_STATUS_FROM_API;

    constructor(dto: MyNineCatDetailDto) {
        this.nineCatId = dto.nineCatId;
        this.title = dto.title;
        this.subTitle = dto.subTitle;
        this.startsAt = dto.startsAt;
        this.slateMatches = dto?.slateMatches?.map(slateMatch => new NineCatMatchVo(slateMatch)) || [];
        this.slateStatus = dto.slateStatus;
    }

    get fullTitle() {
        return `${this.subTitle} ${this.title}`;
    }


}
