import React, { FC, useState } from "react";
import { MdInfo } from "react-icons/md";
import SlateDescriptionPopup from "./SlateDescriptionPopup";

const NineCatDescriptionWithIcon: FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [openNineCatIntroModal, setOpenNineCatIntroModal] = useState(false);

    return (
        <>
            <div className="tw-flex tw-items-center tw-cursor-pointer" onClick={() => {
                setOpenNineCatIntroModal(true);
            }}>
                {children}
                <div className="tw-pl-1">
                    <MdInfo fill={"#B3B3B3"} size={"1.4rem"} />
                </div>
            </div>
            <SlateDescriptionPopup open={openNineCatIntroModal} onOpenChange={setOpenNineCatIntroModal} />
        </>
    );
};

export default NineCatDescriptionWithIcon;
