import { useForm, UseFormReturn } from "react-hook-form";
import { NINE_CAT_STAT, NineCatPlayerDetailVO } from "../data/ComponentTypes";

import { OPTION_ALL, roleOptions } from "../views/config";

function getMultiSelectOptions(opt: string, prevOptions: string[]) {
    if (opt === OPTION_ALL) {
        return [];
    }
    if (prevOptions.includes(opt)) {
        return prevOptions.filter(f => f !== opt);
    }
    return [...prevOptions, opt];
}

export function useCreateTeamFeatureButtonGroup(): {
    form: UseFormReturn<{ search: string, order: NINE_CAT_STAT, filter: string[] }, any, undefined>
    orderVal: NINE_CAT_STAT
    handleToggleFilterOptions: (option: string) => void
    filterPlayerBySearchVal: (nameObj: Pick<NineCatPlayerDetailVO, "firstName" | "middleName" | "lastName">) => boolean
    filterPlayerByFilterVal: (playerDetail: NineCatPlayerDetailVO) => boolean
} {

    const form = useForm<{
        search: string
        order: NINE_CAT_STAT
        filter: string[]
    }>({
        defaultValues: {
            order: NINE_CAT_STAT.PTS,
            filter: [],
        }
    });

    const orderVal = form.watch("order");
    const filterVal = form.watch("filter");
    const searchVal = form.watch("search");


    const handleToggleFilterOptions = (option: string) => {
        form.setValue("filter", getMultiSelectOptions(option, filterVal));
    };

    const filterPlayerBySearchVal = (nameObj: Pick<NineCatPlayerDetailVO, "firstName" | "middleName" | "lastName">) => {
        if (!searchVal) return true;

        function checkNameIncludes(name: string) {
            return name.toLowerCase().includes(searchVal.toLowerCase());
        }

        if (checkNameIncludes(nameObj.firstName)) return true;
        if (checkNameIncludes(nameObj.middleName)) return true;
        if (checkNameIncludes(nameObj.lastName)) return true;

        return false;

    };

    function filterPlayerByFilterVal(playerDetail: NineCatPlayerDetailVO) {
        if (filterVal.length === 0 || filterVal.includes(roleOptions[0])) return true;

        return filterVal.includes(playerDetail.roleAbbr);
    }

    return {
        form,
        orderVal,
        handleToggleFilterOptions,
        filterPlayerBySearchVal,
        filterPlayerByFilterVal
    };
}
