import React from "react";
import { FormattedMessage } from "react-intl";
import { emptyMyTeam } from "helpers/IconHelper";
import { EmptyImg, EmptyText, EmptyWrapper } from "./EmptyStyle";

const UpcomingMatchEmptyTeam = () => {
    return (
        <EmptyWrapper>
            <EmptyImg src={emptyMyTeam} />
            <EmptyText>
                <FormattedMessage id="match_detail_page_my_teams_section_label_empty" />
            </EmptyText>
        </EmptyWrapper>
    );
};

export default UpcomingMatchEmptyTeam;
