import { Tag, TagValue, TagWrapper } from "domain/match/pages/upcoming/contest/card/tag/ContestInfoTagListStyle";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { TagVO } from "../../../../../../../helpers/ContestHelper";
import TagIcon from "./TagIcon";


interface ContestInfoTagListProps {
    tags: TagVO[];
    children?: React.ReactNode;
}

const ContestInfoTagList: React.FC<ContestInfoTagListProps> = ({ tags, children }) => {
    return <TagWrapper data-testid="contest-tags">
        {tags.map(({ bgImg, value, tooltipText }, idx) =>
            <OverlayTrigger
                key={idx}
                placement="bottom"
                overlay={
                    <Tooltip>
                        <div className="fz-sf-regular-footnote">{tooltipText}</div>
                    </Tooltip>
                }
            >
                <Tag key={idx} onClick={e => e.stopPropagation()}>
                    <TagIcon iconImg={bgImg} />
                    <TagValue>{value}</TagValue>
                </Tag>
            </OverlayTrigger>
        )}
        {children}
    </TagWrapper>;
};

export default ContestInfoTagList;
