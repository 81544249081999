import useUserIdentity from "hooks/useUserIdentity";
import React from "react";

interface B2CWrapperProps {
    children: React.ReactNode | React.ReactNode[];
}

const B2CWrapper: React.FC<B2CWrapperProps> = ({ children }) => {
    const { isB2B } = useUserIdentity();

    if (isB2B) return null;

    return <>{children}</>;
};

export default B2CWrapper;
