import { Frame } from "components/avatar/AvatarWithFrameStyle";
import _ from "lodash";
import moment from "moment";
import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";


import { AP_LEVEL } from "../../types/activityPoint/ApLevel";

export const headerHeight = "22.8rem";
export const bgImgPosition = "center center";

const apLevelImageUrl = `${window.FANTASY_GAME_CONFIG().resourceUrl}/game/apLevel`;

type ApLevelConfig = {
    apLevelComponent: (minAp?: number) => ReactNode;
    text: () => ReactNode;
    textUpper: () => ReactNode;
    key: AP_LEVEL;
    recommendedUpgradeLevel: AP_LEVEL | null;
    image: string;
    image40: string;
    bg: string;
    proportion: string;
    frame: ReactNode;
};

export const apLevelConfig: Record<AP_LEVEL, ApLevelConfig> = {
    [AP_LEVEL.BEGINNER]: {
        apLevelComponent: () => <FormattedMessage id="label_ap_level_beginner" />,
        text: () => <FormattedMessage id="label_ap_level_beginner" />,
        textUpper: () => <FormattedMessage id="label_ap_level_beginner_upper" />,
        key: AP_LEVEL.BEGINNER,
        recommendedUpgradeLevel: AP_LEVEL.GOLD,
        image: `${apLevelImageUrl}/badge_beginner.png`,
        image40: `${apLevelImageUrl}/badge_beginner_40.png`,
        bg: `${apLevelImageUrl}/bg_beginner.png`,
        proportion: "90%",
        frame: null
    },
    [AP_LEVEL.STEEL]: {
        apLevelComponent: () => <>
            <div className="tw-font-normal">
                <FormattedMessage id="task_label_buy_any_coins" />
            </div>
            <FormattedMessage id="label_ap_level_steel" />
        </>,
        text: () => <FormattedMessage id="label_ap_level_steel" />,
        textUpper: () => <FormattedMessage id="label_ap_level_steel_upper" />,
        key: AP_LEVEL.STEEL,
        recommendedUpgradeLevel: AP_LEVEL.GOLD,
        image: `${apLevelImageUrl}/badge_steel.png`,
        image40: `${apLevelImageUrl}/badge_steel_40.png`,
        bg: `${apLevelImageUrl}/bg_steel.png`,
        proportion: "86%",
        frame: <Frame src={`${apLevelImageUrl}/frame_steel.png`} />
    },
    [AP_LEVEL.ORDINARY]: {
        apLevelComponent: (minAp?: number) => <>
            <div className="tw-font-normal">
                {minAp}
            </div>
            <FormattedMessage id="label_ap_level_ordinary" />
        </>,
        text: () => <FormattedMessage id="label_ap_level_ordinary" />,
        textUpper: () => <FormattedMessage id="label_ap_level_ordinary_upper" />,
        key: AP_LEVEL.ORDINARY,
        recommendedUpgradeLevel: AP_LEVEL.GOLD,
        image: `${apLevelImageUrl}/badge_ordinary.png`,
        image40: `${apLevelImageUrl}/badge_ordinary_40.png`,
        bg: `${apLevelImageUrl}/bg_ordinary.png`,
        proportion: "78%",
        frame: <Frame src={`${apLevelImageUrl}/frame_ordinary.png`} />
    },
    [AP_LEVEL.SILVER]: {
        apLevelComponent: (minAp?: number) => <>
            <div className="tw-font-normal">
                {minAp}
            </div>
            <FormattedMessage id="label_ap_level_silver" />
        </>,
        text: () => <FormattedMessage id="label_ap_level_silver" />,
        textUpper: () => <FormattedMessage id="label_ap_level_silver_upper" />,
        key: AP_LEVEL.SILVER,
        recommendedUpgradeLevel: AP_LEVEL.GOLD,
        image: `${apLevelImageUrl}/badge_silver.png`,
        image40: `${apLevelImageUrl}/badge_silver_40.png`,
        bg: `${apLevelImageUrl}/bg_silver.png`,
        proportion: "78%",
        frame: <Frame src={`${apLevelImageUrl}/frame_silver.png`} />
    },
    [AP_LEVEL.GOLD]: {
        apLevelComponent: (minAp?: number) => <>
            <div className="tw-font-normal">
                {minAp}
            </div>
            <FormattedMessage id="label_ap_level_gold" />
        </>,
        text: () => <FormattedMessage id="label_ap_level_gold" />,
        textUpper: () => <FormattedMessage id="label_ap_level_gold_upper" />,
        key: AP_LEVEL.GOLD,
        recommendedUpgradeLevel: AP_LEVEL.ULTIMATE_MASTER,
        image: `${apLevelImageUrl}/badge_gold.png`,
        image40: `${apLevelImageUrl}/badge_gold_40.png`,
        bg: `${apLevelImageUrl}/bg_gold.png`,
        proportion: "78%",
        frame: <Frame src={`${apLevelImageUrl}/frame_gold.png`} />
    },
    [AP_LEVEL.PLATINUM]: {
        apLevelComponent: (minAp?: number) => <>
            <div className="tw-font-normal">
                {minAp}
            </div>
            <FormattedMessage id="label_ap_level_platinum" />
        </>,
        text: () => <FormattedMessage id="label_ap_level_platinum" />,
        textUpper: () => <FormattedMessage id="label_ap_level_platinum_upper" />,
        key: AP_LEVEL.PLATINUM,
        recommendedUpgradeLevel: AP_LEVEL.ULTIMATE_MASTER,
        image: `${apLevelImageUrl}/badge_platinum.png`,
        image40: `${apLevelImageUrl}/badge_platinum_40.png`,
        bg: `${apLevelImageUrl}/bg_platinum.png`,
        proportion: "78%",
        frame: <Frame src={`${apLevelImageUrl}/frame_platinum.png`} />
    },
    [AP_LEVEL.EMERALD]: {
        apLevelComponent: (minAp?: number) => <>
            <div className="tw-font-normal">
                {minAp}
            </div>
            <FormattedMessage id="label_ap_level_emerald" />
        </>,
        text: () => <FormattedMessage id="label_ap_level_emerald" />,
        textUpper: () => <FormattedMessage id="label_ap_level_emerald_upper" />,
        key: AP_LEVEL.EMERALD,
        recommendedUpgradeLevel: AP_LEVEL.ULTIMATE_MASTER,
        image: `${apLevelImageUrl}/badge_emerald.png`,
        image40: `${apLevelImageUrl}/badge_emerald_40.png`,
        bg: `${apLevelImageUrl}/bg_emerald.png`,
        proportion: "78%",
        frame: <Frame src={`${apLevelImageUrl}/frame_emerald.png`} />
    },
    [AP_LEVEL.DIAMOND]: {
        apLevelComponent: (minAp?: number) => <>
            <div className="tw-font-normal">
                {minAp}
            </div>
            <FormattedMessage id="label_ap_level_diamond" />
        </>,
        text: () => <FormattedMessage id="label_ap_level_diamond" />,
        textUpper: () => <FormattedMessage id="label_ap_level_diamond_upper" />,
        key: AP_LEVEL.DIAMOND,
        recommendedUpgradeLevel: AP_LEVEL.ULTIMATE_MASTER,
        image: `${apLevelImageUrl}/badge_diamond.png`,
        image40: `${apLevelImageUrl}/badge_diamond_40.png`,
        bg: `${apLevelImageUrl}/bg_diamond.png`,
        proportion: "78%",
        frame: <Frame src={`${apLevelImageUrl}/frame_diamond.png`} />
    },
    [AP_LEVEL.MASTER]: {
        apLevelComponent: (minAp?: number) => <>
            <div className="tw-font-normal">
                {minAp}
            </div>
            <FormattedMessage id="label_ap_level_master" />
        </>,
        text: () => <FormattedMessage id="label_ap_level_master" />,
        textUpper: () => <FormattedMessage id="label_ap_level_master_upper" />,
        key: AP_LEVEL.MASTER,
        recommendedUpgradeLevel: AP_LEVEL.ULTIMATE_MASTER,
        image: `${apLevelImageUrl}/badge_master.png`,
        image40: `${apLevelImageUrl}/badge_master_40.png`,
        bg: `${apLevelImageUrl}/bg_master.png`,
        proportion: "78%",
        frame: <Frame src={`${apLevelImageUrl}/frame_master.png`} />
    },
    [AP_LEVEL.ULTIMATE_MASTER]: {
        apLevelComponent: (minAp?: number) => <>
            <div className="tw-font-normal">
                {minAp}
            </div>
            <FormattedMessage id="label_ap_level_ultimateMaster" />
        </>,
        text: () => <FormattedMessage id="label_ap_level_ultimateMaster" />,
        textUpper: () => <FormattedMessage id="label_ap_level_ultimateMaster_upper" />,
        key: AP_LEVEL.ULTIMATE_MASTER,
        recommendedUpgradeLevel: null,
        image: `${apLevelImageUrl}/badge_ultimate_master.png`,
        image40: `${apLevelImageUrl}/badge_ultimate_master_40.png`,
        bg: `${apLevelImageUrl}/bg_ultimate_master.png`,
        proportion: "78%",
        frame: <Frame src={`${apLevelImageUrl}/frame_ultimate_master.png`} />
    }
};

export const isBeginner = level => level === AP_LEVEL.BEGINNER;
export const isUltimateMaster = level => level === AP_LEVEL.ULTIMATE_MASTER;
export const isTabColorBlack = level => [AP_LEVEL.EMERALD, AP_LEVEL.DIAMOND, AP_LEVEL.MASTER].includes(level);
export const isTextShadowed = level => ![AP_LEVEL.BEGINNER, AP_LEVEL.ULTIMATE_MASTER].includes(level);

export const getCurrentApProgress = ({
                                         currentAp,
                                         currentLevelMinAp,
                                         nextLevelMinAp
                                     }) => (currentAp - currentLevelMinAp) / (nextLevelMinAp - currentLevelMinAp);

export const getAvailableUpgrades = upgrades => {
    const levelKeys = Object.keys(apLevelConfig);
    return upgrades
        .sort((a, b) => a.minAp - b.minAp)
        .filter(upgrade => levelKeys.includes(upgrade.level));
};

export const getMonthsAbbr = (months, intlMessages) => {
    const numberToIntlId = number => _.isNumber(number)
        ? `label_${moment().month(number - 1).format("MMM").toLowerCase()}`
        : "";

    return months
        .sort()
        .map(month => numberToIntlId(month))
        .map(intiId => intlMessages[intiId])
        .join(" & ");
};

export const getUserIdToCurrentLevel = currentApLevelList => currentApLevelList.reduce((prev, {
    userId,
    currentLevel
}) => ({ ...prev, [userId]: currentLevel }), {});
