import { Image } from "react-bootstrap";
import styled from "styled-components";
import { SYSTEM_COLORS } from "styles/Constants";
import { AP_LEVEL } from "types/activityPoint/ApLevel";
import { isBeginner, isUltimateMaster } from "./ActivityPointHelper";

export const ActivityPointProgressWrapper = styled.div.attrs(() => ({
    className: "d-flex w-100 h-auto align-items-center tw-bg-opacity-[0.32] tw-bg-white tw-rounded"
}))`
  padding: .9rem 1rem;
`;
export const BadgeWrapper = styled.div.attrs(() => ({}))`
  width: 4.9rem;
  height: 4.9rem;
  margin: 1rem .8rem 1rem .83rem;
`;
export const LevelBadge = styled(Image).attrs(() => ({
    className: "w-100 h-100"
}))`
`;
export const InfoWrapper = styled.div<{ $level: AP_LEVEL }>`
    width: ${({ $level }) => isBeginner($level) ? "100%" : "calc(100% - 4.9rem - 1.63rem)"};
`;
export const LevelAndApWrapper = styled.div.attrs(() => ({
    className: "d-flex justify-content-between"
}))`
`;
export const CurrentLevel = styled.div.attrs<{ $level: AP_LEVEL }>({
    className: "fz-sf-bold-body-1 tw-text-tertiary-700"
})`
  // color: ${({ $level }) => isUltimateMaster($level) ? SYSTEM_COLORS.specialAp : SYSTEM_COLORS.labelLightPrimary};
`;
export const CurrentApWrapper = styled.div.attrs(() => ({
    className: "d-flex align-items-center"
}))`
`;
export const CurrentAp = styled.div.attrs<{ $level: AP_LEVEL }>({
    className: "fz-sf-bold-body-1 me-1 tw-text-tertiary-700"
})``;
export const TotalProgressBar = styled.div.attrs(() => ({
    className: "w-100 d-flex align-items-center"
}))`
  margin: .6rem 0 1.2rem;
  height: 1.6rem;
  background-color: rgba(0, 0, 0, .15);
  border-radius: 1.6rem;
`;
export const CurrentProgressBar = styled.div.attrs<{ $currentApProgress: number }>({
    className: "tw-bg-gradient-to-r tw-from-tertiary-700 tw-to-tertiary-500"
})`
  width: ${({ $currentApProgress }) => 100 * $currentApProgress}%;
  min-width: .3rem;
  height: 1.6rem;
  border-radius: 1.6rem;
`;
export const Text = styled.div.attrs(() => ({
    className: "fz-sf-regular-caption-1 ps-1 tw-text-tertiary-700"
}))`
`;
