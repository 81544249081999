import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import useMyProfile from "../../hooks/useMyProfile";
import { ROUTER_PATH_CONFIG } from "../../router/RouterPathConfig";
import FriendList from "./list/FriendList";
import { UserProfile } from "./profile/UserProfile";
import TransferFlow from "./transfer/TransferFlow";

const FriendContainer = () => {

    const { myProfile } = useMyProfile();

    if (!myProfile) {
        return null;
    }

    return <Switch>
        <Route exact path={ROUTER_PATH_CONFIG.userProfile.transfer}>
            <TransferFlow />
        </Route>
        <Route exact path={ROUTER_PATH_CONFIG.userProfile.list}>
            <FriendList />
        </Route>
        <Route exact path={ROUTER_PATH_CONFIG.userProfile.info}>
            <UserProfile />
        </Route>
        <Redirect to={ROUTER_PATH_CONFIG.home} />
    </Switch>;
};

export default FriendContainer;
