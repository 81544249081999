import { ajax } from "../ApiUtils";
import { fetchMyCouponUrl } from "../ApiUrlConfig";
import { enrichCoupons } from "../../domain/coupon/helper/CouponHelper";
import { EnrichedCoupons } from "../../types/coupon/Coupon";


export const fetchMyCoupon = (): Promise<EnrichedCoupons> => {
    return ajax<EnrichedCoupons>({ url: fetchMyCouponUrl })
        .then(({ data }) => enrichCoupons(data.data));

};