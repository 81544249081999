import styled from "styled-components";
import { OutlineButton, PrimaryButton } from "../../../styles/component/ButtonsStyle";
import { SYSTEM_COLORS } from "../../../styles/Constants";

export const NotificationWrapper = styled.div.attrs(() => ({
    className: "position-fixed w-100 h-100 d-flex justify-content-center align-items-center"
}))`
    top: 0;
    left: 0;
    z-index: 99;
    max-width: 55rem;
`;
export const NotificationModalOverlay = styled.div.attrs(() => ({
    className: "position-absolute w-100 h-100"
}))`
    background-color: rgba(0, 0, 0, .8);

`;
export const NotificationModal = styled.div.attrs(() => ({
    className: "position-absolute tw-bg-system-bgLightPrimary"
}))`
    z-index: 3;
    width: 32rem;
    border-radius: 1.6rem;
    padding: 2rem;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.35);
`;

export const StatusIcon = styled.div.attrs<{
    iconImage: string
}>({
    className: "mx-auto mb-4"
})`
    width: 6rem;
    padding-bottom: 6rem;
    background-image: url(${({ iconImage }) => iconImage});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
`;
export const Title = styled.div.attrs(() => ({
    className: "fz-sf-bold-title-3 text-center tw-text-system-labelLightPrimary"
}))`
    color: ${SYSTEM_COLORS.labelLightPrimary}
`;
export const SoldOutTitle = styled(Title).attrs(() => ({
    className: ""
}))`
    margin-bottom: 1.2rem;
`;
export const Text = styled.div.attrs(() => ({
    className: "text-center fz-sf-regular-body-2"
}))`
    padding: 1.2rem 0;

    + .present-deposit-wrapper {
        margin-top: 0;
    }
`;
export const RewardPriceText = styled.span.attrs(() => ({
    className: "fz-sf-regular-body-2 text-secondary-1"
}))`
`;

export const ProcessDayText = styled.span.attrs(() => ({
    className: "fz-sf-bold-body-2 text-secondary-1"
}))`
`;
export const RewardNameText = styled.span.attrs(() => ({
    className: "fz-sf-bold-body-2"
}))`
`;
export const SeparateLine = styled.div.attrs(() => ({
    className: "tw-border-system-gray100"
}))`
    border-width: 1px;
    border-style: solid;
    margin: 1.2rem 0;
`;
export const RemindText = styled.div.attrs(() => ({
    className: "fz-sf-regular-subhead tw-text-system-red300"
}))`
    margin-bottom: 1.8rem;
`;
export const ButtonWrapper = styled.div.attrs(() => ({
    className: "d-flex justify-content-center"
}))`
`;
export const CancelButton = styled(OutlineButton).attrs(() => ({
    className: "fz-sf-regular-title-3"
}))`
    width: 47.5%;
`;
export const ConfirmButton = styled(PrimaryButton).attrs(() => ({
    className: "fz-sf-regular-title-3"
}))`
    width: 47.5%;
`;

export const PresentDepositWrapper = styled.div.attrs(() => ({
    className: "present-deposit-wrapper"
}))`
    border-radius: .4rem;
    padding: 1rem 0;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    margin: 1.3rem 0;
`;
export const PresentDepositTitle = styled.div.attrs(() => ({
    className: "fz-sf-regular-subhead d-flex justify-content-center align-items-center mb-1 tw-text-system-labelLightSecondary"
}))`
`;
export const PresentDepositIcon = styled.div.attrs<{
    icon: string
}>({
    className: "me-1"
})`
    width: 1.6rem;
    padding-bottom: 1.6rem;
    background-image: url(${({ icon }) => icon});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
`;