import { MatchEmptyDisplay } from "components/empty/MatchEmptyDisplay";
import ResultMatchCard from "domain/result/ResultMatchCard";
import { getResultMatchesGroupByStartDate } from "helpers/match/MatchHelper";
import { Tournament } from "helpers/TournamentHelper";
import React from "react";
import { ResultMatchVO } from "types/match/Match";
import { SPORT } from "types/sports/Sport";

interface ResultMatchesContextProps {
    currentSport: SPORT;
    tournaments: Tournament[];
    resultMatches: ResultMatchVO[];
}

export const ResultMatchesFactory: React.FC<ResultMatchesContextProps> = ({
                                                                              currentSport,
                                                                              tournaments,
                                                                              resultMatches
                                                                          }) => {
    if (resultMatches.length === 0) {
        return <MatchEmptyDisplay tournaments={tournaments} />;
    }

    const resultMatchesGroupByStartDate = getResultMatchesGroupByStartDate(resultMatches);

    return <>
        {
            resultMatchesGroupByStartDate.reduce<JSX.Element[]>((
                pre, {
                    matchStartDate,
                    matches,
                    offset
                }) => [
                ...pre,
                <div key={`match-${matchStartDate}`} className="fz-sf-bold-subhead pt-2">{matchStartDate}</div>,
                ...matches.map((match) => {
                    return <ResultMatchCard
                        key={`match-${match.matchId}`}
                        sport={currentSport}
                        match={match} />;
                })
            ], [])
        }
    </>;
};
