import { useContext, useEffect, useState } from "react";
import { ApiExecuteContext } from "../../../apis/ApiContext";
import { fetchResultMatches } from "../../../apis/match/MatchApi";
import { MATCH_STATUS_FROM_API, ResultMatchVO } from "../../../types/match/Match";
import { SPORT } from "../../../types/sports/Sport";

const useResultMatches = (sport: SPORT) => {
    const apiExecutor = useContext(ApiExecuteContext);

    const [resultMatches, setResultMatches] = useState<ResultMatchVO[] | null>(null);

    useEffect(() => {
        setResultMatches(null);
        apiExecutor(
            fetchResultMatches(sport),
            {
                onSuccess: (res) => {
                    setResultMatches(res.map(match => ({ ...match, matchStatus: MATCH_STATUS_FROM_API.COMPLETED })));
                }
            }
        );

    }, [apiExecutor, sport]);


    return {
        resultMatches
    };

};

export default useResultMatches;
