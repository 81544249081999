import { getMatchColor } from "helpers/match/MatchHelper";
import { Col, Row } from "react-bootstrap";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import styled, { css } from "styled-components";
import { APP_MAX_WIDTH } from "styles/Constants";
import { MATCH_STATUS_FROM_API } from "types/match/Match";

export const MatchCardHeader = styled.div.attrs(() => ({
    className: "d-flex w-100 justify-content-between align-items-center fantasy-card-header"
}))`
`;
export const MatchCardTournament = styled.div.attrs(() => ({
    className: "w-100 fz-sf-regular-subhead text-truncate"
}))`
`;
export const MatchCardBodyWrapper = styled.div.attrs(() => ({
    className: "d-flex fantasy-card-body w-100"
}))`
`;

export const MatchCardBodyLeft = styled.div.attrs({})`
    width: 71.16%;
`;

export const MatchCardBodyRight = styled.div.attrs({
    className: "d-flex justify-content-center align-items-end flex-column border-light-secondary"
})`
    flex-grow: 1;
    border-left: 1px solid;
`;

export const SquadInfoWrapper = styled.div.attrs(() => ({
    className: "d-flex align-items-center"
}))`

`;
export const SquadLogoWrapper = styled.div.attrs({})`
    width: 1.6rem;
`;
export const MatchStatus = styled.div.attrs<{
    $matchStatus: MATCH_STATUS_FROM_API
}>(({ $matchStatus }) => ({
    className: `text-end ${getMatchColor($matchStatus)} d-flex flex-column align-items-end fz-sf-regular-footnote`
}))`
`;

export const MatchInfoSummary = styled.div.attrs(() => ({
    className: "d-flex justify-content-between fz-sf-regular-caption-1 w-100"
}))`
    padding: .2rem 1.8rem .2rem 1.6rem;
`;

export const TeamAndContestWrapper = styled.div.attrs(() => ({
    className: "d-flex"
}))``;

export const TeamNumberSection = styled.div.attrs(() => ({
    className: "me-2"
}))``;

export const Number = styled.span.attrs(() => ({
    className: "fz-sf-bold-footnote tw-text-primary-600"
}))`
`;

export const Title = styled.span.attrs(() => ({
    className: "fz-sf-bold-subhead tw-text-primary-300"
}))`
`;
export const JoinedWrapper = styled.div.attrs({
    className: "d-flex align-items-center"
})`
    gap: .2rem;
`;
export const JoinedNumbers = styled.span.attrs(() => ({
    className: "fz-sf-bold-subhead fz-sf-regular-caption-1"
}))`
`;
export const CollapseIcon = styled(IoChevronUp).attrs({
    className: "d-inline-block position-absolute"
})`
    font-size: 1.2rem;
    top: .4rem;
    right: .4rem;
`;

export const ExpandIcon = styled(IoChevronDown).attrs({
    className: "d-inline-block position-absolute"
})`
    font-size: 1.2rem;
    top: .6rem;
    right: .4rem;
`;

export const MatchCard = styled.div.attrs<{
    $isPreparing?: boolean,
}>({
    className: "d-flex flex-column justify-content-between align-items-center position-relative overflow-hidden base-shadow-light-60 clickable"
})`
    background: #fff;
    border-radius: 1.2rem;

    ${({ $isPreparing }) => {
        return $isPreparing && css`
            :before {
                content: '';
                background: var(--white-80);
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 1;
            }`;
    }};

`;

export const SquadScoreSection = styled.div.attrs({
    className: "text-text-primary-1 d-flex align-items-center justify-content-end"
})`
    flex: 0 0 8rem;
    white-space: pre;

    .arrow {
        margin-left: .3rem;
        width: .5rem;
        align-self: flex-start;
    }

    @media (min-width: ${APP_MAX_WIDTH}) {
        flex: 0 0 10rem;
    }
`;

export const SquadLogoSection = styled.div.attrs(() => ({
    className: "d-flex w-100 align-items-center"
}))``;
export const SquadName = styled.div.attrs(() => ({
    className: "text-truncate text-text-primary-1 fz-sf-bold-body-1"
}))`
    width: 0;
    flex-grow: 1;
`;
export const MatchCardFooter = styled.div.attrs(() => ({
    className: "fz-sf-regular-caption-1 w-100 position-relative tw-border-system-neutral100 tw-bg-grey-50"
}))`
    padding-bottom: .4rem;
    border-width: 1px 0 0 0;
    border-style: solid;
`;

export const MatchCardButtonWrapper = styled.div.attrs({})`
    padding: 1.6rem 0;
`;

export const MatchCardButton = styled.div.attrs({
    className: "w-100 text-text-primary-1 fz-sf-bold-body-1 d-flex justify-content-between align-items-center"
})`
    padding: 0.8rem 2.4rem;
`;

export const JoinedTeamRow = styled(Row).attrs({
    className: "align-items-center border-light-primary"
})`
    border-top: 1px dashed;
    padding: .4rem 1.6rem;
`;
export const RankCol = styled(Col).attrs({
    className: "fz-sf-regular-body-2 text-text-secondary-1 text-end"
})`
`;
export const TeamCol = styled(Col).attrs({
    className: "col-8 d-flex align-items-center"
})`
    gap: .4rem;
`;
export const LineupsOutWrapper = styled.div.attrs({
    className: "fz-sf-regular-caption-1 text-sys-success-1 flex-shrink-0 position-relative"
})`
    margin: 0 0 0 1.6rem;

    &:after {
        content: '';
        position: absolute;
        right: -1.6rem;
        top: -1.6rem;
        width: 16rem;
        height: 4rem;
        background: linear-gradient(228deg, rgba(235, 249, 238, 0.6) 10%, rgba(235, 249, 238, 0) 50.44%);
        z-index: -1;
    }
`;
