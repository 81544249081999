import RewardOrderHistory from "domain/reward/orders/RewardOrderHistory";
import RewardItem from "domain/reward/RewardItem";
import RewardList from "domain/reward/RewardList";
import React, { createContext, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";


export const ShowNewOrderDotContext = createContext(false);

export const SetShowNewOrderDotContext = createContext((bool: boolean) => {});


const RewardContainer = () => {
    const [showNewOrderDot, setShowNewOrderDot] = useState(false);

    return <ShowNewOrderDotContext.Provider value={showNewOrderDot}>
        <SetShowNewOrderDotContext.Provider value={setShowNewOrderDot}>
            <Switch>
                <Route exact path={ROUTER_PATH_CONFIG.reward.orders}>
                    <RewardOrderHistory />
                </Route>
                <Route exact path={ROUTER_PATH_CONFIG.reward.item}>
                    <RewardItem />
                </Route>
                <Route exact path={ROUTER_PATH_CONFIG.reward.index}>
                    <RewardList />
                </Route>
                <Redirect to={ROUTER_PATH_CONFIG.home} />
            </Switch>
        </SetShowNewOrderDotContext.Provider>
    </ShowNewOrderDotContext.Provider>;
};

export default RewardContainer;

