import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import ScoringNote from "domain/team/player/components/scoringPopup/components/ScoringNote";
import ScoringTable from "domain/team/player/components/scoringPopup/components/ScoringTable";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useIntl } from "react-intl";


function ScoringLoadingSection() {
    return <div className="tw-h-[10rem] tw-flex tw-justify-center tw-items-center">
        <Spinner animation="border" role="status" />
    </div>;
}

const CricketT20ScoringView = () => {
    const intl = useIntl();
    return <div className="tw-flex tw-flex-col tw-gap-6 tw-max-h-[calc(min(91.8rem,85vh)-16.4rem)] overflow-x-auto">
        <ScoringTable
            title={intl.formatMessage({ id: "scoring_popup_subtitle_fantasy_points_system" })}
            scoring={{
                "Being a part of the starting XI": "4",
                "Every run scored": "1",
                "Every wicket taken (excluding run out)": "25",
                "Catch taken": "8",
                "Caught & Bowled": "33",
                "Stumping/Run Out (direct)": "12",
                "Run Out (Thrower/Catcher)": "6/6",
                "Dismissal for a Duck (only for batsmen, wicket-keepers and all-rounders)": "-2"
            }}
        />
        <ScoringTable
            title={intl.formatMessage({ id: "scoring_popup_subtitle_bonus_points" })}
            scoring={{
                "Every boundary hit": "1",
                "Every six-hit": "2",
                "Half Century (50 runs scored by a batsman in a single innings)": "8",
                "Century (100 runs scored by a batsman in a single innings)": "16",
                "Bonus (LBW / Bowled)": "8",
                "3 Catch Bonus": "4",
                "Maiden Over": "12",
                "4 wickets Bonus": "8",
                "5 wickets Bonus": "16",
                "2 wickets Bonus": "N/A",
                "3 wickets Bonus": "4",
                "30 runs scored by a batsman in a single innings": "4"
            }}
        />
        <ScoringTable
            title={intl.formatMessage({ id: "scoring_popup_subtitle_economy_rate" })}
            scoring={{
                "Minimum overs bowled by player to be applicable": "2 Overs",
                "Below 5 runs per over": "6",
                "Between 5-5.99 runs per over": "4",
                "Between 6-7 runs per over": "2",
                "Between 10-11 runs per over": "-2",
                "Between 11.01-12 runs per over": "-4",
                "Above 12 runs per over": "-6",
                "Below 2.5 runs per over": "N/A",
                "Between 2.5-3.49 runs per over": "N/A",
                "Between 3.5-4.5 runs per over": "N/A",
                "Between 7-8 runs per over": "N/A",
                "Between 8.01-9 runs per over": "N/A",
                "Above 9 runs per over": "N/A",
                "Below 7 runs per over": "N/A",
                "Between 7-7.99 runs per over": "N/A",
                "Between 8-9 runs per over": "N/A",
                "Between 14-15 runs per over": "N/A",
                "Between 15.01-16 runs per over": "N/A",
                "Above 16 runs per over": "N/A"
            }}
        />
        <ScoringTable
            title={intl.formatMessage({ id: "scoring_popup_subtitle_strike_rate" })}
            scoring={{
                "Minimum balls faced by a player to be applicable": "10 balls",
                "Between 60-70 runs per 100 balls": "-2",
                "Between 50-59.99 runs per 100 balls": "-4",
                "Below 50 runs per 100 balls": "-6",
                "Between 130-150 runs per 100 balls": "2",
                "Between 150.01-170 runs per 100 balls": "4",
                "Above 170 runs per 100 balls": "6",
                "Between 40-50 runs per 100 balls": "N/A",
                "Between 30-39.99 runs per 100 balls": "N/A",
                "Below 30 runs per 100 balls": "N/A",
                "Between 100-120 runs per 100 balls": "N/A",
                "Between 120.01-140 runs per 100 balls": "N/A",
                "Above 140 runs per 100 balls": "N/A",
                "Between 70-80 runs per 100 balls": "N/A",
                "Between 60-69.99 runs per 100 balls": "N/A",
                "Below 60 runs per 100 balls": "N/A",
                "Between 150-170 runs per 100 balls": "N/A",
                "Between 170.01-190 runs per 100 balls": "N/A",
                "Over 190 runs per 100 balls": "N/A"
            }}
        />
        <ScoringNote />
    </div>;
};

const CricketOdiScoringView = () => {
    const intl = useIntl();
    return <div className="tw-flex tw-flex-col tw-gap-6 tw-max-h-[calc(min(91.8rem,85vh)-16.4rem)] overflow-x-auto">
        <ScoringTable
            title={intl.formatMessage({ id: "scoring_popup_subtitle_fantasy_points_system" })}
            scoring={{
                "Being a part of the starting XI": "4",
                "Every run scored": "1",
                "Every wicket taken (excluding run out)": "25",
                "Catch taken": "8",
                "Caught & Bowled": "33",
                "Stumping/Run Out (direct)": "12",
                "Run Out (Thrower/Catcher)": "6/6",
                "Dismissal for a Duck (only for batsmen, wicket-keepers and all-rounders)": "-3"
            }}
        />
        <ScoringTable
            title={intl.formatMessage({ id: "scoring_popup_subtitle_bonus_points" })}
            scoring={{
                "Every boundary hit": "1",
                "Every six-hit": "2",
                "Half Century (50 runs scored by a batsman in a single innings)": "4",
                "Century (100 runs scored by a batsman in a single innings)": "8",
                "Bonus (LBW / Bowled)": "8",
                "3 Catch Bonus": "4",
                "Maiden Over": "4",
                "4 wickets Bonus": "4",
                "5 wickets Bonus": "8",
                "2 wickets Bonus": "N/A",
                "3 wickets Bonus": "N/A",
                "30 runs scored by a batsman in a single innings": "N/A"
            }}
        />
        <ScoringTable
            title={intl.formatMessage({ id: "scoring_popup_subtitle_economy_rate" })}
            scoring={{
                "Minimum overs bowled by player to be applicable": "5 Overs",
                "Below 5 runs per over": "N/A",
                "Between 5-5.99 runs per over": "N/A",
                "Between 6-7 runs per over": "N/A",
                "Between 10-11 runs per over": "N/A",
                "Between 11.01-12 runs per over": "N/A",
                "Above 12 runs per over": "N/A",
                "Below 2.5 runs per over": "6",
                "Between 2.5-3.49 runs per over": "4",
                "Between 3.5-4.5 runs per over": "2",
                "Between 7-8 runs per over": "-2",
                "Between 8.01-9 runs per over": "-4",
                "Above 9 runs per over": "-6",
                "Below 7 runs per over": "N/A",
                "Between 7-7.99 runs per over": "N/A",
                "Between 8-9 runs per over": "N/A",
                "Between 14-15 runs per over": "N/A",
                "Between 15.01-16 runs per over": "N/A",
                "Above 16 runs per over": "N/A"
            }}
        />
        <ScoringTable
            title={intl.formatMessage({ id: "scoring_popup_subtitle_strike_rate" })}
            scoring={{
                "Minimum balls faced by a player to be applicable": "20 balls",
                "Between 60-70 runs per 100 balls": "N/A",
                "Between 50-59.99 runs per 100 balls": "N/A",
                "Below 50 runs per 100 balls": "N/A",
                "Between 130-150 runs per 100 balls": "N/A",
                "Between 150.01-170 runs per 100 balls": "N/A",
                "Above 170 runs per 100 balls": "N/A",
                "Between 40-50 runs per 100 balls": "-2",
                "Between 30-39.99 runs per 100 balls": "-4",
                "Below 30 runs per 100 balls": "-6",
                "Between 100-120 runs per 100 balls": "2",
                "Between 120.01-140 runs per 100 balls": "4",
                "Above 140 runs per 100 balls": "6",
                "Between 70-80 runs per 100 balls": "N/A",
                "Between 60-69.99 runs per 100 balls": "N/A",
                "Below 60 runs per 100 balls": "N/A",
                "Between 150-170 runs per 100 balls": "N/A",
                "Between 170.01-190 runs per 100 balls": "N/A",
                "Over 190 runs per 100 balls": "N/A"
            }}
        />
        <ScoringNote />
    </div>;
};

const CricketTestScoringView = () => {
    const intl = useIntl();
    return <div className="tw-flex tw-flex-col tw-gap-6 tw-max-h-[calc(min(91.8rem,85vh)-16.4rem)] overflow-x-auto">
        <ScoringTable
            title={intl.formatMessage({ id: "scoring_popup_subtitle_fantasy_points_system" })}
            scoring={{
                "Being a part of the starting XI": "4",
                "Every run scored": "1",
                "Every wicket taken (excluding run out)": "16",
                "Catch taken": "8",
                "Caught & Bowled": "24",
                "Stumping/Run Out (direct)": "12",
                "Run Out (Thrower/Catcher)": "6/6",
                "Dismissal for a Duck (only for batsmen, wicket-keepers and all-rounders)": "-4"
            }}
        />
        <ScoringTable
            title={intl.formatMessage({ id: "scoring_popup_subtitle_bonus_points" })}
            scoring={{
                "Every boundary hit": "1",
                "Every six-hit": "2",
                "Half Century (50 runs scored by a batsman in a single innings)":
                    "4",
                "Century (100 runs scored by a batsman in a single innings)": "8",
                "Bonus (LBW / Bowled)": "8",
                "3 Catch Bonus": "N/A",
                "Maiden Over": "N/A",
                "4 wickets Bonus": "4",
                "5 wickets Bonus": "8",
                "2 wickets Bonus": "N/A",
                "3 wickets Bonus": "N/A",
                "30 runs scored by a batsman in a single innings": "N/A"
            }}
        />
        <ScoringTable
            title={intl.formatMessage({ id: "scoring_popup_subtitle_economy_rate" })}
            scoring={{
                "Minimum overs bowled by player to be applicable": "N/A",
                "Below 5 runs per over": "N/A",
                "Between 5-5.99 runs per over": "N/A",
                "Between 6-7 runs per over": "N/A",
                "Between 10-11 runs per over": "N/A",
                "Between 11.01-12 runs per over": "N/A",
                "Above 12 runs per over": "N/A",
                "Below 2.5 runs per over": "N/A",
                "Between 2.5-3.49 runs per over": "N/A",
                "Between 3.5-4.5 runs per over": "N/A",
                "Between 7-8 runs per over": "N/A",
                "Between 8.01-9 runs per over": "N/A",
                "Above 9 runs per over": "N/A",
                "Below 7 runs per over": "N/A",
                "Between 7-7.99 runs per over": "N/A",
                "Between 8-9 runs per over": "N/A",
                "Between 14-15 runs per over": "N/A",
                "Between 15.01-16 runs per over": "N/A",
                "Above 16 runs per over": "N/A"
            }}
        />
        <ScoringTable
            title={intl.formatMessage({ id: "scoring_popup_subtitle_strike_rate" })}
            scoring={{
                "Minimum balls faced by a player to be applicable": "N/A",
                "Between 60-70 runs per 100 balls": "N/A",
                "Between 50-59.99 runs per 100 balls": "N/A",
                "Below 50 runs per 100 balls": "N/A",
                "Between 130-150 runs per 100 balls": "N/A",
                "Between 150.01-170 runs per 100 balls": "N/A",
                "Above 170 runs per 100 balls": "N/A",
                "Between 40-50 runs per 100 balls": "N/A",
                "Between 30-39.99 runs per 100 balls": "N/A",
                "Below 30 runs per 100 balls": "N/A",
                "Between 100-120 runs per 100 balls": "N/A",
                "Between 120.01-140 runs per 100 balls": "N/A",
                "Above 140 runs per 100 balls": "N/A",
                "Between 70-80 runs per 100 balls": "N/A",
                "Between 60-69.99 runs per 100 balls": "N/A",
                "Below 60 runs per 100 balls": "N/A",
                "Between 150-170 runs per 100 balls": "N/A",
                "Between 170.01-190 runs per 100 balls": "N/A",
                "Over 190 runs per 100 balls": "N/A"
            }}
        />
        <ScoringNote />
    </div>;
};

const CricketT10ScoringView = () => {
    const intl = useIntl();
    return <div className="tw-flex tw-flex-col tw-gap-6 tw-max-h-[calc(min(91.8rem,85vh)-16.4rem)] overflow-x-auto">
        <ScoringTable
            title={intl.formatMessage({ id: "scoring_popup_subtitle_fantasy_points_system" })}
            scoring={{
                "Being a part of the starting XI": "4",
                "Every run scored": "1",
                "Every wicket taken (excluding run out)": "25",
                "Catch taken": "8",
                "Caught & Bowled": "33",
                "Stumping/Run Out (direct)": "12",
                "Run Out (Thrower/Catcher)": "6/6",
                "Dismissal for a Duck (only for batsmen, wicket-keepers and all-rounders)": "-2"
            }}
        />
        <ScoringTable
            title={intl.formatMessage({ id: "scoring_popup_subtitle_bonus_points" })}
            scoring={{
                "Every boundary hit": "1",
                "Every six-hit": "2",
                "Half Century (50 runs scored by a batsman in a single innings)": "16",
                "Century (100 runs scored by a batsman in a single innings)": "N/A",
                "Bonus (LBW / Bowled)": "8",
                "3 Catch Bonus": "4",
                "Maiden Over": "16",
                "4 wickets Bonus": "N/A",
                "5 wickets Bonus": "N/A",
                "2 wickets Bonus": "8",
                "3 wickets Bonus": "16",
                "30 runs scored by a batsman in a single innings": "8"
            }}
        />
        <ScoringTable
            title={intl.formatMessage({ id: "scoring_popup_subtitle_economy_rate" })}
            scoring={{
                "Minimum overs bowled by player to be applicable": "1 Overs",
                "Below 5 runs per over": "N/A",
                "Between 5-5.99 runs per over": "N/A",
                "Between 6-7 runs per over": "N/A",
                "Between 10-11 runs per over": "N/A",
                "Between 11.01-12 runs per over": "N/A",
                "Above 12 runs per over": "N/A",
                "Below 2.5 runs per over": "N/A",
                "Between 2.5-3.49 runs per over": "N/A",
                "Between 3.5-4.5 runs per over": "N/A",
                "Between 7-8 runs per over": "N/A",
                "Between 8.01-9 runs per over": "N/A",
                "Above 9 runs per over": "N/A",
                "Below 7 runs per over": "6",
                "Between 7-7.99 runs per over": "4",
                "Between 8-9 runs per over": "2",
                "Between 14-15 runs per over": "-2",
                "Between 15.01-16 runs per over": "-4",
                "Above 16 runs per over": "-6"
            }}
        />
        <ScoringTable
            title={intl.formatMessage({ id: "scoring_popup_subtitle_strike_rate" })}
            scoring={{
                "Minimum balls faced by a player to be applicable": "5 balls",
                "Between 60-70 runs per 100 balls": "N/A",
                "Between 50-59.99 runs per 100 balls": "N/A",
                "Below 50 runs per 100 balls": "N/A",
                "Between 130-150 runs per 100 balls": "N/A",
                "Between 150.01-170 runs per 100 balls": "N/A",
                "Above 170 runs per 100 balls": "N/A",
                "Between 40-50 runs per 100 balls": "N/A",
                "Between 30-39.99 runs per 100 balls": "N/A",
                "Below 30 runs per 100 balls": "N/A",
                "Between 100-120 runs per 100 balls": "N/A",
                "Between 120.01-140 runs per 100 balls": "N/A",
                "Above 140 runs per 100 balls": "N/A",
                "Between 70-80 runs per 100 balls": "-2",
                "Between 60-69.99 runs per 100 balls": "-4",
                "Below 60 runs per 100 balls": "-6",
                "Between 150-170 runs per 100 balls": "2",
                "Between 170.01-190 runs per 100 balls": "4",
                "Over 190 runs per 100 balls": "6"
            }}
        />
        <ScoringNote />
    </div>;
};

const scoringList = [
    {
        name: "T20",
        components: CricketT20ScoringView,
    },
    {
        name: "ODI",
        components: CricketOdiScoringView,
    },
    {
        name: "Test",
        components: CricketTestScoringView,
    },
    {
        name: "T10",
        components: CricketT10ScoringView,
    }
];

let timeout: NodeJS.Timeout;

const CricketScoringView = () => {
    const [value, setValue] = useState(scoringList[0].name);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    const onChange = (name: string) => {
        if (value === name) return;

        clearTimeout(timeout);
        setIsLoading(true);
        setValue(name);

        timeout = setTimeout(() => {
            setIsLoading(false);
        }, 500);
    };

    return <div className="tw-w-full">
        <Tabs className="tw-w-full" defaultValue={value}>
            <TabsList>
                {
                    scoringList.map((scoring) => {
                        return <TabsTrigger key={scoring.name}
                                            value={scoring.name}
                                            onClick={() => {
                                                onChange(scoring.name);
                                            }}>{scoring.name}</TabsTrigger>;
                    })
                }
            </TabsList>
            {
                isLoading
                    ? <ScoringLoadingSection />
                    : <>
                        {
                            scoringList.map((scoring) => {
                                return <TabsContent key={scoring.name} value={scoring.name}>
                                    <scoring.components />
                                </TabsContent>;
                            })
                        }
                    </>
            }
        </Tabs>
    </div>;
};


export default CricketScoringView;
