import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { UserDeleteSubmitContainer } from "components/userDelete/UserDeleteSubmitStyle";
import useFantasyCommand from "hooks/useFantasyCommand";
import { CheckEmail } from "components/userDelete/CheckEmail";
import SubmitEmailForm from "components/userDelete/SubmitEmailForm";
import { UserRepository } from "data/repository/user/UserRepository";
import { createErrorObject } from "helpers/ErrorHelper";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import useFantasyHistory from "hooks/useFantasyHistory";
import { ERROR_HANDLER_FLOW } from "hooks/useHandleApiError";

const UserDeleteSubmit = () => {
    const history = useFantasyHistory();
    const [email, setEmail] = useState("");

    const userRepository = new UserRepository();

    const deleteUser = useFantasyCommand((data: Record<string, string>) => {
        const { email } = data;
        setEmail(email);
        return userRepository.submitDeleteUser(email);
    }, {
        onFail: (error) => {
            history.push(ROUTER_PATH_CONFIG.error, {
                error: createErrorObject(error)
            });
            return ERROR_HANDLER_FLOW.STOP;
        },
    });

    return <UserDeleteSubmitContainer>
        <Form.Label className={"fz-sf-bold-title-1 text-sys-danger-1 mb-5 text-center"}>
            {"⚠︎ "}
            <FormattedMessage id="user_delete_page_title" />
        </Form.Label>
        {
            deleteUser.isSuccess
                ? (<CheckEmail email={email} />)
                : (<SubmitEmailForm isLoading={deleteUser.isLoading}
                                    onMutate={deleteUser.mutate} />)
        }
    </UserDeleteSubmitContainer>;
};

export default UserDeleteSubmit;
