import {
    Contest,
    ContestBodyRow,
    ContestCardInfo,
    ContestFooterRow,
    ContestInfoRow,
    ContestName
} from "domain/match/pages/upcoming/contest/card/ContestCardStyle";
import React from "react";
import { Placeholder } from "react-bootstrap";
import { CirclePlaceHolder, TextPlaceHolder } from "../SkeletonStyle";

const ContestCardSkeleton = () => {

    return <Placeholder animation="glow">
        <div className="px-4">
            <Contest className="mb-3">
                <ContestBodyRow>
                    <ContestCardInfo>
                        <ContestName className="fz-sf-regular-body-2">
                            <TextPlaceHolder $width={12} $height={1.2} />
                        </ContestName>
                        <ContestInfoRow className="flex-grow-1">
                            <div className="">
                                <div className="pb-2">
                                    <TextPlaceHolder $width={8} $height={1} />
                                </div>
                                <div>
                                    <TextPlaceHolder $width={12} $height={2} />
                                </div>
                            </div>
                        </ContestInfoRow>
                    </ContestCardInfo>
                    <div>
                        <div>
                            <CirclePlaceHolder $size={7}
                                               className="d-inline-flex justify-content-center align-items-center">
                                <CirclePlaceHolder $size={5.5} $bg="bg-gray-1" />
                            </CirclePlaceHolder>
                        </div>
                        <div className="mt-2" style={{ height: "1.9rem" }}>
                            <TextPlaceHolder $width={7} $height={1.9} />
                        </div>
                    </div>
                </ContestBodyRow>
                <ContestFooterRow>
                    <div style={{ height: "1.9rem" }}>
                        {
                            [...Array(6)].map((_, index) => {
                                return <TextPlaceHolder key={index} $width={4.4} $height={1.9} className="me-3" />;
                            })
                        }
                    </div>
                </ContestFooterRow>
            </Contest>
        </div>
    </Placeholder>;
};

export default ContestCardSkeleton;
