import { fetchAuthConfigUrl } from "apis/ApiUrlConfig";
import { ajax } from "apis/ApiUtils";
import { AuthConfigDto } from "../../dto/auth/AuthConfigDto";

export const fetchAuthConfig = async (): Promise<AuthConfigDto> => {
    const { data: { data } } = await ajax<AuthConfigDto>({
        url: fetchAuthConfigUrl
    });
    return data;
};
