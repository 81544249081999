import { fetchCurrentApLevelList } from "apis/ap/ActivityPointApi";
import { ApiExecuteContext } from "apis/ApiContext";
import UserAvatarWithFrame from "components/avatar/UserAvatarWithFrame";
import SwipeButton from "components/buttons/SwipeButton";
import { useLoadingToggle } from "components/loading/Loading";
import { getUserIdToCurrentLevel } from "domain/ap/ActivityPointHelper";
import {
    APInfoButtonIcon,
    AvatarWrapper,
    ClearAmountButton,
    DeleteNumberIcon,
    DisplayAmount,
    DisplayAmountWrapper,
    NumberButton,
    NumberButtonWrapper,
    TransferAmountContentWrapper,
    TransferUserInfo,
    UserName,
    WinningText,
    WinningWrapper,
    ZeroButton
} from "domain/friend/transfer/amount/TransferAmountStyle";
import { TransferNavbar } from "domain/friend/transfer/TransferFlow";
import { checkTransferAmountConditions, popDigit, pushDigitFrom0To9 } from "domain/friend/transfer/TransferHelper";
import { TransferWrapper } from "domain/friend/transfer/TransferStyle";
import { infoIcon } from "helpers/IconHelper";
import SYSTEM_LINKS from "helpers/LinkHelper";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import { useLocaleProvider } from "LocaleProvider";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SYSTEM_COLORS } from "styles/Constants";

const APInfoButton = () => {
    const { locale } = useLocaleProvider();
    return <APInfoButtonIcon
        $bgImg={infoIcon}
        href={SYSTEM_LINKS.apProgram(locale)}
        target="_blank"
    />;
};

const TransferAmount = ({ toPrevStep, toNextStep, myTransferred, selectedFriend, inputAmount, setInputAmount }) => {
    const intl = useIntl();
    const apiExecutor = useContext(ApiExecuteContext);
    const toggleLoading = useLoadingToggle();
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();

    const [userIdToCurrentLevel, setUserIdToCurrentLevel] = useState({});

    const winning = divideByOneHundred(myTransferred.winningCent);
    const todayQuota = divideByOneHundred(myTransferred.todayCentQuota);
    const {
        message: errorMessage,
        showInfoIcon,
        canProceed
    } = checkTransferAmountConditions(myTransferred, inputAmount, intl.formatMessage, toWalletNumber);

    const onNumberClicked = (number) => {
        if (errorMessage) return;
        setInputAmount(prev => pushDigitFrom0To9(prev, number));
    };

    const clearDisplayAmount = () => setInputAmount(0);

    const onBackspaceClicked = () => setInputAmount(prev => popDigit(prev));

    const StatusView = () => {
        const color = errorMessage ? SYSTEM_COLORS.red : SYSTEM_COLORS.labelLightSecondary;

        const content = errorMessage || <>
            <FormattedMessage
                id="label_winning" />:<WinningText>{toWalletNumber(winning)}</WinningText>
                                     (<FormattedMessage id="transfer_page_label_today_quota" values={{
            number: toWalletNumber(todayQuota)
        }} />)
        </>;

        return <WinningWrapper color={color}>
            {content}
            {showInfoIcon && <APInfoButton />}
        </WinningWrapper>;
    };

    const getCurrentApLevelList = useCallback(userIds => {
        return apiExecutor(
            fetchCurrentApLevelList(userIds),
            {
                onSuccess: (res) => setUserIdToCurrentLevel(getUserIdToCurrentLevel(res))
            }
        );
    }, [apiExecutor]);

    useEffect(() => {
        (async () => {
            toggleLoading(true);
            await getCurrentApLevelList([selectedFriend.userId]);
            toggleLoading(false);
        })();
    }, [getCurrentApLevelList, toggleLoading, selectedFriend.userId]);

    return <>
        <TransferNavbar
            text={intl.messages["transfer_page_title"]}
            handleBack={toPrevStep}
        />
        <TransferWrapper>
            <TransferAmountContentWrapper>
                <TransferUserInfo>
                    <AvatarWrapper>
                        <UserAvatarWithFrame url={selectedFriend.avatar}
                                             name={selectedFriend.nickname}
                                             font="fz-sf-bold-title-1"
                                             size="5.6rem"
                                             active
                                             currentLevel={userIdToCurrentLevel[selectedFriend.userId]} />
                    </AvatarWrapper>
                    <UserName>{selectedFriend.nickname}</UserName>
                </TransferUserInfo>

                <StatusView />

                <DisplayAmountWrapper>
                    <DisplayAmount color={errorMessage && SYSTEM_COLORS.red}>
                        {inputAmount === 0 ? 0 : toWalletNumber(inputAmount)}
                    </DisplayAmount>
                    <ClearAmountButton onClick={clearDisplayAmount} />
                </DisplayAmountWrapper>

                <NumberButtonWrapper>
                    <NumberButton onClick={() => onNumberClicked(1)}>1</NumberButton>
                    <NumberButton onClick={() => onNumberClicked(2)}>2</NumberButton>
                    <NumberButton onClick={() => onNumberClicked(3)}>3</NumberButton>
                    <NumberButton onClick={() => onNumberClicked(4)}>4</NumberButton>
                    <NumberButton onClick={() => onNumberClicked(5)}>5</NumberButton>
                    <NumberButton onClick={() => onNumberClicked(6)}>6</NumberButton>
                    <NumberButton onClick={() => onNumberClicked(7)}>7</NumberButton>
                    <NumberButton onClick={() => onNumberClicked(8)}>8</NumberButton>
                    <NumberButton onClick={() => onNumberClicked(9)}>9</NumberButton>
                    <ZeroButton onClick={() => onNumberClicked(0)}>0</ZeroButton>
                    <NumberButton onClick={onBackspaceClicked}>
                        <DeleteNumberIcon />
                    </NumberButton>
                </NumberButtonWrapper>

                <SwipeButton disabled={!canProceed} onSuccess={toNextStep}>
                    <FormattedMessage id="transfer_page_button_next" />
                </SwipeButton>
            </TransferAmountContentWrapper>
        </TransferWrapper>
    </>;
};

export default TransferAmount;

TransferAmount.propTypes = {
    myTransferred: PropTypes.shape({
        winningCent: PropTypes.number,
        todayCentQuota: PropTypes.number
    }).isRequired
};
