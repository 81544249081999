import styled from "styled-components";

export const DialogSmBody = styled.div.attrs({
    className: "bg-light-primary"
})`
`;

export const DialogSmContentWrapper = styled.div.attrs({
    className: "border-light-primary text-center "
})`
  padding: 2.4rem;
  border-bottom-width: 1px;
  border-bottom-style: solid;
`;

export const DialogSmContent = styled.div.attrs(() => ({
    className: "fz-sf-regular-body-2 text-light-primary"
}))`
  * {
    font: inherit;
  }
`;

export const DialogSmButtonWrapper = styled.div`
  padding: 1.2rem;
`;
export const DialogSmButton = styled.div.attrs(() => ({
    className: "fz-sf-bold-title-3 text-center text-sys-info-1"
}))`
  cursor: pointer;
`;