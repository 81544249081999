import React from "react";
import { ListItem, ListTitle, ListValue } from "../components/TransactionDetailStyle";
import { TransactionDetailVo } from "data/vo/wallet/TransactionDetailVo";
import { FormattedMessage } from "react-intl";
import { StatusLabel } from "domain/wallet/transactionDetail/StatusLabel";

interface StatusDetailsProps {
    transactionDetailData: TransactionDetailVo
}

const StatusDetail: React.FC<StatusDetailsProps> = ({
                                                        transactionDetailData
                                                    }) => {


    return <>
        <ListItem>
            <ListTitle><FormattedMessage id="label_status" /></ListTitle>
            <ListValue>
                <StatusLabel transactionDetailData={transactionDetailData} />
            </ListValue>
        </ListItem>
    </>;
};

export default StatusDetail;
