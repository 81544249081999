import styled, { css } from "styled-components";

export const StyledCareerStatsSportTitle = styled.div.attrs({
    className: "fz-sf-bold-title-3 text-light-primary mb-0",
})`
`;

export const StyledCareerStatsDataWrapper = styled.div.attrs({
    className: "d-flex align-items-start gap-3 py-3",
})``;

export const StyledCareerStatsProgress = styled.div.attrs({
    className: "flex-shrink-0",
})`
    width: 8rem;
    height: 8rem;
`;

export const StyledCareerStatsProgressContent = styled.div.attrs({
    className: "d-flex align-items-center justify-content-center flex-column",
})``;

export const StyledCareerStatsProgressPr = styled.div.attrs<{
    $color: string;
}>({
    className: "fz-sf-bold-title-2",
})`
    ${({ $color }) => {
        return css`
            color: ${$color};
        `;
    }}
`;

export const StyledCareerStatsProgressPrLabel = styled.div.attrs({
    className: "fz-sf-regular-caption-2 text-light-secondary",
})``;

export const StyledCareerStatsData = styled.div.attrs({
    className: "d-flex flex-column gap-2",
})``;

export const StyledCareerStatsDataTitle = styled.span.attrs<{
    $color: string;
}>({
    className: "fz-sf-regular-footnote",
})`
    ${({ $color }) => {
        return css`
            color: ${$color};
        `;
    }}
`;

export const StyledCareerStatsDataValueWrapper = styled.div.attrs({
    className: "d-flex gap-4 align-items-center",
})`
    > div {
        flex-shrink: 0;
    }
`;

export const StyledCareerStatsDataValueTitleWrapper = styled.span.attrs({
    className: "d-flex gap-1 align-items-center",
})``;

export const StyledCareerStatsDataValueTitle = styled.span.attrs({
    className: "fz-sf-regular-footnote text-light-secondary",
})``;

export const StyledCareerStatsDataValue = styled.p.attrs({
    className: "fz-sf-bold-title-3 text-light-primary mb-0",
})``;

export const StyledCareerStatsDivider = styled.div.attrs({
    className: "",
})`
    width: 0.1rem;
    height: 2.4rem;
    background-color: #e1e1e1;
`;

export const StyledCareerStatsDataText = styled.p.attrs({
    className: "fz-sf-regular-footnote text-light-secondary mb-0",
})``;

export const StyledCareerStatsDataSpanText = styled.span.attrs({
    className: "fz-sf-regular-footnote text-light-primary mb-0",
})``;
