import { useState } from "react";
import SwiperCore from "swiper";
import { Swiper } from "swiper/types";
import { findYByX } from "../BezierHelper";
import { useApLevelCarouselConfig } from "./useApLevelCarouselConfig";
import { analyticsEvent, logCustomEvent } from "ga";

interface ApLevelCarouselProps {
    currentCarouselIndex: number,
    handleCarouselIndexChange: (index: number | ((pre: number) => number)) => void,
}

export const useApLevelCarousel = ({
                                       currentCarouselIndex,
                                       handleCarouselIndexChange
                                   }: ApLevelCarouselProps) => {
    const {
        width,
        getScaleSize,
        leftBezierConfig,
        rightBezierConfig,
        slideWidth
    } = useApLevelCarouselConfig();

    const [firstSliderTranslate, setFirstSliderTranslate] = useState<number>(0);
    const [swiper, setSwiper] = useState<SwiperCore | null>(null);


    const getCurrentIndex = (translate: number, prevIndex: number) => {
        const currentIndex = Math.floor((width / 2 - translate) / slideWidth - 0.5);

        if (currentIndex >= prevIndex) {
            return Math.min(currentIndex, 9);
        }

        return Math.max(currentIndex + 1, 0);
    };

    const getItemPositionX = (index: number) => {
        const centreOffset = slideWidth / 2;
        const itemOffset = index * slideWidth;
        return firstSliderTranslate + itemOffset + centreOffset;
    };

    const getPositionY = (positionX: number) => {
        if (positionX < 0 || positionX > width)
            return 0;

        const config = positionX < width / 2 ? leftBezierConfig : rightBezierConfig;
        return findYByX(positionX, config);
    };

    const getOffsetY = (index: number) => {
        const positionX = getItemPositionX(index);
        const positionY = getPositionY(positionX);
        const heightOffset = 10 * getScaleSize(currentCarouselIndex - index);
        return positionY - heightOffset;
    };

    const handleSetTranslate = (swiper: Swiper, translate: number) => {
        if (isNaN(translate)) {
            return;
        }

        handleCarouselIndexChange(prevIndex => getCurrentIndex(translate, prevIndex));
        setFirstSliderTranslate(translate);
    };

    const handleSwiperSlideClick = (index: number) => () => {
        logCustomEvent(analyticsEvent.webClickTaskLevel);
        swiper?.slides.forEach((slide) => {
            slide.style.transition = "all 0.1s ease";
        });

        const slideTimes = Math.abs(currentCarouselIndex - index);
        for (let i = 0; i < slideTimes; i++) {
            setTimeout(() => {
                if (index > currentCarouselIndex) {
                    swiper?.slideNext(100);
                } else {
                    swiper?.slidePrev(100);
                }
            }, 150 * i);
        }

        setTimeout(() => {
            swiper?.slides.forEach((slide) => {
                slide.style.transition = "";
            });
        }, 150 * slideTimes);
    };

    const handleRealIndexChange = (swiper: Swiper) => {
        handleCarouselIndexChange(swiper.realIndex);
    };

    const handelSwiperInit = (swiper: SwiperCore) => setSwiper(swiper);

    const getProgress = (index: number) => {
        const offset = slideWidth / 1.8;
        const positionX = getItemPositionX(index);

        if (positionX < 0) {
            return 0;
        }

        if (positionX > width) {
            return 1;
        }

        return Math.min((positionX + offset) / width, 1);
    };

    return {
        handelSwiperInit,
        handleRealIndexChange,
        handleSwiperSlideClick,
        handleSetTranslate,
        getCurrentIndex,
        getOffsetY,
        getProgress
    };
};
