import { TournamentToSupplier } from "components/supplierLogo/SupplierHelper";
import { GameLogVo } from "data/vo/player/gameLog/GameLogVo";
import { PerformanceVo } from "data/vo/player/performance/PerformanceVo";
import { INJURY_PLAYING_CHANCE } from "data/vo/player/profile/BasketballPlayerInfoProfileVo";
import { PlayerInfoProfileVo } from "data/vo/player/profile/PlayerInfoProfileVo";
import { LINEUP } from "types/player/Player";
import { ROLE, SPORT } from "types/sports/Sport";

export interface Profile {
    firstName: string | null;
    lastName: string | null;
    middleName: string | null;
    birthdate: string | null;
    nationality: string | null;
    lineup: LINEUP;
    role: ROLE | null;
    creditCent: number;
    pointAvgCent: number;
    playerSelectionRate: number;
    captainSelectionRate: number;
    viceCaptainSelectionRate: number;

    battingStyle?: string | null;
    bowlingStyle?: string | null;

    height?: number | null;
    weight?: number | null;
    preferredFoot?: string | null;

    injuryStartTime?: number | null;
    injuryBodyPart?: string | null;
    injuryPlayingChance?: INJURY_PLAYING_CHANCE | null;
}

export interface Performance {
    recentPointsCents: number[];

    averageEconomyRatePointsCent?: number | null;
    averageStrikeRatePointsCent?: number | null;
    averageRunPointsCent?: number | null;
    averagePlayerPointsCent?: number | null;

    averageGoalPointsCent?: number | null;
    averageAssistPointsCent?: number | null;


    averagePointsCent?: number | null;
    averageReboundCent?: number | null;
    averageStealCent?: number | null;
}

interface PointCentDetail {
    starting?: number | null;
    run?: number | null;
    four?: number | null;
    six?: number | null;
    strikeRate?: number | null;
    fifty?: number | null;
    duck?: number | null;
    wickets?: number | null;
    maidenOver?: number | null;
    economyRate?: number | null;
    catches?: number | null;
    runOutStumping?: number | null;
    runOutThrower?: number | null;
    runOutCatcher?: number | null;
    directRunOut?: number | null;
    stumping?: number | null;
    thirty?: number | null;
    bonus?: number | null;
    bonusCatch?: number | null;
    bonusBowedLBW?: number | null;

    goal?: number | null;
    assist?: number | null;
    shotsOnTarget?: number | null;
    chanceCreated?: number | null;
    passesCompleted?: number | null;
    tackleWon?: number | null;
    interceptionWon?: number | null;
    save?: number | null;
    penaltiesSaved?: number | null;
    cleanSheet?: number | null;
    yellowCard?: number | null;
    redCard?: number | null;
    ownGoal?: number | null;
    goalsConceded?: number | null;
    penaltyMissed?: number | null;
    starting11?: number | null;
    substitute?: number | null;

    pointsCent?: number;
    reboundsCent?: number;
    assistsCent?: number;
    stealsCent?: number;
    blocksCent?: number;
    turnoversCent?: number;

    fieldGoalsMadeCent?: number;
    fieldGoalsAttemptedCent?: number;
    freeThrowsMadeCent?: number;
    freeThrowsAttemptedCent?: number;
    offensiveReboundsCent?: number;
    defensiveReboundsCent?: number;
    threePointersMadeCent?: number;
}

export interface FlattedGameLogProps extends PointCentDetail {
    matchStartsAt: number;
    oppositeSquadCode: string;
    totalPointCent: number;
    personalFoulsCent?: number | null;
    minutesPlayed?: number | null;
    squadAScores?: string | null;
    squadBScores?: string | null;
    winningSquadCode?: string | null;
    playerSquadCode?: string | null;
}


export class PlayerInfoVo {
    sport: SPORT;
    oppositeSquadCode: string;
    profile: PlayerInfoProfileVo;
    vsOpponentPerformance: (PerformanceVo | null);
    gameLogs: GameLogVo[];
    tournament: string;

    constructor(sport: SPORT,
                oppositeSquadCode: string,
                profile: PlayerInfoProfileVo,
                performance: PerformanceVo | null,
                gameLogs: GameLogVo[],
                tournament: string
    ) {
        this.sport = sport;
        this.oppositeSquadCode = oppositeSquadCode;
        this.profile = profile;
        this.vsOpponentPerformance = performance;
        this.gameLogs = gameLogs;
        this.tournament = tournament;
    }

    get supplier() {
        return TournamentToSupplier[this.tournament] || null;
    }
}
