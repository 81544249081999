import { MatchResultPlayerVo } from "data/vo/player/matchResult/MatchResultPlayersVo";
import { Avatar } from "domain/result/detail/components/Info/component/Avatar";
import { cn } from "helpers/cn";
import React from "react";

interface PlayerCardProps {
    isMvp?: boolean;
    player: MatchResultPlayerVo;
    onAvatarClick: (player: MatchResultPlayerVo) => void;
}

const getAvatarBackGround = (isHome: boolean) => {
    return isHome ? "tw-bg-grey-100/80" : "tw-bg-grey-900";
};

export const PlayerCard: React.FC<PlayerCardProps> = ({ player, isMvp, onAvatarClick }) => {

    return <div className={cn(
        "tw-w-[10rem] tw-min-h-[13rem]",
        "tw-flex tw-flex-col tw-items-center tw-justify-between",
    )}>
        <Avatar
            mvp={isMvp} id={player.id}
            url={player.avatar}
            background={getAvatarBackGround(player.isHome!)}
            onClick={() => onAvatarClick(player)}
        />
        <div className="tw-text-caption-1">
            {player.name}
        </div>
        <div className="tw-text-subtitle-1 tw-font-bold">
            {player.point}
        </div>
    </div>;
};

