import { FantasyCheckbox } from "components/checkbox/FantasyCheckbox";
import { CHECKBOX_VARIANTS } from "components/checkbox/types";
import FantasyBasicPopup from "components/popup/FantasyBasicPopup";
import { BUTTON_PATTERN, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { KycCompleteYourKYCPopup } from "domain/popup/kyc/KycCompleteYourKYCPopup";
import { useKycPopup } from "domain/popup/kyc/KycPopupProvider";
import { kycUnverified } from "helpers/IconHelper";
import useMyProfile from "hooks/useMyProfile";
import React from "react";
import { useIntl } from "react-intl";

export const KycUnverifiedPopup = () => {
    const intl = useIntl();
    const context = useKycPopup();
    const { myProfile } = useMyProfile();

    if (myProfile.isReachKycCompleteDeadline) {
        return <KycCompleteYourKYCPopup
            show={context.isShowUnverified}
            onConfirm={() => {
                context.handleCloseUnverified();
                context.goToKycForm();
            }}
            onCancel={context.handleCloseUnverified}
        />;

    }

    return <FantasyBasicPopup
        show={context.isShowUnverified}
        onHide={context.handleCloseUnverified}
        imageSection={<img src={kycUnverified} alt="kyc-unverified" className="tw-max-w-[8.8rem]" />}
        title={intl.formatMessage({ id: "kyc_verify_to_unlock" })}
        texts={[
            {
                text: intl.formatMessage({ id: "kyc_verify_to_unlock_content" }, { discountPercentage: 20 }),
                textClassName: "tw-text-left",
            },
            {
                text: intl.formatMessage({ id: "kyc_verify_to_unlock_note" }, { discountPercentage: 20 }),
                textClassName: "tw-text-left",
            }
        ]}
        buttonSetting={{
            confirmButtonVariant: NEW_BUTTON_VARIANTS.primaryDark,
            cancelButtonVariant: NEW_BUTTON_VARIANTS.text,
            buttonPattern: BUTTON_PATTERN.rounded,
            confirmButtonText: intl.formatMessage({ id: "kyc_start_verification" }),
            cancelButtonText: intl.formatMessage({ id: "kyc_do_it_later" }),
            onConfirm: () => {
                context.handleCloseUnverified();
                context.goToKycForm();
            },
            onCancel: context.handleCloseUnverified,
        }}
        bottomSection={<div className="tw-flex tw-text-center tw-items-center">
            <FantasyCheckbox variant={CHECKBOX_VARIANTS.white}
                             checked={context.isDoNotShowAgainChecked}
                             text={intl.formatMessage({ id: "kyc_do_not_show_again" })}
                             onClick={() => context.setIsDoNotShowAgainChecked(prev => !prev)} />
        </div>}
    />;
};
