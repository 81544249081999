import React from "react";
import { TransactionDetailVo } from "data/vo/wallet/TransactionDetailVo";
import { WALLET_API_RESPONSE_EVENT, WALLET_TYPE } from "data/dto/wallet/walletHistoryDto";
import TransactionDetailBase from "domain/wallet/transactionDetail/TransactionDetailBase";
import CoinContestMoreDetail from "domain/wallet/transactionDetail/CoinContestMoreDetail";
import { RefundContestNote } from "domain/wallet/transactionDetail/RefundContestNote";
import { RedemptionCodeContestNote } from "domain/wallet/transactionDetail/RedemptionCodeContestNote";
import TransactionProductDetail from "domain/wallet/winning/TransactionProductDetail";
import { ClaimTaskNote } from "domain/wallet/transactionDetail/ClaimTaskNote";
import StatusDetail from "domain/wallet/transactionDetail/StatusDetail";

interface DepositTransactionDetailProps {
    transactionData?: TransactionDetailVo
    onHide: () => void
}

const CoinTransactionDetail: React.FC<DepositTransactionDetailProps> = ({
                                                                            transactionData,
                                                                            onHide
                                                                        }) => {
    if (!transactionData) return null;

    switch (transactionData.event) {
        case WALLET_API_RESPONSE_EVENT.JOIN_CONTEST:
        case WALLET_API_RESPONSE_EVENT.REFUND_CONTEST:
        case WALLET_API_RESPONSE_EVENT.REFUND_DEPOSIT_CONTEST:
            return <TransactionDetailBase
                transactionData={transactionData}
                walletType={WALLET_TYPE.COIN}
                extraDetailComponent={CoinContestMoreDetail}
                noteComponent={RefundContestNote}
                onHide={onHide}
            />;

        case WALLET_API_RESPONSE_EVENT.REDEMPTION_CODE_DEPOSIT:
            return <TransactionDetailBase
                transactionData={transactionData}
                walletType={WALLET_TYPE.COIN}
                noteComponent={RedemptionCodeContestNote}
                onHide={onHide}
            />;

        case WALLET_API_RESPONSE_EVENT.PURCHASE_REWARD_VOUCHER:
        case WALLET_API_RESPONSE_EVENT.PURCHASE_REWARD_REDEPOSIT:
            return <TransactionDetailBase
                transactionData={transactionData}
                walletType={WALLET_TYPE.COIN}
                extraDetailComponent={() => (
                    <TransactionProductDetail product={transactionData.product} />
                )}
                onHide={onHide}
            />;

        case WALLET_API_RESPONSE_EVENT.CLAIM_TASK:
            return <TransactionDetailBase
                transactionData={transactionData}
                walletType={WALLET_TYPE.COIN}
                noteComponent={ClaimTaskNote}
                onHide={onHide}
            />;

        case WALLET_API_RESPONSE_EVENT.USER_DEPOSIT:
            return <TransactionDetailBase
                transactionData={transactionData}
                walletType={WALLET_TYPE.COIN}
                amountExtraComponent={() => <StatusDetail transactionDetailData={transactionData} />}
                noteComponent={ClaimTaskNote}
                onHide={onHide}
            />;

        default:
            return <TransactionDetailBase
                transactionData={transactionData}
                walletType={WALLET_TYPE.COIN}
                onHide={onHide}
            />;
    }
};

export default CoinTransactionDetail;
