import { getResultDetailPageUrl } from "router/RouterUrls";
import { MatchCard, MatchCardHeader, MatchCardTournament } from "components/matchCards/MatchCardStyle";
import { StartedMatchCardBody } from "components/matchCards/StartedMatchCardBody";
import { formatTo24HrTime } from "helpers/TimeHelper";
import React from "react";
import useFantasyHistory from "hooks/useFantasyHistory";

const ResultMatchCard = ({ sport, match }) => {
    const history = useFantasyHistory();
    const toResultDetail = (matchId: string) => {
        history.push(getResultDetailPageUrl(sport, matchId));
    };

    return <MatchCard
        className="animate__animated animate__fadeIn"
        data-testid={`result-match-card-${match.matchId}`}
        onClick={() => toResultDetail(match.matchId)}
    >
        <MatchCardHeader>
            <MatchCardTournament>{match.tournament}</MatchCardTournament>
        </MatchCardHeader>
        <StartedMatchCardBody match={match}>
            <div className="text-end fz-sf-regular-subhead text-text-tertiary-1">
                {formatTo24HrTime(match.matchStartsAt)}
            </div>
        </StartedMatchCardBody>
    </MatchCard>;
};

export default ResultMatchCard;
