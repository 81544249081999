import clsx from "clsx";
import { circleCloseIcon } from "helpers/IconHelper";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { SYSTEM_COLORS } from "../../../../styles/Constants";

export const ConfirmWrapper = styled.div`
    padding: 3rem 2.2rem 7rem;
`;

export const AvatarWrapper = styled.div.attrs({
    className: "d-flex justify-content-center"
})``;

export const ConfirmContent = styled.div.attrs({
    className: "position-relative tw-bg-system-bgLightPrimary"
})`
    padding: 1.6rem 1.6rem 2.4rem;
    border-radius: 1.6rem;
    margin-bottom: .1rem;
`;
export const FirstConfirmContent = styled(ConfirmContent).attrs({})`
    padding: 6.6rem 1.6rem 2.4rem;
`;
export const ConfirmUserInfo = styled.div.attrs({
    className: "position-absolute"
})`
    top: 0;
    left: 50%;
    transform: translate(-50%, -2.8rem);
`;
export const TransferContainer = styled(Container)``;
export const TransferRow = styled(Row)`
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    border-bottom: 1px solid ${SYSTEM_COLORS.labelLightQuaternary};
`;
export const TransferTitleCol = styled(Col).attrs({
    className: "d-flex align-items-center fz-sf-regular-subhead tw-text-system-labelLightSecondary"
})`
`;
export const TransferValueCol = styled(Col).attrs({
    className: "text-end fz-sf-bold-body-1 tw-text-system-labelLightPrimary"
})`
`;

export const TransferAmountCol = styled(TransferValueCol).attrs({
    className: "tw-text-system-red"
})`
`;
export const AmountRow = styled(TransferRow)`
    padding-top: 2.2rem;
    padding-bottom: 2.2rem;
`;
export const BalanceRow = styled(TransferRow).attrs({
    className: "tw-bg-system-neutral25"
})`
`;
export const WinningIcon = styled.div`
    width: 1.6rem;
    padding-bottom: 1.6rem;
    background-image: url(${({ icon }) => icon});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
`;
export const WinningText = styled.span.attrs({
    className: "ms-1"
})``;

export const NoteContentWrapper = styled.div.attrs({
    className: "position-relative"
})`

    &:focus-within {
        & .clear-note {
            display: block;
        }

        & input {
            padding: .55rem 2.5rem .55rem .8rem;
        }
    }
`;

export const NoteInput = styled.input.attrs(({ hasNote }) => ({
    className: clsx("fz-sf-regular-body-1 tw-text-primary-600", {
        "tw-border-primary-600": hasNote,
        "tw-border-system-labelLightQuaternary": !hasNote,
    })
}))`
    padding: .55rem .8rem;
    width: 100%;
    border-width: 0 0 1px 0;
    border-style: solid;

    &:focus-visible {
        outline: none;
    }
`;
export const NoteLength = styled.div.attrs({
    className: "text-end tw-text-system-labelLightSecondary"
})`
`;
export const ClearNote = styled.div.attrs({
    className: "position-absolute clear-note"
})`
    top: 15%;
    right: 0;
    width: 2.4rem;
    padding-bottom: 2.4rem;
    background-image: url(${circleCloseIcon});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    cursor: pointer;
`;
