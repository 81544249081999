import { cloneDeep, sortBy } from "lodash";
import { CONTEST_TYPE_FROM_API, ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";
import { LINEUP } from "types/player/Player";
import { BASKETBALL_ROLE } from "types/sports/Basketball";
import { CRICKET_ROLE } from "types/sports/Cricket";
import { FOOTBALL_ROLE } from "types/sports/Football";
import { ROLE, SPORT } from "types/sports/Sport";


export const DESC = "desc";
export const ASC = "asc";

export type SortingDirection = typeof ASC | typeof DESC

export const sortByTimeDifference = arr => arr && cloneDeep(arr).sort((a, b) => {
    const now = new Date().getTime();
    return Math.abs(a.matchStartsAt - now) - Math.abs(b.matchStartsAt - now);
});


export const sortByNumberKeyAsc = obj => {
    return (obj && Object.entries(obj)
        .sort(([k1], [k2]) => parseInt(k1) - parseInt(k2))
        .reduce((finalObj, [k, v]) => ({ ...finalObj, [k]: v }), {}));
};

export const sortByIdAsc = array => {
    const result = [...array];
    result.sort((a, b) => {
        if (!a.id || !b.id) {
            throw new Error("Invalid Id");
        }

        return a.id - b.id;
    });
    return result;
};

export const sortByIdDesc = array => {
    return array.sort((a, b) => {

        if (!a.id || !b.id) {
            throw new Error("Invalid Id");
        }

        return b.id - a.id;
    });
};

export const entryFeeComparator = (o) => {
    return o.entryFeeCent;
};

export const lineupComparator = (o) => {
    const orders = [
        LINEUP.STARTING_LINEUP,
        LINEUP.SUBSTITUTE,
        LINEUP.TO_BE_DECIDED
    ];

    return orders.indexOf(o.lineup);

};

export const sportComparator = (o) => {
    const orders = [
        SPORT.CRICKET,
        SPORT.FOOTBALL,
        SPORT.BASKETBALL
    ];

    return orders.indexOf(o.toLowerCase());
};

export const contestTypeComparator = (o) => {
    const orders = [
        CONTEST_TYPE_FROM_API.PRIVATE,
        CONTEST_TYPE_FROM_API.PROMOTIONAL,
        CONTEST_TYPE_FROM_API.PRIZE_HUNTER,
        CONTEST_TYPE_FROM_API.NORMAL,
        CONTEST_TYPE_FROM_API.PRACTICE
    ];

    return orders.indexOf(o.type);
};
export const contestEntryFeeTypeComparator = (o) => {
    const orders = [
        ENTRY_FEE_TYPE_FROM_API.DEPOSIT,
        ENTRY_FEE_TYPE_FROM_API.RUBY,
    ];

    return orders.indexOf(o.entryFeeType);
};
export const roleComparator = (role: ROLE) => {
    const orders = [
        CRICKET_ROLE.WICKET_KEEPER.apiValue,
        CRICKET_ROLE.BATSMAN.apiValue,
        CRICKET_ROLE.ALL_ROUNDER.apiValue,
        CRICKET_ROLE.BOWLER.apiValue,
        FOOTBALL_ROLE.GOAL_KEEPER.apiValue,
        FOOTBALL_ROLE.DEFENDER.apiValue,
        FOOTBALL_ROLE.MID_FIELDER.apiValue,
        FOOTBALL_ROLE.FORWARD.apiValue,
        BASKETBALL_ROLE.POINT_GUARD.apiValue,
        BASKETBALL_ROLE.SHOOTING_GUARD.apiValue,
        BASKETBALL_ROLE.SMALL_FORWARD.apiValue,
        BASKETBALL_ROLE.POWER_FORWARD.apiValue,
        BASKETBALL_ROLE.CENTER.apiValue
    ];

    return orders.indexOf(role);
};

export const sortByMatchStartsAt = matches => {
    return [...matches].sort((a, b) => {
        return a.matchStartsAt - b.matchStartsAt;
    });
};

export const sortByMatchStartsAtDesc = matches => {
    return [...matches].sort((a, b) => {
        return b.matchStartsAt - a.matchStartsAt;
    });
};

export const sortBySlateStartsAt = slates => {
    return [...slates].sort((a, b) => {
        return a.slateStartsAt - b.slateStartsAt;
    });
};

export const sortBySlateStartsAtAtDesc = slates => {
    return [...slates].sort((a, b) => {
        return b.slateStartsAt - a.slateStartsAt;
    });
};

export const sortByKeyAndName = (players, key, orderBy) => {
    let result = cloneDeep(players);

    result.sort((a, b) => {
        return a.name.localeCompare(b.name);
    });

    result.sort((a, b) => {
        return orderBy === DESC ? b[key] - a[key] : a[key] - b[key];
    });

    return result;
};

export const sortByRubyAndNickname = rubySummaries => {
    return sortBy(rubySummaries, ["rubyCent", "nickname"]);
};
