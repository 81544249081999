import cx from "classnames";
import styled, { css } from "styled-components";
import { HeaderLine } from "./CustomTab";

export const TabNavWrapper = styled.div.attrs<{
    $evenly: boolean
    $headerLine: HeaderLine
}>(({$headerLine}) => ({
    className: cx("d-flex position-relative", {
        "header-bottom-line": $headerLine === HeaderLine.bottom,
        "header-top-line": $headerLine === HeaderLine.top
    })
}))`
  gap: 1.6rem;
  ${({ $evenly }) => {
    if ($evenly) {
        return css`
        > * {
          flex-grow: 1;
        }
      `;
    } else {
        return css`
        overflow-x: auto;
        flex-shrink: 0`;
    }
}}
`;
export const TabItem = styled.div.attrs({
    className: "position-relative fz-sf-bold-body-1 text-center text-nowrap"
})`
  padding: .6rem .2rem 1rem;
  cursor: pointer;

  * {
    font: inherit;
  }
`;
export const ActiveTabItem = styled(TabItem).attrs({
    className: "nav-tabs-on-line"
})`
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: .4rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

`;
