import {
    MaxTextCount,
    StyledFormLabel,
    StyledTitleFormTextarea,
    TextareaContainer
} from "domain/me/profile/ProfileStyle";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SYSTEM_COLORS } from "styles/Constants";

export interface AboutMeProps {
    isEditing: boolean;
    description: string | null;
    onChangeDescription: (val: string) => void;
}

const AboutMe: React.FC<AboutMeProps> = ({ isEditing, description, onChangeDescription }) => {
    const intl = useIntl();

    return <>
        <TextareaContainer>
            <StyledFormLabel
                color={isEditing ? SYSTEM_COLORS.primary80 : SYSTEM_COLORS.labelLightSecondary}>
                <FormattedMessage id="edit_profile_page_label_about_me" />
            </StyledFormLabel>
            <StyledTitleFormTextarea
                placeholder={intl.formatMessage({ id: "edit_profile_page_about_me_input_label_hint" })}
                maxLength={200}
                rows={4}
                value={description || ""}
                disabled={!isEditing}
                onChange={(e) => onChangeDescription(e.target.value)} />
        </TextareaContainer>
        <MaxTextCount>{description?.length || 0} / 200</MaxTextCount>
    </>;
};

export default AboutMe;
