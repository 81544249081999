import { FriendDto } from "apis/user/UserApi";

export enum FRIEND_TYPE {
    FRIEND = "FRIEND",
    STRANGER = "STRANGER",
    ME = "ME"
}

export const generateDescription = (description) => {
    return description === null || description === undefined ? "" : description;
};

export const isFriend = (type: FRIEND_TYPE) => type === FRIEND_TYPE.FRIEND;
export const isMe = (type: FRIEND_TYPE) => type === FRIEND_TYPE.ME;
export const isStranger = (type: FRIEND_TYPE) => type === FRIEND_TYPE.STRANGER;

export const sortFriendsByNickname = (friends: FriendDto[]) => {
    const _friends = [...friends];

    _friends.sort((a, b) => {
        return a.nickname.localeCompare(b.nickname);
    });

    return _friends;
};

export const canFriendBeAdded = (type: FRIEND_TYPE, myFriendFull: boolean) => type === FRIEND_TYPE.STRANGER && !myFriendFull;

export const getUserIds = users => users.map(user => user.userId);
