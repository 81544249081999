import ContestCodePanel from "components/panel/contestCode";
import AmountDisplay from "containers/amount/AmountDisplay";
import { getCurrencyIconSuitByEntryFeeType } from "containers/amount/CurrencyIconSuitHelper";
import {
    WalletBalance,
    WalletBalanceRow,
    WalletBalanceTitle,
    WalletButtonImageWrapper,
    WalletButtonWrapper
} from "domain/wallet/components/button/WalletButtonStyle";
import { WalletPanel } from "domain/wallet/components/WalletPanel";
import useUpdateWallet from "domain/wallet/useUpdateWallet";
import { DISPLAY_DEPOSIT_LIMIT, isExceedDisplayDepositLimit } from "domain/wallet/WalletHelper";
import { Icon } from "helpers/IconHelper";
import { isNegative } from "helpers/number/NumberHelper";
import { getQuery } from "helpers/queryString";
import useCurrency from "hooks/useCurrency/useCurrency";
import useFantasyHistory from "hooks/useFantasyHistory";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { RootStoreState } from "store/reducer";
import { ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";

export const WalletBalanceLabel = ({ total }) => {
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();
    return <WalletBalance>
        <AmountDisplay currencyIconSuit={getCurrencyIconSuitByEntryFeeType(ENTRY_FEE_TYPE_FROM_API.DEPOSIT)}
                       className="tw-text-h3 tw-font-bold tw-text-grey-850 tw-gap-1">
            {toWalletNumber(total)}
        </AmountDisplay>
    </WalletBalance>;
};

export const WalletBalanceSection = ({ total }) => {
    return <WalletBalanceRow>
        <WalletBalance>
            <Icon.WalletBalance className="text-text-secondary-1" />
            <WalletBalanceTitle>
                <FormattedMessage id="wallet_view_title" />
            </WalletBalanceTitle>
        </WalletBalance>
        <WalletBalanceLabel total={total} />
    </WalletBalanceRow>;
};


export const WalletButtonContent = ({ total }) => {
    const textColor = isNegative(total) ? "text-sys-danger-1" : "text-text-primary-1";
    const walletButtonBgColor = isNegative(total) ? "bg-sys-danger" : "bg-primary-2";
    const { currentCurrencyObject: { toFormattedNumber, toWalletNumber } } = useCurrency();
    const displayTotal = isExceedDisplayDepositLimit(total)
        ? `${toFormattedNumber(DISPLAY_DEPOSIT_LIMIT)}+`
        : toWalletNumber(total);
    return <>
        <div data-testid="wallet-button-text" className={`fz-sf-bold-footnote tw-flex tw-items-center ${textColor}`}>
            <AmountDisplay currencyIconSuit={getCurrencyIconSuitByEntryFeeType(ENTRY_FEE_TYPE_FROM_API.DEPOSIT)}
                           className="tw-gap-0 tw-text-inherit">
                {displayTotal}
            </AmountDisplay>
        </div>
        <WalletButtonImageWrapper className={walletButtonBgColor} data-testid="wallet-button-icon">
            <Icon.WalletBalance className="tw-text-white" />
        </WalletButtonImageWrapper>
    </>;
};

const WalletButton = () => {
    const history = useFantasyHistory();
    const updateWallet = useUpdateWallet();
    const { WalletState: { total } } = useSelector((store: RootStoreState) => store);
    const { wallet: queryOpenWallet } = getQuery();
    const [openWallet, setOpenWallet] = useState(() => !!queryOpenWallet);

    const walletButtonRef = useRef<HTMLDivElement>(null);
    const [walletPanelStyle, setWalletPanelStyle] = useState<CSSProperties>({});

    useEffect(() => {
        if (queryOpenWallet) {
            history.replace({
                search: ""
            });
        }
    }, [history, queryOpenWallet]);

    const onWalletButtonClick = () => {
        setOpenWallet(!openWallet);
    };

    const contestCodePanel = ContestCodePanel.useContestCodePanel();

    const handleInvitationCircleButtonClick = () => {
        setOpenWallet(false);
        contestCodePanel.open();
    };

    const handleWithdrawButtonClick = () => {
        setOpenWallet(false);

        history.push(ROUTER_PATH_CONFIG.withdraw);
    };

    const handleWalletOverlayClick = () => {
        setOpenWallet(false);
    };


    useEffect(() => {
        const current = walletButtonRef.current;

        if (!current) return;
        const rect = current.parentElement!.getBoundingClientRect();

        const rectFromTopDistance = rect.y + rect.height;
        setWalletPanelStyle({
            left: 0,
            top: rectFromTopDistance,
            height: `calc(100vh - ${rectFromTopDistance}px)`
        });

    }, []);

    useEffect(() => {
        updateWallet();
    }, [updateWallet]);

    return <>
        <WalletButtonWrapper
            className="clickable"
            ref={walletButtonRef}
            onClick={onWalletButtonClick}
        >
            <WalletButtonContent total={total} />
        </WalletButtonWrapper>
        {
            openWallet && <WalletPanel
                walletPanelStyle={walletPanelStyle}
                handleWalletOverlayClick={handleWalletOverlayClick}
                handleInvitationCircleButtonClick={handleInvitationCircleButtonClick}
                handleWithdrawButtonClick={handleWithdrawButtonClick}
            />

        }
        <ContestCodePanel {...contestCodePanel} />
    </>;
};

export default WalletButton;
