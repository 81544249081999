export function removeAllSpaces(value: string) {
    return value.replaceAll(/\s/g, "");
}

export function formatToTextGroups(value: string, chunkSize: number = 4) {
    const strings = removeAllSpaces(value).split("");
    let res = "";
    for (let i = 0; i < strings.length; i++) {
        res += strings[i];
        if ((i + 1) % chunkSize === 0) {
            res += " ";
        }
    }
    return res.trim();
}
