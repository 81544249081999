import {
    MatchCard,
    MatchCardBodyLeft,
    MatchCardBodyRight,
    MatchCardBodyWrapper,
    MatchCardHeader,
    MatchCardTournament,
    SquadInfoWrapper,
    SquadLogoSection,
    SquadLogoWrapper,
    SquadName
} from "../../matchCards/MatchCardStyle";
import React from "react";
import { Placeholder } from "react-bootstrap";
import { NormalFooter } from "./Footer";
import { MATCH_CARD_SKELETON_FOOTER_TYPE, MatchCardSkeletonProps } from "./type";
import { CirclePlaceHolder, TextPlaceHolder } from "../SkeletonStyle";

const MatchCardSkeleton: React.FC<MatchCardSkeletonProps> = ({
                                                                 footerType = MATCH_CARD_SKELETON_FOOTER_TYPE.NORMAL
                                                             }) => {
    const Footer = {
        [MATCH_CARD_SKELETON_FOOTER_TYPE.NONE]: () => null,
        [MATCH_CARD_SKELETON_FOOTER_TYPE.NORMAL]: NormalFooter,
    }[footerType];

    return <Placeholder animation="glow">
        <MatchCard>
            <MatchCardHeader>
                <MatchCardTournament>
                    <TextPlaceHolder className="w-100" />
                </MatchCardTournament>
            </MatchCardHeader>
            <MatchCardBodyWrapper>
                <MatchCardBodyLeft>
                    <SquadInfoWrapper>
                        <SquadLogoSection>
                            <SquadLogoWrapper className="me-2">
                                <CirclePlaceHolder />
                            </SquadLogoWrapper>
                            <SquadName>
                                <TextPlaceHolder className="w-50" />
                            </SquadName>
                        </SquadLogoSection>
                    </SquadInfoWrapper>
                    <SquadInfoWrapper>
                        <SquadLogoSection>
                            <SquadLogoWrapper className="me-2">
                                <CirclePlaceHolder />
                            </SquadLogoWrapper>
                            <SquadName>
                                <TextPlaceHolder className="w-50" />
                            </SquadName>
                        </SquadLogoSection>
                    </SquadInfoWrapper>
                </MatchCardBodyLeft>
                <MatchCardBodyRight>
                    <TextPlaceHolder $width={5} />
                </MatchCardBodyRight>
            </MatchCardBodyWrapper>
            <Footer />
        </MatchCard>
    </Placeholder>;
};

export default MatchCardSkeleton;
