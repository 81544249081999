import UserAvatarWithFrame from "components/avatar/UserAvatarWithFrame";
import { TOAST_TYPE } from "designToken/alert/components/AlertHelper";
import { apLevelConfig, getCurrentApProgress, isBeginner, } from "domain/ap/ActivityPointHelper";
import {
    StyledCurrentLevel,
    StyledUserInfoAvatar,
    StyledUserInfoContentWrapper,
    StyledUserInfoDetail,
    StyledUserInfoDetailWrapper,
    StyledUserInfoNickname,
    StyledUserInfoNicknameWrapper,
    StyledUserInfoProgressBarContainer,
    StyledUserInfoProgressBarValue,
    StyledUserInfoProgressValue,
    StyledUserInfoProgressWrapper,
    StyledUserInfoUserCode,
    StyledUserInfoUserCodeWrapper,
    StyledUserInfoWrapper,
} from "domain/me/components/UserInfo/UserInfoStyle";
import { useMe } from "domain/me/me/MeProvider";
import React, { useCallback, useMemo } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { MdContentCopy, MdOutlineChevronRight } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";

import { addToast } from "store/actions/AlertActions";
import { Text } from "../../../ap/ActivityPointProgressStyle";
import { BuyCoinStepper, BuyCoinStepperType } from "../../../ap/BuyCoinStepper";

const UserInfo = () => {
    const { userInfoRef, myProfile, myApOverview } = useMe();
    const { avatar, nickname, userCode } = myProfile;
    const { currentLevel, currentAp, currentLevelMinAp, nextLevelMinAp } =
    myApOverview || {};

    const dispatch = useDispatch();
    const intl = useIntl();

    const currentApProgress = useMemo(
        () =>
            getCurrentApProgress({
                currentAp,
                currentLevelMinAp,
                nextLevelMinAp,
            }),
        [currentAp, currentLevelMinAp, nextLevelMinAp],
    );

    const handleCopy = useCallback(() => {
        dispatch(
            addToast({
                type: TOAST_TYPE.SUCCESS,
                content: intl.messages["id_copied_toast_label"],
            }),
        );
    }, [dispatch, intl.messages]);

    return (
        <StyledUserInfoWrapper ref={userInfoRef}>
            <StyledUserInfoAvatar>
                <UserAvatarWithFrame
                    font="fz-sf-regular-title-lg"
                    url={avatar}
                    name={nickname}
                    size="8rem"
                    currentLevel={currentLevel}
                />
            </StyledUserInfoAvatar>
            <StyledUserInfoContentWrapper>
                <StyledUserInfoDetailWrapper>
                    <StyledUserInfoDetail>
                        <StyledUserInfoNicknameWrapper>
                            <StyledUserInfoNickname>{nickname}</StyledUserInfoNickname>
                            <StyledCurrentLevel to={ROUTER_PATH_CONFIG.activityPoint}>
                                {apLevelConfig[currentLevel as string]?.text()}
                            </StyledCurrentLevel>
                        </StyledUserInfoNicknameWrapper>
                        <StyledUserInfoUserCodeWrapper>
                            <StyledUserInfoUserCode>{userCode}</StyledUserInfoUserCode>
                            <CopyToClipboard text={userCode} onCopy={handleCopy}>
                                <MdContentCopy
                                    size="1.6rem"
                                    className="text-text-secondary-1 clickable"
                                />
                            </CopyToClipboard>
                        </StyledUserInfoUserCodeWrapper>
                    </StyledUserInfoDetail>
                    <div>
                        <Link to={ROUTER_PATH_CONFIG.me.edit}>
                            <MdOutlineChevronRight
                                size="1.6rem"
                                className="text-text-primary-1"
                            />
                        </Link>
                    </div>
                </StyledUserInfoDetailWrapper>

                <div className="tw-pr-4">
                    {
                        isBeginner(myApOverview?.currentLevel)
                            ? <>
                                <Text className="tw-text-right text-light-secondary">
                                    (<FormattedMessage
                                    id="me_ap_hint_buy_any_coins" />)
                                </Text>
                                <BuyCoinStepper type={BuyCoinStepperType.MeStepper}></BuyCoinStepper>
                            </>
                            : <StyledUserInfoProgressWrapper>
                                <StyledUserInfoProgressValue>
                                    ({currentAp} / {nextLevelMinAp})
                                </StyledUserInfoProgressValue>
                                <StyledUserInfoProgressBarContainer>
                                    <StyledUserInfoProgressBarValue
                                        $currentApProgress={currentApProgress}
                                    />
                                </StyledUserInfoProgressBarContainer>
                            </StyledUserInfoProgressWrapper>
                    }
                </div>
            </StyledUserInfoContentWrapper>
        </StyledUserInfoWrapper>
    );
};

export default UserInfo;
