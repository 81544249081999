import { useRef, useState } from "react";

const OUT_OF_ENTRY_LIMIT_TIMEOUT = 2400;

export function useSetNotJoinableContestTemporarily() {
    const timer = useRef<NodeJS.Timeout>();

    const [notJoinableContestId, setNotJoinableContestId] = useState<number | undefined>();

    function resetNotJoinable() {
        setNotJoinableContestId(undefined);
        timer.current = undefined;
    }

    function handleSetNotJoinableContestTemporarily(contestId: number) {
        setNotJoinableContestId(contestId);

        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = undefined;
        }

        timer.current = setTimeout(() => {
            resetNotJoinable();
        }, OUT_OF_ENTRY_LIMIT_TIMEOUT);
    }

    return {
        notJoinableContestId,
        resetNotJoinable,
        handleSetNotJoinableContestTemporarily
    };
}
