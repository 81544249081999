import { divideByOneHundred } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import useFantasyHistory from "hooks/useFantasyHistory";
import React from "react";
import { FormattedMessage } from "react-intl";
import B2CWrapper from "containers/b2cWrapper/B2CWrapper";
import { formatToFullYearDateTime } from "helpers/TimeHelper";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { useSelector } from "store";
import { PrimaryFixedButton } from "styles/component/ButtonsStyle";
import { WalletHistoryTableContainer } from "domain/wallet/components/walletHistoryRow/WalletHistoryRowStyle";
import useWalletHistoryRecords from "domain/wallet/hooks/useWalletHistoryRecords";
import {
    ExpiredInfo,
    ExpiredInfoWrapper,
    ExpiredInSevenDaysRubyAmount,
    ExpiredRubyInfoRow
} from "domain/wallet/ruby/RubyRecordsStyle";
import RubyTransactionDetail from "domain/wallet/ruby/RubyTransactionDetail";
import { WALLET_TYPE } from "data/dto/wallet/walletHistoryDto";
import { WalletHistoriesVo } from "data/vo/wallet/WalletHistoriesVo";
import { HistoryContent } from "domain/wallet/components/HistoryContent";
import { TotalAmountRow } from "domain/wallet/components/walletHistoryRow/components/TotalAmountRow";


const ExpiringRubySection = ({ expiringTime, expiringRubyCent }) => {
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();

    return <ExpiredRubyInfoRow>
        <ExpiredInfoWrapper>
            <ExpiredInfo>
                <FormattedMessage id="wallet_history_page_label_ruby_expiry" values={{
                    date: formatToFullYearDateTime(expiringTime)
                }} />
            </ExpiredInfo>
        </ExpiredInfoWrapper>
        <ExpiredInSevenDaysRubyAmount>
            {toWalletNumber(divideByOneHundred(expiringRubyCent))}
        </ExpiredInSevenDaysRubyAmount>
    </ExpiredRubyInfoRow>;
};

interface RubyRecordsProps {
    histories: WalletHistoriesVo;
}

const RubyRecords: React.FC<RubyRecordsProps> = ({ histories }) => {
    const history = histories.ruby.histories;
    const routeHistory = useFantasyHistory();
    const { featureConfig } = useSelector(state => state.FantasyGameConfigState);

    const {
        WalletState: { rubyCent, },
        transactionDetailData,
        handleHistoryRecordRowClick,
        handleHideTransaction,
    } = useWalletHistoryRecords();

    const goToInviteFriend = () => routeHistory.push(ROUTER_PATH_CONFIG.me.referral.index);

    return <>
        <WalletHistoryTableContainer>
            <ExpiringRubySection expiringTime={histories.ruby.expiringTime}
                                 expiringRubyCent={histories.ruby.expiringRubyCent} />
            <TotalAmountRow totalCent={rubyCent}
                            walletType={WALLET_TYPE.RUBY}
            />

            <HistoryContent records={history}
                            handleHistoryRecordRowClick={handleHistoryRecordRowClick} />

            <B2CWrapper>
                {
                    featureConfig.referral && (
                        <PrimaryFixedButton onClick={goToInviteFriend}>
                            <FormattedMessage id="label_referral_program" />
                        </PrimaryFixedButton>
                    )
                }
            </B2CWrapper>
        </WalletHistoryTableContainer>
        <RubyTransactionDetail
            transactionData={transactionDetailData}
            onHide={handleHideTransaction}
        />
    </>;
};

export default RubyRecords;
