import { BUTTON_PATTERN } from "designToken/button/types";
import { isArray } from "lodash";
import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "store";

import { removePopup } from "store/actions/AlertActions";
import { PopupProps } from "store/reducer/alert/AlertState";
import { FANTASY_DIALOG_VARIANT } from "../../../../components/dialog/FantasyDialog";
import FantasyBasicPopup, { FantasyPopupProps } from "../../../../components/popup/FantasyBasicPopup";
import Button from "../../../button/Button";
import Dialog from "../../../dialog/Dialog";
import { closeAlertMillisecond } from "../AlertHelper";
import {
    DialogBody,
    DialogContent,
    DialogFooter,
    DialogImgContainer,
    DialogImgWrapper,
    DialogTitle
} from "../AlertStyle";

/**
 * const initDialog = {
 *     imgSize: '',
 *     img: '',
 *     title: '',
 *     content: '',
 *     cancelButton: {
 *         onClick: () => {},
 *         variant: BUTTON_VARIANTS.outlinePrimary,
 *         text: ''
 *     },
 *     confirmButton: {
 *         onClick: () => {},
 *         variant: BUTTON_VARIANTS.primary,
 *         text: ''
 *     },
 *     backdrop: DIALOG_BACKDROP.static,
 *     timer: null,
 *     onExited: () => {}
 * }
 */


function CancelButton({ cancelButton, closeDialog }) {
    return <Button variant={cancelButton.variant}
                   pattern={BUTTON_PATTERN.pill}
                   onClick={() => {
                       if (cancelButton.onClick) {
                           cancelButton.onClick();
                       }
                       closeDialog();
                   }}
    >
        {cancelButton.text}
    </Button>;
}

function ConfirmButton({ confirmButton, closeDialog }) {
    return <Button variant={confirmButton.variant}
                   pattern={BUTTON_PATTERN.pill}
                   onClick={() => {
                       if (confirmButton.onClick) {
                           confirmButton.onClick();
                       }
                       closeDialog();
                   }}
    >
        {confirmButton.text}
    </Button>;
}

interface DialogSetting extends Omit<PopupProps, "autoClose"> {
    buttonSetting?: FantasyPopupProps['buttonSetting'];
}

const AlertDialog = () => {
    const dispatch = useDispatch();
    const { AlertState: { popup: statePopup } } = useSelector(store => store);

    const [dialog, setDialog] = useState<DialogSetting>({});
    const [showDialog, setShowDialog] = useState(false);
    const timer = useRef<NodeJS.Timeout | null>(null);

    const hasTitle = !!dialog?.title;
    const hasContent = !!dialog?.content;
    const hasCancelButton = !!dialog?.cancelButton?.text;
    const hasFootSlot = !!dialog?.footerSlot;
    const hasConfirmButton = !!dialog?.confirmButton?.text;
    const hasBodySlot = !!dialog?.bodySlot;

    const closeDialog = useCallback(() => {
        dispatch(removePopup());
        if (timer.current) {
            clearTimeout(timer.current);
        }
    }, [dispatch]);

    useEffect(() => {
        statePopup.length > 0 ? setShowDialog(true) : setShowDialog(false);
    }, [statePopup]);

    const onExited = () => {
        if (dialog.onExited) {
            dialog.onExited();
        }
        setDialog({});
    };

    useEffect(() => {
        if (statePopup[0]) {
            const _dialog = statePopup[0];

            setDialog({ ..._dialog });

            if (_dialog.autoClose) {
                timer.current = setTimeout(() => {
                    closeDialog();
                }, closeAlertMillisecond);
            }

        }
    }, [statePopup, closeDialog]);

    // 多彈窗問題
    if (dialog.variant === FANTASY_DIALOG_VARIANT.FANTASY_BASIC_POPUP) {
        return <FantasyBasicPopup
            onHide={closeDialog}
            {...dialog}
            show={showDialog}
            title={dialog.title as ReactNode}
            content={dialog.content}
            buttonSetting={dialog.buttonSetting}
        />;
    }


    return (
        <Dialog show={showDialog}
                backdrop={dialog?.backdrop || true}
                onHide={closeDialog}
                onExited={onExited}
        >
            <DialogImgWrapper>
                {
                    dialog.imageSection
                        ? dialog.imageSection
                        : <DialogImgContainer $size={dialog?.imgSize} role="dialog-img">
                            <img src={dialog?.img} alt="alert-dialog-img" />
                        </DialogImgContainer>

                }
            </DialogImgWrapper>
            {
                (hasTitle || hasContent || hasBodySlot) &&
                <DialogBody>
                    {hasTitle && <DialogTitle>
                        {
                            isArray(dialog.title)
                                ? dialog.title.map((item) => <>{item}</>)
                                : dialog.title
                        }
                    </DialogTitle>}
                    {hasContent && <DialogContent>{dialog.content}</DialogContent>}
                    {hasBodySlot && dialog.bodySlot}
                </DialogBody>
            }
            {
                (hasCancelButton || hasConfirmButton || hasFootSlot) &&
                <DialogFooter>
                    {
                        hasCancelButton
                        && <CancelButton cancelButton={dialog.cancelButton} closeDialog={closeDialog} />
                    }
                    {
                        hasConfirmButton
                        && <ConfirmButton confirmButton={dialog.confirmButton} closeDialog={closeDialog} />
                    }
                    {
                        dialog.footerSlot
                    }
                </DialogFooter>
            }
        </Dialog>
    );
};

export default AlertDialog;
