import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { formatCountdownTime, isTimeOut, TimeObject, to24HourClock } from "./CountdownHelper";

interface CountdownProps {
    finishAt: number
    handleTimeout?: Function
    onFormatCountdownTime?: (timeObject: TimeObject) => string
}

export type Timer = ReturnType<typeof setInterval> | string | number

const Countdown = ({ finishAt, handleTimeout, onFormatCountdownTime }: CountdownProps) => {
    const intl = useIntl();
    const [timeLeftSeconds, setTimeLeftSeconds] = useState(Math.ceil((finishAt - new Date().getTime()) / 1000));
    const [timeLeftWith24HourClock, setTimeLeftWith24HourClock] = useState(() =>
        isTimeOut(timeLeftSeconds)
            ? { day: 0, hour: 0, minute: 0, second: 0 }
            : to24HourClock(timeLeftSeconds)
    );
    const [shouldShowTimeLeft, setShouldShowTimeLeft] = useState(!isTimeOut(timeLeftSeconds));
    const countdown = useRef<Timer>();

    useEffect(() => {
        if (isTimeOut(timeLeftSeconds)) {
            clearInterval(countdown.current);
            setShouldShowTimeLeft(false);
            if (handleTimeout) handleTimeout();
        } else {
            setShouldShowTimeLeft(true);
            setTimeLeftWith24HourClock(to24HourClock(timeLeftSeconds));
        }
    }, [timeLeftSeconds, handleTimeout]);

    useEffect(() => {
        countdown.current = setInterval(() => {
            setTimeLeftSeconds(prevState => prevState - 1);
        }, 1000);

        return () => clearInterval(countdown.current);
    }, []);


    const dayLocaleFormatter = (day: number) => intl.formatMessage({ id: "label_countdown_days" }, { countdown: day });

    return (
        shouldShowTimeLeft
            ? <div style={{ color: "inherit", fontSize: "inherit" }}>
                {
                    onFormatCountdownTime
                        ? onFormatCountdownTime(timeLeftWith24HourClock)
                        : formatCountdownTime(timeLeftWith24HourClock, dayLocaleFormatter)
                }
            </div>
            : null
    );
};


export default Countdown;
