import React from "react";
import { FormattedMessage } from "react-intl";
import { cn } from "../../../../helpers/cn";

interface ParticipantsProps {
    participants: number;
    spots: number;
    styleWhenSpotsFull?: string
}

export const Participants = ({ participants, spots, styleWhenSpotsFull }: ParticipantsProps) => {
    const isSpotsFull = participants === spots;

    return (
        <p className={cn(
            "tw-text-caption-2 tw-font-light",
            isSpotsFull && !!styleWhenSpotsFull ? styleWhenSpotsFull : "")
        }>
            {isSpotsFull ? (
                    <span>
                        <span className={"tw-font-bold"}>Full</span>
                        {` - ${spots}/${spots}`}
                    </span>
                ) :
                (
                    <span>{`${participants}/${spots} `}
                        <FormattedMessage id={"contest_card_label_participants"} />
                    </span>
                )}
        </p>);
};
