import { cn } from "../../helpers/cn";
import { Icon } from "../../helpers/IconHelper";
import { FormattedMessage } from "react-intl";
import React from "react";
import { InfoCard } from "domain/withdraw/InfoCard";

export function CurrentWinningBalance(props: { isWinningInsufficient: boolean, winning: string }) {
    return <div className={"tw-flex tw-flex-col tw-items-center tw-w-[31rem] tw-px-6 tw-gap-3"}>
        <InfoCard
            icon={<Icon.Wallet size="1.4rem" />}
            label={<FormattedMessage id="confirmation_dialog_label_current_balance" />}
        />
        <div className={cn("tw-text-subtitle-1 tw-font-medium tw-text-grey-800",
            "tw-flex tw-items-start tw-justify-between",
            "tw-w-full tw-pb-3",
            "tw-border-solid tw-border-grey-400 tw-border-b-[1px]"
        )}>
            <div className={"tw-flex tw-items-center"}>
                <Icon.Winning
                    size="1.6rem"
                    data-testid="coin-icon"
                    className="tw-mx-1"
                />
                <FormattedMessage id="label_winning" />
            </div>
            <div className={cn("tw-flex tw-flex-col tw-items-start", {
                    "tw-text-error-800": props.isWinningInsufficient
                }
            )}>
                <div className={cn("tw-flex tw-items-center",
                )}>
                    <Icon.Peso
                        size="1.6rem"
                        data-testid="peso-icon"
                    />
                    {props.winning}
                </div>
                {props.isWinningInsufficient && (
                    <div
                        className={cn("tw-inline-block", "tw-py-0.5 tw-px-1 tw-rounded-sm tw-bg-error-50 tw-text-error-900", "tw-leading-none tw-text-overline")}>
                        <FormattedMessage id="label_withdraw_amount_insufficient" />
                    </div>
                )}
            </div>
        </div>

    </div>;
}
