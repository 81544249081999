import { fetchCurrentApLevelList } from "apis/ap/ActivityPointApi";
import { ApiExecuteContext } from "apis/ApiContext";
import { fetchFriends, FriendDto } from "apis/user/UserApi";
import UserAvatarWithFrame from "components/avatar/UserAvatarWithFrame";
import Navbar from "components/container/navbar/Navbar";
import { useLoadingToggle } from "components/loading/Loading";
import { getUserIdToCurrentLevel } from "domain/ap/ActivityPointHelper";
import { getUserIds, sortFriendsByNickname } from "domain/friend/FriendHelper";
import EmptyFriendList from "domain/friend/list/EmptyFriendList";
import {
    FriendCode,
    FriendListItem,
    FriendListNickname,
    FriendListNicknameWrapper,
    FriendListWrapper,
    NotificationText,
    TopFriendListFullNotification,
} from "domain/friend/list/FriendListStyle";
import { SearchUserBar } from "domain/friend/searchUserBar/SearchUserBar";
import { NavbarCenter } from "domain/me/profile/ProfileStyle";
import useFantasyHistory from "hooks/useFantasyHistory";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { MdOutlinePersonSearch } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { getUserInfoPageUrl } from "router/RouterUrls";
import { RootStoreState } from "store/reducer";


const FriendList = () => {
    const history = useFantasyHistory();
    const apiExecutor = useContext(ApiExecuteContext);
    const toggleLoading = useLoadingToggle();
    const [friendList, setFriendList] = useState<FriendDto[]>([]);
    const [userIdToCurrentLevel, setUserIdToCurrentLevel] = useState({});
    const [myFriendListFull, setMyFriendListFull] = useState(false);
    const [showSearchUserBar, setShowSearchUserBar] = useState(false);
    const { SettingsState: { following: { maxFollowing } } } = useSelector((store: RootStoreState) => store);


    const goToTransfer = (foundUser) =>
        history.push({
            pathname: ROUTER_PATH_CONFIG.userProfile.transfer,
            state: {
                userId: foundUser.id,
                avatar: foundUser.avatar,
                nickname: foundUser.nickname,
            },
        });

    const getCurrentApLevelList = useCallback(
        (userIds) => {
            apiExecutor(fetchCurrentApLevelList(userIds), {
                onSuccess: (res) =>
                    setUserIdToCurrentLevel(getUserIdToCurrentLevel(res)),
            });
        },
        [apiExecutor],
    );

    const getFriendList = useCallback(() => {
        return apiExecutor(fetchFriends(), {
            onSuccess: ({ friends }) => {
                setFriendList(sortFriendsByNickname(friends));
                setMyFriendListFull(friends.length >= maxFollowing);
                getCurrentApLevelList(getUserIds(friends));
            },
        });
    }, [apiExecutor, getCurrentApLevelList, maxFollowing]);

    useEffect(() => {
        (async () => {
            toggleLoading(true);
            await getFriendList();
            toggleLoading(false);
        })();
    }, [toggleLoading, getFriendList]);

    return (
        <>
            <Navbar className="!tw-sticky tw-top-0"
                    shouldCalculatePaddingTop={false}
                    endIcon={
                        <MdOutlinePersonSearch className="clickable" size="2.4rem"
                                               onClick={() => setShowSearchUserBar(!showSearchUserBar)}
                        />
                    }
                    navBackIconColor="tw-text-primary-600"
                    navbarCenter={
                        <NavbarCenter>
                            <FormattedMessage id="label_following" />
                            &nbsp;({friendList.length})
                        </NavbarCenter>
                    }
            />
            {
                myFriendListFull && (
                    <TopFriendListFullNotification>
                        <NotificationText>
                            <FormattedMessage id="user_profile_page_friend_full_tip"
                                              values={{ maxFollowing: maxFollowing }} />
                        </NotificationText>
                    </TopFriendListFullNotification>
                )
            }
            <FriendListWrapper>
                {showSearchUserBar && (
                    <>
                        <SearchUserBar
                            onTransferUserClicked={goToTransfer}
                            onNewFriendAdded={getFriendList}
                        />
                    </>
                )}
                {friendList.length === 0 ? (
                    <EmptyFriendList />
                ) : (
                    friendList.map((friend) => (
                        <FriendListItem
                            key={friend.userId}
                            onClick={() => history.push(getUserInfoPageUrl(friend.userId))}
                        >
                            <UserAvatarWithFrame
                                url={friend.avatar}
                                name={friend.nickname}
                                size="4.8rem"
                                currentLevel={userIdToCurrentLevel[friend.userId]}
                            />

                            <FriendListNicknameWrapper>
                                <FriendListNickname>{friend.nickname}</FriendListNickname>
                                <FriendCode>({friend.userCode})</FriendCode>
                            </FriendListNicknameWrapper>
                        </FriendListItem>
                    ))
                )}
            </FriendListWrapper>
        </>
    );
};

export default FriendList;
