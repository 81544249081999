import useDevice from "hooks/useDevice/useDevice";
import { useEffect, useState } from "react";

export const useFindElementById = (id: string) => {
    const [element, setElement] = useState<HTMLElement | null>(null);
    const { deviceWidth } = useDevice();

    useEffect(() => {
        const interval = setInterval(() => {
            const elementById = document.getElementById(id);
            if (elementById) {
                setElement(elementById);
                clearInterval(interval);
            }
        }, 100);

        return () => {
            clearInterval(interval);
        };
    }, [id, deviceWidth]);

    return element;
};
