import Button from "designToken/button/Button";
import { emptyGeneral, Icon } from "helpers/IconHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { BUTTON_PATTERN, BUTTON_SIZE, NEW_BUTTON_VARIANTS } from "designToken/button/types";

export const EmptyGeneral = () => {
    return <div className={"tw-p-4 tw-pt-[3.2rem]"}>
        <img src={emptyGeneral} alt="my_empty_match" className="tw-w-[25.6rem] tw-h-auto" />
    </div>;
};

export const MyEmptyMatch = () => {
    const history = useFantasyHistory();

    return (
        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-4 tw-py-7">
            <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-[30rem] tw-text-center">
                <EmptyGeneral />
                <p className="tw-text-body-2 tw-text-grey-1000 tw-font-thin" data-testid="my-matches-empty-text">
                    <FormattedMessage id="my_games_page_description_empty_matches" />
                </p>
            </div>

            <Button
                variant={NEW_BUTTON_VARIANTS.primaryLight}
                pattern={BUTTON_PATTERN.pill}
                size={BUTTON_SIZE.sm}
                onClick={() => {
                    history.push(ROUTER_PATH_CONFIG.home);
                }}
                prefixIcon={Icon.FireIcon()}
            >
                <FormattedMessage id="label_join_now" />
            </Button>

        </div>
    );
};

export default MyEmptyMatch;
