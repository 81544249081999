import { FANTASY_DIALOG_VARIANT } from "components/dialog/FantasyDialog";
import useDialogProps from "components/dialog/hook/useDialogProps";
import { DIALOG_IMG_SIZE } from "designToken/alert/components/AlertHelper";
import { BUTTON_PATTERN, BUTTON_VARIANTS, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { DIALOG_BACKDROP } from "designToken/dialog/Dialog";
import { makeInsufficientApLevelDialogProps } from "domain/contest/components/InsufficientApLevelDialog";
import { kycCompleteYourKyc, popupSuccessIcon, popupWarningIcon } from "helpers/IconHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import { isEmpty } from "lodash";
import React, { ReactNode, useCallback } from "react";
import { FormattedMessage, MessageFormatElement, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { getStartedMatchDetailPageUrl } from "router/RouterUrls";
import { addPopup, removePopup } from "store/actions/AlertActions";
import { AP_LEVEL } from "types/activityPoint/ApLevel";

export const useShowInsufficientApDialog = () => {
    const dispatch = useDispatch();
    const { insufficientApDialogProps } = useDialogProps();

    return useCallback((
        currentLevel: AP_LEVEL,
        minLevel: AP_LEVEL
    ) => {
        const dialogProps = insufficientApDialogProps(
            () => {},
            currentLevel,
            minLevel
        );

        const insufficientApLevelDialogProps = makeInsufficientApLevelDialogProps({
            minApLevel: minLevel,
            currentApLevel: currentLevel,
            onUpgradeApLevel: () => {
                dialogProps?.onConfirm && dialogProps?.onConfirm();
                dispatch(removePopup());
            },
            onClose: () => {
                dispatch(removePopup());
            }
        });
        dispatch(
            addPopup({
                ...insufficientApLevelDialogProps,
                variant: FANTASY_DIALOG_VARIANT.FANTASY_BASIC_POPUP,
                title: insufficientApLevelDialogProps.title as ReactNode,
                content: insufficientApLevelDialogProps.content,
            })
        );

    }, [dispatch]);
};

interface WarningDialogProps {
    title: string | React.ReactNode;
    content?: string | React.ReactNode;
    cancelButton?: {
        variant?: BUTTON_VARIANTS;
        text: string;
        onClick?: () => void;
    },
    confirmButton?: {
        variant?: BUTTON_VARIANTS;
        text: string;
        onClick?: () => void;
    },
    backdrop?: boolean | DIALOG_BACKDROP;
}

interface WarningDialogConfig {
    title: string | React.ReactNode;
    content?: string | React.ReactNode;
    img: string;
    imgSize: DIALOG_IMG_SIZE;
    cancelButton?: {
        variant: BUTTON_VARIANTS;
        text: string;
        onClick?: () => void;
    },
    confirmButton?: {
        variant: BUTTON_VARIANTS;
        text: string;
        onClick?: () => void;
    },
    backdrop?: boolean | DIALOG_BACKDROP;
    autoClose?: boolean;
    bodySlot?: React.ReactNode;
    onExited?: () => void;
}

export const useWarningDialog = () => {
    const dispatch = useDispatch();

    return useCallback(({
                            title,
                            content,
                            cancelButton,
                            confirmButton,
                            backdrop
                        }: WarningDialogProps) => {

            let _config: WarningDialogConfig = {
                title,
                content,
                img: popupWarningIcon,
                imgSize: DIALOG_IMG_SIZE.SM
            };

            if (!isEmpty(backdrop)) {
                _config = {
                    ..._config,
                    backdrop: backdrop
                };
            }

            if (!isEmpty(cancelButton)) {
                _config = {
                    ..._config,
                    cancelButton: {
                        variant: BUTTON_VARIANTS.outlineDark,
                        ...cancelButton
                    }
                };
            }

            if (!isEmpty(confirmButton)) {
                _config = {
                    ..._config,
                    confirmButton: {
                        variant: BUTTON_VARIANTS.secondary,
                        ...confirmButton
                    }
                };
            }

            dispatch(addPopup(_config));

        },
        [dispatch]);
};

export const useSuccessDialog = () => {
    const dispatch = useDispatch();

    return useCallback(({ text, bodySlot }: {
        text: string | MessageFormatElement[],
        bodySlot?: React.ReactNode
    }) => {
        dispatch(addPopup({
            title: text,
            img: popupSuccessIcon,
            imgSize: DIALOG_IMG_SIZE.SM,
            autoClose: true,
            bodySlot: bodySlot
        }));
    }, [dispatch]);
};

export const useStopJoiningDialog = () => {
    const history = useFantasyHistory();
    const intl = useIntl();
    const showWarningModal = useWarningDialog();

    const defaultOnConfirm = useCallback(() => {
        history.replace(ROUTER_PATH_CONFIG.home);
    }, [history]);

    return useCallback((onConfirm = defaultOnConfirm) => {
        showWarningModal({
            title: intl.formatMessage({ id: "stop_join_contest_dialog_label" }),
            confirmButton: {
                variant: BUTTON_VARIANTS.primary,
                text: intl.formatMessage({ id: "dialog_button_back" }),
                onClick: onConfirm
            },
            backdrop: DIALOG_BACKDROP.STATIC
        });
    }, [intl, showWarningModal, defaultOnConfirm]);
};

const KickoffDialogTitle = ({ period }) => {
    return <>
        <FormattedMessage id="started_time_out_dialog_label_1" />&nbsp;
        <span className="text-sys-danger-1" style={{ font: "inherit" }}>
                <FormattedMessage id="started_time_out_dialog_label_2" values={{ period }} />
            </span>
    </>;
};

export const useMatchKickoffDialog = () => {
    const intl = useIntl();
    const history = useFantasyHistory();
    const showWarningModal = useWarningDialog();

    return useCallback((sport, matchId, period) => {
        showWarningModal({
            title: <KickoffDialogTitle period={period} />,
            confirmButton: {
                variant: BUTTON_VARIANTS.primary,
                text: intl.formatMessage({ id: "button_got_it" }),
                onClick: () => {
                    history.replace(getStartedMatchDetailPageUrl(sport, matchId));
                }
            },
            backdrop: DIALOG_BACKDROP.STATIC
        });
    }, [history, intl, showWarningModal]);
};

export const useTransferSuccessDialog = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    return useCallback((username, number) => {
        dispatch(addPopup({
            title: intl.formatMessage({ id: "transfer_success_dialog_title" }),
            content: intl.formatMessage(
                { id: "transfer_success_dialog_label" },
                {
                    username: username,
                    number: number
                }),
            img: popupSuccessIcon,
            imgSize: DIALOG_IMG_SIZE.SM,
            autoClose: true
        }));
    }, [intl, dispatch]);

};

export const useReferredSuccessDialog = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    return useCallback((onExited = () => {}) => {
        dispatch(addPopup({
            title: intl.formatMessage({ id: "add_referral_page_toast_referred_success" }),
            img: popupSuccessIcon,
            imgSize: DIALOG_IMG_SIZE.SM,
            autoClose: true,
            onExited: onExited
        }));


    }, [dispatch, intl]);

};

export const useCreateTeamSuccessDialog = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    return useCallback(() => {
        dispatch(addPopup({
            title: intl.formatMessage({ id: "create_team_success_dialog_title" }),
            img: popupSuccessIcon,
            imgSize: DIALOG_IMG_SIZE.SM,
            autoClose: true
        }));


    }, [dispatch, intl]);
};

export const useEditTeamSuccessDialog = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    return useCallback(() => {
        dispatch(addPopup({
            title: intl.formatMessage({ id: "edit_team_success_dialog_title" }),
            img: popupSuccessIcon,
            imgSize: DIALOG_IMG_SIZE.SM,
            autoClose: true
        }));


    }, [dispatch, intl]);
};


export const useCompleteYourKycDialog = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const history = useFantasyHistory();

    const defaultOnCancel = useCallback(() => {
        dispatch(removePopup());
        history.push(ROUTER_PATH_CONFIG.home);
    }, []);

    const defaultOnConfirm = useCallback(() => {
        dispatch(removePopup());
        history.push(ROUTER_PATH_CONFIG.verifyKyc.form);
    }, []);


    return useCallback((props?: { onConfirm: () => void, onCancel: () => void }) => {
        dispatch(
            addPopup({
                variant: FANTASY_DIALOG_VARIANT.FANTASY_BASIC_POPUP,
                dismissible: false,
                onHide: props?.onCancel ? props.onCancel : defaultOnCancel,
                title: intl.formatMessage({ id: "kyc_verification_complete_your_kyc" }),
                imageSection: <img src={kycCompleteYourKyc}
                                   alt="kyc-to-complete-your-kyc"
                                   className="tw-max-w-[10.8rem]" />,
                texts: [
                    {
                        text: intl.formatMessage({ id: "kyc_verification_complete_your_kyc_content_1" }),
                        textClassName: "tw-text-left",
                    },
                    {
                        text: intl.formatMessage({ id: "kyc_verification_complete_your_kyc_content_2" }),
                        textClassName: "tw-text-left",
                    }
                ],
                buttonSetting: {
                    confirmButtonVariant: NEW_BUTTON_VARIANTS.primaryDark,
                    cancelButtonVariant: NEW_BUTTON_VARIANTS.text,
                    buttonPattern: BUTTON_PATTERN.rounded,
                    confirmButtonText: intl.formatMessage({ id: "kyc_start_verification" }),
                    cancelButtonText: intl.formatMessage({ id: "kyc_do_it_later" }),
                    onConfirm: props?.onConfirm ? props.onConfirm : defaultOnConfirm,
                    onCancel: props?.onCancel ? props.onCancel : defaultOnCancel
                }

            })
        );
    }, [dispatch, defaultOnCancel, defaultOnConfirm]);
};
