import { zodResolver } from "@hookform/resolvers/zod";
import { ApiError } from "apis/Api";
import { HINT_CARD_VARIANT, HintCard } from "components/hintcard/HintCard";
import Button from "designToken/button/Button";
import { NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { FantasySelect } from "designToken/textFields/FantasySelect";
import { FantasyTextField, TEXT_FIELD_VARIANT } from "designToken/textFields/FantasyTextField";
import { ZodOnChangeValidator } from "designToken/textFields/OnChangeValidator";
import { ErrorMessage } from "components/error/ErrorMessage";
import { useKycLeavePopup } from "domain/kyc/components/kycLeavePopup/KycLeavePopupProvider";
import { StepProgressBar } from "domain/kyc/components/stepProgressBar/StepProgressBar";
import { SendOtpRequest, useKycOtp } from "domain/kyc/pages/kycOtp/KycOtpProvider";
import { KYC_STEP } from "domain/kyc/pages/kycVerificationForm/KycVerificationForm";
import { OtpSendValidators } from "helpers/ErrorHelper";
import { Icon } from "helpers/IconHelper";
import { ALL_DIGIT_NUMBER_OR_EMPTY_REGEX, isOtpMobileNumber } from "helpers/RegexHelper";
import { getRightSubString } from "helpers/string/StringHelper";
import useHandleApiError, { ERROR_HANDLER_FLOW } from "hooks/useHandleApiError";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { z } from "zod";

const MOBILE_NUMBER_LENGTH = 10;
const schema = z.string().regex(ALL_DIGIT_NUMBER_OR_EMPTY_REGEX);
const integerAmountValidator = new ZodOnChangeValidator(schema);

const getResolver = (formattedMessage: (id: string) => string) => zodResolver(z.object({
    countryCode: z.string(),
    mobileNumber: z.string(),
}).superRefine(({ mobileNumber }, ctx) => {
    if (!isOtpMobileNumber(mobileNumber)) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: formattedMessage("otp_error_mobile_number_incorrect"),
            path: ["mobileNumber"]
        });

        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["countryCode"]
        });
    }
}));

export const MobileNumber: React.FC = () => {


    const intl = useIntl();
    const handleApiError = useHandleApiError();
    const { toVerifyOtp, sendOtp, error, setOtpSendError, responsibleGamingRegistration } = useKycOtp();
    const kycLeavePopup = useKycLeavePopup();

    const form = useForm<SendOtpRequest>({
        resolver: getResolver((id) => intl.formatMessage({ id })),
        defaultValues: {
            countryCode: "+63",
            mobileNumber: responsibleGamingRegistration?.phoneNumber
        },
        mode: "onChange"
    });

    const handleSendOtp = form.handleSubmit(async (data) => {
        try {
            const requestData: SendOtpRequest = {
                countryCode: data.countryCode,
                mobileNumber: getRightSubString(data.mobileNumber, MOBILE_NUMBER_LENGTH)
            };

            setOtpSendError(null);
            await sendOtp(requestData);
            toVerifyOtp(requestData);
        } catch (e) {
            await handleApiError(e as ApiError, (error) => {
                if (OtpSendValidators.some((validator) => validator(error))) {
                    setOtpSendError(error.response!.data.errorCode);
                    return ERROR_HANDLER_FLOW.STOP;
                }

                return ERROR_HANDLER_FLOW.COUNTINUE;
            });
        }
    });

    useEffect(() => {
        const subscription = form.watch((_, { name, type }) => {
            if (type === "change" && name === "mobileNumber") {
                form.trigger("countryCode");
                setOtpSendError(null);
            }
        });

        return () => subscription.unsubscribe();
    }, [form.watch, form.trigger]);

    useEffect(() => {
        if (error.isOtpMobileNumberInUse) {
            form.setError("mobileNumber", {
                message: intl.formatMessage({ id: "otp_error_mobile_number_in_use" }),
            });
            form.setError("countryCode", {
                message: intl.formatMessage({ id: "otp_error_mobile_number_in_use" }),
            });
        }
    }, [error.isOtpMobileNumberInUse]);

    return <>
        <div>
            <div className={"tw-flex tw-items-start"}>
                <Icon.DailyLogo className={"tw-px-[0.4rem] tw-py-[0.6rem] tw-mr-[0.8rem]"} />
                <div className={"tw-text-h6-medium"}>
                    <FormattedMessage id="kyc_verification_welcome_title" />
                </div>
            </div>
            <div className={"tw-text-body-1 tw-pt-[0.4rem]"}>
                <FormattedMessage
                    id="kyc_verification_process_welcome_content" />
            </div>
        </div>
        <StepProgressBar currentStep={KYC_STEP.OTP} />
        <div>
            <div className="tw-text-subtitle-1 tw-font-medium tw-text-grey-1000">
                <FormattedMessage id="otp_mobile_number_title" />
            </div>
            <div className="tw-text-subtitle-2 tw-text-grey-1000">
                <FormattedMessage id="otp_mobile_number_description" />
            </div>
            <FormProvider {...form}>
                <div className="tw-pt-6 tw-pb-[6.8rem] tw-space-y-1 tw-relative">
                    <div className="tw-text-body-1 tw-text-grey-1000">
                        <FormattedMessage id="label_mobile_number" />
                    </div>
                    <div className="tw-flex tw-gap-1">
                        <FantasySelect
                            name="countryCode"
                            variant={TEXT_FIELD_VARIANT.STANDARD}
                            inputProps={{
                                className: "tw-placeholder-grey-600 tw-w-20 tw-flex-shrink-0 tw-bg-grey-100",
                            }}
                            options={[{
                                label: "+63",
                                value: "+63"
                            }]}
                            showErrorMessage={false}
                            disabled
                        />
                        <div className="tw-flex-1">
                            <FantasyTextField
                                name="mobileNumber"
                                variant={TEXT_FIELD_VARIANT.STANDARD}
                                showErrorMessage={false}
                                inputProps={{
                                    className: "tw-placeholder-grey-600 tw-bg-grey-100",
                                    placeholder: intl.formatMessage({ id: "label_mobile_number" }),
                                    "data-testid": "kyc-otp-mobile-number",
                                }}
                                onChangeValidator={integerAmountValidator}
                                disabled
                            />
                        </div>
                    </div>
                    <ErrorMessage errorMessage={form.formState.errors.mobileNumber?.message} />
                    {
                        error.isOtpSendExceedDailyLimit && (
                            <HintCard
                                className="tw-absolute tw-w-full"
                                variant={HINT_CARD_VARIANT.ERROR}
                                hintText={intl.formatMessage({ id: "otp_error_mobile_number_exceed_daily_limit_hint" })}
                                prefixIcon={Icon.Speedometer}
                            />
                        )
                    }
                    {
                        error.isOtpSendExceedIntervalLimit && (
                            <HintCard
                                className="tw-absolute tw-w-full"
                                variant={HINT_CARD_VARIANT.PRIMARY_LIGHT}
                                hintText={intl.formatMessage({ id: "otp_error_mobile_number_exceed_interval_limit_hint" })}
                                prefixIcon={Icon.Hourglass}
                            />
                        )
                    }
                </div>
            </FormProvider>
        </div>
        <div className="tw-flex tw-flex-row tw-space-x-[0.8rem]">
            <Button
                dataTestId="kyc-otp-exit"
                className="tw-flex-1 tw-text-h6 tw-font-medium"
                variant={NEW_BUTTON_VARIANTS.greyDark}
                onClick={kycLeavePopup.open}
            >
                <FormattedMessage id="label_exit" />
            </Button>
            <Button
                dataTestId="kyc-otp-send-code"
                className="tw-flex-1 tw-text-h6 tw-font-medium"
                disabled={!form.formState.isValid || error.isOtpSendExceedDailyLimit || error.isOtpMobileNumberInUse}
                variant={NEW_BUTTON_VARIANTS.primaryDark}
                onClick={handleSendOtp}
            >
                <FormattedMessage id="label_send_code" />
            </Button>
        </div>
    </>;
};
