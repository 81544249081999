import React, { useCallback, useEffect, useState } from "react";
import { Dialog } from "@radix-ui/react-dialog";
import { DialogOverlay, DialogPortal } from "../../../../components/ui/dialog";
import { cn } from "../../../../helpers/cn";
import QuestPrizeAnimation from "../../../home/components/QuestPrizeAnimation/QuestPrizeAnimation";
import { useDailyCheckInPopup } from "../DailyCheckInPopupProvider";
import { RecommendedDailyCheckInPopupItem, UncompletedDailyCheckInPopupItem } from "./DailyCheckInPopupItem";
import { apLevelConfig } from "../../../ap/ActivityPointHelper";
import { RegularQuestPrizeIcon } from "../../components/QuestPrizeIcon";

export const TodayPrizeOverlay = () => {
    const context = useDailyCheckInPopup();

    const [show, setShow] = useState(false);
    const [animationForwarding, setAnimationForwarding] = useState(false);

    const [centerX, setCenterX] = useState(0);
    const [centerY, setCenterY] = useState(0);
    const [prizeCenterY, setPrizeCenterY] = useState(0);

    useEffect(() => setShow(context.isShow), [context.isShow]);

    useEffect(() => {
        if (!context.isPopupReady) {
            return;
        }

        const rect = context.todayTarget.current!.getBoundingClientRect();
        const centerX = (rect.left + rect.right) / 2;
        const centerY = (rect.top + rect.bottom) / 2;

        setCenterX(centerX);
        setCenterY(centerY + 16);
        setPrizeCenterY(centerY - 10);
    }, [context.isPopupReady]);

    const onHide = () => setShow(false);

    const handleHide = useCallback(() => {
        if (animationForwarding) {
            return;
        }

        setAnimationForwarding(true);

        const prizeAnimationDurationMs = 850;
        setTimeout(onHide, prizeAnimationDurationMs);
    }, [animationForwarding]);


    if (!context.isPopupReady) {
        return <EmptyOverlay open={show} onHide={onHide} />;
    }

    return <Dialog
        open={show} onOpenChange={handleHide}>
        <DialogPortal>
            <div
                data-testid="daily-check-in-popup-today-prize-overlay"
                className={cn("tw-fixed tw-max-w-app-max tw-w-full tw-h-full tw-z-[9999] tw-left-0 tw-top-0")}
                style={{
                    "--centerX": `${centerX}px`,
                    "--centerY": `${centerY}px`,
                    "--prizeCenterY": `${prizeCenterY}px`,
                } as any}>
                <DialogOverlay onClick={handleHide} />
                {
                    centerX > 0 &&
                    <>
                        <Highlight />
                        <TodayPrize hideIcon={animationForwarding} />
                        {animationForwarding &&
                            <QuestPrizeAnimation {...context.todayPrize} />
                        }
                    </>
                }
            </div>
        </DialogPortal>
    </Dialog>;
};

const TodayPrize = ({ hideIcon }: { hideIcon: boolean }) => {
    const context = useDailyCheckInPopup();

    const prizeItem = (() => {
        const prize = context.todayPrize;
        return <UncompletedDailyCheckInPopupItem
            leading={true}
            prizeIcon={<RegularQuestPrizeIcon {...prize}
                                              hide={hideIcon} />}
            addSign={true}
            {...prize} />;
    })();

    const recommendedItem = (() => {
        const recommendedApLevel = apLevelConfig[context.myApLevel.currentLevel].recommendedUpgradeLevel;
        if (recommendedApLevel === null) {
            return null;
        }

        const recommendedPrize = context.getPrize(recommendedApLevel, context.todayDaysInRow);
        return <RecommendedDailyCheckInPopupItem
            leading={true}
            prize={<RegularQuestPrizeIcon {...recommendedPrize} />}
        />;
    })();

    return <div
        className={cn(
            "tw-absolute tw-z-50 tw-origin-top-left tw-scale-120",
            "tw-left-[var(--centerX)] tw--translate-x-6/10",
            "tw-top-[var(--centerY)] tw--translate-y-6/10",
            "tw-flex tw-flex-col tw-items-center tw-gap-1"
        )}>
        {prizeItem}
        {recommendedItem}
    </div>;
};

const Highlight = () => <div className={cn(
    "tw-absolute tw-z-50",
    "tw-left-[var(--centerX)] tw--translate-x-1/2",
    "tw-top-[var(--centerY)] tw--translate-y-1/2",
    "tw-bg-check-in-today-highlight",
    "tw-h-[234px] tw-w-[234px]",
)} />;

const EmptyOverlay = ({ open, onHide }: { open: boolean, onHide: () => void }) => {
    return <Dialog open={open} onOpenChange={onHide}>
        <DialogPortal>
            <div
                className={cn("tw-fixed tw-max-w-app-max tw-w-full tw-h-full tw-z-[9999] tw-left-0 tw-top-0")}>
                <DialogOverlay onClick={onHide} />
            </div>
        </DialogPortal>
    </Dialog>;
};
