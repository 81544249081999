import React from "react";
import EmptyResultMatch from "domain/result/EmptyResultMatch";
import ResultContestLeaderboard from "domain/result/detail/components/Leaderboard/ResultContestLeaderboard";
import { useResultMatchDetailContext } from "domain/result/detail/ResultMatchDetailProvider";

export const LeaderboardContent: React.FC = () => {
    const { completedContests } = useResultMatchDetailContext();

    if (!completedContests) {
        return null;
    }

    if (completedContests.length <= 0) {
        return <EmptyResultMatch />;
    } else {
        return <ResultContestLeaderboard contests={completedContests} />;
    }
};
