import { ContestDetailVo } from "data/vo/contest/ContestDetailVo";
import { ContestDetailVO } from "types/contest/ContestDetail";
import { NineCatContestVo } from "../../../slate/data/ComponentTypes";

export enum DISCOUNT_TYPE {
    RUBY = "RUBY",
    COUPON = "COUPON",
    NONE = "NONE"
}

export type ConfirmationDialogContestVO = NineCatContestVo | ContestDetailVO | ContestDetailVo
