import { KycLeavePopup } from "domain/kyc/components/kycLeavePopup/KycLeavePopup";
import React, { useCallback, useMemo, useState } from "react";


interface KycLeavePopupContextState {
    open: () => void;
}

const KycLeavePopupContext = React.createContext<KycLeavePopupContextState | null>(null);


interface KycLeavePopupProviderProps {
    children: React.ReactNode;
}

export const KycLeavePopupProvider: React.FC<KycLeavePopupProviderProps> = ({ children }) => {
    const [isShow, setIsShow] = useState(false);

    const handleOpenPopup = useCallback(() => {
        setIsShow(true);
    }, []);

    const handleClosePopup = () => {
        setIsShow(false);
    };

    const value = useMemo(() => ({
        open: handleOpenPopup,
    }), [handleOpenPopup]);

    return <KycLeavePopupContext.Provider value={value}>
        {children}
        <KycLeavePopup show={isShow} onClose={handleClosePopup} />
    </KycLeavePopupContext.Provider>;
};

export const useKycLeavePopup = () => {
    const context = React.useContext(KycLeavePopupContext);
    if (context === null) {
        throw new Error("useKycLeavePopup must be used within a KycLeavePopupProvider");
    }
    return context;
};
