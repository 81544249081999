import { Nav } from "react-bootstrap";
import styled from "styled-components";
import { AP_LEVEL } from "types/activityPoint/ApLevel";
import { TabItem } from "../../styles/component/TabStyle";
import { SYSTEM_COLORS } from "../../styles/Constants";
import { isTabColorBlack } from "./ActivityPointHelper";

export const ActivityPointProgramTabWrapper = styled(Nav).attrs({
    className: "justify-content-between flex-nowrap w-100"
})`
  background-color: transparent;
  border-radius: 0;
  margin-bottom: 0;
`;
export const ActivityPointProgramTabItem = styled(TabItem).attrs({
    className: "tw-border-secondary-600"
})`
  padding-bottom: 1rem;
  border-radius: 0;
  border-width: 0;

  &.active {
    background: transparent;
    box-shadow: none;
    border-width: 0 0 4px 0;
    border-style: solid;

  }
`;
export const ActivityPointProgramTabLink = styled.a.attrs<
    { $active: boolean, $level: AP_LEVEL }
>
(({ $active }) => ({
    className: $active ? "fz-sf-bold-body-1 hover:tw-text-grey-0" : "fz-sf-regular-body-1 hover:tw-text-grey-0",
    role: "button"
}))`
  color: ${({ $level }) => isTabColorBlack($level) ? SYSTEM_COLORS.labelLightPrimary : SYSTEM_COLORS.labelDarkPrimary};
`;
