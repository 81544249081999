import { AdRepository } from "apis/ad/AdApi";
import { AdCarouselWrapper } from "domain/home/components/ad/carousel/B2CAdCarouselStyle";
import { useSportProvider } from "domain/sport/SportProvider";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import { useLocaleProvider } from "LocaleProvider";
import React, { createContext, useState } from "react";
import SwiperCore from "swiper";
import { Autoplay } from "swiper/modules";
import { SwiperSlide } from "swiper/react";
import Carousel from "../../../../../components/carousel";
import AdSkeleton from "../../../../../components/skeleton/AdSkeleton";
import B2CWrapper from "../../../../../containers/b2cWrapper/B2CWrapper";
import GeneralAd from "./category/GeneralAd";

export const AdCarouselContext = createContext<SwiperCore | null>(null);

const AdCarousel: React.FC = () => {
    const { currentSport: sport } = useSportProvider();
    const { locale } = useLocaleProvider();

    const [swiper, setSwiper] = useState<SwiperCore | null>(null);

    const { data: ads, isLoading } = useFantasyQuery([QUERY_KEY.AD, sport, locale], () => {
        return new AdRepository().getAds(sport, locale);
    });

    if (isLoading) {
        return <AdSkeleton />;
    }

    if (!ads || ads?.length === 0) {
        return null;
    }

    const displayAds = ads.length > 1 ? [...ads, ...ads] : ads;

    return <AdCarouselContext.Provider value={swiper}>
        <AdCarouselWrapper>
            <Carousel loop={true}
                      className="custom-swiper px-4"
                      slidesPerView={1.9}
                      autoplay={{ delay: 5000 }}
                      modules={[Autoplay]}
                      onInit={(swiper: SwiperCore) => setSwiper(swiper)}
                      spaceBetween={8}
            >
                {
                    displayAds.map((ad, index) => {
                        return (
                            <SwiperSlide key={`ad-${index}`} data-testid={`ad-${index}`}>
                                <GeneralAd ad={ad} />
                            </SwiperSlide>
                        );
                    })
                }
            </Carousel>
        </AdCarouselWrapper>
    </AdCarouselContext.Provider>;
};

const B2CAdCarousel = () => (
    <B2CWrapper>
        <AdCarousel />
    </B2CWrapper>
);
export default B2CAdCarousel;
