import { GameLogVo } from "data/vo/player/gameLog/GameLogVo";
import React from "react";

export const gameLogsConfig = [
    {
        key: "matchDate",
        label: "Date"
    },
    {
        key: "oppositeSquadCode",
        label: "VS."
    },
    {
        key: "gameLogResult",
        label: "Result",
    },
    {
        key: "points",
        label: "PTS",
    },
    {
        key: "fieldGoalsMade",
        label: "FGM",
    },
    {
        key: "fieldGoalsPercentage",
        label: "FG%",
    },
    {
        key: "freeThrowsMade",
        label: "FTM",
    },
    {
        key: "freeThrowsPercentage",
        label: "FT%",
    },
    {
        key: "offensiveRebounds",
        label: "OR",
    },
    {
        key: "defensiveRebounds",
        label: "DR",
    },
    {
        key: "assists",
        label: "AST",
    },
    {
        key: "steals",
        label: "ST",
    },
    {
        key: "blocks",
        label: "BLK",
    },
    {
        key: "threePointersMade",
        label: "3PM",
    },
];

const NineCatGameLog: React.FC<{ gameLogs: GameLogVo[] }> = ({ gameLogs }) => {
    return (
        <table>
            <thead>
            <tr>
                {
                    gameLogsConfig.map(({ key, label }) => {
                        return <th
                            key={key}
                            className="tw-bg-primary-100 tw-text-caption-1 tw-text-grey-1000 tw-font-bold tw-px-4 tw-py-2 text-center tw-whitespace-nowrap"
                        >
                            {label}
                        </th>;
                    })
                }
            </tr>
            </thead>
            <tbody>
            {
                gameLogs.map((log: GameLogVo) => {
                    return <tr
                        key={log.matchStartsAt}
                        className="odd:tw-bg-gray-50 even:tw-bg-white"
                    >
                        {
                            gameLogsConfig.map(({ key }) => {
                                return <td
                                    className="tw-text-caption-1 tw-text-grey-1000 tw-px-4 tw-py-2 text-center tw-whitespace-nowrap"
                                    key={key}
                                >
                                    {log[key]}
                                </td>;
                            })
                        }
                    </tr>;
                })
            }
            </tbody>
        </table>
    );
};

export default NineCatGameLog;
