import styled from "styled-components";
import { PrimaryFixedButton } from "styles/component/ButtonsStyle";
import { APP_MAX_WIDTH, SYSTEM_COLORS } from "styles/Constants";

export const RewardItemContentWrapper = styled.div.attrs(() => ({
    className: "position-relative tw-bg-system-bgLightPrimary"
}))`
  padding: 2rem 1.6rem 10rem;
  margin: -1.6rem 0 0;
  border-radius: 1.6rem 1.6rem 0 0;
  z-index: 2;
`;
export const RewardItemTitle = styled.div.attrs(() => ({
    className: "fz-sf-bold-body-1 tw-text-system-labelLightPrimary"
}))`
  margin-top: 1.2rem;
`;
export const RewardItemInfo = styled.div.attrs(() => ({
    className: "d-flex justify-content-between tw-border-system-labelLightQuaternary"
}))`
  border-width: 0 0 1px 0;
  border-style: solid;
  padding: 1.2rem 0;
`;
export const RewardItemContentTitle = styled.div.attrs(() => ({
    className: "py-3 fz-sf-bold-title-3 tw-text-system-labelLightPrimary"
}))`
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
`;
export const RewardItemContentInfo = styled.div.attrs(() => ({
    className: "fz-sf-regular-body-2 py-3 tw-text-system-labelLightSecondary"
}))`
`;
export const RewardItemLinkWrapper = styled.div.attrs(() => ({
    className: "d-flex flex-column"
}))``;
export const RewardItemLink = styled.a.attrs(() => ({
    className: "py-1 tw-text-system-blue clickable"
}))`
  text-decoration: underline;
`;
export const RewardButtonWrapper = styled.div<{
    pointerEvents: string
}>`
  background: linear-gradient(transparent, ${SYSTEM_COLORS.bgLightPrimary});
  height: 10rem;
  position: fixed;
  width: 100%;
  max-width: ${APP_MAX_WIDTH};
  bottom: 0;
  z-index: 3;
  pointer-events: ${({ pointerEvents }) => pointerEvents};
`;
export const RewardBuyButton = styled(PrimaryFixedButton).attrs(() => ({}))`
  position: relative;
  bottom: 1.6rem;
`;

export const RewardItemContainer = styled.div.attrs({
    className: "d-flex flex-column tw-bg-system-bgLightPrimary"
})`
  padding-top: 6.5rem;
  height: 100vh;
`;

export const RewardItemCarousel = styled.div.attrs({})`
`;

export const CountryTag = styled.div.attrs({
    className: "fz-sf-bold-body-1 d-flex align-items-center"
})`
  height: 2.8rem;
  width: 2.8rem;
  gap: 0.8rem;
`;