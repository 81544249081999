import { Profile } from "data/vo/player/PlayerInfoVo";
import { PlayerInfoProfileVo } from "data/vo/player/profile/PlayerInfoProfileVo";
import { getSportConfig } from "domain/SportConfig";
import { getSentenceWithComma } from "helpers/string/StringHelper";
import { SPORT } from "types/sports/Sport";


export class CricketPlayerInfoProfileVo extends PlayerInfoProfileVo {
    private readonly _bowlingStyle: string | null;
    private readonly _battingStyle: string | null;

    constructor(profile: Profile) {
        super(profile);
        this._battingStyle = profile.battingStyle!;
        this._bowlingStyle = profile.bowlingStyle!;
    }

    get bowlingStyle(): string | null | undefined {
        return this._bowlingStyle;
    }

    get battingStyle(): string | null | undefined {
        return this._battingStyle;
    }

    get roleAbbr(): string {
        return this.role ? getSportConfig(SPORT.CRICKET).team.getRoleAbbr(this.role) : "";
    }

    getDescription(formattedMessage: (id: string) => string): string {
        const profiles = [
            {
                key: "roleAbbr",
                title: formattedMessage("role_title")
            },
            {
                key: "battingStyle",
                title: formattedMessage("batting_style_title")
            },
            {
                key: "bowlingStyle",
                title: formattedMessage("bowling_style_title")
            }
        ];

        return getSentenceWithComma(
            ...profiles.map(({ key, title }) => {
                const value = this[key];

                if (!value) return null;

                return `${title}: ${(value)}`;
            })
        );
    }
}
