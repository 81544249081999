import { FormContainer, InputErrorMessage, StyledFormLabel, UsernameFormInput } from "domain/me/profile/ProfileStyle";
import React, { useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";

export interface NicknameProps {
    isEditing: boolean
    nickname: string
    errorMessage?: string
    onChangeNickname: (val: string) => void
}

const Nickname: React.FC<NicknameProps> = ({
                                               isEditing,
                                               nickname,
                                               errorMessage,
                                               onChangeNickname
                                           }) => {

    const inputRef = useRef<HTMLInputElement>();

    useEffect(() => {
        if (isEditing) {
            inputRef.current?.focus();
        }
    }, [isEditing]);

    return (
        <FormContainer>
            <StyledFormLabel $errorMessage={errorMessage}>
                <FormattedMessage id="edit_profile_page_label_username" />
            </StyledFormLabel>
            <UsernameFormInput
                ref={inputRef}
                type="text"
                defaultValue={nickname}
                disabled={!isEditing}
                data-testid={"nickname-input"}
                $errorMessage={errorMessage}
                onChange={(e) => onChangeNickname(e.target.value)}
            />
            {errorMessage && (
                <InputErrorMessage>{errorMessage}</InputErrorMessage>
            )}
        </FormContainer>
    );
};

export default Nickname;
