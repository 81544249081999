import { fetchCurrentApLevelList } from "apis/ap/ActivityPointApi";
import { ApiExecuteContext } from "apis/ApiContext";
import { addFriend, fetchUserProfile } from "apis/user/UserApi";
import UserAvatarWithFrame from "components/avatar/UserAvatarWithFrame";
import { useLoading, useLoadingToggle } from "components/loading/Loading";
import { TOAST_TYPE } from "designToken/alert/components/AlertHelper";
import { getUserIdToCurrentLevel } from "domain/ap/ActivityPointHelper";
import { canFriendBeAdded, isFriend } from "domain/friend/FriendHelper";
import {
    AddFriendIcon,
    ButtonWrapper,
    ClearButton,
    InputLabel,
    NameWrapper,
    SearchFriendId,
    SearchFriendNickName,
    SearchFriendWrapper,
    SearchUserBarForm,
    SearchUserInput,
    TransferIcon,
    WarningLabel
} from "domain/friend/searchUserBar/SearchUserBarStyle";
import { createErrorObject, isDataNotFound } from "helpers/ErrorHelper";
import { filterDigits } from "helpers/number/NumberHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { getUserInfoPageUrl } from "router/RouterUrls";
import { addErrorPopup, addToast } from "store/actions/AlertActions";
import { SYSTEM_COLORS } from "styles/Constants";

const LENGTH_OF_USER_CODE = 6;

export const SearchUserBar = ({ onTransferUserClicked, onNewFriendAdded }) => {
    const intl = useIntl();
    const history = useFantasyHistory();
    const dispatch = useDispatch();
    const apiExecutor = useContext(ApiExecuteContext);
    const toggleLoading = useLoadingToggle();
    const loading = useLoading();
    const inputBoxRef = useRef(null);
    const [inputUserCode, setInputUserCode] = useState("");
    const [foundUser, setFoundUser] = useState(null);
    const [userIdToCurrentLevel, setUserIdToCurrentLevel] = useState({});

    const [warningMessage, setWarningMessage] = useState(null);

    const getCurrentApLevelList = useCallback(userIds => {
        apiExecutor(
            fetchCurrentApLevelList(userIds),
            {
                onSuccess: (res) => setUserIdToCurrentLevel(getUserIdToCurrentLevel(res))
            }
        );
    }, [apiExecutor]);

    const handleTransferIconClicked = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onTransferUserClicked(foundUser);
    };

    const handleAddFriendClicked = (e) => {
        e.preventDefault();
        e.stopPropagation();

        toggleLoading(true);

        apiExecutor(
            addFriend(foundUser.id),
            {
                onSuccess: async () => {
                    setFoundUser(null);
                    setInputUserCode("");
                    await onNewFriendAdded();

                    dispatch(addToast({
                        type: TOAST_TYPE.SUCCESS,
                        content: intl.messages["user_profile_page_toast_add_friend_success"]
                    }));
                    toggleLoading(false);
                },
                onFail: (error) => {
                    toggleLoading(false);
                    dispatch(addErrorPopup(createErrorObject(error)));
                }
            }
        );
    };

    const handleInputChanged = (e) => {
        setInputUserCode(filterDigits(e.target.value));
        setFoundUser(null);
        setWarningMessage(null);
    };

    const handleClearButtonClicked = () => {
        setInputUserCode("");
        setFoundUser(null);
        setWarningMessage(null);
        inputBoxRef.current.focus();
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (
            loading ||
            warningMessage ||
            foundUser
        ) return;

        if (inputUserCode.length !== LENGTH_OF_USER_CODE) {
            setWarningMessage(intl.formatMessage(
                { id: "search_user_view_error_invalid_format" },
                { number: LENGTH_OF_USER_CODE }));
            setFoundUser(null);
            return;
        }

        toggleLoading(true);

        apiExecutor(
            fetchUserProfile(parseInt(inputUserCode, 10)),
            {
                onSuccess: (searchResult) => {
                    setFoundUser(searchResult);
                    setWarningMessage(null);
                    getCurrentApLevelList([searchResult.id]);
                },
                onFail: (error) => {
                    if (isDataNotFound(error)) {
                        setFoundUser(null);
                        setWarningMessage(intl.messages["search_user_view_error_user_not_found"]);
                    } else {
                        dispatch(addErrorPopup(createErrorObject(error)));
                    }
                },
                onFinally: () => {
                    toggleLoading(false);
                }
            }
        );
    };

    const ButtonFactory = () => {
        if (isFriend(foundUser.type)) {
            return <TransferIcon onClick={handleTransferIconClicked} />;
        }

        if (canFriendBeAdded(foundUser.type, foundUser.myFriendFull)) {
            return <AddFriendIcon onClick={handleAddFriendClicked} />;
        }

        return null;
    };

    useEffect(() => {
        // NOTE: The inputBox gains the focus automatically when the first time is it mounted.
        inputBoxRef.current.focus();
    }, []);

    return (<>
            <SearchUserBarForm onSubmit={handleSubmit}>
                <InputLabel
                    className={inputUserCode ? "nonempty-input" : ""}
                    color={warningMessage ? SYSTEM_COLORS.red : SYSTEM_COLORS.labelLightSecondary}
                >
                    <FormattedMessage id="search_user_view_title" />
                </InputLabel>
                <SearchUserInput
                    ref={inputBoxRef}
                    maxLength={LENGTH_OF_USER_CODE}
                    type="text"
                    value={inputUserCode}
                    borderColor={warningMessage ? SYSTEM_COLORS.red : SYSTEM_COLORS.primary60}
                    onChange={handleInputChanged}
                />
                <ClearButton tabIndex={-1} onClick={handleClearButtonClicked} />
                {
                    foundUser && (<SearchFriendWrapper
                        tabIndex={-1}
                        onClick={() => history.push(getUserInfoPageUrl(foundUser.id))}
                    >
                        <UserAvatarWithFrame url={foundUser.avatar}
                                             name={foundUser.nickname}
                                             size="4.8rem"
                                             currentLevel={userIdToCurrentLevel[foundUser.id]} />
                        <NameWrapper>
                            <SearchFriendNickName>{foundUser.nickname}</SearchFriendNickName>
                            <SearchFriendId>{foundUser.userCode}</SearchFriendId>
                        </NameWrapper>
                        <ButtonWrapper>
                            <ButtonFactory />
                        </ButtonWrapper>
                    </SearchFriendWrapper>)
                }
                <WarningLabel>{warningMessage}</WarningLabel>
            </SearchUserBarForm>
        </>
    );
};
