export class SeasonVo {
    public readonly inProgress: boolean;
    public readonly nextStartDate: string | null;

    private constructor(inProgress: boolean, nextStartDate: string | null) {
        this.inProgress = inProgress;
        this.nextStartDate = nextStartDate;
    }

    public static create(inProgress: boolean, nextStartDate: string | null) {
        return new SeasonVo(inProgress, nextStartDate);
    }
}
