import React from "react";
import PublicContest from "../../pages/upcoming/contest/public/PublicContest";
import { useStartedMatchDetailData } from "../../providers/MyStartedMatchDataProvider";
import { useStartedMatchDetailFlow } from "../../providers/MyStartedMatchFlowProvider";

const Contests = () => {
    const { handleCreateTeam, onJoinButtonClick } = useStartedMatchDetailFlow();
    const startedMatchDetailData = useStartedMatchDetailData();
    const publicContests = startedMatchDetailData.publicContests!;
    const match = startedMatchDetailData.match!;

    return (
        <PublicContest contests={publicContests}
                       match={match}
                       toCreateTeam={handleCreateTeam}
                       onJoinButtonClick={onJoinButtonClick} />
    );
};

export default Contests;