import { UpcomingMatchVO } from "apis/match/type";
import Countdown from "components/countdown/Countdown";
import SquadLogo from "components/image/SquadLogo";
import {
    JoinedNumbers,
    JoinedWrapper,
    MatchCardBodyLeft,
    MatchCardBodyRight,
    MatchCardBodyWrapper,
    MatchInfoSummary,
    MatchStatus,
    Number,
    SquadInfoWrapper,
    SquadLogoSection,
    SquadLogoWrapper,
    SquadName,
    TeamAndContestWrapper,
    TeamNumberSection,
    Title
} from "components/matchCards/MatchCardStyle";
import MatchStatusWithDot from "domain/match/components/status/MatchStatusWithDot";
import { isMatchStarted } from "helpers/match/MatchHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import { isNull } from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";


interface UpcomingMatchCardBodyProps {
    match: UpcomingMatchVO;
    handleTimeout: Function;
    children?: React.ReactNode;
}

export const UpcomingMatchCardBody: React.FC<UpcomingMatchCardBodyProps> = ({
                                                                                match,
                                                                                handleTimeout,
                                                                                children = null
                                                                            }) => {
    const {
        squadAName,
        squadALogoUrl,
        squadBName,
        squadBLogoUrl,
        matchStartsAt,
        matchStatus
    } = match;

    return <MatchCardBodyWrapper>
        <MatchCardBodyLeft>
            <SquadInfoWrapper>
                <SquadLogoSection>
                    <SquadLogoWrapper className="me-2">
                        <SquadLogo name={squadAName} url={squadALogoUrl} />
                    </SquadLogoWrapper>
                    <SquadName>{squadAName}</SquadName>
                </SquadLogoSection>
            </SquadInfoWrapper>
            <SquadInfoWrapper>
                <SquadLogoSection>
                    <SquadLogoWrapper className="me-2">
                        <SquadLogo name={squadBName} url={squadBLogoUrl} />
                    </SquadLogoWrapper>
                    <SquadName>{squadBName}</SquadName>
                </SquadLogoSection>
            </SquadInfoWrapper>
        </MatchCardBodyLeft>
        <MatchCardBodyRight>
            <MatchStatus $matchStatus={matchStatus}>
                {
                    isMatchStarted(match.matchStatus)
                        ? <MatchStatusWithDot matchStatus={match.matchStatus} />
                        : <Countdown key={`${match.matchId}-${matchStartsAt}`}
                                     finishAt={matchStartsAt}
                                     handleTimeout={handleTimeout} />
                }
                {children}

            </MatchStatus>
        </MatchCardBodyRight>

    </MatchCardBodyWrapper>;

};

interface MatchInfoSummaryLayoutProps {
    teams: number,
    contests: number,
    joined: number,
    onClick?: (e) => void
}

export const MatchInfoSummaryLayout: React.FC<MatchInfoSummaryLayoutProps> = ({
                                                                                  teams,
                                                                                  contests,
                                                                                  joined,
                                                                                  onClick
                                                                              }) => {
    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();


    return <MatchInfoSummary onClick={onClick}>
        <TeamAndContestWrapper>
            <TeamNumberSection>
                <Number>{teams}</Number>
                <Title>&nbsp;<FormattedMessage id="my_match_card_label_team" /></Title>
            </TeamNumberSection>
            <div>
                <Number>{contests}</Number>
                <Title>&nbsp;<FormattedMessage id="my_match_card_label_contest" /></Title>
            </div>
        </TeamAndContestWrapper>
        {

            !isNull(joined)
            && <JoinedWrapper data-testid="match-info-summary-joined">
                <JoinedNumbers>{toFormattedNumber(joined)}</JoinedNumbers>
                <span className="text-text-secondary-1 fz-sf-regular-subhead"><FormattedMessage
                    id="match_card_label_joined" /></span>
            </JoinedWrapper>

        }
    </MatchInfoSummary>;
};

