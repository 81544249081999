import { zodResolver } from "@hookform/resolvers/zod";
import { CHECKBOX_VARIANTS } from "components/checkbox/types";
import FantasyBasicPopup, { POPUP_SIZE } from "components/popup/FantasyBasicPopup";
import { FantasyFormCheckbox } from "designToken/textFields/FantasyFormCheckbox";
import useFantasyHistory from "hooks/useFantasyHistory";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { z } from "zod";
import { FormattedMessage, useIntl } from "react-intl";

export const ResponsibleGamingPopup: React.FC = () => {
    const history = useFantasyHistory();
    const intl = useIntl();
    const [isShow, setIsShow] = useState(true);

    const form = useForm<{
        isOver21: boolean;
        isNotGovernment: boolean;
        isNotGEL: boolean;
    }>({
        resolver: zodResolver(z.object({
            isOver21: z.boolean().refine(value => value),
            isNotGovernment: z.boolean().refine(value => value),
            isNotGEL: z.boolean().refine(value => value),
        })),
        defaultValues: {
            isOver21: false,
            isNotGovernment: false,
            isNotGEL: false,
        }
    });

    const { formState: { isValid } } = form;

    const handleConfirm = form.handleSubmit(() => {
        setIsShow(false);
    });

    const handleCancel = () => {
        history.replace(ROUTER_PATH_CONFIG.auth.index);
    };

    return <FantasyBasicPopup
        show={isShow}
        title={intl.formatMessage({ id: "responsible_gaming_title" })}
        popupSize={POPUP_SIZE.MD}
        onHide={handleCancel}
        dismissible={false}
        content={<FormProvider {...form}>
            <div className="tw-flex tw-flex-col tw-gap-4 tw-px-3">
                <FantasyFormCheckbox
                    name="isOver21"
                    variant={CHECKBOX_VARIANTS.primary}
                    showErrorMessage={false}
                >
                    <FormattedMessage id="responsible_gaming_age_confirmation" />
                </FantasyFormCheckbox>
                <FantasyFormCheckbox
                    name="isNotGovernment"
                    variant={CHECKBOX_VARIANTS.primary}
                    showErrorMessage={false}
                >
                    <FormattedMessage id="responsible_gaming_non_government_confirmation" />
                </FantasyFormCheckbox>
                <FantasyFormCheckbox
                    name="isNotGEL"
                    variant={CHECKBOX_VARIANTS.primary}
                    showErrorMessage={false}
                >
                    <FormattedMessage id="responsible_gaming_non_gel_confirmation" />
                </FantasyFormCheckbox>
                <p className="tw-text-caption-1 tw-text-start">
                    <FormattedMessage id="responsible_gaming_confirmation_disclaimer" />
                </p>
            </div>
        </FormProvider>}
        buttonSetting={{
            confirmButtonDisabled: !isValid,
            onConfirm: handleConfirm,
            onCancel: handleCancel,
        }}
    />;
};
