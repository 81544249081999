import { divideByOneHundred, multiplyByOneHundred } from "helpers/number/NumberHelper";
import moment from "moment";
import {
    MdOutlineConfirmationNumber,
    MdOutlineFaceRetouchingNatural,
    MdOutlineGroupAdd,
    MdOutlineRedeem
} from "react-icons/md";
import { formatToFullYearDateTime } from "../../../helpers/TimeHelper";
import { Coupon, COUPON_ACTION, Coupons, EnrichedCoupon, EnrichedCoupons } from "../../../types/coupon/Coupon";

export const enrichCoupon = (coupon: Coupon): EnrichedCoupon => {
    const couponActionToIcon = {
        [COUPON_ACTION.REFERRED]: <MdOutlineGroupAdd
            data-testid="icon-coupon-referred"
            size="3.2rem"
            className="m-auto" />,
        [COUPON_ACTION.REGISTER]: <MdOutlineConfirmationNumber
            data-testid="icon-coupon-register"
            size="3.2rem"
            className="m-auto" />,
        [COUPON_ACTION.REDEMPTION_CODE]: <MdOutlineRedeem
            data-testid="icon-coupon-promotional-code"
            size="3.2rem"
            className="m-auto" />,
        [COUPON_ACTION.MANUAL_ADD_COUPON]: <MdOutlineFaceRetouchingNatural
            data-testid="icon-coupon-manual-add-coupon"
            size="3.2rem"
            className="m-auto" />,
    };
    const icon = couponActionToIcon[coupon.couponAction] ?? couponActionToIcon[COUPON_ACTION.REGISTER];

    const startDate = formatToFullYearDateTime(coupon.from);
    const endDate = formatToFullYearDateTime(coupon.to);
    const contestDiscountCent = (contestEntryFeeCent: number) => multiplyByOneHundred(
        Math.floor(divideByOneHundred(contestEntryFeeCent) * coupon.discountPercentage / 100)
    );

    return {
        ...coupon,
        startDate,
        endDate,
        icon,
        contestDiscountCent,
        isFree: coupon.discountPercentage === 100
    };
};

export function enrichCoupons(myCoupon: Coupons): EnrichedCoupons {
    const obj = {} as EnrichedCoupons;

    Object.keys(myCoupon).forEach((key) => {
        obj[key] = myCoupon[key].map(enrichCoupon);
    });

    return obj;
}

export const getToTextColor = (coupon: EnrichedCoupon): string => {

    const today = moment();

    const duration = Math.floor(moment.duration(moment(coupon.to).diff(today)).as("days"));

    if (duration >= 5) {
        return "text-text-secondary-1";
    } else {
        return "text-sys-danger-1";
    }
};

export const isCouponValid = (coupon: Coupon, contestEntryFeeCent: number): boolean => {
    return coupon.minEntryFeeCent <= contestEntryFeeCent && isCouponTimeValid(coupon);
};


export const isCouponTimeValid = (coupon: Coupon) => {
    const now = new Date().getTime();
    if (now < coupon.from) {
        return false;
    }

    if (!coupon.to) {
        return true;
    }

    return now < coupon.to;
};

