import Countdown from "components/countdown/Countdown";
import FantasyScrollingPopup, {
    ScrollingPopupBody,
    ScrollingPopupFooter,
    ScrollingPopupHeader,
    ScrollingPopupPrimaryButton
} from "components/popup/FantasyScrollingPopup";
import React from "react";
import { NineCatDetailVo } from "../data/vo";

interface SlatePopupProps {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    slate: NineCatDetailVo;
}

const SlatePopup: React.FC<SlatePopupProps> = ({ open, onOpenChange, slate }) => {
    const handleClose = () => {
        onOpenChange(false);
    };

    const renderSquad = (url: string, text: string) => {
        return (
            <div className="tw-flex tw-items-center tw-gap-1 tw-w-[6rem] tw-text-h6 tw-text-grey-1000">
                <img
                    className="tw-w-5 tw-h-5"
                    src={url}
                    alt={text} />
                {text}
            </div>
        );
    };

    return (
        <FantasyScrollingPopup
            open={open}
            onOpenChange={onOpenChange}
            title={<ScrollingPopupHeader title={slate.title} onClose={handleClose} />}
            body={
                <ScrollingPopupBody>
                    {
                        slate?.slateMatches?.map(slateMatch => (
                            <div
                                key={`${slateMatch.squadACode}&${slateMatch.squadBCode}`}
                                className="tw-mb-3 tw-bg-grey-100 tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-2 tw-rounded-lg"
                            >
                                <div className="tw-flex tw-items-center tw-gap-2">
                                    {renderSquad(slateMatch.squadALogoUrl, slateMatch.squadACode)}
                                    <span className="tw-text-body-2">V.S.</span>
                                    {renderSquad(slateMatch.squadBLogoUrl, slateMatch.squadBCode)}
                                </div>
                                <div className="tw-text-tertiary-700 tw-text-body-2">
                                    <Countdown finishAt={slateMatch.matchStartsAt} />
                                </div>
                            </div>
                        ))
                    }

                </ScrollingPopupBody>
            }
            footer={
                <ScrollingPopupFooter>
                    <ScrollingPopupPrimaryButton onClick={handleClose} />
                </ScrollingPopupFooter>
            }
        />
    );
};

export default SlatePopup;
