import { ApiExecuteContext } from "apis/ApiContext";
import { editTeam } from "apis/team/TeamApi";
import NonStarterPanel from "domain/team/NonStarterPanel";
import { TeamContext, useOperateTeam } from "domain/team/OperateTeam";
import { getBeEditedTeamSpecialPlayer } from "domain/team/OperateTeamHelper";
import { TeamFlowWrapper } from "domain/team/OperateTeamStyle";
import { createErrorObject, isForbiddenByKYCRestriction, isMatchStarted } from "helpers/ErrorHelper";
import { useCompleteYourKycDialog, useEditTeamSuccessDialog } from "hooks/useDialog";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { updateTeamPreviewShow } from "store/actions/Actions";
import { addErrorPopup } from "store/actions/AlertActions";
import { MatchVO } from "types/match/Match";
import { SPORT } from "types/sports/Sport";
import { MyTeamVO } from "types/team/MyTeam";


interface EditTeamFlowProps {
    sport: SPORT
    match: MatchVO
    onNavbarBackClick: () => void
    onSuccess: (callback?) => void
    beEditedTeam: MyTeamVO
    handleTimeout?: () => void,
    myTeams: MyTeamVO[],
    scrollSpyRootSelector?: string
}

const EditTeamFlow: React.FC<EditTeamFlowProps> = ({
                                                       sport,
                                                       match,
                                                       onNavbarBackClick,
                                                       onSuccess,
                                                       beEditedTeam,
                                                       handleTimeout = () => {},
                                                       myTeams,
                                                       scrollSpyRootSelector
                                                   }) => {
    const dispatch = useDispatch();
    const {
        toggleLoading,
        OperateTeamBody: EditTeamBody,
        allPlayers,
        setAllPlayers,
        selectedPlayers,
        specialPlayer,
        setSpecialPlayer,
        showLeavingWarningPopup,
        backToChoosePlayer,
        backToChooseSpecialPlayer,
        nextToChooseSpecialPlayer,
        showPreview,
        previewToggle,
        restCreditCent,
        isStartingLineupDecided
    } = useOperateTeam({ sport, match, onNavbarBackClick, beOperatedTeam: beEditedTeam });
    const apiExecutor = useContext(ApiExecuteContext);
    const intl = useIntl();
    const handleCompleteYourKycDialog = useCompleteYourKycDialog();

    const editTeamSuccessPopup = useEditTeamSuccessDialog();

    const handleEditTeam = useCallback(() => {
        toggleLoading(true);

        apiExecutor(
            editTeam(sport, beEditedTeam.id, {
                matchId: match.matchId,
                playerIds: selectedPlayers.map(player => player.id),
                captain: specialPlayer["captain"],
                viceCaptain: specialPlayer["viceCaptain"]
            }),
            {
                onSuccess: () => {
                    onSuccess();
                    editTeamSuccessPopup();
                    dispatch(updateTeamPreviewShow(false));
                },
                onFail: (_error) => {
                    if (isMatchStarted(_error)) {
                        handleTimeout();
                        return;
                    }

                    if (isForbiddenByKYCRestriction(_error)) {
                        handleCompleteYourKycDialog();
                        return;
                    }
                    dispatch(addErrorPopup(createErrorObject(_error)));
                },
                onFinally: () => {
                    toggleLoading(false);
                }

            }
        );
    }, [apiExecutor, handleTimeout, toggleLoading, match, onSuccess, selectedPlayers, specialPlayer, beEditedTeam, sport, editTeamSuccessPopup, dispatch]);

    const onBeforeChooseSpecialPlayer = useCallback(() => {
        setSpecialPlayer(getBeEditedTeamSpecialPlayer(beEditedTeam.captain.id, beEditedTeam.viceCaptain.id, selectedPlayers));
    }, [setSpecialPlayer, beEditedTeam, selectedPlayers]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [EditTeamBody]);


    const providedValue = useMemo(() => ({
        sport,
        match,
        allPlayers,
        setAllPlayers,
        selectedPlayers,
        specialPlayer,
        setSpecialPlayer,
        onSuccess,
        navbarTitle: intl.formatMessage({ id: "label_edit_team" }),
        handleSubmit: handleEditTeam,
        onBeforeChooseSpecialPlayer,
        showLeavingWarningPopup,
        backToChoosePlayer,
        backToChooseSpecialPlayer,
        nextToChooseSpecialPlayer,
        showPreview,
        previewToggle,
        handleTimeout,
        restCreditCent,
        myTeams,
        scrollSpyRootSelector,
        isStartingLineupDecided
    }), [
        sport,
        match,
        allPlayers,
        setAllPlayers,
        selectedPlayers,
        specialPlayer,
        setSpecialPlayer,
        onSuccess,
        intl,
        handleEditTeam,
        onBeforeChooseSpecialPlayer,
        showLeavingWarningPopup,
        backToChoosePlayer,
        backToChooseSpecialPlayer,
        nextToChooseSpecialPlayer,
        showPreview,
        previewToggle,
        handleTimeout,
        restCreditCent,
        myTeams,
        scrollSpyRootSelector,
        isStartingLineupDecided
    ]);

    return (<>
        <TeamContext.Provider value={providedValue}>
            <TeamFlowWrapper>
                <EditTeamBody />
                <NonStarterPanel beOperatedTeam={beEditedTeam} />
            </TeamFlowWrapper>
        </TeamContext.Provider>
    </>);
};

export default EditTeamFlow;
