import { getQuery } from "../helpers/queryString";
import SYSTEM_LINKS from "../helpers/LinkHelper";

export const mobileAppKey = "app";

export enum APP_ROUTE {
    home = "/",
    walletHistoryWinning = "/wallet/history/winning",
}

export const useMobileApp = () => {
    return {
        isFromMobileApp: () => {
            const { from } = getQuery();
            return from === mobileAppKey;
        },
        goToApp: (route?: APP_ROUTE) => {
            // TODO: Try to make link to app with only one way, which is android app link / ios universal link.
            // Should consider use android app link / ios universal link at first

            // TODO WARNING: this method use ios / android deep link
            // As article, apple is not allowed `Same domain` and `Javascript call <a href>.click()` to use universal link
            // https://help.branch.io/developers-hub/docs/ios-universal-links#apps-limited-by-apple
            let url = SYSTEM_LINKS.app + route ?? "";
            window.location.assign(url);
        }
    };
};
