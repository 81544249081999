import React from "react";
import useFantasyHistory from "hooks/useFantasyHistory";
import { isUnauthorized } from "helpers/ErrorHelper";
import { B2BWentWrongPage } from "domain/unauthorized/logout/B2BWentWrongPage";
import { B2BLogoutPage } from "domain/unauthorized/logout/B2BLogoutPage";

const B2BErrorPage = () => {
    const history = useFantasyHistory();
    const state = history.location?.state;

    if (isUnauthorized(state?.statusCode)) {
        return <B2BLogoutPage />;
    }

    return <B2BWentWrongPage />;
};

export default B2BErrorPage;
