import FantasyBasicPopup, { POPUP_SIZE } from "components/popup/FantasyBasicPopup";
import { BUTTON_PATTERN, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { Icon, kycVerified } from "helpers/IconHelper";
import React from "react";
import { useIntl } from "react-intl";
import { useKycPopup } from "./KycPopupProvider";

export const KycVerifiedPopup = () => {
    const intl = useIntl();
    const context = useKycPopup();

    return <FantasyBasicPopup
        show={context.isShowCompleted}
        onHide={context.handleCloseCompleted}
        popupSize={POPUP_SIZE.LG}
        imageSection={<img src={kycVerified} alt="kyc-completed" className="tw-max-w-[17.8rem]" />}
        title={{
            title: intl.formatMessage({ id: "label_verification_successful" }),
            titleClassName: "tw-text-success-800",
        }}
        texts={[{
            text: intl.formatMessage({ id: "kyc_verification_successful_content_1" }),
            textClassName: "tw-text-left tw-px-[1.2rem] tw-text-subtitle-1",
        }, {
            text: intl.formatMessage({ id: "kyc_verification_successful_content_2" }),
            textClassName: "tw-text-left tw-w-full tw-px-[1.2rem] tw-text-subtitle-1",
        }]}
        buttonSetting={{
            confirmButton: false,
            cancelButton: true,
            cancelButtonVariant: NEW_BUTTON_VARIANTS.primaryDark,
            buttonPattern: BUTTON_PATTERN.rounded,
            cancelButtonText: intl.formatMessage({ id: "kyc_verification_successful_enjoy" }),
            cancelButtonSuffixIcon: Icon.Congrats,
            onCancel: context.handleCloseCompleted,
        }}
    />;
};
