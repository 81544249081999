import React, { useMemo } from "react";
import { AuthRepository } from "../../data/repository/auth/AuthRepository";
import { AuthConfigVo } from "../../data/vo/auth/AuthConfigVo";
import useFantasyConfig from "../../hooks/useFantasyConfig";
import useFantasyQuery from "../../hooks/useFantasyQuery";
import { QUERY_KEY } from "../../hooks/useFantasyQuery/type";


interface AuthContextProps {
    authConfig?: AuthConfigVo;
    setSelectedSiteId: (siteId: number) => void;
    redirectUrl: string;
}

const AuthContext = React.createContext<AuthContextProps | null>(null);


interface AuthProviderProps {
    children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const { gameUrl } = useFantasyConfig();
    const [selectSiteId, setSelectedSiteId] = React.useState<number>(0);

    const redirectUrl = useMemo(() => {
        if (selectSiteId) {
            return `${gameUrl}?siteId=${selectSiteId}`;
        }

        return gameUrl;
    }, [gameUrl, selectSiteId]);

    const repository = new AuthRepository();

    const authConfig = useFantasyQuery([QUERY_KEY.AUTH_CONFIG], repository.getAuthConfig);

    const value = useMemo(() => ({
        authConfig: authConfig.data || {} as AuthConfigVo,
        setSelectedSiteId,
        redirectUrl
    }), [authConfig.data, setSelectedSiteId, redirectUrl]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = React.useContext(AuthContext);
    if (context === null) {
        throw new Error("useAuth must be used within a AuthProvider");
    }
    return context;
};
