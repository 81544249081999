export enum BUTTON_VARIANTS {
    primary = "primary",
    primaryLight = "primary-light",
    secondary = "secondary",
    success = "success",
    danger = "danger",
    warning = "warning",
    info = "info",
    light = "light",
    dark = "dark",
    tertiary = "tertiary",
    outlinePrimary = "outline-primary",
    outlinePrimaryLight = "outline-primary-light",
    outlineSecondary = "outline-secondary",
    outlineSuccess = "outline-success",
    outlineWarning = "outline-warning",
    outlineDanger = "outline-danger",
    outlineInfo = "outline-info",
    outlineLight = "outline-light",
    outlineDark = "outline-dark",
    outlineOpacityLight = "outline-opacity-light",
    outlineTertiary = "outline-tertiary"
}

export enum NEW_BUTTON_VARIANTS {
    primaryDark = "primary-dark",
    primaryLight = "primary-light",
    primaryUltraLight = "primary-ultra-light",
    secondaryLight = "secondary-light",
    secondaryDark = "secondary-dark",
    grey = "grey",
    greyDark = "grey-dark",
    greyLight = "grey-light",
    errorGrey = "error-grey",
    tertiaryLight = "tertiary-light",
    text = "text",
}

export enum BUTTON_SIZE {
    sm = "sm",
    md = "md",
    lg = "lg"
}

export enum BUTTON_PATTERN {
    rounded = "rounded",
    pill = "pill"
}
