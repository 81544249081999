import { CONTEST_TYPE_FROM_API, ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";

export const secondaryStyle = {
    progressBar: "ui-contestCard-pieChart-secondary-gradient",
    text: "ui-contestCard-pieChart-secondary-text",
    buttonBoxShadow: "ui-contestCard-pieChart-secondary-shadow",
    joinButtonBackground: "ui-contestCard-pieChart-btn"
};

export const primaryStyle = {
    progressBar: "ui-contestCard-pieChart-primary-gradient",
    text: "ui-contestCard-pieChart-primary-text",
    buttonBoxShadow: "ui-contestCard-pieChart-primary-shadow",
    joinButtonBackground: "ui-contestCard-pieChart-btn"
};

export const tertiaryStyle = {
    progressBar: "ui-contestCard-pieChart-tertiary-gradient",
    text: "ui-contestCard-pieChart-tertiary-text",
    buttonBoxShadow: "ui-contestCard-pieChart-tertiary-shadow",
    joinButtonBackground: "ui-contestCard-pieChart-btn"
};

export const dangerStyle = {
    progressBar: "ui-contestCard-pieChart-danger-gradient",
    text: "ui-contestCard-pieChart-danger-text",
    buttonBoxShadow: "ui-contestCard-pieChart-danger-shadow",
    joinButtonBackground: "ui-contestCard-pieChart-btn"
};

export const grayStyle = {
    progressBar: "ui-contestCard-pieChart-gray-gradient",
    text: "ui-contestCard-pieChart-gray-text",
    buttonBoxShadow: "ui-contestCard-pieChart-gray-shadow",
    joinButtonBackground: "ui-contestCard-pieChart-btn-off"
};


export const getColorObject = (isJoinable, isJoined, { entryFeeType, type }) => {
    if (!isJoinable) return grayStyle;

    if (isJoined)
        return secondaryStyle;

    if (entryFeeType === ENTRY_FEE_TYPE_FROM_API.RUBY)
        return dangerStyle;

    if (type === CONTEST_TYPE_FROM_API.PRACTICE)
        return tertiaryStyle;

    return primaryStyle;
};
