import { ROLE } from "types/sports/Sport";
import { LINEUP } from "types/player/Player";
import { chain, Dictionary } from "lodash";

export interface MatchResultPlayerVo {
    id: string;
    name: string;
    point: number;
    role: ROLE;
    squadCode: string;
    squadKey: string;
    lineup: LINEUP;
    recentPointsCents: number[];
    avatar: string,
    isHome?: boolean;
}

export class MatchResultPlayersVo {
    private readonly _players: MatchResultPlayerVo[];

    constructor(players: MatchResultPlayerVo[]) {
        this._players = players;
    }

    public get sortedPlayers(): MatchResultPlayerVo[] {
        return chain(this._players)
            .sortBy("name")
            .sortBy("point").reverse()
            .sortBy(this._lineup)
            .value();
    }

    public attentionWorthyPlayers = (homeSquadKey: string): Dictionary<MatchResultPlayerVo[]> => {
        return chain(this._players)
            .map(player => ({ ...player, isHome: player.squadKey === homeSquadKey }))
            .sortBy("name")
            .sortBy("point").reverse()
            .filter(this._attentionPoint)
            .groupBy("role")
            .mapValues(this._getFirstFourPlayers)
            .value();
    };

    private _attentionPoint = (player: MatchResultPlayerVo) => player.point > 0;

    private _lineup = (player: MatchResultPlayerVo) => player.lineup === LINEUP.STARTING_LINEUP ? 0 : 1;
    private _getFirstFourPlayers = (players: MatchResultPlayerVo[]): MatchResultPlayerVo[] => {
        return players.slice(0, 4);
    };
}
