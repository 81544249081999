import NineCatPlayerPopup from "domain/slate/components/NineCatPlayerPopup";
import { useQuerySlatePlayers } from "domain/slate/hooks/useQuerySlatePlayers";
import { useCreateSlateTeamContext } from "domain/slate/providers/CreateNineCatTeamProvider";
import { useNineCatContext } from "domain/slate/providers/NineCatProvider";
import { CreateNineCatTeamTopBar } from "domain/slate/views/CreateNineCatTeamTopBar";
import { MyNineCatContests } from "domain/slate/views/MyNineCatContests";
import { MyNineCatTeams } from "domain/slate/views/MyNineCatTeams";
import _ from "lodash";
import React from "react";
import { useParams } from "react-router-dom";
import { MouseEventHandler } from "react/index";
import { AutoChooseSlateTeamPlayersButton } from "../components/AutoChooseSlateTeamPlayersButton";
import { FilledPlayerInfoVO, NINE_CAT_STAT, NineCatPlayerDetailVO } from "../data/ComponentTypes";
import { useCreateTeamFeatureButtonGroup } from "../hooks/useCreateTeamFeatureButtonGroup";
import { CreateSlateTeamPanelContainer } from "./CreateSlateTeamPanelContainer";
import FeatureButtonGroup from "./FeatureButtonGroup";
import { NineCatPlayerCardList, NineCatPlayerCardListProps } from "./NineCatPlayerCardList";

const orderPlayersByNineCatStatDesc = (players: NineCatPlayerDetailVO[], orderVal: NINE_CAT_STAT) => (
    _.orderBy(players, orderVal, "desc")
);

export function CreateNineCatTeamPage() {
    const { slateId } = useParams();
    const players = useQuerySlatePlayers(slateId);

    const {
        slateDetail,
        handleOpenPlayerPopup,
        playerState: [openPlayerPopup, setOpenPlayerPopup]
    } = useNineCatContext();

    const {
        focusedPlayerId,
        focusedStat,
        statToPlayerIdMap,
        handleToggleFocusPlayer,
        handleClearStatPlayerIdMap,
        handleResetFocus,
        setStatToPlayerIdMap,
    } = useCreateSlateTeamContext();

    const {
        form,
        orderVal,
        handleToggleFilterOptions,
        filterPlayerBySearchVal,
        filterPlayerByFilterVal
    } = useCreateTeamFeatureButtonGroup();

    const orderedPlayers = orderPlayersByNineCatStatDesc(players.data, orderVal);
    const handledPlayerList: NineCatPlayerCardListProps["players"] = orderedPlayers
        .filter(filterPlayerBySearchVal)
        .filter(filterPlayerByFilterVal)
        .map((playerDetail, i) => ({
            sortSeq: `${orderVal}#${i + 1}`,
            selectedStat: statToPlayerIdMap[playerDetail.id] as NINE_CAT_STAT,
            focused: focusedPlayerId === playerDetail.id && !focusedStat,
            statForChosen: focusedStat,
            playerDetail,
        }));

    const getFilledPlayerInfoList = (): FilledPlayerInfoVO[] => {
        return players.data
            .filter(player => {
                const chosenStat = statToPlayerIdMap[player.id];
                return !!chosenStat;
            })
            .map(player => ({
                stat: statToPlayerIdMap[player.id] as NINE_CAT_STAT,
                playerInfo: player
            }));
    };

    const filledPlayerInfoList = getFilledPlayerInfoList();

    const handleResetFocusByBlur: MouseEventHandler<HTMLDivElement> = e => {
        e.stopPropagation();
        handleResetFocus();
    };

    return (
        <div>
            <div
                className={"tw-flex tw-flex-col tw-h-lvh tw-bg-gray-50"}
                onClick={handleResetFocusByBlur}
            >
                <AutoChooseSlateTeamPlayersButton players={orderedPlayers} onClick={setStatToPlayerIdMap} />
                <CreateNineCatTeamTopBar slate={slateDetail} />
                <div className="tw-max-w-[35.8rem] tw-m-auto tw-relative tw-z-1">
                    <div className={"tw-flex tw-flex-col tw-gap-3 tw-py-3"}>
                        <MyNineCatContests />
                        <MyNineCatTeams />
                    </div>
                    <CreateSlateTeamPanelContainer
                        filledPlayerInfoList={filledPlayerInfoList}
                        onClearAll={handleClearStatPlayerIdMap}
                    />
                </div>
                <div className={"tw-relative tw-z-1 tw-bg-white tw-py-4"}>
                    <FeatureButtonGroup
                        form={form}
                        onToggleFilterOptions={handleToggleFilterOptions} />
                </div>
                <div className={"tw-relative tw-z-1 tw-h-full tw-bg-white tw-py-2 tw-overflow-auto"}
                     onClick={handleResetFocusByBlur}>
                    <NineCatPlayerCardList
                        players={handledPlayerList}
                        onOpenPlayerInfo={handleOpenPlayerPopup}
                        onFocusStatPlayer={handleToggleFocusPlayer}
                    />
                </div>
            </div>
            <NineCatPlayerPopup open={openPlayerPopup} onOpenChange={setOpenPlayerPopup} />
        </div>
    );
}
