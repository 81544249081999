import { ApiExecuteContext } from "apis/ApiContext";
import { createTeam } from "apis/team/TeamApi";
import NonStarterPanel from "domain/team/NonStarterPanel";
import { TeamContext, useOperateTeam } from "domain/team/OperateTeam";
import { getBeEditedTeamSpecialPlayer } from "domain/team/OperateTeamHelper";
import { TeamFlowWrapper } from "domain/team/OperateTeamStyle";
import { createErrorObject, isForbiddenByKYCRestriction, isMatchStarted } from "helpers/ErrorHelper";
import { useCompleteYourKycDialog, useCreateTeamSuccessDialog } from "hooks/useDialog";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { updateTeamPreviewShow } from "store/actions/Actions";
import { addErrorPopup } from "store/actions/AlertActions";
import { MatchVO } from "types/match/Match";
import { SPORT } from "types/sports/Sport";
import { MyTeamVO } from "types/team/MyTeam";

interface CreateTeamFlowProps {
    sport: SPORT;
    match: MatchVO;
    onNavbarBackClick: () => void;
    onSuccess: (callback?) => void;
    handleTimeout?: () => void;
    myTeams: MyTeamVO[];
    copyTeam: MyTeamVO | null;
    scrollSpyRootSelector?: string;
}


const CreateTeamFlow: React.FC<CreateTeamFlowProps> = ({
                                                           sport,
                                                           match,
                                                           onNavbarBackClick,
                                                           onSuccess,
                                                           handleTimeout = () => {},
                                                           myTeams,
                                                           copyTeam,
                                                           scrollSpyRootSelector
                                                       }) => {
    const dispatch = useDispatch();
    const {
        toggleLoading,
        OperateTeamBody: CreateTeamBody,
        allPlayers,
        setAllPlayers,
        selectedPlayers,
        specialPlayer,
        setSpecialPlayer,
        showLeavingWarningPopup,
        backToChoosePlayer,
        backToChooseSpecialPlayer,
        nextToChooseSpecialPlayer,
        showPreview,
        previewToggle,
        restCreditCent,
        isStartingLineupDecided
    } = useOperateTeam({ sport, match, onNavbarBackClick, beOperatedTeam: copyTeam });
    const handleCompleteYourKycDialog = useCompleteYourKycDialog();

    const apiExecutor = useContext(ApiExecuteContext);
    const intl = useIntl();
    const createTeamSuccessPopup = useCreateTeamSuccessDialog();

    const handleCreateTeam = useCallback(() => {
        toggleLoading(true);

        apiExecutor(
            createTeam(sport, {
                matchId: match.matchId,
                playerIds: selectedPlayers.map(player => player.id),
                captain: specialPlayer["captain"],
                viceCaptain: specialPlayer["viceCaptain"]
            }),
            {
                onSuccess: () => {
                    onSuccess();
                    createTeamSuccessPopup();
                    dispatch(updateTeamPreviewShow(false));
                },
                onFail: (_error) => {
                    if (isMatchStarted(_error)) {
                        handleTimeout();
                        return;
                    }
                    if (isForbiddenByKYCRestriction(_error)) {
                        handleCompleteYourKycDialog();
                        return;
                    }

                    dispatch(addErrorPopup(createErrorObject(_error)));
                },
                onFinally: () => {
                    toggleLoading(false);
                }
            }
        );
    }, [apiExecutor, handleTimeout, toggleLoading, match, onSuccess, selectedPlayers, specialPlayer, sport, createTeamSuccessPopup, dispatch]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [CreateTeamBody]);

    const onBeforeChooseSpecialPlayer = useCallback(() => {
        if (!copyTeam) return;

        setSpecialPlayer(getBeEditedTeamSpecialPlayer(copyTeam.captain.id, copyTeam.viceCaptain.id, selectedPlayers));
    }, [setSpecialPlayer, copyTeam, selectedPlayers]);

    const providedValue = useMemo(() => ({
        sport,
        match,
        allPlayers,
        setAllPlayers,
        selectedPlayers,
        specialPlayer,
        setSpecialPlayer,
        onSuccess,
        navbarTitle: intl.formatMessage({ id: "label_create_team" }),
        handleSubmit: handleCreateTeam,
        onBeforeChooseSpecialPlayer,
        showLeavingWarningPopup,
        backToChoosePlayer,
        backToChooseSpecialPlayer,
        nextToChooseSpecialPlayer,
        showPreview,
        previewToggle,
        handleTimeout,
        restCreditCent,
        myTeams,
        scrollSpyRootSelector,
        isStartingLineupDecided
    }), [
        sport,
        match,
        allPlayers,
        selectedPlayers,
        setAllPlayers,
        specialPlayer,
        setSpecialPlayer,
        showLeavingWarningPopup,
        onSuccess,
        intl,
        handleCreateTeam,
        backToChoosePlayer,
        backToChooseSpecialPlayer,
        nextToChooseSpecialPlayer,
        showPreview,
        previewToggle,
        handleTimeout,
        restCreditCent,
        myTeams,
        onBeforeChooseSpecialPlayer,
        scrollSpyRootSelector,
        isStartingLineupDecided
    ]);

    return <>
        <TeamContext.Provider value={providedValue}>
            <TeamFlowWrapper>
                <CreateTeamBody />
            </TeamFlowWrapper>
            <NonStarterPanel beOperatedTeam={copyTeam} />
        </TeamContext.Provider>
    </>;
};

export default CreateTeamFlow;
