import { getSentenceWithComma } from "helpers/string/StringHelper";
import React from "react";
import { Tooltip } from "react-bootstrap";

export const getTournamentInfoTooltip = (matchDetail) => {
    const { subtitle, venue, tournamentPeriodStr, tournament } = matchDetail;
    return <Tooltip id="tooltip-bottom">
        <div className="fz-sf-regular-caption-1">
            {getSentenceWithComma(subtitle, venue, tournamentPeriodStr, tournament)}
        </div>
    </Tooltip>;
};
