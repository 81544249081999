import { isMatchKickoff, isMatchLive } from "helpers/match/MatchHelper";
import React from "react";
import {
    JoinableNavbarWrapper,
    MatchInfoWrapper,
    SquadCode,
    VSText
} from "../../../../components/container/navbar/NavbarStyle";
import { KickoffCountdown } from "../../../home/components/upcoming/UpcomingMatches";

export const isBasketballSquadCodeHighlighted = (squadCode, matchDetail) => {
    const { winningSquadCode, matchStatus } = matchDetail;
    const isLive = isMatchLive(matchStatus);
    const isWinner = winningSquadCode === squadCode;
    return isLive || isWinner;
};

const BasketballNavbarMatchWithScore = ({ match }) => {
    return <JoinableNavbarWrapper>
        <MatchInfoWrapper>
            <SquadCode $highlight={isBasketballSquadCodeHighlighted(match.squadACode, match)}>
                {match.squadACode}({match.squadAScores})
            </SquadCode>
            <VSText>VS</VSText>
            <SquadCode $highlight={isBasketballSquadCodeHighlighted(match.squadBCode, match)}>
                {match.squadBCode}({match.squadBScores})
            </SquadCode>
        </MatchInfoWrapper>
        {
            isMatchKickoff(match.matchStatus) && <div className="ms-2 text-sys-danger-1">
                <KickoffCountdown matchStartsAt={match.matchStartsAt} handleTimeout={() => {}} />
            </div>
        }
    </JoinableNavbarWrapper>;
};

export default BasketballNavbarMatchWithScore;
