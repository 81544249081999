import ErrorContent from "components/error/ErrorContent";
import { ErrorContentWrapper } from "domain/chat/ChatRoomStyle";
import React from "react";

interface ChatRoomErrorProps {
    error: any
}

export const ChatRoomError: React.FC<ChatRoomErrorProps> = ({ error }) => {
    return <ErrorContentWrapper>
        <ErrorContent error={error}
                      title={"fz-sf-bold-title-3"}
        />
    </ErrorContentWrapper>;
};
