import React from "react";
import { Placeholder } from "react-bootstrap";
import MyEmptyMatch from "components/empty/MyEmptyMatch";
import { MatchesSkeleton } from "components/skeleton/MatchesSkeleton";
import { TextPlaceHolder } from "components/skeleton/SkeletonStyle";
import { useMyMatch } from "domain/match/components/providers/MyMatchProvider";
import MyMatchCompleted from "domain/match/components/myMatches/MyMatchCompleted";
import MyMatchLive from "domain/match/components/myMatches/MyMatchLive";
import MyMatchSportFilter from "domain/match/components/myMatches/MyMatchSportFilter";
import MyMatchUpcoming from "domain/match/components/myMatches/MyMatchUpcoming";
import { NoLabelSelected } from "components/empty/NoLabelSelected";
import { FormattedMessage } from "react-intl";

interface MyMatchFantasySportsProps {
    getMyMatches: () => void,
    isLoading?: boolean
}

const MyMatchFantasySports: React.FC<MyMatchFantasySportsProps> = ({
                                                                       getMyMatches,
                                                                       isLoading
                                                                   }) => {
    const { filterSport, myMatches } = useMyMatch();
    const { live, upcoming, completed } = myMatches.group(filterSport);

    if (isLoading) {
        return (
            <div className="tw-px-4 tw-py-6 tw-flex tw-flex-col tw-gap-2" data-testid="my-match-loading">
                <div className="fz-sf-bold-title-3 text-text-primary-1 tw-pt-2">
                    <Placeholder animation="glow">
                        <TextPlaceHolder />
                    </Placeholder>
                </div>
                <MatchesSkeleton />
            </div>
        );
    }

    if (!filterSport?.length) {
        return <>
            <MyMatchSportFilter />
            <NoLabelSelected
                textClassName="tw-font-light"
                message={<FormattedMessage
                    id="my_games_page_description_not_selected_tab"
                    values={{
                        data: <span className="tw-font-semibold">
                            <FormattedMessage id="fantasy_sport_label_sport" />
                        </span>
                    }} />
                }
            />
        </>;
    }

    if (!myMatches?.select(filterSport).length) {
        return (
            <>
                <MyMatchSportFilter />
                <MyEmptyMatch />
            </>
        );
    }

    return (
        <>
            <MyMatchSportFilter />
            <div>
                <MyMatchLive matches={live} handleTimeout={getMyMatches} />
                <MyMatchUpcoming
                    matches={upcoming}
                    handleTimeout={getMyMatches}
                />
                <MyMatchCompleted matches={completed} />
            </div>
        </>
    );
};

export default MyMatchFantasySports;
