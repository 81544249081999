export enum AP_LEVEL {
    BEGINNER = "BEGINNER",
    STEEL = "STEEL",
    ORDINARY = "ORDINARY",
    SILVER = "SILVER",
    GOLD = "GOLD",
    PLATINUM = "PLATINUM",
    EMERALD = "EMERALD",
    DIAMOND = "DIAMOND",
    MASTER = "MASTER",
    ULTIMATE_MASTER = "ULTIMATE_MASTER"
}