import { ContentWrapper, ErrorMessage, ImgContainer, InputGroupWrapper, Label } from "designToken/textFields/Styles";
import { hasError } from "designToken/textFields/TextFields.helper";
import { isEmpty } from "lodash";
import React from "react";
import { Dropdown } from "react-bootstrap";

export function SelectionDropdown({ items, disabledItemIds, activeItem, onItemClick, className = "" }) {
    const title = !isEmpty(activeItem) ? activeItem.value : "Select";

    return <Dropdown>
        <Dropdown.Toggle className="fz-sf-regular-body-1" variant="">
            <span>{title}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu className={className}>
            {
                items.map((item) => {
                    const {
                        startText,
                        endText,
                        startImg,
                        endImg,
                        id
                    } = item;

                    const disabled = disabledItemIds && disabledItemIds.includes(id);
                    const active = !isEmpty(activeItem) && activeItem.id === id;

                    return <Dropdown.Item key={id}
                                          active={active}
                                          disabled={disabled}
                                          onClick={() => onItemClick(item)}
                    >
                        <ContentWrapper>
                            {
                                startImg?.front && <ImgContainer>
                                    <img src={startImg.front} alt={startText} />
                                </ImgContainer>
                            }
                            {
                                startText && <span>{startText}</span>
                            }
                            {
                                startImg?.end && <ImgContainer>
                                    <img src={startImg.end} alt={startText} />
                                </ImgContainer>
                            }
                        </ContentWrapper>
                        <ContentWrapper>
                            {
                                endImg?.front && <ImgContainer>
                                    <img src={endImg.front} alt={endText} />
                                </ImgContainer>
                            }
                            {
                                endText && <span>{endText}</span>
                            }
                            {
                                endImg?.end && <ImgContainer>
                                    <img src={endImg.end} alt={startText} />
                                </ImgContainer>
                            }
                        </ContentWrapper>
                    </Dropdown.Item>;
                })
            }
        </Dropdown.Menu>
    </Dropdown>;
}

const Select: React.FC<SelectProps> = ({
                                           label = "",
                                           items,
                                           onItemClick,
                                           activeItem,
                                           disabledItemIds,
                                           errorMessage = "",
                                       }) => {


    return (
        <InputGroupWrapper className={hasError(errorMessage)}>
            <Label>{label}</Label>
            <SelectionDropdown items={items}
                               disabledItemIds={disabledItemIds}
                               activeItem={activeItem}
                               onItemClick={onItemClick}
                               className="w-100"
            />
            {
                errorMessage
                && <ErrorMessage>{errorMessage}</ErrorMessage>
            }
        </InputGroupWrapper>
    );
};

interface ItemProps {
    id: string | number,
    startImg?: {
        front: string,
        end: string
    },
    startText?: string,
    endImg?: {
        front: string,
        end: string
    },
    endText?: string,
}

export interface ActiveItemProps {
    id: string | number,
    value: string
}


interface SelectProps {
    label: string,
    items: ItemProps[],
    activeItem?: ActiveItemProps,
    disabledItemIds?: string[] | number[],
    onItemClick: (item: ItemProps) => void,
    errorMessage?: string,
}


export default Select;
