import { noLabelSelected } from "helpers/IconHelper";
import React from "react";
import { cn } from "helpers/cn";

interface NoTournamentSelectedProps {
    textClassName?: string;
    message?: React.ReactNode;
}

export const NoLabelSelected: React.FC<NoTournamentSelectedProps> = ({ message, textClassName }) => {

    return <div className={"tw-flex tw-flex-col tw-items-center tw-justify-center"}>
        <div className={"tw-p-4 tw-pt-[56px]"}>
            <img className={"tw-max-w-[72px]"} alt={"no-tournament-selected"} src={noLabelSelected} />
        </div>
        <p className={cn("tw-text-body-2", textClassName)}>
            {message}
        </p>
    </div>;
};
