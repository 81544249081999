import { Dot } from "./TeamPreviewStyle";
import { FormattedMessage } from "react-intl";
import React from "react";

export const TeamPreviewSubstitute = () => {
    return <div data-testid="team-preview-substitute">
        <Dot className="d-inline-block text-dark-primary bg-sys-danger dot" />
        <FormattedMessage id="player_substitute_or_unannounced" />
    </div>;
};
