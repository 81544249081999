import styled from "styled-components";

export const StyledWalletInfo = styled.div.attrs({
    className: "d-flex flex-column justify-content-around border-0 tw-bg-grey-0",
})`
  margin-bottom: 1.6rem;
  border-radius: 0.8rem;
`;

export const StyledWalletOperation = styled.div.attrs({
    className: "p-4 pb-3 tw-shadow-wallet-operation",
})`
  border-radius: 0.8rem;
`;

export const StyledWalletBalance = styled.div.attrs({
    className: "px-4 py-3",
})``;

export const StyledWalletAction = styled.div.attrs({
    className: "d-flex justify-content-around align-items-start",
})``;

export const StyledDivider = styled.div.attrs({
    className: "w-full tw-bg-system-labelLightQuaternary",
})`
  height: 0.1rem;
  margin: 0.8rem 0 1.2rem 0;
`;

export const StyledRegisterLabel = styled.span.attrs({
    className: "fz-sf-regular-footnote text-light-secondary",
})``;

export const StyledRegisterDate = styled.span.attrs({
    className: "fz-sf-bold-footnote text-primary-2",
})``;
