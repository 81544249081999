import React from "react";
import { cn } from "helpers/cn";
import { MatchResultPlayerVo } from "data/vo/player/matchResult/MatchResultPlayersVo";
import { PlayerInfo } from "domain/result/detail/components/PlayerStats/PlayerInfo";
import { SPORT } from "types/sports/Sport";

interface PlayerCardProps {
    sport: SPORT;
    player: MatchResultPlayerVo;
    onClickAvatar: () => void;
}

export const PlayerCard: React.FC<PlayerCardProps> = ({ onClickAvatar, player, sport }) => {

    return <div
        data-testid="player-card"
        className={cn(
            "tw-w-full tw-h-[5.4rem] tw-bg-white tw-border-grey-150 tw-border-dashed tw-border-b-[1px]",
            "tw-flex tw-items-center tw-justify-between tw-p-[0.4rem_1.8rem_0_0.8rem]",
        )}
    >
        <PlayerInfo sport={sport} player={player} onClick={onClickAvatar} />

        <div className="tw-text-subtitle-1 tw-font-bold">
            {player.point}
        </div>

    </div>;
};

