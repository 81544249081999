export enum FONT {
    h1 = "tw-text-h1",
    h2 = "tw-text-h2",
    h3 = "tw-text-h3",
    h4 = "tw-text-h4",
    h5 = "tw-text-h5",
    h6 = "tw-text-h6",
    subtitle1 = "tw-text-subtitle-1",
    subtitle2 = "tw-text-subtitle-2",
    body1 = "tw-text-body-1",
    body2 = "tw-text-body-2",
    caption1 = "tw-text-caption-1",
    caption2 = "tw-text-caption-2",
    overline = "tw-text-overline",
}
