import { getShareContestUrl } from "apis/ApiUrlConfig";
import { ajax } from "apis/ApiUtils";
import {
    fetchContestDetail,
    fetchFeaturedContests,
    fetchFeaturedContestsDaily,
    fetchMyContestJoinedTeam
} from "data/api/contest/ContestApi";
import { ContestDetailVo } from "data/vo/contest/ContestDetailVo";
import { FeaturedContestVo } from "data/vo/contest/FeaturedContestVo";
import { chain } from "lodash";
import store from "store";
import { updateSelectTeamPanelData } from "store/actions/Actions";
import { SPORT } from "types/sports/Sport";
import { LeaderboardTeamDto } from "../../dto/leaderboard/LeaderboardTeamDto";
import { LeaderboardTeamVo } from "../../vo/leaderboard/LeaderboardTeamVo";

export class ContestRepository {
    public async getFeaturedContests(sport: SPORT) {
        const featuredContestDtos = await fetchFeaturedContests(sport);

        return chain(featuredContestDtos)
            .orderBy("matchStartsAt")
            .map(featuredContestDto => new FeaturedContestVo(featuredContestDto))
            .value();
    }

    public async getFeaturedContestsDaily(sport: SPORT) {
        const featuredContestDtos = await fetchFeaturedContestsDaily(sport);

        return chain(featuredContestDtos)
            .orderBy("matchStartsAt")
            .map(featuredContestDto => new FeaturedContestVo(featuredContestDto))
            .value();
    }

    public async shareContest({ contestId }: { contestId: number }) {
        await ajax({ url: getShareContestUrl(contestId), method: "POST" });
    }

    public async getContestDetail(contestCode: string): Promise<ContestDetailVo> {
        const res = await fetchContestDetail(contestCode);
        const myJoinedTeam = await fetchMyContestJoinedTeam(contestCode);
        const contestDetailVo = new ContestDetailVo(res);

        store.dispatch(updateSelectTeamPanelData({
            contest: contestDetailVo,
            joinedTeamIds: myJoinedTeam.map(item => item.teamId)
        }));

        return contestDetailVo;
    }

    public getMyContestJoinedTeam = async (contestCode: string): Promise<LeaderboardTeamVo[]> => {
        const response = await fetchMyContestJoinedTeam(contestCode);

        return chain(response)
            .sortBy('scoreCent')
            .reverse()
            .map((team: LeaderboardTeamDto) => new LeaderboardTeamVo(team))
            .value();
    };
}
