import { ApiError } from "apis/Api";
import {
    fetchTripleAPaymentMethod,
    initializeCryptocurrencyPayment,
    initializeCryptocurrencyPaymentRequest
} from "apis/deposit/DepositApi";
import { useLoadingToggle } from "components/loading/Loading";
import { cryptocurrencyDetails, toSelectedTeamIdsString } from "domain/coin/AddCoinHelper";
import PaymentNoticeModal from "domain/coin/component/notice/PaymentNoticeModal";
import { PaymentMethods } from "domain/coin/pages/SelectPaymentMethod/components/PaymentMethods";
import { createErrorObject } from "helpers/ErrorHelper";
import { setQuery } from "helpers/queryString/set/SetQuery";
import useFantasyCommand from "hooks/useFantasyCommand";
import useFantasyHistory from "hooks/useFantasyHistory";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import { ERROR_HANDLER_FLOW } from "hooks/useHandleApiError";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { addErrorPopup } from "store/actions/AlertActions";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";

interface TripleAPaymentMethodsProps {
    productId: number;
}

export const TripleAPaymentMethods: React.FC<TripleAPaymentMethodsProps> = ({ productId }) => {
    const history = useFantasyHistory();
    const dispatch = useDispatch();
    const toggleLoading = useLoadingToggle();

    const { data: methods } = useFantasyQuery<string[]>([QUERY_KEY.PAYMENT_METHOD], async () => await fetchTripleAPaymentMethod(), {
        options: {
            placeholderData: []
        }
    });
    const initializePayment = useFantasyCommand<initializeCryptocurrencyPaymentRequest, string>(initializeCryptocurrencyPayment, {
        onFail: () => ERROR_HANDLER_FLOW.STOP,
    });

    const [method, setMethod] = useState("");
    const [openWarningModal, setOpenWarningModal] = useState(false);

    const onMethodClick = (method) => {
        setMethod(method);
        setOpenWarningModal(true);
    };

    const onConfirmClick = async () => {
        toggleLoading(true);
        try {
            const response = await initializePayment.mutateAsync({
                depositProductId: productId,
                cryptocurrency: method,
                query: setQuery({
                    ...history.location?.state,
                    addDepositFlowFinishedGoTo: history.location?.state?.addDepositFlowFinished ?? ROUTER_PATH_CONFIG.home,
                    selectedTeamsIds: toSelectedTeamIdsString(
                        history.location?.state?.selectedTeamsIds,
                    ),
                })
            });


            window.location.href = response;
        } catch (error) {
            dispatch(addErrorPopup(createErrorObject(error as ApiError)));
        }
        toggleLoading(false);
    };

    return <>
        <PaymentMethods title={<FormattedMessage id="select_payment_page_select_method_label_cryptocurrency" />}
                        methods={methods!}
                        paymentMethodDetails={cryptocurrencyDetails}
                        onMethodClick={onMethodClick}
        />

        <PaymentNoticeModal open={openWarningModal}
                            setOpen={setOpenWarningModal}
                            methods={methods}
                            onConfirm={onConfirmClick}
        />
    </>;
};
