import clsx from "clsx";
import { Callback } from "Common";
import { multiplyByOneHundred } from "helpers/number/NumberHelper";
import React, { ReactNode, useMemo, useRef, useState } from "react";
import { Progress, SwipeBall, SwipeButtonText, SwipeButtonWrapper } from "./SwipeButtonStyle";

interface SwipeButtonProps {
    disabled: boolean;
    children: ReactNode;
    onSuccess: Callback;
}

const WRAPPER_PADDING = 0.4;
const BALL_DIAMETER = 4.8;

const SwipeButton: React.FC<SwipeButtonProps> = ({ disabled, children, onSuccess }) => {
    const buttonWrapperRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLDivElement>(null);
    const [moveX, setMoveX] = useState(0);

    const [isMouseDown, setIsMouseDown] = useState(false);

    const swipeBallClassName = useMemo(() => {
        if (disabled) {
            return "tw-bg-grey-100";
        }

        if (moveX > 0) {
            return "tw-bg-primary-150";
        }

        return "tw-bg-primary-800";
    }, [moveX, disabled]);

    const onDown = () => {
        if (disabled) return;
        setIsMouseDown(true);
    };

    const onMove = (event) => {
        if (!buttonRef.current) return;
        if (!buttonWrapperRef.current) return;

        if (!isMouseDown) return;

        const buttonWidth = buttonRef.current.getBoundingClientRect().width;
        const { left: wrapperLeft, width: wrapperWidth } = buttonWrapperRef.current.getBoundingClientRect();

        const clientX = (event.clientX || event.touches[0].clientX) - wrapperLeft + (buttonWidth / 2);

        setMoveX(Math.min(multiplyByOneHundred(clientX / wrapperWidth), 100));
    };
    const onUp = () => {
        if (!isMouseDown) return;

        setIsMouseDown(false);
        if (moveX >= 100) {
            onSuccess();
        } else {
            setMoveX(0);
        }
    };

    return (
        <SwipeButtonWrapper
            className={clsx({
                "tw-bg-grey-50": disabled,
                "tw-bg-primary-500": !disabled,
            })}
            ref={buttonWrapperRef}
            onMouseMove={onMove}
            onMouseLeave={onUp}>
            <SwipeButtonText className={clsx({
                "tw-text-grey-300": disabled,
                "tw-text-grey-0": !disabled,
            })}>
                {children}
            </SwipeButtonText>
            <Progress style={{
                width: `${moveX}%`
            }} />
            <SwipeBall ref={buttonRef}
                       className={swipeBallClassName}
                       style={{
                           left: moveX ? `calc(${moveX}% - ${BALL_DIAMETER + WRAPPER_PADDING}rem)` : `${WRAPPER_PADDING}rem`
                       }}
                       onMouseDown={onDown}
                       onMouseUp={onUp}
                       onTouchStart={onDown}
                       onTouchMove={onMove}
                       onTouchEnd={onUp}
            />
        </SwipeButtonWrapper>
    );
};

export default SwipeButton;
