import { SeasonRepository } from "data/repository/season/SeasonRepository";
import { useSportSettings } from "domain/sport/useSportSettings";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import { useSelector } from "react-redux";
import { RootStoreState } from "store/reducer";
import { SPORT } from "types/sports/Sport";


// TODO: combine with useSportSettings after remove fetch season
export const useSportConfig = () => {
    const sportTab = useSportSettings();
    const seasonRepository = new SeasonRepository();
    const { SettingsState } = useSelector((store: RootStoreState) => store);

    const { mpblEnabled } = SettingsState;

    const { data: basketballCurrentSeason } = useFantasyQuery(
        [QUERY_KEY.BASKETBALL_CURRENT_SEASON], () => seasonRepository.getBasketballCurrent(),
        {
            options: {
                staleTime: 60 * 60 * 1000,
                enabled: !mpblEnabled
            }
        });


    return sportTab.map(sport => {
        const isBasketball = sport.key === SPORT.BASKETBALL;

        if (mpblEnabled) {
            return {
                ...sport,
                season: {
                    inProgress: true,
                    nextStartDate: null
                }
            };
        }

        return {
            ...sport,
            ...isBasketball
                ? {
                    season: {
                        inProgress: basketballCurrentSeason?.inProgress ?? false,
                        nextStartDate: basketballCurrentSeason?.nextStartDate ?? null
                    }
                }
                : {
                    season: {
                        inProgress: true,
                        nextStartDate: null
                    }
                }
        };
    });
};
