import { SPORT } from "types/sports/Sport";
import { ajax } from "apis/ApiUtils";
import { MatchResultPlayerDto, PlayerInfoDto } from "data/dto/player/PlayerInfoDto";
import { BasketballPlayerNewsDto } from "data/vo/player/news/BasketballPlayerNewsVo";

export const fetchPlayerInfo = async (sport: SPORT, matchId: string, playerId: string): Promise<PlayerInfoDto> => {
    const res = await ajax<PlayerInfoDto>({
        url: `/${sport}/match/${matchId}/player/${playerId}`
    });
    return res.data.data;
};
export const fetchBasketPlayerNews = async (playerId: string): Promise<BasketballPlayerNewsDto[]> => {
    const res = await ajax<BasketballPlayerNewsDto[]>({
        url: `/basketball/player/${playerId}/news`
    });
    return res.data.data;
};
export const fetchMatchResultPlayers = async (sport: SPORT, matchId: string): Promise<MatchResultPlayerDto[]> => {
    const response = await ajax<MatchResultPlayerDto[]>({
        url: `/${sport}/match/${matchId}/result/players`
    });

    return response.data.data;
};
