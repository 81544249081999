import { SquadDto } from "apis/player/PlayerApi";
import {
    INJURY_PLAYING_CHANCE,
    INJURY_PLAYING_CHANCE_ABBR
} from "data/vo/player/profile/BasketballPlayerInfoProfileVo";
import { getSportConfig } from "domain/SportConfig";
import { CAPTAIN_WEIGHTED_RATE, PLAYER_TYPE, VICE_CAPTAIN_WEIGHTED_RATE } from "domain/team/OperateTeamHelper";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import { checkPlayerIsInjured } from "helpers/PlayerHelpers";
import { isEmpty } from "lodash";
import { ROLE, SPORT } from "types/sports/Sport";

export enum LINEUP {
    STARTING_LINEUP = "STARTING_LINEUP",
    SUBSTITUTE = "SUBSTITUTE",
    TO_BE_DECIDED = "TO_BE_DECIDED",
}

abstract class Player {
    private readonly _sport: SPORT;
    private readonly _id: string;
    private readonly _firstName: string;
    private readonly _middleName: string;
    private readonly _lastName: string;
    private readonly _avatar: string;
    private readonly _creditCent: number;
    private readonly _role: ROLE;
    private readonly _lineup: LINEUP;

    protected constructor(player) {
        this._sport = player.sport;
        this._id = player.id;
        this._firstName = player.firstName;
        this._middleName = player.middleName;
        this._lastName = player.lastName;
        this._avatar = player.avatar;
        this._creditCent = player.creditCent;
        this._role = player.role;
        this._lineup = player.lineup || LINEUP.TO_BE_DECIDED;
        this._type = player.type || PLAYER_TYPE.GENERAL;
    }

    private _type: PLAYER_TYPE;

    get type(): PLAYER_TYPE {
        return this._type;
    }

    get lineup(): LINEUP {
        return this._lineup;
    }

    get role(): ROLE {
        return this._role;
    }

    get credit(): number {
        return divideByOneHundred(this._creditCent);
    }

    get creditCent(): number {
        return this._creditCent;
    }

    get avatar(): string {
        return this._avatar;
    }

    get id(): string {
        return this._id;
    }

    get sport(): SPORT {
        return this._sport;
    }

    get firstName(): string {
        return this._firstName;
    }

    get middleName(): string {
        return this._middleName;
    }

    get lastName(): string {
        return this._lastName;
    }

    public get fullName(): string {
        return `${this._firstName} ${this._middleName} ${this._lastName}`;
    }

    public get name(): string {
        const result: string[] = [];

        if (!isEmpty(this._firstName)) {
            result.push(
                isEmpty(this._middleName) && isEmpty(this._lastName)
                    ? this._firstName
                    : `${this._firstName.slice(0, 1)}.`
            );
        }

        if (!isEmpty(this._middleName)) {
            result.push(this._middleName);
        }

        if (!isEmpty(this._lastName)) {
            result.push(this._lastName);
        }

        return result.join(" ");
    }

    public get isCaptain(): boolean {
        return this._type === PLAYER_TYPE.CAPTAIN;
    }

    public get isViceCaptain(): boolean {
        return this._type === PLAYER_TYPE.VICE_CAPTAIN;
    }

    public get roleAbbr(): string {
        return getSportConfig(this.sport).team.getRoleAbbr(this.role);
    }

    public setGeneral() {
        this._type = PLAYER_TYPE.GENERAL;
    }

    public setCaptain() {
        this._type = PLAYER_TYPE.CAPTAIN;
    }

    public setViceCaptain() {
        this._type = PLAYER_TYPE.VICE_CAPTAIN;
    }
}

export class SquadPlayer extends Player {
    private readonly _isHome: boolean;
    private readonly _squadCode: string;
    private readonly _squadKey: string;
    private readonly _squadLogoUrl: string;
    private readonly _scoreCent: number;
    private readonly _pointAvgCent: number;
    private readonly _playerSelectionRate: number;
    private readonly _captainSelectionRate: number;
    private readonly _viceCaptainSelectionRate: number;
    private readonly _injuryPlayingChance: INJURY_PLAYING_CHANCE;

    constructor(player) {
        super(player);
        this._isHome = player.isHome;
        this._squadCode = player.squadCode;
        this._squadLogoUrl = player.squadLogoUrl;
        this._squadKey = player.squadKey;

        this._scoreCent = player.scoreCent;
        this._pointAvgCent = player.pointAvgCent;
        this._playerSelectionRate = player.playerSelectionRate;
        this._captainSelectionRate = player.captainSelectionRate;
        this._viceCaptainSelectionRate = player.viceCaptainSelectionRate;
        this._injuryPlayingChance = player.injuryPlayingChance;
    }

    get viceCaptainSelectionRate(): number {
        return this._viceCaptainSelectionRate;
    }

    get captainSelectionRate(): number {
        return this._captainSelectionRate;
    }

    get playerSelectionRate(): number {
        return this._playerSelectionRate;
    }

    get viceCaptainSelectionRatePercent(): number {
        return divideByOneHundred(this._viceCaptainSelectionRate);
    }

    get captainSelectionRatePercent(): number {
        return divideByOneHundred(this._captainSelectionRate);
    }

    get playerSelectionRatePercent(): number {
        return divideByOneHundred(this._playerSelectionRate);
    }

    get pointAvg(): number {
        return divideByOneHundred(this._pointAvgCent);
    }

    get pointAvgCent(): number {
        return this._pointAvgCent;
    }

    get score(): number {
        return divideByOneHundred(this._scoreCent);
    }

    get squadLogoUrl(): string {
        return this._squadLogoUrl;
    }

    get squadCode(): string {
        return this._squadCode;
    }

    get squadKey(): string {
        return this._squadKey;
    }

    get isHome(): boolean {
        return this._isHome;
    }

    get injuryPlayingChance(): INJURY_PLAYING_CHANCE {
        return this._injuryPlayingChance;
    }

    get injuryPlayingChanceAbbr(): string {
        return INJURY_PLAYING_CHANCE_ABBR[this.injuryPlayingChance] || "";
    }

    get isInjured(): boolean {
        return checkPlayerIsInjured(this.injuryPlayingChance);
    }
}

interface Selectable {
    isSelected: boolean;
    isDisabled: boolean;

    setSelected(bool: boolean): void;

    toggleSelected(): void;

    setDisabled(bool: boolean): void;
}

export class SelectablePlayer extends SquadPlayer implements Selectable {
    constructor(player) {
        super(player);
        this._isSelected = player.isSelected;
        this._isDisabled = player.isDisabled;
    }

    private _isSelected: boolean;

    public get isSelected(): boolean {
        return this._isSelected;
    }

    private _isDisabled: boolean;

    public get isDisabled(): boolean {
        return this._isDisabled;
    }

    public static fromSquadAllJson(SquadAll: SquadDto[], match, sport): SelectablePlayer[] {
        return SquadAll.reduce((selectablePlayers: SelectablePlayer[], squad) => {
            return selectablePlayers.concat(squad.players.map(player => {
                return new SelectablePlayer({
                    ...player,
                    sport,
                    squadLogoUrl: squad.code === match.squadACode ? match.squadALogoUrl : match.squadBLogoUrl,
                    squadCode: squad.code,
                    squadKey: squad.key,
                    isHome: squad.code === match.squadACode,
                    isSelected: false,
                    isDisabled: false
                });
            }));
        }, []);
    }

    public setSelected(isSelected): void {
        this._isSelected = isSelected;
    }

    public toggleSelected(): void {
        this._isSelected = !this._isSelected;
    }

    public setDisabled(isDisabled): void {
        this._isDisabled = isDisabled;
    }
}

interface StatsPoint {
    point: number;
}

export class StatsPlayer extends SquadPlayer implements StatsPoint {
    private _pointCent: number;

    constructor(player) {
        super(player);
        this._pointCent = player.pointCent;
    }

    public get point(): number {
        if (this.isCaptain) {
            return divideByOneHundred(Math.floor(this._pointCent * CAPTAIN_WEIGHTED_RATE));
        }

        if (this.isViceCaptain) {
            return divideByOneHundred(Math.floor(this._pointCent * VICE_CAPTAIN_WEIGHTED_RATE));
        }

        return divideByOneHundred(this._pointCent);
    }

    public static fromSquadAllJson(SquadAll: SquadDto[], stats, match, sport): StatsPlayer[] {
        const playerToStats = stats.reduce((playerStatsObject, currentPlayer) => ({
            ...playerStatsObject, [currentPlayer.id]: currentPlayer
        }), {});

        return SquadAll.reduce((statsPlayers: StatsPlayer[], squad) => {
            return statsPlayers.concat(squad.players.map(player => {
                return new StatsPlayer({
                    ...player,
                    sport,
                    pointCent: playerToStats[player.id] ? playerToStats[player.id].pointCent : 0,
                    squadLogoUrl: squad.code === match.squadACode ? match.squadALogoUrl : match.squadBLogoUrl,
                    squadCode: squad.code,
                    squadKey: squad.key,
                    isHome: squad.code === match.squadACode
                });
            }));
        }, []);
    }
}


export interface PlayerVO {
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
    avatar: string;
    creditCent: number;
    scoreCent: number;
    squadLogoUrl: string;
    squadCode: string;
    lineup: LINEUP;
    role: ROLE;
    name?: string;

}

export type SpecialPlayerVO = Omit<PlayerVO, "scoreCent" | "squadLogoUrl" | "squadCode" | "lineup">

export interface SquadPlayerVO {
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
    avatar: string;
    creditCent: number;
    scoreCent: number;
    squadLogoUrl: string;
    squadCode: string;
    lineup: LINEUP;
    role: ROLE;
    name?: string;

    pointAvgCent: number;
    captainSelectionRate: number;
    playerSelectionRate: number;
    viceCaptainSelectionRate: number;
    isSelected: boolean;
    isHome: boolean;
    sport: SPORT;
    isDisabled: boolean;
}

export enum HEALTHY_STATUS {
    HEALTHY = "HEALTHY",
    INJURED = "INJURED",
    UNKNOWN = "UNKNOWN"
}
