import styled from "styled-components";
import { Form, Modal } from "react-bootstrap";

export const ModalContainer = styled(Modal.Body).attrs(() => ({
    className: "position-relative"
}))`
  padding: 2.4rem 1.6rem 0;
  overflow: hidden;
  border-radius: inherit;
`;

export const ModalTitleWrapper = styled.div.attrs(() => ({
    className: "d-flex justify-content-between align-items-center"
}))`
`;

export const ModalTitle = styled.div.attrs(() => ({
    className: "fz-sf-bold-title-1 text-primary-1"
}))``;

export const CloseButton = styled.div.attrs(() => ({
    className: "fz-sf-bold-title-3 text-primary-2 clickable"
}))`
  font-size: 3rem;
`;

export const PreparingWrapper = styled.div.attrs({
    className: "d-flex justify-content-between align-items-center border-light-primary"
})`
  padding-bottom: .8rem;
  border-bottom: 1px solid;
`;

export const Text = styled.div.attrs({
    className: "fz-sf-bold-body-1"
})`
`;

export const ModalBodyWrapper = styled.div.attrs(() => ({
    className: "d-flex flex-column"
}))`
  padding: 1.6rem .2rem 7.5rem;
  height: 34rem;
  overflow-y: auto;
`;

export const ModalFooterWrapper = styled.div.attrs(() => ({
    className: "position-absolute d-flex justify-content-center"
}))`
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 2.4rem 1.6rem 1.6rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) -7.74%, #FFFFFF 21.44%);
  gap: 1rem;
  border-radius: var(--modal-border-radius);
`;

export const ModalBodyTopSection = styled.div.attrs(() => ({
    className: "d-flex"
}))``;

export const CheckBoxWrapper = styled.div.attrs({
    className: "d-flex justify-content-between w-100"
})`
  margin-bottom: 1.3rem;
`;

export const CheckBoxLabel = styled(Form.Label).attrs<{
    $checked: boolean
}>(({ $checked }) => ({
    className: `clickable d-flex justify-content-between fz-sf-regular-body-1 ${$checked ? "text-secondary-1" : "text-light-primary"}`
}))``;

export const CustomCheckboxWrapper = styled.div`
  margin-right: 1rem;
`;
