import { StraightLine } from "components/straightLine";
import React from "react";
import { useTaskProvider } from "../../TaskProvider";
import ClaimedBlock, { CLAIMED_BLOCK_TYPE } from "./ClaimedBlock";

export const ClaimedSection: React.FC = () => {
    const {
        taskPrizeClaimed
    } = useTaskProvider();

    return <div className="w-100 px-5 py-4 d-flex align-items-center justify-content-center">
        <ClaimedBlock amount={taskPrizeClaimed?.coin ?? 0}
                      type={CLAIMED_BLOCK_TYPE.COIN} />
        <StraightLine $height={4.5} />
        <ClaimedBlock amount={taskPrizeClaimed?.ruby ?? 0}
                      type={CLAIMED_BLOCK_TYPE.RUBY} />
    </div>;
};

