import React, { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import { addErrorPopup } from "store/actions/AlertActions";
import { createErrorObject } from "../../../helpers/ErrorHelper";
import { getInputText, isInvalidMessage, isNotAllowedError, isOnlyEnterPressed, isTextEmpty } from "../ChatRoomHelper";
import {
    InputWrapper,
    InvalidMessageToast,
    MessageSentToast,
    MessageTextSection,
    SendMessageButton
} from "../ChatRoomStyle";

let compositionTimer = null;
let messageSentTimer = null;
let invalidToastTimer = null;

const CustomizeInput = ({ isScrollbarReachDividePoint, chatObject }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const contentEditableRef = useRef(null);
    const [compositionState, setCompositionState] = useState(true);

    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const [isToastOpen, setIsToastOpen] = useState(false);

    const [invalidMessage, setInvalidMessage] = useState("");
    const [invalidMessageToast, setInvalidMessageToast] = useState(false);


    const handleKeyUp = e => {
        if (isOnlyEnterPressed(e) && compositionState) {
            e.preventDefault();
            void handleSendMessage();
        }
    };

    const handleKeyDown = e => {
        if (isOnlyEnterPressed(e)) {
            e.preventDefault();
        }
    };

    const handlePaste = e => {
        e.preventDefault();
        document.execCommand("insertText", false, e.clipboardData.getData("text"));
    };

    const handleCompositionStart = () => {
        clearTimeout(compositionTimer);
        setCompositionState(false);
    };
    const handleCompositionEnd = () => {
        compositionTimer = setTimeout(() => {
            setCompositionState(true);
        }, 200);
    };

    const showMessageSentToast = () => {
        clearTimeout(messageSentTimer);

        setIsToastOpen(true);

        messageSentTimer = setTimeout(() => {
            setIsToastOpen(false);
        }, 2000);
    };

    const showInvalidMessageToast = text => {

        clearTimeout(invalidToastTimer);

        setInvalidMessage(text);
        setInvalidMessageToast(true);

        invalidToastTimer = setTimeout(() => {
            setInvalidMessageToast(false);
        }, 1500);
    };

    const handleSendMessage = async () => {
        const text = getInputText(contentEditableRef.current);

        if (isSendingMessage || isTextEmpty(text)) return;

        try {
            setIsSendingMessage(true);
            const { message } = await chatObject.channel.sendMessage({ text });

            if (isInvalidMessage(message)) {
                showInvalidMessageToast(message.text);
            } else {
                showMessageSentToast();
            }

            contentEditableRef.current.innerHTML = "";
            setIsSendingMessage(false);

        } catch (error) {
            if (isNotAllowedError(error)) {
                showInvalidMessageToast(<FormattedMessage id="message_sent_fail_toast_label_forbidden" />);
                return;
            }

            dispatch(addErrorPopup(createErrorObject(error)));
            setIsSendingMessage(false);
        }
    };

    const renderMessageSentToast = () => {
        return (!isScrollbarReachDividePoint && isToastOpen) &&
            <MessageSentToast>
                <FormattedMessage id="message_sent_toast_label" />
            </MessageSentToast>;
    };

    const renderInvalidMessageToast = () => {
        return invalidMessageToast && <InvalidMessageToast>{invalidMessage}</InvalidMessageToast>;
    };

    return <>
        <InputWrapper>
            {renderMessageSentToast()}

            {renderInvalidMessageToast()}

            <MessageTextSection ref={contentEditableRef}
                                placeholder={
                                    intl.formatMessage({ id: "chat_room_page_message_input_label_hint" })
                                }
                                contentEditable={true}
                                onKeyUp={handleKeyUp}
                                onKeyDown={handleKeyDown}
                                onCompositionStart={handleCompositionStart}
                                onCompositionEnd={handleCompositionEnd}
                                onPaste={handlePaste}
            />
            <div className={"d-flex justify-content-center"} style={{
                paddingLeft: "1.8rem"
            }}>
                <SendMessageButton onClick={handleSendMessage} />
            </div>
        </InputWrapper>
    </>;
};

export default CustomizeInput;
