import FantasyBasicPopup from "components/popup/FantasyBasicPopup";
import { BUTTON_PATTERN, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { KycToWithdrawPopupProps } from "domain/kyc/components/KycToWithdrawPopupFactory";
import { kycArrowWithdraw, kycInitiationWithdraw, kycWithdraw } from "helpers/IconHelper";
import React from "react";
import { useIntl } from "react-intl";

export const KycAwaitToWithdrawPopup: React.FC<KycToWithdrawPopupProps> = ({
                                                                               isShow,
                                                                               onClose
                                                                           }) => {
    const intl = useIntl();

    return <FantasyBasicPopup
        show={isShow}
        onHide={onClose}
        imageSection={<div>
            <img src={kycInitiationWithdraw} alt="kyc-initiation-withdraw" className="tw-max-w-[25%]" />
            <img src={kycArrowWithdraw} alt="kyc-arrow-withdraw" className="tw-max-w-[7%]" />
            <img src={kycWithdraw} alt="kyc-withdraw" className="tw-max-w-[25%]" />
        </div>}
        title={intl.formatMessage({ id: "kyc_await_to_withdraw" })}
        texts={[{
            text: intl.formatMessage({ id: "kyc_await_to_withdraw_content" }),
            textClassName: "tw-text-left",
        }]}
        buttonSetting={{
            cancelButton: false,
            confirmButtonVariant: NEW_BUTTON_VARIANTS.primaryDark,
            buttonPattern: BUTTON_PATTERN.rounded,
            confirmButtonText: intl.formatMessage({ id: "label_return_to_game" }),
            onConfirm: onClose,
        }}
    />;
};
