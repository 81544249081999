import useDevice from "hooks/useDevice/useDevice";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Pagination } from "swiper/modules";
import { SwiperSlide } from "swiper/react";
import Carousel from "../../../../components/carousel";
import { useHomeProvider } from "../../HomeProvider";
import { FeaturedContestCard } from "./FeaturedContestCard";
import { OnlyContestWrapper } from "./style";

function FeaturedContest() {
    const { featuredContests, isFeaturedContestsLoading } = useHomeProvider();
    const { isDesktop } = useDevice();

    const isOnly = useMemo(() => featuredContests && featuredContests.length === 1, [featuredContests]);

    if (!featuredContests) return null;

    if (featuredContests.length === 0 || isFeaturedContestsLoading) return null;

    if (isOnly) {
        return <div className="tw-pt-[1.8rem] pb-4">
            <p className="fz-sf-bold-subhead ps-4 pb-2">
                <FormattedMessage id="featured_contest_title" />
            </p>
            <OnlyContestWrapper>
                <div>
                    <FeaturedContestCard featuredContest={featuredContests[0]} />
                </div>
                <div className="d-flex align-items-center justify-content-center fz-sf-bold-subhead">
                    <FormattedMessage id="featured_contest_more_coming_soon" />
                </div>
            </OnlyContestWrapper>
        </div>;
    }

    return <div className="tw-pt-[1.8rem]">
        <p className="fz-sf-bold-subhead ps-4 pb-4">
            <FormattedMessage id="featured_contest_title" />
        </p>
        <Carousel className="px-4 custom-swiper"
                  slidesPerView={1.8}
                  navigation={false}
                  pagination={isDesktop && { clickable: true }}
                  spaceBetween={16}
                  modules={[Pagination]}
        >
            {
                featuredContests.map(featuredContest => {
                    return <SwiperSlide key={`featured-contest-${featuredContest.code}`}>
                        <FeaturedContestCard featuredContest={featuredContest} />
                    </SwiperSlide>;
                })
            }
        </Carousel>
    </div>;
}

export default FeaturedContest;
