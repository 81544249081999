import { getMatchColor, isMatchProcessing, parseMatchStatus } from "helpers/match/MatchHelper";
import PropTypes from "prop-types";
import { MATCH_STATUS_FROM_API } from "../../../../types/match/Match";
import { MatchStatusDot, MatchStatusText, MatchStatusWrapper } from "./MatchStatusWrapperStyle";

const MatchStatusWithDot = ({ matchStatus, shouldBlink = true }) => {

    const color = getMatchColor(matchStatus);
    const shouldDotBlink = shouldBlink && [MATCH_STATUS_FROM_API.LIVE, MATCH_STATUS_FROM_API.PROCESSING].includes(matchStatus);
    const shouldTextBlink = shouldBlink && isMatchProcessing(matchStatus);

    return <MatchStatusWrapper>
        <MatchStatusDot className={color} $shouldBlink={shouldDotBlink} />
        <MatchStatusText className={color}
                         $shouldBlink={shouldTextBlink}>{parseMatchStatus(matchStatus)}</MatchStatusText>
    </MatchStatusWrapper>;
};

export default MatchStatusWithDot;

MatchStatusWithDot.propTypes = {
    matchStatus: PropTypes.string.isRequired
};
