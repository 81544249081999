import { UserProfileRepository } from "data/repository/user/UserProfileRepository";
import { TOAST_TYPE } from "designToken/alert/components/AlertHelper";
import { isMe, isStranger } from "domain/friend/FriendHelper";
import { TransferButtonStyle, TransferButtonWrapper } from "domain/friend/profile/buttons/TransferButtonStyle";
import useUserProfileContext from "domain/friend/profile/useUserProfileContext";
import { profileTransferActiveIcon, profileTransferInactiveIcon } from "helpers/IconHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY, QUERY_STATUS } from "hooks/useFantasyQuery/type";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";

import { addToast } from "store/actions/AlertActions";
import { RootStoreState } from "store/reducer";

const EnabledButton = ({ user }) => {
    const history = useFantasyHistory();
    const onClick = () => history.push({
        pathname: ROUTER_PATH_CONFIG.userProfile.transfer,
        state: {
            userId: user.id,
            avatar: user.avatar,
            nickname: user.nickname
        }
    });

    return <TransferButtonStyle className="clickable" onClick={onClick}>
        <img
            data-testid=" transfer-active"
            className="img-fluid" src={profileTransferActiveIcon} alt="transfer-active" />
    </TransferButtonStyle>;
};

const DisableButton = ({ onClick }: { onClick: () => void }) => {
    return <TransferButtonStyle className="disabled" onClick={onClick}>
        <img
            data-testid=" transfer-inactive"
            className="img-fluid" src={profileTransferInactiveIcon} alt="transfer-inactive" />
    </TransferButtonStyle>;
};


export const TransferButton = () => {

    const dispatch = useDispatch();
    const intl = useIntl();
    const { userInfo: otherUser } = useUserProfileContext();

    const {
        FantasyGameConfigState: { featureConfig },
    } = useSelector((store: RootStoreState) => store);

    const userRepository = useMemo(() => new UserProfileRepository(), []);

    const myTransferred = useFantasyQuery([QUERY_KEY.GET_MY_TRANSFERRED], userRepository.getMyTransferred);

    if (myTransferred.status === QUERY_STATUS.LOADING) {
        return null;
    }

    if (isMe(otherUser.type)) {
        return null;
    }

    if (!featureConfig.transfer) {
        return null;
    }

    if (isStranger(otherUser.type)) {
        return <TransferButtonWrapper>
            <DisableButton onClick={() => {
                dispatch(addToast({
                    type: TOAST_TYPE.WARNING,
                    content: intl.formatMessage({ id: "transfer_disabled_unfollow" })
                }));
            }}
            />
        </TransferButtonWrapper>;
    }

    if (myTransferred.data!.todayCentQuota <= 0) {
        return <TransferButtonWrapper>
            <DisableButton onClick={() => {
                dispatch(addToast({
                    type: TOAST_TYPE.DANGER,
                    content: intl.formatMessage({ id: "transfer_disabled_insufficient_ap_level" })
                }));
            }}
            />
        </TransferButtonWrapper>;
    }

    return <TransferButtonWrapper>
        <EnabledButton user={otherUser} />
    </TransferButtonWrapper>;


};


