import { CopyInvitationCodeButton } from "components/copyCode/CopyInvitationCodeButton";
import { useToggle } from "components/tooltip/useTimeoutToggle";
import { shareIcon } from "helpers/IconHelper";
import React, { useCallback } from "react";
import styled from "styled-components";
import ShareContestPopup from "components/popup/shareContestPopup/ShareContestPopup";

export interface CopyInvitationCodeButtonWithModalProps {
    contestCode: string
    contestId: number
}

const CopyButtonWithModalWrapper = styled.div.attrs(() => ({
    className: "ms-auto clickable"
}))`
`;

const CopyInvitationCodeButtonWithModal: React.FC<CopyInvitationCodeButtonWithModalProps> = ({
    contestCode,
    contestId
}) => {
    const {
        toggle: showModal,
        setToggle: setModalShow
    } = useToggle(false);

    const handleOpenModal = useCallback(() => {
        setModalShow(true);
    }, [setModalShow]);

    const handleCloseModal = useCallback(() => {
        setModalShow(false);
    }, [setModalShow]);

    return (
        <CopyButtonWithModalWrapper onClick={e => e?.stopPropagation()}>
            <CopyInvitationCodeButton icon={shareIcon} onCopyModalShow={handleOpenModal} />
            <ShareContestPopup
                code={contestCode}
                show={showModal}
                onHide={handleCloseModal}
                contestId={contestId}
            />
        </CopyButtonWithModalWrapper>
    );
};

export default CopyInvitationCodeButtonWithModal;
