import { zodResolver } from "@hookform/resolvers/zod";
import { Callback } from "Common";
import { Icon } from "helpers/IconHelper";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import _ from "lodash";
import { ChevronRight } from "lucide-react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { CONTEST_TYPE_FROM_API } from "types/contest/Contest";
import { ContestDetailVO } from "types/contest/ContestDetail";
import { z } from "zod";
import { Form, FormField, FormItem } from "../../../components/ui/form";
import { Label } from "../../../components/ui/label";
import { RadioGroup, RadioGroupItem } from "../../../components/ui/radio-group";
import { cn } from "../../../helpers/cn";
import useCurrency from "../../../hooks/useCurrency/useCurrency";
import useUserIdentity from "../../../hooks/useUserIdentity";
import { RootStoreState } from "../../../store/reducer";
import { EnrichedCoupon } from "../../../types/coupon/Coupon";
import { Discount } from "../hooks/useJoinContestCalc";
import { EntrySectionDivider } from "./ConfirmationDialog/ConfirmationModal/EntrySectionDivider";
import { DISCOUNT_TYPE } from "./ConfirmationDialog/types";
import { RubyDiscountExchangeRateTooltip } from "./FullRubySection";

const values = [DISCOUNT_TYPE.COUPON, DISCOUNT_TYPE.RUBY, DISCOUNT_TYPE.NONE];

interface RadioItemWithLabelProps {
    value: any;
    currentFormValue: any;
    children: React.ReactNode;
}

const RadioItemWithLabel: React.FC<RadioItemWithLabelProps> = ({ currentFormValue, value, children, ...props }) =>
    <FormItem
        className={"tw-flex tw-items-center tw-space-x-2 tw-cursor-pointer"} {..._.omit(props, 'data-testid')}>
        <RadioGroupItem
            className={
                cn("tw-text-primary-700",
                    currentFormValue === value ? "tw-border-primary-700" : "tw-border-grey-650"
                )
            }
            value={value}
            id={value} />
        <Label htmlFor={value} className={"tw-text-subtitle-1 tw-cursor-pointer"} data-testid={props['data-testid']}>
            {children}
        </Label>
    </FormItem>;

export interface DiscountSectionProps {
    contest: Pick<ContestDetailVO, 'type' | 'entryFeeCent'>,
    selectedCoupons: EnrichedCoupon[],
    myUnusedCoupon: EnrichedCoupon[],
    openSelectCouponModal: () => void,
    isUseRuby: boolean,
    isUseCoupon: boolean,
    canUseRuby: boolean,
    couponDiscountCent: number,
    rubyDiscountToDepositCent: number,
    onToggleRuby: () => void,
}

interface NewDiscountSectionProps extends Omit<DiscountSectionProps, 'onToggleRuby'> {
    onUseRuby: Callback;
    onUseCoupon: Callback;
    onResetDiscount: Callback;
}

function CouponBadge(props: { selectedCoupons: EnrichedCoupon[] }) {
    if (props.selectedCoupons.length === 0) return null;

    return <div className={"tw-px-2 tw-rounded-full tw-bg-grey-100 tw-text-body-1"}>
        {
            props.selectedCoupons.length === 1 ? (
                <p>
                    {`${props.selectedCoupons[0].discountPercentage}% OFF`}
                </p>
            ) : (
                <div className={"tw-flex tw-items-center tw-gap-1"}>
                    <div className={"tw-text-grey-650"}>
                        <Icon.Coupon />
                    </div>
                    {`x${props.selectedCoupons.length}`}
                </div>
            )
        }
    </div>;
}

function DiscountText({ chosenDiscountType, discountType, walletNumberDiscount }: {
    discountType: DISCOUNT_TYPE,
    chosenDiscountType: DISCOUNT_TYPE,
    walletNumberDiscount: string
}) {
    const isChosen = discountType === chosenDiscountType;

    return <div className={"tw-text-subtitle-1 tw-text-grey-400"} data-testid={`${discountType}-discount`}>
        {isChosen ? (
            <div className={discountType === DISCOUNT_TYPE.COUPON ? "tw-text-primary-600" : "tw-text-secondary-600"}>
                {`-${walletNumberDiscount}`}
            </div>
        ) : "-"}
    </div>;
}

export function checkCanUseCoupon(isB2C, contest: Pick<ContestDetailVO, 'type' | 'entryFeeCent'>) {
    return isB2C && contest.type === CONTEST_TYPE_FROM_API.NORMAL && contest.entryFeeCent > 0;
}

export function DiscountSection({
                                    selectedCoupons,
                                    canUseRuby,
                                    contest,
                                    couponDiscountCent,
                                    rubyDiscountToDepositCent,
                                    openSelectCouponModal,
                                    onUseCoupon,
                                    onUseRuby,
                                    onResetDiscount,
                                }: NewDiscountSectionProps) {
    const { isB2C } = useUserIdentity();
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();
    const canUseCoupon = checkCanUseCoupon(isB2C, contest);

    const { contest: { rubyExchangeRate } } = useSelector((store: RootStoreState) => store.SettingsState);

    const defaultDiscountTypeVal = selectedCoupons.length > 0 ? DISCOUNT_TYPE.COUPON : DISCOUNT_TYPE.NONE;

    const form = useForm({
        resolver: zodResolver(z.object({
            discountType: z.enum([values[0], values[1], values[2]],)
        })),
        defaultValues: {
            discountType: defaultDiscountTypeVal,
        }
    });
    const discountType = form.watch('discountType');

    useEffect(() => {
        const discount = new Discount(discountType);
        if (discount.isUseCoupon()) {
            onUseCoupon();
            if (selectedCoupons.length === 0) {
                openSelectCouponModal();
            }
        }
        if (discount.isUseRuby()) {
            onUseRuby();
        }
        if (discount.isNone()) {
            onResetDiscount();
        }
    }, [discountType]);

    if (!canUseCoupon && !canUseRuby) return null;

    return (
        <Form {...form}>
            <FormField name={'discountType'}
                       render={({ field }) => {
                           return (
                               <RadioGroup defaultValue={field.value} onValueChange={field.onChange}>
                                   <div className={"tw-flex tw-flex-col tw-gap-2"}>
                                       {canUseCoupon &&
                                           <div className={"tw-flex tw-items-center tw-justify-between"}>
                                               <RadioItemWithLabel currentFormValue={field.value} value={values[0]}>
                                                   <FormattedMessage
                                                       id="label_coupon"
                                                   />
                                               </RadioItemWithLabel>
                                               <div
                                                   data-testid="confirmation-coupon"
                                                   className={
                                                       cn("tw-relative",
                                                           "tw-flex tw-items-center tw-gap-2",
                                                           "tw-cursor-pointer tw-select-none"
                                                       )
                                                   }
                                                   onClick={openSelectCouponModal}
                                               >
                                                   <CouponBadge selectedCoupons={selectedCoupons} />
                                                   <DiscountText
                                                       discountType={DISCOUNT_TYPE.COUPON}
                                                       chosenDiscountType={discountType}
                                                       walletNumberDiscount={toWalletNumber(divideByOneHundred(couponDiscountCent))}
                                                   />
                                                   <div className={"tw-absolute tw-right-[-2rem]"}>
                                                       <ChevronRight size={'1.8rem'} />
                                                   </div>
                                               </div>
                                           </div>
                                       }
                                       {canUseRuby && (
                                           <div className={"tw-flex tw-items-center tw-justify-between"}>
                                               <div className={"tw-flex tw-items-center tw-gap-1"}
                                               >
                                                   <RadioItemWithLabel currentFormValue={field.value} value={values[1]}
                                                                       data-testid="confirmation-ruby">
                                                       <FormattedMessage
                                                           id="label_full_ruby"
                                                       />
                                                   </RadioItemWithLabel>
                                                   <RubyDiscountExchangeRateTooltip
                                                       rubyExchangeRate={rubyExchangeRate} />
                                               </div>
                                               <DiscountText
                                                   discountType={DISCOUNT_TYPE.RUBY}
                                                   chosenDiscountType={discountType}
                                                   walletNumberDiscount={toWalletNumber(divideByOneHundred(rubyDiscountToDepositCent))}
                                               />
                                           </div>
                                       )}
                                       <RadioItemWithLabel currentFormValue={field.value} value={values[2]}>
                                           <FormattedMessage
                                               id="label_no_discount"
                                           />
                                       </RadioItemWithLabel>
                                   </div>
                               </RadioGroup>
                           );
                       }} />
            <EntrySectionDivider />
        </Form>
    );
}
