import { getContestCodeDetailUrl, getMyNineCatSlates } from "../../../apis/ApiUrlConfig";
import { ajax } from "../../../apis/ApiUtils";
import { SPORT } from "../../../types/sports/Sport";
import { MyNineCatDto } from "../../dto/nineCat/MyNineCatDto";
import { NineCatContestDetailDto } from "../../dto/nineCat/NineCatContestDetailDto";

export const fetchMyNineCatSlates = async (sport: SPORT) => {
    const response = await ajax<MyNineCatDto[]>({ url: getMyNineCatSlates(sport) });
    return response.data.data;
};

export const fetchMyNineCatSlatesDetail = async (code: string) => {
    const response = await ajax<NineCatContestDetailDto>({ url: getContestCodeDetailUrl(code) });
    return response.data.data;
};