import { ImageDividerWrapper } from "components/supplierLogo/components/ImageDividerWrapper";
import { SupplierLinkPopup } from "components/supplierLogo/components/SupplierLinkPopup";
import { SupplierInfo } from "components/supplierLogo/SupplierHelper";
import { SUPPLIER, SUPPLIER_LOGO_TYPE } from "components/supplierLogo/type";
import { cn } from "helpers/cn";
import React, { useState } from "react";

interface SupplierLogoProps {
    supplier: SUPPLIER | null;
    type?: SUPPLIER_LOGO_TYPE;
    offset?: boolean;
}

export const SupplierLogo: React.FC<SupplierLogoProps> = ({
                                                              supplier,
                                                              type = SUPPLIER_LOGO_TYPE.NORMAL,
                                                              offset = false
                                                          }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const supplierInfo = supplier ? SupplierInfo[supplier] : null;

    if (!supplierInfo) {
        return null;
    }

    const handlePopupOpen = () => {
        if (supplierInfo.link) {
            setIsPopupOpen(true);
        }
    };

    const handlePopupClose = () => {
        setIsPopupOpen(false);
    };

    return <>
        <ImageDividerWrapper type={type}>
            <img
                src={supplierInfo.logo}
                alt="supplier-logo"
                className={cn("tw-h-4", {
                    "tw-cursor-pointer": supplierInfo.link
                })}
                onClick={handlePopupOpen}
            />
        </ImageDividerWrapper>
        {
            supplierInfo.link && <SupplierLinkPopup
                show={isPopupOpen}
                handleClose={handlePopupClose}
                supplierInfo={supplierInfo}
                offset={offset}
            />
        }
    </>;
};
