import { Col } from "react-bootstrap";
import { FiPlus } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import styled, { keyframes } from "styled-components";
import { APP_MAX_WIDTH } from "../../../../../../styles/Constants";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;
export const WarningAndExplanation = styled.div.attrs({
    className: "d-flex justify-content-between"
})`
  padding: .8rem 1.6rem;
`;
export const WarningText = styled.div.attrs({
    className: "fz-sf-regular-body-2 text-light-secondary"
})`
`;

export const ExpandButtonWrapper = styled.div.attrs(() => ({
    className: "position-fixed d-flex justify-content-center align-items-center rounded-circle text-dark-primary bg-elevated-secondary tw-border-system-labelLightTertiary"
}))`
  left: 50%;
  bottom: .4rem;
  width: 4.8rem;
  height: 4.8rem;
  border-width: 1px;
  border-style: solid;
  transform: translateX(-50%);
  cursor: pointer;
  animation: ${fadeIn} .3s ease-in;

  @media (min-width: ${APP_MAX_WIDTH}) {
    bottom: 1rem;
    left: calc(${APP_MAX_WIDTH} / 2);
  }
`;
export const CreateIcon = styled(FiPlus).attrs(() => ({
    className: ""
}))`
  font-size: 2.4rem;
`;
export const ExpandedButtonWrapper = styled.div.attrs(() => ({
    className: "position-fixed d-flex justify-content-center align-items-center rounded-pill text-dark-primary bg-opacity-primary-2"
}))`
  padding: 1rem;
  left: 50%;
  bottom: .9rem;
  min-width: 28.4rem;
  transform: translateX(-50%);
  cursor: auto;
  animation: ${fadeIn} .3s ease-in;

  @media (min-width: ${APP_MAX_WIDTH}) {
    left: calc(${APP_MAX_WIDTH} / 2);
  }
`;

export const B2BCreateTeamButtonWrapper = styled(ExpandedButtonWrapper).attrs(() => ({
    className: "fz-sf-regular-body-2 text-nowrap"
}))`
  min-width: 12.8rem;
  cursor: pointer;
`;

export const CreateContestButton = styled(Col).attrs(() => ({
    className: "fz-sf-regular-body-2 text-nowrap"
}))`
  cursor: pointer;
  text-align: right;
  margin-left: 2.4rem;
`;
export const CreateTeamButton = styled(Col).attrs(() => ({
    className: "fz-sf-regular-body-2 text-nowrap"
}))`
  cursor: pointer;
  text-align: left;
  margin-right: 1.77rem;
`;
export const CloseIcon = styled(IoClose).attrs(() => ({
    className: ""
}))`
  font-size: 2.8rem;
  cursor: pointer;
  margin: 0 1.6rem;
`;

export const CloseButton = styled.div.attrs(() => ({
    className: "fz-sf-bold-title-3 tw-text-system-primary80"
}))`
  font-size: 3rem;
  cursor: pointer;
`;
