import { cn } from "helpers/cn";
import React from "react";

export function SlotCardAnimatedPing() {
    return <div
        className={"tw-absolute tw-top-[-2px] tw-left-[-2px] tw-bottom-[-2px] tw-w-full tw-m-auto tw-overflow-clip tw-rounded-[1.6rem] tw-select-none"}>
        <div
            className={cn("tw-absolute tw-top-[6.6rem] tw-left-[0.8rem] tw-origin-center tw-bg-[#C3C3C3]/[0.6] tw-w-[2rem] tw-h-[2rem]", "tw-rounded-full tw-animate-custom-ping")} />
    </div>;
}
