import styled from "styled-components";

export const DownloadWrapper = styled.div.attrs(() => ({
    className: "position-fixed w-100 h-100 d-flex justify-content-center align-items-center"
}))`
    top: 0;
    left: 0;
    z-index: 99;
    max-width: 55rem;
`;
export const DownloadModalOverlay = styled.div.attrs(() => ({
    className: "position-absolute w-100 h-100"
}))`
    background-color: rgba(0, 0, 0, .8);

`;
export const DownloadModal = styled.div.attrs(() => ({
    className: "position-absolute tw-bg-system-bgLightPrimary"
}))`
    z-index: 3;
    width: 26.8rem;
    border-radius: 1.6rem;
    padding: 2rem;
`;
export const DownloadModalBody = styled.div.attrs(() => ({
    className: "d-flex flex-column align-items-center"
}))``;

export const CloseButton = styled.div.attrs(() => ({
    className: "ms-auto"
}))`
    width: 2rem;
    padding-bottom: 2rem;
    background-image: url(${({ closeImg }) => closeImg});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
    cursor: pointer;
`;
export const Qrcode = styled.div.attrs(() => ({}))`
    width: 15.2rem;
    padding-bottom: 15.2rem;
    background-image: url(${({ qrcodeImg }) => qrcodeImg});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
`;
export const DownloadButton = styled.div.attrs(() => ({
    className: "my-3"
}))`
    width: 15.2rem;
    padding-bottom: 4.836rem;
    background-image: url(${({ androidAppImg }) => androidAppImg});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
    cursor: pointer;
`;
export const Text = styled.div.attrs(() => ({
    className: "fz-sf-normal-subhead text-center tw-text-system-labelLightPrimary"
}))`
`;
