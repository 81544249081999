import { divideByOneHundred } from "helpers/number/NumberHelper";
import { LeaderboardTeamDto } from "../../dto/leaderboard/LeaderboardTeamDto";

export class LeaderboardTeamVo {
    nickname: string;
    rank: number;
    score: number;
    teamId: number;
    teamName: string;
    userId: number;
    wonPrize: number;

    constructor(dto: LeaderboardTeamDto) {
        this.nickname = dto.nickname;
        this.rank = dto.rank;
        this.score = divideByOneHundred(dto.scoreCent);
        this.teamId = dto.teamId;
        this.teamName = dto.teamName;
        this.userId = dto.userId;
        this.wonPrize = divideByOneHundred(dto.wonPrizeCent);
    }
}
