import { ajax } from "apis/ApiUtils";
import { SPORT } from "types/sports/Sport";
import { getCreateTeamUrl, getEditTeamUrl, getTeamUrl } from "../ApiUrlConfig";
import { MyTeamVO } from "types/team/MyTeam";

interface OperateTeamRequest {
    matchId: string,
    playerIds: string[],
    captain: string,
    viceCaptain: string
}

export const createTeam = (sport: SPORT, data: OperateTeamRequest): Promise<boolean> => {
    return ajax({
        method: "POST",
        url: getCreateTeamUrl(sport, data.matchId),
        data
    })
        .then(() => true);
};

export const editTeam = (sport: SPORT, teamId: number, data: OperateTeamRequest): Promise<boolean> => {
    return ajax({
        method: "PUT",
        url: getEditTeamUrl(sport, teamId),
        data
    })
        .then(() => true);
};


export const fetchTeam = (teamId: number): Promise<MyTeamVO> => {
    return ajax<MyTeamVO>({
        method: "GET",
        url: getTeamUrl(teamId)
    }).then(res => res.data.data);
};
