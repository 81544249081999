import React, { Fragment } from "react";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import { Swiper, SwiperProps, SwiperRef, useSwiper } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";


const SwiperPrevButton = () => {
    const swiper = useSwiper();

    return (
        <div
            className="swiper-button-prev"
            onClick={() => swiper.slidePrev()}
        >
            <MdArrowBackIosNew size={"1.75rem"} style={{ width: "auto", height: "auto" }} />
        </div>
    );
};


function SwiperNextButton() {
    const swiper = useSwiper();

    return <div
        className="swiper-button-next"
        onClick={() => swiper.slideNext()}
    >
        <MdArrowForwardIos size="1.75rem" style={{ width: "auto", height: "auto" }} />
    </div>;
}

const Carousel: React.FC<React.RefAttributes<SwiperRef> & SwiperProps> = ({ children, ...props }) => {
    return <Swiper
        {...props}
        navigation={false}
    >
        {children}

        {
            // todo fix arrow first and last disabled
            props.navigation && children.length > 1 &&
            <Fragment>
                <SwiperPrevButton />

                <SwiperNextButton />
            </Fragment>
        }
    </Swiper>;
};

export default Carousel;
