import { ajax } from "apis/ApiUtils";
import { PaymongoReceivingInstitutionDto } from "data/dto/payment/paymongo/paymongoReceivingInstitutionDto";
import { PaymongoWithdrewBeginDto } from "data/dto/payment/paymongo/paymongoWithdrewBeginDto";
import { ORDER_STATUS } from "domain/coin/AddCoinHelper";

export const payMongoWithdrewBegin = async (data: PaymongoWithdrewBeginDto) => {
    return await ajax({
        url: "/withdraw/paymongo/begin",
        method: "POST",
        data
    });
};

export const payMongoReceivingInstitutions = async () => {
    const response = await ajax<PaymongoReceivingInstitutionDto[]>({
        url: "/withdraw/paymongo/receiving-institutions",
    });

    return response.data;
};

export interface initPayMongoRequest {
    coinProductId: number;
    successUrl: string;
    cancelUrl: string;
}

export const initializePayMongoPayment = async (data: initPayMongoRequest): Promise<string> => {
    const { data: { data: { redirectUrl } } } = await ajax<{ redirectUrl: string }>({
        url: "/order/paymongo/init",
        method: "POST",
        data
    });

    return redirectUrl;
};

export const fetchPayMongoOrderStatus = async (paymentReference: string): Promise<ORDER_STATUS> => {
    const { data: { data: { status } } } = await ajax<{ status: ORDER_STATUS }>({
        url: `/order/paymongo/${paymentReference}`,
        method: "GET"
    });

    return status;
};
