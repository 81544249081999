import { emailRegexp, referralCodeRegexp } from "helpers/RegexHelper";
import { useIntl } from "react-intl";

const useValidator = () => {
    const intl = useIntl();

    const emailValidator = {
        required: {
            value: true,
            message: intl.formatMessage({ id: "login_page_label_email_is_required" }),
        },
        pattern: {
            value: emailRegexp,
            message: intl.formatMessage({ id: "login_page_label_provide_a_valid_email" }),
        },
    };

    const passwordValidator = {
        required: {
            value: true,
            message: intl.formatMessage({ id: "login_page_label_password_is_required" }),
        },
        minLength: {
            value: 6,
            message: intl.formatMessage({ id: "login_page_label_password_6_chars" }),
        },
    };

    const confirmPasswordValidator = (watch) => ({
        required: {
            value: true,
            message: intl.formatMessage({ id: "login_page_label_password_is_required" }),
        },
        validate: (value) => {
            if (watch("password") !== value) {
                return intl.formatMessage({ id: "login_page_label_password_format_invalid" });
            }
        }
    });


    const referralCodeValidator = {
        required: {
            value: true,
            message: intl.formatMessage({ id: "referral_code_is_required" })
        },
        pattern: {
            value: referralCodeRegexp,
            message: intl.formatMessage({ id: "add_referral_page_label_code_is_invalid" })
        }
    };

    return {
        emailValidator,
        passwordValidator,
        confirmPasswordValidator,
        referralCodeValidator
    };
};

export default useValidator;
