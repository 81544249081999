import CareerStatsContent from "domain/me/components/CareerStats/CareerStatsContent";
import { useMe } from "domain/me/me/MeProvider";
import { cn } from "helpers/cn";
import { getPeriodFormat } from "helpers/TimeHelper";
import { useDragToScrollX } from "hooks/useDragToScrollX";
import React, { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { SPORT } from "types/sports/Sport";

const CareerStats: React.FC = () => {
    const { careerStats } = useMe();
    const { from, until, stats = {} } = careerStats || {};
    const careerStatsRef = useRef<HTMLDivElement>(null);
    useDragToScrollX(careerStatsRef);

    return (
        <div className={cn("tw-flex tw-flex-col tw-gap-2 tw-mb-4")}>
            <span className="fz-sf-bold-body-2 text-light-primary">
                <FormattedMessage id="me_page_career_stats_title" />
            </span>
            <div className="tw-flex tw-w-full tw-gap-2 tw-cursor-pointer tw-overflow-y-scroll" ref={careerStatsRef}>
                {Object.keys(stats).map((sport) => (
                    <CareerStatsContent
                        key={sport}
                        sport={sport as SPORT}
                        value={stats[sport]}
                    />
                ))}
            </div>
            <div className="fz-sf-regular-subhead text-light-secondary tw-text-end">
                <FormattedMessage id="me_page_career_stats_label_data_from" />
                {from && until && `${getPeriodFormat(from, until)}`}
            </div>
        </div>
    );
};

export default CareerStats;
