import { Icon } from "helpers/IconHelper";
import { divideByOneHundred, multiplyByOneHundred } from "helpers/number/NumberHelper";
import React from "react";
import { FormattedMessage } from "react-intl";
import { FantasyPopupButtonSetting } from "../../../../components/popup/component/ButtonSection";
import FantasyBasicPopup from "../../../../components/popup/FantasyBasicPopup";
import { BUTTON_PATTERN, BUTTON_SIZE, NEW_BUTTON_VARIANTS } from "../../../../designToken/button/types";
import { getRequiredDeposit } from "../../../../helpers/ApLevelHelper";
import { cn } from "../../../../helpers/cn";
import useFantasyHistory from "../../../../hooks/useFantasyHistory";
import { ROUTER_PATH_CONFIG } from "../../../../router/RouterPathConfig";
import { apLevelConfig } from "../../../ap/ActivityPointHelper";
import { useDailyCheckInPopup } from "../DailyCheckInPopupProvider";
import { MaxQuestPrizeIcon } from "../../components/QuestPrizeIcon";

export const dailyCheckInUpgradePopupConfirmButton = {
    none: { confirmButton: false },
    compare: {
        confirmButtonVariant: NEW_BUTTON_VARIANTS.tertiaryLight,
        confirmButtonText: <FormattedMessage id="daily_check_in_upgrade_popup_button_compare" />,
    }
};

const DailyCheckInUpgradePopupTitle = () => {
    return <>
        <div className="tw-font-bold tw-text-h5">
            <FormattedMessage id="daily_check_in_upgrade_popup_title" />
        </div>
        <div className="tw-font-light tw-text-subtitle-2">
            <FormattedMessage id="daily_check_in_upgrade_popup_subtitle" />
        </div>
    </>;
};


const DailyCheckInUpgradePopupContent = () => {
    const context = useDailyCheckInPopup();
    const history = useFantasyHistory();

    const apLevel = context.recommendedUpgradeApLevel;
    if (apLevel === null) {
        return null;
    }

    const reward = context.getPrize(apLevel.level, context.maxDaysInRow);

    const handleUpgradeButtonClick = () => {
        history.push(ROUTER_PATH_CONFIG.deposit.select, {
            requiredDepositCent: multiplyByOneHundred(getRequiredDeposit(context.myApLevel.currentAp, apLevel.weight)),
        });
    };

    return <>
        <div className="tw-text-subtitle-1 tw-font-bold tw-text-primary-800 tw-leading-none">
            {apLevelConfig[apLevel.level].textUpper()}
        </div>
        <div className="tw-relative tw-px-5 tw-w-full">
            <div className={cn(
                "tw-absolute tw-left-1/2 tw--translate-x-1/2 tw-top-0",
                "tw-w-18 tw-h-18",
                "tw-rounded-full tw-bg-primary-900",
                "tw-place-content-center")}>
                <img src={apLevelConfig[apLevel.level].image40} alt="upgrade-popup-ap-level" />
            </div>
            <div className={cn(
                "tw-w-full tw-rounded-[1.6rem] tw-bg-grey-0 tw-mt-9 tw-pt-10",
                "tw-flex tw-flex-col tw-items-center")}>
                <div className="tw-text-subtitle-2 tw-font-bold">
                    <FormattedMessage id="label_check_in" />
                </div>
                <div className="tw-text-caption-1 tw-font-light">
                    <FormattedMessage id="daily_check_in_upgrade_popup_label_max_reward" />
                </div>
                <div
                    className={cn(
                        "tw-mt-2 tw-bg-grey-50 tw-rounded-[0.8rem] tw-h-16 tw-w-28",
                        "tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-1")}>
                    <MaxQuestPrizeIcon {...reward} />
                    <div className="tw-text-subtitle-2 tw-font-bold tw-leading-none">
                        {
                            reward.coinCent > 0 ? divideByOneHundred(reward.coinCent) : divideByOneHundred(reward.rubyCent)
                        }
                    </div>
                </div>
                <div className={cn("tw-pt-5 tw-px-5 tw-pb-7 tw-w-full")}>
                    <div className={cn(
                        "tw-w-full tw-h-9 tw-p-[0.1rem]",
                        "tw-quest-button-bg tw-rounded-[0.9rem]",
                        "tw-shadow-upgrade-now-button",
                        "tw-cursor-pointer",
                    )}
                         onClick={handleUpgradeButtonClick}>
                        <div className={cn(
                            "tw-w-full tw-h-full",
                            "tw-bg-primary-900 tw-rounded-[0.8rem]",
                            "tw-flex tw-flex-row tw-items-center tw-justify-center",
                        )}>
                            <Icon.Unlock className="tw-text-warning-200 tw-m-1 tw-mx-1" />
                            <div className="tw-text-body-1 tw-font-bold tw-text-grey-0">
                                <FormattedMessage id="daily_check_in_upgrade_popup_button_upgrade_now" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
};

const DailyCheckInUpgradePopup = ({ confirmButtonSetting }: { confirmButtonSetting: FantasyPopupButtonSetting }) => {
    const { isShowUpgradePopup: show, changeToDefault, myApLevel } = useDailyCheckInPopup();
    const history = useFantasyHistory();

    const handleConfirm = () => {
        history.push(ROUTER_PATH_CONFIG.task.index, { apLevel: apLevelConfig[myApLevel.currentLevel].recommendedUpgradeLevel });
    };

    return <FantasyBasicPopup
        show={show}
        dismissible={false}
        onHide={changeToDefault}
        title={<DailyCheckInUpgradePopupTitle />}
        content={<DailyCheckInUpgradePopupContent />}
        buttonSetting={{
            buttonWrapperClassName: "tw-px-10",
            buttonPattern: BUTTON_PATTERN.rounded,
            buttonSize: BUTTON_SIZE.md,


            cancelButtonText: <FormattedMessage id="button_got_it" />,
            onCancel: changeToDefault,

            onConfirm: handleConfirm,
            ...confirmButtonSetting,
        }} />;
};

export default DailyCheckInUpgradePopup;
