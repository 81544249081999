import SpeechBubble from "components/speechBubble";
import { SportItem, SportName } from "domain/home/HomeStyle";
import { useSportProvider } from "domain/sport/SportProvider";
import { useSportConfig } from "domain/sport/useSportConfig";
import { cn } from "helpers/cn";
import { isEqual } from "lodash";
import moment from "moment/moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import { SPORT } from "types/sports/Sport";

const NextStatDate: React.FC<{ nextStartDate: string | null }> = ({ nextStartDate }) => {

    return <SpeechBubble
        arrow="left"
        arrowSize="small"
        className="tw-text-grey-1000 tw-text-overline tw-leading-[100%] tw-px-1"
        bgClassName="tw-bg-grey-150"
    >
        {nextStartDate
            ? moment(nextStartDate).format("MMM")
            : <FormattedMessage id="label_soon" />
        }
    </SpeechBubble>;
};

const isCurrentSport = (sport: string, currentSport: SPORT) => isEqual(currentSport, sport);

export const SportMenu: React.FC = () => {
    const sportTabs = useSportConfig();
    const { currentSport, chooseCurrentSport } = useSportProvider();

    return <>
        {
            sportTabs.map(({ icon, name, key: sportKey, season }) => {
                const isSelected = isCurrentSport(sportKey, currentSport);
                const isDisable = !season.inProgress;

                return <SportItem
                    key={sportKey}
                    $isSelected={isSelected}
                    className={cn(isDisable ? "tw-cursor-default" : "tw-cursor-pointer")}
                    onClick={() => !isDisable && chooseCurrentSport(sportKey)}
                >
                    <div className="tw-mr-[0.2rem]">
                        {icon("1.8rem", isSelected ? "nav-tabs-on-text" : "nav-tabs-off-text")}
                    </div>
                    <SportName $isSelected={isSelected}>
                        {name}
                        {isDisable && <NextStatDate nextStartDate={season.nextStartDate} />}
                    </SportName>
                </SportItem>;
            })}
    </>;
};
