import NegativeDepositAlert from "components/alert/NegativeDepositAlert";
import AppDownloadModal from "components/download/AppDownloadModal";
import CareerStats from "domain/me/components/CareerStats/CareerStats";
import { MeMenu } from "domain/me/components/MeMenu/MeMenu";
import RelationBar from "domain/me/components/RelationBar/RelationBar";
import TitleBar from "domain/me/components/TitleBar/TitleBar";
import UserInfo from "domain/me/components/UserInfo/UserInfo";
import WalletInfo from "domain/me/components/WalletInfo/WalletInfo";
import { KycVerifyStatusNotificationBanner } from "domain/me/me/KycVerifyStatusNotificationBanner";
import { useMe } from "domain/me/me/MeProvider";
import { MeContainer, Version, } from "domain/me/me/MeStyle";
import { isNegative } from "helpers/number/NumberHelper";
import React from "react";

const Me = () => {
    const { depositCent } = useMe();

    return (
        <>
            <TitleBar />
            {isNegative(depositCent) && <NegativeDepositAlert />}
            <MeContainer>
                <KycVerifyStatusNotificationBanner />
                <UserInfo />
                <RelationBar />
                <WalletInfo />
                <CareerStats />
                <MeMenu />
                <Version>v{process.env.REACT_APP_VERSION}</Version>
            </MeContainer>
            <AppDownloadModal />
        </>
    );
};

export default Me;
