import { MATCH_STATUS_FROM_API } from "../../../types/match/Match";
import { SPORT } from "../../../types/sports/Sport";
import { UpcomingMatchDto } from "../../dto/match/MatchDto";
import { ContestVo } from "../contest/ContestVo";

export class UpcomingMatchVo {
    contests: ContestVo[];
    isLineupsOut: boolean;
    matchId: string;
    matchName: string;
    matchStartsAt: number;
    matchStatus: MATCH_STATUS_FROM_API;
    squadACode: string;
    squadAKey: string;
    squadALogoUrl: string;
    squadAName: string;
    squadBCode: string;
    squadBKey: string;
    squadBLogoUrl: string;
    squadBName: string;
    tournament: string;
    tournamentId: string;
    sport: SPORT;

    constructor(
        matchDto: UpcomingMatchDto,
        sport: SPORT,
        now: number,
    ) {
        this.contests = matchDto.contests.map(contestDto => new ContestVo(contestDto));
        this.isLineupsOut = matchDto.isLineupsOut;
        this.matchId = matchDto.matchId;
        this.matchName = matchDto.matchName;
        this.matchStartsAt = matchDto.matchStartsAt;
        this.matchStatus = now >= matchDto.matchStartsAt
            ? MATCH_STATUS_FROM_API.KICKOFF
            : matchDto.matchStatus;
        this.squadACode = matchDto.squadACode;
        this.squadAKey = matchDto.squadAKey;
        this.squadALogoUrl = matchDto.squadALogoUrl;
        this.squadAName = matchDto.squadAName;
        this.squadBCode = matchDto.squadBCode;
        this.squadBKey = matchDto.squadBKey;
        this.squadBLogoUrl = matchDto.squadBLogoUrl;
        this.squadBName = matchDto.squadBName;
        this.tournament = matchDto.tournament;
        this.tournamentId = matchDto.tournamentId;
        this.sport = sport;
    }
}
