import useFantasyHistory from "../../../hooks/useFantasyHistory";
import { MdArrowBack } from "react-icons/md";
import React from "react";

type SubpageTopNavbarProps = { title: React.JSX.Element };

export const SubpageTopNavbar = ({ title }: SubpageTopNavbarProps) => {
    const history = useFantasyHistory();

    return <div className="tw-px-[1.2rem] tw-py-[0.8rem] tw-flex tw-flex-row">
        <div className="tw-my-auto " onClick={history.goBack}>
            <MdArrowBack className="tw-cursor-pointer" size={"2.4rem"} />
        </div>
        <div className="tw-text-h5 tw-font-bold tw-flex tw-flex-row tw-justify-center tw-grow">
            {title}
        </div>
    </div>;
};