import { MatchRepository } from "data/repository/match/MatchRepository";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { SPORT } from "types/sports/Sport";
import { NineCatRepository } from "data/repository/nineCat/NineCatRepository";
import { MyNineCatVo } from "data/vo/nineCat/MyNineCatVo";
import useFantasyHistory from "hooks/useFantasyHistory";
import useUpdateCoupon from "domain/coupon/useUpdateCoupon";
import { MyMatchesVo } from "data/vo/match/MyMatchesVo";

interface MyMatchContextProps {
    filterSport: SPORT[];
    handleToggleFilterSport: (sport: SPORT) => void;
    isLoading: boolean;

    myMatches: MyMatchesVo;
    refetchMyMatches: () => void;

    myNineCatSlates?: MyNineCatVo[];
    refetchMyNineCat: () => void;

    currentTab: MY_MATCH_TAB;
    setCurrentTab: (tab: MY_MATCH_TAB) => void;
}

const MyMatchContext = React.createContext<MyMatchContextProps | null>(null);

interface MyMatchProviderProps {
    children: React.ReactNode;
}

export enum MY_MATCH_TAB {
    FANTASY_SPORTS = "fantasy-sports",
    NINE_CAT = "9-cat"
}

export const MyMatchProvider: React.FC<MyMatchProviderProps> = ({ children }) => {
    const history = useFantasyHistory();
    const updateCoupon = useUpdateCoupon();
    const [filterSport, setFilterSport] = useState<SPORT[]>([SPORT.CRICKET, SPORT.FOOTBALL, SPORT.BASKETBALL]);
    const [currentTab, setCurrentTab] = useState<MY_MATCH_TAB>(history.location.state?.myMatchTab as MY_MATCH_TAB || MY_MATCH_TAB.FANTASY_SPORTS);

    const matchRepository = new MatchRepository();
    const nineCatRepository = new NineCatRepository();

    const handleToggleFilterSport = useCallback((sport: SPORT) => {
        setFilterSport(prevSports => prevSports.includes(sport)
            ? prevSports.filter(preSport => preSport !== sport)
            : [...prevSports, sport]);
    }, []);

    const myMatches = useFantasyQuery([QUERY_KEY.MY_MATCHES, currentTab], matchRepository.getMyMatches, {
        options: {
            enabled: currentTab === MY_MATCH_TAB.FANTASY_SPORTS,
        }
    });

    const myNineCatSlate = useFantasyQuery([QUERY_KEY.MY_NINE_CAT_SLATE, currentTab], nineCatRepository.getMyNineCat, {
        options: {
            enabled: currentTab === MY_MATCH_TAB.NINE_CAT,
        }
    });

    useEffect(() => {
        updateCoupon();
    }, []);

    const value = useMemo(() => ({
        filterSport,
        handleToggleFilterSport,
        isLoading: myMatches.isFetching || myNineCatSlate.isFetching,
        myMatches: myMatches.data ?? MyMatchesVo.empty(),
        refetchMyMatches: myMatches.refetch,
        myNineCatSlates: myNineCatSlate.data,
        refetchMyNineCat: myNineCatSlate.refetch,
        currentTab,
        setCurrentTab
    }), [
        filterSport,
        handleToggleFilterSport,

        myMatches.data,
        myMatches.isFetching,
        myMatches.refetch,

        myNineCatSlate.data,
        myNineCatSlate.isFetching,
        myNineCatSlate.refetch,

        currentTab,
        setCurrentTab
    ]);

    return <MyMatchContext.Provider value={value}>
        {children}
    </MyMatchContext.Provider>;
};

export const useMyMatch = () => {
    const context = useContext(MyMatchContext);

    if (!context) {
        throw new Error("useMyMatch must be used within a MyMatchContext");
    }

    return context;
};
