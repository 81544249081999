import React from "react";
import { AP_LEVEL } from "types/activityPoint/ApLevel";
import {
    ActivityPointProgramTabItem,
    ActivityPointProgramTabLink,
    ActivityPointProgramTabWrapper
} from "./ActivityPointProgramTabStyle";


interface ActivityPointProgramTabProps {
    tabs: {
        text: string
        tabKey: string
    }[]
    activeTab: string
    setActiveTab: (tabKey: string) => void
    currentLevel: AP_LEVEL
}

const ActivityPointProgramTab: React.FC<ActivityPointProgramTabProps> = ({
                                                                             tabs,
                                                                             setActiveTab,
                                                                             activeTab,
                                                                             currentLevel
                                                                         }) => {
    const onTabClick = (eventKey: string) => {
        setActiveTab(eventKey);
        window.scrollTo(0, 0);
    };

    return (
        <ActivityPointProgramTabWrapper>
            {tabs.map(({ text, tabKey }) =>
                <ActivityPointProgramTabItem key={tabKey}
                                             className={activeTab === tabKey && "active"}
                                             onClick={() => onTabClick(tabKey)}
                >
                    <ActivityPointProgramTabLink $active={activeTab === tabKey}
                                                 $level={currentLevel}>{text}</ActivityPointProgramTabLink>
                </ActivityPointProgramTabItem>)
            }
        </ActivityPointProgramTabWrapper>
    );
};

export default ActivityPointProgramTab;
