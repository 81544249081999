import { cn } from "helpers/cn";
import React from "react";

interface SlateCardSquadLogoProps {
    text?: string,
    zIndex: number,
    isMoreThanMaxRenderAmount?: boolean,
    className?: string
}

const JoinContestCardTeam: React.FC<SlateCardSquadLogoProps> = ({
                                                                    text,
                                                                    isMoreThanMaxRenderAmount,
                                                                    zIndex,
                                                                    className
                                                                }) => {
    return <div
        className={cn(
            "tw-w-7 tw-h-7 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-z-[var(--zIndex)] tw-text-caption-2",
            className,
            {
                "tw-bg-gradient-to-r tw-from-secondary-50 tw-to-primary-100": !isMoreThanMaxRenderAmount,
                "tw-bg-gradient-to-r tw-from-primary-150 tw-to-secondary-150": isMoreThanMaxRenderAmount,
            }
        )}
        style={{ "--zIndex": `${zIndex}`, } as any}
        data-testid="join-contest-card-syntax"
    >
        {text}
    </div>;
};
const MAX_SQUAD_AMOUNT_ON_SCREEN = 4;

export const JoinContestCardTeams: React.FC<{ teams: string[] }> = ({ teams }) => {
    const isSquadsMoreThanMaxRenderAmount = teams.length > MAX_SQUAD_AMOUNT_ON_SCREEN;
    const renderSquads = isSquadsMoreThanMaxRenderAmount ? teams.slice(0, MAX_SQUAD_AMOUNT_ON_SCREEN - 1) : teams;

    return <div className="tw-flex tw-pt-1">
        {
            renderSquads.map((name, idx: number) => {
                return <JoinContestCardTeam
                    key={name}
                    text={name}
                    className={cn({ "tw--ml-1": idx !== 0 })}
                    zIndex={renderSquads.length - idx}
                />;
            })
        }
        {
            isSquadsMoreThanMaxRenderAmount &&
            <JoinContestCardTeam
                text={`+${teams.length - MAX_SQUAD_AMOUNT_ON_SCREEN + 1}`}
                zIndex={0}
                isMoreThanMaxRenderAmount
                className={"tw--ml-[0.2rem]"}
            />
        }
    </div>;

};
