import { useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { updateWallet } from "store/actions/Actions";
import { ApiExecuteContext } from "../../apis/ApiContext";
import { fetchWallet } from "../../apis/wallet/WalletApi";
import { WalletVO } from "../../types/wallet/Wallet";

const useUpdateWallet = () => {
    const dispatch = useDispatch();
    const apiExecutor = useContext(ApiExecuteContext);

    return useCallback(async () => {
        await apiExecutor(
            fetchWallet(),
            {
                onSuccess: (wallet: WalletVO) => {
                    dispatch(updateWallet(wallet));
                },
            }
        );
    }, [dispatch, apiExecutor]);

};

export default useUpdateWallet;
