import { FormattedMessage } from "react-intl";
import React from "react";
import { cn } from "helpers/cn";
import { Icon, withdrawBalance } from "helpers/IconHelper";
import { CurrentWinningBalance } from "domain/withdraw/CurrentWinningBalance";
import { Form, FormLabel } from "components/ui/form";
import { FantasyTextField } from "designToken/textFields/FantasyTextField";
import { FantasySelect } from "designToken/textFields/FantasySelect";
import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";
import { formatToTextGroups } from "helpers/stringFormatHelpers";
import { WithdrawHintCard } from "domain/withdraw/WithdrawHintCard";
import useCurrency from "hooks/useCurrency/useCurrency";
import { useSelector } from "store";
import {
    accountNumberOnChangeValidator,
    FORM_FIELD_ACCOUNT_BANK,
    FORM_FIELD_ACCOUNT_NAME,
    FORM_FIELD_ACCOUNT_NUMBER
} from "domain/withdraw/hooks/useWithdrawStep1Form";
import { useWithdrawContext } from "domain/withdraw/WithdrawProvider";
import IconButton, { IconButtonFor } from "components/buttons/IconButton";
import { useDevAutofillAccount } from "domain/withdraw/hooks/useDevAutofillAccount";
import WithdrawLayout from "domain/withdraw/WithdrawLayout";

export const WithdrawStep1: React.FC = () => {
    const { banks, step1Form, withdrawSettings, handleGoBack, handleStepNext } = useWithdrawContext();

    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();
    const { WalletState: { winning } } = useSelector(state => state);

    const isWinningInsufficient = winning < withdrawSettings.minimumWithdrawAmount;

    const handleNextStep = step1Form.handleSubmit(handleStepNext);

    const handleDevAutoFillAccount = useDevAutofillAccount();

    return (
        <div className={"tw-pb-[4rem]"} data-testid="step1Wrapper">
            <WithdrawLayout
                topSlot={
                    <div
                        className={cn("tw-flex tw-flex-col tw-items-center", "tw-bg-grey-50", "tw-rounded-bl-[8rem]")}>
                        <div className="tw-w-[12.8rem]" onClick={handleDevAutoFillAccount}>
                            <img src={withdrawBalance} alt="withdraw-balance-illustration" />
                        </div>
                        <CurrentWinningBalance winning={toWalletNumber(winning)}
                                               isWinningInsufficient={isWinningInsufficient} />
                    </div>
                }
                bottomSlot={
                    <div className={cn("tw-pt-[5rem] tw-px-7", "tw-flex tw-flex-col tw-gap-3 tw-m-auto")}>
                        <Form {...step1Form} >
                            <div>
                                <FormLabel
                                    className={"tw-text-body-1"}
                                    htmlFor={FORM_FIELD_ACCOUNT_NAME}>
                                    <FormattedMessage id="withdraw_to_account_label_account_name" />
                                </FormLabel>
                                <FantasyTextField inputProps={{
                                    id: FORM_FIELD_ACCOUNT_NAME,
                                    className: "tw-placeholder-grey-600 tw-bg-grey-50",
                                }} name={FORM_FIELD_ACCOUNT_NAME} />
                            </div>
                            <div>
                                <FormLabel
                                    className={"tw-text-body-1"}
                                    htmlFor={FORM_FIELD_ACCOUNT_BANK}>
                                    <FormattedMessage id="withdraw_to_account_label_account_bank" />
                                </FormLabel>
                                <FantasySelect
                                    inputProps={{
                                        id: FORM_FIELD_ACCOUNT_BANK,
                                        className: "tw-bg-grey-50"
                                    }}
                                    options={banks.map(bank => ({
                                        value: bank.code,
                                        label: bank.bank,
                                    }))}
                                    name={FORM_FIELD_ACCOUNT_BANK} />
                            </div>
                            <div>
                                <FormLabel
                                    className={"tw-text-body-1"}
                                    htmlFor={FORM_FIELD_ACCOUNT_NUMBER}>
                                    <FormattedMessage id="withdraw_to_account_label_account_number" />
                                </FormLabel>
                                <FantasyTextField
                                    name={FORM_FIELD_ACCOUNT_NUMBER}
                                    onChangeValidator={accountNumberOnChangeValidator}
                                    inputProps={{
                                        id: FORM_FIELD_ACCOUNT_NUMBER,
                                        className: "tw-placeholder-grey-600 tw-bg-grey-50",
                                        value: formatToTextGroups(step1Form.watch(FORM_FIELD_ACCOUNT_NUMBER))
                                    }}
                                />
                            </div>
                            <div className={"tw-pt-3"}>
                                <WithdrawHintCard />
                            </div>
                        </Form>

                        <div className={cn("tw-pt-[2.8rem]", "tw-flex tw-gap-3 tw-items-center")}>
                            <IconButton
                                for={IconButtonFor.ON_LIGHT}
                                background={false}
                                icon={() => <Icon.ArrowLeft size={"3.6rem"} className={"tw-text-[3.6rem]"} />}
                                onClick={handleGoBack}
                            />
                            <Button
                                className={"tw-w-full"}
                                pattern={BUTTON_PATTERN.pill}
                                variant={BUTTON_VARIANTS.primaryLight}
                                dataTestId={"nextButton"}
                                size={BUTTON_SIZE.lg}
                                onClick={handleNextStep}
                            >
                                <FormattedMessage id="button_next" />
                            </Button>
                        </div>
                    </div>
                } />
        </div>
    );
};
