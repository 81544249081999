import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";

import { Icon } from "helpers/IconHelper";
import { cn } from "helpers/cn";

const Checkbox = React.forwardRef<
    React.ElementRef<typeof CheckboxPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
    <CheckboxPrimitive.Root
        ref={ref}
        className={cn(
            "tw-peer tw-checkbox tw-shrink-0 tw-rounded-sm tw-ring-offset-background",
            "focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2",
            "disabled:tw-cursor-not-allowed disabled:tw-bg-grey-100 disabled:tw-text-grey-250 disabled:tw-border-0",
            "data-[state=checked]:tw-bg-primary data-[state=checked]:tw-text-primary-foreground",
            className
        )}
        {...props}
    >
        <CheckboxPrimitive.Indicator

            className={cn("tw-flex tw-items-center tw-justify-center tw-text-current")}
        >
            <Icon.Check />
        </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;


export { Checkbox };
