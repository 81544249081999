export const APP_MAX_WIDTH = "550px";
export const APP_MAX_WIDTH_VALUE = 550;
export const APP_MIN_WIDTH_VALUE = 375;
export const MOBILE_BREAKPOINT = 768;

export const PLAYER_AVATAR_WIDTH = "5.6rem";

export const SYSTEM_COLORS = {
    specialAp: "var(--cl-special-ap)",

    primary: "var(--cl-primary)",//$primary: #040663;
    primary60: "var(--cl-primary60)",//$primary60: #66689F;
    primary40: "var(--cl-primary40)",//$primary40: #9B9BC1;
    primary4: "var(--cl-primary4)",

    secondary: "var(--cl-secondary)",
    secondary80: "var(--cl-secondary80)",
    secondary20: "var(--secondary-20)",
    secondary8: "var(--cl-secondary8)",

    bgLightPrimary: "var(--cl-bg-light-primary)",
    bgLightSecondary: "var(--cl-bg-light-secondary)",
    bgLightTertiary: "var(--cl-bg-light-tertiary)",
    primary80: "var(--cl-primary80)",
    primary8: "var(--cl-primary8)",

    labelLightPrimary: "var(--cl-label-light-primary)",
    labelLightSecondary: "var(--cl-label-light-secondary)",
    labelLightTertiary: "var(--cl-label-light-tertiary)",
    labelLightQuaternary: "var(--cl-label-light-quaternary)",

    labelDarkPrimary: "var(--cl-label-dark-primary)",

    neutral200: "var(--cl-neutral200)",
    neutral600: "var(--cl-neutral600)",

    green: "var(--cl-green)",
    red: "var(--cl-red)",
};
