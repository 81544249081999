import { defaultLocale } from "locales";
import { SPORT } from "types/sports/Sport";

const sportLinkMap = {
    [SPORT.BASKETBALL]: "nba",
    [SPORT.CRICKET]: "cricket",
    [SPORT.FOOTBALL]: "football"
};

const LEGEND_BASE_URL = `https://www.legend-fantasy.com`;
const FANTASY_SYSTEM_LINKS = {
    choosePlayerRule: (sport, locale = defaultLocale) => {
        return `${LEGEND_BASE_URL}/sport/${sportLinkMap[sport]}`;
    },
    youtubeLink: "https://m.youtube.com/@Legend-Fantasy/videos",
    apProgram: (locale = defaultLocale) =>
        `${LEGEND_BASE_URL}/game/levelsystem`,
    chatroomGuideline: (locale = defaultLocale) =>
        `${LEGEND_BASE_URL}/term`,
    chatroomThailandLineOfficialAccount: () => "https://lin.ee/gKyxHlk",
    referAFriend: (locale = defaultLocale) =>
        `${LEGEND_BASE_URL}/event/refer`,
    contactUs: `${LEGEND_BASE_URL}/faq`,
    // TODO: check locale
    termsAndConditions: (locale = defaultLocale) =>
        `${LEGEND_BASE_URL}/term`,
    // TODO: check locale
    privacyPolicy: (locale = defaultLocale) =>
        `${LEGEND_BASE_URL}/privacy`,
    firstTimePurchase: (locale = defaultLocale) =>
        `${LEGEND_BASE_URL}/event/first-deposit`,
    app: "legendfantasy://main"
};

const DAILY_BASE_URL = 'https://www.playdailyfantasy.com';

const DAILY_SYSTEM_LINKS = {
    choosePlayerRule: (sport, locale = defaultLocale) => {
        return `${DAILY_BASE_URL}/howtoplay/${sportLinkMap[sport]}`;
    },
    apProgram: (locale = defaultLocale) => DAILY_BASE_URL,
    chatroomGuideline: (locale = defaultLocale) => "",
    chatroomThailandLineOfficialAccount: (locale = defaultLocale) => "",
    referAFriend: (locale = defaultLocale) => DAILY_BASE_URL,
    contactUs: `${DAILY_BASE_URL}/faq`,
    termsAndConditions: (locale = defaultLocale) => `${DAILY_BASE_URL}/terms-conditions`,
    privacyPolicy: (locale = defaultLocale) => `${DAILY_BASE_URL}/privacy-policy`,
    firstTimePurchase: (locale = defaultLocale) => "",
    app: "legendfantasy://main"
};

const linkMap = {
    legend: FANTASY_SYSTEM_LINKS,
    daily: DAILY_SYSTEM_LINKS,
};

function getSystemLinks() {
    if (!window.FANTASY_GAME_CONFIG()?.name) {
        return FANTASY_SYSTEM_LINKS;
    }
    return linkMap[window.FANTASY_GAME_CONFIG().name] || FANTASY_SYSTEM_LINKS;
}

const SYSTEM_LINKS = getSystemLinks();
export default SYSTEM_LINKS;
