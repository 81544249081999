import { Content } from "domain/result/detail/components/Info/component/Content";
import { InfoDetailText } from "domain/result/detail/components/Info/component/InfoDetailText";
import { Ranking } from "domain/result/detail/components/Info/component/Ranking";
import { useResultMatchDetailContext } from "domain/result/detail/ResultMatchDetailProvider";
import { getSportConfig } from "domain/SportConfig";
import usePlayerInfoDialog from "domain/team/playerInfoDialog/PlayerInfoDialog";
import { PlayerInfoDialogFooterType } from "domain/team/playerInfoDialog/PlayerInfoDialogFooter";
import { PlayerInfoDialogHeaderType } from "domain/team/playerInfoDialog/PlayerInfoDialogHeader";
import { cn } from "helpers/cn";
import { formatToFullYearDateTime } from "helpers/TimeHelper";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "store";
import { SquadPlayer } from "types/player/Player";

export const InfoContent: React.FC = () => {
    const { match, matchResultPlayers, sport } = useResultMatchDetailContext();
    const { data: { myTeams } } = useSelector((store) => store.TeamPreviewState);

    const { PlayerInfoDialog, handlePlayerInfoDialogOpen } = usePlayerInfoDialog(
        PlayerInfoDialogHeaderType.POINT,
        PlayerInfoDialogFooterType.ONLY_CLOSE,
    );

    const sportConfig = getSportConfig(sport);
    const attentionWorthyPlayers = matchResultPlayers?.attentionWorthyPlayers(match?.squadAKey ?? "");
    const players = matchResultPlayers?.sortedPlayers;

    if (!match || !attentionWorthyPlayers || !players) {
        return null;
    }

    return <div className={cn(
        "tw-w-full tw-p-4",
        "tw-flex tw-flex-col tw-gap-4",
        "tw-min-h-screen"
    )}>
        <Content title={<FormattedMessage id="match_info" />}>
            <InfoDetailText
                title={<FormattedMessage id="match_info_series" />}
                value={match.tournament} />
            <InfoDetailText
                title={<FormattedMessage id="match_info_start_time" />}
                value={formatToFullYearDateTime(match.matchStartsAt)} />
            <InfoDetailText
                title={<FormattedMessage id="match_info_venue" />}
                value={match.venue} />
        </Content>

        <Content title={<FormattedMessage id="result_match_info_top_scorers_by_role" />}>
            {
                Object.entries(sportConfig.roles).map(([key, value]) => {
                    return <Ranking
                        key={key}
                        sport={sport}
                        role={value.apiValue}
                        players={attentionWorthyPlayers[value.apiValue] ?? []}
                        onAvatarClick={handlePlayerInfoDialogOpen}
                    />;
                })
            }
        </Content>

        <PlayerInfoDialog
            match={match}
            myTeams={myTeams}
            players={
                players.map(player => (new SquadPlayer({
                    ...player,
                    sport: sport,
                    isHome: player.squadKey === match.squadAKey,
                    squadLogoUrl: player.squadKey === match.squadAKey ? match.squadALogoUrl : match.squadBLogoUrl,
                })))
            }
        />
    </div>;
};

