import { createContext } from "react";
import { ApiError } from "apis/Api";

export type Callbacks = {
    onSuccess: (response: any) => void;
    onFail?: ((error: ApiError) => void);
    onFinally: () => void;
}
export type ApiExecutor = (apis: any, callbacks?: Partial<Callbacks>) => Promise<any>

const ApiExecuteContext = createContext<ApiExecutor>((apis, callbacks) => {
    return Promise.resolve();
});

export { ApiExecuteContext };
