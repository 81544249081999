import { auth } from "domain/auth/AuthConfig";
import { signInWithCustomToken } from "firebase/auth";
import { getQuery } from "helpers/queryString";
import useCurrency from "hooks/useCurrency/useCurrency";
import useFantasyHistory from "hooks/useFantasyHistory";
import { useLocaleProvider } from "LocaleProvider";
import { defaultLocale, languages, LOCALE } from "locales";
import { ParsedQs } from "qs";
import { useEffect } from "react";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { getContestDetailPageUrl } from "router/RouterUrls";
import { CURRENCY } from "store/reducer/config/FantasyGameConfigState";

function getDirectUrl(page: ParsedQs[string], params: ParsedQs[string]) {
    if (page === "contest" && !!params) {
        return getContestDetailPageUrl(params as string);
    }
}

const B2bLogin = () => {
    const history = useFantasyHistory();
    const parsedQuery = getQuery();
    const {
        // NOTE: defaultCurrencyFormat from backend and must in url query
        defaultCurrencyFormat,
        currency,
        locale,
        token: customToken,
        page,
        params,
    } = parsedQuery;

    const { setLocale } = useLocaleProvider();
    const { onCurrencyChange, currencies } = useCurrency();

    useEffect(() => {
        const getB2bCurrency = () => {
            return currencies.find((item) => item.key === currency)
                ? currency as CURRENCY
                : defaultCurrencyFormat as CURRENCY;
        };

        const getB2bLanguage = () => {
            return languages.find((language) => language.key === locale)
                ? locale as LOCALE
                : defaultLocale;
        };

        signInWithCustomToken(auth, customToken as string)
            .then(() => {
                onCurrencyChange(getB2bCurrency());
                setLocale(getB2bLanguage());

                history.replace(ROUTER_PATH_CONFIG.home);

                const directUrl = getDirectUrl(page, params);
                if (directUrl) {
                    history.push(directUrl);
                }
            })
            .catch((e) => {
                // TODO, error handling
                console.log("e: ", e);
                const statusCode = e?.code || ""; // 預設 statusCode 為 ""
                history.replace(ROUTER_PATH_CONFIG.auth.index, { statusCode });
            });

    }, [customToken, history, onCurrencyChange, setLocale, currencies, locale, currency]);

    return <div>loading...</div>;
};

export default B2bLogin;
