import styled from "styled-components";

export const CloseButton = styled.div.attrs({
    className: "position-absolute"
})`
  top: 2.4rem;
  right: 1.6rem;
`;
export const ImageWrapper = styled.div.attrs({
    className: "text-center"
})`
  padding: 2.4rem 6.2rem 1.2rem 6.2rem;
`;
export const TextWrapper = styled.div.attrs({
    className: "fz-sf-bold-title-3 text-light-primary text-center"
})`
  padding: 1.2rem 2.4rem;
`;
export const ButtonWrapper = styled.div.attrs({
    className: "d-flex gap-3 flex-column"
})`
  padding: 2.4rem;
`;
