import React from "react";
import { MATCH_STATUS_FROM_API } from "../../../types/match/Match";
import { MyTeamVO } from "../../../types/team/MyTeam";
import { TeamCard } from "../../card/TeamCardStyle";
import TeamCardContent from "../../team/TeamCardContent";
import { TeamName, TeamNameWrapper } from "../../team/TeamCardStyle";
import { TeamCardWrapper } from "../SelectTeamPanelStyle";

interface JoinedCardProps {
    team: MyTeamVO;
    matchStatus: MATCH_STATUS_FROM_API;
}

export const JoinedCard: React.FC<JoinedCardProps> = ({
                                                          team,
                                                          matchStatus
                                                      }) => {

    return <TeamCardWrapper data-testid={`my-team-${team.id}`}
                            data-joined
                            className="fantasy-select-team-card-border"
    >
        <TeamCard className="fantasy-select-team-card-bg">
            <TeamCardContent team={team} matchStatus={matchStatus}>
                <TeamNameWrapper $highlight>
                    <TeamName>{team.name}</TeamName>
                </TeamNameWrapper>
            </TeamCardContent>
        </TeamCard>
    </TeamCardWrapper>;
};
