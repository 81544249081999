import { Tournament } from "helpers/TournamentHelper";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { ModalBodyWrapper } from "domain/home/components/tournamentFilterDialog/TournamentFilterDialogStyle";
import { FilterTournamentDialogCheckBox } from "components/tournament/FilterTournamentDialogCheckBox";

import { TournamentFilterProps } from "components/tournament/types";
import { CheckboxState } from "../checkbox/types";

export const TournamentFilter: React.FC<TournamentFilterProps> = ({
                                                                      selectAllState,
                                                                      tournaments,
                                                                      setFilteredTournaments,
                                                                      generateDisplayText,
                                                                      isPreparingMatchesSwitchOn = false
                                                                  }) => {
    const intl = useIntl();
    const handleAll = () => {
        const isAllChecked = [CheckboxState.CHECKED].includes(selectAllState);
        setFilteredTournaments(tournaments.map(tournament => ({
            ...tournament,
            checked: !isAllChecked
        })));
    };

    const handleFilterClicked = (checked: boolean, filterItem: Tournament) => {
        setFilteredTournaments(tournaments.map(tournament => {
            if (tournament.id === filterItem.id) {

                return {
                    ...tournament,
                    checked
                };
            }

            return tournament;
        }));
    };

    const totalMatchesCount = useMemo(() => {
        return tournaments.reduce((result, tournament) => {
            result.scheduled += tournament.scheduledMatches;
            result.preparing += tournament.preparingMatches;
            result.completed += tournament.completedMatches;

            return result;
        }, {
            scheduled: 0,
            preparing: 0,
            completed: 0
        });
    }, [tournaments]);

    return <ModalBodyWrapper>
        <div className="tw-flex">
            <FilterTournamentDialogCheckBox onChange={handleAll}
                                            checked={selectAllState === CheckboxState.CHECKED}
                                            label={generateDisplayText({
                                                modalShowPreparingMatches: isPreparingMatchesSwitchOn,
                                                name: intl.formatMessage({ id: "tournament_filter_dialog_label_select_all" }),
                                                scheduledMatches: totalMatchesCount.scheduled,
                                                preparingMatches: totalMatchesCount.preparing,
                                                completedMatches: totalMatchesCount.completed
                                            })}
                                            testId={"tournament-select-all"} />
        </div>
        {
            tournaments.map((filterItem) => {
                return <FilterTournamentDialogCheckBox key={`tournament-${filterItem.id}`}
                                                       checked={filterItem.checked}
                                                       label={generateDisplayText({ modalShowPreparingMatches: isPreparingMatchesSwitchOn, ...filterItem })}
                                                       onChange={(checked) => handleFilterClicked(checked, filterItem)}
                                                       testId={`tournament-${filterItem.id}`} />;
            })
        }
    </ModalBodyWrapper>;
};
