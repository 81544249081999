import { fetchPayMongoOrderStatus, initializePayMongoPayment } from "data/api/payment/PayMongoApi";
import { ORDER_STATUS } from "domain/coin/AddCoinHelper";

interface InitPaymentParams {
    productId: number;
    successUrl: string;
    cancelUrl: string;
}

export class PayMongoRepository {
    public async getOrderStatus(paymentReference: string): Promise<ORDER_STATUS> {
        return await fetchPayMongoOrderStatus(paymentReference);

    }

    public async initPayment(request: InitPaymentParams): Promise<string> {
        return await initializePayMongoPayment({
            coinProductId: request.productId,
            successUrl: request.successUrl,
            cancelUrl: request.cancelUrl
        });
    }
}
