import React from "react";
import { useIntl } from "react-intl";
import CountLabel from "components/relation/CountLabel";
import { StraightLine } from "components/straightLine";
import { useReferralProvider } from "domain/me/referral/ReferralProvider";
import useCurrency from "hooks/useCurrency/useCurrency";
import { ReferralCountItem, ReferralCountWrapper } from "domain/me/referral/components/ReferralStyle";
import useLatestJoinedReferee from "hooks/useLatestRefereeJoin";


const ReferralCounterBar: React.FC = () => {
    const intl = useIntl();
    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();

    const { myRefereeSummaries, latestReferralTime, totalReceivedRuby } = useReferralProvider();

    const { hasNewRefereeJoined } = useLatestJoinedReferee(latestReferralTime);

    return (
        <ReferralCountWrapper>
            <ReferralCountItem>
                <CountLabel label={intl.formatMessage({ id: "label_referrals" })}
                            count={myRefereeSummaries.length}
                            isUnread={hasNewRefereeJoined} />
            </ReferralCountItem>
            <StraightLine $height={2.7} />
            <ReferralCountItem data-testid="total-received-rubies">
                <CountLabel label={intl.formatMessage({ id: "invite_friend_page_label_received" })}
                            count={toFormattedNumber(totalReceivedRuby)} />
            </ReferralCountItem>
        </ReferralCountWrapper>

    );
};

export default ReferralCounterBar;
