import { useSelector } from "react-redux";
import { RootStoreState } from "store/reducer";

const useUserIdentity = () => {

    const userState = useSelector((store: RootStoreState) => store.UserState);

    // TODO should not use isB2C
    return {
        isB2B: userState.isB2B,
        /** @deprecated */
        isB2C: userState.isB2C,
    };
};

export default useUserIdentity;
