import { FONT } from "designToken/font";
import React from "react";

export enum CHECKBOX_VARIANTS {
    secondary = "secondary",
    white = "white",
    primary = "primary",
}

export enum CHECKBOX_SHAPE {
    square = "square",
    circle = "circle",
}


export interface FantasyCheckboxProps {
    variant: CHECKBOX_VARIANTS;
    shape?: CHECKBOX_SHAPE;
    text?: string;
    textFont?: FONT;
    checked?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    onCheckedChange?: (checked: boolean) => void;
    children?: React.ReactNode;
}

export const CheckboxState = {
    CHECKED: "checked",
    UNCHECKED: "unchecked",
};
