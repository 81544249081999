export enum MATCH_STATUS_FROM_API {
    PREPARING = "PREPARING",
    SCHEDULED = "SCHEDULED",
    KICKOFF = "KICKOFF",
    LIVE = "LIVE",
    PROCESSING = "PROCESSING",
    COMPLETED = "COMPLETED",
}

export interface ResultMatchVO {
    matchId: string
    tournament: string
    tournamentId: number
    matchStartsAt: number
    squadAName: string
    squadACode: string
    squadALogoUrl: string
    squadAScores: string
    squadAOvers?: string
    squadBName: string
    squadBCode: string
    squadBLogoUrl: string
    squadBScores: string
    squadBOvers?: string
    winningSquadCode: string
    matchStatus: MATCH_STATUS_FROM_API
}

export interface MatchVO {
    matchId: string
    tournament: string
    venue: string
    tournamentPeriodStr: string
    squadAName: string
    squadACode: string
    squadAKey: string
    squadALogoUrl: string
    squadAScores: string | null
    squadBName: string
    squadBCode: string
    squadBKey: string
    squadBLogoUrl: string
    squadBScores: string | null
    matchStartsAt: number
    winningSquadCode: string
    matchStatus: MATCH_STATUS_FROM_API
    squadAOvers?: string
    squadBOvers?: string
}

export interface ResultMatchesGroupByStartDate {
    matchStartDate: string;
    matches: ResultMatchVO[];
    offset: number;
}
