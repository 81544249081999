import useCurrency from "hooks/useCurrency/useCurrency";
import SwipeButton from "components/buttons/SwipeButton";
import { FormattedMessage } from "react-intl";
import React, { ReactNode } from "react";
import { cn } from "helpers/cn";
import { Icon, IconOmitProps, withdrawBalance } from "helpers/IconHelper";
import IconButton, { IconButtonFor } from "components/buttons/IconButton";
import {
    TermsAndConditionLink
} from "domain/contest/components/ConfirmationDialog/ConfirmationModal/TermsAndCondition";
import useFantasyConfig from "hooks/useFantasyConfig";
import { useWithdrawContext } from "domain/withdraw/WithdrawProvider";
import WithdrawLayout from "domain/withdraw/WithdrawLayout";
import { formatToTextGroups } from "helpers/stringFormatHelpers";

export function WithdrawStep3() {
    const { devToggle } = useFantasyConfig();
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();

    const {
        sendToInfo,
        withdrawAmountInfos,
        handleGoToFirstStep,
        handleGoBack,
        handleStepNext,
    } = useWithdrawContext();

    const SendToSection = () => <section className={"tw-flex tw-flex-col tw-gap-2"}>
        <div className="tw-flex tw-items-center tw-gap-2">
            <h2 className="tw-text-caption-1 tw-font-bold">
                <FormattedMessage id="label_send_to" />
            </h2>
            <IconButton
                for={IconButtonFor.ON_LIGHT}
                data-testid={"toStep1Button"}
                iconSize={"1rem"}
                background={true}
                className={cn("tw-w-[2rem] tw-h-[2rem] !tw-p-0", "tw-flex tw-items-center tw-justify-center")}
                icon={BlackIconEditFilled}
                onClick={handleGoToFirstStep} />
        </div>
        <LabelAndTitle
            label={<FormattedMessage id="withdraw_to_account_label_account_name" />}
            title={sendToInfo.accountName} />
        <LabelAndTitle
            label={<FormattedMessage id="withdraw_to_account_label_account_bank" />}
            title={sendToInfo.selectedBank} />
        <LabelAndTitle
            label={<FormattedMessage id="withdraw_to_account_label_account_number" />}
            // TODO, to VO
            title={formatToTextGroups(sendToInfo.accountNumber)} />
    </section>;

    const AmountSection = () => (
        <section className={"tw-flex tw-flex-col tw-gap-2"}>
            <div className="tw-flex tw-items-center tw-gap-2">
                <h2 className="tw-text-caption-1 tw-font-bold">
                    <FormattedMessage id="label_amount" />
                </h2>
                <IconButton
                    for={IconButtonFor.ON_LIGHT}
                    data-testid={"backToEditButton"}
                    iconSize={"1rem"}
                    background={true}
                    className={cn("tw-w-[2rem] tw-h-[2rem] !tw-p-0", "tw-flex tw-items-center tw-justify-center")}
                    icon={BlackIconEditFilled}
                    onClick={handleGoBack} />
            </div>
            <div className="tw-flex tw-justify-between tw-text-subtitle-1">
                <h3>
                    <FormattedMessage id="label_withdrawal" />
                </h3>
                <div>{toWalletNumber(withdrawAmountInfos.withdrawAmount)}</div>
            </div>
            <div className="tw-flex tw-justify-between tw-text-subtitle-1">
                <h3>
                    <FormattedMessage id="label_transaction_fee" />
                </h3>
                <div>{toWalletNumber(withdrawAmountInfos.transactionFee * -1)}</div>
            </div>
            <div
                className={cn("tw-flex tw-justify-between",
                    "tw-pt-1",
                    "tw-border-t-[1px] tw-border-t-grey-150 tw-border-solid",
                    "tw-text-h6 tw-font-bold"
                )}
            >
                <h3 className={"tw-text-h6 tw-font-bold"}>
                    <FormattedMessage id="label_receive_amount" />
                </h3>
                <div>{toWalletNumber(withdrawAmountInfos.receiveAmount)}</div>
            </div>
        </section>
    );

    return (
        <div data-testid="step3Wrapper">
            <WithdrawLayout
                topSlot={(
                    <div
                        className={cn("tw-flex tw-flex-col tw-items-center", "tw-pb-[1rem]", "-tw-mb-[4rem]", "tw-bg-grey-50", "tw-rounded-bl-[8rem]")}>
                        <div className="tw-w-[12.8rem]">
                            <img src={withdrawBalance} alt="withdraw-balance-illustration" />
                        </div>
                    </div>
                )}
                bottomSlot={(
                    <div className={"tw-px-6"}>
                        <div
                            className={cn("tw-relative tw-z-2", "tw-pt-[5rem]", "tw-flex tw-flex-col tw-gap-7 tw-m-auto tw-px-7")}>
                            <SendToSection />
                            <AmountSection />
                            <div className={"tw-text-overline"}>
                                <TermsAndCondition />
                            </div>
                        </div>
                        <div>
                            <SwipeButton disabled={false} onSuccess={handleStepNext}>
                                <FormattedMessage id="withdraw_button_confirm" />
                            </SwipeButton>
                            {devToggle && (
                                <button data-testid={"withdrawStep3DevNextButton"}
                                        className={"tw-hidden"}
                                        onClick={handleStepNext}>Next</button>
                            )}
                        </div>
                    </div>
                )} />
        </div>
    );
}

function BlackIconEditFilled(props: IconOmitProps) {
    return <Icon.EditFilled
        {...props}
        style={{ color: "black" }}
    />;
}

function LabelAndTitle({ label, title }: {
    label: ReactNode
    title: ReactNode
}) {
    return <div>
        <p className="tw-text-caption-1 tw-text-grey-600">
            {label}
        </p>
        <h3 className="tw-text-subtitle-1 tw-text-grey-800">
            {title}
        </h3>
    </div>;
}

function TermsAndCondition() {
    return <p className={"tw-text-center tw-text-grey-600"}>
        <FormattedMessage id="withdraw_to_account_terms_and_condition_1" />
        &nbsp;
        <TermsAndConditionLink />
        &nbsp;
        <FormattedMessage id="withdraw_to_account_terms_and_condition_2" />
    </p>;
}
