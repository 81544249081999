import styled from "styled-components";

export const SelectedAmount = styled.div.attrs({
    className: "fz-sf-regular-body-1"
})`
  color: inherit;
`;

export const AmountValue = styled.span.attrs({
    className: "fz-sf-bold-title-1"
})``;
