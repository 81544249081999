import { UserId } from "Common";
import { CurrentApLevelDto } from "types/activityPoint/CurrentApLevelDto";
import { MyApInfo } from "../../types/activityPoint/MyApInfo";
import { MyApOverview } from "../../types/activityPoint/MyApOverview";
import { fetchCurrentApLevelListUrl, fetchMyApInfoUrl, fetchMyApOverviewUrl } from "../ApiUrlConfig";
import { ajax } from "../ApiUtils";

export const fetchMyApOverview = (): Promise<MyApOverview> => {
    return ajax<MyApOverview>({ url: fetchMyApOverviewUrl })
        .then((res) => res.data.data)
        .catch(error => {
            throw error;
        });
};

export const fetchMyApInfo = (): Promise<MyApInfo> => {
    return ajax<MyApInfo>({ url: fetchMyApInfoUrl })
        .then(({ data }) => data.data)
        .catch(error => {
            throw error;
        });

};

export const fetchCurrentApLevelList = (userIds: UserId[]): Promise<CurrentApLevelDto[]> => {
    return new Promise((resolve, reject) => {
        if (userIds.length === 0) {
            resolve([]);
            return;
        }

        ajax<CurrentApLevelDto[]>({
            method: "POST",
            url: fetchCurrentApLevelListUrl,
            data: { userIds }
        })
            .then(({ data }) => resolve(data.data))
            .catch(error => reject(error));
    });
};
