import { referralColorfulSteamersImg, referralGlareImg } from "helpers/IconHelper";
import { ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { APP_MAX_WIDTH } from "styles/Constants";

export const ReferralHead = styled.div.attrs({
    className: "w-100 position-fixed top-0 start-0 d-flex flex-column",
})`
  max-width: ${APP_MAX_WIDTH};
  padding: 9.7rem 4.8rem 8rem;
  background: url(${referralColorfulSteamersImg}) no-repeat, url(${referralGlareImg}) no-repeat, linear-gradient(0deg, #063575 0%, #02214A 100%); //TODO change to tailwind
  background-size: 100% auto;
  gap: 1rem;
`;

export const ReferralImgWrapper = styled.div.attrs({
    className: "mx-auto"
})`
  width: 20rem;
  min-height: 15rem;
`;
export const ReferralHeadInfoWrapper = styled.div.attrs({
    className: "mx-auto"
})`
  width: 29.5rem;
`;


export const ReferralBody = styled.div.attrs({
    className: "w-100 position-relative z-2"
})`
  margin-top: 40.8rem;
`;

export const ReferralBodyContainer = styled.div.attrs({
    className: "pt-5 pb-4 px-4 d-flex flex-column bg-light-primary"
})`
  height: calc(100vh - 6.5rem);
  border-radius: 1.6rem 1.6rem 0 0;
  gap: 1.2rem;
`;
export const ReferralDescriptionItemImgWrapper = styled.div.attrs({
    className: "flex-shrink-0 align-self-start"
})`
  width: 6rem;
  height: 6rem;
`;

export const ReferralButtonWrapper = styled.div.attrs({
    className: "position-fixed w-100 px-4 pb-5 z-2 start-0 bottom-0"
})`
  max-width: ${APP_MAX_WIDTH}
`;


export const RefereeListWrapper = styled.div.attrs({
    className: "d-flex flex-column "
})`
  padding: 1.6rem 1.6rem 10rem;
  gap: 1.2rem;
`;

export const RefereeListPromotionText = styled.div.attrs({
    className: "fz-sf-regular-subhead text-center text-light-secondary mx-auto"
})`
  width: 60%;
`;

export const RefereeListItemWrapper = styled.div.attrs({
    className: "d-flex align-items-center py-3 px-4 bg-light-primary gap-2 mb-3 border-light-primary border"
})`
  border-radius: 5px;
`;


export const ReferralCountWrapper = styled.div.attrs({
    className: "d-flex justify-content-center align-items-center mx-auto"
})`
  width: 24rem;
`;

export const ReferralCountItem = styled.div.attrs({
    className: "d-flex justify-content-center position-relative"
})`
  flex: 0 0 50%;
`;
export const ReferralCountItemLink = styled(Link).attrs({
    className: "position-relative"
})`
  align-self: center;
`;
export const RubyProgressBar = styled(ProgressBar).attrs({
    className: "my-2"
})`
  height: .4rem;
`;
