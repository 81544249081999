import { FirstTimePurchaseDto } from "apis/deposit/DepositApi";
import CircleButton from "components/buttons/CircleButton";
import { StyledCircleButtonBadge, StyledWithdrawLockedBadge } from "components/buttons/CircleButtonStyle";
import { SupportLink } from "components/supportLink/SupportLink";
import { KycToWithdrawPopupFactory } from "domain/kyc/components/KycToWithdrawPopupFactory";
import {
    StyledDivider,
    StyledRegisterDate,
    StyledRegisterLabel,
    StyledWalletAction,
    StyledWalletBalance,
    StyledWalletInfo,
    StyledWalletOperation,
} from "domain/me/components/WalletInfo/WalletInfoStyle";
import { useMe } from "domain/me/me/MeProvider";
import { WalletBalanceSection } from "domain/wallet/components/button/WalletButton";
import { WalletDetailContent } from "domain/wallet/components/button/WalletDetailContent";
import {
    walletApLevelImg,
    walletDepositImg,
    walletStoreImg,
    walletTransferImg,
    walletWithdrawImg,
} from "helpers/IconHelper";
import { formatToMMMYYYY } from "helpers/TimeHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import React, { ReactNode, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { useSelector } from "store";
import { isVerified } from "types/kyc/KycStatus";

interface IWalletAction {
    image: string;
    text: any;
    onClick: () => void;
    isHidden?: boolean;
    badgeComponent?: ReactNode;
}

const getFirstTimePurchaseBadge = (firstTimePurchase: FirstTimePurchaseDto) => (
    <StyledCircleButtonBadge
        data-testid="circle-button-badge">
        {firstTimePurchase?.rubyPercentage || 0}%
    </StyledCircleButtonBadge>
);

const WalletInfo: React.FC = () => {
        const { total, myProfile, firstTimePurchase } = useMe();
        const {
            FantasyGameConfigState: { featureConfig },
        } = useSelector(store => store);
        const history = useFantasyHistory();

        const [isKycVerifyDialogOpen, setIsKycVerifyDialogOpen] = useState<boolean>(false);
        const handleCloseKycVerifyDialog = () => {
            setIsKycVerifyDialogOpen(false);
        };

        const isKycCompleted = isVerified(myProfile.kycStatus);

        const handleWithdrawOnClick = () => {
            setIsKycVerifyDialogOpen(true);

            if (!isKycCompleted) {
                return;
            }

            history.push(ROUTER_PATH_CONFIG.withdraw);
        };

        const walletActions = useMemo<IWalletAction[]>(() => {
                return [
                    {
                        image: walletDepositImg,
                        text: <FormattedMessage id="label_add_deposit" />,
                        onClick: () => {
                            history.push({
                                pathname: ROUTER_PATH_CONFIG.deposit.select,
                            });
                        },
                        isHidden: !featureConfig.addCoin,
                        badgeComponent: firstTimePurchase?.eligible ? getFirstTimePurchaseBadge(firstTimePurchase) : null
                    },
                    {
                        image: walletTransferImg,
                        text: <FormattedMessage id="label_transfer" />,
                        onClick: () => {
                            history.push(ROUTER_PATH_CONFIG.userProfile.transfer);
                        },
                        isHidden: !featureConfig.transfer
                    },
                    {
                        image: walletWithdrawImg,
                        text: <FormattedMessage id="wallet_view_button_withdraw" />,
                        onClick: handleWithdrawOnClick,
                        isHidden: !featureConfig.withdrawWinning,
                        badgeComponent: isKycCompleted
                            ? null
                            : <StyledWithdrawLockedBadge data-testid="withdraw-locked-img-badge" />
                    },
                    {
                        image: walletApLevelImg,
                        text: <FormattedMessage id="wallet_view_button_ap_level" />,
                        onClick: () => {
                            history.push(ROUTER_PATH_CONFIG.activityPoint);
                        },
                    },
                    {
                        image: walletStoreImg,
                        text: <FormattedMessage id="wallet_view_button_store" />,
                        onClick: () => {
                            history.push(ROUTER_PATH_CONFIG.reward.index);
                        },
                    },
                ];
            }, [firstTimePurchase, history, featureConfig?.addCoin, isKycCompleted]
        );

        return (
            <StyledWalletInfo>
                <StyledWalletOperation>
                    <WalletBalanceSection total={total} />
                    <StyledWalletAction>
                        {walletActions.map(({ image, text, onClick, badgeComponent, isHidden }) => isHidden ? null : (
                            <CircleButton
                                key={image}
                                image={image}
                                text={text}
                                onClick={onClick}
                                badgeComponent={badgeComponent}
                            />
                        ))}
                    </StyledWalletAction>
                    <StyledDivider />
                    <StyledRegisterLabel>
                        <FormattedMessage
                            id="wallet_view_register_label"
                            values={{
                                date: (
                                    <StyledRegisterDate>
                                        {formatToMMMYYYY(myProfile.registeredAt)}
                                    </StyledRegisterDate>
                                ),
                            }}
                        />
                    </StyledRegisterLabel>
                </StyledWalletOperation>
                <StyledWalletBalance>
                    <WalletDetailContent />
                </StyledWalletBalance>
                <SupportLink />
                <KycToWithdrawPopupFactory isShow={isKycVerifyDialogOpen} onClose={handleCloseKycVerifyDialog} />
            </StyledWalletInfo>);
    }
;

export default WalletInfo;
