import { cn } from "../../helpers/cn";
import React, { ReactNode, useMemo, useState } from "react";
import { howToPlayStep3, Icon } from "helpers/IconHelper";
import { FormattedMessage } from "react-intl";
import { Callback } from "Common";
import { SPORT } from "types/sports/Sport";
import { getTableDataListBySport, TeamStructureTable } from "domain/howToPlay/TeamStructureTable";
import StepWrapper from "domain/howToPlay/StepWrapper";

interface SportTabProps {
    sport: SPORT
    selected?: boolean
    icon: ReactNode
    sportName: ReactNode
    onChooseSport: Callback
}

const SportTab: React.FC<SportTabProps> = ({
                                               sport, selected, icon, sportName, onChooseSport
                                           }) => {
    return <button
        data-testid={`sport-tab-${sport}`}
        className={
            cn("tw-h-8 tw-px-[2.2rem]",
                "tw-text-b2b-primary-300",
                "tw-flex tw-gap-1 tw-justify-start tw-items-center",
                "tw-overflow-clip tw-rounded-full",
                "tw-transition-all tw-duration-500 tw-ease-in-out",
                selected ? "tw-max-w-[14rem]" : "tw-max-w-8 tw-px-2",
                selected ? "tw-bg-b2b-primary-50" : "tw-bg-grey-100",
            )
        }
        onClick={onChooseSport}
    >
        <div className={"tw-shrink-0"}>
            {icon}
        </div>
        <p className={
            cn("tw-text-caption-2 tw-font-bold",
                "tw-shrink-0",
                selected ? "tw-visible" : "tw-invisible"
            )
        }>
            {sportName}
        </p>
    </button>;
};

export const Step3 = () => {
    const [chosenSport, setChosenSport] = useState(SPORT.CRICKET);

    function handleChooseSport(sport: SPORT) {
        setChosenSport(sport);
    }

    const sportTabPropList: SportTabProps[] = useMemo(() => [
        {
            sport: SPORT.CRICKET,
            icon: <Icon.HowToPlayCricket />,
            sportName: <FormattedMessage id={"sport_tab_label_cricket"} />,
            onChooseSport: () => handleChooseSport(SPORT.CRICKET)
        },
        {
            sport: SPORT.FOOTBALL,
            icon: <Icon.HowToPlayFootball />,
            sportName: <FormattedMessage id={"sport_tab_label_football"} />,
            onChooseSport: () => handleChooseSport(SPORT.FOOTBALL)
        },
        {
            sport: SPORT.BASKETBALL,
            icon: <Icon.HowToPlayBasketball />,
            sportName: <FormattedMessage id={"sport_tab_label_basketball"} />,
            onChooseSport: () => handleChooseSport(SPORT.BASKETBALL)
        },
    ], []);

    return <StepWrapper
        number={3}
        title={<FormattedMessage id={'how_to_play_step_3_title'} />}
        subtitle={<FormattedMessage id={'how_to_play_step_3_subtitle'} />}
    >
        <img src={howToPlayStep3} alt={'howToPlayStep3'} />
        <div className={"tw-flex tw-flex-col tw-justify-center tw-items-center"}>
            <h3 className={"tw-text-h6 tw-font-bold tw-text-b2b-primary-300"}>
                <FormattedMessage id={'team_structure_title'} />
            </h3>
            <div className={
                cn("tw-flex tw-justify-center tw-gap-[1rem]",
                    "tw-pt-[1rem]"
                )
            }>
                {sportTabPropList.map((sportTabProp, i) => (
                    <SportTab key={i} {...sportTabProp} selected={chosenSport === sportTabProp.sport} />
                ))}
            </div>
            <div className={"tw-pt-[2rem] tw-w-full tw-px-[5.6rem]"}>
                <TeamStructureTable tableDataList={getTableDataListBySport(chosenSport)} />
            </div>
        </div>
    </StepWrapper>;
};
