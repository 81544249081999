import Dialog, { DIALOG_SIZE } from "designToken/dialog/Dialog";
import {
    ExplanationDetail,
    ExplanationIcon,
    ExplanationIconWrapper,
    ExplanationText,
    ModalBody,
    ModalContainer,
    ModalTitle
} from "domain/match/pages/upcoming/contest/public/ExplanationModalStyle";
import {
    firstBlackIcon,
    multipleEntryBlackIcon,
    oneTeamBlackIcon,
    singleEntryBlackIcon,
    vipBlackIcon,
    winRateBlackIcon
} from "helpers/IconHelper";
import { uniqueId } from "lodash";
import React from "react";
import { MdDiamond, MdGroupAdd } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { PrimarySolidButton } from "styles/component/ButtonsStyle";
import useUserIdentity from "../../../../../../hooks/useUserIdentity";

export const EXPLANATIONS = [
    {
        icon: firstBlackIcon,
        text: <FormattedMessage id="contest_item_view_state_explanation_label_first_prize" />
    },
    {
        icon: winRateBlackIcon,
        text: <FormattedMessage id="contest_item_view_state_explanation_label_win_rate" />
    },
    {
        icon: singleEntryBlackIcon,
        text: <FormattedMessage id="contest_item_view_state_explanation_label_single_entry" />
    },
    {
        icon: multipleEntryBlackIcon,
        text: <FormattedMessage id="contest_item_view_state_explanation_label_multiple_entry" />
    },
    {
        icon: vipBlackIcon,
        text: <FormattedMessage id="contest_item_view_state_explanation_label_ap_level" />
    },
    {
        icon: oneTeamBlackIcon,
        text: <FormattedMessage id="contest_item_view_state_explanation_label_prize_hunter" />
    },
    {
        icon: <MdGroupAdd size="1.6rem" />,
        text: <FormattedMessage id="contest_item_view_state_explanation_label_minimum_slip" />
    },
    {
        icon: <MdDiamond size="1.6rem" />,
        text: <FormattedMessage id="contest_item_view_state_explanation_label_allow_discount_with_ruby" />
    },
];

export const B2B_EXPLANATIONS = [
    {
        icon: firstBlackIcon,
        text: <FormattedMessage id="contest_item_view_state_explanation_label_first_prize" />
    },
    {
        icon: winRateBlackIcon,
        text: <FormattedMessage id="contest_item_view_state_explanation_label_win_rate" />
    },
    {
        icon: singleEntryBlackIcon,
        text: <FormattedMessage id="contest_item_view_state_explanation_label_single_entry" />
    },
    {
        icon: multipleEntryBlackIcon,
        text: <FormattedMessage id="contest_item_view_state_explanation_label_multiple_entry" />
    },
    {
        icon: <MdGroupAdd size="1.6rem" />,
        text: <FormattedMessage id="contest_item_view_state_explanation_label_minimum_slip" />
    },
    {
        icon: <MdDiamond size="1.6rem" />,
        text: <FormattedMessage id="contest_item_view_state_explanation_label_allow_discount_with_ruby" />
    },
];

const ExplanationModal = ({ show, onClose }: { show: boolean, onClose: () => void }) => {
    const { isB2B } = useUserIdentity();

    const explanationListData = isB2B ? B2B_EXPLANATIONS : EXPLANATIONS;

    return <Dialog show={show} size={DIALOG_SIZE.LG} backdrop={true} onHide={onClose}>
        <ModalContainer>
            <ModalTitle><FormattedMessage id="contest_item_view_state_explanation_title" /></ModalTitle>
            <ModalBody>
                {
                    explanationListData.map(({ icon, text }) => {
                        return <ExplanationDetail data-testid="explanation" key={uniqueId("explanation-")}>
                            <ExplanationIconWrapper>
                                {typeof icon === "string" ? <ExplanationIcon src={icon} /> : icon}
                            </ExplanationIconWrapper>
                            <ExplanationText>{text}</ExplanationText>
                        </ExplanationDetail>;
                    })
                }
            </ModalBody>
            <PrimarySolidButton onClick={onClose}>
                <FormattedMessage id="button_got_it" />
            </PrimarySolidButton>
        </ModalContainer>
    </Dialog>;
};

export default ExplanationModal;


