import React from "react";
import { FantasyCheckbox } from "../checkbox/FantasyCheckbox";
import { CHECKBOX_VARIANTS } from "../checkbox/types";
import { FONT } from "designToken/font";

interface FilterTournamentDialogCheckBoxProps {
    checked: boolean;
    onChange: (checked: boolean) => void;
    label: string;
    testId?: string;
}

export const FilterTournamentDialogCheckBox = ({
                                                   checked,
                                                   onChange,
                                                   label,
                                                   testId
                                               }: FilterTournamentDialogCheckBoxProps) => {

    return <div data-testid={testId} className="tw-mb-5">
        <FantasyCheckbox variant={CHECKBOX_VARIANTS.secondary}
                         textFont={FONT.subtitle1}
                         text={label}
                         checked={checked}
                         onCheckedChange={(checked => onChange(checked))}
        />
    </div>;
};
