import { MatchCardFooter } from "../../matchCards/MatchCardStyle";
import { PrizeWrapper } from "../../../domain/home/components/upcoming/UpcomingMatchesStyle";
import React from "react";
import { TextPlaceHolder } from "../SkeletonStyle";

export const NormalFooter = () => {
    return <MatchCardFooter>
        <PrizeWrapper>
            <div className="d-flex gap-3">
                <TextPlaceHolder $width={5} />
                <TextPlaceHolder $width={7} />
            </div>
            <div className="d-flex gap-3">
                <TextPlaceHolder $width={5} />
                <TextPlaceHolder $width={5} />
            </div>
        </PrizeWrapper>
    </MatchCardFooter>;
};
