import { readAndCompressImage } from "browser-image-resizer";
import clsx from "clsx";
import React, { ReactNode, useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { MdAddCircle } from "react-icons/md";
import { FormattedMessage } from "react-intl";


interface ImageUploaderProps {
    name?: string;
    fileMaxSizeMB?: number;
    label?: ReactNode;
    initFile?: File | null;
    isError?: boolean;
    onFileChanged: (file: File) => void;
    onSetError?: (err?: string) => void;
}

interface PreviewFile {
    name?: string;
    src: string;
}

export const DEFAULT_IMG_MAX_FILE_SIZE_MB = 1;

const loadFileToImg = (file: File) => {
    return new Promise<string | ArrayBuffer | null>((res, rej) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            if (e.target) {
                return res(e.target.result);
            }
            rej("File parsed failed :(");
        };
        reader.readAsDataURL(file);
    });

};

const bytes = 1024 * 1024;

export const ImageUploader: React.FC<ImageUploaderProps> = ({
                                                                fileMaxSizeMB = DEFAULT_IMG_MAX_FILE_SIZE_MB,
                                                                name,
                                                                initFile,
                                                                label,
                                                                onFileChanged,
                                                                onSetError,
                                                                isError,
                                                            }) => {
    const [file, setFile] = useState<File>();
    const [previewFile, setPreviewFile] = useState<PreviewFile>({
        src: ""
    });

    const handleChangeFile = async (file: File) => {
        const blob = await readAndCompressImage(file);
        const compressedFile = new File([blob], file.name);

        if (compressedFile.size > fileMaxSizeMB * bytes) {
            onSetError && onSetError();
            return;
        }

        setFile(compressedFile);
        onFileChanged(compressedFile);

        try {
            const parsedImg = await loadFileToImg(compressedFile);
            setPreviewFile({
                src: parsedImg as string,
                name: file.name
            });
        } catch (e) {
            onSetError && onSetError();
        }
    };

    useEffect(() => {
        (async () => {
            if (!initFile) return;

            setFile(initFile);
            const parsedFile = await loadFileToImg(initFile);
            setPreviewFile({
                name: initFile.name,
                src: parsedFile as string,
            });
        })();
    }, []);

    const isImgUploaded = !!file;
    return (
        <>
            <FileUploader
                className={"tw-w-full"}
                multiple={false}
                fileOrFiles={file}
                types={["png", "jpeg", "jpg"]}
                handleChange={handleChangeFile}
                name={name}
                onTypeError={onSetError}
                onSizeError={onSetError}
            >
                <div
                    className={
                        clsx("tw-rounded-xl tw-flex-col tw-flex  tw-items-center tw-justify-center tw-min-h-[215px] tw-bg-white overflow-hidden",
                            isError ? "tw-border-solid tw-border-[1px] tw-border-error-700" : ""
                        )
                    }
                >
                    {isImgUploaded
                        ? (
                            <img src={previewFile?.src} alt="preview-file" />
                        )
                        : (
                            <>
                                <MdAddCircle size={56} fill={"#B3B3B3"} />
                                {label &&
                                    <span className={"tw-pt-2 tw-text-[12px] tw-pb-2"}>{label}</span>
                                }
                                <p className={"tw-text-overline"}>
                                    <FormattedMessage id={"placeholder_upload_file_max_size"} values={{
                                        fileMaxSizeMB: fileMaxSizeMB
                                    }} />
                                </p>
                            </>
                        )}
                </div>
            </FileUploader>
            {isImgUploaded && (
                <p className={"tw-text-g-1000 tw-text-caption-1 tw-text-center tw-pt-1"}>
                    <FormattedMessage id={"label_change_image"} />
                </p>
            )}
        </>
    );
};
