import { SupplierLogo } from "components/supplierLogo/SupplierLogo";
import { SUPPLIER } from "components/supplierLogo/type";
import { formatTimeAgo } from "helpers/TimeHelper";
import React from "react";

interface BasketballPlayerNewsProps {
    title: string
    date: number,
    source: string,
    content: string,
    offset?: boolean // todo:先處理讓supplier的popup可以要在右邊還是左邊
}

const BasketballPlayerNews: React.FC<BasketballPlayerNewsProps> = ({
                                                                       title,
                                                                       date,
                                                                       source,
                                                                       content,
                                                                       offset
                                                                   }) => {
    return (
        <div>
            <p className="fz-sf-bold-body-2 text-text-primary-1">
                {title}
            </p>
            <div className="tw-flex tw-items-center tw-mb-2 tw-gap-1">
                <p className="fz-sf-regular-caption-1 text-text-tertiary-1">
                    {formatTimeAgo(date)}
                </p>
                {
                    source === "RotoBaller"
                        ? <SupplierLogo supplier={SUPPLIER.ROTO_BALLER} offset={offset} />
                        : <p className="fz-sf-regular-caption-1 text-text-tertiary-1">
                            | {source}
                        </p>
                }
            </div>

            <p className="fz-sf-regular-subhead text-text-secondary-1">
                {content}
            </p>
            <hr className="tw-mt-2 tw-mb-2 tw-border-t tw-border-system-gray100" />
        </div>
    );
};

export default BasketballPlayerNews;
