import { WalletDetailIcon } from "domain/wallet/components/button/WalletButtonStyle";
import { winningIcon } from "helpers/IconHelper";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import useUserIdentity from "hooks/useUserIdentity";
import React from "react";
import { MdSwapHoriz } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

interface WinningCostProps {
    currentWinningCent: number,
    balanceWinningCent: number,
}

const WinningCostWrapper = styled.div.attrs(() => ({
    className: "mx-auto d-flex justify-content-start align-items-center bg-gray-2 fz-sf-regular-subhead p-3 gap-3 rounded-2"
}))`
    width: 20.8rem;
`;
const CurrentWinningValue = styled.div.attrs(() => ({
    className: "fz-sf-regular-subhead"
}))`
`;
const BalanceWinningValue = styled.div.attrs(() => ({
    className: "fz-sf-regular-subhead text-sys-success-1"
}))`
`;

const WinningCost: React.FC<WinningCostProps> = ({ currentWinningCent, balanceWinningCent }) => {
    const { isB2B } = useUserIdentity();

    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();

    if (isB2B) {
        return null;
    }

    if (currentWinningCent === balanceWinningCent) {
        return null;
    }

    return (
        <WinningCostWrapper>
            <WalletDetailIcon $icon={winningIcon} />
            <FormattedMessage id="label_winning" />
            <CurrentWinningValue>{toFormattedNumber(divideByOneHundred(currentWinningCent))}</CurrentWinningValue>
            <MdSwapHoriz size={16} />
            <BalanceWinningValue>{toFormattedNumber(divideByOneHundred(balanceWinningCent))}</BalanceWinningValue>
        </WinningCostWrapper>
    );
};

export default WinningCost;
