import AmountDisplay from "containers/amount/AmountDisplay";
import { getCurrencyIconSuitByEntryFeeType } from "containers/amount/CurrencyIconSuitHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";

export const RubyBalance: React.FC<{ value: number }> = ({ value }) => {
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();
    return (
        <div
            className="tw-flex tw-justify-between tw-text-grey-800 tw-text-body-1 tw-font-normal">
            <FormattedMessage id="label_ruby" />
            <AmountDisplay currencyIconSuit={getCurrencyIconSuitByEntryFeeType(ENTRY_FEE_TYPE_FROM_API.RUBY)}>
                {toWalletNumber(value)}
            </AmountDisplay>
        </div>
    );
};
