export class DoubledLinkedMap<Key extends keyof any, Value extends keyof any> {
    keyValMap = {} as Record<Key, Value>;
    valKeyMap = {} as Record<Value, Key>;


    constructor(keyValMap: Record<Key, Value>) {
        this.keyValMap = { ...keyValMap };
        this.valKeyMap = { ...keyValMap } as Record<any, any>;
    }

    set(key, value) {
        if (key === undefined) return;
        if (value === undefined) return;
        this.remove(key);
        this.remove(value);
        this.keyValMap[key] = value;
        this.valKeyMap[value] = key;

        return this;
    }

    get(keyOrVal) {
        return this.keyValMap[keyOrVal] || this.valKeyMap[keyOrVal];
    }

    remove(keyOrVal) {
        const val = this.get(keyOrVal);
        const key = this.get(keyOrVal);

        delete this.keyValMap[keyOrVal];
        delete this.valKeyMap[val];
        delete this.keyValMap[key];
        delete this.valKeyMap[keyOrVal];

        return this;
    }

    getMap() {
        return ({
            ...this.keyValMap,
            ...this.valKeyMap,
        });
    }

    exchange(key1, key2) {
        const val1 = this.get(key1);
        const val2 = this.get(key2);
        this.remove(key1);
        this.remove(key2);
        this.set(key1, val2);
        this.set(key2, val1);
        return this;
    }

    exchangeKeyByVal(key, value) {
        if (!this.checkInMap(key, value)) {
            this.set(key, value);
            return this;
        }
        this.exchange(key, this.get(value));
        return this;
    }

    checkInMap(key, value) {
        return this.get(key) && this.get(value);
    }

    exchangeValByKey(value, key) {
        if (!this.checkInMap(key, value)) {
            this.set(key, value);
            return this;
        }
        const key1 = this.get(value);
        this.exchange(key1, key);
        return this;
    }

    exchangeVals(val1, val2) {
        return this.exchange(val1, val2);
    }

}
