import { UPDATE_TEAM_PREVIEW_CONFIG, UPDATE_TEAM_PREVIEW_DATA, UPDATE_TEAM_PREVIEW_SHOW } from "../../Constants";
import { SPORT } from "../../../types/sports/Sport";
import { BOTTOM_SLOT_TYPE, SQUAD_RIGHT_SLOT_TYPE, TeamPreviewState, TOP_LEFT_SLOT_TYPE } from "./types";

const initialState: TeamPreviewState = {
    show: false,
    data: {
        match: null,
        myTeams: [],
        players: [],
        currentTeamId: null,
        userProfile: null,
        teamInfoForUserProfile: {
            teamId: 0,
            teamName: "",
            scoreCent: 0
        },
        currentContestCode: null,
        isOpenWhenBack: false
    },
    config: {
        sport: SPORT.CRICKET,
        offsetHeight: 6.4,
        topLeftSlotType: TOP_LEFT_SLOT_TYPE.EMPTY,
        squadRightSlotType: SQUAD_RIGHT_SLOT_TYPE.EMPTY,
        bottomSlotType: BOTTOM_SLOT_TYPE.EMPTY,
        onAvatarClick: () => {},
        onCreateTeam: () => {},
        onEditTeam: () => {},
    }
};

const teamPreviewState = (preState = initialState, action): TeamPreviewState => {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_TEAM_PREVIEW_SHOW:
            return { ...preState, show: payload };
        case UPDATE_TEAM_PREVIEW_CONFIG:
            return { ...preState, config: { ...initialState.config, ...payload } };
        case UPDATE_TEAM_PREVIEW_DATA:
            return {
                ...preState, data: {
                    ...preState.data,
                    ...payload
                }
            };
        default:
            return preState;
    }
};

export default teamPreviewState;
