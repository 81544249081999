export interface BezierConfig {
    startPoint: [number, number],
    endPoint: [number, number],
    curvePoint1: [number, number],
    curvePoint2: [number, number]
}

export const threeBezier = (
    progress: number, config: BezierConfig) => {
    const {
        startPoint,
        endPoint,
        curvePoint1,
        curvePoint2
    } = config;

    const [startX, StartY] = startPoint;
    const [endX, endY] = endPoint;
    const [curveX1, curveY1] = curvePoint1;
    const [curveX2, curveY2] = curvePoint2;

    let x =
        startX * (1 - progress) * (1 - progress) * (1 - progress) +
        3 * curveX1 * progress * (1 - progress) * (1 - progress) +
        3 * curveX2 * progress * progress * (1 - progress) +
        endX * progress * progress * progress;
    let y =
        StartY * (1 - progress) * (1 - progress) * (1 - progress) +
        3 * curveY1 * progress * (1 - progress) * (1 - progress) +
        3 * curveY2 * progress * progress * (1 - progress) +
        endY * progress * progress * progress;
    return [x, y];
};
export const findTByX = (targetX: number, config: BezierConfig) => {
    let tMin = 0;
    let tMax = 1;
    let t = 0.5;
    const epsilon = 0.01; // 精度

    while (tMax - tMin > epsilon) {
        const xAtT = threeBezier(t, config)[0];
        if (xAtT < targetX) {
            tMin = t;
        } else {
            tMax = t;
        }
        t = (tMax + tMin) / 2;
    }

    return t;
};

export const findYByX = (targetX: number, config: BezierConfig) => {
    const t = findTByX(targetX, config);
    return threeBezier(t, config)[1];
};
