export enum KYC_STATUS {
    UNVERIFIED = "UNVERIFIED",
    UNREAD = "UNREAD",
    COMPLETED = "COMPLETED",
    UNSUCCESSFUL = "UNSUCCESSFUL",
}

export const isAwait = (status: KYC_STATUS) => [KYC_STATUS.UNREAD].includes(status);

export const isVerified = (status: KYC_STATUS) => status === KYC_STATUS.COMPLETED;

export const isUnverified = (status: KYC_STATUS) => status === KYC_STATUS.UNVERIFIED;

export const isUnsuccessful = (status: KYC_STATUS) => status === KYC_STATUS.UNSUCCESSFUL;
