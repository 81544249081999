import { divideByOneHundred } from "helpers/number/NumberHelper";
import { UPDATE_WALLET_DATA } from "../../Constants";

export interface WalletStateProps {
    coinCent: number,
    winningCent: number,
    rubyCent: number,
    totalCent: number,

    coin: number,
    winning: number,
    ruby: number,
    total: number,
    lastCalledTimestamp: number | null
}

const init: WalletStateProps = {
    coinCent: 0,
    winningCent: 0,
    rubyCent: 0,
    totalCent: 0,

    coin: 0,
    winning: 0,
    ruby: 0,
    total: 0,
    lastCalledTimestamp: null
};

const WalletState = (prevState = init, action) => {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_WALLET_DATA:
            const coinCent = payload.coinCent;
            const winningCent = payload.winningCent;
            const rubyCent = payload.rubyCent;

            const totalCent = coinCent + winningCent;
            const coin = divideByOneHundred(coinCent);
            const winning = divideByOneHundred(winningCent);
            const ruby = divideByOneHundred(rubyCent);
            const total = divideByOneHundred(totalCent);
            return {
                coinCent,
                winningCent,
                rubyCent,
                totalCent,
                coin,
                winning,
                ruby,
                total,
                lastCalledTimestamp: new Date().getTime()
            };
        default:
            return prevState;
    }

};

export default WalletState;
