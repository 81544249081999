import { Contest } from "apis/match/type";
import { UpcomingMatchEmptyContest } from "components/empty/UpcomingMatchEmptyContest";
import PageViewRecorder from "components/ga/PageViewRecorder";
import { JoinContestButtonLayout } from "containers/joinContestButtonLayout/JoinContestButtonLayout";
import TeamPreview from "containers/teamPreview";
import { ContestRepository } from "data/repository/contest/ContestRepository";
import { ContestDetailVo } from "data/vo/contest/ContestDetailVo";
import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";
import ContestInfoDetail from "domain/contest/join/info/detail/ContestInfoDetail";
import { contestTypeComparator, entryFeeComparator, } from "helpers/SortingHelper";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import { orderBy } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { FixedFooter } from "styles/component/GeneralStyle";
import { MyContestVO } from "types/contest/Contest";
import { ContestDetailVO } from "types/contest/ContestDetail";
import { MatchVO } from "types/match/Match";
import { ApiExecuteContext } from "../../../../apis/ApiContext";
import { useLoadingToggle } from "../../../../components/loading/Loading";
import { ContestCarousel } from "../../../contest/components/ContestCarousel";
import { STICK_TOP } from "../../pages/started/MyContestDetailStyle";
import { MyContestWrapper } from "./MyContestStyle";

interface MyContestContentWithJoinButtonProps {
    contests: MyContestVO[];
    match: MatchVO;
    onJoinButtonClick: (
        e: MouseEvent,
        contest: Contest | ContestDetailVO | ContestDetailVo,
    ) => void;
    onNoContestButtonClick?: () => void;
}

const MyContestContentWithJoinButton: React.FC<MyContestContentWithJoinButtonProps> = ({
                                                                                           contests,
                                                                                           match,
                                                                                           onJoinButtonClick,
                                                                                           onNoContestButtonClick
                                                                                       }) => {
    const apiExecutor = useContext(ApiExecuteContext);
    const toggleLoading = useLoadingToggle();
    const teamPreview = TeamPreview.useTeamPreview();

    const [currentContestIdx, setCurrentContestIdx] = useState(
        teamPreview.getContestInitIndex(contests),
    );

    const sortedContests = useMemo(
        () => orderBy(contests, [contestTypeComparator, entryFeeComparator]),
        [contests],
    );

    const contestRepository = new ContestRepository();

    const contestDetail = useFantasyQuery(
        [QUERY_KEY.CONTEST_DETAIL, sortedContests[currentContestIdx]?.code],
        async () => contestRepository.getContestDetail(sortedContests[currentContestIdx]?.code),
        {
            options: {
                enabled: !!sortedContests[currentContestIdx]?.code
            }
        }
    );

    const myContestJoinedTeams = useFantasyQuery(
        [QUERY_KEY.MY_CONTEST_JOINED_TEAM, sortedContests[currentContestIdx]?.code],
        async () => contestRepository.getMyContestJoinedTeam(sortedContests[currentContestIdx]?.code),
        {
            options: {
                enabled: !!sortedContests[currentContestIdx]?.code
            }
        }
    );

    const onContestChange = (swiper) => {
        setCurrentContestIdx(swiper.realIndex);
    };

    useEffect(() => {
        if (sortedContests.length === 0) return;

        teamPreview.updateContestCode(sortedContests[currentContestIdx]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiExecutor, toggleLoading, sortedContests, currentContestIdx]);

    return (
        <>
            <PageViewRecorder title="Match Detail - My Contests" />
            <MyContestWrapper data-testid="my-contest">
                {
                    contestDetail?.data ? (<>
                                <ContestCarousel
                                    currentContest={contestDetail?.data}
                                    contests={sortedContests}
                                    handleCardChange={onContestChange}
                                    matchStatus={match.matchStatus}
                                />
                                <div className="px-4">
                                    <ContestInfoDetail
                                        contest={contestDetail?.data}
                                        myJoinedTeams={myContestJoinedTeams.data || []}
                                        stickyTop={STICK_TOP}
                                    />
                                    <JoinContestButtonLayout
                                        match={match}
                                        contest={contestDetail?.data}
                                        myJoinedTeams={myContestJoinedTeams.data || []}
                                        onJoinButtonClick={(e) => onJoinButtonClick(e, contestDetail?.data)}
                                    >
                                        <FormattedMessage
                                            id="contest_detail_page_button_join_contest"
                                            values={{ contestName: contestDetail?.data.name }}
                                        />
                                    </JoinContestButtonLayout>
                                </div>
                            </>
                        )
                        : (<>
                                <UpcomingMatchEmptyContest />
                                {onNoContestButtonClick && (
                                    <FixedFooter>
                                        <Button
                                            className="w-100"
                                            pattern={BUTTON_PATTERN.pill}
                                            variant={BUTTON_VARIANTS.primary}
                                            size={BUTTON_SIZE.lg}
                                            onClick={onNoContestButtonClick}
                                        >
                                            <FormattedMessage id="wallet_history_page_button_join_contest" />
                                        </Button>
                                    </FixedFooter>
                                )}
                            </>
                        )
                }
            </MyContestWrapper>
        </>
    );
};

export default MyContestContentWithJoinButton;
