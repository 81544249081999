import { ActiveTabItem, TabItem, TabNavWrapper } from "components/tab/CustomTabStyle";
import React, { ReactNode } from "react";

export const TabText = ({ text, count }) => {
    return <>{text}<span className="d-inline-block ms-2">({count})</span></>;
};

export enum HeaderLine {
    top = "top",
    bottom = "bottom"
}

interface Tab {
    tabKey: string,
    text: ReactNode
}

interface CustomTabProps {
    tabs: Tab[],
    onClick: (tabKey: string) => void,
    activeTab: string | null,
    isEvenly?: boolean,
    headerLine?: HeaderLine
}

const CustomTab: React.FC<CustomTabProps> = ({ tabs, onClick, activeTab, isEvenly = false, headerLine = HeaderLine.top}) => {
    return <TabNavWrapper $evenly={isEvenly} $headerLine={headerLine}>
        {
            tabs.map(({ tabKey, text }) => {
                if (activeTab === tabKey) {
                    return <ActiveTabItem key={tabKey} className="nav-tabs-on-text" data-testid={`tab-${tabKey}`}>
                        {text}
                    </ActiveTabItem>;
                }

                return <TabItem
                    key={tabKey}
                    className="nav-tabs-off-text"
                    onClick={() => onClick(tabKey)}
                    data-testid={`tab-${tabKey}`}
                >
                    {text}
                </TabItem>;

            })
        }
    </TabNavWrapper>;
};

export default CustomTab;
