import { ajax } from "apis/ApiUtils";
import { fetchBasketballCurrentSeasonUrl } from "apis/ApiUrlConfig";
import { BasketballCurrentSeasonDto } from "data/dto/season/BasketballCurrentSeasonDto";

export const fetchBasketballCurrentSeason = async (): Promise<BasketballCurrentSeasonDto> => {
    const { data: { data } } = await ajax<BasketballCurrentSeasonDto>({
        url: fetchBasketballCurrentSeasonUrl
    });
    return data;
};
