import { useDispatch } from "react-redux";
import { useSelector } from "store";
import { updateLiveChatVisibility } from "store/actions/Actions";

declare global {
    interface Window {
        myCustomFunction: (arg: string) => void;
        LC_API: {
            open_chat_window: () => void
        };
    }
}

const useLiveChat = () => {
    const { visibility } = useSelector(store => store.LiveChatState);
    const dispatch = useDispatch();

    const openChat = () => {
        dispatch(updateLiveChatVisibility("maximized"));
    };

    const closeChat = () => {
        dispatch(updateLiveChatVisibility("hidden"));
    };

    return {
        visibility,
        openChat,
        closeChat,
    };
};

export default useLiveChat;
