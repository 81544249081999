import { FantasyCheckbox } from "components/checkbox/FantasyCheckbox";
import { CHECKBOX_SHAPE, CHECKBOX_VARIANTS } from "components/checkbox/types";
import { SlatePlayerAvatar } from "domain/slate/components/SlatePlayerAvatar";
import { MySlateTeamVo } from "domain/slate/data/vo";
import { cn } from "helpers/cn";
import { Icon } from "helpers/IconHelper";
import React, { useState } from "react";
import useMyProfile from "../../../hooks/useMyProfile";
import SlateTeamPopup from "./SlateTeamPopup";

interface SlateTeamSelectorProps {
    team: MySlateTeamVo;
    hasCheckbox?: boolean;
    checked?: boolean;
    onClick?: (teamId: number) => void;
}

export const NineCatTeamSelector: React.FC<SlateTeamSelectorProps> = ({
                                                                          team,
                                                                          hasCheckbox = true,
                                                                          checked,
                                                                          onClick
                                                                      }) => {
    const { myProfile } = useMyProfile();
    const [open, setOpen] = useState(false);
    return (
        <div>
            <div
                className={cn(
                    "tw-rounded-tl-[1.6rem] tw-rounded-tr-[1.6rem] tw-overflow-hidden  tw-transition-all tw-select-none",
                    {
                        "tw-bg-grey-0": checked,
                        "tw-bg-grey-100": !checked,
                        "tw-cursor-pointer": !!onClick
                    }
                )}
                onClick={() => onClick?.(team.id)}
                data-testid="slate-team-selector"
            >
                <div className={cn("tw-py-2 tw-px-4 tw-flex tw-items-center")}>
                    {
                        hasCheckbox && (
                            <div>
                                <FantasyCheckbox
                                    variant={CHECKBOX_VARIANTS.primary}
                                    shape={CHECKBOX_SHAPE.circle}
                                    checked={checked}
                                />
                            </div>
                        )
                    }

                    <div className="tw-flex tw-items-center tw-flex-[50%]">
                        <div className="tw-text-caption-1 tw-font-medium tw-text-grey-1000 tw-mr-2">
                            {team.name}
                        </div>
                        <div className="tw-flex tw-flex-wrap tw-gap-[.2rem] tw-w-[14rem]">
                            {
                                team.playerAvatars.map((avatar: string, index: number) => {
                                    return <SlatePlayerAvatar
                                        key={avatar}
                                        url={avatar}
                                        selected={checked}
                                        className={cn("tw-z-[var(--zIndex)]", {
                                            "tw--ml-2": index % 5 !== 0
                                        })}
                                        style={{ "--zIndex": `${team.playerAvatars.length - index}` } as any}
                                    />;
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={cn("tw-rounded-bl-[1.6rem] tw-rounded-br-[1.6rem] tw-flex tw-justify-center tw-py-[1px] tw-bg-grey-1000/[0.04] tw-cursor-pointer")}
                onClick={() => setOpen(true)}
            >
                <Icon.TextSearch size="1.4rem" className="tw-text-grey-600" />
            </div>

            <SlateTeamPopup open={open} onOpenChange={setOpen} team={team} username={myProfile.nickname} />
        </div>
    );
};
