import { useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { updateCoupon } from "store/actions/Actions";
import { ApiExecuteContext } from "../../apis/ApiContext";
import { fetchMyCoupon } from "../../apis/coupon/CouponApi";

const useUpdateCoupon = () => {
    const dispatch = useDispatch();
    const apiExecutor = useContext(ApiExecuteContext);

    return useCallback(() => {
        return new Promise<void>((resolve, reject) => {
            apiExecutor(
                fetchMyCoupon(),
                {
                    onSuccess: (coupon) => {
                        dispatch(updateCoupon(coupon));
                        resolve();
                    }
                }
            );
        });
    }, [dispatch, apiExecutor]);

};

export default useUpdateCoupon;
