import { TaskProgressDto } from "data/dto/task/TaskProgressDto";
import { TASK_PROGRESS, TASK_TYPE } from "types/task/TaskType";

export class TaskProgressVo {
    public readonly claimed: number;
    public readonly progress: TASK_PROGRESS;
    public readonly taskType: TASK_TYPE;
    public readonly code: string;

    constructor(taskProgress: TaskProgressDto) {
        this.claimed = taskProgress.claimed;
        this.progress = taskProgress.progress;
        this.taskType = taskProgress.taskType;
        this.code = taskProgress.code;
    }

    public get isAvailable() {
        return this.progress === TASK_PROGRESS.AVAILABLE;
    }

    public get isClaimable() {
        return this.progress === TASK_PROGRESS.CLAIMABLE;
    }

    public get isCompleted() {
        return this.progress === TASK_PROGRESS.COMPLETED;
    }
}

export type TaskProgressVoMap = {
    [key in TASK_TYPE]: TaskProgressVo
}

export class UserTaskProgressVo {
    public readonly incompleteQuantity: number;
    public readonly taskProgresses: TaskProgressVo[];

    constructor(incompleteQuantity: number, taskProgress: TaskProgressVo[]) {
        this.incompleteQuantity = incompleteQuantity;
        this.taskProgresses = taskProgress;
    }

    public getTaskProgress(taskType: TASK_TYPE, code: string): TaskProgressVo {
        return this.taskProgresses.find((taskProgress) => taskProgress.taskType === taskType && taskProgress.code === code)!;
    }
}
