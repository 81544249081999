import {CheckEmailParagraph, CheckEmailTitle, EmailImageContainer} from "components/userDelete/UserDeleteSubmitStyle";
import {MainImage} from "domain/unauthorized/notification/EmailCheckPageStyle";
import {checkEmailImage} from "helpers/IconHelper";
import {FormattedMessage} from "react-intl";
import React from "react";

const EMAIL_CHECK_LIMIT_TIME_MINUTES = 10;

export interface CheckEmailProps {
    email: string
}

export const CheckEmail: React.FC<CheckEmailProps> = ({
                                                          email
                                                      }) => (
    <div className='d-flex flex-column justify-content-center'>
        <EmailImageContainer>
            <MainImage src={checkEmailImage}/>
        </EmailImageContainer>
        <CheckEmailTitle>
            <FormattedMessage id="check_email_page_title"/>
        </CheckEmailTitle>
        {/* TODO: 分行 切段 */}
        <CheckEmailParagraph data-testid="check-email-content">
            <FormattedMessage id="check_email_page_label_check_email_1" values={{email}}/>
            <br/>
            <FormattedMessage id="check_email_page_label_check_email_2_with_var_mins"
                              values={{email, minutes: EMAIL_CHECK_LIMIT_TIME_MINUTES}}/>
            <br/>
            <FormattedMessage id="check_email_page_label_check_email_3"/>
        </CheckEmailParagraph>
    </div>
);