import { getSportKey } from "helpers/SportHelper";
import React, { createContext, useState } from "react";
import { useDispatch } from "react-redux";
import { updateCurrentSport } from "store/actions/Actions";
import { SPORT } from "types/sports/Sport";

export const getSportNameIntlId = (name: string): string => {
    const sportNameKey = name.toLowerCase();

    switch (sportNameKey) {
        case SPORT.CRICKET:
            return "sport_tab_label_cricket";
        case SPORT.FOOTBALL:
            return "sport_tab_label_football";
        case SPORT.BASKETBALL:
            return "sport_tab_label_basketball";

        default:
            return "sport_tab_label_cricket";
    }
};

interface SportProviderProps {
    currentSport: SPORT,
    chooseCurrentSport: (sport: SPORT) => void
}

export const SportContext = createContext<SportProviderProps | null>(null);

const SportProvider = ({ children }) => {
    const [currentSport, setCurrentSport] = useState<SPORT>(getSportKey());
    const dispatch = useDispatch();

    const chooseCurrentSport = (sportKey) => {
        setCurrentSport(sportKey);
        dispatch(updateCurrentSport(sportKey));
    };

    return (
        <SportContext.Provider value={{ currentSport, chooseCurrentSport }}>
            {children}
        </SportContext.Provider>
    );
};

export const useSportProvider = () => {
    const context = React.useContext(SportContext);

    if (context === null) {
        throw new Error("useLocaleProvider must be used within a SportProvider");
    }

    return context;
};

export default SportProvider;
