import Banner, { BANNER_VARIANTS } from "designToken/banner/Banner";
import { Icon } from "helpers/IconHelper";
import { FormattedMessage } from "react-intl";
import React from "react";

export const UnverifiedBanner: React.FC<{ onClick: () => void }> = ({ onClick }) => {
    return <Banner
        variant={BANNER_VARIANTS.warning}
        prefixIcon={Icon.Locked}
        onClick={onClick}
        suffixIcon={Icon.MoveRight}
    >
        <FormattedMessage id="kyc_unverify_hint" />
    </Banner>;
};
