import React from "react";
import { FormattedMessage } from "react-intl";
import { NotificationText, NotificationWrapper } from "./NegativeDepositAlertStyle";

const NegativeDepositAlert = () => {
    return (
        <NotificationWrapper>
            <NotificationText>
                <FormattedMessage id="wallet_view_label_negative_coin_balance" />
            </NotificationText>
        </NotificationWrapper>
    );
};

export default NegativeDepositAlert;
