import MatchInfo from "components/container/navbar/MatchInfo";
import Navbar from "components/container/navbar/Navbar";
import CustomTab, { TabText } from "components/tab/CustomTab";
import TeamPreview from "containers/teamPreview";
import { KickoffCountdown } from "domain/home/components/upcoming/UpcomingMatches";
import Contests from "domain/match/components/contests/Contests";
import MyContestSwitcher from "domain/match/components/myContest/MyContestSwitcher";
import Scorecard from "domain/match/components/scorecard/Scorecard";
import PlayerStats from "domain/match/components/stats/PlayerStats";
import MatchStatusWithDot from "domain/match/components/status/MatchStatusWithDot";
import MyTeamsSwitcher from "domain/match/components/team/MyTeamsSwitcher";
import {
    MatchStatusContainer,
    NavbarBottomWrapper,
    StickySection,
    TabsWrapper
} from "domain/match/pages/started/MyContestDetailStyle";
import { MY_STARTED_MATCH_SUB_TAB } from "domain/match/pages/started/MyStartedMatchHelper";
import { useStartedMatchDetailData } from "domain/match/providers/MyStartedMatchDataProvider";
import { useStartedMatchDetailFlow } from "domain/match/providers/MyStartedMatchFlowProvider";
import { getSportPageConfig } from "domain/SportPageConfig";
import { isMatchKickoff } from "helpers/match/MatchHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import useScrollDetector from "hooks/useScrollDetector";
import React, { ReactElement, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CSSTransition } from "react-transition-group";
import { SPORT } from "types/sports/Sport";

const CreateContestDetailTabs = (sport: SPORT) => {
    const tabs = [
        {
            condition: ({ match }) => isMatchKickoff(match.matchStatus),
            getTabs: () => ({
                tabKey: MY_STARTED_MATCH_SUB_TAB.contests,
                text: <FormattedMessage id="match_detail_page_tab_contests" />,
                content: Contests,
            })
        },
        {
            condition: () => true,
            getTabs: ({ myContests }) => ({
                tabKey: MY_STARTED_MATCH_SUB_TAB.myContests,
                text: <TabText text={<FormattedMessage id="label_my_contests" />} count={myContests.length} />,
                content: MyContestSwitcher,
            })
        },
        {
            condition: () => true,
            getTabs: ({ myTeams }) => ({
                tabKey: MY_STARTED_MATCH_SUB_TAB.myTeams,
                text: <TabText text={<FormattedMessage id="label_my_teams" />} count={myTeams.length} />,
                content: MyTeamsSwitcher,
            })
        },
        {
            condition: () => sport === SPORT.CRICKET,
            getTabs: () => ({
                tabKey: MY_STARTED_MATCH_SUB_TAB.scorecard,
                text: <FormattedMessage id="my_matches_page_started_match_tab_scorecard" />,
                content: Scorecard,
            })
        },
        {
            condition: () => true,
            getTabs: () => ({
                tabKey: MY_STARTED_MATCH_SUB_TAB.playerStats,
                text: <FormattedMessage id="my_matches_page_started_match_tab_player_stats" />,
                content: PlayerStats,
            })
        },
    ];

    return (data): ({
        tabKey: string,
        text: ReactElement,
        content: React.ElementType,
    }[]) => tabs
        .filter(tab => tab.condition(data))
        .map(tab => tab.getTabs(data));
};


const MyContestDetail = () => {
    const { sport } = useStartedMatchDetailFlow();
    const history = useFantasyHistory();
    const startMatchDetailData = useStartedMatchDetailData();
    const teamPreview = TeamPreview.useTeamPreview();
    const publicContests = startMatchDetailData.publicContests;
    const match = startMatchDetailData.match!;
    const myContests = startMatchDetailData.myContests!;
    const myTeams = startMatchDetailData.myTeams!;
    const getTabs = CreateContestDetailTabs(sport);
    const [activeTab, setActiveTab] = useState(history.location?.state?.activeTab ?? MY_STARTED_MATCH_SUB_TAB.myContests);
    const smallNavbarRef = useRef(null);

    const shouldShowSmallNavbar = useScrollDetector();

    const {
        navbarBottom: NavbarBottom,
        quickSummary: QuickSummary,
        navbarStartAppend: NavbarStartAppend
    } = getSportPageConfig(sport).myContestDetail;

    const onTabClick = (tabKey: string) => {
        setActiveTab(tabKey);
        teamPreview.close();
        history.replace({
            state: {
                activeTab: tabKey
            }
        });
    };

    const tabs = getTabs({
        publicContests,
        match,
        myContests,
        myTeams
    });

    const { content: Content } = tabs.find(tab => tab.tabKey === activeTab) || {};


    return <>
        <Navbar className="position-relative border-bottom-0"
                navbarStartAppend={
                    <>
                        <MatchInfo data={match} />
                        {
                            isMatchKickoff(match.matchStatus) &&
                            <div className="ms-2 text-sys-danger-1">
                                <KickoffCountdown matchStartsAt={match.matchStartsAt} handleTimeout={() => {}} />
                            </div>
                        }
                    </>
                }
                navbarBottom={<NavbarBottomWrapper>
                    <NavbarBottom match={match}>
                        <QuickSummary match={match} />
                    </NavbarBottom>
                </NavbarBottomWrapper>}
                shouldCalculatePaddingTop={false}
        />
        <CSSTransition in={shouldShowSmallNavbar}
                       nodeRef={smallNavbarRef}
                       classNames="my-matches-navbar"
                       timeout={400}
                       unmountOnExit
        >
            <Navbar ref={smallNavbarRef}
                    className="border-bottom-0"
                    navbarStartAppend={<NavbarStartAppend match={match} />}
                    endIcon={<MatchStatusContainer>
                        <MatchStatusWithDot matchStatus={match.matchStatus} />
                    </MatchStatusContainer>}
                    shouldCalculatePaddingTop={false}
            />
        </CSSTransition>
        <StickySection $top={6.4}>
            <TabsWrapper>
                <CustomTab tabs={tabs}
                           onClick={onTabClick}
                           activeTab={activeTab} />
            </TabsWrapper>
        </StickySection>

        {Content && <Content onChangeTab={onTabClick} />}
    </>;
};

export default MyContestDetail;
