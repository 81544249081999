import { FirstTimePurchaseDto } from "apis/deposit/DepositApi";
import NegativeDepositAlert from "components/alert/NegativeDepositAlert";
import { KycToWithdrawPopupFactory } from "domain/kyc/components/KycToWithdrawPopupFactory";
import {
    walletContestCodeImg,
    walletDepositImg,
    walletStoreImg,
    walletTransferImg,
    walletWithdrawImg,
} from "helpers/IconHelper";
import { isNegative } from "helpers/number/NumberHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import useMyProfile from "hooks/useMyProfile";
import React, { CSSProperties, ReactNode, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import CircleButton from "../../../components/buttons/CircleButton";
import { StyledCircleButtonBadge, StyledWithdrawLockedBadge } from "../../../components/buttons/CircleButtonStyle";
import { SupportLink } from "../../../components/supportLink/SupportLink";
import B2CWrapper from "../../../containers/b2cWrapper/B2CWrapper";
import useFirstTimePurchase from "../../../hooks/useFirstTimePurchase";
import { useLockScroll } from "../../../hooks/useLockScroll";
import { ROUTER_PATH_CONFIG } from "../../../router/RouterPathConfig";
import { useSelector } from "../../../store";
import { isVerified } from "../../../types/kyc/KycStatus";
import { WalletBalanceSection } from "./button/WalletButton";
import {
    StyledWalletActionsWrapper,
    WalletDetailWrapper,
    WalletOverlay,
    WalletPanelContainer,
    WalletPanelWrapper,
} from "./button/WalletButtonStyle";
import { WalletDetailContent } from "./button/WalletDetailContent";

interface WalletPanelProps {
    walletPanelStyle: CSSProperties;
    handleWalletOverlayClick: () => void;
    handleInvitationCircleButtonClick: () => void;
    handleWithdrawButtonClick: () => void;
}

interface IWalletAction {
    image: string;
    text: any;
    onClick: () => void;
    badgeContent?: string;
    isHidden?: boolean;
    badgeComponent?: ReactNode;
}

const getFirstTimePurchaseBadge = (firstTimePurchase: FirstTimePurchaseDto) => (
    <StyledCircleButtonBadge
        data-testid="circle-button-badge">
        {firstTimePurchase?.rubyPercentage || 0}%
    </StyledCircleButtonBadge>
);


export const WalletPanel: React.FC<WalletPanelProps> = ({
                                                            walletPanelStyle,
                                                            handleWalletOverlayClick,
                                                            handleInvitationCircleButtonClick,
                                                            handleWithdrawButtonClick,
                                                        }) => {
    useLockScroll();
    const history = useFantasyHistory();
    const {
        WalletState: { coin, total },
        FantasyGameConfigState: { featureConfig },
    } = useSelector(store => store);


    const { myProfile: { kycStatus } } = useMyProfile();
    const firstTimePurchase = useFirstTimePurchase();

    const [isKycVerifyDialogOpen, setIsKycVerifyDialogOpen] = useState<boolean>(false);
    const handleCloseKycVerifyDialog = () => {setIsKycVerifyDialogOpen(false);};

    const isKycCompleted = isVerified(kycStatus);

    const handleWithdrawOnClick = () => {
        setIsKycVerifyDialogOpen(true);

        if (!isKycCompleted) {
            return;
        }

        handleWithdrawButtonClick();
    };

    const walletActions = useMemo<IWalletAction[]>(() => {
        return [
            {
                image: walletDepositImg,
                text: <FormattedMessage id="label_add_deposit" />,
                onClick: () => {
                    history.push({
                        pathname: ROUTER_PATH_CONFIG.deposit.select,
                    });
                },
                isHidden: !featureConfig.addCoin,
                badgeComponent: firstTimePurchase?.eligible ? getFirstTimePurchaseBadge(firstTimePurchase) : undefined,
            },
            {
                image: walletTransferImg,
                text: <FormattedMessage id="label_transfer" />,
                onClick: () => {
                    history.push(ROUTER_PATH_CONFIG.userProfile.transfer);
                },
                isHidden: !featureConfig.transfer
            },
            {
                image: walletWithdrawImg,
                text: <FormattedMessage id="wallet_view_button_withdraw" />,
                onClick: () => { handleWithdrawOnClick(); },
                isHidden: !featureConfig.withdrawWinning,
                badgeComponent: isKycCompleted ?
                    null :
                    <StyledWithdrawLockedBadge data-testid="withdraw-locked-img-badge" />
            },
            {
                image: walletContestCodeImg,
                text: <FormattedMessage id="label_contest_code" />,
                onClick: handleInvitationCircleButtonClick,
            },
            {
                image: walletStoreImg,
                text: <FormattedMessage id="wallet_view_button_store" />,
                onClick: () => {
                    history.push(ROUTER_PATH_CONFIG.reward.index);
                },
            },
        ];
    }, [history, handleInvitationCircleButtonClick, firstTimePurchase]);

    return (
        <WalletPanelWrapper style={walletPanelStyle}>
            <WalletOverlay onClick={handleWalletOverlayClick} />
            <WalletPanelContainer>
                {isNegative(coin) && <NegativeDepositAlert />}
                <WalletDetailWrapper>
                    <WalletBalanceSection total={total} />
                    <B2CWrapper>
                        <StyledWalletActionsWrapper>
                            {walletActions.map(({
                                                    image,
                                                    text,
                                                    onClick,
                                                    badgeComponent,
                                                    isHidden
                                                }) => isHidden ? null : (
                                <CircleButton
                                    key={image}
                                    image={image}
                                    text={text}
                                    onClick={onClick}
                                    badgeComponent={badgeComponent}
                                />
                            ))}
                        </StyledWalletActionsWrapper>
                    </B2CWrapper>
                    <WalletDetailContent />
                </WalletDetailWrapper>
                <SupportLink />
            </WalletPanelContainer>
            <KycToWithdrawPopupFactory isShow={isKycVerifyDialogOpen} onClose={handleCloseKycVerifyDialog} />
        </WalletPanelWrapper>
    );
};
