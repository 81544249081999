import { createRoot } from "react-dom/client";
import reportWebVitals from "reportWebVitals";
import Root from "Root";
import "styles/index.scss";
import "styles/tailwind.scss";

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export { durationFormat } from "helpers/TimeHelper";
