import { PaymongoWithdrawFormVo } from "data/vo/payment/PaymongoWithdrawFormVo";
import { payMongoReceivingInstitutions, payMongoWithdrewBegin } from "data/api/payment/PayMongoApi";
import { multiplyByOneHundred } from "helpers/number/NumberHelper";
import { BanksVo } from "data/vo/payment/banksVo";

export class PaymongoWithdrawRepository {

    public async withdrawBegin(form: PaymongoWithdrawFormVo) {

        return await payMongoWithdrewBegin({
            amountCent: multiplyByOneHundred(form.amount),
            bankCode: form.account.bankCode,
            bankAccountName: form.account.name.trim(),
            bankAccountNumber: form.account.number
        });
    }
    
    public async getPayMongoReceivingInstitutions() {
        const response = await payMongoReceivingInstitutions();

        return new BanksVo(response.data.map(receivingInstitution => ({
            code: receivingInstitution.attributes.provider_code,
            bank: receivingInstitution.attributes.name,
        })));
    }

}
