import { EmptyImg, EmptyText, EmptyWrapper } from "./EmptyStyle";
import { emptyMyContest } from "helpers/IconHelper";
import { FormattedMessage } from "react-intl";
import React from "react";

export const StartedMatchEmptyContest = () => {
    return <EmptyWrapper>
        <EmptyImg src={emptyMyContest} />
        <EmptyText>
            <div>
                <FormattedMessage id="my_matches_page_label_empty_my_matches" />
            </div>
            <div>
                <FormattedMessage id="my_matches_page_label_empty_join_contest" />
            </div>
        </EmptyText>
    </EmptyWrapper>;
};
