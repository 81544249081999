import { ApiExecuteContext } from "apis/ApiContext";
import { buyReward } from "apis/reward/RewardApi";
import Portal from "components/portal/Portal";
import {
    ButtonWrapper,
    CancelButton,
    ConfirmButton,
    NotificationModal,
    NotificationModalOverlay,
    NotificationWrapper,
    PresentDepositIcon,
    PresentDepositTitle,
    PresentDepositWrapper,
    ProcessDayText,
    RemindText,
    RewardNameText,
    RewardPriceText,
    SeparateLine,
    SoldOutTitle,
    StatusIcon,
    Text,
    Title
} from "domain/reward/component/PurchaseModalStyle";
import RewardCoins from "domain/reward/component/RewardCoins";
import {
    Country,
    CountryImage,
    GrayScaleWrapper,
    InfoWrapper,
    PreviewImage,
    RewardDetail,
    RewardInfoTitle
} from "domain/reward/component/RewardInfoStyle";
import { SetShowNewOrderDotContext } from "domain/reward/RewardContainer";
import {
    getLabelI18nText,
    isInsufficientRewardError,
    isInsufficientWinningError,
    isSufficient
} from "domain/reward/RewardHelper";
import useUpdateWallet from "domain/wallet/useUpdateWallet";
import { createErrorObject } from "helpers/ErrorHelper";
import { depositIcon, getCountryIcon, popupSuccessIcon, popupWarningIcon } from "helpers/IconHelper";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { addErrorPopup } from "store/actions/AlertActions";
import { RootStoreState } from "store/reducer";
import { REWARD_TYPE } from "types/reward/Reward";
import AmountDisplay from "../../../containers/amount/AmountDisplay";
import { getCurrencyIconSuitByEntryFeeType } from "../../../containers/amount/CurrencyIconSuitHelper";
import { ENTRY_FEE_TYPE_FROM_API } from "../../../types/contest/Contest";

const InsufficientBalance = ({ onButtonClicked }) =>
    <>
        <Title><FormattedMessage id="store_insufficient_balance" /></Title>
        <Text><FormattedMessage id="store_need_more_coins" /></Text>
        <ButtonWrapper>
            <ConfirmButton onClick={onButtonClicked}>
                <FormattedMessage id="button_got_it" />
            </ConfirmButton>
        </ButtonWrapper>
    </>;


const PhysicalRewardInfo = ({ reward }) => {
    return <InfoWrapper>
        <GrayScaleWrapper>
            <CountryImage src={getCountryIcon(reward.country)} />
            <PreviewImage previewUrl={reward.previewUrl} />
        </GrayScaleWrapper>
        <RewardDetail>
            <div>
                <RewardInfoTitle>{reward.title}</RewardInfoTitle>
                <Country>
                    <FormattedMessage id="reward_order_country" />
                    :&nbsp;
                    {getLabelI18nText(reward.country)}
                </Country>
            </div>
            <RewardCoins price={divideByOneHundred(reward.priceCent)} numberFontStyle="fz-sf-bold-title-3" />
        </RewardDetail>
    </InfoWrapper>;
};

const ConfirmPhysicalRewardPurchase = ({ rewardItem, onCancelButtonClicked, onConfirmButtonClicked, isBuying }) => {
    return <>
        <PhysicalRewardInfo reward={rewardItem} />
        <SeparateLine />
        <RemindText><FormattedMessage id="reward_list_page_label_remind_country" /></RemindText>
        <ButtonWrapper>
            <CancelButton disabled={isBuying} onClick={onCancelButtonClicked}>
                <FormattedMessage id="label_cancel" />
            </CancelButton>
            <ConfirmButton disabled={isBuying} onClick={onConfirmButtonClicked}>
                <FormattedMessage id="store_button_buy" />
            </ConfirmButton>
        </ButtonWrapper>
    </>;
};


const VirtualPurchase = ({ deposit, rewardItem, onCancelButtonClicked, onConfirmButtonClicked, isBuying }) => {
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();

    return <>
        <Text>
            <FormattedMessage
                id="store_buy_dialog"
                values={{
                    winning:
                        <RewardPriceText>{toWalletNumber(divideByOneHundred(rewardItem.priceCent))}</RewardPriceText>,
                    rewardTitle: <RewardNameText>{rewardItem.title}</RewardNameText>
                }}
            />
        </Text>
        <PresentDepositWrapper>
            <PresentDepositTitle>
                <PresentDepositIcon icon={depositIcon} />
                <div><FormattedMessage id="store_present_deposit" /></div>
            </PresentDepositTitle>
            <AmountDisplay currencyIconSuit={getCurrencyIconSuitByEntryFeeType(ENTRY_FEE_TYPE_FROM_API.DEPOSIT)}
                           className="tw-gap-1 tw-text-title-2 tw-font-bold">
                {toWalletNumber(deposit)}
            </AmountDisplay>
        </PresentDepositWrapper>
        <ButtonWrapper>
            <CancelButton disabled={isBuying} onClick={onCancelButtonClicked}>
                <FormattedMessage id="label_cancel" />
            </CancelButton>
            <ConfirmButton disabled={isBuying} onClick={onConfirmButtonClicked}>
                <FormattedMessage id="store_button_buy" />
            </ConfirmButton>
        </ButtonWrapper>
    </>;
};

const VirtualRewardTransactionSuccess = ({ deposit, onButtonClicked }) => {
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();

    return <>
        <StatusIcon iconImage={popupSuccessIcon} />
        <Title><FormattedMessage id="store_transaction_successful" /></Title>
        <PresentDepositWrapper>
            <PresentDepositTitle>
                <PresentDepositIcon icon={depositIcon} />
                <div><FormattedMessage id="store_present_deposit" /></div>
            </PresentDepositTitle>

            <AmountDisplay currencyIconSuit={getCurrencyIconSuitByEntryFeeType(ENTRY_FEE_TYPE_FROM_API.DEPOSIT)}
                           className="tw-gap-1 tw-text-title-2 tw-font-bold">
                {toWalletNumber(deposit)}
            </AmountDisplay>
        </PresentDepositWrapper>
        <ButtonWrapper>
            <ConfirmButton onClick={onButtonClicked}>
                <FormattedMessage id="button_got_it" />
            </ConfirmButton>
        </ButtonWrapper>
    </>;
};


const PhysicalRewardTransactionSuccess = ({ onButtonClicked }) =>
    <>
        <StatusIcon iconImage={popupSuccessIcon} />
        <Title><FormattedMessage id="store_transaction_successful" /></Title>
        <Text>
            <FormattedMessage id="store_process"
                              values={{
                                  days: <ProcessDayText>1-14 <FormattedMessage
                                      id={"store_process_days"} /></ProcessDayText>
                              }}
            />
            <div></div>
            <FormattedMessage id="store_email" />
        </Text>
        <ButtonWrapper>
            <ConfirmButton onClick={onButtonClicked}>
                <FormattedMessage id="button_got_it" />
            </ConfirmButton>
        </ButtonWrapper>
    </>;

const PhysicalRewardTransactionFail = ({ onButtonClicked }) =>
    <>
        <StatusIcon iconImage={popupWarningIcon} />
        <Title><FormattedMessage id="label_sorry" /></Title>
        <Text>
            <FormattedMessage id="reward_purchase_fail" />
        </Text>
        <ButtonWrapper>
            <ConfirmButton onClick={onButtonClicked}>
                <FormattedMessage id="store_button_ok" />
            </ConfirmButton>
        </ButtonWrapper>
    </>;

const SoldOut = ({ onButtonClicked }) =>
    <>
        <StatusIcon iconImage={popupWarningIcon} />
        <SoldOutTitle><FormattedMessage id="store_item_sold_out" /></SoldOutTitle>
        <ButtonWrapper>
            <ConfirmButton onClick={onButtonClicked}>
                <FormattedMessage id="button_got_it" />
            </ConfirmButton>
        </ButtonWrapper>
    </>;

const purchaseStage = {
    purchase: "purchase",
    success: "success",
    fail: "fail",
    soldOut: "soldOut",
    insufficientBalance: "insufficientBalance"
};

const getPurchaseBody = (deposit, rewardItem, closeModal, onBuy, onSuccess, onSoldOut, onInsufficientBalance, isBuying) => ({
    [REWARD_TYPE.REDEPOSIT]: {
        [purchaseStage.purchase]: <VirtualPurchase deposit={deposit}
                                                   rewardItem={rewardItem}
                                                   isBuying={isBuying}
                                                   onCancelButtonClicked={closeModal}
                                                   onConfirmButtonClicked={onBuy} />,
        [purchaseStage.success]: <VirtualRewardTransactionSuccess deposit={deposit}
                                                                  onButtonClicked={onSuccess} />,
        [purchaseStage.soldOut]: <SoldOut onButtonClicked={onSoldOut} />,
        [purchaseStage.insufficientBalance]: <InsufficientBalance onButtonClicked={onInsufficientBalance} />
    },
    [REWARD_TYPE.VOUCHER]: {
        [purchaseStage.purchase]: <ConfirmPhysicalRewardPurchase rewardItem={rewardItem}
                                                                 isBuying={isBuying}
                                                                 onCancelButtonClicked={closeModal}
                                                                 onConfirmButtonClicked={onBuy} />,
        [purchaseStage.success]: <PhysicalRewardTransactionSuccess onButtonClicked={onSuccess} />,
        [purchaseStage.fail]: <PhysicalRewardTransactionFail onButtonClicked={onSuccess} />,
        [purchaseStage.soldOut]: <SoldOut onButtonClicked={onSoldOut} />,
        [purchaseStage.insufficientBalance]: <InsufficientBalance onButtonClicked={onInsufficientBalance} />
    }
});

const PurchaseModal = ({ rewardItem, closeModal, onSuccess, onSoldOut }) => {
    const dispatch = useDispatch();
    const { WalletState: { winningCent, coin } } = useSelector((store: RootStoreState) => store);
    const updateShowNewOrderDot = useContext(SetShowNewOrderDotContext);
    const apiExecutor = useContext(ApiExecuteContext);
    const updateWallet = useUpdateWallet();

    const [purchaseBody, setPurchaseBody] = useState(null);
    const [stage, setStage] = useState(() => {
        if (!isSufficient(winningCent, rewardItem.priceCent)) {
            return purchaseStage.insufficientBalance;
        }
        return purchaseStage.purchase;
    });
    const [isBuying, setIsBuying] = useState(false);

    const handleError = useCallback((_error) => {
        if (isInsufficientRewardError(_error)) {
            setStage(purchaseStage.soldOut);
            return;
        }
        if (isInsufficientWinningError(_error)) {
            setStage(purchaseStage.insufficientBalance);
            return;
        }

        if (REWARD_TYPE.VOUCHER === rewardItem.type) {
            setStage(purchaseStage.fail);
            return;
        }

        dispatch(addErrorPopup(createErrorObject(_error)));

    }, [setStage, dispatch, rewardItem.type]);

    const onInsufficientBalance = useCallback(() => {
        updateWallet();
        closeModal();
    }, [updateWallet, closeModal]);

    const onBuy = useCallback(() => {
        setIsBuying(true);

        apiExecutor(
            buyReward(rewardItem.id),
            {
                onSuccess: () => {
                    updateWallet();
                    setStage(purchaseStage.success);
                    updateShowNewOrderDot(true);
                },
                onFail: handleError,
                onFinally: () => {
                    setIsBuying(false);
                }
            }
        );
    }, [updateWallet, apiExecutor, rewardItem, handleError, setIsBuying, updateShowNewOrderDot]);

    useEffect(() => {
        setPurchaseBody(getPurchaseBody(coin, rewardItem, closeModal, onBuy, onSuccess, onSoldOut, onInsufficientBalance, isBuying)[rewardItem.type][stage]);
    }, [stage, coin, rewardItem, closeModal, onBuy, onSuccess, onSoldOut, onInsufficientBalance, isBuying]);

    return <Portal>
        <NotificationWrapper>
            <NotificationModalOverlay />
            <NotificationModal onClick={e => e.stopPropagation()}>
                {purchaseBody}
            </NotificationModal>
        </NotificationWrapper>
    </Portal>;
};

PurchaseModal.propType = {
    rewardItem: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        priceCent: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired
    }),
    closeModal: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onSoldOut: PropTypes.func.isRequired
};

export default PurchaseModal;
