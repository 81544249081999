import { isMatchKickoff } from "helpers/match/MatchHelper";
import React from "react";
import { FormattedMessage } from "react-intl";
import {
    JoinableNavbarWrapper,
    MatchInfoWrapper,
    SquadCode,
    VSText
} from "../../../../components/container/navbar/NavbarStyle";
import { KickoffCountdown } from "../../../home/components/upcoming/UpcomingMatches";


export const isCricketSquadCodeHighlighted = (squadCode, matchDetail) => {
    const { winningSquadCode, battingSquadCode } = matchDetail;
    const isWinner = winningSquadCode === squadCode;
    const isBatting = battingSquadCode === squadCode;
    return isWinner || isBatting;
};
const getScore = (score) => {
    return score ?? <FormattedMessage id="my_contest_detail_page_quick_summary_label_yet_to_bat" />;
};

const CricketNavbarMatchWithScore = ({ match }) => {
    return <JoinableNavbarWrapper>
        <MatchInfoWrapper>
            <SquadCode $highlight={isCricketSquadCodeHighlighted(match.squadACode, match)}>
                {match.squadACode}({getScore(match.squadAScores)})
            </SquadCode>
            <VSText>VS</VSText>
            <SquadCode $highlight={isCricketSquadCodeHighlighted(match.squadBCode, match)}>
                {match.squadBCode}({getScore(match.squadBScores)})
            </SquadCode>
        </MatchInfoWrapper>
        {
            isMatchKickoff(match.matchStatus) && <div className="ms-2 text-sys-danger-1">
                <KickoffCountdown matchStartsAt={match.matchStartsAt} handleTimeout={() => {}} />
            </div>
        }
    </JoinableNavbarWrapper>;
};

export default CricketNavbarMatchWithScore;
