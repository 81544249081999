import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { removeErrorPopup } from "store/actions/AlertActions";
import styled from "styled-components";
import ErrorContent from "../../../../components/error/ErrorContent";
import Dialog from "../../../dialog/Dialog";
import { closeAlertMillisecond } from "../AlertHelper";

const ErrorModalBody = styled.div.attrs(() => ({
    className: "d-flex flex-column align-items-center"
}))`
    padding: 2.4rem;
`;

function ErrorDialog() {
    const dispatch = useDispatch();
    const { AlertState: { errors: stateError } } = useSelector(store => store);

    const [error, setError] = useState({});
    const [showError, setShowError] = useState(false);
    const timer = useRef(null);

    const closeErrorDialog = useCallback(() => {
        dispatch(removeErrorPopup());
        clearTimeout(timer.current);

        setShowError(false);
    }, [dispatch]);

    const onExited = () => {
        if (error.onExited) {
            error.onExited();
        }
        setError({});
    };

    useEffect(() => {
        if (stateError[0]) {
            const _error = stateError[0];

            setError({ ..._error });

            setShowError(true);

            if (_error.autoClose) {
                timer.current = setTimeout(() => {
                    closeErrorDialog();
                }, closeAlertMillisecond);
            }

        }
    }, [stateError, closeErrorDialog]);


    return (
        <Dialog show={showError}
                onHide={closeErrorDialog}
                onExited={onExited}
        >
            <ErrorModalBody>
                <ErrorContent error={error}
                              title={"fz-sf-bold-title-3"} />
            </ErrorModalBody>
        </Dialog>
    );
}

export default ErrorDialog;
