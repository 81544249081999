import React from "react";
import { PrizeListVo } from "data/vo/PrizeList/PrizrListVo";
import { ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";
import { cn } from "helpers/cn";
import { generateRankString } from "components/prizelist/PrizeList";
import AmountDisplay from "containers/amount/AmountDisplay";
import { getCurrencyIconSuitByEntryFeeType } from "containers/amount/CurrencyIconSuitHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import { FormattedMessage } from "react-intl";

export interface PrizeDistributionListProps {
    idealPrizeListLength: number
    prizeList: PrizeListVo[];
    entryFeeType: ENTRY_FEE_TYPE_FROM_API
}

function EmptyPrizeTableRow(props: {}) {
    return <tr data-testid={"empty-prize-list-row"}
               className={cn("tw-border-solid tw-border-[1px] tw-border-b-grey-150 tw-text-primary-800",)}>
        <td className={"tw-py-2 tw-text-left"}>
            -
        </td>
        <td className={"tw-py-2 tw-text-right"}>
            -
        </td>
    </tr>;
}

export const PrizeDistributionList: React.FC<PrizeDistributionListProps> = ({
                                                                                idealPrizeListLength,
                                                                                prizeList,
                                                                                entryFeeType,
                                                                            }) => {
    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();

    const emptyRowLength = idealPrizeListLength - prizeList.length;

    return (
        <div className={cn("tw-bg-grey-100 tw-rounded-lg tw-p-3",
            "tw-text-body-2",
        )}>
            <table className={"tw-w-full"}>
                <thead>
                <tr
                    className={"tw-border-solid tw-border-b-[1px] tw-border-b-grey-150"}
                >
                    <th className={"tw-pb-2 tw-text-left tw-font-normal"}>
                        <FormattedMessage id="contest_detail_page_ranking_label_ranking" />
                    </th>
                    <th className={"tw-pb-2 tw-text-right tw-font-normal"}>
                        <FormattedMessage id="contest_detail_page_ranking_label_prize" />
                    </th>
                </tr>
                </thead>
                <tbody>
                {prizeList.map((prize, i) => (
                    <tr
                        key={i}
                        data-testid="prize-list-row"
                        className={cn("tw-border-solid tw-border-[1px] tw-border-b-grey-150 tw-text-primary-800", {
                            "tw-font-bold": i === 0,
                        })}
                    >
                        <td className={"tw-py-2 tw-text-left"}>
                            #{generateRankString(prize.from, prize.to)}
                        </td>
                        <td className={"tw-py-2 tw-text-right"}>
                            <AmountDisplay
                                currencyIconSuit={getCurrencyIconSuitByEntryFeeType(entryFeeType)}
                                className={"tw-justify-end tw-text-primary-800"}
                            >
                                {toFormattedNumber(prize.prize, 2)}
                            </AmountDisplay>
                        </td>
                    </tr>
                ))}
                {Array(emptyRowLength).fill(0).map((_, i) => (
                    <EmptyPrizeTableRow key={i} />
                ))}
                </tbody>
            </table>
        </div>
    );

};
