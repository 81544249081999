import FantasyBasicPopup from "components/popup/FantasyBasicPopup";
import { NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { presentRuby } from "helpers/IconHelper";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "store";

interface NewUserRubyPopupProps {
    isShow: boolean,
    onClose: () => void
}

const NewUserRubyPopup: React.FC<NewUserRubyPopupProps> = ({ isShow, onClose }) => {
    const intl = useIntl();
    const { newUserGifts } = useSelector(store => store.SettingsState);

    return <FantasyBasicPopup
        show={isShow}
        imageSection={<img src={presentRuby} alt="gift-ruby" className="tw-max-w-[85%]" />}
        title={intl.formatMessage({ id: "new_user_ruby_popup_title" })}
        texts={[
            {
                text: intl.formatMessage({ id: "new_user_ruby_dialog_description" }, { ruby: divideByOneHundred(newUserGifts.rubyCent) }),
            },
        ]}
        buttonSetting={{
            confirmButtonVariant: NEW_BUTTON_VARIANTS.tertiaryLight,
            confirmButtonText: intl.formatMessage({ id: "new_user_ruby_dialog_confirm_btn" }),
            cancelButton: false,
            onConfirm: onClose,
            onCancel: onClose
        }}
        onHide={onClose}
    />;
};

export default NewUserRubyPopup;
