import UserAvatarWithFrame from "components/avatar/UserAvatarWithFrame";
import {
    ChangeNicknameDialog
} from "domain/home/components/FantasyHomeNavbar/B2BHomeNavbar/ChangeNicknameDialog/ChangeNicknameDialog";
import TopNavbarWalletButton from "domain/wallet/components/button/TopNavbarWalletButton";
import { cn } from "helpers/cn";
import { Icon } from "helpers/IconHelper";
import useMyProfile from "hooks/useMyProfile";
import React, { useState } from "react";

const B2BHomeNavbar = () => {
    const { myProfile } = useMyProfile();
    const [isShowDialog, setIsShowDialog] = useState(false);
    const openDialog = () => setIsShowDialog(true);
    const closeDialog = () => setIsShowDialog(false);

    if (!myProfile) {
        return null;
    }

    return (
        <>
            <div
                data-testid="b2b-home-navbar"
                className={cn(
                    "top-navbar",
                    "tw-flex tw-items-center tw-overflow-hidden p-4 header-bg-normal",
                    "tw-shadow-navbar",
                    "tw-bg-b2b-primary-400"
                )}
            >
                <div className="tw-flex-1 tw-flex tw-items-center gap-2">
                    <UserAvatarWithFrame name={myProfile.nickname} size="2.4rem" url={myProfile.avatar} />
                    <div className="tw-text-subtitle-2 tw-font-bold tw-text-white">
                        {myProfile.nickname}
                    </div>
                    <Icon.Edit size={"1.6rem"}
                               className="tw-text-b2b-secondary-400 tw-cursor-pointer"
                               data-testid="edit-icon"
                               onClick={openDialog} />
                </div>
                <TopNavbarWalletButton />
            </div>
            <ChangeNicknameDialog
                show={isShowDialog}
                onClose={closeDialog} />
        </>
    );
};

export default B2BHomeNavbar;
