import React from "react";
import styled from "styled-components";
import { PrimaryFixedButton } from "../../../../styles/component/ButtonsStyle";

export const MyTeamWrapper: React.FC<React.HtmlHTMLAttributes<HTMLDivElement>> = styled.div.attrs(() => ({
    className: ""
}))`
  padding: 1.6rem 1.6rem 9rem;
`;
export const MyTeamCardWrapper: React.FC<React.HtmlHTMLAttributes<HTMLDivElement>> = styled.div.attrs({
    className: "overflow-hidden clickable fantasy-card-border"
})`
  margin-bottom: .8rem;
`;
export const CreateTeamButton = styled(PrimaryFixedButton).attrs({})`
  z-index: 1;
`;
