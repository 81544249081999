import styled from "styled-components";

export const PlayerWrapper = styled.div.attrs(() => ({
    className: "header-top-line"
}))``;

export const PlayersInfoWrapper = styled.div.attrs(() => ({
    className: "d-flex justify-content-between w-100"
}))`
`;
export const PlayersInfoSection = styled.div.attrs(() => ({
    className: "w-100 px-2 my-3"
}))``;

export const PlayerInfo = styled.div.attrs(() => ({
    className: "d-flex justify-content-between fz-sf-regular-footnote text-text-secondary-1"
}))`
`;
export const PlayerName = styled.div.attrs(() => ({
    className: "text-truncate"
}))`
  max-width: 9.2rem;
`;


export const LatestResultWrapper = styled.div.attrs(() => ({
    className: "d-flex"
}))`
  padding-left: .8rem;
`;
const scoreColor = {
    4: "bg-sys-success",
    6: "bg-sys-info",
    W: "bg-sys-danger"
};

const scoreBGColor = (score) => {
    if (scoreColor[score]) return scoreColor[score];
    else if (score) return "bg-opacity-tertiary-2";
    else return "";
};

export const EachBallResult = styled.div.attrs(({ score }) => ({
    className: `d-flex justify-content-center align-items-center fz-sf-bold-subhead border-light-tertiary ${scoreBGColor(score)} text-text-primary-2`
}))`
    border-radius: 4px;
    min-width: 2rem;
    height: 2rem;
    margin: 0 .4rem;
    padding: 0 .2rem;
    border: ${({ score }) => score ? "none" : "1px dashed"};
`;
export const BatsmanBalls = styled.span.attrs(() => ({
    className: "ps-2"
}))``;
export const BowlerOvers = styled.span.attrs(() => ({
    className: "ps-2"
}))`
`;
