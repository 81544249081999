import { Col, Container } from "react-bootstrap";
import styled, { css } from "styled-components";
import { APP_MAX_WIDTH, SYSTEM_COLORS } from "../../../../styles/Constants";

export const WalletButtonWrapper = styled.div.attrs(() => ({
    className: "d-flex align-items-center rounded-pill p-1 gap-2",
}))`
    background-color: #fff;
`;

export const WalletButtonImageWrapper = styled.div.attrs(() => ({
    className: "rounded-circle d-flex justify-content-center align-items-center",
}))`
    width: 2.8rem;
    height: 2.8rem;
    padding: 0.6rem;
`;
export const WalletPanelWrapper = styled.div.attrs({
    className: "position-fixed w-100",
})`
    top: -1px;
    left: 0;
    z-index: 1;
    max-width: ${APP_MAX_WIDTH};
`;

export const WalletOverlay = styled.div.attrs(() => ({
    className: "position-absolute w-100 h-100",
}))`
    background-color: rgba(0, 0, 0, 0.8);
`;

export const WalletPanelContainer = styled(Container).attrs(() => ({
    className:
        "position-absolute w-100 overflow-auto d-flex flex-column justify-content-between tw-bg-system-bgLightPrimary",
}))`
    top: 0;
    border-radius: 0 0 1.6rem 1.6rem;
    padding: 0;
`;
export const WalletBalanceRow = styled.div.attrs(() => ({
    className: "mb-4",
}))``;

export const WalletBalance = styled.div.attrs(() => ({
    className: "d-flex justify-content-center align-items-center",
}))`
    gap: 0.2rem;

    &:first-child {
        margin-bottom: 0.4rem;
    }
`;

export const WalletBalanceTitle = styled.div.attrs(() => ({
    className: "fz-sf-regular-caption-1 text-text-secondary-1",
}))``;

export const WalletDetailWrapper = styled.div.attrs(() => ({}))`
    padding: 1.6rem;
`;
export const WalletContentRow = styled.div.attrs(() => ({
    className: "d-flex align-items-center flex-wrap",
}))`
    padding: 0.6rem 0;
    cursor: pointer;
`;

export const WalletDetailIcon = styled.span.attrs<{
    $icon: string;
}>({
    className: "d-inline-block me-2",
})`
    width: 1.6rem;
    padding-bottom: 1.6rem;
    background-image: url(${({ $icon }) => $icon});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
`;


export const StyledWalletActionsWrapper = styled.div.attrs(() => ({
    className: "w-100 d-flex justify-content-around align-items-start mb-3",
}))``;

export const NavItemCol = styled(Col).attrs({
    className: "d-flex flex-column align-items-center",
})``;

export const WalletCircleButton = styled.div.attrs<{
    $icon: string;
}>({
    className: "tw-border-system-labelLightQuaternary tw-bg-system-bgLightPrimary",
})`
    width: 4.8rem;
    padding-bottom: 4.8rem;
    background: url(${({ $icon }) => $icon}) center no-repeat;
    background-size: 60%;
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
`;

export const WalletCircleButtonWrapper = styled.div.attrs({
    className: "d-flex align-items-center justify-content-center tw-border-system-labelLightQuaternary tw-bg-system-bgLightPrimary",
})`
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
`;


export const StoreButton = styled.div<{
    $icon: string;
}>`
    width: 4.8rem;
    padding-bottom: 4.8rem;
    background: url(${({ $icon }) => $icon}) center no-repeat;
    background-size: 100%;
`;

export const Title = styled.div.attrs(() => ({
    className: "fz-sf-regular-body-1 text-nowrap text-primary-2",
}))``;

export const StyledWalletContentWrapper = styled.div.attrs<{
    $hasDivider?: boolean;
}>({
    className: "d-flex align-items-start justify-content-between pt-3 pb-2",
})`
    cursor: pointer;
    ${({ $hasDivider }) => {
        if ($hasDivider) {
            return css`
                border-bottom: 1px solid ${SYSTEM_COLORS.labelLightQuaternary};
            `;
        }
    }}
`;

export const StyledWalletContentLabelWrapper = styled.div.attrs(() => ({
    className: "d-flex align-items-start gap-2",
}))``;

export const StyledWalletContentImage = styled.div<{
    $icon: string;
}>`
    width: 2.4rem;
    padding-bottom: 2.4rem;
    background-image: url(${({ $icon }) => $icon});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
`;

export const StyledWalletContentLabel = styled.p.attrs(() => ({
    className: "fz-sf-bold-body-1 text-nowrap text-primary-1 mb-0 mt-1 tw-text-left",
}))``;

export const StyledWalletIconWrapper = styled.div.attrs(() => ({
    className: "d-flex align-items-center justify-content-center bg-primary-7",
}))`
    --diameter: 24px;
    width: var(--diameter);
    height: var(--diameter);
    border-radius: 100%;
`;
export const StyledWalletContentNote = styled.p.attrs(() => ({
    className: "fz-sf-regular-caption-1 text-light-secondary mb-0 mt-2",
}))``;

export const StyledWalletContentValueWrapper = styled.div.attrs(() => ({
    className: "d-flex align-items-center gap-2",
}))``;
