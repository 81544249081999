import React from "react";
import { contestCoinNormalIcon, contestRubyNormalIcon } from "helpers/IconHelper";
import { Icon } from "../../TaskStyle";

interface RewardProps {
    rubyAmount?: number
    depositAmount?: number
    isGrayscale?: boolean
}

export const Reward: React.FC<RewardProps> = ({ rubyAmount, depositAmount, isGrayscale }) => {
    return <div className="fz-sf-regular-footnote text-light-secondary d-flex align-items-center gap-2">
        {
            !!depositAmount && <>
                <Icon $src={contestCoinNormalIcon} $isGrayscale={isGrayscale} />
                <div id="deposit" className="font-inherit">+{depositAmount}</div>
            </>
        }
        {
            !!rubyAmount && <>
                <Icon $src={contestRubyNormalIcon} $isGrayscale={isGrayscale} />
                <div id="ruby" className="font-inherit">+{rubyAmount}</div>
            </>
        }
    </div>;
};
