import { FRIEND_TYPE } from "domain/friend/FriendHelper";
import { MatchVO } from "types/match/Match";
import { SquadPlayer } from "types/player/Player";
import { SPORT } from "types/sports/Sport";
import { MyTeamVO } from "types/team/MyTeam";

export enum SQUAD_RIGHT_SLOT_TYPE {
    EMPTY = "EMPTY",
    SUBSTITUTION = "SUBSTITUTION",
}

export enum TOP_LEFT_SLOT_TYPE {
    EMPTY = "EMPTY",
    OPERATION = "OPERATION",
}

export enum BOTTOM_SLOT_TYPE {
    EMPTY = "EMPTY",
    TEAMS = "TEAMS",
    USER_PROFILE = "USER_PROFILE"
}

export enum UserStatus {
    ACTIVE = "ACTIVE",
    DELETED = "DELETED",
    LOCKED = "LOCKED"
}

export interface UserProfile {
    id: number
    email: string
    avatar: string
    nickname: string
    description: string | null
    friendOf: number
    registeredAt: number
    userCode: string
    hasMobileNumber: boolean
    type: FRIEND_TYPE
    status: UserStatus
}

export interface TeamPreviewData {
    match: MatchVO | null;
    myTeams: MyTeamVO[];
    players: SquadPlayer[];
    currentTeamId: number | null,
    userProfile: UserProfile | null,
    teamInfoForUserProfile: {
        teamId: number,
        teamName: string,
        scoreCent: number
    },
    currentContestCode: string | null,
    isOpenWhenBack: boolean
}

export interface TeamPreviewConfig {
    sport: SPORT;
    offsetHeight: number;
    topLeftSlotType: TOP_LEFT_SLOT_TYPE;
    squadRightSlotType: SQUAD_RIGHT_SLOT_TYPE;
    bottomSlotType: BOTTOM_SLOT_TYPE
    onAvatarClick: Function
    onCreateTeam: Function
    onEditTeam: Function
}

export interface TeamPreviewState {
    show: boolean,
    data: TeamPreviewData,
    config: TeamPreviewConfig
}
