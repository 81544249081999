import { fetchBasketPlayerNews, fetchMatchResultPlayers, fetchPlayerInfo } from "data/api/player/PlayerApi";
import { playerInfoFactory } from "data/repository/player/PlayerInfoFactory";
import { MatchResultPlayersVo } from "data/vo/player/matchResult/MatchResultPlayersVo";
import { BasketballPlayerNewsVo } from "data/vo/player/news/BasketballPlayerNewsVo";
import { PlayerInfoVo } from "data/vo/player/PlayerInfoVo";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import { SPORT } from "types/sports/Sport";


export class PlayerRepository {
    public async getPlayerInfo(sport: SPORT, matchId: string, playerId: string): Promise<PlayerInfoVo> {

        const playerInfo = await fetchPlayerInfo(sport, matchId, playerId);

        return playerInfoFactory(sport, playerInfo);
    }

    public async getBasketballPlayerNews(playerId: string): Promise<BasketballPlayerNewsVo[]> {
        const basketballPlayerNewsList = await fetchBasketPlayerNews(playerId);

        return basketballPlayerNewsList.map(news => new BasketballPlayerNewsVo(news));
    }

    public async getMatchResultPlayers(sport: SPORT, matchId: string): Promise<MatchResultPlayersVo> {
        const players = await fetchMatchResultPlayers(sport, matchId);

        return new MatchResultPlayersVo(players.map(player => ({
            ...player,
            name: `${player.firstName} ${player.middleName} ${player.lastName}`,
            point: divideByOneHundred(player.pointCent),
        })));
    }

}
