import { GeneralAdWrapper } from "domain/home/components/ad/carousel/B2CAdCarouselStyle";
import React from "react";
import { Placeholder } from "react-bootstrap";
import styled from "styled-components";

const AdSkeletonContainer = styled.div.attrs({
    className: "ps-4 d-flex gap-3",
})`
  padding-top: 1.8rem;
  padding-bottom: 2.1rem;
`;

const AdSkeleton = () => {
    return <Placeholder animation="glow">
        <AdSkeletonContainer>
            <GeneralAdWrapper className="rounded-2">
                <Placeholder className="bg-gray-3 w-100 h-100" />
            </GeneralAdWrapper>
            <GeneralAdWrapper className="rounded-2">
                <Placeholder className="bg-gray-3 w-100 h-100" />
            </GeneralAdWrapper>
        </AdSkeletonContainer>
    </Placeholder>;
};

export default AdSkeleton;
