import React from "react";
import { cn } from "helpers/cn";
import { SPORT } from "types/sports/Sport";
import { Icon } from "helpers/IconHelper";

const SportToWhiteIcon = {
    [SPORT.CRICKET]: Icon.CricketWhite,
    [SPORT.FOOTBALL]: Icon.FootballWhite,
    [SPORT.BASKETBALL]: Icon.BasketballWhite,
};

const SportToYellowIcon = {
    [SPORT.CRICKET]: Icon.CricketYellow,
    [SPORT.FOOTBALL]: Icon.FootballYellow,
    [SPORT.BASKETBALL]: Icon.BasketballYellow,
};

const getSportIcon = (sport: string, active: boolean) => {
    return active ? SportToYellowIcon[sport] : SportToWhiteIcon[sport];
};

interface TabButtonProps {
    icon: string;
    name: string;
    sportKey: string;
    active: boolean;
    onClick: () => void;
}

export const TabButton: React.FC<TabButtonProps> = ({ icon, name, sportKey, active, onClick }) => {
    const SportIcon = getSportIcon(sportKey.toLowerCase(), active);
    return <div
        onClick={onClick}
        className={cn(
            "tw-flex tw-items-center tw-justify-center",
            "tw-flex-1 tw-h-[82px] tw-cursor-pointer",
            "tw-font-extrabold tw-text-[24px] tw-italic tw-tracking-[-0.03em]",
            "tw-bg-[#001042] tw-text-white",
            { "tw-bg-[#A31428]": active },
        )}
        key={sportKey}
    >
        <SportIcon size="2.4rem" />
        <p className={cn(
            {
                "tw-pr-[5px] tw-bg-gradient-to-b tw-from-[#EAD431] tw-from-[56.13%] tw-to-[#A31428] tw-to-[99.08%] tw-text-transparent tw-bg-clip-text": active
            },
            "tw-hidden xs:tw-block"
        )}>
            {name.toLowerCase() === SPORT.BASKETBALL ? "NBA" : name.toUpperCase()}
        </p>
    </div>;
};

