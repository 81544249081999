import { divideByOneHundred } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ListItem, ListTitle, ListValue } from "../components/TransactionDetailStyle";
import { TransactionDetailVo } from "data/vo/wallet/TransactionDetailVo";

export interface AmountDetailProps extends Pick<TransactionDetailVo, "regularWinningCent" | "extraWinningCent"> {
}

export const shouldHideAmountDetail = (regularWinningCent: number | undefined, extraWinningCent: number | undefined) => {
    if (!regularWinningCent) return true;
    if (!extraWinningCent) return true;
    return false;
};

const WinningContestAmountDetail: React.FC<AmountDetailProps> = (props: AmountDetailProps) => {
    const {
        regularWinningCent,
        extraWinningCent
    } = props;
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();

    if (shouldHideAmountDetail(regularWinningCent, extraWinningCent)) return null;

    return <>
        <ListItem className="bg-gray-2">
            <ListTitle><FormattedMessage id="label_regular" /></ListTitle>
            <ListValue data-testid="regular-cent">
                {toWalletNumber(divideByOneHundred(regularWinningCent as number))}
            </ListValue>
        </ListItem>
        <ListItem className="bg-gray-2">
            <ListTitle><FormattedMessage id="label_extra" /></ListTitle>
            <ListValue data-testid="extra-cent">
                {toWalletNumber(divideByOneHundred(extraWinningCent as number))}
            </ListValue>
        </ListItem>
    </>;
};

export default WinningContestAmountDetail;
