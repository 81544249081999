import styled from "styled-components";
import { APP_MIN_WIDTH_VALUE } from "styles/Constants";

export const EmptyRewardOrderWrapper = styled.div.attrs(() => ({}))`
  // container paddingY 14px
  padding-top: calc((80 - 14) / (${APP_MIN_WIDTH_VALUE} - 28) * 100%);
`;
export const EmptyRewardOrderImage = styled.div<{ defaultImg: string }>`
  // image width 200px / container paddingX 14px
  width: calc(200 / (${APP_MIN_WIDTH_VALUE} - 28) * 100%);
  padding-bottom: calc(200 / (${APP_MIN_WIDTH_VALUE} - 28) * 100%);
  background-image: url(${({ defaultImg }) => defaultImg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
  margin: 0 auto;
`;
export const EmptyRewardOrderText = styled.div.attrs(() => ({
    className: "fz-sf-regular-subhead text-center tw-text-system-labelLightPrimary"
}))`
`;
