import FantasyIcon, { Colorize } from "helpers/FantasyIcon";
import React, { ReactNode } from "react";

export interface TagIconProps {
    iconImg: string | ReactNode;

}

const TagIcon: React.FC<TagIconProps> = ({
                                             iconImg
                                         }) => {
    if (typeof iconImg === "string") {
        return (
            <FantasyIcon src={iconImg} className={"text-text-tertiary-1"} colorize={Colorize.Overwrite} />
        );

    }

    return <>{iconImg}</>;
};

export default TagIcon;
