import styled from "styled-components";

export const FeaturedContestCardWrapper = styled.div.attrs<{
    $bgUrl: string;
    $shadowColor: string;
    onClick?: () => void;
}>({
    className: "w-100 d-flex justify-content-center align-items-center pb-4 px-3 overflow-hidden position-relative tw-cursor-pointer"
})`
    height: 13rem;
    background-image: url(${({ $bgUrl }) => $bgUrl});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 5.2rem;
    box-shadow: 0 0.4rem 1rem 0 ${({ $shadowColor }) => $shadowColor};
    border-radius: 1.2rem;
`;
export const EntryFeeWrapper = styled.div.attrs({
    className: "rounded-circle position-absolute top-0 start-50 translate-middle d-flex justify-content-center align-items-center flex-column"
})`
    width: 7.2rem;
    height: 7.2rem;
    background-color: rgba(0, 0, 0, 0.42);
    padding-top: 3.6rem;
    color: var(--cl-primary40);
    box-shadow: 0 1rem 4rem 0 #F798404D;

`;
export const EntryFee = styled.div.attrs({
    className: "d-flex gap-1 align-items-center justify-content-center fz-sf-bold-subhead"
})`
    color: var(--cl-primary8);
    line-height: 1.6rem;

    img {
        flex-shrink: 0;
        width: 0.8rem !important;
        height: auto !important;
    }
`;
export const SquadWrapper = styled.div.attrs({
    className: "fz-sf-bold-subhead text-dark-primary d-flex gap-2 align-items-center justify-content-center w-100"
})`
    min-width: 0;

    img {
        flex-shrink: 0;
        width: 1.6rem !important;
    }
`;
export const CountdownWrapper = styled.div.attrs({
    className: "text-sys-warning-1 fz-sf-regular-footnote px-3 rounded-pill d-flex gap-2 align-items-center justify-content-center"
})`
    background-color: rgba(0, 0, 0, 0.42);
`;

export const OnlyContestWrapper = styled.div.attrs({
    className: "px-4 d-flex gap-4"
})`
    > div {
        &:first-child {
            flex: 5;
        }

        &:last-child {
            flex: 4;
            color: rgba(0, 0, 0, 0.32);
        }
    }
`;
