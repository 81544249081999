import FantasyBasicPopup, { POPUP_SIZE } from "components/popup/FantasyBasicPopup";
import { BUTTON_PATTERN, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import useFantasyHistory from "hooks/useFantasyHistory";
import React from "react";
import { useIntl } from "react-intl";

export const KycLeavePopup: React.FC<{ show: boolean, onClose: () => void }> = ({ show, onClose }) => {
    const intl = useIntl();
    const history = useFantasyHistory();

    const handleLeave = () => {
        history.goBack();
    };

    return <FantasyBasicPopup
        show={show}
        onHide={onClose}
        popupSize={POPUP_SIZE.LG}
        title={{
            title: intl.formatMessage({ id: "label_verification_incomplete" }),
            titleClassName: "tw-text-grey-1000 tw-text-h6 tw-font-bold"
        }}
        texts={[{
            text: intl.formatMessage({ id: "kyc_verification_incomplete_content" }),
            textClassName: "tw-text-grey-1000 tw-text-subtitle-1 tw-text-left",
        }]}
        buttonSetting={{
            buttonPattern: BUTTON_PATTERN.rounded,
            confirmButtonVariant: NEW_BUTTON_VARIANTS.primaryDark,
            confirmButtonText: intl.formatMessage({ id: "label_continue_verification" }),
            onConfirm: onClose,
            cancelButtonVariant: NEW_BUTTON_VARIANTS.errorGrey,
            cancelButtonText: intl.formatMessage({ id: "label_exit_and_lose_progress" }),
            onCancel: handleLeave,
            buttonGap: 2,
        }}
    />;
};
