import { StorageHelper } from "helpers/localStorage/types";

export const getDataFromLocalStorage = (key: string) => {
    const item = localStorage.getItem(key);

    if (!item) return null;

    if (item === "undefined") return null;

    return JSON.parse(item);
};
export const setDataToLocalStorage = (key: string, data: any) => localStorage.setItem(key, JSON.stringify(data));
export const removeDataFromLocalStorage = (key: string) => localStorage.removeItem(key);
export const clearLocalStorage = () => localStorage.clear();

export const storageHelper: StorageHelper = (...keys: string[]) => {
    const key = keys.join("-");

    const put = (data: any) => setDataToLocalStorage(key, data);
    const get = () => getDataFromLocalStorage(key);
    const remove = () => removeDataFromLocalStorage(key);
    const clear = () => clearLocalStorage();

    return { get, put, remove, clear };
};
