// alert
import { FantasyPopupProps } from "components/popup/FantasyBasicPopup";
import { PopupProps } from "store/reducer/alert/AlertState";

export const ADD_ALERT_POPUP: string = "ADD_ALERT_POPUP";
export const ADD_TOAST: string = "ADD_TOAST";
export const ADD_ERROR_POP: string = "ADD_ERROR_POP";
export const REMOVE_ALERT_POPUP: string = "REMOVE_ALERT_POPUP";
export const REMOVE_TOAST: string = "REMOVE_TOAST";
export const REMOVE_ERROR_POPUP: string = "REMOVE_ERROR_POPUP";
export const KYC_COMPLETE_POPUP = "KYC_COMPLETE_POPUP";

// alert
export const addPopup = (payload: PopupProps | FantasyPopupProps) => ({ type: ADD_ALERT_POPUP, payload });
export const addToast = (payload) => ({ type: ADD_TOAST, payload });
export const addErrorPopup = (payload) => ({ type: ADD_ERROR_POP, payload });
export const removePopup = () => ({ type: REMOVE_ALERT_POPUP });
export const removeToast = () => ({ type: REMOVE_TOAST });
export const removeErrorPopup = () => ({ type: REMOVE_ERROR_POPUP });

export const handleKycCompletePopup = (show: boolean) => ({ type: KYC_COMPLETE_POPUP, payload: show });


