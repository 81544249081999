import React, { ReactNode } from "react";
import { cn } from "helpers/cn";

class InfoCardProps {
    icon?: ReactNode;
    label: ReactNode;
}

export const InfoCard: React.FC<InfoCardProps> = ({
                                                      icon,
                                                      label
                                                  }) => (
    <div className={cn("tw-flex tw-items-center tw-gap-1",
        "tw-py-1.5 tw-px-2", "tw-bg-white tw-rounded-md"
    )}>
        {icon}
        <p className={"tw-text-caption-1"}>
            {label}
        </p>
    </div>
);
