import clsx from "clsx";
import { ProgressBar } from "react-bootstrap";
import styled from "styled-components";

export const VideoPlayerWrapper = styled.div.attrs<{
    width: number
}>({
    className: "position-fixed top-0 start-0"
})`
  width: ${({ width }) => width}px;
  z-index: 3;

  @supports (height: 100dvh) {
    height: 100dvh;
  }

  @supports not (height: 100dvh) {
    height: 100vh;
  }
`;

export const RemainingButtonWrapper = styled.div.attrs({
    className: "position-absolute bg-light-primary rounded-pill px-3 py-1 d-flex gap-2 align-items-center justify-content-between shadow clickable"
})`
  top: 1.6rem;
  right: 1.6rem;
  z-index: 100;
  min-width: 10.5rem;
`;

export const VideoPlayerMask = styled.div.attrs<{
    $width: number,
    $background?: string
}>(({ $background }) => ({
    className: clsx("position-absolute top-0 start-0", $background)
}))`
  width: ${({ $width }) => $width}px;
  z-index: 3;

  @supports (height: 100dvh) {
    height: 100dvh;
  }

  @supports not (height: 100dvh) {
    height: 100vh;
  }
`;
export const ProgressBarWithHeight = styled(ProgressBar).attrs<{ $height: number }>({
    className: "rounded-0 bg-secondary-1",
})`
  height: ${({ $height }) => $height}rem;
`;
