import { min } from "lodash";
import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from "react";
import { fetchMyApOverview } from "../../../apis/ap/ActivityPointApi";
import { PopupRef, usePopupRef } from "../../../components/ui/dialog";
import { TaskRepository } from "../../../data/repository/task/TaskRepository";
import { DailyCheckInQuestVo } from "../../../data/vo/task/DailyCheckInQuestVo";
import useFantasyQuery from "../../../hooks/useFantasyQuery";
import { QUERY_KEY } from "../../../hooks/useFantasyQuery/type";
import { useSelector } from "../../../store";
import { AP_LEVEL } from "../../../types/activityPoint/ApLevel";
import { MyApOverview } from "../../../types/activityPoint/MyApOverview";
import { apLevelConfig } from "../../ap/ActivityPointHelper";

enum DailyCheckInPopupPages { DEFAULT, UPGRADE }

type PrizeVo = { coinCent: number, rubyCent: number };

interface DailyCheckInPopupProps {
    isShow: boolean;
    setIsShow: (value: boolean) => void;
    isShowDefaultPopup: boolean;
    isShowUpgradePopup: boolean;
    changeToDefault: () => void;
    changeToUpgrade: () => void;

    popupRef: PopupRef,
    todayTarget: React.RefObject<HTMLDivElement>;
    isPopupReady: boolean;
    setIsPopupReady: (value: boolean) => void;

    todayPrize: PrizeVo;
    myDaysInRow: number;
    todayDaysInRow: number;
    maxDaysInRow: number;
    getPrize: (apLevel: AP_LEVEL, daysInRow: number) => PrizeVo;
    myApLevel: MyApOverview;
    recommendedUpgradeApLevel: { level: AP_LEVEL, weight: number } | null;
}

const DailyCheckInPopupContext = createContext<DailyCheckInPopupProps | null>(null);

type DailyCheckInPopupProviderProps = {
    children: React.ReactNode;
};

const DailyCheckInPopupProvider: React.FC<DailyCheckInPopupProviderProps> = ({ children }) => {

    const maxDaysInRow = 7;

    const taskRepository = new TaskRepository();

    const [isShow, setIsShow] = useState(false);
    const [page, setPage] = useState(DailyCheckInPopupPages.DEFAULT);
    const [isPopupReady, setIsPopupReady] = useState(false);

    const todayTarget = useRef<HTMLDivElement>(null);
    const popupRef = usePopupRef();

    const apLevels = useSelector(state => state.SettingsState.apLevels);

    const dailyCheckInQuest = useFantasyQuery<DailyCheckInQuestVo>([QUERY_KEY.DAILY_CHECK_IN_QUEST], taskRepository.getDailyCheckInQuest);
    const apOverview = useFantasyQuery<MyApOverview>([QUERY_KEY.MY_AP_OVERVIEW], fetchMyApOverview);

    const recommendedUpgradeApLevel = useMemo(() => {
        if (!apOverview.data) return null;

        return apLevels.find((apLevel) => {
            return apLevel.level === apLevelConfig[apOverview.data.currentLevel].recommendedUpgradeLevel;
        }) ?? null;
    }, [apLevels, apOverview]);

    const getPrize = useCallback((apLevel: AP_LEVEL, daysInRow: number) => {
        return dailyCheckInQuest.data?.config.find((prize) => {

            return prize.apLevel === apLevel && prize.daysInRow === min([daysInRow, maxDaysInRow])!;

        }) ?? { coinCent: 0, rubyCent: 0 };
    }, [dailyCheckInQuest.data]);

    const context = useMemo<DailyCheckInPopupProps | null>(() => {
        if (!dailyCheckInQuest.data || !apOverview.data) {
            return null;
        }

        const myDaysInRow = dailyCheckInQuest.data.todayProgress.daysInRow;
        return {
            isShow,
            setIsShow,
            isShowDefaultPopup: isShow && page === DailyCheckInPopupPages.DEFAULT,
            isShowUpgradePopup: isShow && page === DailyCheckInPopupPages.UPGRADE,
            changeToDefault: () => setPage(DailyCheckInPopupPages.DEFAULT),
            changeToUpgrade: () => setPage(DailyCheckInPopupPages.UPGRADE),

            popupRef,
            todayTarget,
            isPopupReady,
            setIsPopupReady,

            todayPrize: dailyCheckInQuest.data.todayProgress,
            myDaysInRow,
            todayDaysInRow: min([myDaysInRow, maxDaysInRow])!,
            maxDaysInRow,
            getPrize,
            myApLevel: apOverview.data!,
            recommendedUpgradeApLevel,
        };
    }, [isShow, setIsShow, page, popupRef, todayTarget, isPopupReady, setIsPopupReady, dailyCheckInQuest, apOverview]);

    if (context === null) {
        return null;
    }

    return <DailyCheckInPopupContext.Provider value={context}>
        {children}
    </DailyCheckInPopupContext.Provider>;
};

export default DailyCheckInPopupProvider;

export const useDailyCheckInPopup = () => {
    const context = useContext(DailyCheckInPopupContext);

    if (!context) {
        throw new Error("useDailyCheckInPopup must be used within a DailyCheckInPopupProvider");
    }

    return context;
};

