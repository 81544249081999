import { fetchRubyContestChampionReminder } from "apis/reminder/ReminderApi";
import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_VARIANTS } from "designToken/button/types";
import { awardBackground, rubyContestChampionImg } from "helpers/IconHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import useMyProfile from "hooks/useMyProfile";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { getStartedMatchDetailPageUrl } from "router/RouterUrls";
import { SPORT } from "types/sports/Sport";
import Dialog from "../../../../designToken/dialog/Dialog";
import {
    RubyContestChampionDialogButtonWrapper,
    RubyContestChampionDialogContent,
    RubyContestChampionDialogContentWrapper,
    RubyContestChampionDialogImage,
    RubyContestChampionDialogTitle
} from "./RubyContestChampionDialogStyle";
import { ERROR_HANDLER_FLOW } from "hooks/useHandleApiError";
import { cn } from "helpers/cn";
import { useAppPopups } from "../../../popup/AppPopupsProvider";

interface RubyContestChampionReminderVO {
    contestId: number;
    matchId: string;
    sport: SPORT;
    squadACode: string;
    squadBCode: string;
}

const RubyContestChampionDialog: React.FC = () => {
    const popups = useAppPopups();

    const { myProfile } = useMyProfile();
    const history = useFantasyHistory();
    const [isVisible, setIsVisible] = useState(false);
    const [rubyContestChampionReminder, setRubyContestChampionReminder] = useState<RubyContestChampionReminderVO | null>(null);

    const handleViewMore = useCallback(() => {
        history.push({
            pathname: getStartedMatchDetailPageUrl(rubyContestChampionReminder!.sport, rubyContestChampionReminder!.matchId),
            state: {
                contestId: rubyContestChampionReminder!.contestId
            }
        });
    }, [history, rubyContestChampionReminder]);

    const handleClose = useCallback(() => {
        popups.done();
        setIsVisible(false);
        setRubyContestChampionReminder(null);
    }, [popups]);

    const { data } = useFantasyQuery([QUERY_KEY.RUBY_CONTEST_CHAMPION_REMINDER], fetchRubyContestChampionReminder, {
        onFail: () => ERROR_HANDLER_FLOW.STOP
    });

    useEffect(() => {
        if (data === null) {
            popups.done();
        }

        if (data) {
            setIsVisible(true);
            setRubyContestChampionReminder(data);
        }
    }, [data]);

    if (!rubyContestChampionReminder) {
        return null;
    }

    return (
        <Dialog show={isVisible}>
            <div
                style={{ backgroundImage: `url(${awardBackground})` }}
                className={cn(
                    "tw-flex tw-justify-center"
                    , "tw-bg-no-repeat tw-bg-center tw-bg-cover")}
            >
                <RubyContestChampionDialogImage
                    src={rubyContestChampionImg}
                    alt="ruby contest champion" />
            </div>
            <RubyContestChampionDialogContentWrapper>
                <RubyContestChampionDialogTitle>
                    <FormattedMessage id="ruby_contest_champion_title" />
                </RubyContestChampionDialogTitle>
                <RubyContestChampionDialogContent data-testid="ruby-contest-champion-dialog-content">
                    <FormattedMessage id="ruby_contest_champion_content" values={{
                        nickname: myProfile?.nickname,
                        squadA: rubyContestChampionReminder?.squadACode,
                        squadB: rubyContestChampionReminder?.squadBCode,
                    }} />
                </RubyContestChampionDialogContent>
            </RubyContestChampionDialogContentWrapper>
            <RubyContestChampionDialogButtonWrapper>
                <Button className="w-100"
                        pattern={BUTTON_PATTERN.pill}
                        onClick={handleViewMore}>
                    <FormattedMessage id="button_view_more" />
                </Button>
                <Button className="w-100 border-0"
                        variant={BUTTON_VARIANTS.outlineDark}
                        pattern={BUTTON_PATTERN.pill}
                        onClick={handleClose}>
                    <FormattedMessage id="button_cancel" />
                </Button>
            </RubyContestChampionDialogButtonWrapper>
        </Dialog>
    );
};

export default RubyContestChampionDialog;
