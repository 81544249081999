import React from "react";
import { Form } from "react-bootstrap";

interface SwitchProps {
    checked: boolean,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    dataTestId?: string,
}

const Switch = ({ checked, onChange = (_) => null, dataTestId }: SwitchProps) => {
    return (
        <Form.Check checked={checked}
                    onChange={onChange}
                    type="switch"
                    role="switch"
                    data-testid={dataTestId}
        />
    );
};

export default Switch;
