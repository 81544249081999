import { Container } from "react-bootstrap";
import styled from "styled-components";

export const SelectTeamHeader = styled.div.attrs(() => ({
    className: "d-flex align-items-center justify-content-between tw-bg-system-bgLightPrimary"
}))`
  padding-top: 2.4rem;
  padding-right: 1.2rem;
  z-index: 1;
`;
export const Title = styled.div.attrs(() => ({
    className: "fz-sf-bold-title-1 d-flex tw-text-primary-700"
}))``;
export const CloseButton = styled.div.attrs<{
    $icon: string
}>(() => ({
    className: "ms-auto"
}))`
  background: url(${({ $icon }) => $icon}) center no-repeat;
  width: 2.4rem;
  padding-bottom: 2.4rem;
  background-size: 100% auto;
  cursor: pointer;
`;

export const SelectTeamPanelHeadWrapper = styled.div.attrs({
    className: "px-4 w-100"
})``;
export const SelectTeamPanelBodyWrapper = styled.div.attrs({
    className: "px-4"
})`
  padding-bottom: 8rem;
`;

export const EntryAllowedTextWrapper = styled.div.attrs(() => ({
    className: "d-flex align-items-center pt-2"
}))`
  margin-bottom: 1rem;
  gap: 0.5rem;
`;

export const EntryAllowedText = styled.div.attrs(() => ({
    className: "fz-sf-regular-body-1"
}))`
`;
export const BoldText = styled.span.attrs(() => ({
    className: "fz-sf-bold-body-1"
}))`
`;
export const SelectedTeamWrapper = styled.div.attrs(() => ({
    className: "d-flex flex-wrap"
}))`
  margin-bottom: 1.8rem;
`;
export const SelectedTeamText = styled.div.attrs(() => ({
    className: "fz-sf-regular-subhead me-3 tw-text-system-labelLightSecondary"
}))`
`;

export const InfoButtonWrapper = styled.div.attrs(() => ({
    className: "d-flex justify-content-center"
}))`
  padding-top: 1.4rem;
`;
export const TeamCardWrapper = styled.div.attrs(() => ({
    className: "overflow-hidden"
}))`
  margin-bottom: .8rem;
`;
export const MaxEntriesInfoIcon = styled.div<{
    $icon: string
}>`
  width: 1.5rem;
  height: 0;
  padding-bottom: 1.5rem;
  background: url(${({ $icon }) => $icon}) center no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
`;
export const MultipleEntriesInfoModalContainer = styled(Container).attrs(() => ({}))`
  padding: 4rem 2.4rem;
`;
export const MultipleEntriesInfoTableHead = styled.th.attrs(() => ({
    className: "col-6 text-center fz-sf-regular-footnote"
}))`
`;
export const MultipleEntriesInfoTableCell = styled.td.attrs(() => ({
    className: "col-6 text-center fz-sf-regular-footnote"
}))`
`;
