import Navbar from "components/container/navbar/Navbar";
import {
    ActiveExampleText,
    ActiveSelection,
    ActiveSelectionText,
    CheckWrapper,
    CurrencyAndLocalePageWrapper,
    ExampleText,
    Selection,
    SelectionText,
    SubmitButton,
    Text,
    Title
} from "domain/currency/CurrencyStyle";
import { checkMarkPrimaryIcon } from "helpers/IconHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import useFantasyHistory from "hooks/useFantasyHistory";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { CURRENCY } from "store/reducer/config/FantasyGameConfigState";


const Currency = () => {
    const history = useFantasyHistory();
    const { currencies, currentCurrencyObject, onCurrencyChange } = useCurrency();
    const [currency, setCurrency] = useState(currentCurrencyObject.key);

    const onSubmit = (currency: CURRENCY) => {
        onCurrencyChange(currency);
        history.push(ROUTER_PATH_CONFIG.me.index);
    };

    return <>
        <Navbar
            navBackIconColor="tw-text-primary-600"
            navbarCenter={<Title><FormattedMessage id="currency_format_setting_page_title" /></Title>}
        />
        <CurrencyAndLocalePageWrapper>
            <Text><FormattedMessage id="currency_format_setting_label_choose_format" /></Text>
            <div>
                {
                    currencies.map(({ key, text, example }) => {
                        return currency === key
                            ? <ActiveSelection key={key}>
                                <ActiveSelectionText>{text}<ActiveExampleText>({example})</ActiveExampleText></ActiveSelectionText>
                                <CheckWrapper>
                                    <img src={checkMarkPrimaryIcon} alt="check" />
                                </CheckWrapper>
                            </ActiveSelection>
                            : <Selection key={key} onClick={() => setCurrency(key)}>
                                <SelectionText>{text}<ExampleText>({example})</ExampleText></SelectionText>
                            </Selection>;
                    })
                }
            </div>
            <SubmitButton onClick={() => onSubmit(currency)}>
                <FormattedMessage id="setting_button_save_changed" />
            </SubmitButton>
        </CurrencyAndLocalePageWrapper>
    </>;
};

export default Currency;
