import { Performance } from "data/vo/player/PlayerInfoVo";
import { divideByOneHundred } from "helpers/number/NumberHelper";

export class PerformanceVo {
    private readonly _recentPointsCents: number[];
    private readonly _averagePlayerPointsCent: number;

    constructor(performance: Performance) {
        this._recentPointsCents = performance.recentPointsCents;
        this._averagePlayerPointsCent = performance.averagePlayerPointsCent!;
    }

    get recentPointsCents(): number[] {
        return this._recentPointsCents;
    }

    get averagePlayerPoints(): number {
        return Math.floor(divideByOneHundred(this._averagePlayerPointsCent));
    }
}

