import { fetchFollow, FollowDto } from "apis/user/UserApi";
import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useLoadingToggle } from "components/loading/Loading";
import { UserProfileRepository } from "data/repository/user/UserProfileRepository";
import { UserProfileVo } from "data/vo/user/UserProfileVo";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY, QUERY_STATUS } from "hooks/useFantasyQuery/type";
import useMyProfile from "hooks/useMyProfile";
import { RootStoreState } from "store/reducer";
import { UserProfileDetail } from "domain/friend/profile/components/UserProfileDetail";
import UserProfilePanel from "domain/friend/profile/components/UserProfilePanel";
import { UserProfileContext, UserProfileContextProps } from "domain/friend/profile/useUserProfileContext";

const UserProfileProvider = ({ children }) => {
    const toggleLoading = useLoadingToggle();
    const { userId } = useParams();
    const repository = useMemo(() => new UserProfileRepository(), []);

    const { SettingsState: { following: { maxFollowing } } } = useSelector((store: RootStoreState) => store);
    const { myProfile } = useMyProfile();

    const userInfo = useFantasyQuery([QUERY_KEY.USER_INFO, userId], () => repository.getUserInfo(userId));
    const userFollow = useFantasyQuery([QUERY_KEY.GET_USER_FOLLOWERS, userId], () => fetchFollow(userId));
    const myFollow = useFantasyQuery([QUERY_KEY.GET_USER_FOLLOWERS, myProfile.id], () => fetchFollow(myProfile.id));
    const careerStats = useFantasyQuery([QUERY_KEY.USER_CAREER_STATS, userId], () => {
        return repository.getCareerStats(userId, myProfile.id, Date.now());
    }, {
        options: {
            enabled: userInfo?.status === QUERY_STATUS.SUCCESS
        }
    });

    const value = useMemo<UserProfileContextProps>(() => {
        return ({
            userInfo: userInfo?.data ?? {} as UserProfileVo,
            refetchUserInfo: userInfo.refetch,
            careerStatsList: careerStats?.data ?? [],
            userFollow: userFollow?.data ?? {} as FollowDto,
            isReachedFollowingLimit:
                !!myFollow?.data && myFollow?.data?.followingCount >= maxFollowing
            ,
            myProfile: myProfile
        });
    }, [
        myProfile,
        careerStats?.data,
        maxFollowing,
        myFollow?.data,
        userFollow?.data,
        userInfo?.data,
        userInfo?.refetch,
    ]);


    useEffect(() => {
        if (careerStats.isLoading) {
            toggleLoading(true);

        } else {
            toggleLoading(false);
        }
    }, [careerStats.isLoading, toggleLoading]);

    if (!careerStats.data) {
        return null;
    }

    return (
        <UserProfileContext.Provider value={value}>
            {children}
        </UserProfileContext.Provider>
    );
};


export const UserProfile = () => {
    return (
        <UserProfileProvider>
            <UserProfileDetail />
            <UserProfilePanel />
        </UserProfileProvider>
    );
};
