import { fetchCoinProducts } from "data/api/coin/CoinApi";
import { CoinProductVo } from "data/vo/coin/CoinProductVo";
import useUpdateWallet from "domain/wallet/useUpdateWallet";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import { CoinProductsVo } from "data/vo/coin/CoinProductsVo";
import { getProductImgs } from "domain/coin/AddCoinHelper";

export const useFetchProducts = () => {
    const updateWallet = useUpdateWallet();

    const {
        data: products
    } = useFantasyQuery([QUERY_KEY.COIN_AMOUNT], async () => {
        updateWallet();
        const res = await fetchCoinProducts();
        const productImgs = getProductImgs(res.length);

        return CoinProductsVo.create(
            res.sort((a, b) => a.coinCent - b.coinCent)
                .map((dto, index) =>
                    CoinProductVo.create(dto.id, dto.coinCent, dto.currency, dto.currencyCent, dto.saleType, productImgs[index])
                ));
    });

    return {
        products,
    };
};
