import { CAPTAIN_WEIGHTED_RATE, VICE_CAPTAIN_WEIGHTED_RATE } from "domain/team/OperateTeamHelper";
import PlayerCardInfo from "domain/team/player/components/playerCard/PlayerCardInfo";
import {
    PlayerInfoCol,
    SpecialPlayerCardContainer,
    SpecialPlayerCardRow,
    SpecialPoints,
    SpecialRoleCircle,
    SpecialRoleCircleSelected,
    SpecialRoleCol,
    SpecialSelectionRate
} from "domain/team/special/SpecialPlayerCardStyle";
import useCurrency from "hooks/useCurrency/useCurrency";
import React from "react";
import { Col } from "react-bootstrap";
import { SelectablePlayer } from "types/player/Player";

interface SpecialPlayerCardProps {
    player: SelectablePlayer
    specialPlayer: { captain: string, viceCaptain: string }
    handleSpecialRoleOnChange: Function
    roleAbbr: string,
    onAvatarClick: Function
}

const SpecialPlayerCard: React.FC<SpecialPlayerCardProps> = ({
                                                                 player,
                                                                 specialPlayer,
                                                                 handleSpecialRoleOnChange,
                                                                 roleAbbr,
                                                                 onAvatarClick
                                                             }) => {
    const { id, squadCode, captainSelectionRatePercent, viceCaptainSelectionRatePercent } = player;
    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();

    return (
        <SpecialPlayerCardContainer>
            <SpecialPlayerCardRow>
                <PlayerInfoCol className="clickable" onClick={() => onAvatarClick()}>
                    <PlayerCardInfo player={player}
                                    text={`${squadCode} - ${roleAbbr}`} />
                </PlayerInfoCol>
                <Col>
                    <SpecialPoints>
                        {toFormattedNumber(player.pointAvg)}
                    </SpecialPoints>
                </Col>
                <SpecialRoleCol>
                    {
                        specialPlayer.captain === id
                            ? <SpecialRoleCircleSelected
                                onClick={() => handleSpecialRoleOnChange("captain", false, id)}>{CAPTAIN_WEIGHTED_RATE}x</SpecialRoleCircleSelected>
                            : <SpecialRoleCircle onClick={() => handleSpecialRoleOnChange("captain", true, id)}
                                                 data-testid="choose-captain"
                            >C</SpecialRoleCircle>
                    }
                    <SpecialSelectionRate>{`${toFormattedNumber(captainSelectionRatePercent)}%`}</SpecialSelectionRate>
                </SpecialRoleCol>
                <SpecialRoleCol>
                    {
                        specialPlayer.viceCaptain === id
                            ? <SpecialRoleCircleSelected
                                onClick={() => handleSpecialRoleOnChange("viceCaptain", false, id)}>{VICE_CAPTAIN_WEIGHTED_RATE}x</SpecialRoleCircleSelected>
                            : <SpecialRoleCircle
                                onClick={() => handleSpecialRoleOnChange("viceCaptain", true, id)}
                                data-testid="choose-vice-captain"
                            >VC</SpecialRoleCircle>
                    }
                    <SpecialSelectionRate>{`${toFormattedNumber(viceCaptainSelectionRatePercent)}%`}</SpecialSelectionRate>
                </SpecialRoleCol>
            </SpecialPlayerCardRow>
        </SpecialPlayerCardContainer>
    );
};

export default SpecialPlayerCard;
