import { InfoButtonWrapper, MaxEntriesInfoIcon, MultipleEntriesInfoModalContainer } from "../SelectTeamPanelStyle";
import { FormattedMessage } from "react-intl";
import React, { useState } from "react";
import { infoIcon } from "helpers/IconHelper";
import Dialog from "../../../designToken/dialog/Dialog";
import Button from "../../../designToken/button/Button";
import { MaxEntriesInfoTable } from "./MaxEntriesInfoTable";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";

export const MaxEntriesInfo = ({ spotsToMaxEntries, defaultMaxEntries }) => {

    const [showModal, setShowModal] = useState(false);

    return <>
        <MaxEntriesInfoIcon $icon={infoIcon} onClick={() => setShowModal(true)} />

        <Dialog show={showModal}>
            <MultipleEntriesInfoModalContainer>
                <MaxEntriesInfoTable
                    defaultMaxEntries={defaultMaxEntries}
                    spotsToMaxEntries={spotsToMaxEntries} />
                <InfoButtonWrapper>
                    <Button variant={BUTTON_VARIANTS.primary}
                            size={BUTTON_SIZE.md}
                            pattern={BUTTON_PATTERN.pill}
                            onClick={() => setShowModal(false)}>
                        <FormattedMessage id="button_got_it" />
                    </Button>
                </InfoButtonWrapper>
            </MultipleEntriesInfoModalContainer>
        </Dialog>
    </>;
};
