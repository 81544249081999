export enum LOCAL_STORAGE_KEYS {
    AUTH = "legendFantasy",
    USE_RUBY = "useRuby",
    LAST_CHAT_ROOM_READ_TIME = "lastChatRoomReadTime",
    SPORT_CATEGORY = "sportCategory",
    SKIP_UPCOMING_TOURNAMENTS = "skipUpcomingTournaments",
    SKIP_RESULT_TOURNAMENTS = "skipResultTournaments",
    NEW_FEATURES = "newFeatures",
    SHOW_PREPARING = "showPreparing",
    LOCALE = "language",
    CURRENCY = "currency",
    PROMOTIONAL_CODE_OPENED = "promotionalCodeOpened",
    TASKS_TUTORIAL_DONE = "tasksTutorialDone",
    LAST_REFEREE_JOIN_TIME = "lastRefereeJoinTime",
    REDIRECT_PATH = "redirectPath",
    KYC_UNVERIFIED_NOT_SHOW_AGAIN_TIME = "kycUnverifiedNotShowAgainTime",
    KYC_COMPLETED_POPUP_SHOWED = "kycCompletedPopupShowed",
    KYC_UNSUCCESSFUL_POPUP_SHOWED = "kycUnsuccessfulPopupShowed",
    KYC_COMPLETED_HINT_NOT_SHOW_AGAIN = "kycCompletedHintNotShowAgain",
    CONTEST_DETAIL = "contestDetail",
}

export type StorageHelper = (...keys: string[]) => {
    put: (data: any) => void,
    get: () => any | null,
    remove: () => void,
    clear: () => void,
}
