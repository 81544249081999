import React from "react";
import { SUPPLIER_LOGO_TYPE } from "components/supplierLogo/type";

interface ImageDividerWrapperProps {
    children: React.ReactNode;
    type: SUPPLIER_LOGO_TYPE;
}

export const ImageDividerWrapper: React.FC<ImageDividerWrapperProps> = ({ type, children }) => {
    if (type === SUPPLIER_LOGO_TYPE.NORMAL) {
        return <>{children}</>;
    }

    return <div className="tw-text-center tw-flex tw-items-center tw-justify-center tw-gap-2">
        <div className="tw-w-5 tw-h-[1px] tw-bg-gray-300"></div>
        {children}
        <div className="tw-w-5 tw-h-[1px] tw-bg-gray-300"></div>
    </div>;
};
