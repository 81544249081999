import { ASC, DESC, SortingDirection } from "helpers/SortingHelper";
import { useCallback, useState } from "react";


const useSort = (initSortKey): {
    sortKey: string,
    sortingDirection: SortingDirection,
    handleSort: (key: string) => void
} => {
    const [sortKey, setSortKey] = useState(initSortKey);
    const [sortingDirection, setSortingDirection] = useState<SortingDirection>(DESC);

    const handleSort = useCallback((_sortKey) => {
        if (sortKey !== _sortKey) {
            setSortKey(_sortKey);
            setSortingDirection(DESC);
        } else {
            setSortingDirection(prevOrderBy => {
                return prevOrderBy === DESC ? ASC : DESC;
            });
        }
    }, [sortKey]);

    return {
        sortKey, sortingDirection, handleSort
    };
};

export default useSort;
