import { Table } from "react-bootstrap";
import styled, { css } from "styled-components";
import { PLAYER_AVATAR_WIDTH } from "styles/Constants";

export const PlayerStatsWrapper = styled.div`
  position: relative;
  z-index: 1;
`;
export const CaptainDot = styled.div.attrs(() => ({
    className: "rounded-circle w-100 h-100 tw-bg-primary-600"
}))``;
export const ViceCaptainDot = styled.div.attrs(() => ({
    className: "rounded-circle w-100 h-100 tw-bg-secondary-500"
}))``;
export const SymbolWrapper = styled.div.attrs({
    className: "d-flex align-items-baseline"
})`
  margin-left: 1.6rem;
`;
export const SymbolDotWrapper = styled.div.attrs({
    className: ""
})`
  width: 1rem;
  height: 1rem;
  margin-right: .8rem;
`;
export const SymbolText = styled.div.attrs({
    className: "fz-sf-regular-subhead"
})`

`;
export const StatsHeader = styled.div.attrs({
    className: "py-3"
})`
`;
export const TitleRow = styled.div.attrs({
    className: "d-flex justify-content-between"
})`
  padding: 0 .8rem .6rem;
`;
export const Title = styled.div.attrs({
    className: "fz-sf-regular-body-2 text-light-secondary"
})`

`;
export const CAndVcIcon = styled.div.attrs({
    className: "d-flex"
})`

`;
export const MyTeams = styled.div.attrs({
    className: "w-100 d-flex"
})`
  //float: left;
  //white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
`;
export const MyTeam = styled.div.attrs({
    className: "fz-sf-regular-footnote flex-shrink-0"
})`
  padding: .2rem .4rem;
  border-width: 1px;
  border-style: solid;
  border-radius: .4rem;
  margin-left: .8rem;
  cursor: pointer;

  &:last-child {
    margin-right: .8rem;
  }
`;
export const StatsTable = styled(Table).attrs({
    className: "mb-0"
})`
  table-layout: fixed;

  th {
    padding: .75rem .8rem;
  }

  td {
    padding: .75rem 1.8rem .75rem .8rem;
  }

  thead, tbody, tfoot, tr, td, th {
    border-style: dashed;
  }
`;
const colSize = css`
  min-width: 7rem;
  width: 20%;

  &:first-child {
    width: 65%
  }

`;

export const StatsTh = styled.th.attrs({
    className: "fz-sf-regular-subhead"
})`
  vertical-align: middle !important;

  ${colSize};

  &:not(:first-child) {
    cursor: pointer;
    text-align: right;
  }
`;
export const SortingIcon = styled.div.attrs<{
    $bgImg: string
}>({
    className: "d-inline-block"
})`
  background: url(${({ $bgImg }) => $bgImg}) center no-repeat;
  background-size: contain;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  vertical-align: middle;
`;

const StatsBaseTd = styled.td`
  vertical-align: middle !important;
  border-top-style: dotted !important;

  ${colSize};

  &:not(:first-child) {
    text-align: right;
  }
`;
export const PlayerNameTd = styled(StatsBaseTd).attrs({
    className: "fz-sf-regular-subhead text-nowrap"
})``;
export const StatsTd = styled(StatsBaseTd).attrs({
    className: "fz-sf-regular-body-1 text-nowrap"
})`
  font-weight: 500;
`;
export const PlayerInfoWrapper = styled.div.attrs({
    className: "d-flex align-items-center"
})`
  width: 100%;
`;


export const PlayerImageWrapper = styled.div.attrs({
    className: "position-relative me-2"
})`
  width: ${PLAYER_AVATAR_WIDTH};
  height: ${PLAYER_AVATAR_WIDTH};
`;
export const PlayerInfo = styled.div`
  width: calc(100% - 2.6rem);
`;
export const SpecialPlayerDotWrapper = styled.div.attrs({
    className: "position-absolute"
})`
  top: 0;
  left: 0;
  width: .6rem;
  height: .6rem;
`;
export const PlayerName = styled.div.attrs({
    className: "fz-sf-bold-subhead text-truncate w-100"
})``;
export const PlayerRole = styled.div.attrs({
    className: "fz-sf-regular-caption-1 tw-text-system-labelLightSecondary"
})`
`;
