import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { IconOmitProps } from "helpers/IconHelper";
import { IconBaseProps } from "react-icons";
import { cn } from "helpers/cn";

export interface FantasyPopupButtonSetting {
    confirmButton?: boolean;
    cancelButton?: boolean;
    confirmButtonText?: ReactNode;
    cancelButtonText?: ReactNode;
    confirmButtonVariant?: BUTTON_VARIANTS | NEW_BUTTON_VARIANTS;
    cancelButtonVariant?: BUTTON_VARIANTS | NEW_BUTTON_VARIANTS;
    confirmButtonPrefixIcon?: React.FC<IconOmitProps> | React.FC<IconBaseProps>;
    cancelButtonPrefixIcon?: React.FC<IconOmitProps> | React.FC<IconBaseProps>;
    confirmButtonSuffixIcon?: React.FC<IconOmitProps> | React.FC<IconBaseProps>;
    cancelButtonSuffixIcon?: React.FC<IconOmitProps> | React.FC<IconBaseProps>;
    confirmButtonDisabled?: boolean;
    buttonPattern?: BUTTON_PATTERN;
    buttonSize?: BUTTON_SIZE;
    onConfirm?: () => void;
    onCancel?: () => void;
    buttonGap?: number;
    buttonWrapperClassName?: string;
    confirmButtonClassName?: string;
}

export const ButtonInitConfig = {
    confirmButton: true,
    cancelButton: true,
    confirmButtonVariant: NEW_BUTTON_VARIANTS.primaryDark,
    cancelButtonVariant: NEW_BUTTON_VARIANTS.text,
    buttonPattern: BUTTON_PATTERN.pill,
    buttonSize: BUTTON_SIZE.lg,
    buttonGap: 1,
};

export const ButtonSection: React.FC<FantasyPopupButtonSetting> = ({
                                                                       buttonPattern,
                                                                       buttonSize,
                                                                       cancelButton,
                                                                       cancelButtonText,
                                                                       cancelButtonVariant,
                                                                       confirmButton,
                                                                       confirmButtonText,
                                                                       confirmButtonVariant,
                                                                       onCancel,
                                                                       onConfirm,
                                                                       confirmButtonPrefixIcon,
                                                                       cancelButtonPrefixIcon,
                                                                       confirmButtonSuffixIcon,
                                                                       cancelButtonSuffixIcon,
                                                                       confirmButtonDisabled,
                                                                       buttonGap,
                                                                       buttonWrapperClassName = "",
                                                                       confirmButtonClassName
                                                                   }) => {
    if (!confirmButton && !cancelButton) return null;

    return <div className={cn(`tw-flex tw-flex-wrap tw-gap-${buttonGap} tw-pt-4 tw-w-full`, buttonWrapperClassName)}>
        {
            confirmButton &&
            <Button
                variant={confirmButtonVariant}
                pattern={buttonPattern}
                size={buttonSize}
                className={cn("w-100", confirmButtonClassName)}
                onClick={onConfirm}
                prefixIcon={confirmButtonPrefixIcon}
                suffixIcon={confirmButtonSuffixIcon}
                disabled={confirmButtonDisabled}
            >
                {confirmButtonText || <FormattedMessage id="button_confirm" />}
            </Button>
        }
        {
            cancelButton &&
            <Button
                variant={cancelButtonVariant}
                pattern={buttonPattern}
                size={buttonSize}
                className="w-100"
                onClick={onCancel}
                prefixIcon={cancelButtonPrefixIcon}
                suffixIcon={cancelButtonSuffixIcon}
            >
                {cancelButtonText || <FormattedMessage id="button_cancel" />}
            </Button>
        }
    </div>;
};
