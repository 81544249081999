import { StepLabel } from "domain/kyc/components/stepProgressBar/StepLabel";
import { KYC_STEP } from "domain/kyc/pages/kycVerificationForm/KycVerificationForm";
import { cn } from "helpers/cn";
import { MdCheck } from "react-icons/md";

interface StepDotWithBarProps {
    step: KYC_STEP,
    currentStep: KYC_STEP
}

export const StepDotWithBar: React.FC<StepDotWithBarProps> = ({ currentStep, step }) => {
    const stepColor = currentStep >= step
        ? "tw-bg-primary-800 tw-text-grey-0"
        : "tw-bg-grey-150 tw-text-grey-1000";

    return <div className={"tw-flex tw-flex-row tw-grow tw-items-center"}>
        <div className={cn(stepColor, "tw-h-[0.4rem] tw-grow")} />
        <StepLabel step={step} currentStep={currentStep}>
            <div
                className={cn(
                    stepColor,
                    "tw-text-caption-1 tw-font-bold tw-flex tw-items-center tw-justify-center tw-rounded-full tw-w-[2.8rem] tw-h-[2.8rem] tw-pa-auto")}
            >
                {
                    currentStep > step
                        ? <MdCheck className={"tw-text-success-100"} size="2rem" />
                        : step
                }
            </div>
        </StepLabel>
    </div>;
};
