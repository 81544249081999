import JoinableNavbarMatchInfo from "components/container/navbar/JoinableNavbarMatchInfo";
import Navbar from "components/container/navbar/Navbar";
import PageViewRecorder from "components/ga/PageViewRecorder";
import PlayerAvatar from "components/image/PlayerAvatar";
import TeamPreview from "containers/teamPreview";
import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";
import { SortingIcon } from "domain/match/components/stats/PlayerStatsStyle";
import { getSportConfig } from "domain/SportConfig";
import { TeamContext } from "domain/team/OperateTeam";
import { orderPlayers } from "domain/team/player/ChoosePlayerHelper";
import { SortButton } from "domain/team/player/ChoosePlayerStyle";
import { PlayerUrlWrapper } from "domain/team/player/components/playerCard/PlayerCardStyle";
import usePlayerInfoDialog from "domain/team/playerInfoDialog/PlayerInfoDialog";
import { PlayerInfoDialogFooterType } from "domain/team/playerInfoDialog/PlayerInfoDialogFooter";
import { PlayerInfoDialogHeaderType } from 'domain/team/playerInfoDialog/PlayerInfoDialogHeader';
import {
    SelectedByHeadCol,
    SelectedByHeadText,
    SelectedSpecialPlayer,
    SelectedSpecialPlayerWrapper,
    SpecialPlayerContainer,
    SpecialPlayerHead,
    SpecialPlayerHeadCol,
    SpecialPlayerHeadRow,
    SpecialPlayerHeadText
} from "domain/team/special/ChooseSpecialPlayerStyle";
import HandlePlayerList from "domain/team/special/HandlePlayerList";
import SpecialPlayerCard from "domain/team/special/SpecialPlayerCard";
import { Icon, sortDownIcon, sortUpIcon, specialPlayerDefaultAvatar } from "helpers/IconHelper";
import { DESC } from "helpers/SortingHelper";
import useSort from "hooks/useSort/useSort";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CSSTransition } from "react-transition-group";
import { SQUAD_RIGHT_SLOT_TYPE } from "store/reducer/teamPreview/types";
import { FixedFooter } from "styles/component/GeneralStyle";

const playerSelectionRate = "playerSelectionRate";
const pointsAvg = "pointAvg";
const captainSelectionRate = "captainSelectionRate";
const viceCaptainSelectionRate = "viceCaptainSelectionRate";
const defaultSelectedSpecialPlayer = {
    id: 0,
    avatar: specialPlayerDefaultAvatar,
    name: "---",
    className: "header-bg-normal"
};
const ChooseSpecialPlayer = () => {
    const {
        match,
        selectedPlayers,
        specialPlayer,
        setSpecialPlayer,
        backToChoosePlayer,
        handleSubmit,
        handleTimeout: handleMatchStartedPopup,
        sport,
        myTeams
    } = useContext(TeamContext);
    const { getRoleAbbr } = getSportConfig(sport).team;
    const { sortKey, sortingDirection, handleSort } = useSort(playerSelectionRate);

    const captainRef = useRef<HTMLDivElement>(null);
    const viceCaptainRef = useRef<HTMLDivElement>(null);

    const {
        handlePlayerInfoDialogOpen,
        PlayerInfoDialog
    } = usePlayerInfoDialog(PlayerInfoDialogHeaderType.CREDIT, PlayerInfoDialogFooterType.ONLY_CLOSE);

    const teamPreview = TeamPreview.useTeamPreview({
        sport,
        squadRightSlotType: SQUAD_RIGHT_SLOT_TYPE.SUBSTITUTION,
        onAvatarClick: handlePlayerInfoDialogOpen
    });

    useEffect(() => {
        teamPreview.updateData({
            players: selectedPlayers,
        });
    }, [selectedPlayers, teamPreview]);

    const [nextStepFlag, setNextStepFlag] = useState(false);
    const handleSpecialRoleOnChange = (specialRole, checked, selectedPlayer) => {
        const handlePlayerList = new HandlePlayerList(specialRole, checked, selectedPlayer);

        handlePlayerList.execute(selectedPlayers, specialPlayer);
        const { resultSpecialPlayer } = handlePlayerList.getResult();
        const { captain, viceCaptain } = resultSpecialPlayer;
        setSpecialPlayer((prev) => ({ ...prev, captain, viceCaptain }));
    };

    const textAppearance = (_sortKey) => {
        return sortKey === _sortKey ? "text-secondary-2" : "text-light-secondary";
    };

    const iconAppearance = (_sortKey) => {
        return sortKey === _sortKey ? "visible" : "invisible";
    };

    const sortIcon = useMemo(() => {
        return sortingDirection === DESC ? sortDownIcon : sortUpIcon;
    }, [sortingDirection]);

    const renderPlayers = useMemo(() => {
        return orderPlayers(selectedPlayers, sortKey, sortingDirection);
    }, [selectedPlayers, sortKey, sortingDirection]);


    useEffect(() => {
        if (specialPlayer.captain && specialPlayer.viceCaptain) setNextStepFlag(true);
        else setNextStepFlag(false);
    }, [specialPlayer]);


    const selectedCaptain = renderPlayers.find(player => player.id === specialPlayer.captain);
    const selectedViceCaptain = renderPlayers.find(player => player.id === specialPlayer.viceCaptain);

    return <>
        <PageViewRecorder title="Choose Special Player" />
        <Navbar
            navbarStartAppend={<JoinableNavbarMatchInfo match={match}
                                                        handleTimeout={handleMatchStartedPopup} />}
            navbarBottom={<>
                <hr className="m-0 header-top-line opacity-100" />

                <SelectedSpecialPlayerWrapper>
                    <CSSTransition in={!!selectedCaptain}
                                   classNames="captain-transition"
                                   timeout={400}
                                   appear={true}
                                   nodeRef={captainRef}>
                        <SelectedSpecialPlayer ref={captainRef}>
                            <PlayerUrlWrapper className="text-center">
                                <PlayerAvatar
                                    id={selectedCaptain ? selectedCaptain.id : defaultSelectedSpecialPlayer.id}
                                    url={selectedCaptain ? selectedCaptain.avatar : defaultSelectedSpecialPlayer.avatar}
                                />
                            </PlayerUrlWrapper>
                            <div>
                                <div className="fz-sf-bold-footnote text-primary-1">
                                    <FormattedMessage id="select_special_player_page_label_captain" />
                                </div>
                                <div className="fz-sf-regular-footnote text-text-primary-1">
                                    {selectedCaptain ? selectedCaptain.name : defaultSelectedSpecialPlayer.name}
                                </div>
                                <div className="fz-sf-bold-subhead text-text-primary-1">
                                    <FormattedMessage id="label_captain_point_rate" />
                                </div>
                            </div>
                        </SelectedSpecialPlayer>
                    </CSSTransition>
                    <CSSTransition in={!!selectedViceCaptain}
                                   classNames="vice-captain-transition"
                                   appear={true}
                                   timeout={400}
                                   nodeRef={viceCaptainRef}>
                        <SelectedSpecialPlayer ref={viceCaptainRef} className="justify-content-end">
                            <div className="text-end">
                                <div className="fz-sf-bold-footnote text-secondary-1">
                                    <FormattedMessage id="select_special_player_page_label_vice_captain" />
                                </div>
                                <div className="fz-sf-regular-footnote text-text-primary-1">
                                    {selectedViceCaptain ? selectedViceCaptain.name : defaultSelectedSpecialPlayer.name}
                                </div>
                                <div className="fz-sf-bold-subhead text-text-primary-1">
                                    <FormattedMessage id="label_vice_captain_point_rate" />
                                </div>
                            </div>
                            <PlayerUrlWrapper className="text-center">
                                <PlayerAvatar
                                    id={selectedViceCaptain ? selectedViceCaptain.id : defaultSelectedSpecialPlayer.id}
                                    url={selectedViceCaptain ? selectedViceCaptain.avatar : defaultSelectedSpecialPlayer.avatar}
                                />
                            </PlayerUrlWrapper>
                        </SelectedSpecialPlayer>
                    </CSSTransition>
                </SelectedSpecialPlayerWrapper>
            </>}
            onNavbarBackClick={backToChoosePlayer}
        />
        <SpecialPlayerContainer>
            <SpecialPlayerHead>
                <SpecialPlayerHeadRow>
                    <SelectedByHeadCol>
                        <SortButton onClick={() => handleSort(playerSelectionRate)}>
                            <SelectedByHeadText className={textAppearance(playerSelectionRate)}>
                                <FormattedMessage id="label_selected_by" />
                            </SelectedByHeadText>
                            <SortingIcon className={iconAppearance(playerSelectionRate)} $bgImg={sortIcon} />
                        </SortButton>
                    </SelectedByHeadCol>
                    <SpecialPlayerHeadCol className="justify-content-end">
                        <SortButton onClick={() => handleSort(pointsAvg)}>
                            <SpecialPlayerHeadText className={textAppearance(pointsAvg)}>
                                <FormattedMessage id="label_score" />
                            </SpecialPlayerHeadText>
                            <SortingIcon className={iconAppearance(pointsAvg)} $bgImg={sortIcon} />
                        </SortButton>
                    </SpecialPlayerHeadCol>
                    <SpecialPlayerHeadCol className="justify-content-center">
                        <SortButton onClick={() => handleSort(captainSelectionRate)}>
                            <SpecialPlayerHeadText className={textAppearance(captainSelectionRate)}>
                                % C
                            </SpecialPlayerHeadText>
                            <SortingIcon className={iconAppearance(captainSelectionRate)} $bgImg={sortIcon} />
                        </SortButton>
                    </SpecialPlayerHeadCol>
                    <SpecialPlayerHeadCol className="justify-content-center">
                        <SortButton onClick={() => handleSort(viceCaptainSelectionRate)}>
                            <SpecialPlayerHeadText className={textAppearance(viceCaptainSelectionRate)}>
                                % VC
                            </SpecialPlayerHeadText>
                            <SortingIcon className={iconAppearance(viceCaptainSelectionRate)} $bgImg={sortIcon} />
                        </SortButton>
                    </SpecialPlayerHeadCol>
                </SpecialPlayerHeadRow>
            </SpecialPlayerHead>
            {
                renderPlayers.map(player => {
                    const roleAbbr = getRoleAbbr(player.role);
                    return <SpecialPlayerCard key={player.id}
                                              player={player}
                                              roleAbbr={roleAbbr}
                                              specialPlayer={specialPlayer}
                                              onAvatarClick={handlePlayerInfoDialogOpen.bind(null, player)}
                                              handleSpecialRoleOnChange={handleSpecialRoleOnChange} />;
                })
            }
        </SpecialPlayerContainer>

        <FixedFooter>
            <Button onClick={teamPreview.toggle} variant={BUTTON_VARIANTS.secondary}
                    prefixIcon={teamPreview.show ? Icon.EyeOff : Icon.EyeOn}
                    size={BUTTON_SIZE.lg}
                    pattern={BUTTON_PATTERN.pill} className={"w-100"}>
                <FormattedMessage id="label_preview" />
            </Button>
            <Button className={"w-100"}
                    disabled={!nextStepFlag}
                    onClick={handleSubmit}
                    size={BUTTON_SIZE.lg}
                    pattern={BUTTON_PATTERN.pill}
                    dataTestId="choose-special-player-confirm-button"
            >
                <FormattedMessage id="button_confirm" />
            </Button>
        </FixedFooter>

        <PlayerInfoDialog players={renderPlayers} match={match} myTeams={myTeams} />
    </>;

};

export default ChooseSpecialPlayer;
