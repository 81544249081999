import {
    fetchMyReferralCodeUrl,
    fetchRefereeRubySummaryUrl,
    fetchRefereeUrl,
    sendReferralCodeUrl
} from "apis/ApiUrlConfig";
import { ajax } from "apis/ApiUtils";
import { MyRefereeRubySummaryDto } from "data/dto/referral/MyRefereeRubySummaryDto";
import { RefereeDto } from "data/dto/referral/RefereeDto";


export const fetchMyReferralCode = async () => {
    const { data } = await ajax<string>({ url: fetchMyReferralCodeUrl });
    return data.data;
};

export const fetchRefereeRubySummary = async () => {
    const { data } = await ajax<MyRefereeRubySummaryDto[]>({ url: fetchRefereeRubySummaryUrl });
    return data.data;
};

export const fetchReferee = async () => {
    const { data } = await ajax<RefereeDto>({ url: fetchRefereeUrl });
    return data.data;
};

export const sendReferralCode = async (code: string) => {
    await ajax({ url: sendReferralCodeUrl, method: "POST", data: { code } });
};