import PrizeList from "components/prizelist/PrizeList";
import { PracticeContestWinningLayout, WinnerTakesAllRankingLayout, } from "components/ranking/RankingBody";
import { WinningHead } from "components/ranking/WinningHead";
import { ContestDetailVo } from "data/vo/contest/ContestDetailVo";
import { LeaderboardTeamVo } from "data/vo/leaderboard/LeaderboardTeamVo";
import { PrizeListVo } from "data/vo/PrizeList/PrizrListVo";
import { isPracticeContest, isWinnerTakesAll, } from "domain/contest/join/info/detail/ContestInfoDetailHelper";
import { RankingText, Wrapper, } from "domain/contest/join/info/detail/ContestInfoDetailStyle";
import LeaderboardSwitcher from "domain/contest/join/info/detail/LeaderboardSwitcher";
import {
    PrizeDistributionButton,
    PrizeDistributionButtonProps
} from "domain/contest/join/info/detail/PrizeDistributionButton";
import { ScoringTable } from "domain/team/playerInfoDialog/scoringTable/ScoringTable";
import { ScoringTableTitle } from "domain/team/playerInfoDialog/scoringTable/ScoringTableStyle";
import { isMatchStarted } from "helpers/match/MatchHelper";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ActiveTabItem, TabItem, TabWrapper } from "styles/component/TabStyle";
import { CONTEST_TYPE_FROM_API, ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";
import { ContestDetailVO } from "types/contest/ContestDetail";
import { SPORT } from "types/sports/Sport";

interface WinningComponentProps extends WinningBodyProps {
    sport: SPORT;
}

const WinningComponent: React.FC<WinningComponentProps> = ({
                                                               contestId,
                                                               minSlips,
                                                               spots,
                                                               type,
                                                               sport,
                                                               prizeList,
                                                               entryFeeType,
                                                           }) => {
    return (
        <>
            <WinningBody
                prizeList={prizeList}
                entryFeeType={entryFeeType}
                type={type}
                minSlips={minSlips}
                spots={spots}
                contestId={contestId} />
            <div className="mt-3">
                <ScoringTable sport={sport}>
                    <ScoringTableTitle className="text-center">
                        <FormattedMessage id="label_scoring"></FormattedMessage>
                    </ScoringTableTitle>
                </ScoringTable>
            </div>
        </>
    );
};

interface WinningBodyProps extends Omit<PrizeDistributionButtonProps, "idealPrizeListLength"> {
    type: CONTEST_TYPE_FROM_API;
    entryFeeType: ENTRY_FEE_TYPE_FROM_API;
    prizeList: PrizeListVo[];
}

export const WinningBody: React.FC<WinningBodyProps> = ({
                                                            contestId,
                                                            minSlips,
                                                            spots,
                                                            type,
                                                            prizeList,
                                                            entryFeeType,
                                                        }) => {

    if (isPracticeContest(prizeList)) {
        return <PracticeContestWinningLayout />;
    }

    if (isWinnerTakesAll(prizeList)) {
        return (
            <>
                <WinnerTakesAllRankingLayout
                    prizeList={prizeList}
                    entryFeeType={entryFeeType}
                />
            </>
        );
    }

    return (
        <>
            <PrizeList prizeList={prizeList} entryFeeType={entryFeeType} />
            <RankingText>
                <FormattedMessage
                    id="contest_detail_page_ranking_label_prize_hint"
                />
                {type === CONTEST_TYPE_FROM_API.NORMAL &&
                    <PrizeDistributionButton
                        minSlips={minSlips}
                        spots={spots}
                        entryFeeType={entryFeeType}
                        contestId={contestId}
                        idealPrizeListLength={prizeList.length} />
                }
            </RankingText>
        </>
    );
};

const ContestInfoTabWinning = "winnings";
const ContestInfoTabLeaderboard = "leaderboard";

interface ContestInfoDetailProps {
    contest: ContestDetailVo | ContestDetailVO;
    myJoinedTeams: LeaderboardTeamVo[];
    stickyTop?: number;
}

const ContestInfoDetail: React.FC<ContestInfoDetailProps> = ({
                                                                 contest,
                                                                 myJoinedTeams,
                                                                 stickyTop = 6.5,
                                                             }) => {
    const intl = useIntl();

    const contents: {
        key: string;
        name: string;
        tableHead: React.FC<any>;
        tableHeadProps: any;
        component: React.FC<any>;
        componentProps: any;
    }[] = useMemo(() => {
            return [
                {
                    key: ContestInfoTabWinning,
                    name: intl.formatMessage({ id: "contest_detail_page_tab_winnings" }),
                    tableHead:
                        isWinnerTakesAll(contest.prizeList) || isPracticeContest(contest.prizeList)
                            ? () => null
                            : WinningHead,
                    tableHeadProps: {},
                    component: WinningComponent,
                    componentProps: {
                        contestId: contest.id,
                        minSlips: (contest as ContestDetailVo)?.minimumSlip,
                        spots: contest.spots,
                        type: contest.type,
                        sport: contest.sport,
                        prizeList: contest.prizeList,
                        entryFeeType: contest.entryFeeType,
                    } as WinningComponentProps,
                },
                {
                    key: ContestInfoTabLeaderboard,
                    name: intl.formatMessage({ id: "contest_detail_page_tab_leaderboard" }),
                    tableHead: () => null,
                    tableHeadProps: {},
                    component: LeaderboardSwitcher,
                    componentProps: {
                        contest: contest as ContestDetailVo,
                        myJoinedTeams,
                    },
                }
            ];
        },
        [
            intl,
            contest,
            myJoinedTeams,
        ],
    );

    const [activeTab, setActiveTab] = useState(() => {
        if (isMatchStarted(contest.matchStatus)) {
            return ContestInfoTabLeaderboard;
        }
        return ContestInfoTabWinning;
    });

    const {
        tableHead: Head,
        component: Body,
        tableHeadProps,
        componentProps,
    } = _.find(contents, ({ key }) => key === activeTab)!;

    return (
        <>
            <Wrapper $stickyTop={stickyTop}>
                <TabWrapper>
                    {contents.map(({ key, name }) => {
                        return activeTab === key ? (
                            <ActiveTabItem key={_.uniqueId("tab-")}>
                                {_.startCase(name)}
                            </ActiveTabItem>
                        ) : (
                            <TabItem
                                key={_.uniqueId("tab-")}
                                onClick={() => setActiveTab(key)}
                            >
                                {_.startCase(name)}
                            </TabItem>
                        );
                    })}
                </TabWrapper>
                <Head {...tableHeadProps} />
            </Wrapper>
            <Body {...componentProps} />
        </>
    );
};

export default ContestInfoDetail;
