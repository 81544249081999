import { Icon } from "helpers/IconHelper";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { SPORT } from "types/sports/Sport";

const tabSettings = [
    {
        key: SPORT.CRICKET,
        intlId: "sport_tab_label_cricket",
        icon: Icon.CricketIcon
    },
    {
        key: SPORT.FOOTBALL,
        intlId: "sport_tab_label_football",
        icon: Icon.FootballIcon
    },
    {
        key: SPORT.BASKETBALL,
        intlId: "sport_tab_label_basketball",
        icon: Icon.BasketballIcon
    }
];

export const useSportSettings = () => {
    const intl = useIntl();
    return useMemo(() => tabSettings.map(tab => ({
        key: tab.key,
        name: intl.formatMessage({ id: tab.intlId }),
        icon: (size?: string, className?: string) => <tab.icon size={size} className={className} />
    })), []);
};
