import PlayerAvatar from "components/image/PlayerAvatar";
import { NineCatPlayerDetailVO, NineCatPlayerInfoVO, PlayerStatsSlotCardVO } from "domain/slate/data/ComponentTypes";
import { useCreateSlateTeamContext } from "domain/slate/providers/CreateNineCatTeamProvider";
import { cn } from "helpers/cn";

import { Icon } from "helpers/IconHelper";
import { generatePlayerName } from "helpers/PlayerHelpers";
import React from "react";
import { MdQuestionMark } from "react-icons/md";
import { MouseEvent, MouseEventHandler } from "react/index";
import { SlotCardAnimatedPing } from "./SlotCardAnimatedPing";

export interface FilledPlayerStatsSlotCardItemProps extends PlayerStatsSlotCardVO {
    onSelectStatPlayer: (e?: any) => void;
    onOpenPlayerInfo: (player: NineCatPlayerDetailVO) => void;
    playerInfo: NineCatPlayerInfoVO;
    onOpenStatDetailModal: (e: MouseEvent) => void;
}

const FilledPlayerStatSlotCardItem: React.FC<FilledPlayerStatsSlotCardItemProps> = ({
                                                                                        focused,
                                                                                        nineCatStats,
                                                                                        playerInfo,
                                                                                        onSelectStatPlayer,
                                                                                        onOpenPlayerInfo,
                                                                                        onOpenStatDetailModal
                                                                                    }) => {
    const handleOpenPlayerInfo: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation();
        onOpenPlayerInfo(playerInfo as NineCatPlayerDetailVO);
    };

    const slateContext = useCreateSlateTeamContext();
    const { statToPlayerIdMap, focusedPlayerId, focusedStat } = slateContext;

    function getShouldShowPingAnimation() {
        if (focused) return false;
        if (statToPlayerIdMap[focusedPlayerId!] === nineCatStats.statAbbr) return false;

        return !!(focusedStat || focusedPlayerId);
    }

    const shouldShowPingAnimation = getShouldShowPingAnimation();

    const handleToggleStat: MouseEventHandler<HTMLElement> = e => {
        e.stopPropagation();
        onSelectStatPlayer();
    };

    return (
        <div
            data-testid={"player-stat-slot-card"}
            className={cn(
                "tw-relative tw-w-[8.4rem] tw-h-[9.2rem] tw-bg-primary-900 tw-rounded-[1.6rem] tw-pt-[0.6rem] tw-pl-[1.2rem] tw-shadow-sm",
                "tw-border-transparent tw-border-solid tw-border-2",
                "tw-cursor-pointer tw-select-none",
                focused ? "tw-border-secondary-300 tw-border-solid tw-border-2" : "",
            )}
            onClick={handleToggleStat}
        >
            <div
                className={cn("tw-w-[3.4rem] tw-h-[3.4rem] tw-rounded-full tw-overflow-clip tw-bg-secondary-600", "tw-shadow-[-5px_-2px_5px_0px_#00000040_inset]",
                    "tw-relative tw-z-1")}
                onClick={handleOpenPlayerInfo}
            >
                <PlayerAvatar id={playerInfo.id} url={playerInfo.avatar} />
            </div>
            <p className={"tw-pt-1 tw-max-w-[6rem] tw-truncate tw-text-white tw-font-bold"}>
                {generatePlayerName(playerInfo)}
            </p>
            <div className="tw-flex tw-items-center tw-pt-2">
                <Icon.Refresh className={"tw-text-gray-400"} />
                {shouldShowPingAnimation && <SlotCardAnimatedPing />}
                <span className={"tw-pl-0.5 tw-text-[1.6rem] tw-font-light tw-leading-4 tw-text-gray-50"}>
                    {nineCatStats.statAbbr}
                </span>
            </div>
            <button
                className={cn("tw-absolute",
                    "tw-flex tw-items-center tw-justify-center",
                    "tw-border-gray-50 tw-border-2 tw-border-solid",
                    "tw-w-[20px] tw-h-[20px] tw-bg-primary-600",
                    "tw-box-content tw-rounded-tr-[1.2rem] tw-rounded-bl-[1.2rem]",
                    "tw-text-lg",
                    focused ? "tw-text-black" : "tw-text-white",
                    "tw-top-[-4px] tw-right-[-4px]",
                    focused ? "tw-bg-secondary-300" : "",
                )}
                onClick={onOpenStatDetailModal}
            >
                <MdQuestionMark size={"1.2rem"} />
            </button>
        </div>
    );
};

export default FilledPlayerStatSlotCardItem;
