import { getContestCodeDetailUrl } from "apis/ApiUrlConfig";
import Countdown from "components/countdown/Countdown";
import { FeaturedContestVo } from "data/vo/contest/FeaturedContestVo";
import { KickoffCountdown } from "domain/home/components/upcoming/UpcomingMatches";
import MatchStatusWithDot from "domain/match/components/status/MatchStatusWithDot";
import { cn } from "helpers/cn";
import { isMatchKickoff } from "helpers/match/MatchHelper";
import useFantasyConfig from "hooks/useFantasyConfig";
import { Border } from "officialWebsite/FeatureContest/components/Border";
import { CardSquared } from "officialWebsite/FeatureContest/components/CardSquared";
import { EntryFee } from "officialWebsite/FeatureContest/components/EntryFee";
import React, { useCallback } from "react";

interface FeatureContestCardProps {
    featuredContest: FeaturedContestVo;
    onRefetchFeatureContests: () => void;
}

export const FeatureContestCard: React.FC<FeatureContestCardProps> = ({
                                                                          featuredContest,
                                                                          onRefetchFeatureContests
                                                                      }) => {
    const { gameUrl } = useFantasyConfig();

    const renderCountdown = useCallback(() => {
        if (isMatchKickoff(featuredContest.matchStatus)) {
            return (
                <>
                    <MatchStatusWithDot matchStatus={featuredContest.matchStatus}
                                        shouldBlink={false} />
                    <KickoffCountdown matchStartsAt={featuredContest.matchStartsAt}
                                      handleTimeout={onRefetchFeatureContests}
                                      fontStyle="fz-sf-regular-footnote"
                    />
                </>
            );
        }
        return <Countdown finishAt={featuredContest.matchStartsAt} handleTimeout={onRefetchFeatureContests} />;
    }, [featuredContest]);

    const contestDetailUrl = gameUrl + getContestCodeDetailUrl(featuredContest.code);

    return (
        <a target={"_blank"}
           href={contestDetailUrl}
           rel="noreferrer"
        >
            <Border>
                <EntryFee featuredContest={featuredContest} />
                <div className={cn(
                    "tw-pt-[14px] tw-flex tw-flex-col tw-items-center tw-gap-[8px]",
                )}>
                    <CardSquared name={featuredContest.squadAName} icon={featuredContest.squadALogoUrl} />
                    <CardSquared name={featuredContest.squadBName} icon={featuredContest.squadBLogoUrl} />
                </div>

                <div className={cn(
                    " tw-flex tw-items-center tw-justify-center tw-gap-[4px]",
                    "tw-mt-[15px] tw-py-[8px] tw-px-[16px] tw-bg-[#E5E5E7] tw-rounded-full !tw-text-[12px]",
                )}>
                    {renderCountdown()}
                </div>
            </Border>
        </a>
    );


};
