import { getSportConfig } from "domain/SportConfig";
import { updateSelectTeamPanelData, updateTeamPreviewData } from "store/actions/Actions";
import { isBadResponseFormat } from "../../helpers/ErrorHelper";
import { storageHelper } from "../../helpers/localStorage/LocalStorageHelper";
import { sortByIdDesc } from "../../helpers/SortingHelper";
import store from "../../store";
import { MatchVO, ResultMatchVO } from "../../types/match/Match";
import { SPORT } from "../../types/sports/Sport";
import { MyTeamVO } from "../../types/team/MyTeam";
import {
    getMatchDetailUrl,
    getMatchSnapshotUrl,
    getMyContestUrl,
    getMyMatchesUrl,
    getMyTeamUrl,
    getPlayerStatsUrl,
    getPublicContestUrl,
    getScorecardUrl,
    getUpcomingMatchesUrl
} from "../ApiUrlConfig";
import { ajax } from "../ApiUtils";
import { Contest, MyContest, UpcomingMatchVO } from "./type";

export const fetchUpcomingMatches = (sport: string) => {
    return new Promise<UpcomingMatchVO[]>((resolve, reject) => {
        ajax<UpcomingMatchVO[]>({ url: getUpcomingMatchesUrl(sport) })
            .then(({ data }) => {

                const matches = data.data
                    .map(match => ({
                        ...match,
                        sport
                    }));

                resolve(matches);
            })
            .catch(error => reject(error));
    });
};

export const fetchPublicContest = (sport: SPORT, matchId: string): Promise<Contest[]> => {
    return new Promise((resolve, reject) => {
        ajax<Contest[]>({ url: getPublicContestUrl(sport, matchId) })
            .then(({ data }) => resolve(
                data.data)
            )
            .catch(error => reject(error));
    });
};

export const fetchMyContest = (sport: SPORT, matchId: string, signal?: AbortSignal): Promise<MyContest[]> => {
    return new Promise((resolve, reject) => {
        ajax<MyContest[]>({ url: getMyContestUrl(sport, matchId), signal })
            .then(({ data }) => resolve(data.data))
            .catch(error => reject(error));
    });
};

export const BLANK_MATCH_SNAPSHOT = {
    commentary: "",
    batsmen: [],
    bowlers: [],
    scores: []
};

export const fetchMatchSnapshot = (sport, matchId) => {
    return new Promise((resolve, reject) => {
        ajax({ url: getMatchSnapshotUrl(sport, matchId) })
            .then(({ data }) => resolve(data.data))
            .catch(error => isBadResponseFormat(error) ? resolve(BLANK_MATCH_SNAPSHOT) : reject(error));
    });
};

export const fetchSortedMyTeam = (sport, matchId, signal?): Promise<MyTeamVO[]> => {
    return new Promise((resolve, reject) => {
        ajax<MyTeamVO[]>({ url: getMyTeamUrl(sport, matchId), signal })
            .then(({ data }) => {
                const myTeams = sortByIdDesc(data.data.map(team => ({
                    ...team,
                    teamRolesCount: getSportConfig(sport).team.getTeamRolesCount(team)
                })));
                store.dispatch(updateTeamPreviewData({
                    myTeams
                }));
                store.dispatch(updateSelectTeamPanelData({
                    myTeams
                }));
                resolve(myTeams);
            })
            .catch(error => reject(error));
    });
};

export const fetchMatchDetail = (sport, matchId): Promise<MatchVO> => {
    return new Promise((resolve, reject) => {
        ajax<MatchVO>({ url: getMatchDetailUrl(sport, matchId) })
            .then(({ data }) => {
                store.dispatch(updateTeamPreviewData({
                    match: data.data
                }));
                store.dispatch(updateSelectTeamPanelData({
                    match: data.data
                }));
                resolve(data.data);
            })
            .catch(error => reject(error));
    });
};

export const fetchMyMatches = (sport, signal?) => {

    const { get, put } = storageHelper(`myMatches-${sport}`);
    const myMatches = get();

    const isMyMatchesInAMinute = () => (new Date().getTime() - myMatches.time) < (60 * 1000);

    return new Promise((resolve, reject) => {
        if (myMatches && isMyMatchesInAMinute()) {
            resolve(myMatches.myMatches);
            return;
        }

        ajax({ url: getMyMatchesUrl(sport), signal })
            .then(({ data }) => {
                put({ time: new Date().getTime(), myMatches: data.data });
                resolve(data.data);
            })
            .catch(error => reject(error));

    });
};

export const fetchScorecard = (matchId, signal?) => {
    return new Promise((resolve, reject) => {
        ajax({ url: getScorecardUrl(matchId), signal })
            .then(({ data }) => resolve(data.data))
            .catch(error => reject(error));
    });
};

export const fetchPlayerStats = (sport, matchId, signal?) => {
    return new Promise((resolve, reject) => {
        ajax({ url: getPlayerStatsUrl(sport, matchId), signal })
            .then(({ data }) => resolve(data.data))
            .catch(error => reject(error));
    });
};

export const fetchResultMatches = (sport: SPORT): Promise<ResultMatchVO> => {
    return ajax<ResultMatchVO>({
        url: `/${sport}/match/result`
    })
        .then((res) => res.data.data);
};


