import { AWARD_TYPE } from "../../helpers/AwardHelper";
import { ajax, AjaxResponse } from "../ApiUtils";


interface CampaignStatusDto {
    status: string
}

export const fetchCampaign = async () => {
    const response = await ajax<CampaignStatusDto>({ url: "/campaign/status" });
    return response.data.data.status;
};

export const addCampaign = async (redemptionCode: string) => {
    const response: AjaxResponse<AddCampaignResponseProps> = await ajax({
        url: "/campaign",
        method: "POST",
        data: { redemptionCode }
    });
    return response.data.data;
};

export interface AddCampaignResponseProps {
    type: AWARD_TYPE,
    discountPercentage?: number,
    coinCent?: number,
    rubyCent?: number
}
