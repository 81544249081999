import React from "react";
import { orderBy } from "lodash";
import { FormattedMessage } from "react-intl";
import { CouponCardBody, CouponCardFooter, CouponWrapper, CoveredCouponCard } from "./CouponStyle";
import EmptyCoupon from "./EmptyCoupon";
import CouponCardBodyContent from "./CouponCardBodyContent";
import Badge, { BADGE_PATTERN, BADGE_SIZE, BADGE_VARIANTS } from "../../../designToken/badge/Badge";
import { EnrichedCoupon } from "../../../types/coupon/Coupon";


const ExpiredCoupon = ({ coupons }: { coupons: EnrichedCoupon[] }) => {
    return <CouponWrapper>
        {
            coupons.length > 0
                ? <>
                    <div className="text-secondary-2 fz-sf-regular-body-2">
                        <FormattedMessage id="my_coupon_page_label_reserved_30_days" />
                    </div>
                    {
                        orderBy(coupons, "to").map(coupon => {
                            return <CoveredCouponCard key={coupon.id} data-testid={`coupon-card-${coupon.id}`}>
                                <Badge variant={BADGE_VARIANTS.DARK} size={BADGE_SIZE.MD}
                                       pattern={BADGE_PATTERN.ROUNDED}
                                       className="expired-coupon-badge position-absolute top-0 start-0">
                                    <FormattedMessage id="my_coupon_page_tab_expired" />
                                </Badge>
                                <CouponCardBody>
                                    <CouponCardBodyContent coupon={coupon} />
                                </CouponCardBody>
                                <CouponCardFooter className="text-text-secondary-1">
                                    {coupon.startDate} ~ {coupon.endDate}
                                </CouponCardFooter>
                            </CoveredCouponCard>;
                        })
                    }
                </>
                : <EmptyCoupon />
        }

    </CouponWrapper>;
};

export default ExpiredCoupon;