import { IconDot } from "domain/kyc/components/stepProgressBar/IconDot";
import { StepDotWithBar } from "domain/kyc/components/stepProgressBar/StepDotWithBar";

import { KYC_STEP } from "domain/kyc/pages/kycVerificationForm/KycVerificationForm";
import { Icon } from "helpers/IconHelper";

interface StepProgressBarProps {
    currentStep: KYC_STEP;
}

export const StepProgressBar: React.FC<StepProgressBarProps> = ({ currentStep }) => {
    return <div>
        <ul className="tw-flex tw-flex-row tw-pt-[2.2rem]">
            <IconDot
                icon={currentStep === KYC_STEP.OTP ? Icon.KycOtpUnverified : Icon.KycOtpVerified}
                step={KYC_STEP.OTP}
                currentStep={currentStep}
            />

            {
                [KYC_STEP.STEP_1, KYC_STEP.STEP_2, KYC_STEP.STEP_3, KYC_STEP.STEP_4].map(step =>
                    <StepDotWithBar
                        key={`step-progress-${step}`}
                        step={step}
                        currentStep={currentStep}
                    />
                )
            }
        </ul>
    </div>;
};
