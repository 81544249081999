import React, { ReactNode } from "react";

export const CurrentBalanceDivider: React.FC<{ children: ReactNode }> = ({ children }) => <div
    className={"tw-flex tw-items-center"}>
    <div className={"tw-w-full tw-h-[1px] tw-bg-grey-400"} />
    <h4 className={"tw-px-1 tw-shrink-0 tw-text-caption-1 tw-text-grey-800"}>
        {children}
    </h4>
    <div className={"tw-w-full tw-h-[1px] tw-bg-grey-400"} />
</div>;
