import { ListGroup } from "react-bootstrap";
import styled from "styled-components";
import { cn } from "helpers/cn";

export const ModalContainer = styled.div.attrs(() => ({}))`
    padding: 2.4rem 1.6rem;
`;
export const ModalTitleRow = styled.div.attrs(() => ({
    className: "d-flex justify-content-between align-items-center"
}))`
    padding-bottom: 1.2rem;
    margin-bottom: 1.2rem;
`;
export const ModalTitle = styled.div.attrs(() => ({
    className: "fz-sf-bold-title-1 tw-text-system-primary80"
}))``;
export const ModalClose = styled.div.attrs<{ $icon: string }>({
    className: "ms-auto"
})`
    background: url(${({ $icon }) => $icon}) center no-repeat;
    background-size: 100% 100%;
    width: 2.4rem;
    padding-bottom: 2.4rem;
    cursor: pointer;
`;
export const ListItem = styled(ListGroup.Item).attrs(({ className }) => ({
    className: cn("d-flex justify-content-between align-items-center tw-border-system-labelLightQuaternary", className),
    "data-testid": "history-detail-list-item"
}))`
    padding: .8rem;
    border-width: 0 0 1px 0;
    border-style: solid;

`;
export const ListTitle = styled.div.attrs(() => ({
    className: "fz-sf-regular-subhead tw-text-system-labelLightSecondary"
}))`
`;

export const ListValue = styled.div.attrs(() => ({
    className: "fz-sf-regular-body-1 tw-text-system-labelLightPrimary"
}))`
    text-align: right;
`;

