import styled from "styled-components";
import { APP_MAX_WIDTH, SYSTEM_COLORS } from "../../../../styles/Constants";
import { UserCode } from "../../../me/me/MeStyle";

export const FriendGroupWrapper = styled.div.attrs({
    className: "position-relative",
})`
  padding-bottom: 6rem;
`;

export const Alphabet = styled.div.attrs({
    className: "fz-sf-regular-body-1 tw-text-system-labelLightSecondary",
})`
  padding-left: 0.8rem;
  margin-bottom: 1.4rem;
`;
export const FriendListRow = styled.div.attrs({
    className: "d-flex align-items-center",
})`
  padding: 0.8rem;
  margin-bottom: 1.4rem;
  border-radius: 3rem;
  cursor: pointer;

  &.active {
    background-color: ${SYSTEM_COLORS.primary8};
  }
`;

export const FriendNameWrapper = styled.div.attrs({
    className: "d-flex align-items-baseline",
})`
  margin-left: 0.8rem;
`;
export const FriendName = styled.div.attrs({
    className: "fz-sf-regular-body-2 tw-text-system-labelLightPrimary",
})`
`;
export const FriendCode = styled(UserCode).attrs({
    className: "ms-2 tw-text-system-labelLightSecondary",
})`
`;
export const NicknameAnchorWrapper = styled.div.attrs({
    className: "position-fixed",
})`
  top: 8.8rem;
  right: 0;
  width: 1.2rem;
  @media (min-width: ${APP_MAX_WIDTH}) {
    right: auto;
    left: calc(${APP_MAX_WIDTH} - 1.2rem);
  }
`;

export const NicknameAnchor = styled.div.attrs({
    className: "fz-sf-regular-footnote tw-text-system-blue clickable",
})`
`;

export const SearchUserBarWrapper = styled.div.attrs({})`
  margin-bottom: 1.2rem;
`;
