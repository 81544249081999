import { SportRoleVO } from "./Sport";

export type BASKETBALL_KEY = "POINT_GUARD" | "SHOOTING_GUARD" | "SMALL_FORWARD" | "POWER_FORWARD" | "CENTER";
export const BASKETBALL_ROLE: SportRoleVO<BASKETBALL_KEY> = {
    POINT_GUARD: {
        abbr: "PG",
        apiValue: "point_guard"
    },
    SHOOTING_GUARD: {
        abbr: "SG",
        apiValue: "shooting_guard"
    },
    SMALL_FORWARD: {
        abbr: "SF",
        apiValue: "small_forward"
    },
    POWER_FORWARD: {
        abbr: "PF",
        apiValue: "power_forward"
    },
    CENTER: {
        abbr: "C",
        apiValue: "center"
    },
};