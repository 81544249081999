import styled from "styled-components";

export const MatchSquadWrapper = styled.div.attrs(() => ({
    className: "w-100 text-text-tertiary-1 d-flex justify-content-between"
}))``;

export const SquadInfoLeftWrapper = styled.div.attrs(() => ({
    className: "d-flex position-relative justify-content-end pe-4 align-items-end"
}))`
  flex-grow: 1;
  word-break: break-all;
  width: 0;
`;

export const SquadInfoRightWrapper = styled.div.attrs(() => ({
    className: "d-flex position-relative ps-4 align-items-end"
}))`
  flex-grow: 1;
  word-break: break-all;
  width: 0;
`;

export const ImageWrapper = styled.div.attrs(() => ({
    className: "px-2 pt-2 pb-3"
}))``;

export const SquadLogoWrapper = styled.div.attrs(() => ({
    className: "position-relative"
}))`
  width: 2.4rem;
`;
export const SquadCrown = styled.div.attrs<{
    $bgImg: string
}>({
    className: "position-absolute"
})`
  width: 100%;
  padding-bottom: 100%;
  background-image: url(${({ $bgImg }) => $bgImg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  top: -75%;
`;
export const SquadLeftName = styled.div.attrs(() => ({
    className: "text-end"
}))``;
export const SquadRightName = styled.div.attrs(() => ({
    className: "text-start"
}))``;
export const RightSquadQuickSummary = styled.div.attrs<{
    $completed: boolean,
    $highlight: boolean
}>(({ $completed, $highlight }) => ({
    className: `${$completed ? "fz-sf-bold-title-2" : "fz-sf-regular-title-2"} ${$highlight ? "text-text-primary-1" : "text-text-tertiary-1"}`
}))``;
export const LeftSquadQuickSummary = styled(RightSquadQuickSummary).attrs(() => ({
    className: "text-end"
}))`
`;
