import {
    addResponsibleGamingRegistration,
    fetchResponsibleGamingRegistration
} from "data/api/responsibleGaming/ResponsibleGamingApi";
import {
    AddResponsibleGamingRegistrationRequest
} from "data/dto/responsibleGaming/AddResponsibleGamingRegistrationRequest";

export class ResponsibleGamingRepository {
    public async getResponsibleGamingRegistration() {
        return await fetchResponsibleGamingRegistration();
    }

    public async addResponsibleGamingRegistration(request: AddResponsibleGamingRegistrationRequest) {
        await addResponsibleGamingRegistration(request);
    }
}

