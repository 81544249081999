import _ from "lodash";
import React, { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { fetchCurrentApLevelList } from "../../../../apis/ap/ActivityPointApi";
import { ApiExecuteContext } from "../../../../apis/ApiContext";
import { fetchFriends } from "../../../../apis/user/UserApi";
import UserAvatarWithFrame from "../../../../components/avatar/UserAvatarWithFrame";
import { useLoadingToggle } from "../../../../components/loading/Loading";
import { getUserIdToCurrentLevel } from "../../../ap/ActivityPointHelper";
import { getUserIds } from "../../FriendHelper";
import EmptyFriendList from "../../list/EmptyFriendList";
import { SearchUserIcon } from "../../list/FriendListStyle";
import { SearchUserBar } from "../../searchUserBar/SearchUserBar";
import { TransferNavbar } from "../TransferFlow";
import { groupAndSortFriendByNickname, isSelected } from "../TransferHelper";
import { FixedPrimaryButton, TransferContentWrapper, TransferWrapper } from "../TransferStyle";
import {
    Alphabet,
    FriendCode,
    FriendGroupWrapper,
    FriendListRow,
    FriendName,
    FriendNameWrapper,
    NicknameAnchor,
    NicknameAnchorWrapper,
    SearchUserBarWrapper
} from "./SelectFriendStyle";

const searchUserIcon = "/images/search_user_icon.png";


const SelectFriend = ({
                          toPrevStep,
                          toNextStep,
                          selectedFriend,
                          setSelectedFriend,
                          setInputAmount
                      }) => {
    const intl = useIntl();
    const apiExecutor = useContext(ApiExecuteContext);
    const toggleLoading = useLoadingToggle();

    const alphabetRefs = useRef([]);
    const [alphabetToFriends, setAlphabetToFriends] = useState({});
    const [showSearchUserBar, setShowSearchUserBar] = useState(false);
    const [userIdToCurrentLevel, setUserIdToCurrentLevel] = useState({});

    const handleNicknameAnchorClicked = (index) => {
        const top = alphabetRefs.current[index].offsetTop;
        window.scrollTo({
            top,
            behavior: "smooth"
        });
    };
    const alphabets = Object.keys(alphabetToFriends);

    const setFriendAndToNextStep = ({ id, avatar, nickname }) => {
        setSelectedFriend({
            userId: id, avatar, nickname
        });
        toNextStep();
    };

    const getCurrentApLevelList = useCallback(userIds => {
        apiExecutor(
            fetchCurrentApLevelList(userIds),
            {
                onSuccess: (res) => setUserIdToCurrentLevel(getUserIdToCurrentLevel(res))
            }
        );
    }, [apiExecutor]);

    const getFriendList = useCallback(() => {
        return apiExecutor(
            fetchFriends(),
            {
                onSuccess: ({ friends }) => {
                    setAlphabetToFriends(groupAndSortFriendByNickname(friends));
                    getCurrentApLevelList(getUserIds(friends));
                }
            }
        );
    }, [apiExecutor, setAlphabetToFriends, getCurrentApLevelList]);

    useEffect(() => {
        (async () => {
            toggleLoading(true);
            await getFriendList();
            toggleLoading(false);
        })();
    }, [getFriendList, toggleLoading]);

    useEffect(() => {
        setInputAmount(0);
    }, [setInputAmount]);

    return <>
        <TransferNavbar
            text={intl.messages["choose_recipient_page_title"]}
            handleBack={toPrevStep}
            endIcon={<SearchUserIcon
                bgImg={searchUserIcon}
                onClick={() => setShowSearchUserBar(!showSearchUserBar)}
            />}
        />
        <TransferWrapper>
            <TransferContentWrapper>
                {showSearchUserBar && <SearchUserBarWrapper>
                    <SearchUserBar
                        onTransferUserClicked={setFriendAndToNextStep}
                        onNewFriendAdded={getFriendList}
                    />
                </SearchUserBarWrapper>}
                <NicknameAnchorWrapper>
                    {
                        alphabets.map((alphabet, index) =>
                            <NicknameAnchor key={"alphabet-" + alphabet}
                                            onClick={() => handleNicknameAnchorClicked(index)}>{alphabet}</NicknameAnchor>)
                    }
                </NicknameAnchorWrapper>
                <FriendGroupWrapper>
                    {
                        _.isEmpty(alphabetToFriends)
                            ? <EmptyFriendList />
                            : Object.entries(alphabetToFriends).map(([alphabet, friends], index) =>
                                <Fragment key={"group-alphabet-" + alphabet}>
                                    <Alphabet ref={(el) => alphabetRefs.current[index] = el}>
                                        {alphabet}
                                    </Alphabet>
                                    {
                                        friends.map(friend => <FriendListRow key={friend.userId}
                                                                             className={isSelected(friend.userId, selectedFriend.userId) ? "active" : ""}
                                                                             onClick={() => setSelectedFriend(friend)}>
                                            <UserAvatarWithFrame url={friend.avatar}
                                                                 name={friend.nickname}
                                                                 size="3.2rem"
                                                                 active={isSelected(friend.userId, selectedFriend.userId)}
                                                                 currentLevel={userIdToCurrentLevel[friend.userId]} />
                                            <FriendNameWrapper>
                                                <FriendName>{friend.nickname}</FriendName>
                                                <FriendCode>({friend.userCode})</FriendCode>
                                            </FriendNameWrapper>
                                        </FriendListRow>)
                                    }
                                </Fragment>)
                    }

                    <FixedPrimaryButton disabled={_.isEmpty(selectedFriend)}
                                        onClick={toNextStep}>
                        <FormattedMessage id="button_next" />
                    </FixedPrimaryButton>
                </FriendGroupWrapper>
            </TransferContentWrapper>
        </TransferWrapper>
    </>;
};

export default SelectFriend;
