import React from "react";
import { Route, Switch } from "react-router-dom";
import UserDeleteSubmit from "components/userDelete/UserDeleteSubmit";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import UserDeleteCheck from "components/userDelete/UserDeleteCheck";


const UserDeletePage = () => {
    return <Switch>
        <Route exact path={ROUTER_PATH_CONFIG.deleteUser.index}>
            <UserDeleteSubmit />
        </Route>
        <Route exact path={ROUTER_PATH_CONFIG.deleteUser.check}>
            <UserDeleteCheck />
        </Route>
    </Switch>;
};

export default UserDeletePage;
