import useFantasyHistory from "hooks/useFantasyHistory";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { useSelector } from "store";
import { PrimaryFixedButton } from "styles/component/ButtonsStyle";
import { WalletHistoryTableContainer } from "domain/wallet/components/walletHistoryRow/WalletHistoryRowStyle";
import useWalletHistoryRecords from "domain/wallet/hooks/useWalletHistoryRecords";
import CoinTransactionDetail from "domain/wallet/coin/CoinTransactionDetail";
import { WALLET_TYPE } from "data/dto/wallet/walletHistoryDto";
import { WalletHistoriesVo } from "data/vo/wallet/WalletHistoriesVo";
import { HistoryContent } from "domain/wallet/components/HistoryContent";
import { TotalAmountRow } from "domain/wallet/components/walletHistoryRow/components/TotalAmountRow";


interface DepositRecordsProps {
    histories: WalletHistoriesVo
}

const CoinRecords: React.FC<DepositRecordsProps> = ({ histories }) => {
    const history = histories.coin;
    const routeHistory = useFantasyHistory();
    const { featureConfig } = useSelector(state => state.FantasyGameConfigState);
    const {
        WalletState: { coinCent, },
        transactionDetailData,
        handleHistoryRecordRowClick,
        handleHideTransaction,
    } = useWalletHistoryRecords();

    const onAddDepositButtonClick = () => {
        routeHistory.push(ROUTER_PATH_CONFIG.deposit.select, {
            navbarBackGoTo: routeHistory.location?.state?.navbarBackGoTo,
            addDepositFlowFinishedGoTo: routeHistory.location.pathname
        });
    };

    return <>
        <WalletHistoryTableContainer>
            <TotalAmountRow totalCent={coinCent}
                            walletType={WALLET_TYPE.COIN}
            />

            <HistoryContent records={history} handleHistoryRecordRowClick={handleHistoryRecordRowClick} />

            {
                featureConfig.addCoin &&
                <PrimaryFixedButton onClick={onAddDepositButtonClick}>
                    <FormattedMessage id="label_add_deposit" />
                </PrimaryFixedButton>
            }

        </WalletHistoryTableContainer>
        <CoinTransactionDetail
            transactionData={transactionDetailData}
            onHide={handleHideTransaction}
        />
    </>;
};

export default CoinRecords;
