import { CouponCard, CouponCardBody, CouponCardFooter, CouponWrapper } from "./CouponStyle";
import EmptyCoupon from "./EmptyCoupon";
import { getToTextColor } from "../helper/CouponHelper";
import { orderBy } from "lodash";
import { EnrichedCoupon } from "../../../types/coupon/Coupon";
import React from "react";
import CouponCardBodyContent from "./CouponCardBodyContent";
import styled from "styled-components";

const EndDate = styled.span.attrs({})`
  font: inherit;
`;

const UnusedCoupon = ({ coupons }: { coupons: EnrichedCoupon[] }) => {

    return <CouponWrapper>
        {
            coupons.length > 0
                ? orderBy(coupons, "to").map(coupon => {

                    let toTextColor = getToTextColor(coupon);

                    return <CouponCard key={coupon.id} data-testid={`coupon-card-${coupon.id}`}>
                        <CouponCardBody>
                            <CouponCardBodyContent coupon={coupon} />
                        </CouponCardBody>
                        <CouponCardFooter className="text-text-secondary-1">
                            {coupon.startDate} ~ <EndDate className={toTextColor}>{coupon.endDate}</EndDate>
                        </CouponCardFooter>
                    </CouponCard>;
                })
                : <EmptyCoupon />
        }
    </CouponWrapper>;

};

export default UnusedCoupon;