import { combineReducers } from "redux";
import AlertState from "store/reducer/alert/AlertState";
import FantasyGameConfigState from "store/reducer/config/FantasyGameConfigState";
import CouponState from "store/reducer/coupon/CouponState";
import LiveChatState from "store/reducer/liveChat/LiveChatState";
import SelectTeamPanelState from "store/reducer/selectTeamPanel/selectTeamPanelState";
import SettingsState from "store/reducer/setting/SettingsState";
import SportState from "store/reducer/sport/SportState";
import TeamPreviewState from "store/reducer/teamPreview/teamPreviewState";
import UserState from "store/reducer/user/UserState";
import WalletState from "store/reducer/wallet/WalletState";

const reducers = combineReducers({
    WalletState,
    SettingsState,
    AlertState,
    UserState,
    CouponState,
    TeamPreviewState,
    SelectTeamPanelState,
    FantasyGameConfigState,
    LiveChatState,
    SportState
});


export type RootStoreState = ReturnType<typeof reducers>

export default reducers;
