import FantasyIcon from "helpers/FantasyIcon";
import { winningIcon } from "helpers/IconHelper";
import { Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { APP_MIN_WIDTH_VALUE } from "styles/Constants";

export const Title = styled.div.attrs(() => ({
    className: "d-flex justify-content-center align-items-center text-text-primary-1 fz-sf-bold-title-3 h-100"
}))``;

export const RewardListWrapper = styled.div.attrs({
    className: "w-100"
})`
    min-height: calc(100vh - 13.2rem);
    padding: 1.6rem;
`;
export const Text = styled.div.attrs({
    className: "fz-sf-regular-footnote text-secondary-2 text-left"
})`
    padding: 1.2rem 0;
`;
export const RewardItemRow = styled.div.attrs({
    className: "d-flex flex-wrap"
})`
    row-gap: 1.6rem;
`;
export const RewardItemCol = styled(Col).attrs({
    className: "col-6 col-sm-4"
})`
    padding: 0 .8rem;
`;

export const RewardListItem = styled(Link).attrs(() => ({
    className: "fantasy-card-border fantasy-card-bg overflow-hidden d-flex flex-column justify-content-between"
}))`
    pointer-events: ${({ pointerEvents }) => pointerEvents};

`;
export const RewardListItemBody = styled.div.attrs({
    className: "p-3"
})`

`;
export const RewardImageWrapper = styled.div.attrs({
    className: "w-100"
})`
    height: 14.4rem;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

export const PriceImageWrapper = styled.div.attrs({
    className: "me-2"
})`
    width: 2rem;
`;


export const RewardListItemFooter = styled.div.attrs({
    className: "py-2 fz-sf-bold-body-2 d-flex align-items-center justify-content-center"
})`
    background-color: #F5F5F9;
    gap: .4rem;
`;

export const RewardDescription = styled.div.attrs(() => ({
    className: "fz-sf-regular-footnote my-2 text-primary-2"
}))`
    height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
`;
export const OrderIcon = styled.div.attrs(() => ({
    className: "position-relative"
}))`
    width: 2.4rem;
    height: 2.4rem;
    cursor: pointer;
`;
export const NewOrderSignal = styled.div.attrs<{ $show: boolean }>({
    className: "position-absolute tw-bg-system-red"
})`
    top: -.3rem;
    right: .1rem;
    width: .6rem;
    height: .6rem;
    border-radius: 50%;
    display: ${({ $show }) => $show ? "block" : "none"};
`;
export const NoRewardWrapper = styled.div.attrs({
    className: "text-center"
})`
`;
export const NoRewardImage = styled(Image).attrs({})`
    width: calc(250 / ${APP_MIN_WIDTH_VALUE} * 100%);
    margin-top: 96px;
`;
export const NoRewardText = styled.div.attrs({
    className: "text-center fz-sf-regular-subhead"
})`
`;
export const RewardTabWrapper = styled.div.attrs(() => ({
    className: "d-flex align-items-center position-sticky"
}))`
    top: 64px;
    z-index: 3;
    padding: .8rem 0 0;
    gap: 1.6rem;
    overflow-x: scroll;
`;
const RewardTab = styled.div.attrs(() => ({
    className: "d-flex justify-content-center align-items-center text-nowrap"
}))`
    padding: .4rem 0 .8rem 0;
    cursor: pointer;
    user-select: none;
`;

export const ActiveRewardTab = styled(RewardTab).attrs(() => ({
    className: "fz-sf-bold-caption-1 nav-tabs-on-text nav-tabs-on-line position-relative"
}))`
    &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: .4rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }
`;

export const InactiveRewardTab = styled(RewardTab).attrs(() => ({
    className: "fz-sf-regular-caption-1 nav-tabs-off-text"
}))``;

export const RewardIconWrapper = styled.div`
    font: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .4rem;
`;

export const RewardIcon = styled(FantasyIcon).attrs<{ $active: boolean }>({
    size: "2.6rem"
})`
    ${({ $active }) => {
    if (!$active)
        return css`
                filter: grayscale(1);
                opacity: .5;
            `;
}}
}
`;

export const WinningWrapper = styled.div.attrs({
    className: "header-line"
})`
    padding-bottom: 1.6rem;
`;

export const WinningTitle = styled.div.attrs(() => ({
    className: "d-flex align-items-center text-text-secondary-1"
}))`
    gap: .4rem;
`;
export const WinningContent = styled.div.attrs(() => ({
    className: "d-flex align-items-center justify-content-between text-text-primary-1"
}))`
`;
export const WinningIcon = styled.span.attrs(() => ({
    className: "d-inline-block"
}))`
    width: 2rem;
    padding-bottom: 2rem;
    background-image: url(${winningIcon});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
`;
