import { TeamCard } from "components/card/TeamCardStyle";
import UpcomingMatchEmptyTeam from "components/empty/UpcomingMatchEmptyTeam";
import PageViewRecorder from "components/ga/PageViewRecorder";
import TeamCardContent from "components/team/TeamCardContent";
import { TeamName, TeamNameWrapper } from "components/team/TeamCardStyle";
import TeamPreview from "containers/teamPreview";
import { CreateTeamButton, MyTeamCardWrapper, MyTeamWrapper } from "domain/match/components/team/MyTeamStyle";
import usePlayerInfoDialog from "domain/team/playerInfoDialog/PlayerInfoDialog";
import { PlayerInfoDialogFooterType } from "domain/team/playerInfoDialog/PlayerInfoDialogFooter";
import { PlayerInfoDialogHeaderType } from "domain/team/playerInfoDialog/PlayerInfoDialogHeader";
import { analyticsEvent, logCustomEvent } from "ga";
import { isMatchPreparing } from "helpers/match/MatchHelper";
import { useMatchPreparingToast } from "hooks/useToast";
import React from "react";
import { MdOutlineContentCopy, MdOutlineEdit } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { BOTTOM_SLOT_TYPE, SQUAD_RIGHT_SLOT_TYPE, TOP_LEFT_SLOT_TYPE } from "store/reducer/teamPreview/types";
import { MatchVO } from "types/match/Match";
import { MyTeamVO } from "types/team/MyTeam";


interface UpcomingMatchMyTeamsProps {
    match: MatchVO;
    teams: MyTeamVO[];
    toCreateTeam: (team?) => void;
    toEditTeam: (team) => void;
}

const UpcomingMatchMyTeams = ({ match, teams, toCreateTeam, toEditTeam }: UpcomingMatchMyTeamsProps) => {
    const { sport } = useParams();
    const handleMatchPreparingToast = useMatchPreparingToast();

    const {
        PlayerInfoDialog,
        handlePlayerInfoDialogOpen
    } = usePlayerInfoDialog(PlayerInfoDialogHeaderType.POINT, PlayerInfoDialogFooterType.ONLY_CLOSE);

    const teamPreview = TeamPreview.useTeamPreview({
        sport,
        onAvatarClick: handlePlayerInfoDialogOpen,
        onEditTeam: toEditTeam,
        onCreateTeam: toCreateTeam,
        topLeftSlotType: TOP_LEFT_SLOT_TYPE.OPERATION,
        squadRightSlotType: SQUAD_RIGHT_SLOT_TYPE.SUBSTITUTION,
        bottomSlotType: BOTTOM_SLOT_TYPE.TEAMS
    });

    const handlePreviewTeam = (team) => {
        teamPreview.updateCurrentTeam(team);
        teamPreview.open();
    };

    const onEdit = (team) => (e) => {
        logCustomEvent(analyticsEvent.webClickEdit);
        e.stopPropagation();
        toEditTeam(team);
    };

    const onCopy = (team) => (e) => {
        logCustomEvent(analyticsEvent.webClickCopy);
        e.stopPropagation();
        toCreateTeam(team);
    };

    return <>
        <PageViewRecorder title="Match Detail - My Teams" />
        <MyTeamWrapper data-testid="my-team">
            {
                teams.length > 0
                    ? teams.map(team => {

                        return <MyTeamCardWrapper key={team.id}>
                            <TeamCard className="clickable" onClick={() => handlePreviewTeam(team)}>
                                <TeamCardContent team={team} matchStatus={match.matchStatus}>
                                    <TeamNameWrapper className="justify-content-between">
                                        <TeamName>{team.name}</TeamName>
                                        <div className={"d-flex align-items-center gap-3"}>
                                            <MdOutlineEdit data-testid="edit-team-button"
                                                           size="2rem"
                                                           onClick={onEdit(team)}
                                            />
                                            <MdOutlineContentCopy size="2rem"
                                                                  onClick={onCopy(team)} />
                                        </div>
                                    </TeamNameWrapper>
                                </TeamCardContent>
                            </TeamCard>
                        </MyTeamCardWrapper>;
                    })
                    : <UpcomingMatchEmptyTeam />
            }
            {
                isMatchPreparing(match.matchStatus)
                    ? <CreateTeamButton className="disabled" onClick={handleMatchPreparingToast}>
                        <FormattedMessage id="label_create_team" />
                    </CreateTeamButton>
                    : <CreateTeamButton onClick={() => toCreateTeam()}>
                        <FormattedMessage id="label_create_team" />
                    </CreateTeamButton>
            }
        </MyTeamWrapper>

        <PlayerInfoDialog players={teamPreview.players}
                          myTeams={teams}
                          match={match} />
    </>;
};


export default UpcomingMatchMyTeams;
