import React from "react";
import { ListItem, ListTitle, ListValue } from "../components/TransactionDetailStyle";
import { TransactionDetailVo } from "data/vo/wallet/TransactionDetailVo";
import { FormattedMessage } from "react-intl";
import { StatusLabel } from "domain/wallet/transactionDetail/StatusLabel";

interface WithdrawDetailsProps {
    transactionDetailData: TransactionDetailVo
}

const WithdrawDetail: React.FC<WithdrawDetailsProps> = ({
                                                            transactionDetailData
                                                        }) => {
    const {
        withdrawalNetAmount,
        withdrawalHandlingFee,
        receivingInstitutionName,
        displayReceiverAccountNumber,
        isCancelled
    } = transactionDetailData;

    return <>
        {
            !isCancelled && <>
                <ListItem className="tw-bg-[#000000]/5">
                    <ListTitle><FormattedMessage id="label_transaction_fee" /></ListTitle>
                    <ListValue>
                        {withdrawalHandlingFee}
                    </ListValue>
                </ListItem>
                <ListItem className="tw-bg-[#000000]/5">
                    <ListTitle><FormattedMessage id="label_receive_amount" /></ListTitle>
                    <ListValue>
                        {withdrawalNetAmount}
                    </ListValue>
                </ListItem>
                <ListItem>
                    <ListTitle><FormattedMessage id="label_status" /></ListTitle>
                    <ListValue>
                        <StatusLabel transactionDetailData={transactionDetailData} />
                    </ListValue>
                </ListItem>
            </>
        }

        <ListItem>
            <ListTitle><FormattedMessage id="label_to" /></ListTitle>
            <ListValue>
                <div>
                    {receivingInstitutionName},
                </div>
                <div>
                    <FormattedMessage id="label_account_no" /> {displayReceiverAccountNumber}
                </div>
            </ListValue>
        </ListItem>
    </>;
};

export default WithdrawDetail;
