import { collectMessageByUser, isMyMessage } from "domain/chat/ChatRoomHelper";
import React from "react";
import useFantasyConfig from "hooks/useFantasyConfig";
import useFantasyHistory from "hooks/useFantasyHistory";
import { MyMessages } from "domain/chat/components/MyMessages";
import { OthersMessages } from "domain/chat/components/OthersMessages";

export const Messages = ({ messages, myChatId }) => {
    const { gameUrl } = useFantasyConfig();
    const history = useFantasyHistory();

    const handleClickMessage = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        const link = (event.target as HTMLElement).closest("a")?.getAttribute("href");

        if (!link) {
            return;
        }

        if (isInGameLink(gameUrl, link)) {
            const url = new URL(link);
            const path = url.pathname;
            history.push(path);
            return;
        }

        window.open(link, "_blank");
    };

    return <>
        {
            collectMessageByUser(messages).map(message => {
                return isMyMessage(message, myChatId)
                    ? <MyMessages message={message} handleClickMessage={handleClickMessage} />
                    : <OthersMessages message={message} handleClickMessage={handleClickMessage} />;
            })
        }
    </>;
};

const isInGameLink = (gameUrl: string, message: string) => {
    return message.includes(gameUrl);
};
