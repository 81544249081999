import FantasyScrollingPopup, {
    ScrollingPopupBody,
    ScrollingPopupFooter,
    ScrollingPopupHeader,
    ScrollingPopupPrimaryButton
} from "components/popup/FantasyScrollingPopup";
import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { Icon } from "helpers/IconHelper";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

interface SlateLeavePopupProps {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    onLeaveButtonClick: () => void;
}

const NineCatLeavePopup: React.FC<SlateLeavePopupProps> = ({ open, onOpenChange, onLeaveButtonClick }) => {
    const intl = useIntl();
    const handleClose = () => {
        onOpenChange(false);
    };

    return (
        <FantasyScrollingPopup
            open={open}
            onOpenChange={onOpenChange}
            testId="slate-leave-popup"
            title={
                <ScrollingPopupHeader
                    title={intl.formatMessage({ id: "nine_cat_leaving_create_team_popup_title" })}
                    onClose={handleClose}
                >
                    <Icon.Subtract className="tw-mx-auto" />
                </ScrollingPopupHeader>
            }
            body={
                <ScrollingPopupBody>
                    <FormattedMessage id="nine_cat_leaving_create_team_popup_description" />
                </ScrollingPopupBody>
            }
            footer={
                <ScrollingPopupFooter>
                    <ScrollingPopupPrimaryButton
                        text={intl.formatMessage({ id: "nine_cat_leaving_create_team_popup_button_continue" })}
                        onClick={handleClose} />

                    <Button
                        variant={NEW_BUTTON_VARIANTS.errorGrey}
                        pattern={BUTTON_PATTERN.rounded}
                        size={BUTTON_SIZE.lg}
                        className="w-100 tw-mt-3"
                        onClick={onLeaveButtonClick}
                    >
                        <FormattedMessage id="nine_cat_leaving_create_team_popup_button_leave" />
                    </Button>
                </ScrollingPopupFooter>
            }
        />
    );
};

export default NineCatLeavePopup;
