export const enum SUPPLIER {
    SPORTS_DATA_IO = "sportsdataio",
    ROTO_BALLER = "rotoballer",
    GENIUS_SPORTS = "genius_sports",
}

export const enum SUPPLIER_LOGO_TYPE {
    NORMAL = "normal",
    DIVIDER = "divider",
}

