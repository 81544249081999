import { TeamPlayerCrownIcon } from "components/leaderboard/LeaderboardBodyStyle";
import { WonPrizeLayout } from "components/leaderboard/WonPrizeLayout";
import { JoinedTeamRow, RankCol, TeamCol, } from "components/matchCards/MatchCardStyle";
import Badge, { BADGE_PATTERN, BADGE_SIZE, BADGE_VARIANTS, } from "designToken/badge/Badge";
import { crown } from "helpers/IconHelper";
import { isMatchCompleted } from "helpers/match/MatchHelper";
import { contestTypeComparator } from "helpers/SortingHelper";
import { orderBy } from "lodash";
import React, { ReactElement } from "react";
import { ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";
import { MATCH_STATUS_FROM_API } from "types/match/Match";
import { MyMatchRankedTeam } from "types/match/MyMatch";

type MyJoinedTeamsProps = {
    match: {
        rankedTeams: MyMatchRankedTeam[];
        matchStatus: MATCH_STATUS_FROM_API;
    };
};

export const TeamRankContent = ({ rank, size = 3.2 }): null | ReactElement => {
    if (!rank) {
        return null;
    }

    if (rank === 1) {
        return <TeamPlayerCrownIcon $bgImg={crown} $size={size} />;
    }

    return <>#{rank}</>;
};

const MyJoinedTeams = ({ match }: MyJoinedTeamsProps) => {
    return (
        <>
            {orderBy(match.rankedTeams, [contestTypeComparator]).map(
                (rankedTeam, i) => {
                    const { rank, teamName, wonPrizeCent, teamId, entryFeeType } =
                        rankedTeam;

                    return (
                        <JoinedTeamRow key={`${teamId}-${i}`}>
                            <TeamCol>
                                <Badge
                                    className="w-fit-content"
                                    size={BADGE_SIZE.SM}
                                    variant={BADGE_VARIANTS.OUTLINE_SECONDARY}
                                    pattern={BADGE_PATTERN.ROUNDED}
                                >
                                    {teamName}
                                </Badge>
                                {isMatchCompleted(match.matchStatus) && (
                                    <WonPrizeLayout
                                        wonPrizeCent={wonPrizeCent}
                                        isRubyContest={
                                            entryFeeType === ENTRY_FEE_TYPE_FROM_API.RUBY
                                        }
                                    />
                                )}
                            </TeamCol>
                            <RankCol>
                                <TeamRankContent rank={rank} size={2} />
                            </RankCol>
                        </JoinedTeamRow>
                    );
                },
            )}
        </>
    );
};

export default MyJoinedTeams;
