import React from "react";
import { cn } from "../../helpers/cn";
import { Icon } from "../../helpers/IconHelper";

enum BuyCoinStepperType {
    ApOverviewStepper,
    MeStepper
}

interface BuyCoinStepperProps {
    type: BuyCoinStepperType;
}

const BuyCoinStepper: React.FC<BuyCoinStepperProps> = ({ type }) => {
    return <div className="tw-flex tw-items-center">
        <div className="tw-rounded-full tw-bg-tertiary-700 tw-w-[0.8rem] tw-h-[0.8rem]"></div>
        <div
            className={cn("tw-grow", "tw-bg-gradient-to-r", "tw-from-tertiary-700", "tw-to-tertiary-500",
                {
                    "tw-h-[0.4rem]": type === BuyCoinStepperType.ApOverviewStepper,
                    "tw-h-[0.2rem]": type === BuyCoinStepperType.MeStepper,
                })}>
        </div>
        <div
            className={cn("tw-rounded-full", "tw-bg-tertiary-500", "tw-flex", "tw-justify-center", "tw-items-center", {
                "tw-w-[1.6rem]": type === BuyCoinStepperType.ApOverviewStepper,
                "tw-h-[1.6rem]": type === BuyCoinStepperType.ApOverviewStepper,
                "tw-w-[1.4rem]": type === BuyCoinStepperType.MeStepper,
                "tw-h-[1.4rem]": type === BuyCoinStepperType.MeStepper,
            })}>
            <div
                className="tw-rounded-full tw-bg-white tw-w-[1.2rem] tw-h-[1.2rem] tw-flex tw-justify-center tw-items-center">
                <Icon.HandWithCoin className="tw-text-gray-500"></Icon.HandWithCoin>
            </div>
        </div>
    </div>;
};

export { BuyCoinStepperType, BuyCoinStepper };
