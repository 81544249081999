import styled, { css } from "styled-components";

export const AdCarouselWrapper = styled.div.attrs({
    className: "position-relative"
})`
    padding-top: 1.8rem;
`;

const AdContentWrapper = css`
    width: 100%;
    aspect-ratio: 3/1;
`;

export const GeneralAdWrapper = styled.div.attrs({
    className: "base-shadow-light-60"
})`
    ${AdContentWrapper};
    cursor: pointer;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
    }
`;
