import styled from "styled-components";

export const TransferButtonStyle = styled.button.attrs({
    className: "border-0 bg-transparent p-0",
    "data-testid": "transfer-button"
})`
  width: 2.8rem;
  height: 2.8rem;

  &.disabled {
    cursor: auto;
  }
`;
export const TransferButtonWrapper = styled.div.attrs({
    className: "align-self-center",

})`
  margin-left: 1.5rem;
`;
