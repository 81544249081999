import MdCloseButton from "components/buttons/MdCloseButton";
import {
    ContestCodeInput,
    ContestCodePanelBody,
    ContestCodePanelHeader,
    ContestCodePanelTitle,
    ErrorIcon,
    ErrorLabel
} from "components/panel/contestCode/ContestCodePanelStyle";
import Panel from "components/panel/Panel";
import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";
import { createErrorObject, isDataNotFound } from "helpers/ErrorHelper";
import { isContestInviteCodeValid } from "helpers/RegexHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { getContestDetailPageUrl } from "router/RouterUrls";
import { ContestRepository } from "../../../data/repository/contest/ContestRepository";
import useFantasyQuery from "../../../hooks/useFantasyQuery";
import { QUERY_KEY } from "../../../hooks/useFantasyQuery/type";
import { ERROR_HANDLER_FLOW } from "../../../hooks/useHandleApiError";


interface ContestCodePanelProps {
    isPanelOpened: boolean;
    hide: () => void;
}

const PanelBody = forwardRef((_, ref: React.ForwardedRef<HTMLInputElement>) => {
    const history = useFantasyHistory();
    const intl = useIntl();
    const [inviteCode, setInviteCode] = useState<string>("");
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const contestRepository = new ContestRepository();

    const contestDetail = useFantasyQuery(
        [QUERY_KEY.CONTEST_DETAIL, inviteCode],
        async () => contestRepository.getContestDetail(inviteCode),
        {
            options: {
                enabled: isSubmit
            },
            onFail: (error) => {
                setInviteCode("");
                setIsSubmit(false);

                if (isDataNotFound(error)) {
                    setErrorMessage(intl.formatMessage({ id: "home_page_invite_code_not_found" }));
                } else {
                    history.push(ROUTER_PATH_CONFIG.error, {
                        error: createErrorObject(error)
                    });
                }

                return ERROR_HANDLER_FLOW.STOP;
            }
        }
    );


    useEffect(() => {
        if (contestDetail.isSuccess && isSubmit) {
            contestDetail.remove();
            setIsSubmit(false);
            history.push(getContestDetailPageUrl(inviteCode));
        }
    }, [contestDetail.isSuccess, isSubmit]);


    const handleConfirmContestCode = () => {
        if (!isContestInviteCodeValid(inviteCode)) {
            setErrorMessage(intl.formatMessage({ id: "home_page_invite_code_invalid" }));
            return;
        }
        setIsSubmit(true);
    };

    const onChange = (e) => {
        setErrorMessage("");
        setInviteCode(e.target.value);
    };


    return <ContestCodePanelBody className={errorMessage ? "has-error" : ""}
                                 onSubmit={(e) => e.preventDefault()}>
        <div>
            <div className="position-relative">
                <ContestCodeInput type="text"
                                  value={inviteCode}
                                  placeholder={intl.formatMessage({ id: "home_page_invite_code_input_label_hint" })}
                                  onChange={onChange}
                                  ref={ref}
                                  data-testid="contest-code-input"
                />
                <ErrorIcon />
            </div>
            <ErrorLabel data-testid="error-label">{errorMessage}</ErrorLabel>
        </div>
        <div className="mt-4">
            <Button className="w-100"
                    variant={BUTTON_VARIANTS.primary}
                    pattern={BUTTON_PATTERN.pill}
                    size={BUTTON_SIZE.lg}
                    disabled={inviteCode.length <= 0}
                    dataTestId="contest-code-submit-button"
                    onClick={handleConfirmContestCode}
            >
                <FormattedMessage id="button_confirm" />
            </Button>
        </div>
    </ContestCodePanelBody>;
});

const PanelHeader = ({ hide: onHide }) => {
    return <ContestCodePanelHeader>
        <ContestCodePanelTitle><FormattedMessage id="label_contest_code" /></ContestCodePanelTitle>
        <MdCloseButton onClose={onHide} />
    </ContestCodePanelHeader>;
};

const ContestCodePanel: React.FC<ContestCodePanelProps> = ({ isPanelOpened, hide }) => {
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <Panel show={isPanelOpened}
               onHide={hide}
               onEntered={() => {
                   inputRef.current?.focus();
               }}
               Header={<PanelHeader hide={hide} />}
               Body={<PanelBody ref={inputRef} />}
        />);
};

export default ContestCodePanel;
