import React from "react";
import { Placeholder } from "react-bootstrap";
import { CirclePlaceHolder, TextPlaceHolder } from "../../../components/skeleton/SkeletonStyle";

const PlayerPopupProfileSkeleton = () => {
    return (
        <Placeholder animation="glow">
            <div className="tw-flex tw-flex-col tw-gap-1.5 tw-items-center">
                <CirclePlaceHolder $size={7.2} className="!tw-bg-grey-150 " />
                <TextPlaceHolder $width={14} $radius={1.6} className="tw-my-1 !tw-bg-grey-150" />
                <div className="tw-flex tw-gap-1">
                    <TextPlaceHolder $width={8} $radius={1.6} className="!tw-bg-grey-150" />
                    <CirclePlaceHolder size={1.6} className="!tw-bg-grey-150" />
                </div>
            </div>
            <div
                className="tw-absolute -tw-bottom-11 tw-m-auto tw-left-0 tw-right-0 tw-bg-grey-100 tw-rounded-2xl tw-px-4 tw-py-2 tw-w-[90%] tw-text-body-2 tw-text-grey-1000 tw-text-center">
                <div className="tw-flex tw-flex-col tw-items-center tw-gap-2.5">
                    <TextPlaceHolder $height={1.2} $radius={1.6} className="!tw-bg-grey-150 !tw-w-1/2" />
                    <TextPlaceHolder $height={1.2} $radius={1.6} className="!tw-bg-grey-150 !tw-w-1/2" />
                </div>
            </div>
        </Placeholder>
    );
};

export default PlayerPopupProfileSkeleton;