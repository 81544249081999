import { cn } from "helpers/cn";
import { homeGradientBg } from "helpers/IconHelper";
import { Container } from "react-bootstrap";
import styled, { css } from "styled-components";
import { APP_MAX_WIDTH } from "styles/Constants";

export const bgGradientStyle = css`
    background-image: url(${homeGradientBg});
    background-position: -2rem -18rem;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100%;
`;


export const HomeWrapper = styled(Container).attrs(() => ({
    className: "position-relative w-100 px-0 home-wrapper bg-body-body-1"
}))`
    min-height: 100%;
    ${bgGradientStyle};
`;

export const HomeNavBarContainer = styled.div<{
    $height?: number
}>`
    height: ${({ $height }) => `${$height}px`}
`;
export const HomeNavbarWrapper = styled.div.attrs({
    className: "d-flex flex-column justify-content-between position-fixed header-line"
})`
    width: ${APP_MAX_WIDTH};
    max-width: 100%;
    top: 0;
    z-index: 10;
`;

export const FilterWrapper = styled.div.attrs({
    className: "h-100 d-flex justify-content-end align-items-center position-relative clickable"
})`
    flex: 0 0 3rem;
`;
export const FilterDot = styled.div.attrs({
    className: "position-absolute bg-sys-danger rounded-circle end-0 top-0"
})`
    width: .6rem;
    height: .6rem;
`;

export const SportsMenuWrapper = styled.div.attrs({
    className: "d-flex flex-fill"
})`
`;

interface SportTabProps {
    $isSelected: boolean;
}

export const SportItem = styled.div.attrs<SportTabProps>(({ $isSelected }) => ({
    className: cn(
        "d-flex align-items-center position-relative",
        { "nav-tabs-on-line": $isSelected }
    )
}))`
    padding: .6rem .2rem 1rem;

    &:not(:last-child) {
        margin-right: 1.6rem;
    }

    ${({ $isSelected }) => $isSelected ? css`
        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: .4rem;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
        }
    ` : ""}

`;

export const SportIcon = styled.div<{
    $icon: string
}>`
    margin-right: .8rem;
    width: 2.4rem;
    padding-bottom: 2.4rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    background-image: url(${({ $icon }) => $icon});
`;

interface SportNameProps {
    $isSelected: boolean;
}

export const SportName = styled.div.attrs<SportNameProps>(({ $isSelected }) => ({
    className: cn(
        "fz-sf-bold-body-2 tw-flex tw-items-center tw-justify-center tw-gap-2",
        $isSelected ? "nav-tabs-on-text" : "nav-tabs-off-text"
    )
}))`
`;
