import { TournamentFilter } from "components/tournament/TournamentFilter";
import { TournamentFilterButtons } from "components/tournament/TournamentFilterButtons";
import { GenerateDisplayTextProps } from "components/tournament/types";
import {
    CloseButton,
    ModalContainer,
    ModalTitle,
    ModalTitleWrapper
} from "domain/home/components/tournamentFilterDialog/TournamentFilterDialogStyle";

import { getCheckboxState, Tournament } from "helpers/TournamentHelper";
import React, { useEffect, useMemo, useState } from "react";
import { IoClose } from "react-icons/io5";
import { FormattedMessage } from "react-intl";
import Dialog, { DIALOG_SIZE } from "../../../designToken/dialog/Dialog";

interface TournamentFilterDialogProps {
    tournaments: Tournament[];
    show: boolean;
    onHide: () => void;
    onFilter: (filter: any) => void;
}

const ResultTournamentFilterDialog: React.FC<TournamentFilterDialogProps> = ({
                                                                                 tournaments,
                                                                                 show,
                                                                                 onHide,
                                                                                 onFilter
                                                                             }) => {
    const [filteredTournaments, setFilteredTournaments] = useState<Tournament[]>([]);

    const onClose = () => {
        onHide();
    };

    const onApply = () => {
        onFilter({
            skippedTournamentIds: filteredTournaments,
        });
    };

    const selectAllState = useMemo(() => {
        const checkedCount = filteredTournaments.filter(item => item.checked).length;
        const itemsCount = tournaments.length;

        return getCheckboxState(checkedCount, itemsCount);
    }, [filteredTournaments, tournaments]);

    const generateDisplayText = ({ name, completedMatches }: GenerateDisplayTextProps) => {
        return `${name} (${completedMatches})`;
    };

    useEffect(() => {
        setFilteredTournaments(tournaments);
    }, [tournaments]);


    return (
        <Dialog show={show} size={DIALOG_SIZE.LG} onHide={onClose}>
            <ModalContainer>
                <ModalTitleWrapper className="mb-3">
                    <ModalTitle><FormattedMessage id="tournament_filter_dialog_title" /></ModalTitle>
                    <CloseButton onClick={onClose}><IoClose /></CloseButton>
                </ModalTitleWrapper>
                <TournamentFilter
                    selectAllState={selectAllState}
                    tournaments={filteredTournaments}
                    setFilteredTournaments={setFilteredTournaments}
                    generateDisplayText={generateDisplayText}
                />
                <TournamentFilterButtons onClose={onClose} onApply={onApply} />
            </ModalContainer>
        </Dialog>
    );
};

export default ResultTournamentFilterDialog;


