import clsx from "clsx";
import React from "react";
import { IconBaseProps } from "react-icons";
import { MdClose } from "react-icons/md";

export interface MdiCloseButtonProps extends IconBaseProps {
    customClassname?: string;
    onClose: () => void | Promise<void>;
}

const MdCloseButton: React.FC<MdiCloseButtonProps> = ({
                                                          customClassname,
                                                          onClose,
                                                          ...props
                                                      }) => {
    return (
        <MdClose size="2.4rem" className={clsx("clickable", customClassname)} onClick={onClose} {...props} />
    );
};

export default MdCloseButton;
