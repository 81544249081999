import { Callback } from "Common";
import { useNineCatContext } from "domain/slate/providers/NineCatProvider";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import { useCompleteYourKycDialog } from "hooks/useDialog";
import _ from "lodash";
import { useDispatch } from "react-redux";

import { addErrorPopup } from "store/actions/AlertActions";
import { getSelectedTeamsForConfirmation } from "../../../components/panel/SelectTeamPanelHelper";
import { NineCatRepository } from "../../../data/repository/nineCat/NineCatRepository";
import {
    createErrorObject,
    isContestFull,
    isForbiddenByKYCRestriction,
    isTeamsDuplicated
} from "../../../helpers/ErrorHelper";
import useCurrency from "../../../hooks/useCurrency/useCurrency";
import useFantasyCommand from "../../../hooks/useFantasyCommand";
import useFantasyHistory from "../../../hooks/useFantasyHistory";
import { ERROR_HANDLER_FLOW } from "../../../hooks/useHandleApiError";
import { ROUTER_PATH_CONFIG } from "../../../router/RouterPathConfig";
import { OnJoinRubyContestWithClose } from "../../../store/reducer/selectTeamPanel/type";
import { SelectedTeam } from "../../../types/team/SelectedTeam";
import { ConfirmationDialogSubmitParams } from "../../contest/components/ConfirmationDialog/useConfirmationDialog";
import {
    JoinNineCatContestSuccessfullyDialogProps
} from "../../contest/components/JoinNineCatContestSuccessfullyDialog";
import { NineCatContestVo } from "../data/ComponentTypes";
import SlateRepository, { JoinContestRequest, JoinRubyContestRequest } from "../repository/SlateRepository";
import { useJoinNineCatContestDialog } from "./useJoinNineCatContestDialog";

const slateRepository = new SlateRepository();
type GetJoinSuccessfullyDialogPropsPrams =
    Partial<Pick<ConfirmationDialogSubmitParams, 'currentWinningCent' | 'balanceWinningCent'>>
    & {
    contest: Pick<ConfirmationDialogSubmitParams['contest'], 'entryFeeCent' | 'entryFeeType'>
    slateDetailTitle: string,
}

function getContestCurrentJoinedTeams(myJoinedTeamIds: number[], selectedTeamIds: number[], myTeams: SelectedTeam[]) {
    const intersectedJoinedTeamIds = _.intersection(myJoinedTeamIds, selectedTeamIds);
    return myTeams.filter(team => intersectedJoinedTeamIds.includes(team.id));
}

export function useSubmitJoinContest({
                                         contest,
                                         selectedTeamIds,
                                         onResetAfterJoined,
                                     }: {
    contest: NineCatContestVo | null | undefined,
    selectedTeamIds: number[],
    onResetAfterJoined: Callback,
}) {
    const {
        handleOpenTeamsAlreadyJoinedDialog,
        handleOpenSpotsFilledDialog,
        handleOpenJoinSuccessfullyDialog,
        handleCloseDialog,
    } = useJoinNineCatContestDialog();
    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();

    const handleCompleteYourKycDialog = useCompleteYourKycDialog();

    const dispatch = useDispatch();
    const history = useFantasyHistory();

    function getJoinSuccessfullyDialogProps({
                                                slateDetailTitle,
                                                contest,
                                                currentWinningCent,
                                                balanceWinningCent,
                                            }: GetJoinSuccessfullyDialogPropsPrams): JoinNineCatContestSuccessfullyDialogProps {
        return {
            slateDetailTitle,
            teamNames: getSelectedTeamsForConfirmation(selectedTeamIds, myTeams).map(team => team.name),
            entryFee: divideByOneHundred(contest.entryFeeCent),
            entryFeeType: contest.entryFeeType,
            onBackToHome: () => history.replace(ROUTER_PATH_CONFIG.home),
            onClose: handleCloseDialog,
            winningTransferResult: (currentWinningCent && balanceWinningCent) ? {
                currentWinning: toFormattedNumber(divideByOneHundred(currentWinningCent)),
                balanceWinning: toFormattedNumber(divideByOneHundred(balanceWinningCent))
            } : undefined
        };
    }

    const { slateDetail, myTeams } = useNineCatContext();

    const joinContest = useFantasyCommand<JoinContestRequest>(slateRepository.joinContest, {
        onFail: () => ERROR_HANDLER_FLOW.STOP
    });

    const joinRubyContest = useFantasyCommand<JoinRubyContestRequest>(slateRepository.joinRubyContest, {
        onFail: () => ERROR_HANDLER_FLOW.STOP
    });

    async function handleOpenJoinErrorDialog(error) {
        if (!contest) return;

        if (isForbiddenByKYCRestriction(error)) {
            handleCompleteYourKycDialog();
            return;
        }

        if (isContestFull(error)) {
            handleOpenSpotsFilledDialog();
            return;
        }

        if (isTeamsDuplicated(error)) {
            const { myJoinedTeams } = await new NineCatRepository().getNineCatContestDetail(contest.code);
            const joinedTeams = getContestCurrentJoinedTeams(myJoinedTeams, selectedTeamIds, myTeams);
            handleOpenTeamsAlreadyJoinedDialog(joinedTeams.map(team => team.name));
            return;
        }

        dispatch(addErrorPopup(createErrorObject(error)));
    }

    const handleSubmitJoin = async (params: ConfirmationDialogSubmitParams) => {
        if (joinContest.isLoading) return;
        if (!params.contest) return;

        try {
            await joinContest.mutateAsync({
                contestId: params.contestId,
                selectedTeamIds: params.selectedTeamIds,
                useRuby: params.useRuby,
                couponIds: params.couponIds,
            });
            handleOpenJoinSuccessfullyDialog(
                getJoinSuccessfullyDialogProps({
                    ...params,
                    slateDetailTitle: slateDetail?.title || '',
                })
            );
        } catch (error) {
            handleOpenJoinErrorDialog(error);
        } finally {
            onResetAfterJoined();
        }
    };

    const handleSubmitJoinRubyContest: OnJoinRubyContestWithClose = async (params) => {
        if (!contest) return;
        if (joinRubyContest.isLoading) return;

        try {
            await joinRubyContest.mutateAsync({
                contestId: params.contestId,
                selectedTeamIds: params.selectedTeamIds,
            });

            handleOpenJoinSuccessfullyDialog(
                getJoinSuccessfullyDialogProps({
                    contest: contest!,
                    slateDetailTitle: slateDetail?.title || '',
                })
            );
        } catch (error) {
            handleOpenJoinErrorDialog(error);
        } finally {
            onResetAfterJoined();
        }
    };

    return { handleSubmitJoin, handleSubmitJoinRubyContest };
}
