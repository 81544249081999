import { AwaitBanner } from "domain/me/components/KycBanner/AwaitBanner";
import { CompletedBanner } from "domain/me/components/KycBanner/CompletedBanner";
import { UnverifiedBanner } from "domain/me/components/KycBanner/UnverifiedBanner";
import useFantasyHistory from "hooks/useFantasyHistory";
import useMyProfile from "hooks/useMyProfile";
import React from "react";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { useSelector } from "store";
import { isAwait, isVerified } from "types/kyc/KycStatus";

export const KycVerifyStatusNotificationBanner: React.FC = () => {
        const history = useFantasyHistory();
        const {
            FantasyGameConfigState: { featureConfig },
        } = useSelector(store => store);

        const { myProfile: { kycStatus } } = useMyProfile();

        const handleToKycForm = () => history.push(ROUTER_PATH_CONFIG.verifyKyc.form);

        if (!featureConfig.kyc) {
            return null;
        }

        // todo: more KycVerifyStatusNotificationBanner for 1.2~1.4
        if (isVerified(kycStatus)) {
            return <CompletedBanner />;
        }

        if (isAwait(kycStatus)) {
            return <AwaitBanner />;
        }

        return <UnverifiedBanner onClick={handleToKycForm} />;
    }
;
