import { CheckboxState } from "components/checkbox/types";
import { UpcomingMatchVo } from "data/vo/match/UpcomingMatchVo";
import { isMatchPreparing, isMatchProcessingOrCompleted, isMatchUpcoming } from "helpers/match/MatchHelper";
import { filter, flow, map, sortBy, uniqBy } from "lodash/fp";
import { ResultMatchVO } from "types/match/Match";

export interface Tournament {
    id: string;
    name: string;
    checked: boolean;
    scheduledMatches: number;
    preparingMatches: number;
    completedMatches: number;
}

export const getCheckboxState = (checkedCount: number, itemsCount: number) => {
    if (checkedCount === itemsCount) {
        return CheckboxState.CHECKED;
    }

    return CheckboxState.UNCHECKED;
};


export const matchesToTournaments = (matches: (UpcomingMatchVo | ResultMatchVO)[], skippedTournaments: string[]): Tournament[] => {

    skippedTournaments = !skippedTournaments || skippedTournaments.length === 0 ? [] : skippedTournaments;

    const matchesCount = matches.reduce((result, match) => {

        if (!result[match.tournamentId]) {
            result[match.tournamentId] = {
                preparing: 0,
                scheduled: 0,
                completed: 0
            };
        }

        if (isMatchPreparing(match.matchStatus)) {
            result[match.tournamentId].preparing++;
        }
        if (isMatchUpcoming(match.matchStatus)) {
            result[match.tournamentId].scheduled++;
        }

        if (isMatchProcessingOrCompleted(match.matchStatus)) {
            result[match.tournamentId].completed++;
        }

        return result;
    }, {});

    return flow([
        map((match: UpcomingMatchVo) => ({
            id: match.tournamentId,
            name: match.tournament,
            checked: !skippedTournaments.includes(match.tournamentId),
            scheduledMatches: matchesCount[match.tournamentId].scheduled,
            preparingMatches: matchesCount[match.tournamentId].preparing,
            completedMatches: matchesCount[match.tournamentId].completed
        })),
        uniqBy((option: Tournament) => option.id),
        sortBy([
            (option: Tournament) => (option.scheduledMatches) * -1,
            (option: Tournament) => option.name
        ])
    ])(matches);
};

export const getSkippedTournaments = flow([
    filter((tournament: Tournament) => !tournament.checked),
    map((tournament: Tournament) => tournament.id)
]);

export function checkAllTournamentNotSelected(tournaments: Tournament[]) {
    return tournaments.length > 0 && tournaments.every(tournament => !tournament.checked);
}
