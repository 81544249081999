import TransferAmount from "domain/friend/transfer/amount/TransferAmount";
import useFantasyHistory from "hooks/useFantasyHistory";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { ApiExecuteContext } from "../../../apis/ApiContext";
import { fetchMyTransferred } from "../../../apis/user/UserApi";
import Navbar from "../../../components/container/navbar/Navbar";
import { SYSTEM_COLORS } from "../../../styles/Constants";
import ConfirmTransfer from "./confirm/ConfirmTransfer";
import SelectFriend from "./select/SelectFriend";
import { TransferNavbarCenter } from "./TransferStyle";


export const TransferNavbar = ({ text, handleBack = null, endIcon }) => {
    return <Navbar navBackIconColor={SYSTEM_COLORS.primary}
                   navbarBackgroundImageColor={null}
                   onNavbarBackClick={handleBack}
                   endIcon={endIcon}
                   navbarBackgroundColor={SYSTEM_COLORS.bgLightSecondary}
                   navbarCenter={<TransferNavbarCenter>{text}</TransferNavbarCenter>} />;
};

const steps = {
    select: "select",
    amount: "amount",
    confirm: "confirm"
};

const TransferFlow = () => {
    const history = useFantasyHistory();
    const { state: transferStateFromLocation } = useLocation();
    const apiExecutor = useContext(ApiExecuteContext);

    const [step, setStep] = useState(transferStateFromLocation ? steps.amount : steps.select);
    const [selectedFriend, setSelectedFriend] = useState(transferStateFromLocation || {});
    const [inputAmount, setInputAmount] = useState(0);
    const [myTransferred, setMyTransferred] = useState({
        winningCent: 0,
        todayCentQuota: 0,
        coinCent: 0
    });

    const transferStep = useMemo(() => ({
        select: {
            prevStep: () => history.goBack(),
            nextStep: () => setStep(steps.amount),
            component: SelectFriend
        },
        amount: {
            prevStep: () => {
                if (transferStateFromLocation) {
                    history.goBack();
                } else {
                    setStep(steps.select);
                }
            },
            nextStep: () => setStep(steps.confirm),
            component: TransferAmount
        },
        confirm: {
            prevStep: () => setStep(steps.amount),
            nextStep: () => null,
            component: ConfirmTransfer
        }
    }), [history, setStep, transferStateFromLocation]);

    const TransferBodyComponent = useMemo(() => transferStep[step].component, [step, transferStep]);

    useEffect(() => {
        apiExecutor(
            fetchMyTransferred(),
            {
                onSuccess: (_myTransferred) => {
                    setMyTransferred(_myTransferred);
                }
            }
        );
    }, [apiExecutor]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [step]);

    return <TransferBodyComponent
        toPrevStep={transferStep[step].prevStep}
        toNextStep={transferStep[step].nextStep}
        selectedFriend={selectedFriend}
        setSelectedFriend={setSelectedFriend}
        myTransferred={myTransferred}
        inputAmount={inputAmount}
        setInputAmount={setInputAmount}
    />;

};

export default TransferFlow;
