import Button from "designToken/button/Button";
import { authWelcomeImg } from "helpers/IconHelper";
import React from "react";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import styled from "styled-components";
import { APP_MAX_WIDTH } from "../../../styles/Constants";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";

export const AuthWelcomeImage = () => (
    <div className={"tw-p-2"}>
        <img
            alt="welcome"
            src={authWelcomeImg}
            className={"tw-w-[23.6rem] tw-h-auto tw-select-none"}
        />
    </div>
);
export const SelectButtonWrapper = styled.div.attrs({
    className: "gap-4",
})`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: ${APP_MAX_WIDTH}) {
    grid-template-columns: 1fr 1fr;
  }
`;
export const SelectButton = styled(Button).attrs<{ $isActive: boolean, $testId: string }>(
    ({ $isActive, $testId }) => ({
        variant: $isActive ? BUTTON_VARIANTS.dark : BUTTON_VARIANTS.outlineDark,
        icon: $isActive ? <MdRadioButtonChecked /> : <MdRadioButtonUnchecked />,
        pattern: BUTTON_PATTERN.rounded,
        size: BUTTON_SIZE.lg,
        className: "gap-3",
        dataTestId: $testId,
    }),
)`
  > .text {
    flex: 1;
    text-align: left;
  }
`;
