import { handOneFingerSwipe } from "helpers/IconHelper";
import { pixelToRem } from "helpers/number/NumberHelper";
import React from "react";
import { FormattedMessage } from "react-intl";
import useTimeout from "../../../../hooks/useTimeout";
import { useApLevelCarouselConfig } from "../ApLevel/hooks/useApLevelCarouselConfig";
import { useFindElementById } from "./hooks/useFindElementById";
import { Highlight, Position, TutorialBackground } from "./TutorialStyle";
import { HIGHLIGHT_PATTERN, TutorialStepProps } from "./type";

export const TutorialStep2: React.FC<TutorialStepProps> = ({ handleNextStep }) => {
    useTimeout(handleNextStep, 2000);
    const { slideWidth, width } = useApLevelCarouselConfig();

    const apLevelCarouselElement = useFindElementById("ap-level-carousel");

    if (!apLevelCarouselElement) {
        return <TutorialBackground />;
    }

    const positionTop = pixelToRem(apLevelCarouselElement.offsetTop + apLevelCarouselElement.offsetHeight / 2 - 13650 / width);
    const positionLeft = pixelToRem(apLevelCarouselElement.offsetWidth / 2 + slideWidth - 100);
    const highlightHeight = 6;

    return <>
        <TutorialBackground onClick={handleNextStep}>
            <Position top={positionTop} left={positionLeft}>
                <Highlight width={6} height={highlightHeight} pattern={HIGHLIGHT_PATTERN.circle} />
            </Position>
        </TutorialBackground>
        <Position top={positionTop + highlightHeight} left={positionLeft} zIndex={99999}>
            <div className="text-dark-primary fz-sf-regular-body-1 mt-4">
                <FormattedMessage id="daily_task_tutorial_step_2" />
            </div>
            <img src={handOneFingerSwipe} alt="hand-one-finger-swipe" className="mt-4" />
        </Position>
    </>;
};
