import FantasyDialog from "components/dialog/FantasyDialog";
import useDialogProps from "components/dialog/hook/useDialogProps";
import FantasyFormInput from "components/input/FantasyFormInput";
import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";
import { auth } from "domain/auth/AuthConfig";
import { AuthWrapper } from "domain/auth/components/AuthWrapper";
import { authResetPasswordImg } from "helpers/IconHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import useValidator from "hooks/useValidator";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSendPasswordResetEmail } from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import { MdMail } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import styled from "styled-components";

const AuthResetPasswordImg = styled.img.attrs({
    src: authResetPasswordImg,
    alt: "reset-password",
})`
    width: 17.3rem;
    height: auto;
    margin-top: 6.4rem;
    -webkit-user-drag: none;
`;

const ResetPassword: React.FC = () => {
    const intl = useIntl();
    const history = useFantasyHistory();
    const { register, getValues, setValue, setFocus, formState: { errors }, handleSubmit } = useForm();
    const { authResetPasswordDialogProps } = useDialogProps();
    const { emailValidator } = useValidator();
    const [sendPasswordResetEmail, loading] = useSendPasswordResetEmail(auth);

    const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] = useState<boolean>(false);
    const onSubmit = async data => {
        if (loading) return;

        await sendPasswordResetEmail(data.email);
        setIsResetPasswordDialogOpen(true);
    };

    useEffect(() => {
        setValue("email", history.getState("email"));
    }, [setValue, history]);

    useEffect(() => {
        setFocus("email");
    }, [setFocus]);

    return <AuthWrapper onNavBack={() => {
        history.replace(ROUTER_PATH_CONFIG.auth.login);
    }}>
        <div>
            <h1 className="fz-sf-bold-title-1 text-light-primary">
                <FormattedMessage id="login_page_label_forgot_password" />
            </h1>
            <p className="fz-sf-regular-subhead text-text-tertiary-1">
                <FormattedMessage id="forgot_password_page_description" />
            </p>
        </div>
        <div className="flex-grow-1 text-center">
            <AuthResetPasswordImg />
        </div>
        <div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <FantasyFormInput
                    prefixIcon={<MdMail size="2.2rem" />}
                    title={intl.formatMessage({ id: "label_email" })}
                    name="email"
                    type="email"
                    validator={emailValidator}
                    register={register}
                    getValues={getValues}
                    errors={errors}
                />
                <Button
                    disabled={loading}
                    variant={BUTTON_VARIANTS.primary}
                    size={BUTTON_SIZE.lg}
                    pattern={BUTTON_PATTERN.pill}
                    className="w-100 mt-5"
                >
                    <FormattedMessage id="change_password_dialog_button_send_email" />
                </Button>
            </Form>
        </div>
        <FantasyDialog show={isResetPasswordDialogOpen} {...authResetPasswordDialogProps} />
    </AuthWrapper>;
};

export default ResetPassword;
