import styled from "styled-components";
import { PrimaryButton } from "../../../styles/component/ButtonsStyle";
import { APP_MAX_WIDTH } from "../../../styles/Constants";

export const TransferWrapper = styled.div.attrs({
    className: "position-relative transfer-wrapper"
})`
`;
export const TransferContentWrapper = styled.div.attrs({
    className: "w-100"
})`
  height: 100%;
  min-height: calc(100vh - 6.5rem);
  left: 0;
  bottom: 0;
  padding: 1.6rem;
  @media (min-width: ${APP_MAX_WIDTH}) {
    padding: 1.6rem 3.2rem;
  }
`;

export const TransferNavbarCenter = styled.div.attrs({
    className: "h-100 d-flex align-items-center justify-content-center fz-sf-bold-title-3 tw-text-system-primary80"
})`
  text-indent: -2.4rem;
`;

export const FixedPrimaryButton = styled(PrimaryButton).attrs({
    className: "position-fixed"
})`
    bottom: 12px;
    left: 50%;
    width: 87.2%;
    max-width: calc(${APP_MAX_WIDTH} * .872);
    transform: translateX(-50%);
    @media (min-width: ${APP_MAX_WIDTH}) {
        left: calc(${APP_MAX_WIDTH} * .5);
        bottom: ${({ bottom }) => bottom || "12px"};
        width: calc(${APP_MAX_WIDTH} * .872);
    }
`;
