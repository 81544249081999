import { Placeholder, PlaceholderProps } from "react-bootstrap";
import styled from "styled-components";

interface TextPlaceHolderProps extends PlaceholderProps {
    $width?: number;
    $height?: number;
    $radius?: number;
    $bg?: string;
}

export const TextPlaceHolder = styled(Placeholder).attrs<TextPlaceHolderProps>
(({ $bg = "bg-gray-3" }) => ({
    className: `${$bg}`
}))`
    ${({ $width = 10 }) => `width: ${$width}rem;`}
    ${({ $height = 1.6 }) => `height: ${$height}rem;`}
    ${({ $radius = 0.4 }) => `border-radius: ${$radius}rem !important;`}
    min-height: 0;
`;

interface CirclePlaceHolderProps extends Omit<PlaceholderProps, "size"> {
    $size?: number;
    $margin?: string;
    $bg?: string;
}

export const CirclePlaceHolder = styled(Placeholder).attrs<CirclePlaceHolderProps>
(({ $bg = "bg-gray-3" }) => ({
    className: `rounded-circle ${$bg}`
}))`
    ${({ $size = 1.6 }) => `
        width: ${$size}rem;
        height: ${$size}rem;
   `}
`;
