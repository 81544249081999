import { MyMatchDto, RankedTeamsDto } from "data/dto/match/MyMatchDto";
import { isMatchKickoffOrLive, isMatchUpcoming } from "helpers/match/MatchHelper";
import { CONTEST_TYPE_FROM_API, ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";
import { MATCH_STATUS_FROM_API } from "types/match/Match";
import { SPORT, strToSport } from "types/sports/Sport";

export enum MatchComponentStatusEnum {
    LIVE = 'live',
    UPCOMING = 'upcoming',
    COMPLETED = 'completed'
}

class RankedTeamVo {
    contestId: number;
    teamId: number;
    teamName: string;
    scoreCent: number;
    rank: null | number;
    wonPrizeCent: number;
    type: CONTEST_TYPE_FROM_API;
    entryFeeType: ENTRY_FEE_TYPE_FROM_API;

    constructor(dto: RankedTeamsDto) {
        this.contestId = dto.contestId;
        this.teamId = dto.teamId;
        this.teamName = dto.teamName;
        this.scoreCent = dto.scoreCent;
        this.rank = dto.rank;
        this.wonPrizeCent = dto.wonPrizeCent;
        this.type = dto.type;
        this.entryFeeType = dto.entryFeeType;
    }
}

export class MyMatchVo {
    sport: SPORT;
    matchId: string;
    tournament: string;
    matchStartsAt: number;
    matchStatus: MATCH_STATUS_FROM_API;
    teams: number;
    contests: number;
    joined: number;
    squadAName: string;
    squadACode: string;
    squadALogoUrl: string;
    squadAScores: string;
    squadAOvers: string;
    squadBName: string;
    squadBCode: string;
    squadBLogoUrl: string;
    squadBScores: string;
    squadBOvers: string;
    battingSquadCode: null;
    winningSquadCode: string;
    tournamentId: string;
    isLineupsOut: boolean;
    rankedTeams: RankedTeamsDto[];

    constructor(dto: MyMatchDto) {
        this.sport = strToSport(dto.sport.toLowerCase());
        this.matchId = dto.matchId;
        this.tournament = dto.tournament;
        this.matchStartsAt = dto.matchStartsAt;
        this.matchStatus = dto.matchStatus;
        this.teams = dto.teams;
        this.contests = dto.contests;
        this.joined = dto.joined;
        this.squadAName = dto.squadAName;
        this.squadACode = dto.squadACode;
        this.squadALogoUrl = dto.squadALogoUrl;
        this.squadAScores = dto.squadAScores;
        this.squadAOvers = dto.squadAOvers;
        this.squadBName = dto.squadBName;
        this.squadBCode = dto.squadBCode;
        this.squadBLogoUrl = dto.squadBLogoUrl;
        this.squadBScores = dto.squadBScores;
        this.squadBOvers = dto.squadBOvers;
        this.battingSquadCode = dto.battingSquadCode;
        this.winningSquadCode = dto.winningSquadCode;
        this.tournamentId = dto.tournamentId;
        this.isLineupsOut = dto.isLineupsOut;
        this.rankedTeams = dto.rankedTeams.map(team => new RankedTeamVo(team));
    }

    get group(): MatchComponentStatusEnum {
        if (isMatchKickoffOrLive(this.matchStatus)) {
            return MatchComponentStatusEnum.LIVE;
        }
        if (isMatchUpcoming(this.matchStatus)) {
            return MatchComponentStatusEnum.UPCOMING;
        }

        return MatchComponentStatusEnum.COMPLETED;
    }
}
