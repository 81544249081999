import { TextPlaceHolder } from "components/skeleton/SkeletonStyle";
import { gameLogsConfig } from "domain/slate/components/gameLog/NineCatGameLog";
import React from "react";
import { Placeholder } from "react-bootstrap";

const NineCatGameLogSkeleton = () => {
    return (
        <Placeholder animation="glow">
            <table>
                <thead>
                <tr>
                    {
                        gameLogsConfig.map(({ key }) => {
                            return <th
                                key={key}
                                className="tw-bg-primary-100 tw-text-caption-1 tw-text-grey-1000 tw-font-bold tw-px-4 tw-py-2 text-center tw-whitespace-nowrap"
                            >
                                <TextPlaceHolder $radius={1.6} $height={1.2} $width={2.8} />
                            </th>;
                        })
                    }
                </tr>
                </thead>
                <tbody>
                {
                    Array(5).fill(true).map((_, index) => (
                        <tr className="odd:tw-bg-gray-50 even:tw-bg-white" key={index}>
                            {
                                gameLogsConfig.map(({ key }) => {
                                    return <td
                                        className="tw-text-caption-1 tw-text-grey-1000 tw-px-4 tw-py-2 text-center tw-whitespace-nowrap"
                                        key={key}
                                    >
                                        <TextPlaceHolder $radius={1.6} $height={1.2} $width={2.8} />
                                    </td>;
                                })
                            }
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </Placeholder>
    );
};

export default NineCatGameLogSkeleton;
