import { Image } from "react-bootstrap";
import styled from "styled-components";

export const EmailCheckPageWrapper = styled.div.attrs(() => ({
    className: "px-2 py-5"
}))`
`;
export const CancelButtonWrapper = styled.div.attrs(() => ({
    className: "text-end me-4"
}))`
`;
export const CancelButton = styled(Image).attrs(() => ({
    className: ""
}))`
    width: 2rem;
`;
export const MainImageWrapper = styled.div.attrs(() => ({
    className: "text-center mt-5"
}))`
`;
export const MainImage = styled(Image).attrs(() => ({
    className: ""
}))`
    width: 20rem;
`;
export const Title = styled.div.attrs(() => ({
    className: "text-center fz-sf-bold-title-1 tw-text-system-primary80"
}))`
    margin-top: 6rem;
`;
export const Text = styled.div.attrs(() => ({
    className: "text-center fz-sf-regular-body-2 px-5 mt-5 tw-text-system-labelLightSecondary"
}))`
    white-space: pre;
`;
