import { fetchMyApOverview } from "apis/ap/ActivityPointApi";
import { ApiExecuteContext } from "apis/ApiContext";
import { joinContest, joinRubyContest } from "apis/contest/ContestApi";
import { fetchMatchDetail, fetchMyContest, fetchPublicContest, fetchSortedMyTeam } from "apis/match/MatchApi";
import { Contest, MyContest } from "apis/match/type";
import JoinableNavbarMatchInfoWithTooltip from "components/container/navbar/JoinableNavbarMatchInfoWithTooltip";
import Navbar from "components/container/navbar/Navbar";
import WinningCost from "components/dialog/WinningCost";
import { useLoadingToggle } from "components/loading/Loading";
import { isFromAddDeposit } from "components/panel/SelectTeamPanelHelper";
import ContestsSkeleton from "components/skeleton/ContestsSkeleton";
import NavbarMatchInfoSkeleton from "components/skeleton/NavbarMatchInfoSkeleton";
import CustomTab, { TabText } from "components/tab/CustomTab";
import SelectTeamPanel from "containers/selectTeamPanel";
import TeamPreview from "containers/teamPreview";
import { fetchContestDetail, fetchMyContestJoinedTeam } from "data/api/contest/ContestApi";
import { LeaderboardTeamDto } from "data/dto/leaderboard/LeaderboardTeamDto";
import useUpdateCoupon from "domain/coupon/useUpdateCoupon";
import MyContestContentWithJoinButton from "domain/match/components/myContest/MyContestContentWithJoinButton";
import UpcomingMatchMyTeams from "domain/match/components/team/UpcomingMatchMyTeams";
import PublicContest from "domain/match/pages/upcoming/contest/public/PublicContest";
import { MatchDetailFlowWrapper, MatchDetailWrapper } from "domain/match/pages/upcoming/MatchDetailStyle";
import CreateTeamFlow from "domain/team/CreateTeamFlow";
import EditTeamFlow from "domain/team/EditTeamFlow";
import useUpdateWallet from "domain/wallet/useUpdateWallet";
import { analyticsEvent, analyticsParameterKey, logCustomEvent } from "ga";
import { useMinApLevel } from "helpers/ApLevelHelper";
import { hasJoinableTeams, isOnlyOneJoinableTeam } from "helpers/ContestHelper";
import { isMatchJoinable, isMatchPreparing } from "helpers/match/MatchHelper";
import useKycRestriction from "hooks/useCheckKycRestriction/useKycRestriction";
import { useStopJoiningDialog, useSuccessDialog } from "hooks/useDialog";
import useFantasyHistory from "hooks/useFantasyHistory";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import useHandleJoinContestError from "hooks/useHandleJoinContestError/useHandleJoinContestError";
import { useMatchPreparingToast } from "hooks/useToast";
import { isEmpty } from "lodash";
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { updateSelectTeamPanelData } from "store/actions/Actions";
import { MyApOverview } from "types/activityPoint/MyApOverview";
import { ContestDetailVO } from "types/contest/ContestDetail";
import { MatchVO } from "types/match/Match";
import { MyTeamVO } from "types/team/MyTeam";

export enum componentTag {
    DETAIL = "detail",
    CREATE = "create",
    EDIT = "edit"
}

export enum MATCH_DETAIL_TAB_KEY {
    publicContests = "public_contests",
    myContests = "my_contests",
    myTeams = "my_teams"
}

interface MatchDetailTab {
    tabKey: string;
    text: ReactNode;
    layout: React.FC<any>;
    props: object;
}

interface GenerateTabsProps {
    publicContests: Contest[];
    myContests: MyContest[];
    myTeams: MyTeamVO[];
    match: MatchVO | undefined;
    onJoinButtonClick: (e: any, contest: Contest) => void;
    toCreateTeam: (team?: MyTeamVO) => void;
    toEditTeam: (teamId: number) => void;
}

const generateTabs = ({
                          publicContests,
                          myContests,
                          myTeams,
                          match,
                          onJoinButtonClick,
                          toCreateTeam,
                          toEditTeam,
                      }: GenerateTabsProps) => {

    const tabs: MatchDetailTab[] = [
        {
            tabKey: MATCH_DETAIL_TAB_KEY.publicContests,
            text: <FormattedMessage id="match_detail_page_tab_contests" />,
            layout: PublicContest,
            props: {
                contests: publicContests,
                match,
                toCreateTeam,
                onJoinButtonClick,
            }
        },
        {
            tabKey: MATCH_DETAIL_TAB_KEY.myContests,
            text: <TabText text={<FormattedMessage id="label_my_contests" />} count={myContests.length} />,
            layout: MyContestContentWithJoinButton,
            props: {
                match,
                contests: myContests,
                onJoinButtonClick
            }
        },
        {
            tabKey: MATCH_DETAIL_TAB_KEY.myTeams,
            text: <TabText text={<FormattedMessage id="label_my_teams" />} count={myTeams.length} />,
            layout: UpcomingMatchMyTeams,
            props: {
                match,
                teams: myTeams,
                toCreateTeam,
                toEditTeam
            }
        }
    ];

    return tabs;
};

const TabBodyAndPanel = ({ tabs, activeTab }) => {
    const currentTab = tabs.find(({ tabKey }) => tabKey === activeTab);

    if (!currentTab) return null;

    const TabBodyLayout = currentTab.layout;
    const props = currentTab.props;

    return <>
        <TabBodyLayout {...props} />

    </>;
};


const MatchDetailFlow = () => {
    const history = useFantasyHistory();
    const location = history.location;
    const dispatch = useDispatch();
    const matchDetailBack = history.goBack;
    const { matchId, sport } = useParams();
    const handleJoinContestError = useHandleJoinContestError();
    const { isApLevelReached } = useMinApLevel();
    const apiExecutor = useContext(ApiExecuteContext);
    const updateWallet = useUpdateWallet();
    const updateCoupon = useUpdateCoupon();
    const isRestrictedByKyc = useKycRestriction();
    const toggleLoading = useLoadingToggle();
    const stopJoiningDialog = useStopJoiningDialog();
    const successPopup = useSuccessDialog();
    const handleMatchPreparingToast = useMatchPreparingToast();
    const intl = useIntl();
    const teamPreview = TeamPreview.useTeamPreview();

    const [currentComponent, setCurrentComponent] = useState(componentTag.DETAIL);
    const [contest, setContest] = useState<ContestDetailVO | null>(null);
    const [beOperatedTeam, setBeOperatedTeam] = useState<MyTeamVO | null>(null);

    // api
    const [publicContests, setPublicContests] = useState<Contest[]>([]);
    const [myContests, setMyContests] = useState<MyContest[]>([]);
    const [myTeams, setMyTeams] = useState<MyTeamVO[]>([]);
    const [myApOverview, setMyApOverview] = useState<MyApOverview | null>(null);
    const [activeTab, setActiveTab] = useState<MATCH_DETAIL_TAB_KEY | null>(null);

    const [isCreateTeamWhileSelectingTeam, setIsCreateTeamWhileSelectingTeam] = useState(false);

    const { data: match } = useFantasyQuery([QUERY_KEY.MATCH_DETAIL, sport, matchId], () => {
        return fetchMatchDetail(sport, matchId);
    });

    const allData = useFantasyQuery([QUERY_KEY.MATCH_DETAIL_UPDATED_ALL, sport, matchId], () => {
        return Promise.all([
            fetchMyApOverview(),
            fetchPublicContest(sport, matchId),
            fetchMyContest(sport, matchId),
            updateWallet(),
            updateCoupon()
        ]);
    }, {
        options: {
            enabled: !!match,
        }
    });

    const myTeamData = useFantasyQuery([QUERY_KEY.MATCH_DETAIL_UPDATE_MY_TEAM, sport, matchId], () => {
        return fetchSortedMyTeam(sport, matchId);
    }, {
        options: {
            enabled: !!match,
        }
    });


    const contestJoiningCallbacks = (selectPanelClose) => async ({
                                                                     contestId,
                                                                     selectedTeamIds,
                                                                     useRuby,
                                                                     couponIds,
                                                                     currentWinningCent,
                                                                     balanceWinningCent
                                                                 }) => {
        if (await isRestrictedByKyc()) {
            return;
        }
        toggleLoading(true);

        apiExecutor(
            joinContest(contestId, selectedTeamIds, useRuby, couponIds),
            {
                onSuccess: () => {
                    selectPanelClose();
                    successPopup({
                        text: intl.messages["join_contest_success_dialog_title"],
                        bodySlot: <WinningCost currentWinningCent={currentWinningCent}
                                               balanceWinningCent={balanceWinningCent} />
                    });
                    setContest(null);
                    allData.refetch();
                    myTeamData.refetch();
                },
                onFail: (_error) => {
                    selectPanelClose();
                    handleJoinContestError(_error);
                },
                onFinally: () => {
                    toggleLoading(false);
                }

            }
        );
    };

    const toCreateTeam = useCallback(async (team = null) => {
        if (await isRestrictedByKyc()) {
            return;
        }
        setBeOperatedTeam(team);
        setCurrentComponent(componentTag.CREATE);
    }, [isRestrictedByKyc]);

    const toCreateTeamWhileSelectingTeam = useCallback(async (team = null) => {
        await toCreateTeam(team);
        setIsCreateTeamWhileSelectingTeam(true);
    }, [toCreateTeam]);

    const toEditTeam = useCallback(async (team) => {
        if (await isRestrictedByKyc()) {
            return;
        }
        setBeOperatedTeam(team);
        setCurrentComponent(componentTag.EDIT);
    }, [isRestrictedByKyc]);

    const handleJoinRubyContest = (selectPanelClose) => async ({
                                                                   contestId,
                                                                   selectedTeamIds,
                                                               }) => {

        if (await isRestrictedByKyc()) {
            return;
        }
        toggleLoading(true);

        apiExecutor(
            joinRubyContest(contestId, selectedTeamIds),
            {
                onSuccess: () => {
                    selectPanelClose();
                    successPopup({
                        text: intl.messages["join_contest_success_dialog_title"],
                    });
                    setContest(null);
                    allData.refetch();
                    myTeamData.refetch();
                },
                onFail: (_error) => {
                    selectPanelClose();
                    handleJoinContestError(_error);
                },
                onFinally: () => {
                    toggleLoading(false);
                }

            }
        );
    };

    const selectTeamPanel = SelectTeamPanel.useSelectTeamPanel({
        sport,
        goToCreateTeam: toCreateTeamWhileSelectingTeam,
        onSubmit: contestJoiningCallbacks,
        onJoinRubyContest: handleJoinRubyContest,
    }, {
        selectedTeamIds: location?.state?.selectedTeamsIds || [],
        show: isFromAddDeposit(location?.state?.selectedTeamsIds),
        autoOpenConfirmation: isFromAddDeposit(location?.state?.selectedTeamsIds),
    });

    const onChoosePlayerPageReturn = () => {
        if (isCreateTeamWhileSelectingTeam) {
            selectTeamPanel.open();
        }
        setCurrentComponent(componentTag.DETAIL);
    };

    const afterOperatedTeam = async (callback?: Function) => {
        const _myTeams = await myTeamData.refetch();

        setCurrentComponent(componentTag.DETAIL);
        setIsCreateTeamWhileSelectingTeam(false);
        if (callback) {
            callback(_myTeams.data);
        }

    };

    const afterCreateTeam = afterOperatedTeam;
    const afterEditTeam = afterOperatedTeam;

    const onJoinContestFlowAfterCreateTeam = async () => {
        if (!contest) return;

        await afterOperatedTeam((_myTeams) => {
            selectTeamPanel.open({
                selectedTeamIds: [_myTeams[0].id],
                autoOpenConfirmation: isOnlyOneJoinableTeam(_myTeams, contest.myJoinedTeams)
            });
        });
    };

    const onJoinButtonClick = useCallback(async (e: any, contest: Contest) => {
        if (!isApLevelReached(myApOverview, contest)) {
            return;
        }
        if (await isRestrictedByKyc()) {
            return;
        }

        toggleLoading(true);

        apiExecutor(
            Promise.all([
                fetchContestDetail(contest.code),
                fetchMyContestJoinedTeam(contest.code)
            ]),
            {
                onSuccess: ([contest, myJoinedTeams]) => {
                    const newContest = {
                        ...contest,
                        myJoinedTeams,
                    };
                    setContest(newContest);
                    dispatch(updateSelectTeamPanelData({
                        contest: newContest,
                        joinedTeamIds: myJoinedTeams.map((item: LeaderboardTeamDto) => item.teamId)
                    }));
                    logCustomEvent(analyticsEvent.webTryToJoinContest, {
                        [analyticsParameterKey.action]: "circleJoinContest",
                        [analyticsParameterKey.sport]: sport
                    });
                    if (hasJoinableTeams(myTeams, newContest.myJoinedTeams)) {
                        selectTeamPanel.open();
                    } else {
                        toCreateTeam();
                    }
                },
                onFinally: () => {
                    toggleLoading(false);
                }
            }
        );

    }, [sport, myApOverview, isApLevelReached, toggleLoading, apiExecutor, myTeams, selectTeamPanel, toCreateTeam, isRestrictedByKyc]);

    const tabs = useMemo(() => {
        const newToCreateTeam = (team) => {
            logCustomEvent(analyticsEvent.webClickCreateTeam);
            toCreateTeam(team);
            setContest(null);
        };

        return generateTabs({
            match,
            publicContests,
            myContests,
            myTeams,
            toCreateTeam: newToCreateTeam,
            toEditTeam,
            onJoinButtonClick,
        });
    }, [match, publicContests, myContests, myTeams, toEditTeam, onJoinButtonClick, toCreateTeam]);


    const isInJoinContestFlow = !isEmpty(contest);

    const renderComponent = () => {
        if (!match || allData.isLoading) {
            return <ContestsSkeleton />;
        }

        const { DETAIL, CREATE, EDIT } = componentTag;

        switch (currentComponent) {
            case DETAIL:
                return isMatchJoinable(match.matchStatus)
                    ? <MatchDetailWrapper>
                        <TabBodyAndPanel tabs={tabs} activeTab={activeTab} />
                    </MatchDetailWrapper>
                    : null;

            case CREATE:
                return <CreateTeamFlow sport={sport}
                                       match={match}
                                       myTeams={myTeams}
                                       onNavbarBackClick={onChoosePlayerPageReturn}
                                       onSuccess={isInJoinContestFlow ? onJoinContestFlowAfterCreateTeam : afterCreateTeam}
                                       handleTimeout={stopJoiningDialog}
                                       copyTeam={beOperatedTeam} />;
            case EDIT:
                return <EditTeamFlow sport={sport}
                                     match={match}
                                     onNavbarBackClick={onChoosePlayerPageReturn}
                                     onSuccess={afterEditTeam}
                                     myTeams={myTeams}
                                     beEditedTeam={beOperatedTeam!}
                                     handleTimeout={stopJoiningDialog} />;
            default:
                return null;
        }
    };

    const onTabClick = useCallback((eventKey) => {
        setActiveTab(eventKey);
        teamPreview.close();

        history.replace({
            state: {
                tab: eventKey,
                from: location?.state?.from
            }
        });

        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, location?.state?.from]);

    useEffect(() => {
        if (location?.state?.contestCode) {
            apiExecutor(
                Promise.all([
                    fetchContestDetail(location.state.contestCode),
                    fetchMyContestJoinedTeam(location.state.contestCode)
                ]),
                {
                    onSuccess: ([contest, myJoinedTeams]) => {
                        const newContest = {
                            ...contest,
                            myJoinedTeams: myJoinedTeams
                        };
                        setContest(newContest);
                        dispatch(updateSelectTeamPanelData({
                            contest: newContest,
                            joinedTeamIds: myJoinedTeams.map((item: LeaderboardTeamDto) => item.teamId)
                        }));
                    },
                }
            );
        }
    }, [location?.state?.contestCode, apiExecutor]);

    useEffect(() => {
        if (match) {

            if (isMatchPreparing(match.matchStatus)) {
                handleMatchPreparingToast();
                return;
            }

            if (!isMatchJoinable(match.matchStatus)) {
                stopJoiningDialog();
                return;
            }
        }
    }, [match]);

    useEffect(() => {
        if (allData.data) {
            const [_myApOverview, _publicContests, _myContests] = allData.data;
            setMyApOverview(_myApOverview);
            setPublicContests(_publicContests);
            setMyContests(_myContests);
        }
    }, [allData]);

    useEffect(() => {
        if (myTeamData.data) {
            setMyTeams(myTeamData.data);
        }
    }, [myTeamData]);

    useEffect(() => {
        setActiveTab(location?.state?.tab || tabs[0].tabKey);
    }, [tabs, location]);

    return <>
        {
            currentComponent === componentTag.DETAIL &&
            <Navbar
                className="base-shadow-light-60"
                onNavbarBackClick={matchDetailBack}
                navbarStartAppend={match
                    ? <JoinableNavbarMatchInfoWithTooltip sport={sport}
                                                          match={match}
                                                          handleTimeout={stopJoiningDialog} />
                    : <NavbarMatchInfoSkeleton />}
                navbarBottom={<CustomTab tabs={tabs}
                                         onClick={onTabClick}
                                         activeTab={activeTab} />}
                showWallet={true}
                shouldCalculatePaddingTop={false}
            />
        }
        <MatchDetailFlowWrapper data-testid="match-detail-component">
            {renderComponent()}
        </MatchDetailFlowWrapper>
    </>;
};

export default MatchDetailFlow;
