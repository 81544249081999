import styled from "styled-components";


export const ScoringTableTitle = styled.div.attrs({
    className: "fz-sf-bold-body-1 text-text-primary-1"
})`
    padding: 1.6rem 0 .4rem;
`;

export const ScoringTitleWrapper = styled.div.attrs({
    className: "d-flex bg-gray-2 border-top border-bottom border-opaque-primary"
})`
    padding: .8rem 1.2rem;
`;
export const ScoringRowWrapper = styled.div.attrs({})`
    padding: 0 1.2rem;
`;
