import React from "react";
import Dialog, { DIALOG_SIZE } from "designToken/dialog/Dialog";
import {
    DialogSmBody,
    DialogSmButton,
    DialogSmButtonWrapper,
    DialogSmContent,
    DialogSmContentWrapper
} from "designToken/dialog/DialogStyle";
import { FormattedMessage } from "react-intl";

export const TipDialog = ({ dialogContent, onHide, show }: {
    show: boolean,
    onHide: () => void,
    dialogContent: React.JSX.Element
}) => {
    return <Dialog
        show={show}
        contentClassName="overflow-hidden"
        size={DIALOG_SIZE.SM}
        onHide={onHide}
    >
        <DialogSmBody>
            <DialogSmContentWrapper>
                <DialogSmContent>{dialogContent}</DialogSmContent>
            </DialogSmContentWrapper>
            <DialogSmButtonWrapper>
                <DialogSmButton onClick={onHide}>
                    <FormattedMessage id="dialog_button_close" />
                </DialogSmButton>
            </DialogSmButtonWrapper>
        </DialogSmBody>
    </Dialog>;
};
