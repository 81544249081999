import React, { ReactNode } from 'react';
import { StepNumber, StepNumberProps } from "domain/howToPlay/components/StepNumber";
import { StepText, StepTextProps } from "domain/howToPlay/components/StepText";

export interface StepWrapperProps extends StepNumberProps, StepTextProps {
    children: ReactNode | ReactNode[]
}

const StepWrapper: React.FC<StepWrapperProps> = ({ children, number, subtitle, title }) => {
    return (
        <div className="tw-z-1 tw-flex tw-flex-col tw-gap-6">
            <StepNumber number={number} />
            <StepText
                title={title}
                subtitle={subtitle}
            />
            {children}
        </div>
    );
};

export default StepWrapper;
