import { fetchChatTokenUrl } from "../ApiUrlConfig";
import { ajax } from "../ApiUtils";

interface ChatTokenResponse {
    chatId: "string";
    chatToken: "string";
}

export const fetchChatToken = (): Promise<ChatTokenResponse> => {
    return ajax<ChatTokenResponse>({ url: fetchChatTokenUrl })
        .then(({ data }) => data.data)
        .catch(error => {
            throw error;
        });
};
