import React from "react";
import { MyTeamVO } from "../../../types/team/MyTeam";
import { TeamCardWrapper } from "../SelectTeamPanelStyle";
import { TeamCard } from "../../card/TeamCardStyle";
import TeamCardContent from "../../team/TeamCardContent";
import { CheckBoxWrapper, TeamName, TeamNameWrapper } from "../../team/TeamCardStyle";
import { MATCH_STATUS_FROM_API } from "../../../types/match/Match";
import { FantasyCheckbox } from "components/checkbox/FantasyCheckbox";
import { CHECKBOX_VARIANTS } from "components/checkbox/types";

interface UnselectedCardProps {
    team: MyTeamVO;
    onTeamSelected: (teamId: number) => void;
    matchStatus: MATCH_STATUS_FROM_API;
}

export const UnselectedCard: React.FC<UnselectedCardProps> = ({
                                                                  team,
                                                                  onTeamSelected,
                                                                  matchStatus
                                                              }) => {
    return <TeamCardWrapper className="clickable fantasy-card-border"
                            onClick={() => onTeamSelected(team.id)}
                            data-testid={`my-team-${team.id}`}
                            data-unselected
    >
        <TeamCard>
            <TeamCardContent team={team} matchStatus={matchStatus}>
                <TeamNameWrapper>
                    <CheckBoxWrapper>
                        <FantasyCheckbox variant={CHECKBOX_VARIANTS.secondary} />
                    </CheckBoxWrapper>
                    <TeamName>{team.name}</TeamName>
                </TeamNameWrapper>
            </TeamCardContent>
        </TeamCard>
    </TeamCardWrapper>;
};
