import { StatsPlayer } from "types/player/Player";


export const getPlayersWithWeightedPointCent = (players: StatsPlayer[], selectedTeam): StatsPlayer[] => {
    return players.map(player => {

        player.setGeneral();

        if (player.id === selectedTeam.captain.id) {
            player.setCaptain();
        }

        if (player.id === selectedTeam.viceCaptain.id) {
            player.setViceCaptain();
        }

        return player;
    });

};
