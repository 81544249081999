import { Callback } from "Common";
import { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { addPopup, removePopup } from "store/actions/AlertActions";
import { FANTASY_DIALOG_VARIANT } from "../../../components/dialog/FantasyDialog";
import { FantasyPopupProps } from "../../../components/popup/FantasyBasicPopup";
import useFantasyHistory from "../../../hooks/useFantasyHistory";
import useUserIdentity from "../../../hooks/useUserIdentity";
import { ROUTER_PATH_CONFIG } from "../../../router/RouterPathConfig";
import { useSelector } from "../../../store";
import { AP_LEVEL } from "../../../types/activityPoint/ApLevel";
import { makeInsufficientApLevelDialogProps } from "../../contest/components/InsufficientApLevelDialog";
import {
    JoinNineCatContestSuccessfullyDialogProps,
    makeJoinNineCatContestSuccessfullyDialogProps
} from "../../contest/components/JoinNineCatContestSuccessfullyDialog";
import { makePrizesAndDistributionContainerDialog } from "../../contest/components/PrizesAndDistributionDialog";
import { makeContestSpotsFilledProps } from "../../contest/components/SpotsFilledDialog";
import { makeTeamsAlreadyJoinedDialogProps } from "../../contest/components/TeamsAlreadyJoinedDialog";

export function useJoinNineCatContestDialog() {
    const {
        FantasyGameConfigState: { addDepositUrl },
    } = useSelector(state => state);
    const { isB2B } = useUserIdentity();
    const location = useLocation();
    const history = useFantasyHistory();

    const dispatch = useDispatch();

    function handleCloseDialog() {
        dispatch(removePopup());
    }

    function handleOpenPopup(dialogProps: FantasyPopupProps) {
        dispatch(addPopup({
            ...dialogProps,
            variant: FANTASY_DIALOG_VARIANT.FANTASY_BASIC_POPUP,
            title: dialogProps.title as ReactNode,
            content: dialogProps.content,
            onExited: handleCloseDialog
        }));
    }

    function handleUpgradeApLevel(balanceDepositCent: number) {
        handleCloseDialog();

        if (isB2B) {
            window.open(addDepositUrl, "_self");
            return;
        }

        history.push(ROUTER_PATH_CONFIG.deposit.select, {
            requiredDepositCent: Math.abs(balanceDepositCent),
            navbarBackGoTo: ROUTER_PATH_CONFIG.slate.index,
            addDepositFlowFinishedGoTo: location.pathname,
        });
    }

    function handleOpenSpotsFilledDialog() {
        handleOpenPopup(makeContestSpotsFilledProps({
            onClose: handleCloseDialog
        }));
    }

    function handleOpenApLevelInsufficientDialog(currentApLevel: AP_LEVEL, minApLevel: AP_LEVEL, onUpgradeApLevel: Callback) {
        handleOpenPopup(makeInsufficientApLevelDialogProps({
            currentApLevel,
            minApLevel,
            onUpgradeApLevel,
            onClose: handleCloseDialog
        }));
    }

    function handleOpenTeamsAlreadyJoinedDialog(joinedTeamNames: string[]) {
        handleOpenPopup(makeTeamsAlreadyJoinedDialogProps({
            joinedTeamNames,
            onClose: handleCloseDialog,
        }));
    }

    function handleOpenJoinSuccessfullyDialog(params: JoinNineCatContestSuccessfullyDialogProps) {
        handleOpenPopup(makeJoinNineCatContestSuccessfullyDialogProps(params));
    }

    function handleOpenPrizeDistribution(contestCode: string) {
        handleOpenPopup(makePrizesAndDistributionContainerDialog({
            contestCode,
            onClose: handleCloseDialog,
        }));
    }

    return {
        handleUpgradeApLevel,
        handleOpenJoinSuccessfullyDialog,
        handleOpenSpotsFilledDialog,
        handleOpenApLevelInsufficientDialog,
        handleOpenTeamsAlreadyJoinedDialog,
        handleOpenPrizeDistribution,
        handleCloseDialog
    };
}
