import { useSelector } from "../../../store";
import { SQUAD_RIGHT_SLOT_TYPE } from "../../../store/reducer/teamPreview/types";
import { TeamPreviewSubstitute } from "../components/TeamPreviewSubstitute";
import React from "react";

const TeamPreviewSquadRightSlotFactory = () => {
    const {
        squadRightSlotType
    } = useSelector(state => state.TeamPreviewState.config);

    if (squadRightSlotType === SQUAD_RIGHT_SLOT_TYPE.SUBSTITUTION) {
        return <TeamPreviewSubstitute />;
    }

    return null;
};

export default TeamPreviewSquadRightSlotFactory;