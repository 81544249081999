import { divideByOneHundred, isNegative } from "helpers/number/NumberHelper";
import { sortFriendsByNickname } from "../FriendHelper";

export const isSelected = (id, selectedId) => {
    return id === selectedId;
};

export const groupAndSortFriendByNickname = (friends) => {
    return sortFriendsByNickname(friends).reduce((object, friend) => {
        const { nickname } = friend;
        const firstLetter = nickname.slice(0, 1).toUpperCase();

        if (object[firstLetter]) {
            object[firstLetter].push(friend);
        } else {
            object[firstLetter] = [friend];
        }

        return object;
    }, {});
};

export const checkTransferAmountConditions = (myTransferred, inputAmount, formatMessage, toWalletNumber) => {
    const { winningCent, todayCentQuota, depositCent } = myTransferred;
    const winning = divideByOneHundred(winningCent);
    const todayQuota = divideByOneHundred(todayCentQuota);

    if (isNegative(depositCent)) {
        return {
            message: formatMessage({ id: "transfer_page_label_negative_deposit" }),
            showInfoIcon: false,
            canProceed: false
        };
    }
    if (winning < inputAmount) {
        return {
            message: `${formatMessage({ id: "transfer_page_label_exceed_winning" })} ${toWalletNumber(winning)}`,
            showInfoIcon: false,
            canProceed: false
        };
    }
    if (todayQuota < inputAmount) {
        return {
            message: formatMessage({ id: "transfer_page_label_exceed_quota" }),
            showInfoIcon: true,
            canProceed: false
        };
    }
    if (inputAmount <= 0) {
        return {
            message: null,
            showInfoIcon: true,
            canProceed: false
        };
    }
    return {
        message: null,
        showInfoIcon: true,
        canProceed: true
    };
};

export const pushDigitFrom0To9 = (prevNumber, digit) => {
    if (digit >= 10 || digit < 0) throw new Error("Invalid number.");
    return prevNumber * 10 + digit;
};

export const popDigit = (prevNumber) => {
    return Math.floor(prevNumber / 10);
};
