import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { TaskRepository } from "../../data/repository/task/TaskRepository";
import useFantasyQuery from "../../hooks/useFantasyQuery";
import { QUERY_KEY } from "../../hooks/useFantasyQuery/type";
import { TaskPrizeClaimedVo } from "../../data/vo/task/TaskPrizeClaimedVo";
import { MyApOverview } from "../../types/activityPoint/MyApOverview";
import { fetchMyApOverview } from "../../apis/ap/ActivityPointApi";
import { useSelector } from "../../store";

interface QuestContextProps {
    handleCarouselIndexChange: (index: (number | ((pre: number) => number))) => void;
    currentCarouselIndex: number;
    currentLevelIndex: number;
    apOverview: MyApOverview | undefined;
    taskPrizeClaimed?: TaskPrizeClaimedVo,
}

const QuestContext = createContext<QuestContextProps | null>(null);

interface QuestProviderProps {
    children: React.ReactNode;
}

export const QuestProvider: React.FC<QuestProviderProps> = ({ children }) => {
    const apLevels = useSelector(state => state.SettingsState.apLevels);

    const taskRepository = new TaskRepository();

    const taskPrizeClaimed = useFantasyQuery([QUERY_KEY.TASK_PRIZE_CLAIMED], taskRepository.getPrizeClaimed);
    const apOverview = useFantasyQuery([QUERY_KEY.MY_AP_OVERVIEW], fetchMyApOverview);

    const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);
    const handleCarouselIndexChange = useCallback((index: number | ((pre: number) => number)) => {
        setCurrentCarouselIndex(index);
    }, []);


    const currentLevelIndex = useMemo(() => apLevels.findIndex(apLevel => apLevel.weight === apOverview.data?.currentLevelWeight), [apLevels, apOverview.data?.currentLevelWeight]);

    useEffect(() => {
        handleCarouselIndexChange(currentLevelIndex);
    }, [currentLevelIndex, handleCarouselIndexChange]);

    const context = useMemo<QuestContextProps>(() => ({
        handleCarouselIndexChange,
        taskPrizeClaimed: taskPrizeClaimed.data,
        apOverview: apOverview.data,
        currentCarouselIndex,
        currentLevelIndex,
    }), [
        handleCarouselIndexChange,
        taskPrizeClaimed,
        currentCarouselIndex,
        currentLevelIndex,
    ]);

    return <QuestContext.Provider value={context}>
        {children}
    </QuestContext.Provider>;
};

export const useQuestContext = () => {
    const context = useContext(QuestContext);

    if (!context) {
        throw new Error("useTaskProvider must be used within a TaskContext");
    }

    return context;
};
