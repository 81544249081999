import React from "react";
import { TransactionDetailVo } from "data/vo/wallet/TransactionDetailVo";
import { WALLET_API_RESPONSE_EVENT, WALLET_TYPE } from "data/dto/wallet/walletHistoryDto";
import TransactionDetailBase from "domain/wallet/transactionDetail/TransactionDetailBase";
import TransactionProductDetail from "domain/wallet/winning/TransactionProductDetail";
import WinningContestDetails from "domain/wallet/transactionDetail/WinningContestDetails";
import WinningContestAmountDetail from "domain/wallet/transactionDetail/WinningContestAmountDetail";
import WithdrawDetail from "domain/wallet/transactionDetail/WithdrawDetail";

interface WinningTransactionDetailProps {
    transactionDetailData?: TransactionDetailVo;
    onHide: () => void;
}

const WinningTransactionDetail: React.FC<WinningTransactionDetailProps> = ({
                                                                               transactionDetailData,
                                                                               onHide
                                                                           }) => {
    if (!transactionDetailData) return null;

    switch (transactionDetailData.event) {
        case WALLET_API_RESPONSE_EVENT.PURCHASE_REWARD_VOUCHER:
        case WALLET_API_RESPONSE_EVENT.PURCHASE_REWARD_REDEPOSIT:
        case WALLET_API_RESPONSE_EVENT.REFUND_REWARD_ORDER:
            return <TransactionDetailBase
                transactionData={transactionDetailData}
                walletType={WALLET_TYPE.WINNING}
                extraDetailComponent={() => (
                    <TransactionProductDetail product={transactionDetailData.product} />
                )}
                onHide={onHide}
            />;

        case WALLET_API_RESPONSE_EVENT.WIN_CONTEST:
            return <TransactionDetailBase
                transactionData={transactionDetailData}
                walletType={WALLET_TYPE.WINNING}
                extraDetailComponent={() => (
                    <WinningContestDetails {...transactionDetailData} />
                )}
                amountExtraComponent={() => (
                    <WinningContestAmountDetail {...transactionDetailData} />
                )}
                onHide={onHide}
            />;

        case WALLET_API_RESPONSE_EVENT.USER_WITHDRAW_WINNING:
        case WALLET_API_RESPONSE_EVENT.CANCELLED_USER_WITHDRAW_WINNING:
            return <TransactionDetailBase
                transactionData={transactionDetailData}
                onHide={onHide}
                walletType={WALLET_TYPE.WINNING}
                amountExtraComponent={() => (
                    <WithdrawDetail transactionDetailData={transactionDetailData} />
                )}
            />;

        default:
            return <TransactionDetailBase
                transactionData={transactionDetailData}
                walletType={WALLET_TYPE.WINNING}
                onHide={onHide}
            />;
    }
};

export default WinningTransactionDetail;
