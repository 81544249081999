import {
    INJURY_PLAYING_CHANCE,
    INJURY_PLAYING_CHANCE_ABBR
} from "data/vo/player/profile/BasketballPlayerInfoProfileVo";
import { cn } from "helpers/cn";
import React from "react";

interface InjuryLabelProps {
    injuryPlayingChange: INJURY_PLAYING_CHANCE;
}

export const InjuryLabel: React.FC<InjuryLabelProps> = ({
                                                            injuryPlayingChange
                                                        }) => {
    if (injuryPlayingChange === INJURY_PLAYING_CHANCE.UNKNOWN) return (
        <div className={"tw-h-4"} />
    );

    return (
        <div
            className={cn(
                "tw-px-1 tw-py-0.5 tw-text-overline tw-text-white tw-rounded-lg tw-text-center",
                injuryPlayingChange === INJURY_PLAYING_CHANCE.OUT ? "tw-bg-error-800" : "tw-bg-warning-900",
            )}>
            {INJURY_PLAYING_CHANCE_ABBR[injuryPlayingChange]}
        </div>
    );
};
