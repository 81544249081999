import PayMongoPaymentMethods from "domain/coin/pages/SelectPaymentMethod/components/PayMongoPaymentMethods";
import { TripleAPaymentMethods } from "domain/coin/pages/SelectPaymentMethod/components/TripleAPaymentMethods";
import useFantasyConfig from "hooks/useFantasyConfig";
import React from "react";
import { ADD_COIN_METHOD } from "store/reducer/config/FantasyGameConfigState";

export const AddCoinMethods = ({ productId }) => {
    const { featureConfig: { paymentMethod } } = useFantasyConfig();

    // TODO: UPay
    //     if (addCoinMethod === ADD_COIN_METHOD.UPAY) {
    //         return <UpayPaymentMethods productId={productId} />;
    //     }

    if (paymentMethod === ADD_COIN_METHOD.PAY_MONGO) {
        return <PayMongoPaymentMethods productId={productId} />;
    }

    if (paymentMethod === ADD_COIN_METHOD.TRIPLEA) {
        return <TripleAPaymentMethods productId={productId} />;
    }

    return null;
};

