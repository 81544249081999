import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";
import { PlayerInfoDialogCardFooter } from "domain/team/playerInfoDialog/PlayerInfoDialogStyle";
import React from "react";
import { FormattedMessage } from "react-intl";
import { SelectablePlayer, SquadPlayer } from "types/player/Player";


export interface PlayerInfoDialogFooterProps {
    closeDialog: () => void,
    handleSelectedPlayer: Function,
    player: SquadPlayer
}

export const PlayerInfoDialogFooter: React.FC<PlayerInfoDialogFooterProps> = ({ closeDialog }) => {
    return <PlayerInfoDialogCardFooter>
        <Button variant={BUTTON_VARIANTS.outlineDark}
                size={BUTTON_SIZE.md}
                pattern={BUTTON_PATTERN.pill}
                onClick={closeDialog}
                className="w-75"
        >
            <FormattedMessage id="dialog_button_close" />
        </Button>
    </PlayerInfoDialogCardFooter>;
};

const OperateButton: React.FC<{ player: SelectablePlayer, handleSelectedPlayer: Function }> = ({
                                                                                                   player,
                                                                                                   handleSelectedPlayer
                                                                                               }) => {
    if (player.isSelected) {
        return <Button variant={BUTTON_VARIANTS.secondary}
                       size={BUTTON_SIZE.md}
                       pattern={BUTTON_PATTERN.pill}
                       className="flex-grow-1"
                       onClick={() => handleSelectedPlayer(player)}
        >
            <FormattedMessage id="dialog_button_remove" />
        </Button>;
    }

    return <Button variant={BUTTON_VARIANTS.primary}
                   size={BUTTON_SIZE.md}
                   pattern={BUTTON_PATTERN.pill}
                   className="flex-grow-1"
                   disabled={player.isDisabled}
                   onClick={() => handleSelectedPlayer(player)}
    >
        <FormattedMessage id="dialog_button_add" />
    </Button>;
};

export const PlayerInfoDialogOperateFooter: React.FC<PlayerInfoDialogFooterProps> = ({
                                                                                         closeDialog,
                                                                                         handleSelectedPlayer,
                                                                                         player
                                                                                     }) => {
    return <PlayerInfoDialogCardFooter>
        <Button variant={BUTTON_VARIANTS.outlineDark}
                size={BUTTON_SIZE.md}
                pattern={BUTTON_PATTERN.pill}
                onClick={closeDialog}
        >
            <FormattedMessage id="dialog_button_close" />
        </Button>
        {
            player instanceof SelectablePlayer &&
            <OperateButton player={player} handleSelectedPlayer={handleSelectedPlayer} />
        }
    </PlayerInfoDialogCardFooter>;
};

export enum PlayerInfoDialogFooterType {
    WITH_OPERATE = "withOperate",
    ONLY_CLOSE = "onlyClose"
}

export const PlayerInfoDialogFooterMap = {
    [PlayerInfoDialogFooterType.ONLY_CLOSE]: PlayerInfoDialogFooter,
    [PlayerInfoDialogFooterType.WITH_OPERATE]: PlayerInfoDialogOperateFooter
};
