import React from "react";
import { FormattedMessage } from "react-intl";
import { emptyGeneral } from "helpers/IconHelper";

export const HomeEmptyMatch = () => {
    return (
        <div className={"tw-flex tw-flex-col tw-items-center tw-justify-center"}>
            <div className={"tw-p-4 tw-pt-[56px]"}>
                <img className={"tw-max-w-[192px]"} alt={"empty-match-upcoming"} src={emptyGeneral} />
            </div>
            <p className={"tw-text-body-2"}>
                <FormattedMessage id="home_page_label_empty_upcoming_matches" />
            </p>
        </div>
    );
};
