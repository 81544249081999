import { CreateContestRequest } from "types/contest/CreateContestRequest";
import { createContestUrl, getJoinContestUrl, getRubyJoinContestUrl } from "../ApiUrlConfig";
import { ajax } from "../ApiUtils";

export const createContest = (data: CreateContestRequest): Promise<boolean> => {
    return ajax({
        method: "POST",
        url: createContestUrl,
        data
    })
        .then(() => true)
        .catch(error => {
            throw error;
        });
};


export const joinContest = (contestId: number, selectedTeamIds: number[], useRuby: boolean, couponIds: number[] = []): Promise<boolean> => {
    return ajax({
        method: "POST",
        url: getJoinContestUrl(contestId),
        data: {
            teamIds: selectedTeamIds,
            couponIds: couponIds,
            useRuby: useRuby
        }
    })
        .then(() => true)
        .catch(error => {
            throw error;
        });

};

export const joinRubyContest = (contestId: number, selectedTeamIds: number[]): Promise<boolean> => {
    return ajax({
        method: "POST",
        url: getRubyJoinContestUrl(contestId),
        data: {
            teamIds: selectedTeamIds,
        }
    })
        .then(() => true)
        .catch(error => {
            throw error;
        });
};


