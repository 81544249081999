import { Card, Col } from "react-bootstrap";
import styled from "styled-components";
import { APP_MAX_WIDTH } from "styles/Constants";

const height = "85vh";
const maxHeight = "91.8rem";

export const PlayerInfoDialogWrapper = styled.div`
    height: ${height};
    max-height: ${maxHeight};

    @media (max-width: ${APP_MAX_WIDTH}) {
        .swiper-slide:not(.swiper-slide-active) .player-dialog-card:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.7);
            z-index: 3;
        }
    }

    @media (min-width: ${APP_MAX_WIDTH}) {
        .swiper-slide:not(.swiper-slide-active) {
            transform: scale(0.8);
        }
    }
`;

export const PlayerInfoDialogCard = styled(Card).attrs({
    className: "overflow-hidden border-0 text-start position-relative player-dialog-card w-100"
})`
    border-radius: 1.6rem !important;
    box-shadow: .2rem .4rem .8rem rgba(0, 0, 0, 0.4);
`;

export const PlayerInfoDialogCardHeader = styled.div.attrs({
    className: "pt-4 ps-4 position-relative d-flex"
})`
    gap: 3.2rem;
`;

export const PlayerInfoDialogCardFooter = styled.div.attrs({
    className: "position-absolute bottom-0 start-0 d-flex justify-content-center"
})`
    width: 100%;
    padding: 1.6rem 2.4rem 2.4rem;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) -7.74%, #FFFFFF 21.44%);
    gap: 1rem;
    border-radius: var(--modal-border-radius);
`;

export const PlayerInfoDialogCardBody = styled.div.attrs({
    className: "pt-3 px-4 overflow-auto d-flex flex-column"
})`
    padding-bottom: 8rem;
    gap: 1.6rem;
    height: calc(${height} - 9.6rem);
    max-height: calc(${maxHeight} - 9.6rem);
`;

export const AvatarWrapper = styled.div.attrs({
    className: "position-relative"
})`
    width: 8rem;
    height: 8rem;
    z-index: 2;
`;

export const SquadLogoWrapper = styled.div.attrs({
    className: "rounded-circle position-absolute overflow-hidden bg-default-primary-1 d-flex justify-content-center align-items-center"
})`
    top: .8rem;
    left: 1.6rem;
    padding: .2rem;
    width: 3.2rem;
    height: 3.2rem;
    z-index: 3;
`;

export const CreditWrapper = styled.div.attrs({
    className: "position-absolute overflow-hidden fz-sf-bold-footnote text-dark-primary text-center"
})`
    top: .8rem;
    right: 0;
    width: 4.5rem;
    height: 3.2rem;
    line-height: 3.2rem;
    z-index: 3;
    border-radius: 2rem 0 0 2rem;
`;

const RoleText = styled.div.attrs({
    className: "position-absolute end-0"
})`
    bottom: -1.5rem;
    font-size: 6rem;
    line-height: 1.2;
    z-index: 1;
`;

export const HomeRoleText = styled(RoleText)`
    color: var(--black-10);

`;
export const AwayRoleText = styled(RoleText)`
    color: var(--white-10);
`;

export const SquadPlayerInfoWrapper = styled.div.attrs({
    className: "pt-3 position-relative"
})`
    z-index: 2;
`;

export const SelectRate = styled.span.attrs({
    className: "fz-sf-regular-footnote"
})``;

export const DotSeparator = styled.span.attrs({
    className: "rounded-circle"
})`
    width: .2rem;
    height: .2rem;
    background-color: #000000;
`;
export const Separator = styled.span.attrs({
    className: "border-dark-primary border-start"
})`
    height: 1.2rem;
`;

export const GlossaryItem = styled.div.attrs({
    className: "fz-sf-regular-footnote text-light-secondary"
})``;
export const GlossaryItemTitle = styled.span.attrs({
    className: "fz-sf-bold-footnote text-light-primary"
})``;

export const NavigationButtonWrapper = styled.div.attrs({
    className: "text-dark-primary d-flex justify-content-center"
})`
    padding-top: 1.2rem;
    gap: 3.2rem;

    button {
        color: inherit;
    }
`;

export const PerformanceCol = styled(Col).attrs({
    className: "position-relative text-center"
})`
    &:not(:last-child):after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: .1rem;
        height: 2rem;
        background-color: var(--gray-100);
    }
`;
export const GameLogDetail = styled.td.attrs({
    className: "py-3"
})`
    white-space: nowrap;
    padding-left: .4rem;
    padding-right: .4rem;
    min-width: 3.8rem;

    &:last-child {
        padding-right: .8rem;
    }
`;

export const PlayerInfoDialogLoadingWrapper = styled.div.attrs({
    className: "d-flex justify-content-center align-items-center w-100"
})`
    height: 60%;
`;
