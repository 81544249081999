import { logPageViewEvent } from "ga";
import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import useFantasyConfig from "../../hooks/useFantasyConfig";

interface PageViewRecorderProps {
    title?: string
}

const PageViewRecorder = ({ title: pageTitle }: PageViewRecorderProps) => {
    const fantasyConfig = useFantasyConfig();

    const makeTitle = () => {

        if (pageTitle) {
            return `${pageTitle} | ${fantasyConfig.title}`;
        } else {
            return fantasyConfig.title;
        }
    };

    useEffect(() => {
        logPageViewEvent();
    }, [pageTitle]);

    return <HelmetProvider>
        <Helmet>
            <title>{makeTitle()}</title>
        </Helmet>
    </HelmetProvider>;
};

export default PageViewRecorder;
