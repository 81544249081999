export interface TimeObject {
    day: number
    hour: number
    minute: number
    second: number
}

export const formatCountdownTime = (timeObject: TimeObject, dayLocaleFormatter: Function): string => {
    const {
        day,
        hour,
        minute,
        second
    } = timeObject;

    if (day >= 2) return dayLocaleFormatter(day);
    if (day >= 1) return `${hour + 24}h`;
    if (hour !== 0) return minute === 0 ? `${hour}h` : `${hour}h ${minute}m`;
    if (minute !== 0) return second === 0 ? `${minute}m` : `${minute}m ${second}s`;

    return `${second}s`;
};

export const isTimeOut = (seconds: number): boolean => isNaN(+seconds) || seconds < 0;

export const to24HourClock = (seconds): TimeObject => {
    const day = Math.floor(seconds / 60 / 60 / 24);
    const hour = Math.floor(seconds / 60 / 60 - day * 24);
    const minute = Math.floor((seconds / 60) % 60);
    const second = Math.floor(seconds % 60);

    return { day, hour, minute, second };
};

export const isMatchStartsAtExpired = (matchStartsAt: number): boolean => matchStartsAt <= new Date().getTime();
