import { useSelector } from "store";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useIntl } from "react-intl";
import { useWithdrawSettings } from "domain/withdraw/hooks/useWithdrawSettings";

export const FORM_FIELD_AMOUNT = "withdrawAmount";

function useFieldAmountSchema() {
    const intl = useIntl();
    const {
        minimumWithdrawAmount,
    } = useWithdrawSettings();
    const {
        WalletState: { winning }
    } = useSelector(state => state);

    const amountExceededErrorMessage = intl.formatMessage({ id: "withdraw_to_account_amount_exceed_validation_error" });
    const amountMinimumErrorMessage = intl.formatMessage({ id: "withdraw_to_account_minimum_amount_validation_error" }, {
        minimumWithdrawAmount: minimumWithdrawAmount,
    });
    const amountValidationErrorMessage = intl.formatMessage({ id: "withdraw_to_account_amount_validation_error" });

    const formFieldAmountSchema = z
        .coerce
        .number({
            required_error: amountValidationErrorMessage
        })
        .max(winning, {
            message: amountExceededErrorMessage,
        })
        .min(minimumWithdrawAmount, {
            message: amountMinimumErrorMessage
        });

    return formFieldAmountSchema;
}

export function useWithdrawStep2Form() {
    const formFieldAmountSchema = useFieldAmountSchema();

    return useForm<{
        [FORM_FIELD_AMOUNT]: number
    }>({
        resolver: zodResolver(z.object({
            [FORM_FIELD_AMOUNT]:
            formFieldAmountSchema
        })),
        defaultValues: {
            [FORM_FIELD_AMOUNT]: "" as any,
        },
        mode: "onChange"
    });
}
