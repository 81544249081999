import React, { ReactNode } from "react";
import {
    StyledGradientCircleProgressbar,
    StyledGradientCircleProgressbarContent,
    StyledGradientCircleProgressbarWrapper,
} from "./GradientCircleProgressbarStyle";

interface IGradientCircleProgressbarProps {
    size?: number; // px
    percentage: number;
    gradientProgressStartColor?: string;
    gradientProgressEndColor?: string;
    progressDefaultColor?: string;
    progressBarWidth?: number; // px
    children?: ReactNode;
}

const GradientCircleProgressbar: React.FC<IGradientCircleProgressbarProps> = ({
                                                                                  percentage,
                                                                                  size = 80,
                                                                                  progressBarWidth = 12,
                                                                                  gradientProgressStartColor,
                                                                                  gradientProgressEndColor,
                                                                                  progressDefaultColor,
                                                                                  children,
                                                                              }) => {
    const outerCircleSemiRadius = size / 2;
    const innerCircleSemiRadius = (size - progressBarWidth) / 2;
    const circumference = 2 * Math.PI * innerCircleSemiRadius;
    const offset = circumference - (percentage / 100) * circumference;
    const gradientId =
        `${gradientProgressStartColor}${gradientProgressEndColor}`.replace(
            /#/g,
            "",
        );

    return (
        <StyledGradientCircleProgressbarWrapper $size={size}>
            {children && (
                <StyledGradientCircleProgressbarContent>
                    {children}
                </StyledGradientCircleProgressbarContent>
            )}

            <svg
                width="100%"
                height="100%"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" stopColor={gradientProgressEndColor} />
                    <stop offset="100%" stopColor={gradientProgressStartColor} />
                </linearGradient>
                <circle
                    strokeWidth={progressBarWidth}
                    fill="transparent"
                    r={innerCircleSemiRadius}
                    cx={outerCircleSemiRadius}
                    cy={outerCircleSemiRadius}
                    stroke={progressDefaultColor}
                    strokeDasharray={`${circumference} ${circumference}`}
                />
                <StyledGradientCircleProgressbar
                    strokeWidth={progressBarWidth}
                    fill="transparent"
                    r={innerCircleSemiRadius}
                    cx={outerCircleSemiRadius}
                    cy={outerCircleSemiRadius}
                    stroke={`url(#${gradientId})`}
                    strokeLinecap="round"
                    strokeDasharray={`${circumference} ${circumference}`}
                    strokeDashoffset={offset}
                />
            </svg>
        </StyledGradientCircleProgressbarWrapper>
    );
};

export default GradientCircleProgressbar;
