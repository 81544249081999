import B2BHomeNavbar from "domain/home/components/FantasyHomeNavbar/B2BHomeNavbar/B2BHomeNavbar";
import B2CHomeNavbar from "domain/home/components/FantasyHomeNavbar/B2CHomeNavbar";
import useUserIdentity from "hooks/useUserIdentity";
import React from "react";

export const FantasyHomeNavbar = () => {
    const { isB2B } = useUserIdentity();

    if (isB2B) {
        return <B2BHomeNavbar />;
    }

    return <B2CHomeNavbar />;
};
