import { UseQueryOptions } from "@tanstack/react-query/src/types";
import { ApiError } from "apis/Api";
import { ERROR_HANDLER_FLOW } from "hooks/useHandleApiError";

export interface Setting<T> {
    onFail?: (error: ApiError) => ERROR_HANDLER_FLOW;
    onFinally?: () => void;
    options?: Omit<UseQueryOptions<T>, "queryKey" | "queryFn" | "initialData"> & {
        initialData?: () => T | undefined;
    };
    errorHandle?: boolean;
}

export enum QUERY_STATUS {
    LOADING = "loading",
    ERROR = "error",
    SUCCESS = "success",
}

export enum QUERY_KEY {
    TASK_PRIZE_CLAIMED = "taskPrizeClaimed",
    TASKS = "tasks",
    USER_TASK_PROGRESS = "userTaskProgress",
    MY_AP_OVERVIEW = "myApOverview",
    USER_CAREER_STATS = "userCareerStats",
    GET_USER_FOLLOWERS = "getUserFollowers",
    MY_FIRST_TIME_PURCHASE = "myFirstTimePurchase",

    MY_FOLLOW = "myFollow",
    MY_REFEREE = "MyReferee",
    MY_CAREER_STATS = "myCareerStats",
    USER_INFO = "userInfo",
    GET_MY_TRANSFERRED = "getMyTransferred",
    PLAY_INFO = "playInfo",
    MY_REFERRAL_CODE = "myReferralCode",
    MY_REFEREE_SUMMARY = "myRefereeSummary",
    AP_LEVEL_LIST = "apLevelList",

    UPCOMING_MATCHES = "upcomingMatches",
    MY_MATCHES = "myMatches",
    MY_NINE_CAT_SLATE = "myNineCatSlate",

    FEATURED_CONTESTS = "featuredContests",
    FEATURED_CONTESTS_Philippine = "featuredContestsPhilippine",
    BASKETBALL_PLAYER_NEWS = "basketballPlayerNews",
    BASKETBALL_PLAYER_INFO = "basketballPlayerInfo",
    MATCH_DETAIL = "matchDetail",
    MATCH_DETAIL_UPDATED_ALL = "matchDetailUpdateAll",
    MATCH_DETAIL_UPDATE_MY_TEAM = "matchDetailUpdateMyTeam",
    COMPLETED_CONTESTS = "completedContests",
    MATCH_RESULT_PLAYERS = "matchResultPlayers",
    SORTED_MY_TEAM = "sortedMyTeam",
    MATCH_SNAPSHOT = "matchSnapshot",

    SUPPORTED_REGION = "supportedRegion",
    RESULT_CONTEST_DETAIL = "resultContestDetail",
    SETTING = "setting",
    UPCOMING_SLATE = "upcomingSlate",
    RUBY_CONTEST_CHAMPION_REMINDER = "rubyContestChampionReminder",
    DAILY_CHECK_IN_REMINDER = "dailyCheckInReminder",
    AD = "ad",
    CAMPAIGN_STATUS = "campaignStatus",
    COIN_AMOUNT = "coinAmount",
    PAYMENT_METHOD = "paymentMethod",
    UPAY_BANKS = "upayBanks",
    SLATE_DETAIL = "slateDetail",
    GET_MY_NINE_CAT_SLATE_TEAM = "getMyNineCatSlateTeam",
    GET_SLATE_PLAYERS = "getSlatePlayers",
    SLATE_CONTEST_DETAIL = "slateContestDetail",
    CONTEST_DETAIL = "contestDetail",
    MY_CONTEST_JOINED_TEAM = "myContestJoinedTeam",
    GET_MY_JOINED_CONTESTS = "getMyJoinedContests",
    GET_LEADERBOARD = "getLeaderboard",
    CHECK_ORDER_STATUS = "checkOrderStatus",
    PAYMONGO_RECEIVING_INSTITUTIONS = "paymongoReceivingInstitutions",
    DAILY_CHECK_IN_QUEST = "dailyCheckInQuest",
    BASKETBALL_CURRENT_SEASON = "basketballCurrentSeason",
    PRIZE_DISTRIBUTION = "prizeDistribution",
    MY_PROFILE = "myProfile",
    AUTH_CONFIG = "authConfig",
    WALLET_HISTORY = "walletHistory",
    FETCH_VALIDATE_SESSION = "fetchValidateSession",

    RESPONSIBLE_GAMING_REGISTRATION = "responsibleGamingRegistration",
}
