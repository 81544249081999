import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NineCatContestDetailVo } from "../../../data/vo/nineCat/NineCatContestDetailVo";
import { ENTRY_FEE_TYPE_FROM_API } from "../../../types/contest/Contest";
import { ContestEntry } from "../helpers/helpers";

interface MySlateNavTitleProps {
    contestDetail?: NineCatContestDetailVo;
    contestEntry: ContestEntry;
}

const MySlateNavTitle: React.FC<MySlateNavTitleProps> = ({
                                                             contestDetail,
                                                             contestEntry
                                                         }) => {
    const intl = useIntl();

    if (!contestDetail) return null;

    if (contestEntry.isPractice()) {
        return <h2 className="tw-text-h5 tw-font-bold">
            <FormattedMessage id="contest_card_label_entry_fee_free" />
        </h2>;
    }

    const suffixText = contestDetail.entryFeeType === ENTRY_FEE_TYPE_FROM_API.RUBY
        ? intl.formatMessage({ id: "label_ruby" })
        : intl.formatMessage({ id: "label_coin" });

    return <h2 className="tw-text-h5 tw-font-bold">
        {contestDetail.entryFee} {suffixText}
    </h2>;
};

export default MySlateNavTitle;