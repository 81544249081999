import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

export const WalletHistoryTableContainer = styled(Container).attrs(() => ({}))`
    padding-top: 1.6rem;
    padding-bottom: 7.5rem;
`;
export const WalletHistoryHeader = styled.div.attrs(() => ({
    className: "d-flex justify-content-start align-items-center tw-border-system-labelLightQuaternary"
}))`
    padding: .8rem 1.2rem;
    border-width: 1px 0 1px 0;
    border-style: solid;
`;
export const HeaderTitle = styled.div.attrs(() => ({
    className: "fz-sf-regular-body-2 me-auto text-text-secondary-1"
}))`
`;
export const HistoryRecordItemWithChevronButtonWrapper = styled.div.attrs({
    className: "position-relative d-flex align-items-center border-light-primary tw-border-none"
})`
    border-bottom-style: solid;
    border-width: 1px;
    cursor: pointer;
`;

export const HistoryRecordItemWrapper = styled.div.attrs({
    className: " border-light-primary tw-border-none"
})`
    border-bottom-style: solid;
    border-width: 1px;
`;

export const ListItem = styled(Row).attrs(() => ({
    className: "align-items-center justify-content-between w-100"
}))`
    padding: 1.2rem 0;
`;
export const WalletHistoryInfo = styled(Col).attrs(() => ({
    className: "d-flex align-items-center col-8"
}))``;
export const WalletHistoryEventWrapper = styled.div.attrs(() => ({
    className: "d-flex flex-column"
}))``;
export const Date = styled.div.attrs(() => ({
    className: "fz-sf-regular-caption-1 tw-text-system-labelLightSecondary"
}))`
`;
export const WalletEvent = styled.div.attrs(() => ({
    className: "fz-sf-regular-body-1 tw-text-system-labelLightPrimary"
}))`
`;
export const ExpectedAmountCent = styled(Date).attrs(() => ({
    className: "text-end"
}))`
    text-decoration: line-through;
`;
export const WalletHistoryAmountWrapper = styled(Col).attrs(() => ({
    className: "text-end"
}))`
    padding-right: .8rem;
`;

export const NoHistoryDescription = styled.div.attrs({
    className: "tw-text-subtitle-2 text-center tw-text-system-labelLightPrimary"
})`
`;
