import { rewardCoinWinning } from "helpers/IconHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import React from "react";
import { PriceImageWrapper } from "../RewardListStyle";


interface RewardCoinsProps {
    price: number,
    numberFontStyle?: string
}

const RewardCoins = ({ price, numberFontStyle }: RewardCoinsProps) => {
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();
    const fontStyle = "text-secondary-1 " + (numberFontStyle ?? "fz-sf-bold-body-1");

    return (
        <div className="d-flex align-items-center">
            <PriceImageWrapper>
                <img className="img-fluid" src={rewardCoinWinning} alt="coin" />
            </PriceImageWrapper>
            <div className={fontStyle}>{toWalletNumber(price)}</div>
        </div>
    );
};

export default RewardCoins;
