import { RubyWalletHistoryVo } from "data/vo/wallet/RubyWalletHistoryVo";
import { orderBy } from "lodash";
import { DESC } from "helpers/SortingHelper";
import { WalletHistoryVo } from "data/vo/wallet/WalletHistoryVo";

export class WalletHistoriesVo {
    public readonly coin: WalletHistoryVo[];
    public readonly winning: WalletHistoryVo[];
    public readonly ruby: RubyWalletHistoryVo;

    private constructor(coin: WalletHistoryVo[], winning: WalletHistoryVo[], ruby: RubyWalletHistoryVo) {
        this.coin = orderBy(coin, ["createTime", "historyId"], [DESC, DESC]);
        this.winning = orderBy(winning, ["createTime", "historyId"], [DESC, DESC]);
        this.ruby = ruby;
    }

    public static create({
                             coin,
                             winning,
                             ruby
                         }: {
        coin: WalletHistoryVo[],
        winning: WalletHistoryVo[],
        ruby: RubyWalletHistoryVo
    }): WalletHistoriesVo {
        return new WalletHistoriesVo(coin, winning, ruby);
    }

    public static empty() {
        return new WalletHistoriesVo([], [], RubyWalletHistoryVo.create(0, 0, [])
        );
    }
}
