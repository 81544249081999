import { emptyGameLogsImg } from "helpers/IconHelper";
import React from "react";
import { FormattedMessage } from "react-intl";

const NineCatGameLogEmpty = () => {
    return (
        <div className="tw-text-center" data-testid="empty-game-log">
            <p className="tw-text-body-2 tw-font-light tw-text-gray-500 tw-mb-2">
                <FormattedMessage id="player_info_popup_label_no_game_data" />
            </p>
            <img className="tw-w-[12.5rem]" src={emptyGameLogsImg} alt="No Game Logs" />
        </div>
    );
};

export default NineCatGameLogEmpty;
