import MdCloseButton from "components/buttons/MdCloseButton";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const HeaderWrapper: React.FC<React.HtmlHTMLAttributes<HTMLDivElement>> = styled.div.attrs({
    className: "fz-sf-bold-body-1 d-flex justify-content-between w-100"
})`
  padding: 2.4rem 1.6rem 1.2rem;
`;

interface NotStartingPanelHeaderProps {
    onHide: () => void
    notStartingPlayers: number
}

const NotStartingPanelHeader: React.FC<NotStartingPanelHeaderProps> = ({ onHide, notStartingPlayers }) => {
    return <HeaderWrapper data-testid="not-starting-panel-header">
        <FormattedMessage id="label_non_starters" values={{ number: notStartingPlayers }} />
        <MdCloseButton onClose={onHide} />
    </HeaderWrapper>;
};

export default NotStartingPanelHeader;
