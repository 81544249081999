import clsx from "clsx";
import { IconOmitProps } from "helpers/IconHelper";
import React, { ReactNode } from "react";
import { IconBaseProps } from "react-icons";

export const enum HINT_CARD_VARIANT {
    PRIMARY = "PRIMARY",
    PRIMARY_LIGHT = "PRIMARY_LIGHT",
    ERROR = "ERROR",
    ERROR_LIGHT = "ERROR_LIGHT",
    WARNING = "WARNING",
}

export interface HintCardProps {
    hintText: ReactNode;
    variant?: HINT_CARD_VARIANT;
    className?: string;
    prefixIcon?: React.FC<IconOmitProps> | React.FC<IconBaseProps>;
}


const variantMap: Record<HINT_CARD_VARIANT, {
    bg: string
    color: string
    iconColor: string
}> = {
    [HINT_CARD_VARIANT.PRIMARY]: {
        bg: "tw-bg-primary-900",
        color: "tw-text-white",
        iconColor: "tw-text-white",
    },
    [HINT_CARD_VARIANT.PRIMARY_LIGHT]: {
        bg: "tw-bg-primary-50",
        color: "tw-text-info-1000",
        iconColor: "tw-text-primary-300",
    },
    [HINT_CARD_VARIANT.ERROR]: {
        bg: "tw-bg-error-50",
        color: "tw-text-error-900",
        iconColor: "tw-text-error-300",
    },
    [HINT_CARD_VARIANT.ERROR_LIGHT]: {
        bg: "tw-bg-error-50",
        color: "tw-text-error-900",
        iconColor: "tw-text-error-300",
    },
    [HINT_CARD_VARIANT.WARNING]: {
        bg: "tw-bg-warning-50",
        color: "tw-text-warning-900",
        iconColor: "tw-text-warning-800",
    },
};

export const HintCard: React.FC<HintCardProps> = ({
                                                      hintText,
                                                      prefixIcon: PrefixIcon,
                                                      variant = HINT_CARD_VARIANT.PRIMARY,
                                                      className,
                                                  }) => {

    const { color, iconColor, bg } = variantMap[variant];

    return <div
        className={clsx("tw-flex tw-items-center tw-gap-[0.8rem] tw-py-[0.8rem] tw-px-[1.2rem] tw-rounded-[0.8rem]", bg, className)}>
        {PrefixIcon && <PrefixIcon className={clsx("tw-flex-shrink-0", iconColor)} />}
        <div className={clsx("tw-text-body-2", color)}>
            {hintText}
        </div>
    </div>;
};
