import { isMatchLive } from "helpers/match/MatchHelper";
import _ from "lodash";

export const squadAKey = "squadA";
export const squadBKey = "squadB";

export const getTotalExtra = ({
                                  extraRunWides = 0,
                                  extraRunNoBalls = 0,
                                  extraRunByes = 0,
                                  extraRunLegByes = 0
                              }) => {
    return extraRunWides + extraRunNoBalls + extraRunByes + extraRunLegByes;
};

export const isCurrentBatsman = (id, currentBatsmen) => currentBatsmen.includes(id);
export const isBatting = (id, currentBatsmen) => id === currentBatsmen[0];

export const getDefaultAccordionActiveKey = (matchStatus, scorecard) => {
    if (!isMatchLive(matchStatus)) return squadAKey;

    return scorecard.battingSquadCode === scorecard.squadB.code ? squadBKey : squadAKey;
};

export const getAccordionActiveKey = (selectedKey, accordionActiveKey) => {
    if (!_.isNull(selectedKey)) return selectedKey;

    // selectedKey will return "null" while accordion collapse
    return accordionActiveKey === squadAKey ? squadBKey : squadAKey;
};

