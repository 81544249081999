import { FirstTimePurchaseDto } from "apis/deposit/DepositApi";
import { SettingDto } from "apis/setting/SettingApi";
import { analyticsEvent, analyticsParameterKey, logCustomEvent } from "ga";
import { storageHelper } from "helpers/localStorage/LocalStorageHelper";
import { LOCAL_STORAGE_KEYS } from "helpers/localStorage/types";
import { ReactNode } from "react";
import {
    SET_FIRST_TIME_PURCHASE,
    UPDATE_COUPON_DATA,
    UPDATE_CURRENT_SPORT,
    UPDATE_FEATURE_CONFIG,
    UPDATE_LIVE_CHAT_VISIBILITY,
    UPDATE_SELECT_TEAM_PANEL_CONFIG,
    UPDATE_SELECT_TEAM_PANEL_DATA,
    UPDATE_SELECT_TEAM_PANEL_SHOW,
    UPDATE_SETTING,
    UPDATE_SITE_ID,
    UPDATE_TEAM_PREVIEW_CONFIG,
    UPDATE_TEAM_PREVIEW_DATA,
    UPDATE_TEAM_PREVIEW_SHOW,
    UPDATE_UNAUTHORIZED_PAGE,
    UPDATE_WALLET_DATA,
} from "store/Constants";
import { FeatureConfig } from "store/reducer/config/FantasyGameConfigState";
import { LiveChatVisibility } from "store/reducer/liveChat/LiveChatState";
import {
    SelectTeamPanelConfig,
    SelectTeamPanelDate,
    UpdateSelectTeamPanelConfigAction,
    UpdateSelectTeamPanelDataAction,
    UpdateSelectTeamPanelShowAction,
} from "store/reducer/selectTeamPanel/type";
import { TeamPreviewConfig, TeamPreviewData, } from "store/reducer/teamPreview/types";
import { EnrichedCoupons } from "types/coupon/Coupon";
import { SPORT } from "types/sports/Sport";
import { WalletVO } from "types/wallet/Wallet";

type StoreAction<T> = {
    type: string;
    payload: T;
};

// app
export function updateUnauthorizedPage(
    payload: string | ReactNode,
): StoreAction<string | ReactNode> {
    return { type: UPDATE_UNAUTHORIZED_PAGE, payload };
}

export const updateFeatureConfig = (payload: FeatureConfig) => ({
    type: UPDATE_FEATURE_CONFIG,
    payload
});


export const setFirstTimePurchase = (payload: FirstTimePurchaseDto | null) => ({
    type: SET_FIRST_TIME_PURCHASE,
    payload,
});

export const updateSiteId = (payload: number) => ({
    type: UPDATE_SITE_ID,
    payload,
});

// wallet
export const updateWallet = (payload: WalletVO) => ({
    type: UPDATE_WALLET_DATA,
    payload,
});

//coupon
export const updateCoupon = (payload: EnrichedCoupons) => ({
    type: UPDATE_COUPON_DATA,
    payload,
});

//live chat
export const updateLiveChatVisibility = (payload: LiveChatVisibility) => ({
    type: UPDATE_LIVE_CHAT_VISIBILITY,
    payload,
});

// settings

type updateSettingPayload = {
    status: boolean;
    setting: SettingDto;
};
export const updateSettings = (
    payload: updateSettingPayload,
): StoreAction<updateSettingPayload> => ({
    type: UPDATE_SETTING,
    payload,
});

export type SettingAction = ReturnType<typeof updateSettings>;

// team preview
export const updateTeamPreviewShow = (payload: boolean) => ({
    type: UPDATE_TEAM_PREVIEW_SHOW,
    payload,
});
export const updateTeamPreviewConfig = (
    payload: Partial<TeamPreviewConfig>,
) => ({
    type: UPDATE_TEAM_PREVIEW_CONFIG,
    payload,
});
export const updateTeamPreviewData = (payload: Partial<TeamPreviewData>) => ({
    type: UPDATE_TEAM_PREVIEW_DATA,
    payload,
});

// select team

export const updateSelectTeamPanelConfig = (
    payload: Partial<SelectTeamPanelConfig>,
): UpdateSelectTeamPanelConfigAction => ({
    type: UPDATE_SELECT_TEAM_PANEL_CONFIG,
    payload,
});

export const updateSelectTeamPanelData = (
    payload: Partial<SelectTeamPanelDate>,
): UpdateSelectTeamPanelDataAction => ({
    type: UPDATE_SELECT_TEAM_PANEL_DATA,
    payload,
});

export const updateSelectTeamPanelShow = (
    payload: boolean,
): UpdateSelectTeamPanelShowAction => ({
    type: UPDATE_SELECT_TEAM_PANEL_SHOW,
    payload,
});

export function updateCurrentSport(sportKey: SPORT) {
    logCustomEvent(analyticsEvent.webSportType, {
        [analyticsParameterKey.sport]: sportKey
    });
    window.scrollTo(0, 0);
    storageHelper(LOCAL_STORAGE_KEYS.SPORT_CATEGORY).put(sportKey);
    return { type: UPDATE_CURRENT_SPORT, payload: sportKey };
}
