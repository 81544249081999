import styled from "styled-components";
import { TransferContentWrapper } from "../TransferStyle";
import { APP_MAX_WIDTH, SYSTEM_COLORS } from "../../../../styles/Constants";
import { InfoButton } from "domain/ap/ActivityPointProgramStyle";
import { circleCloseIcon } from "helpers/IconHelper";

const deleteNumberIcon = "/images/number_delete_icon.png";

export const TransferAmountContentWrapper = styled(TransferContentWrapper)``;

export const TransferUserInfo = styled.div`
    margin: 0 auto 2.1rem;
`;

export const AvatarWrapper = styled.div.attrs({
    className: "d-flex justify-content-center"
})``;

export const UserName = styled.div.attrs({
    className: "fz-sf-bold-body-2 text-center tw-text-system-labelLightPrimary"
})`
`;
export const WinningWrapper = styled.div.attrs({
    className: "d-flex justify-content-center align-items-center flex-wrap fz-sf-regular-subhead text-center"
})`
    color: ${({ color }) => color};
`;
export const WinningText = styled.span.attrs({
    className: "fz-sf-bold-body-1 mx-1"
})``;

export const DisplayAmountWrapper = styled.div.attrs({
    className: "position-relative my-2"
})`
`;
export const DisplayAmount = styled.div.attrs({
    className: "fz-barlow-regular-title-lg text-center"
})`
    color: ${({ color }) => color || SYSTEM_COLORS.labelLightPrimary};
`;
export const ClearAmountButton = styled.div.attrs({
    className: "position-absolute"
})`
    top: 50%;
    right: 0;
    width: 2.4rem;
    padding-bottom: 2.4rem;
    background-image: url(${circleCloseIcon});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    transform: translateY(-50%);
    cursor: pointer;
`;
export const NumberButtonWrapper = styled.div.attrs({
    className: "d-flex flex-wrap"
})`
    padding: 0 3.5rem;
    @media (min-width: ${APP_MAX_WIDTH}) {
        padding: 0 8.3rem;
    }
`;

export const NumberButton = styled.div.attrs({
    className: "position-relative fz-sf-bold-title-2 text-center tw-text-system-labelLightPrimary"
})`
    width: 33.33333%;
    height: 6.4rem;
    line-height: 6.4rem;
    cursor: pointer;
`;
export const ZeroButton = styled(NumberButton)`
    margin-left: 33.33333%;
`;
export const DeleteNumberIcon = styled(ClearAmountButton)`
    top: 50%;
    left: 50%;
    right: auto;
    background-image: url(${deleteNumberIcon});
    transform: translate(-50%, -50%);
`;
export const APInfoButtonIcon = styled(InfoButton)``;
