import _ from "lodash";
import moment from "moment";
import { formatTo24HrTime } from "../../helpers/TimeHelper";

const oneMinute = 60 * 1_000;

export const isOnlyEnterPressed = e => {
    if (e.key !== "Enter") {
        return false;
    }
    if (e.shiftKey) {
        return false;
    }
    if (e.altKey) {
        return false;
    }
    if (e.ctrlKey) {
        return false;
    }
    if (e.metaKey) {
        return false;
    }

    return true;
};

function getMessageCreateTimestamp(message) {
    return message.created_at?.getTime
        ? message.created_at.getTime()
        : new Date(message.created_at).getTime();
}

export const getMessagesBeforeEntered = (messages, enteredTime) => messages.filter(message => {
    return getMessageCreateTimestamp(message) <= enteredTime;
});
export const getMessagesAfterEntered = (messages, enteredTime) => messages.filter(message => {
    return getMessageCreateTimestamp(message) > enteredTime;
});

export const collectMessageByUser = (messages) => {

    const _sortMessageByCreatedTime = (origin) => {
        const ary = [...origin];
        ary.sort((a, b) => {
            const aTime = moment(a.created_at);
            const bTime = moment(b.created_at);
            return aTime - bTime > 0 ? 1 : aTime - bTime < 0 ? -1 : 0;
        });

        return ary;
    };

    const _isMessageFromSameUser = (lastMessage, currentMessage) => {
        return lastMessage?.userId === currentMessage?.user.id;
    };

    const _isMessageCreatedInAMinute = (lastMessage, currentMessage) => {
        return Math.abs(moment(currentMessage.created_at).valueOf() - moment(lastMessage?.created_at).valueOf()) < oneMinute;
    };

    return _sortMessageByCreatedTime(messages).reduce((newMessages, currentMessage) => {
        const lastMessage = newMessages[newMessages.length - 1];

        if (_isMessageFromSameUser(lastMessage, currentMessage) && _isMessageCreatedInAMinute(lastMessage, currentMessage)) {
            lastMessage.htmls.push(currentMessage.html);
        } else {
            newMessages.push({
                id: currentMessage.id,
                created_at: currentMessage.created_at,
                userId: currentMessage.user.id,
                name: currentMessage.user.name,
                htmls: [currentMessage.html]
            });
        }

        return newMessages;
    }, []);
};

export const isTextEmpty = textContent => textContent.trim() === "";

export const isMyMessage = (message, myChatId) => message.userId === myChatId;

export const formatMessageTime = formatTo24HrTime;

export const hasData = (object) => !_.isEmpty(object);

export const getInputText = (e) => {
    let text = "";
    const selection = window.getSelection();
    selection.selectAllChildren(e);
    text = selection.toString();
    selection.deleteFromDocument();
    return text;
};

export const isScrollbarReachDividePoint = (container, innerHeight, scrollY) => {
    const dividePoint = 30;

    if (container) {
        const { height } = container.getBoundingClientRect();
        return innerHeight + scrollY > height - dividePoint;
    }

    return false;
};

export const isInvalidMessage = (message) => message.type === "error";

// getStream https://getstream.io/chat/docs/react/api_errors_response/
export const isNotAllowedError = error => error.code === 17 && error.status === 403;
