import React from "react";
import { SegmentedTab } from "components/SegmentedTab/SegmentedTab";

import { WALLET_TYPE } from "data/dto/wallet/walletHistoryDto";

interface WalletTabsProps {
    walletTabs: {
        [k in WALLET_TYPE]: {
            key: WALLET_TYPE,
            name: React.ReactNode
        }
    },
    activeTab: WALLET_TYPE,
    onChangeTab: (tab: WALLET_TYPE) => void,
}

export const WalletTabs: React.FC<WalletTabsProps> = ({ walletTabs, activeTab, onChangeTab }) => {

    return <div className="tw-px-3 tw-pt-2 tw-pb-4">
        <SegmentedTab
            currentTab={activeTab}
            tabs={Object.entries(walletTabs).map(([key, tab]) => ({
                key: tab.key,
                name: tab.name,
            }))}
            onChangeTab={onChangeTab}
        />
    </div>;
};
