import Portal from "components/portal/Portal";
import { componentTag } from "domain/match/pages/upcoming/MatchDetailFlow";
import { useStartedMatchDetailData } from "domain/match/providers/MyStartedMatchDataProvider";
import { useStartedMatchDetailFlow } from "domain/match/providers/MyStartedMatchFlowProvider";
import CreateTeamFlow from "domain/team/CreateTeamFlow";
import EditTeamFlow from "domain/team/EditTeamFlow";
import { useStopJoiningDialog } from "hooks/useDialog";
import React from "react";
import styled from "styled-components";

const CreateTeamWrapper = styled.div.attrs({
    className: "position-fixed start-0 top-0 bg-body-body-1",
    id: "create-team-wrapper"
})`
    width: 100%;
    height: 100%;
    max-width: 550px;
    z-index: 11;
    overflow: auto;

    .navbar-wrapper + .choose-player-container {
        height: 100%;
    }

    .navbar-wrapper + .choose-special-player-container {
        padding-top: 14.6rem;
        height: 100%;
    }
`;

const OperationTeamFlow = () => {
    const {
        currentFlow,
        closeFlow,
        sport,
        afterOperatedTeam,
        contest,
        beOperationTeam,
        onJoinContestFlowAfterCreateTeam
    } = useStartedMatchDetailFlow();
    const {
        match,
        myTeams,
    } = useStartedMatchDetailData();
    const stopJoiningDialog = useStopJoiningDialog();

    if (currentFlow === componentTag.CREATE) {
        return (
            <Portal>
                <CreateTeamWrapper>
                    <CreateTeamFlow sport={sport}
                                    match={match!}
                                    myTeams={myTeams!}
                                    onNavbarBackClick={closeFlow}
                                    onSuccess={contest ? onJoinContestFlowAfterCreateTeam : afterOperatedTeam}
                                    copyTeam={beOperationTeam}
                                    handleTimeout={stopJoiningDialog}
                                    scrollSpyRootSelector="#create-team-wrapper"
                    />
                </CreateTeamWrapper>
            </Portal>
        );
    }

    if (currentFlow === componentTag.EDIT && beOperationTeam) {
        return (
            <Portal>
                <CreateTeamWrapper>
                    <EditTeamFlow sport={sport}
                                  match={match!}
                                  myTeams={myTeams!}
                                  onNavbarBackClick={closeFlow}
                                  onSuccess={afterOperatedTeam}
                                  beEditedTeam={beOperationTeam}
                                  handleTimeout={stopJoiningDialog}
                                  scrollSpyRootSelector="#create-team-wrapper"
                    />
                </CreateTeamWrapper>
            </Portal>
        );
    }

    return null;
};

export default OperationTeamFlow;
