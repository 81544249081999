import { LearnMore, Logo, SystemMessageContainer, SystemMessageText, ThaiLandLine } from "domain/chat/ChatRoomStyle";
import { shortLegendLogo } from "helpers/IconHelper";
import SYSTEM_LINKS from "helpers/LinkHelper";
import useFantasyConfig from "hooks/useFantasyConfig";
import { useLocaleProvider } from "LocaleProvider";
import { LOCALE } from "locales";
import React from "react";
import { FormattedMessage } from "react-intl";

export const SystemAnnouncement = () => {
    const { locale } = useLocaleProvider();
    const { title } = useFantasyConfig();

    return <div>
        <SystemMessageContainer>
            <Logo src={shortLegendLogo} />
            <SystemMessageText>
                <FormattedMessage id="chat_room_page_label_system_announcement"
                                  values={{ appName: title }} />
                &nbsp;
                <LearnMore href={SYSTEM_LINKS.chatroomGuideline(locale)}
                           target="_blank">
                    &nbsp;<FormattedMessage id="chat_room_page_label_system_announcement_more" />
                </LearnMore>
            </SystemMessageText>
        </SystemMessageContainer>

        {
            locale === LOCALE.TH &&
            <SystemMessageContainer>
                <Logo src={shortLegendLogo} />
                <SystemMessageText>
                    ตอนนี้เรามีบัญชี LINE OA เพื่อแฟนเกม {title} ชาวไทยแล้ว
                    สามารถเพิ่มเราเป็นเพื่อนเพื่อใช้พูดคุยกันได้ที่ลิงค์นี้เลยครับ →&nbsp;
                    <ThaiLandLine href={SYSTEM_LINKS.chatroomThailandLineOfficialAccount()}
                                  target="_blank">
                        {SYSTEM_LINKS.chatroomThailandLineOfficialAccount()}
                    </ThaiLandLine>
                </SystemMessageText>
            </SystemMessageContainer>
        }
    </div>;
};
