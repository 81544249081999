import React from "react";
import { cn } from "helpers/cn";
import { Icon } from "helpers/IconHelper";
import PlayerAvatar from "components/image/PlayerAvatar";

interface AvatarProps {
    mvp: boolean | undefined;
    id: string;
    background: string;
    onClick: () => void;
    url: string;
}

export const Avatar: React.FC<AvatarProps> = ({ background, id, mvp, onClick, url }) =>
    <div className={cn(
        "tw-w-[8rem] tw-h-[9rem]",
        "tw-flex tw-items-center tw-justify-center",
    )}>
        {
            mvp &&
            <Icon.PlayerMvp className={cn("tw-absolute tw-top-0.25")} />
        }
        <div
            data-testid={`player_avatar_${id}`}
            className={cn(
                "tw-absolute tw-overflow-hidden tw-cursor-pointer",
                "tw-w-[7rem] tw-h-[7rem] tw-bg-white tw-rounded-full",
                background
            )}
            onClick={onClick}
        >
            <PlayerAvatar id={id} url={url} />
        </div>
    </div>;
