import styled from "styled-components";

export const MatchDetailFlowWrapper = styled.div.attrs(() => ({
    className: "w-100 mw-100"
}))`
  padding-bottom: 6rem;
`;

export const MatchDetailWrapper = styled.div`
  padding-top: 10.4rem;
`;
