import styled from "styled-components";

export const MainWrapper = styled.div.attrs(() => ({
    className: "main-wrapper position-relative h-100 d-flex flex-column"
}))`
`;

export const PageWrapper = styled.div.attrs(() => ({
    className: "page-wrapper mw-100 bg-body-body-1"
}))`
  height: 100%;
  min-height: 100%;


  // if children is UserInfo
  &:has(.user-info-wrapper) {
    display: flex;
    flex-direction: column;
  }

`;
