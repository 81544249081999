import styled, { css } from "styled-components";

export const StyledGradientCircleProgressbarWrapper = styled.div.attrs<{ $size: number }>({
    className: "position-relative",
})`
  ${({ $size }) => {
    return css`
      width: ${$size}px;
      height: ${$size}px;
    `;
}}
  border-radius: 100%;
`;

export const StyledGradientCircleProgressbarContent = styled.div.attrs({
    className:
        "position-absolute w-100 h-100 d-flex align-items-center justify-content-center",
})`
  top: 0;
  left: 0;
`;

export const StyledGradientCircleProgressbar = styled.circle`
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;
