import { chain } from "lodash";
import React, { useMemo } from "react";
import MyEmptyNineCat from "../../../../components/empty/MyEmptyNineCat";
import { MyNineCatSkeleton } from "../../../../components/skeleton/MyNineCatSkeleton";
import { MatchComponentStatusEnum } from "../../../../data/vo/match/MyMatchVo";
import { MyNineCatVo } from "../../../../data/vo/nineCat/MyNineCatVo";
import MyMatchNIneCatCards from "../../../slate/components/MyMatchNIneCatCards";

interface MyMatchFantasySportsProps {
    myNineCatSlates?: MyNineCatVo[],
    isLoading: boolean
}

const MyMatchNineCat: React.FC<MyMatchFantasySportsProps> = ({
                                                                 myNineCatSlates,
                                                                 isLoading
                                                             }) => {

    const {
        live,
        upcoming,
        completed
    } = useMemo(() => {
        if (!myNineCatSlates) {
            return {
                live: [],
                upcoming: [],
                completed: []
            };
        }

        const data = chain(myNineCatSlates)
            .groupBy('group')
            .value();

        return {
            live: data[MatchComponentStatusEnum.LIVE] || [],
            upcoming: data[MatchComponentStatusEnum.UPCOMING] || [],
            completed: data[MatchComponentStatusEnum.COMPLETED] || []
        };

    }, [myNineCatSlates]);

    if (isLoading) {
        return <MyNineCatSkeleton />;
    }

    if (!myNineCatSlates?.length) {
        return <MyEmptyNineCat />;
    }

    return <>
        <MyMatchNIneCatCards status={MatchComponentStatusEnum.LIVE} dataSource={live} />
        <MyMatchNIneCatCards status={MatchComponentStatusEnum.UPCOMING} dataSource={upcoming} />
        <MyMatchNIneCatCards status={MatchComponentStatusEnum.COMPLETED} dataSource={completed} />
    </>;

};

export default MyMatchNineCat;
