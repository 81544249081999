import { FormMessage, useFormField } from "components/ui/form";
import { Icon } from "helpers/IconHelper";
import React from "react";

interface FormErrorMessageProps {
    showIcon?: boolean;
    children?: React.ReactNode
}

export const FormErrorMessage: React.FC<FormErrorMessageProps> = ({ showIcon = true, children }) => {
    const { error } = useFormField();
    if (!error?.message && !error?.root?.message) return null;

    return (
        <div className="tw-flex tw-gap-2 tw-items-center tw-pt-1">
            {
                showIcon && <Icon.FormMessageError className="tw-text-error-700 tw-flex-shrink-0" />
            }
            <FormMessage className="tw-text-error-700 tw-text-caption-1 tw-font-normal text-start"
                         children={children} />
        </div>
    );
};
