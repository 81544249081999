import { Form } from "react-bootstrap";
import styled, { keyframes } from "styled-components";
import { PrimaryButton } from "styles/component/ButtonsStyle";
import { APP_MAX_WIDTH, SYSTEM_COLORS } from "styles/Constants";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const EditMeWrapper = styled.div.attrs(() => ({}))``;

export const NavbarCenter = styled.div.attrs(() => ({
    className:
        "h-100 d-flex justify-content-center align-items-center fz-sf-bold-title-3 text-text-primary-1",
}))``;

export const EditMeContainer = styled.div.attrs<{
    $height: number
}>({
    className: "bg-sys-gray-1 position-relative",
})`
  padding: 2.4rem 2rem;
  border-radius: 1.6rem 1.6rem 0 0;
  height: calc(100vh - ${({ $height }) => ($height > 0 ? $height : 64)}px);
`;

export const FormContainer = styled.div.attrs(() => ({
    className: "position-relative",
}))`
  margin-bottom: 2.6rem;
`;

export const StyledFormLabel = styled(Form.Label).attrs<{
    $errorMessage?: string,
    $color?: string
}>(({ $errorMessage }) => ({
    className: `fz-sf-regular-subhead ${
        $errorMessage ? "text-danger" : "text-light-secondary"
    }`,
}))`
  color: ${({ color }) => color || SYSTEM_COLORS.labelLightSecondary};
`;

export const StyledTitleFormTextarea = styled.textarea.attrs({
    className: "fz-sf-regular-body-1 text-label-light-primary tw-border-system-primary80",
})`
  border-width: 0 0 2px 0;
  border-style: solid;
  width: 100%;
  padding: 0.8rem;
  padding-left: 0;
  resize: none;
  background-color: transparent;

  &:disabled {
    border-bottom: 1px solid ${SYSTEM_COLORS.labelLightSecondary};
  }

  &::placeholder {
    color: ${SYSTEM_COLORS.labelLightSecondary};
  }

  &:focus {
    outline: none;
  }
`;

export const TextareaContainer = styled(FormContainer).attrs({})`
  margin-bottom: 0;
`;

export const StyledFormInput = styled(Form.Control).attrs(() => ({
    className: "fz-sf-regular-body-1 tw-text-system-labelLightTertiary tw-border-system-labelLightQuaternary",
}))`
  border-width: 0 0 1px 0;
  border-style: solid;
  background-color: transparent;
  padding-left: 0;

  &:disabled {
    background-color: transparent;
  }

  &::placeholder {
    color: ${SYSTEM_COLORS.labelLightSecondary};
  }
`;
export const MaxTextCount = styled.div.attrs({
    className: "text-end fz-sf-regular-footnote tw-text-system-labelLightSecondary",
})`
`;

export const UsernameFormInput = styled(Form.Control).attrs(
    ({ disabled, $errorMessage }) => ({
        className: `fz-sf-regular-body-1 ${
            disabled ? "text-light-tertiary" : "text-light-secondary"
        } ${$errorMessage ? "border-sys-danger" : "border-primary-primary"}`,
    }),
)`
  border-bottom-width: 2px;
  border-bottom-style: solid;
  background-color: transparent;
  padding-left: 0;

  &:disabled {
    background-color: transparent;
    border-bottom-width: 1px;
  }

  &::placeholder {
    color: ${SYSTEM_COLORS.labelLightSecondary};
  }
`;

export const InputErrorMessage = styled.div.attrs({
    className: "fz-sf-regular-footnote text-danger",
})`
  padding-left: 0.4rem;
`;

export const PasswordContainer = styled.div.attrs({
    className: "d-flex",
})``;

export const StyledPassword = styled(StyledFormInput).attrs(() => ({
    className: "border-light-tertiary",
}))`
  color: ${({ color }) => color};
  border-bottom-width: 1px;
  border-bottom-style: solid;
`;

export const ChangePasswordWrapper = styled.div.attrs(() => ({
    className:
        "position-fixed w-100 h-100 d-flex justify-content-center align-items-center",
}))`
  top: 0;
  left: 0;
  max-width: ${APP_MAX_WIDTH};
  z-index: 10;
  overflow-y: auto;
  animation: ${fadeIn} 0.15s linear;
`;
export const ChangePasswordOverlay = styled.div.attrs(() => ({
    className: "position-absolute w-100 h-100",
}))`
  background-color: rgba(0, 0, 0, 0.8);
  z-index: -1;
`;
export const ChangePasswordContainer = styled.div.attrs(() => ({}))`
  width: 88%;
  padding: 2rem;
  box-shadow: 0.2rem 0.4rem 0.8rem rgba(0, 0, 0, 0.35);
  border-radius: 1.6rem;
  background-color: #fff;
`;
export const ChangePasswordTitle = styled.div.attrs(() => ({
    className: "fz-sf-bold-title-3 text-center tw-text-system-labelLightPrimary",
}))`
  margin-bottom: 1.2rem;
`;
export const ChangePasswordContent = styled.div.attrs(() => ({
    className: " text-center",
}))`
  margin-bottom: 3rem;
`;
export const ChangePasswordInput = styled(Form.Control).attrs(() => ({
    className: "fz-sf-regular-subhead border-0 tw-text-system-labelLightSecondary",
}))`
  margin-bottom: 1rem;

  &:disabled {
    background-color: transparent;
  }

  &:focus {
    box-shadow: 0 0 0 transparent;
  }
`;
export const ChangePasswordDivider = styled.div.attrs(() => ({
    className: "w-100 tw-bg-system-labelLightSecondary",
}))`
  height: 1px;
  margin-bottom: 5rem;
`;

export const ChangePasswordButton = styled(PrimaryButton).attrs(() => ({
    className: "m-auto fz-sf-regular-title-3",
}))`
  width: 50%;
`;

export const MyProfileIdWrapper = styled.div.attrs({
    className:
        "d-flex justify-content-between align-items-center border-light-tertiary",
})`
  border-bottom-width: 1px;
  border-bottom-style: solid;
`;
export const MyProfileId = styled.div.attrs(() => ({
    className: "fz-sf-regular-body-1 tw-text-system-labelLightPrimary",
}))`
  padding: 0.55rem 0;
`;

