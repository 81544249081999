import { orderBy } from "lodash";
import { DESC } from "helpers/SortingHelper";
import { WalletHistoryVo } from "data/vo/wallet/WalletHistoryVo";

export class RubyWalletHistoryVo {
    public expiringTime: number;
    public expiringRubyCent: number;
    public histories: WalletHistoryVo[];

    private constructor(expiringTime: number, expiringRubyCent: number, histories: WalletHistoryVo[]) {
        this.expiringTime = expiringTime;
        this.expiringRubyCent = expiringRubyCent;
        this.histories = orderBy(histories, ["createTime", "historyId"], [DESC, DESC]);
    }

    public static create(
        expiringTime: number,
        expiringRubyCent: number,
        histories: WalletHistoryVo[]
    ): RubyWalletHistoryVo {
        return new RubyWalletHistoryVo(expiringTime, expiringRubyCent, histories);
    }
}
