import clsx from "clsx";
import {
    AvatarImage,
    AvatarWithFrameWrapper,
    AvatarWrapper,
    FirstLetterIcon
} from "components/avatar/AvatarWithFrameStyle";
import { apLevelConfig } from "domain/ap/ActivityPointHelper";
import { power11Avatar } from "helpers/IconHelper";
import { getFirstLetterAndToUppercase } from "helpers/string/StringHelper";
import useUserIdentity from "hooks/useUserIdentity";
import React, { useEffect, useState } from "react";
import { AP_LEVEL } from "types/activityPoint/ApLevel";

interface UserAvatarWithFrameProps {
    url?: string;
    name: string;
    font?: string;
    active?: boolean;
    size?: string;
    currentLevel?: AP_LEVEL;
    hasFrame?: boolean;
}

type B2CUserAvatarProps = Omit<UserAvatarWithFrameProps, "size" | "currentLevel">;

const B2CUserAvatar: React.FC<B2CUserAvatarProps> = ({
                                                         url,
                                                         name,
                                                         font,
                                                         active
                                                     }) => {
    const [showImage, setShowImage] = useState(!!url);

    useEffect(() => {
        setShowImage(!!url);
    }, [url]);


    return showImage
        ? <AvatarImage src={url ?? ""}
                       alt={name}
                       onError={() => setShowImage(false)} />
        : <FirstLetterIcon
            className={clsx(font || "fz-sf-bold-body-1", {
                "tw-bg-primary-600": active,
                "tw-bg-primary-300": !active
            })}>
            {getFirstLetterAndToUppercase(name)}
        </FirstLetterIcon>;
};

const UserAvatarWithFrame: React.FC<UserAvatarWithFrameProps> = (props) => {
    const {
        name,
        size,
        currentLevel,
        hasFrame = true
    } = props;

    const { isB2B } = useUserIdentity();
    const { proportion, frame } = (currentLevel && apLevelConfig[currentLevel]) ?? apLevelConfig[AP_LEVEL.BEGINNER];

    return <AvatarWithFrameWrapper $size={size}>
        <AvatarWrapper $proportion={proportion}>
            {

                isB2B
                    ? <AvatarImage src={power11Avatar} alt={name} />
                    : <B2CUserAvatar {...props} />
            }
        </AvatarWrapper>
        {hasFrame && frame}
    </AvatarWithFrameWrapper>;
};

export default UserAvatarWithFrame;
