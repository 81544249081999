import { ChipFor, ChipStyle } from "components/chip/type";
import { cn } from "helpers/cn";
import FantasyIcon from "helpers/FantasyIcon";
import React, { RefAttributes } from "react";
import { IconBaseProps } from "react-icons";

const IconWrapper: React.FC<{ children: React.ReactNode }> = ({ children, }) => {
    return <div className={cn("tw-p-[.5rem] tw-rounded-full icon-wrapper")}>
        {children}
    </div>;
};

interface ChipProps {
    for: ChipFor,
    style: ChipStyle,
    children: React.ReactNode,
    prefixIcon?: typeof FantasyIcon | React.FC<IconBaseProps>,
    suffixIcon?: typeof FantasyIcon | React.FC<IconBaseProps>,
    disabled?: boolean,
    onClick?: () => void,
    className?: string,
}

const Chip: React.ForwardRefExoticComponent<ChipProps & RefAttributes<HTMLDivElement>> = React.forwardRef(({
                                                                                                               for: chipFor,
                                                                                                               style: chipStyle,
                                                                                                               prefixIcon,
                                                                                                               suffixIcon,
                                                                                                               disabled,
                                                                                                               onClick,
                                                                                                               children,
                                                                                                               className
                                                                                                           }, ref) => {

    const LeftIcon = prefixIcon as typeof FantasyIcon;
    const RightIcon = suffixIcon as typeof FantasyIcon;

    return (
        <div ref={ref}
             onClick={onClick}
             className={cn("tw-chip", className,
                 disabled ? "disabled" : "tw-cursor-pointer",
                 {
                     "tw-px-2 tw-py-[0.5rem]": !prefixIcon && !suffixIcon,
                     "tw-py-0.5": prefixIcon || suffixIcon,
                     "tw-px-0.5": prefixIcon && suffixIcon,
                     "tw-pl-0.5 tw-pr-2": prefixIcon && !suffixIcon,
                     "tw-pl-2 tw-pr-0.5": !prefixIcon && suffixIcon,
                     "on-dark": chipFor === ChipFor.ON_DARK,
                     "on-light": chipFor === ChipFor.ON_LIGHT,
                     "chip-primary": chipStyle === ChipStyle.PRIMARY,
                     "chip-error": chipStyle === ChipStyle.ERROR,
                 }
             )}>
            {
                prefixIcon && <IconWrapper>
                    <LeftIcon size="1.4rem" />
                </IconWrapper>
            }
            {children}
            {
                suffixIcon && <IconWrapper>
                    <RightIcon size="1.4rem" />
                </IconWrapper>
            }
        </div>
    );
});

export default Chip;
