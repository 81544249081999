import { PlayerRepository } from "data/repository/player/PlayerRepository";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import { MatchVO } from "types/match/Match";
import { SquadPlayer } from "types/player/Player";

const usePlayerInfo = (currentPlayer: SquadPlayer | undefined, match: MatchVO) => {
    const repository = new PlayerRepository();
    return useFantasyQuery([QUERY_KEY.PLAY_INFO, currentPlayer?.sport, currentPlayer?.id],
        () => repository.getPlayerInfo(currentPlayer!.sport, match.matchId, currentPlayer!.id),
        {
            options: {
                enabled: !!currentPlayer,
            }
        });
};

export default usePlayerInfo;
