import { SUPPLIER } from "components/supplierLogo/type";
import { geniusSportsLogoImg, rotoBallerImg, sportsDataIoLogoImg } from "helpers/IconHelper";

export const SupplierInfo = {
    [SUPPLIER.SPORTS_DATA_IO]: {
        name: "SportsDataIo",
        link: "https://sportsdata.io",
        logo: sportsDataIoLogoImg,
    },
    [SUPPLIER.ROTO_BALLER]: {
        name: "RotoBaller",
        link: "https://www.rotoballer.com/",
        logo: rotoBallerImg,
    },
    [SUPPLIER.GENIUS_SPORTS]: {
        name: "Genius Sports",
        link: null,
        logo: geniusSportsLogoImg,
    },
};

export const TournamentToSupplier = {
    "NBA": SUPPLIER.SPORTS_DATA_IO,
    "MPBL": SUPPLIER.GENIUS_SPORTS,
};
