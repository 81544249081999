import styled from "styled-components";

export const CouponWrapper = styled.div.attrs({
    className: "d-flex flex-column"
})`
    padding: 1.6rem;
    gap: 1.2rem;
`;
export const CouponCard = styled.div.attrs({
    className: "border-light-primary fantasy-card"
})`
    border: 1px solid;
`;
export const CoveredCouponCard = styled(CouponCard)`
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: .4;
        background-color: #fff;
        z-index: 1;
        pointer-events: none;
    }

    .badge.expired-coupon-badge {
        z-index: 2;
    }
`;

export const CouponCardBody = styled.div.attrs({
    className: "d-flex fantasy-card-body"
})`
    gap: 1.6rem;
`;
export const CouponIcon = styled.div.attrs({
    className: "text-text-primary-2"
})`
    display: flex;
    padding: 1.4rem;
    width: 6rem;
    height: 6rem;
`;
export const CouponInfo = styled.div.attrs({
    className: "d-flex flex-column"
})`
    gap: .4rem;
`;
export const CouponCardFooter = styled.div.attrs({
    className: "fantasy-card-footer fz-sf-regular-footnote"
})`
`;

export const InfoIcon = styled.img.attrs({
    className: "clickable"
})`
    padding: .2rem;
    width: 2.4rem;
    height: 2.4rem;
`;

export const CouponDialogContent = styled.ol.attrs({
    className: "text-start mb-0"
})`
`;

export const EmptyCouponImageWrapper = styled.div.attrs({
    className: "mx-auto"
})`
    width: 67.5%;

    img {
        width: 100%;
        height: 100%;
    }
`;