import { TASK_TYPE } from "types/task/TaskType";
import { FormattedMessage } from "react-intl";
import React from "react";
import { TransactionDetailVo } from "data/vo/wallet/TransactionDetailVo";

export const ClaimTaskNote = ({ taskType }: TransactionDetailVo) => {

    if (taskType === TASK_TYPE.WATCH_AD) {
        return <FormattedMessage id="daily_task_item_title_watch_ad" />;
    }

    return <></>;
};
