import { FantasyCheckbox } from "components/checkbox/FantasyCheckbox";
import { CHECKBOX_VARIANTS } from "components/checkbox/types";
import FantasyBasicPopup, { POPUP_SIZE } from "components/popup/FantasyBasicPopup";
import { BUTTON_PATTERN, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { KycCompleteYourKYCPopup } from "domain/popup/kyc/KycCompleteYourKYCPopup";
import { kycUnsuccessful } from "helpers/IconHelper";
import useMyProfile from "hooks/useMyProfile";
import React from "react";
import { useIntl } from "react-intl";
import { useKycPopup } from "./KycPopupProvider";

export const KycUnsuccessfulPopup = () => {
    const intl = useIntl();
    const context = useKycPopup();
    const { myProfile } = useMyProfile();


    if (myProfile.isReachKycCompleteDeadline) {
        return <KycCompleteYourKYCPopup
            show={context.isShowUnsuccessful}
            onConfirm={() => {
                context.handleCloseUnsuccessful();
                context.goToKycForm();
            }}
            onCancel={context.handleCloseUnsuccessful}
        />;
    }

    return <FantasyBasicPopup
        show={context.isShowUnsuccessful}
        onHide={context.handleCloseUnsuccessful}
        popupSize={POPUP_SIZE.LG}
        imageSection={<img src={kycUnsuccessful} alt="kyc-unsuccessful" className="tw-max-w-[8.8rem]" />}
        title={{
            title: intl.formatMessage({ id: "label_verification_unsuccessful" }),
            titleClassName: "tw-text-error-900",
        }}
        texts={[
            {
                text: intl.formatMessage({ id: "kyc_verification_unsuccessful_content_1" }),
                textClassName: "tw-text-left tw-px-[1.2rem] tw-text-subtitle-1",
            },
            {
                text: intl.formatMessage({ id: "kyc_verification_unsuccessful_content_2" }),
                textClassName: "tw-text-left tw-px-[1.2rem] tw-text-subtitle-1",
            },
            {
                text: intl.formatMessage({ id: "kyc_verification_unsuccessful_content_note" }),
                textClassName: "tw-text-left tw-px-[1.2rem] tw-text-subtitle-1",
            },
        ]}
        buttonSetting={{
            confirmButtonVariant: NEW_BUTTON_VARIANTS.primaryDark,
            cancelButtonVariant: NEW_BUTTON_VARIANTS.text,
            buttonPattern: BUTTON_PATTERN.rounded,
            confirmButtonText: intl.formatMessage({ id: "kyc_verification_unsuccessful_resubmit" }),
            cancelButtonText: intl.formatMessage({ id: "kyc_do_it_later" }),
            onConfirm: () => {
                context.handleCloseUnsuccessful();
                context.goToKycForm();
            },
            onCancel: context.handleCloseUnsuccessful,
        }}
        bottomSection={<div className="tw-flex tw-text-center tw-items-center">
            <FantasyCheckbox variant={CHECKBOX_VARIANTS.white}
                             checked={context.isDoNotShowAgainChecked}
                             text={intl.formatMessage({ id: "kyc_do_not_show_again" })}
                             onClick={() => context.setIsDoNotShowAgainChecked(prev => !prev)} />
        </div>}
    />;
};
