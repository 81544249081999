import { closeIconPrimary } from "helpers/IconHelper";
import { Container } from "react-bootstrap";
import styled from "styled-components";

export const PromotionalCodeWrapper = styled.div.attrs(() => ({
    className: "position-fixed"
}))`
    left: 0;
    bottom: 5rem;
    z-index: 2;
    background: linear-gradient(55deg, rgba(255, 0, 0, 0.55) 7.94%, rgba(255, 0, 0, 0) 46.75%);
    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.05)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
    transform: translateX(-2.2rem);
`;

export const PromotionalCode = styled.img.attrs(() => ({
    className: "clickable"
}))`
`;
export const ClosePromotionalButton = styled.img.attrs({
    className: "position-absolute top-0 start-10 translate-middle clickable"
})`
    right: -33%;
    width: 2.4rem;
    height: 2.4rem;
`;

export const PromotionalToggle = styled.div.attrs({
    className: "position-fixed clickable bg-sys-danger text-text-primary-2 d-flex align-items-center justify-content-center"
})`
    width: 3rem;
    height: 3rem;
    bottom: 12rem;
    border-radius: 0 10rem 10rem 0;
    z-index: 2;
`;


export const PromotionalPanelContainer = styled(Container).attrs(() => ({
    className: "overflow-auto"
}))`
    padding: 0 1.6rem 1.6rem;
`;

export const PromotionalPanelHeader = styled.div.attrs(() => ({
    className: "w-100 d-flex align-items-center justify-content-between tw-bg-system-bgLightPrimary"
}))`
    padding: 2.4rem 1.6rem 0;
    z-index: 1;
`;

export const PromotionalPanelContent = styled.div.attrs({
    className: "fz-sf-regular-subhead text-light-primary mt-2 mb-4"
})`
`;

export const PromotionalPanelTitle = styled.div.attrs(() => ({
    className: "fz-sf-bold-title-1 d-flex tw-text-primary-700"
}))``;

export const PromotionalPanelCloseButton = styled.div.attrs(() => ({
    className: "ms-auto"
}))`
    background: url(${closeIconPrimary}) center no-repeat;
    width: 2.4rem;
    padding-bottom: 2.4rem;
    background-size: 100% auto;
    cursor: pointer;
`;
