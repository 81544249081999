import styled from "styled-components";

export const RubyContestChampionDialogImage = styled.img.attrs({
    className: "tw-max-w-[200px] tw-max-h-[200px] tw-m-auto tw-p-1.5"
})` `;

export const RubyContestChampionDialogContentWrapper = styled.div.attrs({
    className: ""
})`
  padding: 1.2rem 2.4rem;
`;

export const RubyContestChampionDialogTitle = styled.p.attrs({
    className: "fz-sf-bold-title-3 text-center"
})`
  margin-bottom: 1.2rem;
`;

export const RubyContestChampionDialogContent = styled.p.attrs({
    className: "fz-sf-regular-body-2 text-center m-0"
})`
`;

export const RubyContestChampionDialogButtonWrapper = styled.div.attrs({
    className: "d-flex flex-column gap-3"
})`
  padding: 2.4rem;
`;
