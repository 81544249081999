import { Callback } from "Common";
import { Icon } from "helpers/IconHelper";
import React from "react";
import { FormattedMessage } from "react-intl";
import { cn } from "../../../helpers/cn";
import { CONTEST_CARD_VARIANT, CONTEST_ENTRY_TYPE, NineCatContestVo } from "../data/ComponentTypes";
import { JoinedTeam } from "../data/types";
import { ContestEntry, getContestEntryType } from "../helpers/helpers";

import { ContestEntryFeeText } from "./contest/ContestEntryFeeText";
import { AP_LEVEL_VARIANT, ContestTitle } from "./contest/ContestTitle";
import { Participants } from "./contest/Participants";
import { ContestDetailInfos } from "./ContestDetailInfos";

export interface ContestCardItemProps extends NineCatContestVo {
    isJoinable: boolean;
    onOpenPrizeDistribution: Callback<[NineCatContestVo['code']]>;
    onJoinContest: Callback<[NineCatContestVo]>;
}

export const CONTEST_CARD_STYLE_MAP = {
    entryFee: {
        [CONTEST_ENTRY_TYPE.COIN]: "tw-from-secondary-300 tw-to-primary-400",
        [CONTEST_ENTRY_TYPE.RUBY]: "tw-from-primary-400 tw-to-secondary-300",
        [CONTEST_ENTRY_TYPE.FREE]: "tw-from-primary-50 tw-to-primary-50",
    },
    entryFeeInner: {
        [CONTEST_ENTRY_TYPE.COIN]: "tw-bg-primary-800 tw-text-white",
        [CONTEST_ENTRY_TYPE.RUBY]: "tw-bg-secondary-700 tw-text-white",
        [CONTEST_ENTRY_TYPE.FREE]: "tw-bg-primary-50 tw-text-black tw-border-none",
    }
};

const JoinedTeams = ({ hasJoinedTeams, joinedTeams }: { hasJoinedTeams: boolean, joinedTeams: JoinedTeam[] }) => {
    if (!hasJoinedTeams) return null;

    return <div>
        {(
            <div className={
                cn("tw-py-1.5 tw-text-warning-100 tw-bg-black/[0.3]", "tw-text-caption-2 tw-text-center")
            }>
                <FormattedMessage id={"contest_card_label_you_joined_with"} />
                <span className={"tw-font-bold"}>{`${joinedTeams.map(team => team.name).join(", ")}`} </span>
            </div>
        )}
    </div>;
};

const ContestCardItem: React.FC<ContestCardItemProps> = (props) => {
    const {
        code,
        entryFee,
        entryFeeType,
        firstPrize,
        entryLimit,
        spots,
        allowDiscountWithRuby,
        minApLevel,
        myJoinedTeams,
        slipCount,
        type,
        onOpenPrizeDistribution,
    } = props;

    const hasJoinedTeams = myJoinedTeams?.length > 0;
    const contestEntry = new ContestEntry(entryFeeType, type);

    const handleOpenPrizeDistribution = (e) => {
        e.stopPropagation();
        onOpenPrizeDistribution(code);
    };

    return (
        <div
            data-testid={"nine-cat-contest-card"}
            className={cn(
                "tw-flex-shrink-0",
                "tw-rounded-[1.6rem] tw-overflow-clip tw-backdrop-blur-md",
                "tw-border-[1px] tw-border-solid",
                "tw-cursor-pointer tw-select-none",
                hasJoinedTeams ? "tw-border-[#FFEABF85]" : "tw-border-[#FFFFFF9E]",
                hasJoinedTeams ?
                    "tw-bg-gradient-to-r tw-from-primary-800/[0.8] tw-to-primary-800/[0.62]" :
                    "tw-bg-gradient-to-br tw-from-white/[0.88] tw-to-white/[0.72]",
                hasJoinedTeams ? "tw-text-white" : "",
            )}
            onClick={() => props.onJoinContest(props)}
        >
            <div className={"tw-py-3 tw-px-4"}>
                <div className={
                    cn("tw-flex tw-items-center tw-justify-between")
                }>
                    <div className={
                        cn("tw-flex tw-items-center tw-gap-2")
                    }>
                        <div
                            className={
                                cn("tw-w-12 tw-h-12 tw-rounded-full tw-bg-primary-800",
                                    "tw-flex tw-items-center tw-justify-center",
                                    "tw-bg-gradient-to-br tw-from-0% tw-to-45%",
                                    CONTEST_CARD_STYLE_MAP.entryFee[getContestEntryType(contestEntry)],
                                )}
                        >
                            <div
                                className={
                                    cn("tw-w-[4.2rem] tw-h-[4.2rem] tw-rounded-full",
                                        "tw-flex tw-items-center tw-justify-center",
                                        "tw-text-white tw-text-h6 tw-font-bold",
                                        CONTEST_CARD_STYLE_MAP.entryFeeInner[getContestEntryType(contestEntry)]
                                    )}
                            >
                                <ContestEntryFeeText
                                    contestEntry={contestEntry}
                                    entryFee={entryFee} />
                            </div>
                        </div>
                        <div>
                            <ContestTitle
                                variant={CONTEST_CARD_VARIANT.JoinContest}
                                apLevelVariant={hasJoinedTeams ? AP_LEVEL_VARIANT.OUTLINED : AP_LEVEL_VARIANT.FILLED}
                                entryFee={entryFee}
                                entryFeeType={entryFeeType}
                                contestType={props.type}
                                minApLevel={minApLevel} />
                            <Participants
                                styleWhenSpotsFull={hasJoinedTeams ? "tw-text-warning-500" : "tw-text-warning-1000"}
                                participants={slipCount}
                                spots={spots} />
                        </div>
                    </div>
                    <button className={
                        cn("tw-flex tw-items-center tw-gap-1",
                            "tw-px-3 tw-py-1.5",
                            "tw-rounded-full",
                            hasJoinedTeams ? "tw-bg-primary-800" : "tw-bg-white"
                        )
                    } onClick={handleOpenPrizeDistribution}>
                        <Icon.BarChart />
                        <p className={
                            "tw-text-caption-2 tw-font-light"
                        }>
                            <FormattedMessage id={"label_prizes_and_distribution"} />
                        </p>
                    </button>
                </div>
                <ContestDetailInfos
                    isJoinable={props.isJoinable}
                    firstPrize={firstPrize}
                    hasJoinedTeams={hasJoinedTeams}
                    entryLimit={entryLimit}
                    spots={spots}
                    rubyEligible={allowDiscountWithRuby} />
            </div>
            <JoinedTeams hasJoinedTeams={hasJoinedTeams}
                         joinedTeams={myJoinedTeams} />
        </div>
    );
};

export default ContestCardItem;
