import { useState } from "react";

export function useStep(defaultStep: number) {
    const [step, setStep] = useState<number>(defaultStep);

    function handleStepNext() {
        setStep(_step => _step + 1);
    }

    function handleStepBack() {
        setStep(_step => _step - 1);
    }

    return { step, setStep, handleStepNext, handleStepBack };
}
