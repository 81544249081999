import AddCoinFlow from "domain/coin/AddCoinFlow";
import { PAYMENT_STATUS } from "domain/coin/AddCoinHelper";
import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";

const AddCoinContainer = () => {
    const { status } = useParams();

    return Object.keys(PAYMENT_STATUS).includes(status.toUpperCase())
        ? <AddCoinFlow status={status} />
        : <Redirect to={ROUTER_PATH_CONFIG.home} />;
};

export default AddCoinContainer;
