import { useIntl } from "react-intl";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { PrizeDistributionCalculatorRepo } from "data/repository/contest/PrizeDistributionCalculatorRepo";
import { PrizeListVo } from "data/vo/PrizeList/PrizrListVo";

export const ENTRIES_FIELD_NAME = "teams";
const FETCH_WAIT = 500;

function getTeamsInputDefaultValue(minSlip: number, spots: number) {
    return Math.max(Math.floor(spots / 2), minSlip);
}

export function usePrizeDistributionCalculator(minSlips: number, spots: number, contestId: number) {
    const { errMessage, form, entries, inputError } = useEntriesForm(minSlips, spots);

    const loadingRef = useRef<boolean>(false);
    const [prizeList, setPrizeList] = useState<PrizeListVo[]>([]);

    function handleToggleLoading(isLoading: boolean) {
        loadingRef.current = isLoading;
    }

    function handleResetPrizeList() {
        setPrizeList([]);
    }

    const handleFetch = async (_entries: number) => {
        if (loadingRef.current) return;

        try {
            handleToggleLoading(true);
            const fetched = await new PrizeDistributionCalculatorRepo().getPrizeDistribution(contestId, _entries);
            setPrizeList(fetched);
        } catch (e) {
            form.setError(ENTRIES_FIELD_NAME, {
                message: errMessage
            });
            handleResetPrizeList();
        } finally {
            handleToggleLoading(false);
        }
    };

    const handleDebouncedSubmit = useCallback((
        _.debounce((
            form.handleSubmit((data) => {
                handleFetch(data[ENTRIES_FIELD_NAME]);
            }, () => {
                handleResetPrizeList();
            })
        ), FETCH_WAIT)
    ), [contestId]);

    useEffect(() => {
        handleResetPrizeList();
        handleDebouncedSubmit();
    }, [entries]);

    return { form, entries, inputError, prizeList };
}

function useEntriesForm(minSlips: number, spots: number) {
    const intl = useIntl();
    const errMessage = intl.formatMessage({
        id: "prize_distribution_calculator_teams_input_validation_error",
    }, {
        minSlips,
        spots,
    });

    const entriesZodSchema = useMemo(() => (
        z
            .coerce
            .number()
            .int({ message: errMessage })
            .min(minSlips, { message: errMessage })
            .max(spots, { message: errMessage })
    ), [errMessage, minSlips, spots]);

    const resolver = zodResolver(z.object({
        [ENTRIES_FIELD_NAME]: entriesZodSchema
    }));

    const defaultValue = useMemo(() => (
        getTeamsInputDefaultValue(minSlips, spots)
    ), [minSlips, spots]);

    const form = useForm({
        resolver: resolver,
        defaultValues: {
            [ENTRIES_FIELD_NAME]: defaultValue
        },
        mode: "onChange"
    });

    const entries = form.watch(ENTRIES_FIELD_NAME);
    const inputError = form.formState.errors[ENTRIES_FIELD_NAME];
    return { errMessage, entriesZodSchema, form, entries, inputError };
}
