import React from "react";
import { MATCH_STATUS_FROM_API } from "../../../types/match/Match";
import { MyTeamVO } from "../../../types/team/MyTeam";
import { TeamCard } from "../../card/TeamCardStyle";
import TeamCardContent from "../../team/TeamCardContent";
import { CheckBoxWrapper, TeamName, TeamNameWrapper } from "../../team/TeamCardStyle";
import { TeamCardWrapper } from "../SelectTeamPanelStyle";
import { FantasyCheckbox } from "components/checkbox/FantasyCheckbox";
import { CHECKBOX_VARIANTS } from "components/checkbox/types";

interface DisableCardProps {
    team: MyTeamVO;
    matchStatus: MATCH_STATUS_FROM_API;
}

export const DisabledCard: React.FC<DisableCardProps> = ({
                                                             team,
                                                             matchStatus
                                                         }) => {
    return <TeamCardWrapper data-testid={`my-team-${team.id}`}
                            className="fantasy-card-border"
    >
        <TeamCard className="opacity-50">
            <TeamCardContent team={team} matchStatus={matchStatus}>
                <TeamNameWrapper>
                    <CheckBoxWrapper>
                        <FantasyCheckbox variant={CHECKBOX_VARIANTS.secondary} disabled />
                    </CheckBoxWrapper>
                    <TeamName>{team.name}</TeamName>
                </TeamNameWrapper>
            </TeamCardContent>
        </TeamCard>
    </TeamCardWrapper>;
};
