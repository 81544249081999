import { useTaskProvider } from "domain/task/TaskProvider";
import { UpgradeWrapper } from "domain/task/TaskStyle";
import { analyticsEvent, logCustomEvent } from "ga";
import { getRequiredDeposit } from "helpers/ApLevelHelper";
import { multiplyByOneHundred } from "helpers/number/NumberHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import React from "react";
import { MdChevronRight } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { useSelector } from "../../../store";

export const UpgradeSection: React.FC = () => {
    const history = useFantasyHistory();
    const { apOverview } = useTaskProvider();
    const { featureConfig } = useSelector(state => state.FantasyGameConfigState);

    const handleClick = () => {
        logCustomEvent(analyticsEvent.webTaskUpgrading);

        if (!apOverview) {
            return;
        }

        history.replace(
            ROUTER_PATH_CONFIG.deposit.select,
            {
                requiredDepositCent: multiplyByOneHundred(getRequiredDeposit(apOverview.currentAp, apOverview.nextLevelMinAp)),
                addDepositFlowFinishedGoTo: history.location.pathname
            }
        );

    };

    if (!featureConfig.addCoin) return null;

    return <UpgradeWrapper onClick={handleClick}>
                <span className="fz-sf-bold-subhead">
                    <FormattedMessage id="daily_task_panel_label_upgrade" />
                </span>
        <MdChevronRight size={24} />
    </UpgradeWrapper>;
};
