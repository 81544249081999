import { PlayerCard } from "domain/result/detail/components/PlayerStats/PlayerCard";
import { useResultMatchDetailContext } from "domain/result/detail/ResultMatchDetailProvider";
import usePlayerInfoDialog from "domain/team/playerInfoDialog/PlayerInfoDialog";
import { PlayerInfoDialogFooterType } from "domain/team/playerInfoDialog/PlayerInfoDialogFooter";
import { PlayerInfoDialogHeaderType } from "domain/team/playerInfoDialog/PlayerInfoDialogHeader";
import { cn } from "helpers/cn";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "store";
import { SquadPlayer } from "types/player/Player";

export const PlayerStatsContent: React.FC = () => {
    const { data: { myTeams, match } } = useSelector((store) => store.TeamPreviewState);
    const { matchResultPlayers, sport } = useResultMatchDetailContext();
    const players = matchResultPlayers?.sortedPlayers;

    const { PlayerInfoDialog, handlePlayerInfoDialogOpen } = usePlayerInfoDialog(
        PlayerInfoDialogHeaderType.POINT,
        PlayerInfoDialogFooterType.ONLY_CLOSE,
    );

    if (!match || !players) {
        return null;
    }

    return <>
        <div className={cn(
            "tw-flex tw-justify-between tw-p-2",
            "tw-text-subtitle-2 tw-text-grey-750/80"
        )}>
            <div><FormattedMessage id="label_players" /></div>
            <div><FormattedMessage id="result_match_info_label_scores" /></div>
        </div>

        {
            players?.map((player) => {
                return <PlayerCard
                    key={player.id}
                    sport={sport}
                    player={player}
                    onClickAvatar={() => handlePlayerInfoDialogOpen(player)}
                />;
            })
        }

        <PlayerInfoDialog
            match={match}
            myTeams={myTeams}
            players={players.map(player => (new SquadPlayer({
                ...player,
                sport: sport,
                isHome: player.squadKey === match.squadAKey,
                squadLogoUrl: player.squadKey === match.squadAKey ? match.squadALogoUrl : match.squadBLogoUrl,
            })))}
        />
    </>;
};
