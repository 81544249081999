import styled from "styled-components";
import { Image } from "react-bootstrap";

export const TeamsListSection = styled.div.attrs(() => ({
    className: ""
}))`
  width: 84%;
`;
export const TeamTagRow = styled.div.attrs(() => ({
    className: "d-flex flex-wrap"
}))``;

export const TeamTagNameWrapper = styled.div.attrs(() => ({
    className: "tag-name-wrapper d-flex"
}))`
  margin-right: .8rem;
  margin-bottom: .4rem;
`;

export const ImgWrapper = styled.div.attrs(() => ({
    className: ""
}))`
  width: 1.2rem;
`;

export const OverTenTagsIcon = styled(Image).attrs(() => ({
    className: "w-100 h-100"
}))``;
