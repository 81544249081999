import PageViewRecorder from "components/ga/PageViewRecorder";
import useFantasyConfig from "hooks/useFantasyConfig";
import React, { useRef } from "react";
import styled from "styled-components";
import MyMatchNavbar from "domain/match/components/myMatches/MyMatchNavbar";
import MyMatchTab from "domain/match/components/myMatches/MyMatchTab";
import { MyMatchProvider } from "domain/match/components/providers/MyMatchProvider";
import { MyMatchContent } from "domain/match/pages/my/MyMatchContent";

const MyMatches = () => {
    const { featureToggle } = useFantasyConfig();
    const myMatchesNavbarRef = useRef<HTMLDivElement>(null);

    return (
        <MyMatchProvider>
            <PageViewRecorder title="My Games" />
            <MyMatchesContainer
                className="tw-bg-grey-50 tw-h-full"
                $height={myMatchesNavbarRef.current?.clientHeight ?? 0}
            >
                <div
                    ref={myMatchesNavbarRef}
                    className="tw-fixed tw-top-0 tw-left-0 tw-z-3 tw-w-full tw-max-w-app-max"
                >
                    <MyMatchNavbar />
                    {featureToggle && <MyMatchTab />}
                </div>
                <div className="tw-flex tw-flex-col tw-min-h-full" data-testid="my-matches">
                    <MyMatchContent />
                </div>
            </MyMatchesContainer>
        </MyMatchProvider>
    );
};

interface MyMatchesContainerProps {
    $height: number;
}

const MyMatchesContainer = styled.div.attrs<MyMatchesContainerProps>({})`
    ${({ $height }: MyMatchesContainerProps) => {
        return `
        padding-top: ${$height}px;
        `;
    }}
`;


export default MyMatches;
