import React, { useState } from "react";
import { useConnectChannel } from "domain/chat/hooks/UseConnectChannel";
import { useScrollControl } from "domain/chat/hooks/UseScrollControl";
import { ChatRoomError } from "domain/chat/components/ChatRoomError";
import CustomizeInput from "domain/chat/components/CustomizeInput";
import {
    getMessagesAfterEntered,
    getMessagesBeforeEntered,
    isScrollbarReachDividePoint
} from "domain/chat/ChatRoomHelper";
import { AllMessageWrapper, LoadingWrapper, ToBottomButton } from "domain/chat/ChatRoomStyle";
import { Spinner } from "react-bootstrap";
import { Messages } from "domain/chat/components/Messages";
import { SystemAnnouncement } from "domain/chat/components/SystemAnnouncement";

export const ChatRoomContent = () => {
    const [enteredTime] = useState(new Date().getTime());

    const {
        chatObjectData: { data: chatObject, isLoading: chatConnectLoading, error: chatConnectError },
        queryOldMessages,
        messages,
        updateMessages
    } = useConnectChannel();

    const {
        messagesContainer,
        isBottomButtonShow,
        scrollToBottomSmoothly
    } = useScrollControl(messages, updateMessages, queryOldMessages.mutateAsync);

    if (chatConnectError) {
        return <ChatRoomError error={chatConnectError} />;
    }

    if (chatConnectLoading || !chatObject) {
        return null;
    }

    return (
        <>
            <CustomizeInput
                isScrollbarReachDividePoint={isScrollbarReachDividePoint(messagesContainer.current, window.innerHeight, window.scrollY)}
                chatObject={chatObject}
            />
            <AllMessageWrapper ref={messagesContainer}>
                {
                    queryOldMessages.isLoading && <LoadingWrapper>
                        <Spinner animation="border" role="status" />
                    </LoadingWrapper>
                }
                <Messages messages={getMessagesBeforeEntered(messages, enteredTime)} myChatId={chatObject.id} />

                <SystemAnnouncement />

                <Messages messages={getMessagesAfterEntered(messages, enteredTime)} myChatId={chatObject.id} />

                {
                    isBottomButtonShow && <ToBottomButton onClick={scrollToBottomSmoothly} />
                }
            </AllMessageWrapper>
        </>
    );
};
