import { Callback } from "Common";
import { FantasyPopupProps, POPUP_SIZE } from "components/popup/FantasyBasicPopup";
import { BUTTON_PATTERN, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { cn } from "helpers/cn";
import { Icon, joinNineCatContestSuccessfullyImg } from "helpers/IconHelper";
import React from 'react';
import { FormattedMessage } from "react-intl";
import { ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";

export interface JoinNineCatContestSuccessfullyDialogProps {
    winningTransferResult?: {
        currentWinning: string
        balanceWinning: string
    };
    entryFee: number;
    slateDetailTitle: string;
    teamNames: string[];
    entryFeeType: ENTRY_FEE_TYPE_FROM_API;
    onBackToHome: Callback;
    onClose: Callback;
}

export function makeJoinNineCatContestSuccessfullyDialogProps({
                                                                  onBackToHome,
                                                                  onClose,
                                                                  slateDetailTitle,
                                                                  teamNames,
                                                                  entryFee,
                                                                  entryFeeType,
                                                                  winningTransferResult
                                                              }: JoinNineCatContestSuccessfullyDialogProps): FantasyPopupProps {
    const shouldShowWinningTransferResult = winningTransferResult ? winningTransferResult.balanceWinning !== winningTransferResult.currentWinning : false;

    const entryFeeTypeUnit = entryFeeType === ENTRY_FEE_TYPE_FROM_API.DEPOSIT ? (
        <FormattedMessage id={"label_unit_coins"} />
    ) : (
        <FormattedMessage id={"label_unit_rubies"} />
    );

    return {
        show: true,
        popupSize: POPUP_SIZE.MD,
        title: (
            <FormattedMessage id={"dialog_join_contest_successfully_title"} />
        ),
        content: (
            <div className={cn(
                "tw-flex tw-flex-col tw-items-center tw-gap-3"
            )}>
                <div className={"tw-m-auto tw-max-w-[11.6rem]"}>
                    <img alt="join_nine_cat_contest_successfully" src={joinNineCatContestSuccessfullyImg} />
                </div>
                {(winningTransferResult && shouldShowWinningTransferResult) && (
                    <div className={cn(
                        "tw-flex tw-items-center tw-gap-1",
                        "tw-py-1.5 tw-px-2",
                        "tw-bg-grey-100",
                        "tw-rounded-full"
                    )}
                         data-testid="winningTransferBadge"
                    >
                        <Icon.Winning size={"1.6rem"} />
                        <span
                            className={"tw-text-body-1 tw-font-bold tw-text-secondary-600"}>{winningTransferResult.balanceWinning}</span>
                        <span className={"tw-text-caption-2 tw-text-grey-700"}>
                            {`(${winningTransferResult.currentWinning}→${winningTransferResult.balanceWinning})`}
                        </span>
                    </div>
                )}
                <p className={"tw-px-5 tw-text-subtitle-1 tw-whitespace-pre-line tw-text-center"}>
                    <FormattedMessage id={"dialog_join_contest_successfully_content"} values={{
                        slateName: `${slateDetailTitle} 9-CAT`,
                        entryFee,
                        entryFeeType: entryFeeTypeUnit,
                        teams: teamNames.join(', '),
                    }} />
                </p>

            </div>
        ),
        buttonSetting: {
            buttonGap: 3,
            confirmButtonVariant: NEW_BUTTON_VARIANTS.primaryLight,
            buttonPattern: BUTTON_PATTERN.pill,
            confirmButtonText:
                <FormattedMessage id={"button_join_another"} />,
            onConfirm: onClose,
            cancelButtonVariant: NEW_BUTTON_VARIANTS.grey,
            cancelButtonText:
                <FormattedMessage id={"button_back_to_home"} />,
            onCancel: () => {
                onBackToHome();
                onClose();
            },
        },
        onHide: onClose
    };
}
